<template>
    <div>  
        <div
            v-if="showBanner && bannerData.bannerType==='small'"
            class="new-content-notification flex flex-col items-center z-5001 fixed bottom-0 w-full"
        >
            <div class="title flex flex-row flex-grow justify-center items-center">
                <div class="flex-no-grow icon-container items-center content-center">
                    <font-awesome-icon
                        class=" flex-0 text-red text-3xl inline"
                        icon="exclamation-circle"
                    />
                </div>
                <h4 class="flex-grow text-white">
                    {{ bannerData.smallBannerMessage }}
                </h4>
            </div>
            <div class="content flex-grow text-white">
                <a @click="hideBanner" class="cursor-pointer">
                    Click here to close this banner
                </a>
            </div>
        </div>

        <div v-if="showBanner && bannerData.bannerType==='big'">
            <div class="z-5001 absolute top-0 bottom-0 w-full h-full background-dark">
                <div
                    class="new-content-notification big-banner"
                >
                    <div class="title text-center">
                        <font-awesome-icon
                            class="text-red text-3xl inline-block"
                            icon="exclamation-circle"
                        />
                        <h4 class="text-white inline-block ml-5 align-top">
                            {{ bannerData.bigBannerMessage }}
                        </h4>
                    </div>
                    <div class="title flex flex-row flex-grow justify-center items-center">
                        <h4 class="text-white">
                            {{ bannerData.cookieMessage }}
                        </h4>
                    </div>
                    <div class="title">
                        <div class='wrapper'>
                            <tabs v-if="screenSize > 768">
                                <tab title="Chrome"><pre>{{ bannerData.chromeMessage }}</pre></tab>
                                <tab title="Safari"><pre>{{ bannerData.safariMessage }}</pre></tab>
                                <tab title="Edge"><pre>{{ bannerData.edgeMessage }}</pre></tab>
                                <tab title="Firefox"><pre>{{ bannerData.firefoxMessage }}</pre></tab>
                            </tabs>
                            <tabs v-if="screenSize <= 768">
                                <tab title="Chrome">{{ bannerData.chromeMessage }}</tab>
                                <tab title="Safari">{{ bannerData.safariMessage }}</tab>
                                <tab title="Edge">{{ bannerData.edgeMessage }}</tab>
                                <tab title="Firefox">{{ bannerData.firefoxMessage }}</tab>
                            </tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tab from './tab.vue'
import Tabs from './tabs.vue'

export default {
  components: {
    Tab,
    Tabs
  },
  name: "MaintenanceBanner",
  props: ["bannerData"],
  data() {
    return {
      showBanner: true,
      screenSize: null,
      sessionStorage: null
    }     
  },
  mounted() {
    // Gets the current screen size to display the correct tabs
    this.screenSize = screen.width;

    // When close the small banner, the banner won't be shown for the session
    if(sessionStorage.getItem("clickClose") && this.bannerData.bannerType === "small") {
      this.showBanner = false;    
    } else if(this.bannerData.bannerType === "big") {
      sessionStorage.removeItem("clickClose");  
    }
  },
  methods: {
    // Closes the banner
    hideBanner() {
      this.showBanner = false;
      sessionStorage.setItem("clickClose", true);
    }
  }
};
</script>

<style scoped>
pre {
    font-family: "Gotham", Helvetica, Arial, sans-serif;
    font-size: 1em;
}

.new-content-notification {
    background-color: rgb(0, 38, 100);
    color: #fff;
    margin-top: 1.5em;
}

.title {
    padding: 1.1em 0;
}

.icon-container {
    padding-right: 1.5em;
    width: 50px;
}

.content {
    padding: 1em 0 1em 0;
    border-top: 2.8px solid #3c54c3;
}

a:hover {
    color: rgb(119, 150, 172);
}

h4 {
    height: auto;
}

.z-5001 {
    z-index: 5001;
}

.bottom-0 {
    bottom: 0;
}

.big-banner {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    border-radius: 0.5rem;
    padding: 2rem;
}

.background-dark {
    background: rgba(0, 0, 0, 0.6);
}

.wrapper {
    /* width: 100%;
    min-height: 100vh;
    background-color: #f8f8f8;
    margin: 0; */
    padding: 1.25rem; /* 20px */
}

/* .change__style {
    background-color: #eee;
    font-size: 1em;
    margin-bottom: 10px;
    padding: 5px;
} */
</style>
