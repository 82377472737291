var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "flex w-full p-4 bg-blue-button" }, [
    _vm._m(0),
    _c("div", { staticClass: "block sm:hidden" }, [
      _c(
        "button",
        {
          staticClass:
            "flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white",
          on: { click: _vm.toggle },
        },
        [
          _c(
            "svg",
            {
              staticClass: "fill-current h-3 w-3",
              attrs: {
                viewBox: "0 0 20 20",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("title", [_vm._v("Menu")]),
              _c("path", {
                attrs: { d: "M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" },
              }),
            ]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "w-full flex-grow sm:flex sm:items-center sm:w-auto -mt-12",
        class: _vm.open ? "block" : "hidden",
      },
      [
        _c(
          "div",
          { staticClass: "base-container flex flex-row flex-no-wrap" },
          [
            _c("RoadNameSearch"),
            _c(
              "div",
              { staticClass: "sm:flex flex w-1/6 ml-4 justify-end" },
              [
                _vm.user
                  ? _c(
                      "button",
                      {},
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "profile/view",
                                params: { id: _vm.user.email },
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "h-8 absolute -mt-4",
                                attrs: {
                                  "aria-hidden": "true",
                                  focusable: "false",
                                  "data-prefix": "fas",
                                  "data-icon": "user",
                                  id: "SVG",
                                  role: "img",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 496 512",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "hover:text-grey-light text-white font-bold ml-16",
                    attrs: {
                      tag: "button",
                      id: "logout-button",
                      "data-cypress": "logout",
                      to: { name: "logout" },
                    },
                  },
                  [_vm._v(" LOG OUT ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex items-center text-white w-full md:max-w-xs pl-2" },
      [
        _c("img", {
          staticClass: "h-14",
          attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }