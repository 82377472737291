var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ShowLoading", {
    attrs: { isLoading: _vm.RNA_WAITER },
    scopedSlots: _vm._u([
      {
        key: "component",
        fn: function () {
          return [
            _c("div", { staticClass: "base-container text-black" }, [
              _c(
                "section",
                { staticClass: "pt-1 pb-5 justify-center w-full block" },
                [
                  _c("div", { staticClass: "flex justify-between" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center",
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _c("BackArrowWithTitle", {
                          attrs: { title: "Edit RNA" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm.rna
                ? _c("div", { staticClass: "w-full bg-white rounded-lg" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-9/10 flex flex-wrap content-center mx-auto pt-16 pb-24",
                      },
                      [
                        _c("p", { staticClass: "text-title w-full" }, [
                          _vm._v("RNA Details"),
                        ]),
                        _c("div", { staticClass: "w-full dividing-line-h-1" }, [
                          _vm._v(" "),
                        ]),
                        _c(
                          "p",
                          { staticClass: "text-normal-bold w-full pt-4" },
                          [
                            _c("a", { staticClass: "text-red-star" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Name of RNA "),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rna.authority_name,
                              expression: "rna.authority_name",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mt-4 input-area",
                          attrs: {
                            type: "text",
                            "data-cypress": "nameofrna-field",
                            placeholder: "Enter name of RNA here...",
                            name: "Authority Name",
                          },
                          domProps: { value: _vm.rna.authority_name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.rna,
                                "authority_name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("FieldErrorMessage", {
                          attrs: {
                            message: _vm.errors.first("Authority Name"),
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "text-normal-bold w-full pt-4" },
                          [_vm._v(" Default Local Government Area ")]
                        ),
                        _c(
                          "norns-select",
                          {
                            directives: [
                              { name: "validate", rawName: "v-validate" },
                            ],
                            staticClass: "w-full mr-4 norns-select",
                            attrs: {
                              multiple: false,
                              options: _vm.lgasClean,
                              searchable: true,
                              "close-on-select": true,
                              placeholder: "Select LGA...",
                              name: "LGA",
                              "data-cypress": "lga-select",
                              "data-vv-as": "Local Government Authority",
                              "select-on-tab": true,
                            },
                            model: {
                              value: _vm.rna.default_lga,
                              callback: function ($$v) {
                                _vm.$set(_vm.rna, "default_lga", $$v)
                              },
                              expression: "rna.default_lga",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "noOptions" },
                                slot: "noOptions",
                              },
                              [_vm._v("Loading...")]
                            ),
                          ]
                        ),
                        _vm.rna.default_lga &&
                        !_vm.lgasClean.includes(_vm.rna.default_lga)
                          ? _c("FieldErrorMessage", {
                              attrs: {
                                message:
                                  "LGA does not exist or name does not match available LGAs",
                              },
                            })
                          : _vm._e(),
                        _c("p", { staticClass: "w-full text-title mt-16" }, [
                          _vm._v(" Involved Party Gazettal Details "),
                        ]),
                        _c("div", { staticClass: "w-full dividing-line-h-1" }, [
                          _vm._v(" "),
                        ]),
                        _c("p", { staticClass: "text-normal w-full mt-4" }, [
                          _vm._v(
                            " The details below will be inserted into all gazette notices relating to approved road names for this specific RNA. "
                          ),
                          _c("br"),
                          _vm._v(
                            "If the details are changed here, those changes will be reflected in all gazette notices for road names generated by this system. "
                          ),
                        ]),
                        _c("div", { staticClass: "flex flex-row w-full" }, [
                          _c(
                            "div",
                            { staticClass: "mt-6 w-1/2" },
                            [
                              _c("p", { staticClass: "text-normal-bold" }, [
                                _c("a", { staticClass: "text-red-star" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("Name of Responsible Person "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.rna.manager_name,
                                    expression: "rna.manager_name",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "w-full mt-4 input-area",
                                attrs: {
                                  type: "text",
                                  "data-cypress": "nameofperson-field",
                                  placeholder:
                                    "Enter name of Responsible Person here...",
                                  name: "Responsible Person Name",
                                },
                                domProps: { value: _vm.rna.manager_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.rna,
                                      "manager_name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first(
                                    "Responsible Person Name"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-6 w-1/2 ml-8" },
                            [
                              _c("p", { staticClass: "text-normal-bold" }, [
                                _c("a", { staticClass: "text-red-star" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "Organisational Title of Responsible Person "
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.rna.manager_role,
                                    expression: "rna.manager_role",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "w-full mt-4 input-area",
                                attrs: {
                                  type: "text",
                                  "data-cypress": "nameoforg-field",
                                  placeholder: "Enter organisational title...",
                                  name: "Responsible Person Title",
                                },
                                domProps: { value: _vm.rna.manager_role },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.rna,
                                      "manager_role",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first(
                                    "Responsible Person Title"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-6 w-full" },
                          [
                            _c("p", { staticClass: "text-normal-bold" }, [
                              _c("a", { staticClass: "text-red-star" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("RNA Address Line 1 "),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.rna.address_line_1,
                                  expression: "rna.address_line_1",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full mt-4 input-area",
                              attrs: {
                                type: "text",
                                "data-cypress": "address1-field",
                                placeholder: "Enter address here...",
                                name: "Authority Address",
                              },
                              domProps: { value: _vm.rna.address_line_1 },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.rna,
                                    "address_line_1",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("FieldErrorMessage", {
                              attrs: {
                                message: _vm.errors.first("Authority Address"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mt-6 w-full" }, [
                          _c("p", { staticClass: "text-normal-bold" }, [
                            _vm._v("RNA Address Line 2"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.rna.address_line_2,
                                expression: "rna.address_line_2",
                              },
                              { name: "validate", rawName: "v-validate" },
                            ],
                            staticClass: "w-full mt-4 input-area",
                            attrs: {
                              type: "text",
                              "data-cypress": "address2-field",
                              placeholder: "Enter address here...",
                              name: "Authority Address line 2",
                            },
                            domProps: { value: _vm.rna.address_line_2 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.rna,
                                  "address_line_2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "w-full flex flex-row" }, [
                          _c(
                            "div",
                            { staticClass: "mt-6 w-1/2" },
                            [
                              _c("p", { staticClass: "text-normal-bold" }, [
                                _c("a", { staticClass: "text-red-star" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("Suburb "),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.rna.rna_suburb,
                                    expression: "rna.rna_suburb",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "w-full mt-4 input-area",
                                attrs: {
                                  type: "text",
                                  "data-cypress": "suburb-field",
                                  placeholder: "Enter suburb name here...",
                                  name: "Suburb",
                                },
                                domProps: { value: _vm.rna.rna_suburb },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.rna,
                                      "rna_suburb",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: { message: _vm.errors.first("Suburb") },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-1/2 ml-8 flex flex-row" },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-6 w-1/2" },
                                [
                                  _c("p", { staticClass: "text-normal-bold" }, [
                                    _c("a", { staticClass: "text-red-star" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v("State "),
                                  ]),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.rna.rna_state,
                                          expression: "rna.rna_state",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "w-full mt-4 input-area",
                                      attrs: {
                                        "data-cypress": "state-field",
                                        name: "State",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.rna,
                                            "rna_state",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            disabled: "",
                                            hidden: "",
                                            selected: "",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.rna.rna_state))]
                                      ),
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "NSW", selected: "" },
                                        },
                                        [_vm._v("NSW")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "VIC" } },
                                        [_vm._v("VIC")]
                                      ),
                                      _c("option", { attrs: { value: "SA" } }, [
                                        _vm._v("SA"),
                                      ]),
                                      _c("option", { attrs: { value: "WA" } }, [
                                        _vm._v("VIC"),
                                      ]),
                                      _c("option", { attrs: { value: "NT" } }, [
                                        _vm._v("NT"),
                                      ]),
                                      _c(
                                        "option",
                                        { attrs: { value: "QLD" } },
                                        [_vm._v("QLD")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "ACT" } },
                                        [_vm._v("ACT")]
                                      ),
                                    ]
                                  ),
                                  _c("FieldErrorMessage", {
                                    attrs: {
                                      message: _vm.errors.first("State"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-6 ml-8 w-1/2" },
                                [
                                  _c("p", { staticClass: "text-normal-bold" }, [
                                    _c("a", { staticClass: "text-red-star" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v("Postcode "),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:4|min:4",
                                        expression: "'required|max:4|min:4'",
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.rna.rna_postcode,
                                        expression: "rna.rna_postcode",
                                      },
                                    ],
                                    staticClass: "w-full mt-4 input-area",
                                    attrs: {
                                      type: "text",
                                      "data-cypress": "postcode-field",
                                      placeholder: "Enter postcode here...",
                                      name: "Postcode",
                                    },
                                    domProps: { value: _vm.rna.rna_postcode },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.rna,
                                          "rna_postcode",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("FieldErrorMessage", {
                                    attrs: {
                                      message: _vm.errors.first("Postcode"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex my-6" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button-red-hollow",
                      attrs: {
                        type: "button",
                        "data-cypress": "cancel-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue ml-auto",
                      attrs: {
                        type: "button",
                        "data-cypress": "save-button",
                        disabled:
                          !_vm.isFormValid ||
                          !_vm.isFormDirty ||
                          _vm.$wait.is(_vm.SAVING_RNA_WAITER),
                        isSpinning: _vm.$wait.is(_vm.SAVING_RNA_WAITER),
                      },
                      on: { click: _vm.handleSubmitRna },
                    },
                    [_vm._v(" Save & Continue ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }