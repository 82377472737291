<template>
  <button
    :class="initials + ' ' + classNames"
    class="justify-center self-start border-2 rounded-full focus:outline-none focus:shadow-outline self-center"
  >
    {{ initials }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    classNames: {
      type: String
    }
  },
  computed: {
    initials() {
      if (this.label == "Road Name Public" || this.label == "Public") {
        return "PUB";
      } else if (
        this.label == "Road Name Non-Dedicated" ||
        this.label == "Private" ||
        this.label == "Non-Dedicated"
      ) {
        return "ND";
      } else if (
        this.label == "Road Name Pre-Approval" ||
        this.label == "Road Name Unapproved Gazette" ||
        this.label == "Pre-Approval" ||
        this.label == "UNKNOWN"
      ) {
        return "PA";
      }
      return "NA";
    }
  }
};
</script>

<style scoped>
.Public .PUB {
  color: #0a7cb9;
  border-color: #0a7cb9;
}
.Non-Dedicated .ND .NA {
  color: #c60c30;
  border-color: #c60c30;
}
.Pre-Approved .PA {
  color: #00a908;
  border-color: #00a908;
}
</style>
