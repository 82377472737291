<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <div class="w-5/6 mx-auto" v-bind:class="{ 'py-16': !isPublic }">
        <h3
          class="pb-3 mb-1 w-full text-title dividing-line-h"
          v-if="isTypePlaceNameProposal"
        >
          Place Name Proposal Checklist
        </h3>
        <h3
          class="pb-3 mb-8 w-full text-title dividing-line-h"
          v-if="isTypeNewAmendedLocality"
        >
          Proposal Checklist
        </h3>
        <h3
          class="pb-3 mb-8 w-full text-title dividing-line-h"
          v-if="isTypeLocalityBoundaryAmendment"
        >
          Proposal Checklist
        </h3>
        <Place v-if="isTypePlaceNameProposal" :readOnly="readOnly" />
        <Locality v-if="isTypeNewAmendedLocality" :readOnly="readOnly" />
        <Boundary v-if="isTypeLocalityBoundaryAmendment" :readOnly="readOnly" />
      </div>
    </div>
  </div>
</template>

<script>
import Place from "./components/place.vue";
import Locality from "./components/locality.vue";
import Boundary from "./components/boundary.vue";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_BOUNDARY
} from "@/components/proposals/place/pnp-constants.js";

export default {
  props: {
    proposalType: {
      type: String
    },
    readOnly: {
      type: Boolean
    },
    isPublic: {
      type: Boolean
    }
  },
  components: {
    Place,
    Locality,
    Boundary
  },
  computed: {
    isTypePlaceNameProposal() {
      return this.proposalType === PROPOSAL_TYPE_PLACE;
    },
    isTypeNewAmendedLocality() {
      return this.proposalType === PROPOSAL_TYPE_LOCALITY;
    },
    isTypeLocalityBoundaryAmendment() {
      return this.proposalType === PROPOSAL_TYPE_BOUNDARY;
    }
  }
};
</script>
