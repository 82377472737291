import VeeValidate from "vee-validate";
import moment from "moment";

const phoneNumber = {
  getMessage() {
    return "Phone number is in an invalid format";
  },
  validate: value =>
    /^(^((\d )|\d){8}$)|(^(04|02)((\d )|\d){8}$)|(^(\+61)((\d )|\d){9}$)$/g.test(
      value
    )
};

VeeValidate.Validator.extend("phoneNumber", phoneNumber);

const passwordPolicy = {
  getMessage: () =>
    "Password must be at least 8 characters and contain a number, special character, upper and lower case characters.",
  validate: value =>
    new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(\\\\-]).{8,}"
    ).test(value)
};

VeeValidate.Validator.extend("passwordPolicy", passwordPolicy);

const date = {
  getMessage: () => "Date must be a valid date in the format DD/MM/YYYY",
  validate: value => /^[\d]{1,2}\/[\d]{1,2}\/[\d]{4}$/.test(value) && moment(value,"D/M/YYYY").isValid()
};

VeeValidate.Validator.extend("date", date);

export default VeeValidate;
