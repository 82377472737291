"use strict";
import {
  updateUser,
  STATUS_APPROVED,
  STATUS_SUSPENDED
} from "../helpers/userCRUD.js";

export default {
  methods: {
    async suspendUser(user) {
      this.$wait.start(`suspending user ${user.email}`);
      user.user_account_status = STATUS_SUSPENDED;
      await updateUser(user);
      this.$wait.end(`suspending user ${user.email}`);
    },
    async approveUser(user) {
      this.$wait.start(`activating user ${user.email}`);
      user.user_account_status = STATUS_APPROVED;
      await updateUser(user);
      this.$wait.end(`activating user ${user.email}`);
    }
  }
};
