var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-normal" },
    [
      !_vm.unique && !_vm.token
        ? _c(
            "a",
            {
              staticClass: "pt-4 pb-4",
              on: { click: _vm.returnToManageProposalsList },
            },
            [
              _c("BackArrowWithTitle", {
                staticClass: "pl-16 pt-4",
                attrs: { title: "Manage Proposals", size: "h4" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-9/10 mx-auto" },
        [
          _c("showLoading", {
            attrs: { isLoading: "fetchingProposalWithComments" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    _vm.advertisement
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 px-8",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full border flex flex-row border-gray-200 rounded items-center",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-black font-bold text-2xl mx-8",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.advertisement.geographical_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dividing-line-v text-black flex-grow flex flex-row",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4",
                                      },
                                      [
                                        _c("span", [_vm._v("LGA")]),
                                        _vm._l(
                                          _vm.advertisement.lga,
                                          function (lga) {
                                            return _c("p", { key: lga }, [
                                              _vm._v(_vm._s(lga)),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4 pr-4",
                                      },
                                      [
                                        _c("span", [_vm._v("Designation")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertisement.designation
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Publication Date"),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.advertisement.date_start
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4",
                                      },
                                      [
                                        _c("span", [_vm._v("Closing Date")]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.advertisement.date_end
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !!_vm.comments
                      ? _c(
                          "section",
                          {
                            staticClass:
                              "pt-10 mb-20 justify-center w-full block self-center",
                          },
                          [
                            _vm.user.isAdmin
                              ? _c(
                                  "div",
                                  { staticClass: "flex justify-end mb-4" },
                                  [
                                    _vm.comments.length &&
                                    _vm.isAlreadyEncrypted
                                      ? _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button-blue mx-4 jest-view-section-10 flex-1",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModal(
                                                    _vm.modalName[1]
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Decrypt PI ")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.comments.length &&
                                    !_vm.isAlreadyEncrypted
                                      ? _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button-blue mx-4 jest-view-section-10 flex-1",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openModal(
                                                    _vm.modalName[0]
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Encrypt PI ")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "flex" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button-blue mx-4 jest-view-section-10 flex-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadSingleAgenda(
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Download Public Comments Without PI "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    !_vm.isAlreadyEncrypted
                                      ? _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button-blue mx-4 jest-view-section-10 flex-1",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadSingleAgenda(
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Download Public Comments With PI "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm.user.isAdmin &&
                            _vm.proposal.response_to_objections.length
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "black",
                                      "text-align": "right",
                                      margin: "1rem 0",
                                      "font-size": "1.2rem",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Council has uploaded " +
                                        _vm._s(
                                          _vm.proposal.response_to_objections
                                            .length
                                        ) +
                                        " document" +
                                        _vm._s(
                                          _vm.proposal.response_to_objections
                                            .length === 1
                                            ? "."
                                            : "s."
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "h2",
                              {
                                staticClass: "font-bold",
                                class: _vm.isPublic
                                  ? "text-white"
                                  : "text-black",
                              },
                              [_vm._v(" Comments For This Proposal ")]
                            ),
                            _vm.isUploadAvailable
                              ? _c(
                                  "div",
                                  [
                                    _c("uploadScoped", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "min_value:1",
                                          expression: "'min_value:1'",
                                        },
                                      ],
                                      staticClass: "mt-8",
                                      attrs: {
                                        "data-cypress": "upload-map",
                                        label: "Response to Objections Upload",
                                        important: false,
                                        files: _vm.responseToObjectionFiles,
                                        drafted: true,
                                        stateKey: "proposal",
                                        stateProp: "response_to_objections",
                                        uploadFile: _vm.uploadFile,
                                        disabled: false,
                                        labelColor: "black",
                                        geonameIdentifier:
                                          _vm.proposal.geoname_identifier,
                                        proposalName:
                                          _vm.proposal.geographical_name,
                                        councilName:
                                          _vm.proposal.organisation_name,
                                      },
                                      on: {
                                        deleteFile: (fileIndex) =>
                                          _vm.responseToObjectionFiles.splice(
                                            fileIndex,
                                            1
                                          ),
                                        rerender: (payload) =>
                                          _vm.rerenderUploadScope(payload),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full flex rounded-lg pt-6 pb-6 mt-6 mb-6 pr-8 pl-8 orange-box border-2 text-center self-center justify-center",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass:
                                      "svg-inline--fa fa-exclamation-circle fa-w-32 w-8 h-8 mr-2",
                                    attrs: {
                                      "aria-hidden": "true",
                                      focusable: "false",
                                      "data-prefix": "fas",
                                      "data-icon": "exclamation-circle",
                                      role: "img",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 512 512",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "currentColor",
                                        d: "M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "text-center self-center orange-text",
                                  },
                                  [
                                    _vm._v(
                                      " The comments provided here are private/confidential and should not be made public. "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._l(_vm.comments, function (comment, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 mb-6",
                                },
                                [
                                  _c("div", { staticClass: "w-9/10 mx-auto" }, [
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-between" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-collumn justify-between pt-4 pb-4 -mr-4 self-center",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "pb-4 text-base font-thin text-black",
                                              },
                                              [
                                                _vm._v(
                                                  " Posted " +
                                                    _vm._s(
                                                      _vm.formattedTime(
                                                        comment.date_created
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm.user.isAdmin
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "font-bold self-center pb-4",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          comment.first_name
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          comment.last_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.user.isAdmin
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "pb-8 text-base font-thin text-black",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          comment.address_line_1
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          comment.address_line_2
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(comment.suburb) +
                                                        " " +
                                                        _vm._s(comment.state) +
                                                        " " +
                                                        _vm._s(comment.postcode)
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(comment.email)
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          comment.phone_no
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isPublic || !_vm.user.isAdmin
                                              ? _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "font-bold self-center pb-4",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "pb-8 text-base font-thin text-black",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              comment.suburb
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              comment.state
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              comment.postcode
                                                            )
                                                        ),
                                                        _c("br"),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-start justify-between pt-4 pb-4 -mr-4",
                                          },
                                          [
                                            _c("StatusButton", {
                                              attrs: {
                                                label: `Public Comment ${_vm.getCommentType(
                                                  comment.is_supportive
                                                )}`,
                                                status: _vm.getCommentType(
                                                  comment.is_supportive
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "self-center" }, [
                                      _c("b", [
                                        _vm._v("Interest In Proposal:"),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pb-8 text-base text-black",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(comment.interest) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "self-center" }, [
                                      _c("b", [_vm._v("Comment:")]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pb-8 text-base text-black break-words",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(comment.comment) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    comment.public_comment_reference_document &&
                                    !_vm.isAlreadyEncrypted
                                      ? _c(
                                          "div",
                                          { staticClass: "self-center pb-8" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                "Supporting Documentation:"
                                              ),
                                            ]),
                                            _vm._l(
                                              comment.public_comment_reference_document,
                                              function (document, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: _vm.relativeFileUrl(
                                                            document.s3_key
                                                          ),
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(document.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    comment.public_comment_reference_document &&
                                    _vm.isAlreadyEncrypted
                                      ? _c(
                                          "div",
                                          { staticClass: "self-center pb-8" },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                "Supporting Documentation:"
                                              ),
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    `${comment.public_comment_reference_document.length} file(s) encrypted`
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    comment.is_supportive === false &&
                                    !comment.response
                                      ? _c("div", [
                                          _c(
                                            "form",
                                            { staticClass: "w-full" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "py-3 mb-2 flex content-start flex-wrap",
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "font-bold self-center pb-4",
                                                    },
                                                    [_vm._v("Your Response")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex md:w-full md:mb-0",
                                                    },
                                                    [
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              comment.draftResponse,
                                                            expression:
                                                              "comment.draftResponse",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "w-full mb-3 input-area",
                                                        attrs: {
                                                          id: "comment-response",
                                                          type: "text",
                                                          rows: "7",
                                                          placeholder:
                                                            "Comment Response",
                                                          disabled:
                                                            !_vm.isRespondToComments ||
                                                            (!_vm.isPublic &&
                                                              !!_vm.user
                                                                .isAdmin),
                                                        },
                                                        domProps: {
                                                          value:
                                                            comment.draftResponse,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              comment,
                                                              "draftResponse",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex justify-between",
                                            },
                                            [
                                              _c(
                                                "ButtonWithSpinner",
                                                {
                                                  staticClass:
                                                    "button-blue-hollow mt-4 mb-4",
                                                  attrs: {
                                                    isSpinning:
                                                      _vm.$wait.is(
                                                        "submittingComment"
                                                      ),
                                                    disabled:
                                                      _vm.$wait.is(
                                                        "submittingComment"
                                                      ) ||
                                                      !_vm.isRespondToComments ||
                                                      (!_vm.isPublic &&
                                                        !!_vm.user.isAdmin),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.submitComment(
                                                        comment.draftResponse,
                                                        comment.identifier
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Send Response ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    comment.response
                                      ? _c(
                                          "div",
                                          { staticClass: "pb-4 w-full" },
                                          [
                                            _c("hr", {
                                              staticClass:
                                                "pnp-divider w-full mb-8",
                                            }),
                                            _c("h3", { staticClass: "mb-2" }, [
                                              _vm._v("Your Response"),
                                            ]),
                                            _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(comment.response)
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-end w-full",
                                              },
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    staticClass:
                                                      "button-blue-hollow mt-4 mb-4",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.isRespondToComments ||
                                                        (!_vm.isPublic &&
                                                          !!_vm.user.isAdmin),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateComment(
                                                          comment.draftResponse,
                                                          comment.identifier
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Update Response ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            _c(
                              "buttonWithSpinner",
                              {
                                staticClass: "button-blue float-right",
                                attrs: {
                                  disabled:
                                    _vm.$wait.is("finalisingComments") ||
                                    !_vm.isRespondToComments ||
                                    (!_vm.isPublic && !!_vm.user.isAdmin),
                                  isSpinning:
                                    _vm.$wait.is("finalisingComments"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.finaliseComments()
                                  },
                                },
                              },
                              [_vm._v(" Finalise Comment Responses ")]
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { name: _vm.modalName[0], height: "auto" },
          on: { "before-close": _vm.changeUploadTrimConfirmationToFalse },
        },
        [
          _c("div", { staticClass: "flex flex-col justify-between" }, [
            _c("h2", { staticClass: "text-title flex-0 px-4 mb-4" }, [
              _vm._v("Encrypt Personal Info"),
            ]),
            _c("div", { staticClass: "dividing-line-h" }),
            _c("div", { staticClass: "p-4" }, [
              _c("div", [
                _c("div", { staticClass: "mr-3 py-3 justify-center md:mb-0" }, [
                  _c(
                    "label",
                    {
                      staticClass: "container flex height-fix text-black ml-8",
                    },
                    [
                      _vm._v(
                        " Confirm that comments have been uploaded to TRIM successfully "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isUploadTrimConfirmed,
                            expression: "isUploadTrimConfirmed",
                          },
                        ],
                        staticClass: "checkbox-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.isUploadTrimConfirmed)
                            ? _vm._i(_vm.isUploadTrimConfirmed, null) > -1
                            : _vm.isUploadTrimConfirmed,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.isUploadTrimConfirmed,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.isUploadTrimConfirmed = $$a.concat([
                                    $$v,
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isUploadTrimConfirmed = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isUploadTrimConfirmed = $$c
                            }
                          },
                        },
                      }),
                      _c("span", { staticClass: "border-2 checkmark" }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "self-end my-6 flex justify-between" },
                [
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue-hollow mt-4 mb-4",
                      attrs: {
                        isSpinning: _vm.$wait.is("encryptingPersonalInfo"),
                        disabled:
                          _vm.$wait.is("encryptingPersonalInfo") ||
                          !_vm.isUploadTrimConfirmed,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.encryptPersonalInfo()
                        },
                      },
                    },
                    [_vm._v(" Encrypt ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button-red-hollow mt-4 mb-4",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal(_vm.modalName[0])
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: { name: _vm.modalName[1], height: "auto" },
          on: { "before-close": _vm.deletePastedPersonalInfo },
        },
        [
          _c("div", { staticClass: "flex flex-col justify-between" }, [
            _c("h2", { staticClass: "text-title flex-0 px-4 mb-4" }, [
              _vm._v("Decrypt Personal Info"),
            ]),
            _c("div", { staticClass: "dividing-line-h" }),
            _c("div", { staticClass: "p-4" }, [
              _c("div", { staticClass: "text-black mb-4" }, [
                _c("span", [
                  _vm._v(
                    "Please copy the encrypted personal information attached in the Jira issue and paste it below:"
                  ),
                ]),
              ]),
              _c("div", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.encryptedPI,
                      expression: "encryptedPI",
                    },
                  ],
                  staticClass: "w-full input-area text-area-min-h",
                  domProps: { value: _vm.encryptedPI },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.encryptedPI = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "text-black" }, [
                _c("span", [
                  _vm._v(
                    "If there is no encrypted personal information in the Jira issue, decryption is not possible."
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "self-end mb-6 flex justify-between" },
                [
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue-hollow mt-4 mb-4",
                      attrs: {
                        isSpinning: _vm.$wait.is("decryptingPersonalInfo"),
                        disabled:
                          _vm.$wait.is("decryptingPersonalInfo") ||
                          !_vm.encryptedPI,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.decryptPersonalInfo(_vm.encryptedPI)
                        },
                      },
                    },
                    [_vm._v(" Decrypt ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button-red-hollow mt-4 mb-4",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal(_vm.modalName[1])
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }