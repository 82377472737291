<template>
  <div class="w-full flex bg-white mb-8 mx-64 px-16 pb-16 pt-8 mt-32">
    <showLoading isLoading="fetchingGeoname">
      <template v-slot:component>
        <div v-if="geoname">
          <h3>View Record</h3>
          <hr class="divider w-full" />
          <div class="py-4">
            <h3>
              <b>{{ geoname.geographical_name }}</b>
            </h3>
          </div>
          <div v-if="geoname.reference" class="w-full py-1">
            <span class="w-64 inline-block"><b>Reference</b></span
            ><span>{{ geoname.reference }}</span>
          </div>
          <div v-if="geoname.designation" class="w-full py-1">
            <span class="w-64 inline-block"
              ><a
                class="block text-normal-bold mb-2 text-blue-button"
                title="View Glossary of designation values in the Geographical Names Register"
                target="_blank"
                href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf"
              >
                Designation</a
              ></span
            ><span>{{ geoname.designation }}</span>
          </div>
          <div v-if="geoname.status" class="w-full py-1">
            <span class="w-64 inline-block"
              ><a
                class="block text-normal-bold mb-2 text-blue-button"
                title="View Glossary of status values in the Geographical Names Register"
                target="_blank"
                href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf"
              >
                Status
              </a></span
            ><span
              ><b
                >{{ geoname.dual_named ? "Dual - " : ""
                }}{{ geoname.status }}</b
              ></span
            >
          </div>
          <div
            v-if="geoname.dual_geographical_name && geoname.dual_identifier"
            class="w-full py-1"
          >
            <span class="w-64 inline-block"><b>Dual Name</b></span>
            <span
              ><a
                role="button"
                class="text-blue-button"
                @click="goToPublicGeoname(geoname.dual_identifier)"
                >{{ geoname.dual_geographical_name }}</a
              ></span
            >
          </div>
          <div
            v-if="geoname.gazettal_dates && geoname.gazettal_dates.length"
            class="w-full py-1"
          >
            <span class="w-64 inline-block align-top"><b>Gazettal Date</b></span
            ><span
              class="inline-block"
              v-if="geoname.gazettal_dates && geoname.gazettal_dates.length"
            >
              <p
                v-for="(gazettal_date, index) in geoname.gazettal_dates"
                :key="`gazettal_date-${index}`"
              >
                {{ formatDate(gazettal_date.date_gazetted) }}
              </p>
            </span>
          </div>

          <div v-if="geoname.gnb_file_reference" class="w-full py-1">
            <span class="w-64 inline-block"><b>GNB File No.</b></span
            ><span>{{ geoname.gnb_file_reference }}</span>
          </div>

          <div v-if="geoname.lga && geoname.lga.length" class="w-full py-1">
            <span class="w-64 inline-block align-top"><b>LGA(s)</b></span
            ><span
              class="inline-block"
              v-if="geoname.lga && geoname.lga.length"
            >
              <p v-for="lga in geoname.lga" :key="lga">{{ lga }}</p>
            </span>
          </div>

          <div
            v-if="geoname.lga_gazettal && geoname.lga_gazettal.length"
            class="w-full py-1"
          >
            <span class="w-64 inline-block align-top"><b>LGA(s) at Gazettal</b></span
            ><span class="inline-block">
              <p
                v-for="lga_gazettal in geoname.lga_gazettal"
                :key="lga_gazettal"
              >
                {{ lga_gazettal }}
              </p>
            </span>
          </div>

          <div v-if="geoname.latitude && geoname.longitude" class="w-full py-1">
            <span class="w-64 inline-block"><b>GDA2020 Lat</b></span
            ><span v-html="geoname.dms.lat.formattedString"></span>
          </div>

          <div v-if="geoname.longitude && geoname.latitude" class="w-full py-1">
            <span class="w-64 inline-block"><b>GDA2020 Long</b></span
            ><span v-html="geoname.dms.lon.formattedString"></span>
          </div>

          <div v-if="geoname.map_cma" class="w-full py-1">
            <span class="w-64 inline-block"><b>1:25,000 Map Name</b></span
            ><span>{{ geoname.map_cma }}</span>
          </div>

          <div v-if="legacyMapData" class="w-full py-1">
            <span class="w-64 inline-block"><b>1:100,000 Map</b></span
            ><span>{{ legacyMapData }}</span>
          </div>

          <div
            v-if="geoname.parish && geoname.parish.length"
            class="w-full py-1"
          >
            <span class="w-64 inline-block align-top"><b>Parish</b></span
            ><span
              class="inline-block"
              v-if="geoname.parish && geoname.parish.length"
            >
              <p v-for="parish in geoname.parish" :key="parish">{{ parish }}</p>
            </span>
          </div>

          <div
            v-if="geoname.county && geoname.county.length"
            class="w-full py-1"
          >
            <span class="w-64 inline-block align-top"><b>County</b></span
            ><span
              class="inline-block"
              v-if="geoname.county && geoname.county.length"
            >
              <p v-for="county in geoname.county" :key="county">{{ county }}</p>
            </span>
          </div>

          <div v-if="geoname.description" class="mt-8 pb-1">
            <b>Description</b>
          </div>
          <p>{{ geoname.description }}</p>

          <div v-if="geoname.official_map_reference_document" class="mt-8 pb-1">
            <b>Official Map</b>
          </div>
          <div
            :key="`official_map_reference_document-${index}`"
            v-for="(document, index) in geoname.official_map_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div
            v-if="geoname.origin || geoname.origin_reference_document"
            class="mt-8 pb-1"
          >
            <b>Origin</b>
          </div>
          <p>{{ geoname.origin }}</p>
          <div
            :key="`origin_reference_document-${index}`"
            v-for="(document, index) in geoname.origin_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div
            v-if="geoname.history || geoname.historical_reference_document"
            class="mt-8 pb-1"
          >
            <b>History</b>
          </div>
          <p>{{ geoname.history }}</p>
          <div
            :key="`historical_reference_document-${index}`"
            v-for="(document, index) in geoname.historical_reference_document"
          >
            <div
              v-if="
                document.document_type === 'history_verified_approved' ||
                  document.document_type === 'history_unverified_approved'
              "
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.meaning" class="mt-8 pb-1"><b>Meaning</b></div>
          <p>{{ geoname.meaning }}</p>

          <div
            v-if="
              geoname.pronunciation || geoname.pronunciation_reference_document
            "
            class="mt-8 pb-1"
          >
            <b>Pronunciation</b>
          </div>
          <p>{{ geoname.pronunciation }}</p>
          <div
            :key="`pronunciation_reference_document-${index}`"
            v-for="(document,
            index) in geoname.pronunciation_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.aboriginal_name !== null" class="mt-8 pb-1">
            <b>Aboriginal Name</b>
          </div>
          <p>
            {{
              geoname.aboriginal_name === true
                ? "Yes"
                : geoname.aboriginal_name === false
                ? "No"
                : ""
            }}
          </p>

          <div v-if="geoname.source_reference_document" class="mt-8 pb-1">
            <b>Commemoration Source</b>
          </div>
          <div
            :key="`source_reference_document-${index}`"
            v-for="(document, index) in geoname.source_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.marked_map_reference_document" class="mt-8 pb-1">
            <b>Marked Map</b>
          </div>
          <div
            :key="`marked_map_reference_document-${index}`"
            v-for="(document, index) in geoname.marked_map_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.marked_photo_reference_document" class="mt-8 pb-1">
            <b>Marked Photo</b>
          </div>
          <div
            :key="`marked_photo_reference_document-${index}`"
            v-for="(document, index) in geoname.marked_photo_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.advert_reference_document" class="mt-8 pb-1">
            <b>Advertisement</b>
          </div>
          <div
            :key="`advert_reference_document-${index}`"
            v-for="(document, index) in geoname.advert_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.gazette_reference_document" class="mt-8 pb-1">
            <b>Gazette Notice</b>
          </div>
          <div
            :key="`gazette_reference_document-${index}`"
            v-for="(document, index) in geoname.gazette_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div v-if="geoname.general_reference_document" class="mt-8 pb-1">
            <b>Other Information</b>
          </div>
          <div
            :key="`general_reference_document-${index}`"
            v-for="(document, index) in geoname.general_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div
            v-if="geoname.media_release_reference_document"
            class="mt-8 pb-1"
          >
            <b>Media Release</b>
          </div>
          <div
            :key="`media_release_reference_document-${index}`"
            v-for="(document,
            index) in geoname.media_release_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <div
            v-if="geoname.evaluation_report_reference_document"
            class="mt-8 pb-1"
          >
            <b>Pre-Evaluation Report</b>
          </div>
          <div
            :key="`evaluation_report_reference_document-${index}`"
            v-for="(document,
            index) in geoname.evaluation_report_reference_document"
          >
            <div
              class="w-full mt-3 flex items-center rounded-lg border border-grey-border"
            >
              <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
              {{ document.title }}
              <a
                class="cursor-pointer ml-auto text-normal-bold text-blue-button mr-6"
                :href="relativeFileUrl(document.s3_key)"
                target="_blank"
                >View Document</a
              >
            </div>
          </div>

          <hr class="divider w-full mt-16 mb-8" />
          <p class="mb-8">
            If you have any information about this feature's history, origin,
            meaning or pronunciation, which does not appear here, please submit
            your comments and information by selecting the 'Add place name
            information' button below.
          </p>
          <button class="button-blue-hollow" @click="goToNewSearch()">
            Back
          </button>
          <button
            class="button-blue float-right"
            @click="goToAddPlaceNameInformation()"
          >
            Add Place Name Information
          </button>
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import pnpCRUD from "../../helpers/pnpCRUD.js";
import moment from "moment";

export default {
  name: "geonameViewPublic",
  data() {
    return {
      geoname: null
    };
  },
  computed: {
    geonameId() {
      return this.$route.params.geonameId;
    },
    legacyMapData() {
      if (
        !this.geoname ||
        !this.geoname.legacy_map_data ||
        !this.geoname.legacy_map_data.length
      )
        return null;
      else
        return this.geoname.legacy_map_data.reduce((mapData, map, index) => {
          mapData += `${map.legacy_map_name}`;
          if (map.legacy_map_reference) {
            mapData += ` ${map.legacy_map_reference}`;
          }
          if (this.geoname.legacy_map_data.length !== index + 1) {
            mapData += ", ";
          }
          return mapData;
        }, "");
    }
  },
  watch: {
    $route() {
      this.fetchGeoname();
    }
  },
  async created() {
    await this.fetchGeoname();
  },
  methods: {
    fetchGeoname: waitFor("fetchingGeoname", async function() {
      try {
        this.geoname = await pnpCRUD.getGeonameByIdPublic(this.geonameId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Issue Retrieving Record",
          text: "There was an issue retrieving the geographical name record"
        });
      }
    }),
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    goToNewSearch() {
      this.$router.push({ name: "geoNameSearchPublic" });
    },
    goToAddPlaceNameInformation() {
      this.$router.push({ name: "geonameEditPublic" });
    },
    goToPublicGeoname(geoname_identifier) {
      this.$router.push({
        params: {
          geonameId: geoname_identifier
        }
      });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style scoped>
.divider {
  height: 1px;
  width: 100%;
  background-color: #cbcbcb;
}
</style>
