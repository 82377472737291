<template>
  <entry cardTitle="Roadname Proposal Updated" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">The following proposal data point(s) were modified:</p>
    </div>

    <div
      v-for="(change, index) in Object.keys(changes)"
      :key="index"
      class="mt-4"
    >
      <h3 class="font-bold mb-2 text-base">{{ change }}</h3>
      <h4 class="pl-4 text-sm">Previous value</h4>
      <p class="pl-6 mb-2 text-sm">
        {{ changes[change].from ? changes[change].from : "null" }}
      </p>
      <h4 class="pl-4 text-sm">New value</h4>
      <p class="pl-6 text-sm">
        {{ changes[change].to }}
      </p>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";
import _pickby from "lodash.pickby";

export default {
  name: "RoadNameProposalUpdateTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      mapping: {
        "Road Name": null,
        "Road Name Type": null,
        Extent: null,
        Origin: null,
        Commemorated: null,
        "commemorated full name": null,
        "commemorated birth date": null,
        "commemorated death date": null,
        "commemorated dates unknown description": null,
        "commemorated association description": null,
        "commemorated source": null,
        "Has multi-cultural dimension": null,
        "Has aboriginal dimension": null,
        Comment: null
      }
    };
  },

  /**
   *
   */
  computed: {
    changes() {
      this.entryData.items.forEach(entry => {
        if (Object.keys(this.mapping).includes(entry.label)) {
          this.mapping[entry.label] = {
            from: entry.from,
            to: entry.to
          };
        }
      });

      return _pickby(this.mapping);
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
