var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black flex flex-grow" },
    [
      _c(
        "section",
        { staticClass: "justify-start w-full block flex flex-col" },
        [
          _c(
            "a",
            {
              staticClass: "flex justify-between",
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "proposals" })
                },
              },
            },
            [
              _c("BackArrowWithTitle", {
                attrs: { title: "Create an Unapproved Gazettal Notice" },
              }),
            ],
            1
          ),
          _c(
            "ShowLoading",
            { attrs: { isLoading: "loading" } },
            [
              _c("template", { slot: "component" }, [
                _c("div", { staticClass: "w-full flex bg-white" }, [
                  _c(
                    "div",
                    { staticClass: "w-5/6 mx-auto py-16" },
                    [
                      _c("p", { staticClass: "font-bold" }, [
                        _c("span", { staticClass: "text-red-star" }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("On Behalf of RNA "),
                      ]),
                      _c(
                        "norns-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mr-4 norns-select",
                          attrs: {
                            disabled: _vm.isDisabled,
                            label: "authority_name",
                            reduce: (rna) => rna.identifier,
                            multiple: false,
                            options: _vm.rnaList,
                            searchable: true,
                            "close-on-select": true,
                            placeholder: "Select Road Naming Authority...",
                            name: "rnaEntity",
                            "data-cypress": "authority",
                            "data-vv-as": "Road Naming Authority",
                            "select-on-tab": true,
                            filter: _vm.rnaFilter,
                          },
                          model: {
                            value: _vm.road_naming_authority_id,
                            callback: function ($$v) {
                              _vm.road_naming_authority_id = $$v
                            },
                            expression: "road_naming_authority_id",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Loading...")]
                          ),
                        ]
                      ),
                      _c("FieldErrorMessage", {
                        attrs: { message: _vm.errors.first("rnaEntity") },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full flex content-center items-start mt-8",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pr-4 w-1/2 items-start" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2" },
                                [
                                  _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Local Government Area "),
                                ]
                              ),
                              _c(
                                "norns-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full mr-4 norns-select",
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    multiple: false,
                                    options: _vm.lgasLimited,
                                    searchable: true,
                                    "close-on-select": true,
                                    placeholder: "Select LGA...",
                                    name: "LGA",
                                    "data-cypress": "lga-select",
                                    "data-vv-as": "Local Government Authority",
                                    "select-on-tab": true,
                                  },
                                  model: {
                                    value: _vm.roadName.lga_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.roadName, "lga_name", $$v)
                                    },
                                    expression: "roadName.lga_name",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions",
                                    },
                                    [_vm._v("Loading...")]
                                  ),
                                ]
                              ),
                              _c("FieldErrorMessage", {
                                attrs: { message: _vm.errors.first("LGA") },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pl-4 w-1/2 flex flex-col" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2" },
                                [
                                  _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Address Locality (Suburb) "),
                                ]
                              ),
                              _c(
                                "norns-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full mr-4 norns-select",
                                  attrs: {
                                    disabled:
                                      !_vm.roadName.lga_name || _vm.isDisabled,
                                    multiple: true,
                                    options: _vm.suburbOptions,
                                    searchable: true,
                                    "close-on-select": false,
                                    "show-labels": true,
                                    "hide-selected": true,
                                    placeholder: "Pick a Suburb",
                                    name: "suburb",
                                    "data-cypress": "suburb-field",
                                    "data-vv-as": "Suburb",
                                    "select-on-tab": true,
                                  },
                                  model: {
                                    value: _vm.roadName.suburb_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.roadName, "suburb_name", $$v)
                                    },
                                    expression: "roadName.suburb_name",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions",
                                    },
                                    [_vm._v("Loading...")]
                                  ),
                                ]
                              ),
                              _c("FieldErrorMessage", {
                                attrs: { message: _vm.errors.first("suburb") },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full flex items-start mt-8" },
                        [
                          _c(
                            "div",
                            { staticClass: "pr-4 w-2/3" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-1" },
                                [
                                  _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Proposed Road Name "),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.roadName.roadname,
                                    expression: "roadName.roadname",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "w-full mr-4 input-area mb-1 mt-1",
                                attrs: {
                                  type: "text",
                                  disabled:
                                    !_vm.lgaName ||
                                    !_vm.roadName.suburb_name.length ||
                                    _vm.isDisabled,
                                  "data-cypress": "roadname-field",
                                  placeholder: "Enter Name Here...",
                                  name: "roadname",
                                  id: "roadName",
                                  "data-vv-as": "Road Name",
                                },
                                domProps: { value: _vm.roadName.roadname },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.roadName,
                                      "roadname",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first("roadname"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pl-4 w-1/3 mb-3" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2" },
                                [
                                  _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Road Type "),
                                ]
                              ),
                              _c(
                                "norns-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass:
                                    "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                  attrs: {
                                    name: "roadtype",
                                    id: "roadType",
                                    placeholder: "Select Road Type...",
                                    "data-cypress": "select-roadtype-input",
                                    multiple: false,
                                    disabled:
                                      !_vm.lgaName ||
                                      !_vm.roadName.suburb_name.length ||
                                      _vm.isDisabled,
                                    options: _vm.roadTypesClean,
                                    searchable: true,
                                    "close-on-select": true,
                                    "select-on-tab": true,
                                    "data-vv-as": "Road Type",
                                  },
                                  model: {
                                    value: _vm.roadName.road_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.roadName, "road_type", $$v)
                                    },
                                    expression: "roadName.road_type",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions",
                                    },
                                    [_vm._v("Loading...")]
                                  ),
                                ]
                              ),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first("roadtype"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "w-full flex mt-8" }, [
                        _c("label", { staticClass: "w-full" }, [
                          _c("p", { staticClass: "text-normal-bold mb-2" }, [
                            _c("span", { staticClass: "text-red-star" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Extent "),
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.roadName.extent,
                                expression: "roadName.extent",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full h-48 input-area",
                            attrs: {
                              "data-cypress": "extent-field",
                              placeholder:
                                "Please describe where the road starts and ends",
                              name: "extent",
                              disabled: _vm.isDisabled,
                            },
                            domProps: { value: _vm.roadName.extent },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.roadName,
                                  "extent",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-6" },
                        [
                          _c("p", { staticClass: "font-bold" }, [
                            _c("span", { staticClass: "text-red-star" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Gazette Date "),
                          ]),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  date_format: "DD/MM/YYYY",
                                },
                                expression:
                                  "{ required: true, date_format: 'DD/MM/YYYY' }",
                              },
                            ],
                            staticClass: "p-3 w-1/3 rounded-lg bg-grey-input",
                            attrs: {
                              disabled: _vm.isDisabled,
                              name: "gazettedate",
                              placeholder: "Select date...",
                              format: "dd/MM/yyyy",
                              "input-class":
                                "w-full bg-grey-input text-grey-text",
                              "data-vv-as": "Gazette Date",
                            },
                            model: {
                              value: _vm.gazetteDate,
                              callback: function ($$v) {
                                _vm.gazetteDate = $$v
                              },
                              expression: "gazetteDate",
                            },
                          }),
                          _c("FieldErrorMessage", {
                            attrs: { message: _vm.errors.first("gazettedate") },
                          }),
                        ],
                        1
                      ),
                      _c("uploadComponent", {
                        attrs: {
                          label:
                            "Please upload Map and Other Supporting Document",
                          drafted: true,
                          deleteFile: _vm.deleteFunction,
                          disabled: _vm.isDisabled,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "flex justify-end mt-6" },
                        [
                          _c(
                            "ButtonWithSpinner",
                            {
                              staticClass:
                                "button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold",
                              attrs: {
                                "data-cypress": "cancel-button",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({ name: "proposals" })
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "ButtonWithSpinner",
                            {
                              staticClass:
                                "button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold",
                              attrs: {
                                "data-cypress": "addroad-button",
                                disabled: _vm.isDisabled || !_vm.canSubmit,
                                isSpinning: _vm.$wait.is(
                                  "saving unapproved gazette"
                                ),
                              },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }