<template>
  <div class="base-container">
    <section class="pt-1 justify-center w-full block">
      <div class="flex justify-between"></div>
      <h2 class="text-black ml-4 self-center">Submit Road Name Appeal</h2>
      <div
        class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap"
      >
        <div>
          <div class="w-full justify-center flex mt-20">
            <img
              src="../../../assets/Images/Icon_Gazettal.png"
              class="w-48 h-48"
            />
          </div>
          <p class="text-title mb-4 mt-8 text-center">
            Your Appeal for {{ road.roadname.toUpperCase() }}
            {{ road.road_type.toUpperCase() }} has been submitted
          </p>
          <p class="mb-24 text-title text-center">
            and will be considered for review by the GNB Secretariat.
          </p>
        </div>
      </div>

      <div class="flex pt-4 text-center pb-10">
        <router-link
          :to="{ name: 'proposals' }"
          class="w-1/3 button-blue-hollow"
          >Go to My Proposals Dashboard</router-link
        >
        <button @click="$router.go(-2)" class="ml-auto w-1/3 button-blue">
          Return to Road Name Proposal
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    road: { type: Object }
  }
};
</script>
