var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [_c("authenticationContent")]
        },
        proxy: true,
      },
      {
        key: "form",
        fn: function () {
          return [_c("authenticationForm")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }