<template>
  <div class="base-container">
    <showLoading isLoading="loading Proposal">
      <template v-slot:component>
        <section class="pt-1 justify-center w-full block" v-if="correctStatus">
          <a
            @click="$router.push({ name: 'placeNameProposals' })"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
          >
            <BackArrowWithTitle title="Proposal Seek Clarification" />
          </a>

          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 font-semibold "
          >
            <div class="w-9/10 flex mx-auto content-center pt-4">
              <div>
                <h2>{{ geographical_name }}</h2>
                <h4 class="text-blue">Local Government Area(s)</h4>
                <div v-for="lga in lga_name" v-bind:key="lga.index">
                  <span class="flex">
                    {{ lga }}
                  </span>
                </div>
              </div>
              <StatusButton
                :label="display_status"
                :status="display_status"
                class="ml-auto mr-6 font-semibold text-sm"
              />
            </div>
          </div>

          <div
            class="w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start"
          >
            <div class="w-9/10 mx-auto">
              <span class="self-center pt-10 w-full block">
                <h2 class="text-title text-black">Clarification Request:</h2>

                <textarea
                  class="text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input"
                  v-model="clarificationText"
                  placeholder="Enter your clarification request here..."
                ></textarea>

                <uploadComponent
                  label="Attach supporting documentation"
                  class="mb-10"
                  v-on:uploadfinished="uploading = false"
                  v-on:uploadstarted="uploading = true"
                  :disabled="uploading"
                  :drafted="true"
                  :deleteFile="deleteFunction"
                />

              </span>
            </div>
          </div>
          <div class="flex pt-4 text-center pb-10 justify-end">
            <ButtonWithSpinner
              class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
              @click="submitClarification"
              :isSpinning="$wait.is('submitting clarification request')"
              :disabled="$wait.is('submitting clarification request')"
              >Submit Clarification</ButtonWithSpinner
            >
          </div>
        </section>
        <section class="pt-1 justify-center w-full block" v-if="!correctStatus">
          <a
            @click="$router.push({ name: 'placeNameProposals' })"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
          >
            <BackArrowWithTitle title="Proposal Seek Clarification" />
          </a>
          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center"
          >
            <div class="w-9/10 flex mx-auto" v-if="user.isAdmin">
              <font-awesome-icon icon="times" class="mr-2 text-red" />
              Proposal is not in the correct status to seek clarification
            </div>
            <div class="w-9/10 flex mx-auto" v-if="!user.isAdmin">
              <font-awesome-icon icon="times" class="mr-2 text-red" />
              You do not have permission
            </div>
          </div>
        </section>
      </template>
    </showLoading>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapMutations } from "vuex";
import { waitFor } from "vue-wait";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import { clarificationRequest } from "@/helpers/pnpCRUD";

import { deleteSupportingDocument } from "@/helpers/s3";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY
} from "@/components/proposals/place/pnp-constants.js";

export default {
  async created() {
    await this.fetchProposal();
  },
  data() {
    return {
      uploading: false,
      clarificationText: "",
      deleteFunction: deleteSupportingDocument
    };
  },
  computed: {
    ...mapGetters({
      uploadedFiles: "uploadedFilesState"
    }),
    ...mapUserGetters(["user"]),
    proposalId() {
      return this.$route.params.proposalId;
    },
    type: {
      get() {
        return this.getProposalType();
      }
    },
    geographical_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE)
          return this.get_place_name_geographical_name();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_geographical_name();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_geographical_name();
        else return null;
      }
    },
    lga_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE) return this.get_place_name_lga();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_lga();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_lga();
        else return null;
      }
    },
    display_status: {
      get() {
        return this.getDisplayStatus();
      }
    },
    isDisabled() {
      return !this.clarificationResponse.clarification_response;
    },
    correctStatus() {
      return this.display_status === "Under Review";
    }
  },
  methods: {
    ...mapPnpGetters([
      "get_place_name_geographical_name",
      "get_place_name_lga",
      "get_address_locality_geographical_name",
      "get_address_locality_lga",
      "get_boundary_amendment_geographical_name",
      "get_boundary_amendment_lga",
      "getDisplayStatus",
      "getProposalType"
    ]),
    ...mapPnpActions(["selectProposal"]),
    ...mapMutations(["clearUploadState"]),
    fetchProposal: waitFor("loading Proposal", async function() {
      try {
        await this.selectProposal(this.proposalId);

        if (!!this.display_status && this.display_status !== "Under Review") {
          this.$router.push({
            name: "pnpSeekClarificationSuccess",
            params: {
              proposalName: this.geographical_name,
              invalidStatus: true
            }
          });
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Retrieval has Failed",
          text: `The retrieval of this Place Name Proposal has failed. ${error}`
        });
      }
    }),
    submitClarification: waitFor(
      "submitting clarification request",
      async function() {
        try {
          const payload = {
            proposalId: this.proposalId,
            clarification_request: this.clarificationText,
            files: this.uploadedFiles
          };
          await this.clearUploadState();
          await clarificationRequest(payload);
          this.$router.push({
            name: "pnpSeekClarificationSuccess",
            params: {
              proposalName: this.geographical_name,
              invalidStatus: false
            }
          });
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Sending Clarification",
            text: "Error"
          });
        }
      }
    )
  }
};
</script>

<style>
.dividing-line-h {
  border-bottom-width: 1px;
}

textarea::placeholder {
  color: #646974;
  opacity: 1;
}
</style>
