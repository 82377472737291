<template>
  <section class="flex main">
    <span class="arrow-icon w-6 h-6 text-center text-red-600 self-center">
      <!-- TODO move the arrow lefts to components -->
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="arrow-left"
        class="h-7"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          class=""
          fill="#C60C30"
          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
        />
      </svg>
    </span>
    <h1 v-if="size === 'h1'" class="ml-4 flex justify-center items-center">
      {{ title }}
    </h1>
    <h2 v-if="size === 'h2'" class="ml-4 flex justify-center items-center">
      {{ title }}
    </h2>
    <h3 v-if="size === 'h3'" class="ml-4 flex justify-center items-center">
      {{ title }}
    </h3>
    <h4 v-if="size === 'h4'" class="ml-4 flex justify-center items-center">
      {{ title }}
    </h4>
  </section>
</template>

<script>
"use strict";
export default {
  props: {
    title: {
      type: String,
      default: "Title"
    },
    size: {
      type: String,
      default: "h1"
    }
  }
};
</script>

<style scoped>
.main {
  cursor: pointer;
}
</style>
