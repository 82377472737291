var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full mx-auto pt-6 mb-2 px-6 text-black flex flex-col text-normal",
      attrs: { "data-cypress": "add-box" },
    },
    [
      !_vm.$wait.is("processingLink")
        ? _c(
            "a",
            { staticClass: "cursor-pointer ml-auto", on: { click: _vm.hide } },
            [_c("errorIcon", { attrs: { classNames: "w-4" } })],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "px-10" },
        [
          _c("search-component", {
            attrs: { isClosing: _vm.isClosing, geoName: _vm.geoName },
            on: { cancel: _vm.cancelAction },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "flex items-center content-center justify-center mt-6 mb-5 px-10",
      },
      [_c("h2", [_vm._v(" Link to an existing GNR Record ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }