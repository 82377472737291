var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c("h1", { staticClass: "text-title mt-6 pb-2 self-center" }, [
      _vm._v(" Road Name Similarity Threshold "),
    ]),
    _c(
      "div",
      { staticClass: "w-full mx-auto bg-white rounded-lg text-normal" },
      [
        _c("div", { staticClass: "w-9/10 mx-auto" }, [
          _c(
            "p",
            { staticClass: "w-full mt-3 pt-12 mb-8 text-normal font-semibold" },
            [_vm._v(" Current Threshold ")]
          ),
          _c("p", { staticClass: "mb-2" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.orderNumber,
                expression: "orderNumber",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "digits",
                expression: "'digits'",
              },
            ],
            staticClass: "w-full h-12 mb-20 pl-6 bg-grey-input",
            attrs: {
              type: "text",
              name: "orderNumberTxt",
              "data-cypress": "ordernumber",
              placeholder: "0123456789",
              id: "",
            },
            domProps: { value: _vm.orderNumber },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.orderNumber = $event.target.value
              },
            },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "button-blue w-1/4 mt-6 ml-auto justify-center items-center flex cursor-pointer whitespace-no-wrap",
        attrs: { "data-cypress": "save" },
        on: { click: _vm.updateOrderNumber },
      },
      [_vm._v(" Save Changes ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }