<template>
  <div class="sm:flex flex self-center flex-grow">
    <input
      type="search"
      class="search shadow rounded border-0 p-3 pl-12 text-white font-normal bg-black opacity-75 rounded-r-none flex-grow"
      placeholder="Search for proposals..."
      v-model="localKeyword"
      v-on:keyup.enter="search"
    />
    <font-awesome-icon
      class="search-icon absolute mt-4 ml-4 text-white"
      icon="search"
    />
    <button
      class="text-blue-button rounded bg-teal-button focus:outline-none focus:shadow-outline w-32 font-bold rounded-l-none"
      type="button"
      @click="search()"
    >
      Search
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SearchBar",
  data() {
    return {
      localKeyword: this.$route.query.keyword
    };
  },
  computed: {
    keyword: {
      get() {
        return this.proposalSearch.keyword;
      },
      set(value) {
        this.setKeyword(value);
      }
    },
    ...mapGetters(["isRNPView", "isPNPView", "proposalSearch"])
  },
  methods: {
    onSearchEvent(_event) {},
    search() {
      this.setKeyword(this.localKeyword);
      if (this.isRNPView) {
        this.$router.push({
          name: "proposals",
          query: { ...this.$route.query, keyword: this.keyword }
        });
      } else if (this.isPNPView) {
        this.$router.push({
          name: "placeNameProposals",
          query: { ...this.$route.query, keyword: this.keyword }
        });
      }
    },
    ...mapMutations(["setKeyword"])
  }
};
</script>

<style lang="scss" scoped>
.search {
  background-color: rgba(255, 255, 255, 0.2);
  height: 50px;
}
.bg-teal-button {
  transition-duration: 0.2s;
}
.bg-teal-button:hover {
  background-color: white;
}

.search-icon {
  height: 20px;
  width: 20px;
}
</style>
