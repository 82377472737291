<template>
  <entry cardTitle="Roadname Formalisation" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">
        The proposal was endorsed on {{ format(entryData.dateEndorsed) }}.
      </p>
    </div>
  </entry>
</template>

<script>
import moment from "moment";
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "RoadNameFormalisationTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  },

  /**
   * Local instance methods
   */
  methods: {
    format(date) {
      return moment(date).format("DD MMM YYYY");
    }
  }
};
</script>
