<template>
  <div class="base-container text-black flex flex-grow">
    <section class="justify-start w-full block flex flex-col">
      <a
        class="flex justify-between"
        @click="$router.push({ name: 'roadNameProposalBucket', proposalId })"
      >
        <BackArrowWithTitle title="Create Erratum Gazette" />
      </a>

      <showLoading :isLoading="'fetchingRoadInformation'">
        <template v-slot:component>
          <div v-if="!!road">
            <div
              class="flex flex-1 items-start justify-center"
              v-if="hasNoGazettalToCorrect"
            >
              <div class="flex flex-row mt-8">
                <font-awesome-icon
                  class="flex-0 text-red text-3xl inline mr-2"
                  icon="exclamation-circle"
                />

                <p>
                  {{ road.roadname }} {{ road.road_type }} does not have a
                  gazette notice requiring correction.
                </p>
              </div>
            </div>

            <div v-else>
              <div
                class="w-full flex bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4 text-center"
              >
                <proposalIcon
                  :label="road.roadname_classification"
                  classNames="w-10 h-10 text-normal"
                />

                <h3 class="self-center ml-4">
                  {{ road.roadname }} {{ road.road_type }}
                </h3>

                <StatusButton
                  :label="road.name_status"
                  :status="road.name_status"
                  class="ml-auto mr-6 text-normal font-bold"
                />
              </div>

              <div
                class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 pb-16 pt-12"
                v-if="erratumGazette"
              >
                <div class="w-9/10 mx-auto flex flex-col">
                  <div class="flex w-full">
                    <!-- ROAD NAME -->

                    <label class="text-normal-bold w-1/2 mr-4" for="lgaText">
                      Local Government Area (LGA)
                      <input
                        id="lgaText"
                        name="lgaText"
                        type="text"
                        class="flex input-area w-full mt-2"
                        v-model="road.lga_name"
                        disabled
                      />
                    </label>

                    <!-- ROAD TYPE -->

                    <div class="w-1/2 text-normal-bold">
                      <p class="text-normal-bold mb-2">
                        <span class="text-red-star">*</span>Address Locality
                        (Suburb)
                      </p>
                      <norns-select
                        class="w-full mr-4 norns-select"
                        v-model="suburb_name"
                        :multiple="true"
                        :options="suburbOptions"
                        :searchable="true"
                        :close-on-select="false"
                        :show-labels="true"
                        :hide-selected="true"
                        placeholder="Pick a Suburb"
                        name="suburb"
                        data-cypress="suburb-field"
                        v-validate="'required'"
                        data-vv-as="Suburb"
                        :select-on-tab="true"
                        @input="setSuburb"
                      >
                        <span slot="noOptions">Loading...</span>
                      </norns-select>
                      <FieldErrorMessage :message="errors.first('suburb')" />
                    </div>
                  </div>

                  <div class="flex w-full mt-4">
                    <!-- ROAD NAME -->

                    <label class="text-normal-bold w-1/2 mr-4" for="road_name">
                      <a class="text-red-star">*</a>Road name

                      <input
                        id="road_name"
                        name="road_name"
                        type="text"
                        class="flex w-full input-area mt-2 mb-4"
                        v-validate="'required'"
                        v-model="roadName"
                        @keyup="setToStore('roadName', $event)"
                      />
                    </label>

                    <!-- ROAD TYPE -->

                    <div class="w-1/2 text-normal-bold" for="lgaRoadType">
                      <p class="text-normal-bold mb-2">
                        <a class="text-red-star">*</a>Road Type
                      </p>

                      <norns-select
                        class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                        name="Road Type"
                        id="roadType"
                        placeholder="Select Road Type..."
                        data-cypress="roadtype-select"
                        v-model="roadType"
                        :multiple="false"
                        :options="roadTypesClean"
                        :searchable="true"
                        :close-on-select="true"
                        @input="setRoadType"
                        v-validate="'required'"
                        :select-on-tab="true"
                      >
                        <span slot="noOptions">Loading...</span>
                      </norns-select>
                    </div>
                  </div>

                  <div class="w-full flex mt-4">
                    <label class="w-full">
                      <p class="text-normal-bold mb-2">
                        <span class="text-red-star">*</span>Extent
                      </p>
                      <textarea
                        id="extent"
                        name="extent"
                        class="w-full h-48 input-area"
                        data-cypress="extent-field"
                        placeholder="Please describe where the road starts and ends"
                        v-validate="'required'"
                        v-model="extent"
                        @keyup="setToStore('extent', $event)"
                      ></textarea>
                    </label>
                  </div>
                  <FieldErrorMessage :message="errors.first('extent')" />

                  <div class="flex w-full">
                    <!-- GAZETTE NUMBER -->

                    <label
                      class="text-normal-bold w-1/2 mt-4"
                      for="gazette_number"
                    >
                      <a class="text-red-star">*</a>Gazette Number

                      <input
                        id="gazette_number"
                        name="gazette_number"
                        type="text"
                        class="flex w-full input-area mt-2 mb-4"
                        v-validate="'required'"
                        v-model="gazetteNumber"
                        @keyup="setToStore('gazetteNumber', $event)"
                        data-vv-as="gazette number"
                      />
                    </label>

                    <label
                      class="text-normal-bold w-1/2 ml-8 mt-4"
                      for="published_date"
                    >
                      <a class="text-red-star">*</a>Publication Date

                      <input
                        id="published_date"
                        name="published_date"
                        type="text"
                        class="flex w-full input-area mt-2 mb-4"
                        v-model="publicationDate"
                        @keyup="setToStore('publicationDate', $event)"
                        placeholder="DD/MM/YYYY"
                        v-validate="'required|date_format:dd/mm/yyyy'"
                        data-vv-as="publication date"
                      />
                      <span
                        class="w-full text-red text-sm ml-2"
                        v-show="errors.has('published_date')"
                        >{{ errors.first("published_date") }}</span
                      >
                    </label>
                  </div>

                  <div class="flex w-full">
                    <!-- FOLIO NUMBER -->
                    <label
                      class="text-normal-bold w-1/2 mt-4"
                      for="folio_number"
                    >
                      <a class="text-red-star">*</a>Folio Number

                      <input
                        id="folio_number"
                        name="folio_number"
                        type="text"
                        class="flex w-full input-area mt-2 mb-4"
                        v-validate="'required'"
                        v-model="folioNumber"
                        @keyup="setToStore('folioNumber', $event)"
                        data-vv-as="folio number"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="flex pt-4 text-center pb-10">
                <a
                  @click="
                    $router.push({ name: 'roadNameProposalBucket', proposalId })
                  "
                  class="button-red-hollow flex-no-grow"
                  >Cancel Erratum Notice</a
                >

                <div class="flex-grow"></div>

                <button
                  @click="submitNotice"
                  class="button-blue flex-no-grow"
                  :disabled="isSaveDisabled"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </template>
      </showLoading>
    </section>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import { getRoadNameById } from "@/helpers/rnpCRUD.js";
import proposalIcon from "../proposals/road/proposalIcon";
import roadTypeListMixin from "../../mixins/road-type-list-filter";
import { mapMutations, mapGetters, createNamespacedHelpers } from "vuex";
const { mapMutations: mapRnpMutations } = createNamespacedHelpers("rnp");
import { getSuburbs } from "@/helpers/servicesq.js";

export default {
  name: "ErratumGazetteNotice",

  /**
   * Instance mixins
   */
  mixins: [roadTypeListMixin],

  /**
   * Instance child components
   */
  components: {
    proposalIcon
  },

  /**
   * Local instance data
   */
  data() {
    return {
      road: null,
      roadName: null,
      roadType: null,
      gazetteNumber: null,
      publicationDate: null,
      folioNumber: null,
      lga_name: null,
      suburb_name: null,
      extent: null,
      suburbOptions: []
    };
  },

  /**
   * Instance computed properties
   */
  computed: {
    ...mapGetters(["erratumGazette"]),

    roadNameId() {
      return this.$route.params.roadNameId;
    },

    proposalId() {
      return this.$route.params.proposalId;
    },

    isSaveDisabled() {
      return (
        !this.roadName ||
        !this.roadType ||
        !this.gazetteNumber ||
        !this.folioNumber ||
        !this.publicationDate ||
        !(this.suburb_name && this.suburb_name.length) ||
        !this.extent
      );
    },

    hasNoGazettalToCorrect() {
      return !this.road.date_gazetted;
    }
  },

  /**
   * The instance was created
   */
  async created() {
    await this.fetchRoadInformation();

    this.setStartValues();

    await this.getSuburbsForLGA(this.road.lga_name);
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapMutations(["setSourceErratumGazette", "setErratumGazette"]),

    setRoadType(value) {
      this.setErratumGazette({ target: "roadType", value: value });
    },

    setSuburb(value) {
      this.setErratumGazette({ target: "suburb_name", value: value });
    },

    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      this.suburbOptions = suburbs.filter(sub => sub !== null && sub !== "");
    },

    async submitNotice() {
      await this.$validator.validate();

      this.$router.push({
        name: "erratumGazettalPreview",
        params: {
          road: this.road,
          proposalId: this.proposalId,
          roadNameId: this.roadNameId
        }
      });
    },

    fetchRoadInformation: waitFor("fetchingRoadInformation", async function () {
      try {
        this.road = await getRoadNameById(this.roadNameId);

        this.setStartValues();
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve road name information",
          text: error.response.data.message
        });
      }
    }),

    setStartValues: waitFor("fetchingRoadInformation", async function () {
      if (!this.erratumGazette.userValues) {
        this.setSourceErratumGazette({
          target: "roadName",
          value: this.road.roadname
        });

        this.setSourceErratumGazette({
          target: "roadType",
          value: this.road.road_type
        });

        this.setSourceErratumGazette({
          target: "suburb_name",
          value: this.road.suburb_name
        });

        this.setSourceErratumGazette({
          target: "extent",
          value: this.road.extent
        });
      }

      this.roadName = this.erratumGazette.roadName;
      this.roadType = this.erratumGazette.roadType;
      this.gazetteNumber = this.erratumGazette.gazetteNumber;
      this.publicationDate = this.erratumGazette.publicationDate;
      this.folioNumber = this.erratumGazette.folioNumber;
      this.suburb_name = this.erratumGazette.suburb_name;
      this.extent = this.erratumGazette.extent;
    }),

    setToStore(target, evt) {
      this.setErratumGazette({ target, value: evt.target.value });
    }
  }
};
</script>
