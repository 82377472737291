<template>
  <div class="base-container">
    <div
      class="justify-center  block self-center bg-white text-black rounded-lg px-16 py-16 mx-16"
    >
      <h3 class="font-bold">Suburb Search</h3>
      <hr class="divider w-full" />
      <p class="mt-8 mb-12">
        Enter a suburb name to view an interactive map of the suburb/locality
        extent and surrounds. The interactive map will open in a new window in
        the Spatial Information Exchange (SIX) website. Please note - first time
        users may be prompted to install a plug-in on first use.
      </p>
      <div class="w-full pt-4">
        <p class="text-normal-bold pt-4 mb-2">
          Suburb Name
        </p>
        <norns-select
          class="w-full mr-4 norns-select"
          v-model="selectedLocality"
          :multiple="false"
          :options="localityList"
          :close-on-select="true"
          :show-labels="false"
          :hide-selected="false"
          :select-on-tab="true"
          placeholder="Select a Suburb"
          v-validate="'required'"
          name="localities"
          data-cypress="localities-field"
        ></norns-select>
      </div>
      <button class="button-red-hollow mt-4" @click="$router.go(-1)">
        Cancel
      </button>
      <button
        class="button-blue float-right mt-4"
        :disabled="!selectedLocality"
        @click="openSuburb()"
      >
        Search
      </button>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import pnpCRUD from "../../helpers/pnpCRUD.js";

export default {
  name: "suburbSearch",
  async created() {
    await this.fetchLocalities();
  },
  data() {
    return {
      localities: [],
      selectedLocality: null
    };
  },
  computed: {
    localityList() {
      return this.localities;
    }
  },
  methods: {
    openSuburb() {
      window.open(
        `https://maps.six.nsw.gov.au/?search={"name":"Suburb","fields":{"suburbname":"${
          this.selectedLocality
        }"}}`,
        "_blank"
      );
    },
    fetchLocalities: waitFor("fetchingLocalityList", async function() {
      try {
        this.localities = await pnpCRUD.getLocalityList();
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to Retrieve Suburb List",
          text: "There was an issue retrieving the list of suburbs."
        });
      }
    })
  }
};
</script>

<style scoped>
.divider {
  height: 1px;
  width: 100%;
  background-color: #cbcbcb;
}
</style>
