<template>
  <Layout>
    <template v-slot:content>
      <authenticationContent />
    </template>

    <template v-slot:form>
      <authenticationForm />
    </template>
  </Layout>
</template>

<script>
import Layout from "../components/authentication/authentication-layout";
import authenticationForm from "@/components/authentication/authenticationForm.vue";
import authenticationContent from "@/components/authentication/authencticationContent.vue";

export default {
  name: "Authentication",
  components: {
    authenticationForm,
    authenticationContent,
    Layout
  },
  computed: {
    userData() {
      return this.$store.getters.user;
    }
  },
  mounted() {
    if (this.userData) {
      // Live View session identifiers
      // eslint-disable-next-line no-undef
      __ls("identify", {
        name: this.userData.first_name,
        email: this.userData.email
      });
      // this.$router.push("/Dashboard");
    }
    // this.$router.push("/Dashboard");
  }
};
</script>
