<template>
  <div class="pl-4 flex flex-auto flex-row list-item" v-if="!!rna">
    <div class="flex sm:flex-col sm:items-start flex-no-grow w-1/4 py-4">
      <div
        class="flex-auto flex justify-between content-between items-center w-full"
      >
        <div
          class="sm:hidden xl:flex w-12 h-12 rounded-full mr-4 bg-grey-lighter  justify-center items-center flex-none"
        >
          <div class="flex-none">
            <font-awesome-icon icon="road" class="text-2xl" />
          </div>
        </div>
        <div class="flex-grow flex flex-col justify-start items-start">
          <router-link
            tag="p"
            class="flex-auto pr-2 font-bold cursor-pointer"
            data-cypress="rna-name-link"
            :to="{ name: 'rnasView', params: { id: rna.identifier } }"
          >
            {{ rna.authority_name }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="dividing-line-v-2 mx-4"></div>
    <div
      class="py-4 flex-grow flex  sm:flex-row flex-wrap items-stretch justify-around content-start"
    >
      <div class="flex-1   flex flex-col justify-start">
        <div class="flex-auto text-label">
          <div class="flex-no-grow flex flex-col break-all">
            <a
              data-cypress="rna-email-link"
              class="text-black pb-2 pr-4 flex-1 flex align-center cursor-default"
              ><font-awesome-icon
                icon="envelope"
                class="pr-2 text-2xl flex-none"
              />
              Address
            </a>
            <span class="h-18"
              >{{ rna.address_line_1 }}<br />
              {{ rna.address_line_2 }}<br v-if="rna.address_line_2" />
              {{ rna.rna_suburb }} <br />
              {{ rna.rna_state }} {{ rna.rna_postcode }}</span
            >
          </div>
        </div>
      </div>
      <div class="flex-1   flex flex-col justify-start">
        <div class="flex-auto text-label">
          <div class="flex-no-grow flex flex-col break-all">
            <a
              data-cypress="rna-email-link"
              class="text-black pb-2 pr-4 flex-1 flex align-center cursor-default"
              ><font-awesome-icon icon="user" class="pr-2 text-2xl flex-none" />
              Responsible Person
            </a>
            <span
              >{{ rna.manager_name }}<br />
              {{ rna.manager_role }}</span
            >
          </div>
        </div>
      </div>
      <rnaProperty
        name="Users"
        :value="rna.members"
        class="flex-1 flex flex-col justify-around items-center"
        data-cypress="date-created"
      />
      <rnaProperty
        name="Pending Proposals"
        :value="rna.proposals"
        class="flex-1 flex flex-col justify-around items-center"
        data-cypress="date-created"
      />

      <!-- account status -->
      <div class="flex-0 flex justify-end">
        <div class="flex-1 flex items-center justify-center">
          <AccountStatus
            :account_status="rna.rna_account_status"
            data-cypress="rna-account"
          />
        </div>
      </div>
    </div>
    <div class="dividing-line-v-2 ml-4"></div>
    <div class="flex-no-grow flex flex-col sm:items-end sm:justify-center">
      <!-- actions -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import rnaProperty from "./rnaProperty";

export default {
  name: "rnaListItem",
  components: {
    rnaProperty
  },
  props: ["rna"]
};
</script>

<style scoped>
.list-item {
  min-height: 6em;
}

.telephone-link {
  font-weight: normal;
  color: black !important;
}

.email {
  color: black !important;
}
</style>
