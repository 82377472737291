var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "autocomplete" }, [
    _c("input", {
      staticClass: "w-full rounded-lg p-4 text-normal input-autocomplete",
      attrs: {
        "data-cypress": "select-entity-input",
        list: "entityList",
        name: "entityList",
      },
      domProps: { value: _vm.localName },
      on: {
        change: function ($event) {
          return _vm.selectEntity($event.target.value)
        },
        input: _vm.filterDataList,
      },
    }),
    _c(
      "datalist",
      { attrs: { id: "entityList" } },
      _vm._l(_vm.dataList, function (item) {
        return _c("option", { key: item.index }, [_vm._v(_vm._s(item.name))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }