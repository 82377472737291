<template>
  <ShowLoading :isLoading="RNA_WAITER">
    <template v-slot:component>
      <div>
        <div class="base-container text-black text-normal" v-if="rna">
          <section class="pb-5 justify-center w-full block">
            <div class="flex justify-between">
              <a
                @click="$router.go(-1)"
                class="flex justify-between mr-4 self-center"
                data-cypress="goback-arrow"
              >
                <BackArrowWithTitle
                  :title="`View RNA - ${rna.authority_name}`"
                />
              </a>
            </div>
          </section>

          <div class="bg-white w-full rounded-lg flex h-26 items-center">
            <div
              class="sm:hidden xl:flex w-12 h-12 rounded-full ml-6 bg-grey-lighter  justify-center items-center flex-none"
            >
              <div class="flex-none">
                <font-awesome-icon icon="road" class="text-2xl" />
              </div>
            </div>

            <div class="w-1/3 flex flex-wrap content-between ml-2 pl-4 py-8">
              <h3 class="w-full">{{ rna.authority_name }}</h3>
            </div>
            <div class="h-full dividing-line-v"></div>
            <div class="w-2/3 flex ml-6 h-full content-center">
              <div class="w-1/2 flex flex-wrap content-between py-6">
                <a
                  data-cypress="organisation-email-link"
                  class="text-black pr-4 flex-1 flex align-center cursor-default"
                  ><font-awesome-icon
                    icon="envelope"
                    class="pr-2 text-2xl flex-none"
                  />
                  Address
                </a>
                <span class="w-full">
                  {{ rna.address_line_1 }} {{ rna.address_line_2 }}<br />
                  {{ rna.rna_suburb }}
                  {{ rna.rna_state }}
                  {{ rna.rna_postcode }}
                </span>
              </div>
              <div class="w-1/2 flex flex-wrap content-between py-6">
                <a
                  data-cypress="organisation-email-link"
                  class="text-black pr-4 flex-1 flex align-center cursor-default"
                  ><font-awesome-icon
                    icon="user"
                    class="pr-2 text-2xl flex-none"
                  />
                  Responsible Person
                </a>
                <span class="w-full">
                  {{ rna.manager_name }}<br />Role: {{ rna.manager_role }}
                </span>
              </div>
            </div>

            <!-- actions -->
            <div
              class="w-16 h-full flex-auto flex flex-col content-around items-center border-l-2"
            >
              <router-link
                class="flex-auto flex justify-center items-center font-bold actions w-full h-1/2 action-button rounded-tr-lg"
                data-cypress="edit-rna-link"
                :to="{
                  name: 'rnasEdit',
                  params: { rna: rna, id: rna.identifier }
                }"
                :title="`Edit ${rna.authority_name} details`"
                ><font-awesome-icon icon="edit" class="flex-auto"
              /></router-link>

              <button
                :title="`Suspend ${rna.authority_name}`"
                class="flex-auto flex justify-center items-center text-red font-bold h-1/2 w-full action-button suspend-user rounded-br-lg "
                data-cypress="suspend-button"
                disabled="disabled"
              >
                <font-awesome-icon icon="lock" class="flex-auto" />
              </button>
            </div>
          </div>

          <div class="flex-auto flex items-center mt-8">
            <h2>{{ usersTitle }}</h2>
          </div>
          <ShowLoading :isLoading="USERS_WAITER" class="flex-0">
            <template slot="component">
              <UserList v-if="!!users" :users="users">
                <template slot-scope="{ user }">
                  <div
                    class="w-12 h-full flex-auto flex flex-col content-around items-center "
                  >
                    <router-link
                      class="flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button"
                      data-cypress="view-user-link"
                      :to="{ name: 'profile/view', params: { id: user.email } }"
                      :title="
                        `View ${user.first_name} ${user.last_name}'s profile`
                      "
                      ><font-awesome-icon icon="eye" class="flex-auto"
                    /></router-link>

                    <router-link
                      :to="{ name: 'profile/edit', params: { id: user.email } }"
                      data-cypress="edit-user-link"
                      :title="
                        `Edit ${user.first_name} ${user.last_name}'s profile`
                      "
                      class="flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button"
                      ><font-awesome-icon icon="user-edit" class="flex-auto"
                    /></router-link>

                    <button
                      v-if="user.user_account_status === 'approved'"
                      :title="`Suspend ${user.first_name} ${user.last_name}`"
                      class="flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user "
                      data-cypress="suspend-button"
                      :disabled="user.user_account_status !== 'approved'"
                      @click="suspendUser(user)"
                    >
                      <font-awesome-icon icon="lock" class="flex-auto" />
                    </button>
                    <button
                      v-if="user.user_account_status !== 'approved'"
                      :title="`Approve ${user.first_name} ${user.last_name}`"
                      class="flex-auto flex justify-center items-center text-green font-bold h-1/3 w-full action-button activate-user "
                      data-cypress="suspend-button"
                      :disabled="user.user_account_status === 'pending'"
                      @click="approveUser(user)"
                    >
                      <font-awesome-icon icon="check" class="flex-auto" />
                    </button>
                  </div>
                </template>
              </UserList>
            </template>
          </ShowLoading>
          <div class="h-24 flex-auto" name="purelyABuffer"></div>
        </div></div
    ></template>
  </ShowLoading>
</template>

<script>
import UserList from "../userprofiles/userList";
import rnaMixin from "../../mixins/rna-mixin";
import userActionsMixin from "../../mixins/user-actions-mixin";

export default {
  name: "rnaView",
  mixins: [rnaMixin, userActionsMixin],
  components: {
    UserList
  },
  props: ["id"],
  computed: {
    usersTitle() {
      if (!this.users) {
        return "";
      }

      return `${this.users.length} Users`;
    },
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    }
  },
  methods: {
    async fetch() {
      try {
        this.rna = await this.fetchRNA(this.id);
        this.users = await this.fetchRNAUsers(this.id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        // this.checkResponseError(e);
      }
    }
  },
  async created() {
    await this.fetch();
  },
  async updated() {
    await this.fetch();
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetch();
      }
    }
  }
};
</script>

<style scoped>
.action-button:hover {
  background: #002664;
  color: white;
}

.action-button.suspend-user:hover {
  background: #e3342f;
  color: #fff;
}

.action-button.activate-user:hover {
  background: #38c172;
  color: #fff;
}

.action-button:disabled {
  opacity: 0.4;
}
</style>
