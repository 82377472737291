// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/Images/BG_Places-white.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main[data-v-db5e65ea] {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-attachment: fixed;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n.border-blue[data-v-db5e65ea] {\n  border: 3px solid #002664;\n}\n.tree-img[data-v-db5e65ea] {\n  height: 50px;\n}\n.pnp-divider[data-v-db5e65ea] {\n  height: 1px;\n  width: 100%;\n  background-color: #e6e6e6;\n}\n.button-back[data-v-db5e65ea] {\n  cursor: pointer;\n}\n.modified-background[data-v-db5e65ea] {\n  background-color: #ebebebd2 !important;\n}\n", ""]);
// Exports
module.exports = exports;
