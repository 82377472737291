var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "entry",
        {
          attrs: {
            eventDate: _vm.entryData.date,
            cardTitle: "Advertising Dates",
          },
        },
        [
          _vm.advertisingStartDate
            ? _c("div", { staticClass: "mt-2" }, [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v("The Advertising Start Date was set to:"),
                ]),
                _c("strong", [_vm._v(_vm._s(_vm.advertisingStartDate))]),
              ])
            : _vm._e(),
          _vm.advertisingEndDate
            ? _c("div", { staticClass: "mt-2" }, [
                _c("p", { staticClass: "mb-2" }, [
                  _vm._v("The Advertising End Date was set to:"),
                ]),
                _c("strong", [_vm._v(_vm._s(_vm.advertisingEndDate))]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }