var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Roadname Guideline Breaches",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", { staticClass: "w-full flex items-start mt-8" }, [
        _c("div", { staticClass: "pr-4 items-start" }, [
          _c("p", { staticClass: "text-normal-bold mb-2" }, [
            _vm._v("Rejection Guidelines"),
          ]),
          _c("table", { staticClass: "rejection-table" }, [
            _c(
              "tbody",
              _vm._l(_vm.entryData.breaches, function (breach, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "px-3 py-2 align-top" }, [
                    _vm._v(_vm._s(breach.guidelineNumber)),
                  ]),
                  _c("td", { staticClass: "px-2 py-2 align-top" }, [
                    _vm._v(" " + _vm._s(breach.guidelineDescription) + " "),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }