<template>
  <button
    :class="label + ' ' + classNames"
    class="mx-2 my-2 flex-wrap justify-center self-start border-2 pt-2 pb-2 pl-8 pr-8 font-bold rounded-full focus:outline-none self-center"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    classNames: {
      type: String
    }
  }
};
</script>

<style scoped>
/* road names */
.Public {
  color: #0a7cb9;
  border-color: #0a7cb9;
}
.Non-Dedicated {
  color: #c60c30;
  border-color: #c60c30;
}
.Pre-Approval {
  color: #00a908;
  border-color: #00a908;
}
.Locality {
  color: #0a7cb9;
  border-color: #0a7cb9;
}

/* place names */
.Boundary {
  color: #c60c30;
  border-color: #c60c30;
}

.Place {
  color: #00a908;
  border-color: #00a908;
}
</style>
