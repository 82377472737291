var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("showLoading", {
    attrs: { isLoading: "fetching users" },
    scopedSlots: _vm._u(
      [
        {
          key: "component",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "flex flex-col flex-grow",
                  attrs: { "data-cypress": "user-list" },
                },
                [
                  _vm._l(_vm.users, function (user, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "flex flex-grow flex-row bg-white text-black rounded-lg mt-4 overflow-hidden",
                      },
                      [
                        _c(
                          "UserListItem",
                          { attrs: { user: user } },
                          [_vm._t("default", null, { user: user })],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  !_vm.users.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-grow align-center justify-center mt-8",
                        },
                        [
                          _c("h3", { staticClass: "flex-0 text-black" }, [
                            _vm._v("No Users found."),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }