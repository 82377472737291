<template>
  <div>
    <!-- The 'Delete' button is only shown in the user profile page -->
    <button
      v-if="isFromUserProfile && showDelete"
      @click="openDeleteUserModal(modalName)"
      class="
        mx-2
        mt-2
        border-1
        pt-2
        pb-2
        pl-6
        pr-6
        rounded-full
        focus:outline-none
        text-error
        cursor-pointer
        button-red-hollow
      "
    >
      Delete
    </button>
    <!-- Shows a trash bin icon in the Administer user list page -->
    <button
      v-if="!isFromUserProfile"
      class="actions action-button w-full h-full"
      :title="`Delete ${user.first_name} ${user.last_name}`"
      data-cypress="delete-button"
      @click="determineShowDeleteInAdminUsers()"
    >
      <font-awesome-icon icon="trash" class="flex-auto" />
    </button>

    <modal :name="modalName" :height="'auto'">
      <div class="flex flex-col justify-between">
        <!-- Appends the modal title -->
        <h2 class="text-title flex-0 px-4">Delete User</h2>
        <div class="dividing-line-h"></div>
        <!-- Appends the confirmation text -->
        <div class="text-normal text-center mt-4">
          <h3>{{ confirmMessage }}</h3>
        </div>
        <!-- Appends a delete button -->
        <div class="my-6 flex justify-around">
          <button
            type="button"
            class="button-red"
            @click="callDeleteUser()"
            data-cypress="delete-button"
          >
            Delete
          </button>
          <!-- Appens a cancel button -->
          <button
            type="button"
            class="button-blue"
            @click="closeDeleteUserModal(modalName)"
            data-cypress="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { deleteUser } from "../../../helpers/userCRUD.js";
import pnpCrud from "../../../helpers/pnpCRUD";
import { getAllRoadnameProposals } from "../../../helpers/proposalsCRUD";

export default {
  name: "DeleteUserModal",
  props: ["user", "isFromUserProfile", "loggedUser", "passedRoadNameProposals", "passedPlaceNameProposals"],
  data() {
    return {
      modalName: this.user.identifier,
      showDelete: false,
      roadNameProposals: null,
      placeNameProposals: null,
      errorMessage: "",
      confirmMessage: "Are you sure to delete this user?"
    };
  },
  async mounted() {
    // Adds the currently logged user name to the object
    this.user.loggedUserFirstName = this.loggedUser.first_name;
    this.user.loggedUserLastName = this.loggedUser.last_name;

    if(this.isFromUserProfile) {
      // Checks whether the delete button in the user profile is shown or not
      await this.getPlaceNameProposals();
      await this.getRoadNameProposals();
      this.determineShowDelete();
    }
  },
  watch: {
    // Watches the number of proposals and when the number of a proposal becomes 0, the delete button in the user profile will be shown
    passedRoadNameProposals: async function(newVal) {
      this.roadNameProposals = newVal;
      await this.getPlaceNameProposals();
      this.determineShowDelete();
    },
    passedPlaceNameProposals: async function(newVal) {
      this.placeNameProposals = newVal;
      await this.getRoadNameProposals();
      this.determineShowDelete();
    }
  },
  methods: {
    // Opens the delete modal
    openDeleteUserModal(modalName) {
      this.$modal.show(modalName, {
        height: "auto"
      });
    },
    // Closes the delete modal
    closeDeleteUserModal(modalName) {
      this.$modal.hide(modalName);
    },
    // Implements deleting a user
    async callDeleteUser() {
      // Calls the API to delete the user
      const result = await deleteUser(this.user);

      // Closes the modal once deleting is completed
      this.closeDeleteUserModal(this.modalName);
      // Shows a message based on the result 
      this.$notify({
        group: "toast",
        type: result.status,
        title: result.status,
        text: result.message
      });
      // Redirects to the Administer users list
      this.$router.push("/users");
    },
    // Checks whether the delete button in the user profile is shown or not
    determineShowDelete() {
      // Initialises the error message
      this.errorMessage = "";

      if(this.placeNameProposals.length === 0 && this.roadNameProposals.length === 0) {
        this.showDelete = true;
      }

      this.errorMessage = "A user having proposal(s) cannot be deleted.";
    },
    // Checkes whether the user can be removed in the Administer users page
    async determineShowDeleteInAdminUsers() {
      try{
        await this.getPlaceNameProposals();
        await this.getRoadNameProposals();

        // Checks whether the user can be removed
        this.determineShowDelete();

        // If the user can be removed
        if(this.showDelete) {
          // Opens the user delete modal
          this.openDeleteUserModal(this.modalName);
        // If not
        } else {
          // Shows a message
          this.$notify({
              group: "toast",
              type: "error",
              title: "Cannot be deleted",
              text: this.errorMessage
            });
        }
      } catch(e) {
        console.error(e)
      }
    },
    // Fetches roadname proposals
    async getRoadNameProposals() {
      try {
        // Gets only 1 proposal to reduce the loading time
        const result = await getAllRoadnameProposals({
          proposer_id: this.user.identifier,
          page: 1,
          limit: 1
        });
        // Stores the result
        this.roadNameProposals = result.proposals;
      } catch (e) {
        this.roadNameProposals = [];
        this.$notify({
          group: "toast",
          type: "error",
          title: "Roadname Proposals",
          text: e.response.data.message
        });
      }
    },
    // Fetches placename proposals
    async getPlaceNameProposals() {
      try {
        // Gets only 1 proposal to reduce the loading time
        const result = await pnpCrud.searchPlacenameProposals({
          email: this.user.email,
          page: 1,
          limit: 1,
          includeTotal: true
        });
        // Stores the result
        this.placeNameProposals = result.proposals;
      } catch (e) {
        this.placeNameProposals = [];
        this.$notify({
          group: "toast",
          type: "error",
          title: "Placename Proposals",
          text: e.response.data.message
        });
      }
    },
  }
};
</script>