<template>
  <v-wait
    :for="isLoading"
    v-bind:key="isLoading"
    class="wait-container flex flex-grow flex-col"
  >
    <Loading slot="waiting"> </Loading>
    <slot name="component" v-if="!errorLoading"></slot>
    <NetworkError v-if="errorLoading" />
  </v-wait>
</template>

<script>
import Loading from "./loading-spinner";
export default {
  name: "ShowLoadingSpinner",
  props: ["isLoading", "errorLoading"],
  components: { Loading }
};
</script>

<style>
.wait-container > span {
  flex: 1 0 auto;
  display: flex;
}
</style>
