import { render, staticRenderFns } from "./appeal-submit.vue?vue&type=template&id=5213b405&"
import script from "./appeal-submit.vue?vue&type=script&lang=js&"
export * from "./appeal-submit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5213b405')) {
      api.createRecord('5213b405', component.options)
    } else {
      api.reload('5213b405', component.options)
    }
    module.hot.accept("./appeal-submit.vue?vue&type=template&id=5213b405&", function () {
      api.rerender('5213b405', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/road/appeal-submit.vue"
export default component.exports