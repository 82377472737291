<template>
  <modal
    :name="this.roadnameId"
    :width="'50%'"
    height="auto"
    :classes="'rounded-lg bg-white'"
  >
    <div
      class="w-full mx-auto pt-6 pb-14 mb-8 px-6 text-black flex flex-col text-normal"
    >
      <a @click="hide()" class="cursor-pointer ml-auto">
        <ErrorIcon classNames="w-4" />
      </a>
      <div
        class="flex items-center content-center justify-center mt-6 mb-5 px-10"
      >
        <p v-if="!road.date_endorsed" class="text-title">
          Enter Formalised Date
        </p>
        <p v-else-if="road.date_endorsed" class="text-title">
          Update Formalised Date
        </p>
      </div>
      <div class="px-10">
        <p class="text-center text-3xl pt-4 pb-1">
          {{ road.roadname }} {{ road.road_type }}
        </p>

        <p class="text-normal-bold pt-6 pb-1">
          <a class="text-red-star">*</a>Formalised Date
        </p>
        <datepicker
          class="w-full rounded-lg p-4 mb-4 bg-grey-input"
          v-model="formalisedDate"
          :value="formalisedDate"
          name="formalisedDate"
          :format="customFormatter"
          v-validate="{ required: true, date_format: 'yyyy-MM-dd' }"
          input-class="w-full bg-grey-input"
          calendar-class="fix-date-picker"
        >
        </datepicker>

        <div class="flex items-center" v-if="errors.first('formalisedDate')">
          <ErrorIcon />
          <div class="ml-2 text-red-error text-normal-bold">
            The formalise date is required.
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full mt-16 px-10">
        <div @click="hide()" class="button-red-hollow mr-4 w-2/5">Cancel</div>
        <ButtonWithSpinner
          @click="submitFormalise()"
          class="button-blue ml-4 w-2/5"
          type="button"
          :disabled="false"
          :isSpinning="$wait.is('saving formalisation')"
        >
          Submit Date
        </ButtonWithSpinner>
      </div>
    </div>
  </modal>
</template>
<script>
import moment from "moment";
import { waitFor } from "vue-wait";
import { mapActions } from "vuex";
import {
  scheduleFormalise,
  updateFormalise
} from "../../../../helpers/gazettalCRUD";
import Datepicker from "vuejs-datepicker";

export default {
  name: "formalised-date-modal",
  components: { Datepicker },
  props: ["road", "proposalId"],
  methods: {
    ...mapActions(["getRoadNameProposalBucketById"]),
    hide() {
      this.$modal.hide(this.roadnameId);
    },
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    submitFormalise: waitFor("saving formalisation", async function() {
      try {
        this.formalisedDate = moment(this.formalisedDate).format("YYYY-MM-DD");
        this.$wait.start("loading");
        const valid = await this.$validator.validate();
        if (valid) {
          if (this.road.date_endorsed) {
            await updateFormalise({
              roadnameId: this.roadnameId,
              formalisedDate: this.formalisedDate,
              jira_id: this.road.jira_id
            });
          } else {
            await scheduleFormalise({
              roadnameId: this.roadnameId,
              formalisedDate: this.formalisedDate,
              jira_id: this.road.jira_id
            });
          }

          await this.getRoadNameProposalBucketById(this.proposalId);

          this.$modal.hide(this.roadnameId);
          this.$wait.end("loading");
          this.$notify({
            group: "toast",
            type: "success",
            title: "Formalised roadname",
            text: "Road name date has been scheduled."
          });
        }
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Formalise issue",
          text: e.response.data.message
        });
      }
    })
  },
  data() {
    return {
      formalisedDate: moment(this.road.date_endorsed || new Date()).format(
        "YYYY-MM-DD"
      ),
      roadnameId: this.road.identifier
    };
  }
};
</script>
