<template>
  <button
    :class="status + ' ' + classNames"
    class="mx-2 my-2 flex-wrap justify-center self-start border-2 pt-2 pb-2 pl-8 pr-8 rounded-full focus:outline-none self-center cursor-default"
  >
    {{ label }}
    <p v-if="extra">{{ extra }}</p>
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    status: String,
    classNames: {
      type: String,
      default: ""
    },
    extra: {
      type: String,
      optional: true
    }
  }
};
</script>

<style scoped>

/*Roadname Statuses*/
.Appealed {
  color: #ffffff;
  border-color: #F04E1E;
  background-color: #F04E1E;
}

.Approved, .Pre-Approved, .Verified {
  color: #ffffff;
  border-color: #1EF026;
  background-color: #1EF026;
}

.Approved.Archived, .Approved.Appeal, .Endorsed, .Gazetted {
  color: #ffffff;
  border-color: #049319;
  background-color: #049319;
}

.Clarification {
  color: #ffffff;
  /*border-color: #F0C31E;
  background-color: #F0C31E;*/
  border-color: #E4D70B;
  background-color: #E4D70B;
}

.Pending.Endorsement {
  color: #ffffff;
  /*border-color: #F0C31E;
  background-color: #F0C31E;*/
  border-color: #F0A61E;
  background-color: #F0A61E;
}

.Pending.Gazette, .Pending {
  color: #ffffff;
  /*border-color: #F0C31E;
  background-color: #F0C31E;*/
  border-color: #F0A61E;
  background-color: #F0A61E;
}

.Discontinued {
  color: #ffffff;
  border-color: #EC0B52;
  background-color: #EC0B52;
}

.Drafted, .Draft {
  color: #ffffff;
  border-color: #1EE2F0;
  background-color: #1EE2F0;
}

.Gazette.Notice.Under.Review,
.Review.Pre-Approval,
.Review.Erratum {
  color: #ffffff;
  border-color: #E4D70B;
  background-color: #E4D70B;
}

.Under.Review,
.Applicant.To.Review.Comments,
.Clarification.Advert {
  color: #ffffff;
  border-color: #E4D70B;
  background-color: #E4D70B;
}

.Proposed, .To.Be.Advertised.For.Public.Comment {
  color: #ffffff;
  border-color: #1EA5F0;
  background-color: #1EA5F0;
}

.Rejected, .Withdrawn, .Modified {
  color: #ffffff;
  border-color: #F01E1E;
  background-color: #F01E1E;
}

.Rejected.On.Appeal {
  color: #ffffff;
  border-color: #F01E1E;
  background-color: #F01E1E;
}

.Sent.For.Appeal {
  color: #ffffff;
  border-color: #1E63F0;
  background-color: #1E63F0;
}

/*Placename Statuses*/
.Dual.Official.Assigned,
.Concurred,
.Official.Assigned,
.Official.Recorded,
.Processed.Administratively {
  color: #ffffff;
  border-color: #049319;
  background-color: #049319;
}

.Deferred {
  color: #ffffff;
  border-color: #F0A61E;
  background-color: #F0A61E;
}

.Discontinued {
  color: #ffffff;
  border-color: #EC0B52;
  background-color: #EC0B52;
}

.Variant {
  color: #ffffff;
  border-color: #1EF026;
  background-color: #1EF026;
}

.Abandoned {
  color: #ffffff;
  border-color: #F01E1E;
  background-color: #F01E1E;
}
.Open.For.Comment, .Closed.For.Comment {
  color: #ffffff;
  border-color: #1E63F0;
  background-color: #1E63F0;
}

.Recommendation.With.Minister, .Modification.Required {
  color: #ffffff;
  border-color: #751EF0;
  background-color: #751EF0;
}

.Objections.Under.Review, .Board.To.Reconsider, .Board.To.Consider {
  color: #ffffff;
  border-color: #2F1EF0;
  background-color: #2F1EF0;
}

/* existing */
.Objecting,
.deactivated {
  color: #c60c30;
  border-color: #ffccd5;
  background-color: #ffccd5;
}
.Supporting {
  color: #007906;
  border-color: #d4f8b7;
  background-color: #d4f8b7;
}

</style>
