<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div class="w-2/3 bg-white mx-auto my-3 z-10 rounded">
      <div class="w-full bg-white text-black rounded-lg p-10">
        <div class="w-full align-center text-center mt-16">
          <img src="../../../../assets/Logos/gnb.jpg" class="gnb-logo" />
          <h3 class="pb-3 mb-4 text-title ">
            Comment on Place Name Proposal
          </h3>
          <p class="mb-8 mx-16">
            In accordance with Section 9 of the Geographical Names Act 1966, all
            submissions lodged may be subject to a
            <span class="span-blue font-bold"
              >Government Information (Public Access) act</span
            >
            application and may be viewed by a third party to assist the Board
            in considering this proposal.
          </p>
        </div>
        <div class="flex justify-center mb-24">
          <Button
            class="button-blue-hollow flex justify-center mr-3"
            data-cypress="return-button"
            :disabled="false"
            @click="backToProposals()"
            >Return to Current Proposals Page
          </Button>
          <Button
            class="button-blue flex justify-center "
            data-cypress="comment-button"
            :disabled="false"
            @click="goToCommentForm()"
            >Submit a Comment
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commentStart",
  methods: {
    goToCommentForm() {
      this.$router.push({
        name: "placeNameProposalPublicCommentForm",
        params: { commentId: "create" }
      });
    },
    backToProposals() {
      this.$router.push({ name: "currentPlaceNameProposals" });
    }
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}

.gnb-logo {
  height: 125px;
}

.span-blue {
  color: #002664;
}
</style>
