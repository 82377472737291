var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c(
        "div",
        {
          staticClass:
            "justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3",
        },
        [
          _c("section", { staticClass: "justify-center w-full block" }, [
            _c(
              "h3",
              { staticClass: "pb-3 mb-8 w-full text-title dividing-line-h" },
              [_vm._v(" Road Name Evaluation ")]
            ),
            _c("p", { staticClass: "mb-4" }, [
              _vm._v("Check the eligibility of a Proposed Road Name."),
            ]),
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(
                "This is not a formal approval. All road name proposals must be submitted by a legislated Road Naming Authority to the GNB Secretariat for a more comprehensive evaluation against the guidelines of the NSW Address Policy and User Manual."
              ),
            ]),
            _c("div", { staticClass: "flex mb-5" }, [
              _c(
                "div",
                { staticClass: "w-full flex content-center items-start mt-8" },
                [
                  _c(
                    "div",
                    { staticClass: "pr-4 w-1/2 items-start" },
                    [
                      _vm._m(0),
                      _c(
                        "norns-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mr-4 norns-select",
                          attrs: {
                            multiple: false,
                            options: _vm.lgasClean,
                            searchable: true,
                            "close-on-select": true,
                            placeholder: "Select LGA...",
                            name: "LGA",
                            "data-cypress": "lga-select",
                            "data-vv-as": "Local Government Authority",
                            disabled: _vm.$wait.is("sending email"),
                            "select-on-tab": true,
                          },
                          model: {
                            value: _vm.lgaName,
                            callback: function ($$v) {
                              _vm.lgaName = $$v
                            },
                            expression: "lgaName",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Loading...")]
                          ),
                        ]
                      ),
                      _c("FieldErrorMessage", {
                        attrs: { message: _vm.errors.first("LGA") },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pl-4 w-1/2 flex flex-col" },
                    [
                      _vm._m(1),
                      _c(
                        "norns-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full mr-4 norns-select",
                          attrs: {
                            disabled:
                              !_vm.lgaName || _vm.$wait.is("sending email"),
                            multiple: true,
                            options: _vm.suburbOptions,
                            searchable: true,
                            "close-on-select": false,
                            "show-labels": true,
                            "hide-selected": true,
                            placeholder: "Pick a Suburb",
                            name: "suburb",
                            "data-cypress": "suburb-field",
                            "data-vv-as": "Suburb",
                            "select-on-tab": true,
                          },
                          model: {
                            value: _vm.suburbName,
                            callback: function ($$v) {
                              _vm.suburbName = $$v
                            },
                            expression: "suburbName",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Loading...")]
                          ),
                        ]
                      ),
                      _c("FieldErrorMessage", {
                        attrs: { message: _vm.errors.first("suburb") },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "flex mb-5" }, [
              _c(
                "div",
                { staticClass: "w-full flex content-center items-start mt-8" },
                [
                  _c("div", { staticClass: "pr-4 w-1/2 items-start" }, [
                    _vm._m(2),
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.continues",
                          value: "required",
                          expression: "'required'",
                          modifiers: { continues: true },
                        },
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.roadName,
                          expression: "roadName",
                          modifiers: { trim: true },
                        },
                      ],
                      staticClass: "w-full mr-4 input-area mb-1 mt-1",
                      attrs: {
                        type: "text",
                        disabled:
                          !_vm.lgaName ||
                          !_vm.suburbName.length ||
                          _vm.$wait.is("sending email"),
                        "data-cypress": "roadname-field",
                        placeholder: "Enter Name Here...",
                        name: "Proposed Road Name",
                        id: "roadName",
                      },
                      domProps: { value: _vm.roadName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.roadName = $event.target.value.trim()
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "pl-4 w-1/2 flex flex-col" }, [
                    _c(
                      "p",
                      { staticClass: "text-normal-bold mb-2" },
                      [
                        _c("span", { staticClass: "text-red-star" }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("Radius "),
                        _c(
                          "norns-select",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full mr-4 mt-3 norns-select",
                            attrs: {
                              disabled:
                                !_vm.lgaName || _vm.$wait.is("sending email"),
                              multiple: false,
                              options: _vm.distanceOptions,
                              reduce: (d) => d.value,
                              searchable: false,
                              "close-on-select": true,
                              "show-labels": true,
                              "hide-selected": true,
                              placeholder: "Pick a radius",
                              name: "distance",
                              "data-cypress": "radius-field",
                              "select-on-tab": true,
                            },
                            model: {
                              value: _vm.distance,
                              callback: function ($$v) {
                                _vm.distance = $$v
                              },
                              expression: "distance",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "noOptions" },
                                slot: "noOptions",
                              },
                              [_vm._v("Loading...")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._m(3),
            _c(
              "div",
              [
                _c("WebMap", {
                  ref: "WebMapRef",
                  staticStyle: { height: "450px" },
                  attrs: {
                    id: "WebMap",
                    lgaName: _vm.lgaName,
                    suburbs: _vm.suburbName,
                    distance: _vm.distance,
                  },
                  on: { "draw-complete": _vm.onDrawComplete },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "ButtonWithSpinner",
                  {
                    staticClass: "button-blue mb-1 mt-1",
                    attrs: {
                      isSpinning: _vm.$wait.is("evaluatingName"),
                      disabled:
                        _vm.$wait.is("evaluatingName") ||
                        !(
                          _vm.roadName &&
                          _vm.distance &&
                          Object.keys(_vm.suburbName).length &&
                          Object.keys(_vm.geometry).length
                        ),
                    },
                    on: { click: _vm.evaluate },
                  },
                  [_vm._v(" Evaluate ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col mb-5" },
              [
                _vm.$wait.is("evaluatingName")
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "flex-grow text-black text-sm font-bold text-center",
                      },
                      [_vm._v(" Checking Road name validity ")]
                    )
                  : _vm._e(),
                _c("ShowLoading", {
                  attrs: { isLoading: "evaluatingName" },
                  scopedSlots: _vm._u([
                    {
                      key: "component",
                      fn: function () {
                        return [
                          !_vm.emailError
                            ? _c(
                                "div",
                                { staticClass: "flex flex-grow items-start" },
                                [
                                  _vm.evaluated &&
                                  _vm.evaluation &&
                                  !!_vm.evaluation.breaches
                                    ? _c(
                                        "div",
                                        { staticClass: "flex-grow" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-orange-dark font-bold text-lg",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.evaluation.breaches
                                                      .issueCount
                                                  ) +
                                                  " potential issue(s) found "
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.evaluation.breaches.failures,
                                            function (failedGuideline) {
                                              return _c(
                                                "div",
                                                {
                                                  key: failedGuideline.guideLineType,
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-orange font-bold pt-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            failedGuideline.title
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    failedGuideline.issues,
                                                    function (message, index) {
                                                      return _c(
                                                        "MessagePanel",
                                                        {
                                                          key: `${failedGuideline.guideLineType}-${index}`,
                                                          staticClass:
                                                            "flex-grow",
                                                          attrs: {
                                                            warning: "",
                                                            msg: message.shortDescription,
                                                            mapURL:
                                                              message.mapURL,
                                                            geometry:
                                                              message.geometry,
                                                            guideLineType:
                                                              failedGuideline.guideLineType,
                                                          },
                                                          on: {
                                                            "increment-btn":
                                                              _vm.showLine,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  !failedGuideline.issues ||
                                                  failedGuideline.issues
                                                    .length < 1
                                                    ? _c("MessagePanel", {
                                                        key: failedGuideline.guideLineType,
                                                        staticClass:
                                                          "flex-grow",
                                                        attrs: {
                                                          warning: "",
                                                          msg: failedGuideline.shortDescription,
                                                          mapURL:
                                                            failedGuideline.mapURL,
                                                          geometry:
                                                            failedGuideline.geometry,
                                                          guideLineType:
                                                            failedGuideline.guideLineType,
                                                        },
                                                        on: {
                                                          "increment-btn":
                                                            _vm.showLine,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm.evaluated &&
                                      _vm.evaluation.passes == true
                                    ? _c(
                                        "div",
                                        [
                                          _c("MessagePanel", {
                                            staticClass: "flex-grow",
                                            attrs: {
                                              msg: "0 potential issues found",
                                              ok: "",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          !!_vm.emailError
                            ? _c(
                                "div",
                                { staticClass: "flex flex-1 items-start" },
                                [
                                  _vm.emailError
                                    ? _c("MessagePanel", {
                                        staticClass: "flex-grow",
                                        attrs: {
                                          error: "",
                                          msg: _vm.emailError,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex", attrs: { name: "3 buttons" } },
            [
              _c(
                "ButtonWithSpinner",
                {
                  staticClass:
                    "button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto",
                  attrs: {
                    isSpinning: false,
                    disabled:
                      _vm.$wait.is("sending email") ||
                      _vm.$wait.is("evaluatingName"),
                  },
                  on: { click: _vm.resetSearchForm },
                },
                [_vm._v("Reset")]
              ),
              _c(
                "ButtonWithSpinner",
                {
                  staticClass: "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                  attrs: {
                    isSpinning: _vm.$wait.is("sending email"),
                    disabled:
                      _vm.$wait.is("sending email") ||
                      _vm.$wait.is("evaluatingName") ||
                      !_vm.isValidForm,
                  },
                  on: { click: _vm.emailResults },
                },
                [_vm._v("Email Results")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("modal", { attrs: { name: "results" } }, [
        _c(
          "div",
          {
            staticClass:
              "base-container text-black h-auto flex flex-col pt-6 px-2 pb-14",
            attrs: { "data-cypress": "evaluation-modal" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-center content-center justify-center mt-8 mb-2",
              },
              [
                !_vm.emailError
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("img", {
                          staticClass: "h-24",
                          attrs: {
                            src: require("@/assets/Images/Icon_Gazettal.png"),
                          },
                        }),
                        _c("p", { staticClass: "flex-grow text-lg" }, [
                          _vm._v(" Your evaluation report has been sent "),
                        ]),
                        _c(
                          "Button",
                          {
                            staticClass: "button-blue mt-4 flex-grow",
                            on: { click: _vm.closeEvaluationModal },
                          },
                          [_vm._v("Okay")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.emailError
                  ? _c(
                      "p",
                      { staticClass: "flex-grow" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-red",
                          attrs: { icon: "exclamation" },
                        }),
                        _vm._v(
                          " There was an error sending your evaluation report "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Local Government Area "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Address Locality (Suburb) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Proposed Road Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex mb-5" }, [
      _c(
        "div",
        { staticClass: "w-full flex content-center items-start mt-8" },
        [
          _c("div", { staticClass: "pr-4 items-start" }, [
            _c("p", { staticClass: "text-normal-bold mb-2" }, [
              _vm._v(" Road Name Extent "),
            ]),
            _vm._v(" Please draw the extent of the proposed road on the map. "),
            _c("ul", [
              _c("li", [
                _vm._v(" Select either the polyline "),
                _c("img", {
                  staticStyle: { width: "40px" },
                  attrs: { src: "/img/icons/polyline-110x80.png" },
                }),
                _vm._v(" or polygon "),
                _c("img", {
                  staticStyle: { width: "32px" },
                  attrs: { src: "/img/icons/polygon-92x92.png" },
                }),
                _vm._v(" tool from within the map "),
              ]),
              _c("li", [_vm._v("Left click to mark the start of the road")]),
              _c("li", [_vm._v("Add additional points to mark out the road")]),
              _c("li", [_vm._v("Double click for the extent to be completed")]),
              _c("li", [
                _vm._v("Click Evaluate to undertake a road name evaluation"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }