var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex-auto text-label" },
      [
        !!_vm.icon
          ? _c("font-awesome-icon", { attrs: { icon: _vm.icon } })
          : _vm._e(),
        !_vm.icon ? _c("span", [_vm._v(_vm._s(_vm.name))]) : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex justify-center items-start flex-auto text-black" },
      [
        _vm._t("default", function () {
          return [_vm._v(" " + _vm._s(_vm.value) + " ")]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }