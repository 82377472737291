var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c("section", { staticClass: "mb-8" }, [
      _c("h2", { staticClass: "text-black text-title mb-8 self-center" }, [
        _vm._v(" Submit Gazettal Notices "),
      ]),
      _c(
        "div",
        {
          staticClass:
            "w-full bg-white text-black rounded-lg pt-16 pb-16 mt-4 justify-center flex flex-wrap",
        },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "justify-center text-center" },
            [
              _c("showLoading", { attrs: { isLoading: "gazetting roads" } }),
              _vm._l(_vm.gazettedRoads, function (road, index) {
                return _c("div", { key: index, staticClass: "flex pb-2" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-bold text-center text-sm text-white flex pr-8",
                      class: [road.error ? "invalid" : "valid"],
                    },
                    [
                      road.error
                        ? _c("font-awesome-icon", {
                            staticClass: "mx-auto self-center",
                            attrs: { icon: "times" },
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "mx-auto self-center",
                            attrs: { icon: "check" },
                          }),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v("Your Gazettal Notice for "),
                    _c("b", [_vm._v(_vm._s(road.name))]),
                    _vm._v(
                      " has " +
                        _vm._s(
                          road.error ? "failed to submit" : "been submitted"
                        ) +
                        "."
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex pt-4 text-center pb-10" },
        [
          _c(
            "router-link",
            {
              staticClass: "w-1/3 ml-auto",
              attrs: { to: { name: "proposals" } },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button-blue",
                  attrs: { disabled: _vm.$wait.any },
                },
                [_vm._v(" Go to My Proposals Dashboard ")]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full justify-center flex mb-8 pt-5" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/Images/press-release.png"),
          alt: "Press release image",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }