var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "absolute h-12 logoPos",
        attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }