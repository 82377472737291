var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("showLoading", {
    attrs: { isLoading: "loadingRnaList" },
    scopedSlots: _vm._u(
      [
        {
          key: "component",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "flex flex-col flex-grow",
                  attrs: { "data-cypress": "user-list" },
                },
                _vm._l(_vm.rnas, function (rna, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "flex flex-row bg-white text-black rounded-lg mt-4 overflow-hidden",
                    },
                    [
                      _c(
                        "RnaListItem",
                        { attrs: { rna: rna } },
                        [_vm._t("default", null, { rna: rna })],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }