var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c("ShowLoading", {
        attrs: { isLoading: "fetching clarification data" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _vm.errorLoading ? _c("NetworkError") : _vm._e(),
                !_vm.errorLoading && !!_vm.road
                  ? _c(
                      "section",
                      { staticClass: "pt-1 justify-center w-full block" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "flex justify-between pt-4 pb-4 -mr-4 self-center",
                            on: {
                              click: function ($event) {
                                return _vm.$router.go(-1)
                              },
                            },
                          },
                          [
                            _c("BackArrowWithTitle", {
                              attrs: { title: "Proposal Clarification" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center font-semibold",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 flex mx-auto" },
                              [
                                _c("proposalIcon", {
                                  attrs: {
                                    label: _vm.road.roadname_classification,
                                    classNames: "text-base mr-3 h-10 w-10",
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass: "self-center text-title mt-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.road.roadname) +
                                        " " +
                                        _vm._s(_vm.road.road_type) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("StatusButton", {
                                  staticClass:
                                    "ml-auto mr-6 font-semibold text-sm",
                                  attrs: {
                                    label: _vm.road.name_status,
                                    status: _vm.road.name_status,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start",
                          },
                          [
                            _c("div", { staticClass: "w-9/10 mx-auto" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "self-center pt-10 w-full block",
                                },
                                [
                                  _c(
                                    "h2",
                                    { staticClass: "text-title text-black" },
                                    [_vm._v("Clarification Request:")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "self-center text-normal text-grey-text",
                                    },
                                    [
                                      _vm._v(
                                        " Requested " +
                                          _vm._s(_vm.clarificationRequestDate) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex justify-center" },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "dividing-line-h w-full mt-4",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "self-center textRejectionReason text-normal text-black mt-5",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.clarificationInfo.request
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-normal-bold content-start mt-5",
                                },
                                [_vm._v(" Clarification Documents ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-10" },
                                [
                                  _vm.clarificationInfo
                                    ? _c("viewFile", {
                                        attrs: {
                                          "data-cypress": "clarification-files",
                                          files: _vm.clarificationInfo.files,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 mx-auto" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-normal-bold content-start mb-2 mt-10",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-red-star" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("Your Response "),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.appealText,
                                      expression: "appealText",
                                    },
                                  ],
                                  staticClass:
                                    "text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input",
                                  attrs: {
                                    placeholder: "Please enter your response",
                                    disabled: _vm.$wait.any,
                                  },
                                  domProps: { value: _vm.appealText },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.appealText = $event.target.value
                                    },
                                  },
                                }),
                                _c("uploadComponent", {
                                  staticClass: "mb-10",
                                  attrs: {
                                    label: "Attach supporting documentation",
                                    disabled: _vm.uploading || _vm.$wait.any,
                                    drafted: true,
                                    deleteFile: _vm.deleteFunction,
                                  },
                                  on: {
                                    uploadfinished: function ($event) {
                                      _vm.uploading = false
                                    },
                                    uploadstarted: function ($event) {
                                      _vm.uploading = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex pt-4 text-center pb-10 justify-end",
                          },
                          [
                            _c(
                              "ButtonWithSpinner",
                              {
                                staticClass: "p-3 ml-4 w-1/5 button-blue",
                                attrs: {
                                  disabled:
                                    _vm.uploading ||
                                    !_vm.appealText ||
                                    _vm.$wait.any,
                                  isSpinning: _vm.$wait.is(
                                    "saving clarification response"
                                  ),
                                },
                                on: { click: _vm.submitClarification },
                              },
                              [_vm._v(" Submit Clarification ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }