"use strict";

import API from "@/helpers/api";

const apiName = "dfsiApplicationAPI";

export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/ /g, "");
  if (phoneNumber.length == 8) {
    phoneNumber = "2" + phoneNumber;
  }

  if (phoneNumber.substring(0, 1).includes("0")) {
    phoneNumber = phoneNumber.slice(1, phoneNumber.length);
  }

  return phoneNumber.substring(0, 3).includes("+61")
    ? phoneNumber
    : "+61" + phoneNumber;
}

export function generateDateFormatted() {
  const dateObj = new Date();
  return dateObj.toLocaleDateString();
}

export async function evaluateRoadname(roadName, lgaName, suburbs) {
  const url = "/public/rnp/evaluate-roadname";
  return API.get(apiName, url, {
    queryStringParameters: {
      lgaName,
      roadName,
      suburbs: JSON.stringify(suburbs)
    }
  });
}

export async function evaluateRoadnameNew(
  roadName,
  lgaName,
  suburbs,
  distance = 10000,
  geometry,
  proposalId,
  includeCountOnly = false
) {
  const url = "/roadname/guidelines/validate";

  if (!distance || !geometry) {
    return false;
  }

  const distanceInMeters = Number.isInteger(distance)
    ? distance
    : distance.substring(0, 2) + "000";
  return await API.post(apiName, url, {
    body: {
      roadName: roadName, //"FRAZER",
      lga: lgaName, //"CENTRAL COAST",
      suburbs: suburbs, //["GREEN POINT"],
      proposalId: proposalId,
      similarityThreshold: 77,
      includeCloseMatches: false,
      includeAllResults: false,
      distance: distanceInMeters,
      debug: false,
      includeSuburbs: true,
      includeSuburbGeometry: false,
      geometry: geometry,
      includeCountOnly: includeCountOnly
    }
  });
}
export async function evaluateRoadnameNewBulk(roads, proposalId) {
  const url = "/roadname/guidelines/validate";

  const body = { roads: [] };

  roads.map((r, i) => {
    if (!r.distance || !r.geometry) {
      return false;
    }

    const distanceInMeters = Number.isInteger(r.distance)
      ? r.distance
      : r.distance.substring(0, 2) + "000";

    body.roads.push({
      identifier: i,
      roadName: r.roadname,
      lga: r.lga_name,
      suburbs: r.suburb_name,
      proposalId: proposalId,
      similarityThreshold: 77,
      includeCloseMatches: false,
      includeAllResults: false,
      distance: distanceInMeters,
      debug: false,
      includeSuburbs: true,
      includeSuburbGeometry: false,
      geometry: r.geometry,
      includeCountOnly: true
    });
  });

  return await API.post(apiName, url, {
    body: body
  });
}
