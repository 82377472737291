<template>
  <div
    v-show="legacyAdvertisements.length"
    class="w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 mb-12"
  >
    <showLoading isLoading="fetchingLegacyAdverts">
      <template v-slot:component>
        <div class="w-5/6 mx-auto">
          <div class="py-6 mb-6 border-b-2 self-center">
            <h3 class="font-bold">Legacy Advertisements</h3>
          </div>
        </div>
        <div
          class="w-5/6 mx-auto mb-4"
          :class="[
            index === legacyAdvertisements.length - 1 ? '' : 'dividing-line-h'
          ]"
          v-for="(legacyAd, index) in legacyAdvertisements"
          :key="legacyAd.identifier"
        >
          <div class="flex">
            <div class="text-black font-bold flex-grow">
              {{ legacyAd.ad_title || legacyAd.identifier }}
            </div>
            <div @click="legacyAd.collapsed = !legacyAd.collapsed">
              <div
                ref="collapse"
                class="rotate-transition text-blue-darker"
                :class="[legacyAd.collapsed ? '' : 'rotate-chevron']"
              >
                <font-awesome-icon icon="chevron-circle-down" />
              </div>
            </div>
          </div>
          <transition
            name="collapse"
            @enter="beforeEnterCollapse(index)"
            @leave="beforeLeaveCollapse(index)"
          >
            <div ref="collapse-body" v-show="!legacyAd.collapsed">
              <div
                class="w-full flex flex-row border-gray-200 rounded items-end"
              >
                <div class="text-black flex-grow flex-row">
                  <div class="flex">
                    <div class="flex-grow pb-4 pt-4 w-1/2">
                      <b>Publication Date</b>
                      <p>{{ formatDate(legacyAd.date_publication) }}</p>
                    </div>
                    <div class="flex-grow pb-4 pt-4 w-1/2">
                      <b>Closing Date</b>
                      <p>{{ formatDate(legacyAd.date_closing) }}</p>
                    </div>
                  </div>
                  <div class="flex py-2">
                    <div class="flex-grow w-1/2">
                      <b>Government Advert Id</b>
                      <p>{{ legacyAd.govt_ad_id }}</p>
                    </div>
                    <div class="flex-grow w-1/2">
                      <b>GNB Files</b>
                      <p>{{ legacyAd.gnb_files }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="w-1/3 ml-4 dividing-line-v stretch justify-end text-right"
                >
                  <p class="text-label font-bold pb-2 pt-4">Actions</p>
                  <div
                    v-if="legacyAd.filename_gazette"
                    class="text-blue-button"
                  >
                    <a
                      target="_blank"
                      :href="relativeFileUrl(legacyAd.filename_gazette)"
                      >Download Gazette</a
                    >
                  </div>
                  <div v-else class="text-red font-bold">
                    No Gazette
                  </div>
                  <div v-if="legacyAd.filename_map" class="text-blue-button">
                    <a
                      target="_blank"
                      :href="relativeFileUrl(legacyAd.filename_map)"
                      >Download Map</a
                    >
                  </div>
                  <div v-else class="text-red font-bold">
                    No Map
                  </div>
                  <div
                    ref="download-comment-button"
                    v-if="legacyAd.has_legacy_commentary"
                    class="text-blue-button cursor-pointer"
                  >
                    <a
                      @click="downloadComments(legacyAd.govt_ad_id, index)"
                      :disabled="legacyAd.loadingComments === true"
                      :class="{
                        'cursor-wait': legacyAd.loadingComments === true
                      }"
                      >Download Comments</a
                    >
                  </div>
                  <div v-else class="text-red font-bold">
                    No Comments
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import pnpCRUD from "../../helpers/pnpCRUD";
// import { waitFor } from "vue-wait";
import moment from "moment";
import Vue from "vue";

export default {
  name: "LegacyAdverts",
  data() {
    return {
      legacyAdvertisements: []
    };
  },
  mounted() {
    this.fetchLegacyAdverts();
  },
  props: ["geonameId"],
  methods: {
    fetchLegacyAdverts,
    beforeEnterCollapse,
    beforeLeaveCollapse,
    formatDate,
    downloadComments,
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    }
  }
};

async function fetchLegacyAdverts() {
  this.$wait.start("fetchingLegacyAdverts");
  try {
    const legacyAdvertisementsResponse = await pnpCRUD.getGeonameLegacyAdvertisementsById(
      this.geonameId
    );
    this.legacyAdvertisements = legacyAdvertisementsResponse.map(legacyAd => {
      return Object.assign({}, legacyAd, { collapsed: true });
    });
  } catch (error) {
    this.legacyAdvertisements = [];
    this.$notify({
      group: "toast",
      type: "error",
      title: "Unable to get Legacy Advertisements",
      text: "There was an issue retrieving legacy advertisements"
    });
  } finally {
    this.$wait.end("fetchingLegacyAdverts");
  }
}

function beforeEnterCollapse(index) {
  const content = this.$refs["collapse-body"][index];
  if (content) {
    content.style.maxHeight = content.scrollHeight + "px";
  }
}

function beforeLeaveCollapse(index) {
  const content = this.$refs["collapse-body"][index];
  content.style.maxHeight = 0 + "px";
}

function formatDate(date) {
  return moment(date).format("D MMM, YYYY");
}

async function downloadComments(govt_ad_id, index) {
  Vue.set(this.legacyAdvertisements[index], "loadingComments", true);
  const comments = await pnpCRUD.getGeonameLegacyAdvertisementCommentsByGovtAdId(
    this.geonameId,
    govt_ad_id
  );
  const blob = new Blob([comments], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
  Vue.set(this.legacyAdvertisements[index], "loadingComments", false);
  const a = document.createElement("a");
  // a.download = `${this.legacyAdvertisements[index].ad_title}_comments.pdf`;
  a.href = url;
  a.target = "_blank";
  a.rel = "noopener";
  a.click();
}
</script>

<style scoped>
.standardHeight {
  height: 50px;
}
.stretch {
  align-self: stretch;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.25s;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
}

.rotate-transition {
  transition: transform 0.25s;
  cursor: pointer;
  font-size: 1.5rem;
}

.rotate-chevron {
  transform: rotate(180deg);
}
</style>
