<template>
  <div>
    <button
      @click="openPasswordChange"
      class="button-red-hollow"
      data-cypress="change_password-button"
      type="button"
    >
      Change Password
    </button>

    <modal name="change-password" :height="'auto'" @before-close="beforeClose">
      <div class="flex flex-col justify-between">
        <h2 class="text-title flex-0 px-4">Change Password</h2>
        <div class="dividing-line-h"></div>
        <div class="p-4">
          <label
            class="block text-black text-normal-bold mb-2"
            for="passwordInitial"
            >Current Password</label
          >
          <input
            v-validate="'required'"
            class="w-full input-area"
            id="currentPassword"
            name="currentPassword"
            type="password"
            ref="currentPassword"
            v-model="currentPassword"
            placeholder
            data-cypress="password"
          />
          <span
            class="text-error mt-2"
            v-show="errors.has('currentPassword')"
            data-cypress="current-password-error"
            >{{ errors.first("currentPassword") }}</span
          >

          <label
            class="block text-black text-normal-bold mb-2 mt-4"
            for="newPassword"
            >New Password</label
          >
          <input
            v-validate="'required|passwordPolicy'"
            class="w-full input-area"
            id="newPassword"
            name="newPassword"
            type="password"
            ref="newPassword"
            v-model="newPassword"
            data-cypress="newPassword"
          />
          <span
            class="text-error"
            v-show="errors.has('newPassword')"
            data-cypress="password-error"
            >{{ errors.first("newPassword") }}</span
          >
          <label
            class="block text-black text-normal-bold my-2"
            for="confirmNewPassword"
            >Confirm Password</label
          >
          <input
            v-validate="'required|confirmed:newPassword'"
            class="w-full input-area"
            id="confirmNewPassword"
            name="Confirm Password"
            type="password"
            v-model="confirmNewPassword"
            data-cypress="password-confirm"
          />
          <span class="text-error" v-show="errors.has('Confirm Password')"
            >Passwords do not match.</span
          >
          <div class="self-end my-6 flex justify-between">
            <button
              @click="closePasswordChange"
              class="button-red-hollow"
              data-cypress="cancel-button"
              type="button"
            >
              Cancel
            </button>
            <ButtonWithSpinner
              type="button"
              class="button-blue"
              data-cypress="change-button"
              @click="changePassword"
              :disabled="$wait.any || hasErrors"
              :isSpinning="$wait.is('changing password')"
            >
              Change
            </ButtonWithSpinner>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { changePassword } from "../../helpers/cognito";
import { waitFor } from "vue-wait";

export default {
  props: ["user"],
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    };
  },
  computed: {
    hasErrors: {
      get() {
        return !!this.errors.items.length;
      }
    }
  },
  methods: {
    openPasswordChange() {
      this.$modal.show("change-password", {
        height: "auto"
      });
    },
    closePasswordChange() {
      this.$modal.hide("change-password");
    },
    changePassword: waitFor("changing password", async function() {
      try {
        await changePassword(this.currentPassword, this.newPassword);

        this.$modal.hide("change-password");
      } catch (err) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Cannot change password",
          text:
            "There has been an error changing your password, please try again later."
        });
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }),
    beforeClose() {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    }
  }
};
</script>
