<template>
  <div class="base-container justify-center text-black">
    <NetworkError v-if="fetchError" />
    <div class="w-full text-black" v-if="!fetchError">
      <h1
        class="mb-4 w-full text-title text-black mt-6"
        data-cypress="header-text"
      >
        Road Name Types
      </h1>

      <div class="flex w-full rules-center mb-5">
        <label for class="w-1/2 flex-0">
          <p class="font-bold">Add New Roadname Type</p>
          <input
            type="text"
            name="newType"
            v-model="newType"
            placeholder="Enter the roadname type here..."
            class="w-full mt-2 input-area bg-white"
            data-cypress="add-field"
            v-validate.immediate="'required'"
            data-vv-scope="newType"
            :disabled="$wait.any"
          />
          <FieldErrorMessage :message="duplicateError" />
        </label>

        <input
          type="button"
          value="Add"
          v-on:click="createRoadnameType"
          class="w-1/6 my-8 ml-4 button button-green"
          data-cypress="add-button"
          :disabled="createIsDisabled"
        />
      </div>

      <div class="rounded-lg w-full flex justify-center bg-white">
        <div class="w-9/10 mx-auto flex justify-center">
          <div class="pt-12 pb-16 w-full">
            <div class="flex mb-4">
              <p class="w-1/2 font-bold">Roadname type</p>
              <p class="w-1/3 font-bold ml-4">Active</p>
            </div>
            <Loading-spinner
              v-wait:visible="'loading roadname types'"
              class="flex-0"
            />
            <div
              v-wait:hidden="'loading roadname types'"
              v-for="(type, index) in types"
              v-bind:key="type.identifier"
              class="w-full flex flex-no-wrap rules-center mb-4 flex-col"
            >
              <div class="w-full flex">
                <!-- <div class="flex flex-col flex-0 w-1/2"> -->
                <input
                  type="text"
                  v-model="type.label"
                  :name="`${type.identifier}-type`"
                  class="w-1/2  mr-4 input-area"
                  data-cypress="word-field"
                  :disabled="$wait.any"
                  :data-vv-scope="type.identifier"
                  v-validate="'required'"
                />
                <!-- </div> -->
                <div class="w-1/3">
                  <select
                    class="w-full input-area-appear  "
                    v-model="type.isActive"
                    :disabled="$wait.any"
                    :data-vv-scope="type.identifier"
                    placeholder="Choose state"
                    v-validate="'required'"
                    :name="`${type.identifier}-activeState`"
                  >
                    <option
                      v-for="(state, index) in states"
                      v-bind:value="state.value"
                      v-bind:key="index"
                      >{{ state.label }}</option
                    ></select
                  >
                </div>

                <ButtonWithSpinner
                  class="button-blue  ml-4"
                  type="button"
                  @click="() => saveRoadnameType(type)"
                  :isSpinning="$wait.is(`saving type ${type.identifier}`)"
                  :disabled="isDisabled(type)"
                >
                  <span>Save</span>
                </ButtonWithSpinner>

                <input
                  type="button"
                  value="Delete"
                  @click="showDeleteModal(type)"
                  class="ml-4 w-1/7 cursor-pointer button-red "
                  data-cypress="delete-button"
                  :disabled="$wait.any"
                />
              </div>

              <FieldErrorMessage
                :message="
                  isChangeDuplicate(type)
                    ? `${type.label} is already in the list`
                    : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-8 pb-24">
        <a
          @click="$router.go(-1)"
          class="button-blue ml-auto"
          data-cypress="save-button"
          >Return to Dashboard</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as rnpCruds from "../../helpers/rnpCRUD";
import _orderBy from "lodash.orderby";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import validatorFieldBagMixin from "../../mixins/validator-field-bag-mixin";

export default {
  name: "RoadNameTypesComponent",

  /**
   * Instance mixins
   */
  mixins: [validatorFieldBagMixin],

  /**
   * Local instance data
   */
  data() {
    return {
      newType: "",
      states: [
        {
          value: "",
          label: "Choose active state"
        },
        {
          value: false,
          label: "Inactive"
        },
        {
          value: true,
          label: "Active"
        }
      ],
      types: null,
      fetchError: false,
      duplicateError: ""
    };
  },

  /**
   * Instance child components
   */
  components: {},

  /**
   * The instance was mounted
   */
  async mounted() {
    await this.fetchRoadNameTypes();
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * boolean value to determine whether the log in modal should be shown
     */
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    },

    /**
     * Is creation of a new road name type disabled
     */
    createIsDisabled() {
      return (
        !this.newType || this.isDuplicateType(this.newType) || this.$wait.any
      );
    }
  },

  /**
   * Instance value watchers
   */
  watch: {
    /**
     * Watch for change to the new road name
     */
    newType(val) {
      if (val && this.isDuplicateType(val)) {
        return (this.duplicateError = `There is already a road name type called ${val}`);
      }

      return (this.duplicateError = "");
    },

    /**
     *
     */
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchRoadNameTypes();
      }
    }
  },
  /**
   * Local instance methods
   */
  methods: {
    /**
     * Is entry disabled
     */
    isDisabled(type) {
      return (
        !this.isValidatorScopeValid(type.identifier) ||
        !this.isValidatorScopeDirty(type.identifier) ||
        this.isChangeDuplicate(type) ||
        this.$wait.any
      );
    },

    /**
     * Retrieve the list of road name types from the server
     */
    async fetchRoadNameTypes() {
      try {
        this.$wait.start("loading roadname types");

        const res = await rnpCruds.getRoadnameTypes();

        this.setTypes(res.types);

        this.$wait.end("loading roadname types");
      } catch (e) {
        if (e.response && e.response.status === 500) {
          this.fetchError = true;
        }

        this.$wait.end("loading road types");
      }
    },

    /**
     * Create a new roadname type record
     */
    async createRoadnameType() {
      this.$wait.start("creating roadname type");

      try {
        const res = await rnpCruds.createRoadnameType(this.newType);

        this.setTypes(res.types);

        this.newType = "";

        this.$notify({
          group: "toast",
          type: "success",
          title: "Road type record was created",
          text: "The new roadname type was successfully created."
        });

        this.$wait.end("creating roadname type");
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We could not create the roadname type."
        });
        this.$wait.end("creating roadname type");
      }
    },

    /**
     * Save a new road name type to the server
     */
    async saveRoadnameType(type) {
      this.$wait.start("saving roadname type");
      try {
        const res = await rnpCruds.saveRoadnameType(type);

        this.setTypes(res.types);

        this.$notify({
          group: "toast",
          type: "success",
          title: "Record was updated",
          text: "The roadname type record was successfully updated."
        });

        this.$wait.end("saving roadname type");
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We could not save the roadname type."
        });
        this.$wait.end("saving roadname type");
      }
    },

    /**
     * Delete an existing road name type record
     */
    async deleteRoadnameType(type) {
      this.$wait.start("deleting type");
      try {
        const res = await rnpCruds.deleteRoadnameType(type);

        this.setTypes(res.types);

        this.$notify({
          group: "toast",
          type: "success",
          title: "Record was deleted",
          text: "The roadname type record was successfully deleted."
        });

        this.$wait.end("deleting type");
      } catch (e) {
        // eslint-disable-next-line no-console
        this.$wait.end("deleting type");

        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We couldnt delete the type at this time."
        });
      }
    },

    /**
     * Determine if an entered value already exists in the system
     */
    isDuplicateType(value) {
      if (!this.types || this.types.length === 0) {
        return false;
      }

      return this.types.some(t => {
        if (t.hasOwnProperty("label") && t.label !== "" && t.label !== null) {
          return t.label.toLowerCase() === value.toLowerCase();
        }
      });
    },

    /**
     * Will a change to a record result in a duplicate
     */
    isChangeDuplicate(type) {
      if (!type.label) {
        return false;
      }

      return this.types
        .filter(t => t !== type)
        .some(t => t.label.toLowerCase() === type.label.toLowerCase());
    },

    /**
     * Set the types to the components
     */
    setTypes(types) {
      let ts = types.filter(t => t.label !== null);

      this.types = _orderBy(ts, ["label"], ["asc"]);
    },

    /**
     * Show the delete road type modal
     */
    showDeleteModal(type) {
      this.$modal.show(
        ConfirmDeleteModal,
        {
          type
        },
        {
          height: "auto",
          width: "50%"
        },
        {
          "before-close": async () => {
            await this.fetchRoadNameTypes();
          }
        }
      );
    },

    /**
     * Hide the delete road name type modal
     */
    hideDeleteModal() {
      this.$modal.hide("delete-roadname-type");
    },

    /**
     * Report a toast error in one of the processes
     */
    reportError(message) {
      this.$notify({
        group: "toast",
        type: "error",
        title: "An error occurred",
        text: message
      });
    }
  }
};
</script>

<style>
.bg-primary-green {
  transition-duration: 0.2s;
}
.bg-primary-green:hover {
  @apply bg-primary-green-hover;
}
</style>
