var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAdmin
    ? _c(
        "div",
        { staticClass: "base-container text-black mb-32" },
        [
          _c("editGeoNameConfirmationModal", {
            on: {
              saveGeoName: function ($event) {
                return _vm.saveGeoName(false)
              },
              saveGeoNameWithUpdate: function ($event) {
                return _vm.saveGeoName(true)
              },
            },
          }),
          _vm._m(0),
          _c("showLoading", {
            attrs: { isLoading: "fetchingGeoname" },
            scopedSlots: _vm._u(
              [
                {
                  key: "component",
                  fn: function () {
                    return [
                      _vm.geonameExists
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "w-full flex bg-white mb-8" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-5/6 mx-auto pt-8 pb-16" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "py-6 border-b-2 mb-6 self-center",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "font-bold" },
                                            [_vm._v("Place Name Status")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "status_select",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-red-star",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    " Change Place Name Status "
                                                  ),
                                                ]
                                              ),
                                              _c("norns-select", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mr-4 norns-select",
                                                attrs: {
                                                  disabled:
                                                    _vm.readOnly ||
                                                    !_vm.canChangeStatus,
                                                  multiple: false,
                                                  options: _vm.statusList,
                                                  "close-on-select": true,
                                                  "select-on-tab": true,
                                                  "show-labels": true,
                                                  "hide-selected": true,
                                                  placeholder:
                                                    "Select a Status",
                                                  name: "status",
                                                  "data-cypress":
                                                    "status-field",
                                                  "data-vv-as": "Status",
                                                },
                                                model: {
                                                  value: _vm.geoName.status,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "status",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "geoName.status",
                                                },
                                              }),
                                              _c("fieldErrorMessage", {
                                                attrs: {
                                                  message:
                                                    _vm.errors.first("status"),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "status"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("StatusButton", {
                                                attrs: {
                                                  label: _vm.geoName.dual_named
                                                    ? `Dual - ${_vm.geoName.status}`
                                                    : _vm.geoName.status,
                                                  status: _vm.geoName.status,
                                                  classNames:
                                                    "text-normal-bold",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "date_created",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "date_created"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.date_created,
                                                    expression: "date_created",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-2 input-area",
                                                attrs: {
                                                  disabled: true,
                                                  id: "date_created",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value: _vm.date_created,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.date_created =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "date_modified",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "date_modified"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.date_modified,
                                                    expression: "date_modified",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-2 input-area",
                                                attrs: {
                                                  disabled: true,
                                                  id: "date_modified",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value: _vm.date_modified,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.date_modified =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "previous_name",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "previous_name"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("taggableInput", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tagList:
                                                    _vm.geoName.previous_name,
                                                  placeholder: `Start typing and press enter to add Previous Names...`,
                                                  datacypress: "address-input",
                                                  "data-vv-as":
                                                    "Previous Names",
                                                  label:
                                                    "previous_name_taglist",
                                                },
                                                on: {
                                                  addTag: (previous_name) =>
                                                    _vm.geoName.previous_name.push(
                                                      previous_name
                                                    ),
                                                  removeTag: (index) =>
                                                    _vm.geoName.previous_name.splice(
                                                      index,
                                                      1
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.previous_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "previous_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.previous_name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "gazettal_dates",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "gazettal_dates"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("taggable-date-selector", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tags: _vm.gazettal_dates,
                                                  datacypress: "date-input",
                                                  "data-vv-as": "Gazettal Date",
                                                  label:
                                                    "gazettal_date_taglist",
                                                },
                                                on: {
                                                  addTag: function ($event) {
                                                    return _vm.addNewGazettalDate(
                                                      $event
                                                    )
                                                  },
                                                  removeTag: function ($event) {
                                                    return _vm.setRemovedGazettalDate(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.gazettal_dates,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "gazettal_dates",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.gazettal_dates",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("dual-named", {
                                        attrs: {
                                          geoName: _vm.geoName,
                                          readOnly: _vm.readOnly,
                                        },
                                      }),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mt-3",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "is_suitable_for_public_display"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-row text-normal",
                                          },
                                          [
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-1",
                                                attrs: {
                                                  "data-cypress":
                                                    "is_suitable_for_public_display-no",
                                                  name: "is_suitable_for_public_display-no",
                                                  checkedValue: false,
                                                  disabled: _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName
                                                      .is_suitable_for_public_display,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "is_suitable_for_public_display",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.is_suitable_for_public_display",
                                                },
                                              },
                                              [_vm._v("No")]
                                            ),
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-1",
                                                attrs: {
                                                  name: "is_suitable_for_public_display-yes",
                                                  "data-cypress":
                                                    "is_suitable_for_public_display-yes",
                                                  checkedValue: true,
                                                  disabled: _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName
                                                      .is_suitable_for_public_display,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "is_suitable_for_public_display",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.is_suitable_for_public_display",
                                                },
                                              },
                                              [_vm._v("Yes")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-full flex bg-white mb-8" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-5/6 mx-auto pt-8 pb-16" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "py-6 mb-6 border-b-2 self-center",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "font-bold" },
                                            [_vm._v("Geographical Details")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: {
                                                for: "geographical_name",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "geographical_name"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.geoName.geographical_name,
                                                expression:
                                                  "geoName.geographical_name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass:
                                              "w-full mb-4 input-area",
                                            attrs: {
                                              id: "geographical_name",
                                              type: "text",
                                              name: "geographical_name",
                                              "data-vv-as": "Geographical Name",
                                              disabled: _vm.readOnly,
                                            },
                                            domProps: {
                                              value:
                                                _vm.geoName.geographical_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "geographical_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "geographical_name"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "place_name" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "place_name"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geoName.place_name,
                                                expression:
                                                  "geoName.place_name",
                                              },
                                            ],
                                            staticClass:
                                              "w-full mb-4 input-area",
                                            attrs: {
                                              id: "place_name",
                                              type: "text",
                                              name: "place_name",
                                              disabled: _vm.readOnly,
                                            },
                                            domProps: {
                                              value: _vm.geoName.place_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "place_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "designation" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "designation"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("norns-select", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass:
                                              "w-full mr-4 norns-select",
                                            attrs: {
                                              disabled: _vm.readOnly,
                                              multiple: false,
                                              options: _vm.designations,
                                              "close-on-select": true,
                                              "select-on-tab": true,
                                              "show-labels": true,
                                              "hide-selected": true,
                                              placeholder:
                                                "Select a Designation",
                                              name: "designation",
                                              "data-cypress":
                                                "designation-field",
                                              "data-vv-as": "Designation",
                                            },
                                            model: {
                                              value: _vm.geoName.designation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "designation",
                                                  $$v
                                                )
                                              },
                                              expression: "geoName.designation",
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("designation"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "lga" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-red-star",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel("lga")
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c("norns-select", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mr-4 norns-select",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  multiple: true,
                                                  options: _vm.lgasArray,
                                                  searchable: true,
                                                  "close-on-select": false,
                                                  "select-on-tab": true,
                                                  "show-labels": true,
                                                  "hide-selected": true,
                                                  placeholder:
                                                    "Select an LGA(s)",
                                                  name: "lga",
                                                  "data-cypress": "lga-field",
                                                  "data-vv-as":
                                                    "Local Government Area(s)",
                                                },
                                                model: {
                                                  value: _vm.geoName.lga,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "lga",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "geoName.lga",
                                                },
                                              }),
                                              _c("fieldErrorMessage", {
                                                attrs: {
                                                  message:
                                                    _vm.errors.first("lga"),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "lga-gazettal",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "lga_gazettal"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("norns-select", {
                                                staticClass:
                                                  "w-full mr-4 norns-select",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  multiple: true,
                                                  options: _vm.lgasArray,
                                                  searchable: true,
                                                  "close-on-select": false,
                                                  "select-on-tab": true,
                                                  "show-labels": true,
                                                  "hide-selected": true,
                                                  placeholder:
                                                    "Select an LGA(s)",
                                                  name: "lga-gazettal",
                                                  "data-cypress":
                                                    "lga-gazettal-field",
                                                  "data-vv-as":
                                                    "Local Government Area (LGA) at Gazettal",
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.lga_gazettal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "lga_gazettal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.lga_gazettal",
                                                },
                                              }),
                                              _c("fieldErrorMessage", {
                                                attrs: {
                                                  message:
                                                    _vm.errors.first(
                                                      "lga-gazettal"
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "lga-spatial" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "lga_spatial"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("norns-select", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mr-4 norns-select",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  multiple: true,
                                                  options: _vm.lgasArray,
                                                  searchable: true,
                                                  "close-on-select": false,
                                                  "select-on-tab": true,
                                                  "show-labels": true,
                                                  "hide-selected": true,
                                                  placeholder:
                                                    "Select an LGA(s)",
                                                  name: "lga-spatial",
                                                  "data-cypress":
                                                    "lga-spatial-field",
                                                  "data-vv-as":
                                                    "Local Government Area(s)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.lga_spatial,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "lga_spatial",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.lga_spatial",
                                                },
                                              }),
                                              _c("fieldErrorMessage", {
                                                attrs: {
                                                  message:
                                                    _vm.errors.first(
                                                      "lga-spatial"
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "suburb" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "locality"
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c("taggableInput", {
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tagList: _vm.geoName.locality,
                                                  placeholder: `Start typing and press enter to add a Locality...`,
                                                  datacypress: "address-input",
                                                  label:
                                                    "address_localities_taglist",
                                                },
                                                on: {
                                                  addTag: (locality) =>
                                                    _vm.geoName.locality.push(
                                                      locality
                                                    ),
                                                  removeTag: (index) =>
                                                    _vm.geoName.locality.splice(
                                                      index,
                                                      1
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.geoName.locality,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "locality",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.locality",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "parish" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "parish"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("taggableInput", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tagList: _vm.geoName.parish,
                                                  placeholder: `Start typing and press enter to add a Parish...`,
                                                  datacypress: "address-input",
                                                  "data-vv-as": "Parishes",
                                                  label:
                                                    "address_localities_taglist",
                                                },
                                                on: {
                                                  addTag: (parish) =>
                                                    _vm.geoName.parish.push(
                                                      parish
                                                    ),
                                                  removeTag: (index) =>
                                                    _vm.geoName.parish.splice(
                                                      index,
                                                      1
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.geoName.parish,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "parish",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "geoName.parish",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "county" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "county"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("taggableInput", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tagList: _vm.geoName.county,
                                                  placeholder: `Start typing and press enter to add a County...`,
                                                  datacypress: "address-input",
                                                  "data-vv-as": "Counties",
                                                  label:
                                                    "address_localities_taglist",
                                                },
                                                on: {
                                                  addTag: (county) =>
                                                    _vm.geoName.county.push(
                                                      county
                                                    ),
                                                  removeTag: (index) =>
                                                    _vm.geoName.county.splice(
                                                      index,
                                                      1
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.geoName.county,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "county",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "geoName.county",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "state_electorate",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "electorate"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("taggableInput", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass: "w-full mr-4",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  tagList:
                                                    _vm.geoName.electorate,
                                                  placeholder: `Start typing and press enter to add an Electorate...`,
                                                  datacypress: "address-input",
                                                  "data-vv-as": "Electorates",
                                                  label: "electorate_taglist",
                                                },
                                                on: {
                                                  addTag: (electorate) =>
                                                    _vm.geoName.electorate.push(
                                                      electorate
                                                    ),
                                                  removeTag: (index) =>
                                                    _vm.geoName.electorate.splice(
                                                      index,
                                                      1
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.geoName.electorate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "electorate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.electorate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-1/2 mr-3" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "block text-gray-700 font-bold mb-2",
                                                      attrs: {
                                                        for: "gnb_file_reference",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.mapFieldToLabel(
                                                              "gnb_file_reference"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.geoName
                                                            .gnb_file_reference,
                                                        expression:
                                                          "geoName.gnb_file_reference",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "w-full mb-4 input-area",
                                                    attrs: {
                                                      disabled: _vm.readOnly,
                                                      id: "gnb_file_reference",
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.geoName
                                                          .gnb_file_reference,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.geoName,
                                                          "gnb_file_reference",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "w-1/2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "block text-gray-700 font-bold mb-2",
                                                      attrs: { for: "height" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.mapFieldToLabel(
                                                              "height"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.geoName.height,
                                                        expression:
                                                          "geoName.height",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "w-full mb-4 input-area",
                                                    attrs: {
                                                      disabled: _vm.readOnly,
                                                      id: "height",
                                                      type: "text",
                                                    },
                                                    domProps: {
                                                      value: _vm.geoName.height,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.geoName,
                                                          "height",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "longitude" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "longitude"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.geoName.longitude,
                                                    expression:
                                                      "geoName.longitude",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-4 input-area",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  id: "longitude",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value: _vm.geoName.longitude,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "longitude",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: { for: "latitude" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "latitude"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.geoName.latitude,
                                                    expression:
                                                      "geoName.latitude",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-4 input-area",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  id: "latitude",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value: _vm.geoName.latitude,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "latitude",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "description" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "description"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex flex-col" },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.geoName.description,
                                                    expression:
                                                      "geoName.description",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-4 input-area h-48 flex",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  id: "description",
                                                  name: "description",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.geoName.description,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("fieldErrorMessage", {
                                                attrs: {
                                                  message:
                                                    _vm.errors.first(
                                                      "description"
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-full flex bg-white" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-5/6 mx-auto pb-16 pt-8" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "py-6 mb-6 border-b-2 self-center",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "font-bold" },
                                            [_vm._v("Name Details and Origin")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "origin" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel("origin")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " Please describe the origin of the feature's proposed name in the context of what the name signifies, what culture or background the name derives its meaning or to whom the name commemorates. "
                                            ),
                                          ]),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geoName.origin,
                                                expression: "geoName.origin",
                                              },
                                            ],
                                            staticClass:
                                              "w-full input-area h-48",
                                            attrs: {
                                              disabled: _vm.readOnly,
                                              id: "origin",
                                            },
                                            domProps: {
                                              value: _vm.geoName.origin,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "origin",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `origin_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .origin_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "origin",
                                              stateProp:
                                                "origin_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "origin_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("origin"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `marked_map_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .marked_map_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "marked_map",
                                              stateProp:
                                                "marked_map_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.marked_map_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("marked_map"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `marked_photo_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .marked_photo_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "marked_photo",
                                              stateProp:
                                                "marked_photo_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.marked_photo_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "marked_photo"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `source_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .source_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "source",
                                              stateProp:
                                                "source_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.source_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("source"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `pending_endorsement_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .pending_endorsement_reference_document,
                                              drafted: true,
                                              stateKey: "pending_endorsement",
                                              stateProp:
                                                "pending_endorsement_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.pending_endorsement_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message: _vm.errors.first(
                                                "pending_endorsement"
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `public_support_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .public_support_reference_document,
                                              drafted: true,
                                              stateKey: "public_support",
                                              stateProp:
                                                "public_support_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.public_support_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "public_support"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `public_consultation_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .public_consultation_reference_document,
                                              drafted: true,
                                              stateKey: "public_consultation",
                                              stateProp:
                                                "public_consultation_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.public_consultation_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "public_support"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `pronunciation_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .pronunciation_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "pronunciation",
                                              stateProp:
                                                "pronunciation_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.pronunciation_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "pronunciation"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-2",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "spelling" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "pronunciation",
                                                    true
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.geoName.pronunciation,
                                                expression:
                                                  "geoName.pronunciation",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              disabled: _vm.readOnly,
                                              id: "spelling",
                                              type: "text",
                                            },
                                            domProps: {
                                              value: _vm.geoName.pronunciation,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "pronunciation",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-2",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "meaning" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "meaning",
                                                    true
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geoName.meaning,
                                                expression: "geoName.meaning",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              disabled: _vm.readOnly,
                                              id: "meaning",
                                            },
                                            domProps: {
                                              value: _vm.geoName.meaning,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "meaning",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-2",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-2",
                                              attrs: { for: "history" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.mapFieldToLabel("history")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geoName.history,
                                                expression: "geoName.history",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              disabled: _vm.readOnly,
                                              id: "history",
                                            },
                                            domProps: {
                                              value: _vm.geoName.history,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "history",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-2",
                                        },
                                        [
                                          _c("uploadScopedWithTypes", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `historical_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .historical_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "historical",
                                              stateProp:
                                                "historical_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.historical_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                              fileTypeChanged: (payload) =>
                                                _vm.updateHistoricalFileType(
                                                  payload
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("historical"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mt-8",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "aboriginal_name"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-row text-normal",
                                          },
                                          [
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-1",
                                                attrs: {
                                                  "data-cypress":
                                                    "aboriginal_name-no",
                                                  name: "aboriginal_name-no",
                                                  checkedValue: false,
                                                  disabled:
                                                    !!_vm.geoName
                                                      .multicultural ||
                                                    _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.aboriginal_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "aboriginal_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.aboriginal_name",
                                                },
                                              },
                                              [_vm._v("No")]
                                            ),
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-1",
                                                attrs: {
                                                  name: "aboriginal_name-yes",
                                                  "data-cypress":
                                                    "aboriginal_name-yes",
                                                  checkedValue: true,
                                                  disabled:
                                                    !!_vm.geoName
                                                      .multicultural ||
                                                    _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.aboriginal_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "aboriginal_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.aboriginal_name",
                                                },
                                              },
                                              [_vm._v("Yes")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "w-full flex mt-4" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "origin_county",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mapFieldToLabel(
                                                          "aboriginal_country"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.geoName
                                                        .aboriginal_country,
                                                    expression:
                                                      "geoName.aboriginal_country",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-4 input-area",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  id: "origin_county",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.geoName
                                                      .aboriginal_country,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "aboriginal_country",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "md:w-1/2 justify-center flex-col ml-4 md:mb-4",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "block text-gray-700 font-bold mb-2",
                                                  attrs: {
                                                    for: "language_group",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mapFieldToLabel(
                                                        "aboriginal_language"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.geoName
                                                        .aboriginal_language,
                                                    expression:
                                                      "geoName.aboriginal_language",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full mb-4 input-area",
                                                attrs: {
                                                  disabled: _vm.readOnly,
                                                  id: "language_group",
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.geoName
                                                      .aboriginal_language,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "aboriginal_language",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "p",
                                          { staticClass: "text-normal-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    "multicultural"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-row text-normal",
                                          },
                                          [
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-2",
                                                attrs: {
                                                  "data-cypress":
                                                    "multicultural-no",
                                                  name: "multicultural-no",
                                                  checkedValue: false,
                                                  disabled:
                                                    !!_vm.geoName
                                                      .aboriginal_name ||
                                                    _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.multicultural,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "multicultural",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.multicultural",
                                                },
                                              },
                                              [_vm._v("No")]
                                            ),
                                            _c(
                                              "RadioButton",
                                              {
                                                staticClass:
                                                  "flex-0 mr-6 mt-3 mb-2",
                                                attrs: {
                                                  name: "multicultural-yes",
                                                  "data-cypress":
                                                    "multicultural-yes",
                                                  checkedValue: true,
                                                  disabled:
                                                    !!_vm.geoName
                                                      .aboriginal_name ||
                                                    _vm.readOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.geoName.multicultural,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.geoName,
                                                      "multicultural",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "geoName.multicultural",
                                                },
                                              },
                                              [_vm._v("Yes")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mt-4",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-3 mt-2",
                                              attrs: {
                                                for: "geographical_name",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mapFieldToLabel(
                                                      "map_cma"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geoName.map_cma,
                                                expression: "geoName.map_cma",
                                              },
                                            ],
                                            staticClass:
                                              "w-full mb-4 input-area",
                                            attrs: {
                                              id: "map_cma",
                                              type: "text",
                                              name: "map_cma",
                                              disabled: _vm.readOnly,
                                            },
                                            domProps: {
                                              value: _vm.geoName.map_cma,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.geoName,
                                                  "map_cma",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mx-auto" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 font-bold mb-4 mt-2",
                                              attrs: { for: "legacy_map" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.mapFieldToLabel(
                                                      "legacy_map_data"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "w-full flex mb-2" },
                                            [
                                              _c("p", { staticClass: "w-3%" }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-normal-bold w-1/2",
                                                },
                                                [_vm._v("Map Name")]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-normal-bold w-1/2",
                                                },
                                                [_vm._v("Map Number")]
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.legacy_map_data,
                                            function (map_data, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "w-full flex items-center mt-3",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-grey text-sub-title w-3%",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    staticClass:
                                                      "rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center w-1/2",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        map_data.legacy_map_name,
                                                    },
                                                  }),
                                                  _c("input", {
                                                    staticClass:
                                                      "rounded p-4 mr-4 text-normal text-grey-text bg-grey-input self-center w-1/4",
                                                    attrs: {
                                                      type: "text",
                                                      disabled: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        map_data.legacy_map_reference,
                                                    },
                                                  }),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "justify-center p-4 items-center cursor-pointer w-1/4",
                                                      class: {
                                                        redButtonFaded:
                                                          _vm.readOnly,
                                                        redButtonSolid:
                                                          !_vm.readOnly,
                                                      },
                                                      attrs: {
                                                        disabled: _vm.readOnly,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeLegacyMapData(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Remove ")]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-full flex items-center mt-3",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-grey text-sub-title w-3%",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.legacy_map_data
                                                          .length + 1 || ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.new_legacy_map_entry
                                                        .legacy_map_name,
                                                    expression:
                                                      "new_legacy_map_entry.legacy_map_name",
                                                  },
                                                ],
                                                staticClass:
                                                  "rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center w-1/2",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Enter Name...",
                                                  "data-cypress":
                                                    "map-name-input",
                                                  disabled: _vm.readOnly,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.new_legacy_map_entry
                                                      .legacy_map_name,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.new_legacy_map_entry,
                                                      "legacy_map_name",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.new_legacy_map_entry
                                                        .legacy_map_reference,
                                                    expression:
                                                      "new_legacy_map_entry.legacy_map_reference",
                                                  },
                                                ],
                                                staticClass:
                                                  "rounded p-4 mr-4 text-normal text-grey-text bg-grey-input self-center w-1/4",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Enter Number...",
                                                  "data-cypress":
                                                    "map-number-input",
                                                  disabled: _vm.readOnly,
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.new_legacy_map_entry
                                                      .legacy_map_reference,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.new_legacy_map_entry,
                                                      "legacy_map_reference",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "justify-center p-4 items-center cursor-pointer w-1/4",
                                                  class: {
                                                    greenButtonFaded:
                                                      _vm.readOnly ||
                                                      !_vm.new_legacy_map_entry
                                                        .legacy_map_reference ||
                                                      !_vm.new_legacy_map_entry
                                                        .legacy_map_name,
                                                    greenButtonSolid:
                                                      !_vm.readOnly &&
                                                      !!_vm.new_legacy_map_entry
                                                        .legacy_map_reference &&
                                                      !!_vm.new_legacy_map_entry
                                                        .legacy_map_name,
                                                  },
                                                  attrs: {
                                                    "data-cypress":
                                                      "add-map-button",
                                                    disabled:
                                                      _vm.readOnly ||
                                                      !_vm.new_legacy_map_entry
                                                        .legacy_map_reference ||
                                                      !_vm.new_legacy_map_entry
                                                        .legacy_map_name,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.addNewLegacyMapEntry,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Add map information "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "w-full flex bg-white" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-5/6 mx-auto pb-16 pt-8" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "py-6 mb-6 border-b-2 self-center",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "font-bold" },
                                            [_vm._v("GNB Attachments")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `advert_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .advert_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "advert",
                                              stateProp:
                                                "advert_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "advert_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("advert"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `gazette_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .gazette_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "gazette",
                                              stateProp:
                                                "gazette_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "gazette_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("gazette"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `general_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .general_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "general",
                                              stateProp:
                                                "general_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "general_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("general"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `minister_briefing_note_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .minister_briefing_note_reference_document,
                                              drafted: true,
                                              stateKey:
                                                "minister_briefing_note",
                                              stateProp:
                                                "minister_briefing_note_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "minister_briefing_note_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message: _vm.errors.first(
                                                "minister_briefing_note"
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `media_release_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .media_release_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "media_release",
                                              stateProp:
                                                "media_release_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "media_release_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "media_release"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `official_map_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .official_map_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "official_map",
                                              stateProp:
                                                "official_map_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) =>
                                                _vm.geoName.official_map_reference_document.splice(
                                                  fileIndex,
                                                  1
                                                ),
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "official_map"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full justify-center flex-col mb-4",
                                        },
                                        [
                                          _c("uploadScoped", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "min_value:1",
                                                expression: "'min_value:1'",
                                              },
                                            ],
                                            staticClass: "mt-4",
                                            attrs: {
                                              "data-cypress": "upload-photo",
                                              label: _vm.mapFieldToLabel(
                                                `evaluation_report_reference_document`,
                                                true
                                              ),
                                              important: false,
                                              files:
                                                _vm.geoName
                                                  .evaluation_report_reference_document,
                                              showPublicDisplay: true,
                                              changeVisibility:
                                                _vm.changeFileVisibility,
                                              drafted: true,
                                              stateKey: "evaluation_report",
                                              stateProp:
                                                "evaluation_report_reference_document",
                                              uploadFile:
                                                _vm.uploadFileForComponent,
                                              disabled: _vm.readOnly,
                                            },
                                            on: {
                                              deleteFile: (fileIndex) => {
                                                _vm.deleteFile(
                                                  "evaluation_report_reference_document",
                                                  fileIndex
                                                )
                                              },
                                            },
                                          }),
                                          _c("fieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first(
                                                  "evaluation_report"
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex justify-between w-full" },
                                [
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass:
                                        "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                                      attrs: {
                                        disabled:
                                          _vm.$wait.is("savingGeoname") ||
                                          _vm.readOnly,
                                        "data-cypress": "delete-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel Changes")]
                                  ),
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass:
                                        "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                                      attrs: {
                                        disabled:
                                          !_vm.isFormValid ||
                                          _vm.readOnly ||
                                          _vm.$wait.is("savingGeoname"),
                                        isSpinning:
                                          _vm.$wait.is("savingGeoname"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmChanges()
                                        },
                                      },
                                    },
                                    [_vm._v("Confirm Changes")]
                                  ),
                                ],
                                1
                              ),
                              _c("LegacyAdverts", {
                                attrs: { geonameId: _vm.geonameId },
                              }),
                              _c("EditHistory", {
                                attrs: {
                                  geonameId: _vm.geonameId,
                                  fetchGeonameHistory: _vm.fetchGeonameHistory,
                                  history: _vm.history,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              160981960
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "justify-center w-full block" }, [
      _c("div", { staticClass: "flex w-full mb-3" }, [
        _c("h1", [_vm._v("Edit Place Name")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }