var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex input-area",
      class: { disabled: _vm.$attrs.disabled },
    },
    [
      _c("input", {
        staticClass: "w-full flex-grow rounded-lg input-autocomplete",
        attrs: {
          "data-cypress": "select-entity-input",
          list: "entityList",
          name: "entityList",
          type: "text",
          disabled: _vm.$attrs.disabled,
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.selectEntity },
      }),
      _c(
        "datalist",
        { attrs: { id: "entityList" } },
        _vm._l(_vm.entityListFiltered, function (item) {
          return _c("option", { key: item.index }, [_vm._v(_vm._s(item.name))])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }