var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container justify-center text-black" },
    [
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c("div", { staticClass: "w-full text-black" }, [
            _c(
              "h1",
              {
                staticClass: "mb-4 w-full text-title text-black mt-6",
                attrs: { "data-cypress": "header-text" },
              },
              [_vm._v(" Road Name Types ")]
            ),
            _c("div", { staticClass: "flex w-full rules-center mb-5" }, [
              _c(
                "label",
                { staticClass: "w-1/2 flex-0", attrs: { for: "" } },
                [
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v("Add New Roadname Type"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newType,
                        expression: "newType",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value: "required",
                        expression: "'required'",
                        modifiers: { immediate: true },
                      },
                    ],
                    staticClass: "w-full mt-2 input-area bg-white",
                    attrs: {
                      type: "text",
                      name: "newType",
                      placeholder: "Enter the roadname type here...",
                      "data-cypress": "add-field",
                      "data-vv-scope": "newType",
                      disabled: _vm.$wait.any,
                    },
                    domProps: { value: _vm.newType },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newType = $event.target.value
                      },
                    },
                  }),
                  _c("FieldErrorMessage", {
                    attrs: { message: _vm.duplicateError },
                  }),
                ],
                1
              ),
              _c("input", {
                staticClass: "w-1/6 my-8 ml-4 button button-green",
                attrs: {
                  type: "button",
                  value: "Add",
                  "data-cypress": "add-button",
                  disabled: _vm.createIsDisabled,
                },
                on: { click: _vm.createRoadnameType },
              }),
            ]),
            _c(
              "div",
              { staticClass: "rounded-lg w-full flex justify-center bg-white" },
              [
                _c(
                  "div",
                  { staticClass: "w-9/10 mx-auto flex justify-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "pt-12 pb-16 w-full" },
                      [
                        _vm._m(0),
                        _c("Loading-spinner", {
                          directives: [
                            {
                              name: "wait",
                              rawName: "v-wait:visible",
                              value: "loading roadname types",
                              expression: "'loading roadname types'",
                              arg: "visible",
                            },
                          ],
                          staticClass: "flex-0",
                        }),
                        _vm._l(_vm.types, function (type, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "wait",
                                  rawName: "v-wait:hidden",
                                  value: "loading roadname types",
                                  expression: "'loading roadname types'",
                                  arg: "hidden",
                                },
                              ],
                              key: type.identifier,
                              staticClass:
                                "w-full flex flex-no-wrap rules-center mb-4 flex-col",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "w-full flex" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: type.label,
                                        expression: "type.label",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-1/2 mr-4 input-area",
                                    attrs: {
                                      type: "text",
                                      name: `${type.identifier}-type`,
                                      "data-cypress": "word-field",
                                      disabled: _vm.$wait.any,
                                      "data-vv-scope": type.identifier,
                                    },
                                    domProps: { value: type.label },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          type,
                                          "label",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "w-1/3" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: type.isActive,
                                            expression: "type.isActive",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "w-full input-area-appear",
                                        attrs: {
                                          disabled: _vm.$wait.any,
                                          "data-vv-scope": type.identifier,
                                          placeholder: "Choose state",
                                          name: `${type.identifier}-activeState`,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              type,
                                              "isActive",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.states,
                                        function (state, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: index,
                                              domProps: { value: state.value },
                                            },
                                            [_vm._v(_vm._s(state.label))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass: "button-blue ml-4",
                                      attrs: {
                                        type: "button",
                                        isSpinning: _vm.$wait.is(
                                          `saving type ${type.identifier}`
                                        ),
                                        disabled: _vm.isDisabled(type),
                                      },
                                      on: {
                                        click: () => _vm.saveRoadnameType(type),
                                      },
                                    },
                                    [_c("span", [_vm._v("Save")])]
                                  ),
                                  _c("input", {
                                    staticClass:
                                      "ml-4 w-1/7 cursor-pointer button-red",
                                    attrs: {
                                      type: "button",
                                      value: "Delete",
                                      "data-cypress": "delete-button",
                                      disabled: _vm.$wait.any,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDeleteModal(type)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.isChangeDuplicate(type)
                                    ? `${type.label} is already in the list`
                                    : "",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "flex mt-8 pb-24" }, [
              _c(
                "a",
                {
                  staticClass: "button-blue ml-auto",
                  attrs: { "data-cypress": "save-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("Return to Dashboard")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex mb-4" }, [
      _c("p", { staticClass: "w-1/2 font-bold" }, [_vm._v("Roadname type")]),
      _c("p", { staticClass: "w-1/3 font-bold ml-4" }, [_vm._v("Active")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }