<template>
  <div class="mb-4 w-1/2 flex-grow filter">
    <span>{{ label }}</span>
    <div class="flex flex-row mr-4">
      <v-select
        v-if="type === 'text'"
        @input="value => $emit('input', value)"
        :value="val"
        class="bg-grey-input flex-grow"
        :options="options"
        :disabled="$attrs.disabled"
        :multiple="multiple"
      ></v-select>
      <v-select
        v-if="type === 'object'"
        :reduce="opt => opt.value"
        label="label"
        @input="value => $emit('input', value)"
        :value="val"
        class="bg-grey-input flex-grow"
        :options="options"
        :disabled="$attrs.disabled"
      ></v-select>
      <v-datepicker
        class="bg-grey-input flex-grow date-filter"
        v-if="type === 'date'"
        mode="single"
        :value="dateVal"
        :name="label"
        @input="dateSelected"
      >
        <input
          type="text"
          :value="dateVal"
          placeholder="Select date"
          class="w-full mr-4 input-area date-filter"
          v-on:keydown="e => e.preventDefault()"
          name="finalisedDateEntry"
        />
      </v-datepicker>
      <button
        v-if="!!val"
        class="ml-2 px-4 button-date-picker"
        v-bind:class="{
          redButtonFaded: $attrs.disabled,
          redButtonSolid: !$attrs.disabled
        }"
        :disabled="$attrs.disabled"
        @click="$emit('clear')"
      >
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DataTableFilter",
  props: ["value", "options", "label", "type", "name", "multiple"],
  computed: {
    val: {
      get() {
        return this.value ? this.value.value : this.value;
      }
    },
    dateVal: {
      get() {
        if (this.value && this.value.value) {
          if (typeof this.value.value === "string") {
            return this.value.value;
          }
          return moment(this.value.value).format("DD/MM/YYYY");
        } else {
          return null;
        }
      }
    }
  },
  methods: {
    dateSelected: function(value) {
      if (value && typeof value !== "string") {
        value = moment(value).format("DD/MM/YYYY");
      }
      this.$emit("input", value);
    }
  }
};
</script>

<style>
.filter {
  flex: 0 0 25%;
}

.v-select {
  min-height: 40px !important;
}

.button-date-picker,
.date-filter {
  max-height: 40px !important;
}

</style>
