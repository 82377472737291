<template>
  <div class="base-container justify-center text-black">
    <NetworkError v-if="fetchError" />
    <div class="w-full text-black" v-if="!fetchError">
      <h1
        v-if="type == 'pnr'"
        class="mb-4 w-full text-title text-black mt-6"
        data-cypress="header-text"
      >
        Place Name Rules
      </h1>
      <h1
        v-else
        class="mb-4 w-full text-title text-black mt-6"
        data-cypress="header-text"
      >
        Road Name Rules
      </h1>

      <div class="flex w-full rules-center mb-5">
        <label for class="w-1/2 flex-0">
          <p class="font-bold">Add Disallowed Word</p>
          <input
            type="text"
            name="newWord"
            id="disallowed"
            v-model="newWord"
            placeholder="Enter the disallowed word here..."
            class="w-full mt-2 input-area bg-white"
            data-cypress="add-field"
            v-validate.immediate="'required'"
            data-vv-scope="newRule"
            :disabled="$wait.any"
          />
          <FieldErrorMessage :message="duplicateError" />
        </label>

        <label for class="ml-4 flex-0 w-1/3">
          <p class="mb-2 font-bold">Reason</p>
          <select
            v-model="newCategory"
            name="newCategory"
            class=" input-area-appear w-full bg-white self-center"
            data-cypress="reason-select"
            v-validate.immediate.continues="'required'"
            data-vv-scope="newRule"
            :disabled="$wait.any"
          >
            <option value="" disabled>
              <div class="my-4 py-4">Select a rule</div>
            </option>
            <option
              v-for="(reason, index) in categories"
              v-bind:key="index"
              :value="reason.id || reason.identifier"
              class="my-4 input-area-appear bg-white"
            >
              <p class="input-area-appear bg-white">{{ reason.category }}</p>
            </option>
          </select>
        </label>

        <input
          type="button"
          value="Add"
          v-on:click="saveRule"
          class="w-1/6 my-8 ml-4 button button-green"
          data-cypress="add-button"
          :disabled="isDisabled('newRule')"
        />
      </div>

      <div class="rounded-lg w-full flex justify-center bg-white">
        <div class="w-9/10 mx-auto flex justify-center">
          <div class="pt-12 pb-16 w-full">
            <div class="flex mb-4">
              <p class="w-1/2 font-bold">Disallowed Word</p>
              <p class="w-1/3 font-bold ml-4">Reason</p>
            </div>
            <Loading-spinner v-wait:visible="'loading rules'" class="flex-0" />
            <div
              v-wait:hidden="'loading rules'"
              v-for="(rule, index) in rules"
              v-bind:key="rule.id || rule.identifier"
              class="w-full flex flex-no-wrap rules-center mb-4 flex-col"
            >
              <div class="w-full flex">
                <!-- <div class="flex flex-col flex-0 w-1/2"> -->
                <input
                  type="text"
                  v-model="rule.word"
                  :name="`${rule.identifier}-word`"
                  class="w-1/2  mr-4 input-area"
                  data-cypress="word-field"
                  :disabled="$wait.any"
                  :data-vv-scope="rule.identifier"
                  v-validate="'required'"
                />
                <!-- </div> -->
                <select
                  class="w-1/3 input-area-appear  "
                  v-model="rule.banned_word_category_id"
                  :disabled="$wait.any"
                  :data-vv-scope="rule.identifier"
                  v-validate="'required'"
                  :name="`${rule.identifier}-category`"
                >
                  <option
                    v-for="(reason, index) in categories"
                    v-bind:value="reason.identifier"
                    v-bind:key="index"
                    >{{ reason.category }}</option
                  ></select
                >

                <ButtonWithSpinner
                  class="button-blue  ml-4"
                  type="button"
                  @click="() => updateRule(rule)"
                  :isSpinning="$wait.is(`saving rule ${rule.identifier}`)"
                  :disabled="isDisabled(rule.identifier)"
                >
                  <span>Save</span>
                </ButtonWithSpinner>
                <input
                  type="button"
                  value="Delete"
                  @click="deleteRule(rule)"
                  class="ml-4 w-1/7 cursor-pointer button-red "
                  data-cypress="delete-button"
                  :disabled="$wait.any"
                />
              </div>
              <FieldErrorMessage
                :message="
                  isChangeDuplicate(rule)
                    ? `${rule.word} is already in the list`
                    : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-8 pb-24">
        <a
          @click="$router.go(-1)"
          class="button-blue ml-auto"
          data-cypress="save-button"
          >Return to Dashboard</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as rulesCruds from "../../helpers/namingRulesCRUD";
import validatorFieldBagMixin from "../../mixins/validator-field-bag-mixin";

export default {
  name: "RoadAndPlaceNameRules",
  mixins: [validatorFieldBagMixin],
  data() {
    return {
      newWord: "",
      newCategory: "",
      rules: [],
      categories: [],
      type: this.$route.meta.isPlaceNameRoute ? "pnr" : "rnr",
      fetchError: false,
      duplicateError: ""
    };
  },
  computed: {
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    }
  },
  watch: {
    newWord(val) {
      if (val && this.isDuplicateRule(val)) {
        this.duplicateError = `${val} is already in the banned words list`;
      } else {
        this.duplicateError = "";
      }
    },
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchRulesLists();
      }
    }
  },
  methods: {
    isDisabled(scope) {
      return (
        !this.isValidatorScopeValid(scope) ||
        !this.isValidatorScopeDirty(scope) ||
        this.$wait.any
      );
    },
    async fetchRulesLists() {
      try {
        this.$wait.start("loading rules");
        this.rules = await rulesCruds.getRulesList(this.type);
        this.categories = await rulesCruds.getRulesCategories(this.type);

        this.$wait.end("loading rules");
      } catch (e) {
        if (e.response && e.response.status === 500) {
          this.fetchError = true;
        }

        this.$wait.end("loading rules");
      }
    },
    async saveRule() {
      this.$wait.start("saving rule");
      try {
        await rulesCruds.saveRuleToDb(
          {
            word: this.newWord,
            banned_word_category_id: this.newCategory
          },
          this.type
        );
        await this.fetchRulesLists();
        this.newWord = "";
        this.newCategory = "";
        this.$wait.end("saving rule");
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We couldn't save the rule at this time."
        });
        this.$wait.end("saving rule");
      }
    },
    async updateRule(rule) {
      this.$wait.start(`saving rule ${rule.identifier}`);
      try {
        await rulesCruds.updateRule(rule, this.type);
        this.$wait.end(`saving rule ${rule.identifier}`);
      } catch (e) {
        // eslint-disable-next-line no-console
        this.$wait.end(`saving rule ${rule.identifier}`);
        console.error(e);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We couldnt save the rule at this time."
        });
      }
    },

    async deleteRule(rule) {
      this.$wait.start(`saving rule ${rule.identifier}`);
      try {
        await rulesCruds.deleteRuleFromDb(rule, this.type);
        await this.fetchRulesLists();
        this.$wait.end(`saving rule ${rule.identifier}`);
      } catch (e) {
        // eslint-disable-next-line no-console
        this.$wait.end(`saving rule ${rule.identifier}`);
        console.error(e);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Server Error",
          text: "We couldnt delete the rule at this time."
        });
      }
    },

    isDuplicateRule(word) {
      return this.rules.some(
        rule => rule.word.toLowerCase() === word.toLowerCase()
      );
    },
    isChangeDuplicate(rule) {
      return this.rules
        .filter(r => r !== rule)
        .some(r => r.word.toLowerCase() === rule.word.toLowerCase());
    }
  },
  async mounted() {
    await this.fetchRulesLists();
  }
};
</script>

<style>
.bg-primary-green {
  transition-duration: 0.2s;
}
.bg-primary-green:hover {
  @apply bg-primary-green-hover;
}
</style>
