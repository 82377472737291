var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c("section", { staticClass: "justify-center w-full block" }, [
        _c("div", { staticClass: "flex w-full mb-3" }, [
          _c(
            "a",
            {
              staticClass: "cursor-pointer flex self-center",
              attrs: { "data-cypress": "back-arrow" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _vm.nameStatus !== null
                ? _c("BackArrowWithTitle", {
                    attrs: { title: _vm.actionType + " Road Name" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c(
            "ShowLoading",
            { attrs: { isLoading: "setting up road name proposal page" } },
            [
              _c("template", { slot: "component" }, [
                _c("div", [
                  _c("div", { staticClass: "w-full flex bg-white" }, [
                    _c(
                      "div",
                      { staticClass: "w-5/6 mx-auto py-16" },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.unapproved_gazette,
                                  expression: "unapproved_gazette",
                                },
                              ],
                              staticClass:
                                "text-orange issue border-orange border-2 font-bold py-1 px-4 rounded-full ml-auto",
                            },
                            [_vm._v(" Unapproved Gazette ")]
                          ),
                        ]),
                        _c("p", { staticClass: "font-bold" }, [
                          _c("span", { staticClass: "text-red-star" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("Proposal Type "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-row text-normal" },
                          [
                            _c(
                              "RadioButton",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      "required|included:Public,Non-Dedicated,Pre-Approval",
                                    expression:
                                      "\n                  'required|included:Public,Non-Dedicated,Pre-Approval'\n                ",
                                  },
                                ],
                                staticClass: "flex-0 mr-6 mt-3 mb-1",
                                attrs: {
                                  name: "Classification",
                                  "data-cypress": "public-proposal",
                                  checkedValue: "Public",
                                  disabled:
                                    _vm.isDisabled ||
                                    _vm.nameStatus === "Proposed" ||
                                    _vm.nameStatus === "Clarification",
                                  "data-vv-as": "Road Name Classification",
                                },
                                model: {
                                  value: _vm.proposalType,
                                  callback: function ($$v) {
                                    _vm.proposalType = $$v
                                  },
                                  expression: "proposalType",
                                },
                              },
                              [_vm._v("Public Road")]
                            ),
                            _c(
                              "RadioButton",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      "required|included:Public,Non-Dedicated,Pre-Approval",
                                    expression:
                                      "\n                  'required|included:Public,Non-Dedicated,Pre-Approval'\n                ",
                                  },
                                ],
                                staticClass: "flex-0 mr-6 mt-3 mb-1",
                                attrs: {
                                  "data-cypress": "nondedicated-proposal",
                                  name: "Classification",
                                  checkedValue: "Non-Dedicated",
                                  disabled:
                                    _vm.isDisabled ||
                                    _vm.nameStatus === "Proposed" ||
                                    _vm.nameStatus === "Clarification",
                                  "data-vv-as": "Road Name Classification",
                                },
                                model: {
                                  value: _vm.proposalType,
                                  callback: function ($$v) {
                                    _vm.proposalType = $$v
                                  },
                                  expression: "proposalType",
                                },
                              },
                              [_vm._v("Non-Dedicated/Private Road")]
                            ),
                            _vm.nameStatus != "Pre-Approved"
                              ? _c(
                                  "RadioButton",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          "required|included:Public,Non-Dedicated,Pre-Approval",
                                        expression:
                                          "\n                  'required|included:Public,Non-Dedicated,Pre-Approval'\n                ",
                                      },
                                    ],
                                    staticClass: "flex-0 mr-6 mt-3 mb-1",
                                    attrs: {
                                      "data-cypress": "preapproved-proposal",
                                      name: "Classification",
                                      checkedValue: "Pre-Approval",
                                      disabled:
                                        _vm.isDisabled ||
                                        _vm.nameStatus === "Proposed" ||
                                        _vm.nameStatus === "Clarification",
                                      "data-vv-as": "Road Name Classification",
                                    },
                                    model: {
                                      value: _vm.proposalType,
                                      callback: function ($$v) {
                                        _vm.proposalType = $$v
                                      },
                                      expression: "proposalType",
                                    },
                                  },
                                  [_vm._v("Road Name Pre-Approval")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("FieldErrorMessage", {
                          attrs: {
                            message: _vm.errors.first("Classification"),
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex content-center items-start mt-8",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-4 w-1/2 items-start" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-red-star" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("Local Government Area "),
                                  ]
                                ),
                                _c(
                                  "norns-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mr-4 norns-select",
                                    attrs: {
                                      disabled:
                                        _vm.nameStatus === "Pre-Approved" ||
                                        _vm.isDisabled ||
                                        _vm.$wait.is("evaluatingName"),
                                      multiple: false,
                                      options: _vm.lgasLimited,
                                      searchable: true,
                                      "close-on-select": true,
                                      placeholder: "Select LGA...",
                                      name: "LGA",
                                      "data-cypress": "lga-select",
                                      "data-vv-as":
                                        "Local Government Authority",
                                      "select-on-tab": true,
                                    },
                                    model: {
                                      value: _vm.lgaName,
                                      callback: function ($$v) {
                                        _vm.lgaName = $$v
                                      },
                                      expression: "lgaName",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "noOptions" },
                                        slot: "noOptions",
                                      },
                                      [_vm._v("Loading...")]
                                    ),
                                  ]
                                ),
                                _c("FieldErrorMessage", {
                                  attrs: { message: _vm.errors.first("LGA") },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "pl-4 w-1/2 flex flex-col" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-red-star" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("Address Locality (Suburb) "),
                                  ]
                                ),
                                _c(
                                  "norns-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mr-4 norns-select",
                                    attrs: {
                                      disabled:
                                        !_vm.lgaName ||
                                        _vm.nameStatus === "Pre-Approved" ||
                                        _vm.isDisabled ||
                                        _vm.$wait.is("evaluatingName"),
                                      multiple: true,
                                      options: _vm.suburbOptions,
                                      searchable: true,
                                      "close-on-select": false,
                                      "show-labels": true,
                                      "hide-selected": true,
                                      placeholder: "Pick a Suburb",
                                      name: "suburb",
                                      "data-cypress": "suburb-field",
                                      "data-vv-as": "Suburb",
                                      "select-on-tab": true,
                                    },
                                    model: {
                                      value: _vm.suburbName,
                                      callback: function ($$v) {
                                        _vm.suburbName = $$v
                                      },
                                      expression: "suburbName",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "noOptions" },
                                        slot: "noOptions",
                                      },
                                      [_vm._v("Loading...")]
                                    ),
                                  ]
                                ),
                                _c("FieldErrorMessage", {
                                  attrs: {
                                    message: _vm.errors.first("suburb"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "w-full flex items-start mt-8" },
                          [
                            _c("div", { staticClass: "w-1/3" }, [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-1" },
                                [
                                  _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Proposed Road Name "),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.roadName,
                                    expression: "roadName",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "w-full mr-4 input-area mb-1 mt-1",
                                attrs: {
                                  type: "text",
                                  disabled:
                                    !_vm.lgaName ||
                                    !_vm.suburbName.length ||
                                    _vm.nameStatus === "Pre-Approved" ||
                                    _vm.isDisabled ||
                                    _vm.$wait.is("evaluatingName"),
                                  "data-cypress": "roadname-field",
                                  placeholder: "Enter Name Here...",
                                  name: "Proposed Road Name",
                                  id: "roadName",
                                },
                                domProps: { value: _vm.roadName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.roadName = $event.target.value.trim()
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "pl-4 w-1/3 -mb-3" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [
                                    _vm.proposalType !== "Pre-Approval"
                                      ? _c(
                                          "a",
                                          { staticClass: "text-red-star" },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v("Road Type "),
                                  ]
                                ),
                                _c(
                                  "norns-select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          required:
                                            this.proposalType !==
                                            "Pre-Approval",
                                        },
                                        expression:
                                          "{\n                    required: this.proposalType !== 'Pre-Approval'\n                  }",
                                      },
                                    ],
                                    staticClass:
                                      "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                    attrs: {
                                      name: "Road Type",
                                      id: "roadType",
                                      placeholder: "Select Road Type...",
                                      "data-cypress": "select-roadtype-input",
                                      multiple: false,
                                      disabled:
                                        !_vm.lgaName ||
                                        !_vm.suburbName.length ||
                                        _vm.isDisabled ||
                                        _vm.$wait.is("evaluatingName"),
                                      options: _vm.roadTypesClean,
                                      searchable: true,
                                      "close-on-select": true,
                                      "select-on-tab": true,
                                    },
                                    model: {
                                      value: _vm.roadType,
                                      callback: function ($$v) {
                                        _vm.roadType = $$v
                                      },
                                      expression: "roadType",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "noOptions" },
                                        slot: "noOptions",
                                      },
                                      [_vm._v("Loading...")]
                                    ),
                                  ]
                                ),
                                _c("FieldErrorMessage", {
                                  attrs: {
                                    message: _vm.errors.first("Road Type"),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "pl-4 w-1/3 -mb-3" }, [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2" },
                                [
                                  _c("a", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Radius "),
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2" },
                                [
                                  _c(
                                    "norns-select",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                      attrs: {
                                        disabled:
                                          _vm.isDisabled ||
                                          !_vm.lgaName ||
                                          _vm.$wait.is("evaluatingName"),
                                        multiple: false,
                                        options: _vm.distanceOptions,
                                        reduce: (d) => d.value,
                                        searchable: false,
                                        "close-on-select": true,
                                        "show-labels": true,
                                        "hide-selected": true,
                                        placeholder: "Pick a radius",
                                        name: "distance",
                                        "data-cypress": "radius-field",
                                        "select-on-tab": true,
                                      },
                                      model: {
                                        value: _vm.distance,
                                        callback: function ($$v) {
                                          _vm.distance = $$v
                                        },
                                        expression: "distance",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "noOptions" },
                                          slot: "noOptions",
                                        },
                                        [_vm._v("Loading...")]
                                      ),
                                    ]
                                  ),
                                  _c("FieldErrorMessage", {
                                    attrs: {
                                      message: _vm.errors.first("Radius"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "flex mb-5" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full flex content-center items-start mt-8",
                            },
                            [
                              _c("div", { staticClass: "pr-4 items-start" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [_vm._v(" Road Name Extent ")]
                                ),
                                _vm._v(
                                  " Please draw the extent of the proposed road on the map. "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(" Select either the polyline "),
                                    _c("img", {
                                      staticStyle: { width: "40px" },
                                      attrs: {
                                        src: "/img/icons/polyline-110x80.png",
                                      },
                                    }),
                                    _vm._v(" or polygon "),
                                    _c("img", {
                                      staticStyle: { width: "32px" },
                                      attrs: {
                                        src: "/img/icons/polygon-92x92.png",
                                      },
                                    }),
                                    _vm._v(" tool from within the map "),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Left click to mark the start of the road"
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Add additional points to mark out the road"
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Double click for the extent to be completed"
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      " Click Evaluate to undertake a road name evaluation "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c("WebMap", {
                              ref: "WebMapRef",
                              staticStyle: { height: "450px" },
                              attrs: {
                                id: "WebMap",
                                lgaName: _vm.lgaName,
                                suburbs: _vm.suburbName,
                                distance: _vm.distance,
                                readOnly: !_vm.canBeEdited,
                              },
                              on: {
                                "draw-complete": _vm.onDrawComplete,
                                "draw-delete": _vm.onDrawDelete,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "1rem" } },
                            [
                              _c(
                                "ButtonWithSpinner",
                                {
                                  staticClass: "button-blue mb-1 mt-1",
                                  attrs: {
                                    isSpinning: _vm.$wait.is("evaluatingName"),
                                    disabled:
                                      !this.canBeEdited ||
                                      _vm.$wait.is("evaluatingName") ||
                                      !(
                                        _vm.roadName &&
                                        _vm.distance &&
                                        Object.keys(_vm.suburbName).length &&
                                        Object.keys(_vm.geometry).length
                                      ),
                                  },
                                  on: { click: _vm.evaluateRoadName },
                                },
                                [_vm._v(" Evaluate ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm.user.isAdmin
                                ? _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass: "button-blue mb-1 mt-1",
                                      attrs: {
                                        isSpinning: _vm.$wait.is(
                                          "gettingSimilarRoads"
                                        ),
                                        disabled:
                                          (!this.canBeEdited &&
                                            _vm.nameStatus !==
                                              "Under Review") ||
                                          _vm.$wait.is("gettingSimilarRoads") ||
                                          !(
                                            _vm.roadName &&
                                            _vm.distance &&
                                            Object.keys(_vm.suburbName)
                                              .length &&
                                            Object.keys(_vm.geometry).length
                                          ),
                                      },
                                      on: {
                                        click: _vm.getSimilarRoadnamesFromDB,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Get Similar Roadnames in DB (For GNB) "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-col mb-5" },
                          [
                            _vm.$wait.is("evaluatingName")
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "flex-grow text-black text-sm font-bold text-center",
                                  },
                                  [_vm._v(" Checking Road name validity ")]
                                )
                              : _vm._e(),
                            _c("ShowLoading", {
                              attrs: { isLoading: "evaluatingName" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "component",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-grow items-start",
                                          },
                                          [
                                            _vm.evaluated &&
                                            _vm.evaluation &&
                                            !!_vm.evaluation.breaches
                                              ? _c(
                                                  "div",
                                                  { staticClass: "flex-grow" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-orange-dark font-bold text-lg",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.evaluation
                                                                .breaches
                                                                .issueCount
                                                            ) +
                                                            " potential issue(s) found "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.evaluation.breaches
                                                        .failures,
                                                      function (
                                                        failedGuideline
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: failedGuideline.guideLineType,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-orange font-bold pt-4",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      failedGuideline.title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              failedGuideline.issues,
                                                              function (
                                                                message,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "MessagePanel",
                                                                  {
                                                                    key: `${failedGuideline.guideLineType}-${index}`,
                                                                    staticClass:
                                                                      "flex-grow",
                                                                    attrs: {
                                                                      warning:
                                                                        "",
                                                                      msg: message.shortDescription,
                                                                      mapURL:
                                                                        message.mapURL,
                                                                      geometry:
                                                                        message.geometry,
                                                                      guideLineType:
                                                                        failedGuideline.guideLineType,
                                                                    },
                                                                    on: {
                                                                      "increment-btn":
                                                                        _vm.showLine,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            !failedGuideline.issues ||
                                                            failedGuideline
                                                              .issues.length < 1
                                                              ? _c(
                                                                  "MessagePanel",
                                                                  {
                                                                    key: failedGuideline.guideLineType,
                                                                    staticClass:
                                                                      "flex-grow",
                                                                    attrs: {
                                                                      warning:
                                                                        "",
                                                                      msg: failedGuideline.shortDescription,
                                                                      mapURL:
                                                                        failedGuideline.mapURL,
                                                                      geometry:
                                                                        failedGuideline.geometry,
                                                                      guideLineType:
                                                                        failedGuideline.guideLineType,
                                                                    },
                                                                    on: {
                                                                      "increment-btn":
                                                                        _vm.showLine,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm.evaluated &&
                                                _vm.evaluation.passes == true
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("MessagePanel", {
                                                      staticClass: "flex-grow",
                                                      attrs: {
                                                        msg: "0 potential issues found",
                                                        ok: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1031926213
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.evaluated && _vm.similarRoadnames
                          ? _c(
                              "div",
                              { staticClass: "flex flex-col mb-5" },
                              [
                                _vm.$wait.is("gettingSimilarRoads")
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex-grow text-black text-sm font-bold text-center",
                                      },
                                      [
                                        _vm._v(
                                          " Checking Similar Road names in the Proposals System "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("ShowLoading", {
                                  attrs: { isLoading: "gettingSimilarRoads" },
                                }),
                                !!this.similarRoadnames.breach &&
                                this.similarRoadnames.breach.issues.length >=
                                  0 &&
                                !_vm.$wait.is("gettingSimilarRoads")
                                  ? _c("SimilarRoadnameLists", {
                                      attrs: {
                                        similarRoadnames:
                                          _vm.similarRoadnames.breach,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "w-full flex mt-8" }, [
                          _c("label", { staticClass: "w-full" }, [
                            _c("p", { staticClass: "text-normal-bold mb-2" }, [
                              _vm.proposalType !== "Pre-Approval"
                                ? _c("span", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ])
                                : _vm._e(),
                              _vm._v("Extent "),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.extent,
                                  expression: "extent",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    required:
                                      this.proposalType !== "Pre-Approval",
                                  },
                                  expression:
                                    "{\n                    required: this.proposalType !== 'Pre-Approval'\n                  }",
                                },
                              ],
                              staticClass: "w-full h-48 input-area",
                              attrs: {
                                "data-cypress": "extent-field",
                                placeholder:
                                  "Please describe where the road starts and ends",
                                name: "extent",
                                disabled: _vm.isDisabled,
                              },
                              domProps: { value: _vm.extent },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.extent = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("FieldErrorMessage", {
                          attrs: { message: _vm.errors.first("extent") },
                        }),
                        _c("div", { staticClass: "w-full flex mt-8" }, [
                          _c("label", { staticClass: "w-full" }, [
                            _c("p", { staticClass: "text-normal-bold mb-2" }, [
                              _c("span", { staticClass: "text-red-star" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v("Origin "),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.origin,
                                  expression: "origin",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full h-48 input-area",
                              attrs: {
                                disabled: _vm.isDisabled,
                                "data-cypress": "origin-field",
                                placeholder:
                                  "Please provide the meaning and history of the proposed name",
                                name: "Origin",
                              },
                              domProps: { value: _vm.origin },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.origin = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("FieldErrorMessage", {
                          attrs: { message: _vm.errors.first("Origin") },
                        }),
                        _c("commemorate-person", {
                          ref: "personCommemoration",
                          attrs: {
                            roadIndex: _vm.index,
                            readOnly:
                              _vm.isDisabled ||
                              _vm.$wait.is("saving roadname") ||
                              _vm.$wait.is(
                                "setting up road name proposal page"
                              ),
                          },
                        }),
                        _c("p", { staticClass: "text-normal-bold mt-8 mb-5" }, [
                          _vm._v(
                            " Does this road contain a multi-cultural dimension? "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex text-normal" },
                          [
                            _c(
                              "RadioButton",
                              {
                                staticClass: "mr-3",
                                attrs: {
                                  "data-cypress":
                                    "doesNotHaveMulticulturalDiemnsion",
                                  checkedValue: false,
                                  disabled:
                                    !!_vm.aboriginalDimension || _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.multiculturalDimension,
                                  callback: function ($$v) {
                                    _vm.multiculturalDimension = $$v
                                  },
                                  expression: "multiculturalDimension",
                                },
                              },
                              [_vm._v("No")]
                            ),
                            _c(
                              "RadioButton",
                              {
                                attrs: {
                                  "data-cypress":
                                    "doesHaveMulticulturalDiemnsion",
                                  checkedValue: true,
                                  disabled:
                                    !!_vm.aboriginalDimension || _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.multiculturalDimension,
                                  callback: function ($$v) {
                                    _vm.multiculturalDimension = $$v
                                  },
                                  expression: "multiculturalDimension",
                                },
                              },
                              [
                                _vm._v(
                                  " Yes (Please specify in the 'Additional Information' field) "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "text-normal-bold mt-8 mb-5" }, [
                          _vm._v(
                            " Does this road contain an Aboriginal dimension? "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row text-normal justify-start",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex text-normal" },
                              [
                                _c(
                                  "RadioButton",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      "data-cypress":
                                        "doesNotHaveAboriginalDiemnsion",
                                      checkedValue: false,
                                      disabled:
                                        !!_vm.multiculturalDimension ||
                                        _vm.isDisabled,
                                    },
                                    model: {
                                      value: _vm.aboriginalDimension,
                                      callback: function ($$v) {
                                        _vm.aboriginalDimension = $$v
                                      },
                                      expression: "aboriginalDimension",
                                    },
                                  },
                                  [_vm._v("No")]
                                ),
                                _c(
                                  "RadioButton",
                                  {
                                    attrs: {
                                      "data-cypress":
                                        "doesHaveAboriginalDiemnsion",
                                      checkedValue: true,
                                      disabled:
                                        !!_vm.multiculturalDimension ||
                                        _vm.isDisabled,
                                    },
                                    model: {
                                      value: _vm.aboriginalDimension,
                                      callback: function ($$v) {
                                        _vm.aboriginalDimension = $$v
                                      },
                                      expression: "aboriginalDimension",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Yes (Please specify in the 'Additional Information' field) "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "text-normal-bold mt-8" }, [
                          _vm._v(" Additional information for this Road "),
                        ]),
                        _c("div", { staticClass: "w-full mt-3 flex" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.comment,
                                expression: "comment",
                              },
                            ],
                            staticClass: "w-full h-48 input-area",
                            attrs: {
                              "data-cypress": "information-field",
                              disabled: _vm.isDisabled,
                              name: "comment",
                            },
                            domProps: { value: _vm.comment },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.comment = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("uploadComponent", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                _vm.proposalType !== "Pre-Approval"
                                  ? "min_value:1"
                                  : "",
                              expression:
                                "\n                proposalType !== 'Pre-Approval' ? 'min_value:1' : ''\n              ",
                            },
                          ],
                          staticClass: "mt-8",
                          attrs: {
                            label:
                              "Please upload Map and Other Supporting Document",
                            important: _vm.proposalType !== "Pre-Approval",
                            uploads: _vm.documents,
                            drafted: !_vm.isDisabled,
                            disabled: _vm.isDisabled,
                            deleteFile: _vm.stageForDelete,
                            name: "documents",
                          },
                          on: {
                            uploadfinished: function ($event) {
                              _vm.uploading = false
                            },
                            uploadstarted: function ($event) {
                              _vm.uploading = true
                            },
                          },
                        }),
                        _c("FieldErrorMessage", {
                          attrs: {
                            "data-cypress": "nofile-error",
                            message: _vm.errors.first("documents")
                              ? "No files were uploaded for this Road Name. Please upload required documents."
                              : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex justify-end" },
                    [
                      _c(
                        "ButtonWithSpinner",
                        {
                          staticClass:
                            "button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold",
                          attrs: {
                            "data-cypress": "cancel-button",
                            disabled: _vm.$wait.is("saving roadname"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "ButtonWithSpinner",
                        {
                          staticClass:
                            "button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold",
                          attrs: {
                            "data-cypress": "addroad-button",
                            disabled: _vm.isSubmitDisabled,
                            isSpinning: _vm.$wait.is("saving roadname"),
                          },
                          on: { click: _vm.validateRoadNameProposal },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex ml-auto" }, [
      _c(
        "a",
        {
          staticClass:
            "button-blue-hollow ml-4 focus:outline-none focus:shadow-outline self-center",
          attrs: {
            href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=96",
            target: "_blank",
          },
        },
        [_vm._v("View Road Naming Guidelines")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }