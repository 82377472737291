var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-1 flex-col" },
    [
      _c("Loading-spinner", {
        directives: [
          {
            name: "wait",
            rawName: "v-wait:visible",
            value: "fetching user details",
            expression: "'fetching user details'",
            arg: "visible",
          },
        ],
        staticClass: "flex-0",
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "wait",
              rawName: "v-wait:hidden",
              value: "fetching user details",
              expression: "'fetching user details'",
              arg: "hidden",
            },
          ],
          staticClass: "flex-1 flex",
        },
        [
          _vm.unuathorised
            ? _c("UnauthorisedMessage", { staticClass: "flex flex-1" })
            : _vm._e(),
          !_vm.unuathorised
            ? _c(
                "div",
                { staticClass: "base-container text-normal text-black" },
                [
                  _c(
                    "section",
                    { staticClass: "pt-1 justify-center w-full block" },
                    [
                      _c("div", { staticClass: "flex justify-between" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "flex justify-between pb-4 self-center",
                            on: {
                              click: function ($event) {
                                return _vm.$router.go(-1)
                              },
                            },
                          },
                          [
                            _c("BackArrowWithTitle", {
                              attrs: { title: "Edit Profile" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-between items-center py-4",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "button-blue-hollow flex-0 items-center",
                                attrs: { to: { name: "profile/view" } },
                              },
                              [_vm._v("View User Profile")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c("form", { attrs: { novalidate: "" } }, [
                    _c(
                      "section",
                      {
                        staticClass:
                          "pt-1 bg-white w-full block rounded-lg pt-8 pb-8 mb-6",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-9/10 mx-auto flex flex-col text-black",
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "dividing-line-h" }),
                            _c("div", { staticClass: "pt-6 pb-6 flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1/5 flex-col text-center lg:flex sm:hidden",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "icon relative w-24 h-24 rounded-full flex justify-center items-center mr-5 bg-grey-lighter text-center",
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "text-4xl flex-0",
                                        attrs: { icon: "user" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sm:w-full lg:w-4/5 justify-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "py-3 w-full flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sm:w-full lg:w-1/2 justify-center md:mb-0",
                                        },
                                        [
                                          _vm._m(1),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.first_name,
                                                expression: "user.first_name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              id: "first_name",
                                              "data-cypress": "first_name",
                                              name: "first_name",
                                              type: "text",
                                              "data-vv-as": "First name",
                                            },
                                            domProps: {
                                              value: _vm.user.first_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.user,
                                                  "first_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("FieldErrorMessage", {
                                            staticClass: "mt-2 flex-row",
                                            attrs: {
                                              message:
                                                _vm.errors.first("first_name"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0",
                                        },
                                        [
                                          _vm._m(2),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.last_name,
                                                expression: "user.last_name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              id: "last_name",
                                              "data-cypress": "last_name",
                                              name: "last_name",
                                              type: "text",
                                              "data-vv-as": "Last name",
                                            },
                                            domProps: {
                                              value: _vm.user.last_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.user,
                                                  "last_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("FieldErrorMessage", {
                                            staticClass: "mt-2 flex-row",
                                            attrs: {
                                              message:
                                                _vm.errors.first("last_name"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "py-3 flex flex-row mb-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sm:w-full lg:w-1/2 pr-4 justify-center md:mb-0",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "block text-gray-700 text-sm font-semibold mb-1",
                                              attrs: {
                                                for: "user_account_status",
                                              },
                                            },
                                            [_vm._v("Account Status")]
                                          ),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.user
                                                      .user_account_status,
                                                  expression:
                                                    "user.user_account_status",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                },
                                              ],
                                              staticClass: "w-full input-area",
                                              attrs: {
                                                "data-cypress":
                                                  "account_status",
                                                name: "user_account_status",
                                                id: "user_account_status",
                                                disabled:
                                                  !_vm.currentUser.isAdmin,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.user,
                                                    "user_account_status",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.accountStatuses,
                                              function (value) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: value,
                                                    domProps: { value: value },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toProperCase(value)
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full py-3 flex flex-col mb-2",
                                    },
                                    [
                                      _vm._m(3),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-1 flex flex-wrap",
                                        },
                                        [
                                          _c("Checkbox", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                              },
                                            ],
                                            staticClass: "w-1/2 flex-no-shrink",
                                            attrs: {
                                              disabled: _vm.isRNPDisabled,
                                              label:
                                                "Road Name Authority Representative",
                                              "data-cypress":
                                                "RNA_Representative",
                                              name: "isRNP",
                                              isDisabled: true,
                                            },
                                            model: {
                                              value: _vm.isRNP,
                                              callback: function ($$v) {
                                                _vm.isRNP = $$v
                                              },
                                              expression: "isRNP",
                                            },
                                          }),
                                          _vm.currentUser.isAdmin &&
                                          _vm.user.isRNP &&
                                          _vm.entity ===
                                            "Administrative Spatial Program"
                                            ? _c("Checkbox", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-1/2 flex-no-shrink",
                                                attrs: {
                                                  disabled:
                                                    !_vm.currentUser.isAdmin,
                                                  label:
                                                    "Administrative Spatial Program User",
                                                  "data-cypress": "ASP_User",
                                                  name: "isASP",
                                                  isDisabled: true,
                                                },
                                                model: {
                                                  value: _vm.isASP,
                                                  callback: function ($$v) {
                                                    _vm.isASP = $$v
                                                  },
                                                  expression: "isASP",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("Checkbox", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                              },
                                            ],
                                            staticClass: "w-1/2 flex-no-shrink",
                                            attrs: {
                                              disabled:
                                                _vm.isPublic ||
                                                _vm.user.isRNP ||
                                                _vm.user.isPNP,
                                              label: "Public Applicant",
                                              "data-cypress":
                                                "Public_applicant",
                                              name: "isPublic",
                                              isDisabled: true,
                                            },
                                            model: {
                                              value: _vm.isPublic,
                                              callback: function ($$v) {
                                                _vm.isPublic = $$v
                                              },
                                              expression: "isPublic",
                                            },
                                          }),
                                          _c("Checkbox", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                              },
                                            ],
                                            staticClass: "w-1/2 flex-no-shrink",
                                            attrs: {
                                              disabled: _vm.isPNPDisabled,
                                              label:
                                                "Local/Other Govt Entity Representative",
                                              "data-cypress":
                                                "Other_Representative",
                                              name: "isPNP",
                                              isDisabled: true,
                                            },
                                            model: {
                                              value: _vm.isPNP,
                                              callback: function ($$v) {
                                                _vm.isPNP = $$v
                                              },
                                              expression: "isPNP",
                                            },
                                          }),
                                          _vm.currentUser.isAdmin
                                            ? _c("Checkbox", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-1/2 flex-no-shrink",
                                                attrs: {
                                                  label: "GNB Admin",
                                                  "data-cypress": "Admin",
                                                  name: "isAdmin",
                                                  disabled: _vm.isAdminDisabled,
                                                  isDisabled: true,
                                                },
                                                model: {
                                                  value: _vm.user.isAdmin,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "isAdmin",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.isAdmin",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  !_vm.user.isPublic
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sm:w-full lg:w-1/2 justify-center md:mb-0",
                                            },
                                            [
                                              _vm._m(4),
                                              _c("EntitySelect", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      _vm.isRNP || _vm.isPNP
                                                        ? "required"
                                                        : "",
                                                    expression:
                                                      "isRNP || isPNP ? 'required' : ''",
                                                  },
                                                ],
                                                staticClass:
                                                  "flex-1 input-area",
                                                attrs: {
                                                  name: "entity",
                                                  "data-cypress":
                                                    "entity_represents",
                                                  entityList: _vm.entityList,
                                                  disabled:
                                                    !_vm.currentUser.isAdmin ||
                                                    (!_vm.originalUser.isRNP &&
                                                      !_vm.originalUser.isPNP),
                                                },
                                                model: {
                                                  value: _vm.entity,
                                                  callback: function ($$v) {
                                                    _vm.entity = $$v
                                                  },
                                                  expression: "entity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0",
                                            },
                                            [
                                              _vm._m(5),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.user.position,
                                                    expression: "user.position",
                                                  },
                                                ],
                                                staticClass:
                                                  "w-full input-area",
                                                attrs: {
                                                  id: "position",
                                                  name: "position",
                                                  "data-cypress": "titel",
                                                  type: "text",
                                                  disabled: _vm.user.isPublic,
                                                  "data-vv-as": "Position",
                                                },
                                                domProps: {
                                                  value: _vm.user.position,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.user,
                                                      "position",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("FieldErrorMessage", {
                                                staticClass: "mt-2 flex-row",
                                                attrs: {
                                                  message:
                                                    _vm.errors.first(
                                                      "position"
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sm:w-full lg:w-1/2 justify-center md:mb-0",
                                        },
                                        [
                                          _vm._m(6),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.phone_no,
                                                expression: "user.phone_no",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|phoneNumber",
                                                expression:
                                                  "'required|phoneNumber'",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              id: "phone_no",
                                              name: "phone_no",
                                              "data-cypress": "phoneNumber",
                                              type: "text",
                                              "data-vv-as": "Phone number",
                                            },
                                            domProps: {
                                              value: _vm.user.phone_no,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.user,
                                                  "phone_no",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("FieldErrorMessage", {
                                            staticClass: "mt-2 flex-row",
                                            attrs: {
                                              message:
                                                _vm.errors.first("phone_no"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0",
                                        },
                                        [
                                          _vm._m(7),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.email,
                                                expression: "user.email",
                                              },
                                            ],
                                            staticClass: "w-full input-area",
                                            attrs: {
                                              id: "email",
                                              name: "email",
                                              "data-cypress": "email",
                                              type: "email",
                                              disabled: "",
                                            },
                                            domProps: { value: _vm.user.email },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.user,
                                                  "email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.user.isPublic
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-full justify-center md:mb-0",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "block text-gray-700 text-sm font-semibold mb-1",
                                                    attrs: {
                                                      for: "address_line_1",
                                                    },
                                                  },
                                                  [_vm._v("Address Line 1")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.user.address_line_1,
                                                      expression:
                                                        "user.address_line_1",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full input-area",
                                                  attrs: {
                                                    id: "address_line_1",
                                                    name: "address_line_1",
                                                    type: "text",
                                                    "data-vv-as":
                                                      "Address line 1",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.user.address_line_1,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.user,
                                                        "address_line_1",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-full justify-center md:mb-0",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "block text-gray-700 text-sm font-semibold mb-1",
                                                    attrs: {
                                                      for: "address_line_2",
                                                    },
                                                  },
                                                  [_vm._v("Address Line 2")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.user.address_line_2,
                                                      expression:
                                                        "user.address_line_2",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full input-area",
                                                  attrs: {
                                                    id: "address_line_2",
                                                    name: "address_line_2",
                                                    type: "text",
                                                    "data-vv-as":
                                                      "Address line 2",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.user.address_line_2,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.user,
                                                        "address_line_2",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sm:w-full lg:w-1/2 justify-center md:mb-0",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "block text-gray-700 text-sm font-semibold mb-1",
                                                    attrs: { for: "suburb" },
                                                  },
                                                  [_vm._v("Suburb")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.user.suburb,
                                                      expression: "user.suburb",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full input-area",
                                                  attrs: {
                                                    id: "suburb",
                                                    name: "suburb",
                                                    type: "text",
                                                    "data-vv-as": "Suburb",
                                                  },
                                                  domProps: {
                                                    value: _vm.user.suburb,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.user,
                                                        "suburb",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "block text-gray-700 text-sm font-semibold mb-1",
                                                    attrs: {
                                                      for: "address_line_2",
                                                    },
                                                  },
                                                  [_vm._v("State")]
                                                ),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.user.state,
                                                        expression:
                                                          "user.state",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "w-full input-area",
                                                    attrs: {
                                                      name: "state",
                                                      id: "state",
                                                      "data-vv-as": "State",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          _vm.user,
                                                          "state",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.states,
                                                    function (value) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: value,
                                                          domProps: {
                                                            value: value,
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(value))]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sm:w-full lg:w-1/2 lg:pr-4 justify-center md:mb-0",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "block text-gray-700 text-sm font-semibold mb-1",
                                                    attrs: { for: "postcode" },
                                                  },
                                                  [_vm._v("Postcode")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.user.postcode,
                                                      expression:
                                                        "user.postcode",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full input-area",
                                                  attrs: {
                                                    id: "postcode",
                                                    name: "postcode",
                                                    type: "text",
                                                    "data-vv-as": "Post code",
                                                  },
                                                  domProps: {
                                                    value: _vm.user.postcode,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.user,
                                                        "postcode",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("section", { staticClass: "pt-1 w-full block" }, [
                      _c(
                        "div",
                        { staticClass: "flex justify-end mb-24" },
                        [
                          _c(
                            "ButtonWithSpinner",
                            {
                              staticClass: "button-blue",
                              attrs: {
                                "data-cypress": "save-button",
                                isSpinning: _vm.$wait.is("saving user"),
                                disabled:
                                  !_vm.isDirty ||
                                  !!_vm.errors.items.length ||
                                  _vm.$wait.any,
                              },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Save Changes")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-6 pb-0" }, [
      _c("h2", { staticClass: "text-title" }, [_vm._v("Personal Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "first_name" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" First Name "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "last_name" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" Last Name "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "md:w-full block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "grid-entity" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" User Types "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "entity" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" Entity User Represents "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "position" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" Position/Title "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "phone_no" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" Phone Number "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "block text-gray-700 text-sm font-semibold mb-1",
        attrs: { for: "last_name" },
      },
      [
        _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
        _vm._v(" Email Address "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }