<template>
  <div class="base-container text-black">
    <modal
      name="roadname-bulk-upload-delete-confirm"
      :width="'40%'"
      height="auto"
      :classes="'rounded-lg bg-white py-6 '"
    >
      <div class="w-9/10 mx-auto flex flex-col justify-center">
        <a @click="hideModal()" class="cursor-pointer ml-auto">
          <ErrorIcon classNames="w-5" />
        </a>
        <div class="w-full flex justify-center text-black mt-10">
          Remove Road Name From Upload?
        </div>

        <h2
          class="w-full flex justify-center font-light text-black mt-8 text-center"
        >
          {{ modalRoad.roadname && modalRoad.roadname.value }}
          {{ modalRoad.road_type && modalRoad.road_type.value }}
        </h2>
        <div
          class="w-full inline font-light text-black mt-4 text-center mb-4 flex-auto"
        >
          Suburb:&nbsp;
          <span class="font-bold text-black inline ">
            {{ modalRoad.suburb_name && modalRoad.suburb_name.value }}
          </span>
        </div>
        <br />
        <h4
          class="w-full flex justify-center font-light text-black text-center"
        >
          in {{ modalRoad.lga_name && modalRoad.lga_name.value }}
        </h4>
        <div
          class="w-full flex text-justify text-sm font-light text-black mt-4 mb-4 py-2 justify-center"
        >
          Are you sure you want to remove the above road name from the upload?
        </div>
        <div class="flex justify-between pt-4">
          <div
            class="flex w-full justify-between content-between pt-4 pb-4 mb-4 text-center"
          >
            <button @click="hideModal" class="button-blue-hollow w-1/2 text-sm">
              No, don't remove yet
            </button>
            <button
              @click="removeFromUpload(modalRoad.index)"
              :disabled="false"
              class="button-red w-1/2 text-sm pt-4 ml-8"
            >
              Yes, remove this road name
            </button>
          </div>
        </div>
      </div>
    </modal>
    <section class="justify-center block">
      <div class="flex mb-3">
        <a
          @click="
            $router.push({
              name: 'roadNameProposalBucket',
              params: { proposalId: $route.params.proposalId }
            })
          "
          class="cursor-pointer flex self-center"
          data-cypress="back-arrow"
        >
          <BackArrowWithTitle title="Add Road Names" />
        </a>
        <div class="ml-auto self-end mr-8 text-sm text-right">
          <div class="px-4">
            Adding {{ roadNamesInternal.length }} road names
          </div>
          <div
            v-show="errorCount > 0 && !$wait.is('evaluating roads')"
            class="text-white issue font-bold py-2 px-4 rounded-full"
          >
            {{ errorCount }} invalid road names
          </div>
        </div>
      </div>
    </section>
    <section v-if="!bulkError">
      <showLoading :isLoading="'evaluating roads'">
        <template v-slot:component>
          <div class="w-full bg-white">
            <div class="w-5/6 mx-auto py-16">
              <div>
                <div
                  v-for="(roadName, key) in roadNamesInternal"
                  :key="key"
                  class="w-full flex mb-5 mr-4"
                  :class="[
                    rowIsValid(roadName)
                      ? 'bg-green-lightest'
                      : 'bg-red-lightest'
                  ]"
                >
                  <div class="p-5 flex-grow">
                    <div class="flex">
                      <div class="w-1/4 text-label">
                        Proposal Type
                      </div>
                      <div class="w-1/4 text-label">
                        Road Name
                      </div>
                      <div class="w-1/4 text-label">
                        Road Type
                      </div>
                      <div class="w-1/4 text-label">
                        &nbsp;
                      </div>
                    </div>
                    <div class="flex font-500">
                      <div class="w-1/4">
                        <valid :field="roadName.roadname_classification" />
                      </div>
                      <div class="w-1/4">
                        <valid :field="roadName.roadname" />
                      </div>
                      <div class="w-1/4">
                        <valid :field="roadName.road_type" />
                      </div>
                      <div class="w-1/4 flex flex-row-reverse">
                        <div
                          @click="roadName.collapsed = !roadName.collapsed"
                          ref="collapse"
                          class="rotate-transition text-blue-darker"
                          :class="[roadName.collapsed ? '' : 'rotate-chevron']"
                        >
                          <font-awesome-icon icon="chevron-circle-down" />
                        </div>
                        <div class="py-1 pl-3 pr-8">
                          <font-awesome-icon
                            role="button"
                            @click="showModal(key)"
                            icon="trash"
                            class="align-baseline"
                          ></font-awesome-icon>
                        </div>
                      </div>
                    </div>
                    <transition
                      name="collapse"
                      @enter="beforeEnterCollapse(key)"
                      @leave="beforeLeaveCollapse(key)"
                    >
                      <div ref="collapse-body" v-show="!roadName.collapsed">
                        <div class="flex mt-2">
                          <div class="w-1/2">
                            <div class="text-label">
                              LGA
                            </div>
                            <div>
                              <valid :field="roadName.lga_name" />
                            </div>
                          </div>
                          <div class="w-1/2">
                            <div class="text-label">
                              Suburb
                            </div>
                            <div>
                              <valid :field="roadName.suburb_name" />
                            </div>
                          </div>
                        </div>
                        <div class="mt-2">
                          <div class="text-label">
                            Origin
                          </div>
                          <div>
                            <valid :field="roadName.roadname_origin" />
                          </div>
                        </div>
                        <div class="mt-2">
                          <div class="text-label">
                            Extent
                          </div>
                          <div>
                            <valid :field="roadName.extent" />
                          </div>
                        </div>
                        <template
                          v-if="
                            roadName.commemorated &&
                              !!roadName.commemorated.value
                          "
                        >
                          <div class="flex mt-2">
                            <div class="w-full text-label">
                              Commemoration Info
                            </div>
                          </div>
                          <div class="flex">
                            <div class="pl-2 pt-1 w-1/4">
                              <div class="text-label">
                                Name
                              </div>
                              <div>
                                <valid
                                  :field="roadName.commemorated_full_name"
                                />
                              </div>
                            </div>
                            <div class="pt-1 w-1/4">
                              <div class="text-label">
                                Date of Birth
                              </div>
                              <div>
                                <valid
                                  :field="roadName.commemorated_birth_date"
                                />
                              </div>
                            </div>
                            <div class="pt-1 w-1/4">
                              <div class="text-label">
                                Date of Death
                              </div>
                              <div>
                                <valid
                                  :field="roadName.commemorated_death_date"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="pl-2 pt-1">
                            <div class="text-label">
                              Unknown Date of Birth/Death?
                            </div>
                            <div>
                              <valid
                                :field="roadName.commemorated_dates_unknown"
                              />
                            </div>
                          </div>
                          <div
                            class="pl-2 pt-1"
                            v-if="
                              roadName.commemorated_dates_unknown &&
                                roadName.commemorated_dates_unknown.value ===
                                  true
                            "
                          >
                            <div class="text-label">
                              Reason why Date of Birth/Death is Unknown
                            </div>
                            <div>
                              <valid
                                :field="
                                  roadName.commemorated_dates_unknown_description
                                "
                              />
                            </div>
                          </div>
                          <div class="flex">
                            <div class="pl-2 pt-1">
                              <div class="text-label">
                                Person's association with the road name
                              </div>
                              <div>
                                <valid
                                  :field="
                                    roadName.commemorated_association_description
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="pl-2 pt-1"
                            v-if="
                              roadName.commemorated_source &&
                                roadName.commemorated_source.value
                            "
                          >
                            <div class="text-label">
                              Source of commemoration information
                            </div>
                            <div>
                              <valid :field="roadName.commemorated_source" />
                            </div>
                          </div>
                        </template>
                        <div class="flex">
                          <div
                            class="pt-1 w-1/2"
                            v-if="
                              roadName.has_multicultural_dimension &&
                                roadName.has_multicultural_dimension.value
                            "
                          >
                            <div class="text-label">
                              Has multicultural dimension?
                            </div>
                            <div>
                              <valid
                                :field="roadName.has_multicultural_dimension"
                              />
                            </div>
                          </div>
                          <div
                            class="pt-1 w-1/2"
                            v-if="
                              roadName.has_aboriginal_dimension &&
                                roadName.has_aboriginal_dimension.value
                            "
                          >
                            <div class="text-label">
                              Has aboriginal dimension?
                            </div>
                            <div>
                              <valid
                                :field="roadName.has_aboriginal_dimension"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="pt-1"
                          v-if="roadName.comment && roadName.comment.value"
                        >
                          <div class="text-label">
                            Additional Information
                          </div>
                          <div>
                            <valid :field="roadName.comment" />
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                  <div
                    :class="[
                      rowIsValid(roadName) ? 'valid-border' : 'invalid-border'
                    ]"
                    class="font-bold text-center text-sm text-white flex"
                  >
                    <font-awesome-icon
                      v-if="roadErrorCount(roadName) > 0"
                      icon="times"
                      class="mx-auto self-center"
                    />
                    <font-awesome-icon
                      v-else
                      icon="check"
                      class="mx-auto self-center"
                    />
                  </div>
                </div>
              </div>

              <div class="w-1/3 -mb-3">
                <p class="text-normal-bold mb-2">
                  <a class="text-red-star">*</a>Radius
                </p>
                <p class="text-normal-bold mb-2">
                  <norns-select
                    class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                    v-model="distance"
                    :multiple="false"
                    :options="distanceOptions"
                    :reduce="d => d.value"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Pick a radius"
                    name="distance"
                    data-cypress="radius-field"
                    v-validate="'required'"
                    :select-on-tab="true"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('Radius')" />
                </p>
              </div>

              <div class="flex mb-5">
                <!-- Form textbox / inputs section -->
                <div class="w-full flex content-center items-start mt-8">
                  <div class="pr-4 items-start">
                    <p class="text-normal-bold mb-2">
                      Road Name Extent
                    </p>
                    Please draw the extent of the proposed roads on the map.
                    <ul>
                      <li>
                        Select the polygon
                        <img
                          src="/img/icons/polygon-92x92.png"
                          style="width:32px;"
                        />
                        tool from within the map
                      </li>
                      <li>Left click to mark the start of the road</li>
                      <li>Add additional points to mark out the road</li>
                      <li>Double click for the extent to be completed</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <WebMap
                  id="WebMap"
                  ref="WebMapRef"
                  style="height: 450px"
                  :lgaName="lgaName"
                  :distance="0"
                  v-on:draw-complete="onDrawComplete"
                  v-on:draw-delete="geometry = {}"
                  :readOnly="false"
                  :availableCreateTools="availableCreateTools"
                >
                </WebMap>
              </div>

              <div class="mx-auto py-8">
                <Checkbox
                  v-model="noDraw"
                  v-validate="'required:true'"
                  name="noDraw"
                  class="w-full"
                  :disabled="$wait.any || geometryIsSet"
                >
                  I have decided to not draw the extent of the roads and
                  understand that I will need to manually draw the extent on
                  each of the roads in the proposal before I can submit the
                  proposal.
                </Checkbox>
              </div>

              <div v-if="roadNamesInternal.length > 0">
                <uploadComponent
                  class="mt-8 pr-8"
                  label="Please upload Map and Other Supporting Documents.  Any uploaded files will be added to ALL roads above."
                  :uploads="uploadedFilesState"
                  :drafted="true"
                  v-on:uploadfinished="uploading = false"
                  v-on:uploadstarted="uploading = true"
                  :disabled="!canUpload"
                  :deleteFile="deleteFile"
                  name="documents"
                  :important="needsFile"
                />
              </div>

              <div class="mr-8">
                <div
                  v-if="roadNamesInternal.length === 0"
                  class="flex flex-grow flex-col justify-center items-center py-4"
                >
                  <font-awesome-icon
                    class=" flex-0 text-red text-3xl inline"
                    icon="exclamation-circle"
                  />
                  <p class="text-black flex-0">
                    No road name records found
                  </p>
                </div>
                <div
                  v-else-if="errorCount > 0"
                  class="text-red-dark text-sm font-bold"
                >
                  There are invalid road name records in this submission.
                  <br />
                  Please remove the invalid road names from this submission or
                  make corrections to your excel file and upload again.
                </div>
                <div
                  v-else-if="errorCount === 0 && needsFile"
                  class="text-orange-dark text-sm font-bold"
                >
                  Please upload a map and/or supporting documents before
                  submitting.
                </div>
              </div>
              <div class="flex flex-row-reverse w-full pr-8 mt-10 mb-20">
                <ButtonWithSpinner
                  type="button"
                  @click="addRoadsToProposal"
                  class="button-blue-hollow"
                  data-cypress="bulk-addroad-button"
                  :disabled="!canSubmit"
                  >Add Roads To Proposal</ButtonWithSpinner
                >
                <ButtonWithSpinner
                  class="button-red-hollow mr-auto"
                  data-cypress="cancel-button"
                  @click="
                    $router.push({
                      name: 'roadNameProposalBucket',
                      params: { proposalId: $route.params.proposalId }
                    })
                  "
                  :disabled="uploading"
                >
                  Cancel
                </ButtonWithSpinner>
              </div>
            </div>
          </div>
        </template>
      </showLoading>
    </section>
    <div
      v-if="bulkError"
      class="flex flex-grow flex-col justify-center items-center py-4"
    >
      <font-awesome-icon
        class=" flex-0 text-red text-3xl inline"
        icon="exclamation-circle"
      />
      <p class="text-black flex-0">
        Cannot upload roads for multiple LGAs.
      </p>
    </div>
  </div>
</template>

<script>
import { getSuburbs } from "../../../helpers/servicesq";
import roadTypeListMixin from "../../../mixins/road-type-list-filter";
import lgaListMixin from "../../../mixins/lga-list-filter";
import memoize from "lodash.memoize";
import valid from "./roadname-proposal-bulk-validity";
import { mapMutations, mapGetters } from "vuex";
import { deleteFile } from "../../../helpers/s3";
import moment from "moment";

import WebMap from "./evaluation-public/WebMap.vue";

export default {
  provide() {
    return {
      $validator: this.$validator
    };
  },
  components: {
    valid,
    WebMap
  },
  mixins: [roadTypeListMixin, lgaListMixin],
  beforeEnter(to, from, next) {
    next(vm => {
      if (
        to.params.proposalId !== "create" &&
        vm.$store.getters.roadNameProposalBucket.identifier
      ) {
        vm.router.replace({
          name: "roadNameProposalBucket",
          params: {
            proposalId: to.params.proposalId
          }
        });
      }
    });
  },
  // props: ["roadNames"]
  props: {
    roadNames: {
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      roadNamesInternal: this.roadNames,
      modalRoad: {},
      bulkError: false,
      uploading: false,
      suburbs: [],
      columns: [
        {
          text: "Proposal Type",
          key: "roadname_classification"
        },
        {
          text: "LGA",
          key: "lga_name"
        },
        {
          text: "Suburb",
          key: "suburb_name"
        },
        {
          text: "Origin",
          key: "roadname_origin"
        },
        {
          text: "Road Name",
          key: "roadname"
        },
        {
          text: "Extent",
          key: "extent"
        },
        {
          text: "Road Type",
          key: "road_type"
        },
        {
          text: "Commemorates A Person?",
          key: "commemorated"
        },
        {
          text: "Person Name",
          key: "commemorated_full_name"
        },
        {
          text: "Date of Birth (DD/MM/YYYY)",
          key: "commemorated_birth_date"
        },
        {
          text: "Date of Death (DD/MM/YYYY)",
          key: "commemorated_death_date"
        },
        {
          text: "Unknown Dates",
          key: "commemorated_dates_unknown"
        },
        {
          text: "Unknown Description",
          key: "commemorated_dates_unknown_description"
        },
        {
          text: "Person's Association With Feature",
          key: "commemorated_association_description"
        },
        {
          text: "Source of Information",
          key: "commemorated_source"
        }
      ],
      lgaName: "",
      availableCreateTools: ["polygon"],
      distanceOptions: [
        { label: "10km - Metropolitan", value: 10000 },
        { label: "20km - Regional Urban", value: 20000 },
        { label: "30km - Rural", value: 30000 }
      ],
      distance: null,
      geometry: {},
      noDraw: false
    };
  },
  async created() {
    // Clear the uploaded files if the for was entered/exited improperly
    this.$wait.start("evaluating roads");
    this.clearUploadState();
    if (this.roadNamesInternal.length) {
      //check there is only a single LGA submitted.
      const firstLga = this.roadNamesInternal[0].lga_name.value;

      // zoom to the LGA
      window.setTimeout(() => {
        this.lgaName = firstLga;
      }, 5000);

      if (this.roadNamesInternal.every(rn => rn.lga_name.value === firstLga)) {
        this.suburbs = await this.getSuburbsForLGA(firstLga);
        const validatedRoadNames = this.roadNamesInternal.map(rn => {
          const prop = this.validateRoadName(rn);
          prop.collapsed = true;
          return prop;
        });
        this.$set(this, "roadNamesInternal", validatedRoadNames);
      } else {
        this.bulkError = true;
      }
    }
    setTimeout(() => {
      this.$wait.end("evaluating roads");
    }, 1000);
  },
  methods: {
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      return suburbs.filter(sub => sub !== null && sub !== "");
    },
    validateRoadName(roadName) {
      const roadNameCopy = {};
      roadNameCopy.roadname_classification = {
        ...roadName.roadname_classification,
        valid:
          !!roadName.roadname_classification &&
          [
            "Public",
            "Non-Dedicated",
            "Non-Dedicated (Private)",
            "Pre-Approval"
          ].includes(roadName.roadname_classification.value)
      };
      roadNameCopy.roadname = {
        ...roadName.roadname,
        valid: roadName.roadname && !!roadName.roadname.value
      };
      roadNameCopy.road_type = {
        ...roadName.road_type,
        valid:
          roadName.roadname_classification &&
          roadName.roadname_classification.value !== "Pre-Approval"
            ? roadName.road_type &&
              !!roadName.road_type.value &&
              this.roadTypesClean.includes(roadName.road_type.value)
            : true
      };
      roadNameCopy.lga_name = {
        ...roadName.lga_name,
        valid:
          roadName.lga_name &&
          roadName.lga_name.value &&
          this.lgasClean.includes(roadName.lga_name.value)
      };
      roadNameCopy.suburb_name = {
        ...roadName.suburb_name,
        valid:
          !!roadName.suburb_name &&
          this.suburbs.includes(roadName.suburb_name.value)
      };
      roadNameCopy.roadname_origin = {
        ...roadName.roadname_origin,
        valid: roadName.roadname_origin && !!roadName.roadname_origin.value
      };
      roadNameCopy.extent = {
        ...roadName.extent,
        valid:
          roadName.roadname_classification &&
          roadName.roadname_classification.value !== "Pre-Approval"
            ? roadName.extent && !!roadName.extent.value
            : true
      };
      roadNameCopy.has_multicultural_dimension = {
        ...roadName.has_multicultural_dimension,
        value:
          roadName.has_multicultural_dimension &&
          roadName.has_multicultural_dimension.value
            ? roadName.has_multicultural_dimension.value === "Yes"
            : undefined,
        valid:
          roadName.has_multicultural_dimension &&
          ["Yes", "No", undefined].includes(
            roadName.has_multicultural_dimension.value
          )
      };
      roadNameCopy.has_aboriginal_dimension = {
        ...roadName.has_aboriginal_dimension,
        value:
          roadName.has_aboriginal_dimension &&
          roadName.has_aboriginal_dimension.value
            ? roadName.has_aboriginal_dimension.value === "Yes"
            : undefined,
        valid:
          roadName.has_aboriginal_dimension &&
          ["Yes", "No", undefined].includes(
            roadName.has_aboriginal_dimension.value
          )
      };
      if (
        roadNameCopy.has_multicultural_dimension.value === true &&
        roadNameCopy.has_aboriginal_dimension.value === true
      ) {
        roadNameCopy.has_multicultural_dimension.valid = roadNameCopy.has_aboriginal_dimension.valid = false;
      }
      roadNameCopy.comment = {
        ...roadName.comment,
        valid: true
      };
      roadNameCopy.commemorated = {
        ...roadName.commemorated,
        value: !!(
          roadName.commemorated.value && roadName.commemorated.value === "Yes"
        ),
        valid:
          roadName.commemorated &&
          ["Yes", "No", undefined].includes(roadName.commemorated.value)
      };
      if (roadName.commemorated && roadName.commemorated.value === "Yes") {
        roadNameCopy.commemorated_full_name = {
          ...roadName.commemorated_full_name,
          valid:
            roadName.commemorated_full_name &&
            !!roadName.commemorated_full_name.value
        };

        roadNameCopy.commemorated_dates_unknown = {
          ...roadName.commemorated_dates_unknown,
          value: !!(
            roadName.commemorated_dates_unknown &&
            roadName.commemorated_dates_unknown.value === "Yes"
          )
        };

        roadNameCopy.commemorated_birth_date = {
          ...roadName.commemorated_birth_date,
          valid:
            roadName.commemorated_birth_date &&
            roadName.commemorated_birth_date.value
              ? roadName.commemorated_birth_date &&
                roadName.commemorated_birth_date.value &&
                this.$validator.rules.date.validate(
                  roadName.commemorated_birth_date.value
                )
              : roadName.commemorated_dates_unknown &&
                roadName.commemorated_dates_unknown.value === "Yes"
        };
        roadNameCopy.commemorated_death_date = {
          ...roadName.commemorated_death_date,
          valid:
            roadName.commemorated_death_date &&
            roadName.commemorated_death_date.value
              ? roadName.commemorated_death_date &&
                roadName.commemorated_death_date.value &&
                this.$validator.rules.date.validate(
                  roadName.commemorated_death_date.value
                )
              : roadName.commemorated_dates_unknown &&
                roadName.commemorated_dates_unknown.value === "Yes"
        };

        roadNameCopy.commemorated_dates_unknown.valid =
          roadName.commemorated_dates_unknown &&
          roadName.commemorated_dates_unknown.value === "Yes"
            ? // one of the above dates must be missing
              !roadName.commemorated_birth_date ||
              (roadName.commemorated_birth_date &&
                !roadName.commemorated_birth_date.value) ||
              !roadName.commemorated_death_date ||
              (roadName.commemorated_death_date &&
                !roadName.commemorated_death_date.value)
            : // both values must be present
              !!roadNameCopy.commemorated_birth_date &&
              roadNameCopy.commemorated_birth_date.valid &&
              !!roadNameCopy.commemorated_death_date &&
              roadNameCopy.commemorated_death_date.valid;

        if (
          roadName.commemorated_dates_unknown &&
          roadName.commemorated_dates_unknown.value === "Yes"
        ) {
          roadNameCopy.commemorated_dates_unknown_description = {
            ...roadName.commemorated_dates_unknown_description,
            valid:
              roadName.commemorated_dates_unknown_description &&
              !!roadName.commemorated_dates_unknown_description.value
          };
        }
        roadNameCopy.commemorated_association_description = {
          ...roadName.commemorated_association_description,
          valid:
            roadName.commemorated_association_description &&
            !!roadName.commemorated_association_description.value
        };
        roadNameCopy.commemorated_source = {
          ...roadName.commemorated_source,
          valid: true
        };
      }
      return roadNameCopy;
    },
    beforeEnterCollapse(index) {
      const content = this.$refs["collapse-body"][index];
      if (content) {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    },
    beforeLeaveCollapse(index) {
      const content = this.$refs["collapse-body"][index];
      content.style.maxHeight = 0 + "px";
    },
    rowIsValid: memoize(
      row => {
        const rowCopy = Object.assign({}, row);
        delete rowCopy.collapsed;
        return Object.values(rowCopy).every(x => !!x.valid) || false;
      },
      row => JSON.stringify(row)
    ),
    roadErrorCount: memoize(
      row => {
        return Object.keys(row)
          .map(key => (row[key].valid === false ? 1 : 0))
          .reduce((total, num) => total + num);
      },
      row => JSON.stringify(row)
    ),
    showModal(index) {
      this.modalRoad = Object.assign({}, this.roadNamesInternal[index], {
        index
      });
      this.$modal.show("roadname-bulk-upload-delete-confirm");
    },
    hideModal() {
      this.modalRoad = {};
      this.$modal.hide("roadname-bulk-upload-delete-confirm");
    },
    removeFromUpload(index) {
      this.roadNamesInternal.splice(index, 1);
      this.$modal.hide("roadname-bulk-upload-delete-confirm");
    },
    addRoadsToProposal() {
      this.editRoadName();
      const roads = this.roadNamesInternal.map(rn => {
        const road = {};
        for (const [key, value] of Object.entries(rn)) {
          road[key] = value.value;
        }
        // transform individual keys for vuex state
        road.suburb_name = [road.suburb_name];
        if (road.roadname_classification === "Non-Dedicated (Private)") {
          road.roadname_classification = "Non-Dedicated";
        }
        road.commemorated_birth_date = road.commemorated_birth_date
          ? moment(road.commemorated_birth_date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )
          : null;
        road.commemorated_death_date = road.commemorated_death_date
          ? moment(road.commemorated_death_date, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null;
        road.distance = this.distance;
        road.geometry = this.geometry;
        return Object.assign({}, this.roadNameProposal, road, {
          uploadedFiles: [...this.uploadedFilesState]
        });
      });
      this.addBulkRoadsToProposal({ roads });
      this.$router.push({
        name: "roadNameProposalBucket",
        params: { proposalId: this.$route.params.proposalId }
      });
    },
    onDrawComplete(geometry) {
      console.log("geometry = ", geometry);
      this.geometry = geometry;
      this.noDraw = false;
    },
    ...mapMutations([
      "addBulkRoadsToProposal",
      "editRoadName",
      "clearUploadState"
    ]),
    deleteFile
  },
  computed: {
    needsFile() {
      return (
        this.roadNamesInternal.some(
          rn =>
            rn.roadname_classification &&
            rn.roadname_classification.value &&
            ["Public", "Non-Dedicated", "Non-Dedicated (Private)"].includes(
              rn.roadname_classification.value
            )
        ) && this.uploadedFilesState.length === 0
      );
    },
    canSubmit() {
      return (
        !this.needsFile &&
        this.canUpload &&
        this.roadNamesInternal.length > 0 &&
        this.distance &&
        (this.geometryIsSet || this.noDraw)
      );
    },
    canUpload() {
      return (
        !this.uploading &&
        this.roadNamesInternal.map(rn => this.rowIsValid(rn)).every(x => x) &&
        !this.$wait.any
      );
    },
    errorCount() {
      return this.roadNamesInternal.filter(rn => !this.rowIsValid(rn)).length;
    },
    geometryIsSet() {
      return Object.keys(this.geometry).length > 0;
    },
    ...mapGetters(["roadNameProposal", "uploadedFilesState"])
  }
};
</script>

<style scoped>
/deep/ .invalid {
  color: #ad0000;
}

.valid {
  color: rgb(43, 169, 2);
}

.valid-border {
  background: rgb(43, 169, 2);
  width: 25px;
}

.invalid-border {
  background: #ad0000;
  width: 25px;
}

.issue {
  border-radius: 27px;
  background-color: #ff7f2f;
}

.responsive {
  max-width: 100%;
  overflow-x: scroll;
}

.font-500 {
  font-weight: 500;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.25s;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
}

.rotate-transition svg {
  transition: transform 0.25s;
  cursor: pointer;
  font-size: 1.5rem;
}

.rotate-chevron svg {
  transform: rotate(180deg);
}
</style>
