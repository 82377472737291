<template>
  <div class="base-container flex flex-col justify-start">
    <section class="flex-auto">
      <div class="flex justify-between flex-wrap">
        <h1 class="flex-auto text-black justify-between text-title self-center">
          Administer Users
        </h1>
        <ButtonWithSpinner
          class="button-red-hollow mt-4 mb-4 mr-4"
          @click="exportUserAccessReview()"
          :isSpinning="$wait.is('downloadingUserData')"
          :disabled="$wait.is('downloadingUserData')"
          >
            Export User Data
        </ButtonWithSpinner>
        <router-link
          v-if="isRNPView"
          :to="{ name: 'editSurveyorGeneralInfo' }"
          data-cypress="edit-surveyor-link"
          class="flex-no-grow flex-no-shrink pt-4 pb-4 mt-4 mb-4 pl-6 pr-6 rounded focus:outline-none focus:shadow-outline self-center button-blue-hollow"
          >Edit Surveyor General Info</router-link
        >
      </div>
    </section>

    <section class="flex flex-col flex-auto pt-1 justify-center pb-20">
      <div class="flex flex-1 flex-row items-center flex-wrap">
        <input
          class="flex-1 bg-white w-1/4 pr-12 input-area mr-2"
          v-model="textSearch"
          name="FullNameSearch"
          placeholder="Search by First Name or Last Name..."
        />
        <SelectEntity
          class="flex-1 bg-white mr-2 autocomplete"
          :entityList="entityList"
          v-model="entityName"
          data-cypress="entity-filter"
          name="namingEntity"
          :v-wait-hidden="entityWaiter"
          placeholder="Search by Entity"
        />
        <select
          class="flex-1 mr-2"
          data-cypress="search-usertype"
          v-model="userTypesFilter"
          name="UserType"
          id="userType"
        >
          <option value="" disabled hidden>Search by User Type</option>
          <option
            v-for="(item, key) in userTypes"
            :value="item"
            v-bind:key="key"
            >{{ item }}</option
          >
        </select>
        <select
          class="flex-1"
          v-model="userStatusFilter"
          v-validate="'required'"
          name="userStatus"
          id="userStatus"
        >
          <option value="" disabled hidden>Search by User Status</option>
          <option
            v-for="(item, key) in userStatusDropdown"
            :value="item"
            v-bind:key="key"
            >{{ item }}</option
          >
        </select>
      </div>

      <UserList :users="users">
        <template slot-scope="{ user }">
          <div
            class="w-12 h-full flex-auto flex flex-col content-around items-center "
          >
            <router-link
              class="flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button"
              data-cypress="view-user-link"
              :to="{ name: 'profile/view', params: { id: user.email } }"
              :title="`View ${user.first_name} ${user.last_name}'s profile`"
              ><font-awesome-icon icon="eye" class="flex-auto"
            /></router-link>

            <router-link
              :to="{ name: 'profile/edit', params: { id: user.email } }"
              data-cypress="edit-user-link"
              :title="`Edit ${user.first_name} ${user.last_name}'s profile`"
              class="flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button"
              ><font-awesome-icon icon="user-edit" class="flex-auto"
            /></router-link>

            <button
              v-if="user.user_account_status === 'approved'"
              :title="`Suspend ${user.first_name} ${user.last_name}`"
              class="flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user "
              v-bind:class="{
                'cursor-not-allowed': user.user_account_status !== 'approved'
              }"
              data-cypress="suspend-button"
              :disabled="user.user_account_status !== 'approved'"
              @click="suspendUser(user)"
            >
              <font-awesome-icon icon="lock" class="flex-auto" />
            </button>
            <button
              v-if="user.user_account_status !== 'approved'"
              :title="`Approve ${user.first_name} ${user.last_name}`"
              class="flex-auto flex justify-center items-center text-green font-bold h-1/3 w-full action-button activate-user "
              data-cypress="suspend-button"
              @click="approveUser(user)"
            >
              <font-awesome-icon icon="check" class="flex-auto" />
            </button>
            <!-- delete button -->
            <!-- Appends a delete button to the user profile page. -->
            <!-- Only admin users can delete users -->
            <DeleteUserModal
              class="flex-auto flex justify-center items-center w-full"
              :user="user"
              :isFromUserProfile="false"
              :loggedUser="loggedUser"
              :passedRoadNameProposals="null"
              :passedPlaceNameProposals="null"
            />
          </div>
        </template>
      </UserList>

      <section
        v-if="!$wait.is(`fetching users`) && total"
        class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6"
      >
        <div class="mx-auto text-center self-center">
          <paginate
            v-model="page"
            :page-count="Math.ceil(total / parseInt(limit))"
            :page-range="10"
            :margin-pages="1"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :click-handler="scrollToTop"
          >
          </paginate>
          <p>
            Showing
            {{ ((page - 1) * parseInt(limit) + 1).toLocaleString() }}
            to
            {{
              Math.min(
                (page - 1) * parseInt(limit) + parseInt(limit),
                total
              ).toLocaleString()
            }}
            of
            {{ total.toLocaleString() }}
            users
          </p>
        </div>
      </section>

      <div class="w-full">
        <user-export-component />
      </div>
    </section>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import { mapActions, mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import debounce from "lodash.debounce";
import { waitFor } from "vue-wait";
import invert from "lodash.invert";
import {
  searchUsers,
  getUserAccessReviewData,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_SUSPENDED
} from "../../helpers/userCRUD.js";
import UserList from "./userList";
import entityListMixin from "../../mixins/entity-list";
import userActionsMixin from "../../mixins/user-actions-mixin";
import SelectEntity from "./entitySelect";
import UserExportComponent from "./userExportComponent";
import DeleteUserModal from "../proposals/common/deleteUserModal";

const userTypeMap = {
  "All User Types": "isAll",
  "RNA Representative": "isRNP",
  "Public Applicant": "isPublic",
  "Local/Other Gov Entity": "isPNP",
  "GNB Admin": "isAdmin"
};

const userTypeValuesMap = invert(userTypeMap);

const userStatusMap = {
  "Any Status": "",
  Active: STATUS_APPROVED,
  Suspended: STATUS_SUSPENDED,
  Pending: STATUS_PENDING
};

const userStatusValuesMap = invert(userStatusMap);

export default {
  name: "administerUsers",
  mixins: [entityListMixin, userActionsMixin],
  components: {
    UserList,
    SelectEntity,
    Paginate,
    UserExportComponent,
    DeleteUserModal
  },

  async created() {
    this.searchUsers = debounce(this._search, 250);
    this.qsToProp(this.$route.query);
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm._search();
    });
  },

  methods: {
    ...mapActions(["getRnaList"]),

    qsToProp(queryString) {
      if (queryString.offset) this.offset = parseInt(queryString.offset, 10);
      if (queryString.limit) this.limit = parseInt(queryString.limit, 10);
      if (queryString.road_naming_authority_id)
        this.userEntityFilter.rnid = queryString.road_naming_authority_id;
      if (queryString.organisation_id)
        this.userEntityFilter.pnid = parseInt(queryString.organisation_id, 10);

      this.setEntityFilter();

      if (queryString.isRNP) this.userTypeFilter = userTypeValuesMap["isRNP"];
      if (queryString.isPNP) this.userTypeFilter = userTypeValuesMap["isPNP"];
      if (queryString.isPublic)
        this.userTypeFilter = userTypeValuesMap["isPublic"];
      if (queryString.user_account_status)
        this.userStatusFilter =
          userStatusValuesMap[queryString.user_account_status];
      if (queryString.full_name) this.textSearch = queryString.full_name;
    },

    propsToQueryObject() {
      const queryObject = {};
      if (this.textSearch) {
        queryObject.full_name = this.textSearch.trim();
      }

      if (this.userEntityFilter) {
        if (this.userEntityFilter.rnid)
          queryObject.road_naming_authority_id = this.userEntityFilter.rnid;
        if (this.userEntityFilter.pnid)
          queryObject.organisation_id = this.userEntityFilter.pnid;
      }

      if (
        this.userTypesFilter &&
        this.userTypesFilter !== 0 &&
        userTypeMap[this.userTypesFilter]
      ) {
        queryObject[userTypeMap[this.userTypesFilter]] = true;
      }

      if (
        this.userStatusFilter &&
        this.userStatusFilter &&
        userStatusMap[this.userStatusFilter]
      ) {
        queryObject.user_account_status = userStatusMap[this.userStatusFilter];
      }

      queryObject.offset = (this.page - 1) * this.limit;
      queryObject.limit = this.limit;

      return queryObject;
    },

    _search: waitFor("fetching users", async function() {
      try {
        const users = await searchUsers(this.propsToQueryObject());
        this.users = users.users;
        this.total = users.total;
      } catch (e) {
        this.users = [];
        this.total = 0;
      }
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    setEntityFilter() {
      const entity = this.entityList.filter(e => {
        if (this.userEntityFilter.rnid)
          return e.rnid === this.userEntityFilter.rnid;
        if (this.userEntityFilter.pnid)
          return e.pnid === this.userEntityFilter.pnid;
      });

      if (entity[0]) {
        this.userEntityFilter = Object.assign({}, entity[0]);
      }
    },
    updateQueryString() {
      try {
        const query = this.propsToQueryObject();
        const queryKeys = Object.keys(query);
        if (
          // numbers in $route.query are strings
          queryKeys.some(key => this.$route.query[key] != query[key]) ||
          queryKeys.length !== Object.keys(this.$route.query)
        ) {
          this.$router.push({ name: this.$route.name, query: query });
        }
      } catch (e) {
        if (e.name === "NavigationDuplicated") {
          return;
        }
      }
    },
    async exportUserAccessReview() {
      try{
        this.$wait.start("downloadingUserData");
        await getUserAccessReviewData().then(result => {
          let csvData = [];
          let csv = "";

          // Adds a header to the csv file
          this.expotDataTableHeaders.forEach((item, index) => {
            csv += item;
            if(index === this.expotDataTableHeaders.length - 1) csv += '\n';
            else csv += ', ';
          });

          // Adds users to the csv file
          // The data should be in the same order as 'expotDataTableHeaders'
          result.users.forEach(user => {
            csvData.push([
                          user.email, // Account Log-on ID
                          user.accountOwnerName, // Account Owner's Name
                          user.applicationRole, // User Application Role
                          user.user_account_status, //Account Status
                          "No", // Generic Logon must be always 'No'
                          user.dateAccountDisabled, // Date Account Disabled
                          user.logout_time_aest, // User Last Log out
                          user.position, // Users Job Role
                          !user.isRNP ? user.organisation_name : user.authority_name // User's Division
                        ]);
          });

          // Adds deleted users to the csv file
          result.deletedUsers.forEach(deletedUser => {
            csvData.push([
                          deletedUser.email, // Account Log-on ID
                          deletedUser.accountOwnerName, // Account Owner's Name
                          deletedUser.applicationRole, // User Application Role
                          deletedUser.user_account_status, //Account Status
                          null, // Generic Logon for deleted users must be always 'Null'
                          deletedUser.dateAccountDisabled, // Date Account Disabled
                          deletedUser.logout_time_aest, // User Last Log out
                          deletedUser.position, // Users Job Role
                          deletedUser.organisation_name // User's Division
                        ]);
          });
          
          csvData.forEach(row => {
            csv += row.join(',');
            csv += "\n";
          });
          
          // Creates a link and downloads the csv file
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'userAccessReview.csv';
          anchor.click();

          // Invalidates the object URL to prevent memory leaks.
          URL.revokeObjectURL(anchor.href);
        });
      } catch(e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Export User Data download failed",
          text: "An internal error has occurred. Please try again later."
        });
      } finally {
        // Invalidates the object URL to prevent memory leaks.
        if(this.$wait.is("downloadingUserData")) this.$wait.end("downloadingUserData");
      }
    }
  },

  computed: {
    ...mapGetters(["isRNPView", "showLoginModal"]),
    ...mapUserGetters({ loggedUser: "user" }),
    entityName: {
      get() {
        return this.userEntityFilter.name;
      },
      set(entity) {
        this.userEntityFilter = Object.assign(
          {},
          this.userEntityFilter,
          { name: "", pnid: null, rnid: "" },
          entity || {}
        );
      }
    }
  },
  watch: {
    async textSearch(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchUsers.cancel();
        this.page = 1;
        this.updateQueryString();
      }
    },
    async userEntityFilter(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.searchUsers.cancel();
        this.page = 1;
        this.updateQueryString();
      }
    },
    async userTypesFilter(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === "Public Applicant") {
          this.entityName = "";
        }
        this.searchUsers.cancel();
        this.page = 1;
        this.updateQueryString();
      }
    },
    async userStatusFilter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchUsers.cancel();
        this.page = 1;
        this.updateQueryString();
      }
    },
    async page(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchUsers.cancel();
        this.updateQueryString();
      }
    },
    $route(to, from) {
      if (to.query !== from.query) {
        this.searchUsers.cancel();
        this.searchUsers();
      }
    },
    entityList(newVal) {
      if (!newVal.length) return;
      this.setEntityFilter();
    },
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.searchUsers.cancel();
        this.searchUsers();
      }
    }
  },
  data() {
    return {
      users: [],
      exportUserData: [],
      userTypes: Object.keys(userTypeMap),
      userStatusDropdown: Object.keys(userStatusMap),
      userEntityFilter: { name: "", pnid: null, rnid: "" },
      userTypesFilter: "",
      userStatusFilter: "",
      textSearch: "",
      limit: 20,
      page: 1,
      total: 0,
      expotDataTableHeaders: [
        "Account Log-on ID",
        "Account Owner's Name",
        "User Application Role",
        "Account Status",
        "Generic Logon",
        "Date Account Disabled",
        "User Last Log out",
        "User's Job Role",
        "User's Division"
      ]
    };
  }
};
</script>

<style scoped>
.actions {
  color: #002664;
}
#userSVG {
  fill: grey;
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}

.dividing-line-v-2 {
  border-left-width: 1px;
}

.wait-container {
  padding-top: 0;
}

.action-button:hover {
  background: #002664;
  color: white;
}

.action-button.suspend-user:hover {
  background: #e3342f;
  color: #fff;
}
.action-button.activate-user:hover {
  background: #38c172;
  color: #fff;
}

.action-button:disabled {
  opacity: 0.4;
}
.action-button.suspend-user:disabled:hover {
  background: #fff;
  color: #e3342f;
}

.faded {
  opacity: 0.4;
}
</style>

<style>
.pagination > nav > .VuePagination__count {
  display: none;
}
</style>
