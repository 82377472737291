<template>
  <div>
    <div class="mb-4" v-for="(file, index) in files" :key="index">
      <div class="w-full mt-5 flex items-center rounded-lg border border-grey-border">
        <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
        <p class="textNormal break-all pr-4" data-cypress="file-title">{{ file.title }}</p>
        <a
          class="cursor-pointer ml-auto mr-8 text-normal-bold text-blue-button flex-no-shrink"
          :href="relativeFileUrl(file.s3_key)"
        >View File</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewFile",
  props: ["files"],
  methods:{
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    }
  }
};
</script>
