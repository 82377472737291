import { render, staticRenderFns } from "./document.vue?vue&type=template&id=0d91f713&"
import script from "./document.vue?vue&type=script&lang=js&"
export * from "./document.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d91f713')) {
      api.createRecord('0d91f713', component.options)
    } else {
      api.reload('0d91f713', component.options)
    }
    module.hot.accept("./document.vue?vue&type=template&id=0d91f713&", function () {
      api.rerender('0d91f713', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/audit-trail/documents/document.vue"
export default component.exports