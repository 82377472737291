var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main p-2 mt-4 px-4 font-bold rounded",
      class: { isError: _vm.error, isWarning: _vm.warning, isOk: _vm.ok },
    },
    [
      !!_vm.warning
        ? _c("font-awesome-icon", {
            staticClass: "mr-2",
            attrs: { icon: "exclamation" },
          })
        : _vm._e(),
      !!_vm.error
        ? _c("font-awesome-icon", {
            staticClass: "mr-2",
            attrs: { icon: "times" },
          })
        : _vm._e(),
      !!_vm.ok
        ? _c("font-awesome-icon", {
            staticClass: "mr-2",
            attrs: { icon: "check" },
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.msg)),
      _vm.mapURL && !_vm.geometry
        ? _c("span", [
            _vm._v(" - "),
            _c(
              "a",
              {
                staticClass: "text-blue-button font-bold",
                attrs: { href: _vm.mapURL, target: "_blank" },
              },
              [_vm._v("View Map")]
            ),
          ])
        : _vm._e(),
      _vm.geometry
        ? _c("span", [
            _vm._v(" - "),
            _c(
              "a",
              {
                staticClass: "text-blue-button font-bold cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.$emit("increment-btn", _vm.geometry)
                  },
                },
              },
              [_vm._v("View on map")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }