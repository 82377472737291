"use strict";

import { mapWaitingActions } from "vue-wait";
import { mapGetters } from "vuex";

const WAITER = "loading entities";
// Provides functions used for entitySelect.vue
// for screens that need a list of RNAs and Organisations with duplicate
// (by name) enties merged
const entityListMixin = {
  async created() {
    if (!this.entityList.length) {
      await this.getEntityList();
    }
  },
  data() {
    return {
      entityFilter: "",
      entityWaiter: WAITER
    };
  },
  computed: {
    ...mapGetters(["entityList"])
  },
  methods: {
    ...mapWaitingActions({
      getEntityList: WAITER
    })
  }
};

export default entityListMixin;
