"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

// push new naming rule to database
export function saveRuleToDb(ruleData, type) {
  const payload = {
    body: {
      ruleData: ruleData
    }
  };
  return API.post(apiName, `/rules/${type}`, payload);
}
export function updateRule(ruleData, type) {
  const payload = {
    body: {
      ruleData
    }
  };
  return API.put(apiName, `/rules/${type}`, payload);
}

export function getRulesList(type) {
  return API.get(apiName, `/rules/${type}`);
}

export function getRulesCategories(type) {
  return API.get(apiName, `/rules/${type}/categories`);
}
export function deleteRuleFromDb(ruleData, type) {
  const payload = {
    body: {
      identifier: ruleData.identifier
    }
  };
  return API.del(apiName, `/rules/${type}`, payload);
}
