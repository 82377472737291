var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormContainer", [
    !_vm.isUserPending
      ? _c("div", { staticClass: "flex flex-col content-between" }, [
          _c(
            "h1",
            {
              staticClass: "pt-6 mb-10 text-center mt-4 text-title",
              attrs: { "data-cypress": "header-text" },
            },
            [_vm._v(" Register for account ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "flex lg:flex-row md:flex-col xs:flex-col justify-between mb-4",
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-col flex-grow flex-shrink" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "block text-normal-bold mb-2",
                      attrs: { for: "firstname" },
                    },
                    [_vm._v("First name")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userRegistrationDetails.first_name,
                        expression: "userRegistrationDetails.first_name",
                      },
                    ],
                    staticClass: "w-full input-area",
                    attrs: {
                      id: "firstname",
                      name: "First Name",
                      type: "text",
                      required: "",
                      placeholder: "",
                      disabled: _vm.isWaiting,
                      "data-cypress": "first-name",
                    },
                    domProps: { value: _vm.userRegistrationDetails.first_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.userRegistrationDetails,
                          "first_name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.errors.has("First Name")
                    ? _c(
                        "span",
                        {
                          staticClass: "text-error",
                          attrs: { "data-cypress": "first-name-error" },
                        },
                        [_vm._v(_vm._s(_vm.errors.first("First Name")))]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col flex-grow flex-shrink lg:ml-4 sm:ml-4 md:ml-0",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "block text-normal-bold mb-2",
                      attrs: { for: "lastname" },
                    },
                    [_vm._v("Last name")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userRegistrationDetails.last_name,
                        expression: "userRegistrationDetails.last_name",
                      },
                    ],
                    staticClass: "w-full input-area",
                    attrs: {
                      id: "lastname",
                      name: "Last Name",
                      type: "text",
                      placeholder: "",
                      disabled: _vm.isWaiting,
                      "data-cypress": "last-name",
                    },
                    domProps: { value: _vm.userRegistrationDetails.last_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.userRegistrationDetails,
                          "last_name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm.errors.has("Last Name")
                    ? _c(
                        "span",
                        {
                          staticClass: "text-error",
                          attrs: { "data-cypress": "last-name-error" },
                        },
                        [_vm._v(_vm._s(_vm.errors.first("Last Name")))]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "mb-4 flex flex-col" }, [
            _c(
              "label",
              {
                staticClass: "block text-black text-normal-bold mb-2",
                attrs: { for: "email" },
              },
              [_vm._v("Email Address")]
            ),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userRegistrationDetails.email,
                  expression: "userRegistrationDetails.email",
                },
              ],
              staticClass: "w-full input-area",
              attrs: {
                id: "username",
                name: "Email Address",
                type: "text",
                placeholder: "",
                disabled: _vm.isWaiting,
                "data-cypress": "email",
              },
              domProps: { value: _vm.userRegistrationDetails.email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.userRegistrationDetails,
                      "email",
                      $event.target.value
                    )
                  },
                  function ($event) {
                    _vm.userRegistrationDetails.email =
                      _vm.userRegistrationDetails.email.toLowerCase()
                  },
                ],
                change: function ($event) {
                  _vm.isEmailAddressInUse = false
                },
              },
            }),
            _vm.errors.has("Email Address")
              ? _c(
                  "span",
                  {
                    staticClass: "text-error",
                    attrs: { "data-cypress": "email-error" },
                  },
                  [_vm._v(_vm._s(_vm.errors.first("Email Address")))]
                )
              : _vm._e(),
            _vm.isEmailAddressInUse
              ? _c(
                  "div",
                  {
                    staticClass: "flex items-between flex-row",
                    attrs: { "data-cypress": "email-in-use" },
                  },
                  [
                    _c("ErrorIcon", { staticClass: "w-4 h-4 ml-0" }),
                    _c("span", { staticClass: "text-error" }, [
                      _vm._v("Email already in use."),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "font-bold recover",
                        attrs: {
                          to: {
                            name: "reset",
                            query: { email: _vm.userRegistrationDetails.email },
                          },
                        },
                      },
                      [_vm._v("Recover my account password")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mb-4 flex flex-col" }, [
            _c(
              "label",
              {
                staticClass: "block text-black text-normal-bold mb-2 mt-2",
                attrs: { for: "phone" },
              },
              [_vm._v("Phone number")]
            ),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|phoneNumber",
                  expression: "'required|phoneNumber'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userRegistrationDetails.phone_no,
                  expression: "userRegistrationDetails.phone_no",
                },
              ],
              staticClass: "w-full input-area",
              attrs: {
                id: "phone",
                name: "Phone Number",
                type: "text",
                placeholder: "",
                disabled: _vm.isWaiting,
                "data-cypress": "phone",
              },
              domProps: { value: _vm.userRegistrationDetails.phone_no },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.userRegistrationDetails,
                    "phone_no",
                    $event.target.value
                  )
                },
              },
            }),
            _vm.errors.has("Phone Number")
              ? _c(
                  "span",
                  {
                    staticClass: "text-error",
                    attrs: { "data-cypress": "phone-error" },
                  },
                  [_vm._v(_vm._s(_vm.errors.first("Phone Number")))]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mb-4 flex justify-between mt-2" }, [
            _c(
              "div",
              { staticClass: "flex flex-col flex-grow flex-shrink w-1/2 mb-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "block text-black text-normal-bold mb-2",
                    attrs: { for: "passwordInitial" },
                  },
                  [_vm._v("Password")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|passwordPolicy",
                      expression: "'required|passwordPolicy'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userRegistrationDetails.passwordInitial,
                      expression: "userRegistrationDetails.passwordInitial",
                    },
                  ],
                  ref: "passwordInitial",
                  staticClass: "w-full input-area",
                  attrs: {
                    id: "passwordInitial",
                    name: "Password",
                    type: "password",
                    placeholder: "",
                    disabled: _vm.isWaiting,
                    "data-cypress": "password",
                  },
                  domProps: {
                    value: _vm.userRegistrationDetails.passwordInitial,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.userRegistrationDetails,
                        "passwordInitial",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm.errors.has("Password")
                  ? _c(
                      "span",
                      {
                        staticClass: "text-error",
                        attrs: { "data-cypress": "password-error" },
                      },
                      [_vm._v(_vm._s(_vm.errors.first("Password")))]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col flex-grow flex-shrink lg:ml-4 sm:ml-4 md:ml-0 w-1/2 mb-3",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "block text-black text-normal-bold mb-2",
                    attrs: { for: "passwordconfirm" },
                  },
                  [_vm._v("Confirm Password")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|confirmed:passwordInitial",
                      expression: "'required|confirmed:passwordInitial'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userRegistrationDetails.passwordconfirm,
                      expression: "userRegistrationDetails.passwordconfirm",
                    },
                  ],
                  staticClass: "w-full input-area",
                  attrs: {
                    id: "passwordconfirm",
                    name: "Confirm Password",
                    type: "password",
                    placeholder: "",
                    disabled: _vm.isWaiting,
                    "data-cypress": "password-confirm",
                  },
                  domProps: {
                    value: _vm.userRegistrationDetails.passwordconfirm,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.userRegistrationDetails,
                        "passwordconfirm",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm.errors.has("Confirm Password")
                  ? _c("span", { staticClass: "text-error" }, [
                      _vm._v("Passwords do not match."),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c("div", { staticClass: "flex flex-col mb-4" }, [
            _c("p", { staticClass: "flex-1 font-bold text-normal w-full" }, [
              _vm._v(
                " Do you represent a Local or State Government Authority? "
              ),
            ]),
            _c("div", { staticClass: "flex flex-row" }, [
              _c("div", { staticClass: "flex flex-row items-center mt-3" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "cont w-6 h-6 mr-3 flex justify-center absolute",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userRegistrationDetails.isRNA,
                          expression: "userRegistrationDetails.isRNA",
                        },
                      ],
                      staticClass: "hidden",
                      attrs: {
                        type: "radio",
                        name: "roadTypeCheck",
                        id: "govEntity",
                        "data-cypress": "is-not-rna",
                      },
                      domProps: {
                        value: false,
                        checked: _vm._q(
                          _vm.userRegistrationDetails.isRNA,
                          false
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.userRegistrationDetails,
                            "isRNA",
                            false
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "inner w-3 h-3 self-center" }),
                  ]
                ),
                _c("p", { staticClass: "ml-10 text-normal" }, [_vm._v("No")]),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-row items-center ml-8 mt-3" },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "cont w-6 h-6 mr-3 flex justify-center absolute",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userRegistrationDetails.isRNA,
                            expression: "userRegistrationDetails.isRNA",
                          },
                        ],
                        staticClass: "hidden",
                        attrs: {
                          type: "radio",
                          name: "roadTypeCheck",
                          id: "govEntity",
                          "data-cypress": "is-rna",
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(
                            _vm.userRegistrationDetails.isRNA,
                            true
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.userRegistrationDetails,
                              "isRNA",
                              true
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "inner w-3 h-3 self-center" }),
                    ]
                  ),
                  _c("p", { staticClass: "ml-10 text-normal" }, [
                    _vm._v("Yes"),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm.userRegistrationDetails.isRNA
            ? _c(
                "div",
                { staticClass: "w-full mt-6" },
                [
                  _c("p", { staticClass: "font-bold text-normal" }, [
                    _vm._v(" Which proposals are you Authorised to submit? "),
                  ]),
                  _c("Checkbox", {
                    staticClass: "w-full pl-0",
                    attrs: {
                      "data-cypress": "is-road-cbx",
                      label: "Road Names",
                    },
                    model: {
                      value: _vm.isRoad,
                      callback: function ($$v) {
                        _vm.isRoad = $$v
                      },
                      expression: "isRoad",
                    },
                  }),
                  _c("Checkbox", {
                    staticClass: "w-full pl-0",
                    attrs: {
                      "data-cypress": "is-others-cbx",
                      label:
                        "Place Names/Address Locality Names/Address Locality Boundary Amendments",
                    },
                    model: {
                      value: _vm.isOthers,
                      callback: function ($$v) {
                        _vm.isOthers = $$v
                      },
                      expression: "isOthers",
                    },
                  }),
                  !_vm.isOthers && !_vm.isRoad
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isWaiting,
                              expression: "!isWaiting",
                            },
                          ],
                          staticClass:
                            "text-blue font-medium mt-1 text-size-14",
                          attrs: {
                            "data-cypress": "please-select-option-message",
                          },
                        },
                        [_vm._v(" Please select an option above to continue ")]
                      )
                    : _vm._e(),
                  _vm.isOthers || _vm.isRoad
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isWaiting,
                              expression: "!isWaiting",
                            },
                          ],
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "font-bold text-normal pt-4 mb-2" },
                            [_vm._v(" Which entity do you represent? ")]
                          ),
                          _c(
                            "svg",
                            {
                              staticClass: "absolute w-5 mt-4 ml-4",
                              attrs: {
                                "aria-hidden": "true",
                                focusable: "false",
                                "data-prefix": "fas",
                                "data-icon": "search",
                                role: "img",
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 512 512",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "currentColor",
                                  d: "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z",
                                },
                              }),
                            ]
                          ),
                          _c("entitySelect", {
                            attrs: {
                              entityList: _vm.entityList,
                              isRoad: _vm.isRoad,
                              isOthers: _vm.isOthers,
                              selectedEntity:
                                _vm.userRegistrationDetails.organisation_name,
                              "data-cypress": "authority",
                              name: "rnaEntity",
                              id: "rnaEntity",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateEntityDetails($event)
                              },
                            },
                          }),
                          this.noNewEntityError
                            ? _c(
                                "div",
                                {
                                  staticClass: "block",
                                  attrs: {
                                    "data-cypress": "no-new-entity-error",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-error",
                                      attrs: {
                                        "data-cypress":
                                          "must-select-existing-rna-error",
                                      },
                                    },
                                    [_vm._v("You must select an existing RNA.")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          this.showNewEntityMessage
                            ? _c(
                                "div",
                                {
                                  staticClass: "block",
                                  attrs: {
                                    "data-cypress": "create-new-entity-message",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-blue text-xs font-medium mt-1",
                                    },
                                    [
                                      _vm._v(
                                        "On approval, this entity will be created in our system."
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.errors.has("rnaEntity")
                            ? _c(
                                "div",
                                {
                                  staticClass: "block pb-8",
                                  attrs: { "data-cypress": "authority-error" },
                                },
                                [
                                  _c("span", { staticClass: "text-error" }, [
                                    _vm._v("You must select an entity"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            { staticClass: "font-bold pt-4 text-normal" },
                            [_vm._v("Title/Position")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userRegistrationDetails.position,
                                expression: "userRegistrationDetails.position",
                              },
                            ],
                            staticClass: "mt-2 w-full input-area",
                            attrs: {
                              id: "position",
                              name: "position",
                              type: "text",
                              placeholder: "Enter Title/Position",
                              disabled: _vm.isWaiting,
                              "data-cypress": "authority-position",
                            },
                            domProps: {
                              value: _vm.userRegistrationDetails.position,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userRegistrationDetails,
                                  "position",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.errors.has("position")
                            ? _c(
                                "div",
                                {
                                  staticClass: "block pb-8",
                                  attrs: {
                                    "data-cypress": "authority-position-error",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text-error" }, [
                                    _vm._v("Title is required"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "flex flex-grow self-stretch" }),
          _c(
            "div",
            { staticClass: "flex flex-grow" },
            [
              _c(
                "Checkbox",
                {
                  staticClass: "w-full pl-0",
                  model: {
                    value: _vm.personalInformationCollectionNotice,
                    callback: function ($$v) {
                      _vm.personalInformationCollectionNotice = $$v
                    },
                    expression: "personalInformationCollectionNotice",
                  },
                },
                [
                  [
                    _vm._v(" Please tick to acknowledge the "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" GNB Personal Information Collection Notice ")]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "items-center justify-between mt-6" },
            [
              _c(
                "ButtonWithSpinner",
                {
                  staticClass: "w-full button-blue",
                  attrs: {
                    disabled:
                      _vm.errors.any() ||
                      _vm.isWaiting ||
                      this.isSubmitDisabled ||
                      !_vm.personalInformationCollectionNotice,
                    isSpinning: _vm.isWaiting,
                    "data-cypress": "register",
                  },
                  on: { click: _vm.registerUserToCognito },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass:
                "w-full bg-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 return text-normal mb-10",
              attrs: { type: "button", "data-cypress": "return-to-login" },
              on: { click: _vm.handleReturnToLogin },
            },
            [_vm._v(" Return to login page ")]
          ),
        ])
      : _vm._e(),
    _vm.isUserPending
      ? _c(
          "div",
          {
            staticClass: "flex flex-col items-center",
            attrs: { "data-cypress": "register-success" },
          },
          [
            _c(
              "h2",
              {
                staticClass:
                  "pt-8 pb-12 text-center flex-no-grow text-title text-2xl",
              },
              [_vm._v(" Account request successful ")]
            ),
            _c("p", { staticClass: "flex-auto text-normal mt-4 mb-4" }, [
              _vm._v(
                " Your registration form has been successfully submitted for approval. You should receive a confirmation email within 1 - 2 business days. "
              ),
            ]),
            _c(
              "button",
              {
                staticClass:
                  "flex-no-grow signin w-full mt-4 border-2 bg-white text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-normal",
                attrs: { type: "button" },
                on: { click: _vm.handleReturnToLogin },
              },
              [_vm._v(" Return to login page ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }