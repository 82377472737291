<template>
  <Layout>
    <template v-slot:content>
      <authenticationContent />
    </template>

    <template v-slot:form>
      <registerForm :entityList="entityList" />
    </template>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Layout from "../components/authentication/authentication-layout";
import registerForm from "@/components/authentication/registerForm";
import authenticationContent from "@/components/authentication/authencticationContent.vue";

export default {
  name: "Authentication",
  components: {
    registerForm,
    authenticationContent,
    Layout
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.getEntityList());
  },
  computed: {
    viewState() {
      return this.$store.state.viewState.authentication;
    },
    ...mapGetters(["entityList"], { isLoadingRNA: "isWaiting" })
  },
  methods: {
    ...mapActions(["getEntityList"])
  }
};
</script>
