<template>
  <span v-if="field" :class="{ invalid: field.valid === false }">
    <font-awesome-icon
      class="mr-2"
      v-if="field.valid === false"
      icon="times"
    ></font-awesome-icon>
    <span>{{ transformedField }}</span>
  </span>
</template>

<script>
export default {
  props: ["field"],
  computed: {
    transformedField() {
      if (this.field.value === true) {
        return "Yes";
      }
      if (this.field.value === false) {
        return "No";
      }
      return this.field.value;
    }
  }
};
</script>

<style scoped>
.invalid {
  color: #ad0000;
}
</style>