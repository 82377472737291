<template>
  <div class="flex-1">
    <p class="text-label">Account Status</p>
    <span
      :class="account_status"
      class="inline-block rounded-full w-2/3 px-2 py-2 text-sm font-bold mt-2 ml-auto mr-auto text-center"
      >{{ account_status }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    account_status: {
      type: String
    }
  }
};
</script>

<style scoped>
.active {
  background-color: #d4f8b7;
  color: #007906;
}
.approved {
  background-color: #d4f8b7;
  color: #007906;
}
.pending {
  background-color: #fdf5bf;
  color: #dc5800;
}
.deactivated {
  background-color: #ffccd5;
  color: #c60c30;
}
.needs {
  background-color: #c9ecff;
  color: #0a7cb9;
}
</style>
