<template>
  <div class="text-black mx-16 my-16 bg-white p-8">
    <DataTableReport
      :columns="columns"
      :rows="rows"
      :title="`Road Name Proposal Summary`"
      :recordType="`proposals`"
      :paginationProps="pagination"
      :isLoading="$wait.is('fetchingRecords')"
      v-on:updatePage="updatePage"
      v-on:sortDesc="sortDesc"
      v-on:sortAsc="sortAsc"
      :filterLists="filterLists"
      :filters="filters"
      :filterValues="qsParams"
      v-on:resetFilters="resetFilterSearchParams"
      v-on:filterChange="setSearchParamsFromFilters"
      :pageResultCountList="pageResultCountList"
      v-on:updatePageLimit="updatePageLimit"
      v-on:setDateTo="setDateToParam"
      :showDates="true"
      :showKeywordSearch="true"
      v-on:setDateFrom="setDateFromParam"
      v-on:searchViaKeyword="setRoadnameParam"
      v-on:download="downloadCsv"
      :isAdmin="isAdmin"
    />
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
const FileSaver = require("file-saver");

import { createNamespacedHelpers, mapGetters } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import {
  fetchRoadNameRecordsForReport,
  fetchRoadNameStatuses
} from "@/helpers/rnpCRUD.js";
import { getSuburbs } from "@/helpers/servicesq.js";

import DataTableReport from "./data-table/index.vue";

const defaultParams = {
  includeTotal: true,
  orderDirection: "ASC",
  orderBy: "roadname",
  includeArchived: true
};

import rnaUserMixin from "@/mixins/rna-mixin";
import rnaListMixin from "@/mixins/rna-list-mixin";
import lgaListMixin from "@/mixins/lga-list-filter";

import moment from "moment";

export default {
  name: "roadNameProposalsReport",
  components: { DataTableReport },
  mixins: [rnaUserMixin, lgaListMixin, rnaListMixin],
  methods: {
    fetchRecords: waitFor("fetchingRecords", async function(isCsv) {
      try {
        const records = await fetchRoadNameRecordsForReport(
          {
            ...this.qsParams,
            submittedDateEnd: this.qsParams.to
              ? moment(this.qsParams.to, "DD/MM/YYYY").format("YYYY-MM-DD")
              : "",
            submittedDateStart: this.qsParams.from
              ? moment(this.qsParams.from, "DD/MM/YYYY").format("YYYY-MM-DD")
              : "",
            finalisedDate: this.qsParams.finalisedDate
              ? moment(this.qsParams.finalisedDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : "",
            limit: this.pagination.limit,
            page: this.pagination.page
          },
          isCsv
        );

        if (!isCsv) {
          this.rows = records.proposals.map(proposal => {
            const viewProposalRoute = this.$router.resolve({
              name: "roadNameProposalEdit",
              params: {
                proposalId: proposal.proposal_identifier,
                roadNameId: proposal.identifier
              }
            });
            proposal.url = `${viewProposalRoute.href}`;
            return proposal;
          });
          this.pagination.record_count = records.meta.totalProposals;
        } else return records;
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error fetching records",
          text: error.response ? error.response.data.message : error
        });
      }
    }),
    downloadCsv: waitFor("fetchingRecords", async function() {
      try {
        const data = await this.fetchRecords(true);
        const blob = new Blob([data.csv], {
          type: "text/csv;charset=utf-8"
        });
        FileSaver.saveAs(blob, data.filename);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error downloading records",
          text: error
        });
      }
    }),
    async setSearchParamsFromFilters(payload) {
      this.pagination.page = 1;
      this.qsParams.includeArchived = true;
      // this.qsParams[payload.name] = payload.filters[payload.name].value;
      this.qsParams = {
        ...this.qsParams,
        [payload.name]: payload.filters[payload.name].value
      };

      if (this.qsParams[payload.name] === "Yes")
        this.qsParams[payload.name] = "yes";
      if (this.qsParams[payload.name] === "No")
        this.qsParams[payload.name] = "no";

      if (payload.name === "lga") {
        this.qsParams = { ...this.qsParams, suburb: "" };
        this.suburbList = [];

        this.suburbList = this.qsParams.lga
          ? await getSuburbs(this.qsParams.lga)
          : [];
      }

      await this.fetchRecords();
    },
    async resetFilterSearchParams() {
      this.pagination.page = 1;
      this.qsParams = { ...defaultParams };
      this.qsParams.includeArchived = true;
      await this.fetchRecords();
    },
    async updatePage(page) {
      this.pagination.page = page;
      await this.fetchRecords();
    },
    async sortDesc(field) {
      this.qsParams.orderDirection = "DESC";
      this.qsParams.orderBy = field;
      await this.fetchRecords();
    },
    async sortAsc(field) {
      this.qsParams.orderDirection = "ASC";
      this.qsParams.orderBy = field;
      await this.fetchRecords();
    },
    async updatePageLimit(limit) {
      this.pagination = {
        ...this.pagination,
        limit: limit,
        page: 1
      };

      await this.fetchRecords();
    },
    async setDateToParam(to) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        to,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    async setDateFromParam(from) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        from,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    async setRoadnameParam(name) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        roadname: name,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    initLists: waitFor("fetchingRecords", async function() {
      const [users, statuses] = await Promise.all([
        this.fetchRNAUsers(
          !this.user.isAdmin ? this.user.road_naming_authority_id : null
        ),
        fetchRoadNameStatuses()
      ]);

      this.lgaList = this.lgasClean;
      if (!this.user.isASP && this.lgaList.includes(this.defaultRnaLga)) {
        this.qsParams.lga = this.defaultRnaLga;
      }

      this.statusList = statuses.map(status => status.name_status);

      this.userList = users.reduce((users, user) => {
        const fullName = `${user.first_name} ${user.last_name}`;

        if (!users.includes(fullName)) {
          users.push(fullName);
        }

        return users;
      }, []);
    })
  },
  async created() {
    this.qsParams = Object.assign({}, { ...defaultParams });
    await this.initLists();
    await this.fetchRecords();
  },
  computed: {
    ...mapUserGetters(["user"]),
    ...mapGetters(["showLoginModal", "defaultRnaLga"]),
    isAdmin() {
      //for this page an ASP user is as good as an Admin
      return !!this.user.isAdmin || !!this.user.isASP;
    },
    filterState: {
      get() {
        return this.filters.reduce((state, filter) => {
          state[filter.name] = { value: this.qsParams[filter.name] };
          return state;
        }, {});
      }
    },
    filterLists() {
      return {
        userList: this.userList,
        lgaList: this.lgaList,
        typeList: this.typeList,
        suburbList: this.suburbList,
        statusList: this.statusList,
        reducedRnaList: this.reducedRnaList,
        multiculturalList: this.multiculturalList,
        aboriginalList: this.aboriginalList,
        unapprovedGazzetteList: this.unapprovedGazzetteList
      };
    },
    reducedRnaList() {
      return this.rnaList.reduce((rnas, rna) => {
        if (rna.authority_name) {
          rnas.push({ label: rna.authority_name, value: rna.identifier });
        }
        return rnas;
      }, []);
    }
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchRecords();
      }
    }
  },
  data() {
    return {
      pageResultCountList: [5, 10, 20, 50, 100],
      userList: [],
      statusList: [],
      lgaList: [],
      suburbList: [],
      typeList: ["Public", "Non-Dedicated", "Pre-Approval"],
      multiculturalList: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
        { label: "Unknown", value: "unknown" }
      ],
      aboriginalList: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
        { label: "Unknown", value: "unknown" }
      ],
      unapprovedGazzetteList: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      pagination: {
        record_count: 0,
        page: 1,
        limit: 10
      },
      qsParams: {
        includeTotal: true,
        orderDirection: "ASC",
        orderBy: "roadname"
      },
      filters: [
        {
          name: "classification",
          label: "Proposal Type",
          type: "text",
          options: "typeList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "lga",
          label: "LGA",
          type: "text",
          options: "lgaList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "suburb",
          label: "Locality",
          type: "text",
          options: "suburbList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "rnaIdentifier",
          label: "RNA",
          type: "object",
          options: "reducedRnaList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "proposer",
          label: "Submitted By",
          type: "text",
          options: "userList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "hasAboriginalDimension",
          label: "Aboriginal Name",
          type: "object",
          options: "aboriginalList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "hasMulticulturalDimension",
          label: "Multicultural Name",
          type: "object",
          options: "multiculturalList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "hasUnapprovedGazetteDimension",
          label: "Unapproved Gazette",
          type: "object",
          options: "unapprovedGazzetteList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "status",
          label: "Status",
          type: "text",
          options: "statusList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "finalisedDate",
          label: "Finalised Date",
          type: "date",
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      columns: [
        {
          label: "Road Name Proposal",
          field: "roadname",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Proposal Type",
          field: "roadname_classification",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "LGA",
          field: "lgas",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Locality",
          field: "suburbs",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "RNA",
          field: "authority_name",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Submitted By",
          field: "proposer",
          type: "text",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Submitted Date",
          field: "date_submitted",
          type: "date",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Aboriginal Name",
          field: "has_aboriginal_dimension",
          type: "boolean",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Multicultural Name",
          field: "has_multicultural_dimension",
          type: "boolean",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Unapproved Gazette",
          field: "unapproved_gazette",
          type: "boolean",
          isSortable: true,
          default: null,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Status",
          field: "name_status",
          type: "status",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Finalised Date",
          field: "date_finalised",
          type: "date",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      rows: []
    };
  }
};
</script>
