var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        {
          staticClass:
            "flex-none w-full md:max-w-xs bg-white text-white min-h-screen",
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center pt-14 pb-14 border-b",
              attrs: { "data-cypress": "side-bar-user-info" },
            },
            [
              _c("p", { staticClass: "text-label" }, [
                _vm._v(_vm._s(_vm.userTitle)),
              ]),
              _c("h3", { staticClass: "text-black mt-2 mb-2" }, [
                _vm._v(_vm._s(_vm.authority)),
              ]),
              _c(
                "div",
                { staticClass: "text-blue profile-link break-all" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "profile/view",
                          params: { id: _vm.user.email },
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(`${_vm.user.first_name} ${_vm.user.last_name}`)
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "flex flex-col justify-center text-center" },
            [
              _vm.user.isAdmin
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "flex-grow pt-5 pb-5 border-b menu-item cursor-pointer",
                      attrs: {
                        tag: "div",
                        to: { name: "administerUsers" },
                        "data-cypress": "administer-users-link",
                      },
                    },
                    [_vm._v("Administer Users")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "rnpRelevantParties" },
                        "data-cypress": "relevant-parties-link",
                      },
                    },
                    [_vm._v("Relevant Parties")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "pnpInterestedParties" },
                        "data-cypress": "interested-parties-link",
                      },
                    },
                    [_vm._v("Interested Parties")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        exact: "",
                        to: { name: "rnpRules" },
                        "data-cypress": "road-and-place-name-rules-link",
                      },
                    },
                    [_vm._v("Road Naming Rules")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        exact: "",
                        to: { name: "pnpRules" },
                        "data-cypress": "road-and-place-name-rules-link",
                      },
                    },
                    [_vm._v("Place Naming Rules")]
                  )
                : _vm._e(),
              (_vm.user.isRNP || _vm.user.isAdmin) && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "proposals",
                          query: {
                            road_naming_authority_id:
                              !_vm.user.isAdmin && !_vm.user.isASP
                                ? _vm.user.road_naming_authority_id
                                : "",
                          },
                        },
                        "data-cypress": "manage-road-name-proposals-link",
                      },
                    },
                    [_vm._v("Manage Proposals")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "road-name-types",
                        },
                        "data-cypress": "manage-roadname-types-link",
                      },
                    },
                    [_vm._v("Manage Road Name Types")]
                  )
                : _vm._e(),
              (_vm.user.isRNP || _vm.user.isAdmin) &&
              _vm.isRNPView &&
              !_vm.user.isASP
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "roadNameProposalBucket",
                          params: { proposalId: "create" },
                        },
                        "data-cypress": "create-roadname-proposals-link",
                      },
                    },
                    [_vm._v("Create Road Name Proposal")]
                  )
                : _vm._e(),
              (_vm.user.isASP || _vm.user.isAdmin) && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "unapprovedGazette",
                        },
                        "data-cypress": "create-roadname-proposals-link",
                      },
                    },
                    [_vm._v("Create Unapproved Gazette")]
                  )
                : _vm._e(),
              (_vm.user.isPNP || _vm.user.isPublic || _vm.user.isAdmin) &&
              _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "placeNameProposals",
                        },
                        "data-cypress": "manage-place-name-proposals-link",
                      },
                    },
                    [_vm._v("Manage Proposals")]
                  )
                : _vm._e(),
              (_vm.user.isPNP || _vm.user.isAdmin || _vm.user.isPublic) &&
              _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "placeNameProposal",
                          params: { proposalId: "create" },
                        },
                        "data-cypress": "create-place-name-proposals-link",
                      },
                    },
                    [_vm._v("Create Place Name Proposal")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "rnasManage" },
                        "data-cypress": "manage-rna-link",
                      },
                    },
                    [_vm._v("Manage RNAs")]
                  )
                : _vm._e(),
              !_vm.user.isAdmin &&
              _vm.user.isRNP &&
              _vm.isRNPView &&
              !_vm.user.isASP
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "rnasView",
                          params: { id: _vm.user.road_naming_authority_id },
                        },
                        "data-cypress": "my-rna-link",
                      },
                    },
                    [_vm._v("My RNA")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "organisationsManage" },
                        "data-cypress": "manage-organisation-link",
                      },
                    },
                    [_vm._v("Manage Organisations")]
                  )
                : _vm._e(),
              !_vm.user.isAdmin && _vm.user.isPNP && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: {
                          name: "organisationsView",
                          params: {
                            identifier: _vm.user.organisation_identifier,
                          },
                        },
                        "data-cypress": "my-organisation-link",
                      },
                    },
                    [_vm._v("My Organisation")]
                  )
                : _vm._e(),
              !_vm.user.isAdmin && !_vm.user.isASP && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "roadNameProposalBulkGazette" },
                        "data-cypress": "gazettal-purchase-order-link",
                      },
                    },
                    [_vm._v("Bulk Gazette")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        exact: "",
                        tag: "div",
                        to: { name: "gazettalOrder" },
                        "data-cypress": "gazettal-purchase-order-link",
                      },
                    },
                    [_vm._v("Road Name Similarity Threshold")]
                  )
                : _vm._e(),
              _c(
                "router-link",
                {
                  staticClass:
                    "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                  attrs: {
                    tag: "div",
                    to: {
                      name: "profile/view",
                      params: { id: _vm.user.email },
                    },
                    "data-cypress": "manage-my-profile-link",
                  },
                },
                [_vm._v("Manage My Profile")]
              ),
              _vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        to: { name: "geoNameSearchGNB" },
                        "data-cypress": "switch-to-road-names-link",
                      },
                    },
                    [_vm._v("Manage GNR")]
                  )
                : _vm._e(),
              _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        to: { name: "placeNameReport" },
                        "data-cypress": "place-name-report-link",
                      },
                    },
                    [_vm._v("Place Name Proposal Summary")]
                  )
                : _vm._e(),
              _vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        to: { name: "lgaUpdateService" },
                        "data-cypress": "place-name-report-link",
                      },
                    },
                    [_vm._v("LGA Update Service")]
                  )
                : _vm._e(),
              _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        id: "roadNameReport",
                        to: { name: "roadNameReport" },
                        "data-cypress": "road-name-report-link",
                      },
                    },
                    [_vm._v("Road Name Proposal Summary")]
                  )
                : _vm._e(),
              _vm.isRNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        id: "roadNameSearchReport",
                        to: { name: "roadNameSearchReport" },
                        "data-cypress": "road-name-search-report-link",
                      },
                    },
                    [_vm._v("Road Name Search Report")]
                  )
                : _vm._e(),
              !_vm.user.isAdmin && _vm.isPNPView
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: {
                        tag: "div",
                        to: { name: "geoNameSearchPublic" },
                        "data-cypress": "geoname-public-search-link",
                      },
                    },
                    [_vm._v("Search Place Names")]
                  )
                : _vm._e(),
              (_vm.user.isRNP || _vm.user.isAdmin) && _vm.isPNPView
                ? _c(
                    "div",
                    {
                      key: _vm.$route.fullPath,
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: { "data-cypress": "switch-to-road-names-link" },
                      on: {
                        click: function ($event) {
                          return _vm.gotoRNP()
                        },
                      },
                    },
                    [
                      _c("Road", { staticClass: "menu-img hover:text-white" }),
                      _vm._v("Road Names "),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.user.isPNP || _vm.user.isAdmin) && _vm.isRNPView
                ? _c(
                    "div",
                    {
                      key: _vm.$route.fullPath,
                      staticClass:
                        "menu-item cursor-pointer flex-grow pt-5 pb-5 border-b",
                      attrs: { "data-cypress": "switch-to-place-names-link" },
                      on: {
                        click: function ($event) {
                          return _vm.gotoPNP()
                        },
                      },
                    },
                    [
                      _c("Tree", { staticClass: "menu-img" }),
                      _vm._v("Place Names "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }