var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c("cancelProposalModal"),
      _c("convertToNonDedicatedModal", {
        attrs: { beforeClose: _vm.beforeClose },
      }),
      _c("section", { staticClass: "justify-center w-full block" }, [
        _c("div", { staticClass: "flex justify-between" }, [
          _c(
            "a",
            {
              staticClass: "cursor-pointer flex justify-between self-center",
              on: {
                click: function ($event) {
                  _vm.$router.push({
                    name: "proposals",
                    query: { ..._vm.roadSpecificQueryString() },
                  })
                },
              },
            },
            [
              _c("BackArrowWithTitle", {
                attrs: { title: "View Road Name Proposal" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("showLoading", {
        attrs: { isLoading: "loadingProposal" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _c("div", [
                  _c(
                    "section",
                    {
                      staticClass:
                        "justify-center w-full block self-center bg-white text-black rounded-lg pt-8 mt-3 mb-3",
                    },
                    [
                      _c("div", { staticClass: "w-9/10 flex-col mx-auto" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex-1 self-center flex justify-between pb-6",
                          },
                          [
                            _c("div", { staticClass: "self-center" }, [
                              _c("h2", { staticClass: "self-center mb-2" }, [
                                _vm._v(
                                  "Proposal " +
                                    _vm._s(_vm.proposalBucket.reference_no)
                                ),
                              ]),
                              _c("p", { staticClass: "pb-3" }, [
                                _vm._v(
                                  " Submitted " +
                                    _vm._s(_vm.dateSubmitted) +
                                    " by "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-1 text-blue-button text-normal-bold",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.proposalBucket.authority_name)
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-wrap flex-start" },
                                _vm._l(
                                  _vm.proposalBucket.classificationList,
                                  function (item, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeRoadClassification(
                                              {
                                                target: { value: item.name },
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("proposalButton", {
                                          attrs: {
                                            label:
                                              item.amount +
                                              ` ` +
                                              item.name +
                                              ` Roads`,
                                            status:
                                              item.roadname_classification,
                                            classNames: "text-normal-bold",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "dividing-line-h-1 w-full" }),
                      _c("div", { staticClass: "w-9/10 flex-col mx-auto" }, [
                        _c(
                          "div",
                          { staticClass: "self-center justify pt-4 flex" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex w-1/8 float-left mt-4" },
                              [_c("h3", [_vm._v(_vm._s(_vm.numberOfRoads))])]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-wrap w-5/6 mb-4" },
                              _vm._l(
                                _vm.proposalBucket.statusList,
                                function (item, index) {
                                  return _c(
                                    "span",
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatus({
                                            target: { value: item.status },
                                          })
                                        },
                                      },
                                    },
                                    [
                                      item.amount === 0
                                        ? _c("StatusButton", {
                                            attrs: {
                                              label: item.status,
                                              status: item.status,
                                              classNames:
                                                "text-normal-bold  cursor-pointer",
                                            },
                                          })
                                        : _vm._e(),
                                      item.amount !== 0
                                        ? _c("StatusButton", {
                                            attrs: {
                                              label:
                                                item.amount + ` ` + item.status,
                                              status: item.status,
                                              classNames:
                                                "text-normal-bold cursor-pointer ",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "section",
                    {
                      staticClass:
                        "pt-12 mb-20 justify-center w-full block self-center",
                    },
                    [
                      _c("h2", { staticClass: "text-black pb-8" }, [
                        _vm._v("Roads in Proposal"),
                      ]),
                      _c("div", { staticClass: "flex" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.roadSearch,
                              expression: "roadSearch",
                            },
                          ],
                          staticClass:
                            "mr-4 h-12 pl-4 pt-3 pb-3 rounded-lg text-normal w-1/2",
                          attrs: { placeholder: "Narrow search by Road Name…" },
                          domProps: { value: _vm.roadSearch },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.roadSearch = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.classificationFilter,
                                expression: "classificationFilter",
                              },
                            ],
                            staticClass:
                              "h-12 mr-4 pt-3 pb-3 text-normal w-1/4 bg-white self-center rounded",
                            attrs: {
                              disabled: _vm.classificationList.length === 2,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.classificationFilter = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changeRoadClassification,
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                staticClass:
                                  "dropdown my-4 font-light pl-4 h-12",
                                attrs: {
                                  value: "",
                                  disabled: "",
                                  hidden: "",
                                  selected: "",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "my-4 text-normal text-black",
                                  },
                                  [_vm._v("All Types")]
                                ),
                              ]
                            ),
                            _vm._l(
                              _vm.classificationList,
                              function (type, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    staticClass:
                                      "dropdown my-4 font-light pl-4 h-12",
                                    domProps: {
                                      value: type.roadname_classification,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "my-4" }, [
                                      _vm._v(_vm._s(type.name)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusFilter,
                                expression: "statusFilter",
                              },
                            ],
                            staticClass:
                              "h-12 pt-3 pb-3 text-normal w-1/4 bg-white self-center rounded",
                            attrs: { disabled: _vm.statusList.length === 2 },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.statusFilter = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changeStatus,
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                staticClass:
                                  "dropdown my-4 font-light pl-4 h-12",
                                attrs: {
                                  value: "",
                                  disabled: "",
                                  hidden: "",
                                  selected: "",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "my-4 text-normal text-black",
                                  },
                                  [_vm._v("All Statuses")]
                                ),
                              ]
                            ),
                            _vm._l(_vm.statusList, function (status, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  staticClass: "dropdown my-4 pl-4 h-12",
                                  domProps: { value: status.status },
                                },
                                [
                                  _c("div", { staticClass: "my-4" }, [
                                    _vm._v(_vm._s(status.status)),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._l(_vm.roads, function (road, index) {
                        return _c("roadInProposal", {
                          key: index,
                          attrs: {
                            road: road,
                            showWithdraw: _vm.showWithdraw,
                            showConvertToNonDedicated:
                              _vm.showConvertToNonDedicated,
                            proposalId: _vm.proposalBucket.identifier,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }