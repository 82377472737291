<template>
  <clarification-response :entryData="transformedEntryData" />
</template>

<script>
import ClarificationResponse from "@/components/proposals/common/audit-trail/entries/clarification-response";

export default {
  name: "ClarificationResponseTrailEntryContainer",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    ClarificationResponse
  },
  computed: {
    transformedEntryData() {
      const transformedData = {};
      transformedData.createdAt = this.entryData.createdAt;
      if (this.entryData.response && this.entryData.response) {
        transformedData.response = this.entryData.response.to;
      }
      return transformedData;
    }
  }
};
</script>
