<template>
  <div class="pl-4 flex flex-auto flex-row list-item" v-if="!!user">
    <div class="flex sm:flex-col sm:items-start flex-no-grow w-1/4 py-4">
      <div
        class="flex-auto flex justify-between content-between items-center w-full"
      >
        <div
          class="sm:hidden xl:flex w-12 h-12 rounded-full mr-4 bg-grey-lighter  justify-center items-center flex-none"
        >
          <div class="flex-none">
            <font-awesome-icon icon="user" class="text-2xl" />
          </div>
        </div>
        <div class="flex-grow flex flex-col justify-start items-start">
          <router-link
            tag="p"
            class="flex-auto pr-2 font-bold cursor-pointer"
            data-cypress="user-name-link"
            :to="{ name: 'profile/view', params: { id: user.email } }"
            >{{ name }}</router-link
          >
          <p class="text-base" data-cypress="user-authority-name">
            {{ user.authority_name || user.organisation_name }}
          </p>
        </div>
      </div>
    </div>
    <div class="dividing-line-v-2 mx-4"></div>
    <div
      class="py-4 flex-grow flex  sm:flex-row flex-wrap items-stretch justify-around content-start"
    >
      <div class="flex-1   flex flex-col justify-start">
        <div class="flex-auto text-label">
          <span>Contact</span>
          <div class="flex-no-grow flex flex-col break-all">
            <a
              data-cypress="user-email-link"
              class="text-black pb-2 pr-4 flex-1 flex align-center"
              :title="user.email"
              :href="mailLink"
              ><font-awesome-icon
                icon="envelope"
                class="pr-2 text-2xl flex-none"
              />
              {{ user.email }}
            </a>
            <a
              data-cypress="user-telephone-link"
              :href="telLink"
              rel="tel"
              class="flex-1 flex align-center"
            >
              <font-awesome-icon icon="phone" class="pr-2 text-2xl flex-none" />
              <span class="telephone-link  flex-none">{{ user.phone_no }}</span>
            </a>
          </div>
        </div>
      </div>
      <UserProperty
        name="User Type"
        class="flex-none flex flex-col justify-around items-center"
      >
        <div class="flex flex-col" data-cypress="user-type">
          <div
            class="account-type flex-1 flex flex-row justify-between text-black"
            v-for="type in accountType"
            v-bind:key="type"
          >
            <Tree
              v-if="type === 'PNP'"
              class="flex-none text-black w-6 h-6 pr-2"
            />
            <Road
              v-if="type === 'RNP'"
              class="flex-none menu-img text-black w-6 h-6 pr-2"
            />
            <span class="flex-auto">{{ type }}</span>
          </div>
        </div>
      </UserProperty>
      <UserProperty
        name="Date Created"
        :value="dateCreated"
        class="flex-1 flex flex-col justify-around items-center"
        data-cypress="date-created"
      />

      <!-- account status -->
      <div class="flex-0 flex justify-end">
        <div class="flex-1 flex items-center justify-center">
          <AccountStatus
            :account_status="user.user_account_status"
            data-cypress="user-account"
          />
        </div>
      </div>
    </div>
    <div class="dividing-line-v-2 ml-4"></div>
    <div class="flex-no-grow flex flex-col sm:items-end sm:justify-center">
      <!-- actions -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import UserProperty from "./userProperty";
import Road from "../../assets/Images/road-dark-blue.svg";
import Tree from "../../assets/Images/tree-dark-blue.svg";

import userDetailsMixin from "../../mixins/user-details";

export default {
  name: "UserListItem",
  components: {
    UserProperty,
    Road,
    Tree
  },
  mixins: [userDetailsMixin],
  props: ["user"]
};
</script>

<style scoped>
.list-item {
  min-height: 6em;
}

.telephone-link {
  font-weight: normal;
  color: black !important;
}

.email {
  color: black !important;
}
</style>
