var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c("div", { staticClass: "w-full flex bg-white" }, [
      _c(
        "div",
        { staticClass: "w-5/6 mx-auto", class: { "py-16": !_vm.isPublic } },
        [
          _c(
            "h3",
            { staticClass: "pb-3 mb-8 w-full text-title dividing-line-h" },
            [_vm._v(" Geographical Details ")]
          ),
          _vm.isTypePlaceNameProposal
            ? _c("Place", { attrs: { readOnly: _vm.readOnly } })
            : _vm._e(),
          _vm.isTypeLocalityBoundaryAmendment
            ? _c("Boundary", { attrs: { readOnly: _vm.readOnly } })
            : _vm._e(),
          _vm.isTypeNewAmendedLocality
            ? _c("Locality", { attrs: { readOnly: _vm.readOnly } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }