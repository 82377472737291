var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"},[_c('img',{staticClass:"absolute h-12 logoPos",attrs:{"src":require("../../../../assets/Logos/GNB logo_White_RGB.png")}}),_c('div',{staticClass:"w-2/3 bg-white mx-auto my-3 z-10 rounded"},[_c('div',{staticClass:"w-full bg-white text-black rounded-lg p-10"},[_c('div',[_c('span',{staticClass:"text-red text-normal-bold cursor-pointer",on:{"click":function($event){return _vm.back()}}},[_c('font-awesome-icon',{staticClass:"flex-auto",attrs:{"icon":"arrow-left"}}),_vm._v(" Back ")],1)]),_c('h3',{staticClass:"pb-3 mb-8 w-full text-title dividing-line-h"},[_vm._v(" View "+_vm._s(_vm.proposal.geographical_name)+" ")]),_c('ShowLoading',{attrs:{"isLoading":"fetchingProposal"},scopedSlots:_vm._u([{key:"component",fn:function(){return [_c('div',{staticClass:"mx-20"},[(
                _vm.proposal.official_map_reference_document &&
                  _vm.proposal.official_map_reference_document.length
              )?_c('p',[_c('a',{staticClass:"text-blue-button",attrs:{"href":_vm.relativeFileUrl(
                    _vm.proposal.official_map_reference_document[0].s3_key
                  ),"target":"_blank"}},[_vm._v("View Official Map ")])]):_vm._e(),(
                _vm.proposal.gazette_reference_document &&
                  _vm.proposal.gazette_reference_document.length
              )?_c('p',[_c('a',{staticClass:"text-blue-button",attrs:{"href":_vm.relativeFileUrl(
                    _vm.proposal.gazette_reference_document[0].s3_key
                  ),"target":"_blank"}},[_vm._v("View Gazette Notice ")])]):_vm._e(),(
                _vm.proposal.media_release_reference_document &&
                  _vm.proposal.media_release_reference_document.length
              )?_c('p',[_c('a',{staticClass:"text-blue-button",attrs:{"href":_vm.relativeFileUrl(
                    _vm.proposal.media_release_reference_document[0].s3_key
                  ),"target":"_blank"}},[_vm._v("View Media Release ")])]):_vm._e(),_c('p',[_c('a',{staticClass:"text-blue-button",attrs:{"data-cypress":"viewplace-button","role":"button"},on:{"click":function($event){return _vm.viewPlaceNameInfo()}}},[_vm._v(" View Place Name Record ")]),_c('br')]),_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"pb-3 mb-3 w-full text-title"},[_vm._v(" GEOGRAPHICAL NAMES ACT 1966 ")])]),_c('p',{staticClass:"mb-3"},[_vm._v(" In accordance with Section 9 of the Geographical Names Act 1966, all submissions lodged may be subject to a Government Information (Public Access) application and may be viewed by a third party to assist the Board in considering this proposal. ")]),_c('p',{staticClass:"text-right mb-3 mt-4"},[_vm._v(" NARELLE UNDERWOOD "),_c('br'),_vm._v(" Chair ")]),_c('p',{staticClass:"mb-6"},[_vm._v(" Geographical Names Board"),_c('br'),_vm._v(" PO Box 143"),_c('br'),_vm._v(" BATHURST NSW 2795 ")])]),_c('div',{staticClass:"dividing-line-h my-6"}),_c('div',{staticClass:"flex justify-center"},[_c('Button',{staticClass:"button-blue-hollow flex justify-center mr-3",attrs:{"data-cypress":"return-button","disabled":false},on:{"click":function($event){return _vm.backToProposals()}}},[_vm._v("Return to Current Proposals Page ")]),(_vm.canComment)?_c('ButtonWithSpinner',{staticClass:"button-blue flex justify-center",attrs:{"data-cypress":"comment-button","disabled":false},on:{"click":function($event){return _vm.goToCommentForm()}}},[_vm._v("Comment on Proposal")]):_vm._e()],1)]},proxy:true}])})],1)])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }