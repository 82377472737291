<template>
  <div
    id="uploadDiv"
    data-cypress="upload-box"
    class="my-4 w-full"
    :class="classNames"
  >
    <p class="pb-3 text-normal-bold">
      <a class="text-red-star" v-if="important">*</a>
      {{ label }}
    </p>
    <div class="flex w-full">
      <label class="flex w-full">
        <div
          class="w-7/8 flex p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded"
          v-bind:class="{ 'disabled-file-picker': disabled }"
          ref="fileform"
        >
          Click or drag file here to upload...
          <input
            type="file"
            class="hidden"
            ref="fileInput"
            data-cypress="file-upload-input"
            @change="filesChange($event.target.files)"
            :disabled="disabled"
          />
        </div>

        <ButtonWithSpinner
          class="bg-blue-button rounded rounded-l-none relative w-1/8 flex justify-center items-center cursor-pointer text-white text-normal font-semibold"
          :disabled="isWaiting || disabled"
          :isSpinning="isWaiting"
          data-cypress="addFilesButton"
          @click="$refs.fileInput.click()"
          >Add Files</ButtonWithSpinner
        >
      </label>
    </div>
    <div class="mb-4" v-for="(file, index) in files" v-bind:key="index">
      <div
        class="w-full mt-5 flex items-center rounded-lg border border-grey-border"
      >
        <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
        <p class="textNormal" data-cypress="file-title">{{ file.title }}</p>
        <a
          class="cursor-pointer ml-auto text-normal-bold text-blue-button"
          :href="relativeFileUrl(file.s3_key)"
          >View File</a
        >
        <button
          class="cursor-pointer ml-10 mr-8 text-normal-bold"
          v-bind:class="{
            'text-grey-light': !drafted || disabled,
            'text-red-button': drafted && !disabled
          }"
          data-cypress="removefiles"
          @click="removeFile(index)"
          :disabled="disabled"
        >
          Remove
        </button>
      </div>
      <div class="document-type-select">
        <v-select
          :key="file.s3_key"
          @input="event => setFileType(event, index)"
          :value="file.document_type"
          label="label"
          :class="`${file.document_type} bg-grey-input`"
          :reduce="type => type.value"
          :options="[
            { value: 'history', label: 'History (Private)' },
            {
              value: 'history_unverified_approved',
              label: 'Unverified Approved (Public)'
            },
            {
              value: 'history_verified_approved',
              label: 'Verified Approved (Public)'
            },
            {
              value: 'history_verified_rejected',
              label: 'Verified Rejected (Private)'
            },
            {
              value: 'history_unverified_rejected',
              label: 'Unverified Rejected (Private)'
            }
          ]"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { deleteFile } from "../../helpers/s3";
import ButtonWithSpinner from "./buttonWithSpinner";

export default {
  components: {
    ButtonWithSpinner
  },
  props: {
    label: {
      type: String
    },
    files: {
      type: Array
    },
    important: {
      type: Boolean
    },
    classNames: {
      type: String
    },
    drafted: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    stateKey: {
      type: String
    },
    stateProp: {
      type: String
    },
    uploadFile: {
      type: Function
    }
  },
  $_veeValidate: {
    name() {
      return "fileUploader";
    },
    value() {
      return this.files.length;
    }
  },
  data() {
    return {
      s3Key: Date.now(),
      isWaiting: false,
      uploadNum: 0,
      dragAndDropCapable: false
    };
  },
  methods: {
    ...mapMutations(["deleteUploadedFile"]),
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    setFileType(value, index) {
      this.$emit("fileTypeChanged", { value, index });
    },
    async filesChange(fileList) {
      this.isWaiting = true;
      this.uploadNum = fileList.length;
      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        await this.uploadFile({
          key: this.stateKey,
          prop: this.stateProp,
          fileData: { file, s3Key: this.s3Key, document_type: "history" }
        });
      }
      this.isWaiting = false;
      this.$emit("uploadfinished");
    },
    removeFile(fileIndex) {
      if (this.drafted) {
        deleteFile(this.files[fileIndex].s3_key);
        this.$emit("deleteFile", fileIndex);
      } else {
        this.$notify({
          group: "toast",
          type: "notice",
          title: "Cannot remove",
          text: "Files can only be removed from drafted proposals"
        });
      }
    },
    /*
       Determines if the drag and drop functionality is in the
       window
    */
    determineDragAndDropCapable() {
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement("div");

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.
      */
      return "draggable" in div || ("ondragstart" in div && "ondrop" in div);
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    this.drafted === !!this.drafted;

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          /*
        For each event add an event listener that prevents the default action
        (opening the file in the browser) and stop the propagation of the event (so
        no other elements open the file in the browser)
      */
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
      Add an event listener for drop to the form
    */
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          /*
        Capture the files from the drop event and handle them
      */
          this.filesChange(e.dataTransfer.files);
        }.bind(this)
      );
    }
  }
};
</script>

<style>
.history_unverified_approved {
  color: #4caf50 !important;
  font-weight: 700;
}

.history_verified_approved {
  color: #4caf50 !important;
  font-weight: 700;
}

.history_verified_rejected {
  color: #f44336 !important;
  font-weight: 700;
}

.history_unverified_rejected {
  color: #f44336 !important;
  font-weight: 700;
}

.vs__selected {
  padding: 4px !important;
}

.vs__clear {
  display: none;
}

.vs-actions {
  margin-right: 4px !important;
}

.history {
  font-weight: 700;
}

.document-type-select {
  width: 35%;
  min-width: 300px;
}

.disabled-file-picker {
  background-color: #d4d8da !important;
}
</style>
