var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-3 py-3 justify-center md:mb-0" },
    [
      _c(
        "label",
        { staticClass: "container items-baseline flex height-fix" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.label))]
          }),
          _c("input", {
            staticClass: "checkbox-input",
            attrs: {
              type: "checkbox",
              disabled: _vm.$attrs.disabled,
              name: _vm.$attrs.name,
            },
            domProps: { checked: _vm.value },
            on: {
              change: function ($event) {
                return _vm.$emit("input", $event.target.checked)
              },
            },
          }),
          _c("span", { staticClass: "border-2 checkmark" }),
        ],
        2
      ),
      _c("FieldErrorMessage", { attrs: { message: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }