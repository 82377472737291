import { render, staticRenderFns } from "./locality.vue?vue&type=template&id=97a3db0c&"
import script from "./locality.vue?vue&type=script&lang=js&"
export * from "./locality.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97a3db0c')) {
      api.createRecord('97a3db0c', component.options)
    } else {
      api.reload('97a3db0c', component.options)
    }
    module.hot.accept("./locality.vue?vue&type=template&id=97a3db0c&", function () {
      api.rerender('97a3db0c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-form/geographical-details/components/locality.vue"
export default component.exports