<template>
  <section class="flex h-full flex-col">
    <navigationMenu class="flex-initial"></navigationMenu>

    <div class="md:flex flex-auto justify-start">
      <sidebarMenu></sidebarMenu>
      <!-- main section -->
      <div
        class="flex-grow flex bg-grey-lighter shadow-lgInverseRight text-white"
      >
        <ShowLoading isLoading="routeData">
          <template v-slot:component>
            <transition appear name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </template>
        </ShowLoading>
      </div>
    </div>
    <modal
      name="authentication-modal"
      :maxWidth="540"
      :scrollable="true"
      :clickToClose="false"
      height="auto"
      @before-close="beforeClose"
    >
      <authenticationModal />
    </modal>
  </section>
</template>

<script>
import navigationMenu from "../components/navigation/navigationMenu";
import sidebarMenu from "../components/dashboard/sidebarMenu";
import { mapActions, mapGetters } from "vuex";
import { waitFor } from "vue-wait";
import authenticationModal from "@/components/authentication/authenticationModal.vue";

export default {
  name: "Dashboard",
  components: { navigationMenu, sidebarMenu, authenticationModal },
  methods: {
    ...mapActions(["getAllLgasFromServiceSq"]),
    init: waitFor("load init data", async function() {
      // await this.getAllLgasFromServiceSq();
      this.$store.commit("updateLoginModalStatus", false);
    }),
    beforeClose() {
      this.$store.commit("updateLoginModalStatus", false);
    }
  },
  computed: {
    ...mapGetters(["showLoginModal"])
  },
  watch: {
    async showLoginModal() {
      await this.$nextTick();
      if (this.showLoginModal) {
        this.$modal.show("authentication-modal");
      }
    }
  },
  async created() {
    await this.init();
  },
  beforeDestroy() {
    clearTimeout(this.modalTimeout);
    this.$store.commit("updateLoginModalStatus", false);
    this.$modal.hide("authentication-modal");
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
