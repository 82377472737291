var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "absolute h-12 logoPos",
        attrs: {
          src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
        },
      }),
      _c("div", { staticClass: "w-2/3 bg-white mx-auto my-3 z-10 rounded" }, [
        _c(
          "div",
          { staticClass: "w-full bg-white text-black rounded-lg p-10" },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "flex justify-center mb-24" },
              [
                _c(
                  "Button",
                  {
                    staticClass: "button-blue-hollow flex justify-center mr-3",
                    attrs: { "data-cypress": "return-button", disabled: false },
                    on: {
                      click: function ($event) {
                        return _vm.backToProposals()
                      },
                    },
                  },
                  [_vm._v("Return to Current Proposals Page ")]
                ),
                _c(
                  "Button",
                  {
                    staticClass: "button-blue flex justify-center",
                    attrs: {
                      "data-cypress": "comment-button",
                      disabled: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.goToCommentForm()
                      },
                    },
                  },
                  [_vm._v("Submit a Comment ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full align-center text-center mt-16" }, [
      _c("img", {
        staticClass: "gnb-logo",
        attrs: { src: require("../../../../assets/Logos/gnb.jpg") },
      }),
      _c("h3", { staticClass: "pb-3 mb-4 text-title" }, [
        _vm._v(" Comment on Place Name Proposal "),
      ]),
      _c("p", { staticClass: "mb-8 mx-16" }, [
        _vm._v(
          " In accordance with Section 9 of the Geographical Names Act 1966, all submissions lodged may be subject to a "
        ),
        _c("span", { staticClass: "span-blue font-bold" }, [
          _vm._v("Government Information (Public Access) act"),
        ]),
        _vm._v(
          " application and may be viewed by a third party to assist the Board in considering this proposal. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }