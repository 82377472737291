<template>
  <div class="base-container">
    <section class="justify-center w-full block">
      <a
        v-if="proposalId !== 'bulk'" class="flex justify-between"
        @click="
          $router.push({ name: 'gazettalNotice', params: { road: roads[0] } })
        "
      >
        <BackArrowWithTitle title="Preview Gazettal Notice" />
      </a>
      <showLoading isLoading="fetching rna details">
        <template v-slot:component>
          <div
            class="w-full bg-white text-black rounded-lg pl-8 pr-8 pt-24 pb-24 mt-4 justify-center flex"
          >
            <div class="w-4/5 mx-auto" v-if="multipleRNAError">
              <p class="text-center pb-3">
                <font-awesome-icon
                  class=" flex-0 text-red text-3xl inline"
                  icon="exclamation-circle"
                />
              </p>
              <p>
                You can only gazette multiple roads if they are proposed by the
                same Road Naming Authority
              </p>
            </div>
            <gazette-mock :roads="roads" :rna="rna" :user="user"></gazette-mock>
          </div>

          <div class="flex pt-4 text-center pb-10">
            <router-link
              v-if="proposalId !== 'bulk'"
              :to="{ name: 'gazettalNotice', params: { road: roads[0] } }"
              class="button-blue-hollow flex-no-grow"
              >Return to draft</router-link
            >
            <router-link
              v-if="proposalId === 'bulk'"
              :to="{ name: 'roadNameProposalBulkGazette' }"
              class="button-blue-hollow flex-no-grow"
              >Return to bulk gazettte</router-link
            >
            <div class="flex-grow"></div>
            <ButtonWithSpinner
              class="button-blue relative flex flex-no-grow justify-center whitespace-no-wrap font-semibold"
              data-cypress="submit-gazettal-notice-button"
              :disabled="$wait.is('saving gazette') || multipleRNAError"
              :isSpinning="$wait.is('saving gazette')"
              @click="submit"
              >Create Gazettal Notice</ButtonWithSpinner
            >
          </div>
        </template></showLoading
      >
    </section>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { getRNA } from "../../helpers/rnaCRUD";
import { createGazettalNotice } from "../../helpers/rnpCRUD";
import { waitFor } from "vue-wait";
import gazetteMock from "./gazette-mock";

export default {
  name: "gazettalPreview",
  components: {
    gazetteMock
  },
  props: {
    roads: { type: Array },
    uploads: { type: Array },
    proposalId: { type: String },
    bulk: { type: Boolean, default: false }
  },
  data() {
    return {
      rna: {},
      errorFetching: false,
      multipleRNAError: false
    };
  },
  computed: {
    ...mapUserGetters(["user"])
  },
  async created() {
    const allRnas = this.roads.map(road => road.road_naming_authority_id);
    if (allRnas.every(rna => rna === allRnas[0])) {
      await this.fetchRNA();
    } else {
      this.multipleRNAError = true;
    }
  },
  methods: {
    fetchRNA: waitFor("fetching rna details", async function() {
      try {
        this.rna = await getRNA(this.roads[0].road_naming_authority_id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.errorFetching = true;
      }
    }),
    submit: waitFor("saving gazette", async function() {
      try {
        if (this.proposalId === "bulk") {
          this.$router.push({
            name: "roadNameProposalBulkGazetteSubmit",
            params: {
              roads: this.roads,
              uploads: this.uploads
            }
          });
        } else {
          await createGazettalNotice(this.roads[0], this.uploads);
          this.$router.push({
            name: "gazettalSubmit",
            params: {
              road: this.roads[0],
              proposalId: this.proposalId
            }
          });
        }
      } catch (e) {
        const text =
          e.response.status === 409
            ? e.response.data.message
            : "There has been an error creating your gazettal notice. Please try again later";
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error creating gazette notice",
          text
        });
      }
    })
  }
};
</script>

<style scoped>
.tableRow {
  border: black 1px solid;
}
</style>
