<template>
  <clarification-request :entryData="transformedEntryData" />
</template>

<script>
import ClarificationRequest from "@/components/proposals/common/audit-trail/entries/clarification-request";

export default {
  name: "ClarificationRequestTrailEntryContainer",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    ClarificationRequest
  },
  computed: {
    transformedEntryData() {
      const transformedData = {};
      transformedData.createdAt = this.entryData.createdAt;
      if (this.entryData.request && this.entryData.request) {
        transformedData.request = this.entryData.request.to;
      }
      return transformedData;
    }
  }
};
</script>
