<template>
  <div class="base-container">
    <ShowLoading isLoading="loading Proposal">
      <template v-slot:component>
        <NetworkError v-if="errorLoading" />
        <section
          class="pt-1 justify-center w-full block"
          v-if="!errorLoading && !!proposal"
        >
          <a
            @click="$router.go(-1)"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
            v-if="!unique && !token"
          >
            <BackArrowWithTitle title="Endorsement Information" />
          </a>

          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 font-semibold"
          >
            <div class="w-9/10 flex mx-auto content-center pt-4">
              <div>
                <h2>{{ geographical_name }}</h2>
                <template v-if="lga_name.length">
                  <h4 class="text-blue">Local Government Area(s)</h4>
                  <div v-for="lga in lga_name" v-bind:key="lga.index">
                    <span class="flex">{{ lga }}</span>
                  </div>
                </template>
              </div>
              <StatusButton
                :label="proposal.display_status"
                :status="proposal.display_status"
                class="ml-auto mr-6 font-semibold text-sm"
              />
            </div>
          </div>

          <div
            class="w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start"
            v-if="recentBoardMeeting"
          >
            <div class="w-9/10 mx-auto">
              <span class="self-center pt-10 w-full block">
                <h2 class="text-title text-black">Endorsement Information:</h2>

                <p class="self-center text-normal text-grey-text">
                  Board meeting decision date: {{ recentBoardMeetingDate }}
                </p>

                <div class="flex justify-center">
                  <span class="dividing-line-h w-full mt-4"></span>
                </div>

                <p
                  class="self-center textRejectionReason text-normal text-black mt-5 pb-10"
                >
                  {{ recentBoardMeeting.summary }}
                </p>
              </span>
            </div>
          </div>
          <div class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4">
            <div class="w-9/10 mx-auto">
              <p class="text-normal-bold content-start mb-2 mt-10">
                <span class="text-red-star">*</span>Information
              </p>

              <textarea
                class="text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input"
                v-model="endorsementText"
                placeholder="Please provide any additional information relating to the endorsement"
                :disabled="$wait.any"
              ></textarea>

              <uploadComponent
                label="Attach endorsement documentation"
                class="mb-10"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                :disabled="uploading"
                :drafted="true"
                :deleteFile="deleteFunction"
              />
            </div>
          </div>
          <div class="flex pt-4 text-center pb-10 justify-end">
            <ButtonWithSpinner
              @click="submitEndorsement"
              class="p-3 ml-4 w-1/5 button-blue"
              :disabled="
                $wait.is('submitting endorsement information') ||
                  uploading ||
                  isDisabled
              "
              :isSpinning="$wait.is('submitting endorsement information')"
              >Submit Information</ButtonWithSpinner
            >
          </div>
        </section>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import moment from "moment";
import { createNamespacedHelpers, mapGetters, mapMutations } from "vuex";
import { deleteSupportingDocument } from "@/helpers/s3";
import pnpCruds from "@/helpers/pnpCRUD";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY
} from "@/components/proposals/place/pnp-constants.js";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  name: "AddEndorsementInformation",

  /**
   * Instance child components
   */
  components: {},

  /**
   * Instance props
   */
  props: {
    proposalId: String
  },

  /**
   * The instance was created
   */
  async created() {
    await this.fetchProposal();
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * Retrieve the proposal from the store
     */
    proposal() {
      return this.$store.state.pnpState.proposal;
    },
    type: {
      get() {
        return this.getProposalType();
      }
    },
    geographical_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE)
          return this.get_place_name_geographical_name();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_geographical_name();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_geographical_name();
        else return null;
      }
    },
    lga_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE) return this.get_place_name_lga();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_lga();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_lga();
        else return null;
      }
    },
    /**
     * The sorted (by date) board decisions
     */
    boardDecisions() {
      return this.proposal.board_decisions;
    },

    /**
     * determine the most recent boad meeting from
     * the last provided
     */
    recentBoardMeeting() {
      if (!this.boardDecisions || this.boardDecisions.length === 0) {
        return null;
      }

      return this.boardDecisions.filter(bd => {
        return bd.decision === "PENDING ENDORSEMENT";
      })[0];
    },

    /**
     * Return a formatted meeting date for the recent
     * board meeting
     */
    recentBoardMeetingDate() {
      return moment(this.recentBoardMeeting.date).format("DD MMM YYYY");
    },
    isDisabled() {
      return !this.endorsementText;
    },
    isPublic() {
      return this.$route.meta && this.$route.meta.isPublic;
    },
    token() {
      return this.$route.query ? this.$route.query.token : null;
    },
    unique() {
      return this.$route.query ? this.$route.query.unique : null;
    },
    ...mapGetters({
      uploadedFiles: "uploadedFilesState"
    })
  },

  /**
   * Local instance data
   */
  data() {
    return {
      uploading: false,
      errorLoading: false,
      endorsementText: "",
      deleteFunction: deleteSupportingDocument
    };
  },

  /**
   * Instance methods
   */
  methods: {
    /**
     *
     */
    ...mapPnpActions(["selectProposal", "selectProposalPublic"]),
    ...mapPnpGetters([
      "get_place_name_geographical_name",
      "get_address_locality_geographical_name",
      "get_boundary_amendment_geographical_name",
      "get_place_name_lga",
      "get_address_locality_lga",
      "get_boundary_amendment_lga",
      "getProposalType"
    ]),
    ...mapMutations(["clearUploadState"]),
    /**
     * Retrieve the proposal
     */
    fetchProposal: waitFor("loading Proposal", async function() {
      try {
        await this.clearUploadState();
        if (this.token && this.unique) {
          await this.selectProposalPublic({
            proposalId: this.proposalId,
            unique: this.unique,
            token: this.token
          });

          if (this.proposal.display_status !== "Pending Endorsement") {
            this.$router.push({
              name: "endorsePlaceNameProposalPublicSubmitted",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: true
              }
            });
          }
        } else {
          await this.selectProposal(this.proposalId);
          if (this.proposal.display_status !== "Pending Endorsement") {
            this.$router.push({
              name: "endorsementSubmitted",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: true
              }
            });
          }
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Retrieval has Failed",
          text: `The retrieval of this Place Name Proposal has failed. ${error}`
        });
      }
    }),

    /**
     * Submit the endorsement information to the server
     */
    submitEndorsement: waitFor(
      "submitting endorsement information",
      async function() {
        try {
          const payload = {
            proposal_id: this.proposalId,
            endorsement_comment: this.endorsementText,
            files: this.uploadedFiles
          };
          if (this.token && this.unique) {
            await pnpCruds.submitEndorsementInformationPublic(
              payload,
              this.unique,
              this.token
            );
            await this.clearUploadState();
            this.$router.push({
              name: "endorsePlaceNameProposalPublicSubmitted",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: false
              }
            });
          } else {
            await pnpCruds.submitEndorsementInformation(payload);
            await this.clearUploadState();
            this.$router.push({
              name: "endorsementSubmitted",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: false
              }
            });
          }
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Sending Endorsement Information",
            text: "Error"
          });
        }
      }
    )
  }
};
</script>

<style scoped>
.dividing-line-h {
  border-bottom-width: 1px;
}

textarea::placeholder {
  color: #646974;
  opacity: 1;
}
</style>
