import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faPhone,
  faEye,
  faUser,
  faUsers,
  faUserEdit,
  faLock,
  faUnlock,
  faArrowLeft,
  faExclamation,
  faExclamationCircle,
  faTimes,
  faCheck,
  faRoad,
  faEdit,
  faTree,
  faSearch,
  faArrowUp,
  faArrowDown,
  faExternalLinkAlt,
  faSortNumericUpAlt,
  faSortAlphaDownAlt,
  faFilter,
  faSortNumericDown,
  faSortAlphaUp,
  faCloudDownloadAlt,
  faChevronCircleDown,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEnvelope);
library.add(faPhone);
library.add(faEye);
library.add(faUser);
library.add(faUsers);
library.add(faUserEdit);
library.add(faLock);
library.add(faUnlock);
library.add(faArrowLeft);
library.add(faExclamationCircle);
library.add(faExclamation);
library.add(faTimes);
library.add(faCheck);
library.add(faRoad);
library.add(faEdit);
library.add(faTree);
library.add(faSearch);
library.add(faArrowUp);
library.add(faArrowDown);
library.add(faExternalLinkAlt);
library.add(faFilter);
library.add(faSortNumericDown);
library.add(faSortNumericUpAlt);
library.add(faSortAlphaDownAlt);
library.add(faSortAlphaUp);
library.add(faCloudDownloadAlt);
library.add(faChevronCircleDown);
library.add(faTrash);
Vue.component("font-awesome-icon", FontAwesomeIcon);
