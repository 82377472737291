<template>
  <div
    class="w-full mx-auto pt-6 pb-14 mb-8 px-6 text-black flex flex-col text-normal"
    data-cypress="add-box"
  >
    <a @click="hide()" class="cursor-pointer ml-auto">
      <errorIcon classNames="w-4" />
    </a>
    <div
      class="flex items-center content-center justify-center mt-6 mb-5 px-10"
    >
      <h2 v-if="!party.identifier">Add New {{ partyType }} Party</h2>
      <h2 v-else>Edit {{ partyType }} Party</h2>
    </div>
    <div class="px-10">
      <p class="text-normal-bold pt-4 pb-1">
        <a class="text-red-star">*</a>Name of {{ partyType }} Party
      </p>
      <input
        type="text"
        class="w-full rounded-lg p-4 bg-grey-input"
        data-cypress="name"
        :placeholder="`Name of ${partyType} Party`"
        v-model="name"
        v-validate="{ required: true, regex: /^([a-zA-Z]+( *-*\s*)*)+$/ }"
        name="name"
      />
      <fieldErrorMessage :message="errors.first('name')" />

      <p class="text-normal-bold pt-6 pb-1">
        <a class="text-red-star">*</a>Email Address
      </p>
      <input
        type="email"
        class="w-full rounded-lg p-4 mb-8 bg-grey-input"
        data-cypress="email"
        :placeholder="`Email Address of ${partyType} Party`"
        v-model="email"
        name="email"
        v-validate="'email|required'"
      />
      <fieldErrorMessage :message="errors.first('email')" />
    </div>
    <div class="flex justify-center w-full mt-2 px-10">
      <div
        @click="hide()"
        class="button-red-hollow mr-4 w-2/5"
        data-cypress="cancel"
      >
        Cancel
      </div>

      <button
        @click="confirmParty()"
        class="button-blue ml-4 w-2/5"
        data-cypress="confirm"
        :disabled="errors.items.length > 0"
      >
        Confirm
      </button>
    </div>
  </div>
</template>
<script>
import errorIcon from "../elements/errorIcon";
import * as partyCruds from "../../helpers/partyCRUD";

export default {
  name: "edit-party",
  components: { errorIcon },
  props: ["party", "type"],
  data() {
    return {
      name: this.party ? this.party.name : "",
      email: this.party ? this.party.email : ""
    };
  },
  computed: {
    partyType() {
      return this.type === "pnr" ? "Interested" : "Relevant";
    }
  },
  methods: {
    async hide() {
      this.$emit("close");
    },
    async confirmParty() {
      try {
        const response = await this.$validator.validateAll();
        if (response) {
          if (!this.party.identifier) {
            await partyCruds.savePartyToDb(
              {
                name: this.name,
                email: this.email
              },
              this.type
            );
            this.hide();
            this.$notify({
              group: "toast",
              type: "success",
              title: "Save Succesful",
              text: "Party has been saved"
            });
          } else {
            await partyCruds.updateParty(
              {
                name: this.name,
                email: this.email,
                identifier: this.party.identifier
              },
              this.type
            );
            this.hide();
            this.$notify({
              group: "toast",
              type: "success",
              title: "Update Succesful",
              text: "Party info has been updated"
            });
          }
        }
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Update Error",
          text: "Error updating party"
        });
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }
};
</script>
