var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
      _c("div", { staticClass: "flex justify-between" }),
      _c("h2", { staticClass: "text-black ml-4 self-center" }, [
        _vm._v("Submit Road Name Appeal"),
      ]),
      _c(
        "div",
        {
          staticClass:
            "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap",
        },
        [
          _c("div", [
            _vm._m(0),
            _c("p", { staticClass: "text-title mb-4 mt-8 text-center" }, [
              _vm._v(
                " Your Appeal for " +
                  _vm._s(_vm.road.roadname.toUpperCase()) +
                  " " +
                  _vm._s(_vm.road.road_type.toUpperCase()) +
                  " has been submitted "
              ),
            ]),
            _c("p", { staticClass: "mb-24 text-title text-center" }, [
              _vm._v(
                " and will be considered for review by the GNB Secretariat. "
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex pt-4 text-center pb-10" },
        [
          _c(
            "router-link",
            {
              staticClass: "w-1/3 button-blue-hollow",
              attrs: { to: { name: "proposals" } },
            },
            [_vm._v("Go to My Proposals Dashboard")]
          ),
          _c(
            "button",
            {
              staticClass: "ml-auto w-1/3 button-blue",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-2)
                },
              },
            },
            [_vm._v(" Return to Road Name Proposal ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full justify-center flex mt-20" }, [
      _c("img", {
        staticClass: "w-48 h-48",
        attrs: { src: require("../../../assets/Images/Icon_Gazettal.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }