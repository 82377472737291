"use strict";

import { getCredentials } from "../../helpers/s3";

import Vue from "vue";

// initial state
const state = {
  uploadedFiles: []
};

// getters
const getters = {
  uploadedFilesState: state => {
    return state.uploadedFiles;
  }
};

// actions
const actions = {
  async uploadFile(state, payload) {
    let uploadCreds;
    try {
      uploadCreds = await getCredentials(payload.s3Key, payload.file.name);
    } catch (e) {
      Vue.notify({
        group: "toast",
        type: "notice",
        title: "Unsupported file type",
        text: "Please choose a different type of file"
      });
      return;
    }

    const formData = new FormData();
    formData.append("Content-Disposition", "attachment");
    formData.append("Content-Type", "application/octet-stream");
    formData.append("Cache-Control", "max-age=31536000");
    Object.entries(uploadCreds.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", payload.file); // must be the last one

    const xhr = new XMLHttpRequest();
    return new Promise(function(resolve, reject) {
      xhr.open("POST", uploadCreds.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        if (this.status === 204) {
          state.commit("uploadedFilesState", {
            title: payload.file.name,
            s3_key: uploadCreds.fields.key
          });
          resolve();
        } else {
          Vue.notify({
            group: "toast",
            type: "error",
            title: this.status + " Error",
            text: "Please choose a different file"
          });
          reject(new Error("Error, status code = " + this.status));
        }
      };
    });
  }
};

// mutations
const mutations = {
  uploadedFilesState(state, payload) {
    state.uploadedFiles.push(payload);
  },
  deleteUploadedFile(state, payload) {
    state.uploadedFiles.splice(payload, 1);
  },
  clearUploadState(state) {
    state.uploadedFiles = [];
  },
  setUploadedFiles(state, files) {
    state.uploadedFiles = [...files];
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
