var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full mx-auto pt-6 pb-14 mb-8 px-6 text-black flex flex-col text-normal",
      attrs: { "data-cypress": "add-box" },
    },
    [
      _c(
        "a",
        {
          staticClass: "cursor-pointer ml-auto",
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
        },
        [_c("errorIcon", { attrs: { classNames: "w-4" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex items-center content-center justify-center mt-6 mb-5 px-10",
        },
        [
          !_vm.party.identifier
            ? _c("h2", [_vm._v("Add New " + _vm._s(_vm.partyType) + " Party")])
            : _c("h2", [_vm._v("Edit " + _vm._s(_vm.partyType) + " Party")]),
        ]
      ),
      _c(
        "div",
        { staticClass: "px-10" },
        [
          _c("p", { staticClass: "text-normal-bold pt-4 pb-1" }, [
            _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
            _vm._v("Name of " + _vm._s(_vm.partyType) + " Party "),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, regex: /^([a-zA-Z]+( *-*\s*)*)+$/ },
                expression:
                  "{ required: true, regex: /^([a-zA-Z]+( *-*\\s*)*)+$/ }",
              },
            ],
            staticClass: "w-full rounded-lg p-4 bg-grey-input",
            attrs: {
              type: "text",
              "data-cypress": "name",
              placeholder: `Name of ${_vm.partyType} Party`,
              name: "name",
            },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
          _c("fieldErrorMessage", {
            attrs: { message: _vm.errors.first("name") },
          }),
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "email|required",
                expression: "'email|required'",
              },
            ],
            staticClass: "w-full rounded-lg p-4 mb-8 bg-grey-input",
            attrs: {
              type: "email",
              "data-cypress": "email",
              placeholder: `Email Address of ${_vm.partyType} Party`,
              name: "email",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _c("fieldErrorMessage", {
            attrs: { message: _vm.errors.first("email") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex justify-center w-full mt-2 px-10" }, [
        _c(
          "div",
          {
            staticClass: "button-red-hollow mr-4 w-2/5",
            attrs: { "data-cypress": "cancel" },
            on: {
              click: function ($event) {
                return _vm.hide()
              },
            },
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "button",
          {
            staticClass: "button-blue ml-4 w-2/5",
            attrs: {
              "data-cypress": "confirm",
              disabled: _vm.errors.items.length > 0,
            },
            on: {
              click: function ($event) {
                return _vm.confirmParty()
              },
            },
          },
          [_vm._v(" Confirm ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold pt-6 pb-1" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Email Address "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }