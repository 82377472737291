import * as proposalsCruds from "../../helpers/proposalsCRUD";
import Vue from "vue";

// initial state
const state = {
  allProposalsList: [],
  totalProposals: 0,
  selectedProposal: {},
  proposalSearch: {
    keyword: null,
    page: 1,
    includeArchived: false,
    road_naming_authority_id: null,
    limit: 20,
    type: null,
    display_status: null
  }
};

// getters
const getters = {
  allProposalsListState: state => state.allProposalsList,
  selectedProposal: state => state.selectedProposal,
  totalProposalsForQuery: state => state.totalProposals,
  proposalSearch: state => state.proposalSearch
};

// actions
const actions = {
  async getProposalsList(state, params) {
    const proposalList = await proposalsCruds.getAllRoadnameProposals(params);

    state.commit("allProposalsList", proposalList);
  },
  async getProposalById(state, id) {
    const proposal = await proposalsCruds.getProposalById(id);

    state.commit("setSelectedProposal", proposal);
  },
  resetProposalSearch({ commit }) {
    commit("setProposalSearch", {
      keyword: null,
      page: 1,
      includeArchived: false,
      road_naming_authority_id: null,
      limit: 20,
      type: null,
      display_status: null
    });
  }
};

// mutations
const mutations = {
  allProposalsList(state, payload) {
    state.allProposalsList = payload.proposals;
    state.totalProposals = payload.total;
  },
  setSelectedProposal(state, proposal) {
    state.selectedProposal = proposal;
  },
  setKeyword(state, keyword) {
    Vue.set(state.proposalSearch, "keyword", keyword);
  },
  setIncludeArchived(state, value) {
    Vue.set(state.proposalSearch, "includeArchived", !!value);
  },
  setProposalSearchRoadNamingAuthority(state, value) {
    Vue.set(state.proposalSearch, "road_naming_authority_id", value);
  },
  setProposalSearchPage(state, value) {
    Vue.set(state.proposalSearch, "page", value);
  },
  setProposalSearchLimit(state, value) {
    Vue.set(state.proposalSearch, "limit", value);
  },
  setProposalSearchType(state, value) {
    Vue.set(state.proposalSearch, "type", value);
  },
  setProposalSearchDisplayStatus(state, value) {
    Vue.set(state.proposalSearch, "display_status", value);
  },
  setProposalSearch(state, value){
    state.proposalSearch = value
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
