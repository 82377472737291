<template>
  <div :key="proposalId">
    <div class="text-black mb-16" v-if="exists || proposalId == 'create'">
      <a @click="returnToManageProposalsList()">
        <BackArrowWithTitle
          title="Manage Proposals"
          size="h4"
          class="pl-16 pt-4"
        />
      </a>
      <div class="base-container text-3xl font-semibold" v-if="!readOnly">
        Create Place Name Proposal
      </div>
      <div class="base-container text-3xl font-semibold" v-if="readOnly">
        View Place Name Proposal
      </div>

      <showLoading isLoading="loadingProposal">
        <template v-slot:component>
          <transition appear name="fade" mode="out-in">
            <div :key="proposalId">
              <Type
                :readOnly="readOnly"
                :isAdmin="isAdmin"
                :organisations="organisations"
                class="base-container"
              />
              <GeographicalDetails
                :proposalType="type"
                :readOnly="readOnly"
                class="base-container"
              />
              <Lot
                :proposalType="type"
                :readOnly="readOnly"
                class="base-container"
              />
              <NameDetailsAndOrigin
                :proposalType="type"
                :readOnly="readOnly"
                class="base-container"
              />
              <AdditionalInfo
                :proposalType="type"
                :readOnly="readOnly"
                class="base-container"
              />
              <Checklist
                :proposalType="type"
                :readOnly="readOnly"
                class="base-container"
              />
              <LocalNewspaper v-if="!school_or_national_park" :readOnly="readOnly" class="base-container" />
              <div class="flex base-container" name="3 buttons">
                <ButtonWithSpinner
                  v-if="showDeleteButton"
                  class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
                  @click="deleteProposalById"
                  data-cypress="delete-button"
                  :isSpinning="$wait.is('deletingProposal')"
                  :disabled="$wait.is('deletingProposal')"
                  >Delete Proposal</ButtonWithSpinner
                >
                <ButtonWithSpinner
                  v-if="showDraftButton"
                  class="button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto"
                  @click="saveAsDraft"
                  data-cypress="savedraft-button"
                  :isSpinning="$wait.is('savingProposalAsDraft')"
                  :disabled="$wait.is('savingProposalAsDraft') || !isDraftValid"
                  >Save as Draft</ButtonWithSpinner
                >
                <ButtonWithSpinner
                  v-if="showSubmitButton"
                  class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
                  @click="save"
                  data-cypress="submit-proposal-button"
                  :isSpinning="$wait.is('savingProposal')"
                  :disabled="
                    $wait.is('savingProposal') ||
                      !isFormValid ||
                      !referenceNumber ||
                      (errors.items && errors.items.length > 0)
                  "
                  >Submit Proposal</ButtonWithSpinner
                >
                <ButtonWithSpinner
                  v-if="isEditing && type !== PROPOSAL_TYPE_PLACE"
                  class="button-blue flex relative w-2/6 mt-4 mb-10 ml-auto"
                  @click="save"
                  data-cypress="submit-proposal-button"
                  :isSpinning="$wait.is('savingProposal')"
                  :disabled="
                    $wait.is('savingProposal') ||
                      !isFormValid ||
                      !referenceNumber
                  "
                  >Update Proposal</ButtonWithSpinner
                >
              </div>
            </div>
          </transition>
        </template>
      </showLoading>
    </div>
    <div
      v-if="!exists && proposalId !== 'create'"
      class="text-black mb-16 text-center mt-16"
    >
      <h3>
        no proposal found for proposal ID: <b>{{ proposalId }}</b>
      </h3>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import { waitFor } from "vue-wait";
const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import Type from "./proposal-type/proposalType.vue";
import GeographicalDetails from "./geographical-details/layout.vue";
import Lot from "./lot-and-dp-information/layout.vue";
import NameDetailsAndOrigin from "./name-details-and-origin/layout.vue";
import AdditionalInfo from "./additional-info/layout.vue";
import Checklist from "./checklists/layout.vue";
import LocalNewspaper from "./local-newspaper/layout.vue";
import pnpCRUD from "@/helpers/pnpCRUD.js";

import {
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY
} from "../pnp-constants.js";

export default {
  provide() {
    return {
      $validator: this.$validator
    };
  },
  name: "placeNameProposal",
  data() {
    return {
      isSaving: false
    };
  },
  components: {
    Type,
    GeographicalDetails,
    NameDetailsAndOrigin,
    Lot,
    AdditionalInfo,
    Checklist,
    LocalNewspaper
  },
  computed: {
    PROPOSAL_TYPE_PLACE() {
      return PROPOSAL_TYPE_PLACE;
    },
    isEditing() {
      return Boolean(this.$route.query.edit);
    },
    type: {
      get() {
        return this.getProposalType();
      }
    },
    referenceNumber: {
      get() {
        return this.getReferenceNumber();
      }
    },
    isBoundaryAmendmentFormValid() {
      if (this.isAdmin) {
        return this.getBoundaryAmendmentFormValidityAsAdmin();
      } else {
        return this.getBoundaryAmendmentFormValidity();
      }
    },
    isLocalityFormValid() {
      if (this.isAdmin) {
        return this.getLocalityFormValidityAsAdmin();
      } else {
        return this.getLocalityFormValidity();
      }
    },
    isPlaceNameFormValid() {
      if (this.isAdmin) {
        return this.getPlaceNameFormValidityAsAdmin();
      } else {
        return this.getPlaceNameFormValidity();
      }
    },
    localityHardStops() {
      return (
        this.$store.state.pnpState.proposal.address_locality.checklist
          .supported_by_council === false ||
        this.$store.state.pnpState.proposal.address_locality.checklist
          .map_or_gis_file_provided === false
      );
    },
    boundaryAmendmentHardStops() {
      return (
        this.$store.state.pnpState.proposal.boundary_amendment.checklist
          .map_or_gis_file_provided === false
      );
    },
    isFormValid() {
      if (this.type === PROPOSAL_TYPE_BOUNDARY) {
        return (
          this.isBoundaryAmendmentFormValid && !this.boundaryAmendmentHardStops
        );
      } else if (this.type === PROPOSAL_TYPE_PLACE) {
        return this.isPlaceNameFormValid;
      } else if (this.type === PROPOSAL_TYPE_LOCALITY) {
        return this.isLocalityFormValid && !this.localityHardStops;
      } else return false;
    },
    isDraftValid() {
      return this.getDraftValidity();
    },
    exists: {
      get() {
        return this.proposalExists();
      }
    },
    readOnly: {
      get() {
        return this.proposalId !== "create" && this.status !== "Draft";
      }
    },
    proposalId: {
      get() {
        return this.$route.params.proposalId;
      }
    },
    status: {
      get() {
        return this.proposalStatus();
      }
    },
    showDraftButton: {
      get() {
        return this.proposalId === "create" || this.status === "Draft";
      }
    },
    showDeleteButton: {
      get() {
        return this.status === "Draft";
      }
    },
    showSubmitButton: {
      get() {
        return this.proposalId === "create" || this.status === "Draft";
      }
    },
    isAdmin: {
      get() {
        return this.user.isAdmin;
      }
    },
    organisations: {
      get() {
        return this.getOrganisations();
      }
    },
    school_or_national_park(){return this.get_place_name_school_or_national_park()},
    ...mapGetters(["proposalSearch"]),
    ...mapUserGetters(["user"])
  },
  async created() {
    await this.initProposal();
  },
  watch: {
    proposalId: function(newId, oldId) {
      if (newId === "create" && newId !== oldId) {
        this.createNewProposal();
      }
    }
  },
  methods: {
    initProposal: waitFor("loadingProposal", async function() {
      if (this.isAdmin) {
        this.fetchOrganisations();
      }
      const id = this.proposalId;
      if (id === "create") {
        //create new proposal
        try {
          await this.createNewProposal();
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Proposal Retrieval has Failed",
            text: `The retrieval of this Place Name Proposal has failed. ${error}`
          });
        }
      } else {
        // fetch proposal
        try {
          await this.selectProposal(this.proposalId);
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Proposal Retrieval has Failed",
            text: `The retrieval of this Place Name Proposal has failed. ${error}`
          });
        }
      }
    }),
    async save() {
      if (!(await this.$validator.validateAll())) {
        return;
      }
      this.$wait.start("savingProposal");
      try {
        await this.saveProposal();
        this.$wait.end("savingProposal");
        this.$router.push({ name: "pnpSubmitted" });
      } catch (error) {
        this.$wait.end("savingProposal");
        this.$notify({
          group: "toast",
          type: "error",
          title: "Submission has Failed",
          text: `The Place Name Proposal submission has failed. ${error}`
        });
      }
    },
    async saveAsDraft() {
      this.$wait.start("savingProposalAsDraft");
      try {
        await this.saveDraftProposal();
        this.$wait.end("savingProposalAsDraft");
        this.$router.push({ name: "placeNameProposals" });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Draft Submission has Failed",
          text: `The Place Name Proposal draft submission has failed. ${error}`
        });
        this.$wait.end("savingProposalAsDraft");
      }
    },
    async deleteProposalById() {
      this.$wait.start("deletingProposal");
      try {
        await this.deleteProposal(this.proposalId);
        this.$wait.end("deletingProposal");
        this.$router.push({ name: "placeNameProposals" });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Submission Deletion has Failed",
          text: `The deletion of this Place Name Proposal has failed. ${error}`
        });
        this.$wait.end("deletingProposal");
      }
    },
    returnToManageProposalsList() {
      this.$router.push({ name: "placeNameProposals", query: { keyword: this.proposalSearch.keyword}});
    },
    ...mapPnpActions([
      "saveProposal",
      "saveDraftProposal",
      "selectProposal",
      "fetchProposalsList",
      "createNewProposal",
      "fetchOrganisations",
      "deleteProposal"
    ]),
    ...mapPnpGetters([
      "getProposalType",
      "getReferenceNumber",
      "proposalExists",
      "proposalStatus",
      "getOrganisations",
      "getPlaceNameFormValidity",
      "getLocalityFormValidity",
      "getBoundaryAmendmentFormValidity",
      "getPlaceNameFormValidityAsAdmin",
      "getLocalityFormValidityAsAdmin",
      "getBoundaryAmendmentFormValidityAsAdmin",
      "getDraftValidity",
      "get_place_name_school_or_national_park"
    ])
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.6s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
