<template>
  <div>
    <div class="dividing-line-h"></div>

    <div class="text-center my-10 text-normal-bold">
      {{ resultsCount.toLocaleString() }} Result<template
        v-if="resultsCount > 1"
        >s</template
      >
      Found
    </div>

    <div class="flex justify-between text-sm text-grey text-normal-bold">
      <div class="w-12 mr-4"></div>
      <div class="w-1/5">Place Name</div>
      <div class="w-1/5">Local Government Area</div>
      <div class="w-1/5">Designation</div>
      <div class="w-1/5 text-center">Status</div>
      <div class="w-1/6"></div>
    </div>

    <div class="dividing-line-h mt-3"></div>

    <div v-for="(result, key) in results" :key="key">
      <div class="flex justify-between my-5">
        <div class="w-12 mr-4 text-grey text-normal-bold text-sm pt-1">
          {{ countOffset + key + 1 }}.
        </div>
        <div class="w-1/5 mr-4 text-normal-bold">
          {{ result.geographical_name }}
        </div>
        <div class="w-1/5 mr-4">
          <span v-for="(lga, key) in result.lga" :key="key">
            {{ lga }}<span v-if="result.lga.length > key + 1">,</span>
          </span>
        </div>
        <div class="w-1/5 mr-4">{{ result.designation }}</div>

        <StatusButton
          v-if="result.status"
          :label="result.dual_named ? `Dual - ${result.status}` : result.status"
          :status="result.status"
          class="w-1/5 font-semibold text-sm"
        />
        <StatusButton
          v-if="!result.status"
          :label="`No Status`"
          :status="result.status"
          class="w-1/5 font-semibold text-sm"
        />
        <div class="w-1/6 text-right" v-if="isPublicRoute">
          <a @click="view(result)" class="cursor-pointer text-blue-button"
            >View info</a
          >
        </div>
        <div class="w-1/6 text-right" v-if="!isPublicRoute">
          <a @click="view(result)" class="cursor-pointer text-blue-button"
            >View/Edit info</a
          >
        </div>
      </div>
      <div class="dividing-line-h"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "showResults",
  props: {
    results: {
      type: Array,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    resultsCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    countOffset() {
      return this.page * this.limit - this.limit;
    },
    isPublicRoute() {
      return this.$route.meta && this.$route.meta.isPublic;
    }
  },
  methods: {
    view(result) {
      let routeData = this.$router.resolve({
        name: this.isPublicRoute ? "geonameViewPublic" : "geonameEdit",
        params: { geonameId: result.identifier }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
