<template>
  <modal
    :clickToClose="false"
    name="edit-geoname"
    :width="'40%'"
    height="auto"
    :classes="'rounded-lg bg-white py-6'"
    :scrollable="true"
  >
    <div class="w-9/10 mx-auto flex flex-col justify-center">
      <a @click="hide()" class="cursor-pointer ml-auto">
        <errorIcon classNames="w-5" />
      </a>

      <h2 class="w-full flex justify-center font-bold text-black mt-12">
        Confirm Changes
      </h2>
      <div
        class="w-full flex text-justify text-md text-black mt-10 px-16 py-2 justify-center"
      >
        Do the changes necessitate updates to the Spatial Ops team?
      </div>
      <div
        class="w-full flex text-justify text-md text-black mt-10 mb-4 px-16 py-2 justify-center"
      >
        Please confirm that you have considered the implications of this change
        - in particular for Interested Parties. It is your responsibility to
        inform them of any material change you have made to this record.
      </div>
      <div class="flex justify-between pt-4">
        <div
          class="flex w-full flex-col justify-between content-between pt-4 pb-4 mx-4 mb-4 text-center"
        >
          <button @click="saveChanges()" class="button-blue-hollow m-4">
            Save Changes
          </button>
          <button @click="saveChangesAndNotify()" class="button-blue m-4">
            Save & Notify Spatial Ops
          </button>
          <button @click="hide" class="button-red m-4">
            Cancel Changes
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import errorIcon from "../elements/errorIcon";

export default {
  name: "editGeoNameConfirmationModal",
  data() {
    return {
      geoName: {}
    };
  },
  components: { errorIcon },
  methods: {
    show() {
      this.$modal.show("edit-geoname");
    },
    hide() {
      this.$modal.hide("edit-geoname");
    },
    saveChanges() {
      this.$emit("saveGeoName");
      this.hide();
    },
    saveChangesAndNotify() {
      this.$emit("saveGeoNameWithUpdate");
      this.hide();
    }
  }
};
</script>
<style>
.fullRedButton {
  background-color: #c60c30;
  color: #ffffff;
  border-color: #c60c30;
}
.saveButton {
  color: #ffffff;
  background-color: #002664;
  border-color: #002664;
}
</style>
