<template>
  <div class="fork min-h-screen">
    <img src="../../assets/Logos/GNB logo_White_RGB.png" class="gnbLogoTop" />
    <button class="logout float-right" @click="userLogout">
      Log Out
    </button>
    <div class="sm:text-center">
      <img src="../../assets/Logos/GNB logo_White_RGB.png" class="gnbLogo" />
    </div>
    <h3 class="subHeading sm:text-center">
      Hello {{ displayName }}, welcome to the NSW Place and Road Naming Proposal
      System.
    </h3>
    <div class="flex flex-wrap justify-center items-start">
      <div class="shadow-md bg-white rounded-lg panel">
        <Road class="icon" />
        <h3 class="sm:text-center blue">
          Road Name Proposals
        </h3>
        <button
          class="w-full mt-4 font-bold button-blue focus:outline-none focus:shadow-outline mb-10 btn-blue jest-go-to-RNP"
          type="button"
          @click="goToRNPDashboard"
          data-cypress="fork-manage-road-names"
        >
          Manage Road Name Proposals
        </button>
      </div>
      <div class="shadow-md bg-white rounded-lg panel">
        <Tree class="icon" />
        <h3 class="sm:text-center green">
          Place Name Proposals
        </h3>
        <button
          class="w-full mt-4 font-bold button-green focus:outline-none focus:shadow-outline mb-10 btn-green jest-go-to-PNP"
          type="button"
          @click="goToPNPDashboard"
          data-cypress="fork-manage-place-names"
        >
          Manage Place Name Proposals
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Road from "../../assets/Images/road-blue.svg";
import Tree from "../../assets/Images/tree-green.svg";
export default {
  props: ["displayName", "goToRNPDashboard", "goToPNPDashboard", "userLogout"],
  components: { Road, Tree },
  name: "ForkLayout"
};
</script>

<style scoped>
.fork {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url("../../assets/Images/BG_Login.jpg");
}

.icon {
  max-height: 50px;
}

.btn-green {
  background-color: #69b32d;
  border-color: #69b32d;
  margin-bottom: 0;
}

.btn-green:hover {
  background-color: white;
  color: #69b32d;
}

.btn-blue {
  background-color: #00abe6;
  border-color: #00abe6;
  margin-bottom: 0;
}

.btn-blue:hover {
  background-color: white;
  color: #00abe6;
}

.subHeading {
  color: white;
  size: 38px;
  font-weight: 600;
  margin: 16px;
}

.gnbLogo {
  margin-top: 64px;
  width: 400px;
}

.gnbLogoTop {
  width: 150px;
  margin: 16px;
}

.panel {
  padding: 56px;
  margin: 16px;
  font-weight: 600;
}

.logout {
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  color: white;
}
</style>
