<template>
  <div class="base-container text-black text-normal">
    <section class="pb-5 justify-center w-full block">
      <div class="flex justify-between">
        <router-link
          :to="{ name: 'organisationsManage' }"
          class="flex justify-between mr-4 self-center"
          data-cypress="goback-arrow"
        >
          <BackArrowWithTitle
            :title="'View Organisation - ' + organisation.organisation_name"
          />
        </router-link>
      </div>
    </section>

    <div class="bg-white w-full rounded-lg flex h-26 items-center">
      <div
        class="sm:hidden xl:flex w-12 h-12 rounded-full ml-6 bg-grey-lighter  justify-center items-center flex-none"
      >
        <div class="flex-none">
          <font-awesome-icon icon="road" class="text-2xl" />
        </div>
      </div>

      <div class="w-1/3 flex flex-wrap content-between ml-2 pl-4 py-8">
        <h3 class="w-full">{{ organisation.organisation_name }}</h3>
      </div>
      <div class="h-full dividing-line-v"></div>

      <div class="w-2/3 flex ml-6 h-full content-center">
        <div class="w-1/2 flex flex-col justify-start">
          <a
            data-cypress="organisation-email-link"
            class="text-black pb-2 pr-4 py-4 flex-1 flex align-center cursor-default"
            ><font-awesome-icon
              icon="envelope"
              class="pr-2 text-2xl flex-none"
            />
            Address
          </a>
          <span class="h-18"
            >{{ organisation.address_line_1 }} {{ organisation.address_line_2
            }}<br />
            {{ organisation.organisation_suburb }}
            {{ organisation.organisation_state }}
            {{ organisation.organisation_postcode }}</span
          >
        </div>
        <div class="w-1/2 flex flex-wrap content-between py-8">
          <AccountStatus
            :account_status="organisation.organisation_status"
            data-cypress="organisation-account"
          />
        </div>
      </div>

      <!-- actions -->
      <div
        class="w-16 h-full flex-auto flex flex-col content-around items-center border-l-2"
      >
        <router-link
          class="flex-auto flex justify-center items-center font-bold actions w-full h-1/2 action-button rounded-tr-lg"
          data-cypress="edit-organisation-link"
          :to="{
            name: 'organisationsEdit',
            params: { organisation: organisation, id: organisation.identifier }
          }"
          :title="`Edit ${organisation.organisation_name} details`"
          ><font-awesome-icon icon="edit" class="flex-auto"
        /></router-link>

        <button
          v-if="organisation.organisation_status == 'approved'"
          :title="`Suspend ${organisation.organisation_name}`"
          class="flex-auto flex justify-center items-center text-red font-bold h-1/2 w-full action-button suspend-user rounded-br-lg "
          data-cypress="suspend-button"
          @click="confirmOrganisation(organisation)"
        >
          <font-awesome-icon icon="lock" class="flex-auto" />
        </button>
        <button
          v-if="organisation.organisation_status != 'approved'"
          :title="`Approve ${organisation.organisation_name}`"
          class="flex-auto flex justify-center items-center text-green font-bold h-1/4 w-full action-button activate-user rounded-br-lg"
          data-cypress="suspend-button"
          @click="confirmOrganisation(organisation)"
        >
          <font-awesome-icon icon="check" class="flex-auto" />
        </button>
      </div>
    </div>

    <div class="flex-auto flex items-center mt-8">
      <h2>{{ users.length }} Users</h2>
    </div>
    <UserList :users="users">
      <template slot-scope="{ user }">
        <div
          class="w-12 h-full flex-auto flex flex-col content-around items-center "
        >
          <router-link
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button"
            data-cypress="view-user-link"
            :to="{ name: 'profile/view', params: { id: user.email } }"
            :title="`View ${user.first_name} ${user.last_name}'s profile`"
            ><font-awesome-icon icon="eye" class="flex-auto"
          /></router-link>

          <button
            :title="`Suspend ${user.first_name} ${user.last_name}`"
            class="flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user "
            data-cypress="suspend-button"
            disabled="disabled"
          >
            <font-awesome-icon icon="lock" class="flex-auto" />
          </button>
        </div>
      </template>
    </UserList>

    <div class="h-24 flex-auto" name="purelyABuffer"></div>
  </div>
</template>

<script>
import UserList from "../userprofiles/userList";
import { searchUsers } from "../../helpers/userCRUD.js";

import organisationModal from "./organisationModal.vue";
export default {
  components: {
    UserList
  },
  props: ["organisation"],
  async mounted() {
    // Get users list from DB
    const users = await searchUsers({
      organisation_id: this.organisation.id,
      limit: 999
    });

    this.users = users.users;
  },
  data: function() {
    return {
      users: []
    };
  },
  computed: {
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    }
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        const users = await searchUsers({
          organisation_id: this.organisation.id,
          limit: 999
        });

        this.users = users.users;
      }
    }
  },
  methods: {
    confirmOrganisation(organisation) {
      this.$modal.show(
        organisationModal,
        { organisation },
        {
          height: "auto"
        }
      );
    }
  }
};
</script>

<style scoped>
.action-button.activate-user:hover {
  background: #38c172;
  color: #fff;
}
.action-button.activate-user:disabled:hover {
  background: #fff;
  color: #38c172;
}
</style>
