<template>
  <div class="base-container text-black flex flex-grow">
    <section class="justify-start w-full block flex flex-col">
      <a
        class="flex justify-between"
        @click="$router.push({ name: 'roadNameProposalBucket', proposalId })"
      >
        <BackArrowWithTitle title="Create a Gazettal Notice" />
      </a>
      <showLoading :isLoading="'fetchingRoadInformation'">
        <template v-slot:component>
          <div v-if="!!road">
            <div
              class="flex flex-1 items-start justify-center"
              v-if="!isReadyForGazettal"
            >
              <div class="flex flex-row mt-8">
                <font-awesome-icon
                  class="flex-0 text-red text-3xl inline mr-2"
                  icon="exclamation-circle"
                />
                <p>
                  {{ road.roadname }} {{ road.road_type }} already has a
                  Gazettal notice
                </p>
              </div>
            </div>
            <div v-if="isReadyForGazettal">
              <div
                class="w-full flex bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4 text-center"
              >
                <proposalIcon
                  :label="road.roadname_classification"
                  classNames="w-10 h-10 text-normal"
                />
                <h3 class="self-center ml-4">
                  {{ road.roadname }} {{ road.road_type }}
                </h3>
                <StatusButton
                  :label="road.name_status"
                  :status="road.name_status"
                  class="ml-auto mr-6 text-normal font-bold"
                />
              </div>

              <div
                class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 pb-16 pt-12"
              >
                <div class="w-9/10 mx-auto flex flex-col">
                  <div class="flex w-full">
                    <label class="w-1/2 text-normal-bold" for="lgaText">
                      Local Government Area (LGA)
                      <input
                        id="lgaText"
                        name="lgaText"
                        type="text"
                        class="flex input-area w-full mt-2"
                        v-model="road.lga_name"
                        disabled
                      />
                    </label>

                    <div class="w-1/2 ml-8 text-normal-bold">
                      <label
                        class="text-normal-bold w-1/2 ml-8 mt-4"
                        for="locality"
                      >
                        Address Locality (Suburb)
                        <input
                          id="locality"
                          name="locality"
                          type="text"
                          class="flex w-full input-area mt-2 mb-4"
                          v-model="road.suburb_name"
                          disabled
                        />
                      </label>
                    </div>
                  </div>

                  <div class="flex w-full">
                    <label class="text-normal-bold w-1/2 mt-4" for="roadType">
                      <a class="text-red-star">*</a>Road Type
                      <norns-select
                        class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                        name="Road Type"
                        id="roadType"
                        placeholder="Select Road Type..."
                        data-cypress="roadtype-select"
                        v-model="road.road_type"
                        :multiple="false"
                        :options="roadTypesClean"
                        :searchable="true"
                        :close-on-select="true"
                        @input="setRoadType"
                        v-validate="'required'"
                        :select-on-tab="true"
                      >
                        <span slot="noOptions">Loading...</span>
                      </norns-select>
                    </label>
                    <label class="text-normal-bold w-1/2 ml-8 mt-4"> </label>
                  </div>
                  <FieldErrorMessage :message="errors.first('roadType')" />

                  <div class="w-full flex mt-8">
                    <label class="w-full">
                      <p class="text-normal-bold mb-2">
                        <span class="text-red-star">*</span>Extent
                      </p>
                      <textarea
                        id="extent"
                        name="extent"
                        class="w-full h-48 input-area"
                        data-cypress="extent-field"
                        placeholder="Please describe where the road starts and ends"
                        v-validate="'required'"
                        v-model="road.extent"
                      ></textarea>
                    </label>
                  </div>
                  <FieldErrorMessage :message="errors.first('extent')" />

                  <uploadComponent
                    label="Attach Relevant Image (Optional)"
                    :drafted="true"
                    :deleteFile="deleteFunction"
                  />
                  <p class="text-normal-bold mb-2">
                    <a class="text-red-star">*</a>Public Comment
                  </p>
                  <Checkbox
                    class="w-full"
                    label="This road name proposal has been advertised for public comment."
                    v-model="advertisedComment"
                    v-validate="'required:true'"
                    :error="errors.first('Advertise for Public Comment')"
                    name="Public Comment"
                  ></Checkbox>
                </div>
              </div>
              <div class="flex pt-4 text-center pb-10">
                <a
                  @click="
                    $router.push({ name: 'roadNameProposalBucket', proposalId })
                  "
                  class="button-red-hollow flex-no-grow"
                  >Cancel Gazettal Notice</a
                >
                <div class="flex-grow"></div>
                <button
                  @click="submitNotice"
                  class="button-blue flex-no-grow"
                  :disabled="isSaveDisabled"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </template>
      </showLoading>
    </section>
  </div>
</template>

<script>
import proposalIcon from "../proposals/road/proposalIcon";

import { mapMutations } from "vuex";
import { deleteSupportingDocument } from "../../helpers/s3";
import roadTypeListMixin from "../../mixins/road-type-list-filter";
import { getRoadNameById } from "@/helpers/rnpCRUD.js";
import { waitFor } from "vue-wait";
export default {
  name: "gazettalNotice",
  mixins: [roadTypeListMixin],
  components: {
    proposalIcon
  },
  data() {
    return {
      advertisedComment: false,
      road: null
    };
  },
  methods: {
    ...mapMutations(["clearUploadState"]),
    deleteFunction: deleteSupportingDocument,
    setRoadType(value) {
      this.$set(this.road, "road_type", value);
    },
    async submitNotice() {
      await this.$validator.validate();
      const uploads = this.uploadedFiles;
      await this.clearUploadState();
      this.$router.push({
        name: "gazettalPreview",
        params: {
          roads: [this.road],
          uploads,
          proposalId: this.proposalId,
          roadNameId: this.roadNameId
        }
      });
    },
    fetchRoadInformation: waitFor("fetchingRoadInformation", async function () {
      try {
        this.road = await getRoadNameById(this.roadNameId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve road name information",
          text: error.response.data.message
        });
      }
    })
  },
  computed: {
    uploadedFiles() {
      return this.$store.getters.uploadedFilesState;
    },
    roadNameId() {
      return this.$route.params.roadNameId;
    },
    proposalId() {
      return this.$route.params.proposalId;
    },
    isSaveDisabled() {
      return (
        !this.advertisedComment || !this.road.road_type || !this.road.extent
      );
    },
    isReadyForGazettal() {
      if (this.road) {
        return ["approved", "approved on appeal", "concurred", "verified"].some(
          s => this.road.name_status.toLowerCase() === s
        );
      }

      return false;
    }
  },
  async created() {
    await this.fetchRoadInformation();
  }
};
</script>
