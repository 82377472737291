var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex bg-blue-button mb-6 text-white" },
    [
      _c("div", { staticClass: "w-5/6 mx-auto pt-8 pb-12" }, [
        _vm._m(0),
        _c("div", [
          _c("p", { staticClass: "text-normal-bold mb-2" }, [
            _vm._v("Proposed Name"),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.proposal.roadname) +
                " " +
                _vm._s(_vm.proposal.roadType)
            ),
          ]),
        ]),
        _c("h4", { staticClass: "font-bold" }),
        _c(
          "div",
          { staticClass: "w-full flex content-center items-start mt-8" },
          [
            _c("div", { staticClass: "pr-4 w-1/2 items-start" }, [
              _c("p", { staticClass: "text-normal-bold mb-2" }, [
                _vm._v("Proposal Submitted Date"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.format(_vm.proposal.dateSubmitted)))]),
            ]),
            _c("div", { staticClass: "pl-4 w-1/2 flex flex-col" }, [
              _c("p", { staticClass: "text-normal-bold mb-2" }, [
                _vm._v("Proposal Submitted By"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.proposal.proposerName))]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "border-b-2 mb-6 self-center" }, [
      _c("h3", { staticClass: "font-bold text-white" }, [
        _vm._v("Proposal Info"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }