<template>
  <div class="base-container text-black mb-12">
    <span class="flex items-center text-black mt-3">
      <h1 class="text-title" data-cypress="header-text">
        Manage Organisations
      </h1>
      <router-link
        :to="{ name: 'organisationsAdd' }"
        class="ml-auto button-blue-hollow"
        data-cypress="addneworganisation-button"
        >Add New Organisation</router-link
      >
    </span>
    <div class="w-full mt-4">
      <input
        type="text"
        class="input-area bg-white w-full"
        placeholder="Search by organisation name..."
        v-model="queryParams.search"
        @input="searchOrganisations"
      />
    </div>
    <Loading-spinner v-wait:visible="'loadingOrg'" class="flex-0" />
    <OrganisationList
      :organisations="organisations.orgs"
      v-wait:hidden="'loadingOrg'"
    >
      <template slot-scope="{ organisation }">
        <div
          class="w-12 h-full flex-auto flex flex-col content-around items-center "
        >
          <router-link
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            data-cypress="edit-organisation-link"
            :to="{
              name: 'organisationsEdit',
              params: {
                identifier: organisation.identifier
              }
            }"
            :title="`Edit ${organisation.organisation_name} details`"
            ><font-awesome-icon icon="edit" class="flex-auto"
          /></router-link>
          <router-link
            :to="{
              name: 'administerUsers',
              query: { organisation_id: organisation.id }
            }"
            data-cypress="users-organisation-link"
            :title="`Manage ${organisation.organisation_name}'s users`"
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            ><font-awesome-icon icon="users" class="flex-auto"
          /></router-link>
          <router-link
            :to="{
              name: 'placeNameProposals',
              query: { organisation_id: organisation.id }
            }"
            data-cypress="proposals-organisation-link"
            :title="`View ${organisation.organisation_name}'s proposals`"
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            ><font-awesome-icon icon="tree" class="flex-auto"
          /></router-link>
          <button
            v-if="organisation.organisation_status == 'approved'"
            :title="`Suspend ${organisation.organisation_name}`"
            class="flex-auto flex justify-center items-center text-red font-bold h-1/4 w-full action-button suspend-user "
            data-cypress="suspend-button"
            @click="confirmOrganisation(organisation)"
          >
            <font-awesome-icon icon="lock" class="flex-auto" />
          </button>

          <button
            v-if="organisation.organisation_status != 'approved'"
            :title="`Approve ${organisation.organisation_name}`"
            class="flex-auto flex justify-center items-center text-green font-bold h-1/4 w-full action-button activate-user "
            data-cypress="suspend-button"
            @click="confirmOrganisation(organisation)"
          >
            <font-awesome-icon icon="check" class="flex-auto" />
          </button>
        </div>
      </template>
    </OrganisationList>
    <div
      class="self-center justify-center flex mt-32 text-black font-bold text-2xl w-full"
      v-if="!$wait.is(`loadingOrg`) && organisations && !organisations.count"
    >
      No Organisations Found
    </div>

    <div
      v-if="!$wait.is(`loadingOrg`) && organisations && organisations.count"
      class="mx-auto text-center self-center"
    >
      <paginate
        v-model="queryParams.page"
        :page-count="Math.ceil(organisations.count / queryParams.limit)"
        :page-range="10"
        :margin-pages="1"
        :click-handler="clickCallback"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
      <p>
        Showing
        {{ ((queryParams.page - 1) * queryParams.limit + 1).toLocaleString() }}
        to
        {{
          Math.min(
            (queryParams.page - 1) * queryParams.limit + queryParams.limit,
            organisations.count
          ).toLocaleString()
        }}
        of
        {{ organisations.count.toLocaleString() }}
        organisations
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import organisationModal from "./organisationModal.vue";
import Paginate from "vuejs-paginate";
import OrganisationList from "./organisationList";

export default {
  name: "manageOrganisation",
  props: {
    organisation: {
      name: "",
      members: "",
      proposals: "",
      address: ""
    }
  },
  components: {
    Paginate,
    OrganisationList
  },
  async mounted() {
    // Get organisation list from DB
    this.$wait.start("loadingOrg");
    await this.getOrganisations(this.queryParams);
    this.$wait.end("loadingOrg");
  },
  data() {
    return {
      queryParams: {
        page: 1,
        limit: 20,
        search: ""
      }
    };
  },
  methods: {
    async searchOrganisations(event) {
      if (event.target.value.length >= 3 || event.target.value == "") {
        this.$wait.start("loadingOrg");
        this.queryParams.page = 1;
        window.scrollTo(0, 0);
        await this.getOrganisations(this.queryParams);
        this.$wait.end("loadingOrg");
      }
    },
    async clickCallback(pageNum) {
      this.$wait.start("loadingOrg");
      window.scrollTo(0, 0);
      await this.getOrganisations(this.queryParams);
      this.queryParams.page = pageNum;
      this.$wait.end("loadingOrg");
    },
    confirmOrganisation(organisation) {
      let queryParams = Object.assign({}, this.queryParams);
      this.$modal.show(
        organisationModal,
        { organisation, queryParams },
        {
          height: "auto"
        }
      );
    },
    ...mapActions(["getOrganisations"])
  },
  computed: {
    ...mapGetters(["organisations","showLoginModal"])
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.getOrganisations();
      }
    }
  }
};
</script>

<style scoped>
.action-button.activate-user:hover {
  background: #38c172;
  color: #fff;
}
.action-button.activate-user:disabled:hover {
  background: #fff;
  color: #38c172;
}
</style>
