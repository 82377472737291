var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main flex flex-grow" }, [
    _c(
      "div",
      { staticClass: "base-container flex flex-col" },
      [
        _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
          _c(
            "div",
            { staticClass: "flex text-normal self-center justify-center mb-4" },
            [_c("Road", { staticClass: "road-img" })],
            1
          ),
          _c(
            "h1",
            { staticClass: "flex self-center justify-center text-title mb-4" },
            [_vm._v(" Manage Road Name Proposals ")]
          ),
          _c(
            "div",
            { staticClass: "flex flex-1 flex-row items-between flex-wrap" },
            [
              _vm.user.isAdmin
                ? _c("RnaFilteredList", {
                    staticClass: "flex-1 mr-2 bg-white",
                    attrs: {
                      rnaList: _vm.rnaList,
                      name: "rnaFilter",
                      id: "rnaFilter",
                      "data-cypress": "filter-by-rna",
                      placeholder: "Filter by Authority",
                    },
                    model: {
                      value: _vm.rnaName,
                      callback: function ($$v) {
                        _vm.rnaName = $$v
                      },
                      expression: "rnaName",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex-0" },
                [
                  _c("Checkbox", {
                    directives: [{ name: "validate", rawName: "v-validate" }],
                    staticClass: "text-black ml-auto flex-no-shrink",
                    attrs: {
                      "data-cypress": "checkboxArchived",
                      disabled: false,
                      name: "isArchived",
                      label: "Show Archived Proposals?",
                    },
                    model: {
                      value: _vm.includeArchived,
                      callback: function ($$v) {
                        _vm.includeArchived = $$v
                      },
                      expression: "includeArchived",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.fetchError ? _c("NetworkError") : _vm._e(),
        !_vm.fetchError
          ? _c("showLoading", {
              attrs: { isLoading: "loading Proposals" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "component",
                    fn: function () {
                      return [
                        _c(
                          "section",
                          {
                            staticClass:
                              "justify-center w-full block self-center",
                          },
                          [
                            _vm._l(
                              _vm.roadnameProposals,
                              function (proposal, index) {
                                return _c("ProposalListItem", {
                                  key: index,
                                  attrs: { proposal: proposal },
                                })
                              }
                            ),
                            !_vm.roadnameProposals.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-center items-center flex-grow mt-8",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "flex-0 text-black" },
                                      [_vm._v("No Road Name Proposals found.")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1240448189
              ),
            })
          : _vm._e(),
        !_vm.fetchError
          ? _c(
              "section",
              {
                staticClass:
                  "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
              },
              [
                !_vm.$wait.is(`loading Proposals`) &&
                _vm.roadnameProposals.length
                  ? _c(
                      "div",
                      { staticClass: "mx-auto text-center self-center" },
                      [
                        _c("paginate", {
                          attrs: {
                            "page-count": Math.ceil(
                              _vm.totalProposalsForQuery / _vm.limit
                            ),
                            "page-range": 10,
                            "margin-pages": 1,
                            "prev-text": "<",
                            "next-text": ">",
                            "container-class": "pagination",
                            "page-class": "page-item",
                          },
                          model: {
                            value: _vm.page,
                            callback: function ($$v) {
                              _vm.page = $$v
                            },
                            expression: "page",
                          },
                        }),
                        _c("p", [
                          _vm._v(
                            " Showing " +
                              _vm._s(
                                (
                                  (_vm.page - 1) * _vm.limit +
                                  1
                                ).toLocaleString()
                              ) +
                              " to " +
                              _vm._s(
                                Math.min(
                                  (_vm.page - 1) * _vm.limit + _vm.limit,
                                  _vm.totalProposalsForQuery
                                ).toLocaleString()
                              ) +
                              " of " +
                              _vm._s(
                                _vm.totalProposalsForQuery.toLocaleString()
                              ) +
                              " proposals "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }