<template>
  <v-select v-bind="$attrs" v-on="$listeners" :filter="filter">
    <template #selected-option="option">
      <span v-if="$attrs.label">{{ option[$attrs.label] }}</span>
      <span v-else>{{ option.label }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    filter: {
      type: Function,
      default(options, search) {
        if (search.length) {
          const re = new RegExp(`^${search}`, "i");
          return options.filter(option => re.test(option));
        }
        return options;
      }
    }
  }
};
</script>
