var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "field-error-message", class: { hidden: !_vm.message } },
    [
      _c(
        "div",
        { staticClass: "flex justify-center items-start" },
        [
          _c("errorIcon", { staticClass: "flex-0 mt-1" }),
          _c("div", { staticClass: "flex-1" }, [
            _c(
              "p",
              {
                staticClass: "pl-2 text-red-error text-normal-bold",
                attrs: { "data-cypress": "error-text" },
              },
              [_vm._v(" " + _vm._s(_vm.message) + " ")]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }