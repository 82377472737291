<template>
  <entry
    cardTitle="Roadname Supporting Document"
    :eventDate="entryData.createdAt"
  >
    <div>
      <p class="mb-2">The following proposal supporting document was added:</p>
      <a :href="entryData.url.to">
        {{ entryData.filename.to }}
      </a>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "ProposalSupportingDocumentTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
