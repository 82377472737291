"use strict";

import { waitFor } from "vue-wait";

import { getRNA, updateRna } from "../helpers/rnaCRUD";
import { searchUsers } from "../helpers/userCRUD.js";

const USERS_WAITER = "loading users";
const RNA_WAITER = "fetching rna";
const SAVING_RNA_WAITER = "saving rna";

export default {
  data() {
    return {
      rna: null,
      users: null,
      USERS_WAITER,
      RNA_WAITER,
      SAVING_RNA_WAITER
    };
  },
  methods: {
    fetchRNA: waitFor(RNA_WAITER, async function(id) {
      return await getRNA(id);
    }),
    fetchRNAUsers: waitFor(USERS_WAITER, async function(id) {
      const { users } = await searchUsers({
        road_naming_authority_id: id,
        limit: 1000000
      });
      return users;
    }),
    saveRNA: waitFor(SAVING_RNA_WAITER, async function(rna) {
      return await updateRna(rna);
    })
  }
};
