<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-3xl leading-6 font-medium text-gray-900 mb-6">
        Current Place Name Proposals
      </h3>

      <p class="mb-3 text-sm">
        The Geographical Names Board is seeking comment on the proposals below.
      </p>

      <p class="mb-3 text-sm">
        By selecting the relevant proposal, you can:
      </p>

      <ul class="mb-3 text-sm">
        <li>view details of the proposal</li>
        <li>view a map of the proposal</li>
        <li>lodge your submission</li>
      </ul>

      <p class="text-sm">
        All submissions will be considered prior to the proposal being
        officially assigned.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionCard",

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {}
};
</script>
