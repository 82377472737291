<template>
  <div class="base-container text-black flex flex-grow">
    <section class="justify-start w-full block flex flex-col">
      <a
        class="flex justify-between"
        @click="$router.push({ name: 'proposals' })"
      >
        <BackArrowWithTitle title="Create an Unapproved Gazettal Notice" />
      </a>
      <ShowLoading isLoading="loading">
        <template slot="component">
          <div class="w-full flex bg-white">
            <div class="w-5/6 mx-auto py-16">
              <p class="font-bold">
                <span class="text-red-star">*</span>On Behalf of RNA
              </p>
              <!--rnaSelect
                class="input-area input-area-alt"
                :rnaList="rnaList"
                v-model="rna"
                data-cypress="authority"
                name="rnaEntity"
                id="rnaEntity"
                :disabled="$wait.any"
              /-->
              <norns-select
                :disabled="isDisabled"
                class="w-full mr-4 norns-select"
                label="authority_name"
                :reduce="rna => rna.identifier"
                v-model="road_naming_authority_id"
                :multiple="false"
                :options="rnaList"
                :searchable="true"
                :close-on-select="true"
                placeholder="Select Road Naming Authority..."
                name="rnaEntity"
                data-cypress="authority"
                v-validate="'required'"
                data-vv-as="Road Naming Authority"
                :select-on-tab="true"
                :filter="rnaFilter"
              >
                <span slot="noOptions">Loading...</span>
              </norns-select>
              <FieldErrorMessage :message="errors.first('rnaEntity')" />
              <!-- LGA and Localities -->
              <div class="w-full flex content-center items-start mt-8">
                <div class="pr-4 w-1/2 items-start">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Local Government Area
                  </p>
                  <norns-select
                    :disabled="isDisabled"
                    class="w-full mr-4 norns-select"
                    v-model="roadName.lga_name"
                    :multiple="false"
                    :options="lgasLimited"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="Select LGA..."
                    name="LGA"
                    data-cypress="lga-select"
                    v-validate="'required'"
                    data-vv-as="Local Government Authority"
                    :select-on-tab="true"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('LGA')" />
                </div>
                <div class="pl-4 w-1/2 flex flex-col">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Address Locality
                    (Suburb)
                  </p>
                  <norns-select
                    :disabled="!roadName.lga_name || isDisabled"
                    class="w-full mr-4 norns-select"
                    v-model="roadName.suburb_name"
                    :multiple="true"
                    :options="suburbOptions"
                    :searchable="true"
                    :close-on-select="false"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Pick a Suburb"
                    name="suburb"
                    data-cypress="suburb-field"
                    v-validate="'required'"
                    data-vv-as="Suburb"
                    :select-on-tab="true"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('suburb')" />
                </div>
              </div>
              <!-- proposed road name -->
              <div class="w-full flex items-start mt-8">
                <div class="pr-4 w-2/3">
                  <p class="text-normal-bold mb-1">
                    <span class="text-red-star">*</span>Proposed Road Name
                  </p>
                  <input
                    type="text"
                    :disabled="
                      !lgaName || !roadName.suburb_name.length || isDisabled
                    "
                    class="w-full mr-4 input-area mb-1 mt-1"
                    data-cypress="roadname-field"
                    placeholder="Enter Name Here..."
                    name="roadname"
                    id="roadName"
                    v-validate="'required'"
                    v-model.trim="roadName.roadname"
                    data-vv-as="Road Name"
                  />
                  <FieldErrorMessage :message="errors.first('roadname')" />
                </div>
                <div class="pl-4 w-1/3 mb-3">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Road Type
                  </p>
                  <norns-select
                    class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                    name="roadtype"
                    id="roadType"
                    placeholder="Select Road Type..."
                    data-cypress="select-roadtype-input"
                    v-model="roadName.road_type"
                    :multiple="false"
                    :disabled="
                      !lgaName || !roadName.suburb_name.length || isDisabled
                    "
                    :options="roadTypesClean"
                    :searchable="true"
                    :close-on-select="true"
                    :select-on-tab="true"
                    v-validate="'required'"
                    data-vv-as="Road Type"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('roadtype')" />
                </div>
              </div>
              <div class="w-full flex mt-8">
                <label class="w-full">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Extent
                  </p>
                  <textarea
                    class="w-full h-48 input-area"
                    data-cypress="extent-field"
                    placeholder="Please describe where the road starts and ends"
                    v-model="roadName.extent"
                    name="extent"
                    v-validate="'required'"
                    :disabled="isDisabled"
                  ></textarea>
                </label>
              </div>
              <div class="mt-6">
                <p class="font-bold">
                  <span class="text-red-star">*</span>Gazette Date
                </p>
                <datepicker
                  :disabled="isDisabled"
                  class="p-3 w-1/3 rounded-lg bg-grey-input"
                  v-model="gazetteDate"
                  name="gazettedate"
                  placeholder="Select date..."
                  format="dd/MM/yyyy"
                  v-validate="{ required: true, date_format: 'DD/MM/YYYY' }"
                  input-class="w-full bg-grey-input text-grey-text"
                  data-vv-as="Gazette Date"
                >
                </datepicker>
                <FieldErrorMessage :message="errors.first('gazettedate')" />
              </div>
              <uploadComponent
                label="Please upload Map and Other Supporting Document"
                :drafted="true"
                :deleteFile="deleteFunction"
                :disabled="isDisabled"
              />
              <div class="flex justify-end mt-6">
                <ButtonWithSpinner
                  class="button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold"
                  data-cypress="cancel-button"
                  @click="$router.push({name: 'proposals'})"
                  :disabled="isDisabled"
                  >Cancel</ButtonWithSpinner
                >
                <ButtonWithSpinner
                  class="button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold"
                  data-cypress="addroad-button"
                  @click="save"
                  :disabled="isDisabled || !canSubmit"
                  :isSpinning="$wait.is('saving unapproved gazette')"
                  >Save</ButtonWithSpinner
                >
              </div>
            </div>
          </div>
        </template>
      </ShowLoading>
    </section>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

import roadTypeListMixin from "@/mixins/road-type-list-filter";
import lgaListMixin from "@/mixins/lga-list-filter";
import { getSuburbs } from "@/helpers/servicesq.js";
import { mapGetters, mapMutations } from "vuex";
import { deleteSupportingDocument } from "@/helpers/s3";

import { submitUnapprovedGazette } from "@/helpers/rnpCRUD";

export default {
  mixins: [roadTypeListMixin, lgaListMixin],
  components: {
    Datepicker
  },
  data() {
    return {
      roadName: {
        lga_name: "",
        suburb_name: [],
        road_type: "",
        roadname: "",
        extent: ""
      },
      road_naming_authority_id: "",
      gazetteDate: null,
      suburbOptions: []
    };
  },
  computed: {
    isDisabled() {
      return this.$wait.any;
    },
    lgaName() {
      return this.roadName.lga_name;
    },
    canSubmit() {
      return (
        !!this.roadName.lga_name &&
        !!this.roadName.roadname &&
        !!this.roadName.suburb_name.length &&
        !!this.roadName.road_type &&
        !!this.road_naming_authority_id &&
        !!this.gazetteDate
      );
    },
    ...mapGetters(["rnaList","uploadedFilesState"])
  },
  watch: {
    lgaName: {
      async handler(newVal, oldVal) {
        await this.getSuburbsForLGA(newVal);
        if (newVal !== oldVal && oldVal !== "") {
          this.roadName.suburb_name = [];
        }
      },
      immediate: false
    }
  },
  methods: {
    rnaFilter(options, search) {
      if (search.length) {
        const re = new RegExp(`^${search}`, "i");
        return options.filter(option => re.test(option.authority_name));
      }
      return options;
    },
    async getSuburbsForLGA(lgaName) {
      this.$wait.start("getting suburbs");
      const suburbs = await getSuburbs(lgaName);
      this.suburbOptions = suburbs.filter(sub => sub !== null && sub !== "");
      this.$wait.end("getting suburbs");
    },
    async save() {
      this.$wait.start("saving unapproved gazette");
      try {
        const result = await this.$validator.validate();
        if (!result) {
          throw new Error("invalid submission");
        }
        const roadName = Object.assign({}, this.roadName);
        roadName.uploadedFiles = this.uploadedFilesState;
        const savedRoad = await submitUnapprovedGazette({
          road_naming_authority_id: this.road_naming_authority_id,
          roads: [roadName],
          gazetteDate: this.gazetteDate
        });
        this.clearUploadState();
        this.$router.push({
          name: "roadNameProposalEdit",
          params: {
            proposalId: savedRoad.proposal_identifier,
            roadNameId: savedRoad.identifier
          }
        });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error saving unapproved gazette",
          text: error.response ? error.response.data.message : error
        });
      }
      this.$wait.end("saving unapproved gazette");
    },
    deleteFunction: deleteSupportingDocument,
    ...mapMutations(["clearUploadState"])
  },
  async created() {
    this.clearUploadState();
    if (this.user.isAdmin || this.user.isASP) {
      this.$wait.start("loading");
      if (!this.rnaList.length) {
        await this.$store.dispatch("getRnaList");
      }
      this.$wait.end("loading");
    }
  }
};
</script>

<style>

</style>