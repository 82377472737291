var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c("section", { staticClass: "justify-center w-full block" }, [
        _c("div", { staticClass: "flex w-full mb-3" }, [
          _c(
            "a",
            {
              staticClass: "cursor-pointer flex self-center",
              attrs: { "data-cypress": "back-arrow" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_c("BackArrowWithTitle", { attrs: { title: "Audit Trail" } })],
            1
          ),
        ]),
      ]),
      _vm._t("summary"),
      _vm._t("timeline"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }