<template>
  <div>
    <ol class="checklist pl-0">
      <div class="pb-6 dividing-line-h">
        <p class="text-normal-bold mb-2">
          How many lots or owners/occupiers does the proposed boundary amendment
          affect?
        </p>
        <div class="flex flex-row text-normal">
          <!-- <div "> -->
          <RadioButton
            class="flex-0 mr-6 mt-3 mb-1"
            name="affected_lot_owners_occupiers-<10"
            data-cypress="affected_lot_owners_occupiers"
            v-model="affected_lot_owners_occupiers"
            checkedValue="<10"
            :disabled="readOnly"
            v-validate="'required'"
            >Less than 10</RadioButton
          >

          <RadioButton
            class="flex-0 mr-6 mt-3 mb-1"
            data-cypress="affected_lot_owners_occupiers"
            name="affected_lot_owners_occupiers-10-20"
            v-model="affected_lot_owners_occupiers"
            checkedValue="10-20"
            :disabled="readOnly"
            v-validate="'required'"
            >10 - 20</RadioButton
          >

          <RadioButton
            class="flex-0 mr-6 mt-3 mb-1"
            data-cypress="affected_lot_owners_occupiers"
            name="affected_lot_owners_occupiers->20"
            v-model="affected_lot_owners_occupiers"
            checkedValue=">20"
            :disabled="readOnly"
            v-validate="'required'"
            >More than 20</RadioButton
          >
        </div>
        <div
          class="flex items-center absolute"
          v-if="errors.first('Road type check')"
        >
          <errorIcon />
          <div class="ml-2 text-red-error text-normal-bold">
            The type check field is required.
          </div>
        </div>
      </div>
      <li
        class="flex flex-row text-normal pb-6 pt-6 dividing-line-h"
        v-if="affected_lot_owners_occupiers === `>20`"
      >
        <p class="text-normal w-5/6">
          For an urban area, does the new address locality meet the size limits as
          described in
          <a
            href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105"
            target="_blank"
            >Chapter 6.8 of the NSW Addressing User Manual</a
          >?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="proposed_locality_meets_size_limits-no"
            name="proposed_locality_meets_size_limits-no"
            v-model="proposed_locality_meets_size_limits"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="proposed_locality_meets_size_limits-yes"
            data-cypress="proposed_locality_meets_size_limits-yes"
            v-model="proposed_locality_meets_size_limits"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <MessagePanel
        error
        msg="A map or GIS file is needed before this proposal can be submitted"
        v-if="map_or_gis_file_provided === false"
      />
      <li
        class="flex flex-row text-normal pb-6 pt-6 dividing-line-h"
        v-if="affected_lot_owners_occupiers === `>20`"
      >
        <p class="text-normal w-5/6">
          Has a map or GIS file been provided (ESRI Shape file (.shp) preferred)?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="map_or_gis_file_provided-no"
            name="map_or_gis_file_provided-no"
            v-model="map_or_gis_file_provided"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="map_or_gis_file_provided-yes"
            data-cypress="map_or_gis_file_provided-yes"
            v-model="map_or_gis_file_provided"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <MessagePanel
        warning
        msg="This response suggests that this proposal is likely to be deemed unacceptable"
        v-if="proposed_locality_multiple_lgas === true"
      />
      <li
        class="flex flex-row text-normal pb-6 pt-6"
        v-if="affected_lot_owners_occupiers === `>20`"
      >
        <p class="text-normal w-5/6">
          Does the address locality cover more than one LGA? If so, please
          indicate whether each affected Local Government Authority has been
          consulted?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="proposed_locality_multiple_lgas-no"
            name="proposed_locality_multiple_lgas-no"
            v-model="proposed_locality_multiple_lgas"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="proposed_locality_multiple_lgas-yes"
            data-cypress="proposed_locality_multiple_lgas-yes"
            v-model="proposed_locality_multiple_lgas"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <div
        class="flex flex-row text-normal dividing-line-h"
        v-if="affected_lot_owners_occupiers === `>20`"
      >
        <div v-if="proposed_locality_multiple_lgas" class="flex flex-grow">
          <AffectedLgaTable
            :stateKey="`boundary_amendment`"
            :readOnly="readOnly"
            :lgas="lgas"
          />
        </div>
      </div>
      <MessagePanel
        warning
        msg="This response suggests that this proposal is likely to be deemed unacceptable"
        v-if="
          public_consultation_by_council === false &&
            affected_lot_owners_occupiers === `>20`
        "
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Has a public consultation been carried out by the Council?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="public_consultation_by_council-no"
            name="public_consultation_by_council-no"
            v-model="public_consultation_by_council"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="public_consultation_by_council-yes"
            data-cypress="public_consultation_by_council-yes"
            v-model="public_consultation_by_council"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <div class="text-normal pb-6 dividing-line-h" v-if="public_consultation_by_council">
        <uploadScoped
          class="mt-4"
          data-cypress="upload-public_consultation"
          label="Attach supporting material for public consultation"
          :important="false"
          :files="public_consultation_reference_document"
          :drafted="true"
          stateKey="boundary_amendment"
          stateProp="public_consultation_reference_document"
          :uploadFile="
            params =>
              uploadFile(Object.assign({}, params, { isChecklistFile: true }))
          "
          v-on:deleteFile="
            fileIndex =>
              deleteUploadedChecklistFile({
                key: 'boundary_amendment',
                prop: 'public_consultation_reference_document',
                index: fileIndex
              })
          "
          :disabled="readOnly"
        />
      </div>
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Do all affected owners/occupiers agree with the proposed amendment?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="affected_lot_owners_occupiers_agree-no"
            name="affected_lot_owners_occupiers_agree-no"
            v-model="affected_lot_owners_occupiers_agree"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="affected_lot_owners_occupiers_agree-yes"
            data-cypress="affected_lot_owners_occupiers_agree-yes"
            v-model="affected_lot_owners_occupiers_agree"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>

      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Do the address locality boundaries follow clear and distinguishable
          lines such as rivers, railways, major road centerlines?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="proposed_locality_follows_clear_lines-no"
            name="proposed_locality_follows_clear_lines-no"
            v-model="proposed_locality_follows_clear_lines"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="proposed_locality_follows_clear_lines-yes"
            data-cypress="proposed_locality_follows_clear_lines-yes"
            v-model="proposed_locality_follows_clear_lines"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <MessagePanel
        warning
        msg="This response suggests that this proposal is likely to be deemed unacceptable"
        v-if="proposed_locality_boundaries_bisect_properties === true"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Do the address locality boundaries bisect properties?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="proposed_locality_boundaries_bisect_properties-no"
            name="proposed_locality_boundaries_bisect_properties-no"
            v-model="proposed_locality_boundaries_bisect_properties"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="proposed_locality_boundaries_bisect_properties-yes"
            data-cypress="proposed_locality_boundaries_bisect_properties-yes"
            v-model="proposed_locality_boundaries_bisect_properties"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapPnpGetters } = createNamespacedHelpers("pnpState");
const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");
const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import AffectedLgaTable from "./affectedLgaTable.vue";
import MessagePanel from "@/components/proposals/place/messagePanel";

export default {
  props: {
    readOnly: {
      type: Boolean
    }
  },
  components: {
    AffectedLgaTable,
    MessagePanel
  },
  computed: {
    proposed_locality_meets_size_limits: {
      get() {
        return this.get_boundary_amendment_proposed_locality_meets_size_limits();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_proposed_locality_meets_size_limits(
            value
          );
      }
    },
    map_or_gis_file_provided: {
      get() {
        return this.get_boundary_amendment_map_or_gis_file_provided();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_map_or_gis_file_provided(value);
      }
    },
    proposed_locality_multiple_lgas: {
      get() {
        return this.get_boundary_amendment_proposed_locality_multiple_lgas();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_proposed_locality_multiple_lgas(value);
      }
    },
    lgas: {
      get() {
        return this.get_boundary_amendment_lgas();
      },
      set(value) {
        if (value || value === false) this.set_boundary_amendment_lgas(value);
      }
    },
    public_consultation_by_council: {
      get() {
        return this.get_boundary_amendment_public_consultation_by_council();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_public_consultation_by_council(value);
      }
    },
    public_consultation_reference_document: {
      get() {
        return this.get_boundary_amendment_public_consultation_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_public_consultation_reference_document(value);
      }
    },
    affected_lot_owners_occupiers: {
      get() {
        return this.get_boundary_amendment_affected_lot_owners_occupiers();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_affected_lot_owners_occupiers(value);
      }
    },
    affected_lot_owners_occupiers_agree: {
      get() {
        return this.get_boundary_amendment_affected_lot_owners_occupiers_agree();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_affected_lot_owners_occupiers_agree(
            value
          );
      }
    },
    proposed_locality_follows_clear_lines: {
      get() {
        return this.get_boundary_amendment_proposed_locality_follows_clear_lines();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_proposed_locality_follows_clear_lines(
            value
          );
      }
    },
    proposed_locality_boundaries_bisect_properties: {
      get() {
        return this.get_boundary_amendment_proposed_locality_boundaries_bisect_properties();
      },
      set(value) {
        if (value || value === false)
          this.set_boundary_amendment_proposed_locality_boundaries_bisect_properties(
            value
          );
      }
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_boundary_amendment_proposed_locality_meets_size_limits",
      "get_boundary_amendment_map_or_gis_file_provided",
      "get_boundary_amendment_proposed_locality_multiple_lgas",
      "get_boundary_amendment_lgas",
      "get_boundary_amendment_public_consultation_by_council",
      "get_boundary_amendment_public_consultation_reference_document",
      "get_boundary_amendment_affected_lot_owners_occupiers",
      "get_boundary_amendment_affected_lot_owners_occupiers_agree",
      "get_boundary_amendment_proposed_locality_follows_clear_lines",
      "get_boundary_amendment_proposed_locality_boundaries_bisect_properties"
    ]),
    ...mapPnpMutations([
      "deleteUploadedChecklistFile",
      "set_boundary_amendment_proposed_locality_meets_size_limits",
      "set_boundary_amendment_map_or_gis_file_provided",
      "set_boundary_amendment_proposed_locality_multiple_lgas",
      "set_boundary_amendment_lgas",
      "set_boundary_amendment_public_consultation_by_council",
      "set_boundary_amendment_public_consultation_reference_document",
      "set_boundary_amendment_affected_lot_owners_occupiers",
      "set_boundary_amendment_affected_lot_owners_occupiers_agree",
      "set_boundary_amendment_proposed_locality_follows_clear_lines",
      "set_boundary_amendment_proposed_locality_boundaries_bisect_properties"
    ])
  }
};
</script>

<style scoped>
.checklist {
  list-style: none;
  counter-reset: li;
  /* list-style-position: inside; */
}

.checklist li::before {
  content: "." counter(li);
  width: 1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.checklist li {
  counter-increment: li;
}
</style>