import { render, staticRenderFns } from "./place.vue?vue&type=template&id=771af74a&scoped=true&"
import script from "./place.vue?vue&type=script&lang=js&"
export * from "./place.vue?vue&type=script&lang=js&"
import style0 from "./place.vue?vue&type=style&index=0&id=771af74a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771af74a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('771af74a')) {
      api.createRecord('771af74a', component.options)
    } else {
      api.reload('771af74a', component.options)
    }
    module.hot.accept("./place.vue?vue&type=template&id=771af74a&scoped=true&", function () {
      api.rerender('771af74a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-form/name-details-and-origin/components/place.vue"
export default component.exports