import { getCredentials } from "../../helpers/s3";
import * as rnpCruds from "../../helpers/rnpCRUD";
import * as servicesq from "../../helpers/servicesq";
import { getProposalById } from "../../helpers/proposalsCRUD";
//import moment from "moment";

const createProposalBucket = () => ({
  reference_no: "",
  status: "Drafted",
  roads: [],
  road_naming_authority_id: "",
  proposer_id: ""
});

const createRoadName = proposal_id => ({
  suburb_name: [],
  uploadedFiles: [],
  lga_name: "",
  roadname: "",
  road_type: "",
  roadname_classification: "",
  roadname_origin: "",
  extent: "",
  distance: "",
  geometry: "",
  has_multicultural_dimension: undefined,
  has_aboriginal_dimension: undefined,
  comment: "",
  name_status: "",
  proposal_id,
  commemorated: null,
  commemorated_full_name: null,
  commemorated_birth_date: null,
  commemorated_death_date: null,
  commemorated_dates_unknown: false,
  commemorated_dates_unknown_description: null,
  commemorated_association_description: null,
  commemorated_source: null,
  commemorated_supporting: []
});

// initial state
const state = {
  roadNameProposalBucket: createProposalBucket(),
  roadNameProposal: createRoadName(),
  lgas: [],
  roadTypes: [],
  rejectedRoad: [],
  rejectedRoadInfo: [],
  roadForAppeal: [],
  reasonForAppeal: "",
  appealedRoadInfo: [],
  roadsForGazette: [],
  erratumGazette: {
    userValues: false,
    suburb_name: null,
    roadName: null,
    roadType: null,
    extent: null,
    gazetteNumber: null,
    folioNumber: null,
    publicationDate: null
  }
};

// getters
const getters = {
  erratumGazette: state => {
    return state.erratumGazette;
  },
  roadsForProposal: state => {
    return state.roadNameProposalBucket.roads;
  },

  roadNameProposalBucket: state => state.roadNameProposalBucket,

  roadNameProposal: state => {
    return state.roadNameProposal;
  },

  lgaState: state => {
    return state.lgas;
  },

  roadTypeList: state => state.roadTypes,

  rejectedRoadInfo: state => {
    return state.rejectedRoadInfo;
  },

  appealedRoadInfo: state => {
    return state.appealedRoadInfo;
  },

  roadForAppeal: state => {
    return state.roadForAppeal;
  },

  getAppealReason: state => {
    return state.reasonForAppeal;
  },

  getRoadsForGazette: state => {
    return state.roadsForGazette;
  }
};

// actions
const actions = {
  async pushRoadnameProposalToDatabase({ state, commit }, status) {
    const roads = state.roadNameProposalBucket.roads.map(r => {
      return {
        ...r,
        name_status: status
      };
    });
    const proposal = {
      ...state.roadNameProposalBucket,
      status,
      roads
    };
    await rnpCruds.saveRoadnameProposalToDb(proposal);
    commit("proposalstatus", status);
  },

  async getAllLgasFromServiceSq(state) {
    state.commit("mutateLgaState", await servicesq.getAllLgasFromServiceSq());
  },

  async fetchRoadTypes({ commit }) {
    const roadTypes = await rnpCruds.getRoadnameTypes();

    commit("setRoadTypeList", roadTypes.types);
  },

  async getRejectedRoadInfo(state, id) {
    const rejected = await rnpCruds.getRejectedRoadInfo(id);
    state.commit("rejectedRoadInfo", rejected[rejected.length - 1]);
  },

  async getAppeledRoadInfo(state, id) {
    const appealedRoad = await rnpCruds.getAppealedRoadInfo(id);

    state.commit("appealedRoadInfo", appealedRoad[appealedRoad.length - 1]);
  },

  async getRoadNameProposalById(state, id) {
    const roadNameProposal = await rnpCruds.getRoadNameById(id);
    /*
    if (roadNameProposal.commemorated_birth_date) {
      roadNameProposal.commemorated_birth_date = moment(
        roadNameProposal.commemorated_birth_date
      ).format("D/M/YYYY");
    }
    if (roadNameProposal.commemorated_death_date) {
      roadNameProposal.commemorated_death_date = moment(
        roadNameProposal.commemorated_death_date
      ).format("D/M/YYYY");
    }
    */
    state.commit("setRoadNameProposal", roadNameProposal);
  },

  async getRoadNameProposalBucketById(state, id) {
    const bucket = await getProposalById(id);
    state.commit("setProposalBucket", bucket);
  },

  async saveDraftRoadNameProposal(state, proposal) {
    await rnpCruds.saveDraftRoadNameProposal(proposal);
  },

  uploadCommemorationFile: async (state, payload) => {
    let uploadCreds;
    try {
      uploadCreds = await getCredentials(
        payload.fileData.s3Key,
        payload.fileData.file.name
      );
    } catch (e) {
      console.log("There was an error", e);

      Vue.notify({
        group: "toast",
        type: "notice",
        title: "Unsupported file type",
        text: "Please choose a different type of file"
      });
      return;
    }

    const formData = new FormData();
    formData.append("Content-Disposition", "attachment");
    formData.append("Content-Type", "application/octet-stream");
    formData.append("Cache-Control", "max-age=31536000");
    Object.entries(uploadCreds.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", payload.fileData.file); // must be the last one

    const xhr = new XMLHttpRequest();
    return new Promise(function (resolve, reject) {
      xhr.open("POST", uploadCreds.url, true);
      xhr.send(formData);
      xhr.onload = function () {
        if (this.status === 204) {
          if (payload.fileData.document_type) {
            resolve({
              title: payload.fileData.file.name,
              s3_key: uploadCreds.fields.key,
              document_type: payload.fileData.document_type
            });
          }

          resolve({
            title: payload.fileData.file.name,
            s3_key: uploadCreds.fields.key
          });

          state.commit("addFileToCommemoration", {
            key: payload.key,
            prop: payload.prop,
            title: payload.fileData.file.name,
            s3_key: uploadCreds.fields.key
          });

          resolve();
        } else {
          console.log(this);
          Vue.notify({
            group: "toast",
            type: "error",
            title: this.status + " Error",
            text: "Please choose a different file 4"
          });
          reject(new Error("Error, status code = " + this.status));
        }
      };
    });
  }
};

// mutations
const mutations = {
  setSourceErratumGazette(state, payload) {
    state.erratumGazette[payload.target] = payload.value;
  },

  setErratumGazette(state, payload) {
    state.erratumGazette.userValues = true;
    state.erratumGazette[payload.target] = payload.value;
  },

  resetErratumGazette() {
    state.erratumGazette.userValues = false;
    state.erratumGazette.suburb_name = null;
    state.erratumGazette.roadName = null;
    state.erratumGazette.roadType = null;
    state.erratumGazette.extent = null;
    state.erratumGazette.gazetteNumber = null;
    state.erratumGazette.folioNumber = null;
    state.erratumGazette.publicationDate = null;
  },

  // proposal bucket mutators
  setProposalBucket(state, bucket) {
    state.roadNameProposalBucket = bucket;
  },

  proposalIdentifier(state, payload) {
    state.roadNameProposalBucket.identifier = payload;
  },

  setReferenceName(state, value) {
    state.roadNameProposalBucket.reference_no = value;
  },

  setBucketRNA(state, value) {
    state.roadNameProposalBucket.road_naming_authority_id = value;
  },

  proposalstatus(state, payload) {
    state.roadNameProposalBucket.status = payload;
    for (let road of state.roadNameProposalBucket.roads) {
      road.name_status = payload;
    }
  },

  mutateLgaState(state, payload) {
    state.lgas = payload.LGAs;
  },

  setRoadTypeList(state, roadTypes) {
    state.roadTypes = roadTypes;
  },

  proposalUser(state, payload) {
    state.roadNameProposalBucket.user = payload;
  },

  clearState(state) {
    state.roadNameProposal = createRoadName();
    state.roadNameProposalBucket = createProposalBucket();
  },

  updateValidityStatus(state, payload) {
    state.roadNameProposalBucket.roads[payload.index].validity =
      payload.validity;
  },

  roadsForProposalStateRemove(state, index) {
    state.roadNameProposalBucket.roads.splice(index, 1);
  },

  // individual road name proposal mutators
  editRoadName(state, index) {
    const proposalId =
      state.roadNameProposalBucket.identifier !== "create"
        ? state.roadNameProposalBucket.identifier
        : "";
    state.roadNameProposal = state.roadNameProposalBucket.roads[index]
      ? Object.assign({}, state.roadNameProposalBucket.roads[index])
      : createRoadName(proposalId);
  },

  addRoadToProposal(state, { road, index }) {
    state.roadNameProposalBucket.roads.splice(index, 1, road);
    state.roadNameProposal = createRoadName();
  },

  addBulkRoadsToProposal(state, { roads }) {
    state.roadNameProposalBucket.roads = state.roadNameProposalBucket.roads.concat(
      roads
    );
  },

  setRoadsToBulkGazette(state, roads) {
    state.roadsForGazette = roads;
  },

  toggleSelectRoadsForGazette(state, { road, value }) {
    const roadInState = state.roadsForGazette.find(
      rd => rd.identifier === road.identifier
    );
    roadInState.selected = !!value;
  },

  setRoadNameProposal(state, value) {
    value.commemorated_dates_unknown = !!value.commemorated_dates_unknown_description;

    state.roadNameProposal = value;
  },

  setRoadSuburbName(state, value) {
    state.roadNameProposal.suburb_name = value;
  },

  setRoadLGAName(state, value) {
    state.roadNameProposal.lga_name = value;
  },

  setRoadName(state, value) {
    state.roadNameProposal.roadname = value;
  },

  setRoadType(state, value) {
    // TODO: this should be the type Id,not the value which gets converted in the api.
    state.roadNameProposal.road_type = value;
  },

  setProposalType(state, value) {
    // TODO: this should be the type Id,not the value which gets converted in the api.
    state.roadNameProposal.roadname_classification = value;
  },

  setRoadDistance(state, value) {
    state.roadNameProposal.distance = value;
  },

  setRoadGeometry(state, value) {
    state.roadNameProposal.geometry = value;
  },

  setRoadOrigin(state, value) {
    state.roadNameProposal.roadname_origin = value;
  },

  setRoadExtent(state, value) {
    state.roadNameProposal.extent = value;
  },

  setRoadMulticultruralDimension(state, value) {
    state.roadNameProposal.has_multicultural_dimension = value;
  },

  setRoadAboriginalDimension(state, value) {
    state.roadNameProposal.has_aboriginal_dimension = value;
  },

  setRoadComment(state, value) {
    state.roadNameProposal.comment = value;
  },

  setRoadStatus(state, value) {
    state.roadNameProposal.name_status = value;
  },

  setCommemoration(state, payload) {
    state.roadNameProposal.commemorated = payload.isCommemorated;
  },

  setCommemoratedPerson(state, payload) {
    state.roadNameProposal.commemorated_full_name = payload.name;
  },

  setCommemoratedPersonDob(state, payload) {
    state.roadNameProposal.commemorated_birth_date = payload.dob;
  },

  setCommemoratedPersonDod(state, payload) {
    state.roadNameProposal.commemorated_death_date = payload.dod;
  },

  setCommemorationDateUnknown(state, payload) {
    state.roadNameProposal.commemorated_dates_unknown = payload.isUnknown;
  },

  setCommemorationDateUnknownReason(state, payload) {
    state.roadNameProposal.commemorated_dates_unknown_description =
      payload.reason;
  },

  setCommemorationAssociation(state, payload) {
    state.roadNameProposal.commemorated_association_description =
      payload.association;
  },

  setCommemorationSource(state, payload) {
    state.roadNameProposal.commemorated_source = payload.source;
  },

  setRoadDocuments(state, files) {
    // TODO: rename to documents on front and back end
    state.roadNameProposal.uploadedFiles = [...files];
  },

  // appeal
  selectRoadForAppeal(state, payload) {
    state.roadForAppeal = payload;
  },

  updateAppealReason(state, payload) {
    state.reasonForAppeal = payload;
  },

  clearAppealState(state) {
    state.reasonForAppeal = "";
  },

  rejectedRoadInfo(state, getRejectedRoadInfo) {
    state.rejectedRoadInfo = getRejectedRoadInfo;
  },

  appealedRoadInfo(state, getAppealedRoadInfo) {
    state.appealedRoadInfo = getAppealedRoadInfo;
  },

  addFileToCommemoration: (state, payload) => {
    state.roadNameProposal["commemorated_supporting"].push({
      title: payload.title,
      s3_key: payload.s3_key
    });
  },

  removeUploadedCommemorationFile: (state, payload) => {
    state.roadNameProposal["commemorated_supporting"].splice(payload.index, 1);
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
