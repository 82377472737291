<template>
  <div class="base-container text-black flex flex-grow">
    <section class="justify-start w-full block flex flex-col">
      <a
        class="flex justify-between"
        @click="
          $router.push({
            name: 'roadNameProposalBucket',
            params: { proposalId }
          })
        "
      >
        <BackArrowWithTitle title="Re-gazette A Road" />
      </a>
      <ShowLoading isLoading="fetchingRoadInformation">
        <template slot="component">
          <div v-if="!!roadName">
            <div
              class="flex flex-1 items-start justify-center"
              v-if="!isReadyForReGazettal"
            >
              <div class="flex flex-row mt-8">
                <font-awesome-icon
                  class="flex-0 text-red text-3xl inline mr-2"
                  icon="exclamation-circle"
                />
                <p>
                  {{ roadName.roadname }} {{ roadName.road_type }} is not
                  currently Gazetted
                </p>
              </div>
            </div>
            <div v-if="isReadyForReGazettal">
              <div
                class="w-full flex bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4 text-center"
              >
                <proposalIcon
                  :label="roadName.roadname_classification"
                  classNames="w-10 h-10 text-normal"
                />
                <h3 class="self-center ml-4">
                  {{ roadName.roadname }} {{ roadName.road_type }}
                </h3>
                <StatusButton
                  :label="roadName.name_status"
                  :status="roadName.name_status"
                  class="ml-auto mr-6 text-normal font-bold"
                />
              </div>

              <div
                class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 pb-16 pt-12"
              >
                <div class="w-9/10 mx-auto flex flex-col">
                  <div class="flex w-full">
                    <label class="w-1/2 text-normal-bold" for="lgaText">
                      Local Government Area (LGA)
                      <input
                        id="lgaText"
                        name="lgaText"
                        type="text"
                        class="flex input-area w-full mt-2"
                        v-model="roadName.lga_name"
                        disabled
                      />
                    </label>

                    <div class="w-1/2 ml-8 text-normal-bold">
                      <p class="text-normal-bold mb-2">
                        <span class="text-red-star">*</span>Address Locality
                        (Suburb)
                      </p>
                      <norns-select
                        :disabled="!roadName.lga_name"
                        class="w-full mr-4 norns-select"
                        v-model="roadName.suburb_name"
                        :multiple="true"
                        :options="suburbOptions"
                        :searchable="true"
                        :close-on-select="false"
                        :show-labels="true"
                        :hide-selected="true"
                        placeholder="Pick a Suburb"
                        name="suburb"
                        data-cypress="suburb-field"
                        v-validate="'required'"
                        data-vv-as="Suburb"
                        :select-on-tab="true"
                      >
                        <span slot="noOptions">Loading...</span>
                      </norns-select>
                      <FieldErrorMessage :message="errors.first('suburb')" />
                    </div>
                  </div>
                  <div class="flex w-full">
                    <div class="text-normal-bold w-1/2 mt-4" for="lgaRoadType">
                      <p class="text-normal-bold mb-2">
                        <a class="text-red-star">*</a>Road Type
                      </p>
                      <norns-select
                        class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                        name="Road Type"
                        id="roadType"
                        placeholder="Select Road Type..."
                        data-cypress="roadtype-select"
                        v-model="roadName.road_type"
                        :multiple="false"
                        :options="roadTypesClean"
                        :searchable="true"
                        :close-on-select="true"
                        @input="setRoadType"
                        v-validate="'required'"
                        :select-on-tab="true"
                      >
                        <span slot="noOptions">Loading...</span>
                      </norns-select>
                    </div>

                    <div class="w-1/2 ml-8 text-normal-bold"></div>
                  </div>
                  <div class="w-full flex mt-8">
                    <label class="w-full">
                      <p class="text-normal-bold mb-2">
                        <span class="text-red-star">*</span>Extent
                      </p>
                      <textarea
                        id="extent"
                        name="extent"
                        class="w-full h-48 input-area"
                        data-cypress="extent-field"
                        placeholder="Please describe where the road starts and ends"
                        v-validate="'required'"
                        v-model="roadName.extent"
                      ></textarea>
                    </label>
                  </div>
                  <FieldErrorMessage :message="errors.first('extent')" />

                  <uploadComponent
                    label="Please upload Map and Other Supporting Document"
                    :drafted="true"
                    :deleteFile="deleteFunction"
                    :disabled="$wait.is('saving re-gazette')"
                  />
                  <div class="flex justify-end mt-6">
                    <ButtonWithSpinner
                      class="button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold"
                      data-cypress="cancel-button"
                      @click="
                        $router.push({
                          name: 'roadNameProposalBucket',
                          params: { proposalId }
                        })
                      "
                      :disabled="$wait.is('saving re-gazette')"
                      >Cancel</ButtonWithSpinner
                    >
                    <ButtonWithSpinner
                      class="button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold"
                      data-cypress="addroad-button"
                      @click="save"
                      :disabled="$wait.any || !canSubmit"
                      :isSpinning="$wait.is('saving re-gazette')"
                      >Continue</ButtonWithSpinner
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ShowLoading>
    </section>
  </div>
</template>

<script>
import proposalIcon from "../proposals/road/proposalIcon";

import { deleteSupportingDocument } from "@/helpers/s3";
import roadTypeListMixin from "@/mixins/road-type-list-filter";
import { getSuburbs } from "@/helpers/servicesq.js";
import { mapGetters, mapMutations } from "vuex";
import { getRoadNameById } from "@/helpers/rnpCRUD";

// import { submitUnapprovedGazette } from "@/helpers/rnpCRUD";

export default {
  mixins: [roadTypeListMixin],
  components: {
    proposalIcon
  },
  data() {
    return {
      roadName: null,
      suburbOptions: []
    };
  },
  computed: {
    lgaName() {
      return this.roadName && this.roadName.lga_name;
    },
    canSubmit() {
      return (
        !!this.roadName &&
        !!this.roadName.lga_name &&
        !!this.roadName.roadname &&
        !!this.roadName.suburb_name.length &&
        !!this.roadName.road_type &&
        !!this.roadName.extent
      );
    },
    roadNameId() {
      return this.$route.params.roadNameId;
    },
    proposalId() {
      return this.$route.params.proposalId;
    },
    isReadyForReGazettal() {
      if (this.roadName) {
        return this.roadName.name_status.toLowerCase() === "gazetted";
      }
      return false;
    },
    ...mapGetters(["rnaList", "uploadedFilesState"])
  },
  methods: {
    ...mapMutations(["clearUploadState"]),
    deleteFunction: deleteSupportingDocument,
    setRoadType(value) {
      this.$set(this.roadName, "road_type", value);
    },
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      this.suburbOptions = suburbs.filter(sub => sub !== null && sub !== "");
    },
    async save() {
      this.$wait.start("saving re-gazette");
      const uploads = this.uploadedFilesState;
      await this.clearUploadState();
      this.$router.push({
        name: "reGazettePreview",
        params: {
          road: this.roadName,
          proposalId: this.proposalId,
          uploads
        }
      });
      this.$wait.end("saving re-gazette");
    }
  },
  async created() {
    this.$wait.start("fetchingRoadInformation");
    try {
      await this.clearUploadState();
      this.roadName = await getRoadNameById(this.roadNameId);
      await this.getSuburbsForLGA(this.roadName.lga_name);
    } catch (error) {
      this.$notify({
        group: "toast",
        type: "error",
        title: "Unable to retrieve road name information",
        text: error.response.data.message
      });
    }
    this.$wait.end("fetchingRoadInformation");
  }
};
</script>
