var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c("section", { staticClass: "pt-1 pb-5 justify-center w-full block" }, [
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "a",
          {
            staticClass:
              "cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_c("BackArrowWithTitle", { attrs: { title: "Edit Organisation" } })],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "w-full bg-white rounded-lg" }, [
      _c(
        "div",
        {
          staticClass:
            "w-9/10 flex flex-wrap content-center mx-auto pt-16 pb-24",
        },
        [
          _c("p", { staticClass: "text-title w-full" }, [
            _vm._v("Organisation Details"),
          ]),
          _c("div", { staticClass: "w-full dividing-line-h-1" }, [_vm._v(" ")]),
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.internalOrganisation.organisation_name,
                expression: "internalOrganisation.organisation_name",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full mt-4 input-area",
            attrs: {
              type: "text",
              "data-cypress": "nameoforganisation-field",
              placeholder: "Enter name of organisation here...",
              name: "Organisation Name",
            },
            domProps: { value: _vm.internalOrganisation.organisation_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.internalOrganisation,
                  "organisation_name",
                  $event.target.value
                )
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("Organisation Name") },
          }),
          _c("p", { staticClass: "text-normal-bold w-full pt-4" }, [
            _vm._v(" Default Local Government Area "),
          ]),
          _c(
            "norns-select",
            {
              staticClass: "w-full mr-4 norns-select",
              attrs: {
                multiple: false,
                options: _vm.lgasClean,
                searchable: true,
                "close-on-select": true,
                placeholder: "Select LGA...",
                name: "LGA",
                "data-cypress": "lga-select",
                "data-vv-as": "Local Government Authority",
                "select-on-tab": true,
              },
              model: {
                value: _vm.internalOrganisation.default_lga,
                callback: function ($$v) {
                  _vm.$set(_vm.internalOrganisation, "default_lga", $$v)
                },
                expression: "internalOrganisation.default_lga",
              },
            },
            [
              _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
                _vm._v("Loading..."),
              ]),
            ]
          ),
          _vm.internalOrganisation.default_lga &&
          !_vm.lgasClean.includes(_vm.internalOrganisation.default_lga)
            ? _c("FieldErrorMessage", {
                attrs: {
                  message:
                    "LGA does not exist or name does not match available LGAs",
                },
              })
            : _vm._e(),
          _vm._m(1),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.internalOrganisation.organisation_status,
                  expression: "internalOrganisation.organisation_status",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full mt-4 input-area",
              attrs: {
                "data-cypress": "organisation-status-field",
                name: "Organisation Status",
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.internalOrganisation,
                    "organisation_status",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c(
                "option",
                {
                  attrs: {
                    value: "",
                    disabled: "",
                    hidden: "",
                    selected: "selected",
                  },
                },
                [_vm._v("Select Status")]
              ),
              _c(
                "option",
                {
                  staticClass: "bg-white w-full input-area",
                  attrs: { value: "approved" },
                },
                [_vm._v("Approved")]
              ),
              _c(
                "option",
                {
                  staticClass: "bg-white w-full input-area",
                  attrs: { value: "suspended" },
                },
                [_vm._v("Suspended")]
              ),
              _c(
                "option",
                {
                  staticClass: "bg-white w-full input-area",
                  attrs: { value: "pending" },
                },
                [_vm._v("Pending")]
              ),
            ]
          ),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("Organisation Status") },
          }),
          _c("div", { staticClass: "w-full dividing-line-h-1" }, [_vm._v(" ")]),
          _c(
            "div",
            { staticClass: "mt-6 w-full" },
            [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.internalOrganisation.address_line_1,
                    expression: "internalOrganisation.address_line_1",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full mt-4 input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "address1-field",
                  placeholder: "Enter address here...",
                  name: "Organisation Address",
                },
                domProps: { value: _vm.internalOrganisation.address_line_1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.internalOrganisation,
                      "address_line_1",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("Organisation Address") },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mt-6 w-full" }, [
            _c("p", { staticClass: "text-normal-bold" }, [
              _vm._v("Organisation Address Line 2"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.internalOrganisation.address_line_2,
                  expression: "internalOrganisation.address_line_2",
                },
              ],
              staticClass: "w-full mt-4 input-area",
              attrs: {
                type: "text",
                "data-cypress": "address2-field",
                placeholder: "Enter address here...",
                name: "address2",
              },
              domProps: { value: _vm.internalOrganisation.address_line_2 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.internalOrganisation,
                    "address_line_2",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _c("div", { staticClass: "w-full flex flex-row" }, [
            _c(
              "div",
              { staticClass: "mt-6 w-1/2" },
              [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.internalOrganisation.organisation_suburb,
                      expression: "internalOrganisation.organisation_suburb",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full mt-4 input-area",
                  attrs: {
                    type: "text",
                    "data-cypress": "suburb-field",
                    placeholder: "Enter suburb name here...",
                    name: "Organisation Suburb",
                  },
                  domProps: {
                    value: _vm.internalOrganisation.organisation_suburb,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.internalOrganisation,
                        "organisation_suburb",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("Organisation Suburb") },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-1/2 ml-8 flex flex-row" }, [
              _c("div", { staticClass: "mt-6 w-1/2" }, [
                _vm._m(4),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalOrganisation.organisation_state,
                        expression: "internalOrganisation.organisation_state",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full mt-4 input-area",
                    attrs: { "data-cypress": "state-field", name: "state" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.internalOrganisation,
                          "organisation_state",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "", hidden: "", selected: "" } },
                      [
                        _vm._v(
                          _vm._s(_vm.internalOrganisation.organisation_state)
                        ),
                      ]
                    ),
                    _c("option", { attrs: { value: "NSW", selected: "" } }, [
                      _vm._v("NSW"),
                    ]),
                    _c("option", { attrs: { value: "VIC" } }, [_vm._v("VIC")]),
                    _c("option", { attrs: { value: "SA" } }, [_vm._v("SA")]),
                    _c("option", { attrs: { value: "WA" } }, [_vm._v("VIC")]),
                    _c("option", { attrs: { value: "NT" } }, [_vm._v("NT")]),
                    _c("option", { attrs: { value: "QLD" } }, [_vm._v("QLD")]),
                    _c("option", { attrs: { value: "ACT" } }, [_vm._v("ACT")]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-6 ml-8 w-1/2" },
                [
                  _vm._m(5),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:4|min:4",
                        expression: "'required|max:4|min:4'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalOrganisation.organisation_postcode,
                        expression:
                          "internalOrganisation.organisation_postcode",
                      },
                    ],
                    staticClass: "w-full mt-4 input-area",
                    attrs: {
                      type: "text",
                      "data-cypress": "postcode-field",
                      placeholder: "Enter postcode here...",
                      name: "Organisation Postcode",
                    },
                    domProps: {
                      value: _vm.internalOrganisation.organisation_postcode,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.internalOrganisation,
                          "organisation_postcode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("FieldErrorMessage", {
                    attrs: {
                      message: _vm.errors.first("Organisation Postcode"),
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "flex my-6" }, [
      _c(
        "button",
        {
          staticClass: "button-red-hollow",
          attrs: { "data-cypress": "cancel-button" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v(" Cancel ")]
      ),
      _c(
        "button",
        {
          staticClass: "button-blue ml-auto",
          attrs: { "data-cypress": "save-button", disabled: _vm.errors.any() },
          on: { click: _vm.handleSumbitOrganisation },
        },
        [_vm._v(" Save & Continue ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold w-full pt-4" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Name of Organisation "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold w-full pt-8" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Organisation Status "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Organisation Address Line 1 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Suburb"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("State "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Postcode "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }