<template>
  <!-- <div class="base-container text-black"> -->
  <div class="w-full flex mb-8">
    <div class="w-full">
      <div class="w-full flex mb-2">
        <p class="w-3%"></p>
        <p class="text-normal-bold flex-grow mr-4 mr-4">
          Local Government Authority
        </p>
        <p class="text-normal-bold w-1/6 pl-4 mr-4">
          Consulted?
        </p>
        <p class="w-1/6"></p>
      </div>

      <div
        class="w-full flex items-center mt-3"
        v-for="(lga, index) in lgas"
        :key="index"
      >
        <p class="text-grey text-sub-title w-3%">
          {{ index + 1 }}
        </p>
        <input
          type="text"
          class="flex-grow rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
          v-bind:value="lga.lga"
          disabled
        />
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-4 ml-2"
            name="lga-consulted-yes"
            data-cypress="lga-consulted-yes"
            v-model="lga.consulted"
            :checkedValue="true"
            :disabled="true"
            v-validate="'required'"
            >Yes</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="lga-consulted-no"
            name="lga-consulted-no"
            v-model="lga.consulted"
            :checkedValue="false"
            :disabled="true"
            v-validate="'required'"
            >No</RadioButton
          >
        </div>
        <div
          class="redButtonSolid justify-center  w-1/6 p-4 items-center cursor-pointer"
          @click="removeLga(index)"
        >
          Remove
        </div>
      </div>

      <div class="w-full flex items-center mt-3">
        <p class="text-grey text-sub-title w-3%">
          {{ lgas.length + 1 }}
        </p>

        <select
          class="flex-grow flex w-3% p-4 mr-4 input-area-appear"
          data-cypress="lga-select"
          v-model="newLga.lga"
          v-validate="'required'"
          name="lgaName_field"
          id="lgaName_field"
          :disabled="readOnly"
        >
          <option
            v-for="(item, key) in lgasArray"
            :value="item"
            v-bind:key="key"
          >
            {{ item }}
          </option>
        </select>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-4 ml-2"
            name="lga-consulted-yes"
            data-cypress="lga-consulted-yes"
            v-model="newLga.consulted"
            :checkedValue="true"
            v-validate="'required'"
            :disabled="readOnly"
            >Yes</RadioButton
          >

          <RadioButton
            class="flex-0 mr-6"
            data-cypress="lga-consulted-no"
            name="lga-consulted-no"
            v-model="newLga.consulted"
            :checkedValue="false"
            v-validate="'required'"
            :disabled="readOnly"
            >No</RadioButton
          >
        </div>
        <div
          class="w-1/6 justify-center p-4 items-center cursor-pointer"
          data-cypress="checklist-add-lga-button"
          v-bind:class="{
            greenButtonFaded: readOnly || !newLga.lga || !!newLga.lga.length,
            greenButtonSolid: !readOnly && newLga.lga && newLga.lga.length
          }"
          @click="addNewLga"
          :disabled="readOnly || !newLga.lga || !!newLga.lga.length"
        >
          Add LGA
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");

export default {
  data() {
    return {
      isDisabled: false,
      newLga: {
        lga: ""
      }
    };
  },
  props: {
    lgas: {
      type: Array
    },
    stateKey: {
      type: String
    },
    readOnly: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      lgasArray: "lgaState"
    })
  },
  methods: {
    addNewLga() {
      if (!this.newLga.lga.length) return;
      this.addAffectedLga({ key: this.stateKey, lga: this.newLga });
      this.newLga = {
        lga: ""
      };
    },
    removeLga(index) {
      this.removeAffectedLga({ key: this.stateKey, index });
    },
    ...mapPnpMutations(["addAffectedLga", "removeAffectedLga"])
  }
};
</script>

<style>
.greenButtonSolid {
  background-color: #00a908;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.redButtonSolid {
  background-color: #c60c30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}
</style>
