var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.results && !_vm.$wait.is(`fetchingGeonames`)
        ? _c(
            "div",
            {
              staticClass:
                "justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3",
            },
            [
              _c("section", { staticClass: "justify-center w-full block" }, [
                _c(
                  "h3",
                  {
                    staticClass: "pb-3 mb-8 w-full text-title dividing-line-h",
                  },
                  [_vm._v(" Place Name Search ")]
                ),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500 mr-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "geographical_name" },
                      },
                      [_vm._v("Place Name")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.geographical_name,
                          expression: "search.geographical_name",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "geographical_name",
                        type: "text",
                        placeholder: "Enter all or part of the place name...",
                      },
                      domProps: { value: _vm.search.geographical_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.search,
                            "geographical_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: {
                            title:
                              "View Glossary of status values in the Geographical Names Register",
                            target: "_blank",
                            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf",
                          },
                        },
                        [_vm._v(" Status ")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.statuses || _vm.readOnly,
                          multiple: true,
                          options: _vm.statuses,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select a status",
                          name: "status",
                          id: "status",
                          "data-cypress": "status-field",
                        },
                        model: {
                          value: _vm.search.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "status", $$v)
                          },
                          expression: "search.status",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500 mr-4" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: {
                            title:
                              "View Glossary of designation values in the Geographical Names Register",
                            target: "_blank",
                            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf",
                          },
                        },
                        [_vm._v(" Designation")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.designations || _vm.readOnly,
                          multiple: true,
                          options: _vm.designations,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select designation(s)",
                          name: "designation",
                          id: "designation",
                          "data-cypress": "designation-field",
                        },
                        model: {
                          value: _vm.search.designation,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "designation", $$v)
                          },
                          expression: "search.designation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: { for: "lga" },
                        },
                        [_vm._v("LGA")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.lgas || _vm.readOnly,
                          multiple: true,
                          options: _vm.lgas,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select an LGA",
                          name: "lga",
                          id: "lga",
                          "data-cypress": "lga-field",
                        },
                        model: {
                          value: _vm.search.lga,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "lga", $$v)
                          },
                          expression: "search.lga",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500 mr-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "topographical_map" },
                      },
                      [_vm._v("1:25,000 Map Name")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.topographical_map,
                          expression: "search.topographical_map",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "topographical_map",
                        type: "text",
                        placeholder: "Enter all or part of the map name",
                      },
                      domProps: { value: _vm.search.topographical_map },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.search,
                            "topographical_map",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "w-1/2 bg-gray-500" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "parish" },
                      },
                      [_vm._v("Parish")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.parish,
                          expression: "search.parish",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "parish",
                        type: "text",
                        placeholder: "Enter all or part of the parish name",
                      },
                      domProps: { value: _vm.search.parish },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.search, "parish", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "lga" },
                      },
                      [_vm._v("Dual Named")]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search.dual_named,
                            expression: "search.dual_named",
                          },
                        ],
                        staticClass: "w-full mt-4 input-area",
                        attrs: { id: "dual_named", name: "dual_named" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.search,
                              "dual_named",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", selected: "" } }, [
                          _vm._v("Select Dual Named Status"),
                        ]),
                        _c("option", { attrs: { value: "Yes" } }, [
                          _vm._v("Yes"),
                        ]),
                        _c("option", { attrs: { value: "No" } }, [
                          _vm._v("No"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex", attrs: { name: "3 buttons" } },
                [
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass:
                        "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                      attrs: { disabled: _vm.$wait.any },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass:
                        "button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto",
                      attrs: { isSpinning: false, disabled: false },
                      on: { click: _vm.resetSearch },
                    },
                    [_vm._v("Reset")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                      attrs: { isSpinning: false, disabled: false },
                      on: { click: _vm.searchRecords },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("search-results", {
        attrs: { search: _vm.query(), geoName: _vm.geoName },
        on: { "clear-search": _vm.resetSearch, "page-change": _vm.pageChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }