var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormContainer", [
    _c("div", { staticStyle: { "text-align": "center", color: "red" } }, [
      _vm._v(" OFFICIAL: Sensitive – Personal "),
    ]),
    _c("h1", { staticClass: "pt-6 mb-10 text-center mt-4 text-title" }, [
      _vm._v(" Log in to your account "),
    ]),
    _c("div", { staticClass: "mb-6" }, [
      _c(
        "label",
        {
          staticClass: "block text-black text-normal-bold mb-2",
          attrs: { for: "username" },
        },
        [_vm._v("Email Address")]
      ),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|email",
            expression: "'required|email'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.login,
            expression: "login",
          },
        ],
        staticClass: "w-full input-area",
        class: { inputError: _vm.isInvalidLoginCredentials },
        attrs: {
          id: "username",
          name: "email",
          type: "text",
          placeholder: "",
          disabled: _vm.isWaiting,
          "data-cypress": "email",
        },
        domProps: { value: _vm.login },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.login = $event.target.value
            },
            function ($event) {
              _vm.login = _vm.login.toLowerCase()
            },
          ],
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("email"),
              expression: "errors.has('email')",
            },
          ],
          staticClass: "text-red text-sm ml-2",
          attrs: { "data-cypress": "email-error" },
        },
        [_vm._v(_vm._s(_vm.errors.first("email")))]
      ),
    ]),
    _c("div", {}, [
      _c(
        "label",
        {
          staticClass: "block text-black text-normal-bold mb-2",
          attrs: { for: "password" },
        },
        [_vm._v("Password")]
      ),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password",
          },
        ],
        staticClass: "w-full input-area",
        class: { inputError: _vm.isInvalidLoginCredentials },
        attrs: {
          id: "password",
          name: "password",
          type: "password",
          placeholder: "",
          disabled: _vm.isWaiting,
          "data-cypress": "password",
        },
        domProps: { value: _vm.password },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.signIn.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.password = $event.target.value
          },
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("password"),
              expression: "errors.has('password')",
            },
          ],
          staticClass: "text-red text-xs ml-2",
          attrs: { "data-cypress": "password-error" },
        },
        [_vm._v(_vm._s(_vm.errors.first("password")))]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "flex ml-4 pb-4",
        attrs: { "data-cypress": "invalid-credentials-error" },
      },
      [_c("CognitoErrorMessage", { attrs: { error: _vm.cognitoError } })],
      1
    ),
    _c(
      "div",
      { staticClass: "items-center justify-between text-center mt-5" },
      [
        _c(
          "ButtonWithSpinner",
          {
            staticClass: "w-full button-blue",
            attrs: {
              disabled:
                _vm.errors.has("email") ||
                _vm.errors.has("password") ||
                _vm.isWaiting,
              isSpinning: _vm.isWaiting,
              "data-cypress": "login",
            },
            on: { click: _vm.signIn },
          },
          [_vm._v("Submit")]
        ),
        _c(
          "a",
          {
            staticClass:
              "reset pt-4 inline-block align-baseline font-bold hover:text-blue-darker cursor-pointer",
            attrs: { "data-cypress": "reset-password" },
            on: { click: _vm.forgotPasswordRoute },
          },
          [_vm._v("I need to reset my password")]
        ),
      ],
      1
    ),
    _c("p", { staticClass: "pt-6 text-normal" }, [
      _vm._v(
        " Don’t have an account? If you are a representative of a government (Local/NSW/Other) entity, you can register here to submit and manage road and place name proposals. "
      ),
    ]),
    _c(
      "button",
      {
        staticClass:
          "register w-full mt-4 bg-white font-bold button-blue-hollow focus:outline-none focus:shadow-outline mb-10",
        attrs: { type: "button", "data-cypress": "register" },
        on: { click: _vm.handleRegister },
      },
      [_vm._v(" Register Now ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }