var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c("div", { staticClass: "w-full flex bg-white" }, [
      _c(
        "div",
        { staticClass: "w-5/6 mx-auto", class: { "py-16": !_vm.isPublic } },
        [
          _c(
            "h3",
            { staticClass: "pb-3 mb-8 w-full text-title dividing-line-h" },
            [_vm._v(" Applicant Details ")]
          ),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("p", { staticClass: "text-normal-bold my-4" }, [
                _vm._v(" What organisation do you represent? "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.organisation_represented,
                    expression: "organisation_represented",
                  },
                ],
                staticClass:
                  "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                attrs: {
                  type: "text",
                  placeholder: "Enter Organisation Here...",
                  "data-cypress": "organisation-field",
                  name: "organisation_represented",
                  "data-vv-as": "Organisation Represented",
                },
                domProps: { value: _vm.organisation_represented },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.organisation_represented = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first("organisation_represented"),
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "w-full flex" }, [
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _vm._m(0),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.first_name,
                      expression: "first_name",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass:
                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: {
                    type: "text",
                    placeholder: "Enter First Name Here...",
                    "data-cypress": "firstname-field",
                    name: "first_name",
                    "data-vv-as": "First Name",
                  },
                  domProps: { value: _vm.first_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.first_name = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("first_name") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-1/2 ml-8" },
              [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.last_name,
                      expression: "last_name",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass:
                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Last Name Here...",
                    "data-cypress": "lastname-field",
                    name: "last_name",
                    "data-vv-as": "Last Name",
                  },
                  domProps: { value: _vm.last_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.last_name = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("last_name") },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address_line_1,
                    expression: "address_line_1",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass:
                  "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                attrs: {
                  type: "text",
                  placeholder: "Enter Address Here...",
                  "data-cypress": "adress-field",
                  name: "address_line_1",
                  "data-vv-as": "Address",
                },
                domProps: { value: _vm.address_line_1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.address_line_1 = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("address_line_1") },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "w-full" }, [
            _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
              _vm._v(" Address Line 2 "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address_line_2,
                  expression: "address_line_2",
                },
              ],
              staticClass:
                "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
              attrs: {
                type: "text",
                placeholder: "e.g. Apartment number, floor number, etc",
                "data-cypress": "adress2-field",
                name: "address_line_2",
              },
              domProps: { value: _vm.address_line_2 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.address_line_2 = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "w-full flex items-center" }, [
            _c(
              "div",
              { staticClass: "w-1/2 mr-8" },
              [
                _vm._m(3),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.suburb,
                      expression: "suburb",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass:
                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Suburb Here...",
                    "data-cypress": "suburb-field",
                    name: "suburb",
                    "data-vv-as": "Suburb",
                  },
                  domProps: { value: _vm.suburb },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.suburb = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("suburb") },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-1/2 flex items-center" }, [
              _c(
                "div",
                { staticClass: "w-1/2 mr-8 mt-8 mb-2" },
                [
                  _vm._m(4),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass:
                      "w-full rounded-lg text-normal text-grey-text bg-grey-input self-center h-12 norns-select",
                    attrs: {
                      multiple: false,
                      options: _vm.australianStates,
                      searchable: false,
                      "close-on-select": true,
                      "select-on-tab": true,
                      "show-labels": false,
                      "hide-selected": false,
                      placeholder: "Select a State",
                      name: "state",
                      "data-cypress": "state-select",
                      "data-vv-as": "State",
                    },
                    model: {
                      value: _vm.state,
                      callback: function ($$v) {
                        _vm.state = $$v
                      },
                      expression: "state",
                    },
                  }),
                  _c("FieldErrorMessage", {
                    attrs: { message: _vm.errors.first("state") },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-1/2" },
                [
                  _vm._m(5),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.postcode,
                        expression: "postcode",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "digits:4",
                        expression: "'digits:4'",
                      },
                    ],
                    staticClass:
                      "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                    attrs: {
                      type: "text",
                      placeholder: "Enter Postcode Here...",
                      "data-cypress": "postcode-field",
                      name: "postcode",
                      "data-vv-as": "Postcode",
                    },
                    domProps: { value: _vm.postcode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.postcode = $event.target.value
                      },
                    },
                  }),
                  _c("FieldErrorMessage", {
                    attrs: { message: _vm.errors.first("postcode") },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "w-full flex items-center" }, [
            _c(
              "div",
              { staticClass: "w-1/2 mr-8" },
              [
                _vm._m(6),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.phone_no,
                      expression: "phone_no",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|phoneNumber",
                      expression: "'required|phoneNumber'",
                    },
                  ],
                  staticClass:
                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Phone Number Here...",
                    "data-cypress": "phonenumber-field",
                    name: "phone_no",
                    "data-vv-as": "Phone Number",
                  },
                  domProps: { value: _vm.phone_no },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.phone_no = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("phone_no") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _vm._m(7),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "email|required",
                      expression: "'email|required'",
                    },
                  ],
                  staticClass:
                    "w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: {
                    type: "text",
                    placeholder: "Enter Email Address Here...",
                    "data-cypress": "email-field",
                    name: "email",
                    "data-vv-as": "Email Address",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("email") },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
                _vm._v(" Contact Person "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.contact_person,
                    expression: "contact_person",
                  },
                ],
                staticClass:
                  "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                attrs: {
                  type: "text",
                  placeholder: "Contact Person...",
                  "data-cypress": "contactperson-field",
                  name: "contact_person",
                  "data-vv-as": "Enter Contact Person Here...",
                },
                domProps: { value: _vm.contact_person },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.contact_person = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("contact_person") },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("First Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Last Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Address Line 1 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Suburb "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("State "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Postcode "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Phone Number "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Email Address "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }