<template>
  <modal
    name="cancel-proposal"
    @before-open="getProposal"
    :width="'40%'"
    height="auto"
    :classes="'rounded-lg bg-white py-6 '"
  >
    <div class="w-9/10 mx-auto flex flex-col justify-center">
      <a @click="hide()" class="cursor-pointer ml-auto">
        <ErrorIcon classNames="w-5" />
      </a>
      <div class="w-full flex justify-center text-black mt-10">
        Withdraw Road Name Proposal?
      </div>

      <h2
        class="w-full flex justify-center font-light text-black mt-8 text-center"
      >
        {{ road.roadname }} {{ road.road_type }}
      </h2>
      <div
        class="w-full inline font-light text-black mt-4 text-center mb-4 flex-auto"
      >
        Suburb:&nbsp;
        <span
          class="font-bold text-black inline "
          v-for="(sub, index) in road.suburb_name"
          v-bind:key="index"
        >
          {{ sub
          }}<span v-if="index !== road.suburb_name.length - 1">, &nbsp;</span>
        </span>
      </div>
      <br />
      <h4 class="w-full flex justify-center font-light text-black text-center">
        in {{ road.lga_name }}
      </h4>
      <div
        class="w-full flex text-justify text-sm font-light text-black mt-4 mb-4 py-2 justify-center"
      >
        Are you sure you want to withdraw the above road name proposal?
      </div>
      <div class="flex justify-between pt-4">
        <div
          class="flex w-full justify-between content-between pt-4 pb-4 mb-4 text-center"
        >
          <button
            v-wait:disabled="'withdrawing'"
            @click="hide"
            class="button-blue-hollow w-1/2 text-sm"
          >
            No, keep this road name proposal active
          </button>
          <buttonWithSpinner
            :isSpinning="$wait.is('withdrawing')"
            @click="withdraw"
            :disabled="false"
            class="button-red w-1/2 text-sm pt-4 ml-8"
          >
            Yes, withdraw this road name proposal
          </buttonWithSpinner>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { withdrawProposal } from "../../../helpers/proposalsCRUD";
import { mapActions } from "vuex";
import { waitFor } from "vue-wait";

export default {
  name: "withdrawProposalModal",
  data() {
    return {
      road: {},
      proposalId: String
    };
  },
  computed: {
    suburbs() {
      return Array.isArray(this.road.suburb_name)
        ? this.road.suburb_name.join(", ")
        : "";
    }
  },
  methods: {
    ...mapActions(["getRoadNameProposalBucketById"]),
    getProposal(event) {
      this.road = event.params.road;
      this.proposalId = event.params.proposalId;
      return this.road;
    },
    withdraw: waitFor("withdrawing", async function() {
      try {
        await withdrawProposal(this.road.identifier);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Proposal successfully withdrawn"
        });

        this.hide();
        await this.getRoadNameProposalBucketById(this.proposalId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "Error",
          title: "Error",
          text: "Proposal withdraw unsuccessful"
        });
      }
    }),
    show() {
      this.$modal.show("cancel-proposal");
    },
    hide() {
      this.$modal.hide("cancel-proposal");
    }
  }
};
</script>
