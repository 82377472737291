<template>
  <div>
    <div class="flex flex-row text-normal mb-4 mt-4">
      <div class="w-1/2 pr-4">
        <label>
          <p class="text-normal-bold mb-2">
            <span class="text-red-star">*</span>Local Government Area(s)
          </p>
        </label>
        <norns-select
          :disabled="!lgasArray || readOnly"
          class="w-full mr-4 norns-select"
          v-model="lga_name"
          :multiple="true"
          :options="lgasClean"
          :searchable="true"
          :close-on-select="false"
          :select-on-tab="true"
          :show-labels="true"
          :hide-selected="true"
          placeholder="Select an LGA(s)"
          v-validate=""
          name="lga_name"
          data-cypress="lga-field"
          data-vv-as="Local Government Area(s)"
        ></norns-select>
        <FieldErrorMessage :message="errors.first('lga_name')" />
      </div>
      <div class="pr-4 w-1/2">
        <label>
          <p class="text-normal-bold mb-2">
            <span class="text-red-star">*</span>Address Locality/Suburb
          </p>
        </label>
        <norns-select
          :disabled="(readOnly && !isEditing) || !lga_name.length"
          class="w-full norns-select"
          v-model="suburb"
          :multiple="true"
          :options="affectedAddressLocalities"
          :searchable="true"
          :close-on-select="false"
          :select-on-tab="true"
          :show-labels="true"
          :hide-selected="true"
          placeholder="Start typing and press enter to add a Locality..."
          name="suburb"
          data-cypress="address-input"
          v-validate.continues=""
          data-vv-as="Address Localities"
        ></norns-select>
        <FieldErrorMessage
          :message="errors.first('address_localities_taglist')"
        />
      </div>
    </div>
    <label>
      <p class="text-normal-bold mb-2 pt-4">
        <span class="text-red-star">*</span>Proposed Geographical Name
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="place-name-field"
        placeholder="Enter Name Here..."
        name="geographical_name"
        id="geographical_name"
        v-validate="'required'"
        v-model="geographical_name"
        data-vv-as="Proposed Geographical Name"
        :disabled="readOnly || !lga_name.length || !suburb.length"
      />
      <FieldErrorMessage :message="errors.first('geographical_name')" />
      <showLoading isLoading="evaluatingPlaceName">
        <template v-slot:component>
          <div
            v-if="
              !!geographical_name &&
                !!geographical_name.length &&
                evaluation &&
                !!evaluation.length
            "
          >
            <MessagePanel
              v-for="(issue, key) in evaluation"
              :key="key"
              warning
              :msg="issue.shortDescription"
            />
          </div>
        </template>
      </showLoading>
    </label>
    <div class="flex flex-row text-normal mb-4 mt-4">
      <label class="pr-4 w-1/2">
        <p class="text-normal-bold mb-2">
          Parish
        </p>
        <taggableInput
          class="w-full mr-4"
          :tagList="parish"
          :placeholder="`Start typing and press enter to add a Parish...`"
          :datacypress="'parish-input'"
          :disabled="readOnly"
          v-on:addTag="
            parish => addParish({ key: 'place_name', parish: parish })
          "
          v-on:removeTag="
            index => removeParish({ key: 'place_name', index: index })
          "
        />
      </label>
      <label class="w-1/2">
        <p class="text-normal-bold mb-2">
          County
        </p>

        <taggableInput
          class="w-full mr-4"
          :tagList="county"
          :placeholder="`Start typing and press enter to add a County...`"
          :datacypress="'county-input'"
          :disabled="readOnly"
          v-on:addTag="
            county => addCounty({ key: 'place_name', county: county })
          "
          v-on:removeTag="
            index => removeCounty({ key: 'place_name', index: index })
          "
        />
      </label>
    </div>
    <div class="flex flex-row text-normal mt-6 mb-4">
      <label class="w-1/2 pr-4">
        <p class="text-normal-bold mb-2">
          State Electorate
        </p>
        <taggableInput
          class="w-full mr-4"
          :tagList="electorate"
          :placeholder="`Start typing and press enter to add an Electorate...`"
          :datacypress="'state-input'"
          :disabled="readOnly"
          v-on:addTag="
            electorate =>
              addElectorate({ key: 'place_name', electorate: electorate })
          "
          v-on:removeTag="
            index => removeElectorate({ key: 'place_name', index: index })
          "
        />
      </label>
    </div>
    <template v-if="user && !user.isPublic">
      <p class="text-normal-bold mt-8">
        Is the proposal for a School or a National Park?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3"
          data-cypress="school_or_national_park-no"
          name="school_or_national_park-no"
          v-model="school_or_national_park"
          :checkedValue="false"
          :disabled="readOnly"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3"
          data-cypress="school_or_national_park-yes"
          name="school_or_national_park-yes"
          v-model="school_or_national_park"
          :checkedValue="true"
          :disabled="readOnly"
          >Yes</RadioButton
        >
      </div>
    </template>

    <label class="w-2/3 mt-4">
      <p class="text-normal-bold mb-2 mt-6 ">
        <span class="text-red-star">*</span>Describe the feature and its extent
      </p>
      <div class="w-full mt-3 flex flex-col">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="feature-extent-location-description-field"
          v-model="description"
          name="description"
          v-validate="'required'"
          data-vv-as="Description"
          :disabled="readOnly"
        ></textarea>
        <FieldErrorMessage :message="errors.first('description')" />
      </div>
    </label>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-map"
        label="Attach Marked Map"
        :important="true"
        :files="marked_map_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="marked_map_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'marked_map_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No Marked Map was uploaded for this place name. Please upload required
          files
        </div>
      </div>
    </div>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-photo"
        label="Attach Marked Photo"
        :important="false"
        :files="marked_photo_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="marked_photo_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'marked_photo_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No Photos were uploaded for this place name.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import _debounce from "lodash.debounce";
import taggableInput from "@/components/proposals/place/taggableInput.vue";
import lgaListMixin from "@/mixins/lga-list-filter";
import MessagePanel from "@/components/proposals/place/messagePanel";

import { getSuburbs } from "@/helpers/servicesq.js";

export default {
  inject: ["$validator"],
  mixins: [lgaListMixin],
  components: {
    taggableInput,
    MessagePanel
  },
  data() {
    return {
      model: {},
      reasonForNewAmendedLocalityAddressList: [
        "Represent new community",
        "Area of proposed urban growth",
        "Unique character compared to surrounding areas",
        "Change of land use",
        "Difficulties with the efficient delivery of services",
        "Problems assigning addresses",
        "Further land development and construction of roads",
        "Duplication of address locality name",
        "Other - Please provide details"
      ],
      affectedAddressLocalities: []
    };
  },
  created() {
    if (this.lgasClean.includes(this.defaultOrganisationLga)){
      this.lga_name = [this.defaultOrganisationLga];
    }
  },
  props: {
    proposalType: {
      type: String
    },
    readOnly: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      lgasArray: "lgaState",
      defaultOrganisationLga: "defaultOrganisationLga"
    }),
    ...mapUserGetters(["user"]),
    isTypePlaceNameProposal() {
      return this.proposalType === "placeNameProposal";
    },
    isTypeNewAmendedLocality() {
      return this.proposalType === "newAmendedLocality";
    },
    isTypeLocalityBoundaryAmendment() {
      return this.proposalType === "localityBoundaryAmendment";
    },
    geographical_name: {
      get() {
        return this.get_place_name_geographical_name();
      },
      set(value) {
        this.set_place_name_geographical_name(value);
        if (value && value.length) {
          this.evaluate();
        }
      }
    },
    lga_name: {
      get() {
        const lgas = this.get_place_name_lga() || [];
        return lgas;
      },
      set(value) {
        this.set_place_name_lga(value);
        if (this.geographical_name && this.geographical_name.length) {
          this.evaluate();
        }
      }
    },
    county: {
      get() {
        return this.get_place_name_county();
      },
      set(value) {
        if (value || value === false) this.set_place_name_county(value);
      }
    },
    parish: {
      get() {
        return this.get_place_name_parish();
      },
      set(value) {
        if (value || value === false) this.set_place_name_parish(value);
      }
    },
    suburb: {
      get() {
        return this.get_place_name_locality();
      },
      set(value) {
        if (value || value === false) this.set_place_name_locality(value);
      }
    },
    electorate: {
      get() {
        return this.get_place_name_electorate();
      },
      set(value) {
        if (value || value === false) this.set_place_name_electorate(value);
      }
    },
    description: {
      get() {
        return this.get_place_name_description();
      },
      set(value) {
        this.set_place_name_description(value);
      }
    },
    marked_map_reference_document: {
      get() {
        return this.get_place_name_marked_map_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_marked_map_reference_document(value);
      }
    },
    marked_photo_reference_document: {
      get() {
        return this.get_place_name_marked_photo_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_marked_photo_reference_document(value);
      }
    },
    evaluation: {
      get() {
        return this.getEvaluation();
      }
    },
    school_or_national_park: {
      get() {
        return this.get_place_name_school_or_national_park();
      },
      set(value){
        this.set_place_name_school_or_national_park(value);
      }
    }
  },
  watch: {
    lga_name: {
      async handler(newVal) {
        if (newVal) {
          const newValsSorted = Object.assign([], newVal).sort();
          const newSubsReq = newValsSorted.map(sub =>
            this.getSuburbsForLGA(sub)
          );
          let newSubs2d = await Promise.all(newSubsReq);
          newSubs2d = newSubs2d.map((lgaSubs, index) =>
            lgaSubs.map(sub => `${sub} (${newValsSorted[index]})`)
          );
          this.affectedAddressLocalities = newSubs2d.flat();

          this.suburb = this.suburb.filter(al =>
            this.affectedAddressLocalities.includes(al)
          );
        } else {
          this.affectedAddressLocalities = [];
          this.suburb = [];
        }
      },
      immediate: false
    }
  },
  methods: {
    evaluate: _debounce(async function() {
      this.$wait.start("evaluatingPlaceName");
      try {
        await this.evaluatePlaceName({
          placeName: this.geographical_name,
          lgaNames: this.lga_name
        });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Retrieving Evaluation",
          text: error
        });
      }
      this.$wait.end("evaluatingPlaceName");
    }, 500),
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      return suburbs.filter(sub => sub !== null && sub !== "");
    },
    ...mapPnpActions(["uploadFile", "evaluatePlaceName"]),
    ...mapPnpGetters([
      "get_place_name_geographical_name",
      "get_place_name_lga",
      "get_place_name_county",
      "get_place_name_parish",
      "get_place_name_locality",
      "get_place_name_electorate",
      "get_place_name_school_or_national_park",
      "get_place_name_description",
      "get_place_name_marked_map_reference_document",
      "get_place_name_marked_photo_reference_document",
      "getEvaluation"
    ]),
    ...mapPnpMutations([
      "addLgaName",
      "removeLgaName",
      "addParish",
      "removeParish",
      "addCounty",
      "removeCounty",
      "addLocality",
      "removeLocality",
      "addElectorate",
      "removeElectorate",
      "deleteUploadedFile",
      "set_place_name_geographical_name",
      "set_place_name_lga",
      "set_place_name_county",
      "set_place_name_parish",
      "set_place_name_locality",
      "set_place_name_electorate",
      "set_place_name_school_or_national_park",
      "set_place_name_description",
      "set_place_name_marked_map_reference_document",
      "set_place_name_marked_photo_reference_document"
    ])
  }
};
</script>

<style>
.multiselect__input {
  background-color: #ecf1f3 !important;
  color: grey;
  padding-top: 10px;
}

.multiselect__placeholder {
  color: grey;
  font-weight: 400;
  font-size: 16px;
}
</style>
