"use strict";

import moment from "moment";

const userDetailsMixin = {
  computed: {
    name: {
      get() {
        return `${this.user.first_name} ${this.user.last_name}`;
      }
    },
    dateCreated: {
      get() {
        return moment(this.user.date_created).format("DD/MM/YYYY");
      }
    },
    accountType: {
      get() {
        if (this.user.isAdmin) {
          return ["GNB"];
        }
        if (this.user.isPublic) {
          return ["Member of public"];
        }
        let type = [];
        if (this.user.isRNP) {
          type.push("Road Name");
        }

        if (this.user.isPNP) {
          type.push("Place Name");
        }

        return type;
      }
    },
    mailLink() {
      return `mailto:${this.user.email}`;
    },
    telLink() {
      return `tel:${this.user.phone_no}`;
    }
  }
};

export default userDetailsMixin;
