var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "convert-to-non-dedicated",
        maxWidth: 600,
        height: "auto",
        classes: "rounded-lg bg-white",
        scrollable: false,
        clickToClose: false,
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _vm.road
        ? _c(
            "div",
            {
              staticClass:
                "w-full mx-auto pt-6 pb-10 px-6 text-black flex flex-col text-normal",
            },
            [
              _c(
                "a",
                {
                  staticClass: "cursor-pointer ml-auto",
                  on: {
                    click: function ($event) {
                      return _vm.hide()
                    },
                  },
                },
                [_c("ErrorIcon", { attrs: { classNames: "w-4" } })],
                1
              ),
              _c("div", { staticClass: "px-10" }, [
                _c("p", { staticClass: "text-center text-3xl pb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.road.roadname) +
                      " " +
                      _vm._s(_vm.road.road_type) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "px-10 pb-1 text-full" }, [
                _vm._v(" Are you sure you want to convert "),
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.road.roadname) + " " + _vm._s(_vm.road.road_type)
                  ),
                ]),
                _vm._v(" to a Non-Dedicated/Private proposal? "),
              ]),
              _c(
                "div",
                { staticClass: "flex justify-center w-full mt-10 px-10" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button-red-hollow mr-4 w-2/5",
                      on: {
                        click: function ($event) {
                          return _vm.hide()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue ml-4 w-2/5",
                      attrs: {
                        type: "button",
                        disabled: _vm.$wait.is("converting proposal"),
                        isSpinning: _vm.$wait.is("converting proposal"),
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }