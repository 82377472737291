<template>
  <div class="base-container text-black">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Compare a string with some strings
        </h3>
        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          Enter a target word followed by a list of words to compare it against
          <br />
          for phonetic similarity and edit distance scoring.
        </p>
      </div>
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <dl>
          <div class="col-span-6">
            <label
              for="street_address"
              class="block text-sm font-medium leading-5 text-gray-700 "
              >Comparison word</label
            >
            <input
              id="street_address"
              class="border p-4 mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              :placeholder="sourceString"
              v-model="sourceString"
            />
          </div>

          <div class="mt-6">
            <label
              for="about"
              class="block text-sm leading-5 font-medium text-gray-700"
            >
              List of words to compare with
            </label>
            <div class="rounded-md shadow-sm">
              <textarea
                id="about"
                rows="3"
                class="border p-4 form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :placeholder="wordList"
                v-model="wordList"
              ></textarea>
            </div>
          </div>

          <!-- RESULTS -->
          <div class="flex flex-col mt-6 mb-6" v-if="results">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
              Comparison results
            </h3>
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div
                  class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="border-b">
                      <tr>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Source
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Compared with
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Similarity score
                        </th>
                        <th
                          class="text-center px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Sounds similar
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(result, index) in results" :key="index">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="text-sm flex items-center">
                            {{ result.comparedWith }}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="text-sm leading-5 text-gray-900">
                            {{ result.sourceWord }}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-center">
                          <span
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                          >
                            {{ result.similarityScore.toFixed(2) * 100 }}
                          </span>
                        </td>
                        <td
                          class="text-center px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                        >
                          {{ result.soundsLike }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class=" mt-4 py-3 bg-grey-lighter text-left sm:px-6">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-red-dark hover:bg-red focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mr-4"
                @click="reset"
              >
                Reset
              </button>
            </span>

            <span class="inline-flex rounded-md shadow-sm">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-teal-dark hover:bg-teal focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                :disabled="formIncomplete"
                @click="checkString"
              >
                Check
              </button>
            </span>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../helpers/stringCheckApi";
import _orderBy from "lodash.orderby";

export default {
  name: "StringSimilarityChecker",

  /**
   * Local instance data
   */
  data() {
    return {
      sourceString: "cog",
      wordList: ["cat", "hat", "frog", "almond", "sausage"],
      results: null
    };
  },

  computed: {
    formIncomplete() {
      return this.sourceString === "" || this.wordList.length === 0;
    }
  },

  /**
   * Instance child components
   */
  components: {
    //
  },

  /**
   * Local instance methods
   */
  methods: {
    async checkString() {
      const res = await api.stringChecker({
        target: this.sourceString,
        list: Array.isArray(this.wordList)
          ? this.wordList
          : this.wordList.split(",")
      });

      this.results = _orderBy(res, ["similarityScore"], ["desc"]);
    },

    reset() {
      this.results = null;
      this.sourceString = "";
      this.wordList = [];
    }
  }
};
</script>
