<template>
  <!-- side bar -->
  <div
    class="flex-none w-full md:max-w-xs bg-white text-white min-h-screen"
    v-if="user"
  >
    <!-- profile -->
    <div
      class="text-center pt-14 pb-14 border-b"
      data-cypress="side-bar-user-info"
    >
      <p class="text-label">{{ userTitle }}</p>
      <h3 class="text-black mt-2 mb-2">{{ authority }}</h3>
      <div class="text-blue profile-link break-all">
        <router-link
          :to="{ name: 'profile/view', params: { id: user.email } }"
          >{{ `${user.first_name} ${user.last_name}` }}</router-link
        >
      </div>
    </div>
    <!-- sidebar buttons -->
    <div class="flex flex-col justify-center text-center">
      <router-link
        tag="div"
        class="flex-grow pt-5 pb-5 border-b menu-item cursor-pointer"
        v-if="user.isAdmin"
        :to="{ name: 'administerUsers' }"
        data-cypress="administer-users-link"
        >Administer Users</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isRNPView"
        :to="{ name: 'rnpRelevantParties' }"
        data-cypress="relevant-parties-link"
        >Relevant Parties</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isPNPView"
        :to="{ name: 'pnpInterestedParties' }"
        data-cypress="interested-parties-link"
        >Interested Parties</router-link
      >

      <router-link
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isRNPView"
        exact
        :to="{ name: 'rnpRules' }"
        data-cypress="road-and-place-name-rules-link"
        >Road Naming Rules</router-link
      >
      <router-link
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isPNPView"
        exact
        :to="{ name: 'pnpRules' }"
        data-cypress="road-and-place-name-rules-link"
        >Place Naming Rules</router-link
      >

      <router-link
        v-if="(user.isRNP || user.isAdmin) && isRNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        exact
        tag="div"
        :to="{
          name: 'proposals',
          query: {
            road_naming_authority_id:
              !user.isAdmin && !user.isASP ? user.road_naming_authority_id : ''
          }
        }"
        data-cypress="manage-road-name-proposals-link"
        >Manage Proposals</router-link
      >
      <router-link
        v-if="user.isAdmin && isRNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        exact
        tag="div"
        :to="{
          name: 'road-name-types'
        }"
        data-cypress="manage-roadname-types-link"
        >Manage Road Name Types</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="(user.isRNP || user.isAdmin) && isRNPView && !user.isASP"
        :to="{
          name: 'roadNameProposalBucket',
          params: { proposalId: 'create' }
        }"
        data-cypress="create-roadname-proposals-link"
        >Create Road Name Proposal</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="(user.isASP || user.isAdmin) && isRNPView"
        :to="{
          name: 'unapprovedGazette'
        }"
        data-cypress="create-roadname-proposals-link"
        >Create Unapproved Gazette</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="(user.isPNP || user.isPublic || user.isAdmin) && isPNPView"
        :to="{
          name: 'placeNameProposals'
        }"
        data-cypress="manage-place-name-proposals-link"
        >Manage Proposals</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="(user.isPNP || user.isAdmin || user.isPublic) && isPNPView"
        :to="{
          name: 'placeNameProposal',
          params: { proposalId: 'create' }
        }"
        data-cypress="create-place-name-proposals-link"
        >Create Place Name Proposal</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isRNPView"
        :to="{ name: 'rnasManage' }"
        data-cypress="manage-rna-link"
        >Manage RNAs</router-link
      >

      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="!user.isAdmin && user.isRNP && isRNPView && !user.isASP"
        :to="{
          name: 'rnasView',
          params: { id: user.road_naming_authority_id }
        }"
        data-cypress="my-rna-link"
        >My RNA</router-link
      >
      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isPNPView"
        :to="{ name: 'organisationsManage' }"
        data-cypress="manage-organisation-link"
        >Manage Organisations</router-link
      >

      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="!user.isAdmin && user.isPNP && isPNPView"
        :to="{
          name: 'organisationsView',
          params: { identifier: user.organisation_identifier }
        }"
        data-cypress="my-organisation-link"
        >My Organisation</router-link
      >

      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="!user.isAdmin && !user.isASP && isRNPView"
        :to="{ name: 'roadNameProposalBulkGazette' }"
        data-cypress="gazettal-purchase-order-link"
        >Bulk Gazette</router-link
      >

      <router-link
        exact
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        v-if="user.isAdmin && isRNPView"
        :to="{ name: 'gazettalOrder' }"
        data-cypress="gazettal-purchase-order-link"
        >Road Name Similarity Threshold</router-link
      >

      <router-link
        tag="div"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'profile/view', params: { id: user.email } }"
        data-cypress="manage-my-profile-link"
        >Manage My Profile</router-link
      >
      <router-link
        tag="div"
        v-if="user.isAdmin && isPNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'geoNameSearchGNB' }"
        data-cypress="switch-to-road-names-link"
        >Manage GNR</router-link
      >
      <router-link
        tag="div"
        v-if="isPNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'placeNameReport' }"
        data-cypress="place-name-report-link"
        >Place Name Proposal Summary</router-link
      >
      <router-link
        tag="div"
        v-if="user.isAdmin && isPNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'lgaUpdateService' }"
        data-cypress="place-name-report-link"
        >LGA Update Service</router-link
      >
      <router-link
        tag="div"
        id="roadNameReport"
        v-if="isRNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'roadNameReport' }"
        data-cypress="road-name-report-link"
        >Road Name Proposal Summary</router-link
      >
      <router-link
        tag="div"
        id="roadNameSearchReport"
        v-if="isRNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'roadNameSearchReport' }"
        data-cypress="road-name-search-report-link"
        >Road Name Search Report</router-link
      >
      <router-link
        tag="div"
        v-if="!user.isAdmin && isPNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        :to="{ name: 'geoNameSearchPublic' }"
        data-cypress="geoname-public-search-link"
        >Search Place Names</router-link
      >
      <div
        :key="$route.fullPath"
        v-if="(user.isRNP || user.isAdmin) && isPNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        @click="gotoRNP()"
        data-cypress="switch-to-road-names-link"
      >
        <Road class="menu-img hover:text-white" />Road Names
      </div>
      <div
        :key="$route.fullPath"
        v-if="(user.isPNP || user.isAdmin) && isRNPView"
        class="menu-item cursor-pointer flex-grow pt-5 pb-5 border-b"
        @click="gotoPNP()"
        data-cypress="switch-to-place-names-link"
      >
        <Tree class="menu-img" />Place Names
      </div>
    </div>
  </div>
</template>

<script>
import Road from "../../assets/Images/road-dark-blue.svg";
import Tree from "../../assets/Images/tree-dark-blue.svg";

import { createNamespacedHelpers, mapGetters, mapMutations } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "sidebarMenu",
  components: {
    Road,
    Tree
  },
  methods: {
    ...mapMutations(["viewRNP", "viewPNP"]),
    gotoPNP() {
      this.viewPNP();
      this.$router.push({ name: "placeNameProposals" });
    },
    gotoRNP() {
      this.viewRNP();
      this.$router.push({ name: "proposals" });
    }
  },
  computed: {
    ...mapUserGetters(["user"]),
    ...mapGetters(["isRNPView", "isPNPView"]),
    userTitle() {
      if (this.user.isAdmin) return "Team Member";

      if (this.user.isRNP || this.user.isPNP) {
        return this.user.position;
      }

      return "";
    },
    authority() {
      if (this.user.isAdmin || this.user.isAdmin)
        return "Geographical Names Board";

      if (this.user.authority_name) return this.user.authority_name;

      if (this.user.organisation_name) return this.user.organisation_name;

      return "Public User";
    }
  }
};
</script>

<style scoped>
.menu-item {
  text-decoration: none;
  color: #002664;
}
a:visited {
  color: #002664;
}
.menu-item.router-link-active {
  background: #002664;
  color: white;
}

.menu-item:hover {
  background: #002664;
  color: white;
}

.profile-link:hover {
  color: #003c9f;
}

.menu-img {
  height: 20px;
  margin-right: 5px;
}
</style>
