<template>
  <div>
    <NetworkError v-if="fetchError" />

    <ShowLoading v-if="!fetchError" isLoading="fetching-audit-trail">
      <template v-slot:component v-if="trailData">
        <audit-trail :trailData="trailData">
          <template v-slot:summary>
            <Summary :proposal="trailData.proposal"></Summary>
          </template>
          <template v-slot:timeline>
            <component
              :is="safeGetTrailEntryComponent(entry.eventType)"
              v-for="(entry, index) in trailData.timeline"
              :key="index"
              :entryData="entry"
            ></component>
          </template>
        </audit-trail>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import pnpCRUD from "@/helpers/pnpCRUD";

import AuditTrail from "@/components/proposals/common/audit-trail";
import Summary from "./summary";

import ComponentMapper from "@/components/proposals/common/audit-trail/mixins/componentMapper";

import Document from "./documents";

import Unknown from "./entries/unknown";
import Status from "./entries/status";
import Clarification from "./entries/clarification";
import BoardDecision from "./entries/board-decision";
import ClarificationAdvert from "./clarification-advert";
import Advertisement from "./entries/advertisement";
import MinisterDecision from "./entries/minister-decision";
import GazetteDate from "./entries/gazette-date";
import Email from "./entries/email";

export default {
  name: "PlaceNameAuditHistoryComponent",

  mixins: [ComponentMapper],

  /**
   * Local instance data
   */
  data() {
    return {
      fetchError: false,
      trailData: null,
      componentMap: {
        status: Status,
        clarification: Clarification,
        document: Document,
        board_decision: BoardDecision,
        clarification_advert: ClarificationAdvert,
        advertisement: Advertisement,
        minister_decision: MinisterDecision,
        gazettal: GazetteDate,
        email: Email
      }
    };
  },

  /**
   * Instance child components
   */
  components: {
    AuditTrail,
    Summary,
    Unknown
  },

  /**
   * The instance was mounted
   */
  async mounted() {
    await this.initialiseTrail();
  },

  /**
   * Local instance methods
   */
  methods: {
    /**
     * Fetch the audit trail and set it up
     */
    async initialiseTrail() {
      this.$wait.start("fetching-audit-trail");

      try {
        const [proposal, timeline] = await Promise.all([
          pnpCRUD.getSinglePlaceNameProposal(this.$route.params.proposalId),
          pnpCRUD.getProposalAuditTrailById(this.$route.params.proposalId)
        ]);
        this.trailData = { proposal, timeline };
      } catch (e) {
        this.fetchError = true;
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        this.$wait.end("fetching-audit-trail");
      }
    },
    safeGetTrailEntryComponent(eventType) {
      try {
        return this.getTrailEntryComponent(eventType);
      } catch {
        return Unknown;
      }
    }
  }
};
</script>
