var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c("div", { staticClass: "flex justify-between items-center mt-2" }, [
        _c(
          "h1",
          {
            staticClass: "text-title self-center",
            attrs: { "data-cypress": "header" },
          },
          [
            _vm.$route.meta.isPlaceNameRoute
              ? _c("Tree", { staticClass: "header-img" })
              : _vm._e(),
            _vm.$route.meta.isRoadNameRoute
              ? _c("Road", { staticClass: "header-img" })
              : _vm._e(),
            _vm._v(_vm._s(_vm.partyType) + " Parties "),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "button-blue-hollow",
            attrs: { "data-cypress": "addnew-button" },
            on: {
              click: function ($event) {
                return _vm.showEdit({ name: "", email: "" }, -1)
              },
            },
          },
          [_vm._v(" Add New " + _vm._s(_vm.partyType) + " Party ")]
        ),
      ]),
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      _c("Loading-spinner", {
        directives: [
          {
            name: "wait",
            rawName: "v-wait:visible",
            value: "loading parties",
            expression: "'loading parties'",
            arg: "visible",
          },
        ],
        staticClass: "flex-0",
      }),
      !_vm.fetchError
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "wait",
                  rawName: "v-wait:hidden",
                  value: "loading parties",
                  expression: "'loading parties'",
                  arg: "hidden",
                },
              ],
              staticClass: "flex flex-grow flex-col",
            },
            _vm._l(_vm.parties, function (party, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex flex-grow bg-white rounded-lg mt-4 items-center content-center",
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "flex items-center text-black w-1/3 h-18 pl-8",
                    },
                    [_vm._v(" " + _vm._s(party.name) + " ")]
                  ),
                  _c("p", { staticClass: "font-bold text-blue-button pl-3" }, [
                    _vm._v(" " + _vm._s(party.email) + " "),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-bold text-blue-button ml-auto cursor-pointer",
                      attrs: { "data-cypress": "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.showEdit(party, index)
                        },
                      },
                    },
                    [_vm._v(" Edit ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-bold text-red-button ml-12 mr-12 cursor-pointer",
                      attrs: { "data-cypress": "remove" },
                      on: {
                        click: function ($event) {
                          return _vm.showRemove(party, index)
                        },
                      },
                    },
                    [_vm._v(" Remove ")]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }