// default imports from vue-cli
import Vue from "vue";
import App from "./App.vue";
import VueWait from "vue-wait";

// import vue modal
import VModal from "vue-js-modal";
// import for the bulma css framework
import "@/assets/css/tailwind.css";
import "@/assets/css/vue-multiselect.css";

// import vue-notification for toast
import Notifications from "vue-notification";
// vue validations
import VeeValidate from "./helpers/validators";
import { register as registerServiceWorker } from "./registerServiceWorker.js";
import store from "./vuex/store";
import router from "./router";

import Auth from "@aws-amplify/auth";
import congitoConfig from "./aws-config/cognito";
Auth.configure(congitoConfig);

// Amplify.configure(
//   Object.assign({}, { Auth: congitoConfig }, { API: apigConfig })
// );

import registerElementComponents from "./helpers/register-element-components";
import "./font-awesome";
import { setupCalendar, DatePicker } from "v-calendar";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// vue-select css overrides
import "@/assets/css/vue-select-overrides.css";

Vue.component("v-select", vSelect);

setupCalendar(Vue, {});

Vue.component("v-datepicker", DatePicker);

Vue.use(VeeValidate);
registerServiceWorker();
registerElementComponents(Vue);

// Vue.use(AmplifyModules);
Vue.use(Notifications);
Vue.use(VueWait);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true
});

Vue.config.productionTip = false;

store.dispatch("user/checkUserAuth").then(() => {
  new Vue({
    store,
    router,
    wait: new VueWait({
      useVuex: true
    }),
    render: h => h(App)
  }).$mount("#app");
});
