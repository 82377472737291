<template>
  <div
    class="base-container text-black h-auto flex flex-col pt-6 px-2 pb-14 mb-8"
    data-cypress="delete-box"
  >
    <a @click="$emit('close')" class="cursor-pointer ml-auto">
      <errorIcon classNames="w-4" />
    </a>
    <div class="flex items-center content-center justify-center mt-8 px-10">
      <h2 v-if="organisation.organisation_status == 'approved'">
        Suspend Organisation
      </h2>
      <h2 v-else>
        Approve Organisation
      </h2>
    </div>
    <div class="w-full flex justify-center text-black mb-4 px-10">
      {{ organisation.organisation_name }}
    </div>
    <div
      v-if="organisation.organisation_status == 'approved'"
      class="flex text-black mb-8 text-normal px-10"
    >
      Are you sure you want to suspend the above organisation?
    </div>
    <div v-else class="flex text-black mb-8 text-normal px-10">
      Are you sure you want approve the above organisation?
    </div>
    <div class="flex justify-center w-full">
      <button
        :disabled="isWaiting"
        @click="$emit('close')"
        class="button-blue-hollow mr-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
        data-cypress="nokeep-button"
      >
        Close Dialog
      </button>
      <button
        v-if="organisation.organisation_status == 'approved'"
        :disabled="isWaiting"
        @click="confirm('suspended')"
        class="button-red ml-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
        data-cypress="yesdelete-button"
      >
        Suspend organisation
      </button>
      <button
        v-else
        :disabled="isWaiting"
        @click="confirm('approved')"
        class="button-green ml-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
        data-cypress="yesdelete-button"
      >
        Approve organisation
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import errorIcon from "../elements/errorIcon";
import { updateOrganisation } from "../../helpers/organisationCRUD";
export default {
  components: {
    errorIcon
  },
  name: "organisation",
  props: ["organisation", "queryParams"],
  methods: {
    ...mapActions(["getOrganisations", "getOrganisation"]),
    async confirm(action) {
      try {
        let org = Object.assign({}, this.organisation);
        org.organisation_status = action;
        await updateOrganisation(org);
        if (this.queryParams) {
          await this.getOrganisations(this.queryParams);
        } else {
          await this.getOrganisation(org.identifier);
        }
        this.$emit("close");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Deleting organisation",
          text: "The organisation could not be deleted."
        });
      }
    },
    ...mapActions(["removeOrganisation"])
  },
  computed: {
    ...mapGetters(["isWaiting"])
  }
};
</script>
<style scoped>
.nameTitle {
  font-family: Gotham;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.16px;
  line-height: 34px;
  text-align: center;
}
.titleWeight {
  color: #000000;
  font-family: Gotham;
  font-size: 18px;
  font-weight: bold;
}
.titleFormatting {
  letter-spacing: 0.1px;
  line-height: 22px;
}
.subTextWeight {
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
}
.subTextFormatting {
  color: black;
  letter-spacing: 0.08px;
  line-height: 17px;
}

.base-container {
  font-family: Gotham;
}

.popup-container {
  width: 50%;
}
</style>
