var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-3 py-3 justify-center md:mb-0", class: _vm.classNames },
    [
      _c("label", { staticClass: "container pt-1 pb-1" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
        _c("input", { attrs: { type: "checkbox" } }),
        _c("span", { staticClass: "border-2 checkmark" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }