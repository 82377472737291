"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

export function saveRoadnameProposalToDb(proposal) {
  const payload = {
    body: proposal
  };
  if (proposal.identifier) {
    return API.put(apiName, `/proposals/${proposal.identifier}`, payload);
  } else {
    return API.post(apiName, "/proposals", payload);
  }
}

export function saveDraftRoadNameProposal(draft) {
  if (draft.identifier && draft.proposal_id) {
    return API.put(
      apiName,
      `/proposals/${draft.proposal_id}/roadname/${draft.identifier}`,
      { body: draft }
    );
  } else if (draft.proposal_id) {
    return API.post(apiName, `/proposals/${draft.proposal_id}/roadname`, {
      body: draft
    });
  }
}

export function getAllRoadnameProposals() {
  return API.get(apiName, "/roadnames");
}

export function getRoadnameTypes() {
  return API.get(apiName, "/roadnames/road-name/types");
}

export function createRoadnameType(newType) {
  let payload = {
    body: {
      label: newType
    }
  };

  return API.post(apiName, "/roadnames/road-name/type", payload);
}

export function saveRoadnameType(type) {
  let payload = {
    body: {
      identifier: type.identifier,
      label: type.label,
      isActive: type.isActive
    }
  };

  return API.put(apiName, "/roadnames/road-name/type", payload);
}

export function deleteRoadnameType(type) {
  let payload = {
    body: {
      identifier: type.identifier
    }
  };

  return API.del(apiName, "/roadnames/road-name/type", payload);
}

export function getRejectedRoadInfo(id) {
  return API.get(apiName, `/roadnames/${id}/rejected`);
}

export function getSimilarRoadnamesFromDB(roadname) {
  return API.get(apiName, `/roadnames/${roadname}/similarsounding`);
}

export function getRejectionRoadGuidelines(id) {
  return API.get(apiName, `/roadnames/${id}/rejection-guidelines`);
}

export function getAppealedRoadInfo(id) {
  return API.get(apiName, `/appeal/${id}`);
}

export function submitAppealJira(appealData) {
  // prepare the payload for Jira API
  let payload = {
    body: {
      identifier: appealData.road.identifier,
      jira_id: appealData.road.jira_id,
      road: appealData.road,
      reason: appealData.reason,
      files: appealData.files
    }
  };

  return API.post(apiName, "/road/appeal", payload);
}
export function submitUpholdJira(appealData) {
  // prepare the payload for Jira API
  let payload = {
    body: {
      roadname_identifier: appealData.road.identifier,
      comment: appealData.comment
    }
  };

  return API.post(apiName, "/appeal/uphold", payload);
}
export function submitOverturnJira(appealData) {
  // prepare the payload for Jira API
  let payload = {
    body: {
      roadname_identifier: appealData.road.identifier,
      comment: appealData.comment
    }
  };

  return API.post(apiName, "/appeal/overturn", payload);
}

// create the gazettal notice
export function createGazettalNotice(
  { identifier, extent, road_type: roadType, companionRoads },
  uploads
) {
  const notice = {
    body: {
      roadnameIdentifier: identifier,
      companionRoads,
      extent,
      roadType,
      uploads
    }
  };
  return API.post(apiName, "/gazettal/notice", notice);
}

export function createErratumGazettal(roadIdentifier, erratumGazette) {
  const notice = {
    body: {
      identifier: roadIdentifier,
      ...erratumGazette
    }
  };

  return API.post(apiName, "/gazettal/erratum-notice", notice);
}

export function createReGazettalNotice(
  { identifier, extent, road_type: roadType, suburb_name: suburbs },
  uploads
) {
  const notice = {
    body: {
      roadnameIdentifier: identifier,
      extent,
      roadType,
      suburbs,
      uploads
    }
  };
  return API.post(apiName, "/gazettal/re-gazettal-notice", notice);
}

export function submitClarificationRequestJira(clarificationData) {
  // prepare the payload for Jira API
  let payload = {
    body: {
      clarification_request: clarificationData.clarification_request,
      files: clarificationData.files
    }
  };

  return API.post(
    apiName,
    `/roadnames/${clarificationData.roadnameIdentifier}/clarification`,
    payload
  );
}

export function submitClarificationResponseJira(clarificationData) {
  // prepare the payload for Jira API
  let payload = {
    body: {
      jira_id: clarificationData.road.jira_id,
      road: clarificationData.road,
      clarification_response: clarificationData.clarification_response,
      files: clarificationData.files
    }
  };

  return API.put(
    apiName,
    `/roadnames/${clarificationData.road.identifier}/clarification/${clarificationData.requestId}`,
    payload
  );
}

export async function getClarificationInfo(id) {
  return await API.get(apiName, `/roadnames/${id}/clarification`);
}

export function getRoadNameById(id) {
  return API.get(apiName, `/roadnames/${id}`);
}

export function getRoadNameByJiraId(id) {
  return API.get(apiName, `/roadnames/${id}/get-roadname-by-jira-id`);
}

export function getAllRoadTypes() {
  return API.get(apiName, "/public/rnp/road-types");
}

export function sendPublicEvaluationReport(email, roadName) {
  return API.post(apiName, "/public/rnp/email-evaluation", {
    body: { email, roadName }
  });
}

export function fetchRoadNameRecordsForReport(params, isCsv) {
  const data = {
    queryStringParameters: params
  };

  if (isCsv) {
    data.headers = {
      accept: "text/csv"
    };
  } else {
    data.headers = {
      accept: "application/json"
    };
  }
  return API.get(apiName, "/roadnames", data);
}

export function fetchRoadNamesForBulkGazette(params) {
  const data = {
    queryStringParameters: params,
    headers: {
      accept: "application/json"
    }
  };
  return API.get(apiName, "/roadnames/gazettable-roadnames", data);
}

export function fetchRoadNameStatuses() {
  return API.get(apiName, "/roadnames/statuses");
}

export function fetchRoadNameAuditTrail(roadNameId) {
  return API.get(apiName, `/roadnames/${roadNameId}/audit-trail`);
}

export function fetchRoadNamesFromSdw(params, isCsv) {
  const data = {
    queryStringParameters: params
  };

  if (isCsv) {
    data.headers = {
      accept: "text/csv"
    };
  } else {
    data.headers = {
      accept: "application/json"
    };
  }

  return API.get(apiName, "/sdw/roadnames", data);
}

export function submitConvertRoadNameProposal(id, body) {
  const payload = {
    body
  };

  return API.put(apiName, `/roadnames/${id}/convert`, payload);
}

export function submitLegacyToNonDedicated(id) {
  return API.put(apiName, `/roadnames/${id}/legacy-to-non-dedicated`);
}

export function submitUnapprovedGazette(body) {
  const payload = {
    body
  };
  return API.post(apiName, "/gazettal/unapproved-gazette", payload);
}

export async function reassignRnp(params) {
  const data = {
    body: {
      proposalIdentifier: params.proposalIdentifier,
      userFirstName: params.userFirstName,
      userLastName: params.userLastName,
      userEmail: params.userEmail,
      userIdentifier: params.userIdentifier,
      fromUserFirstName: params.fromUserFirstName,
      fromUserLastName: params.fromUserLastName
    }
  };

  const result = await API.put(apiName, "/proposals/roadname/proposal-reassignment", data);

  return result;
}