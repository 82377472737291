"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

// push new Party to database
export function savePartyToDb(partyData, type) {
  const payload = {
    body: {
      partyData
    }
  };
  return API.post(apiName, `/party/${type}`, payload);
}
export function updateParty(partyData, type) {
  const payload = {
    body: {
      partyData
    }
  };

  return API.put(apiName, `/party/${type}`, payload);
}

export function getPartyList(type) {
  return API.get(apiName, `/party/${type}`);
}
export function deletePartyFromDb(id, type) {
  const payload = {
    body: {
      id: id
    }
  };
  return API.del(apiName, `/party/${type}`, payload);
}
