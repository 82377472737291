var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.proposalId }, [
    _vm.exists || _vm.proposalId == "create"
      ? _c(
          "div",
          { staticClass: "text-black mb-16" },
          [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.returnToManageProposalsList()
                  },
                },
              },
              [
                _c("BackArrowWithTitle", {
                  staticClass: "pl-16 pt-4",
                  attrs: { title: "Manage Proposals", size: "h4" },
                }),
              ],
              1
            ),
            !_vm.readOnly
              ? _c(
                  "div",
                  { staticClass: "base-container text-3xl font-semibold" },
                  [_vm._v(" Create Place Name Proposal ")]
                )
              : _vm._e(),
            _vm.readOnly
              ? _c(
                  "div",
                  { staticClass: "base-container text-3xl font-semibold" },
                  [_vm._v(" View Place Name Proposal ")]
                )
              : _vm._e(),
            _c("showLoading", {
              attrs: { isLoading: "loadingProposal" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "component",
                    fn: function () {
                      return [
                        _c(
                          "transition",
                          {
                            attrs: { appear: "", name: "fade", mode: "out-in" },
                          },
                          [
                            _c(
                              "div",
                              { key: _vm.proposalId },
                              [
                                _c("Type", {
                                  staticClass: "base-container",
                                  attrs: {
                                    readOnly: _vm.readOnly,
                                    isAdmin: _vm.isAdmin,
                                    organisations: _vm.organisations,
                                  },
                                }),
                                _c("GeographicalDetails", {
                                  staticClass: "base-container",
                                  attrs: {
                                    proposalType: _vm.type,
                                    readOnly: _vm.readOnly,
                                  },
                                }),
                                _c("Lot", {
                                  staticClass: "base-container",
                                  attrs: {
                                    proposalType: _vm.type,
                                    readOnly: _vm.readOnly,
                                  },
                                }),
                                _c("NameDetailsAndOrigin", {
                                  staticClass: "base-container",
                                  attrs: {
                                    proposalType: _vm.type,
                                    readOnly: _vm.readOnly,
                                  },
                                }),
                                _c("AdditionalInfo", {
                                  staticClass: "base-container",
                                  attrs: {
                                    proposalType: _vm.type,
                                    readOnly: _vm.readOnly,
                                  },
                                }),
                                _c("Checklist", {
                                  staticClass: "base-container",
                                  attrs: {
                                    proposalType: _vm.type,
                                    readOnly: _vm.readOnly,
                                  },
                                }),
                                !_vm.school_or_national_park
                                  ? _c("LocalNewspaper", {
                                      staticClass: "base-container",
                                      attrs: { readOnly: _vm.readOnly },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex base-container",
                                    attrs: { name: "3 buttons" },
                                  },
                                  [
                                    _vm.showDeleteButton
                                      ? _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                                            attrs: {
                                              "data-cypress": "delete-button",
                                              isSpinning:
                                                _vm.$wait.is(
                                                  "deletingProposal"
                                                ),
                                              disabled:
                                                _vm.$wait.is(
                                                  "deletingProposal"
                                                ),
                                            },
                                            on: {
                                              click: _vm.deleteProposalById,
                                            },
                                          },
                                          [_vm._v("Delete Proposal")]
                                        )
                                      : _vm._e(),
                                    _vm.showDraftButton
                                      ? _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto",
                                            attrs: {
                                              "data-cypress":
                                                "savedraft-button",
                                              isSpinning: _vm.$wait.is(
                                                "savingProposalAsDraft"
                                              ),
                                              disabled:
                                                _vm.$wait.is(
                                                  "savingProposalAsDraft"
                                                ) || !_vm.isDraftValid,
                                            },
                                            on: { click: _vm.saveAsDraft },
                                          },
                                          [_vm._v("Save as Draft")]
                                        )
                                      : _vm._e(),
                                    _vm.showSubmitButton
                                      ? _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                                            attrs: {
                                              "data-cypress":
                                                "submit-proposal-button",
                                              isSpinning:
                                                _vm.$wait.is("savingProposal"),
                                              disabled:
                                                _vm.$wait.is(
                                                  "savingProposal"
                                                ) ||
                                                !_vm.isFormValid ||
                                                !_vm.referenceNumber ||
                                                (_vm.errors.items &&
                                                  _vm.errors.items.length > 0),
                                            },
                                            on: { click: _vm.save },
                                          },
                                          [_vm._v("Submit Proposal")]
                                        )
                                      : _vm._e(),
                                    _vm.isEditing &&
                                    _vm.type !== _vm.PROPOSAL_TYPE_PLACE
                                      ? _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-blue flex relative w-2/6 mt-4 mb-10 ml-auto",
                                            attrs: {
                                              "data-cypress":
                                                "submit-proposal-button",
                                              isSpinning:
                                                _vm.$wait.is("savingProposal"),
                                              disabled:
                                                _vm.$wait.is(
                                                  "savingProposal"
                                                ) ||
                                                !_vm.isFormValid ||
                                                !_vm.referenceNumber,
                                            },
                                            on: { click: _vm.save },
                                          },
                                          [_vm._v("Update Proposal")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1370844305
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.exists && _vm.proposalId !== "create"
      ? _c("div", { staticClass: "text-black mb-16 text-center mt-16" }, [
          _c("h3", [
            _vm._v(" no proposal found for proposal ID: "),
            _c("b", [_vm._v(_vm._s(_vm.proposalId))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }