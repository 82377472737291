var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Roadname Rejection",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v("The proposal was rejected."),
        ]),
        _c("strong", [_vm._v(_vm._s(_vm.entryData.rejectionDescription.to))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }