var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-row items-center",
      class: { disabled: _vm.disabled },
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "div",
        { staticClass: "feux-radio-outer flex justify-center items-center" },
        [
          _c("div", {
            staticClass: "feux-radio-inner",
            class: { selected: _vm.checked },
          }),
        ]
      ),
      _c(
        "span",
        { staticClass: "flex-1 text-black" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.value))]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }