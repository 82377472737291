<template>
  <div>
    <entry cardTitle="Minister Decision" :eventDate="transformedEntryData.createdAt" v-if="transformedEntryData">
      <div>
        <p class="mb-2" v-if="entryData.action === 'insert'">On {{transformedEntryData.meetingDate}}, the minister made the following decision:</p>
        <p class="mb-2" v-if="entryData.action === 'update'">The minister decision was modified to:</p>
        <strong>{{ transformedEntryData.decisionType }}</strong>
        <div>{{ transformedEntryData.summary }}</div>
        <div v-if="entryData.action === 'update'" class="pt-2">
          <div>Meeting Date: {{ transformedEntryData.meetingDate }}</div>
        </div>
      </div>
    </entry>
  </div>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";
import moment from "moment";

export default {
  name: "MinisterDecisionTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  },
  computed: {
    transformedEntryData() {
      const changes = this.entryData.changes;
      return {
        summary: changes["decision summary"].to,
        meetingDate: moment(changes["meeting date"].to).format("DD MMM YYYY"),
        decisionType: changes["board decision type"].to,
        createdAt: this.entryData.date
      };
    }
  }
};
</script>
