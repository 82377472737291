import { render, staticRenderFns } from "./proposal-list-item.vue?vue&type=template&id=7028e5ed&scoped=true"
import script from "./proposal-list-item.vue?vue&type=script&lang=js"
export * from "./proposal-list-item.vue?vue&type=script&lang=js"
import style0 from "./proposal-list-item.vue?vue&type=style&index=0&id=7028e5ed&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7028e5ed",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7028e5ed')) {
      api.createRecord('7028e5ed', component.options)
    } else {
      api.reload('7028e5ed', component.options)
    }
    module.hot.accept("./proposal-list-item.vue?vue&type=template&id=7028e5ed&scoped=true", function () {
      api.rerender('7028e5ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-list-item.vue"
export default component.exports