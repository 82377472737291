<template>
  <div
    class="w-full mx-auto pt-6 px-6 pb-14 mb-8 flex-col flex"
    data-cypress="remove-box"
  >
    <a @click="hide()" class="cursor-pointer ml-auto">
      <errorIcon classNames="w-4" />
    </a>
    <div
      class="flex items-center content-center justify-center mt-8 mb-6 px-10"
    >
      <h2 class="text-black">Delete {{ partyType }} Party</h2>
    </div>
    <h3 class="w-full flex justify-center text-black mb-6 px-10">
      {{ party.name }}
    </h3>
    <div class="flex text-black mb-8 text-normal px-10">
      Are you sure you want to delete the above
      {{ partyType.toLowerCase() }} party? You will not be able to retrieve
      their contact information once deleted.
    </div>
    <div class="flex justify-center w-full px-10">
      <div
        @click="hide()"
        class="button-blue-hollow mr-4 px-2 w-2/5 cursor-pointer text-center self-center justify-center flex leading-none"
        data-cypress="no-keep"
      >
        No, keep this {{ partyType }} Party
      </div>
      <div
        @click="confirmParty()"
        class="button-red ml-4 w-2/5 cursor-pointer text-center px-2 self-center justify-center flex leading-none"
        data-cypress="yes-delete"
      >
        Yes, delete this {{ partyType }} Party
      </div>
    </div>
  </div>
</template>
<script>
import errorIcon from "../elements/errorIcon";
import * as partyCruds from "../../helpers/partyCRUD";

export default {
  name: "cancel-party",
  components: { errorIcon },
  props: ["party", "type"],
  computed: {
    partyType() {
      return this.type === "pnr" ? "Interested" : "Relevant";
    }
  },
  methods: {
    hide() {
      this.$emit("close");
    },
    async confirmParty() {
      await partyCruds.deletePartyFromDb(this.party.identifier, this.type);
      this.hide();
    }
  }
};
</script>

<style>
.button-red {
  line-height: 1rem;
}
</style>
