import Auth from "@aws-amplify/auth";

// sign up via cognito Amplify auth
export async function signupToCognito(userRegistrationData) {
  return await Auth.signUp({
    username: userRegistrationData.email.toLowerCase(),
    password: userRegistrationData.passwordconfirm,
    attributes: {
      email: userRegistrationData.email.toLowerCase(),
      given_name: userRegistrationData.first_name,
      family_name: userRegistrationData.last_name,
      phone_number: userRegistrationData.phone_no
    },
    validationData: []
  });
}

export async function resendEmailconfirmation() {
  try {
    return await Auth.verifyCurrentUserAttribute("email");
  } catch (e) {
    throw new Error("Could not send confirmation code");
  }
}

export async function verifyEmailAddress(code) {
  try {
    const result = await Auth.verifyCurrentUserAttributeSubmit("email", code);
    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    let message = "";
    switch (e.code) {
      case "LimitExceededException":
        message =
          "Maximum number of resends reached. Please try again in 10 minutes";
        break;
      default:
        message = "Unable to verify your email. Check your code and try again";
    }
    throw new Error(message);
  }
}

// reset password authentication stage
export function forgotPasswordAuthCognito(userEmailAddress) {
  return Auth.forgotPassword(userEmailAddress.toLowerCase());
}

// reset password submit stage
export function forgotPasswordSubmitToCognito(
  userEmailAddress,
  recoveryCode,
  newPassword
) {
  return Auth.forgotPasswordSubmit(
    userEmailAddress.toLowerCase(),
    recoveryCode,
    newPassword
  );
}

// logout of cognito and destroy session
export function logoutUserCognitoAndDestroySession() {
  return Auth.signOut({ global: true });
}

export async function changePassword(currentPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, currentPassword, newPassword);
}
