var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container justify-center text-black" },
    [
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c("div", { staticClass: "w-full text-black" }, [
            _vm.type == "pnr"
              ? _c(
                  "h1",
                  {
                    staticClass: "mb-4 w-full text-title text-black mt-6",
                    attrs: { "data-cypress": "header-text" },
                  },
                  [_vm._v(" Place Name Rules ")]
                )
              : _c(
                  "h1",
                  {
                    staticClass: "mb-4 w-full text-title text-black mt-6",
                    attrs: { "data-cypress": "header-text" },
                  },
                  [_vm._v(" Road Name Rules ")]
                ),
            _c("div", { staticClass: "flex w-full rules-center mb-5" }, [
              _c(
                "label",
                { staticClass: "w-1/2 flex-0", attrs: { for: "" } },
                [
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v("Add Disallowed Word"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newWord,
                        expression: "newWord",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate.immediate",
                        value: "required",
                        expression: "'required'",
                        modifiers: { immediate: true },
                      },
                    ],
                    staticClass: "w-full mt-2 input-area bg-white",
                    attrs: {
                      type: "text",
                      name: "newWord",
                      id: "disallowed",
                      placeholder: "Enter the disallowed word here...",
                      "data-cypress": "add-field",
                      "data-vv-scope": "newRule",
                      disabled: _vm.$wait.any,
                    },
                    domProps: { value: _vm.newWord },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.newWord = $event.target.value
                      },
                    },
                  }),
                  _c("FieldErrorMessage", {
                    attrs: { message: _vm.duplicateError },
                  }),
                ],
                1
              ),
              _c(
                "label",
                { staticClass: "ml-4 flex-0 w-1/3", attrs: { for: "" } },
                [
                  _c("p", { staticClass: "mb-2 font-bold" }, [
                    _vm._v("Reason"),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newCategory,
                          expression: "newCategory",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate.immediate.continues",
                          value: "required",
                          expression: "'required'",
                          modifiers: { immediate: true, continues: true },
                        },
                      ],
                      staticClass:
                        "input-area-appear w-full bg-white self-center",
                      attrs: {
                        name: "newCategory",
                        "data-cypress": "reason-select",
                        "data-vv-scope": "newRule",
                        disabled: _vm.$wait.any,
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.newCategory = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _vm._m(0),
                      _vm._l(_vm.categories, function (reason, index) {
                        return _c(
                          "option",
                          {
                            key: index,
                            staticClass: "my-4 input-area-appear bg-white",
                            domProps: { value: reason.id || reason.identifier },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "input-area-appear bg-white" },
                              [_vm._v(_vm._s(reason.category))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c("input", {
                staticClass: "w-1/6 my-8 ml-4 button button-green",
                attrs: {
                  type: "button",
                  value: "Add",
                  "data-cypress": "add-button",
                  disabled: _vm.isDisabled("newRule"),
                },
                on: { click: _vm.saveRule },
              }),
            ]),
            _c(
              "div",
              { staticClass: "rounded-lg w-full flex justify-center bg-white" },
              [
                _c(
                  "div",
                  { staticClass: "w-9/10 mx-auto flex justify-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "pt-12 pb-16 w-full" },
                      [
                        _vm._m(1),
                        _c("Loading-spinner", {
                          directives: [
                            {
                              name: "wait",
                              rawName: "v-wait:visible",
                              value: "loading rules",
                              expression: "'loading rules'",
                              arg: "visible",
                            },
                          ],
                          staticClass: "flex-0",
                        }),
                        _vm._l(_vm.rules, function (rule, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "wait",
                                  rawName: "v-wait:hidden",
                                  value: "loading rules",
                                  expression: "'loading rules'",
                                  arg: "hidden",
                                },
                              ],
                              key: rule.id || rule.identifier,
                              staticClass:
                                "w-full flex flex-no-wrap rules-center mb-4 flex-col",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "w-full flex" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: rule.word,
                                        expression: "rule.word",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-1/2 mr-4 input-area",
                                    attrs: {
                                      type: "text",
                                      name: `${rule.identifier}-word`,
                                      "data-cypress": "word-field",
                                      disabled: _vm.$wait.any,
                                      "data-vv-scope": rule.identifier,
                                    },
                                    domProps: { value: rule.word },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          rule,
                                          "word",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: rule.banned_word_category_id,
                                          expression:
                                            "rule.banned_word_category_id",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "w-1/3 input-area-appear",
                                      attrs: {
                                        disabled: _vm.$wait.any,
                                        "data-vv-scope": rule.identifier,
                                        name: `${rule.identifier}-category`,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            rule,
                                            "banned_word_category_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.categories,
                                      function (reason, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: {
                                              value: reason.identifier,
                                            },
                                          },
                                          [_vm._v(_vm._s(reason.category))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass: "button-blue ml-4",
                                      attrs: {
                                        type: "button",
                                        isSpinning: _vm.$wait.is(
                                          `saving rule ${rule.identifier}`
                                        ),
                                        disabled: _vm.isDisabled(
                                          rule.identifier
                                        ),
                                      },
                                      on: { click: () => _vm.updateRule(rule) },
                                    },
                                    [_c("span", [_vm._v("Save")])]
                                  ),
                                  _c("input", {
                                    staticClass:
                                      "ml-4 w-1/7 cursor-pointer button-red",
                                    attrs: {
                                      type: "button",
                                      value: "Delete",
                                      "data-cypress": "delete-button",
                                      disabled: _vm.$wait.any,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRule(rule)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.isChangeDuplicate(rule)
                                    ? `${rule.word} is already in the list`
                                    : "",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "flex mt-8 pb-24" }, [
              _c(
                "a",
                {
                  staticClass: "button-blue ml-auto",
                  attrs: { "data-cypress": "save-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("Return to Dashboard")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("option", { attrs: { value: "", disabled: "" } }, [
      _c("div", { staticClass: "my-4 py-4" }, [_vm._v("Select a rule")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex mb-4" }, [
      _c("p", { staticClass: "w-1/2 font-bold" }, [_vm._v("Disallowed Word")]),
      _c("p", { staticClass: "w-1/3 font-bold ml-4" }, [_vm._v("Reason")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }