var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ShowLoading", {
    attrs: { isLoading: _vm.RNA_WAITER },
    scopedSlots: _vm._u([
      {
        key: "component",
        fn: function () {
          return [
            _c("div", [
              _vm.rna
                ? _c(
                    "div",
                    { staticClass: "base-container text-black text-normal" },
                    [
                      _c(
                        "section",
                        { staticClass: "pb-5 justify-center w-full block" },
                        [
                          _c("div", { staticClass: "flex justify-between" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "flex justify-between mr-4 self-center",
                                attrs: { "data-cypress": "goback-arrow" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.go(-1)
                                  },
                                },
                              },
                              [
                                _c("BackArrowWithTitle", {
                                  attrs: {
                                    title: `View RNA - ${_vm.rna.authority_name}`,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-white w-full rounded-lg flex h-26 items-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "sm:hidden xl:flex w-12 h-12 rounded-full ml-6 bg-grey-lighter justify-center items-center flex-none",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-none" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "text-2xl",
                                    attrs: { icon: "road" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-1/3 flex flex-wrap content-between ml-2 pl-4 py-8",
                            },
                            [
                              _c("h3", { staticClass: "w-full" }, [
                                _vm._v(_vm._s(_vm.rna.authority_name)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "h-full dividing-line-v" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-2/3 flex ml-6 h-full content-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1/2 flex flex-wrap content-between py-6",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-black pr-4 flex-1 flex align-center cursor-default",
                                      attrs: {
                                        "data-cypress":
                                          "organisation-email-link",
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "pr-2 text-2xl flex-none",
                                        attrs: { icon: "envelope" },
                                      }),
                                      _vm._v(" Address "),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "w-full" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.rna.address_line_1) +
                                        " " +
                                        _vm._s(_vm.rna.address_line_2)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.rna.rna_suburb) +
                                        " " +
                                        _vm._s(_vm.rna.rna_state) +
                                        " " +
                                        _vm._s(_vm.rna.rna_postcode) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-1/2 flex flex-wrap content-between py-6",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-black pr-4 flex-1 flex align-center cursor-default",
                                      attrs: {
                                        "data-cypress":
                                          "organisation-email-link",
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "pr-2 text-2xl flex-none",
                                        attrs: { icon: "user" },
                                      }),
                                      _vm._v(" Responsible Person "),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "w-full" }, [
                                    _vm._v(" " + _vm._s(_vm.rna.manager_name)),
                                    _c("br"),
                                    _vm._v(
                                      "Role: " +
                                        _vm._s(_vm.rna.manager_role) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-16 h-full flex-auto flex flex-col content-around items-center border-l-2",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "flex-auto flex justify-center items-center font-bold actions w-full h-1/2 action-button rounded-tr-lg",
                                  attrs: {
                                    "data-cypress": "edit-rna-link",
                                    to: {
                                      name: "rnasEdit",
                                      params: {
                                        rna: _vm.rna,
                                        id: _vm.rna.identifier,
                                      },
                                    },
                                    title: `Edit ${_vm.rna.authority_name} details`,
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "flex-auto",
                                    attrs: { icon: "edit" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "flex-auto flex justify-center items-center text-red font-bold h-1/2 w-full action-button suspend-user rounded-br-lg",
                                  attrs: {
                                    title: `Suspend ${_vm.rna.authority_name}`,
                                    "data-cypress": "suspend-button",
                                    disabled: "disabled",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "flex-auto",
                                    attrs: { icon: "lock" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-auto flex items-center mt-8" },
                        [_c("h2", [_vm._v(_vm._s(_vm.usersTitle))])]
                      ),
                      _c(
                        "ShowLoading",
                        {
                          staticClass: "flex-0",
                          attrs: { isLoading: _vm.USERS_WAITER },
                        },
                        [
                          _c(
                            "template",
                            { slot: "component" },
                            [
                              !!_vm.users
                                ? _c("UserList", {
                                    attrs: { users: _vm.users },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ user }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-12 h-full flex-auto flex flex-col content-around items-center",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button",
                                                      attrs: {
                                                        "data-cypress":
                                                          "view-user-link",
                                                        to: {
                                                          name: "profile/view",
                                                          params: {
                                                            id: user.email,
                                                          },
                                                        },
                                                        title: `View ${user.first_name} ${user.last_name}'s profile`,
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "flex-auto",
                                                        attrs: { icon: "eye" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button",
                                                      attrs: {
                                                        to: {
                                                          name: "profile/edit",
                                                          params: {
                                                            id: user.email,
                                                          },
                                                        },
                                                        "data-cypress":
                                                          "edit-user-link",
                                                        title: `Edit ${user.first_name} ${user.last_name}'s profile`,
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "flex-auto",
                                                        attrs: {
                                                          icon: "user-edit",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  user.user_account_status ===
                                                  "approved"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user",
                                                          attrs: {
                                                            title: `Suspend ${user.first_name} ${user.last_name}`,
                                                            "data-cypress":
                                                              "suspend-button",
                                                            disabled:
                                                              user.user_account_status !==
                                                              "approved",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.suspendUser(
                                                                user
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "flex-auto",
                                                              attrs: {
                                                                icon: "lock",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  user.user_account_status !==
                                                  "approved"
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "flex-auto flex justify-center items-center text-green font-bold h-1/3 w-full action-button activate-user",
                                                          attrs: {
                                                            title: `Approve ${user.first_name} ${user.last_name}`,
                                                            "data-cypress":
                                                              "suspend-button",
                                                            disabled:
                                                              user.user_account_status ===
                                                              "pending",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.approveUser(
                                                                user
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "flex-auto",
                                                              attrs: {
                                                                icon: "check",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2425875844
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("div", {
                        staticClass: "h-24 flex-auto",
                        attrs: { name: "purelyABuffer" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }