var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", { staticClass: "w-2/3" }, [
      _vm._m(0),
      _c("p", [
        _vm._v(
          " Please describe the origin of the feature's proposed name in the context of what the name signifies, what culture or background the name derives its meaning or to whom the name commemorates. "
        ),
      ]),
      _c(
        "div",
        { staticClass: "w-full mt-3 flex" },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.origin,
                expression: "origin",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full h-48 input-area",
            attrs: {
              "data-cypress": "origin-field",
              name: "origin_of_name",
              "data-vv-as": "Origin of Proposed Name",
              disabled: _vm.readOnly,
            },
            domProps: { value: _vm.origin },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.origin = $event.target.value
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("origin_of_name") },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c("uploadScoped", {
          staticClass: "mt-8",
          attrs: {
            "data-cypress": "upload-origin",
            label: "Attach supporting material for origin",
            important: false,
            files: _vm.origin_reference_document,
            drafted: true,
            stateKey: "place_name",
            stateProp: "origin_reference_document",
            uploadFile: _vm.uploadFile,
            disabled: _vm.readOnly,
          },
          on: {
            deleteFile: (fileIndex) =>
              _vm.deleteUploadedFile({
                key: "place_name",
                prop: "origin_reference_document",
                index: fileIndex,
              }),
          },
        }),
      ],
      1
    ),
    _c("label", { staticClass: "w-2/3" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "w-full mt-3 flex" },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reason_for_name_choice,
                expression: "reason_for_name_choice",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full h-48 input-area",
            attrs: {
              "data-cypress": "reason_for_name_choice-field",
              name: "reason_for_name",
              "data-vv-as": "Reason for the choice of Proposed Name",
              disabled: _vm.readOnly,
            },
            domProps: { value: _vm.reason_for_name_choice },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reason_for_name_choice = $event.target.value
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("reason_for_name") },
          }),
        ],
        1
      ),
    ]),
    _c("label", { staticClass: "w-2/3" }, [
      _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
        _vm._v(" Meaning of word used in proposed name "),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.meaning,
            expression: "meaning",
          },
        ],
        staticClass: "w-full mr-4 input-area",
        attrs: {
          type: "text",
          "data-cypress": "meaning-field",
          placeholder: "Enter Meaning of Word Here...",
          name: "meaning",
          id: "meaning",
          disabled: _vm.readOnly,
        },
        domProps: { value: _vm.meaning },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.meaning = $event.target.value
          },
        },
      }),
    ]),
    _c("label", { staticClass: "w-2/3" }, [
      _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
        _vm._v(" Provide the phonetic spelling of the proposed name "),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pronunciation,
            expression: "pronunciation",
          },
        ],
        staticClass: "w-full mr-4 input-area",
        attrs: {
          type: "text",
          "data-cypress": "pronunciation-field",
          placeholder: "Enter Phonetic Spelling Here...",
          name: "Phonetic Spelling",
          id: "phoneticSpelling",
          disabled: _vm.readOnly,
        },
        domProps: { value: _vm.pronunciation },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.pronunciation = $event.target.value
          },
        },
      }),
    ]),
    _c(
      "div",
      [
        _c("uploadScoped", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "min_value:1",
              expression: "'min_value:1'",
            },
          ],
          staticClass: "mt-8",
          attrs: {
            "data-cypress": "upload-sound",
            label: "Attach pronunciation audio/sound files",
            important: false,
            files: _vm.pronunciation_reference_document,
            drafted: true,
            stateKey: "place_name",
            stateProp: "pronunciation_reference_document",
            uploadFile: _vm.uploadFile,
            disabled: _vm.readOnly,
          },
          on: {
            deleteFile: (fileIndex) =>
              _vm.deleteUploadedFile({
                key: "place_name",
                prop: "pronunciation_reference_document",
                index: fileIndex,
              }),
          },
        }),
      ],
      1
    ),
    _vm._m(2),
    _c(
      "div",
      { staticClass: "flex flex-row text-normal" },
      [
        _c(
          "RadioButton",
          {
            staticClass: "flex-0 mr-6 mt-3",
            attrs: {
              "data-cypress": "commemorated-no",
              name: "commemorated-no",
              checkedValue: false,
              disabled: _vm.readOnly,
            },
            model: {
              value: _vm.commemorated,
              callback: function ($$v) {
                _vm.commemorated = $$v
              },
              expression: "commemorated",
            },
          },
          [_vm._v("No")]
        ),
        _c(
          "RadioButton",
          {
            staticClass: "flex-0 mr-6 mt-3",
            attrs: {
              name: "commemorated-yes",
              "data-cypress": "commemorated-yes",
              checkedValue: true,
              disabled: _vm.readOnly,
            },
            model: {
              value: _vm.commemorated,
              callback: function ($$v) {
                _vm.commemorated = $$v
              },
              expression: "commemorated",
            },
          },
          [_vm._v("Yes")]
        ),
      ],
      1
    ),
    _vm.commemorated
      ? _c(
          "label",
          { staticClass: "w-2/3" },
          [
            _vm._m(3),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.commemorated_full_name,
                  expression: "commemorated_full_name",
                },
              ],
              staticClass: "w-full mr-4 input-area",
              attrs: {
                type: "text",
                "data-cypress": "commemorated_full_name-field",
                placeholder: "Enter Name Here...",
                name: "commemorated_full_name",
                id: "commemorated_full_name",
                "data-vv-as": "Full Name of Person",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.commemorated_full_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.commemorated_full_name = $event.target.value
                },
              },
            }),
            _c("FieldErrorMessage", {
              attrs: { message: _vm.errors.first("commemorated_full_name") },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.commemorated
      ? _c(
          "div",
          { staticClass: "text-normal mb-4 mt-4" },
          [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "flex flex-row mt-2" },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        mode: "single",
                        "max-date":
                          _vm.commemorated_death_date || _vm.todaysDate,
                      },
                      on: {
                        dayclick: function ($event) {
                          return _vm.validateDate("commemorated_birth_date")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ inputProps, updateValue }) {
                              return _c(
                                "input",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|date",
                                        expression: "'required|date'",
                                      },
                                    ],
                                    staticClass: "w-full mr-4 input-area",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Enter Date (DD/MM/YYYY)",
                                      "data-cypress": "commemorated_birth_date",
                                      autocomplete: "off",
                                      disabled: _vm.readOnly,
                                      name: "commemorated_birth_date",
                                      "data-vv-as": "Date of Birth",
                                    },
                                    domProps: {
                                      value: _vm.commemorated_birth_date,
                                    },
                                    on: {
                                      input: (e) =>
                                        _vm.updateDate(e, updateValue),
                                      change: _vm.setDobNullIfBlank,
                                    },
                                  },
                                  "input",
                                  inputProps,
                                  false
                                )
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2992721607
                      ),
                      model: {
                        value: _vm.commemorated_birth_date,
                        callback: function ($$v) {
                          _vm.commemorated_birth_date = $$v
                        },
                        expression: "commemorated_birth_date",
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "ml-2 w-1/8",
                        class: {
                          redButtonFaded: !_vm.commemorated_birth_date,
                          redButtonSolid: _vm.commemorated_birth_date,
                        },
                        attrs: { disabled: !_vm.commemorated_birth_date },
                        on: {
                          click: function ($event) {
                            _vm.commemorated_birth_date = null
                          },
                        },
                      },
                      [_vm._v(" clear ")]
                    ),
                  ],
                  1
                ),
                _c("FieldErrorMessage", {
                  attrs: {
                    message: _vm.errors.first("commemorated_birth_date"),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "flex flex-row mt-2" },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        mode: "single",
                        "max-date": _vm.todaysDate,
                        "min-date": _vm.commemorated_birth_date,
                      },
                      on: {
                        dayclick: function ($event) {
                          return _vm.validateDate("commemorated_death_date")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ inputProps, updateValue }) {
                              return _c(
                                "input",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|date",
                                        expression: "'required|date'",
                                      },
                                    ],
                                    staticClass: "w-full mr-4 input-area",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Enter Date of Death...",
                                      "data-cypress": "commemorated_death_date",
                                      autocomplete: "off",
                                      name: "commemorated_death_date",
                                      "data-vv-as": "Date of Death",
                                      disabled: _vm.readOnly,
                                    },
                                    domProps: {
                                      value: _vm.commemorated_death_date,
                                    },
                                    on: {
                                      input: (e) =>
                                        _vm.updateDate(e, updateValue),
                                      change: _vm.setDodNullIfBlank,
                                    },
                                  },
                                  "input",
                                  inputProps,
                                  false
                                )
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        792331771
                      ),
                      model: {
                        value: _vm.commemorated_death_date,
                        callback: function ($$v) {
                          _vm.commemorated_death_date = $$v
                        },
                        expression: "commemorated_death_date",
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "ml-2 w-1/8",
                        class: {
                          redButtonFaded: !_vm.commemorated_death_date,
                          redButtonSolid: _vm.commemorated_death_date,
                        },
                        attrs: { disabled: !_vm.commemorated_death_date },
                        on: {
                          click: function ($event) {
                            _vm.commemorated_death_date = null
                          },
                        },
                      },
                      [_vm._v(" clear ")]
                    ),
                  ],
                  1
                ),
                _c("FieldErrorMessage", {
                  attrs: {
                    message: _vm.errors.first("commemorated_death_date"),
                  },
                }),
              ],
              1
            ),
            _c("Checkbox", {
              staticClass: "pt-8 pl-0",
              attrs: {
                "data-cypress": "commemorated_dates_unknown-field",
                label: "The Date of Birth or Death is unknown",
                disabled:
                  _vm.readOnly ||
                  (_vm.commemorated_birth_date && _vm.commemorated_death_date),
              },
              model: {
                value: _vm.commemorated_dates_unknown,
                callback: function ($$v) {
                  _vm.commemorated_dates_unknown = $$v
                },
                expression: "commemorated_dates_unknown",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.commemorated_dates_unknown
      ? _c("label", { staticClass: "w-2/3 mb-4" }, [
          _c("p", { staticClass: "text-normal-bold mb-2" }, [
            _vm._v(
              " Please explain why the Date of Birth or Death is unknown "
            ),
          ]),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.commemorated_dates_unknown_description,
                  expression: "commemorated_dates_unknown_description",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "commemorated_dates_unknown_description-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.commemorated_dates_unknown_description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.commemorated_dates_unknown_description =
                    $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm.commemorated
      ? _c("label", { staticClass: "w-2/3" }, [
          _vm._m(6),
          _c(
            "div",
            { staticClass: "w-full mt-3 flex" },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.commemorated_association_description,
                    expression: "commemorated_association_description",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full h-48 input-area",
                attrs: {
                  "data-cypress": "ommemorated_association_description-field",
                  name: "commemorated_association_description",
                  "data-vv-as": "Association",
                  disabled: _vm.readOnly,
                },
                domProps: { value: _vm.commemorated_association_description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.commemorated_association_description =
                      $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first(
                    "commemorated_association_description"
                  ),
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.commemorated
      ? _c("label", { staticClass: "w-2/3" }, [
          _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
            _vm._v(" What is the source for this information? "),
          ]),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.commemorated_source,
                  expression: "commemorated_source",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "commemorated_source-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.commemorated_source },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.commemorated_source = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm.commemorated
      ? _c(
          "div",
          [
            _c("uploadScoped", {
              staticClass: "mt-8",
              attrs: {
                label: "Attach supporting documentation",
                important: false,
                files: _vm.source_reference_document,
                drafted: true,
                stateKey: "place_name",
                stateProp: "source_reference_document",
                uploadFile: _vm.uploadFile,
                disabled: _vm.readOnly,
              },
              on: {
                deleteFile: (fileIndex) =>
                  _vm.deleteUploadedFile({
                    key: "place_name",
                    prop: "source_reference_document",
                    index: fileIndex,
                  }),
              },
            }),
            _vm.errors.first("fileUploader")
              ? _c(
                  "div",
                  { staticClass: "flex items-center absolute" },
                  [
                    _c("errorIcon"),
                    _c(
                      "div",
                      { staticClass: "ml-2 text-red-error text-normal-bold" },
                      [
                        _vm._v(
                          " No supported documentation was uploaded. Please upload required files "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _c("div", [
      _c("p", { staticClass: "text-normal-bold mt-8" }, [
        _vm._v(" Is the proposed name an Aboriginal name? "),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal" },
        [
          _c(
            "RadioButton",
            {
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                "data-cypress": "aboriginal_name-no",
                name: "aboriginal_name-no",
                checkedValue: false,
                disabled: !!_vm.multicultural || _vm.readOnly,
              },
              model: {
                value: _vm.aboriginal_name,
                callback: function ($$v) {
                  _vm.aboriginal_name = $$v
                },
                expression: "aboriginal_name",
              },
            },
            [_vm._v("No")]
          ),
          _c(
            "RadioButton",
            {
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                name: "aboriginal_name-yes",
                "data-cypress": "aboriginal_name-yes",
                checkedValue: true,
                disabled: !!_vm.multicultural || _vm.readOnly,
              },
              model: {
                value: _vm.aboriginal_name,
                callback: function ($$v) {
                  _vm.aboriginal_name = $$v
                },
                expression: "aboriginal_name",
              },
            },
            [_vm._v("Yes")]
          ),
        ],
        1
      ),
    ]),
    _vm.aboriginal_name
      ? _c(
          "div",
          [
            _c("p", { staticClass: "text-normal-bold mt-8" }, [
              _vm._v(
                " Has the name been selected in consultation with the Local Aboriginal Land Council and the local Aboriginal community? "
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row text-normal" },
              [
                _c(
                  "RadioButton",
                  {
                    staticClass: "flex-0 mr-6 mt-3 mb-1",
                    attrs: {
                      "data-cypress": "aboriginal_consulted-no",
                      name: "aboriginal_consulted-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.aboriginal_consulted,
                      callback: function ($$v) {
                        _vm.aboriginal_consulted = $$v
                      },
                      expression: "aboriginal_consulted",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    staticClass: "flex-0 mr-6 mt-3 mb-1",
                    attrs: {
                      name: "aboriginal_consulted-yes",
                      "data-cypress": "aboriginal_consulted-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.aboriginal_consulted,
                      callback: function ($$v) {
                        _vm.aboriginal_consulted = $$v
                      },
                      expression: "aboriginal_consulted",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
            _vm.aboriginal_consulted === false
              ? _c("MessagePanel", {
                  staticClass: "mt-4",
                  attrs: {
                    warning: "",
                    msg: "This response suggests that this proposal is likely to be deemed unacceptable",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.aboriginal_consulted && _vm.aboriginal_name
      ? _c("label", { staticClass: "w-2/3" }, [
          _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
            _vm._v(
              " List the community groups consulted and provide evidence of support "
            ),
          ]),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.aboriginal_consulted_communities,
                  expression: "aboriginal_consulted_communities",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "aboriginal_consulted_communities-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.aboriginal_consulted_communities },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.aboriginal_consulted_communities = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm.aboriginal_consulted && _vm.aboriginal_name
      ? _c(
          "div",
          [
            _c("uploadScoped", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "min_value:1",
                  expression: "'min_value:1'",
                },
              ],
              staticClass: "mt-8",
              attrs: {
                label: "Attach supporting documentation",
                important: true,
                files: _vm.aboriginal_reference_document,
                drafted: true,
                stateKey: "place_name",
                stateProp: "aboriginal_reference_document",
                uploadFile: _vm.uploadFile,
                disabled: _vm.readOnly,
                "data-vv-as": "Supporting Documentation",
                name: "aboriginal_reference_document",
              },
              on: {
                deleteFile: (fileIndex) =>
                  _vm.deleteUploadedFile({
                    key: "place_name",
                    prop: "aboriginal_reference_document",
                    index: fileIndex,
                  }),
              },
            }),
            _c("FieldErrorMessage", {
              attrs: {
                message: _vm.errors.first("aboriginal_reference_document"),
              },
            }),
            _vm.errors.first("fileUploader")
              ? _c(
                  "div",
                  { staticClass: "flex items-center absolute" },
                  [
                    _c("errorIcon"),
                    _c(
                      "div",
                      { staticClass: "ml-2 text-red-error text-normal-bold" },
                      [
                        _vm._v(
                          " No supporting documentation was uploaded. Please upload required files "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.aboriginal_name
      ? _c("div", { staticClass: "flex flex-row text-normal mb-4 mt-8" }, [
          _c("label", { staticClass: "pr-4 w-2/3" }, [
            _c("p", { staticClass: "text-normal-bold mb-2" }, [
              _vm._v(" Country/Aboriginal nation "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.aboriginal_country,
                  expression: "aboriginal_country",
                },
              ],
              staticClass: "w-full mr-4 input-area",
              attrs: {
                type: "text",
                "data-cypress": "aboriginal_country-field",
                placeholder: "Enter Country Here...",
                name: "aboriginal_country",
                id: "aboriginal_country",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.aboriginal_country },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.aboriginal_country = $event.target.value
                },
              },
            }),
          ]),
          _c("label", { staticClass: "w-2/3" }, [
            _vm._m(7),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.aboriginal_language,
                  expression: "aboriginal_language",
                },
              ],
              staticClass: "w-full mr-4 input-area",
              attrs: {
                type: "text",
                "data-cypress": "aboriginal_language-field",
                placeholder: "Enter Language Group Here...",
                name: "aboriginal_language",
                id: "aboriginal_language",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.aboriginal_language },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.aboriginal_language = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm.aboriginal_name
      ? _c("label", { staticClass: "w-2/3" }, [
          _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
            _vm._v(" How would you describe the place type? "),
          ]),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.aboriginal_place_type,
                  expression: "aboriginal_place_type",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "aboriginal_place_type-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.aboriginal_place_type },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.aboriginal_place_type = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _c("p", { staticClass: "text-normal-bold mt-8" }, [
      _vm._v(" Does the proposed name have a multicultural dimension? "),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-row text-normal" },
      [
        _c(
          "RadioButton",
          {
            staticClass: "flex-0 mr-6 mt-3 mb-1",
            attrs: {
              "data-cypress": "multicultural-no",
              name: "multicultural-no",
              checkedValue: false,
              disabled: !!_vm.aboriginal_name || _vm.readOnly,
            },
            model: {
              value: _vm.multicultural,
              callback: function ($$v) {
                _vm.multicultural = $$v
              },
              expression: "multicultural",
            },
          },
          [_vm._v("No")]
        ),
        _c(
          "RadioButton",
          {
            staticClass: "flex-0 mr-6 mt-3 mb-1",
            attrs: {
              name: "multicultural-yes",
              "data-cypress": "multicultural-yes",
              checkedValue: true,
              disabled: !!_vm.aboriginal_name || _vm.readOnly,
            },
            model: {
              value: _vm.multicultural,
              callback: function ($$v) {
                _vm.multicultural = $$v
              },
              expression: "multicultural",
            },
          },
          [_vm._v("Yes")]
        ),
      ],
      1
    ),
    _vm.multicultural
      ? _c("label", { staticClass: "w-2/3" }, [
          _vm._m(8),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.multicultural_description,
                  expression: "multicultural_description",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "multicultural_description-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.multicultural_description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.multicultural_description = $event.target.value
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Origin of Proposed Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-8 mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Reason for the choice of the proposed name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Does this proposed name commemorate a person? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Full Name of Person "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold pb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Birth "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Death "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-4" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(
        "What is the person's association with this feature and their main contribution to the local community? "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Language Group "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Please describe the multicultural dimension "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }