<template>
  <div>
    <entry :eventDate="entryData.date" cardTitle="Advertising Dates">
      <div v-if="advertisingStartDate" class="mt-2">
        <p class="mb-2">The Advertising Start Date was set to:</p>
        <strong>{{ advertisingStartDate }}</strong>
      </div>
      <div v-if="advertisingEndDate" class="mt-2">
        <p class="mb-2">The Advertising End Date was set to:</p>
        <strong>{{ advertisingEndDate }}</strong>
      </div>
    </entry>
  </div>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";
import moment from "moment";

export default {
  name: "AdvertisementTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Entry
  },
  computed: {
    advertisingStartDate() {
      if (this.entryData.changes) {
        const adStartField = this.entryData.changes["advertising start date"];
        return adStartField
          ? moment(adStartField.to).format("DD MMM YYYY")
          : null;
      }
      return null;
    },
    advertisingEndDate() {
      if (this.entryData.changes) {
        const adEndField = this.entryData.changes["advertising end date"];
        return adEndField ? moment(adEndField.to).format("DD MMM YYYY") : null;
      }
      return null;
    }
  }
};
</script>
