<template>
  <div class="w-4/5 mx-auto font-times">
    <div class="border-t-2 border-b-2 border-solid border-black text-center">
      <div class="w-full flex justify-center">
        <div class="w-full">
          <p class="pt-5 text-title">{{ rna.authority_name }}</p>
          <p class="pt-3 text-normal-large">ROADS ACT 1993</p>
          <p class="pt-3 text-normal">Naming of Roads</p>
          <p class="pt-3 text-normal text-left justfiy-left">
            Notice is hereby given that {{ rna.authority_name }}, section 162 of
            the Roads Act 1993, has officially named the road(s) as shown
            hereunder:
          </p>
        </div>
      </div>
      <div
        class="w-full flex justify-center text-left mt-4 mb-4"
        v-for="road of roads"
        :key="road.identifier"
      >
        <table class="w-full">
          <tr class="tableRow text-normal-bold">
            <th class="tableRow p-2 w-1/2">Name</th>
            <th class="tableRow p-2 w-1/2">Locality</th>
          </tr>
          <tr class="tableRow text-normal">
            <td class="tableRow p-2">
              {{ road.roadname }} {{ road.road_type }}
            </td>
            <td class="tableRow p-2">
              {{ road.suburb_name && road.suburb_name.join(", ") }}
            </td>
          </tr>
          <tr class="tableRow text-normal-bold">
            <th colspan="2" class="tableRow p-2">Description</th>
          </tr>
          <tr class="tableRow text-normal">
            <td colspan="2" class="tableRow p-2">
              {{ road.extent }}
            </td>
          </tr>
        </table>
      </div>
      <!-- TODO need to do this on behalf of RNA user as GNB member? -->
      <div v-if="user.authority_name" class="w-full flex justify-left mb-4">
        <p class="w-4/5 text-left text-normal">
          {{ rna.manager_name }} <br />
          {{ rna.manager_role }} <br />
          {{ rna.authority_name }} <br />
          {{ rna.address_line_1
          }}<span v-if="rna.address_line_2"
            ><br />
            {{ rna.address_line_2 }}</span
          >
          <br />
          {{ rna.rna_suburb }} {{ rna.rna_state }}
          {{ rna.rna_postcode }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["roads", "user", "rna"]
};
</script>

<style scoped>
.tableRow {
  border: black 1px solid;
}
</style>
