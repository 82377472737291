<template>
  <div>
    <div
      class="justify-center w-full block self-center bg-white text-black rounded-lg px-16 py-16"
    >
      <!--h3 class="p-4">GNB Public Screens</h3-->
      <div class="flex flex-col">
        <button
          class="p-3 button-blue w-full mb-4"
          @click="goToRoute('geoNameSearchPublic')"
        >
          Geoname Search
        </button>
        <button
          class="p-3 button-blue w-full mb-4 "
          @click="goToRoute('currentPlaceNameProposals')"
        >
          Comment on Place Name Proposal
        </button>
        <button
          class="p-3 button-blue w-full mb-4"
          @click="goToRoute('placeNameProposalPublic')"
        >
          Create a Place Name Proposal
        </button>
        <button
          class="p-3 button-blue w-full mb-4"
          @click="goToRoute('suburbSearch')"
        >
          Suburb Search
        </button>
        <button
          class="p-3 button-blue w-full mb-4"
          @click="goToRoute('roadNameEvaluationPublic')"
        >
          Road Name Evaluation
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicLanding",
  methods: {
    goToRoute(routeName) {
      this.$router.push({ name: routeName, params: { proposalId: "create" } });
    }
  }
};
</script>
