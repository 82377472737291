<template>
  <div class="w-full flex bg-white mb-8 mx-64 px-16 py-16 mt-32">
    <showLoading isLoading="fetchingGeoname">
      <template v-slot:component>
        <div v-if="geoname">
          <h3>
            <b>Add Place Name Information</b>
          </h3>
          <hr class="divider w-full mb-8" />
          <p>
            Please enter history, origin, meaning or pronunciation information
            you may have for this geographical name. You can also upload any
            documents, including maps and audio files, that may be useful. Once
            submitted, the Geographical Names Board Secretariat will review the
            information supplied and may contact you to discuss further.
          </p>
          <div class="w-full border flex flex-row border-gray-200 rounded items-center mt-8">
            <span class="text-black font-bold text-2xl mx-8">{{ geoname.geographical_name }}</span>
            <div class="dividing-line-v text-black flex-grow flex flex-row">
              <div class="flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4">
                <span>LGA</span>
                <p v-for="lga in geoname.lga" :key="lga">{{ lga }}</p>
              </div>
              <div class="flex-grow pb-4 pt-4 w-1/4 pr-4">
                <span>Designation</span>
                <p>{{ geoname.designation }}</p>
              </div>
              <div class="flex-grow pb-4 pt-4 w-1/4">
                <span>Gazettal Date</span>
                <p
                  v-for="gazettal_date in geoname.gazettal_dates"
                  :key="gazettal_date"
                >{{ formatDate(gazettal_date.date_gazetted) }}</p>
              </div>
            </div>
          </div>
          <label class="w-2/3">
            <p class="text-normal-bold mb-2 mt-8">
              <span class="text-red-star">*</span>Additional Information
            </p>
            <div class="w-full mt-3 flex">
              <textarea
                class="w-full h-48 input-area"
                data-cypress="additionalInformation-field"
                v-model="additional_information"
                data-vv-as="Additional Information"
                name="additional_information"
              ></textarea>
            </div>
            <FieldErrorMessage :message="errors.first('additional_information')" />
          </label>
          <div class="w-full justify-center flex-col mb-4">
            <uploadScoped
              class="mt-4"
              data-cypress="historical_reference_document"
              label="Upload Files"
              :important="false"
              :files="historical_reference_document"
              :drafted="true"
              stateKey="historical_reference_document"
              stateProp="historical_reference_document"
              :uploadFile="uploadFileForComponent"
              v-on:deleteFile="
                fileIndex => {
                  historical_reference_document.splice(fileIndex, 1);
                }
              "
            />
          </div>
          <h3 class="pb-3 mb-4 mt-8 w-full text-title dividing-line-h">Contact Details</h3>
          <div class="w-full flex">
            <div class="w-1/2">
              <p class="text-normal-bold mt-4 mb-2">
                <a class="text-red-star">*</a>First Name
              </p>
              <input
                type="text"
                class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter First Name Here..."
                v-model="first_name"
                name="first_name"
                v-validate="'required'"
                data-vv-as="First Name"
              />
              <FieldErrorMessage :message="errors.first('first_name')" />
            </div>
            <div class="w-1/2 ml-8">
              <p class="text-normal-bold mt-4 mb-2">
                <a class="text-red-star">*</a>Last Name
              </p>
              <input
                type="text"
                class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter Last Name Here..."
                v-model="last_name"
                name="last_name"
                v-validate="'required'"
                data-vv-as="Last Name"
              />
              <FieldErrorMessage :message="errors.first('last_name')" />
            </div>
          </div>
          <div class="w-full">
            <p class="text-normal-bold mt-4 mb-2">Address Line 1</p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Address Here..."
              v-model="address_line_1"
              name="address_line_1"
            />
          </div>
          <div class="w-full">
            <p class="text-normal-bold mt-4 mb-2">Address Line 2</p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="e.g. Apartment number, floor number, etc"
              v-model="address_line_2"
              name="address_line_2"
            />
          </div>

          <div class="w-full flex items-center">
            <div class="w-1/2 mr-8">
              <p class="text-normal-bold mt-4 mb-2">Suburb</p>
              <input
                type="text"
                class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter Suburb Here..."
                v-model="suburb"
                name="suburb"
              />
            </div>
            <div class="w-1/2 flex items-center">
              <div class="w-1/2 mr-8 mt-8 mb-2">
                <p class="text-normal-bold">State</p>
                <norns-select
                  :selected="state"
                  class="w-full rounded-lg text-normal text-grey-text bg-grey-input self-center h-12"
                  :options="[
                    'NSW',
                    'QLD',
                    'VIC',
                    'SA',
                    'WA',
                    'NT',
                    'ACT',
                    'TAS'
                  ]"
                ></norns-select>
              </div>
              <div class="w-1/2">
                <p class="text-normal-bold mt-4 mb-2">Postcode</p>
                <input
                  type="text"
                  class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                  placeholder="Enter Postcode Here..."
                  v-model="postcode"
                  name="postcode"
                />
              </div>
            </div>
          </div>
          <div class="w-full">
            <p class="text-normal-bold mt-4 mb-2">Country</p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Country Here..."
              v-model="country"
              name="country"
            />
          </div>
          <div class="w-full flex items-center">
            <div class="w-1/2 mr-8">
              <p class="text-normal-bold mt-4 mb-2">Phone Number</p>
              <input
                type="text"
                class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter Phone Number Here..."
                v-model="phone_no"
                name="phone_no"
              />
            </div>
            <div class="w-1/2">
              <p class="text-normal-bold mt-4 mb-2">
                <a class="text-red-star">*</a>Email Address
              </p>
              <input
                type="text"
                class="w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter Email Address Here..."
                v-model="email"
                name="email"
                v-validate="'required|email'"
                data-vv-as="Email Address"
              />
              <FieldErrorMessage :message="errors.first('email')" />
            </div>
          </div>
          <hr class="divider w-full mb-8 mt-4" />
          <button class="button-red-hollow" @click="backToInfo()">Cancel</button>
          <buttonWithSpinner
            class="button-blue float-right"
            @click="updateGeoname()"
            :disabled="!isFormValid || $wait.is('updatingGeoname')"
            :isSpinning="$wait.is('updatingGeoname')"
          >Submit Additional Information</buttonWithSpinner>
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import pnpCRUD from "../../helpers/pnpCRUD.js";
import { waitFor } from "vue-wait";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

export default {
  name: "addGeonameInfo",
  data() {
    return {
      geoname: null,
      additional_information: null,
      historical_reference_document: [],
      first_name: null,
      last_name: null,
      address_line_1: null,
      address_line_2: null,
      suburb: null,
      postcode: null,
      state: null,
      country: null,
      phone_no: null,
      email: null
    };
  },
  async created() {
    await this.fetchGeoname();
  },
  computed: {
    geonameId() {
      return this.$route.params.geonameId;
    },
    isFormValid() {
      return (
        !!this.additional_information &&
        !!this.first_name &&
        !!this.last_name &&
        !!this.email
      );
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    async uploadFileForComponent(payload) {
      const s3FileData = await this.uploadFile(
        Object.assign({}, payload, {
          componentOnly: true
        })
      );
      if (s3FileData) {
        this.historical_reference_document.push(s3FileData);
      }
    },
    backToInfo() {
      this.$router.push({
        name: "geonameViewPublic",
        params: {
          geonameId: this.geonameId
        }
      });
    },
    formatDate: date => {
      return moment(date).format("DD MMM, YYYY");
    },
    fetchGeoname: waitFor("fetchingGeoname", async function() {
      try {
        this.geoname = await pnpCRUD.getGeonameByIdPublic(this.geonameId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Issue Retrieving Record",
          text: "There was an issue retrieving the place name name record"
        });
      }
    }),
    updateGeoname: waitFor("updatingGeoname", async function() {
      try {
        const valid = await this.$validator.validateAll();
        if (valid) {
          const params = {
            payload: {
              additional_information: this.additional_information,
              historical_reference_document: this.historical_reference_document,
              first_name: this.first_name,
              last_name: this.last_name,
              address_line_1: this.address_line_1,
              address_line_2: this.address_line_2,
              suburb: this.suburb,
              postcode: this.postcode,
              state: this.state,
              country: this.country,
              phone_no: this.phone_no,
              email: this.email
            },
            id: this.geonameId
          };
          await pnpCRUD.saveGeonameHistoricalData(params);
          this.$notify({
            group: "toast",
            type: "success",
            title: "Place Name Information Saved",
            text:
              "Your information has been saved successfully for this place name"
          });
          this.$router.push({
            name: "geonameViewPublic",
            params: {
              geonameId: this.geonameId
            }
          });
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Issue Saving Record",
          text: "There was an issue saving the place name name record"
        });
      }
    })
  }
};
</script>

<style>
.divider {
  height: 1px;
  width: 100%;
  background-color: #cbcbcb;
}

.vs__dropdown-toggle {
  border: none !important;
}
</style>
