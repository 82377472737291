var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-screen h-screen flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "absolute h-12 logoPos",
        attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
      }),
      !_vm.loading && _vm.road.name_status == "Sent For Appeal"
        ? _c(
            "div",
            {
              staticClass:
                "w-2/3 bg-white mx-auto mt-16 z-10 rounded shadow-lg container",
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass:
                    "w-9/10 mx-auto flex rounded-lg p-3 border border-solid border-grey-border",
                },
                [
                  _c("proposalIcon", {
                    attrs: {
                      label: _vm.road.roadname_classification,
                      classNames: "w-12 h-12 ml-2 mr-5 text-sm",
                    },
                  }),
                  _c("div", { staticClass: "self-center w-3/8" }, [
                    _c(
                      "p",
                      {
                        staticClass: "font-semibold text-black text-sub-title",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.road.roadname) +
                            " " +
                            _vm._s(_vm.road.road_type) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "w-3/8 self-center pl-8" }, [
                    _c(
                      "p",
                      { staticClass: "text-black text-grey-text text-normal" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.road.suburb_name) +
                            " " +
                            _vm._s(_vm.road.lga_name) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-auto mr-2 self-center text-sm font-semibold",
                    },
                    [
                      _c("StatusButton", {
                        attrs: {
                          label: _vm.road.name_status,
                          status: _vm.road.name_status,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "w-9/10 mx-auto" }, [
                _c(
                  "div",
                  {
                    staticClass: "pt-10 pb-2 w-full font-semibold text-normal",
                  },
                  [
                    _vm._v(
                      " Please enter any comments or feedback to be shared with the Road Naming Authority here: "
                    ),
                  ]
                ),
                _c("div", { staticClass: "w-full" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.comments,
                        expression: "comments",
                      },
                    ],
                    staticClass:
                      "w-full p-5 bg-grey-input h-64 rounded-lg text-normal",
                    attrs: {
                      placeholder: "Write your comments or feedback here",
                    },
                    domProps: { value: _vm.comments },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.comments = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "p-4 mt-4 flex justify-center ml-auto mb-16 button-blue w-1/5",
                    on: { click: _vm.submitUphold },
                  },
                  [_vm._v(" Submit Uphold ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.loading && _vm.road.name_status != "Sent For Appeal"
        ? _c(
            "div",
            { staticClass: "w-2/3 bg-white mx-auto mt-36 z-10 rounded" },
            [_vm._m(1)]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-9/10 mx-auto mb-8 dividing-line-h" }, [
      _c("div", { staticClass: "mb-4 pt-16 text-black text-title" }, [
        _vm._v("Uphold Decision"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap",
      },
      [
        _c("div", [
          _c("div", { staticClass: "w-full justify-center flex mt-20" }, [
            _c("img", {
              staticClass: "w-36 h-36",
              attrs: { src: require("../../assets/Images/Icon_Gazettal.png") },
            }),
          ]),
          _c("p", { staticClass: "text-title mb-20 mt-8" }, [
            _vm._v(" Your access to this page is denied. "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }