var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-wait",
    {
      key: _vm.isLoading,
      staticClass: "wait-container flex flex-grow flex-col",
      attrs: { for: _vm.isLoading },
    },
    [
      _c("Loading", { attrs: { slot: "waiting" }, slot: "waiting" }),
      !_vm.errorLoading ? _vm._t("component") : _vm._e(),
      _vm.errorLoading ? _c("NetworkError") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }