<template>
  <div class="base-container text-black">
    <section class="pr-20 justify-center w-full block">
      <div class="flex justify-between">
        <router-link
          :to="{
            name: 'organisationsManage',
            params: { organisation: organisation }
          }"
          class="flex justify-between mb-3 -mr-4 self-center"
          data-cypress="goback"
        >
          <BackArrowWithTitle title="Add New organisation" />
        </router-link>
      </div>
    </section>

    <div class="w-full bg-white rounded-lg text-normal pb-10">
      <div class="w-9/10 flex flex-wrap content-center mx-auto pt-12 pb-12">
        <h2 class="w-full">Organisation Details</h2>
        <div class="dividing-line-h-1 h-2">&nbsp;</div>
        <p class="font-bold w-full pt-8 mb-2">
          <a class="text-red-star">*</a>Name of Organisation
        </p>
        <input
          type="text"
          class="w-full input-area"
          data-cypress="nameoforganisation-field"
          placeholder="Enter name of organisation here..."
          v-model="organisation.organisation_name"
          v-validate="'required'"
          name="Organisation Name"
        />
        <FieldErrorMessage :message="errors.first('Organisation Name')" />
        <div class="w-full dividing-line-h-1">&nbsp;</div>
        <div class="mt-6 w-full">
          <p class="text-normal-bold">
            <a class="text-red-star">*</a>Organisation Address Line 1
          </p>
          <input
            type="text"
            class="w-full mt-4 input-area"
            data-cypress="address1-field"
            placeholder="Enter address here..."
            v-model="organisation.address_line_1"
            v-validate="'required'"
            name="Organisation Address"
          />
          <FieldErrorMessage :message="errors.first('Organisation Address')" />
        </div>
        <div class="mt-6 w-full">
          <p class="text-normal-bold">Organisation Address Line 2</p>
          <input
            type="text"
            class="w-full mt-4 input-area"
            data-cypress="address2-field"
            placeholder="Enter address here..."
            v-model="organisation.address_line_2"
            name="address2"
          />
        </div>
        <div class="w-full flex flex-row">
          <div class="mt-6 w-1/2">
            <p class="text-normal-bold"><a class="text-red-star">*</a>Suburb</p>
            <input
              type="text"
              class="w-full mt-4 input-area"
              data-cypress="suburb-field"
              placeholder="Enter suburb name here..."
              v-model="organisation.organisation_suburb"
              v-validate="'required'"
              name="Organisation Suburb"
            />
            <FieldErrorMessage :message="errors.first('Organisation Suburb')" />
          </div>
          <div class="w-1/2 ml-8 flex flex-row">
            <div class="mt-6 w-1/2">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>State
              </p>
              <select
                class="w-full mt-4 input-area"
                v-model="organisation.organisation_state"
                data-cypress="state-field"
                v-validate="'required'"
                name="State"
              >
                <option disabled hidden selected>{{
                  organisation.organisation_state
                }}</option>
                <option value="NSW" selected>NSW</option>
                <option value="VIC">VIC</option>
                <option value="SA">SA</option>
                <option value="WA">VIC</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="ACT">ACT</option>
              </select>
              <FieldErrorMessage :message="errors.first('State')" />
            </div>
            <div class="mt-6 ml-8 w-1/2">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>Postcode
              </p>
              <input
                type="text"
                v-validate="'required|max:4|min:4'"
                class="w-full mt-4 input-area"
                data-cypress="postcode-field"
                placeholder="Enter postcode here..."
                v-model="organisation.organisation_postcode"
                name="Postcode"
              />
              <FieldErrorMessage :message="errors.first('Postcode')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex my-6">
      <router-link
        :to="{
          name: 'organisationsManage',
          params: { organisation: organisation }
        }"
        class="button-red-hollow"
        data-cypress="cancel-button"
        >Cancel</router-link
      >
      <button
        @click="handleSumbitOrganisation"
        class="button-blue ml-auto"
        data-cypress="save-button"
        :disabled="errors.items.length > 0"
      >
        Save & Continue
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      organisation: {}
    };
  },
  methods: {
    async handleSumbitOrganisation() {
      try {
        const isValid = await this.$validator.validate();

        if (isValid) {
          this.organisation.members = [];
          await this.$store
            .dispatch("updateNewOrganisationDataState", this.organisation)
            .then(() => {
              this.$store.dispatch("pushNewOrganisationToDatabase").then(() => {
                this.$notify({
                  group: "toast",
                  type: "success",
                  title: "Success",
                  text: "Organisation Saved"
                });
                this.$router.go(-1);
              });
            });
        } else {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Missing Information",
            text: "Please update all required fields"
          });
        }
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Saving organisation",
          text: "Could not save the Organisation"
        });
      }
    }
  },
  computed: {}
};
</script>

<style scoped></style>
