var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-1 justify-center items-center" }, [
    _c(
      "div",
      {
        staticClass:
          "flex-0 justify-center items-center flex flex-col self-center bg-white text-black rounded-lg px-16 py-16",
      },
      [
        _c(
          "h1",
          { staticClass: "text-6xl" },
          [
            _c("font-awesome-icon", {
              staticClass: "text-red",
              attrs: { icon: "exclamation" },
            }),
            _vm._v("  Page Not Found "),
          ],
          1
        ),
        _c(
          "p",
          [
            _vm._v("Go back to the "),
            _c("router-link", { attrs: { to: "/" } }, [_vm._v("GNB")]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }