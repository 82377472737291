<template>
  <div class="base-container">
    <section class="pt-1 justify-center w-full block">
      <div class="flex justify-between"></div>
      <h2 class="text-black ml-4 self-center text-title">
        Submit Proposal Clarification Advert Information
      </h2>
      <div
        class="max-w-6xl bg-white text-black rounded-lg pl-8 pt-3 pb-3 mt-4 flex flex-col justify-center text-center"
      >
        <div class="text-title">
          <img
            src="../../../../assets/Images/Icon_Gazettal.png"
            class=" h-52 my-4"
          />
        </div>
        <p
          class="text-title pt-4 text-center pb-10"
          v-if="!invalidStatusRedirect"
        >
          Your proposal clarification for
          {{ proposalName }} has been submitted.
        </p>
        <p
          class="text-title pt-4 text-center pb-10"
          v-if="invalidStatusRedirect"
        >
          No clarification is currently pending for
          <b>{{ proposalName }}</b
          >.
        </p>
      </div>

      <div class="flex pt-4 text-center pb-10">
        <router-link
          class="ml-auto w-1/3 button-blue"
          :to="{ name: 'placeNameProposals' }"
          >Return to My Proposals</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    proposalName: String
  },
  data() {
    return {
      invalidStatusRedirect: false
    };
  },

  methods: {},
  mounted() {
    this.invalidStatusRedirect = this.$route.params.invalidStatus;
  }
};
</script>

<style scoped></style>
