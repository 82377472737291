<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <div class="w-5/6 mx-auto" v-bind:class="{ 'py-16': !isPublic }">
        <h3 class="pb-3 mb-4 w-full text-title dividing-line-h">
          Local Newspaper(s)
        </h3>
        <p class="mb-8">
          If approved by the GNB, this proposal will be advertised in a local
          newspaper. Please provide the name of a local newspaper.
        </p>
        <label class="pr-4 w-2/3">
          <taggableInput
            class="w-full mr-4"
            :tagList="localNewspapers"
            :placeholder="
              `Start typing and press enter to add a Newspaper name...`
            "
            :datacypress="'newspaper-input'"
            v-on:addTag="
              newspaper => addLocalNewspapers({ local_newspaper: newspaper })
            "
            v-on:removeTag="index => removeLocalNewspapers({ index: index })"
            v-model="localNewspapers"
            v-validate=""
            data-vv-as="Local Newspaper(s)"
            label="local_newspaper_taglist"
            :disabled="readOnly"
          />
          <FieldErrorMessage
            :message="errors.first('local_newspaper_taglist')"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapPnpGetters } = createNamespacedHelpers("pnpState");
const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");

import taggableInput from "@/components/proposals/place/taggableInput.vue";

export default {
  inject: ["$validator"],
  components: {
    taggableInput
  },
  data() {
    return {
      evaluation: false
    };
  },
  props: {
    readOnly: {
      type: Boolean
    },
    isPublic: {
      type: Boolean
    }
  },
  computed: {
    localNewspapers: {
      get() {
        return this.getLocalNewspapers();
      }
    }
  },
  methods: {
    ...mapPnpGetters(["getLocalNewspapers"]),
    ...mapPnpMutations(["addLocalNewspapers", "removeLocalNewspapers"])
  }
};
</script>
