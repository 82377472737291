var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "mx-2 my-2 flex-wrap justify-center self-start border-2 pt-2 pb-2 pl-8 pr-8 font-bold rounded-full focus:outline-none self-center",
      class: _vm.label + " " + _vm.classNames,
    },
    [_vm._v(" " + _vm._s(_vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }