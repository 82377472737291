var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c("div", { staticClass: "w-full flex bg-white" }, [
      _c(
        "div",
        { staticClass: "w-5/6 mx-auto", class: { "py-16": !_vm.isPublic } },
        [
          _c(
            "h3",
            { staticClass: "pb-3 mb-4 w-full text-title dividing-line-h" },
            [_vm._v(" Lot and Plan Information ")]
          ),
          !_vm.isTypePlaceNameProposal
            ? _c("p", { staticClass: "mb-8" }, [
                _vm._v(
                  " Please provide lot and plan information or address details of affected properties "
                ),
              ])
            : _vm._e(),
          _vm.isTypePlaceNameProposal
            ? _c("p", { staticClass: "mb-8" }, [
                _vm._v(
                  " Please provide lot and plan information if available "
                ),
              ])
            : _vm._e(),
          _vm._m(0),
          _vm._l(_vm.lots, function (lot, index) {
            return _c(
              "div",
              { key: index, staticClass: "w-full flex items-center mt-3" },
              [
                _c("p", { staticClass: "text-grey text-sub-title w-3%" }, [
                  _vm._v(" " + _vm._s(index + 1) + " "),
                ]),
                _c("input", {
                  staticClass:
                    "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: lot.lot },
                }),
                _c("input", {
                  staticClass:
                    "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: lot.section },
                }),
                _c("input", {
                  staticClass:
                    "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: lot.plan },
                }),
                _c("input", {
                  staticClass:
                    "rounded p-4 mr-4 text-normal text-grey-text bg-grey-input flex-grow self-center",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: lot.address },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow justify-center p-4 items-center cursor-pointer",
                    class: {
                      redButtonFaded: _vm.readOnly,
                      redButtonSolid: !_vm.readOnly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.remove(index)
                      },
                    },
                  },
                  [_vm._v(" Remove ")]
                ),
              ]
            )
          }),
          _c("div", { staticClass: "w-full flex items-center mt-3" }, [
            _c("p", { staticClass: "text-grey text-sub-title w-3%" }, [
              _vm._v(" " + _vm._s(_vm.lots.length + 1 || "") + " "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newLot.lot,
                  expression: "newLot.lot",
                },
              ],
              staticClass:
                "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
              attrs: {
                type: "text",
                placeholder: "Enter lot...",
                "data-cypress": "lot-input",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.newLot.lot },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newLot, "lot", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newLot.section,
                  expression: "newLot.section",
                },
              ],
              staticClass:
                "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
              attrs: {
                type: "text",
                placeholder: "Enter Section...",
                "data-cypress": "section-input",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.newLot.section },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newLot, "section", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newLot.plan,
                  expression: "newLot.plan",
                },
              ],
              staticClass:
                "w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
              attrs: {
                type: "text",
                placeholder: "Enter Plan...",
                "data-cypress": "plan-input",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.newLot.plan },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newLot, "plan", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newLot.address,
                  expression: "newLot.address",
                },
              ],
              staticClass:
                "rounded p-4 mr-4 text-normal text-grey-text bg-grey-input flex-grow self-center",
              attrs: {
                type: "text",
                placeholder: "Enter address...",
                "data-cypress": "lotaddress-input",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.newLot.address },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newLot, "address", $event.target.value)
                },
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow justify-center p-4 items-center cursor-pointer",
                class: {
                  greenButtonFaded: _vm.readOnly,
                  greenButtonSolid: !_vm.readOnly,
                },
                attrs: {
                  "data-cypress": "add-lot-button",
                  disabled: _vm.readOnly,
                },
                on: { click: _vm.addNewLot },
              },
              [_vm._v(" Add Lot ")]
            ),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full flex mb-2" }, [
      _c("p", { staticClass: "w-3%" }),
      _c("p", { staticClass: "text-normal-bold w-1/6 mr-4 mr-4" }, [
        _vm._v("Lot"),
      ]),
      _c("p", { staticClass: "text-normal-bold w-1/6 mr-4" }, [
        _vm._v("Section"),
      ]),
      _c("p", { staticClass: "text-normal-bold w-1/6 mr-4" }, [_vm._v("Plan")]),
      _c("p", { staticClass: "text-normal-bold mr-4 flex-grow" }, [
        _vm._v("Address"),
      ]),
      _c("p", {}),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }