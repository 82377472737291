var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-4/5 mx-auto font-times" }, [
    _c(
      "div",
      {
        staticClass:
          "border-t-2 border-b-2 border-solid border-black text-center",
      },
      [
        _c("div", { staticClass: "w-full flex justify-center" }, [
          _c("div", { staticClass: "w-full" }, [
            _c("p", { staticClass: "pt-5 text-title" }, [
              _vm._v(_vm._s(_vm.rna.authority_name)),
            ]),
            _c("p", { staticClass: "pt-3 text-normal-large" }, [
              _vm._v("ROADS ACT 1993"),
            ]),
            _c("p", { staticClass: "pt-3 text-normal" }, [
              _vm._v("Naming of Roads"),
            ]),
            _c(
              "p",
              { staticClass: "pt-3 text-normal text-left justfiy-left" },
              [
                _vm._v(
                  " Notice is hereby given that " +
                    _vm._s(_vm.rna.authority_name) +
                    ", section 162 of the Roads Act 1993, has officially named the road(s) as shown hereunder: "
                ),
              ]
            ),
          ]),
        ]),
        _vm._l(_vm.roads, function (road) {
          return _c(
            "div",
            {
              key: road.identifier,
              staticClass: "w-full flex justify-center text-left mt-4 mb-4",
            },
            [
              _c("table", { staticClass: "w-full" }, [
                _vm._m(0, true),
                _c("tr", { staticClass: "tableRow text-normal" }, [
                  _c("td", { staticClass: "tableRow p-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(road.roadname) +
                        " " +
                        _vm._s(road.road_type) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "tableRow p-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          road.suburb_name && road.suburb_name.join(", ")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(1, true),
                _c("tr", { staticClass: "tableRow text-normal" }, [
                  _c(
                    "td",
                    { staticClass: "tableRow p-2", attrs: { colspan: "2" } },
                    [_vm._v(" " + _vm._s(road.extent) + " ")]
                  ),
                ]),
              ]),
            ]
          )
        }),
        _vm.user.authority_name
          ? _c("div", { staticClass: "w-full flex justify-left mb-4" }, [
              _c("p", { staticClass: "w-4/5 text-left text-normal" }, [
                _vm._v(" " + _vm._s(_vm.rna.manager_name) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.rna.manager_role) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.rna.authority_name) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.rna.address_line_1)),
                _vm.rna.address_line_2
                  ? _c("span", [
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.rna.address_line_2)),
                    ])
                  : _vm._e(),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.rna.rna_suburb) +
                    " " +
                    _vm._s(_vm.rna.rna_state) +
                    " " +
                    _vm._s(_vm.rna.rna_postcode) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "tableRow text-normal-bold" }, [
      _c("th", { staticClass: "tableRow p-2 w-1/2" }, [_vm._v("Name")]),
      _c("th", { staticClass: "tableRow p-2 w-1/2" }, [_vm._v("Locality")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "tableRow text-normal-bold" }, [
      _c("th", { staticClass: "tableRow p-2", attrs: { colspan: "2" } }, [
        _vm._v("Description"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }