var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-container"},[_c('section',{staticClass:"justify-center w-full block"},[_c('a',{staticClass:"flex justify-between",on:{"click":function($event){return _vm.$router.push({
          name: 'erratumGazettalNotice',
          params: { road: _vm.proposal }
        })}}},[_c('BackArrowWithTitle',{attrs:{"title":"Preview Erratum Gazettal"}})],1),_c('showLoading',{attrs:{"isLoading":"fetching rna details"},scopedSlots:_vm._u([{key:"component",fn:function(){return [_c('div',{staticClass:"w-full bg-white text-black rounded-lg pl-8 pr-8 pt-24 pb-24 mt-4 justify-center flex"},[_c('div',{staticClass:"w-4/5 mx-auto font-times"},[_c('div',{staticClass:"border-t-2 border-b-2 border-solid border-black text-center"},[_c('div',{staticClass:"w-full flex justify-center"},[_c('div',{staticClass:"w-2/3"},[_c('p',{staticClass:"pt-5 text-title"},[_vm._v(_vm._s(_vm.rna.authority_name))]),_c('p',{staticClass:"pt-3 text-normal-large"},[_vm._v("ROADS ACT 1993")]),_c('p',{staticClass:"pt-3 text-normal"},[_vm._v("Naming of Roads")]),_c('p',{staticClass:"pt-3 text-normal"},[_vm._v("ERRATUM")]),_c('p',{staticClass:"pt-3 text-normal text-left justify-left"},[_vm._v(" In the notice published in NSW Government Gazette No. "+_vm._s(_vm.erratumGazette.gazetteNumber)+" on "+_vm._s(_vm.formattedPublicationDate)+", page number "+_vm._s(_vm.erratumGazette.folioNumber)+", "),(
                        _vm.proposal.roadname != _vm.erratumGazette.roadName ||
                        _vm.proposal.road_type != _vm.erratumGazette.roadType
                      )?_c('span',[_vm._v(" the words \""+_vm._s(_vm.proposal.roadname)+" "+_vm._s(_vm.proposal.road_type)+"\" are replaced with \""+_vm._s(_vm.erratumGazette.roadName)+" "+_vm._s(_vm.erratumGazette.roadType)+"\" ")]):_vm._e(),(_vm.proposal.extent != _vm.erratumGazette.extent)?_c('span',[_vm._v(" the extent \""+_vm._s(_vm.proposal.extent)+"\" is replaced with \""+_vm._s(_vm.erratumGazette.extent)+"\" ")]):_vm._e(),(
                        _vm.sortAndJoin(_vm.proposal.suburb_name) !==
                        _vm.sortAndJoin(_vm.erratumGazette.suburb_name)
                      )?_c('span',[_vm._v(" the locality \""+_vm._s(_vm.sortAndJoin(_vm.proposal.suburb_name))+"\" is replaced with \""+_vm._s(_vm.sortAndJoin(_vm.erratumGazette.suburb_name))+"\" ")]):_vm._e(),(
                        _vm.proposal.roadname == _vm.erratumGazette.roadName &&
                        _vm.proposal.road_type == _vm.erratumGazette.roadType
                      )?_c('span',[_vm._v(" for the road "+_vm._s(_vm.proposal.roadname)+" "+_vm._s(_vm.proposal.road_type)+". ")]):_vm._e()]),_c('p',{staticClass:"pt-3 text-normal text-left justify-left"},[_vm._v(" This notice corrects that error. ")]),_c('p',{staticClass:"pt-3 text-normal text-left justify-left mb-8"},[_vm._v(" The gazettal date remains "+_vm._s(_vm.formattedGazettalDate)+". ")])])]),(_vm.user.authority_name)?_c('div',{staticClass:"w-full flex justify-center mb-4"},[_c('p',{staticClass:"w-2/3 text-left"},[_vm._v(" "+_vm._s(_vm.rna.manager_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.rna.manager_role)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.rna.authority_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.rna.address_line_1)),(_vm.rna.address_line_2)?_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.rna.address_line_2))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.rna.rna_suburb)+" "+_vm._s(_vm.rna.rna_state)+" "+_vm._s(_vm.rna.rna_postcode)+" ")])]):_vm._e()])])]),_c('div',{staticClass:"flex pt-4 text-center pb-10"},[_c('router-link',{staticClass:"button-blue-hollow flex-no-grow",attrs:{"to":{ name: 'erratumGazettalNotice', params: { road: _vm.road } }}},[_vm._v("Return to draft")]),_c('div',{staticClass:"flex-grow"}),_c('ButtonWithSpinner',{staticClass:"button-blue relative flex flex-no-grow justify-center whitespace-no-wrap font-semibold",attrs:{"data-cypress":"submit-gazettal-notice-button","disabled":_vm.$wait.is('saving gazette'),"isSpinning":_vm.$wait.is('saving gazette')},on:{"click":_vm.submit}},[_vm._v("Create Erratum Gazettal")])],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }