import * as organisationCruds from "../../helpers/organisationCRUD";

// initial state
const state = {
  newOrganisationData: {},
  allOrganisations: [],
  organisation: {
    members: [],
    proposals: []
  },
  organisations: {
    count: 0,
    orgs: []
  },
  entityList: [],
  isWaiting: false,
  usersWithoutOrg: [],
  defaultOrganisationLga: null
};

// getters
const getters = {
  getNewOrganisationDataState: state => state.newOrganisationData,
  organisationList: state => state.allOrganisations,
  organisation: state => state.organisation,
  organisations: state => state.organisations,
  usersWithoutOrg: state => state.usersWithoutOrg,
  defaultOrganisationLga: state => state.defaultOrganisationLga
};

// actions
const actions = {
  async pushNewOrganisationToDatabase({ state, commit, dispatch }) {
    commit("setWaiting", true);
    try {
      await organisationCruds.saveOrganisationToDb(state.newOrganisationData);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      await dispatch("getOrganisations");
      commit("setWaiting", false);
    }
  },
  updateNewOrganisationDataState(state, payload) {
    state.commit("mutateNewOrganisationDataState", payload);
  },
  async getOrganisationList({ commit }) {
    commit("setWaiting", true);
    try {
      const organisationList = await organisationCruds.getOrganisationList();
      commit("setOrganisationList", organisationList);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async getEntityList({ commit }) {
    commit("setWaiting", true);
    try {
      const entityList = await organisationCruds.getEntityList();
      commit("setEntityList", entityList);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async getOrganisation({ commit }, id) {
    commit("setWaiting", true);
    try {
      let organisation = await organisationCruds.getOrganisation(id);

      commit("setOrganisation", organisation);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
    // await organisationCruds.getOrganisation(id);
  },
  async getOrganisations({ commit }, query) {
    commit("setWaiting", true);
    try {
      const organisationList = await organisationCruds.getOrganisations(query);
      commit("setOrganisations", organisationList);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async getUsersWithoutOrg({ commit }) {
    commit("setWaiting", true);
    try {
      const usersList = await organisationCruds.getUsersWithoutOrganisation();
      commit("setUsersWithoutOrg", usersList.users);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async removeOrganisation({ dispatch, commit }, id) {
    commit("setWaiting", true);
    try {
      await organisationCruds.deleteOrganisation(id);
      await dispatch("getOrganisations");
    } catch (e) {
      commit("setWaiting", false);
      // let the caller know there was an error
      throw e;
    }
  },
  async fetchDefaultOrganisationLga({ commit }, orgIdentifier) {
    const defaultOrganisationLga = await organisationCruds.getDefaultOrganisationLga(
      orgIdentifier
    );
    commit("setDefaultOrganisationLga", defaultOrganisationLga);
  }
};

// mutations
const mutations = {
  mutateNewOrganisationDataState(state, payload) {
    state.newOrganisationData = payload;
  },
  setOrganisationList(state, organisationList) {
    state.allOrganisations = organisationList;
    state.isWaiting = false;
  },
  setOrganisations(state, organisations) {
    state.organisations = organisations;
    state.isWaiting = false;
  },
  setOrganisation(state, organisation) {
    state.organisation = organisation;
    state.isWaiting = false;
  },
  setUsersWithoutOrg(state, userList) {
    state.usersWithoutOrg = userList;
    state.isWaiting = false;
  },
  setEntityList(state, entityList) {
    state.entityList = entityList;
    state.isWaiting = false;
  },
  setWaiting(state, isWaiting) {
    state.isWaiting = isWaiting;
  },
  setDefaultOrganisationLga(state, value) {
    state.defaultOrganisationLga = value;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
