<template>
  <FormContainer>
    <div style="text-align: center; color: red;">
      OFFICIAL: Sensitive – Personal
    </div>
    <h1 class="pt-6 mb-10 text-center mt-4 text-title">
      Log in to your account
    </h1>
    <!-- email address -->
    <div class="mb-6">
      <label class="block text-black text-normal-bold mb-2" for="username"
        >Email Address</label
      >
      <input
        v-validate="'required|email'"
        v-bind:class="{ inputError: isInvalidLoginCredentials }"
        class="w-full input-area"
        id="username"
        name="email"
        type="text"
        v-model="login"
        placeholder
        :disabled="isWaiting"
        data-cypress="email"
        @input="login = login.toLowerCase()"
      />
      <span
        class="text-red text-sm ml-2"
        v-show="errors.has('email')"
        data-cypress="email-error"
        >{{ errors.first("email") }}</span
      >
    </div>
    <!-- password -->
    <div class="">
      <label class="block text-black text-normal-bold mb-2" for="password"
        >Password</label
      >
      <input
        v-validate="'required'"
        v-model="password"
        v-on:keyup.enter="signIn"
        v-bind:class="{ inputError: isInvalidLoginCredentials }"
        class="w-full input-area"
        id="password"
        name="password"
        type="password"
        placeholder
        :disabled="isWaiting"
        data-cypress="password"
      />
      <span
        class="text-red text-xs ml-2"
        v-show="errors.has('password')"
        data-cypress="password-error"
        >{{ errors.first("password") }}</span
      >
    </div>
    <!-- sign in invalid credentials error -->
    <div class="flex ml-4 pb-4" data-cypress="invalid-credentials-error">
      <CognitoErrorMessage :error="cognitoError" />
    </div>
    <!-- password reset -->
    <div class="items-center justify-between text-center mt-5">
      <ButtonWithSpinner
        :disabled="errors.has('email') || errors.has('password') || isWaiting"
        class="w-full button-blue"
        @click="signIn"
        :isSpinning="isWaiting"
        data-cypress="login"
        >Submit</ButtonWithSpinner
      >
      <a
        class="reset pt-4 inline-block align-baseline font-bold hover:text-blue-darker cursor-pointer"
        @click="forgotPasswordRoute"
        data-cypress="reset-password"
        >I need to reset my password</a
      >
    </div>
    <!-- register account -->
    <p class="pt-6 text-normal">
      Don’t have an account? If you are a representative of a government
      (Local/NSW/Other) entity, you can register here to submit and manage road
      and place name proposals.
    </p>
    <button
      class="register w-full mt-4 bg-white font-bold button-blue-hollow focus:outline-none focus:shadow-outline mb-10"
      type="button"
      data-cypress="register"
      @click="handleRegister"
    >
      Register Now
    </button>
  </FormContainer>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import FormContainer from "./form-container";
import Auth from "@aws-amplify/auth";
import CognitoErrorMessage from "./cognitoErrorMessage";
import { logFailedLogin } from "../../helpers/userCRUD";

const {
  mapMutations: mapUserMutations,
  mapActions: mapUserActions
} = createNamespacedHelpers("user");

export default {
  name: "authenticationForm",
  components: {
    FormContainer,
    CognitoErrorMessage
  },
  data() {
    return {
      login: "",
      password: "",
      isInvalidLoginCredentials: false,
      userIsNotConfirmed: false,
      submitted: false,
      isWaiting: false,
      cognitoError: null
    };
  },
  computed: {
    signedIn() {
      return !!this.$store.getters.user;
    }
  },
  watch: {
    login(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isInvalidLoginCredentials = false;
        this.userIsNotConfirmed = false;
      }
    },
    password(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isInvalidLoginCredentials = false;
        this.userIsNotConfirmed = false;
      }
    }
  },
  methods: {
    handleRegister() {
      this.$router.push("/register");
    },
    async signIn() {
      try {
        const response = await this.$validator.validateAll();
        if (response) {
          this.isWaiting = true;
          await Auth.signIn(this.login, this.password);
          await this.checkUserAuth();
          this.isWaiting = false;

          this.$router.replace(
            this.$route.query.redirect
              ? this.$route.query.redirect
              : { name: "fork" }
          );
        }
      } catch (err) {
        this.isInvalidLoginCredentials =
          err.code === "UserNotFoundException" ||
          err.code === "NotAuthorizedException" ||
          err.code === "UserNotConfirmedException";

        if (err.code === "NotAuthorizedException") {
          await logFailedLogin(this.login);
        }

        this.cognitoError = err;
        this.isWaiting = false;
      }
    },
    async signOut() {
      try {
        await Auth.signOut({ global: true });
        this.setUser(null);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    forgotPasswordRoute() {
      this.$router.push({ name: "reset" });
    },
    ...mapUserMutations(["setUser"]),
    ...mapUserActions(["checkUserAuth"])
  }
};
</script>

<style scoped>
/* .authenticationForm {
  min-height: 80%;
} */
input[type="submit"] {
  border: none;
  cursor: pointer;
}

.reset {
  color: #002664;
  text-decoration: none;
}
/* .register {
  color: #002664;
  border-color: #002664;
} */
h2 {
  font-weight: 800;
}
.inputError {
  border: 2px solid #d7153a;
}

.text-red {
  color: #b81237;
}
</style>
