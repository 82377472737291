var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container mb-16" }, [
    !_vm.results && !_vm.$wait.is(`fetchingGeonames`)
      ? _c(
          "div",
          {
            staticClass:
              "justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3",
          },
          [
            _c(
              "section",
              { staticClass: "justify-center w-full block" },
              [
                _c(
                  "h3",
                  {
                    staticClass: "pb-3 mb-8 w-full text-title dividing-line-h",
                  },
                  [_vm._v(" Place Name Search ")]
                ),
                _vm.isPublic
                  ? _c("p", { staticClass: "mb-4" }, [
                      _vm._v(
                        " Search for a place name or geographic feature in the Geographical Name Register (GNR) of NSW to retrieve information about its name. "
                      ),
                    ])
                  : _vm._e(),
                _vm.isPublic
                  ? _c(
                      "ButtonWithSpinner",
                      {
                        staticClass:
                          "button-blue-hollow flex relative w-2/6 my-8 mr-auto",
                        attrs: {
                          isSpinning: _vm.$wait.is("downloadingGeonames"),
                          disabled: _vm.$wait.is("downloadingGeonames"),
                        },
                        on: { click: _vm.downloadGNRRecords },
                      },
                      [_vm._v("Download all GNR Records")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500 mr-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "geographical_name" },
                      },
                      [_vm._v("Place Name")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.geographical_name,
                          expression: "search.geographical_name",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "geographical_name",
                        type: "text",
                        placeholder: "Enter all or part of the place name...",
                      },
                      domProps: { value: _vm.search.geographical_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.search,
                            "geographical_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: {
                            title:
                              "View Glossary of status values in the Geographical Names Register",
                            target: "_blank",
                            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf",
                          },
                        },
                        [_vm._v(" Status ")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.statusArray || _vm.readOnly,
                          multiple: true,
                          options: _vm.statusArray,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select a status",
                          name: "status",
                          id: "status",
                          "data-cypress": "status-field",
                        },
                        model: {
                          value: _vm.search.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "status", $$v)
                          },
                          expression: "search.status",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500 mr-4" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: {
                            title:
                              "View Glossary of designation values in the Geographical Names Register",
                            target: "_blank",
                            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf",
                          },
                        },
                        [_vm._v(" Designation")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.designations || _vm.readOnly,
                          multiple: true,
                          options: _vm.designations,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select designation(s)",
                          name: "designation",
                          id: "designation",
                          "data-cypress": "designation-field",
                        },
                        model: {
                          value: _vm.search.designation,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "designation", $$v)
                          },
                          expression: "search.designation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-1/2 bg-gray-500" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "block text-normal-bold mb-2",
                          attrs: { for: "lga" },
                        },
                        [_vm._v("LGA")]
                      ),
                      _c("norns-select", {
                        staticClass: "w-full norns-select",
                        attrs: {
                          disabled: !_vm.lgasArray || _vm.readOnly,
                          multiple: true,
                          options: _vm.lgasArray,
                          searchable: true,
                          "close-on-select": false,
                          "select-on-tab": true,
                          "show-labels": true,
                          "hide-selected": false,
                          placeholder: "Select an LGA",
                          name: "lga",
                          id: "lga",
                          "data-cypress": "lga-field",
                        },
                        model: {
                          value: _vm.search.lga,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "lga", $$v)
                          },
                          expression: "search.lga",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500 mr-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "topographical_map" },
                      },
                      [_vm._v("1:25,000 Map Name")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.topographical_map,
                          expression: "search.topographical_map",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "topographical_map",
                        type: "text",
                        placeholder: "Enter all or part of the map name",
                      },
                      domProps: { value: _vm.search.topographical_map },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.search,
                            "topographical_map",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "w-1/2 bg-gray-500" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "parish" },
                      },
                      [_vm._v("Parish")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.parish,
                          expression: "search.parish",
                        },
                      ],
                      staticClass: "w-full input-area",
                      attrs: {
                        id: "parish",
                        type: "text",
                        placeholder: "Enter all or part of the parish name",
                      },
                      domProps: { value: _vm.search.parish },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.search, "parish", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-5" }, [
                  _c("div", { staticClass: "w-1/2 bg-gray-500" }, [
                    _c(
                      "label",
                      {
                        staticClass: "block text-normal-bold mb-2",
                        attrs: { for: "lga" },
                      },
                      [_vm._v("Dual Named")]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search.dual_named,
                            expression: "search.dual_named",
                          },
                        ],
                        staticClass: "w-full mt-4 input-area",
                        attrs: { id: "dual_named", name: "dual_named" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.search,
                              "dual_named",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "", selected: "" } }, [
                          _vm._v("Select Dual Named Status"),
                        ]),
                        _c("option", { attrs: { value: "Yes" } }, [
                          _vm._v("Yes"),
                        ]),
                        _c("option", { attrs: { value: "No" } }, [
                          _vm._v("No"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex", attrs: { name: "3 buttons" } },
              [
                _c(
                  "ButtonWithSpinner",
                  {
                    staticClass:
                      "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                    attrs: {
                      "data-cypress": "delete-button",
                      disabled: _vm.$wait.any,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "ButtonWithSpinner",
                  {
                    staticClass:
                      "button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto",
                    attrs: { isSpinning: false, disabled: false },
                    on: { click: _vm.resetSearchForm },
                  },
                  [_vm._v("Reset")]
                ),
                _c(
                  "ButtonWithSpinner",
                  {
                    staticClass: "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                    attrs: { isSpinning: false, disabled: false },
                    on: { click: _vm.fetchSearchResults },
                  },
                  [_vm._v("Search")]
                ),
              ],
              1
            ),
            _vm.isPublic
              ? _c("div", { staticClass: "dividing-line-h flex" })
              : _vm._e(),
            _vm.isPublic
              ? _c(
                  "section",
                  { staticClass: "justify-center w-full block mb-16" },
                  [
                    _c("h3", { staticClass: "pb-3 mb-8 w-full text-title" }, [
                      _vm._v(" How do I search the GNR? "),
                    ]),
                    _c("p", { staticClass: "mb-6" }, [
                      _vm._v(
                        " In the place name field, enter only the simple name component with no descriptive elements related to the placename. For example, to search for 'Mount Kosciuszko', just enter ‘Kosciuszko’. "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-6" }, [
                      _vm._v(
                        " References to former LGA names, topographic maps and parishes occur in the database because the geographical features were originally gazetted against these entities. "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-6" }, [
                      _vm._v(
                        " Large features such as rivers and national parks may span multiple LGAs, maps and parishes. The values of these attributes stored against a feature were considered significant to the feature at time of gazettal. "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-6" }, [
                      _vm._v(
                        " If you are unsure which LGA, map or parish you are looking for, it may be useful to perform a place name search for a known feature within the area and then use the LGA, map or parish name shown on the resulting extract as the basis for your search. "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-4" }, [
                      _vm._v(
                        " You can download your search results or alternatively, you can download every record in the GNR database. "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.results || _vm.$wait.is(`fetchingGeonames`)
      ? _c(
          "div",
          {
            staticClass:
              "justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3",
          },
          [
            _c(
              "div",
              {
                staticClass: "px-4 text-red text-normal-bold cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "flex-auto",
                  attrs: { icon: "arrow-left" },
                }),
                _vm._v(" Back "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "px-20" },
              [
                _c(
                  "h3",
                  {
                    staticClass: "pb-3 mb-8 w-full text-title dividing-line-h",
                  },
                  [_vm._v(" Place Name Search Results ")]
                ),
                _c("div", { staticClass: "flex mb-2" }, [
                  _c("div", { staticClass: "text-normal-bold w-1/4" }, [
                    _vm._v(" Place Name "),
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.search.geographical_name || "None")),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _vm._m(0),
                  !_vm.search.status.length
                    ? _c("div", [_vm._v("None")])
                    : _vm._e(),
                  _vm.search.status.length
                    ? _c(
                        "div",
                        _vm._l(_vm.search.status, function (status, index) {
                          return _c("p", { key: index }, [
                            _vm._v(" " + _vm._s(status) + " "),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _vm._m(1),
                  !_vm.search.designation.length
                    ? _c("div", [_vm._v("None")])
                    : _vm._e(),
                  _vm.search.designation.length
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.search.designation,
                          function (designation, index) {
                            return _c("p", { key: index }, [
                              _vm._v(" " + _vm._s(designation) + " "),
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _c("div", { staticClass: "text-normal-bold w-1/4" }, [
                    _vm._v(" LGA "),
                  ]),
                  !_vm.search.lga.length
                    ? _c("div", [_vm._v("None")])
                    : _vm._e(),
                  _vm.search.lga.length
                    ? _c(
                        "div",
                        _vm._l(_vm.search.lga, function (lga, index) {
                          return _c("p", { key: index }, [
                            _vm._v(" " + _vm._s(lga) + " "),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _c("div", { staticClass: "text-normal-bold w-1/4" }, [
                    _vm._v(" 1:25,000 Map Name "),
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.search.topographical_map || "None")),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _c("div", { staticClass: "text-normal-bold w-1/4" }, [
                    _vm._v(" Parish "),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.search.parish || "None"))]),
                ]),
                _c("div", { staticClass: "flex mb-2" }, [
                  _c("div", { staticClass: "text-normal-bold w-1/4" }, [
                    _vm._v(" Dual Named "),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.search.dual_named || "None"))]),
                ]),
                !_vm.$wait.is(`fetchingGeonames`) &&
                _vm.results &&
                _vm.results.length
                  ? _c("showResults", {
                      attrs: {
                        results: _vm.results,
                        page: _vm.queryParams.page,
                        limit: _vm.queryParams.limit,
                        resultsCount: _vm.resultsCount,
                      },
                    })
                  : _vm._e(),
                _vm.$wait.is(`fetchingGeonames`)
                  ? _c("fetchingResults")
                  : _vm._e(),
                _vm.resultsCount === 0 ? _c("noResults") : _vm._e(),
                !_vm.$wait.is(`fetchingGeonames`) &&
                _vm.results &&
                _vm.results.length
                  ? _c(
                      "section",
                      {
                        staticClass:
                          "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mx-auto text-center self-center" },
                          [
                            _c("paginate", {
                              attrs: {
                                "page-count": Math.ceil(
                                  _vm.resultsCount / _vm.queryParams.limit
                                ),
                                "page-range": 10,
                                "margin-pages": 1,
                                "click-handler": _vm.changePage,
                                "prev-text": "<",
                                "next-text": ">",
                                "container-class": "pagination",
                                "page-class": "page-item",
                              },
                              model: {
                                value: _vm.queryParams.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "page", $$v)
                                },
                                expression: "queryParams.page",
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                " Showing " +
                                  _vm._s(
                                    (
                                      (_vm.queryParams.page - 1) *
                                        _vm.queryParams.limit +
                                      1
                                    ).toLocaleString()
                                  ) +
                                  " to " +
                                  _vm._s(
                                    Math.min(
                                      (_vm.queryParams.page - 1) *
                                        _vm.queryParams.limit +
                                        _vm.queryParams.limit,
                                      _vm.resultsCount
                                    ).toLocaleString()
                                  ) +
                                  " of " +
                                  _vm._s(_vm.resultsCount.toLocaleString()) +
                                  " place names "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex justify-between mt-5 mb-10" },
                  [
                    _c(
                      "ButtonWithSpinner",
                      {
                        staticClass:
                          "button-blue-hollow flex w-2/6 mt-4 mb-10 ml-4",
                        attrs: { isSpinning: false, disabled: false },
                        on: { click: _vm.beginNewSearch },
                      },
                      [_vm._v("Begin New Search")]
                    ),
                    _c(
                      "ButtonWithSpinner",
                      {
                        staticClass: "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                        attrs: {
                          isSpinning: _vm.$wait.is("downloadingGeonames"),
                          disabled: _vm.$wait.is("downloadingGeonames"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.downloadGNRRecords(_vm.search)
                          },
                        },
                      },
                      [_vm._v("Download Search Results")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-normal-bold w-1/4" }, [
      _c(
        "a",
        {
          attrs: {
            title:
              "View Glossary of status values in the Geographical Names Register",
            target: "_blank",
            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf",
          },
        },
        [_vm._v(" Status ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-normal-bold w-1/4" }, [
      _c(
        "a",
        {
          attrs: {
            title:
              "View Glossary of designation values in the Geographical Names Register",
            target: "_blank",
            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf",
          },
        },
        [_vm._v(" Designation")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }