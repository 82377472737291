<template>
  <div>
    <div class="flex-auto text-label">
      <font-awesome-icon :icon="icon" v-if="!!icon" />
      <span v-if="!icon">{{ name }}</span>
    </div>

    <div class="flex justify-center items-start flex-auto text-black">
      <slot>
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "organisationProperty",
  props: ["name", "value", "icon"]
};
</script>
