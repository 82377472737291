<template>
  <div class="flex flex-1 justify-center items-center">
    <div
      class="flex-0 justify-center items-center flex flex-col self-center bg-white text-black rounded-lg px-16 py-16"
    >
      <h1 class="text-6xl">
        <font-awesome-icon class="text-red" icon="exclamation" />&nbsp; Page Not
        Found
      </h1>

      <p>Go back to the <router-link to="/">GNB</router-link></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage"
};
</script>
