var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "justify-center w-full block self-center bg-white text-black rounded-lg pt-4 mt-3 mb-3",
    },
    [
      _c("div", { staticClass: "w-full pl-14 pr-2 flex-col mx-auto" }, [
        _c(
          "div",
          { staticClass: "flex-1 self-center flex justify-between pb-6" },
          [
            _c("div", { staticClass: "self-center flex-1" }, [
              _c("div", { staticClass: "flex items-center" }, [
                _vm.loggedUser.isAdmin
                  ? _c("div", { staticClass: "py-3 justify-center mb-1" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "container items-baseline flex height-fix",
                        },
                        [
                          _c("input", {
                            staticClass: "checkbox-input",
                            attrs: { type: "checkbox" },
                            domProps: { checked: _vm.isChecked },
                            on: { change: _vm.passProposalIdentifier },
                          }),
                          _c("span", { staticClass: "border-2 checkmark" }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "h2",
                  {
                    staticClass: "self-center pt-2 h-auto mb-4 pr-10",
                    attrs: { "data-cypress": "proposal-reference" },
                  },
                  [_vm._v(" " + _vm._s(_vm.proposal.geographical_name) + " ")]
                ),
              ]),
              _vm.proposal.reference_no
                ? _c("p", { staticClass: "mb-2" }, [
                    _c(
                      "span",
                      { staticClass: "flex-1 text-blue text-normal-bold" },
                      [
                        _vm._v(
                          " Reference Number: " +
                            _vm._s(_vm.proposal.reference_no) +
                            " "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "pb-2 text-normal" }, [
                _vm._v(
                  " Submitted " +
                    _vm._s(_vm.formattedTime(_vm.proposal.date_created)) +
                    " by "
                ),
                _vm.proposal.organisation_name
                  ? _c(
                      "span",
                      {
                        staticClass: "flex-1 text-blue-button text-normal-bold",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.proposal.organisation_name) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.proposal.organisation_name
                  ? _c(
                      "span",
                      {
                        staticClass: "flex-1 text-blue-button text-normal-bold",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.proposal.first_name) +
                            " " +
                            _vm._s(_vm.proposal.last_name) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "flex-wrap flex-start" },
                [
                  _c("proposalButton", {
                    attrs: {
                      label: _vm.placeClassificationHelper(_vm.proposal.type),
                      classNames: `text-normal-bold cursor-default ${_vm.proposal.type}`,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "pr-4 flex flex-row" }, [
              _vm.proposal.geoname_status === "DRAFTED"
                ? _c(
                    "div",
                    { staticClass: "pr-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-blue-button font-bold",
                          attrs: {
                            to: {
                              name: "placeNameProposal",
                              params: { proposalId: _vm.proposal.identifier },
                            },
                          },
                        },
                        [_vm._v("Resume Draft")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proposal.geoname_status === "Awaiting Clarification"
                ? _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-blue-button font-bold",
                          attrs: {
                            to: {
                              name: "clarifyPnp",
                              params: {
                                proposalId: _vm.proposal.identifier,
                              },
                            },
                          },
                        },
                        [_vm._v("Respond to Query")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.proposal.geoname_status !== "DRAFTED"
                ? _c(
                    "div",
                    { staticClass: "pr-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-blue-button font-bold",
                          attrs: {
                            to: {
                              name: "placeNameProposal",
                              params: { proposalId: _vm.proposal.identifier },
                            },
                          },
                        },
                        [_vm._v("View Proposal")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "dividing-line-h-1 w-full" }),
      _c(
        "div",
        { staticClass: "flex justify-between w-9/10 mx-auto" },
        [
          _c("div", { staticClass: "pt-4 flex flex-row flex-grow" }, [
            _c(
              "div",
              { staticClass: "w-3/6 pt-0 pb-4 mr-4" },
              [
                _c(
                  "span",
                  { staticClass: "flex-1 text-blue text-normal-bold" },
                  [_vm._v(" Local Government Area(s) ")]
                ),
                _vm._l(_vm.proposal.lga, function (lga, index) {
                  return _c("span", { key: index, staticClass: "block" }, [
                    _vm._v(_vm._s(lga)),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "flex-grow ml-10 pb-4" }, [
              _c("span", { staticClass: "flex-1 text-blue text-normal-bold" }, [
                _vm._v(" Localities "),
              ]),
              _vm.proposal.type === _vm.PROPOSAL_TYPE_PLACE
                ? _c(
                    "div",
                    _vm._l(_vm.proposal.locality, function (locality, index) {
                      return _c("span", { key: index, staticClass: "block" }, [
                        _vm._v(_vm._s(locality)),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.proposal.type === _vm.PROPOSAL_TYPE_BOUNDARY ||
              _vm.proposal.type === _vm.PROPOSAL_TYPE_LOCALITY
                ? _c(
                    "div",
                    _vm._l(
                      _vm.proposal.affected_localities,
                      function (locality, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "block" },
                          [_vm._v(_vm._s(locality))]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _c("StatusButton", {
            attrs: {
              label: _vm.proposal.display_status,
              status: _vm.proposal.display_status,
              classNames: "text-normal-bold cursor-default flex-no-grow",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }