<template>
  <div>
    <entry :eventDate="entryData.date" cardTitle="Email">
      <div class="mt-2">
        <p class="mb-2">
          The below email was
          {{ recipientEmail ? "sent to " + recipientEmail : "generated" }}:
        </p>
        <strong v-if="emailSubject">{{ emailSubject }}</strong>
        <strong v-else-if="emailType">{{ emailType }}</strong>
      </div>
    </entry>
  </div>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "EmailTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Entry
  },
  computed: {
    emailType() {
      if (this.entryData.changes) {
        const emailField = this.entryData.changes["email type"];
        return emailField ? emailField.to : null;
      }
      return null;
    },
    emailSubject() {
      if (this.entryData.changes) {
        const emailField = this.entryData.changes["email subject"];
        return emailField ? emailField.to : null;
      }
      return null;
    },
    recipientEmail() {
      if (this.entryData.changes) {
        const emailField = this.entryData.changes["recipient email"];
        return emailField ? emailField.to : null;
      }
      return null;
    }
  }
};
</script>
