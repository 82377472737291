var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c("div", { staticClass: "w-full flex bg-white" }, [
      _c(
        "div",
        { staticClass: "w-5/6 mx-auto", class: { "py-16": !_vm.isPublic } },
        [
          _c(
            "h3",
            { staticClass: "pb-3 mb-4 w-full text-title dividing-line-h" },
            [_vm._v(" Additional Information ")]
          ),
          _c("label", { staticClass: "pr-4 w-2/3" }, [
            _c("p", { staticClass: "text-normal-bold mb-2 mt-6" }, [
              _vm._v(
                " Any other relevant information regarding this proposal? "
              ),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.additional_information,
                  expression: "additional_information",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "additional_information-field",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.additional_information },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.additional_information = $event.target.value
                },
              },
            }),
          ]),
          _c("uploadScoped", {
            staticClass: "mt-4",
            attrs: {
              "data-cypress": "upload-additional-information",
              label:
                "Attach supporting material for the additional information",
              important: false,
              files: _vm.additional_information_reference_document,
              drafted: true,
              stateKey: _vm.uploadKey,
              stateProp: "additional_information_reference_document",
              uploadFile: (params) =>
                _vm.uploadFile(Object.assign({}, params, {})),
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: _vm.uploadKey,
                  prop: "additional_information_reference_document",
                  index: fileIndex,
                }),
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }