var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex mb-8" }, [
    _c(
      "div",
      { staticClass: "w-full" },
      [
        _vm._m(0),
        _vm._l(_vm.lgas, function (lga, index) {
          return _c(
            "div",
            { key: index, staticClass: "w-full flex items-center mt-3" },
            [
              _c("p", { staticClass: "text-grey text-sub-title w-3%" }, [
                _vm._v(" " + _vm._s(index + 1) + " "),
              ]),
              _c("input", {
                staticClass:
                  "flex-grow rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                attrs: { type: "text", disabled: "" },
                domProps: { value: lga.lga },
              }),
              _c(
                "div",
                { staticClass: "float right flex-row flex" },
                [
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-4 ml-2",
                      attrs: {
                        name: "lga-consulted-yes",
                        "data-cypress": "lga-consulted-yes",
                        checkedValue: true,
                        disabled: true,
                      },
                      model: {
                        value: lga.consulted,
                        callback: function ($$v) {
                          _vm.$set(lga, "consulted", $$v)
                        },
                        expression: "lga.consulted",
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6",
                      attrs: {
                        "data-cypress": "lga-consulted-no",
                        name: "lga-consulted-no",
                        checkedValue: false,
                        disabled: true,
                      },
                      model: {
                        value: lga.consulted,
                        callback: function ($$v) {
                          _vm.$set(lga, "consulted", $$v)
                        },
                        expression: "lga.consulted",
                      },
                    },
                    [_vm._v("No")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "redButtonSolid justify-center w-1/6 p-4 items-center cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.removeLga(index)
                    },
                  },
                },
                [_vm._v(" Remove ")]
              ),
            ]
          )
        }),
        _c("div", { staticClass: "w-full flex items-center mt-3" }, [
          _c("p", { staticClass: "text-grey text-sub-title w-3%" }, [
            _vm._v(" " + _vm._s(_vm.lgas.length + 1) + " "),
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newLga.lga,
                  expression: "newLga.lga",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-grow flex w-3% p-4 mr-4 input-area-appear",
              attrs: {
                "data-cypress": "lga-select",
                name: "lgaName_field",
                id: "lgaName_field",
                disabled: _vm.readOnly,
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.newLga,
                    "lga",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.lgasArray, function (item, key) {
              return _c("option", { key: key, domProps: { value: item } }, [
                _vm._v(" " + _vm._s(item) + " "),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-4 ml-2",
                  attrs: {
                    name: "lga-consulted-yes",
                    "data-cypress": "lga-consulted-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.newLga.consulted,
                    callback: function ($$v) {
                      _vm.$set(_vm.newLga, "consulted", $$v)
                    },
                    expression: "newLga.consulted",
                  },
                },
                [_vm._v("Yes")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "lga-consulted-no",
                    name: "lga-consulted-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.newLga.consulted,
                    callback: function ($$v) {
                      _vm.$set(_vm.newLga, "consulted", $$v)
                    },
                    expression: "newLga.consulted",
                  },
                },
                [_vm._v("No")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "w-1/6 justify-center p-4 items-center cursor-pointer",
              class: {
                greenButtonFaded:
                  _vm.readOnly || !_vm.newLga.lga || !!_vm.newLga.lga.length,
                greenButtonSolid:
                  !_vm.readOnly && _vm.newLga.lga && _vm.newLga.lga.length,
              },
              attrs: {
                "data-cypress": "checklist-add-lga-button",
                disabled:
                  _vm.readOnly || !_vm.newLga.lga || !!_vm.newLga.lga.length,
              },
              on: { click: _vm.addNewLga },
            },
            [_vm._v(" Add LGA ")]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full flex mb-2" }, [
      _c("p", { staticClass: "w-3%" }),
      _c("p", { staticClass: "text-normal-bold flex-grow mr-4 mr-4" }, [
        _vm._v(" Local Government Authority "),
      ]),
      _c("p", { staticClass: "text-normal-bold w-1/6 pl-4 mr-4" }, [
        _vm._v(" Consulted? "),
      ]),
      _c("p", { staticClass: "w-1/6" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }