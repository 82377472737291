var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "mx-2 my-2 border-2 pt-2 pb-2 pl-8 pr-8 rounded-full focus:outline-none text-normal-bold cursor-pointer button-blue-hollow",
          attrs: { disabled: _vm.btnDisabled },
          on: {
            click: function ($event) {
              return _vm.openReassignProposal(_vm.modalName)
            },
          },
        },
        [_vm._v(" Reassign ")]
      ),
      _c(
        "modal",
        {
          attrs: { name: _vm.modalName, height: "auto" },
          on: { "before-close": _vm.clearInputs },
        },
        [
          _c("div", { staticClass: "flex flex-col justify-between" }, [
            _c("h2", { staticClass: "text-title flex-0 px-4" }, [
              _vm._v("Reassign Proposals"),
            ]),
            _c("div", { staticClass: "dividing-line-h" }),
            _c(
              "div",
              { staticClass: "p-4" },
              [
                _c(
                  "label",
                  {
                    staticClass: "block text-black text-normal-bold mb-2",
                    attrs: { for: "firstName" },
                  },
                  [_vm._v("First Name")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.firstName,
                      expression: "firstName",
                    },
                  ],
                  ref: "firstName",
                  staticClass: "w-full input-area",
                  attrs: {
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    "data-cypress": "firstName",
                    list: "firstNameList",
                  },
                  domProps: { value: _vm.firstName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.firstName = $event.target.value
                    },
                  },
                }),
                _c(
                  "datalist",
                  { attrs: { id: "firstNameList" } },
                  _vm._l(_vm.firstNameList, function (firstName, index) {
                    return _c("option", { key: index }, [
                      _vm._v(" " + _vm._s(firstName) + " "),
                    ])
                  }),
                  0
                ),
                _c(
                  "label",
                  {
                    staticClass: "block text-black text-normal-bold mb-2 mt-4",
                    attrs: { for: "lastName" },
                  },
                  [_vm._v("Last Name")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lastName,
                      expression: "lastName",
                    },
                  ],
                  ref: "lastName",
                  staticClass: "w-full input-area",
                  attrs: {
                    id: "lastName",
                    name: "lastName",
                    type: "text",
                    "data-cypress": "lastName",
                    list: "lastNameList",
                  },
                  domProps: { value: _vm.lastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.lastName = $event.target.value
                    },
                  },
                }),
                _c(
                  "datalist",
                  { attrs: { id: "lastNameList" } },
                  _vm._l(_vm.lastNameList, function (lastName, index) {
                    return _c("option", { key: index }, [
                      _vm._v(" " + _vm._s(lastName) + " "),
                    ])
                  }),
                  0
                ),
                _c(
                  "label",
                  {
                    staticClass: "block text-black text-normal-bold mb-2 mt-4",
                    attrs: { for: "email" },
                  },
                  [_vm._v("Email")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  ref: "email",
                  staticClass: "w-full input-area",
                  attrs: {
                    id: "email",
                    name: "email",
                    type: "email",
                    "data-cypress": "email",
                    list: "emailList",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _c(
                  "datalist",
                  { attrs: { id: "emailList" } },
                  _vm._l(_vm.emailList, function (email, index) {
                    return _c("option", { key: index }, [
                      _vm._v(" " + _vm._s(email) + " "),
                    ])
                  }),
                  0
                ),
                _vm.isExistingUser
                  ? _c(
                      "label",
                      {
                        staticClass:
                          "block text-black text-normal-bold mb-2 mt-4",
                        attrs: { for: "text" },
                      },
                      [_vm._v("Account Status")]
                    )
                  : _vm._e(),
                _vm.isExistingUser
                  ? _c("AccountStatus", {
                      attrs: {
                        account_status:
                          _vm.filteredUsers[0].user_account_status,
                        "data-cypress": "user-account-status",
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "mt-2" }, [
                  !_vm.isExistingUser
                    ? _c(
                        "span",
                        {
                          staticClass: "text-error",
                          attrs: { "data-cypress": "no-matching-user-error" },
                        },
                        [_vm._v("No user is matched")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "self-end my-6 flex justify-between" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button-blue-hollow",
                        attrs: {
                          "data-cypress": "clear-button",
                          type: "button",
                        },
                        on: { click: _vm.clearInputs },
                      },
                      [_vm._v(" Clear ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button-red-hollow",
                        attrs: {
                          "data-cypress": "cancel-button",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.closeReassignProposal(_vm.modalName)
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button-blue",
                        attrs: {
                          type: "button",
                          "data-cypress": "reassign-button",
                          disabled: _vm.isExistingUser ? false : true,
                        },
                        on: { click: _vm.reassignProposal },
                      },
                      [_vm._v(" Reassign ")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }