var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c(
      "div",
      { staticClass: "w-full flex bg-white" },
      [
        _c(
          "modal",
          {
            attrs: {
              name: "redirect-to-login",
              width: "33%",
              height: "auto",
              classes: "rounded-lg bg-white py-16 px-16 text-center",
            },
            on: {
              "before-close": function ($event) {
                _vm.represents_entity = null
              },
            },
          },
          [
            _c("h3", { staticClass: "mb-4" }, [_vm._v("Login Required")]),
            _c("p", [
              _vm._v(
                " As a representative of a Government Authority, you are required to log in to the system before submitting a Place Name Proposal. "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "button-blue mt-4",
                on: { click: _vm.redirectToLogin },
              },
              [_vm._v(" Proceed to log in page ")]
            ),
          ]
        ),
        _c("div", { staticClass: "w-5/6 mx-auto" }, [
          _vm.isAdmin
            ? _c("div", { staticClass: "w-full pb-8 pt-8" }, [
                _vm._m(0),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.organisationIdentifier,
                        expression: "organisationIdentifier",
                      },
                    ],
                    staticClass:
                      "w-full rounded-md p-4 text-normal input-field",
                    attrs: {
                      "data-cypress": "behalf-of-organisation-select",
                      disabled: _vm.readOnly,
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.organisationIdentifier = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.organisations, function (pna, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        staticClass: "my-4 font-light pl-4 h-8",
                        domProps: { value: pna.identifier },
                      },
                      [
                        _c("div", { staticClass: "my-4" }, [
                          _vm._v(_vm._s(pna.organisation_name)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.isPublic
            ? _c("div", { staticClass: "w-full mx-auto" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "flex flex-row text-normal" },
                  [
                    _c(
                      "RadioButton",
                      {
                        staticClass: "flex-0 mr-6 mt-3 mb-1",
                        attrs: {
                          name: "represents_entity",
                          "data-cypress": "represents_entity-no",
                          checkedValue: false,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.represents_entity,
                          callback: function ($$v) {
                            _vm.represents_entity = $$v
                          },
                          expression: "represents_entity",
                        },
                      },
                      [_vm._v("No")]
                    ),
                    _c(
                      "RadioButton",
                      {
                        staticClass: "flex-0 mr-6 mt-3 mb-1",
                        attrs: {
                          name: "represents_entity",
                          "data-cypress": "represents_entity-true",
                          checkedValue: true,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.represents_entity,
                          callback: function ($$v) {
                            _vm.represents_entity = $$v
                          },
                          expression: "represents_entity",
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("label", { staticClass: "w-2/3" }, [
            _vm._m(2),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.referenceNumber,
                  expression: "referenceNumber",
                },
              ],
              staticClass: "w-full mr-4 input-area",
              attrs: {
                type: "text",
                "data-cypress": "referenceNumber-field",
                placeholder: "Enter your reference...",
                name: "referenceNumber",
                id: "referenceNumber",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.referenceNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.referenceNumber = $event.target.value
                },
              },
            }),
            _vm.evaluation
              ? _c(
                  "div",
                  _vm._l(_vm.evaluation.issues, function (rules, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flex items-center relative" },
                      [
                        _c("errorIcon"),
                        _c(
                          "div",
                          {
                            staticClass: "text-red-error text-normal-bold ml-2",
                          },
                          [_vm._v(" " + _vm._s(rules.shortDescription) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "w-5/6 mx-auto pt-8 pb-16 flex-grow w-full" },
            [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "flex flex-row text-normal" },
                [
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6 mt-3 mb-1",
                      attrs: {
                        name: "placeNameProposal",
                        "data-cypress": "place-name-proposal",
                        checkedValue: _vm.PROPOSAL_TYPE_PLACE,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.proposalType,
                        callback: function ($$v) {
                          _vm.proposalType = $$v
                        },
                        expression: "proposalType",
                      },
                    },
                    [_vm._v("Place Name Proposal")]
                  ),
                  !_vm.isPublic
                    ? _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6 mt-3 mb-1",
                          attrs: {
                            "data-cypress": "new-amended-address-locality",
                            name: "newAmendedLocality",
                            checkedValue: _vm.PROPOSAL_TYPE_LOCALITY,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.proposalType,
                            callback: function ($$v) {
                              _vm.proposalType = $$v
                            },
                            expression: "proposalType",
                          },
                        },
                        [_vm._v("New/Amended Address Locality Name")]
                      )
                    : _vm._e(),
                  !_vm.isPublic
                    ? _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6 mt-3 mb-1",
                          attrs: {
                            "data-cypress":
                              "address-locality-boundary-amendment",
                            name: "localityBoundaryAmendment",
                            checkedValue: _vm.PROPOSAL_TYPE_BOUNDARY,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.proposalType,
                            callback: function ($$v) {
                              _vm.proposalType = $$v
                            },
                            expression: "proposalType",
                          },
                        },
                        [_vm._v("Address Locality Boundary Amendment")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.errors.first("Road type check")
                ? _c(
                    "div",
                    { staticClass: "flex items-center absolute" },
                    [
                      _c("errorIcon"),
                      _c(
                        "div",
                        { staticClass: "ml-2 text-red-error text-normal-bold" },
                        [_vm._v(" The type check field is required. ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 w-full" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("On Behalf of Organisation: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-4" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Do you represent a Government Authority? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 w-full mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Your Reference "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Type of Proposal "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }