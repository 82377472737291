var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex bg-white" }, [
    _c(
      "div",
      { staticClass: "w-5/6 mx-auto pb-16 pt-8" },
      [
        _c("div", { staticClass: "mb-4 flex flex-row" }, [
          _c("span", { staticClass: "font-bold flex-grow text-lg" }, [
            _vm._v("History of changes"),
          ]),
          _c(
            "div",
            {
              staticClass:
                "sort-button border-blue-button font-medium border-l-2 border-t-2 border-b-2 py-1 px-4 rounded-l-lg",
              class: { "bg-blue-button": !_vm.isAsc, "text-white": !_vm.isAsc },
              on: {
                click: () => {
                  if (!_vm.$wait.is("fetchingHistory")) {
                    _vm.sortDesc()
                  }
                },
              },
            },
            [_vm._v(" Latest ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "sort-button border-blue-button font-medium border-r-2 border-t-2 border-b-2 py-1 px-4 rounded-r-lg",
              class: { "bg-blue-button": _vm.isAsc, "text-white": _vm.isAsc },
              on: {
                click: () => {
                  if (!_vm.$wait.is("fetchingHistory")) {
                    _vm.sortAsc()
                  }
                },
              },
            },
            [_vm._v(" Oldest ")]
          ),
        ]),
        (!_vm.history || !_vm.history.length) &&
        !_vm.$wait.is("fetchingHistory")
          ? _c("div", { staticClass: "w-full text-lg py-8 text-center" }, [
              _vm._v(
                " There is no change history for this geographical name record. "
              ),
            ])
          : _vm._e(),
        _c("showLoading", {
          attrs: { isLoading: "fetchingHistory" },
          scopedSlots: _vm._u([
            {
              key: "component",
              fn: function () {
                return _vm._l(_vm.historyData, function (history, index) {
                  return _c("div", { key: index, staticClass: "w-full" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full font-medium text-grey-darkest py-2",
                      },
                      [_vm._v(" " + _vm._s(history.date) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full flex flex-row font-bold border-b-2",
                      },
                      [
                        _c("div", { staticClass: "w-1/3 pl-2" }, [
                          _vm._v("Field"),
                        ]),
                        _c("div", { staticClass: "w-1/3 pl-2" }, [
                          _vm._v("From"),
                        ]),
                        _c("div", { staticClass: "w-1/3 pl-2" }, [
                          _vm._v("To"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      _vm._l(
                        history.historyForDate,
                        function (changeSet, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "my-4 w-full" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xs text-grey font-bold w-full text-right",
                                },
                                [_vm._v(" " + _vm._s(changeSet.time) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "border-l-4" },
                                _vm._l(
                                  changeSet.changes,
                                  function (change, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "w-full flex flex-row py-1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "w-1/3 pl-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mapFieldToLabel(
                                                    change.field,
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        !Array.isArray(change.from)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/3 pl-2 text-sm font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      change.from || "None"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        Array.isArray(change.from)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/3 pl-2 text-sm font-bold",
                                              },
                                              [
                                                !change.from.length
                                                  ? _c("div", [_vm._v("None")])
                                                  : _c("div", [
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          change.from,
                                                          function (
                                                            changeFrom,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _c("li", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: _vm.relativeFileUrl(
                                                                          changeFrom.s3_key
                                                                        ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            changeFrom.title
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        !Array.isArray(change.to)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/3 pl-2 text-sm font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      change.to || "None"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        Array.isArray(change.to)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/3 pl-2 text-sm font-bold",
                                              },
                                              [
                                                !change.to.length
                                                  ? _c("div", [_vm._v("None")])
                                                  : _c("div", [
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          change.to,
                                                          function (
                                                            changeTo,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: index },
                                                              [
                                                                _c("li", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href: _vm.relativeFileUrl(
                                                                          changeTo.s3_key
                                                                        ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            changeTo.title
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                })
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }