var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex bg-white mb-8 mx-64 px-16 py-16 mt-32" },
    [
      _c("showLoading", {
        attrs: { isLoading: "fetchingGeoname" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _vm.geoname
                  ? _c(
                      "div",
                      [
                        _c("h3", [
                          _c("b", [_vm._v("Add Place Name Information")]),
                        ]),
                        _c("hr", { staticClass: "divider w-full mb-8" }),
                        _c("p", [
                          _vm._v(
                            " Please enter history, origin, meaning or pronunciation information you may have for this geographical name. You can also upload any documents, including maps and audio files, that may be useful. Once submitted, the Geographical Names Board Secretariat will review the information supplied and may contact you to discuss further. "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full border flex flex-row border-gray-200 rounded items-center mt-8",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-black font-bold text-2xl mx-8",
                              },
                              [_vm._v(_vm._s(_vm.geoname.geographical_name))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dividing-line-v text-black flex-grow flex flex-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4",
                                  },
                                  [
                                    _c("span", [_vm._v("LGA")]),
                                    _vm._l(_vm.geoname.lga, function (lga) {
                                      return _c("p", { key: lga }, [
                                        _vm._v(_vm._s(lga)),
                                      ])
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-grow pb-4 pt-4 w-1/4 pr-4",
                                  },
                                  [
                                    _c("span", [_vm._v("Designation")]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.geoname.designation)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-grow pb-4 pt-4 w-1/4" },
                                  [
                                    _c("span", [_vm._v("Gazettal Date")]),
                                    _vm._l(
                                      _vm.geoname.gazettal_dates,
                                      function (gazettal_date) {
                                        return _c("p", { key: gazettal_date }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                gazettal_date.date_gazetted
                                              )
                                            )
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "label",
                          { staticClass: "w-2/3" },
                          [
                            _c(
                              "p",
                              { staticClass: "text-normal-bold mb-2 mt-8" },
                              [
                                _c("span", { staticClass: "text-red-star" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("Additional Information "),
                              ]
                            ),
                            _c("div", { staticClass: "w-full mt-3 flex" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.additional_information,
                                    expression: "additional_information",
                                  },
                                ],
                                staticClass: "w-full h-48 input-area",
                                attrs: {
                                  "data-cypress": "additionalInformation-field",
                                  "data-vv-as": "Additional Information",
                                  name: "additional_information",
                                },
                                domProps: { value: _vm.additional_information },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.additional_information =
                                      $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _c("FieldErrorMessage", {
                              attrs: {
                                message: _vm.errors.first(
                                  "additional_information"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "w-full justify-center flex-col mb-4",
                          },
                          [
                            _c("uploadScoped", {
                              staticClass: "mt-4",
                              attrs: {
                                "data-cypress": "historical_reference_document",
                                label: "Upload Files",
                                important: false,
                                files: _vm.historical_reference_document,
                                drafted: true,
                                stateKey: "historical_reference_document",
                                stateProp: "historical_reference_document",
                                uploadFile: _vm.uploadFileForComponent,
                              },
                              on: {
                                deleteFile: (fileIndex) => {
                                  _vm.historical_reference_document.splice(
                                    fileIndex,
                                    1
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "pb-3 mb-4 mt-8 w-full text-title dividing-line-h",
                          },
                          [_vm._v("Contact Details")]
                        ),
                        _c("div", { staticClass: "w-full flex" }, [
                          _c(
                            "div",
                            { staticClass: "w-1/2" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mt-4 mb-2" },
                                [
                                  _c("a", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("First Name "),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.first_name,
                                    expression: "first_name",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass:
                                  "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter First Name Here...",
                                  name: "first_name",
                                  "data-vv-as": "First Name",
                                },
                                domProps: { value: _vm.first_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.first_name = $event.target.value
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first("first_name"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-1/2 ml-8" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mt-4 mb-2" },
                                [
                                  _c("a", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Last Name "),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.last_name,
                                    expression: "last_name",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass:
                                  "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter Last Name Here...",
                                  name: "last_name",
                                  "data-vv-as": "Last Name",
                                },
                                domProps: { value: _vm.last_name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.last_name = $event.target.value
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: {
                                  message: _vm.errors.first("last_name"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "w-full" }, [
                          _c(
                            "p",
                            { staticClass: "text-normal-bold mt-4 mb-2" },
                            [_vm._v("Address Line 1")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address_line_1,
                                expression: "address_line_1",
                              },
                            ],
                            staticClass:
                              "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                            attrs: {
                              type: "text",
                              placeholder: "Enter Address Here...",
                              name: "address_line_1",
                            },
                            domProps: { value: _vm.address_line_1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.address_line_1 = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "w-full" }, [
                          _c(
                            "p",
                            { staticClass: "text-normal-bold mt-4 mb-2" },
                            [_vm._v("Address Line 2")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.address_line_2,
                                expression: "address_line_2",
                              },
                            ],
                            staticClass:
                              "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                            attrs: {
                              type: "text",
                              placeholder:
                                "e.g. Apartment number, floor number, etc",
                              name: "address_line_2",
                            },
                            domProps: { value: _vm.address_line_2 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.address_line_2 = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "w-full flex items-center" }, [
                          _c("div", { staticClass: "w-1/2 mr-8" }, [
                            _c(
                              "p",
                              { staticClass: "text-normal-bold mt-4 mb-2" },
                              [_vm._v("Suburb")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.suburb,
                                  expression: "suburb",
                                },
                              ],
                              staticClass:
                                "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                              attrs: {
                                type: "text",
                                placeholder: "Enter Suburb Here...",
                                name: "suburb",
                              },
                              domProps: { value: _vm.suburb },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.suburb = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "w-1/2 flex items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "w-1/2 mr-8 mt-8 mb-2" },
                                [
                                  _c("p", { staticClass: "text-normal-bold" }, [
                                    _vm._v("State"),
                                  ]),
                                  _c("norns-select", {
                                    staticClass:
                                      "w-full rounded-lg text-normal text-grey-text bg-grey-input self-center h-12",
                                    attrs: {
                                      selected: _vm.state,
                                      options: [
                                        "NSW",
                                        "QLD",
                                        "VIC",
                                        "SA",
                                        "WA",
                                        "NT",
                                        "ACT",
                                        "TAS",
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "w-1/2" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mt-4 mb-2" },
                                  [_vm._v("Postcode")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.postcode,
                                      expression: "postcode",
                                    },
                                  ],
                                  staticClass:
                                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter Postcode Here...",
                                    name: "postcode",
                                  },
                                  domProps: { value: _vm.postcode },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.postcode = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "w-full" }, [
                          _c(
                            "p",
                            { staticClass: "text-normal-bold mt-4 mb-2" },
                            [_vm._v("Country")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.country,
                                expression: "country",
                              },
                            ],
                            staticClass:
                              "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                            attrs: {
                              type: "text",
                              placeholder: "Enter Country Here...",
                              name: "country",
                            },
                            domProps: { value: _vm.country },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.country = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "w-full flex items-center" }, [
                          _c("div", { staticClass: "w-1/2 mr-8" }, [
                            _c(
                              "p",
                              { staticClass: "text-normal-bold mt-4 mb-2" },
                              [_vm._v("Phone Number")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.phone_no,
                                  expression: "phone_no",
                                },
                              ],
                              staticClass:
                                "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                              attrs: {
                                type: "text",
                                placeholder: "Enter Phone Number Here...",
                                name: "phone_no",
                              },
                              domProps: { value: _vm.phone_no },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.phone_no = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "w-1/2" },
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mt-4 mb-2" },
                                [
                                  _c("a", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Email Address "),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email",
                                    expression: "'required|email'",
                                  },
                                ],
                                staticClass:
                                  "w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center",
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter Email Address Here...",
                                  name: "email",
                                  "data-vv-as": "Email Address",
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                              _c("FieldErrorMessage", {
                                attrs: { message: _vm.errors.first("email") },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("hr", { staticClass: "divider w-full mb-8 mt-4" }),
                        _c(
                          "button",
                          {
                            staticClass: "button-red-hollow",
                            on: {
                              click: function ($event) {
                                return _vm.backToInfo()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "buttonWithSpinner",
                          {
                            staticClass: "button-blue float-right",
                            attrs: {
                              disabled:
                                !_vm.isFormValid ||
                                _vm.$wait.is("updatingGeoname"),
                              isSpinning: _vm.$wait.is("updatingGeoname"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateGeoname()
                              },
                            },
                          },
                          [_vm._v("Submit Additional Information")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }