<template>
  <div
    id="uploadDiv"
    data-cypress="upload-box"
    class="my-4 w-full flex flex-col"
    :class="classNames"
  >
    <p class="pb-3 text-normal-bold">
      <a class="text-red-star" v-if="important">*</a>{{ label }}
    </p>
    <div class="flex w-full flex-row flex-1">
      <label class="flex flex-1">
        <div
          class="flex flex-1 p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded"
          ref="fileform"
        >
          Click or drag file here to upload...
          <input
            type="file"
            class="hidden"
            ref="fileInput"
            data-cypress="file-upload-input"
            @change="filesChange($event.target.files)"
            :disabled="disabled"
            :accept="acceptedFiles"
          />
        </div>

        <ButtonWithSpinner
          class="bg-blue-button rounded rounded-l-none relative flex flex-no-grow justify-center items-center cursor-pointer text-white text-normal font-semibold"
          :disabled="isWaiting || disabled"
          :isSpinning="isWaiting"
          data-cypress="addFilesButton1"
          @click="$refs.fileInput.click()"
          >Add Files</ButtonWithSpinner
        >
      </label>
    </div>
    <div class="mb-4" v-for="(file, index) in uploadedFiles" v-bind:key="index">
      <div
        class="w-full mt-5 flex items-center rounded-lg border border-grey-border"
      >
        <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
        <p class="textNormal" data-cypress="file-title">{{ file.title }}</p>
        <a
          class="cursor-pointer ml-auto text-normal-bold text-blue-button"
          :href="relativeFileUrl(file.s3_key)"
          >View File</a
        >
        <button
          class="cursor-pointer ml-10 mr-8 text-normal-bold"
          v-bind:class="{
            'text-grey-light': !drafted,
            'text-red-button': drafted
          }"
          @click="removeFile(index)"
          :disabled="disabled"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ButtonWithSpinner from "./buttonWithSpinner";
import {
  allFilesList,
  documentFilesList,
  gisFilesList,
  imageFilesList
} from "../../helpers/fileWhitelists";
export default {
  components: {
    ButtonWithSpinner
  },
  props: {
    label: {
      type: String
    },
    important: {
      type: Boolean
    },
    classNames: {
      type: String
    },
    drafted: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    deleteFile: {
      type: Function
    },
    accept: {
      type: String,
      default: ""
    }
  },
  $_veeValidate: {
    name() {
      return "fileUploader";
    },
    value() {
      return this.uploadedFiles.length;
    }
  },
  data() {
    return {
      s3Key: Date.now(),
      isWaiting: false,
      uploadNum: 0,
      dragAndDropCapable: false
    };
  },
  computed: {
    ...mapGetters({
      uploadedFilesState: "uploadedFilesState"
    }),
    acceptedFiles() {
      if (this.accept === "") return allFilesList;
      if (this.accept === "documents") return documentFilesList;
      if (this.accept === "images") return imageFilesList;
      if (this.accept === "gisFiles") return gisFilesList;
      return this.accept;
    },
    uploadedFiles: {
      get: function() {
        return this.uploadedFilesState;
      },
      set: function(file) {
        const _this = this;
        this.$store
          .dispatch("uploadFile", { file, s3Key: this.s3Key })
          .then(() => {
            //uploadnum counts the number of files uploading, if 0 then they've all been uploaded
            _this.uploadNum--;
            if (_this.uploadNum <= 0) {
              _this.isWaiting = false;
              _this.$emit("uploadfinished");
            }
          })
          .catch(e => {
            _this.isWaiting = false;
            _this.$emit("uploadfinished");
            let title = "Cannot upload";
            let text = "Error uploading file";
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.statusCode === 415
            ) {
              title = "Unsupported file type";
            }
            this.$notify({
              group: "toast",
              type: "error",
              title,
              text
            });
          });
      }
    }
  },
  methods: {
    ...mapMutations(["deleteUploadedFile"]),
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    filesChange(fileList) {
      this.isWaiting = true;
      this.$emit("uploadstarted");
      this.uploadNum = fileList.length;
      this.$emit("input", fileList.length);
      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        let newFile = new File(
          [file],
          file.name.replace(/[^a-z0-9-_()./]+/gi, "_"),
          { type: file.type }
        );
        this.uploadedFiles = newFile;
      }
      this.$refs.fileInput.value = "";
    },
    removeFile(fileIndex) {
      if (this.drafted) {
        this.deleteFile(this.uploadedFiles[fileIndex].s3_key);
        this.deleteUploadedFile(fileIndex);
      } else {
        this.$notify({
          group: "toast",
          type: "notice",
          title: "Cannot remove",
          text: "Files can only be removed from drafted proposals"
        });
      }
    },
    /*
       Determines if the drag and drop functionality is in the
       window
    */
    determineDragAndDropCapable() {
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement("div");

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.
      */
      return "draggable" in div || ("ondragstart" in div && "ondrop" in div);
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    this.drafted === !!this.drafted;

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          /*
        For each event add an event listener that prevents the default action
        (opening the file in the browser) and stop the propagation of the event (so
        no other elements open the file in the browser)
      */
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
      Add an event listener for drop to the form
    */
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          /*
        Capture the files from the drop event and handle them
      */
          this.filesChange(e.dataTransfer.files);
        }.bind(this)
      );
    }
  }
};
</script>

<style></style>
