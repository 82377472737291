<template>
  <div class="main flex flex-grow">
    <div class="base-container flex flex-col">
      <section class="pt-1 justify-center w-full block">
        <div class="flex text-normal self-center justify-center mb-4">
          <Road class="road-img" />
        </div>
        <h1 class="flex self-center justify-center text-title mb-4">
          Manage Road Name Proposals
        </h1>
        <div class="flex flex-1 flex-row items-between flex-wrap">
          <RnaFilteredList
            :rnaList="rnaList"
            v-model="rnaName"
            class="flex-1 mr-2 bg-white"
            name="rnaFilter"
            id="rnaFilter"
            v-if="user.isAdmin"
            data-cypress="filter-by-rna"
            placeholder="Filter by Authority"
          />

          <div class="flex-0">
            <Checkbox
              data-cypress="checkboxArchived"
              v-model="includeArchived"
              :disabled="false"
              name="isArchived"
              class="text-black ml-auto flex-no-shrink"
              label="Show Archived Proposals?"
              v-validate
            ></Checkbox>
          </div>
        </div>
      </section>
      <NetworkError v-if="fetchError" />
      <showLoading isLoading="loading Proposals" v-if="!fetchError">
        <template v-slot:component>
          <section class="justify-center w-full block self-center">
            <ProposalListItem
              :proposal="proposal"
              v-for="(proposal, index) in roadnameProposals"
              v-bind:key="index"
            />
            <div
              class="flex justify-center items-center flex-grow mt-8"
              v-if="!roadnameProposals.length"
            >
              <h3 class="flex-0 text-black">No Road Name Proposals found.</h3>
            </div>
          </section>
        </template>
      </showLoading>
      <section
        v-if="!fetchError"
        class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6"
      >
        <div
          v-if="!$wait.is(`loading Proposals`) && roadnameProposals.length"
          class="mx-auto text-center self-center"
        >
          <paginate
            v-model="page"
            :page-count="Math.ceil(totalProposalsForQuery / limit)"
            :page-range="10"
            :margin-pages="1"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
          <p>
            Showing
            {{ ((page - 1) * limit + 1).toLocaleString() }}
            to
            {{
              Math.min(
                (page - 1) * limit + limit,
                totalProposalsForQuery
              ).toLocaleString()
            }}
            of
            {{ totalProposalsForQuery.toLocaleString() }}
            proposals
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import { mapActions, mapGetters, createNamespacedHelpers, mapMutations } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { waitFor } from "vue-wait";

import Road from "../../../assets/Images/road-black.svg";
import ProposalListItem from "./proposal-list-item";

import rnaMixin from "../../../mixins/rna-list-mixin";

export default {
  name: "proposals",
  components: {
    ProposalListItem,
    Paginate,
    Road
  },
  mixins: [rnaMixin],
  methods: {
    ...mapActions(["getProposalsList","resetProposalSearch"]),
    ...mapMutations([
      "setKeyword",
      "setIncludeArchived",
      "setProposalSearchPage",
      "setProposalSearchLimit",
      "setProposalSearchRoadNamingAuthority"
    ]),
    qsToProp(queryString) {
      if (queryString.page)
        this.setProposalSearchPage(parseInt(queryString.page, 10));

      if (queryString.limit) {
        this.limit = parseInt(queryString.limit, 10);
      }

      this.road_naming_authority_id =
        queryString.road_naming_authority_id || null;

      if (typeof queryString.includeArchived === "boolean") {
        this.includeArchived = queryString.includeArchived;
      } else {
        this.includeArchived = queryString.includeArchived === "true";
      }

      this.setKeyword(queryString.keyword);

      this.setSelectedRNA(this.road_naming_authority_id);
    },
    _fetch: waitFor("loading Proposals", async function() {
      this.fetchError = false;
      await this.getProposalsList(this.proposalSearch);
    }),
    async fetchProposals() {
      if (this.isSearching) {
        return;
      }
      try {
        this.isSearching = true;
        await this._fetch();
        await this.$router.push({ query: this.roadSpecificQueryString() });
      } catch (e) {
        if (e.name === "NavigationDuplicated") {
          return;
        }
        if (e.response.status >= 500) {
          //eslint-disable-next-line no-console
          console.error(`Error fetching proposals: ${e.response.data.message}`);
          this.fetchError = true;
        }
      } finally {
        this.isSearching = false;
      }
    },
    //Fixes payload for proposalAdd page
    roadClassificationHelper(type) {
      if (type === "Public") {
        return "Road Name Public";
      } else if (type === "Private") {
        return "Road Name Non-Dedicated";
      } else {
        return "Road Name Pre-Approval";
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    roadSpecificQueryString() {
      return {
        keyword: this.proposalSearch.keyword,
        page: this.page,
        includeArchived: this.includeArchived,
        road_naming_authority_id: this.road_naming_authority_id,
        limit: this.limit
      };
    }
  },
  watch: {
    $route(to) {
      this.qsToProp(to.query);
    },
    rnaList(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (!newVal.length) return;
      this.setSelectedRNA(this.road_naming_authority_id);
    },
    async includeArchived(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        await this.fetchProposals();
      }
    },
    async "proposalSearch.keyword"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        await this.fetchProposals();
      }
    },
    async page(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.fetchProposals();
      }
    },
    async selectedRNA(newVal, oldVal) {
      if (Object.is(newVal, oldVal)) return;

      if (newVal.identifier !== this.road_naming_authority_id) {
        this.page = 1;
        this.road_naming_authority_id = newVal ? newVal.identifier : null;
      }

      await this.fetchProposals();
    },
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchProposals();
      }
    }
  },
  computed: {
    roadnameProposals() {
      return this.$store.getters.allProposalsListState;
    },
    includeArchived: {
      get() {
        return this.proposalSearch.includeArchived;
      },
      set(value) {
        this.setIncludeArchived(value);
      }
    },
    page: {
      get() {
        return this.proposalSearch.page;
      },
      set(value) {
        this.setProposalSearchPage(value);
      }
    },
    limit: {
      get() {
        return this.proposalSearch.limit;
      },
      set(value) {
        this.setProposalSearchLimit(value);
      }
    },
    road_naming_authority_id: {
      get() {
        return this.proposalSearch.road_naming_authority_id;
      },
      set(value) {
        this.setProposalSearchRoadNamingAuthority(value);
      }
    },
    ...mapGetters([
      "totalProposalsForQuery",
      "showLoginModal",
      "proposalSearch"
    ]),
    ...mapUserGetters(["user"])
  },
  data() {
    return {
      isSearching: false,
      fetchError: false
    };
  },
  beforeRouteEnter(to, from, next){
    next(async vm => {
      if(from.name !== "roadNameProposalBucket"){
        await vm.resetProposalSearch();
      }
      vm.qsToProp(to.query);

      if (!vm.user.isAdmin && !vm.user.isASP) {
        // if the user is not an admin user or asp user and no filters are selected, force the users rna
        if (
          !vm.road_naming_authority_id ||
          vm.road_naming_authority_id !== vm.user.road_naming_authority_id
        ) {
          vm.road_naming_authority_id = vm.user.road_naming_authority_id;
        }
      }

      await vm.fetchProposals();
    });
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/Images/BG_Roads-white.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom;
}

.road-img {
  height: 50px;
}
</style>
