"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

export const STATUS_APPROVED = "approved";
export const STATUS_PENDING = "pending";
export const STATUS_SUSPENDED = "suspended";

// push new user to database
export function saveNewUseFromCogntioToPg(newUserData, dateParsedFromClient) {
  const myInit = {
    body: { newUserData, dateParsedFromClient }
  };
  return API.post(apiName, "/users", myInit);
}

export async function searchUsers(queryStringParameters) {
  const result = await API.get(apiName, "/users", { queryStringParameters });

  return result;
}

export async function getUserAccessReviewData(queryStringParameters) {
  const result = await API.get(apiName, "/users/get-user-access-review-data", { queryStringParameters });

  return result;
}

export async function exportUsers(queryStringParameters) {
  const result = await API.get(apiName, "/users/export", {
    queryStringParameters
  });

  return result;
}

export function getUser(email) {
  return API.get(apiName, encodeURI(`/users/${email}`));
}

export function updateUser(userDetails) {
  const details = {
    id: userDetails.email,
    body: userDetails
  };
  return API.put(
    apiName,
    `/users/${encodeURIComponent(userDetails.email)}`,
    details
  );
}

export function logFailedLogin(email) {
  return API.post(apiName, `/failed/${encodeURIComponent(email)}`, {
    body: {}
  });
}

export function logout() {
  return API.put(apiName, encodeURI("/users/logout"));
}

// delete user
export function deleteUser(deleteUser) {
  const myInit = {
    body: { deleteUser }
  };
  return API.post(apiName, "/users/delete", myInit);
}