var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    { attrs: { cardTitle: "Document", eventDate: _vm.entryData.createdAt } },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v(
            "The following " +
              _vm._s(_vm.entryData.docType || "document") +
              " was uploaded:"
          ),
        ]),
        _c("a", { attrs: { href: _vm.entryData.url } }, [
          _vm._v(" " + _vm._s(_vm.entryData.filename) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }