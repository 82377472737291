<template>
  <div class="flex-column bg-white text-black rounded-lg p-5 mt-4 mb-2">
    <div class="flex justify-between pb-0 border-b-2">
      <h2>Acting Surveyor General Details</h2>
    </div>
    <ShowLoading isLoading="fetching acting surveyor">
      <template slot="component">
        <NetworkError v-if="fetchError" />
        <form class="w-full justify-center flex flex-col" novalidate>
          <div class="py-3 flex flex-wrap mt-4">
            <div class="flex flex-grow flex-wrap">
              <div class="md:w-1/2 pr-3 justify-center md:mb-0">
                <label class="block font-bold mb-2" for="grid-password">
                  <span class="redStar">*</span> Start Date
                </label>
                <datepicker
                  class="appearance-none block w-full grey-bg rounded mb-3 leading-tight text-normal text-grey-text input-form rounded-lg mb-4"
                  v-model="actingDateStart"
                  name="actingStartDate"
                  placeholder="Start Date"
                  :format="customFormatter"
                  v-validate="{
                    date_format: 'yyyy-MM-dd',
                    required: true
                  }"
                  data-vv-as="Acting Surveyor General start date"
                  input-class="w-full input-area"
                  calendar-class="mb-4"
                  v-wait:disabled="'saving acting surveyor'"
                >
                </datepicker>
                <FieldErrorMessage :message="errors.first('actingDateStart')" />
              </div>
              <div class="md:w-1/2 justify-center md:mb-0">
                <label class="block font-bold mb-2" for="grid-password">
                  <span class="redStar">*</span> End Date
                </label>
                <datepicker
                  class="appearance-none block w-full grey-bg rounded mb-3 leading-tight text-normal text-grey-text input-form rounded-lg mb-4 "
                  v-model="actingDateEnd"
                  name="actingDateEnd"
                  placeholder="End Date"
                  :format="customFormatter"
                  v-validate="{
                    date_format: 'yyyy-MM-dd',
                    required: true
                  }"
                  data-vv-as="Acting Surveyor General end date"
                  input-class="w-full input-area"
                  calendar-class="mb-4"
                  :open-date="
                    actingSurveyorGeneral.dateStart
                      ? actingSurveyorGeneral.dateStart.toDate()
                      : new Date()
                  "
                  v-wait:disabled="'saving acting surveyor'"
                />
                <FieldErrorMessage :message="errors.first('actingDateEnd')" />
              </div>
            </div>
            <div class="md:w-1/2 pr-3 justify-center md:mb-0">
              <label class="block font-bold mb-2" for="grid-acting-first-name">
                <span class="redStar">*</span> First Name
              </label>
              <input
                class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
                id="grid-acting-first-name"
                type="text"
                placeholder="First Name"
                v-model="actingSurveyorGeneral.firstName"
                name="ASG First Name"
                v-wait:disabled="'saving acting surveyor'"
                v-validate="'required'"
              />
            </div>
            <div class="md:w-1/2 m justify-center md:mb-0">
              <label class="block font-bold mb-2" for="grid-acting-last-name">
                <span class="redStar">*</span> Last Name
              </label>
              <input
                class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
                id="grid-acting-last-name"
                type="text"
                placeholder="Last Name"
                v-model="actingSurveyorGeneral.lastName"
                name="ASG Last Name"
                v-wait:disabled="'saving acting surveyor'"
                v-validate="'required'"
              />
            </div>
          </div>
          <div class="justify-center">
            <label class="block font-bold mb-2" for="grid-acting-password">
              <span class="redStar">*</span> Email Address
            </label>
            <input
              class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
              id="grid-acting-password"
              type="text"
              placeholder="Email"
              v-model="actingSurveyorGeneral.email"
              name="Email"
              v-wait:disabled="'saving acting surveyor'"
              v-validate="'email|required'"
            />
            <FieldErrorMessage
              :message="errors.first('Email', 'actingSurveyorGeneral')"
            />
          </div>

          <div
            class="flex items-center absolute"
            v-if="errors.any('actingSurveyorGeneral')"
          >
            <errorIcon />
            <div class="text-red-error text-normal-bold ml-2">
              Acting surveyor general info cannot be left partial
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <button
              @click="$emit('close')"
              class="self-center  button-red-hollow flex-no-grow"
              type="button"
            >
              Cancel
            </button>
            <ButtonWithSpinner
              @click="removeActingSurveyorGeneral"
              class="self-center button-red-hollow flex-no-grow"
              :isSpinning="$wait.is('deleteing acting surveyor')"
              v-wait:disabled="'deleteing acting surveyor'"
            >
              Remove
            </ButtonWithSpinner>
            <ButtonWithSpinner
              class="saveButton self-center button-blue"
              @click="save"
              type="button"
              :disabled="isSaveDisabled"
              :isSpinning="$wait.is('saving acting surveyor')"
              >Save Changes
            </ButtonWithSpinner>
          </div>
        </form>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { waitFor } from "vue-wait";
import moment from "moment-timezone";
import {
  getActingSurveyorGeneral,
  deleteActingSurveyorGeneral,
  saveActingSurveyorGeneral
} from "../../helpers/surveyorGeneralCruds";

import validatorFieldBagMixin from "@/mixins/validator-field-bag-mixin";

export default {
  name: "ActingSurveyorGeneralModal",
  components: {
    Datepicker
  },
  mixins: [validatorFieldBagMixin],
  data() {
    return {
      actingSurveyorGeneral: {},
      fetchError: false
    };
  },
  computed: {
    actingDateStart: {
      get() {
        if (!this.actingSurveyorGeneral.dateStart) return null;
        return this.actingSurveyorGeneral.dateStart.format("YYYY-MM-DD");
      },
      set(value) {
        if (
          this.actingSurveyorGeneral.dateEnd &&
          this.actingSurveyorGeneral.dateEnd.isBefore(value)
        ) {
          this.errors.add({
            field: "actingStartDate",
            msg: "Start Date must be before end date"
          });
          return;
        }
        this.errors.remove("actingStartDate");
        this.actingSurveyorGeneral.dateStart = moment(value);
      }
    },
    actingDateEnd: {
      get() {
        if (!this.actingSurveyorGeneral.dateEnd) return null;
        return this.actingSurveyorGeneral.dateEnd.format("YYYY-MM-DD");
      },
      set(value) {
        if (
          this.actingSurveyorGeneral.dateStart &&
          this.actingSurveyorGeneral.dateStart.isAfter(value)
        ) {
          this.errors.add({
            field: "actingDateEnd",
            msg: "End Date must be after start date"
          });
          return;
        }

        this.errors.remove("actingDateEnd");

        this.actingSurveyorGeneral.dateEnd = moment(value);
      }
    },
    isSaveDisabled() {
      return !this.isFormValid || !this.isFormDirty || this.$wait.any;
    }
  },
  methods: {
    fetch: waitFor("fetching acting surveyor", async function() {
      try {
        this.fetchError = false;
        const actingSurveyorGeneral = await getActingSurveyorGeneral();
        this.actingSurveyorGeneral = {
          ...actingSurveyorGeneral,
          dateStart: moment(actingSurveyorGeneral.dateStart),
          dateEnd: moment(actingSurveyorGeneral.dateEnd)
        };
      } catch (e) {
        console.error(e);
        this.fetchError = true;
      }
    }),
    save: waitFor("saving acting surveyor", async function() {
      try {
        this.fetchError = false;
        await saveActingSurveyorGeneral(this.actingSurveyorGeneral);
        this.$emit("close");
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Save Error",
          text: e.response.data.message
        });
      }
    }),
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    removeActingSurveyorGeneral: waitFor(
      "deleteing acting surveyor",
      async function() {
        try {
          await deleteActingSurveyorGeneral();
          this.$emit("close");
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          this.$notify({
            group: "toast",
            type: "error",
            title: "Update Error",
            text: "Error removing the active surveyor general info"
          });
        }
      }
    )
  },
  async mounted() {
    await this.fetch();
  }
};
</script>

<style></style>
