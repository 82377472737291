<template>
  <div class="base-container">
    <section class="pt-1 justify-center w-full block">
      <div class="flex justify-between"></div>
      <h2 class="text-black ml-4 self-center text-title">
        Submit Proposal Clarification Request
      </h2>
      <div
        class="max-w-6xl bg-white text-black rounded-lg pl-8 pt-3 pb-3 mt-4 justify-center flex flex-wrap"
      >
        <div class="w-full justify-center flex">
          <img src="@/assets/Images/Icon_Gazettal.png" class=" h-52 my-4" />
        </div>
        <p v-if="road" class="text-title">
          Your proposal clarification for {{ road.roadname }}
          {{ road.road_type }} has been submitted.
        </p>
      </div>

      <div class="flex pt-4 text-center pb-10">
        <router-link
          v-if="road"
          :to="{ name: 'proposals' }"
          class="w-1/3 button-blue-hollow"
          >Go to Proposals Dashboard</router-link
        >
        <button
          @click="
            $router.push({
              name: 'roadNameProposalBucket',
              params: { proposalId: road.proposal_id }
            })
          "
          class="ml-auto w-1/3 button-blue"
        >
          Return to Road Name Proposal
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "GNBSeekingClarificationSuccess",
  components: {},
  props: {
    road: { type: Object },
    placename: { type: Object }
  },
  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped></style>
