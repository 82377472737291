var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
      _c("div", { staticClass: "flex justify-between" }),
      _c("h2", { staticClass: "text-black ml-4 self-center text-title" }, [
        _vm._v(" Submit Proposal Clarification Request "),
      ]),
      _c(
        "div",
        {
          staticClass:
            "max-w-6xl bg-white text-black rounded-lg pl-8 pt-3 pb-3 mt-4 flex flex-col justify-center text-center",
        },
        [
          _vm._m(0),
          !_vm.invalidStatusRedirect
            ? _c("p", { staticClass: "text-title pt-4 text-center pb-10" }, [
                _vm._v(
                  " Your proposal clarification for " +
                    _vm._s(_vm.proposalName) +
                    " has been submitted. "
                ),
              ])
            : _vm._e(),
          _vm.invalidStatusRedirect
            ? _c("p", { staticClass: "text-title pt-4 text-center pb-10" }, [
                _vm._v(" No clarification is currently pending for "),
                _c("b", [_vm._v(_vm._s(_vm.proposalName))]),
                _vm._v(". "),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex pt-4 text-center pb-10" },
        [
          _c(
            "router-link",
            {
              staticClass: "ml-auto w-1/3 button-blue",
              attrs: { to: { name: "placeNameProposals" } },
            },
            [_vm._v("Return to Manage Proposals")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-title" }, [
      _c("img", {
        staticClass: "h-52 my-4",
        attrs: { src: require("../../../../assets/Images/Icon_Gazettal.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }