var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-white rounded-lg flex items-center text-sub-title mt-5"},[_c('div',{staticClass:"items-center flex w-1/4 pl-4",attrs:{"data-cypress":"proposed-road-name"}},[_c('proposalIcon',{attrs:{"label":_vm.road.roadname_classification,"classNames":'w-12 h-12 text-base mr-2'}}),_vm._v(" "+_vm._s(_vm.road.roadname)+" "+_vm._s(_vm.road.road_type)+" ")],1),_c('div',{staticClass:"dividing-line-v items-baseline flex w-1/2 pl-4 border-r"},[_c('div',{staticClass:"pl-4 mr-4 w-1/2"},[_c('p',{staticClass:"text-xs font-ibold text-grey-text mb-2 pt-5"},[_vm._v(" Road Location ")]),_c('div',{staticClass:"pb-3"},_vm._l((_vm.road.suburb_name),function(sub,index){return _c('p',{key:index,staticClass:"text-black text-sub-normal pb-1"},[_vm._v(" "+_vm._s(sub)+" ")])}),0)]),_c('div',{staticClass:"w-1/2 mb-5"},[_c('p',{staticClass:"text-xs font-semibold text-grey-text mb-2"},[_vm._v(" Road Name Validity ")]),(Object.keys(_vm.road.geometry).length > 0 && _vm.road.distance)?_c('p',{staticClass:"text-black text-sub-normal-bold py-2",class:_vm.road.validity},[(_vm.evaluation === null)?_c('Loading',{staticClass:"flex-0",staticStyle:{"height":"15px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.evaluation === null ? "" : _vm.evaluation + " Potential issues found")+" ")],1):_vm._e(),(Object.keys(_vm.road.geometry).length < 1 || !_vm.road.distance)?_c('p',{staticClass:"text-red text-sub-normal-bold py-2",class:_vm.road.validity},[_vm._v(" Extent not drawn ")]):_vm._e()])]),_c('div',{staticClass:"pl-5 w-1/4 items-baseline justify-end flex"},[_c('div',{staticClass:"pl-4 mr-4 w-full"},[_c('p',{staticClass:"text-xs font-semibold text-grey-text w-full mb-2"},[_vm._v("Actions")]),_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"text-black text-normal font-semibold text-blue-button",attrs:{"to":_vm.road.identifier && _vm.road.name_status !== 'Drafted'
              ? {
                  name: 'roadNameProposalEdit',
                  params: { roadNameId: _vm.road.identifier }
                }
              : {
                  name: 'roadNameProposalEdit',
                  params: { roadNameId: _vm.index }
                },"data-cypress":"edit-road","disabled":_vm.$wait.is('loading')}},[_vm._v("Edit Road")]),_c('a',{staticClass:"ml-4 text-normal font-semibold text-red-button cursor-pointer",attrs:{"data-cypress":"remove-road","disabled":_vm.$wait.is('loading')},on:{"click":function($event){return _vm.removeRoad(_vm.index)}}},[_vm._v("Remove Road")])],1)])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }