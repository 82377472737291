<template>
  <div
    class="w-full mx-auto pt-6 px-6 pb-14 mb-8 flex-col flex"
    data-cypress="remove-box"
  >
    <a @click="hide()" class="cursor-pointer ml-auto">
      <errorIcon classNames="w-4" />
    </a>
    <div
      class="flex items-center content-center justify-center mt-8 mb-6 px-10"
    >
      <h2 class="text-black">Delete Roadname Type</h2>
    </div>
    <h3 class="w-full flex justify-center text-black mb-6 px-10">
      {{ type.label }}
    </h3>
    <div class="flex text-black mb-8 text-normal px-10">
      Are you sure you want to delete the road name type? You will not be able
      to reverse this operation once it is complete.
    </div>
    <div class="flex justify-center w-full px-10">
      <div
        @click="hide()"
        class="button-blue-hollow mr-4 w-2/5 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
        data-cypress="no-keep"
      >
        No, keep the road type
      </div>
      <div
        @click="confirmDelete()"
        class="button-red ml-4 w-2/5 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
        data-cypress="yes-delete"
      >
        Yes, delete the road type
      </div>
    </div>
  </div>
</template>

<script>
import errorIcon from "../elements/errorIcon";
import * as rnpCruds from "../../helpers/rnpCRUD";

export default {
  /**
   * The name of the component
   */
  name: "delete-roadname-type",

  /**
   * Pass in props
   */
  props: ["type"],

  /**
   * instance child components
   */
  components: { errorIcon },

  /**
   * local instance methods
   */
  methods: {
    /**
     * hide the modal
     */
    hide() {
      this.$emit("close");
    },

    /**
     * The user confirmed the delete of the road name type
     */
    async confirmDelete() {
      rnpCruds
        .deleteRoadnameType(this.type)
        .then(res => {
          this.$emit("delete-complete", { types: res.types });
          return this.hide();
        })
        .catch(err => {
          if (err.response.data.message) {
            this.reportError(err.response.data.message);
          }
        });
    },

    /**
     * Report a toast error in one of the processes
     */
    reportError(message) {
      this.$notify({
        group: "toast",
        type: "error",
        title: "An error occurred",
        text: message
      });
    }
  }
};
</script>

<style>
.button-red {
  line-height: 1rem;
}
</style>
