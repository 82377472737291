var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full h-screen overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c(
        "div",
        { staticClass: "bg-white my-3 rounded" },
        [
          _c("showLoading", {
            attrs: { isLoading: "fetchingSubmittedProposalWithComment" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    _vm.proposal
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black text-center rounded-lg p-14",
                          },
                          [
                            _c(
                              "h3",
                              { staticClass: "pb-3 mb-4 w-full text-title" },
                              [
                                _vm._v(
                                  " The comment for " +
                                    _vm._s(_vm.proposal.geographical_name) +
                                    " has been submitted "
                                ),
                              ]
                            ),
                            _c("p", [
                              _vm._v(
                                " These comments will be considered in the development of the proposal. Once finalised, the outcome of the proposal may be viewed on the GNB website. "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex justify-center mt-8 mb-8" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "button-blue-hollow mr-3",
                                    attrs: { disabled: false },
                                    on: {
                                      click: function ($event) {
                                        return _vm.returnToProposals()
                                      },
                                    },
                                  },
                                  [_vm._v("Return to Place Name Proposals ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }