<template>
  <div id="app" class="min-h-screen flex flex-grow flex-col">
    <router-view />

    <notifications group="toast" position="bottom right" />
    <MaintenanceBanner 
      slot="body"
      v-if="bannerData.displayBanner"
      :bannerData="bannerData"
    />
    <notifications
      group="newContentNotification"
      position="bottom center"
      width="100%"
    >
      <NewContentNotification slot="body" />
    </notifications>
  </div>  
</template>

<script>
import { mapGetters } from "vuex";
import MaintenanceBanner from "../src/components/elements/maintenenceBanner"
import pnpCrud from "../src/helpers/pnpCRUD";

export default {
  computed: {
    ...mapGetters(["isUpdateReady"])
  },
  watch: {
    isUpdateReady(value) {
      if (value) {
        this.$notify({
          duration: -1,
          group: "newContentNotification"
        });
      }
    }
  },
  components: {
    MaintenanceBanner
  },
  data() {
    return {
      bannerData : {}
    }
  },
  async mounted() {
    if (this.isUpdateReady) {
      this.$notify({
        duration: -1,
        group: "newContentNotification"
      });
    }

    // Gets banner data
    this.bannerData = { ...await pnpCrud.getBannerContent()};
  }
};
</script>

<style>
#app {
  font-family: "Gotham", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.break-all {
  word-break: break-all;
}
/* Global styles */
.dropdown-toggle:hover {
  background: none !important;
}

/* .text-title {
  @apply font-bold;
  line-height: 1.38;
  font-size: 26px;
} */
.text-sub-title {
  @apply font-medium;
  font-size: 20px;
  line-height: 1.6;
}
.text-normal {
  @apply font-normal;
  line-height: 1.5;
  font-size: 16px;
}
.text-normal-bold {
  @apply font-bold;
  line-height: 1.5;
  font-size: 16px;
}

.text-normal-large {
  @apply text-base;
  @apply font-normal;
  @apply leading-tight;
}
.text-sub-normal {
  @apply text-xs;
  @apply font-normal;
  @apply leading-tight;
}
.text-sub-normal-bold {
  @apply text-xs;
  @apply font-semibold;
  @apply leading-tight;
}
.text-title-large {
  @apply text-2xl font-bold leading-tight;
}
/* .button-hollow-red {
  @apply text-center;
  @apply p-4;
  @apply pl-8;
  @apply pr-8;
  @apply rounded;
  @apply border-2;
  @apply border-solid;
  @apply border-red-button;
  @apply text-red-button;
  @apply text-normal-bold;
  @apply no-underline;
  @apply cursor-pointer;
  @apply text-center;
} */

.text-red-button:hover {
  @apply text-primary-red-hover;
  cursor: pointer;
}
.button-blue {
  @apply p-4 pl-10 pr-10 border-2 border-solid border-blue-button bg-blue-button text-white font-bold rounded no-underline cursor-pointer text-center leading-none;
  transition-duration: 0.2s;
}
.button-blue:not([disabled]):hover {
  @apply text-white bg-primary-blue-hover border-primary-blue-hover;
}
.button-blue:disabled {
  @apply text-white bg-primary-blue-disabled border-primary-blue-disabled;
}
.button-blue-hollow {
  @apply p-4 pl-10 pr-10 border-2 border-solid border-blue-button text-blue-button font-bold rounded no-underline cursor-pointer text-center leading-none;
  transition-duration: 0.2s;
}
.button-blue-hollow:not([disabled]):hover {
  @apply text-white bg-primary-blue-hover border-primary-blue-hover;
}
.button-red-hollow {
  @apply p-4 pl-10 pr-10 border-2 border-solid border-red-button text-red-button font-bold rounded no-underline cursor-pointer text-center leading-none;
  transition-duration: 0.2s;
}
.button-red-hollow:not([disabled]):hover {
  @apply text-white bg-primary-red-hover border-primary-red-hover;
}
.button-red {
  @apply p-4 pl-10 pr-10 bg-red-button text-white font-bold rounded no-underline cursor-pointer text-center leading-none;
  transition-duration: 0.2s;
}
.button-red:not([disabled]):hover {
  @apply text-white bg-primary-red-hover border-primary-red-hover;
}

.button-green {
  @apply p-4 pl-10 pr-10 border-2 border-solid border-primary-green bg-primary-green text-white font-bold rounded no-underline cursor-pointer text-center leading-none;
  transition-duration: 0.2s;
}

.button-green:not([disabled]):hover {
  @apply text-white bg-primary-green-hover border-primary-green-hover;
}

.button[disabled] {
  opacity: 0.7;
  cursor: default;
}
.dividing-line-h {
  @apply border-b-2 border-grey-border border-solid w-full;
}
.dividing-line-h-1 {
  @apply border-b border-grey-border border-solid w-full;
}
.dividing-line-v {
  @apply border-l border-grey-border border-solid;
}
.dividing-line-v-2 {
  @apply border-l-2 border-grey-border border-solid;
}
.base-container {
  @apply w-5/6 pt-12 mx-auto;
  max-width: 1200px;
}

/* Font settings */

h1 {
  @apply font-bold text-black text-size-44;
}

.text-title {
  @apply mt-4;
}

h2 {
  @apply font-bold text-black text-size-26 leading-snug;
}

h3 {
  @apply font-medium text-black text-size-20 leading-relaxed;
}
h4 {
  @apply font-medium text-black text-size-18 leading-less;
}

h5 {
  @apply font-medium text-black text-size-16 leading-normal;
}

body {
  @apply font-normal text-black text-size-16 leading-normal;
}

.text-normal-bold {
  @apply font-bold text-size-16 leading-normal;
}

.text-label {
  @apply font-normal text-grey-text text-size-14;
}

.text-error {
  @apply font-medium text-error-red text-size-14 mt-1;
}

a {
  @apply font-bold no-underline;
}

a:visited {
  @apply no-underline;
}

a:hover {
  @apply text-primary-blue-hover;
}

/* Input fields */

.input-area {
  @apply appearance-none rounded py-4 px-4 text-black bg-grey-input opacity-100 font-normal;
}
.input-area::placeholder {
  @apply text-grey-text opacity-100;
}
.input-area-appear {
  @apply rounded py-4 px-4 opacity-100 bg-grey-input;
}
.input-area:focus {
  @apply outline-none shadow-outline;
}
.input-area:disabled {
  @apply appearance-none rounded py-4 px-4;
  background-color: #d4d8da;
}
.input-area-appear:disabled {
  @apply rounded py-4 px-4;
  background-color: #d4d8da;
}

.input-area-alt {
  @apply appearance-none rounded py-4 px-4 text-black bg-white opacity-100 font-normal;
}

select {
  @apply block appearance-none bg-white py-4 px-4 rounded opacity-100;
  background-image: url("assets/Images/chevron-down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 1em auto, 100%;
}

select::placeholder {
  @apply text-grey-text opacity-100;
}

html body p {
  height: auto;
}

p {
  @apply text-normal;
}

.fa-arrow-left:hover {
  color: #f11b45;
}
/* pagination styles from bootstrap  */
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.fix-date-picker {
  position: fixed !important;
}

.multiselect__select {
  background: none !important;
}

/* forms */
</style>
