var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "flex main" }, [
    _c(
      "span",
      {
        staticClass: "arrow-icon w-6 h-6 text-center text-red-600 self-center",
      },
      [
        _c(
          "svg",
          {
            staticClass: "h-7",
            attrs: {
              "aria-hidden": "true",
              focusable: "false",
              "data-prefix": "fas",
              "data-icon": "arrow-left",
              role: "img",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 448 512",
            },
          },
          [
            _c("path", {
              attrs: {
                fill: "#C60C30",
                d: "M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z",
              },
            }),
          ]
        ),
      ]
    ),
    _vm.size === "h1"
      ? _c("h1", { staticClass: "ml-4 flex justify-center items-center" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
    _vm.size === "h2"
      ? _c("h2", { staticClass: "ml-4 flex justify-center items-center" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
    _vm.size === "h3"
      ? _c("h3", { staticClass: "ml-4 flex justify-center items-center" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
    _vm.size === "h4"
      ? _c("h4", { staticClass: "ml-4 flex justify-center items-center" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }