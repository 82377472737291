var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c(
      "section",
      { staticClass: "justify-center w-full block" },
      [
        _vm.proposalId !== "bulk"
          ? _c(
              "a",
              {
                staticClass: "flex justify-between",
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "gazettalNotice",
                      params: { road: _vm.roads[0] },
                    })
                  },
                },
              },
              [
                _c("BackArrowWithTitle", {
                  attrs: { title: "Preview Gazettal Notice" },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("showLoading", {
          attrs: { isLoading: "fetching rna details" },
          scopedSlots: _vm._u([
            {
              key: "component",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full bg-white text-black rounded-lg pl-8 pr-8 pt-24 pb-24 mt-4 justify-center flex",
                    },
                    [
                      _vm.multipleRNAError
                        ? _c("div", { staticClass: "w-4/5 mx-auto" }, [
                            _c(
                              "p",
                              { staticClass: "text-center pb-3" },
                              [
                                _c("font-awesome-icon", {
                                  staticClass:
                                    "flex-0 text-red text-3xl inline",
                                  attrs: { icon: "exclamation-circle" },
                                }),
                              ],
                              1
                            ),
                            _c("p", [
                              _vm._v(
                                " You can only gazette multiple roads if they are proposed by the same Road Naming Authority "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("gazette-mock", {
                        attrs: {
                          roads: _vm.roads,
                          rna: _vm.rna,
                          user: _vm.user,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-4 text-center pb-10" },
                    [
                      _vm.proposalId !== "bulk"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button-blue-hollow flex-no-grow",
                              attrs: {
                                to: {
                                  name: "gazettalNotice",
                                  params: { road: _vm.roads[0] },
                                },
                              },
                            },
                            [_vm._v("Return to draft")]
                          )
                        : _vm._e(),
                      _vm.proposalId === "bulk"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button-blue-hollow flex-no-grow",
                              attrs: {
                                to: { name: "roadNameProposalBulkGazette" },
                              },
                            },
                            [_vm._v("Return to bulk gazettte")]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "flex-grow" }),
                      _c(
                        "ButtonWithSpinner",
                        {
                          staticClass:
                            "button-blue relative flex flex-no-grow justify-center whitespace-no-wrap font-semibold",
                          attrs: {
                            "data-cypress": "submit-gazettal-notice-button",
                            disabled:
                              _vm.$wait.is("saving gazette") ||
                              _vm.multipleRNAError,
                            isSpinning: _vm.$wait.is("saving gazette"),
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("Create Gazettal Notice")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }