var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.transformedEntryData
        ? _c(
            "entry",
            {
              attrs: {
                cardTitle: "Board Outcome",
                eventDate: _vm.transformedEntryData.createdAt,
              },
            },
            [
              _c("div", [
                _vm.entryData.action === "insert"
                  ? _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "At the board meeting held on " +
                          _vm._s(_vm.transformedEntryData.meetingDate) +
                          ", the following decision was made:"
                      ),
                    ])
                  : _vm._e(),
                _vm.entryData.action === "update"
                  ? _c("p", { staticClass: "mb-2" }, [
                      _vm._v("The board decision was modified to:"),
                    ])
                  : _vm._e(),
                _c("strong", [
                  _vm._v(_vm._s(_vm.transformedEntryData.summary)),
                ]),
                _vm.entryData.action === "update"
                  ? _c("div", { staticClass: "pt-2" }, [
                      _c("div", [
                        _vm._v(
                          "Meeting Date: " +
                            _vm._s(_vm.transformedEntryData.meetingDate)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }