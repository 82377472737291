<template>
  <div class="pt-10 flex flex-grow items-center justify-start flex-row">
    <div class="flex flex-col flex-auto flex-grow items-center justify-center">
      <Loading class="flex-none"></Loading>
      <span class="pt-2 text-black">Loading...</span>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-simple-spinner'
export default {
  components: {
    Loading
  }
};
</script>
