import Vue from "vue";
import Router from "vue-router";
import store from "./vuex/store";

// import /views into the router
import Authentication from "./views/Authentication.vue";
import Dashboard from "./views/Dashboard.vue";
import Register from "./views/Register.vue";
import Reset from "./views/Reset.vue";
import Fork from "./views/Fork.vue";
import Public from "./views/Public.vue";

// parent route container
import ParentRoute from "./views/ParentRoute.vue";

// route not found / no access page
import NotFoundPage from "./views/NotFoundPage.vue";

// dashboard routes to components
import Administer from "./components/userprofiles/administerUsers.vue";

// userprofiles routes
import editSurveyorInfo from "./components/userprofiles/edit-surveyor-info.vue";
import userProfile from "./components/userprofiles/userProfile.vue";
import editUserProfile from "./components/userprofiles/editUserProfile.vue";

// road name proposals
import proposalBucket from "./components/proposals/road/proposal-bucket.vue";
import roadNameProposals from "./components/proposals/road/proposals.vue";
import submitAppeal from "./components/proposals/road/appeal-submit.vue";
import proposalAppeal from "./components/proposals/road/proposal-appeal.vue";
import clarifyProposal from "./components/proposals/road/clarify-proposal.vue";
import clarifySubmit from "./components/proposals/road/clarify-submit.vue";
import editRoadNameProposal from "./components/proposals/road/roadname-proposal-edit.vue";
import roadNameProposalBulkAdd from "./components/proposals/road/roadname-proposal-bulk-add.vue";
import proposalSubmitted from "./components/proposals/road/proposal-submitted.vue";
import RNPSeekingClarification from "./components/proposals/road/seek-clarification.vue";
import RNPSeekingClarificationSuccess from "./components/proposals/road/seek-clarification-success.vue";
import RNPJiraClarificationRedirect from "./components/proposals/road/seek-clarification-redirect.vue";
import roadNameProposalAuditLog from "./components/proposals/road/audit-trail/main";

// place name proposals
import placeNameProposals from "./components/proposals/place/proposals.vue";
import placeNameProposal from "./components/proposals/place/proposal-form/proposal.vue";
import clarifyPnp from "./components/proposals/place/clarification/clarifyPnp.vue";
import clarifyPnpSubmit from "./components/proposals/place/clarification/clarifyPnpSubmit.vue";
import pnpSubmitted from "./components/proposals/place/pnpSubmitted.vue";
import placeNameProposalOfficialMap from "./components/proposals/place/officialMap.vue";
import clarifyAdvertPnp from "./components/proposals/place/clarification/clarifyAdvertPnp.vue";
import clarifyAdvertPnpSubmit from "./components/proposals/place/clarification/clarifyAdvertPnpSubmit.vue";
import placeNameProposalEndorsement from "./components/proposals/place/endorsement/add";
import placeNameEndorsementSubmitted from "./components/proposals/place/endorsement/submitted";
import placeNamePublicEndorsementSubmitted from "./components/proposals/place/endorsement/publicSubmitted";
import PNPSeekingClarification from "./components/proposals/place/clarification/seekClarification.vue";
import PNPSeekingClarificationSuccess from "./components/proposals/place/clarification/seekClarificationSuccess.vue";
import PNPJiraClarificationRedirect from "./components/proposals/place/clarification/seekClarificationRedirect.vue";
import PNPAuditTrail from "./components/proposals/place/audit-trail/main.vue";

// public proposal routes
import clarificationSubmitPublic from "./components/proposals/place/clarification/clarificationSubmitPublic.vue";
import clarifyAdvertPnpSubmitPublic from "./components/proposals/place/clarification/clarifyAdvertPnpSubmitPublic.vue";

import suburbSearch from "./components/suburbs/suburbSearch.vue";

// PNP PUBLIC COMMENTS
import currentPlaceNameProposals from "./components/proposals/place/public-comments/currentPlaceNameProposals.vue";
import currentPlaceNameProposal from "./components/proposals/place/public-comments/currentPlaceNameProposal.vue";
import PlaceNameProposalPublicCommentStart from "./components/proposals/place/public-comments/commentStart.vue";
import PlaceNameProposalPublicCommentForm from "./components/proposals/place/public-comments/commentForm.vue";
import PlaceNameProposalPublicCommentSubmitted from "./components/proposals/place/public-comments/commentSubmitted.vue";

// PNP PUBLIC COMMENT VIA GNB
import placeNameProposalAddPublicComment from "./components/proposals/place/public-comments/internal/commentFormInternal.vue";
import placeNameProposalAddPublicCommentSubmitted from "./components/proposals/place/public-comments/internal/commentFormInternalSubmitted.vue";

// PNP response to comments
import pnpProposalComments from "./components/proposals/place/public-comments/response/proposalComments.vue";

// public PNP routes
import placeNameProposalPublic from "./components/proposals/place/proposal-form/proposalPublic.vue";
import proposalPublicSubmitted from "./components/proposals/place/proposal-form/proposalPublicSubmitted.vue";

// objection routes
import objectionOverturn from "./views/objection/overturn.vue";
import objectionUphold from "./views/objection/uphold.vue";
import afterSubmit from "./views/objection/afterSubmit.vue";

// gazzetal routes
import gazettalNotice from "./components/gazettalPage/notice.vue";
import gazettalPreview from "./components/gazettalPage/preview.vue";
import gazettalSubmit from "./components/gazettalPage/submit.vue";
import gazettalOrder from "./components/gazettal/purchaseOrder.vue";
import roadAndPlaceNameRules from "./components/roadAndPlaceRules/roadAndPlaceNameRules.vue";
import roadNameProposalBulkGazette from "./components/gazettal/bulkGazette.vue";
import roadNameProposalBulkGazetteSubmit from "./components/gazettal/bulkSubmit.vue";
import erratumGazettalNotice from "./components/gazettalPage/erratum-notice.vue";
import erratumGazettalPreview from "./components/gazettalPage/erratum-preview.vue";
import unapprovedGazette from "./components/gazettal/unapproved-gazette.vue";
import reGazette from "./components/gazettal/re-gazette.vue";
import reGazettePreview from "./components/gazettal/re-gazette-preview";

// RNA routes
import rnasManage from "./components/RNAs/manage.vue";
import rnasAdd from "./components/RNAs/add.vue";
import rnasEdit from "./components/RNAs/edit.vue";
import rnasView from "./components/RNAs/view.vue";
import Parties from "./components/parties/parties.vue";

// organisation routes
import organisationsManage from "./components/organisation/manage.vue";
import organisationsAdd from "./components/organisation/add.vue";
import organisationsEdit from "./components/organisation/edit.vue";
import organisationsAddMembers from "./components/organisation/addMembers.vue";
import organisationsView from "./components/organisation/view.vue";
import organisationContainer from "./components/organisation/organisation-container.vue";

// Geonames
import geoNameEdit from "./components/geo-names/edit.vue";
import geoNameSearch from "./components/geo-names/search/index.vue";
import roadNameEvaluationPublic from "./components/proposals/road/evaluation-public/index.vue";
import geonameViewPublic from "./components/geo-names/viewPublic.vue";
import geoNameEditPublic from "./components/geo-names/editPublic.vue";

import parentRoutePublic from "./components/geo-names/public.vue";

// Roadnames
import roadNameTypes from "./components/road-name-types/List";

// Reports
import placeNameReport from "./components/reports/placeNames.vue";
import roadNameReport from "./components/reports/roadNames.vue";
import roadNameSearchReport from "./components/reports/roadNameSearch.vue";

import stringSimilarityChecker from "./components/stringSimilarity/Main.vue";

import lgaUpdateService from "./components/lga-update/lga-update.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: __BASE_URL__,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    {
      path: "/",
      redirect: "/fork"
    },
    {
      path: "/login",
      name: "Authentication",
      component: Authentication,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/string-similarity-checker",
      name: "StringSimilarityChecker",
      component: stringSimilarityChecker,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter(to, from, next) {
        store.dispatch("user/logout");
        next({
          name: "Authentication"
        });
      }
    },
    {
      path: "/register",
      name: "register",
      component: Register,

      meta: {
        isPublic: true
      }
    },
    {
      path: "/fork",
      name: "fork",
      component: Fork,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reset",
      name: "reset",
      component: Reset,
      props: route => {
        return {
          email: route.query.email || ""
        };
      },
      meta: {
        isPublic: true
      }
    },
    {
      path: "/reset/verify",
      name: "resetEnd",
      component: Reset,
      props: route => {
        return {
          email: route.query.email || "",
          recoveryCode: route.query.recoverycode || ""
        };
      },
      meta: {
        isPublic: true
      }
    },
    {
      path: "/not-found",
      name: "notFound",
      component: NotFoundPage,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/public",
      component: parentRoutePublic,
      meta: {
        isPublic: true
      },
      children: [
        {
          path: "place-names/:proposalId/endorse",
          component: placeNameProposalEndorsement,
          name: "endorsePlaceNameProposalPublic",
          meta: {
            isPublic: true
          },
          props: true
        },
        {
          path: "place-names/:proposalId/endorse/submitted",
          component: placeNamePublicEndorsementSubmitted,
          name: "endorsePlaceNameProposalPublicSubmitted",
          meta: {
            isPublic: true
          },
          props: true
        },
        {
          path: "",
          component: Public,
          name: "publicLanding"
        },
        {
          path: "geonames/search",
          component: geoNameSearch,
          name: "geoNameSearchPublic",
          meta: {
            isPublic: true
          }
        },
        {
          path: "geonames/:geonameId",
          component: geonameViewPublic,
          name: "geonameViewPublic"
        },
        {
          path: "geonames/:geonameId/edit",
          component: geoNameEditPublic,
          name: "geonameEditPublic"
        },
        {
          path: "suburbs/search",
          component: suburbSearch,
          name: "suburbSearch"
        },
        {
          path: "place-names/public-comments/:advertisementId",
          component: pnpProposalComments,
          name: "pnpProposalCommentsPublic",
          meta: {
            isPublic: true
          }
        },
        {
          path: "road-names/evaluation",
          component: roadNameEvaluationPublic,
          name: "roadNameEvaluationPublic",
          meta: {
            isPublic: true
          }
        },
        {
          path: "place-names/:proposalId/clarify",
          component: clarifyPnp,
          name: "clarificationPublic",
          meta: {
            isPublic: true
          }
        },
        {
          path: "place-names/:proposalId/clarify/submitted",
          component: clarificationSubmitPublic,
          name: "clarifyPnpSubmitPublic",
          props: true,
          meta: {
            isPublic: true
          }
        },
        {
          path: "place-names/:proposalId/clarifyAdvert",
          component: clarifyAdvertPnp,
          name: "clarifyAdvertPublic",
          meta: {
            isPublic: true
          }
        },
        {
          path: "place-names/:proposalId/clarifyAdvert/submitted",
          component: clarifyAdvertPnpSubmitPublic,
          name: "clarifyAdvertPnpSubmitPublic",
          props: true,
          meta: {
            isPublic: true
          }
        }
      ]
    },
    {
      path: "/public/place-names/:proposalId",
      component: placeNameProposalPublic,
      name: "placeNameProposalPublic",
      props: true,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/public/place-names/submitted",
      component: proposalPublicSubmitted,
      name: "proposalPublicSubmitted",
      meta: {
        isPublic: true
      }
    },
    {
      path: "/currentproposals",
      component: currentPlaceNameProposals,
      name: "currentPlaceNameProposals"
    },
    {
      path: "/currentproposals/:advertisementId",
      component: currentPlaceNameProposal,
      name: "currentPlaceNameProposal"
    },
    {
      path: "/currentproposals/:advertisementId/comments",
      component: PlaceNameProposalPublicCommentStart,
      name: "placeNameProposalPublicCommentStart"
    },
    {
      path: "/currentproposals/:advertisementId/comments/:commentId",
      component: PlaceNameProposalPublicCommentForm,
      name: "placeNameProposalPublicCommentForm"
    },
    {
      path: "/currentproposals/:advertisementId/comments/submitted",
      component: PlaceNameProposalPublicCommentSubmitted,
      name: "placeNameProposalPublicCommentSubmitted"
    },
    {
      path: "/overturn/:id",
      component: objectionOverturn,
      name: "objectionOverturn"
    },
    {
      path: "/uphold/:id",
      component: objectionUphold,
      name: "objectionUphold"
    },
    {
      path: "/overturn/:id/submitted",
      component: afterSubmit,
      name: "afterSubmitOverturn",
      props: true
    },
    {
      path: "/uphold/:id/submitted",
      component: afterSubmit,
      name: "afterSubmitUphold",
      props: true
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/users",
          component: Administer,
          name: "administerUsers",
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: "geonames/:geonameId/edit",
          component: geoNameEdit,
          name: "geonameEditGNB",
          props: true,
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: "geonames/search",
          component: geoNameSearch,
          name: "geoNameSearchGNB",
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: "place-names/public-comments/:advertisementId",
          component: pnpProposalComments,
          name: "publicProposalComments"
        },
        {
          path: "surveyorInfo/edit",
          component: editSurveyorInfo,
          name: "editSurveyorGeneralInfo",
          meta: {
            requiresAdmin: true,
            isRoadNameRoute: true
          }
        },
        {
          path: "profile/:id/edit",
          component: editUserProfile,
          props: true,
          name: "profile/edit"
        },
        {
          path: "profile/:id",
          name: "profile/view",
          component: userProfile,
          props: true
        },

        {
          path: "proposals/road-names/submitted",
          name: "proposalSubmitted",
          component: proposalSubmitted,
          beforeEnter(to, from, next) {
            if (from.name !== "roadNameProposalBucket") {
              return next({ name: "proposals" });
            }

            next();
          }
        },
        {
          path: "gazettal/purchaseOrder",
          name: "gazettalOrder",
          component: gazettalOrder,
          meta: {
            requiresAdmin: true,
            isRoadNameRoute: true
          }
        },
        {
          path: "rules/place-names",
          component: roadAndPlaceNameRules,
          name: "pnpRules",
          meta: {
            requiresAdmin: true,
            isPlaceNameRoute: true
          }
        },
        {
          path: "rules/road-names",
          component: roadAndPlaceNameRules,
          name: "rnpRules",
          meta: {
            requiresAdmin: true,
            isRoadNameRoute: true
          }
        },
        {
          path: "proposals/place-names",
          component: ParentRoute,
          meta: {
            isPlaceNameRoute: true
          },
          children: [
            {
              path: "report",
              component: placeNameReport,
              name: "placeNameReport"
            },
            {
              path: "",
              component: placeNameProposals,
              name: "placeNameProposals"
            },
            {
              path: ":proposalId",
              component: placeNameProposal,
              name: "placeNameProposal"
            },
            {
              path: ":jiraId/seek-clarification-redirect",
              component: PNPJiraClarificationRedirect,
              name: "pnpJiraClarificationRedirect"
            },
            {
              path: ":proposalId/endorse",
              component: placeNameProposalEndorsement,
              name: "endorsePlaceNameProposal",
              props: true
            },
            {
              path: ":proposalId/endorsement/submitted",
              component: placeNameEndorsementSubmitted,
              name: "endorsementSubmitted"
            },
            {
              path: ":proposalId/seek-clarification",
              name: "pnpSeekClarification",
              component: PNPSeekingClarification,
              props: true
            },
            {
              path: ":proposalId/seek-clarification-success",
              name: "pnpSeekClarificationSuccess",
              component: PNPSeekingClarificationSuccess,
              props: true
            },
            {
              path: ":proposalId/clarify",
              name: "clarifyPnp",
              component: clarifyPnp,
              props: true
            },
            {
              path: ":proposalId/clarify/submitted",
              name: "clarifyPnpSubmit",
              component: clarifyPnpSubmit,
              props: true
            },
            {
              path: ":proposalId/submitted",
              name: "pnpSubmitted",
              component: pnpSubmitted
            },
            {
              path: ":proposalId/officialmap",
              component: placeNameProposalOfficialMap,
              name: "placeNameProposalOfficialMap",
              props: true
            },
            {
              path: ":proposalId/public-comment",
              component: placeNameProposalAddPublicComment,
              name: "placeNameProposalAddPublicComment"
            },
            {
              path: ":proposalId/public-comment/submitted",
              component: placeNameProposalAddPublicCommentSubmitted,
              name: "placeNameProposalAddPublicCommentSubmitted"
            },
            {
              path: "public-comment/:advertisementId",
              component: pnpProposalComments,
              name: "pnpProposalComments"
            },
            {
              path: ":proposalId/clarifyAdvert",
              name: "clarifyAdvertPnp",
              component: clarifyAdvertPnp,
              props: true
            },
            {
              path: ":proposalId/clarifyAdvert/submitted",
              name: "clarifyAdvertPnpSubmit",
              component: clarifyAdvertPnpSubmit,
              props: true
            },
            {
              path: ":proposalId/audit-trail",
              component: PNPAuditTrail,
              name: "PNPAuditTrail"
            }
          ]
        },
        {
          path: "proposals/road-names",
          component: ParentRoute,
          meta: {
            isRoadNameRoute: true
          },
          children: [
            {
              path: "",
              component: roadNameProposals,
              name: "proposals"
            },
            {
              path: "report",
              component: roadNameReport,
              name: "roadNameReport"
            },
            {
              path: "search-report",
              component: roadNameSearchReport,
              name: "roadNameSearchReport"
            },
            {
              path: "bulk-gazette",
              component: roadNameProposalBulkGazette,
              name: "roadNameProposalBulkGazette"
            },
            {
              path: "bulk-gazette/submit",
              component: roadNameProposalBulkGazetteSubmit,
              name: "roadNameProposalBulkGazetteSubmit",
              props: true
            },
            {
              path: ":proposalId",
              props: true,
              component: proposalBucket,
              name: "roadNameProposalBucket"
            },
            {
              path: ":jiraId/seek-clarification-redirect",
              component: RNPJiraClarificationRedirect,
              name: "roadNameJiraClarificationRedirect"
            },
            {
              path: ":proposalId/roadname",
              component: editRoadNameProposal,
              name: "proposalAdd",
              props: true,
              meta: {
                keepProposal: true
              },
              beforeEnter(to, from, next) {
                if (
                  to.params.proposalId !== "create" &&
                  !store.getters.roadNameProposalBucket.identifier
                ) {
                  next({
                    name: "roadNameProposalBucket",
                    params: {
                      proposalId: to.params.proposalId
                    },
                    replace: true
                  });
                } else {
                  next();
                }
              }
            },
            {
              path: ":proposalId/roadname/upload",
              component: roadNameProposalBulkAdd,
              name: "proposalBulkAdd",
              props: true,
              meta: {
                keepProposal: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId",
              component: editRoadNameProposal,
              name: "roadNameProposalEdit",
              props: route => {
                if (
                  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                    route.params.roadNameId
                  )
                ) {
                  // existing record
                  return {
                    proposalId: route.params.proposalId,
                    roadNameId: route.params.roadNameId
                  };
                }
                return {
                  proposalId: route.params.proposalId,
                  index: parseInt(route.params.roadNameId, 10)
                };
              },
              meta: {
                keepProposal: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/view",
              component: editRoadNameProposal,
              name: "roadNameProposalView",
              props: route => {
                if (
                  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                    route.params.roadNameId
                  )
                ) {
                  // existing record
                  return {
                    proposalId: route.params.proposalId,
                    roadNameId: route.params.roadNameId,
                    readOnly: true
                  };
                }
                return {
                  proposalId: route.params.proposalId,
                  index: parseInt(route.params.roadNameId, 10),
                  readOnly: true
                };
              },
              meta: {
                keepProposal: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/appeal",
              name: "appeal",
              component: proposalAppeal,
              props: true
            },
            {
              path: ":proposalId/roadname/:roadNameId/appeal/submitted",
              name: "submitAppeal",
              component: submitAppeal,
              props: true,
              beforeEnter(to, from, next) {
                if (from.name !== "appeal") {
                  return next({ name: "proposals" });
                }

                next();
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/clarify",
              name: "clarifyProposal",
              component: clarifyProposal,
              props: true
            },
            {
              path: ":proposalId/roadname/:roadNameId/seek-clarification",
              name: "roadSeekClarification",
              component: RNPSeekingClarification,
              props: true,
              meta: {
                requiresAdmin: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/clarifySubmit",
              name: "clarifySubmit",
              component: clarifySubmit,
              props: true,
              beforeEnter(to, from, next) {
                if (from.name !== "clarifyProposal") {
                  return next({
                    name: "roadNameProposalEdit",
                    params: to.params
                  });
                }
                return next();
              }
            },
            {
              path:
                ":proposalId/roadname/:roadNameId/seek-clarification-success",
              name: "clarifySeekSuccess",
              component: RNPSeekingClarificationSuccess,
              props: true,
              beforeEnter(to, from, next) {
                if (from.name !== "roadSeekClarification") {
                  return next({
                    name: "roadNameProposalEdit",
                    params: to.params
                  });
                }
                return next();
              }
            },

            {
              path: ":proposalId/roadname/:roadNameId/gazettal",
              name: "gazettalNotice",
              component: gazettalNotice,
              props: true,
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/gazettal/preview",
              name: "gazettalPreview",
              component: gazettalPreview,
              props: true,
              beforeEnter(to, from, next) {
                if (!from.name === "gazettalNotice") {
                  return next({ name: "proposals" });
                }

                next();
              },
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/erratum-gazettal",
              name: "erratumGazettalNotice",
              component: erratumGazettalNotice,
              props: true,
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/erratum-gazettal/preview",
              name: "erratumGazettalPreview",
              component: erratumGazettalPreview,
              props: true,
              beforeEnter(to, from, next) {
                if (!from.name === "erratumGazettalNotice") {
                  return next({ name: "proposals" });
                }
                next();
              },
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/gazettal/submit",
              name: "gazettalSubmit",
              component: gazettalSubmit,
              props: true,
              beforeEnter(to, from, next) {
                if (!from.name === "gazettalPreview") {
                  return next({ name: "proposals" });
                }

                next();
              },
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/re-gazette",
              name: "reGazette",
              component: reGazette,
              // component: gazettalNotice,
              props: true,
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/re-gazette/preview",
              name: "reGazettePreview",
              component: reGazettePreview,
              props: true,
              meta: {
                isRoadNameRoute: true
              }
            },
            {
              path: ":proposalId/roadname/:roadNameId/audit-trail",
              name: "roadNameProposalAuditTrail",
              component: roadNameProposalAuditLog,
              props: true,
              meta: {
                isRoadNameRoute: true
              }
            }
          ]
        },
        {
          path: "/unapproved-gazette/create",
          name: "unapprovedGazette",
          component: unapprovedGazette,
          meta: {
            isRoadNameRoute: true
          }
        },
        {
          path: "/road-name-types",
          name: "road-name-types",
          component: roadNameTypes,
          meta: {
            requiresAdmin: true,
            isRoadNameRoute: true
          }
        },
        {
          path: "/road-naming-authorities",
          component: ParentRoute,
          children: [
            {
              path: "",
              component: rnasManage,
              name: "rnasManage",
              props: true,
              meta: {
                requiresAdmin: true,
                isRoadNameRoute: true
              }
            },
            {
              path: "add",
              component: rnasAdd,
              name: "rnasAdd",
              props: true,
              meta: {
                requiresAdmin: true,
                isRoadNameRoute: true
              }
            },
            {
              path: ":id",
              component: ParentRoute,
              meta: {
                isRoadNameRoute: true
              },
              beforeEnter(to, from, next) {
                const user = store.getters["user/user"];
                if (
                  user.isAdmin ||
                  user.road_naming_authority_id === to.params.id
                ) {
                  next();
                } else {
                  if (from.fullPath !== "/") {
                    next(false);
                  } else {
                    next("/not-found");
                  }
                }
              },
              children: [
                {
                  path: "",
                  component: rnasView,
                  name: "rnasView",
                  props: true
                },
                {
                  path: "edit",
                  component: rnasEdit,
                  name: "rnasEdit",
                  props: true
                }
              ]
            }
          ]
        },

        {
          path: "organisations/manage",
          component: organisationsManage,
          name: "organisationsManage",
          props: true,
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: "organisations/add",
          component: organisationsAdd,
          name: "organisationsAdd",
          props: true,
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: "organisations/:identifier",
          component: organisationContainer,
          props: true,
          children: [
            {
              path: "",
              component: organisationsView,
              name: "organisationsView",
              props: true
            },
            {
              path: "edit",
              component: organisationsEdit,
              name: "organisationsEdit",
              props: true
            },
            {
              path: "addMembers",
              component: organisationsAddMembers,
              name: "organisationsAddMembers",
              props: true
            }
          ]
        },
        {
          path: "parties/interested/place-names",
          component: Parties,
          name: "pnpInterestedParties",
          meta: {
            isPlaceNameRoute: true,
            requiresAdmin: true
          }
        },
        {
          path: "parties/relevant/road-names",
          component: Parties,
          name: "rnpRelevantParties",
          meta: {
            isRoadNameRoute: true,
            requiresAdmin: true
          }
        },
        {
          path: "geonames",
          component: ParentRoute,
          children: [
            {
              path: ":geonameId/edit",
              component: geoNameEdit,
              name: "geonameEdit",
              props: true
            },
            {
              path: "search",
              component: geoNameSearch,
              name: "geoNameSearch"
            },
            {
              path: "lga-update",
              component: lgaUpdateService,
              name: "lgaUpdateService"
            }
          ]
        }
      ]
    }
  ]
});

const setRouteType = (to, from, user) => {
  if (to.matched.some(route => route.meta.isPlaceNameRoute)) {
    (!user || user.isPNP) && store.commit("viewPNP");
  } else if (to.matched.some(route => route.meta.isRoadNameRoute)) {
    (!user || user.isRNP) && store.commit("viewRNP");
  } else if (from.matched.length === 0) {
    // this route could be either RNP or PNP, and the user has come into the page here
    if (!user || user.isPNP || user.isPublic) {
      store.commit("viewPNP");
    } else {
      store.commit("viewRNP");
    }
  }
};

router.beforeEach((to, from, next) => {
  try {
    const user = store.getters["user/user"];
    if (!to.matched.length) return next("/");

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (user) {
        if (
          to.name !== "notFound" &&
          to.matched.some(route => route.meta.requiresAdmin) &&
          !user.isAdmin
        ) {
          // authenticated route that requires admin rights
          // user is not admin
          next("/");
          return;
        }

        // authenticated route
        setRouteType(to, from, user);
        next();
      } else {
        // authenticated route but user not logged in
        setRouteType(to, from, user);
        next({
          name: "Authentication",
          query: {
            redirect: to ? to.fullPath : "/"
          }
        });
      }
    } else {
      // unAuthenticated route
      next();
    }
  } catch (e) {
    next({
      name: "Authentication",
      query: {
        redirect: to ? to.fullPath : "/"
      }
    });
  }
});

router.onError(function(err) {
  // eslint-disable-next-line no-console
  console.log(err);
  // eslint-disable-next-line no-console
  console.log(err.name);
});

export default router;
