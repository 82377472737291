var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "new-content-notification flex flex-col items-center content-start",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "title flex flex-row flex-grow justify-center items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex-no-grow icon-container items-center content-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "flex-0 text-red text-3xl inline",
                attrs: { icon: "exclamation-circle" },
              }),
            ],
            1
          ),
          _c("h4", { staticClass: "flex-grow text-white" }, [
            _vm._v(
              " NSW Place and Road Naming Proposals System has been updated! "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "content flex-grow text-white" }, [
        _c(
          "a",
          { staticClass: "cursor-pointer", on: { click: _vm.refreshBroswer } },
          [_vm._v("Click here to load the new content")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }