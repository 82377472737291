var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full text-center" }, [
    _c("h3", { staticClass: "text-xl mb-4" }, [
      _vm._v("Are you sure you want to link"),
    ]),
    _c(
      "span",
      { staticClass: "block w-full text-2xl font-bold mb-4 text-blue-darker" },
      [_vm._v(_vm._s(_vm.source.geographical_name))]
    ),
    _c("span", { staticClass: "block w-full mb-4" }, [_vm._v("with")]),
    _c(
      "span",
      { staticClass: "block w-full text-2xl font-bold mb-4 text-blue-darker" },
      [_vm._v(_vm._s(_vm.target.geographical_name))]
    ),
    _c("div", { staticClass: "mt-12 mb-8" }, [
      _c(
        "button",
        {
          staticClass: "button-red-hollow py-4 px-4 mr-6",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.cancel.apply(null, arguments)
            },
          },
        },
        [_vm._v(" Cancel ")]
      ),
      _c(
        "button",
        {
          staticClass:
            "btn focus:outline-none focus:shadow-outline relative px-4 button-blue",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.confirm.apply(null, arguments)
            },
          },
        },
        [_vm._v(" Yes, go ahead ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }