<template>
  <div>
    <NetworkError v-if="fetchError" />

    <ShowLoading v-if="!fetchError" isLoading="fetching-audit-trail">
      <template v-slot:component v-if="trailData">
        <audit-trail :trailData="trailData">
          <template v-slot:summary>
            <proposal-summary :proposal="trailData.proposal" />
          </template>

          <template v-slot:timeline>
            <component
              :is="getTrailEntryComponent(entry.eventType)"
              v-for="(entry, index) in trailData.timeline"
              :key="index"
              :entryData="entry"
            ></component>
          </template>
        </audit-trail>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import { fetchRoadNameAuditTrail } from "@/helpers/rnpCRUD";

import AuditTrail from "@/components/proposals/common/audit-trail";
import ProposalSummary from "@/components/proposals/road/audit-trail/summary";

import RoadNameFormalisation from "./entries/roadname-formalisation";
import GazetteNotice from "./entries/gazette-notice";
import ErratumGazetteNotice from "./entries/erratum-gazette-notice";
import RoadNameRejection from "./entries/roadname-rejection";
import RoadNameRejectionAppeal from "./entries/roadname-rejection-appeal";
import Gazettal from "./entries/gazettal";
import ErratumGazettal from "./entries/erratum-gazettal";
import RoadNameRejectionAppealDecision from "./entries/roadname-rejection-appeal-decision";
import RoadNameRejectionGuidelines from "./entries/rejected-guidelines";
import GazetteNoticeRejection from "./entries/gazette-notice-rejection";
import ProposalContentUpdate from "./entries/proposal-content-update";
import GazetteNoticeDocument from "./documents/gazette-notice";
import ErratumNoticeDocument from "./documents/erratum-gazette-notice";
import ReferenceDocument from "./documents/reference-document";
import SupportingDocument from "./documents/supporting-document";
import ClarificationRequest from "./entries/clarification-request";
import ClarificationResponse from "./entries/clarification-response";

import ComponentMapper from "@/components/proposals/common/audit-trail/mixins/componentMapper";

export default {
  name: "RoadNameAuditHistoryComponent",

  mixins: [ComponentMapper],

  /**
   * Local instance data
   */
  data() {
    return {
      fetchError: false,
      trailData: null,
      componentMap: {
        CLARIFICATION_REQUEST: ClarificationRequest,
        CLARIFICATION_RESPONSE: ClarificationResponse,
        ROADNAME_FORMALISATION: RoadNameFormalisation,
        GAZETTE_NOTICE: GazetteNotice,
        GAZETTE_NOTICE_DOCUMENT_CREATED: GazetteNoticeDocument,
        ERRATUM_GAZETTE_NOTICE: ErratumGazetteNotice,
        ERRATUM_GAZETTE_NOTICE_DOCUMENT_CREATED: ErratumNoticeDocument,
        ROADNAME_REJECTION: RoadNameRejection,
        REJECTION_APPEAL: RoadNameRejectionAppeal,
        REJECTION_APPEAL_DECISION: RoadNameRejectionAppealDecision,
        REJECTED_GUIDELINES: RoadNameRejectionGuidelines,
        GAZETTAL: Gazettal,
        ERRATUM_GAZETTAL: ErratumGazettal,
        ROADNAME_REFERENCE_DOCUMENT_CREATED: ReferenceDocument,
        ROADNAME_SUPPORTING_DOCUMENT_CREATED: SupportingDocument,
        GAZETTE_NOTICE_REJECTION: GazetteNoticeRejection,
        ROADNAME_PROPOSAL_CONTENT_UPDATE: ProposalContentUpdate
      }
    };
  },

  /**
   * Instance child components
   */
  components: {
    AuditTrail,
    ProposalSummary
  },

  /**
   * The instance was mounted
   */
  async mounted() {
    await this.initialiseTrail();
  },

  /**
   * Local instance methods
   */
  methods: {
    /**
     * Fetch the audit trail and set it up
     */
    async initialiseTrail() {
      this.$wait.start("fetching-audit-trail");

      try {
        this.trailData = await fetchRoadNameAuditTrail(
          this.$route.params.roadNameId
        );
      } catch (e) {
        this.fetchError = true;
      } finally {
        this.$wait.end("fetching-audit-trail");
      }
    }
  }
};
</script>
