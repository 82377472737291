var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c("ShowLoading", {
        attrs: { isLoading: "fetching clarification data" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _vm.errorLoading ? _c("NetworkError") : _vm._e(),
                !_vm.errorLoading && _vm.correctStatus && _vm.user.isAdmin
                  ? _c(
                      "section",
                      { staticClass: "pt-1 justify-center w-full block" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "flex justify-between pt-4 pb-4 -mr-4 self-center",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "roadNameProposalBucket",
                                  params: {
                                    proposalId: _vm.$route.params.proposalId,
                                  },
                                })
                              },
                            },
                          },
                          [
                            _c("BackArrowWithTitle", {
                              attrs: { title: "Proposal Seek Clarification" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center font-semibold",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 flex mx-auto" },
                              [
                                _c("proposalIcon", {
                                  attrs: {
                                    label: _vm.road.roadname_classification,
                                    classNames: "text-base mr-3 h-10 w-10",
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass: "self-center text-title mt-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.road.roadname) +
                                        " " +
                                        _vm._s(_vm.road.road_type) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("StatusButton", {
                                  staticClass:
                                    "ml-auto mr-6 font-semibold text-sm",
                                  attrs: {
                                    label: _vm.road.name_status,
                                    status: _vm.road.name_status,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 mx-auto" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-normal-bold content-start mb-2 mt-10",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-red-star" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("Clarification Request "),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.clarificationText,
                                      expression: "clarificationText",
                                    },
                                  ],
                                  staticClass:
                                    "text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input",
                                  attrs: {
                                    placeholder:
                                      "Please enter your clarification request",
                                    disabled: _vm.$wait.any,
                                  },
                                  domProps: { value: _vm.clarificationText },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.clarificationText =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _c("uploadComponent", {
                                  staticClass: "mb-10",
                                  attrs: {
                                    label: "Attach supporting documentation",
                                    disabled: _vm.uploading || _vm.$wait.any,
                                    drafted: true,
                                    deleteFile: _vm.deleteFunction,
                                  },
                                  on: {
                                    uploadfinished: function ($event) {
                                      _vm.uploading = false
                                    },
                                    uploadstarted: function ($event) {
                                      _vm.uploading = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex pt-4 text-center pb-10 justify-end",
                          },
                          [
                            _c(
                              "ButtonWithSpinner",
                              {
                                staticClass: "p-3 ml-4 w-1/5 button-blue",
                                attrs: {
                                  disabled:
                                    _vm.uploading ||
                                    !_vm.clarificationText ||
                                    _vm.$wait.any,
                                  isSpinning: _vm.$wait.is(
                                    "saving clarification request"
                                  ),
                                },
                                on: { click: _vm.submitClarification },
                              },
                              [_vm._v(" Submit Clarification Request ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.errorLoading && !_vm.correctStatus
                  ? _c(
                      "section",
                      { staticClass: "pt-1 justify-center w-full block" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "flex justify-between pt-4 pb-4 -mr-4 self-center",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({ name: "proposals" })
                              },
                            },
                          },
                          [
                            _c("BackArrowWithTitle", {
                              attrs: { title: "Proposal Seek Clarification" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center",
                          },
                          [
                            _vm.user.isAdmin
                              ? _c(
                                  "div",
                                  { staticClass: "w-9/10 flex mx-auto" },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2 text-red",
                                      attrs: { icon: "times" },
                                    }),
                                    _vm._v(
                                      " Proposal is not in the correct status to seek clarification "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.user.isAdmin
                              ? _c(
                                  "div",
                                  { staticClass: "w-9/10 flex mx-auto" },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2 text-red",
                                      attrs: { icon: "times" },
                                    }),
                                    _vm._v(" You do not have permission "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }