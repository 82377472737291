var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4 w-full",class:_vm.classNames,attrs:{"id":"uploadDiv","data-cypress":"upload-box"}},[_c('p',{staticClass:"pb-3 text-normal-bold"},[(_vm.important)?_c('a',{staticClass:"text-red-star"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"flex w-full"},[_c('label',{staticClass:"flex w-full"},[_c('div',{ref:"fileform",staticClass:"w-7/8 flex p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded",class:{ 'disabled-file-picker': _vm.disabled }},[_vm._v(" Click or drag file here to upload... "),_c('input',{ref:"fileInput",staticClass:"hidden",attrs:{"type":"file","data-cypress":"file-upload-input","disabled":_vm.disabled},on:{"change":function($event){return _vm.filesChange($event.target.files)}}})]),_c('ButtonWithSpinner',{staticClass:"bg-blue-button rounded rounded-l-none relative w-1/8 flex justify-center items-center cursor-pointer text-white text-normal font-semibold",attrs:{"disabled":_vm.isWaiting || _vm.disabled,"isSpinning":_vm.isWaiting,"data-cypress":"addFilesButton"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_vm._v("Add Files")])],1)]),_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('div',{staticClass:"w-full mt-5 flex items-center rounded-lg border border-grey-border"},[_c('img',{staticClass:"mx-3 m-1",attrs:{"src":require("../../assets/Images/Icon-xls.png")}}),_c('p',{staticClass:"textNormal",attrs:{"data-cypress":"file-title"}},[_vm._v(_vm._s(file.title))]),_c('a',{staticClass:"cursor-pointer ml-auto text-normal-bold text-blue-button",attrs:{"href":_vm.relativeFileUrl(file.s3_key)}},[_vm._v("View File")]),_c('button',{staticClass:"cursor-pointer ml-10 mr-8 text-normal-bold",class:{
          'text-grey-light': !_vm.drafted || _vm.disabled,
          'text-red-button': _vm.drafted && !_vm.disabled
        },attrs:{"data-cypress":"removefiles","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeFile(index)}}},[_vm._v(" Remove ")])]),_c('div',{staticClass:"document-type-select"},[_c('v-select',{key:file.s3_key,class:`${file.document_type} bg-grey-input`,attrs:{"value":file.document_type,"label":"label","reduce":type => type.value,"options":[
          { value: 'history', label: 'History (Private)' },
          {
            value: 'history_unverified_approved',
            label: 'Unverified Approved (Public)'
          },
          {
            value: 'history_verified_approved',
            label: 'Verified Approved (Public)'
          },
          {
            value: 'history_verified_rejected',
            label: 'Verified Rejected (Private)'
          },
          {
            value: 'history_unverified_rejected',
            label: 'Unverified Rejected (Private)'
          }
        ]},on:{"input":event => _vm.setFileType(event, index)}})],1)])})],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }