var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "switch m-auto justify-center" }, [
    _c("input", {
      attrs: { type: "checkbox", id: "togBtn", disabled: _vm.disabled },
      domProps: { checked: _vm.value },
      on: { change: (e) => _vm.$emit("input", e.target.checked) },
    }),
    _c("div", { staticClass: "slider round p-auto text-sm justify-center" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }