var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container flex flex-col justify-start" },
    [
      _c("section", { staticClass: "flex-auto" }, [
        _c(
          "div",
          { staticClass: "flex justify-between flex-wrap" },
          [
            _c(
              "h1",
              {
                staticClass:
                  "flex-auto text-black justify-between text-title self-center",
              },
              [_vm._v(" Administer Users ")]
            ),
            _c(
              "ButtonWithSpinner",
              {
                staticClass: "button-red-hollow mt-4 mb-4 mr-4",
                attrs: {
                  isSpinning: _vm.$wait.is("downloadingUserData"),
                  disabled: _vm.$wait.is("downloadingUserData"),
                },
                on: {
                  click: function ($event) {
                    return _vm.exportUserAccessReview()
                  },
                },
              },
              [_vm._v(" Export User Data ")]
            ),
            _vm.isRNPView
              ? _c(
                  "router-link",
                  {
                    staticClass:
                      "flex-no-grow flex-no-shrink pt-4 pb-4 mt-4 mb-4 pl-6 pr-6 rounded focus:outline-none focus:shadow-outline self-center button-blue-hollow",
                    attrs: {
                      to: { name: "editSurveyorGeneralInfo" },
                      "data-cypress": "edit-surveyor-link",
                    },
                  },
                  [_vm._v("Edit Surveyor General Info")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "section",
        { staticClass: "flex flex-col flex-auto pt-1 justify-center pb-20" },
        [
          _c(
            "div",
            { staticClass: "flex flex-1 flex-row items-center flex-wrap" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.textSearch,
                    expression: "textSearch",
                  },
                ],
                staticClass: "flex-1 bg-white w-1/4 pr-12 input-area mr-2",
                attrs: {
                  name: "FullNameSearch",
                  placeholder: "Search by First Name or Last Name...",
                },
                domProps: { value: _vm.textSearch },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.textSearch = $event.target.value
                  },
                },
              }),
              _c("SelectEntity", {
                staticClass: "flex-1 bg-white mr-2 autocomplete",
                attrs: {
                  entityList: _vm.entityList,
                  "data-cypress": "entity-filter",
                  name: "namingEntity",
                  "v-wait-hidden": _vm.entityWaiter,
                  placeholder: "Search by Entity",
                },
                model: {
                  value: _vm.entityName,
                  callback: function ($$v) {
                    _vm.entityName = $$v
                  },
                  expression: "entityName",
                },
              }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userTypesFilter,
                      expression: "userTypesFilter",
                    },
                  ],
                  staticClass: "flex-1 mr-2",
                  attrs: {
                    "data-cypress": "search-usertype",
                    name: "UserType",
                    id: "userType",
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.userTypesFilter = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", hidden: "" } },
                    [_vm._v("Search by User Type")]
                  ),
                  _vm._l(_vm.userTypes, function (item, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userStatusFilter,
                      expression: "userStatusFilter",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-1",
                  attrs: { name: "userStatus", id: "userStatus" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.userStatusFilter = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", hidden: "" } },
                    [_vm._v("Search by User Status")]
                  ),
                  _vm._l(_vm.userStatusDropdown, function (item, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("UserList", {
            attrs: { users: _vm.users },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ user }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-12 h-full flex-auto flex flex-col content-around items-center",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button",
                            attrs: {
                              "data-cypress": "view-user-link",
                              to: {
                                name: "profile/view",
                                params: { id: user.email },
                              },
                              title: `View ${user.first_name} ${user.last_name}'s profile`,
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "flex-auto",
                              attrs: { icon: "eye" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button",
                            attrs: {
                              to: {
                                name: "profile/edit",
                                params: { id: user.email },
                              },
                              "data-cypress": "edit-user-link",
                              title: `Edit ${user.first_name} ${user.last_name}'s profile`,
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "flex-auto",
                              attrs: { icon: "user-edit" },
                            }),
                          ],
                          1
                        ),
                        user.user_account_status === "approved"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user",
                                class: {
                                  "cursor-not-allowed":
                                    user.user_account_status !== "approved",
                                },
                                attrs: {
                                  title: `Suspend ${user.first_name} ${user.last_name}`,
                                  "data-cypress": "suspend-button",
                                  disabled:
                                    user.user_account_status !== "approved",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.suspendUser(user)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "flex-auto",
                                  attrs: { icon: "lock" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        user.user_account_status !== "approved"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-auto flex justify-center items-center text-green font-bold h-1/3 w-full action-button activate-user",
                                attrs: {
                                  title: `Approve ${user.first_name} ${user.last_name}`,
                                  "data-cypress": "suspend-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.approveUser(user)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "flex-auto",
                                  attrs: { icon: "check" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("DeleteUserModal", {
                          staticClass:
                            "flex-auto flex justify-center items-center w-full",
                          attrs: {
                            user: user,
                            isFromUserProfile: false,
                            loggedUser: _vm.loggedUser,
                            passedRoadNameProposals: null,
                            passedPlaceNameProposals: null,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          !_vm.$wait.is(`fetching users`) && _vm.total
            ? _c(
                "section",
                {
                  staticClass:
                    "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mx-auto text-center self-center" },
                    [
                      _c("paginate", {
                        attrs: {
                          "page-count": Math.ceil(
                            _vm.total / parseInt(_vm.limit)
                          ),
                          "page-range": 10,
                          "margin-pages": 1,
                          "prev-text": "<",
                          "next-text": ">",
                          "container-class": "pagination",
                          "page-class": "page-item",
                          "click-handler": _vm.scrollToTop,
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " Showing " +
                            _vm._s(
                              (
                                (_vm.page - 1) * parseInt(_vm.limit) +
                                1
                              ).toLocaleString()
                            ) +
                            " to " +
                            _vm._s(
                              Math.min(
                                (_vm.page - 1) * parseInt(_vm.limit) +
                                  parseInt(_vm.limit),
                                _vm.total
                              ).toLocaleString()
                            ) +
                            " of " +
                            _vm._s(_vm.total.toLocaleString()) +
                            " users "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "w-full" },
            [_c("user-export-component")],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }