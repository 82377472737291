import * as rnaCruds from "../../helpers/rnaCRUD";

// initial state
const state = {
  newRnaData: {},
  allRnas: [],
  entityList: [],
  isWaiting: false,
  defaultRnaLga: null
};

// getters
const getters = {
  getNewRnaDataState: state => state.newRnaData,
  rnaList: state => state.allRnas,
  entityList: state => {
    return state.entityList;
  },
  isWaiting: state => state.isWaiting,
  defaultRnaLga: state => state.defaultRnaLga
};

// actions
const actions = {
  async pushNewRNAToDatabase({ state, commit }) {
    commit("setWaiting", true);
    try {
      await rnaCruds.saveRnaToDb(state.newRnaData);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  updateNewRnaDataState(state, payload) {
    state.commit("mutateNewRnaDataState", payload);
  },
  async getRnaList({ commit }) {
    commit("setWaiting", true);
    try {
      const rnaList = await rnaCruds.getRnaList();
      commit("setRNAList", rnaList);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async getEntityList({ commit }) {
    commit("setWaiting", true);
    try {
      const entityList = await rnaCruds.getEntityList();
      commit("setEntityList", entityList);
    } catch (e) {
      // let the caller know there was an error
      throw e;
    } finally {
      commit("setWaiting", false);
    }
  },
  async getRNA(id) {
    await rnaCruds.getRNA(id);
  },
  async removeRna({ dispatch, commit }, id) {
    commit("setWaiting", true);
    try {
      await rnaCruds.deleteRNA(id);
      await dispatch("getRnaList");
    } catch (e) {
      commit("setWaiting", false);
      // let the caller know there was an error
      throw e;
    }
  },
  async fetchDefaultRnaLga({ commit }, rnaIdentifier) {
    const defaultRnaLga = await rnaCruds.getDefaultRnaLga(rnaIdentifier);
    commit("setDefaultRnaLga", defaultRnaLga);
  }
};

// mutations
const mutations = {
  mutateNewRnaDataState(state, payload) {
    state.newRnaData = payload;
  },
  setRNAList(state, rnaList) {
    state.allRnas = rnaList;
    state.isWaiting = false;
  },
  setEntityList(state, entityList) {
    state.entityList = entityList;
    state.isWaiting = false;
  },
  setWaiting(state, isWaiting) {
    state.isWaiting = isWaiting;
  },
  setDefaultRnaLga(state, value) {
    state.defaultRnaLga = value;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
