let documentFiles = [
  ".doc",
  ".docx",
  ".odt",
  ".rtf",
  ".txt",
  ".pdf",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".csv"
];

let gisFiles = [
  ".shp",
  ".dbf",
  ".shx",
  ".prj",
  ".xml",
  ".sbn",
  ".sbx",
  ".geojson",
  ".json",
  ".gml",
  ".kml",
  ".kmz",
  ".gpx",
  ".vct",
  ".vdc",
  ".tab",
  ".dat",
  ".id",
  ".map",
  ".ind",
  ".osm",
  ".dlg",
  ".img",
  ".asc",
  ".rst",
  ".rdc",
  ".bil",
  ".bip",
  ".bsq",
  ".pix",
  ".gdb",
  ".mdb",
  ".gpkg",
  ".mbtiles",
  ".vmds",
  ".sl3",
  ".sqlite",
  ".mxd",
  ".qgs",
  ".qgz",
  ".aprx",
  ".mxt",
  ".wor",
  ".mws",
  ".3dd",
  ".sxd",
  ".map",
  ".lyr",
  ".lyrx",
  ".qlr",
  ".styl",
  ".stylx",
  ".qml"
];

let imageFiles = [
  ".jpg",
  ".jpeg",
  ".jpe",
  ".jif",
  ".jfif",
  ".jfi",
  ".jp2",
  ".j2k",
  ".jpf",
  ".jpx",
  ".jpm",
  ".mj2",
  ".tif",
  ".tiff",
  ".ovr",
  ".tfw",
  ".aux",
  ".wav",
  ".gif",
  ".bmp",
  ".dib",
  ".png",
  ".pbm",
  ".pgm",
  ".ppm",
  ".pnm",
  ".webp",
  ".heif",
  ".heic",
  ".bpg",
  ".drw",
  ".ecw",
  ".fits",
  ".fit",
  ".fts",
  ".flif",
  ".jxr",
  ".hdp",
  ".wdp",
  ".cgm",
  ".gbr",
  ".svg",
  ".svgz",
  ".dwf",
  ".dwfx",
  ".dwg",
  ".dws",
  ".dwt",
  ".dxf",
  ".sid",
  ".sdw",
  ".dgn"
];
let allFiles = [].concat(documentFiles, gisFiles, imageFiles);

export const allFilesList = allFiles.join();
export const documentFilesList = documentFiles.join();
export const gisFilesList = gisFiles.join();
export const imageFilesList = imageFiles.join();
