var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black mb-12" },
    [
      _c(
        "span",
        { staticClass: "flex items-center text-black mt-3" },
        [
          _c(
            "h1",
            {
              staticClass: "text-title",
              attrs: { "data-cypress": "header-text" },
            },
            [_vm._v("Manage RNAs")]
          ),
          _c(
            "router-link",
            {
              staticClass: "ml-auto button-blue-hollow",
              attrs: {
                to: { name: "rnasAdd" },
                "data-cypress": "addnewrna-button",
              },
            },
            [_vm._v("Add New RNA")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "w-full mt-4" }, [
        _c("input", {
          staticClass: "input-area bg-white w-full",
          attrs: { type: "text", placeholder: "Search by RNA name..." },
          on: { input: _vm.searchRna },
        }),
      ]),
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c("Loading-spinner", {
            directives: [
              {
                name: "wait",
                rawName: "v-wait:visible",
                value: "loadingRnaList",
                expression: "'loadingRnaList'",
                arg: "visible",
              },
            ],
            staticClass: "flex-0",
          })
        : _vm._e(),
      !_vm.fetchError
        ? _c("rnaList", {
            directives: [
              {
                name: "wait",
                rawName: "v-wait:hidden",
                value: "loadingRnaList",
                expression: "'loadingRnaList'",
                arg: "hidden",
              },
            ],
            attrs: { rnas: _vm.rnas },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ rna }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-12 h-full flex-auto flex flex-col content-around items-center",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                              attrs: {
                                "data-cypress": "edit-rna-link",
                                to: {
                                  name: "rnasEdit",
                                  params: { id: rna.identifier },
                                },
                                title: `Edit ${rna.authority_name} details`,
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "flex-auto",
                                attrs: { icon: "edit" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                              attrs: {
                                to: {
                                  name: "administerUsers",
                                  query: {
                                    road_naming_authority_id: rna.identifier,
                                  },
                                },
                                "data-cypress": "users-rna-link",
                                title: `Manage ${rna.authority_name}'s users`,
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "flex-auto",
                                attrs: { icon: "users" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                              attrs: {
                                to: {
                                  name: "proposals",
                                  query: {
                                    road_naming_authority_id: rna.identifier,
                                  },
                                },
                                "data-cypress": "proposals-rna-link",
                                title: `View ${rna.authority_name}'s proposals`,
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "flex-auto",
                                attrs: { icon: "road" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              221476948
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }