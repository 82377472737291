<template>
  <div class="w-full flex bg-white">
    <div class="w-5/6 mx-auto pb-16 pt-8">
      <div class="mb-4 flex flex-row">
        <span class="font-bold flex-grow text-lg">History of changes</span>
        <div
          class="sort-button border-blue-button font-medium border-l-2 border-t-2 border-b-2 py-1 px-4 rounded-l-lg"
          v-bind:class="{ 'bg-blue-button': !isAsc, 'text-white': !isAsc }"
          v-on:click="
            () => {
              if (!$wait.is('fetchingHistory')) {
                sortDesc();
              }
            }
          "
        >
          Latest
        </div>
        <div
          class="sort-button border-blue-button font-medium border-r-2 border-t-2 border-b-2 py-1 px-4 rounded-r-lg"
          v-bind:class="{ 'bg-blue-button': isAsc, 'text-white': isAsc }"
          v-on:click="
            () => {
              if (!$wait.is('fetchingHistory')) {
                sortAsc();
              }
            }
          "
        >
          Oldest
        </div>
      </div>
      <div
        v-if="(!history || !history.length) && !$wait.is('fetchingHistory')"
        class="w-full text-lg py-8 text-center"
      >
        There is no change history for this geographical name record.
      </div>
      <showLoading isLoading="fetchingHistory">
        <template v-slot:component>
          <div
            v-for="(history, index) in historyData"
            class="w-full"
            :key="index"
          >
            <div class="w-full font-medium text-grey-darkest py-2">
              {{ history.date }}
            </div>
            <div class="w-full flex flex-row font-bold border-b-2">
              <div class="w-1/3 pl-2">Field</div>
              <div class="w-1/3 pl-2">From</div>
              <div class="w-1/3 pl-2">To</div>
            </div>
            <div class="mb-4">
              <div
                class="my-4 w-full"
                v-for="(changeSet, index) in history.historyForDate"
                :key="index"
              >
                <div class="text-xs text-grey font-bold w-full text-right">
                  {{ changeSet.time }}
                </div>
                <div class="border-l-4">
                  <div
                    class="w-full flex flex-row py-1"
                    v-for="(change, index) in changeSet.changes"
                    :key="index"
                  >
                    <div class="w-1/3 pl-2">
                      {{ mapFieldToLabel(change.field, true) }}
                    </div>
                    <div
                      v-if="!Array.isArray(change.from)"
                      class="w-1/3 pl-2 text-sm font-bold"
                    >
                      {{ change.from || "None" }}
                    </div>
                    <div
                      v-if="Array.isArray(change.from)"
                      class="w-1/3 pl-2 text-sm font-bold"
                    >
                      <div v-if="!change.from.length">None</div>
                      <div v-else>
                        <ul>
                          <div v-for="(changeFrom, index) in change.from" v-bind:key="index">
                            <li> <a :href="relativeFileUrl(changeFrom.s3_key)">
                              {{ changeFrom.title }}
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div
                      v-if="!Array.isArray(change.to)"
                      class="w-1/3 pl-2 text-sm font-bold"
                    >
                      {{ change.to || "None" }}
                    </div>
                    <div
                      v-if="Array.isArray(change.to)"
                      class="w-1/3 pl-2 text-sm font-bold"
                    >
                      <div v-if="!change.to.length">None</div>
                      <div v-else>
                        <ul>
                          <div v-for="(changeTo, index) in change.to" v-bind:key="index">
                            <li> <a :href="relativeFileUrl(changeTo.s3_key)">
                              {{ changeTo.title }}
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </showLoading>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _orderBy from "lodash.orderby";
// import { waitFor } from "vue-wait";

// import pnpCRUD from "../../helpers/pnpCRUD";
import mapFieldToLabel from "./map-field-to-label.js";

export default {
  name: "EditHistory",
  data() {
    return {
      order: "DESC"
    };
  },
  props: {
    geonameId: String,
    fetchGeonameHistory: Function,
    history: Array
  },
  async created() {
    await this.fetchGeonameHistory();
  },
  methods: {
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    mapFieldToLabel,
    async sortAsc() {
      if (this.order === "ASC") return;
      this.order = "ASC";
      await this.fetchGeonameHistory(this.order);
    },
    async sortDesc() {
      if (this.order === "DESC") return;
      this.order = "DESC";
      await this.fetchGeonameHistory(this.order);
    }
  },
  computed: {
    isAsc() {
      return this.order === "ASC";
    },
    historyData() {
      const hd = this.history.reduce((historyData, current) => {
        const date = moment(current.date).format("D MMMM YYYY");

        const time = moment(current.date).format("h:mm:ss A");

        const pos = historyData.map(h => h.date).indexOf(date);

        if (pos >= 0) {
          const timePos = historyData[pos].historyForDate
            .map(hfd => hfd.time)
            .indexOf(time);

          if (timePos >= 0) {
            historyData[pos].historyForDate[timePos].changes.push(
              ...current.changes
            );
          } else {
            historyData[pos].historyForDate.push({
              time: time,
              changes: [...current.changes]
            });
          }
        } else {
          historyData.push({
            date: date,
            historyForDate: [
              {
                time: time,
                changes: [...current.changes]
              }
            ]
          });
        }
        return historyData;
      }, []);

      const historyDataWithLabels = hd.map(data => {
        data.historyForDate = data.historyForDate.map(hfd => {
          hfd.changes = hfd.changes.map(change => {
            change.label = mapFieldToLabel(change.field, true);
            return change;
          });
          return hfd;
        });
        return data;
      });

      return historyDataWithLabels.map(data => {
        data.historyForDate = data.historyForDate.map(hfd => {
          hfd.changes = _orderBy(hfd.changes, ["label"], ["asc"]);
          return hfd;
        });

        return data;
      });
    }
  }
};
</script>

<style>
.sort-button {
  cursor: pointer;
}

ul {
  padding-left: 18px !important;
}
</style>
