var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sm:flex flex self-center flex-grow" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localKeyword,
            expression: "localKeyword",
          },
        ],
        staticClass:
          "search shadow rounded border-0 p-3 pl-12 text-white font-normal bg-black opacity-75 rounded-r-none flex-grow",
        attrs: { type: "search", placeholder: "Search for proposals..." },
        domProps: { value: _vm.localKeyword },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.search.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.localKeyword = $event.target.value
          },
        },
      }),
      _c("font-awesome-icon", {
        staticClass: "search-icon absolute mt-4 ml-4 text-white",
        attrs: { icon: "search" },
      }),
      _c(
        "button",
        {
          staticClass:
            "text-blue-button rounded bg-teal-button focus:outline-none focus:shadow-outline w-32 font-bold rounded-l-none",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.search()
            },
          },
        },
        [_vm._v(" Search ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }