var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c(
      "div",
      {
        staticClass:
          "justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3",
      },
      [
        _c("div", [
          _c(
            "span",
            {
              staticClass: "text-red text-normal-bold cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "flex-auto",
                attrs: { icon: "arrow-left" },
              }),
              _vm._v(" Back "),
            ],
            1
          ),
        ]),
        _c(
          "h3",
          { staticClass: "pb-3 mb-8 w-full text-title dividing-line-h" },
          [_vm._v(" Add a Comment for a Place Name Proposal ")]
        ),
        _c("showLoading", {
          attrs: { isLoading: "fetchingProposalForComment" },
          scopedSlots: _vm._u([
            {
              key: "component",
              fn: function () {
                return [
                  _vm.proposal
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "text-black" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full border flex flex-row border-gray-200 rounded",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "p-6 mr-16 ml-4 mt-2 inline-block align-middle text-black font-bold text-2xl",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.proposal.geographical_name) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dividing-line-v text-black flex-grow flex flex-row",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4",
                                      },
                                      [
                                        _c("span", [_c("b", [_vm._v("LGA")])]),
                                        _vm._l(
                                          _vm.proposal.lga,
                                          function (lga) {
                                            return _c("p", { key: lga }, [
                                              _vm._v(_vm._s(lga)),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4 pr-4",
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [_vm._v("Designation")]),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.proposal.designation)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4",
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [_vm._v("Publication Date")]),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.proposal.date_start
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow pb-4 pt-4 w-1/4",
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [_vm._v("Closing Date")]),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.proposal.date_end
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _c("p", { staticClass: "text-normal-bold mt-8" }, [
                              _c("a", { staticClass: "text-red-star" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                "Does the comment support the above proposed place name? "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex flex-row text-normal" },
                              [
                                _c(
                                  "RadioButton",
                                  {
                                    staticClass: "flex-0 mr-6 mt-3 mb-1",
                                    attrs: {
                                      name: "supportive-no",
                                      checkedValue: true,
                                    },
                                    model: {
                                      value: _vm.submission.is_supportive,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submission,
                                          "is_supportive",
                                          $$v
                                        )
                                      },
                                      expression: "submission.is_supportive",
                                    },
                                  },
                                  [_vm._v("I support")]
                                ),
                                _c(
                                  "RadioButton",
                                  {
                                    staticClass: "flex-0 mr-6 mt-3 mb-1",
                                    attrs: {
                                      name: "supportive-yes",
                                      checkedValue: false,
                                    },
                                    model: {
                                      value: _vm.submission.is_supportive,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submission,
                                          "is_supportive",
                                          $$v
                                        )
                                      },
                                      expression: "submission.is_supportive",
                                    },
                                  },
                                  [_vm._v("I Object")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("label", [
                            _c(
                              "p",
                              { staticClass: "text-normal-bold mb-2 mt-8" },
                              [
                                _vm._v(
                                  " What is their interest in this proposal? "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "w-full mt-3 flex flex-col" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.submission.interest,
                                      expression: "submission.interest",
                                    },
                                  ],
                                  staticClass: "w-full h-48 input-area",
                                  domProps: { value: _vm.submission.interest },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.submission,
                                        "interest",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "label",
                            [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mb-2 mt-8" },
                                [
                                  _c("a", { staticClass: "text-red-star" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v(
                                    "What is their reason for supporting/objecting to this proposal? "
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v(
                                  " Please outline where this proposal does not comply with the relevant policies GNB Place Naming Policy and NSW Addressing User Manual "
                                ),
                              ]),
                              _c("div", { staticClass: "w-full mt-3 flex" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.submission.comment,
                                      expression: "submission.comment",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full h-48 input-area",
                                  attrs: {
                                    "data-vv-as": "Interest in Proposal",
                                    name: "comment",
                                  },
                                  domProps: { value: _vm.submission.comment },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.submission,
                                        "comment",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("FieldErrorMessage", {
                                attrs: { message: _vm.errors.first("comment") },
                              }),
                            ],
                            1
                          ),
                          _c("uploadScoped", {
                            staticClass: "mt-8",
                            attrs: {
                              label: "Add Supporting Documentation",
                              important: false,
                              files:
                                _vm.submission
                                  .public_comment_reference_document,
                              drafted: true,
                              stateProp: "public_comment_reference_document",
                              uploadFile: _vm.uploadFileForComponent,
                              name: "public_comment_reference_document",
                            },
                            on: {
                              deleteFile: (fileIndex) =>
                                _vm.submission.public_comment_reference_document.splice(
                                  fileIndex,
                                  1
                                ),
                            },
                          }),
                          _c("div", { staticClass: "mt-8" }, [
                            _c("div", { staticClass: "mx-auto" }, [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "pb-3 mb-8 w-full text-title dividing-line-h",
                                },
                                [_vm._v(" Contact Details ")]
                              ),
                              _c("div", { staticClass: "w-full flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "w-1/2" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-normal-bold mt-4 mb-2",
                                      },
                                      [
                                        _c(
                                          "a",
                                          { staticClass: "text-red-star" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v("First Name "),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.submission.first_name,
                                          expression: "submission.first_name",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter First Name Here...",
                                        name: "first_name",
                                        "data-vv-as": "First Name",
                                      },
                                      domProps: {
                                        value: _vm.submission.first_name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.submission,
                                            "first_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("FieldErrorMessage", {
                                      attrs: {
                                        message: _vm.errors.first("first_name"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-1/2 ml-8" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-normal-bold mt-4 mb-2",
                                      },
                                      [
                                        _c(
                                          "a",
                                          { staticClass: "text-red-star" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v("Last Name "),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.submission.last_name,
                                          expression: "submission.last_name",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Last Name Here...",
                                        name: "last_name",
                                        "data-vv-as": "Last Name",
                                      },
                                      domProps: {
                                        value: _vm.submission.last_name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.submission,
                                            "last_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("FieldErrorMessage", {
                                      attrs: {
                                        message: _vm.errors.first("last_name"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "w-full" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mt-4 mb-2" },
                                  [_vm._v(" Address Line 1 ")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.submission.address_line_1,
                                      expression: "submission.address_line_1",
                                    },
                                  ],
                                  staticClass:
                                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter Address Here...",
                                  },
                                  domProps: {
                                    value: _vm.submission.address_line_1,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.submission,
                                        "address_line_1",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "w-full" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mt-4 mb-2" },
                                  [_vm._v(" Address Line 2 ")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.submission.address_line_2,
                                      expression: "submission.address_line_2",
                                    },
                                  ],
                                  staticClass:
                                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "e.g. Apartment number, floor number, etc",
                                    name: "address_line_2",
                                  },
                                  domProps: {
                                    value: _vm.submission.address_line_2,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.submission,
                                        "address_line_2",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "w-full flex items-center" },
                                [
                                  _c("div", { staticClass: "w-1/2 mr-8" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-normal-bold mt-4 mb-2",
                                      },
                                      [_vm._v(" Suburb ")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.submission.suburb,
                                          expression: "submission.suburb",
                                        },
                                      ],
                                      staticClass:
                                        "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Suburb Here...",
                                      },
                                      domProps: {
                                        value: _vm.submission.suburb,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.submission,
                                            "suburb",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "w-1/2 flex items-center" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "w-1/2 mr-8 mt-8 mb-2" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-normal-bold" },
                                            [_vm._v(" State ")]
                                          ),
                                          _c("v-select", {
                                            staticClass:
                                              "w-full mr-4 norns-select",
                                            attrs: {
                                              multiple: false,
                                              options: _vm.australianStates,
                                              searchable: false,
                                              "close-on-select": true,
                                              "select-on-tab": true,
                                              "show-labels": false,
                                              "hide-selected": true,
                                              placeholder: "Select a State",
                                              name: "state",
                                            },
                                            model: {
                                              value: _vm.submission.state,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.submission,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression: "submission.state",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "w-1/2" }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mt-4 mb-2",
                                          },
                                          [_vm._v(" Postcode ")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.submission.postcode,
                                              expression: "submission.postcode",
                                            },
                                          ],
                                          staticClass:
                                            "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Enter Postcode Here...",
                                          },
                                          domProps: {
                                            value: _vm.submission.postcode,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.submission,
                                                "postcode",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-full flex items-center" },
                                [
                                  _c("div", { staticClass: "w-1/2 mr-8" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-normal-bold mt-4 mb-2",
                                      },
                                      [_vm._v(" Phone Number ")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.submission.phone_no,
                                          expression: "submission.phone_no",
                                        },
                                      ],
                                      staticClass:
                                        "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "Enter Phone Number Here...",
                                        name: "phone_no",
                                      },
                                      domProps: {
                                        value: _vm.submission.phone_no,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.submission,
                                            "phone_no",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "w-1/2" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-normal-bold mt-4 mb-2",
                                        },
                                        [
                                          _vm.notify_outcome === true
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e(),
                                          _vm._v("Email Address "),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.submission.email,
                                            expression: "submission.email",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              _vm.submission.notify_outcome ===
                                              true
                                                ? "required|email"
                                                : "email",
                                            expression:
                                              "\n                      submission.notify_outcome === true\n                        ? 'required|email'\n                        : 'email'\n                    ",
                                          },
                                        ],
                                        staticClass:
                                          "w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "Enter Email Address Here...",
                                          name: "email",
                                          "data-vv-as": "Email",
                                        },
                                        domProps: {
                                          value: _vm.submission.email,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.submission,
                                              "email",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("FieldErrorMessage", {
                                        attrs: {
                                          message: _vm.errors.first("email"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "w-full" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mt-4 mb-2" },
                                  [_vm._v(" Country ")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.submission.country,
                                      expression: "submission.country",
                                    },
                                  ],
                                  staticClass:
                                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter Country Here...",
                                  },
                                  domProps: { value: _vm.submission.country },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.submission,
                                        "country",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", [
                              _c(
                                "p",
                                { staticClass: "text-normal-bold mt-12" },
                                [
                                  _vm._v(
                                    " Does the commenter want to receive email outcome of this proposal? "
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "mt-4" }, [
                                _vm._v(
                                  " Please note that if you select 'Yes' they will be advised of any decisions regarding this proposal via email. "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex flex-row text-normal" },
                                [
                                  _c(
                                    "RadioButton",
                                    {
                                      staticClass: "flex-0 mr-6 mt-3 mb-1",
                                      attrs: {
                                        name: "supportive-no",
                                        checkedValue: true,
                                      },
                                      model: {
                                        value: _vm.submission.notify_outcome,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.submission,
                                            "notify_outcome",
                                            $$v
                                          )
                                        },
                                        expression: "submission.notify_outcome",
                                      },
                                    },
                                    [_vm._v("Yes")]
                                  ),
                                  _c(
                                    "RadioButton",
                                    {
                                      staticClass: "flex-0 mr-6 mt-3 mb-1",
                                      attrs: {
                                        name: "supportive-yes",
                                        checkedValue: false,
                                      },
                                      model: {
                                        value: _vm.submission.notify_outcome,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.submission,
                                            "notify_outcome",
                                            $$v
                                          )
                                        },
                                        expression: "submission.notify_outcome",
                                      },
                                    },
                                    [_vm._v("No")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex flex-grow" },
                              [
                                _c(
                                  "Checkbox",
                                  {
                                    staticClass: "w-full pl-0",
                                    model: {
                                      value:
                                        _vm.submission
                                          .personalInformationCollectionNotice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submission,
                                          "personalInformationCollectionNotice",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "submission.personalInformationCollectionNotice",
                                    },
                                  },
                                  [
                                    [
                                      _vm._v(
                                        " Please tick to acknowledge the "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " GNB Personal Information Collection Notice "
                                          ),
                                        ]
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c("div", {
                              staticClass:
                                "dividing-line-h w-full pt-4 pb-8 mb-8",
                            }),
                            _c(
                              "div",
                              { staticClass: "flex w-full justify-end" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass:
                                      "button-red-hollow w-2/6 mt-4 mb-10",
                                    attrs: {
                                      disabled:
                                        _vm.$wait.is("submittingComment"),
                                    },
                                    on: { click: _vm.backToProposals },
                                  },
                                  [_vm._v("Cancel Comment")]
                                ),
                                _c(
                                  "ButtonWithSpinner",
                                  {
                                    staticClass:
                                      "button-blue w-2/6 mt-4 mb-10 ml-4",
                                    attrs: {
                                      isSpinning:
                                        _vm.$wait.is("submittingComment"),
                                      disabled:
                                        _vm.$wait.is("submittingComment") ||
                                        !_vm.isFormValid ||
                                        !_vm.submission
                                          .personalInformationCollectionNotice,
                                    },
                                    on: { click: _vm.submitComment },
                                  },
                                  [_vm._v("Submit Comment")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }