<template>
  <div class="pl-4 flex flex-auto flex-row list-item" v-if="!!organisation">
    <div class="flex sm:flex-col sm:items-start flex-no-grow w-1/4 py-4">
      <div
        class="flex-auto flex justify-between content-between items-center w-full"
      >
        <div
          class="sm:hidden xl:flex w-12 h-12 rounded-full mr-4 bg-grey-lighter  justify-center items-center flex-none"
        >
          <div class="flex-none">
            <font-awesome-icon icon="tree" class="text-2xl" />
          </div>
        </div>
        <div class="flex-grow flex flex-col justify-start items-start">
          <router-link
            tag="p"
            class="flex-auto pr-2 font-bold cursor-pointer"
            data-cypress="organisation-name-link"
            :to="{
              name: 'organisationsView',
              params: { identifier: organisation.identifier }
            }"
          >
            {{ organisation.organisation_name }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="dividing-line-v-2 mx-4"></div>
    <div
      class="py-4 flex-grow flex  sm:flex-row flex-wrap items-stretch justify-around content-start"
    >
      <div class="flex-1   flex flex-col justify-start">
        <div class="flex-auto text-label">
          <div class="flex-no-grow flex flex-col break-all">
            <a
              data-cypress="organisation-email-link"
              class="text-black pb-2 pr-4 flex-1 flex align-center cursor-default"
              ><font-awesome-icon
                icon="envelope"
                class="pr-2 text-2xl flex-none"
              />
              Address
            </a>
            <span class="h-18"
              >{{ organisation.address_line_1 }}<br />
              {{ organisation.address_line_2
              }}<br v-if="organisation.address_line_2" />
              {{ organisation.organisation_suburb }} <br />
              {{ organisation.organisation_state }}
              {{ organisation.organisation_postcode }}</span
            >
          </div>
        </div>
      </div>

      <organisationProperty
        name="Users"
        :value="organisation.members"
        class="flex-0 flex flex-col justify-start items-center"
        data-cypress="date-created"
      />
      <organisationProperty
        name="Pending Proposals"
        :value="organisation.proposals"
        class="flex-1 flex flex-col justify-start items-center"
        data-cypress="date-created"
      />

      <!-- account status -->
      <div class="flex-1   flex flex-col justify-center">
        <AccountStatus
          :account_status="organisation.organisation_status"
          data-cypress="organisation-account"
        />
      </div>
    </div>
    <div class="dividing-line-v-2 ml-4"></div>
    <div class="flex-no-grow flex flex-col sm:items-end sm:justify-center">
      <!-- actions -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import organisationProperty from "./organisationProperty";

export default {
  name: "organisationListItem",
  components: {
    organisationProperty
  },
  props: ["organisation"]
};
</script>

<style scoped>
.list-item {
  min-height: 6em;
}

.telephone-link {
  font-weight: normal;
  color: black !important;
}

.email {
  color: black !important;
}
</style>
