<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div class="w-2/3 bg-white mx-auto my-3 z-10 rounded">
      <showLoading isLoading="fetchingSubmittedProposalWithComment">
        <template v-slot:component>
          <div
            v-if="proposal"
            class="w-full bg-white text-black text-center rounded-lg p-14"
          >
            <img src="../../../../assets/Logos/gnb.jpg" class="gnb-logo mt-8" />
            <h3 class="pb-3 mb-4 w-full text-title">
              Your comment on {{ proposal.geographical_name }} has been
              submitted
            </h3>
            <p>
              Your comments will be considered in the development of the
              proposal. Once finalised, you can view the outcome of the proposal
              on the GNB website.
            </p>
            <div class="flex justify-center mt-8 mb-8">
              <Button
                class="button-blue-hollow  mr-3"
                data-cypress="return"
                :disabled="false"
                @click="backToProposals()"
                >Return to Current Proposals Page
              </Button>
            </div>
          </div>
        </template>
      </showLoading>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";

import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "commentSubmitted",
  data() {
    return {
      proposal: null
    };
  },
  async created() {
    await this.fetchProposal();
  },
  computed: {
    advertisementId() {
      return this.$route.params.advertisementId;
    }
  },
  methods: {
    backToProposals() {
      this.$router.push({ name: "currentPlaceNameProposals" });
    },
    goToRegisterScreen() {
      this.$router.push({ name: "register" });
    },
    fetchProposal: waitFor(
      "fetchingSubmittedProposalWithComment",
      async function() {
        try {
          const proposal = await pnpCRUD.getAdvertisedProposal(
            this.advertisementId
          );
          this.proposal = proposal;
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Unable to retrieve proposal",
            text: error
          });
        }
      }
    )
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}
.gnb-logo {
  height: 100px;
}
</style>
