var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("showLoading", {
    attrs: { isLoading: _vm.isLoading },
    scopedSlots: _vm._u([
      {
        key: "component",
        fn: function () {
          return [
            _c(
              "keep-alive",
              [
                _c("router-view", {
                  directives: [
                    {
                      name: "wait",
                      rawName: "v-wait:hidden",
                      value: _vm.isLoading,
                      expression: "isLoading",
                      arg: "hidden",
                    },
                  ],
                  attrs: { organisation: _vm.organisation },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }