<template>
  <div class="autocomplete">
    <input
      class="w-full rounded-lg p-4 text-normal input-autocomplete"
      data-cypress="select-entity-input"
      v-on:change="selectEntity($event.target.value)"
      list="entityList"
      name="entityList"
      @input="filterDataList"
      :value="localName"
    />
    <datalist id="entityList">
      <option v-for="item in dataList" :key="item.index">{{
        item.name
      }}</option>
    </datalist>
  </div>
</template>

<script>

export default {
  name: "entitySelect",
  props: {
    entityList: {
      type: Array
    },
    rna: {
      type: String
    },
    isRoad: {
      type: Boolean
    },
    isOthers: {
      type: Boolean
    },
    entityName: {
      type: String
    },
    showAll: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      dataList: [],
      filteredEntityList: [],
      localName: ""
    };
  },
  watch: {
    isRoad() {
      this.updateEntityList();
    },
    isOthers() {
      this.updateEntityList();
    },
    entityName(newVal) {
      this.localName = newVal;
    }
  },
  methods: {
    updateEntityList() {
      this.filteredEntityList = this.showAll
        ? this.entityList
        : this.entityList.reduce((rnas, rna) => {
            if (this.isOthers && this.isRoad) {
              if (
                typeof rna.pnid === "number" &&
                typeof rna.rnid === "string"
              ) {
                rnas.push(rna);
                return rnas;
              } else return rnas;
            } else if (this.isOthers && typeof rna.pnid === "number") {
              rnas.push(rna);
              return rnas;
            } else if (this.isRoad && typeof rna.rnid === "string") {
              rnas.push(rna);
              return rnas;
            }
            return rnas;
          }, []);
    },
    selectEntity(entityName) {
      const entityData = this.filteredEntityList.find(
        rna => rna.name === entityName
      );
      if (!entityData) {
        const payload = {
          name: entityName
        };
        this.$emit("change", payload);
      } else {
        const payload = {
          ...entityData
        };
        this.$emit("change", payload);
      }
    },
    filterDataList(e) {
      this.localName = e.target.value;
      let pattern = new RegExp("^" + this.localName, "i");
      this.dataList = this.filteredEntityList.filter(entity => {
        return entity.name.match(pattern);
      });
    }
  },
  mounted() {
    this.updateEntityList();
    this.localName = this.entityName;
  }
};
</script>

<style scoped>
.autocomplete {
  background-color: #ecf1f3;
  border-radius: 4px;
}

.input-autocomplete:focus {
  outline: none;
}

.input-autocomplete {
  background-color: rgba(0, 0, 0, 0);
}

datalist {
  display: none;
}
/* specifically hide the arrow on focus */
.input-autocomplete::-webkit-calendar-picker-indicator {
  display: none;
}

option {
  /* font-weight: 900 !important; */
  display: block;
  line-height: 1.5em;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
</style>
