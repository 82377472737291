<template>
  <div class="base-container">
    <div
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3"
    >
      <section class="justify-center w-full block">
        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Road Name Evaluation
        </h3>
        <p class="mb-4">Check the eligibility of a Proposed Road Name.</p>
        <p class="mb-4">This is not a formal approval. All road name proposals must be submitted by a legislated Road Naming Authority to the GNB Secretariat for a more comprehensive evaluation against the guidelines of the NSW Address Policy and User Manual.</p>
        <div class="flex mb-5">
          <!-- Form textbox / inputs section -->
          <div class="w-full flex content-center items-start mt-8">
            <div class="pr-4 w-1/2 items-start">
              <p class="text-normal-bold mb-2">
                <span class="text-red-star">*</span>Local Government Area
              </p>
              <norns-select
                class="w-full mr-4 norns-select"
                v-model="lgaName"
                :multiple="false"
                :options="lgasClean"
                :searchable="true"
                :close-on-select="true"
                placeholder="Select LGA..."
                name="LGA"
                data-cypress="lga-select"
                v-validate="'required'"
                data-vv-as="Local Government Authority"
                :disabled="$wait.is('sending email')"
                :select-on-tab="true"
              >
                <span slot="noOptions">Loading...</span>
              </norns-select>
              <FieldErrorMessage :message="errors.first('LGA')" />
            </div>
            <div class="pl-4 w-1/2 flex flex-col">
              <p class="text-normal-bold mb-2">
                <span class="text-red-star">*</span>Address Locality (Suburb)
              </p>
              <norns-select
                :disabled="!lgaName || $wait.is('sending email')"
                class="w-full mr-4 norns-select"
                v-model="suburbName"
                :multiple="true"
                :options="suburbOptions"
                :searchable="true"
                :close-on-select="false"
                :show-labels="true"
                :hide-selected="true"
                placeholder="Pick a Suburb"
                name="suburb"
                data-cypress="suburb-field"
                v-validate="'required'"
                data-vv-as="Suburb"
                :select-on-tab="true"
              >
                <span slot="noOptions">Loading...</span></norns-select
              >
              <FieldErrorMessage :message="errors.first('suburb')" />
            </div>
          </div>
        </div>

        <div class="flex mb-5">
          <!-- Form textbox / inputs section -->
          <div class="w-full flex content-center items-start mt-8">
            <div class="pr-4 w-1/2 items-start">
              <p class="text-normal-bold mb-2">
                <span class="text-red-star">*</span>Proposed Road Name
              </p>
              <input
                type="text"
                :disabled="
                  !lgaName || !suburbName.length || $wait.is('sending email')
                "
                class="w-full mr-4 input-area mb-1 mt-1"
                data-cypress="roadname-field"
                placeholder="Enter Name Here..."
                name="Proposed Road Name"
                id="roadName"
                v-validate.continues="'required'"
                v-model.trim="roadName"
              />
            </div>
            <div class="pl-4 w-1/2 flex flex-col">
              <p class="text-normal-bold mb-2">
                <span class="text-red-star">*</span>Radius
                <norns-select
                  :disabled="!lgaName || $wait.is('sending email')"
                  class="w-full mr-4 mt-3 norns-select"
                  v-model="distance"
                  :multiple="false"
                  :options="distanceOptions"
                  :reduce="d => d.value"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="true"
                  :hide-selected="true"
                  placeholder="Pick a radius"
                  name="distance"
                  data-cypress="radius-field"
                  v-validate="'required'"
                  :select-on-tab="true"
                >
                  <span slot="noOptions">Loading...</span></norns-select
                >
              </p>
            </div>
          </div>
        </div>

        <div class="flex mb-5">
          <!-- Form textbox / inputs section -->
          <div class="w-full flex content-center items-start mt-8">
            <div class="pr-4 items-start">
              <p class="text-normal-bold mb-2">
                Road Name Extent
              </p>
              Please draw the extent of the proposed road on the map.
              <ul>
                <li>
                  Select either the polyline
                  <img
                    src="/img/icons/polyline-110x80.png"
                    style="width:40px;"
                  />
                  or polygon
                  <img src="/img/icons/polygon-92x92.png" style="width:32px;" />
                  tool from within the map
                </li>
                <li>Left click to mark the start of the road</li>
                <li>Add additional points to mark out the road</li>
                <li>Double click for the extent to be completed</li>
                <li>Click Evaluate to undertake a road name evaluation</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <WebMap
            id="WebMap"
            ref="WebMapRef"
            style="height: 450px"
            :lgaName="lgaName"
            :suburbs="suburbName"
            :distance="distance"
            v-on:draw-complete="onDrawComplete"
          >
          </WebMap>
        </div>

        <div>
          <ButtonWithSpinner
            class="button-blue mb-1 mt-1"
            @click="evaluate"
            :isSpinning="$wait.is('evaluatingName')"
            :disabled="
              $wait.is('evaluatingName') ||
                !(
                  roadName &&
                  distance &&
                  Object.keys(suburbName).length &&
                  Object.keys(geometry).length
                )
            "
          >
            Evaluate
          </ButtonWithSpinner>
        </div>

        <div class="flex flex-col mb-5">
          <p
            class="flex-grow text-black text-sm font-bold text-center"
            v-if="$wait.is('evaluatingName')"
          >
            Checking Road name validity
          </p>
          <ShowLoading isLoading="evaluatingName">
            <template v-slot:component>
              <div class="flex flex-grow items-start" v-if="!emailError">
                <div
                  v-if="evaluated && evaluation && !!evaluation.breaches"
                  class="flex-grow"
                >
                  <div class="text-orange-dark font-bold text-lg">
                    {{ evaluation.breaches.issueCount }} potential issue(s)
                    found
                  </div>
                  <div
                    v-for="failedGuideline in evaluation.breaches.failures"
                    :key="failedGuideline.guideLineType"
                  >
                    <div class="text-orange font-bold pt-4">
                      {{ failedGuideline.title }}
                    </div>
                    <MessagePanel
                      class="flex-grow"
                      v-for="(message, index) in failedGuideline.issues"
                      :key="`${failedGuideline.guideLineType}-${index}`"
                      warning
                      :msg="message.shortDescription"
                      :mapURL="message.mapURL"
                      :geometry="message.geometry"
                      v-on:increment-btn="showLine"
                      :guideLineType="failedGuideline.guideLineType"
                    />
                    <MessagePanel
                      class="flex-grow"
                      v-if="
                        !failedGuideline.issues ||
                          failedGuideline.issues.length < 1
                      "
                      :key="failedGuideline.guideLineType"
                      warning
                      :msg="failedGuideline.shortDescription"
                      :mapURL="failedGuideline.mapURL"
                      :geometry="failedGuideline.geometry"
                      v-on:increment-btn="showLine"
                      :guideLineType="failedGuideline.guideLineType"
                    />
                  </div>
                </div>
                <div v-else-if="evaluated && evaluation.passes == true">
                  <MessagePanel
                    class="flex-grow"
                    :msg="'0 potential issues found'"
                    ok
                  />
                </div>
              </div>
              <div class="flex flex-1 items-start" v-if="!!emailError">
                <MessagePanel
                  class="flex-grow"
                  error
                  :msg="emailError"
                  v-if="emailError"
                />
              </div>
            </template>
          </ShowLoading>
        </div>
      </section>
      <div class="flex" name="3 buttons">
        <ButtonWithSpinner
          class="button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto"
          @click="resetSearchForm"
          :isSpinning="false"
          :disabled="$wait.is('sending email') || $wait.is('evaluatingName')"
          >Reset</ButtonWithSpinner
        >
        <ButtonWithSpinner
          class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
          @click="emailResults"
          :isSpinning="$wait.is('sending email')"
          :disabled="
            $wait.is('sending email') ||
              $wait.is('evaluatingName') ||
              !isValidForm
          "
          >Email Results</ButtonWithSpinner
        >
      </div>
    </div>
    <modal name="results">
      <div
        class="base-container text-black h-auto flex flex-col pt-6 px-2 pb-14"
        data-cypress="evaluation-modal"
      >
        <div
          class="flex flex-col items-center content-center justify-center mt-8 mb-2"
        >
          <div v-if="!emailError" class="text-center">
            <img src="@/assets/Images/Icon_Gazettal.png" class="h-24" />
            <p class="flex-grow text-lg">
              Your evaluation report has been sent
            </p>
            <Button
              @click="closeEvaluationModal"
              class="button-blue mt-4 flex-grow"
              >Okay</Button
            >
          </div>
          <p class="flex-grow" v-if="emailError">
            <font-awesome-icon
              class="text-red"
              icon="exclamation"
            ></font-awesome-icon
            >&nbsp;There was an error sending your evaluation report
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import lgaListMixin from "../../../../mixins/lga-list-filter";
import { evaluateRoadnameNew } from "../../../../helpers/validations.js";
import { getSuburbs } from "../../../../helpers/servicesq.js";
import { sendPublicEvaluationReport } from "../../../../helpers/rnpCRUD";
import MessagePanel from "../../place/messagePanel";
import evaluationModal from "./evaluationModal.vue";
import WebMap from "./WebMap.vue";
//import MapView from "esri-loader.views";

export default {
  mixins: [lgaListMixin],
  components: {
    MessagePanel,
    WebMap
  },
  data() {
    return {
      roadname_classification: "",
      suburbName: "",
      lgaName: "",
      roadName: "",
      suburbOptions: [],
      distance: 0,
      distanceOptions: [
        { label: "10km - Metropolitan", value: 10000 },
        { label: "20km - Regional Urban", value: 20000 },
        { label: "30km - Rural", value: 30000 }
      ],
      geometry: {},
      evaluation: {},
      evaluated: false,
      emailError: false
    };
  },
  watch: {
    lgaName: {
      async handler(newVal, oldVal) {
        this.getSuburbsForLGA(newVal);
        if (newVal !== oldVal && oldVal !== "") {
          this.suburbName = [];
          this.geometry = {};
          if (this.$refs.WebMapRef) {
            this.$refs.WebMapRef.doClearAll();
          }
        }
        this.evaluated = false;
        this.evaluation = {};
      },
      immediate: true
    },
    suburbName() {
      this.evaluated = false;
      this.evaluation = {};
    },
    roadName() {
      this.evaluated = false;
      this.evaluation = {};
    },
    distance() {
      this.evaluated = false;
      this.evaluation = {};
      this.$refs.WebMapRef.clearRadius();
    }
  },
  computed: {
    isPublic() {
      return this.$route.meta && this.$route.meta.isPublic;
    },
    isDisabled() {
      return this.errors.any();
    },
    isValidRoadName() {
      return (
        this.evaluation &&
        !this.evaluation.issues.length &&
        this.roadName &&
        this.roadName.length
      );
    },
    isValidForm() {
      return (
        !!this.roadName &&
        !!this.roadName.length &&
        !!this.distance &&
        this.evaluated
      );
    },
    lgasClean() {
      return this.lgas.filter(lga => lga !== null && lga !== "");
    },
    numIssues() {
      if (this.evaluation.issues) {
        const mappedIssueCounts = Object.keys(this.evaluation.issues).map(
          key => this.evaluation.issues[key].length
        );
        return mappedIssueCounts.reduce((a, b) => a + b);
      }
      return 0;
    }
  },
  methods: {
    resetSearchForm() {
      this.roadname_classification = "";
      this.suburbName = [];
      this.lgaName = "";
      this.roadName = "";
      this.distance = 0;
      this.emailError = false;
      this.evaluation = { issues: [] };
      this.evaluated = false;
      this.$validator.reset();
      this.$refs.WebMapRef.doClearAll();
    },
    closeEvaluationModal() {
      this.$modal.hide("results");
      //this.resetSearchForm();
    },
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      this.suburbOptions = suburbs.filter(sub => sub !== null && sub !== "");
    },
    getEmail(road) {
      return new Promise(resolve => {
        this.$modal.show(
          evaluationModal,
          { road: road, answer: resolve },
          {
            height: "auto",
            clickToClose: false
          }
        );
      });
    },
    async emailResults() {
      try {
        this.$wait.start("sending email");
        const email = await this.getEmail({
          name: this.roadName,
          roadname_classification: this.roadname_classification
        });

        if (!email) return;

        await sendPublicEvaluationReport(email, {
          roadname_classification: this.roadname_classification,
          suburbName: this.suburbName,
          location: this.suburbName,
          lgaName: this.lgaName,
          roadName: this.roadName,
          distance: this.distance,
          geometry: this.geometry
        });

        this.$modal.show("results", {
          beforeClose: () => {
            this.emailError = "";
          },
          height: "auto"
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.emailError =
          "There has been a problem emailing your evaluation report";
      } finally {
        this.$wait.end("sending email");
      }
    },
    async evaluate() {
      this.$wait.start("evaluatingName");
      //this.distance = "10000";
      console.log("Evaluate was clicked");

      this.$refs.WebMapRef.doShowRadius();

      //this.showRadius = true;
      try {
        const evalResponse = await evaluateRoadnameNew(
          this.roadName,
          this.lgaName,
          this.suburbName,
          this.distance,
          this.geometry
        );
        this.evaluation = evalResponse;
        this.evaluated = true;
      } catch (e) {
        this.$notify({
          message: e
        });
      }
      this.$wait.end("evaluatingName");
    },
    onDrawComplete(geometry) {
      console.log("geometry = ", geometry);
      this.geometry = geometry;
      this.evaluated = false;
      this.evaluation = {};
    },
    showLine(geometry) {
      this.$refs.WebMapRef.doShowRoad(geometry);

      document.querySelector(".backgroundDiv").scrollTo({
        top: 650,
        behavior: "smooth"
      });
      //this.geometry = geometry.geometry;
    }
  }
};
</script>
