var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-4 w-full",
      class: _vm.classNames,
      attrs: { id: "uploadDiv" },
    },
    [
      _c("p", { staticClass: "pb-3 text-normal-bold" }, [
        _vm.important
          ? _c("a", { staticClass: "text-red-star" }, [_vm._v("*")])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
      _c("div", { staticClass: "flex w-full" }, [
        _c("label", { staticClass: "flex w-full" }, [
          _c(
            "div",
            {
              staticClass:
                "w-7/8 flex p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded",
            },
            [
              _vm._v(" Click or drag files here to upload... "),
              _c("input", {
                staticClass: "hidden",
                attrs: { type: "file" },
                on: {
                  change: function ($event) {
                    return _vm.filesChange($event.target.files)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "bg-blue-button rounded w-1/8 flex justify-center items-center cursor-pointer text-white text-normal-bold",
            },
            [_vm._v(" Add Files ")]
          ),
        ]),
      ]),
      _vm._l(_vm.uploadedFile, function (file, index) {
        return _c("div", { key: index, staticClass: "mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "w-full mt-5 flex items-center rounded-lg border border-grey-border",
            },
            [
              _c("img", {
                staticClass: "mx-3 m-1",
                attrs: { src: require("../../assets/Images/Icon-xls.png") },
              }),
              _c("p", { staticClass: "textNormal" }, [
                _vm._v(_vm._s(file.name)),
              ]),
              _c(
                "a",
                {
                  staticClass:
                    "cursor-pointer ml-auto text-normal-bold text-blue-button",
                },
                [_vm._v("View File")]
              ),
              _c(
                "a",
                {
                  staticClass:
                    "cursor-pointer ml-10 mr-2 text-normal-bold text-red-button",
                  on: {
                    click: function ($event) {
                      return _vm.removeFile(index)
                    },
                  },
                },
                [_vm._v("Remove")]
              ),
            ]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }