import { render, staticRenderFns } from "./locality.vue?vue&type=template&id=601fa920&scoped=true&"
import script from "./locality.vue?vue&type=script&lang=js&"
export * from "./locality.vue?vue&type=script&lang=js&"
import style0 from "./locality.vue?vue&type=style&index=0&id=601fa920&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "601fa920",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('601fa920')) {
      api.createRecord('601fa920', component.options)
    } else {
      api.reload('601fa920', component.options)
    }
    module.hot.accept("./locality.vue?vue&type=template&id=601fa920&scoped=true&", function () {
      api.rerender('601fa920', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-form/name-details-and-origin/components/locality.vue"
export default component.exports