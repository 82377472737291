<template>
  <showLoading isLoading="fetching users">
    <template v-slot:component>
      <div class="flex flex-col flex-grow" data-cypress="user-list">
        <div
          v-for="(user, index) in users"
          v-bind:key="index"
          class="flex flex-grow flex-row bg-white text-black rounded-lg mt-4 overflow-hidden"
        >
          <UserListItem :user="user">
            <slot :user="user"></slot>
          </UserListItem>
        </div>

        <div
          v-if="!users.length"
          class="flex flex-grow align-center justify-center mt-8"
        >
          <h3 class="flex-0 text-black">No Users found.</h3>
        </div>
      </div>
    </template>
  </showLoading>
</template>

<script>
import UserListItem from "./userListItem";
import showLoading from "../elements/showLoading";

export default {
  name: "UserList",
  components: {
    showLoading,
    UserListItem
  },
  props: ["users"]
};
</script>

<style scoped>
.actions {
  color: #002664;
}
#userSVG {
  fill: grey;
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}

.dividing-line-v-2 {
  border-left-width: 1px;
}

.wait-container {
  padding-top: 0;
}
</style>
