var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    { attrs: { cardTitle: "Gazettal", eventDate: _vm.entryData.createdAt } },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v(" The proposal gazette date was set to. "),
        ]),
        _c("p", [
          _c("b", [_vm._v(_vm._s(_vm.format(_vm.entryData.dateGazetted.to)))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }