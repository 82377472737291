"use strict";

import _orderBy from "lodash.orderby";
import { mapGetters, mapActions } from "vuex";

const roadTypeListMixin = {
  async created() {
    if (!this.roadTypeList.length) {
      await this.fetchRoadTypes();
    }
  },
  data() {
    return {
      roadTypeFilter: ""
    };
  },
  computed: {
    ...mapGetters(["roadTypeList"]),
    roadTypes() {
      const filter = this.roadTypeFilter;
      const listing = this.roadTypeList
        .filter(roadType => new RegExp(`^${filter}`, "i").test(roadType.label))
        .filter(roadType => roadType.isActive)
        .filter(rt => rt.label !== null && rt.label !== "");

      return _orderBy(listing, ["label"], ["asc"]);
    },
    roadTypesClean() {
      return this.roadTypeList
        .filter(rt => {
          return rt.label !== null && rt.label !== "";
        })
        .filter(rt => rt.isActive)
        .map(rt => rt.label)
        .sort();
    }
  },
  methods: {
    ...mapActions(["fetchRoadTypes"]),
    setRoadTypeFilter(val) {
      this.roadTypeFilter = val;
    }
  }
};

export default roadTypeListMixin;
