var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass:
        "inline-block rounded-full px-4 py-2 text-sm font-bold ml-auto mr-auto text-center capitalize",
      class: _vm.account_status,
    },
    [_vm._v(_vm._s(_vm.account_status))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }