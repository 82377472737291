var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c(
        "div",
        { staticClass: "flex items-start justify-center text-red-button" },
        [
          _c("ErrorIcon", { staticClass: "flex-0 mt-1" }),
          _vm.error.code === "NotAuthorizedException"
            ? _c("span", { staticClass: "text-error flex-1" }, [
                _vm.error.message === "User is disabled" ||
                _vm.error.message === "User is disabled."
                  ? _c("span", { staticClass: "text-error flex-1" }, [
                      _vm._v("Your account is suspended. Please contact "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "mailto:ss-gnb@customerservice.nsw.gov.au",
                          },
                        },
                        [_vm._v("GNB")]
                      ),
                    ])
                  : _vm._e(),
                _vm.error.message === "Incorrect username or password."
                  ? _c("span", { staticClass: "text-error flex-1" }, [
                      _vm._v("Incorrect username or password."),
                    ])
                  : _vm._e(),
              ])
            : _vm.error.code === "UserNotConfirmedException"
            ? _c("span", { staticClass: "text-error flex-1" }, [
                _vm._v(
                  "You cannot login until your account has been confirmed by the GNB Secretariat."
                ),
              ])
            : _c("span", { staticClass: "text-error flex-1" }, [
                _vm._v("We could not find you in our system."),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }