<template>
  <div class="base-container pt-16">
    <!-- <suspendModal/> -->

    <section class="pt-1 justify-center w-full block">
      <div class="flex justify-between"></div>
      <h2 class="text-title text-black self-center">
        Submit Place Name Proposal
      </h2>
      <div
        class="w-full bg-white text-black rounded-lg mt-2 justify-center flex flex-wrap h-1/2"
      >
        <div>
          <div class="w-full justify-center flex">
            <img
              src="../../../assets/Images/Icon_Gazettal.png"
              class="w-48 h-48 mt-20 mb-6"
            />
          </div>
          <h3 class="text-title mb-20 font-bold">
            Your Place Name Proposal has been submitted for consideration.
          </h3>
        </div>
      </div>

      <div class="flex pt-4 text-center pb-10 mt-2">
        <router-link
          :to="{ name: 'placeNameProposals' }"
          class="w-1/3 button-blue-hollow"
          data-cypress="proposals-dashboard-link"
          >Go to My Proposals Dashboard</router-link
        >
        <router-link
          :to="{
            name: 'placeNameProposal',
            params: { proposalId: 'create' }
          }"
          class="ml-auto w-1/3 button-blue"
          data-cypress="create-another-link"
          >Create Another Place Name Proposal</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {}
};
</script>
