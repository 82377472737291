var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c("showLoading", {
            attrs: { isLoading: "loadingProposal" },
            scopedSlots: _vm._u(
              [
                {
                  key: "component",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _vm.isDraft
                            ? _c("createProposal", {
                                attrs: {
                                  proposalBucket: _vm.roadNameProposalBucket,
                                  isDraft: _vm.isDraft,
                                },
                              })
                            : _vm._e(),
                          !_vm.isDraft
                            ? _c("viewProposal", {
                                attrs: {
                                  proposalBucket: _vm.roadNameProposalBucket,
                                  reloadPage: _vm.reloadPage,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2184800399
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }