<template>
  <entry
    cardTitle="Roadname Rejection Appeal Decision"
    :eventDate="entryData.createdAt"
  >
    <div>
      <p class="mb-2">Decision reached on the roadname rejection appeal.</p>
      <strong>{{ entryData.appealDecision.to }}</strong>
      <p class="mt-2 mb-2">SG decision comment:</p>
      <p class="mt-2">
        <strong>{{ entryData.decisionComment.to }}</strong>
      </p>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "RoadNameRejectionAppealDecisionTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
