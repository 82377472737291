import API from "@/helpers/api";
const API_NAME = "dfsiApplicationAPI";
const BASE_PATH = "/s3/private-bucket-credentials";

export function getCredentials(key, filename) {
  const payload = {
    body: {
      id: key,
      filename
    }
  };

  return API.post(API_NAME, BASE_PATH, payload);
}
export function deleteFile(s3Key) {
  const payload = {
    body: {
      s3Key
    }
  };
  return API.del(API_NAME, "/file/road-names", payload);
}

export function deleteSupportingDocument(s3Key) {
  const payload = {
    body: {
      s3Key
    }
  };
  return API.del(API_NAME, "/file/delete-supporting-document", payload);
}
