<template>
  <div class="base-container text-black">
    <span class="flex items-center">
      <a
        @click="$router.go(-1)"
        class="cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center"
      >
        <BackArrowWithTitle title="Organisation - Manage Members" />
      </a>
    </span>
    <p class="text-normal-bold mt-6">Organisation members</p>

    <div class="w-full flex items-center mt-3">
      <select
        v-model="newMemberId"
        class="bg-white w-full input-area"
        data-cypress="addmember-field"
      >
        <option value disabled hidden selected="selected"
          >Select Authority User</option
        >
        <option
          class="bg-white w-full input-area"
          v-for="(user, index) in usersWithoutOrg"
          v-bind:key="index"
          v-bind:value="user.identifier"
          >{{ user.first_name }} {{ user.last_name }}</option
        >
      </select>
      <button
        @click="addOrganisationMember"
        :disabled="!this.newMemberId"
        class="greenButton py-4 px-10 ml-4 whitespace-no-wrap"
        data-cypress="addmember-button"
      >
        Add to Organisation
      </button>
    </div>

    <div
      class="w-full bg-white rounded-lg flex mt-5 items-center"
      v-for="(user, index) in organisation.members"
      v-bind:key="index"
    >
      <img src="../../assets/Images/Icon_User.png" class="w-12 h-12 m-4" />
      <p class="text-title mr-4 w-1/6">
        {{ user.first_name }} {{ user.last_name }}
      </p>
      <StatusButton
        :label="user.user_account_status"
        :status="user.user_account_status"
        class="ml-12 mr-6 py-1 px-10 text-normal font-bold"
      />
      <button
        @click="removeOrganisationMember(user.identifier)"
        class="removeText ml-auto mr-8"
      >
        Remove
      </button>
    </div>

    <div class="flex my-6"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateUserOrganisation } from "../../helpers/organisationCRUD.js";

export default {
  props: ["organisation"],
  async mounted() {
    try {
      await this.getUsersWithoutOrg();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  computed: {
    ...mapGetters(["usersWithoutOrg"])
  },
  data() {
    return {
      removedMembers: [],
      newMemberId: "",
      newMember: [],
      usersList: []
    };
  },

  methods: {
    ...mapActions(["getOrganisation", "getUsersWithoutOrg"]),
    async addOrganisationMember() {
      try {
        let payload = {
          user_id: this.newMemberId,
          organisation_id: this.organisation.identifier
        };
        await updateUserOrganisation(payload);

        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "User Added to organisation"
        });
        await this.getOrganisation(this.$route.params.id);
        await this.getUsersWithoutOrg();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error adding user",
          text: "Error"
        });
      }
    },
    async removeOrganisationMember(id) {
      try {
        let payload = {
          user_id: id,
          organisation_id: null
        };
        await updateUserOrganisation(payload);

        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "User removed from organisation"
        });
        await this.getOrganisation(this.$route.params.id);
        await this.getUsersWithoutOrg();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Removing removing user",
          text: "Error"
        });
      }
    }
  }
};
</script>

<style scoped>
.greenButton {
  border-radius: 4px;
  background-color: #00a908;
  color: #ffffff;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}
.greenButton[disabled] {
  opacity: 0.7;
}
.removeText {
  color: #d7153a;
  font-family: Gotham;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.07px;
  line-height: 14px;
  text-align: right;
}
</style>
