<template>
  <div class="base-container text-black">
    <ShowLoading isLoading="loading rejection reason">
      <template v-slot:component>
        <NetworkError v-if="errorLoading" />
        <section
          class="justify-center w-full block"
          v-if="!errorLoading && roadNameProposal"
        >
          <div class="flex justify-between">
            <a @click="$router.go(-1)" class="flex justify-between self-center">
              <BackArrowWithTitle title="Appeal Road Name" />
            </a>
          </div>
          <div
            class="w-full flex bg-white text-black rounded-lg pl-8 pt-4 pb-4 mt-4 text-center"
          >
            <proposalIcon
              :label="roadNameProposal.roadname_classification"
              classNames="font-bold w-12 h-12"
            />
            <h3 class="self-center ml-4">
              {{ roadNameProposal.roadname }} {{ roadNameProposal.road_type }}
            </h3>
            <StatusButton
              :label="roadNameProposal.name_status"
              :status="roadNameProposal.name_status"
              class="ml-auto mr-8 font-bold"
            />
          </div>

          <div
            class="w-full mx-auto flex flex-col bg-white text-black rounded-lg pb-8 mt-4"
          >
            <div class="w-9/10 mx-auto flex flex-col">
              <span class="self-center pt-5 w-full">
                <h2 class="mt-6">Reason for Rejection</h2>
                <p class="self-center text-normal mt-2">
                  Submitted {{ dateSubmitted }}
                </p>
              </span>
              <span class="dividing-line-h mt-4 w-full"></span>
              <span class="self-center block w-full">
                <p class="self-center text-normal mt-5 pb-10">
                  {{ rejectionReason }}
                </p>
              </span>
            </div>
          </div>
          <div class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4">
            <div class="w-9/10 mx-auto flex flex-col">
              <p class="font-bold mb-2 mt-8 w-full">
                <span class="text-red-star">*</span>
                Reason for Appeal
              </p>

              <textarea
                class="text-normal overflow-y-auto h-52 text-left items-start rounded p-5 text-grey-text bg-grey-input w-full mb-4"
                v-model="appealReason"
                v-validate="'required'"
                name="Appeal Reason"
                :disabled="$wait.is('saving appeal') || uploading"
              ></textarea>
              <uploadComponent
                label="Attach supporting documentation"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                :disabled="$wait.is('saving appeal') || uploading"
                class="pb-10"
                :drafted="true"
                :deleteFile="deleteFunction"
              />
            </div>
          </div>
          <div class="flex pt-4 text-center pb-16 justify-end">
            <ButtonWithSpinner
              class="button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold"
              data-cypress="submit-appeal-button"
              :disabled="
                !appealReason ||
                  $wait.is('saving appeal') ||
                  roadNameProposal.roadname_classification.toLowerCase() ===
                    'pre-approval'
              "
              :isSpinning="$wait.is('saving appeal')"
              @click="submitAppeal"
              >Submit Appeal</ButtonWithSpinner
            >
          </div>
        </section>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters, mapMutations } from "vuex";
import proposalIcon from "../road/proposalIcon";

import { submitAppealJira } from "../../../helpers/rnpCRUD";
import { deleteSupportingDocument } from "../../../helpers/s3";

import { waitFor } from "vue-wait";

export default {
  name: "AppealRejection",
  components: {
    proposalIcon
  },

  async created() {
    await this.fetchData();
  },

  data() {
    return {
      uploading: false,
      errorLoading: false
    };
  },

  props: ["roadNameId"],

  computed: {
    ...mapGetters(["getAppealReason", "rejectedRoadInfo", "roadNameProposal"]),
    rejectionReason() {
      return this.rejectedRoadInfo
        ? this.rejectedRoadInfo.rejection_description
        : null;
    },
    appealReason: {
      get: function() {
        return this.getAppealReason;
      },
      set: function(newValue) {
        this.updateAppealReason(newValue);
      }
    },
    dateSubmitted() {
      // prettier-ignore
      return this.rejectedRoadInfo ? moment(this.rejectedRoadInfo.date_created).format(
        "DD MMM YYYY hh:mm:ss a z"
      ) : null;
    },
    uploadedFiles() {
      return this.$store.getters.uploadedFilesState;
    }
  },

  methods: {
    ...mapActions(["getRejectedRoadInfo", "getRoadNameProposalById"]),
    ...mapMutations(["updateAppealReason", "clearUploadState"]),
    deleteFunction: deleteSupportingDocument,
    fetchData: waitFor("loading rejection reason", async function() {
      this.errorLoading = false;
      try {
        await Promise.all([
          this.clearUploadState(),
          this.getRejectedRoadInfo(this.roadNameId),
          this.getRoadNameProposalById(this.roadNameId)
        ]);
      } catch (e) {
        this.errorLoading = true;
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }),
    submitAppeal: waitFor("saving appeal", async function() {
      try {
        await submitAppealJira({
          road: this.roadNameProposal,
          reason: this.appealReason,
          files: this.uploadedFiles
        });
        this.$notify({
          group: "toast",
          type: "success",
          title: "Appeal Submitted",
          text: "Appeal Submitted"
        });
        await this.clearUploadState();
        this.$store.commit("clearAppealState");
        this.$router.push({
          name: "submitAppeal",
          params: { road: this.roadNameProposal }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Submitting Roadname Appeal",
          text:
            "There has been an error submitting your appeal. Please try again later"
        });
      }
    })
  }
};
</script>

<style scoped></style>
