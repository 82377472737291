<template>
  <entry cardTitle="Change in status" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">The proposal status was changed to:</p>
      <strong>{{ entryData.statusLabel.to }}</strong>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "StatusChangeTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
