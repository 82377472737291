var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex bg-white mb-8 mx-64 px-16 pb-16 pt-8 mt-32" },
    [
      _c("showLoading", {
        attrs: { isLoading: "fetchingGeoname" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _vm.geoname
                  ? _c(
                      "div",
                      [
                        _c("h3", [_vm._v("View Record")]),
                        _c("hr", { staticClass: "divider w-full" }),
                        _c("div", { staticClass: "py-4" }, [
                          _c("h3", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.geoname.geographical_name)),
                            ]),
                          ]),
                        ]),
                        _vm.geoname.reference
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("Reference")]),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.geoname.reference)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.geoname.designation
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "block text-normal-bold mb-2 text-blue-button",
                                    attrs: {
                                      title:
                                        "View Glossary of designation values in the Geographical Names Register",
                                      target: "_blank",
                                      href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf",
                                    },
                                  },
                                  [_vm._v(" Designation")]
                                ),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.geoname.designation)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.geoname.status
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "block text-normal-bold mb-2 text-blue-button",
                                    attrs: {
                                      title:
                                        "View Glossary of status values in the Geographical Names Register",
                                      target: "_blank",
                                      href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf",
                                    },
                                  },
                                  [_vm._v(" Status ")]
                                ),
                              ]),
                              _c("span", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.geoname.dual_named ? "Dual - " : ""
                                    ) + _vm._s(_vm.geoname.status)
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.geoname.dual_geographical_name &&
                        _vm.geoname.dual_identifier
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("Dual Name")]),
                              ]),
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-blue-button",
                                    attrs: { role: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToPublicGeoname(
                                          _vm.geoname.dual_identifier
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.geoname.dual_geographical_name)
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.geoname.gazettal_dates &&
                        _vm.geoname.gazettal_dates.length
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c(
                                "span",
                                { staticClass: "w-64 inline-block align-top" },
                                [_c("b", [_vm._v("Gazettal Date")])]
                              ),
                              _vm.geoname.gazettal_dates &&
                              _vm.geoname.gazettal_dates.length
                                ? _c(
                                    "span",
                                    { staticClass: "inline-block" },
                                    _vm._l(
                                      _vm.geoname.gazettal_dates,
                                      function (gazettal_date, index) {
                                        return _c(
                                          "p",
                                          { key: `gazettal_date-${index}` },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatDate(
                                                    gazettal_date.date_gazetted
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.geoname.gnb_file_reference
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("GNB File No.")]),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.geoname.gnb_file_reference)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.geoname.lga && _vm.geoname.lga.length
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c(
                                "span",
                                { staticClass: "w-64 inline-block align-top" },
                                [_c("b", [_vm._v("LGA(s)")])]
                              ),
                              _vm.geoname.lga && _vm.geoname.lga.length
                                ? _c(
                                    "span",
                                    { staticClass: "inline-block" },
                                    _vm._l(_vm.geoname.lga, function (lga) {
                                      return _c("p", { key: lga }, [
                                        _vm._v(_vm._s(lga)),
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.geoname.lga_gazettal &&
                        _vm.geoname.lga_gazettal.length
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c(
                                "span",
                                { staticClass: "w-64 inline-block align-top" },
                                [_c("b", [_vm._v("LGA(s) at Gazettal")])]
                              ),
                              _c(
                                "span",
                                { staticClass: "inline-block" },
                                _vm._l(
                                  _vm.geoname.lga_gazettal,
                                  function (lga_gazettal) {
                                    return _c("p", { key: lga_gazettal }, [
                                      _vm._v(" " + _vm._s(lga_gazettal) + " "),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm.geoname.latitude && _vm.geoname.longitude
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("GDA2020 Lat")]),
                              ]),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.geoname.dms.lat.formattedString
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.geoname.longitude && _vm.geoname.latitude
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("GDA2020 Long")]),
                              ]),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.geoname.dms.lon.formattedString
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.geoname.map_cma
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("1:25,000 Map Name")]),
                              ]),
                              _c("span", [_vm._v(_vm._s(_vm.geoname.map_cma))]),
                            ])
                          : _vm._e(),
                        _vm.legacyMapData
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c("span", { staticClass: "w-64 inline-block" }, [
                                _c("b", [_vm._v("1:100,000 Map")]),
                              ]),
                              _c("span", [_vm._v(_vm._s(_vm.legacyMapData))]),
                            ])
                          : _vm._e(),
                        _vm.geoname.parish && _vm.geoname.parish.length
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c(
                                "span",
                                { staticClass: "w-64 inline-block align-top" },
                                [_c("b", [_vm._v("Parish")])]
                              ),
                              _vm.geoname.parish && _vm.geoname.parish.length
                                ? _c(
                                    "span",
                                    { staticClass: "inline-block" },
                                    _vm._l(
                                      _vm.geoname.parish,
                                      function (parish) {
                                        return _c("p", { key: parish }, [
                                          _vm._v(_vm._s(parish)),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.geoname.county && _vm.geoname.county.length
                          ? _c("div", { staticClass: "w-full py-1" }, [
                              _c(
                                "span",
                                { staticClass: "w-64 inline-block align-top" },
                                [_c("b", [_vm._v("County")])]
                              ),
                              _vm.geoname.county && _vm.geoname.county.length
                                ? _c(
                                    "span",
                                    { staticClass: "inline-block" },
                                    _vm._l(
                                      _vm.geoname.county,
                                      function (county) {
                                        return _c("p", { key: county }, [
                                          _vm._v(_vm._s(county)),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.geoname.description
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Description")]),
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.geoname.description))]),
                        _vm.geoname.official_map_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Official Map")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.official_map_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: `official_map_reference_document-${index}`,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.origin ||
                        _vm.geoname.origin_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Origin")]),
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.geoname.origin))]),
                        _vm._l(
                          _vm.geoname.origin_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `origin_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.history ||
                        _vm.geoname.historical_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("History")]),
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.geoname.history))]),
                        _vm._l(
                          _vm.geoname.historical_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `historical_reference_document-${index}` },
                              [
                                document.document_type ===
                                  "history_verified_approved" ||
                                document.document_type ===
                                  "history_unverified_approved"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mx-3 m-1",
                                          attrs: {
                                            src: require("../../assets/Images/Icon-xls.png"),
                                          },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(document.title) + " "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                            attrs: {
                                              href: _vm.relativeFileUrl(
                                                document.s3_key
                                              ),
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v("View Document")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.meaning
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Meaning")]),
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.geoname.meaning))]),
                        _vm.geoname.pronunciation ||
                        _vm.geoname.pronunciation_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Pronunciation")]),
                            ])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(_vm.geoname.pronunciation))]),
                        _vm._l(
                          _vm.geoname.pronunciation_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: `pronunciation_reference_document-${index}`,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.aboriginal_name !== null
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Aboriginal Name")]),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.geoname.aboriginal_name === true
                                  ? "Yes"
                                  : _vm.geoname.aboriginal_name === false
                                  ? "No"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                        _vm.geoname.source_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Commemoration Source")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.source_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `source_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.marked_map_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Marked Map")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.marked_map_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `marked_map_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.marked_photo_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Marked Photo")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.marked_photo_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: `marked_photo_reference_document-${index}`,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.advert_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Advertisement")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.advert_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `advert_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.gazette_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Gazette Notice")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.gazette_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `gazette_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.general_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Other Information")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.general_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              { key: `general_reference_document-${index}` },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.media_release_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Media Release")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.media_release_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: `media_release_reference_document-${index}`,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm.geoname.evaluation_report_reference_document
                          ? _c("div", { staticClass: "mt-8 pb-1" }, [
                              _c("b", [_vm._v("Pre-Evaluation Report")]),
                            ])
                          : _vm._e(),
                        _vm._l(
                          _vm.geoname.evaluation_report_reference_document,
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: `evaluation_report_reference_document-${index}`,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full mt-3 flex items-center rounded-lg border border-grey-border",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-3 m-1",
                                      attrs: {
                                        src: require("../../assets/Images/Icon-xls.png"),
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(document.title) + " "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer ml-auto text-normal-bold text-blue-button mr-6",
                                        attrs: {
                                          href: _vm.relativeFileUrl(
                                            document.s3_key
                                          ),
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("View Document")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _c("hr", { staticClass: "divider w-full mt-16 mb-8" }),
                        _c("p", { staticClass: "mb-8" }, [
                          _vm._v(
                            " If you have any information about this feature's history, origin, meaning or pronunciation, which does not appear here, please submit your comments and information by selecting the 'Add place name information' button below. "
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "button-blue-hollow",
                            on: {
                              click: function ($event) {
                                return _vm.goToNewSearch()
                              },
                            },
                          },
                          [_vm._v(" Back ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "button-blue float-right",
                            on: {
                              click: function ($event) {
                                return _vm.goToAddPlaceNameInformation()
                              },
                            },
                          },
                          [_vm._v(" Add Place Name Information ")]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }