var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "justify-center self-start border-2 rounded-full focus:outline-none focus:shadow-outline self-center",
      class: _vm.initials + " " + _vm.classNames,
    },
    [_vm._v(" " + _vm._s(_vm.initials) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }