<template>
  <FormContainer>
    <div v-if="!isUserPending" class="flex flex-col content-between">
      <h1
        class="pt-6 mb-10 text-center mt-4 text-title"
        data-cypress="header-text"
      >
        Register for account
      </h1>
      <!-- names -->
      <div
        class="flex lg:flex-row md:flex-col xs:flex-col justify-between mb-4"
      >
        <div class="flex flex-col flex-grow flex-shrink">
          <label class="block text-normal-bold mb-2" for="firstname"
            >First name</label
          >
          <input
            v-validate="'required'"
            class="w-full input-area"
            id="firstname"
            name="First Name"
            type="text"
            required
            v-model="userRegistrationDetails.first_name"
            placeholder
            :disabled="isWaiting"
            data-cypress="first-name"
          />
          <span
            class="text-error"
            v-if="errors.has('First Name')"
            data-cypress="first-name-error"
            >{{ errors.first("First Name") }}</span
          >
        </div>
        <div
          class="flex flex-col flex-grow flex-shrink lg:ml-4 sm:ml-4 md:ml-0"
        >
          <label class="block text-normal-bold mb-2" for="lastname"
            >Last name</label
          >
          <input
            v-validate="'required'"
            class="w-full input-area"
            id="lastname"
            name="Last Name"
            type="text"
            v-model="userRegistrationDetails.last_name"
            placeholder
            :disabled="isWaiting"
            data-cypress="last-name"
          />
          <span
            class="text-error"
            v-if="errors.has('Last Name')"
            data-cypress="last-name-error"
            >{{ errors.first("Last Name") }}</span
          >
        </div>
      </div>
      <!-- email address -->
      <div class="mb-4 flex flex-col">
        <label class="block text-black text-normal-bold mb-2" for="email"
          >Email Address</label
        >
        <input
          v-validate="'required|email'"
          class="w-full input-area"
          id="username"
          name="Email Address"
          type="text"
          v-model="userRegistrationDetails.email"
          placeholder
          @input="
            userRegistrationDetails.email = userRegistrationDetails.email.toLowerCase()
          "
          @change="isEmailAddressInUse = false"
          :disabled="isWaiting"
          data-cypress="email"
        />
        <span
          class="text-error"
          v-if="errors.has('Email Address')"
          data-cypress="email-error"
          >{{ errors.first("Email Address") }}</span
        >
        <div
          class="flex items-between flex-row"
          v-if="isEmailAddressInUse"
          data-cypress="email-in-use"
        >
          <ErrorIcon class="w-4 h-4 ml-0" />
          <span class="text-error">Email already in use.</span>
          <router-link
            :to="{
              name: 'reset',
              query: { email: userRegistrationDetails.email }
            }"
            class="font-bold recover"
            >Recover my account password</router-link
          >
        </div>
      </div>
      <!-- phone -->
      <div class="mb-4 flex flex-col">
        <label class="block text-black text-normal-bold mb-2 mt-2" for="phone"
          >Phone number</label
        >
        <input
          v-validate="'required|phoneNumber'"
          class="w-full input-area"
          id="phone"
          name="Phone Number"
          type="text"
          v-model="userRegistrationDetails.phone_no"
          placeholder
          :disabled="isWaiting"
          data-cypress="phone"
        />
        <span
          class="text-error"
          v-if="errors.has('Phone Number')"
          data-cypress="phone-error"
          >{{ errors.first("Phone Number") }}</span
        >
      </div>
      <!-- passwords -->
      <div class="mb-4 flex justify-between mt-2">
        <div class="flex flex-col flex-grow flex-shrink w-1/2 mb-3">
          <label
            class="block text-black text-normal-bold mb-2"
            for="passwordInitial"
            >Password</label
          >
          <input
            v-validate="'required|passwordPolicy'"
            class="w-full input-area"
            id="passwordInitial"
            name="Password"
            type="password"
            ref="passwordInitial"
            v-model="userRegistrationDetails.passwordInitial"
            placeholder
            :disabled="isWaiting"
            data-cypress="password"
          />
          <span
            class="text-error"
            v-if="errors.has('Password')"
            data-cypress="password-error"
            >{{ errors.first("Password") }}</span
          >
        </div>
        <div
          class="flex flex-col flex-grow flex-shrink lg:ml-4 sm:ml-4 md:ml-0 w-1/2 mb-3"
        >
          <label
            class="block text-black text-normal-bold mb-2"
            for="passwordconfirm"
            >Confirm Password</label
          >
          <input
            v-validate="'required|confirmed:passwordInitial'"
            class="w-full input-area"
            id="passwordconfirm"
            name="Confirm Password"
            type="password"
            v-model="userRegistrationDetails.passwordconfirm"
            placeholder
            :disabled="isWaiting"
            data-cypress="password-confirm"
          />
          <span class="text-error" v-if="errors.has('Confirm Password')"
            >Passwords do not match.</span
          >
        </div>
        <!-- password complexity response -->
      </div>

      <!-- is local RNA -->
      <!-- custom radio input -->
      <div class="flex flex-col mb-4">
        <!-- title -->
        <p class="flex-1 font-bold text-normal w-full">
          Do you represent a Local or State Government Authority?
        </p>
        <div class="flex flex-row">
          <!-- radio button -->
          <div class="flex flex-row items-center mt-3">
            <label class="cont w-6 h-6 mr-3 flex justify-center absolute">
              <input
                type="radio"
                v-model="userRegistrationDetails.isRNA"
                name="roadTypeCheck"
                class="hidden"
                id="govEntity"
                :value="false"
                data-cypress="is-not-rna"
              />
              <div class="inner w-3 h-3 self-center"></div>
            </label>
            <p class="ml-10 text-normal">No</p>
          </div>
          <!-- radio button -->
          <div class="flex flex-row items-center ml-8 mt-3">
            <label class="cont w-6 h-6 mr-3 flex justify-center absolute">
              <input
                type="radio"
                v-model="userRegistrationDetails.isRNA"
                name="roadTypeCheck"
                class="hidden"
                id="govEntity"
                :value="true"
                data-cypress="is-rna"
              />
              <div class="inner w-3 h-3 self-center"></div>
            </label>
            <p class="ml-10 text-normal">Yes</p>
          </div>
        </div>
      </div>

      <!-- isRNA true sections -->
      <div class="w-full mt-6" v-if="userRegistrationDetails.isRNA">
        <p class="font-bold text-normal">
          Which proposals are you Authorised to submit?
        </p>
        <Checkbox
          data-cypress="is-road-cbx"
          class="w-full pl-0"
          label="Road Names"
          v-model="isRoad"
        ></Checkbox>
        <Checkbox
          class="w-full pl-0"
          data-cypress="is-others-cbx"
          label="Place Names/Address Locality Names/Address Locality Boundary Amendments"
          v-model="isOthers"
        ></Checkbox>
        <div
          v-if="!isOthers && !isRoad"
          v-show="!isWaiting"
          class="text-blue font-medium mt-1 text-size-14"
          data-cypress="please-select-option-message"
        >
          Please select an option above to continue
        </div>
        <div v-if="isOthers || isRoad" v-show="!isWaiting">
          <p class="font-bold text-normal pt-4 mb-2">
            Which entity do you represent?
          </p>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="search"
            class="absolute w-5 mt-4 ml-4"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
            />
          </svg>

          <entitySelect
            :entityList="entityList"
            :isRoad="isRoad"
            :isOthers="isOthers"
            :selectedEntity="userRegistrationDetails.organisation_name"
            data-cypress="authority"
            name="rnaEntity"
            @change="updateEntityDetails($event)"
            id="rnaEntity"
          />
          <div
            class="block"
            v-if="this.noNewEntityError"
            data-cypress="no-new-entity-error"
          >
            <span
              class="text-error"
              data-cypress="must-select-existing-rna-error"
              >You must select an existing RNA.</span
            >
          </div>
          <div
            class="block"
            v-if="this.showNewEntityMessage"
            data-cypress="create-new-entity-message"
          >
            <span class="text-blue text-xs font-medium mt-1"
              >On approval, this entity will be created in our system.</span
            >
          </div>
          <div
            class="block pb-8"
            v-if="errors.has('rnaEntity')"
            data-cypress="authority-error"
          >
            <span class="text-error">You must select an entity</span>
          </div>
          <p class="font-bold pt-4 text-normal">Title/Position</p>
          <input
            v-validate="'required'"
            class="mt-2 w-full input-area"
            id="position"
            name="position"
            type="text"
            v-model="userRegistrationDetails.position"
            placeholder="Enter Title/Position"
            :disabled="isWaiting"
            data-cypress="authority-position"
          />
          <div
            class="block pb-8"
            v-if="errors.has('position')"
            data-cypress="authority-position-error"
          >
            <span class="text-error">Title is required</span>
          </div>
        </div>
      </div>
      <div class="flex flex-grow self-stretch"></div>

      <div class="flex flex-grow">
        <Checkbox
          class="w-full pl-0"            
          v-model="personalInformationCollectionNotice">
          <template slot:label>
            Please tick to acknowledge the <a href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf" target="_blank" > GNB Personal Information Collection Notice </a>
         </template>
        </Checkbox>        
      </div>
      
      <!-- submite for approval -->
      <div class="items-center justify-between mt-6">
        <ButtonWithSpinner
          class="w-full button-blue"
          @click="registerUserToCognito"
          :disabled="errors.any() || isWaiting || this.isSubmitDisabled || !personalInformationCollectionNotice"
          :isSpinning="isWaiting"
          data-cypress="register"
          >{{ buttonText }}</ButtonWithSpinner
        >
      </div>
      <!-- go home -->
      <button
        class="w-full bg-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 return text-normal mb-10"
        type="button"
        @click="handleReturnToLogin"
        data-cypress="return-to-login"
      >
        Return to login page
      </button>
    </div>
    <!-- register success screen -->
    <div
      v-if="isUserPending"
      class="flex flex-col items-center"
      data-cypress="register-success"
    >
      <h2 class="pt-8 pb-12 text-center flex-no-grow text-title text-2xl">
        Account request successful
      </h2>
      <p class="flex-auto text-normal mt-4 mb-4">
        Your registration form has been successfully submitted for approval. You
        should receive a confirmation email within 1 - 2 business days.
      </p>
      <button
        class="flex-no-grow signin w-full mt-4 border-2 bg-white text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-normal"
        type="button"
        @click="handleReturnToLogin"
      >
        Return to login page
      </button>
    </div>
  </FormContainer>
</template>

<script>
import { mapGetters } from "vuex";

import * as userCRUD from "../../helpers/userCRUD";
import * as cognito from "../../helpers/cognito.js";

import ButtonWithSpinner from "../elements/buttonWithSpinner";
import FormContainer from "./form-container";
import ErrorIcon from "../elements/errorIcon";
import entitySelect from "../elements/entitySelect";

import * as validate from "../../helpers/validations.js";

const SUBMIT_TEXT = "Submit for Approval";

export default {
  props: ["entityList"],
  components: {
    FormContainer,
    ButtonWithSpinner,
    ErrorIcon,
    entitySelect
  },
  data() {
    return {
      isUserPending: false,
      isWaiting: false,
      isSubmitDisabled: true,
      noNewEntityError: false,
      showNewEntityMessage: false,
      isEmailAddressInUse: false,
      buttonText: SUBMIT_TEXT,
      isRoad: false,
      isOthers: false,
      userRegistrationDetails: {
        first_name: "",
        last_name: "",
        phone_no: "",
        isRNA: false,
        position: "",
        rnaRoad: "",
        rnaOthers: "",
        road_naming_authority_id: "",
        organisation_id: "",
        organisation_name: "",
        email: ""
      },
      errors_cognito: {},
      errors_other: {},
      personalInformationCollectionNotice: false
    };
  },
  watch: {
    isRoad: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.resetEntityDetails();
      }
    },
    isOthers: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.resetEntityDetails();
      }
    },
    userRegistrationDetails: {
      deep: true,
      immediate: true,
      handler() {
        this.checkSubmitValidity();
      }
    }
  },
  methods: {
    async registerUserToCognito() {
      try {
        const response = await this.$validator.validateAll();
        let phoneNumberInput = this.userRegistrationDetails.phone_no;
        this.userRegistrationDetails.email = this.userRegistrationDetails.email.toLowerCase();
        if (response) {
          this.userRegistrationDetails.phone_no = validate.formatPhoneNumber(
            this.userRegistrationDetails.phone_no
          );
          this.isWaiting = true;
          this.buttonText = "Submitting for Approval...";

          await cognito.signupToCognito(
            Object.assign({}, this.userRegistrationDetails, {
              phone_no: validate.formatPhoneNumber(
                this.userRegistrationDetails.phone_no
              )
            })
          );
          this.userRegistrationDetails.phone_no = phoneNumberInput;
          await userCRUD.saveNewUseFromCogntioToPg(
            Object.assign({}, this.userRegistrationDetails, {
              isRoad: this.isRoad,
              isOthers: this.isOthers
            }),
            new Date().toLocaleDateString()
          );
          this.isWaiting = false;
          this.isUserPending = true;
        }
      } catch (e) {
        let errorMessage = "";
        switch (e.code) {
          case "UsernameExistsException":
            errorMessage = "Email address in use";
            this.isEmailAddressInUse = true;
            break;
          case "InvalidParameterException":
            if (
              e.message &&
              typeof e.message == "string" &&
              e.message.toLowerCase().includes("password")
            ) {
              errorMessage =
                "Password must be at least 8 characters and contain a number, special character, upper and lower case characters.";
            } else if (e.message) {
              errorMessage = e.message;
            } else {
              errorMessage =
                "Unable to create your user account, please try again later";
            }
            break;
          case "InvalidPasswordException":
            errorMessage =
              "Password must be at least 8 characters and contain a number, special character, upper and lower case characters.";
            break;
          default:
            errorMessage = e.message
              ? e.message
              : "Unable to create your user account, please try again later";
            // eslint-disable-next-line no-console
            console.error(e.message ? e.message : e);
        }
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Creating account",
          text: errorMessage
        });
        this.buttonText = SUBMIT_TEXT;
        this.isWaiting = false;
        const el = document.querySelector(".text-error");
        el &&
          el.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
      }
    },
    handleReturnToLogin() {
      this.$router.push("/");
    },
    updateEntityDetails(event) {
      this.userRegistrationDetails.road_naming_authority_id = event.rnid;
      this.userRegistrationDetails.organisation_id = event.pnid;
      this.userRegistrationDetails.organisation_name = event.name;
      this.checkSubmitValidity();
    },
    resetEntityDetails() {
      this.userRegistrationDetails.organisation_name = null;
      this.userRegistrationDetails.organisation_id = null;
      this.userRegistrationDetails.road_naming_authority_id = null;
      this.showNewEntityMessage = false;
      this.noNewEntityError = false;
    },
    checkSubmitValidity() {
      const model = this.userRegistrationDetails;
      if (!model.isRNA) {
        this.isSubmitDisabled = false;
      } else {
        if (!this.isRoad && !this.isOthers) {
          this.isSubmitDisabled = true;
        } else if (model.organisation_name && model.organisation_name.length) {
          if (!model.organisation_id && !model.road_naming_authority_id) {
            if (this.isRoad) {
              this.showNewEntityMessage = false;
              this.noNewEntityError = true;
              this.isSubmitDisabled = true;
            } else {
              this.showNewEntityMessage = true;
              this.noNewEntityError = false;
              this.isSubmitDisabled = false;
            }
          } else if (model.organisation_id || model.road_naming_authority_id) {
            this.showNewEntityMessage = false;
            this.noNewEntityError = false;
            this.isSubmitDisabled = false;
          }
        } else {
          this.showNewEntityMessage = false;
          this.isSubmitDisabled = true;
          this.noNewEntityError = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters({ isLoadingRNA: "isWaiting" })
  }
};
</script>

<style scoped>
input,
select {
  background: #ecf1f3;
  height: 50px;
}
.signin {
  background: #002664;
  height: 50px;
}
.signin:disabled {
  background: #3d4552;
  height: 50px;
}
select {
  margin-top: 10px;
  padding-top: 10px;
}
.return,
.recover {
  color: #002664;
  text-decoration: none;
}

.error-box {
  background-color: #e25f62;
  border-top-color: #ff0202;
}
.error {
  @apply text-red font-bold;
}

.icon {
  width: 2em;
  height: 2em;
  margin-right: 2em;
}

.cont {
  border: 2px solid #002664;
  border-radius: 99999px;
}
.inner {
  border-radius: 99999px;
}
.cont input:checked ~ .inner {
  background-color: #002664;
}

#rnaEntity {
  padding: 0;
  padding-left: 45px;
  margin-bottom: 1rem;
}
</style>
