import { render, staticRenderFns } from "./authencticationContent.vue?vue&type=template&id=18852612&scoped=true"
var script = {}
import style0 from "./authencticationContent.vue?vue&type=style&index=0&id=18852612&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18852612",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18852612')) {
      api.createRecord('18852612', component.options)
    } else {
      api.reload('18852612', component.options)
    }
    module.hot.accept("./authencticationContent.vue?vue&type=template&id=18852612&scoped=true", function () {
      api.rerender('18852612', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/authentication/authencticationContent.vue"
export default component.exports