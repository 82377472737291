"use strict";

import { mapGetters, mapActions, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

const lgaListMixin = {
  async created() {
    // get Lgas
    try {
      if (!this.lgaState.length) {
        await this.getAllLgasFromServiceSq();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
  data() {
    return {
      lgaFilter: ""
    };
  },
  computed: {
    ...mapGetters(["lgaState", "defaultRnaLga", "defaultOrganisationLga"]),
    ...mapUserGetters({ user: "user" }),
    lgas() {
      const filter = this.lgaFilter;
      return this.lgaState.filter(lga =>
        new RegExp(`^${filter}`, "i").test(lga)
      );
    },
    lgasClean() {
      return this.lgaState.filter(lga => lga !== null && lga !== "");
    },
    lgasLimited() {
      if (this.defaultRnaLga && this.lgaState.includes(this.defaultRnaLga)) {
        return [this.defaultRnaLga];
      }
      return this.lgaState.filter(lga => lga !== null && lga !== "");
    }
  },
  methods: {
    ...mapActions(["getAllLgasFromServiceSq"]),
    setLgaFilter(val) {
      this.lgaFilter = val;
    }
  }
};

export default lgaListMixin;
