<template>
  <div
    class="backgroundDiv w-screen h-screen flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div class="w-2/3 bg-white mx-auto mt-36 z-10 rounded">
      <div
        class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap"
      >
        <div>
          <div class="w-full justify-center flex mt-20">
            <img
              src="../../../../assets/Images/Icon_Gazettal.png"
              class="w-36 h-36"
            />
          </div>
          <h3 class="text-title mb-20 font-bold">
            Your Place Name Proposal has been submitted for consideration.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "proposalPublicSubmitted",
  props: {
    proposalName: String
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}
</style>
