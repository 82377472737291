<template>
  <div class="flex items-start justify-center text-red-button" v-if="error">
    <ErrorIcon class="flex-0 mt-1" />
    <span
      class="text-error flex-1"
      v-if="error.code === 'NotAuthorizedException'"
    >
      <span
        class="text-error flex-1"
        v-if="
          error.message === 'User is disabled' ||
            error.message === 'User is disabled.'
        "
        >Your account is suspended. Please contact
        <a href="mailto:ss-gnb@customerservice.nsw.gov.au">GNB</a></span
      >
      <span
        class="text-error flex-1"
        v-if="error.message === 'Incorrect username or password.'"
        >Incorrect username or password.</span
      >
    </span>
    <span
      class="text-error flex-1"
      v-else-if="error.code === 'UserNotConfirmedException'"
      >You cannot login until your account has been confirmed by the GNB
      Secretariat.</span
    >
    <span class="text-error flex-1" v-else
      >We could not find you in our system.</span
    >
  </div>
</template>

<script>
export default {
  name: "CognitoErrorMessage",
  props: ["error"]
};
</script>
