var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-grow flex-col justify-center items-center py-4" },
    [
      _c("font-awesome-icon", {
        staticClass: "flex-0 text-red text-3xl inline",
        attrs: { icon: "exclamation-circle" },
      }),
      _c("p", { staticClass: "text-black flex-0" }, [
        _vm._v(" You are not authorised to view this content "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }