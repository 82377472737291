var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-black mx-16 my-16 bg-white p-8" },
    [
      _c("DataTableReport", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          title: `Road Name Proposal Summary`,
          recordType: `proposals`,
          paginationProps: _vm.pagination,
          isLoading: _vm.$wait.is("fetchingRecords"),
          filterLists: _vm.filterLists,
          filters: _vm.filters,
          filterValues: _vm.qsParams,
          pageResultCountList: _vm.pageResultCountList,
          showDates: true,
          showKeywordSearch: true,
          isAdmin: _vm.isAdmin,
        },
        on: {
          updatePage: _vm.updatePage,
          sortDesc: _vm.sortDesc,
          sortAsc: _vm.sortAsc,
          resetFilters: _vm.resetFilterSearchParams,
          filterChange: _vm.setSearchParamsFromFilters,
          updatePageLimit: _vm.updatePageLimit,
          setDateTo: _vm.setDateToParam,
          setDateFrom: _vm.setDateFromParam,
          searchViaKeyword: _vm.setRoadnameParam,
          download: _vm.downloadCsv,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }