var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c("div", { staticClass: "w-9/10 mx-auto" }, [
      _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
        _c("div", { staticClass: "flex justify-between" }),
        _c("h2", { staticClass: "text-black text-title mb-8 self-center" }, [
          _vm._v(" Submit Gazettal Notice "),
        ]),
        _c(
          "div",
          {
            staticClass:
              "w-full bg-white text-black rounded-lg pt-16 pb-16 mt-4 justify-center flex flex-wrap",
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "justify-center text-center" }, [
              _c("p", { staticClass: "mb-4 w-full text-title" }, [
                _vm._v(
                  " Your Gazettal Notice for " +
                    _vm._s(_vm.road.roadname) +
                    " " +
                    _vm._s(_vm.road.road_type) +
                    " has been submitted. "
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "flex pt-4 text-center pb-10" },
          [
            _c(
              "router-link",
              {
                staticClass: "button-blue-hollow w-1/3",
                attrs: { to: { name: "proposals" } },
              },
              [_vm._v("Go to My Proposals Dashboard")]
            ),
            _c(
              "router-link",
              {
                staticClass: "button-blue ml-auto w-1/3",
                attrs: {
                  to: {
                    name: "roadNameProposalBucket",
                    params: { proposalId: _vm.proposalId },
                  },
                },
              },
              [_vm._v("Return to Road Name Proposal")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full justify-center flex mb-8 pt-5" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/Images/press-release.png"),
          alt: "Press release image",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }