<template>
  <div class="w-full flex bg-blue-button mb-6 text-white">
    <div class="w-5/6 mx-auto pt-8 pb-12">
      <div class="border-b-2 mb-6 self-center">
        <h3 class="font-bold text-white">Proposal Info</h3>
      </div>
      <div>
        <p class="text-normal-bold mb-2">Proposed Name</p>
        <p>{{ proposal.roadname }} {{ proposal.roadType }}</p>
      </div>
      <h4 class="font-bold"></h4>
      <div class="w-full flex content-center items-start mt-8">
        <div class="pr-4 w-1/2 items-start">
          <p class="text-normal-bold mb-2">Proposal Submitted Date</p>
          <p>{{ format(proposal.dateSubmitted) }}</p>
        </div>
        <div class="pl-4 w-1/2 flex flex-col">
          <p class="text-normal-bold mb-2">Proposal Submitted By</p>
          <p>{{ proposal.proposerName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AuditTrailProposalSummary",

  /**
   * Instance properties
   */
  props: {
    proposal: {
      type: Object,
      defautl: () => {
        return {};
      }
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    format(date) {
      return moment(date).format("DD MMM YYYY");
    }
  }
};
</script>
