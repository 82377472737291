<template>
  <section>
    <div class="flex w-full">
      <a @click="confirm(false)" class="cursor-pointer ml-auto p-2">
        <errorIcon classNames="w-4" />
      </a>
    </div>
    <div
      class="base-container text-black h-auto flex flex-col pt-6 px-2 pb-14"
      data-cypress="evaluation-modal"
    >
      <div
        class="flex items-center content-center justify-center mt-4 px-10 mb-2"
      >
        <h2>
          Email Evaluation Results for
        </h2>
      </div>
      <h3 class="w-full flex justify-center text-black px-10 mb-2">
        {{ road.name.toUpperCase() }} {{ road.type }}
      </h3>
      <h3 class="w-full flex justify-center text-black mb-6 px-10 mb-2">
        {{ road.roadname_classification }} Road Name Proposal
      </h3>

      <div class="w-full mb-8">
        <p class="text-normal-bold mb-1">
          <span class="text-red-star">*</span>Email Address
        </p>
        <input
          type="text"
          class="w-full input-area mb-1 mt-1"
          data-cypress="email-field"
          placeholder="Enter Email Here..."
          name="Email Address"
          id="email"
          v-validate="'email|required'"
          v-model.trim="email"
          autofocus
          data-vv-validate-on="blur"
        />
        <FieldErrorMessage :message="errors.first('Email Address')" />
      </div>

      <div class="flex justify-center w-full">
        <button
          @click="confirm(false)"
          class="button-blue-hollow mr-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
          data-cypress="nokeep-button"
        >
          Cancel
        </button>
        <button
          @click="confirm(email)"
          class="button-blue ml-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none"
          data-cypress="yesdelete-button"
          :disabled="!email || errors.items.length > 0"
        >
          Send Email
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import errorIcon from "../../../elements/errorIcon";
export default {
  components: {
    errorIcon
  },
  name: "evaluation-modal",
  props: ["road", "answer"],
  methods: {
    confirm(ans) {
      this.answer(ans);
      this.$emit("close");
    }
  },
  data: function() {
    return {
      email: ""
    };
  }
};
</script>
<style scoped>
.base-container {
  font-family: Gotham;
}

.popup-container {
  width: 60%;
}
</style>
