<template>
  <div>
    <button
      :disabled="btnDisabled"
      @click="openReassignProposal(modalName)"
      class="
        mx-2
        my-2
        border-2
        pt-2
        pb-2
        pl-8
        pr-8
        rounded-full
        focus:outline-none
        text-normal-bold
        cursor-pointer
        button-blue-hollow
      "
    >
      Reassign
    </button>
    <modal :name="modalName" :height="'auto'" @before-close="clearInputs">
      <div class="flex flex-col justify-between">
        <h2 class="text-title flex-0 px-4">Reassign Proposals</h2>
        <div class="dividing-line-h"></div>
        <div class="p-4">
          <label class="block text-black text-normal-bold mb-2" for="firstName"
            >First Name</label
          >
          <input
            v-validate="'required'"
            v-model="firstName"
            class="w-full input-area"
            id="firstName"
            name="firstName"
            type="text"
            ref="firstName"
            data-cypress="firstName"
            list="firstNameList"
          />
          <datalist id="firstNameList">
            <option
              v-for="(firstName, index) in firstNameList"
              v-bind:key="index"
            >
              {{ firstName }}
            </option>
          </datalist>

          <label
            class="block text-black text-normal-bold mb-2 mt-4"
            for="lastName"
            >Last Name</label
          >
          <input
            v-validate="'required'"
            v-model="lastName"
            class="w-full input-area"
            id="lastName"
            name="lastName"
            type="text"
            ref="lastName"
            data-cypress="lastName"
            list="lastNameList"
          />
          <datalist id="lastNameList">
            <option
              v-for="(lastName, index) in lastNameList"
              v-bind:key="index"
            >
              {{ lastName }}
            </option>
          </datalist>

          <label class="block text-black text-normal-bold mb-2 mt-4" for="email"
            >Email</label
          >
          <input
            v-validate="'required|email'"
            v-model="email"
            class="w-full input-area"
            id="email"
            name="email"
            type="email"
            ref="email"
            data-cypress="email"
            list="emailList"
          />
          <datalist id="emailList">
            <option v-for="(email, index) in emailList" v-bind:key="index">
              {{ email }}
            </option>
          </datalist>

          <label
            v-if="isExistingUser"
            class="block text-black text-normal-bold mb-2 mt-4"
            for="text"
            >Account Status</label
          >
          <AccountStatus
            v-if="isExistingUser"
            :account_status="filteredUsers[0].user_account_status"
            data-cypress="user-account-status"
          />
          <div class="mt-2">
            <span
              class="text-error"
              v-if="!isExistingUser"
              data-cypress="no-matching-user-error"
              >No user is matched</span
            >
          </div>

          <div class="self-end my-6 flex justify-between">
            <button
              @click="clearInputs"
              class="button-blue-hollow"
              data-cypress="clear-button"
              type="button"
            >
              Clear
            </button>
            <button
              @click="closeReassignProposal(modalName)"
              class="button-red-hollow"
              data-cypress="cancel-button"
              type="button"
            >
              Cancel
            </button>
            <button
              type="button"
              class="button-blue"
              data-cypress="reassign-button"
              :disabled="isExistingUser ? false : true"
              @click="reassignProposal"
            >
              Reassign
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { searchUsers } from "../../../helpers/userCRUD.js";
import pnpCRUD from "../../../helpers/pnpCRUD.js";
import { reassignRnp } from "../../../helpers/rnpCRUD.js";
import { mapGetters } from "vuex";

export default {
  name: "BulkReassignmentsModal",
  props: ["proposals", "btnDisabled", "fromUser"],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      users: [],
      filteredUsers: [],
      firstNameList: [],
      lastNameList: [],
      emailList: [],
      isExistingUser: false,
      currentProposalIdentifier: "",
      modalName: "bulkReassignment"
    };
  },
  async mounted() {
    await this.getUsers();
  },
  computed: {
    inputFields: function () {
      return [this.firstName, this.lastName, this.email];
    },
    ...mapGetters(["isRNPView"])
  },
  watch: {
    // Triggered when any value of input fields for firstName, lastName and email changes
    inputFields: {
      handler: function () {
        this.isExistingUser = false;

        // When email has a value, populate matched first name and last name
        if (this.email.trim().toLowerCase() !== "") {
          this.users.forEach(user => {
            if (user.email === this.email.trim().toLowerCase()) {
              this.firstName = user.first_name;
              this.lastName = user.last_name;
            }
          });
        }

        // Clears lists
        this.clearLists();

        this.users.forEach(user => {
          // Updates the list for first names based on the last name and the email.
          if (
            user.last_name
              .toLowerCase()
              .includes(this.lastName.trim().toLowerCase()) &&
            user.email.toLowerCase().includes(this.email.trim().toLowerCase())
          ) {
            this.firstNameList.push(user.first_name);
          }

          // Updates the list for last names based on the first name and the email.
          if (
            user.first_name
              .toLowerCase()
              .includes(this.firstName.trim().toLowerCase()) &&
            user.email.toLowerCase().includes(this.email.trim().toLowerCase())
          ) {
            this.lastNameList.push(user.last_name);
          }

          // Updates the list for emails based on the first name and last name.
          if (
            user.first_name
              .toLowerCase()
              .includes(this.firstName.trim().toLowerCase()) &&
            user.last_name
              .toLowerCase()
              .includes(this.lastName.trim().toLowerCase())
          ) {
            this.emailList.push(user.email);
          }
        });

        // Remove duplicates and sort in the alphabetical order
        this.removeDuplicates();
        this.sortLists();

        // Searches for a user who has the same first name, last name and email.
        this.filteredUsers = this.users.filter(
          user =>
            user.first_name === this.firstName.trim() &&
            user.last_name === this.lastName.trim() &&
            user.email === this.email.trim()
        );

        // If there is a user who matches all input values, enables the reassign button.
        if (this.filteredUsers.length === 1) {
          this.isExistingUser = true;
          this.clearLists();
        }
      },
      deep: true
    }
  },
  methods: {
    // Opens the proposal modal
    openReassignProposal(modalName) {
      this.$modal.show(modalName, {
        height: "auto"
      });
    },
    // Closes the proposal modal
    closeReassignProposal(modalName) {
      this.$modal.hide(modalName);
    },
    // Gets all users
    async getUsers() {
      try {
        const users = await searchUsers({ limit: 99999999 });
        const temp = users.users;

        if (this.isRNPView) {
          this.users = temp.filter(user => user.isRNP === true);
        } else {
          this.users = temp.filter(
            user => user.isPNP === true || user.isPublic === true
          );
        }

        this.users.forEach(user => {
          this.firstNameList.push(user.first_name);
          this.lastNameList.push(user.last_name);
          this.emailList.push(user.email);
        });
        this.removeDuplicates();
        this.sortLists();
      } catch (e) {
        this.users = [];
      }
    },
    clearInputs() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
    },
    clearLists() {
      this.firstNameList = [];
      this.lastNameList = [];
      this.emailList = [];
    },
    removeDuplicates() {
      this.firstNameList = [...new Set(this.firstNameList)];
      this.lastNameList = [...new Set(this.lastNameList)];
      this.emailList = [...new Set(this.emailList)];
    },
    sortLists() {
      this.firstNameList.sort();
      this.lastNameList.sort();
      this.emailList.sort();
    },
    // Sends the status to the parent component once reassignemnt is completed
    reRender() {
      this.$emit("passStatusOfReassignment", "sucess");
    },
    // Shows a message box
    closeModalAfterUpdating(result) {
      this.$notify({
        group: "toast",
        type: result.status,
        title: result.status,
        text: result.message
      });

      if (result.status === "success") {
        this.reRender();
      }

      this.closeReassignProposal(this.modalName);
    },
    async reassignProposal() {
      try {
        let result = null;

        const params = {
          proposalIdentifier: this.$props.proposals.identifiers,
          userFirstName: this.filteredUsers[0].first_name,
          userLastName: this.filteredUsers[0].last_name,
          userEmail: this.filteredUsers[0].email,
          userIdentifier: this.filteredUsers[0].identifier,
          fromUserFirstName: this.$props.fromUser.first_name,
          fromUserLastName: this.$props.fromUser.last_name
        };

        if (this.isRNPView) {
          result = await reassignRnp(params);
        } else {
          result = await pnpCRUD.reassignPnp(params);
        }
        this.closeModalAfterUpdating(result);
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "An error occured",
          text: "An error occrued while processing your request. Please retry it later."
        });
      }
    }
  }
};
</script>