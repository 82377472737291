<template>
  <div class="bg-white mb-5 p-5">
    <div class="flex">
      <div class="w-2/5">
        <div class="text-label">
          Road Name
        </div>
        <div class="font-bold">
          {{ road.roadname }} {{road.road_type}}
        </div>
      </div>
      <div class="w-2/5">
        <div class="text-label">
          Locality
        </div>
        <div>
          {{ road.suburbs && road.suburbs.join(", ") }}
        </div>
      </div>
      <div class="w-1/5 flex flex-row-reverse">
        <div
          @click="collapsed = !collapsed"
          ref="collapse"
          class="rotate-transition text-blue-darker self-center pr-4"
          :class="[collapsed ? '' : 'rotate-chevron']"
        >
          <font-awesome-icon icon="chevron-circle-down" />
        </div>
      </div>
    </div>
    <transition
      name="collapse"
      @enter="beforeEnterCollapse()"
      @leave="beforeLeaveCollapse()"
    >
      <div ref="collapse-body" v-show="!collapsed" class="mt-4">
        <div class="text-label">
          Extent
        </div>
        <div>
          {{ road.extent }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["road"],
  data() {
    return {
      collapsed: true
    };
  },
  methods: {
    beforeEnterCollapse() {
      const content = this.$refs["collapse-body"];
      if (content) {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    },
    beforeLeaveCollapse() {
      const content = this.$refs["collapse-body"];
      content.style.maxHeight = 0 + "px";
    }
  }
};
</script>

<style scoped>
.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.25s;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
}

.rotate-transition svg {
  transition: transform 0.25s;
  cursor: pointer;
  font-size: 1.5rem;
}

.rotate-chevron svg {
  transform: rotate(180deg);
}
</style>