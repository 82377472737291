var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-1" }, [
    _c("label", { staticClass: "switch m-auto justify-center" }, [
      _c("input", {
        attrs: { type: "checkbox", id: "togBtn", model: _vm.account_status },
      }),
      _c("div", { staticClass: "slider round p-auto text-sm justify-center" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }