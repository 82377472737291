<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapWaitingActions, mapWaitingGetters } from "vue-wait";

export default {
  name: "public",
  computed: {
    ...mapWaitingGetters(["routeData"])
  },
  methods: {
    ...mapWaitingActions({
      getAllLgasFromServiceSq: "routeData"
    })
  },
  mounted() {
    this.getAllLgasFromServiceSq();
  }
};
</script>
<style scoped>
.backgroundDiv {
  background-image: url("../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
  position: fixed;
}
</style>
