var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Roadname Proposal Updated",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v("The following proposal data point(s) were modified:"),
        ]),
      ]),
      _vm._l(Object.keys(_vm.changes), function (change, index) {
        return _c("div", { key: index, staticClass: "mt-4" }, [
          _c("h3", { staticClass: "font-bold mb-2 text-base" }, [
            _vm._v(_vm._s(change)),
          ]),
          _c("h4", { staticClass: "pl-4 text-sm" }, [_vm._v("Previous value")]),
          _c("p", { staticClass: "pl-6 mb-2 text-sm" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.changes[change].from ? _vm.changes[change].from : "null"
                ) +
                " "
            ),
          ]),
          _c("h4", { staticClass: "pl-4 text-sm" }, [_vm._v("New value")]),
          _c("p", { staticClass: "pl-6 text-sm" }, [
            _vm._v(" " + _vm._s(_vm.changes[change].to) + " "),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }