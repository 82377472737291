<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <div class="w-5/6 mx-auto" v-bind:class="{ 'py-16': !isPublic }">
        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Applicant Details
        </h3>

        <div class="w-full">
          <p class="text-normal-bold my-4">
            What organisation do you represent?
          </p>
          <input
            type="text"
            class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Enter Organisation Here..."
            data-cypress="organisation-field"
            v-model="organisation_represented"
            name="organisation_represented"
            data-vv-as="Organisation Represented"
          />
          <FieldErrorMessage
            :message="errors.first('organisation_represented')"
          />
        </div>
        <div class="w-full flex">
          <div class="w-1/2">
            <p class="text-normal-bold mt-4 mb-2">
              <a class="text-red-star">*</a>First Name
            </p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter First Name Here..."
              data-cypress="firstname-field"
              v-model="first_name"
              name="first_name"
              v-validate="'required'"
              data-vv-as="First Name"
            />
            <FieldErrorMessage :message="errors.first('first_name')" />
          </div>
          <div class="w-1/2 ml-8">
            <p class="text-normal-bold  mt-4 mb-2">
              <a class="text-red-star">*</a>Last Name
            </p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Last Name Here..."
              data-cypress="lastname-field"
              v-model="last_name"
              name="last_name"
              v-validate="'required'"
              data-vv-as="Last Name"
            />
            <FieldErrorMessage :message="errors.first('last_name')" />
          </div>
        </div>
        <div class="w-full">
          <p class="text-normal-bold  mt-4 mb-2">
            <a class="text-red-star">*</a>Address Line 1
          </p>
          <input
            type="text"
            class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Enter Address Here..."
            data-cypress="adress-field"
            v-model="address_line_1"
            name="address_line_1"
            v-validate="'required'"
            data-vv-as="Address"
          />
          <FieldErrorMessage :message="errors.first('address_line_1')" />
        </div>
        <div class="w-full">
          <p class="text-normal-bold mt-4 mb-2">
            Address Line 2
          </p>
          <input
            type="text"
            class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="e.g. Apartment number, floor number, etc"
            data-cypress="adress2-field"
            v-model="address_line_2"
            name="address_line_2"
          />
        </div>

        <div class="w-full flex items-center">
          <div class="w-1/2 mr-8">
            <p class="text-normal-bold mt-4 mb-2">
              <a class="text-red-star">*</a>Suburb
            </p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Suburb Here..."
              data-cypress="suburb-field"
              v-model="suburb"
              name="suburb"
              v-validate="'required'"
              data-vv-as="Suburb"
            />
            <FieldErrorMessage :message="errors.first('suburb')" />
          </div>
          <div class="w-1/2 flex items-center">
            <div class="w-1/2 mr-8 mt-8 mb-2">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>State
              </p>
              <v-select
                class="w-full rounded-lg text-normal text-grey-text bg-grey-input self-center h-12 norns-select"
                v-model="state"
                :multiple="false"
                :options="australianStates"
                :searchable="false"
                :close-on-select="true"
                :select-on-tab="true"
                :show-labels="false"
                :hide-selected="false"
                placeholder="Select a State"
                v-validate="'required'"
                name="state"
                data-cypress="state-select"
                data-vv-as="State"
              ></v-select>
              <FieldErrorMessage :message="errors.first('state')" />
            </div>
            <div class="w-1/2">
              <p class="text-normal-bold  mt-4 mb-2">
                <a class="text-red-star">*</a>Postcode
              </p>
              <input
                type="text"
                class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                placeholder="Enter Postcode Here..."
                data-cypress="postcode-field"
                v-model="postcode"
                name="postcode"
                v-validate="'digits:4'"
                data-vv-as="Postcode"
              />
              <FieldErrorMessage :message="errors.first('postcode')" />
            </div>
          </div>
        </div>

        <div class="w-full flex items-center">
          <div class="w-1/2 mr-8">
            <p class="text-normal-bold mt-4 mb-2">
              <a class="text-red-star">*</a>Phone Number
            </p>
            <input
              type="text"
              class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Phone Number Here..."
              data-cypress="phonenumber-field"
              v-model="phone_no"
              name="phone_no"
              v-validate="'required|phoneNumber'"
              data-vv-as="Phone Number"
            />
            <FieldErrorMessage :message="errors.first('phone_no')" />
          </div>
          <div class="w-1/2">
            <p class="text-normal-bold mt-4 mb-2">
              <a class="text-red-star">*</a>Email Address
            </p>
            <input
              type="text"
              class="w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center"
              placeholder="Enter Email Address Here..."
              data-cypress="email-field"
              v-model="email"
              name="email"
              v-validate="'email|required'"
              data-vv-as="Email Address"
            />
            <FieldErrorMessage :message="errors.first('email')" />
          </div>
        </div>
        <div class="w-full">
          <p class="text-normal-bold mt-4 mb-2">
            Contact Person
          </p>
          <input
            type="text"
            class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Contact Person..."
            data-cypress="contactperson-field"
            v-model="contact_person"
            name="contact_person"
            data-vv-as="Enter Contact Person Here..."
          />
          <FieldErrorMessage :message="errors.first('contact_person')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapPnpGetters } = createNamespacedHelpers("pnpState");
const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");

export default {
  inject: ["$validator"],
  data() {
    return {
      selectedState: {
        name: "NSW"
      },
      australianStates: ["NSW", "QLD", "VIC", "ACT", "SA", "WA", "NT"]
    };
  },
  props: {
    readOnly: {
      type: Boolean
    },
    isPublic: {
      type: Boolean
    }
  },
  computed: {
    organisation_represented: {
      get() {
        return this.get_public_applicant_organisation_represented();
      },
      set(value) {
        this.set_public_applicant_organisation_represented(value);
      }
    },
    first_name: {
      get() {
        return this.get_public_applicant_first_name();
      },
      set(value) {
        this.set_public_applicant_first_name(value);
      }
    },
    last_name: {
      get() {
        return this.get_public_applicant_last_name();
      },
      set(value) {
        this.set_public_applicant_last_name(value);
      }
    },
    address_line_1: {
      get() {
        return this.get_public_applicant_address_line_1();
      },
      set(value) {
        this.set_public_applicant_address_line_1(value);
      }
    },
    address_line_2: {
      get() {
        return this.get_public_applicant_address_line_2();
      },
      set(value) {
        this.set_public_applicant_address_line_2(value);
      }
    },
    suburb: {
      get() {
        return this.get_public_applicant_suburb();
      },
      set(value) {
        this.set_public_applicant_suburb(value);
      }
    },
    state: {
      get() {
        return this.get_public_applicant_state();
      },
      set(value) {
        this.set_public_applicant_state(value);
      }
    },
    postcode: {
      get() {
        return this.get_public_applicant_postcode();
      },
      set(value) {
        this.set_public_applicant_postcode(value);
      }
    },
    phone_no: {
      get() {
        return this.get_public_applicant_phone_no();
      },
      set(value) {
        this.set_public_applicant_phone_no(value);
      }
    },
    email: {
      get() {
        return this.get_public_applicant_email();
      },
      set(value) {
        this.set_public_applicant_email(value.toLowerCase());
      }
    },
    contact_person: {
      get() {
        return this.get_public_applicant_contact_person();
      },
      set(value) {
        this.set_public_applicant_contact_person(value);
      }
    }
  },
  methods: {
    ...mapPnpGetters([
      "get_public_applicant_organisation_represented",
      "get_public_applicant_first_name",
      "get_public_applicant_last_name",
      "get_public_applicant_address_line_1",
      "get_public_applicant_address_line_2",
      "get_public_applicant_suburb",
      "get_public_applicant_state",
      "get_public_applicant_postcode",
      "get_public_applicant_phone_no",
      "get_public_applicant_email",
      "get_public_applicant_contact_person"
    ]),
    ...mapPnpMutations([
      "set_public_applicant_organisation_represented",
      "set_public_applicant_first_name",
      "set_public_applicant_last_name",
      "set_public_applicant_address_line_1",
      "set_public_applicant_address_line_2",
      "set_public_applicant_suburb",
      "set_public_applicant_state",
      "set_public_applicant_postcode",
      "set_public_applicant_phone_no",
      "set_public_applicant_email",
      "set_public_applicant_contact_person"
    ])
  }
};
</script>
