<template>
  <div class="main">
    <div class="base-container">
      <section class="pt-1 justify-center w-full block">
        <div class="flex text-normal self-center justify-center mb-4">
          <Tree class="tree-img" />
        </div>
        <span
          class="flex font-bold text-3xl text-black self-center justify-center mb-4"
        >
          Manage Place Name Proposals
        </span>
        <modal
          name="withdraw-proposal"
          :width="'33%'"
          height="auto"
          :classes="'rounded-lg bg-white py-16 px-16 text-center'"
        >
          <h3 class="mb-4">Confirm Withdrawal</h3>
          <p class="text-black mb-4">
            Are you sure you wish to withdraw this proposal?
          </p>

          <ButtonWithSpinner
            class="button-red w-2/6 mt-4 ml-auto"
            @click="withdrawConfirm"
            :isSpinning="$wait.is('withdrawing proposal')"
            :disabled="$wait.is('withdrawing proposal')"
            >Withdraw</ButtonWithSpinner
          >
          <ButtonWithSpinner
            class="button-blue-hollow w-2/6 mt-4 ml-4"
            @click="withdrawCancel"
            :disabled="$wait.is('withdrawing proposal')"
            >Cancel</ButtonWithSpinner
          >
        </modal>
        <modal
          name="redraft-proposal"
          :width="'50%'"
          height="auto"
          :classes="'rounded-lg bg-white py-16 px-16 text-center'"
        >
          <h3 class="mb-4">Re-draft Proposal</h3>
          <p class="text-black">
            Are you sure that you want to Re-draft this proposal? Doing so will
            retire the current record and it’s status and create a new duplicate
            record in a draft state. You will be able to modify the proposal
            before submission.
          </p>
          <div class="flex flex-row justify-center w-full">
            <ButtonWithSpinner
              @click="redraftCancel"
              :disabled="$wait.is('loading Proposals')"
              class="button-red mt-4 mx-4"
            >
              Cancel Re-draft
            </ButtonWithSpinner>
            <ButtonWithSpinner
              @click="redraftConfirm"
              :isSpinning="$wait.is('loading Proposals')"
              :disabled="$wait.is('loading Proposals')"
              class="button-blue mt-4 mx-4"
            >
              Re-draft Proposal
            </ButtonWithSpinner>
          </div>
        </modal>
        <modal
          name="delete-proposal"
          :width="'50%'"
          height="auto"
          :classes="'rounded-lg bg-white py-16 px-16 text-center'"
        >
          <h3 class="mb-4">Delete Proposal</h3>
          <p class="text-black">
            Are you sure that you want to delete this proposal?
          </p>
          <div class="flex flex-row justify-center w-full">
            <ButtonWithSpinner
              @click="deleteCancel"
              :disabled="$wait.is('deleting proposal')"
              class="button-red mt-4 mx-4"
            >
              Cancel
            </ButtonWithSpinner>
            <ButtonWithSpinner
              @click="deleteConfirm"
              :isSpinning="$wait.is('deleting proposal')"
              :disabled="$wait.is('deleting proposal')"
              class="button-blue mt-4 mx-4"
            >
              Delete Proposal
            </ButtonWithSpinner>
          </div>
        </modal>
        <div v-if="isAdmin && !isPreparingAgenda()" class="w-full flex">
          <button
            class="button-blue mt-4 mb-6 mx-4 jest-view-section-8 flex-1"
            @click="getSection8()"
          >
            View Proposals for Board Consideration
          </button>
          <button
            class="button-blue mt-4 mb-6 mx-4 jest-view-section-10 flex-1"
            @click="getSection10()"
          >
            View Proposals for GNB Reconsideration
          </button>
        </div>

        <showLoading isLoading="loading Proposals">
          <template v-slot:component>
            <div class="flex items-center mt-4" v-if="!isPreparingAgenda()">
              <select
                class="w-1/2 input-area-appear bg-white mr-4"
                v-model="pnpTypeFilter"
                name="pnp_type"
                id="pnp_type"
                @change="filterTypes"
              >
                <option value="" disabled hidden selected="selected"
                  >Any Proposal Type</option
                >
                <option
                  v-for="(item, key) in GEONAME_TYPE_LABELS"
                  :value="item.type"
                  v-bind:key="key"
                  >{{ item.label }}</option
                >
              </select>
              <select
                class="w-1/2 input-area-appear bg-white mr-0"
                v-model="pnpGeonameStatusFilter"
                name="display_status"
                id="display_status"
                @change="filterGeonameStatus"
              >
                <option value="" disabled hidden selected="selected"
                  >All Statuses</option
                >
                <option
                  v-for="(item, key) in statusList"
                  :value="item"
                  v-bind:key="key"
                  >{{ item }}</option
                >
              </select>
            </div>
            <div class="w-full " v-if="isPreparingAgenda()">
              <div class="button-back" @click="closeAgendaView()">
                <BackArrowWithTitle
                  title="Manage Proposals"
                  class="float-left"
                />
              </div>
              <ButtonWithSpinner
                v-if="isViewingSection8"
                class="button-blue float-right w-40 mt-4 mb-6 mx-4"
                @click="downloadAgenda()"
                :isSpinning="$wait.is('downloadingAgenda')"
                :disabled="$wait.is('downloadingAgenda') || agenda.length < 1"
                >Download Proposals</ButtonWithSpinner
              >
            </div>
            <div class="flex-0">
              <Checkbox
                v-if="!isPreparingAgenda()"
                data-cypress="checkboxArchived"
                v-model="includeArchived"
                :disabled="false"
                name="isArchived"
                class="text-black ml-auto flex-no-shrink"
                label="Show Archived Proposals?"
                :isDisabled="true"
              ></Checkbox>
            </div>
            <div
              class="self-center justify-center flex mt-32 text-black font-bold text-2xl w-full"
              v-if="!proposals.length"
            >
              No Proposals Found
            </div>
            <section
              v-bind:class="{
                'border-blue': proposal.selected_for_agenda,
                'modified-background': proposal.display_status === 'Modified'
              }"
              class="justify-center w-full block self-center bg-white text-black rounded-lg pt-4 mt-3 mb-3"
              v-for="(proposal, index) in proposals"
              v-bind:key="index"
            >
              <div class="w-full pl-14 pr-2 flex-col mx-auto">
                <div class="flex-1 self-center flex justify-between pb-6">
                  <div class="self-center flex-1">
                    <h2
                      class="self-center pt-2 h-auto mb-4 pr-10"
                      data-cypress="proposal-reference"
                    >
                      {{ proposal.geographical_name }}
                    </h2>
                    <p class="mb-2" v-if="proposal.reference_no">
                      <span class="flex-1 text-blue text-normal-bold">
                        Reference Number: {{ proposal.reference_no }}
                      </span>
                    </p>
                    <p class="pb-2 text-normal">
                      Submitted {{ formattedTime(proposal.date_created) }} by
                      <span
                        class="flex-1 text-blue-button text-normal-bold"
                        v-if="proposal.organisation_name"
                      >
                        {{ proposal.organisation_name }}
                      </span>
                      <span
                        class="flex-1 text-blue-button text-normal-bold"
                        v-if="!proposal.organisation_name"
                      >
                        {{ proposal.first_name }} {{ proposal.last_name }}
                      </span>
                    </p>
                    <div class="flex-wrap flex-start">
                      <proposalButton
                        :label="placeClassificationHelper(proposal.type)"
                        :classNames="
                          `text-normal-bold cursor-default ${proposal.type}`
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="pr-14 pt-2 flex-column text-right"
                    v-if="!isPreparingAgenda()"
                  >
                    <div v-if="proposal.display_status !== 'Draft'">
                      <router-link
                        :to="{
                          name: 'placeNameProposal',
                          params: { proposalId: proposal.identifier }
                        }"
                        class="text-blue-button font-bold"
                        >View Proposal</router-link
                      >
                    </div>
                    <div v-if="proposal.display_status !== 'Draft'">
                      <router-link
                        :to="{
                          name: 'PNPAuditTrail',
                          params: { proposalId: proposal.identifier }
                        }"
                        class="text-blue-button font-bold"
                        >View Audit/Records</router-link
                      >
                    </div>
                    <div
                      v-if="
                        isAdmin &&
                          proposal.display_status !== 'Draft' &&
                          (proposal.type === PROPOSAL_TYPE_BOUNDARY ||
                            proposal.type === PROPOSAL_TYPE_LOCALITY)
                      "
                    >
                      <router-link
                        :to="{
                          name: 'placeNameProposal',
                          params: { proposalId: proposal.identifier },
                          query: { edit: true }
                        }"
                        class="text-blue-button font-bold"
                        >Edit Proposal</router-link
                      >
                    </div>

                    <div v-if="isProposalPendingEndorsement(proposal)">
                      <router-link
                        :to="{
                          name: 'endorsePlaceNameProposal',
                          params: { proposalId: proposal.identifier }
                        }"
                        class="text-blue-button font-bold"
                        >Add Endorsement Info</router-link
                      >
                    </div>

                    <div v-if="proposal.display_status === 'Draft'">
                      <router-link
                        :to="{
                          name: 'placeNameProposal',
                          params: { proposalId: proposal.identifier }
                        }"
                        class="text-blue-button font-bold"
                        >Resume Draft</router-link
                      >
                    </div>
                    <div
                      v-if="checkAddPublicCommentAvailability(proposal)"
                    >
                      <router-link
                        :to="{
                          name: 'placeNameProposalAddPublicComment',
                          params: {
                            proposalId: proposal.advertisement_identifier
                          }
                        }"
                        class="text-blue-button font-bold"
                        >Add Public Comment</router-link
                      >
                    </div>
                    <div
                      v-if="
                        proposal.display_status === 'Under Review' &&
                          user.isAdmin
                      "
                    >
                      <router-link
                        :to="{
                          name: 'pnpSeekClarification',
                          params: {
                            proposalId: proposal.identifier
                          }
                        }"
                        class="text-blue-button font-bold"
                        >Seek Clarification</router-link
                      >
                    </div>
                    <div
                      v-if="
                        proposal.display_status === 'Clarification' ||
                          proposal.display_status === 'Clarification - Dual'
                      "
                      class="float-right"
                    >
                      <router-link
                        :to="{
                          name: 'clarifyPnp',
                          params: {
                            proposalId: proposal.identifier
                          }
                        }"
                        class="text-blue-button font-bold"
                        >Respond to Query</router-link
                      >
                    </div>
                    <div
                      v-if="proposal.display_status === 'Clarification Advert'"
                      class="float-right"
                    >
                      <router-link
                        :to="{
                          name: 'clarifyAdvertPnp',
                          params: {
                            proposalId: proposal.identifier
                          }
                        }"
                        class="text-blue-button font-bold"
                        >Add Display Information</router-link
                      >
                    </div>
                    <div
                      v-if="
                           proposal.display_status === 'Applicant To Review Comments' ||
                           proposal.display_status === 'Applicant To Review Comments - Dual'
                          "
                    >
                      <router-link
                        :to="{
                          name: 'pnpProposalComments',
                          params: {
                            proposal: proposal,
                            advertisementId: proposal.advertisement_identifier,
                            fromRespondToComments: true,
                            isCouncilUploadAvailable: proposal.display_status === 'Applicant To Review Comments'
                          }
                        }"
                        v-if="
                          compareDate(proposal.advertisement_date_end) &&
                            !user.isAdmin &&
                            user.organisation_name
                        "
                        class="text-blue-button font-bold"
                        >Respond to Comments</router-link
                      >
                      <span
                        v-if="
                          !compareDate(proposal.advertisement_date_end) &&
                            !user.isAdmin
                        "
                        >Response to Comments available <br />after
                        {{
                          formattedTime(proposal.advertisement_date_end)
                        }}</span
                      >
                    </div>
                    <div v-if="checkPublicCommentAvailability(proposal)">
                      <router-link
                        :to="{
                          name: 'pnpProposalComments',
                          params: {
                            advertisementId: proposal.advertisement_identifier,
                            fromRespondToComments: false,
                            proposal: proposal
                          }
                        }"
                        v-if="proposal.advertisement_identifier"
                        class="text-blue-button font-bold"
                      >
                        View Comments/Responses
                      </router-link>
                    </div>                    

                    <div v-if="proposal.withdrawalAvailable">
                      <a
                        @click="withdrawProposal(proposal.identifier)"
                        class=" text-red-button font-bold"
                        >Withdraw</a
                      >
                    </div>
                    <div v-if="proposal.modifiedAvailable && !isAdmin">
                      <a
                        @click="
                          proposal.display_status === 'Modified'
                            ? redraftConfirm(proposal.identifier)
                            : redraftProposal(proposal.identifier)
                        "
                        class=" text-red-button font-bold"
                        >Re-draft</a
                      >
                    </div>
                    <div v-if="proposal.display_status === 'Draft'">
                      <a
                        @click="deleteProposal(proposal.identifier)"
                        class=" text-red-button font-bold"
                        >Delete Draft</a
                      >
                    </div>
                  </div>

                  <div
                    class="self-start flex flex-row"
                    v-if="isPreparingAgenda()"
                  >
                    <template v-if="isViewingSection8">
                      <span class="pt-3">Select Proposal?</span>
                      <Checkbox
                        class="inline font-bold flex text-blue-button"
                        v-on:input="
                          val =>
                            selectProposalForAgenda({
                              id: proposal.identifier,
                              value: val
                            })
                        "
                      ></Checkbox>
                    </template>
                    <div class="pr-14 pt-2" v-if="isViewingSection10">
                      <a
                        @click="
                          downloadSingleAgenda(proposal.identifier, index)
                        "
                        class="text-blue-button font-bold cursor-pointer"
                        :disabled="proposal.loadingAgenda"
                        :class="[
                          proposal.loadingAgenda
                            ? 'cursor-wait'
                            : 'cursor-pointer'
                        ]"
                        >Download Agenda Item</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr class="pnp-divider w-full" />
              <div class="w-9/10 mx-auto">
                <div class="flex flex-row">
                  <div class="w-3/6 pb-4 pt-2 mr-4">
                    <span class="flex-1 text-blue-button text-normal-bold">
                      Local Government Area(s)
                    </span>
                    <span
                      class="block"
                      v-for="(lga, index) in proposal.lga"
                      :key="index"
                      >{{ lga }}</span
                    >
                    <span v-if="!proposal.lga.length" class="font-bold block">
                      None
                    </span>
                  </div>
                  <div class="flex-grow ml-10 pt-2 pb-4">
                    <span class="flex-1 text-blue-button text-normal-bold">
                      Localities
                    </span>
                    <div v-if="proposal.type === PROPOSAL_TYPE_PLACE">
                      <span
                        class="block"
                        v-for="(locality, index) in proposal.locality"
                        :key="index"
                        >{{ locality }}</span
                      >
                      <span v-if="!proposal.locality.length" class="font-bold">
                        None
                      </span>
                    </div>
                    <div
                      v-if="
                        proposal.type === PROPOSAL_TYPE_BOUNDARY ||
                          proposal.type === PROPOSAL_TYPE_LOCALITY
                      "
                    >
                      <span
                        class="block"
                        v-for="(locality,
                        index) in proposal.affected_localities"
                        :key="index"
                        >{{ locality }}</span
                      >
                      <span
                        v-if="!proposal.affected_localities.length"
                        class="font-bold"
                      >
                        None
                      </span>
                    </div>
                  </div>
                  <StatusButton
                    :label="proposal.display_status"
                    :status="proposal.display_status"
                    classNames="text-normal-bold cursor-default"
                  />
                </div>
              </div>
            </section>
            <section
              class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6"
            >
              <div
                v-if="!$wait.is('loading Proposals') && proposals.length"
                class="mx-auto text-center self-center"
              >
                <paginate
                  v-model="page"
                  :page-count="Math.ceil(proposalCount / limit)"
                  :page-range="10"
                  :margin-pages="1"
                  :click-handler="changePage"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                >
                </paginate>
                <p>
                  Showing
                  {{
                    (
                      (page - 1) * limit +
                      1
                    ).toLocaleString()
                  }}
                  to
                  {{
                    Math.min(
                      (page - 1) * limit + limit,
                      proposalCount
                    ).toLocaleString()
                  }}
                  of
                  {{ proposalCount.toLocaleString() }}
                  proposals
                </p>
              </div>
            </section>
          </template>
        </showLoading>
      </section>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { createNamespacedHelpers, mapGetters, mapMutations, mapActions } from "vuex";
import Paginate from "vuejs-paginate";

import { waitFor } from "vue-wait";
import FileSaver from "file-saver";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions,
  mapMutations: mapPnpMutations
} = createNamespacedHelpers("pnpState");

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY,
  GEONAME_TYPE_LABELS
} from "./pnp-constants.js";

import Tree from "@/assets/Images/tree-black.svg";
import proposalButton from "@/components/proposals/road/proposalButton";

import pnpCRUD from "@/helpers/pnpCRUD.js";

export default {
  name: "proposals",
  data() {
    return {
      user: [],
      proposalIdSelectedForWithdrawal: null,
      isViewingSection8: false,
      isViewingSection10: false,
      organisation_id: this.$route.query.organisation_id
        ? parseInt(this.$route.query.organisation_id)
        : null,
      PROPOSAL_TYPE_PLACE,
      PROPOSAL_TYPE_BOUNDARY,
      PROPOSAL_TYPE_LOCALITY,
      GEONAME_TYPE_LABELS,
      pnpTypeFilter: "ANY",
      pnpGeonameStatusFilter: "All Statuses",
      addPublicCommentStatus: [
        "Open For Public Comment",
        "Open For Public Comment - Dual",
        "Closed For Public Comment",
        "Closed For Public Comment - Dual"
      ],
      publicCommentsProposalStatus: {
        admin: [
          "Modified",
          "Open For Public Comment",
          "Open For Public Comment - Dual",
          "Applicant To Review Comments",
          "Applicant To Review Comments - Dual",
          "Board To Reconsider",
          "Board To Reconsider - Dual",
          "Abandoned",
          "Abandoned - Dual",
          "Closed For Public Comment",
          "Closed For Public Comment - Dual",
          "Official Assigned",
          "Official Assigned - Dual",
          "Recommendation With Minister",
          "Recommendation With Minister - Dual",
          "Rejected",
          "Rejected - Dual",
          "Pending Gazette",
          "Pending Gazette - Dual",
          "Withdrawn",
          "Official Assigned NPWS",
          "Official Assigned NPWS - Dual",
          "Official Assigned EDU",
          "Official Assigned EDU - Dual",
          "Deferred",
          "Deferred - Dual",
          "Pending Endorsement",
          "Pending Endorsement - Dual"
        ],
        council: [
          "Modified",
          "Pending Gazette",
          "Pending Gazette - Dual",
          "Official Assigned",
          "Official Assigned - Dual",
          "Official Assigned NPWS",
          "Official Assigned NPWS - Dual",
          "Official Assigned EDU",
          "Official Assigned EDU - Dual",
          "Abandoned",
          "Abandoned - Dual",
          "Deferred",
          "Deferred - Dual",
          "Pending Endorsement",
          "Pending Endorsement - Dual",
          "Withdrawn",
          "Recommendation With Minister",
          "Recommendation With Minister - Dual"
        ]
      }
    };
  },
  components: {
    Paginate,
    Tree,
    proposalButton
  },
  computed: {
    proposals() {
      const proposals = this.getProposalsList();
      proposals.forEach(p => (p.loadingAgenda = false));
      return proposals;
    },
    isModified(status) {
      return !!status === "Modified";
    },
    proposalCount: {
      get() {
        return this.getProposalCount();
      }
    },
    agenda: {
      get() {
        return this.getAgenda();
      }
    },
    statusList: {
      get() {
        return this.getPlaceNameStatuses();
      }
    },
    isAdmin() {
      return !!this.user.isAdmin;
    },
    includeArchived: {
      get() {
        return this.proposalSearch.includeArchived;
      },
      set(value) {
        this.setIncludeArchived(value);
      }
    },
    page: {
      get() {
        return this.proposalSearch.page;
      },
      set(value) {
        this.setProposalSearchPage(value);
      }
    },
    limit: {
      get() {
        return this.proposalSearch.limit;
      },
      set(value) {
        this.setProposalSearchLimit(value);
      }
    },
    type: {
      get() {
        return this.proposalSearch.type;
      },
      set(value) {
        this.setProposalSearchType(value);
      }
    },
    display_status: {
      get() {
        return this.proposalSearch.display_status;
      },
      set(value) {
        this.setProposalSearchDisplayStatus(value);
      }
    },
    ...mapGetters(["showLoginModal", "proposalSearch"])
  },
  async created() {
    this.user = this.currentUser();
    // this.queryParams.keyword = this.$route.query.keyword;
    await this.fetchProposals();
  },
  watch: {
    $route(to) {
      this.page = 1;
      // this.queryParams.keyword = to.query.keyword;
    },
    async includeArchived(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.page = 1;
        await this.fetchProposals();
      }
    },
    async "proposalSearch.keyword"() {
      this.page = 1;
      await this.fetchProposals();
    },
    async showLoginModal() {
      // a login modal has been closed, so we'd better refresh
      await this.$nextTick();
      this.fetchProposals();
    }
  },
  methods: {
    ...mapPnpActions([
      "fetchProposalsList",
      "withdrawProposalById",
      "fetchPlaceNameStatuses"
    ]),
    ...mapPnpGetters([
      "getProposalsList",
      "getProposalCount",
      "getAgenda",
      "getPlaceNameStatuses"
    ]),
    ...mapPnpMutations([
      "selectProposalForAgenda",
      "removeProposalFromAgenda",
      "clearAgenda"
    ]),
    ...mapUserGetters({ currentUser: "user" }),
    ...mapMutations([
      "setKeyword",
      "setIncludeArchived",
      "setProposalSearchPage",
      "setProposalSearchLimit",
      "setProposalSearchType",
      "setProposalSearchDisplayStatus"
    ]),
    ...mapActions(["resetProposalSearch"]),
    compareDate(date) {
      return moment(date).format("X") < moment().format("X");
    },
    async prepareSection10Agenda() {
      const agenda = [...this.agenda];
      const section10Agenda = [];

      for (const agendaItem of agenda) {
        const comments = await pnpCRUD.getAdvertisedProposalComments(
          agendaItem.advertisement_identifier
        );
        const objectingComments = comments.filter(
          comment => comment.is_supportive === false
        );
        const supportingComments = comments.filter(
          comment => comment.is_supportive === true
        );
        section10Agenda.push({
          geographical_name: agendaItem.geographical_name,
          count_supporting: supportingComments.length,
          count_objecting: objectingComments.length,
          comments: objectingComments,
          objection_comments_gnb: agendaItem.objection_comments_gnb
        });
      }
      return section10Agenda;
    },
    async downloadAgenda() {
      this.$wait.start("downloadingAgenda");
      try {
        if (this.isViewingSection10) {
          const section10Agenda = await this.prepareSection10Agenda();
          const docoLink = await pnpCRUD.getAgendaDocument(
            section10Agenda,
            true
          );
          const s3Params = docoLink.documentLocation.split("/");
          FileSaver.saveAs(`${docoLink.documentUrl}`, s3Params[1]);
        } else {
          const docoLink = await pnpCRUD.getAgendaDocument(this.agenda);
          const s3Params = docoLink.documentLocation.split("/");
          FileSaver.saveAs(`${docoLink.documentUrl}`, s3Params[1]);
        }
        this.$wait.end("downloadingAgenda");
        this.clearAgenda();
        this.closeAgendaView();
      } catch (e) {
        this.clearAgenda();
        this.closeAgendaView();
        this.$wait.end("downloadingAgenda");
        // eslint-disable-next-line no-console
        console.error("There was an Error while downloading the Agenda ", e);
      }
    },
    async downloadSingleAgenda(identifier, index) {
      this.$wait.start("downloadingAgenda");
      this.$set(this.proposals[index], "loadingAgenda", true);
      try {
        if (this.isViewingSection10) {
          const docoLink = await pnpCRUD.getSingleAgendaDocument(
            identifier,
            true
          );
          const s3Params = docoLink.documentLocation.split("/");
          FileSaver.saveAs(`${docoLink.documentUrl}`, s3Params[1]);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("There was an Error while downloading the Agenda ", e);
      } finally {
        this.clearAgenda();
        this.closeAgendaView();
        this.$set(this.proposals[index], "loadingAgenda", false);
        this.$wait.end("downloadingAgenda");
      }
    },
    changePage: waitFor("loading Proposals", async function(pageNum) {
      this.page = pageNum;
      window.scrollTo(0, 0);
      await this.fetchProposals();
    }),
    filterTypes: waitFor("loading Proposals", async function(event) {
      let value = event.target.value;
      this.pnpTypeFilter = value;
      this.page = 1;
      if (value === "ANY") {
        this.type = "";
      } else {
        this.type = value;
      }
      window.scrollTo(0, 0);
      await this.fetchProposals();
    }),
    filterGeonameStatus: waitFor("loading Proposals", async function(event) {
      let value = event.target.value;

      this.pnpGeonameStatusFilter = value;
      this.page = 1;
      if (value === "All Statuses") {
        this.display_status = "";
      } else {
        this.display_status = value;
      }
      window.scrollTo(0, 0);
      await this.fetchProposals();
    }),
    isPreparingAgenda() {
      return (
        this.isAdmin && (this.isViewingSection8 || this.isViewingSection10)
      );
    },

    async getSection8() {
      this.page = 1;
      this.isViewingSection8 = true;
      await this.fetchProposals({
        display_status: "Board To Consider",
        page: 1
      });
    },
    async getSection10() {
      this.page = 1;
      this.isViewingSection10 = true;
      await this.fetchProposals({
        display_status: "Board To Reconsider",
        page: 1
      });
    },
    async closeAgendaView() {
      this.isViewingSection8 = false;
      this.isViewingSection10 = false;
      await this.fetchProposals();
    },
    fetchProposals: waitFor("loading Proposals", async function(params) {
      try {
        const reqParams = params
          ? Object.assign({}, this.proposalSearch, {
              display_status: params.display_status,
              page: params.page
            })
          : this.proposalSearch;
        reqParams.organisation_id = this.organisation_id;
        await this.fetchPlaceNameStatuses();
        await this.fetchProposalsList(reqParams);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching proposals: ${e.message}`);
      }
    }),
    placeClassificationHelper(type) {
      if (type === PROPOSAL_TYPE_PLACE) {
        return "Place Name Proposal";
      } else if (type === PROPOSAL_TYPE_LOCALITY) {
        return "New/Amended Address Locality Name";
      } else if (type === PROPOSAL_TYPE_BOUNDARY) {
        return "Address Locality Boundary Amendment";
      } else return type;
    },
    withdrawProposal(proposalId) {
      this.$modal.show("withdraw-proposal");
      this.proposalIdSelectedForWithdrawal = proposalId;
    },

    withdrawConfirm: waitFor(
      "withdrawing proposal",
      async function withdrawConfirm() {
        try {
          await this.withdrawProposalById(this.proposalIdSelectedForWithdrawal);
          await this.fetchProposals();
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Proposal Withdrawal has Failed",
            text: `The withdrawal of this Place Name Proposal has failed. ${error}`
          });
        }

        this.$modal.hide("withdraw-proposal");
        this.proposalIdSelectedForWithdrawal = null;
      }
    ),
    withdrawCancel() {
      this.$modal.hide("withdraw-proposal");
      this.proposalIdSelectedForWithdrawal = null;
    },
    redraftProposal(proposalId) {
      this.$modal.show("redraft-proposal");
      this.proposalIdSelectedForRedraft = proposalId;
    },
    redraftConfirm: waitFor("loading Proposals", async function(proposalId) {
      const proposalIdForRedraft = proposalId
        ? proposalId
        : this.proposalIdSelectedForRedraft;
      try {
        await pnpCRUD.createRedraftedProposal(proposalIdForRedraft);
        this.proposalIdSelectedForRedraft = null;
        await this.fetchProposals();
        this.$notify({
          group: "toast",
          type: "success",
          title: "Re-draft Successful",
          text:
            "The re-drafted proposal has been added to the top of your proposals list."
        });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Re-draft has Failed",
          text: `The re-draft of this Place Name Proposal has failed. ${error}`
        });
        this.proposalIdSelectedForRedraft = null;
      }

      this.$modal.hide("redraft-proposal");
      this.proposalIdSelectedForRedraft = null;
    }),
    redraftCancel() {
      this.$modal.hide("redraft-proposal");
      this.proposalIdSelectedForRedraft = null;
    },
    deleteProposal(proposalId) {
      this.$modal.show("delete-proposal");
      this.proposalIdSelectedForDelete = proposalId;
    },
    deleteConfirm: waitFor("deleting proposal", async function() {
      try {
        await pnpCRUD.deletePlaceNameProposal(this.proposalIdSelectedForDelete);
        this.proposalIdSelectedForDelete = null;
        await this.fetchProposals();
        this.$notify({
          group: "toast",
          type: "success",
          title: "Deletion Successful",
          text: "The proposal has successfully been deleted."
        });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal deletion has Failed",
          text: `This proposal was unable to be deleted. ${error}`
        });
        this.proposalIdSelectedForDelete = null;
      }

      this.$modal.hide("delete-proposal");
      this.proposalIdSelectedForDelete = null;
    }),
    deleteCancel() {
      this.$modal.hide("delete-proposal");
      this.proposalIdSelectedForDelete = null;
    },
    formattedTime(time) {
      var tm = moment(time, "YYYY-MM-DD kk:mm:ss.SSSSSS");
      return tm.format("DD MMM YYYY hh:mm:ss a z ");
    },

    /**
     * Is the proposal in a pending state
     */
    isProposalPendingEndorsement(proposal) {
      return proposal.display_status === "Pending Endorsement";
    },
    checkPublicCommentAvailability(proposal) {
      // Checks the role of the current user
      const role = this.user.isAdmin ? "admin" : !this.user.isAdmin && this.user.organisation_id ? "council" : false;

      // Checks the proposal display status to see if the public comment option is available
      if(role) return this.publicCommentsProposalStatus[role].includes(proposal.display_status);
      else return false;
    },
    checkAddPublicCommentAvailability(proposal) {
      // Checks the role of the current user
      const role = this.user.isAdmin ? true : false;

      if(role) return this.addPublicCommentStatus.includes(proposal.display_status);
      else return false;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if(from.name !== "placeNameProposal"){
        await vm.resetProposalSearch();
      } else {
        if (vm.type) {
          vm.pnpTypeFilter = vm.type;
        }
        if (vm.display_status) {
          vm.pnpGeonameStatusFilter = vm.display_status;
        }
      }
    });
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/Images/BG_Places-white.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.border-blue {
  border: 3px solid #002664;
}

.tree-img {
  height: 50px;
}

.pnp-divider {
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

.button-back {
  cursor: pointer;
}

.modified-background {
  background-color: #ebebebd2 !important;
}
</style>
