<template>
  <div class="w-full bg-white rounded-lg flex items-center text-sub-title mt-5">
    <div class="items-center flex w-1/4 pl-4" data-cypress="proposed-road-name">
      <proposalIcon
        :label="road.roadname_classification"
        :classNames="'w-12 h-12 text-base mr-2'"
      />
      {{ road.roadname }} {{ road.road_type }}
    </div>

    <div class="dividing-line-v items-baseline flex w-1/2 pl-4 border-r">
      <div class=" pl-4 mr-4 w-1/2">
        <p class="text-xs font-ibold text-grey-text mb-2 pt-5">
          Road Location
        </p>
        <div class="pb-3">
          <p
            class="text-black text-sub-normal pb-1"
            v-for="(sub, index) in road.suburb_name"
            v-bind:key="index"
          >
            {{ sub }}
          </p>
        </div>
      </div>
      <div class="w-1/2 mb-5">
        <p class="text-xs font-semibold text-grey-text mb-2">
          Road Name Validity
        </p>
        <p
          v-if="Object.keys(road.geometry).length > 0 && road.distance"
          class="text-black text-sub-normal-bold py-2"
          :class="road.validity"
        >
          <Loading
            v-if="evaluation === null"
            class="flex-0"
            style="height: 15px"
          ></Loading>
          {{
            evaluation === null ? "" : evaluation + " Potential issues found"
          }}
        </p>
        <p
          v-if="Object.keys(road.geometry).length < 1 || !road.distance"
          class="text-red text-sub-normal-bold py-2"
          :class="road.validity"
        >
          Extent not drawn
        </p>
      </div>
    </div>

    <div class="pl-5 w-1/4 items-baseline justify-end flex">
      <div class=" pl-4 mr-4 w-full ">
        <p class="text-xs font-semibold text-grey-text w-full mb-2">Actions</p>
        <div class="flex">
          <router-link
            :to="
              road.identifier && road.name_status !== 'Drafted'
                ? {
                    name: 'roadNameProposalEdit',
                    params: { roadNameId: road.identifier }
                  }
                : {
                    name: 'roadNameProposalEdit',
                    params: { roadNameId: index }
                  }
            "
            class="text-black text-normal font-semibold text-blue-button"
            data-cypress="edit-road"
            :disabled="$wait.is('loading')"
            >Edit Road</router-link
          >
          <a
            class="ml-4 text-normal font-semibold text-red-button cursor-pointer"
            @click="removeRoad(index)"
            data-cypress="remove-road"
            :disabled="$wait.is('loading')"
            >Remove Road</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { evaluateRoadnameNew } from "../../../../helpers/validations";
import proposalIcon from "../proposalIcon.vue";
import Loading from 'vue-simple-spinner'

export default {
  components: {
    proposalIcon,
    Loading
  },
  props: ["road", "removeRoad", "index", "evaluation"],
  watch: {
    road: {
      handler(newVal) {
        newVal && this.nameEvaluation(newVal);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async nameEvaluation(roadNameProposal) {
      return;
      if (
        !roadNameProposal.roadname ||
        !roadNameProposal.lga_name ||
        !roadNameProposal.suburb_name ||
        !roadNameProposal.distance ||
        !roadNameProposal.geometry
      )
        return;
      const evaluation = await evaluateRoadnameNew(
        roadNameProposal.roadname,
        roadNameProposal.lga_name,
        roadNameProposal.suburb_name,
        roadNameProposal.distance,
        roadNameProposal.geometry,
        true
      );
      this.$emit(
        "evaluation-results",
        roadNameProposal.roadname,
        evaluation.breaches ? evaluation.breaches.issueCount : 0
      );
    }
  }
};
</script>
