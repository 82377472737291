<template>
     <div :class='{"tabs__light": mode === "light", "tabs__dark": mode === "dark"}'>
        <ul class='tabs__header'>
        <li v-for='(tab, index) in tabs'
            :key='tab.title'
            @click='selectTab(index)'
            :class='{"tab__selected": (index == selectedIndex)}'>
            {{ tab.title }}
        </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Tabs",
        props: {
        mode: {
            type: String,
            default: 'light'
            }
        },
        data () {
            return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: []         // all of the tabs
            }
        },
        created () {
            this.tabs = this.$children
        },
        mounted () {
            this.selectTab(0)
        },
        methods: {
            selectTab (i) {
                this.selectedIndex = i
                // loop over all the tabs
                this.tabs.forEach((tab, index) => {
                    tab.isActive = (index === i)
                })
            }
        }
    }
</script>

<style scoped>
  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0 0 0 1.25rem;
    padding: 0;
  }

  ul.tabs__header > li {
    padding: 0.937rem 1.875rem;
    border-radius: 0.625rem; /* 10px */
    margin: 0;
    display: inline-block;
    margin-right: 0.312rem; /* 5px */
    cursor: pointer;
  }

  ul.tabs__header > li.tab__selected {
    font-weight: bold;
    border-radius: 0.625rem 0.625rem 0 0;
    border-bottom: 0.5rem solid transparent;
  }

  .tab {
    display: inline-block;
    color: black;
    padding: 1.25rem; /* 20px */
    width: 100%;
    border-radius: 0.625rem; /* 10px */
    min-height: 20rem; /* 320px */
  }

  .tabs__light .tab{
    background-color: #fff;
  }

  .tabs__light li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__light li.tab__selected {
    background-color: #fff;
    color: rgb(0, 38, 100);
  }

  /* .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }

  .tabs__dark li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;
  } */
</style>