<template>
  <div
    class="
      justify-center
      w-full
      block
      self-center
      bg-white
      text-black
      rounded-lg
      pt-8
      mt-3
      mb-3
    "
    :class="{ isProcessing: !!numProcessing }"
  >
    <div
      class="
        proposal-list-item
        flex flex-col flex flex-grow
        justify-center
        self-center
      "
    >
      <div class="w-9/10 mx-auto">
        <div class="flex-1 self-center flex justify-between pb-6">
          <div class="self-center flex-1">
            <div class="flex items-center">
              <div
                class="py-3 justify-center mb-1"
                v-if="reassign && user.isAdmin"
              >
                <label class="container items-baseline flex height-fix">
                  <input
                    class="checkbox-input"
                    type="checkbox"
                    :checked="isChecked"
                    @change="passProposalIdentifier"
                  />
                  <span class="border-2 checkmark"></span>
                </label>
              </div>
              <h2
                class="self-center pt-2 h-auto mb-4 pr-10"
                data-cypress="proposal-reference"
              >
                Proposal {{ proposal.reference_no }}
              </h2>
            </div>
            <p class="pb-2 text-normal">
              {{ submittedOrDrafted }} {{ dateSubmitted }} by
              <span class="flex-1 text-blue-button text-normal-bold">
                {{ proposal.authority_name }}
              </span>
            </p>
            <div class="flex-wrap flex-start">
              <span
                v-for="(item, index) in proposal.classificationList"
                v-bind:key="index"
              >
                <proposalButton
                  :label="`${item.amount} ${item.name} Roads`"
                  :status="item.roadname_classification"
                  classNames="text-normal-bold cursor-default"
                />
              </span>
            </div>
          </div>
          <div class="self-start">
            <router-link
              :to="{
                name: 'roadNameProposalBucket',
                params: { proposalId: proposal.identifier }
              }"
              class="text-blue-button font-bold flex-1 actions"
              >{{ viewText }}</router-link
            >
          </div>
          <!-- actions -->
        </div>
      </div>
      <div class="dividing-line-h-1 w-full"></div>
      <div class="w-9/10 flex-col mx-auto">
        <div class="self-center justify pt-4 flex">
          <div class="flex flex-col w-1/6 flex-no-grow mt-4">
            <h3 class="">{{ numRoads }} Roads</h3>
            <p v-if="!!numProcessing">{{ numProcessing }} Roads processing</p>
          </div>

          <!-- actions -->
          <div class="flex-wrap flex-1 mb-4">
            <span
              v-for="(item, index) in proposal.statusList"
              v-bind:key="index"
            >
              <StatusButton
                v-if="item.amount === 0"
                :label="item.status"
                :status="item.status"
                classNames="text-normal-bold"
              />
              <StatusButton
                v-if="item.amount !== 0"
                :label="item.amount + ` ` + item.status"
                :status="item.status"
                classNames="text-normal-bold cursor-default"
                :data-cypress="`proposal-classification-${item.roadname_classification}`"
              />
            </span>
          </div>
          <div class="ml-auto flex" @click="collapsed = !collapsed">
            <div
              ref="collapse"
              class="rotate-transition text-blue-darker self-center"
              :class="[collapsed ? '' : 'rotate-chevron']"
            >
              <font-awesome-icon icon="chevron-circle-down" />
            </div>
          </div>
        </div>
      </div>
      <transition
        name="collapse"
        @enter="beforeEnterCollapse"
        @leave="beforeLeaveCollapse"
      >
        <div ref="collapse-body" v-show="!collapsed">
          <div v-for="road of proposal.roads" :key="road.identifier">
            <div class="flex pl-8">
              <div class="w-1/8 self-center">
                <proposalIcon
                  :label="road.roadname_classification"
                  classNames="w-12 h-12"
                />
              </div>
              <div class="w-1/4 mr-8 break-words">
                <p class="text-label pt-5 pb-2">Road Name</p>
                <h3>{{ road.roadname }} {{ road.road_type }}</h3>
              </div>
              <div class="w-3/8">
                <p class="text-label pt-5 pb-3">Road Location</p>
                <div class="pb-3">
                  <p
                    class="pb-1"
                    v-for="(sub, index) in road.suburb_name"
                    v-bind:key="index"
                  >
                    {{ sub }}
                  </p>
                </div>
              </div>
              <div class="w-1/4 flex flex-col justify-center">
                <span class="flex-0 text-label pt-5 self-center"
                  >Proposal status</span
                >
                <StatusButton
                  :label="road.name_status"
                  :status="road.name_status"
                  :extra="finalisedDate(road)"
                  classNames="font-bold mb-6"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import proposalButton from "../road/proposalButton";
import proposalIcon from "./proposalIcon";
import memoize from "lodash.memoize";

export default {
  name: "ProposalListItem",
  components: {
    proposalButton,
    proposalIcon
  },
  props: ["proposal", "reassign", "checkAll", "untickProposal"],
  data() {
    return {
      collapsed: true,
      isChecked: false
    };
  },
  computed: {
    numProcessing: {
      get() {
        // prettier-ignore
        return this.proposal.num_road_names
          ? Math.max(
            this.proposal.num_road_names - this.proposal.roads.length,
            0
          )
          : 0;
      }
    },
    numRoads: {
      get() {
        return this.proposal.num_road_names
          ? this.proposal.num_road_names
          : this.proposal.roads.length;
      }
    },
    viewText() {
      if (this.proposal.statusList[0].status === "Drafted") {
        return "Resume Draft";
      }
      return "View Proposal";
    },
    dateSubmitted: {
      get() {
        if (!this.proposal.date_submitted) {
          return "";
        }
        return moment(
          this.proposal.date_submitted,
          "YYYY-MM-DD kk:mm:ss.SSSSSS"
        ).format("D MMM YYYY h:mm:ss a");
      }
    },
    submittedOrDrafted() {
      return this.proposal.roads.some(road => road.name_status === "Drafted")
        ? "Drafted"
        : "Submitted";
    },
    user: function () {
      return this.$store.state.user.user;
    }
  },
  methods: {
    beforeEnterCollapse() {
      const content = this.$refs["collapse-body"];
      if (content) {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    },
    beforeLeaveCollapse() {
      const content = this.$refs["collapse-body"];
      content.style.maxHeight = 0 + "px";
    },
    finalisedDate: memoize(
      function (road) {
        if (road.date_gazetted) {
          return moment(road.date_gazetted).format("DD/MM/YYYY");
        }
        if (road.date_endorsed) {
          return moment(road.date_endorsed).format("DD/MM/YYYY");
        }

        return null;
      },
      road => JSON.stringify(road)
    ),
    passProposalIdentifier() {
      this.isChecked = !this.isChecked;
      const data = {
        isChecked: this.isChecked,
        identifier: this.$props.proposal.identifier,
        jiraId: this.$props.proposal.jira_id
      };
      this.$emit("passProposalIdentifier", data);
    }
  },
  watch: {
    checkAll: function (newVal, oldVal) {
      if(!this.$props.untickProposal) {
        this.isChecked = newVal;  
      }
    }
  }
};
</script>

<style scoped>
.isProcessing {
  background-color: rgba(255, 255, 255, 0.63);
  border: 1px solid black;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: max-height 0.25s;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
}

.rotate-transition {
  transition: transform 0.25s;
  cursor: pointer;
  font-size: 1.5rem;
}

.rotate-chevron {
  transform: rotate(180deg);
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #002664;
  background-color: #ffffff;
  transition-duration: 0.2s;
  border-radius: 2px;
}
input:disabled ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #5b5e63;
  background-color: rgb(194, 193, 193);
  transition-duration: 0.2s;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #84bddc;
  border-color: #002664;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #d8d8d8;
  border-color: #464a52;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #002664;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.height-fix {
  min-height: 24px;
}
</style>
