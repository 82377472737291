<template>
  <div>
    <modal
      name="update-lga"
      :maxWidth="600"
      height="auto"
      :classes="'rounded-lg bg-white'"
      :scrollable="false"
      :clickToClose="false"
    >
      <div
        class="w-full mx-auto pt-6 pb-10 px-6 text-black flex flex-col text-normal"
      >
        <a @click="hide()" class="cursor-pointer ml-auto">
          <ErrorIcon classNames="w-4" />
        </a>
        <div class="px-10 pb-1 text-full">
          Are you sure you want to update all geoname LGAs?
        </div>
        <div class="flex justify-center w-full mt-10 px-10">
          <div @click="hide()" class="button-red-hollow mr-4 w-2/5">Cancel</div>
          <ButtonWithSpinner
            class="button-blue ml-4 w-2/5"
            type="button"
            :disabled="$wait.is('updating lgas')"
            :isSpinning="$wait.is('updating lgas')"
            @click="updateLGAs"
          >
            OK
          </ButtonWithSpinner>
        </div>
      </div>
    </modal>
    <div class="base-container text-black flex flex-grow">
      <section class="justify-start w-full block flex flex-col">
        <ShowLoading isLoading="fetching cron state">
          <template slot="component">
            <NetworkError v-if="errorLoading" />
            <div v-if="!errorLoading">
              <h1 class="text-title" data-cypress="header">
                LGA Update Service
              </h1>
              <div
                class="w-full bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4"
              >
                <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
                  Spatial Portal
                </h3>
                <div class="flex mb-8">
                  <div class="w-2/3 text-justify pr-2">
                    When "Enabled" this service will query the Spatial Portal with the longitude and latitude of 1000 geonames every hour for the first week of each month.
                  </div>
                  <div class="w-1/3 flex pr-2">
                    <div class="ml-auto">
                      <event-rule-toggle v-model="enabled" :disabled="$wait.is('toggling event')"/>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="w-2/3 text-justify pr-2">
                    Or you can query 1000 geonames right now
                  </div>
                  <div class="w-1/3 flex pr-2">
                    <div class="ml-auto">
                      <ButtonWithSpinner
                        class="button-blue"
                        :disabled="$wait.is('querying Spatial')"
                        :isSpinning="$wait.is('querying Spatial')"
                        @click="queryNow"
                        >Query Now</ButtonWithSpinner
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-full bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4"
              >
                <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
                  Update LGAs
                </h3>
                <div class="flex mb-8">
                  <div class="w-2/3 text-justify pr-2">
                    Update the LGA of all geoname records with the "LGA Spatial" for those records
                  </div>
                  <div class="w-1/3 flex pr-2">
                    <div class="ml-auto">
                      <button
                        class="btn button-blue"
                        :disabled="$wait.is('querying Spatial')"
                        @click="$modal.show('update-lga')"
                        >Update LGAs</button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ShowLoading>
      </section>
    </div>
  </div>
</template>

<script>
import eventRuleToggle from "./event-rule-toggle";
import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  components: {
    eventRuleToggle
  },
  data() {
    return {
      enabled: null,
      errorLoading: false
    };
  },
  async created() {
    this.$wait.start("fetching cron state");
    try {
      const resp = await pnpCRUD.getLGASpatialQueryCronStatus();
      if (resp) {
        this.enabled = !!resp.enabled;
      }
    } catch (e) {
      this.errorLoading = true;
    }
    this.$wait.end("fetching cron state");
  },
  watch: {
    enabled(newVal, oldVal) {
      if (oldVal !== null) {
        this.toggleEventRuleStatus(newVal);
      }
    }
  },
  methods: {
    async queryNow() {
      this.$wait.start("querying Spatial");
      try {
        await pnpCRUD.querySpatialPortalForLGA();
        this.$notify({
          group: "toast",
          type: "success",
          title: "Spatial LGAs updated",
          text: "Spatial LGAs function run successfully."
        });
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "Failed to successfully get Spatial LGAs"
        });
      }
      this.$wait.end("querying Spatial");
    },
    async toggleEventRuleStatus(val) {
      this.$wait.start("toggling event");
      const status = val === true ? "enabled" : "disabled";
      try {
        await pnpCRUD.toggleLGASpatialQueryCron(status);
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: `Failed to toggle ${status}`
        });
        this.enabled = !val;
      }
      this.$wait.end("toggling event");
    },
    async updateLGAs() {
      this.$wait.start("updating LGAs");
      try {
        await pnpCRUD.updateLGAwithSpatialLGA();
        this.hide();
        this.$notify({
          group: "toast",
          type: "success",
          title: "LGAs updated",
          text: "geoname LGAs updated"
        });
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "Failed to update geoname LGAs"
        });
      }
      this.$wait.end("updating LGAs");
    },
    hide() {
      if (!this.$wait.is("updating lgas")) {
        this.$modal.hide("update-lga");
      }
    }
  }
};
</script>
