<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div class="w-2/3 bg-white mx-auto my-3 z-10 rounded">
      <div class="w-full bg-white text-black rounded-lg p-10">
        <div>
          <span
            @click="back()"
            class="text-red text-normal-bold cursor-pointer"
          >
            <font-awesome-icon icon="arrow-left" class="flex-auto" /> Back
          </span>
        </div>

        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          View {{ proposal.geographical_name }}
        </h3>
        <ShowLoading isLoading="fetchingProposal">
          <template v-slot:component>
            <div class="mx-20">
              <p
                v-if="
                  proposal.official_map_reference_document &&
                    proposal.official_map_reference_document.length
                "
              >
                <a
                  :href="
                    relativeFileUrl(
                      proposal.official_map_reference_document[0].s3_key
                    )
                  "
                  class="text-blue-button"
                  target="_blank"
                  >View Official Map
                </a>
              </p>

              <p
                v-if="
                  proposal.gazette_reference_document &&
                    proposal.gazette_reference_document.length
                "
              >
                <a
                  :href="
                    relativeFileUrl(
                      proposal.gazette_reference_document[0].s3_key
                    )
                  "
                  class="text-blue-button"
                  target="_blank"
                  >View Gazette Notice
                </a>
              </p>

              <p
                v-if="
                  proposal.media_release_reference_document &&
                    proposal.media_release_reference_document.length
                "
              >
                <a
                  :href="
                    relativeFileUrl(
                      proposal.media_release_reference_document[0].s3_key
                    )
                  "
                  class="text-blue-button"
                  target="_blank"
                  >View Media Release
                </a>
              </p>

              <p>
                <a
                  class="text-blue-button"
                  data-cypress="viewplace-button"
                  role="button"
                  @click="viewPlaceNameInfo()"
                >
                  View Place Name Record
                </a>
                <br />
              </p>

              <div class="text-center">
                <h3 class="pb-3 mb-3 w-full text-title ">
                  GEOGRAPHICAL NAMES ACT 1966
                </h3>
              </div>

              <p class="mb-3">
                In accordance with Section 9 of the Geographical Names Act 1966,
                all submissions lodged may be subject to a Government
                Information (Public Access) application and may be viewed by a
                third party to assist the Board in considering this proposal.
              </p>
              <p class="text-right mb-3 mt-4">
                NARELLE UNDERWOOD
                <br />
                Chair
              </p>
              <p class="mb-6">
                Geographical Names Board<br />
                PO Box 143<br />
                BATHURST NSW 2795
              </p>
            </div>

            <div class="dividing-line-h my-6"></div>

            <div class="flex justify-center">
              <!-- <ButtonWithSpinner
                class="button-blue-hollow flex justify-center mr-3"
                :disabled="false"
                @click="downloadCommentForm()"
                >Download Comment Form</ButtonWithSpinner
              > -->
              <Button
                class="button-blue-hollow flex justify-center mr-3"
                data-cypress="return-button"
                :disabled="false"
                @click="backToProposals()"
                >Return to Current Proposals Page
              </Button>
              <ButtonWithSpinner
                v-if="canComment"
                class="button-blue flex justify-center"
                data-cypress="comment-button"
                :disabled="false"
                @click="goToCommentForm()"
                >Comment on Proposal</ButtonWithSpinner
              >
            </div>
          </template>
        </ShowLoading>
      </div>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import moment from "moment";

import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "afterSubmit",
  async created() {
    // this.proposalId = this.$route.params.proposalId;
    this.fetchProposal();
  },
  computed: {
    advertisementId() {
      return this.$route.params.advertisementId;
    },
    canComment() {
      return this.proposal.allow_action === "ALLOW_COMMENT";
    }
  },
  data() {
    return {
      proposal: {}
    };
  },
  methods: {
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    back() {
      this.$router.push({ name: "currentPlaceNameProposals" });
    },
    fetchProposal: waitFor("fetchingProposal", async function() {
      try {
        this.proposal = await pnpCRUD.getAdvertisedProposal(
          this.advertisementId
        );
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve proposal",
          text: error
        });
      }
    }),
    formatDate: date => {
      return moment(date).format("DD MMM, YYYY");
    },
    viewPlaceNameInfo() {
      let route = this.$router.resolve({
        name: "geonameViewPublic",
        params: {
          geonameId: this.proposal.geoname_identifier
        }
      });
      window.open(route.href, "_blank");
    },
    goToCommentForm() {
      this.$router.push({
        name: "placeNameProposalPublicCommentForm",
        params: { commentId: "create" }
      });
    },
    backToProposals() {
      this.$router.push({ name: "currentPlaceNameProposals" });
    },
    downloadCommentForm() {
      // TODO
    }
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}
</style>
