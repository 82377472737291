"use strict";

import Vuex from "vuex";
import Vue from "vue";
import upperFirst from "lodash/upperFirst";
// state modules
import user from "./modules/userState";
import rnpState from "./modules/rnpState";
import pnpState from "./modules/pnpState";
import rnaState from "./modules/rnaState";
import proposalsState from "./modules/proposalsState";
import uploadState from "./modules/uploadState";
import systemState from "./modules/systemState";
import organisationState from "./modules/organisationState";

Vue.use(Vuex);

const initState = defaultState => state => {
  Object.assign(state, defaultState);
};

const stateMap = {
  user,
  rnpState,
  pnpState,
  rnaState,
  proposalsState,
  uploadState,
  systemState,
  organisationState
};
// add module initialisation mutation
Object.keys(stateMap).reduce((memo, moduleName) => {
  const module = stateMap[moduleName];
  const initModuleStateMutation = initState(module.state);
  module.mutations[`init${upperFirst(moduleName)}`] = initModuleStateMutation;
}, {});

// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    user,
    rnpState,
    pnpState,
    rnaState,
    proposalsState,
    uploadState,
    systemState,
    organisationState
  },
  strict: debug
});

export default store;
