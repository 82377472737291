var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "button-red-hollow",
          attrs: { "data-cypress": "change_password-button", type: "button" },
          on: { click: _vm.openPasswordChange },
        },
        [_vm._v(" Change Password ")]
      ),
      _c(
        "modal",
        {
          attrs: { name: "change-password", height: "auto" },
          on: { "before-close": _vm.beforeClose },
        },
        [
          _c("div", { staticClass: "flex flex-col justify-between" }, [
            _c("h2", { staticClass: "text-title flex-0 px-4" }, [
              _vm._v("Change Password"),
            ]),
            _c("div", { staticClass: "dividing-line-h" }),
            _c("div", { staticClass: "p-4" }, [
              _c(
                "label",
                {
                  staticClass: "block text-black text-normal-bold mb-2",
                  attrs: { for: "passwordInitial" },
                },
                [_vm._v("Current Password")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentPassword,
                    expression: "currentPassword",
                  },
                ],
                ref: "currentPassword",
                staticClass: "w-full input-area",
                attrs: {
                  id: "currentPassword",
                  name: "currentPassword",
                  type: "password",
                  placeholder: "",
                  "data-cypress": "password",
                },
                domProps: { value: _vm.currentPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.currentPassword = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("currentPassword"),
                      expression: "errors.has('currentPassword')",
                    },
                  ],
                  staticClass: "text-error mt-2",
                  attrs: { "data-cypress": "current-password-error" },
                },
                [_vm._v(_vm._s(_vm.errors.first("currentPassword")))]
              ),
              _c(
                "label",
                {
                  staticClass: "block text-black text-normal-bold mb-2 mt-4",
                  attrs: { for: "newPassword" },
                },
                [_vm._v("New Password")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|passwordPolicy",
                    expression: "'required|passwordPolicy'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newPassword,
                    expression: "newPassword",
                  },
                ],
                ref: "newPassword",
                staticClass: "w-full input-area",
                attrs: {
                  id: "newPassword",
                  name: "newPassword",
                  type: "password",
                  "data-cypress": "newPassword",
                },
                domProps: { value: _vm.newPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newPassword = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("newPassword"),
                      expression: "errors.has('newPassword')",
                    },
                  ],
                  staticClass: "text-error",
                  attrs: { "data-cypress": "password-error" },
                },
                [_vm._v(_vm._s(_vm.errors.first("newPassword")))]
              ),
              _c(
                "label",
                {
                  staticClass: "block text-black text-normal-bold my-2",
                  attrs: { for: "confirmNewPassword" },
                },
                [_vm._v("Confirm Password")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|confirmed:newPassword",
                    expression: "'required|confirmed:newPassword'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmNewPassword,
                    expression: "confirmNewPassword",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  id: "confirmNewPassword",
                  name: "Confirm Password",
                  type: "password",
                  "data-cypress": "password-confirm",
                },
                domProps: { value: _vm.confirmNewPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.confirmNewPassword = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("Confirm Password"),
                      expression: "errors.has('Confirm Password')",
                    },
                  ],
                  staticClass: "text-error",
                },
                [_vm._v("Passwords do not match.")]
              ),
              _c(
                "div",
                { staticClass: "self-end my-6 flex justify-between" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button-red-hollow",
                      attrs: {
                        "data-cypress": "cancel-button",
                        type: "button",
                      },
                      on: { click: _vm.closePasswordChange },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue",
                      attrs: {
                        type: "button",
                        "data-cypress": "change-button",
                        disabled: _vm.$wait.any || _vm.hasErrors,
                        isSpinning: _vm.$wait.is("changing password"),
                      },
                      on: { click: _vm.changePassword },
                    },
                    [_vm._v(" Change ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }