<template>
  <div class="base-container">
    <div
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3"
    >
      <div>
        <span @click="back()" class="text-red text-normal-bold cursor-pointer">
          <font-awesome-icon icon="arrow-left" class="flex-auto" /> Back
        </span>
      </div>

      <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
        Add a Comment for a Place Name Proposal
      </h3>
      <showLoading isLoading="fetchingProposalForComment">
        <template v-slot:component>
          <div v-if="proposal">
            <div class="text-black">
              <div class="w-full border flex flex-row border-gray-200 rounded ">
                <span
                  class="p-6 mr-16 ml-4 mt-2 inline-block align-middle text-black font-bold text-2xl"
                >
                  {{ proposal.geographical_name }}
                </span>
                <div
                  class="dividing-line-v text-black flex-grow flex flex-row "
                >
                  <div class="flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4">
                    <span><b>LGA</b></span>
                    <p v-for="lga in proposal.lga" :key="lga">{{ lga }}</p>
                  </div>
                  <div class="flex-grow pb-4 pt-4 w-1/4 pr-4">
                    <span><b>Designation</b></span>
                    <p>{{ proposal.designation }}</p>
                  </div>
                  <div class="flex-grow pb-4 pt-4 w-1/4">
                    <span><b>Publication Date</b></span>
                    <p>{{ formatDate(proposal.date_start) }}</p>
                  </div>
                  <div class="flex-grow pb-4 pt-4 w-1/4">
                    <span><b>Closing Date</b></span>
                    <p>{{ formatDate(proposal.date_end) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="text-normal-bold mt-8">
                <a class="text-red-star">*</a>Does the comment support the above
                proposed place name?
              </p>
              <div class="flex flex-row text-normal">
                <RadioButton
                  class="flex-0 mr-6 mt-3 mb-1"
                  name="supportive-no"
                  v-model="submission.is_supportive"
                  :checkedValue="true"
                  >I support</RadioButton
                >
                <RadioButton
                  class="flex-0 mr-6 mt-3 mb-1"
                  name="supportive-yes"
                  v-model="submission.is_supportive"
                  :checkedValue="false"
                  >I Object</RadioButton
                >
              </div>
            </div>
            <label>
              <p class="text-normal-bold mb-2 mt-8">
                What is their interest in this proposal?
              </p>
              <div class="w-full mt-3 flex flex-col">
                <textarea
                  class="w-full h-48 input-area"
                  v-model="submission.interest"
                ></textarea>
              </div>
            </label>
            <label>
              <p class="text-normal-bold mb-2 mt-8">
                <a class="text-red-star">*</a>What is their reason for
                supporting/objecting to this proposal?
              </p>
              <p>
                Please outline where this proposal does not comply with the
                relevant policies GNB Place Naming Policy and NSW Addressing
                User Manual
              </p>
              <div class="w-full mt-3 flex">
                <textarea
                  class="w-full h-48 input-area"
                  v-model="submission.comment"
                  v-validate="'required'"
                  data-vv-as="Interest in Proposal"
                  name="comment"
                ></textarea>
              </div>
              <FieldErrorMessage :message="errors.first('comment')" />
            </label>
            <uploadScoped
              class="mt-8"
              label="Add Supporting Documentation"
              :important="false"
              :files="submission.public_comment_reference_document"
              :drafted="true"
              stateProp="public_comment_reference_document"
              :uploadFile="uploadFileForComponent"
              v-on:deleteFile="
                fileIndex =>
                  submission.public_comment_reference_document.splice(
                    fileIndex,
                    1
                  )
              "
              name="public_comment_reference_document"
            />
            <div class="mt-8">
              <!-- Contact Details -->
              <div class=" mx-auto">
                <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
                  Contact Details
                </h3>

                <div class="w-full flex">
                  <div class="w-1/2">
                    <p class="text-normal-bold mt-4 mb-2">
                      <a class="text-red-star">*</a>First Name
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter First Name Here..."
                      v-model="submission.first_name"
                      name="first_name"
                      v-validate="'required'"
                      data-vv-as="First Name"
                    />
                    <FieldErrorMessage :message="errors.first('first_name')" />
                  </div>
                  <div class="w-1/2 ml-8">
                    <p class="text-normal-bold  mt-4 mb-2">
                      <a class="text-red-star">*</a>Last Name
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter Last Name Here..."
                      v-model="submission.last_name"
                      name="last_name"
                      v-validate="'required'"
                      data-vv-as="Last Name"
                    />
                    <FieldErrorMessage :message="errors.first('last_name')" />
                  </div>
                </div>
                <div class="w-full">
                  <p class="text-normal-bold  mt-4 mb-2">
                    Address Line 1
                  </p>
                  <input
                    type="text"
                    class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                    placeholder="Enter Address Here..."
                    v-model="submission.address_line_1"
                  />
                </div>
                <div class="w-full">
                  <p class="text-normal-bold mt-4 mb-2">
                    Address Line 2
                  </p>
                  <input
                    type="text"
                    class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                    placeholder="e.g. Apartment number, floor number, etc"
                    v-model="submission.address_line_2"
                    name="address_line_2"
                  />
                </div>

                <div class="w-full flex items-center">
                  <div class="w-1/2 mr-8">
                    <p class="text-normal-bold mt-4 mb-2">
                      Suburb
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter Suburb Here..."
                      v-model="submission.suburb"
                    />
                  </div>
                  <div class="w-1/2 flex items-center">
                    <div class="w-1/2 mr-8 mt-8 mb-2">
                      <p class="text-normal-bold">
                        State
                      </p>
                      <v-select
                        class="w-full mr-4 norns-select"
                        v-model="submission.state"
                        :multiple="false"
                        :options="australianStates"
                        :searchable="false"
                        :close-on-select="true"
                        :select-on-tab="true"
                        :show-labels="false"
                        :hide-selected="true"
                        placeholder="Select a State"
                        name="state"
                      ></v-select>
                    </div>
                    <div class="w-1/2">
                      <p class="text-normal-bold  mt-4 mb-2">
                        Postcode
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter Postcode Here..."
                        v-model="submission.postcode"
                      />
                    </div>
                  </div>
                </div>

                <div class="w-full flex items-center">
                  <div class="w-1/2 mr-8">
                    <p class="text-normal-bold mt-4 mb-2">
                      Phone Number
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter Phone Number Here..."
                      v-model="submission.phone_no"
                      name="phone_no"
                    />
                  </div>
                  <div class="w-1/2">
                    <p class="text-normal-bold mt-4 mb-2">
                      <a class="text-red-star" v-if="notify_outcome === true"
                        >*</a
                      >Email Address
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 text-normal
                    bg-grey-input text-grey-text self-center"
                      placeholder="Enter Email Address Here..."
                      v-model="submission.email"
                      v-validate="
                        submission.notify_outcome === true
                          ? 'required|email'
                          : 'email'
                      "
                      name="email"
                      data-vv-as="Email"
                    />
                    <FieldErrorMessage :message="errors.first('email')" />
                  </div>
                </div>

                <div class="w-full">
                  <p class="text-normal-bold  mt-4 mb-2">
                    Country
                  </p>
                  <input
                    type="text"
                    class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                    placeholder="Enter Country Here..."
                    v-model="submission.country"
                  />
                </div>
              </div>
              <div>
                <p class="text-normal-bold mt-12">
                  Does the commenter want to receive email outcome of this
                  proposal?
                </p>
                <p class="mt-4">
                  Please note that if you select 'Yes' they will be advised of
                  any decisions regarding this proposal via email.
                </p>
                <div class="flex flex-row text-normal">
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="supportive-no"
                    v-model="submission.notify_outcome"
                    :checkedValue="true"
                    >Yes</RadioButton
                  >
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="supportive-yes"
                    v-model="submission.notify_outcome"
                    :checkedValue="false"
                    >No</RadioButton
                  >
                </div>
              </div>

              <div class="flex flex-grow">
                <Checkbox
                  class="w-full pl-0"            
                  v-model="submission.personalInformationCollectionNotice">
                  <template slot:label>
                    Please tick to acknowledge the <a href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf" target="_blank" > GNB Personal Information Collection Notice </a>
                 </template>
                </Checkbox>        
              </div>


              <div class="dividing-line-h w-full pt-4 pb-8 mb-8" />
              <div class="flex w-full justify-end">
                <Button
                  class="button-red-hollow w-2/6 mt-4 mb-10"
                  :disabled="$wait.is('submittingComment')"
                  @click="backToProposals"
                  >Cancel Comment</Button
                >

                <ButtonWithSpinner
                  class="button-blue  w-2/6 mt-4 mb-10 ml-4"
                  :isSpinning="$wait.is('submittingComment')"
                  :disabled="$wait.is('submittingComment') || !isFormValid || !submission.personalInformationCollectionNotice"
                  @click="submitComment"
                  >Submit Comment</ButtonWithSpinner
                >
              </div>
            </div>
          </div>
        </template>
      </showLoading>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "commentFormInternal",
  data() {
    return {
      proposal: null,
      interestMaxLength: 1023,
      australianStates: ["NSW", "QLD", "VIC", "SA", "WA", "ACT", "NT", "TAS"],
      submission: {
        is_supportive: null,
        interest: null,
        comment: null,
        notify_outcome: null,
        hidden_contact: null,
        public_comment_reference_document: [],
        first_name: null,
        last_name: null,
        address_line_1: null,
        address_line_2: null,
        suburb: null,
        state: null,
        postcode: null,
        phone_no: null,
        country: "Australia",
        email: null,
        personalInformationCollectionNotice: false
      }
    };
  },
  async created() {
    await this.fetchProposal();
  },
  computed: {
    proposalId() {
      return this.$route.params.proposalId;
    },
    isFormValid() {
      return (
        (this.submission.is_supportive === true ||
          this.submission.is_supportive === false) &&
        !!this.submission.comment &&
        !!this.submission.comment.length &&
        !!this.submission.first_name &&
        !!this.submission.first_name.length &&
        !!this.submission.last_name &&
        !!this.submission.last_name.length &&
        (this.submission.notify_outcome
          ? !!this.submission.email && !!this.submission.email.length
          : true)
      );
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    back() {
      this.$router.push({ name: "placeNameProposals" });
    },
    backToProposals() {
      this.$router.push({ name: "placeNameProposals" });
    },
    fetchProposal: waitFor("fetchingProposalForComment", async function() {
      try {
        const proposal = await pnpCRUD.getAdvertisedProposal(this.proposalId);
        this.proposal = proposal;
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve proposal",
          text: error
        });
      }
    }),
    async uploadFileForComponent(payload) {
      const s3FileData = await this.uploadFile(
        Object.assign({}, payload, {
          componentOnly: true
        })
      );
      if (s3FileData) {
        this.submission.public_comment_reference_document.push(s3FileData);
      }
    },
    submitComment: waitFor("submittingComment", async function() {
      try {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
          const payload = Object.assign({}, this.submission, {
            advertisement_identifier: this.proposal.advertisement_identifier
          });
          await pnpCRUD.createAdvertisedProposalCommentForPublic(payload);
          this.$router.push({
            name: "placeNameProposalAddPublicCommentSubmitted"
          });
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to save comment",
          text: error
        });
      }
    }),
    formatDate: date => {
      return moment(date).format("DD MMM, YYYY");
    }
  }
};
</script>
