var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.complied_chapter_6_8 === false
        ? _c("MessagePanel", {
            staticClass: "mb-4",
            attrs: {
              warning: "",
              msg: "This response suggests that this proposal is likely to be deemed unacceptable",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 1. ")]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "complied_chapter_6_8-no",
                    name: "complied_chapter_6_8-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.complied_chapter_6_8,
                    callback: function ($$v) {
                      _vm.complied_chapter_6_8 = $$v
                    },
                    expression: "complied_chapter_6_8",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "complied_chapter_6_8-yes",
                    "data-cypress": "complied_chapter_6_8-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.complied_chapter_6_8,
                    callback: function ($$v) {
                      _vm.complied_chapter_6_8 = $$v
                    },
                    expression: "complied_chapter_6_8",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "text-normal pt-6",
          class: {
            "dividing-line-h pb-6": !_vm.proposed_name_background_information,
          },
        },
        [
          _c("div", { staticClass: "flex-row flex" }, [
            _c("p", { staticClass: "w-3%" }, [_vm._v(" 2. ")]),
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(
                " Is there background information on the proposed name (ie. Historical information, entomology, reason for selection)? "
              ),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "proposed_name_background_information-no",
                      name: "proposed_name_background_information-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_name_background_information,
                      callback: function ($$v) {
                        _vm.proposed_name_background_information = $$v
                      },
                      expression: "proposed_name_background_information",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "proposed_name_background_information-yes",
                      "data-cypress":
                        "proposed_name_background_information-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_name_background_information,
                      callback: function ($$v) {
                        _vm.proposed_name_background_information = $$v
                      },
                      expression: "proposed_name_background_information",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.proposed_name_background_information
        ? _c(
            "div",
            { staticClass: "text-normal pb-6 dividing-line-h" },
            [
              _c("uploadScoped", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "min_value:1",
                    expression: "'min_value:1'",
                  },
                ],
                staticClass: "mt-4",
                attrs: {
                  label: "Attach Supporting Documentation",
                  important: true,
                  files: _vm.background_info_reference_document,
                  drafted: true,
                  stateKey: "address_locality",
                  stateProp: "background_info_reference_document",
                  uploadFile: (params) =>
                    _vm.uploadFile(
                      Object.assign({}, params, { isChecklistFile: true })
                    ),
                  disabled: _vm.readOnly,
                },
                on: {
                  deleteFile: (fileIndex) =>
                    _vm.deleteUploadedChecklistFile({
                      key: "address_locality",
                      prop: "background_info_reference_document",
                      index: fileIndex,
                    }),
                },
              }),
              _vm.errors.first("fileUploader")
                ? _c(
                    "div",
                    { staticClass: "flex items-center absolute" },
                    [
                      _c("errorIcon"),
                      _c(
                        "div",
                        { staticClass: "ml-2 text-red-error text-normal-bold" },
                        [
                          _vm._v(
                            " No Photos were uploaded for this place name. "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.evaluation
                ? _c(
                    "div",
                    _vm._l(_vm.evaluation.issues, function (rules, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex items-center relative",
                        },
                        [
                          _c("errorIcon"),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-red-error text-normal-bold ml-2",
                            },
                            [_vm._v(" " + _vm._s(rules.shortDescription) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.supported_by_council === false
        ? _c("MessagePanel", {
            attrs: {
              error: "",
              msg: "Council resolution support is needed before this proposal can be submitted",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "text-normal pb-6 pt-6 dividing-line-h" }, [
        _c("div", { staticClass: "flex flex-row" }, [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 3. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(" Is the name supported by council resolution? "),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "name-no",
                    name: "name-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.supported_by_council,
                    callback: function ($$v) {
                      _vm.supported_by_council = $$v
                    },
                    expression: "supported_by_council",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "supported_by_council-yes",
                    "data-cypress": "supported_by_council-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.supported_by_council,
                    callback: function ($$v) {
                      _vm.supported_by_council = $$v
                    },
                    expression: "supported_by_council",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]),
        _vm.supported_by_council
          ? _c(
              "div",
              [
                _c("uploadScoped", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "min_value:1",
                      expression: "'min_value:1'",
                    },
                  ],
                  staticClass: "mt-4",
                  attrs: {
                    label: "Attach supporting documentation",
                    important: true,
                    files: _vm.council_resolution_reference_document,
                    drafted: true,
                    stateKey: "address_locality",
                    stateProp: "council_resolution_reference_document",
                    uploadFile: (params) =>
                      _vm.uploadFile(
                        Object.assign({}, params, { isChecklistFile: true })
                      ),
                    disabled: _vm.readOnly,
                  },
                  on: {
                    deleteFile: (fileIndex) =>
                      _vm.deleteUploadedChecklistFile({
                        key: "address_locality",
                        prop: "council_resolution_reference_document",
                        index: fileIndex,
                      }),
                  },
                }),
                _vm.errors.first("fileUploader")
                  ? _c(
                      "div",
                      { staticClass: "flex items-center absolute" },
                      [
                        _c("errorIcon"),
                        _c(
                          "div",
                          {
                            staticClass: "ml-2 text-red-error text-normal-bold",
                          },
                          [
                            _vm._v(
                              " No Photos were uploaded for this place name. "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.evaluation
                  ? _c(
                      "div",
                      _vm._l(_vm.evaluation.issues, function (rules, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "flex items-center relative",
                          },
                          [
                            _c("errorIcon"),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-red-error text-normal-bold ml-2",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(rules.shortDescription) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.proposed_locality_name_unique === false
        ? _c("MessagePanel", {
            attrs: {
              warning: "",
              msg: "This response suggests that this proposal is likely to be deemed unacceptable",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 4. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " To your knowledge, is the new address locality name unique in Australia? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "proposed_locality_name_unique-no",
                    name: "proposed_locality_name_unique-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_name_unique,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_unique = $$v
                    },
                    expression: "proposed_locality_name_unique",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "proposed_locality_name_unique-yes",
                    "data-cypress": "proposed_locality_name_unique-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_name_unique,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_unique = $$v
                    },
                    expression: "proposed_locality_name_unique",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 5. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " Is the address locality named after an estate or linked to a subdivision name? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress":
                      "proposed_locality_name_linked_to_estate_or_subdivision-no",
                    name: "proposed_locality_name_linked_to_estate_or_subdivision-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value:
                      _vm.proposed_locality_name_linked_to_estate_or_subdivision,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_linked_to_estate_or_subdivision =
                        $$v
                    },
                    expression:
                      "proposed_locality_name_linked_to_estate_or_subdivision",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "proposed_locality_name_linked_to_estate_or_subdivision-yes",
                    "data-cypress":
                      "proposed_locality_name_linked_to_estate_or_subdivision-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value:
                      _vm.proposed_locality_name_linked_to_estate_or_subdivision,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_linked_to_estate_or_subdivision =
                        $$v
                    },
                    expression:
                      "proposed_locality_name_linked_to_estate_or_subdivision",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.proposed_locality_name_uses_suffix_prefix_indicator === true
        ? _c("MessagePanel", {
            attrs: {
              warning: "",
              msg: "This response suggests that this proposal is likely to be deemed unacceptable",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 6. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " Does the name use a qualifying terminology such as a prefix or suffix (e.g. north, south, inner, upper, old, new)? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress":
                      "proposed_locality_name_uses_suffix_prefix_indicator-no",
                    name: "proposed_locality_name_uses_suffix_prefix_indicator-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value:
                      _vm.proposed_locality_name_uses_suffix_prefix_indicator,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_uses_suffix_prefix_indicator =
                        $$v
                    },
                    expression:
                      "proposed_locality_name_uses_suffix_prefix_indicator",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "proposed_locality_name_uses_suffix_prefix_indicator-yes",
                    "data-cypress":
                      "proposed_locality_name_uses_suffix_prefix_indicator-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value:
                      _vm.proposed_locality_name_uses_suffix_prefix_indicator,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_uses_suffix_prefix_indicator =
                        $$v
                    },
                    expression:
                      "proposed_locality_name_uses_suffix_prefix_indicator",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 7. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " Does the proposal improve public safety and service delivery? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress":
                      "proposed_locality_name_improves_safety_service-no",
                    name: "proposed_locality_name_improves_safety_service-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_name_improves_safety_service,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_improves_safety_service = $$v
                    },
                    expression:
                      "proposed_locality_name_improves_safety_service",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "proposed_locality_name_improves_safety_service-yes",
                    "data-cypress":
                      "proposed_locality_name_improves_safety_service-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_name_improves_safety_service,
                    callback: function ($$v) {
                      _vm.proposed_locality_name_improves_safety_service = $$v
                    },
                    expression:
                      "proposed_locality_name_improves_safety_service",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 8. ")]),
          _vm._m(1),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "proposed_locality_meets_size_limits-no",
                    name: "proposed_locality_meets_size_limits-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_meets_size_limits,
                    callback: function ($$v) {
                      _vm.proposed_locality_meets_size_limits = $$v
                    },
                    expression: "proposed_locality_meets_size_limits",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "proposed_locality_meets_size_limits-yes",
                    "data-cypress": "proposed_locality_meets_size_limits-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.proposed_locality_meets_size_limits,
                    callback: function ($$v) {
                      _vm.proposed_locality_meets_size_limits = $$v
                    },
                    expression: "proposed_locality_meets_size_limits",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.map_or_gis_file_provided === false
        ? _c("MessagePanel", {
            attrs: {
              error: "",
              msg: "A map or GIS file is needed before this proposal can be submitted",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 9. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " Has a map or GIS file been provided (ESRI Shape file (.shp) preferred)? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "map_or_gis_file_provided-no",
                    name: "map_or_gis_file_provided-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.map_or_gis_file_provided,
                    callback: function ($$v) {
                      _vm.map_or_gis_file_provided = $$v
                    },
                    expression: "map_or_gis_file_provided",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "map_or_gis_file_provided-yes",
                    "data-cypress": "map_or_gis_file_provided-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.map_or_gis_file_provided,
                    callback: function ($$v) {
                      _vm.map_or_gis_file_provided = $$v
                    },
                    expression: "map_or_gis_file_provided",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "flex flex-row text-normal pb-6 pt-6" }, [
        _c("p", { staticClass: "w-3%" }, [_vm._v(" 10. ")]),
        _c("p", { staticClass: "text-normal w-5/6" }, [
          _vm._v(" Does the address locality cover more than one LGA? "),
        ]),
        _c(
          "div",
          { staticClass: "float right flex-row flex" },
          [
            _c(
              "RadioButton",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "flex-0 mr-6",
                attrs: {
                  "data-cypress": "proposed_locality_multiple_lgas-no",
                  name: "proposed_locality_multiple_lgas-no",
                  checkedValue: false,
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.proposed_locality_multiple_lgas,
                  callback: function ($$v) {
                    _vm.proposed_locality_multiple_lgas = $$v
                  },
                  expression: "proposed_locality_multiple_lgas",
                },
              },
              [_vm._v("No")]
            ),
            _c(
              "RadioButton",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "flex-0 mr-6",
                attrs: {
                  name: "proposed_locality_multiple_lgas-yes",
                  "data-cypress": "proposed_locality_multiple_lgas-yes",
                  checkedValue: true,
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.proposed_locality_multiple_lgas,
                  callback: function ($$v) {
                    _vm.proposed_locality_multiple_lgas = $$v
                  },
                  expression: "proposed_locality_multiple_lgas",
                },
              },
              [_vm._v("Yes")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex flex-row text-normal dividing-line-h" }, [
        _vm.proposed_locality_multiple_lgas
          ? _c(
              "div",
              { staticClass: "flex flex-grow" },
              [
                _c("AffectedLgaTable", {
                  attrs: {
                    stateKey: `address_locality`,
                    readOnly: _vm.readOnly,
                    lgas: _vm.lgas,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.public_consultation_by_council === false
        ? _c("MessagePanel", {
            attrs: {
              warning: "",
              msg: "This response suggests that this proposal is likely to be deemed unacceptable",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h" },
        [
          _c("p", { staticClass: "w-3%" }, [_vm._v(" 11. ")]),
          _c("p", { staticClass: "text-normal w-5/6" }, [
            _vm._v(
              " Has public consultation been carried out by the council? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "float right flex-row flex" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    "data-cypress": "public_consultation_by_council-no",
                    name: "public_consultation_by_council-no",
                    checkedValue: false,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.public_consultation_by_council,
                    callback: function ($$v) {
                      _vm.public_consultation_by_council = $$v
                    },
                    expression: "public_consultation_by_council",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6",
                  attrs: {
                    name: "public_consultation_by_council-yes",
                    "data-cypress": "public_consultation_by_council-yes",
                    checkedValue: true,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.public_consultation_by_council,
                    callback: function ($$v) {
                      _vm.public_consultation_by_council = $$v
                    },
                    expression: "public_consultation_by_council",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.public_consultation_by_council
        ? _c(
            "div",
            { staticClass: "text-normal pb-6 dividing-line-h" },
            [
              _c("uploadScoped", {
                staticClass: "mt-4",
                attrs: {
                  "data-cypress": "upload-public_consultation",
                  label: "Attach supporting material for public consultation",
                  important: false,
                  files: _vm.public_consultation_reference_document,
                  drafted: true,
                  stateKey: "address_locality",
                  stateProp: "public_consultation_reference_document",
                  uploadFile: (params) =>
                    _vm.uploadFile(
                      Object.assign({}, params, { isChecklistFile: true })
                    ),
                  disabled: _vm.readOnly,
                },
                on: {
                  deleteFile: (fileIndex) =>
                    _vm.deleteUploadedChecklistFile({
                      key: "address_locality",
                      prop: "public_consultation_reference_document",
                      index: fileIndex,
                    }),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-5/6" }, [
      _vm._v(" Does this proposal comply with "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105",
            target: "_blank",
          },
        },
        [_vm._v("Chapter 6.8 of the NSW Addressing User Manual?")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-5/6" }, [
      _vm._v(
        " For an urban area, does the new address locality meet the size limits as described in "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105",
            target: "_blank",
          },
        },
        [_vm._v("Chapter 6.8 of the NSW Addressing User Manual")]
      ),
      _vm._v("? "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }