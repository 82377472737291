var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "entry",
        {
          attrs: {
            cardTitle:
              _vm.entryData.action === "update"
                ? "Change in status"
                : "" + "Status",
            eventDate: _vm.entryData.date,
          },
        },
        [
          _c("div", [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                "The proposal status was " +
                  _vm._s(
                    _vm.entryData.action === "update" ? "changed" : "set"
                  ) +
                  " to:"
              ),
            ]),
            _c("strong", [_vm._v(_vm._s(_vm.entryData.changes.status.to))]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }