<template>
  <div
    class="
      justify-center
      w-full
      block
      self-center
      bg-white
      text-black
      rounded-lg
      pt-4
      mt-3
      mb-3
    "
  >
    <div class="w-full pl-14 pr-2 flex-col mx-auto">
      <div class="flex-1 self-center flex justify-between pb-6">
        <div class="self-center flex-1">
          <div class="flex items-center">
            <div class="py-3 justify-center mb-1" v-if="loggedUser.isAdmin">
              <label class="container items-baseline flex height-fix">
                <input
                  class="checkbox-input"
                  type="checkbox"
                  :checked="isChecked"
                  @change="passProposalIdentifier"
                />
                <span class="border-2 checkmark"></span>
              </label>
            </div>
            <h2
              class="self-center pt-2 h-auto mb-4 pr-10"
              data-cypress="proposal-reference"
            >
              {{ proposal.geographical_name }}
            </h2>
          </div>
          <p class="mb-2" v-if="proposal.reference_no">
            <span class="flex-1 text-blue text-normal-bold">
              Reference Number: {{ proposal.reference_no }}
            </span>
          </p>
          <p class="pb-2 text-normal">
            Submitted {{ formattedTime(proposal.date_created) }} by
            <span
              class="flex-1 text-blue-button text-normal-bold"
              v-if="proposal.organisation_name"
            >
              {{ proposal.organisation_name }}
            </span>
            <span
              class="flex-1 text-blue-button text-normal-bold"
              v-if="!proposal.organisation_name"
            >
              {{ proposal.first_name }} {{ proposal.last_name }}
            </span>
          </p>
          <div class="flex-wrap flex-start">
            <proposalButton
              :label="placeClassificationHelper(proposal.type)"
              :classNames="`text-normal-bold cursor-default ${proposal.type}`"
            />
          </div>
        </div>
        <div class="pr-4 flex flex-row">
          <div v-if="proposal.geoname_status === 'DRAFTED'" class="pr-4">
            <router-link
              :to="{
                name: 'placeNameProposal',
                params: { proposalId: proposal.identifier }
              }"
              class="text-blue-button font-bold"
              >Resume Draft</router-link
            >
          </div>
          <div v-if="proposal.geoname_status === 'Awaiting Clarification'">
            <router-link
              :to="{
                name: 'clarifyPnp',
                params: {
                  proposalId: proposal.identifier
                }
              }"
              class="text-blue-button font-bold"
              >Respond to Query</router-link
            >
          </div>
          <!-- TODO: update with 'view proposal' router link once view proposal screen created -->
          <div v-if="proposal.geoname_status !== 'DRAFTED'" class="pr-4">
            <router-link
              :to="{
                name: 'placeNameProposal',
                params: { proposalId: proposal.identifier }
              }"
              class="text-blue-button font-bold"
              >View Proposal</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="dividing-line-h-1 w-full"></div>
    <div class="flex justify-between w-9/10 mx-auto">
      <div class="pt-4 flex flex-row flex-grow">
        <div class="w-3/6 pt-0 pb-4 mr-4">
          <span class="flex-1 text-blue text-normal-bold">
            Local Government Area(s)
          </span>
          <span
            class="block"
            v-for="(lga, index) in proposal.lga"
            :key="index"
            >{{ lga }}</span
          >
        </div>
        <div class="flex-grow ml-10 pb-4">
          <span class="flex-1 text-blue text-normal-bold"> Localities </span>
          <div v-if="proposal.type === PROPOSAL_TYPE_PLACE">
            <span
              class="block"
              v-for="(locality, index) in proposal.locality"
              :key="index"
              >{{ locality }}</span
            >
          </div>
          <div
            v-if="
              proposal.type === PROPOSAL_TYPE_BOUNDARY ||
              proposal.type === PROPOSAL_TYPE_LOCALITY
            "
          >
            <span
              class="block"
              v-for="(locality, index) in proposal.affected_localities"
              :key="index"
              >{{ locality }}</span
            >
          </div>
        </div>
      </div>
      <StatusButton
        :label="proposal.display_status"
        :status="proposal.display_status"
        classNames="text-normal-bold cursor-default flex-no-grow"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import proposalButton from "@/components/proposals/road/proposalButton";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY
} from "./pnp-constants.js";

export default {
  name: "PlaceNameProposalListItem",
  components: {
    proposalButton
  },
  props: ["proposal", "checkAll", "untickProposal"],
  data: function () {
    return {
      PROPOSAL_TYPE_PLACE,
      PROPOSAL_TYPE_BOUNDARY,
      PROPOSAL_TYPE_LOCALITY,
      isChecked: false
    };
  },
  methods: {
    viewText(proposal) {
      if (proposal.status === "Drafted") {
        return "Resume Draft";
      }
      return "View Proposal";
    },
    formattedTime(time) {
      var tm = moment(time, "YYYY-MM-DD kk:mm:ss.SSSSSS");
      return tm.format("DD MMM YYYY hh:mm:ss a z ");
    },
    placeClassificationHelper(type) {
      if (type === PROPOSAL_TYPE_PLACE) {
        return "Place Name Proposal";
      } else if (type === PROPOSAL_TYPE_LOCALITY) {
        return "New/Amended Address Locality Name";
      } else if (type === PROPOSAL_TYPE_BOUNDARY) {
        return "Address Locality Boundary Amendment";
      } else return type;
    },
    // Passes the proposal and checkbox info to the parent component
    passProposalIdentifier() {
      this.isChecked = !this.isChecked;
      const data = {
        isChecked: this.isChecked,
        identifier: this.$props.proposal.identifier,
        jiraId: this.$props.proposal.jira_id
      };
      this.$emit("passProposalIdentifier", data);
    }
  },
  watch: {
    // Checks the status of check all checkbox in the parent component
    checkAll: function (newVal, oldVal) {
      if(!this.$props.untickProposal) {
        this.isChecked = newVal;  
      }
    }
  },
  computed: {
    ...mapUserGetters({ loggedUser: "user" })
  }
};
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #002664;
  background-color: #ffffff;
  transition-duration: 0.2s;
  border-radius: 2px;
}
input:disabled ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #5b5e63;
  background-color: rgb(194, 193, 193);
  transition-duration: 0.2s;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #84bddc;
  border-color: #002664;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #d8d8d8;
  border-color: #464a52;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #002664;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.height-fix {
  min-height: 24px;
}
</style>