<template>
  <div>
    <label class="w-2/3">
      <p class="text-normal-bold mb-2">
        <span class="text-red-star">*</span>Reason for the choice of name?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="reason_for_name_choice-field"
          v-model="reason_for_name_choice"
          :disabled="readOnly"
          v-validate="'required'"
          name="reason for choice"
        ></textarea>
      </div>
      <FieldErrorMessage :message="errors.first('reason for choice')" />
    </label>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-origin"
        label="Attach supporting documentation"
        :important="false"
        :files="supporting_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="supporting_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'address_locality',
              prop: 'supporting_reference_document',
              index: fileIndex
            })
        "
        :disabled="readOnly"
      />
    </div>
    <p class="text-normal-bold mt-6">
      <span class="text-red-star">*</span>Does this proposed name commemorate a
      person?
    </p>
    <div class="flex flex-row text-normal">
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        data-cypress="commemorated-no"
        name="commemorated checkbox"
        v-model="commemorated"
        :checkedValue="false"
        :disabled="readOnly"
        v-validate="'required'"
        >No</RadioButton
      >
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        name="commemorated checkbox"
        data-cypress="commemorated-yes"
        v-model="commemorated"
        :checkedValue="true"
        :disabled="readOnly"
        v-validate="'required'"
        >Yes</RadioButton
      >
    </div>
    <FieldErrorMessage :message="errors.first('commemorated checkbox')" />
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-4">
        <span class="text-red-star">*</span>Full Name of Person
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="commemorated_full_name-field"
        placeholder="Enter Name Here..."
        name="commemorated person name"
        id="commemorated_full_name"
        v-validate="'required'"
        v-model="commemorated_full_name"
        :disabled="readOnly"
      />
      <FieldErrorMessage :message="errors.first('commemorated person name')" />
    </label>
    <div class=" text-normal mb-4 mt-4" v-if="commemorated">
      <div class="mb-4">
        <label class="text-normal-bold pb-2">
          <span class="text-red-star">*</span>Date of Birth
        </label>
        <div class="flex flex-row mt-2">
          <v-datepicker
            mode="single"
            v-model="commemorated_birth_date"
            :max-date="commemorated_death_date || todaysDate"
            @dayclick="validateDate('commemorated_birth_date')"
          >
            <input
              slot-scope="{ inputProps, updateValue }"
              type="text"
              :value="commemorated_birth_date"
              placeholder="Enter Date of Birth..."
              class="w-full mr-4 input-area"
              data-cypress="commemorated_birth_date"
              v-bind="inputProps"
              @input="e => updateDate(e, updateValue)"
              @change="setDobNullIfBlank"
              autocomplete="off"
              :disabled="readOnly"
              name="commemorated_birth_date"
              data-vv-as="Date of Birth"
              v-validate="'required|date'"
            />
          </v-datepicker>
          <button
            class="ml-2 w-1/8"
            v-bind:class="{
              redButtonFaded: !commemorated_birth_date,
              redButtonSolid: commemorated_birth_date
            }"
            :disabled="!commemorated_birth_date"
            @click="commemorated_birth_date = null"
          >
            clear
          </button>
        </div>
        <FieldErrorMessage :message="errors.first('commemorated_birth_date')" />
      </div>
      <div class="">
        <label class="text-normal-bold mb-2">
          <span class="text-red-star">*</span>Date of Death
        </label>
        <div class="flex flex-row mt-2">
          <v-datepicker
            mode="single"
            v-model="commemorated_death_date"
            :max-date="todaysDate"
            :min-date="commemorated_birth_date"
            @dayclick="validateDate('commemorated_death_date')"
          >
            <input
              slot-scope="{ inputProps, updateValue }"
              type="text"
              :value="commemorated_death_date"
              placeholder="Enter Date of Death..."
              class="w-full mr-4 input-area"
              data-cypress="commemorated_death_date"
              v-bind="inputProps"
              @input="e => updateDate(e, updateValue)"
              @change="setDodNullIfBlank"
              autocomplete="off"
              name="commemorated_death_date"
              data-vv-as="Date of Death"
              v-validate="'required|date'"
              :disabled="readOnly"
            />
          </v-datepicker>
          <button
            class="ml-2 w-1/8"
            v-bind:class="{
              redButtonFaded: !commemorated_death_date,
              redButtonSolid: commemorated_death_date
            }"
            @click="commemorated_death_date = null"
            :disabled="!commemorated_death_date"
          >
            clear
          </button>
        </div>
        <FieldErrorMessage :message="errors.first('commemorated_death_date')" />
      </div>
      <Checkbox
        class="pt-8 w-full pl-0"
        data-cypress="commemorated_dates_unknown-field"
        label="The Date of Birth or Death is unknown"
        v-model="commemorated_dates_unknown"
        :disabled="
          readOnly || (commemorated_birth_date && commemorated_death_date)
        "
      ></Checkbox>
    </div>
    <FieldErrorMessage :message="errors.first('commemorated person death')" />

    <label class="w-2/3 mb-4" v-if="commemorated_dates_unknown">
      <p class="text-normal-bold mb-2">
        <span class="text-red-star">*</span>Please explain why the Date of Birth
        or Death is unknown
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="commemorated_dates_unknown_description-field"
          v-model="commemorated_dates_unknown_description"
          :disabled="readOnly"
          name="explanation"
          v-validate="'required'"
        ></textarea>
      </div>
      <FieldErrorMessage :message="errors.first('explanation')" />
    </label>
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span> What is the person's association
        with this feature and their main contribution to the local community?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="commemorated_association_description-field"
          v-model="commemorated_association_description"
          :disabled="readOnly"
          name="commemorated person association"
          v-validate="'required'"
        ></textarea>
      </div>
      <FieldErrorMessage
        :message="errors.first('commemorated person association')"
      />
    </label>
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span> What is the source for this
        information?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="commemorated_source-field"
          v-model="commemorated_source"
          :disabled="readOnly"
          name="source of information"
          v-validate="'required'"
        ></textarea>
      </div>
      <FieldErrorMessage :message="errors.first('source of information')" />
    </label>
    <div v-if="commemorated">
      <uploadScoped
        class="mt-8"
        label="Attach supporting documentation"
        :important="true"
        :files="source_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="source_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'address_locality',
              prop: 'source_reference_document',
              index: fileIndex
            })
        "
        v-model="source_reference_document"
        v-validate="'required'"
        name="supporting documentation"
        :disabled="readOnly"
      />
      <FieldErrorMessage :message="errors.first('supporting documentation')" />
    </div>
    <div>
      <p class="text-normal-bold mt-6">
        Is the proposed name an Aboriginal name?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          data-cypress="aboriginal_name-no"
          name="aboriginal checkbox"
          v-model="aboriginal_name"
          :checkedValue="false"
          :disabled="!!multicultural || readOnly"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          name="aboriginal checkbox"
          data-cypress="aboriginal_name-yes"
          v-model="aboriginal_name"
          :checkedValue="true"
          :disabled="!!multicultural || readOnly"
          >Yes</RadioButton
        >
      </div>
      <FieldErrorMessage :message="errors.first('aboriginal checkbox')" />
    </div>
    <div v-if="aboriginal_name">
      <p class="text-normal-bold mt-8">
        <span class="text-red-star">*</span>Has the name been selected in
        consultation with the Local Aboriginal Land Council and the local
        Aboriginal community?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          data-cypress="aboriginal_consulted-no"
          name="consultation"
          v-model="aboriginal_consulted"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          name="consultation"
          data-cypress="aboriginal_consulted-yes"
          v-model="aboriginal_consulted"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
      <MessagePanel
        warning
        msg="This response suggests that this proposal is likely to be deemed unacceptable"
        class="mt-4"
        v-if="aboriginal_consulted === false"
      />
      <FieldErrorMessage :message="errors.first('consultation')" />
    </div>
    <label class="w-2/3" v-if="aboriginal_consulted && aboriginal_name">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span>List the community groups consulted
        and provide evidence of support
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="aboriginal_consulted_communities-field"
          v-model="aboriginal_consulted_communities"
          :disabled="readOnly"
          name="consulted communities"
          v-validate="'required'"
        ></textarea>
      </div>
      <FieldErrorMessage :message="errors.first('consulted communities')" />
    </label>
    <div v-if="aboriginal_consulted && aboriginal_name">
      <uploadScoped
        class="mt-8"
        label="Attach supporting documentation"
        :important="true"
        :files="aboriginal_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="aboriginal_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'address_locality',
              prop: 'aboriginal_reference_document',
              index: fileIndex
            })
        "
        v-validate="'required'"
        :disabled="readOnly"
        v-model="aboriginal_reference_document"
        name="aboriginal supporting documentation"
      />
      <FieldErrorMessage
        :message="errors.first('aboriginal supporting documentation')"
      />
    </div>
    <div v-if="aboriginal_name" class="flex flex-row text-normal mb-4 mt-8">
      <label class="pr-4 w-2/3">
        <p class="text-normal-bold mb-2">Country/Aboriginal nation</p>
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="aboriginal_country-field"
          placeholder="Enter Country Here..."
          name="aboriginal country"
          id="aboriginal_country"
          v-validate="'required'"
          v-model="aboriginal_country"
          :disabled="readOnly"
        />
        <FieldErrorMessage :message="errors.first('aboriginal country')" />
      </label>
      <label class="w-2/3">
        <p class="text-normal-bold mb-2">
          <span class="text-red-star">*</span>Language Group
        </p>
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="aboriginal_language-field"
          placeholder="Enter Language Group Here..."
          name="aboriginal language"
          id="aboriginal_language"
          v-validate="'required'"
          v-model="aboriginal_language"
          :disabled="readOnly"
        />
        <FieldErrorMessage :message="errors.first('aboriginal language')" />
      </label>
    </div>

    <p class="text-normal-bold mt-6">
      Does the proposed name have a multicultural dimension?
    </p>
    <div class="flex flex-row text-normal">
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        data-cypress="multicultural-no"
        name="multicultural checkbox"
        v-model="multicultural"
        :checkedValue="false"
        :disabled="!!aboriginal_name || readOnly"
        >No</RadioButton
      >
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        name="multicultural checkbox"
        data-cypress="multicultural-yes"
        v-model="multicultural"
        :checkedValue="true"
        :disabled="!!aboriginal_name || readOnly"
        v-validate="'required'"
        >Yes</RadioButton
      >
    </div>
    <FieldErrorMessage :message="errors.first('multicultural checkbox')" />
    <label class="w-2/3" v-if="multicultural">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span>Please describe the multicultural
        dimension
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="multicultural_description-field"
          v-model="multicultural_description"
          :disabled="readOnly"
          name="multicultural dimension"
          v-validate="'required'"
        ></textarea>
      </div>
      <FieldErrorMessage :message="errors.first('multicultural dimension')" />
    </label>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import debounce from "lodash.debounce";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

import MessagePanel from "@/components/proposals/place/messagePanel";

export default {
  inject: ["$validator"],
  components: {
    MessagePanel
  },
  data() {
    return {
      evaluation: false,
      isDisabled: false,
      dateFormat: "YYYY-MM-DD",
      readableDateFormat: "DD/MM/YYYY"
    };
  },
  props: {
    readOnly: {
      type: Boolean
    }
  },
  computed: {
    todaysDate() {
      return moment().format("YYYY-MM-DD");
    },
    reason_for_name_choice: {
      get() {
        return this.get_address_locality_reason_for_name_choice();
      },
      set(value) {
        this.set_address_locality_reason_for_name_choice(value);
      }
    },
    commemorated: {
      get() {
        return this.get_address_locality_commemorated();
      },
      set(value) {
        this.set_address_locality_commemorated(value);
      }
    },
    commemorated_full_name: {
      get() {
        return this.get_address_locality_commemorated_full_name();
      },
      set(value) {
        this.set_address_locality_commemorated_full_name(value);
      }
    },
    commemorated_birth_date: {
      get() {
        const date = this.get_address_locality_commemorated_birth_date();
        if (!date) return null;
        return moment(date, this.dateFormat).format(this.readableDateFormat);
      },
      set(value) {
        this.set_address_locality_commemorated_birth_date(
          value
            ? moment(value, this.readableDateFormat).format(this.dateFormat)
            : null
        );
      }
    },
    commemorated_death_date: {
      get() {
        const date = this.get_address_locality_commemorated_death_date();
        if (!date) return null;
        return moment(date, this.dateFormat).format(this.readableDateFormat);
      },
      set(value) {
        this.set_address_locality_commemorated_death_date(
          value
            ? moment(value, this.readableDateFormat).format(this.dateFormat)
            : null
        );
      }
    },
    commemorated_dates_unknown: {
      get() {
        return this.get_address_locality_commemorated_dates_unknown();
      },
      set(value) {
        this.set_address_locality_commemorated_dates_unknown(value);
      }
    },
    commemorated_dates_unknown_description: {
      get() {
        return this.get_address_locality_commemorated_dates_unknown_description();
      },
      set(value) {
        this.set_address_locality_commemorated_dates_unknown_description(value);
      }
    },
    commemorated_association_description: {
      get() {
        return this.get_address_locality_commemorated_association_description();
      },
      set(value) {
        this.set_address_locality_commemorated_association_description(value);
      }
    },
    commemorated_source: {
      get() {
        return this.get_address_locality_commemorated_source();
      },
      set(value) {
        this.set_address_locality_commemorated_source(value);
      }
    },
    source_reference_document: {
      get() {
        return this.get_address_locality_source_reference_document();
      },
      set(value) {
        this.set_address_locality_source_reference_document(value);
      }
    },
    supporting_reference_document: {
      get() {
        return this.get_address_locality_supporting_reference_document();
      },
      set(value) {
        this.set_address_locality_supporting_reference_document(value);
      }
    },
    aboriginal_name: {
      get() {
        return this.get_address_locality_aboriginal_name();
      },
      set(value) {
        this.set_address_locality_aboriginal_name(value);
      }
    },
    aboriginal_consulted: {
      get() {
        return this.get_address_locality_aboriginal_consulted();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_aboriginal_consulted(value);
      }
    },
    aboriginal_consulted_communities: {
      get() {
        return this.get_address_locality_aboriginal_consulted_communities();
      },
      set(value) {
        this.set_address_locality_aboriginal_consulted_communities(value);
      }
    },
    aboriginal_reference_document: {
      get() {
        return this.get_address_locality_aboriginal_reference_document();
      },
      set(value) {
        this.set_address_locality_aboriginal_reference_document(value);
      }
    },
    aboriginal_country: {
      get() {
        return this.get_address_locality_aboriginal_country();
      },
      set(value) {
        this.set_address_locality_aboriginal_country(value);
      }
    },
    aboriginal_language: {
      get() {
        return this.get_address_locality_aboriginal_language();
      },
      set(value) {
        this.set_address_locality_aboriginal_language(value);
      }
    },
    aboriginal_place_type: {
      get() {
        return this.get_address_locality_aboriginal_place_type();
      },
      set(value) {
        this.set_address_locality_aboriginal_place_type(value);
      }
    },
    multicultural: {
      get() {
        return this.get_address_locality_multicultural();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_multicultural(value);
      }
    },
    multicultural_description: {
      get() {
        return this.get_address_locality_multicultural_description();
      },
      set(value) {
        this.set_address_locality_multicultural_description(value);
      }
    },
    /**
     * Should we disable the unknown date checkbox
     */
    shouldDisableUnknown() {
      return !!(this.commemorated_birth_date && this.commemorated_death_date);
    }
  },
  /**
   * Value watchers
   */
  watch: {
    /**
     * react to changes to the disable unknown flag
     */
    shouldDisableUnknown(shouldDisable) {
      if (shouldDisable) {
        this.commemorated_dates_unknown = false;
        // this.$store.commit("setCommemorationDateUnknown", {
        //   isUnknown: false
        // });
      }
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_address_locality_reason_for_name_choice",
      "get_address_locality_commemorated",
      "get_address_locality_commemorated_full_name",
      "get_address_locality_commemorated_birth_date",
      "get_address_locality_commemorated_death_date",
      "get_address_locality_commemorated_dates_unknown",
      "get_address_locality_commemorated_dates_unknown_description",
      "get_address_locality_commemorated_association_description",
      "get_address_locality_commemorated_source",
      "get_address_locality_source_reference_document",
      "get_address_locality_supporting_reference_document",
      "get_address_locality_aboriginal_name",
      "get_address_locality_aboriginal_consulted",
      "get_address_locality_aboriginal_consulted_communities",
      "get_address_locality_aboriginal_reference_document",
      "get_address_locality_aboriginal_country",
      "get_address_locality_aboriginal_language",
      "get_address_locality_aboriginal_place_type",
      "get_address_locality_multicultural",
      "get_address_locality_multicultural_description"
    ]),
    ...mapPnpMutations([
      "deleteUploadedFile",
      "set_address_locality_reason_for_name_choice",
      "set_address_locality_commemorated",
      "set_address_locality_commemorated_full_name",
      "set_address_locality_commemorated_birth_date",
      "set_address_locality_commemorated_death_date",
      "set_address_locality_commemorated_dates_unknown",
      "set_address_locality_commemorated_dates_unknown_description",
      "set_address_locality_commemorated_association_description",
      "set_address_locality_commemorated_source",
      "set_address_locality_source_reference_document",
      "set_address_locality_supporting_reference_document",
      "set_address_locality_aboriginal_name",
      "set_address_locality_aboriginal_consulted",
      "set_address_locality_aboriginal_consulted_communities",
      "set_address_locality_aboriginal_reference_document",
      "set_address_locality_aboriginal_country",
      "set_address_locality_aboriginal_language",
      "set_address_locality_aboriginal_place_type",
      "set_address_locality_multicultural",
      "set_address_locality_multicultural_description"
    ]),
    updateDate: debounce((e, updateFunc) => {
      if (
        /^[\d]{1,2}\/[\d]{1,2}\/[\d]{4}$/.test(e.target.value) &&
        moment(e.target.value, "D/M/YYYY").isValid()
      ) {
        //convert to YYYY-MM-DD so it interprets date correctly
        updateFunc(""); //  clear first so it detects it as an update. This is a but sloppy but seems to work.
        updateFunc(moment(e.target.value, "DD/MM/YYYY").format("YYYY-MM-DD"));
      }
    }, 1200),
    setDobNullIfBlank(e) {
      if (e.target.value == "") {
        this.commemorated_birth_date = null;
      }
    },
    setDodNullIfBlank(e) {
      if (e.target.value == "") {
        this.commemorated_death_date = null;
      }
    },
    async validateDate(element) {
      await this.$nextTick();
      this.$validator.validate(element);
    }
  }
};
</script>

<style scoped>
.redButtonSolid {
  background-color: #c60c30;
  color: white;
  font-size: 16px;
  width: 55px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.redButtonFaded {
  background-color: #c60c3170;
  color: white;
  font-size: 16px;
  width: 55px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: default;
}
</style>
