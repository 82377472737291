"use strict";

import * as userCRUD from "../../helpers/userCRUD";
import Auth from "@aws-amplify/auth";

// initial state
const state = {
  isWaiting: false,
  user: null,
  cognitoToken: null,
  allUsersList: null,
  registeredEmail: null
};

// getters
const getters = {
  user: state => state.user,
  cognitoToken: state => state.cognitoToken,
  allUsersListState: state => {
    return state.allUsersList;
  },
  registeredEmail: state => state.registeredEmail,
  isWaiting: state => state.isWaiting.Auth,
  userGroups: state =>
    state.cognitoToken &&
    state.cognitoToken.signInUserSession.accessToken.payload["cognito:groups"],
  userGivenName: state =>
    state.cognitoToken && state.cognitoToken.attributes.given_name,
  isAdmin: state =>
    state.cognitoToken &&
    state.cognitoToken.signInUserSession &&
    state.cognitoToken.signInUserSession.accessToken &&
    state.cognitoToken.signInUserSession.accessToken.payload[
      "cognito:groups"
    ].indexOf("GNBAdmins") > -1
};

// actions
const actions = {
  async checkUserAuth({ state, commit, dispatch }) {
    try {
      const cognitoToken = await Auth.currentAuthenticatedUser();
      commit("setCognitoToken", cognitoToken);
      //awaiting juuuuust in case
      await dispatch("getAllLgasFromServiceSq", {}, { root: true });
      if (
        cognitoToken.signInUserSession.accessToken.payload["cognito:groups"] &&
        cognitoToken.signInUserSession.accessToken.payload[
          "cognito:groups"
        ].some(group => group === "GNBAdmins")
      ) {
        // user is a GNB Admin
        const user = await userCRUD.getUser(cognitoToken.attributes.email);
        commit(
          "setUser",
          Object.assign({}, user, {
            isAdmin: true
          })
        );
      } else {
        await dispatch("getUserFromDB", cognitoToken.attributes.email);
        //dispatch some more actions
        await Promise.all([
          isRNA(state.user)
            ? dispatch(
                "fetchDefaultRnaLga",
                state.user.road_naming_authority_id,
                { root: true }
              )
            : Promise.resolve(),
          isOrg(state.user)
            ? dispatch(
                "fetchDefaultOrganisationLga",
                state.user.organisation_identifier,
                { root: true }
              )
            : Promise.resolve()
        ]).catch(e => console.error(e));
      }
    } catch (err) {
      await dispatch("logout");
    }
  },

  async getUserFromDB({ commit, dispatch }, email) {
    try {
      const user = await userCRUD.getUser(email);
      commit("setUser", user);
    } catch (err) {
      await dispatch("logout");
    }
  },

  setRegistrationEmail({ commit }, email) {
    commit("setRegisteredEmail", email);
  },

  setAllUsers({ commit }, payload) {
    commit("setAllUsers", payload);
  },

  getSingleStateUserById({ state }, payload) {
    return state.getters.allUsersListState.find(u => {
      return u.id === payload;
    });
  },

  async logout({ commit }) {
    if (state.cognitoToken) {
      await userCRUD.logout().catch(() => {
        //user most likely logged out in another tab/window/browser/device - no action required
      });
    }
    await Auth.signOut({ global: true }).catch(() => {
      //user most likely logged out in another tab/window/browser/device - no action required
    });
    commit("setUser", null);
    commit("setCognitoToken", null);
    commit("setDefaultRnaLga", null, {root: true});
    commit("setDefaultOrganisationLga", null, {root: true});
  }
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setAllUsers(state, payload) {
    state.allUsersList = payload;
  },
  setCognitoToken(state, token) {
    state.cognitoToken = token;
  }
};

// logic helper functions
const isRNA = user => {
  return (
    !user.isAdmin &&
    !user.isPublic &&
    user.isRNP &&
    user.road_naming_authority_id
  );
};

const isOrg = user => {
  return (
    !user.isAdmin &&
    !user.isPublic &&
    user.isPNP &&
    user.organisation_identifier
  );
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
