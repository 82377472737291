var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "dividing-line-h" }),
      _c(
        "div",
        { staticClass: "text-center my-10 text-normal-bold" },
        [
          _vm._v(" " + _vm._s(_vm.resultsCount.toLocaleString()) + " Result"),
          _vm.resultsCount > 1 ? [_vm._v("s")] : _vm._e(),
          _vm._v(" Found "),
        ],
        2
      ),
      _vm._m(0),
      _c("div", { staticClass: "dividing-line-h mt-3" }),
      _vm._l(_vm.results, function (result, key) {
        return _c("div", { key: key }, [
          _c(
            "div",
            { staticClass: "flex justify-between my-5" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-12 mr-4 text-grey text-normal-bold text-sm pt-1",
                },
                [_vm._v(" " + _vm._s(_vm.countOffset + key + 1) + ". ")]
              ),
              _c("div", { staticClass: "w-1/5 mr-4 text-normal-bold" }, [
                _vm._v(" " + _vm._s(result.geographical_name) + " "),
              ]),
              _c(
                "div",
                { staticClass: "w-1/5 mr-4" },
                _vm._l(result.lga, function (lga, key) {
                  return _c("span", { key: key }, [
                    _vm._v(" " + _vm._s(lga)),
                    result.lga.length > key + 1
                      ? _c("span", [_vm._v(",")])
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "w-1/5 mr-4" }, [
                _vm._v(_vm._s(result.designation)),
              ]),
              result.status
                ? _c("StatusButton", {
                    staticClass: "w-1/5 font-semibold text-sm",
                    attrs: {
                      label: result.dual_named
                        ? `Dual - ${result.status}`
                        : result.status,
                      status: result.status,
                    },
                  })
                : _vm._e(),
              !result.status
                ? _c("StatusButton", {
                    staticClass: "w-1/5 font-semibold text-sm",
                    attrs: { label: `No Status`, status: result.status },
                  })
                : _vm._e(),
              _vm.isPublicRoute
                ? _c("div", { staticClass: "w-1/6 text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "cursor-pointer text-blue-button",
                        on: {
                          click: function ($event) {
                            return _vm.view(result)
                          },
                        },
                      },
                      [_vm._v("View info")]
                    ),
                  ])
                : _vm._e(),
              !_vm.isPublicRoute
                ? _c("div", { staticClass: "w-1/6 text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "cursor-pointer text-blue-button",
                        on: {
                          click: function ($event) {
                            return _vm.view(result)
                          },
                        },
                      },
                      [_vm._v("View/Edit info")]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "dividing-line-h" }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex justify-between text-sm text-grey text-normal-bold",
      },
      [
        _c("div", { staticClass: "w-12 mr-4" }),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("Place Name")]),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("Local Government Area")]),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("Designation")]),
        _c("div", { staticClass: "w-1/5 text-center" }, [_vm._v("Status")]),
        _c("div", { staticClass: "w-1/6" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }