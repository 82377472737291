var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-between my-5 text-xs" }, [
    _c("div", { staticClass: "w-12 mr-4" }),
    _c("div", { staticClass: "w-1/5 mr-4 flex items-center" }, [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v(_vm._s(_vm.result.geographical_name)),
      ]),
    ]),
    _c("div", { staticClass: "w-1/5 mr-4 align-middle flex items-center" }, [
      _vm._v(_vm._s(_vm.lga)),
    ]),
    _c("div", { staticClass: "w-1/5 mr-4 align-middle flex items-center" }, [
      _vm._v(" " + _vm._s(_vm.designation) + " "),
    ]),
    _c(
      "div",
      { staticClass: "w-1/5 text-center" },
      [
        _c("status-button", {
          attrs: { label: _vm.status, status: _vm.status },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-1/6 text-center flex items-center justify-center" },
      [
        !_vm.resultIsSource
          ? _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.linkMe.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Link")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }