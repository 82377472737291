var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "label",
        { staticClass: "w-2/3" },
        [
          _vm._m(0),
          _c("div", { staticClass: "w-full mt-3 flex" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reason_for_name_choice,
                  expression: "reason_for_name_choice",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "reason_for_name_choice-field",
                disabled: _vm.readOnly,
                name: "reason for choice",
              },
              domProps: { value: _vm.reason_for_name_choice },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.reason_for_name_choice = $event.target.value
                },
              },
            }),
          ]),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("reason for choice") },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("uploadScoped", {
            staticClass: "mt-8",
            attrs: {
              "data-cypress": "upload-origin",
              label: "Attach supporting documentation",
              important: false,
              files: _vm.supporting_reference_document,
              drafted: true,
              stateKey: "address_locality",
              stateProp: "supporting_reference_document",
              uploadFile: _vm.uploadFile,
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: "address_locality",
                  prop: "supporting_reference_document",
                  index: fileIndex,
                }),
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal" },
        [
          _c(
            "RadioButton",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                "data-cypress": "commemorated-no",
                name: "commemorated checkbox",
                checkedValue: false,
                disabled: _vm.readOnly,
              },
              model: {
                value: _vm.commemorated,
                callback: function ($$v) {
                  _vm.commemorated = $$v
                },
                expression: "commemorated",
              },
            },
            [_vm._v("No")]
          ),
          _c(
            "RadioButton",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                name: "commemorated checkbox",
                "data-cypress": "commemorated-yes",
                checkedValue: true,
                disabled: _vm.readOnly,
              },
              model: {
                value: _vm.commemorated,
                callback: function ($$v) {
                  _vm.commemorated = $$v
                },
                expression: "commemorated",
              },
            },
            [_vm._v("Yes")]
          ),
        ],
        1
      ),
      _c("FieldErrorMessage", {
        attrs: { message: _vm.errors.first("commemorated checkbox") },
      }),
      _vm.commemorated
        ? _c(
            "label",
            { staticClass: "w-2/3" },
            [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.commemorated_full_name,
                    expression: "commemorated_full_name",
                  },
                ],
                staticClass: "w-full mr-4 input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "commemorated_full_name-field",
                  placeholder: "Enter Name Here...",
                  name: "commemorated person name",
                  id: "commemorated_full_name",
                  disabled: _vm.readOnly,
                },
                domProps: { value: _vm.commemorated_full_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.commemorated_full_name = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first("commemorated person name"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.commemorated
        ? _c(
            "div",
            { staticClass: "text-normal mb-4 mt-4" },
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "flex flex-row mt-2" },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          mode: "single",
                          "max-date":
                            _vm.commemorated_death_date || _vm.todaysDate,
                        },
                        on: {
                          dayclick: function ($event) {
                            return _vm.validateDate("commemorated_birth_date")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ inputProps, updateValue }) {
                                return _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|date",
                                          expression: "'required|date'",
                                        },
                                      ],
                                      staticClass: "w-full mr-4 input-area",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Date of Birth...",
                                        "data-cypress":
                                          "commemorated_birth_date",
                                        autocomplete: "off",
                                        disabled: _vm.readOnly,
                                        name: "commemorated_birth_date",
                                        "data-vv-as": "Date of Birth",
                                      },
                                      domProps: {
                                        value: _vm.commemorated_birth_date,
                                      },
                                      on: {
                                        input: (e) =>
                                          _vm.updateDate(e, updateValue),
                                        change: _vm.setDobNullIfBlank,
                                      },
                                    },
                                    "input",
                                    inputProps,
                                    false
                                  )
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2884505796
                        ),
                        model: {
                          value: _vm.commemorated_birth_date,
                          callback: function ($$v) {
                            _vm.commemorated_birth_date = $$v
                          },
                          expression: "commemorated_birth_date",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "ml-2 w-1/8",
                          class: {
                            redButtonFaded: !_vm.commemorated_birth_date,
                            redButtonSolid: _vm.commemorated_birth_date,
                          },
                          attrs: { disabled: !_vm.commemorated_birth_date },
                          on: {
                            click: function ($event) {
                              _vm.commemorated_birth_date = null
                            },
                          },
                        },
                        [_vm._v(" clear ")]
                      ),
                    ],
                    1
                  ),
                  _c("FieldErrorMessage", {
                    attrs: {
                      message: _vm.errors.first("commemorated_birth_date"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {},
                [
                  _vm._m(4),
                  _c(
                    "div",
                    { staticClass: "flex flex-row mt-2" },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          mode: "single",
                          "max-date": _vm.todaysDate,
                          "min-date": _vm.commemorated_birth_date,
                        },
                        on: {
                          dayclick: function ($event) {
                            return _vm.validateDate("commemorated_death_date")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ inputProps, updateValue }) {
                                return _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|date",
                                          expression: "'required|date'",
                                        },
                                      ],
                                      staticClass: "w-full mr-4 input-area",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Enter Date of Death...",
                                        "data-cypress":
                                          "commemorated_death_date",
                                        autocomplete: "off",
                                        name: "commemorated_death_date",
                                        "data-vv-as": "Date of Death",
                                        disabled: _vm.readOnly,
                                      },
                                      domProps: {
                                        value: _vm.commemorated_death_date,
                                      },
                                      on: {
                                        input: (e) =>
                                          _vm.updateDate(e, updateValue),
                                        change: _vm.setDodNullIfBlank,
                                      },
                                    },
                                    "input",
                                    inputProps,
                                    false
                                  )
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          792331771
                        ),
                        model: {
                          value: _vm.commemorated_death_date,
                          callback: function ($$v) {
                            _vm.commemorated_death_date = $$v
                          },
                          expression: "commemorated_death_date",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "ml-2 w-1/8",
                          class: {
                            redButtonFaded: !_vm.commemorated_death_date,
                            redButtonSolid: _vm.commemorated_death_date,
                          },
                          attrs: { disabled: !_vm.commemorated_death_date },
                          on: {
                            click: function ($event) {
                              _vm.commemorated_death_date = null
                            },
                          },
                        },
                        [_vm._v(" clear ")]
                      ),
                    ],
                    1
                  ),
                  _c("FieldErrorMessage", {
                    attrs: {
                      message: _vm.errors.first("commemorated_death_date"),
                    },
                  }),
                ],
                1
              ),
              _c("Checkbox", {
                staticClass: "pt-8 w-full pl-0",
                attrs: {
                  "data-cypress": "commemorated_dates_unknown-field",
                  label: "The Date of Birth or Death is unknown",
                  disabled:
                    _vm.readOnly ||
                    (_vm.commemorated_birth_date &&
                      _vm.commemorated_death_date),
                },
                model: {
                  value: _vm.commemorated_dates_unknown,
                  callback: function ($$v) {
                    _vm.commemorated_dates_unknown = $$v
                  },
                  expression: "commemorated_dates_unknown",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("FieldErrorMessage", {
        attrs: { message: _vm.errors.first("commemorated person death") },
      }),
      _vm.commemorated_dates_unknown
        ? _c(
            "label",
            { staticClass: "w-2/3 mb-4" },
            [
              _vm._m(5),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.commemorated_dates_unknown_description,
                      expression: "commemorated_dates_unknown_description",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress":
                      "commemorated_dates_unknown_description-field",
                    disabled: _vm.readOnly,
                    name: "explanation",
                  },
                  domProps: {
                    value: _vm.commemorated_dates_unknown_description,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.commemorated_dates_unknown_description =
                        $event.target.value
                    },
                  },
                }),
              ]),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("explanation") },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.commemorated
        ? _c(
            "label",
            { staticClass: "w-2/3" },
            [
              _vm._m(6),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.commemorated_association_description,
                      expression: "commemorated_association_description",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress":
                      "commemorated_association_description-field",
                    disabled: _vm.readOnly,
                    name: "commemorated person association",
                  },
                  domProps: { value: _vm.commemorated_association_description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.commemorated_association_description =
                        $event.target.value
                    },
                  },
                }),
              ]),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first("commemorated person association"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.commemorated
        ? _c(
            "label",
            { staticClass: "w-2/3" },
            [
              _vm._m(7),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.commemorated_source,
                      expression: "commemorated_source",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress": "commemorated_source-field",
                    disabled: _vm.readOnly,
                    name: "source of information",
                  },
                  domProps: { value: _vm.commemorated_source },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.commemorated_source = $event.target.value
                    },
                  },
                }),
              ]),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("source of information") },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.commemorated
        ? _c(
            "div",
            [
              _c("uploadScoped", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "mt-8",
                attrs: {
                  label: "Attach supporting documentation",
                  important: true,
                  files: _vm.source_reference_document,
                  drafted: true,
                  stateKey: "address_locality",
                  stateProp: "source_reference_document",
                  uploadFile: _vm.uploadFile,
                  name: "supporting documentation",
                  disabled: _vm.readOnly,
                },
                on: {
                  deleteFile: (fileIndex) =>
                    _vm.deleteUploadedFile({
                      key: "address_locality",
                      prop: "source_reference_document",
                      index: fileIndex,
                    }),
                },
                model: {
                  value: _vm.source_reference_document,
                  callback: function ($$v) {
                    _vm.source_reference_document = $$v
                  },
                  expression: "source_reference_document",
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first("supporting documentation"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("p", { staticClass: "text-normal-bold mt-6" }, [
            _vm._v(" Is the proposed name an Aboriginal name? "),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-row text-normal" },
            [
              _c(
                "RadioButton",
                {
                  staticClass: "flex-0 mr-6 mt-3 mb-1",
                  attrs: {
                    "data-cypress": "aboriginal_name-no",
                    name: "aboriginal checkbox",
                    checkedValue: false,
                    disabled: !!_vm.multicultural || _vm.readOnly,
                  },
                  model: {
                    value: _vm.aboriginal_name,
                    callback: function ($$v) {
                      _vm.aboriginal_name = $$v
                    },
                    expression: "aboriginal_name",
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "RadioButton",
                {
                  staticClass: "flex-0 mr-6 mt-3 mb-1",
                  attrs: {
                    name: "aboriginal checkbox",
                    "data-cypress": "aboriginal_name-yes",
                    checkedValue: true,
                    disabled: !!_vm.multicultural || _vm.readOnly,
                  },
                  model: {
                    value: _vm.aboriginal_name,
                    callback: function ($$v) {
                      _vm.aboriginal_name = $$v
                    },
                    expression: "aboriginal_name",
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("aboriginal checkbox") },
          }),
        ],
        1
      ),
      _vm.aboriginal_name
        ? _c(
            "div",
            [
              _vm._m(8),
              _c(
                "div",
                { staticClass: "flex flex-row text-normal" },
                [
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6 mt-3 mb-1",
                      attrs: {
                        "data-cypress": "aboriginal_consulted-no",
                        name: "consultation",
                        checkedValue: false,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.aboriginal_consulted,
                        callback: function ($$v) {
                          _vm.aboriginal_consulted = $$v
                        },
                        expression: "aboriginal_consulted",
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6 mt-3 mb-1",
                      attrs: {
                        name: "consultation",
                        "data-cypress": "aboriginal_consulted-yes",
                        checkedValue: true,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.aboriginal_consulted,
                        callback: function ($$v) {
                          _vm.aboriginal_consulted = $$v
                        },
                        expression: "aboriginal_consulted",
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
              _vm.aboriginal_consulted === false
                ? _c("MessagePanel", {
                    staticClass: "mt-4",
                    attrs: {
                      warning: "",
                      msg: "This response suggests that this proposal is likely to be deemed unacceptable",
                    },
                  })
                : _vm._e(),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("consultation") },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.aboriginal_consulted && _vm.aboriginal_name
        ? _c(
            "label",
            { staticClass: "w-2/3" },
            [
              _vm._m(9),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.aboriginal_consulted_communities,
                      expression: "aboriginal_consulted_communities",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress": "aboriginal_consulted_communities-field",
                    disabled: _vm.readOnly,
                    name: "consulted communities",
                  },
                  domProps: { value: _vm.aboriginal_consulted_communities },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.aboriginal_consulted_communities = $event.target.value
                    },
                  },
                }),
              ]),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("consulted communities") },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.aboriginal_consulted && _vm.aboriginal_name
        ? _c(
            "div",
            [
              _c("uploadScoped", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "mt-8",
                attrs: {
                  label: "Attach supporting documentation",
                  important: true,
                  files: _vm.aboriginal_reference_document,
                  drafted: true,
                  stateKey: "address_locality",
                  stateProp: "aboriginal_reference_document",
                  uploadFile: _vm.uploadFile,
                  disabled: _vm.readOnly,
                  name: "aboriginal supporting documentation",
                },
                on: {
                  deleteFile: (fileIndex) =>
                    _vm.deleteUploadedFile({
                      key: "address_locality",
                      prop: "aboriginal_reference_document",
                      index: fileIndex,
                    }),
                },
                model: {
                  value: _vm.aboriginal_reference_document,
                  callback: function ($$v) {
                    _vm.aboriginal_reference_document = $$v
                  },
                  expression: "aboriginal_reference_document",
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first(
                    "aboriginal supporting documentation"
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.aboriginal_name
        ? _c("div", { staticClass: "flex flex-row text-normal mb-4 mt-8" }, [
            _c(
              "label",
              { staticClass: "pr-4 w-2/3" },
              [
                _c("p", { staticClass: "text-normal-bold mb-2" }, [
                  _vm._v("Country/Aboriginal nation"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.aboriginal_country,
                      expression: "aboriginal_country",
                    },
                  ],
                  staticClass: "w-full mr-4 input-area",
                  attrs: {
                    type: "text",
                    "data-cypress": "aboriginal_country-field",
                    placeholder: "Enter Country Here...",
                    name: "aboriginal country",
                    id: "aboriginal_country",
                    disabled: _vm.readOnly,
                  },
                  domProps: { value: _vm.aboriginal_country },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.aboriginal_country = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("aboriginal country") },
                }),
              ],
              1
            ),
            _c(
              "label",
              { staticClass: "w-2/3" },
              [
                _vm._m(10),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.aboriginal_language,
                      expression: "aboriginal_language",
                    },
                  ],
                  staticClass: "w-full mr-4 input-area",
                  attrs: {
                    type: "text",
                    "data-cypress": "aboriginal_language-field",
                    placeholder: "Enter Language Group Here...",
                    name: "aboriginal language",
                    id: "aboriginal_language",
                    disabled: _vm.readOnly,
                  },
                  domProps: { value: _vm.aboriginal_language },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.aboriginal_language = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: { message: _vm.errors.first("aboriginal language") },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("p", { staticClass: "text-normal-bold mt-6" }, [
        _vm._v(" Does the proposed name have a multicultural dimension? "),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal" },
        [
          _c(
            "RadioButton",
            {
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                "data-cypress": "multicultural-no",
                name: "multicultural checkbox",
                checkedValue: false,
                disabled: !!_vm.aboriginal_name || _vm.readOnly,
              },
              model: {
                value: _vm.multicultural,
                callback: function ($$v) {
                  _vm.multicultural = $$v
                },
                expression: "multicultural",
              },
            },
            [_vm._v("No")]
          ),
          _c(
            "RadioButton",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                name: "multicultural checkbox",
                "data-cypress": "multicultural-yes",
                checkedValue: true,
                disabled: !!_vm.aboriginal_name || _vm.readOnly,
              },
              model: {
                value: _vm.multicultural,
                callback: function ($$v) {
                  _vm.multicultural = $$v
                },
                expression: "multicultural",
              },
            },
            [_vm._v("Yes")]
          ),
        ],
        1
      ),
      _c("FieldErrorMessage", {
        attrs: { message: _vm.errors.first("multicultural checkbox") },
      }),
      _vm.multicultural
        ? _c(
            "label",
            { staticClass: "w-2/3" },
            [
              _vm._m(11),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.multicultural_description,
                      expression: "multicultural_description",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress": "multicultural_description-field",
                    disabled: _vm.readOnly,
                    name: "multicultural dimension",
                  },
                  domProps: { value: _vm.multicultural_description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.multicultural_description = $event.target.value
                    },
                  },
                }),
              ]),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("multicultural dimension") },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Reason for the choice of name? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-6" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Does this proposed name commemorate a person? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-4" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Full Name of Person "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold pb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Birth "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Death "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Please explain why the Date of Birth or Death is unknown "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(
        " What is the person's association with this feature and their main contribution to the local community? "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(" What is the source for this information? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(
        "Has the name been selected in consultation with the Local Aboriginal Land Council and the local Aboriginal community? "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(
        "List the community groups consulted and provide evidence of support "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Language Group "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Please describe the multicultural dimension "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }