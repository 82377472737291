<template>
  <div class="base-container">
    <div class="w-9/10 mx-auto">
      <section class="pt-1 justify-center w-full block">
        <div class="flex justify-between"></div>
        <h2 class="text-black text-title mb-8 self-center">
          Submit Gazettal Notice
        </h2>
        <div
          class="w-full bg-white text-black rounded-lg pt-16 pb-16 mt-4 justify-center flex flex-wrap"
        >
          <div class="w-full justify-center flex mb-8 pt-5">
            <img
              src="../../assets/Images/press-release.png"
              class
              alt="Press release image"
            />
          </div>
          <div class="justify-center text-center">
            <p class="mb-4 w-full text-title">
              Your Gazettal Notice for {{ road.roadname }}
              {{ road.road_type }} has been submitted.
            </p>
          </div>
        </div>

        <div class="flex pt-4 text-center pb-10">
          <router-link
            :to="{ name: 'proposals' }"
            class="button-blue-hollow w-1/3"
            >Go to My Proposals Dashboard</router-link
          >
          <router-link
            :to="{
              name: 'roadNameProposalBucket',
              params: { proposalId: proposalId }
            }"
            class="button-blue ml-auto w-1/3"
            >Return to Road Name Proposal</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "GazetteSubmittedNotification",
  props: {
    road: { type: Object },
    proposalId: { type: String }
  }
};
</script>
