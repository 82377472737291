<template>
  <div class="text-sm">
    <div class="flex flex-row mb-4">
      <h3 class="flex-grow self-center">{{ title }}</h3>
    </div>
    <div>
      <button
        class="button-blue px-2 py-2 mb-4"
        @click="isShowingFilter = !isShowingFilter"
      >
        <font-awesome-icon icon="filter" class="flex-auto mr-2 filter-icon" />{{
          isShowingFilter ? `Hide filter options` : `Show filter options`
        }}
      </button>
      <button
        class="button-blue px-2 py-2 mb-4 float-right"
        @click="download"
        :disabled="!rows.length"
      >
        <font-awesome-icon
          icon="cloud-download-alt"
          class="flex-auto mr-2 filter-icon"
        />
        Download Results
      </button>
    </div>
    <transition name="fade">
      <div v-if="isShowingFilter" class="mb-4 pt-2">
        <div class="mb-4 flex-grow">
          <span>Selected Columns</span>
          <v-select
            multiple
            @input="columns => updateColumnSelection(columns)"
            :value="selectedColumns"
            label="label"
            :class="`bg-grey-input mr-4`"
            :reduce="value => value.field"
            :options="filteredColumns"
          ></v-select>
        </div>
        <div class="flex flex-row pb-4 mr-4">
          <div v-if="showDates">
            <span>
              Start Date
            </span>
            <div class="flex flex-row">
              <v-datepicker mode="single" v-model="fromDate">
                <input
                  slot-scope="props"
                  type="text"
                  :value="fromDate"
                  placeholder="Select date"
                  class="w-full mr-4 input-area date-picker"
                  @change.native="props.updateValue($event.target.value)"
                  v-validate="'required'"
                  :disabled="isLoading"
                  v-on:keydown="e => e.preventDefault()"
                />
              </v-datepicker>
              <button
                class="ml-2 px-4 button-date-picker"
                v-if="!!fromDate"
                v-bind:class="{
                  redButtonFaded: isLoading,
                  redButtonSolid: !isLoading
                }"
                :disabled="!fromDate"
                @click="fromDate = null"
              >
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>
          <div class="ml-4" v-if="showDates">
            <span>
              End Date
            </span>
            <div class="flex flex-row">
              <v-datepicker mode="single" v-model="toDate">
                <input
                  slot-scope="props"
                  type="text"
                  :value="toDate"
                  placeholder="Select date"
                  class="w-full input-area date-picker"
                  @change.native="props.updateValue($event.target.value)"
                  v-validate="'required'"
                  :disabled="isLoading"
                  v-on:keydown="e => e.preventDefault()"
                />
              </v-datepicker>
              <button
                class="ml-2 px-4 button-date-picker"
                v-if="!!toDate"
                v-bind:class="{
                  redButtonFaded: isLoading,
                  redButtonSolid: !isLoading
                }"
                :disabled="!toDate"
                @click="toDate = null"
              >
                <font-awesome-icon icon="times" />
              </button>
            </div>
          </div>

          <div class="mb-4" v-bind:class="{ 'ml-4': showDates }">
            <span>Results per Page</span>
            <v-select
              @input="updatePageLimit"
              :value="paginationProps.limit"
              label="label"
              class="bg-grey-input"
              :options="pageResultCountList"
              :disabled="isLoading"
            ></v-select>
          </div>
          <div
            class="mb-4 ml-4 flex flex-grow flex-col"
            v-if="showKeywordSearch"
          >
            <span>Keyword</span>
            <div class="flex flex-row">
              <input
                type="text"
                class="bg-grey-input flex-grow keyword pl-4"
                placeholder="Filter..."
                name="filter"
                id="filter"
                v-model="keyword"
                v-on:keyup.enter="searchViaKeyword"
              />
              <button
                class="ml-2 px-4 button-blue keyword"
                @click="searchViaKeyword"
              >
                <font-awesome-icon icon="search" class="flex-auto -mt-3" />
              </button>
            </div>
          </div>
        </div>
        <hr class="divider w-full mb-4 mt-2 mr-4" />
        <div class="filter__container">
          <DataTableFilter
            v-for="(filter, index) in filteredFilters"
            :key="index"
            :label="filter.label"
            :type="filter.type"
            :name="filter.name"
            :value="filterState[filter.name]"
            :options="filterLists[filter.options]"
            :multiple="filter.multiple || false"
            @input="value => updateFilter({ value: value, name: filter.name })"
            @clear="updateFilter({ value: null, name: filter.name })"
            :disabled="isLoading"
          />
        </div>
        <button class="button-blue-hollow px-2 py-2 mr-4" @click="resetFilters">
          <font-awesome-icon icon="times" class="mr-2 filter-icon" />Reset
          Filters
        </button>
      </div>
    </transition>
    <table class="table-auto text-black w-full">
      <Header
        v-on:sortDesc="sortDesc"
        v-on:sortAsc="sortAsc"
        :columns="filteredColumns"
        :columnState="columnState"
        :filterLists="filterLists"
        :selectedColumns="selectedColumns"
      />
      <tr v-if="isLoading">
        <td colspan="80">
          <Fetching v-if="isLoading" />
        </td>
      </tr>
      <tr
        v-if="!isLoading && !!missingRequiredFilters"
        class="border border-gray-300"
      >
        <td colspan="8">
          <div class="py-16 text-center">
            <p class="text-black text-md">
              Please select {{ missingRequiredFilters.label }}
            </p>
          </div>
        </td>
      </tr>
      <tr
        v-if="!isLoading && (!rows || !rows.length) && !missingRequiredFilters"
        class="border border-gray-300"
      >
        <td colspan="80">
          <div class="py-16 text-center">
            <p class="text-black text-xl">No {{ recordType }} found</p>
            <p class="text-gray text-sm">
              Please adjust the filter options and try again
            </p>
          </div>
        </td>
      </tr>
      <template v-if="!isLoading && !missingRequiredFilters">
        <tr
          v-for="(row, row_index) in rows"
          :key="row_index"
          class="datatable_row border border-gray-300"
          :href="row.url"
          target="_blank"
          @click="openProposalInNewTab(row.url)"
        >
          <td
            v-for="(column, index) in filteredColumns"
            :key="index"
            class="p-2"
          >
            <span v-if="column.type === 'boolean' && isShowing(column.field)">
              <span v-if="row[column.field] === true"> Yes </span>
              <span v-else-if="row[column.field] === false"> No </span>
              <span v-else> Unknown </span>
            </span>
            <span v-if="column.type === 'status' && isShowing(column.field)">
              <b>{{ row[column.field] }}</b>
            </span>
            <span v-if="column.type === 'type' && isShowing(column.field)">
              <b>{{ displayType(row[column.field]) }}</b>
            </span>
            <span
              v-if="
                column.field === 'affected_localities' &&
                  row.type === 'PLACENAME' &&
                  isShowing(column.field)
              "
            >
              <b>N/A</b>
            </span>
            <span
              v-if="
                column.type === 'text' &&
                  !Array.isArray(row[column.field]) &&
                  isShowing(column.field)
              "
              ><span v-if="!!row[column.field]">{{ row[column.field] }}</span>
              <span v-if="!row[column.field]"
                ><b>{{ column.ifNull }}</b></span
              ></span
            >
            <span
              v-if="
                column.type === 'text' &&
                  Array.isArray(row[column.field]) &&
                  isShowing(column.field)
              "
            >
              {{ constructString(row[column.field]) }}
            </span>
            <span v-if="column.type === 'date' && isShowing(column.field)"
              >{{ parseDate(row[column.field]) }}
            </span>
          </td>
        </tr>
      </template>
    </table>

    <section
      class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3"
    >
      <div
        v-if="!isLoading && parseInt(paginationProps.record_count)"
        class="mx-auto text-center self-center"
      >
        <paginate
          v-model="paginationProps.page"
          :page-count="
            Math.ceil(
              parseInt(paginationProps.record_count) / paginationProps.limit
            )
          "
          :page-range="10"
          :margin-pages="1"
          :click-handler="changePage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
        <p>
          Showing
          {{
            (
              (paginationProps.page - 1) * paginationProps.limit +
              1
            ).toLocaleString()
          }}
          to
          {{
            Math.min(
              (paginationProps.page - 1) * paginationProps.limit +
                paginationProps.limit,
              parseInt(paginationProps.record_count)
            ).toLocaleString()
          }}
          of
          {{ parseInt(paginationProps.record_count).toLocaleString() }}
          {{ recordType }}
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import moment from "moment";
import Paginate from "vuejs-paginate";

//Datatable components
import Header from "./header.vue";
import Fetching from "./fetching.vue";
import DataTableFilter from "./filter.vue";

export default {
  name: "dataTableReport",
  components: { Paginate, Header, Fetching, DataTableFilter },
  props: {
    columns: {
      type: Array
    },
    rows: {
      type: Array
    },
    paginationProps: {
      type: Object
    },
    title: {
      type: String
    },
    recordType: {
      type: String
    },
    isLoading: {
      type: Boolean
    },
    filterLists: {
      type: Object
    },
    filters: {
      type: Array
    },
    pageResultCountList: {
      type: Array
    },
    isAdmin: {
      type: Boolean
    },
    showDates: {
      type: Boolean
    },
    showKeywordSearch: {
      type: Boolean
    },
    showFilters: {
      type: Boolean,
      default: false
    },
    filterValues: {
      type: Object
    }
  },
  created() {
    this.initialiseColumns();
    this.selectedColumns = this.filteredColumns.reduce((columns, column) => {
      if (column.default) {
        columns.push(column.field);
      }
      return columns;
    }, []);
  },
  data() {
    return {
      columnState: [],
      selectedColumns: [],
      keyword: null,
      to: null,
      from: null,
      isShowingFilter: this.showFilters
    };
  },
  computed: {
    filterState() {
      return Object.keys(this.filterValues).reduce((s, k) => {
        s[k] = {
          value: this.filterValues[k] ? this.filterValues[k] : undefined
        };
        return s;
      }, {});
    },
    missingRequiredFilters() {
      return this.filteredFilters.find(
        filter =>
          filter.required &&
          (!this.filterState[filter.name] ||
            !this.filterState[filter.name].value)
      );
    },
    filteredFilters() {
      const isAdmin = this.isAdmin;
      return this.filters.reduce((filters, filter) => {
        if (isAdmin && filter.isAdminView) {
          filters.push(filter);
        } else if (!isAdmin && filter.isAuthorityView) {
          filters.push(filter);
        }
        return filters;
      }, []);
    },

    filteredColumns() {
      const isAdmin = this.isAdmin;
      return this.columns.reduce((columns, column) => {
        if (isAdmin && column.isAdminView) {
          columns.push(column);
        } else if (!isAdmin && column.isAuthorityView) {
          columns.push(column);
        }
        return columns;
      }, []);
    },
    toDate: {
      get() {
        if (!this.filterValues.to) return null;
        else {
          return moment(this.filterValues.to, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          );
        }
      },
      set(date) {
        if (!date) {
          this.$emit("setDateTo", null);
        } else {
          this.$emit(
            "setDateTo",
            moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
          );
        }
      }
    },
    fromDate: {
      get() {
        if (!this.filterValues.from) return null;
        else {
          return moment(this.filterValues.from, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          );
        }
      },
      set(date) {
        if (!date) {
          this.$emit("setDateFrom", null);
        } else {
          this.$emit(
            "setDateFrom",
            moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
          );
        }
      }
    }
  },
  methods: {
    initialiseColumns() {
      this.columnState = [];
      this.columns.map(column => {
        if (this.isAdmin && column.isAdminView) {
          this.columnState.push({});
          return;
        } else if (!this.isAdmin && column.isAuthorityView) {
          this.columnState.push({});
          return;
        }
      });
    },
    updateFilter(event) {
      const name = event.name;
      const value = event.value;
      this.$emit("filterChange", {
        filters: {
          [name]: {
            value: value
          }
        },
        name
      });
    },
    resetFilters() {
      this.to = null;
      this.from = null;
      this.keyword = null;
      this.$emit("resetFilters");
    },
    isShowing(field) {
      return !!this.selectedColumns.find(column => column === field);
    },
    updateColumnSelection(columns) {
      this.selectedColumns = this.filteredColumns
        .filter(object => columns.includes(object.field))
        .map(c => c.field);
    },
    parseDate(date) {
      if (date && typeof date == "string")
        return moment(date.substr(0, 10)).format("DD MMM YYYY");
    },
    constructString(array) {
      return array.reduce((string, item, index) => {
        index !== array.length - 1 ? (string += `${item}, `) : (string += item);
        return string;
      }, "");
    },
    changePage(pageData) {
      window.scrollTo(0, 0);
      this.$emit("updatePage", pageData);
    },
    sortDesc(props) {
      this.columnState = this.columnState.reduce((newState, current, index) => {
        if (props.index === index) {
          current.isDesc = true;
          current.isAsc = false;
        } else {
          current.isDesc = false;
          current.isAsc = false;
        }
        newState.push(current);
        return newState;
      }, []);
      this.$emit("sortDesc", props.field);
    },
    sortAsc(props) {
      this.columnState = this.columnState.reduce((newState, current, index) => {
        if (props.index === index) {
          current.isDesc = false;
          current.isAsc = true;
        } else {
          current.isDesc = false;
          current.isAsc = false;
        }
        newState.push(current);
        return newState;
      }, []);
      this.$emit("sortAsc", props.field);
    },
    displayType(type) {
      if (type === "PLACENAME") return "Place Name";
      else if (type === "BOUNDARY") return "Boundary Amendment";
      else if (type === "LOCALITY") return "Address Locality";
      else return type;
    },
    updatePageLimit(limit) {
      this.$emit("updatePageLimit", limit);
    },
    openProposalInNewTab(url) {
      window.open(url, "_blank");
    },
    searchViaKeyword() {
      this.$emit(
        "searchViaKeyword",
        this.keyword ? this.keyword.toLowerCase() : ""
      );
    },
    download() {
      this.$emit("download");
    }
  }
};
</script>
<style scoped>
.datatable {
}

.datatable_row:hover {
  background-color: #ebe9e9;
}

.filter-icon {
  color: #a4a4a4;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.filter__container {
  display: flex;
  flex-wrap: wrap;
}
.filter__container > * {
  flex: 0 0 25%;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-select {
  min-height: 40px !important;
}
.divider {
  background-color: #e0e0e0;
}

.date-picker {
  max-height: 40px !important;
}

.keyword {
  max-height: 40px !important;
  min-height: 40px !important;
}

.button-date-picker {
  max-height: 40px !important;
}
</style>
