var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field
    ? _c(
        "span",
        { class: { invalid: _vm.field.valid === false } },
        [
          _vm.field.valid === false
            ? _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: { icon: "times" },
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.transformedField))]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }