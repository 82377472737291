<template>
  <div>
    <document :entryData="transformedEntryData" v-if="transformedEntryData" />
  </div>
</template>

<script>
import Document from "./document";

export default {
  name: "DocumentTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Document
  },
  computed: {
    transformedEntryData() {
      const changes = this.entryData.changes;
      return {
        filename: changes["document name"].to,
        url: `${__FILES_URL__}/${changes["s3_key"].to}`,
        docType: this.generateFriendlyName(changes["document type"].to),
        createdAt: this.entryData.date
      };
    }
  },
  methods: {
    generateFriendlyName(docType) {
      switch (docType) {
        case "ABORIGINAL":
          return "Aboriginal community consultation file";
        case "BACKGROUND_INFO":
          return "Background Information file";
        case "CLARIFICATION":
          return "Clarification Response file";
        case "CLARIFICATION_REQUEST":
          return "Clarification Request file";
        case "COUNCIL_RESOLUTION":
          return "Council Resolution file";
        case "EVALUATION_REPORT":
          return "Evaluation Report";
        case "GENERAL":
          return "Additional Information file";
        case "MARKED_MAP":
          return "Marked Map";
        case "MARKED_PHOTO":
          return "Marked Photo";
        case "ORIGIN":
          return "Origin file";
        case "PRONUNCIATION":
          return "Pronunciation file";
        case "PUBLIC_CONSULTATION":
          return "Public Consultation file";
        case "PUBLIC_SUPPORT":
          return "Public Support file";
        case "SOURCE":
          return "Commemoration file";
        case "SUPPORTING":
          return "Supporting file";
        default:
          return null;
      }
    }
  }
};
</script>
