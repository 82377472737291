var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full flex bg-white text-black rounded-lg pl-8 mt-4" },
    [
      _c("formalisedDateModal", {
        attrs: { road: _vm.road, proposalId: _vm.proposalId },
      }),
      _c(
        "div",
        { staticClass: "self-center flex h-full -ml-3 w-18" },
        [
          _c("proposalIcon", {
            attrs: {
              label: _vm.road.roadname_classification,
              classNames: "w-12 h-12",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "self-center w-1/5 mr-8 break-words" }, [
        _c("h3", [
          _vm._v(_vm._s(_vm.road.roadname) + " " + _vm._s(_vm.road.road_type)),
        ]),
      ]),
      _c("div", { staticClass: "dividing-line-v pl-4 mr-4 w-1/2" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "w-1/2" }, [
            _c("p", { staticClass: "text-label pt-5 pb-3" }, [
              _vm._v("Road Location"),
            ]),
            _c(
              "div",
              { staticClass: "pb-3" },
              _vm._l(_vm.road.suburb_name, function (sub, index) {
                return _c("p", { key: index, staticClass: "pb-1" }, [
                  _vm._v(" " + _vm._s(sub) + " "),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-1/2 flex flex-col justify-center" },
            [
              _c(
                "span",
                { staticClass: "flex-0 text-label pt-5 self-center" },
                [_vm._v("Proposal status")]
              ),
              _c("StatusButton", {
                attrs: {
                  label: _vm.road.name_status,
                  status: _vm.road.name_status,
                  extra: _vm.finalisedDate,
                  classNames: "font-bold mb-6",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            {
              staticClass: "second-row w-1/2",
              class: { invisible: _vm.road.issue_count === null },
            },
            [
              _c("p", { staticClass: "text-label" }, [
                _vm._v("Road Name Validity"),
              ]),
              _c("span", { staticClass: "text-black text-sub-normal-bold" }, [
                _vm._v(
                  _vm._s(_vm.road.issue_count) + " Potential issues found"
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "second-row w-1/2 text-center",
              class: { invisible: !_vm.user.isAdmin },
            },
            [
              _c("p", { staticClass: "text-label" }, [_vm._v("Naming System")]),
              _c("span", { staticClass: "text-black font-semibold" }, [
                _vm._v(_vm._s(_vm.road.naming_system)),
              ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "dividing-line-v pl-5 w-3/10 justify-end" }, [
        _c("p", { staticClass: "pt-5 pb-2 text-right pr-3 text-label" }, [
          _vm._v("Actions"),
        ]),
        _c(
          "div",
          { staticClass: "items-center text-right pr-3 justify-start" },
          [
            _vm.allowEdit
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-normal font-bold text-blue-button",
                        attrs: {
                          to: {
                            name: "roadNameProposalEdit",
                            params: {
                              proposalId: _vm.proposalId,
                              roadNameId: _vm.road.identifier,
                            },
                          },
                        },
                      },
                      [_vm._v(" Edit Road ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.allowEdit
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "text-normal font-bold text-blue-button",
                        attrs: {
                          to: {
                            name: "roadNameProposalView",
                            params: {
                              proposalId: _vm.proposalId,
                              roadNameId: _vm.road.identifier,
                            },
                          },
                        },
                      },
                      [_vm._v(" View ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isGazetted
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-normal font-bold text-blue-button whitespace-no-wrap",
                        attrs: {
                          to: {
                            name: "erratumGazettalNotice",
                            params: {
                              proposalId: _vm.proposalId,
                              roadNameId: _vm.road.identifier,
                            },
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.resetErratumGazette.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Create Erratum Gazette")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !this.user.isASP
              ? [
                  _vm.isPublicApprovedProposal
                    ? _c("div", [
                        _vm.road.is_gazette_allowed === false
                          ? _c("div", { staticClass: "text-blue-button" }, [
                              _vm._v(
                                " Gazette available " +
                                  _vm._s(_vm.dateGazetteAllowed) +
                                  " "
                              ),
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-normal font-bold text-blue-button whitespace-no-wrap",
                                    attrs: {
                                      to: {
                                        name: "gazettalNotice",
                                        params: {
                                          proposalId: _vm.proposalId,
                                          roadNameId: _vm.road.identifier,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("Create Gazettal")]
                                ),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                  _vm.showReGazetteLink
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-normal font-bold text-blue-button whitespace-no-wrap",
                              attrs: {
                                to: {
                                  name: "reGazette",
                                  params: {
                                    proposalId: _vm.proposalId,
                                    roadNameId: _vm.road.identifier,
                                  },
                                },
                              },
                            },
                            [_vm._v("Re-gazette")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showConvertToNonDedicatedLink
                    ? _c("div", [
                        _vm.road.in_jira === false
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "text-normal font-bold text-blue-button whitespace-no-wrap",
                                on: {
                                  click: function ($event) {
                                    return _vm.showConvertToNonDedicated(
                                      _vm.road
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Convert to Non-Dedicated ")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.showFormaliseDateEntry
                    ? _c("div", [
                        _vm.road.is_formalisation_allowed === false
                          ? _c("div", { staticClass: "text-blue-button" }, [
                              _vm._v(
                                " Formalisation available " +
                                  _vm._s(_vm.dateFormalisationAllowed) +
                                  " "
                              ),
                            ])
                          : _c(
                              "button",
                              {
                                staticClass:
                                  "text-normal font-bold text-blue-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.showEdit(_vm.road.identifier)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.road.date_endorsed
                                        ? "Edit Formalise Date"
                                        : "Add Formalise Date"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ])
                    : _vm.isRejected &&
                      _vm.road.roadname_classification.toLowerCase() !==
                        "pre-approval"
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-normal font-bold text-blue-button whitespace-no-wrap",
                              attrs: {
                                to: {
                                  name: "appeal",
                                  params: {
                                    proposalId: _vm.proposalId,
                                    roadNameId: _vm.road.identifier,
                                  },
                                },
                              },
                            },
                            [_vm._v("Option to Appeal")]
                          ),
                        ],
                        1
                      )
                    : _vm.canSeekClarification
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-normal font-bold text-blue-button whitespace-no-wrap",
                              attrs: {
                                to: {
                                  name: "roadSeekClarification",
                                  params: {
                                    proposalId: _vm.proposalId,
                                    roadNameId: _vm.road.identifier,
                                  },
                                },
                              },
                            },
                            [_vm._v("Seek Clarification")]
                          ),
                        ],
                        1
                      )
                    : _vm.needsClarification
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-normal font-bold text-blue-button whitespace-no-wrap",
                              attrs: {
                                to: {
                                  name: "clarifyProposal",
                                  params: {
                                    proposalId: _vm.proposalId,
                                    roadNameId: _vm.road.identifier,
                                  },
                                },
                              },
                            },
                            [_vm._v("Respond to Query")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPreApprovedApprovedProposal
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-normal font-bold text-blue-button",
                              attrs: {
                                to: {
                                  name: "roadNameProposalEdit",
                                  params: {
                                    proposalId: _vm.proposalId,
                                    roadNameId: _vm.road.identifier,
                                  },
                                },
                              },
                            },
                            [_vm._v("Formalise/Gazette")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canWithdraw
                    ? _c("div", { staticClass: "ml-2" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-red-button text-normal font-bold cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.showWithdraw(_vm.road)
                              },
                            },
                          },
                          [_vm._v("Withdraw")]
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-normal font-bold text-blue-button",
                    attrs: {
                      to: {
                        name: "roadNameProposalAuditTrail",
                        params: {
                          proposalId: _vm.proposalId,
                          roadNameId: _vm.road.identifier,
                        },
                      },
                    },
                  },
                  [_vm._v("View Audit/Records")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }