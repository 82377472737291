var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ol",
      { staticClass: "checklist pl-0" },
      [
        !_vm.school_or_national_park
          ? [
              _vm.supported_by_council === false
                ? _c("MessagePanel", {
                    staticClass: "mb-4",
                    attrs: {
                      warning: "",
                      msg: "Potential issue identified. Refer 9.5 of the Place Naming Policy.",
                    },
                  })
                : _vm._e(),
              _c(
                "li",
                {
                  staticClass:
                    "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
                },
                [
                  _c("p", { staticClass: "text-normal w-5/6" }, [
                    _vm._v(" Is the name supported by council resolution? "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "float right flex-row flex" },
                    [
                      _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6",
                          attrs: {
                            "data-cypress": "supported_by_council-no",
                            name: "supported_by_council-no",
                            checkedValue: false,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.supported_by_council,
                            callback: function ($$v) {
                              _vm.supported_by_council = $$v
                            },
                            expression: "supported_by_council",
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6",
                          attrs: {
                            name: "supported_by_council-yes",
                            "data-cypress": "supported_by_council-yes",
                            checkedValue: true,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.supported_by_council,
                            callback: function ($$v) {
                              _vm.supported_by_council = $$v
                            },
                            expression: "supported_by_council",
                          },
                        },
                        [_vm._v("Yes")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm.supported_by_public === false
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "Potential issue identified. Refer 9.5 of the Place Naming Policy.",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v("Does the name have public support?"),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "supported_by_public-no",
                      name: "supported_by_public-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.supported_by_public,
                      callback: function ($$v) {
                        _vm.supported_by_public = $$v
                      },
                      expression: "supported_by_public",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "supported_by_public-yes",
                      "data-cypress": "supported_by_public-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.supported_by_public,
                      callback: function ($$v) {
                        _vm.supported_by_public = $$v
                      },
                      expression: "supported_by_public",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.supported_by_public
          ? _c(
              "div",
              { staticClass: "text-normal pb-6 dividing-line-h" },
              [
                _c("uploadScoped", {
                  staticClass: "mt-4",
                  attrs: {
                    "data-cypress": "upload-public-support",
                    label: "Attach supporting material for public support",
                    important: false,
                    files: _vm.public_support_reference_document,
                    drafted: true,
                    stateKey: "place_name",
                    stateProp: "public_support_reference_document",
                    uploadFile: (params) =>
                      _vm.uploadFile(
                        Object.assign({}, params, { isChecklistFile: true })
                      ),
                    disabled: _vm.readOnly,
                  },
                  on: {
                    deleteFile: (fileIndex) =>
                      _vm.deleteUploadedChecklistFile({
                        key: "place_name",
                        prop: "public_support_reference_document",
                        index: fileIndex,
                      }),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.is_currently_named === true
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "Potential issue identified. Refer 9.2 of the Place Naming Policy.",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v("Is the feature currently named?"),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "is_currently_named-no",
                      name: "is_currently_named-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.is_currently_named,
                      callback: function ($$v) {
                        _vm.is_currently_named = $$v
                      },
                      expression: "is_currently_named",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "is_currently_named-yes",
                      "data-cypress": "is_currently_named-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.is_currently_named,
                      callback: function ($$v) {
                        _vm.is_currently_named = $$v
                      },
                      expression: "is_currently_named",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.is_currently_named
          ? _c("div", { staticClass: "text-normal pb-6 dividing-line-h" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.current_name,
                    expression: "current_name",
                  },
                ],
                staticClass: "w-full mr-4 input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "currently_named-field",
                  placeholder: "Enter Current Name Here...",
                  name: "currrent_name",
                  id: "current_name",
                  disabled: _vm.readOnly,
                },
                domProps: { value: _vm.current_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.current_name = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.complied_section_9_2 === false
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "Potential issue identified. Refer 9.2 of the Place Naming Policy.",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "complied_section_9_2-no",
                      name: "complied_section_9_2-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.complied_section_9_2,
                      callback: function ($$v) {
                        _vm.complied_section_9_2 = $$v
                      },
                      expression: "complied_section_9_2",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "complied_section_9_2-yes",
                      "data-cypress": "complied_section_9_2-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.complied_section_9_2,
                      callback: function ($$v) {
                        _vm.complied_section_9_2 = $$v
                      },
                      expression: "complied_section_9_2",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        !_vm.school_or_national_park
          ? [
              _vm.complied_section_9_8 === false
                ? _c("MessagePanel", {
                    attrs: {
                      warning: "",
                      msg: "Potential issue identified. Refer 9.8 of the Place Naming Policy.",
                    },
                  })
                : _vm._e(),
              _c(
                "li",
                {
                  staticClass:
                    "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
                },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "float right flex-row flex" },
                    [
                      _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6",
                          attrs: {
                            "data-cypress": "complied_section_9_8-no",
                            name: "complied_section_9_8-no",
                            checkedValue: false,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.complied_section_9_8,
                            callback: function ($$v) {
                              _vm.complied_section_9_8 = $$v
                            },
                            expression: "complied_section_9_8",
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "RadioButton",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "flex-0 mr-6",
                          attrs: {
                            name: "complied_section_9_8-yes",
                            "data-cypress": "complied_section_9_8-yes",
                            checkedValue: true,
                            disabled: _vm.readOnly,
                          },
                          model: {
                            value: _vm.complied_section_9_8,
                            callback: function ($$v) {
                              _vm.complied_section_9_8 = $$v
                            },
                            expression: "complied_section_9_8",
                          },
                        },
                        [_vm._v("Yes")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm.aboriginal_name_considered === false
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "Potential issue identified. Refer 9.2 of the Place Naming Policy.",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v("Has an Aboriginal name been considered?"),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "aboriginal_name_considered-no",
                      name: "aboriginal_name_considered-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.aboriginal_name_considered,
                      callback: function ($$v) {
                        _vm.aboriginal_name_considered = $$v
                      },
                      expression: "aboriginal_name_considered",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "aboriginal_name_considered-yes",
                      "data-cypress": "aboriginal_name_considered-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.aboriginal_name_considered,
                      callback: function ($$v) {
                        _vm.aboriginal_name_considered = $$v
                      },
                      expression: "aboriginal_name_considered",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.multicultural_name_considered === false
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "Potential issue identified. Refer 9.2 of the Place Naming Policy.",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(" Has a multi-cultural name been considered? "),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "multicultural_name_considered-no",
                      name: "multicultural_name_considered-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.multicultural_name_considered,
                      callback: function ($$v) {
                        _vm.multicultural_name_considered = $$v
                      },
                      expression: "multicultural_name_considered",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "multicultural_name_considered-yes",
                      "data-cypress": "multicultural_name_considered-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.multicultural_name_considered,
                      callback: function ($$v) {
                        _vm.multicultural_name_considered = $$v
                      },
                      expression: "multicultural_name_considered",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-5/6" }, [
      _vm._v(" Does this name comply with "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=10",
            target: "_blank",
          },
        },
        [_vm._v("Section 9.2")]
      ),
      _vm._v(" in the GNB Place Naming Policy? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-5/6" }, [
      _vm._v(" If naming a reserve, does this name comply with "),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=11",
            target: "_blank",
          },
        },
        [_vm._v("Section 9.8 Generic Reserve Names")]
      ),
      _vm._v(" in the GNB Place Naming Policy? "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }