<template>
  <div class="base-container">
    <cancelProposalModal />
    <convertToNonDedicatedModal :beforeClose="beforeClose" />
    <section class="justify-center w-full block">
      <div class="flex justify-between">
        <a
          @click="
            $router.push({
              name: 'proposals',
              query: { ...roadSpecificQueryString() }
            })
          "
          class="cursor-pointer flex justify-between self-center"
        >
          <BackArrowWithTitle title="View Road Name Proposal" />
        </a>
      </div>
    </section>
    <showLoading isLoading="loadingProposal">
      <template v-slot:component>
        <div>
          <section
            class="justify-center w-full block self-center bg-white text-black rounded-lg pt-8 mt-3 mb-3"
          >
            <div class="w-9/10 flex-col mx-auto">
              <div class="flex-1 self-center flex justify-between pb-6">
                <div class="self-center">
                  <h2 class="self-center mb-2">Proposal {{ proposalBucket.reference_no }}</h2>
                  <p class="pb-3">
                    Submitted
                    {{ dateSubmitted }} by
                    <span
                      class="flex-1 text-blue-button text-normal-bold"
                    >{{ proposalBucket.authority_name }}</span>
                  </p>
                  <div class="flex-wrap flex-start">
                    <span
                      v-for="(item, index) in proposalBucket.classificationList"
                      v-bind:key="index"
                      @click="
                        changeRoadClassification({
                          target: { value: item.name }
                        })
                      "
                    >
                      <proposalButton
                        :label="item.amount + ` ` + item.name + ` Roads`"
                        :status="item.roadname_classification"
                        classNames="text-normal-bold"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="dividing-line-h-1 w-full"></div>
            <div class="w-9/10 flex-col mx-auto">
              <div class="self-center justify pt-4 flex">
                <div class="flex w-1/8 float-left mt-4">
                  <h3>{{ numberOfRoads }}</h3>
                </div>

                <!-- actions -->
                <div class="flex-wrap w-5/6 mb-4">
                  <span
                    v-for="(item, index) in proposalBucket.statusList"
                    v-bind:key="index"
                    @click="changeStatus({ target: { value: item.status } })"
                  >
                    <StatusButton
                      v-if="item.amount === 0"
                      :label="item.status"
                      :status="item.status"
                      classNames="text-normal-bold  cursor-pointer"
                    />
                    <StatusButton
                      v-if="item.amount !== 0"
                      :label="item.amount + ` ` + item.status"
                      :status="item.status"
                      classNames="text-normal-bold cursor-pointer "
                    />
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section class="pt-12 mb-20 justify-center w-full block self-center">
            <h2 class="text-black pb-8">Roads in Proposal</h2>
            <div class="flex">
              <input
                class="mr-4 h-12 pl-4 pt-3 pb-3 rounded-lg text-normal w-1/2"
                v-model="roadSearch"
                placeholder="Narrow search by Road Name…"
              />
              <select
                class="h-12 mr-4 pt-3 pb-3 text-normal w-1/4 bg-white self-center rounded"
                @change="changeRoadClassification"
                v-model="classificationFilter"
                :disabled="classificationList.length === 2"
              >
                <option value class="dropdown my-4 font-light pl-4 h-12" disabled hidden selected>
                  <div class="my-4 text-normal text-black">All Types</div>
                </option>
                <option
                  v-for="(type, index) in classificationList"
                  v-bind:key="index"
                  :value="type.roadname_classification"
                  class="dropdown my-4 font-light pl-4 h-12"
                >
                  <div class="my-4">{{ type.name }}</div>
                </option>
              </select>

              <select
                class="h-12 pt-3 pb-3 text-normal w-1/4 bg-white self-center rounded"
                @change="changeStatus"
                v-model="statusFilter"
                :disabled="statusList.length === 2"
              >
                <option value class="dropdown my-4 font-light pl-4 h-12" disabled hidden selected>
                  <div class="my-4 text-normal text-black">All Statuses</div>
                </option>
                <option
                  v-for="(status, index) in statusList"
                  v-bind:key="index"
                  :value="status.status"
                  class="dropdown my-4 pl-4 h-12"
                >
                  <div class="my-4">{{ status.status }}</div>
                </option>
              </select>
            </div>
            <roadInProposal
              v-for="(road, index) in roads"
              v-bind:key="index"
              :road="road"
              :showWithdraw="showWithdraw"
              :showConvertToNonDedicated="showConvertToNonDedicated"
              :proposalId="proposalBucket.identifier"
            />
          </section>
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import moment from "moment-timezone";
import roadInProposal from "./proposal-view/road-in-proposal";
import cancelProposalModal from "./withdraw-proposal-modal";
import convertToNonDedicatedModal from "./convert-to-non-dedicated-modal";
import proposalButton from "../road/proposalButton";
import { mapGetters } from "vuex";

export default {
  name: "proposalView",
  components: {
    cancelProposalModal,
    convertToNonDedicatedModal,
    proposalButton,
    roadInProposal
  },
  methods: {
    showWithdraw(road) {
      if (road.name_status === "Withdrawn") {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "Proposal already withdrawn"
        });
        return;
      }
      this.$modal.show("cancel-proposal", {
        road: Object.assign({}, road),
        proposalId: this.proposalBucket.identifier
      });
    },
    showConvertToNonDedicated(road) {
      this.$modal.show("convert-to-non-dedicated", {
        road: Object.assign({}, road)
      });
    },
    changeRoadClassification(event) {
      const val = event.target.value;
      if (val === "All") {
        this.classificationFilter = "";
      } else {
        this.classificationFilter = val;
      }
    },
    changeStatus(event) {
      const val = event.target.value;
      if (val === "All") {
        this.statusFilter = "";
      } else {
        this.statusFilter = val;
      }
    },
    beforeClose() {
      this.reloadPage(this.proposalBucket.identifier);
    },
    roadSpecificQueryString() {
      return {
        keyword: this.proposalSearch.keyword,
        page: this.proposalSearch.page,
        includeArchived: this.proposalSearch.includeArchived,
        road_naming_authority_id: this.proposalSearch.road_naming_authority_id,
        limit: this.proposalSearch.limit
      };
    }
  },
  computed: {
    roads() {
      if (!this.proposalBucket || !Array.isArray(this.proposalBucket.roads)) {
        return [];
      }

      return this.proposalBucket.roads
        .filter(road => {
          if (road.road_type) {
            return (
              road.roadname
                .toLowerCase()
                .includes(this.roadSearch.toLowerCase()) ||
              road.road_type
                .toLowerCase()
                .includes(this.roadSearch.toLowerCase())
            );
          } else {
            return road.roadname
              .toLowerCase()
              .includes(this.roadSearch.toLowerCase());
          }
        })
        .filter(road => {
          return road.roadname_classification
            .toLowerCase()
            .includes(this.classificationFilter.toLowerCase());
        })
        .filter(road => {
          return road.name_status
            .toLowerCase()
            .includes(this.statusFilter.toLowerCase());
        });
    },
    numberOfRoads() {
      const numRoads = this.proposalBucket.roads.length;
      switch (numRoads) {
        case 0:
          return "No Roads";
        case 1:
          return "1 Road";
        default:
          return `${numRoads} Roads`;
      }
    },
    dateSubmitted() {
      if (!this.proposalBucket.date_submitted) {
        return "";
      }
      return moment(
        this.proposalBucket.date_submitted,
        "YYYY-MM-DD kk:mm:ss.SSSSSS"
      ).format("D MMM YYYY h:mm:ss a");
    },
    statusList() {
      if (!this.proposalBucket || !this.proposalBucket.statusList) return [];
      return this.proposalBucket.statusList;
    },
    classificationList() {
      if (!this.proposalBucket || !this.proposalBucket.classificationList)
        return [];
      return this.proposalBucket.classificationList;
    },
    ...mapGetters(["proposalSearch"])
  },
  props: ["proposalBucket", "isLoading", "reloadPage"],
  data() {
    return {
      roadSearch: "",
      statusFilter: "",
      classificationFilter: ""
    };
  }
};
</script>
