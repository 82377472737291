var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container pt-16" }, [
    _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
      _c("div", { staticClass: "flex justify-between" }),
      _c("h2", { staticClass: "text-title text-black self-center" }, [
        _vm._v(" Submit Place Name Proposal "),
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex pt-4 text-center pb-10 mt-2" },
        [
          _c(
            "router-link",
            {
              staticClass: "w-1/3 button-blue-hollow",
              attrs: {
                to: { name: "placeNameProposals" },
                "data-cypress": "proposals-dashboard-link",
              },
            },
            [_vm._v("Go to My Proposals Dashboard")]
          ),
          _c(
            "router-link",
            {
              staticClass: "ml-auto w-1/3 button-blue",
              attrs: {
                to: {
                  name: "placeNameProposal",
                  params: { proposalId: "create" },
                },
                "data-cypress": "create-another-link",
              },
            },
            [_vm._v("Create Another Place Name Proposal")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "w-full bg-white text-black rounded-lg mt-2 justify-center flex flex-wrap h-1/2",
      },
      [
        _c("div", [
          _c("div", { staticClass: "w-full justify-center flex" }, [
            _c("img", {
              staticClass: "w-48 h-48 mt-20 mb-6",
              attrs: {
                src: require("../../../assets/Images/Icon_Gazettal.png"),
              },
            }),
          ]),
          _c("h3", { staticClass: "text-title mb-20 font-bold" }, [
            _vm._v(
              " Your Place Name Proposal has been submitted for consideration. "
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }