<template>
  <modal
    name="convert-to-non-dedicated"
    :maxWidth="600"
    height="auto"
    :classes="'rounded-lg bg-white'"
    :scrollable="false"
    :clickToClose="false"
    @before-open="beforeOpen"
  >
    <div
      v-if="road"
      class="w-full mx-auto pt-6 pb-10 px-6 text-black flex flex-col text-normal"
    >
      <a @click="hide()" class="cursor-pointer ml-auto">
        <ErrorIcon classNames="w-4" />
      </a>
      <div class="px-10">
        <p class="text-center text-3xl pb-1">
          {{ road.roadname }} {{ road.road_type }}
        </p>
      </div>
      <div class="px-10 pb-1 text-full">
        Are you sure you want to convert <b>{{ road.roadname }}
        {{ road.road_type }}</b> to a Non-Dedicated/Private proposal?
      </div>
      <div class="flex justify-center w-full mt-10 px-10">
        <div @click="hide()" class="button-red-hollow mr-4 w-2/5">Cancel</div>
        <ButtonWithSpinner
          class="button-blue ml-4 w-2/5"
          type="button"
          :disabled="$wait.is('converting proposal')"
          :isSpinning="$wait.is('converting proposal')"
          @click="submit"
        >
          OK
        </ButtonWithSpinner>
      </div>
    </div>
  </modal>
</template>

<script>
import { submitLegacyToNonDedicated } from "@/helpers/rnpCRUD";

export default {
  name: "convert-to-non-dedicated-modal",
  props: ["beforeClose"],
  methods: {
    submit,
    convertProposal,
    hide() {
      if (!this.$wait.is("converting proposal")) {
        this.$modal.hide("convert-to-non-dedicated");
      }
    },
    beforeOpen(event) {
      this.road = event.params.road;
    }
  },
  data() {
    return {
      road: null
    };
  }
};

async function submit() {
  try {
    this.$wait.start("converting proposal");
    const valid = await this.$validator.validate();

    if (valid) {
      await this.convertProposal();

      this.$wait.end("converting proposal");
      this.beforeClose();
      this.hide();
    } else {
      this.$wait.end("converting proposal");
    }
  } catch (error) {
    this.$notify({
      group: "toast",
      type: "error",
      title: "Conversion to Non-Dedicated issue",
      text: error.response.data.message
    });
    // eslint-disable-next-line no-console
    console.error(error);
    this.$wait.end("converting proposal");
  }
}

function convertProposal() {
  //returns a promise
  return submitLegacyToNonDedicated(this.road.identifier);
}
</script>
