var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "roadname-bulk-upload-lga",
            width: "40%",
            height: "auto",
            classes: "rounded-lg bg-white py-6",
          },
        },
        [
          _c(
            "div",
            { staticClass: "w-9/10 mx-auto flex flex-col justify-center" },
            [
              _c(
                "a",
                {
                  staticClass: "cursor-pointer ml-auto",
                  on: {
                    click: function ($event) {
                      return _vm.hideModal()
                    },
                  },
                },
                [_c("ErrorIcon", { attrs: { classNames: "w-5" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "w-full mb-10" },
                [
                  _c("p", { staticClass: "text-normal" }, [
                    _vm._v(
                      " Please select a Local Government Area. Note that all roads in a bulk upload submission must have the same Local Government Area. "
                    ),
                  ]),
                  _c("p", { staticClass: "text-normal-bold mb-2" }, [
                    _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
                    _vm._v("Local Government Area "),
                  ]),
                  _c(
                    "norns-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "w-full mr-4 norns-select",
                      attrs: {
                        multiple: false,
                        options: _vm.lgasLimited,
                        searchable: true,
                        "close-on-select": true,
                        placeholder: "Select LGA...",
                        name: "LGA",
                        "data-cypress": "lga-select",
                        "data-vv-as": "Local Government Authority",
                        "select-on-tab": true,
                      },
                      model: {
                        value: _vm.lgaName,
                        callback: function ($$v) {
                          _vm.lgaName = $$v
                        },
                        expression: "lgaName",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Loading...")]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "button-blue w-1/2 text-sm pt-4 mx-auto mt-20 block",
                      attrs: { disabled: !_vm.lgaName },
                      on: {
                        click: function ($event) {
                          return _vm.setLga()
                        },
                      },
                    },
                    [_vm._v(" Download ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "mt-6 justify-center w-full block" }, [
        _c(
          "a",
          {
            staticClass: "cursor-pointer flex self-center",
            attrs: { "data-cypress": "back-arrow" },
            on: { click: () => _vm.$router.push({ name: "proposals" }) },
          },
          [
            _c("BackArrowWithTitle", {
              attrs: { title: "Road Name Proposal" },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "mb-10" }, [
          _vm._v(
            " Welcome. Here you can create your Road Name Proposal. A Road Name Proposal can consist of a single road or multiple roads. You can add the details for each road using ‘Add New Road’ and provide the information sought. Once a single road name proposal is completed you can repeat that process to add more roads to the proposal, or you can use the tick boxes to confirm that your proposal complies with NSW Road Naming rules and then submit the proposal. "
          ),
        ]),
        this.user.isAdmin
          ? _c(
              "div",
              [
                _c("h2", [_vm._v("On Behalf of RNA")]),
                _c("rnaSelect", {
                  staticClass: "input-area input-area-alt",
                  attrs: {
                    rnaList: _vm.rnaList,
                    "data-cypress": "authority",
                    name: "rnaEntity",
                    id: "rnaEntity",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.rna,
                    callback: function ($$v) {
                      _vm.rna = $$v
                    },
                    expression: "rna",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("h2", [_vm._v("Reference Name")]),
        _c("p", { staticClass: "mb-4" }, [
          _vm._v(
            " You can give this proposal a reference name to make future identification easier. "
          ),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.referenceName,
              expression: "referenceName",
            },
          ],
          staticClass: "w-full input-area-alt mb-16",
          attrs: {
            type: "text",
            name: "referenceName",
            "data-cypress": "reference-field",
            placeholder: "Enter reference name here",
            disabled: _vm.$wait.any,
          },
          domProps: { value: _vm.referenceName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.referenceName = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "justify-center w-full block" }, [
        _c(
          "div",
          { staticClass: "flex justify-between" },
          [
            _c(
              "button",
              {
                staticClass: "button-blue-hollow",
                attrs: {
                  type: "button",
                  "data-cypress": "addroad-button",
                  disabled: _vm.$wait.any,
                },
                on: { click: _vm.addNewRoad },
              },
              [_vm._v(" Add New Road ")]
            ),
            _c(
              "ButtonWithSpinner",
              {
                staticClass: "button-blue-hollow",
                attrs: {
                  type: "button",
                  "data-cypress": "get-template-button",
                  disabled: _vm.$wait.any,
                  isSpinning: _vm.$wait.is("downloading template"),
                },
                on: { click: _vm.getUploadTemplate },
              },
              [_vm._v("Get Bulk Upload Template")]
            ),
            _c("input", {
              ref: "fileInput",
              staticClass: "hidden",
              attrs: {
                type: "file",
                "data-cypress": "file-upload-input",
                disabled: _vm.$wait.any,
                accept: [".xlsx", ".xls"],
              },
              on: {
                change: function ($event) {
                  return _vm.filesChange($event.target.files)
                },
              },
            }),
            _c(
              "button",
              {
                staticClass: "button-blue-hollow",
                attrs: {
                  type: "button",
                  "data-cypress": "bulkupload-button",
                  disabled: _vm.$wait.any,
                },
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileInput.click()
                  },
                },
              },
              [_vm._v(" Bulk Upload ")]
            ),
          ],
          1
        ),
        _c("h2", { staticClass: "pt-3" }, [
          _vm._v(
            " New Road Names - " +
              _vm._s(this.roadsForProposal.length) +
              " Roads "
          ),
        ]),
        _c("div", { staticClass: "flex items-center my-4 justify-between" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.roadSearch,
                expression: "roadSearch",
              },
            ],
            staticClass: "w-2/3 mr-4 input-area input-area-alt self-center",
            attrs: {
              type: "text",
              "data-cypress": "search-field",
              placeholder: "Narrow search by road name or ID...",
              disabled: _vm.$wait.any,
            },
            domProps: { value: _vm.roadSearch },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.roadSearch = $event.target.value
              },
            },
          }),
          _vm.totalEvaluationIssues !== null
            ? _c(
                "div",
                {
                  staticClass:
                    "py-4 px-6 rounded-full font-bold text-white whitespace-no-wrap",
                  class: [_vm.totalEvaluationIssues > 0 ? "Issue" : "Valid"],
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.totalEvaluationIssues === null
                          ? ""
                          : _vm.totalEvaluationIssues + " Potential Issues"
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm.roadsForProposal.length === 0
          ? _c("div", [_vm._m(0)])
          : _c(
              "div",
              _vm._l(_vm.filteredRoads, function (road, index) {
                return _c("roadInProposal", {
                  key: index,
                  attrs: {
                    road: road,
                    removeRoad: _vm.removeRoad,
                    index: index,
                    evaluation: _vm.getIssueCount(index),
                  },
                  on: { "evaluation-results": _vm.handleEvaluationCreation },
                })
              }),
              1
            ),
        _c("h2", { staticClass: "mt-16" }, [_vm._v("Road Name Agreements")]),
        _c("div", { staticClass: "w-full bg-white rounded-lg text-normal" }, [
          _c(
            "div",
            { staticClass: "mx-auto w-9/10 py-8" },
            [
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    "data-cypress": "livingConfirmationCheckbox",
                    error: _vm.errors.first("Living"),
                    name: "Living",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 0, $$v)
                    },
                    expression: "checkBoxChecked[0]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that no roads in this proposal are named after any currently living person. "
                  ),
                ]
              ),
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    error: _vm.errors.first("Spelling"),
                    name: "Spelling",
                    "data-cypress": "spellingConfirmationCheckbox",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[1],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 1, $$v)
                    },
                    expression: "checkBoxChecked[1]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that all roads have the same spelling as the name from which they are derived from. "
                  ),
                ]
              ),
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    error: _vm.errors.first("Commercial"),
                    name: "Commercial",
                    "data-cypress": "commercialNameConfirmationCheck",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[2],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 2, $$v)
                    },
                    expression: "checkBoxChecked[2]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that I have not used a commercial or business name that is currently active for any road names. "
                  ),
                ]
              ),
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    error: _vm.errors.first("Given Name"),
                    name: "Given Name",
                    "data-cypress": "givenNameConfirmationCheckbox",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[3],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 3, $$v)
                    },
                    expression: "checkBoxChecked[3]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that I have not used a given or first name in conjunction with a surname for any road names. "
                  ),
                ]
              ),
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    "data-cypress": "abbreviationsConfirmationCheckbox",
                    error: _vm.errors.first("Abbreviations"),
                    name: "Abbreviations",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[4],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 4, $$v)
                    },
                    expression: "checkBoxChecked[4]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that I have not used abbreviations or acronyms for or in any road names. (Except St can be used for Saint) "
                  ),
                ]
              ),
              _c(
                "Checkbox",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required:true",
                      expression: "'required:true'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    error: _vm.errors.first("Authorization"),
                    name: "Authorization",
                    "data-cypress": "authorisationConfirmationCheckbox",
                    disabled: _vm.$wait.any,
                  },
                  model: {
                    value: _vm.checkBoxChecked[5],
                    callback: function ($$v) {
                      _vm.$set(_vm.checkBoxChecked, 5, $$v)
                    },
                    expression: "checkBoxChecked[5]",
                  },
                },
                [
                  _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
                  _vm._v(
                    "I confirm that I am authorised by this Road Naming Authority (as defined by the Roads Act 1993, section 162) to submit this road name proposal. "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex", attrs: { name: "3 buttons" } },
          [
            _c(
              "ButtonWithSpinner",
              {
                staticClass:
                  "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                attrs: {
                  "data-cypress": "delete-button",
                  disabled: _vm.$wait.any,
                },
                on: { click: _vm.handleDeleteProposal },
              },
              [_vm._v("Delete Proposal")]
            ),
            _c(
              "ButtonWithSpinner",
              {
                staticClass:
                  "button-red-hollow flex relative w-2/6 mt-4 mb-10 ml-auto",
                attrs: {
                  "data-cypress": "cancel-button",
                  disabled: _vm.$wait.any,
                },
                on: { click: _vm.handleCancelChanges },
              },
              [
                _vm._v(
                  " Cancel " +
                    _vm._s(
                      _vm.$route.params.proposalId !== "create" ? "Changes" : ""
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "ButtonWithSpinner",
              {
                staticClass:
                  "button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-4",
                attrs: {
                  "data-cypress": "savedraft-button",
                  isSpinning: _vm.$wait.is("saving draft"),
                  disabled: _vm.$wait.any || !_vm.roadsForProposal.length,
                },
                on: { click: _vm.handleSubmitDraft },
              },
              [_vm._v("Save as Draft")]
            ),
            _c(
              "ButtonWithSpinner",
              {
                staticClass: "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                attrs: {
                  "data-cypress": "submit-button",
                  isSpinning: _vm.$wait.is("saving proposal"),
                  disabled:
                    _vm.$wait.any ||
                    !_vm.roadsForProposal.length ||
                    !_vm.allCheckBoxes ||
                    _vm.hasErrors,
                },
                on: { click: _vm.handleSubmitProposal },
              },
              [_vm._v("Submit Proposal")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full bg-white rounded-lg" }, [
      _c("div", { staticClass: "w-full flex justify-center items-center" }, [
        _c("img", {
          staticClass: "w-32 mx-16 mt-16 mb-4",
          attrs: { src: require("../../../assets/Images/Icon_Gazettal.png") },
        }),
      ]),
      _c(
        "p",
        {
          staticClass: "pb-16 font-bold text-grey-text text-center",
          attrs: { "data-cypress": "noroads-text" },
        },
        [_vm._v(" No roads have been added to this proposal ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }