var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-full mx-auto pt-6 px-6 pb-14 mb-8 flex-col flex",
      attrs: { "data-cypress": "remove-box" },
    },
    [
      _c(
        "a",
        {
          staticClass: "cursor-pointer ml-auto",
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
        },
        [_c("errorIcon", { attrs: { classNames: "w-4" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex items-center content-center justify-center mt-8 mb-6 px-10",
        },
        [
          _c("h2", { staticClass: "text-black" }, [
            _vm._v("Delete " + _vm._s(_vm.partyType) + " Party"),
          ]),
        ]
      ),
      _c(
        "h3",
        { staticClass: "w-full flex justify-center text-black mb-6 px-10" },
        [_vm._v(" " + _vm._s(_vm.party.name) + " ")]
      ),
      _c("div", { staticClass: "flex text-black mb-8 text-normal px-10" }, [
        _vm._v(
          " Are you sure you want to delete the above " +
            _vm._s(_vm.partyType.toLowerCase()) +
            " party? You will not be able to retrieve their contact information once deleted. "
        ),
      ]),
      _c("div", { staticClass: "flex justify-center w-full px-10" }, [
        _c(
          "div",
          {
            staticClass:
              "button-blue-hollow mr-4 px-2 w-2/5 cursor-pointer text-center self-center justify-center flex leading-none",
            attrs: { "data-cypress": "no-keep" },
            on: {
              click: function ($event) {
                return _vm.hide()
              },
            },
          },
          [_vm._v(" No, keep this " + _vm._s(_vm.partyType) + " Party ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "button-red ml-4 w-2/5 cursor-pointer text-center px-2 self-center justify-center flex leading-none",
            attrs: { "data-cypress": "yes-delete" },
            on: {
              click: function ($event) {
                return _vm.confirmParty()
              },
            },
          },
          [_vm._v(" Yes, delete this " + _vm._s(_vm.partyType) + " Party ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }