<template>
  <div class="w-full flex bg-white mb-6">
    <div class="w-5/6 mx-auto pt-8 pb-6">
      <div class="border-b-2 mb-6 self-center">
        <h3 class="w-3/4 inline-block font-bold text-2xl">{{ cardTitle }}</h3>
        <h3 class="w-1/4 inline-block text-right text-xs">
          {{ formattedEventDate }}
        </h3>
      </div>
      <slot></slot>
      <div class="w-full flex content-center items-start mt-8"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AuditTrailEntryCard",

  props: {
    cardTitle: {
      type: String,
      default: "Change in Status"
    },
    eventDate: {
      type: String,
      default: "2020-01-28T17:10:05.75053"
    }
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * The date & time of the timeline event
     */
    formattedEventDate() {
      return moment(this.eventDate).format("DD MMM YYYY HH:mm:ss");
    }
  }
};
</script>
