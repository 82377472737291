var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showBanner && _vm.bannerData.bannerType === "small"
      ? _c(
          "div",
          {
            staticClass:
              "new-content-notification flex flex-col items-center z-5001 fixed bottom-0 w-full",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "title flex flex-row flex-grow justify-center items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-no-grow icon-container items-center content-center",
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "flex-0 text-red text-3xl inline",
                      attrs: { icon: "exclamation-circle" },
                    }),
                  ],
                  1
                ),
                _c("h4", { staticClass: "flex-grow text-white" }, [
                  _vm._v(" " + _vm._s(_vm.bannerData.smallBannerMessage) + " "),
                ]),
              ]
            ),
            _c("div", { staticClass: "content flex-grow text-white" }, [
              _c(
                "a",
                {
                  staticClass: "cursor-pointer",
                  on: { click: _vm.hideBanner },
                },
                [_vm._v(" Click here to close this banner ")]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.showBanner && _vm.bannerData.bannerType === "big"
      ? _c("div", [
          _c(
            "div",
            {
              staticClass:
                "z-5001 absolute top-0 bottom-0 w-full h-full background-dark",
            },
            [
              _c(
                "div",
                { staticClass: "new-content-notification big-banner" },
                [
                  _c(
                    "div",
                    { staticClass: "title text-center" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-red text-3xl inline-block",
                        attrs: { icon: "exclamation-circle" },
                      }),
                      _c(
                        "h4",
                        {
                          staticClass: "text-white inline-block ml-5 align-top",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.bannerData.bigBannerMessage) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "title flex flex-row flex-grow justify-center items-center",
                    },
                    [
                      _c("h4", { staticClass: "text-white" }, [
                        _vm._v(
                          " " + _vm._s(_vm.bannerData.cookieMessage) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "wrapper" },
                      [
                        _vm.screenSize > 768
                          ? _c(
                              "tabs",
                              [
                                _c("tab", { attrs: { title: "Chrome" } }, [
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.bannerData.chromeMessage)
                                    ),
                                  ]),
                                ]),
                                _c("tab", { attrs: { title: "Safari" } }, [
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.bannerData.safariMessage)
                                    ),
                                  ]),
                                ]),
                                _c("tab", { attrs: { title: "Edge" } }, [
                                  _c("pre", [
                                    _vm._v(_vm._s(_vm.bannerData.edgeMessage)),
                                  ]),
                                ]),
                                _c("tab", { attrs: { title: "Firefox" } }, [
                                  _c("pre", [
                                    _vm._v(
                                      _vm._s(_vm.bannerData.firefoxMessage)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.screenSize <= 768
                          ? _c(
                              "tabs",
                              [
                                _c("tab", { attrs: { title: "Chrome" } }, [
                                  _vm._v(_vm._s(_vm.bannerData.chromeMessage)),
                                ]),
                                _c("tab", { attrs: { title: "Safari" } }, [
                                  _vm._v(_vm._s(_vm.bannerData.safariMessage)),
                                ]),
                                _c("tab", { attrs: { title: "Edge" } }, [
                                  _vm._v(_vm._s(_vm.bannerData.edgeMessage)),
                                ]),
                                _c("tab", { attrs: { title: "Firefox" } }, [
                                  _vm._v(_vm._s(_vm.bannerData.firefoxMessage)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }