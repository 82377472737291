var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-black mx-16 my-16 bg-white p-8" },
    [
      _c("DataTableReport", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          title: `Place Names Proposal Summary`,
          recordType: `proposals`,
          paginationProps: _vm.pagination,
          isLoading: _vm.$wait.is("fetchingRecords"),
          filterLists: {
            localityList: _vm.localityList,
            affectedLocalitiesList: _vm.affectedLocalitiesList,
            lgaList: _vm.lgaList,
            parishList: _vm.parishList,
            countyList: _vm.countyList,
            electorateList: _vm.electorateList,
            typeList: _vm.typeList,
            aboriginalList: _vm.aboriginalList,
            multiculturalList: _vm.multiculturalList,
            organisationList: _vm.organisationList,
            statusList: _vm.statusList,
            usersList: _vm.usersList,
          },
          filters: _vm.filters,
          filterValues: _vm.qsParams,
          pageResultCountList: _vm.pageResultCountList,
          showDates: true,
          showKeywordSearch: true,
          isAdmin: _vm.isAdmin,
        },
        on: {
          updatePage: _vm.updatePage,
          sortDesc: _vm.sortDesc,
          sortAsc: _vm.sortAsc,
          resetFilters: _vm.resetFilterSearchParams,
          filterChange: _vm.setSearchParamsFromFilters,
          updatePageLimit: _vm.updatePageLimit,
          setDateTo: _vm.setDateToParam,
          setDateFrom: _vm.setDateFromParam,
          searchViaKeyword: _vm.setGeographicalNameParam,
          download: _vm.downloadCsv,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }