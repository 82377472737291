<template>
  <div
    v-bind:class="{
      'input-field-disabled': disabled,
      'input-field': !disabled,
      'py-6': tagList.length === 0 && disabled
    }"
    class="py-4 px-4 rounded"
  >
    <span
      class="tags-input-badge tags-input-badge-pill tags-input-badge-selected-default"
      v-for="(tag, index) in tagList"
      :key="index"
    >
      <span v-if="!isDate" v-html="tag" class="tag-text"></span>
      <span v-if="isDate" v-html="formatDate(tag)" class="tag-text"></span>
      <i
        v-if="!disabled"
        href="#"
        class="multiselect__tag-icon"
        @click.prevent="removeTag(index)"
      ></i>
    </span>
    <input
      v-if="!disabled && !isDate"
      type="text"
      ref="taginput"
      v-bind:class="disabled ? 'input-field-disabled' : 'input-field'"
      v-model="input"
      @keydown.enter.prevent="addTag(false)"
      @keydown.tab="addTag"
      @blur="input = ``"
      :placeholder="placeholder"
      :data-cypress="datacypress"
      :disabled="disabled"
    />
    <datepicker
      v-if="!disabled && isDate"
      class="w-full rounded-lg bg-grey-input"
      @selected="addDateTag($event)"
      name="dateInput"
      placeholder="Select date..."
      :format="datePlaceholder"
      v-validate="{ required: true, date_format: 'yyyy-MM-dd' }"
      input-class="w-full bg-grey-input text-grey-text"
    >
    </datepicker>
  </div>
</template>
<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  $_veeValidate: {
    value() {
      return this.tagList;
    },
    name() {
      return this.label;
    }
  },
  components: { Datepicker },
  name: "taggableInput",
  data() {
    return {
      input: ""
    };
  },
  props: {
    tagList: {
      type: Array
    },
    placeholder: {
      type: String
    },
    datacypress: {
      type: String
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean
    },
    isDate: {
      type: Boolean
    }
  },
  methods: {
    formatDate: date => {
      return moment(date).format("DD-MM-YYYY");
    },
    datePlaceholder() {
      return "Select date...";
    },
    addTag() {
      const exists = this.tagList.find(tag => tag === this.input);
      if (!exists) {
        this.$emit("addTag", this.input);
        this.input = "";
      }
    },
    addDateTag(event) {
      this.input = moment(event).format("YYYY-MM-DDTHH:mm:ss");
      const exists = this.tagList.find(tag => tag === this.input);
      if (!exists) {
        this.$emit("addTag", this.input);
        this.input = "";
      }
    },
    removeTag(index) {
      this.$emit("removeTag", index);
    }
  }
};
</script>
<style scoped>
.tags-input-badge-pill {
  position: relative !important;
  display: inline-block !important;
  padding: 8px 34px 8px !important;
  border-radius: 26px !important;
  margin-right: 10px !important;
  color: rgb(255, 255, 255) !important;
  line-height: 1 !important;
  background: #002664 !important;
  margin-bottom: 3px !important;
  margin-top: 3px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 100% !important;
  text-overflow: ellipsis !important;
}

.tag-text {
  color: white;
}

.input-field {
  background-color: #ecf1f3;
  width: 100%;
  outline: none !important;

  -webkit-appearance: none;
}

.input-field-disabled {
  background-color: #d4d8da;
  width: 100%;
  outline: none !important;
  color: #35495e !important;
  -webkit-appearance: none;
}

input:focus {
  outline: none !important;

  -webkit-appearance: none;
}
</style>
