var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c(
      "div",
      {
        staticClass:
          "justify-center block self-center bg-white text-black rounded-lg px-16 py-16 mx-16",
      },
      [
        _c("h3", { staticClass: "font-bold" }, [_vm._v("Suburb Search")]),
        _c("hr", { staticClass: "divider w-full" }),
        _c("p", { staticClass: "mt-8 mb-12" }, [
          _vm._v(
            " Enter a suburb name to view an interactive map of the suburb/locality extent and surrounds. The interactive map will open in a new window in the Spatial Information Exchange (SIX) website. Please note - first time users may be prompted to install a plug-in on first use. "
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-full pt-4" },
          [
            _c("p", { staticClass: "text-normal-bold pt-4 mb-2" }, [
              _vm._v(" Suburb Name "),
            ]),
            _c("norns-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full mr-4 norns-select",
              attrs: {
                multiple: false,
                options: _vm.localityList,
                "close-on-select": true,
                "show-labels": false,
                "hide-selected": false,
                "select-on-tab": true,
                placeholder: "Select a Suburb",
                name: "localities",
                "data-cypress": "localities-field",
              },
              model: {
                value: _vm.selectedLocality,
                callback: function ($$v) {
                  _vm.selectedLocality = $$v
                },
                expression: "selectedLocality",
              },
            }),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "button-red-hollow mt-4",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v(" Cancel ")]
        ),
        _c(
          "button",
          {
            staticClass: "button-blue float-right mt-4",
            attrs: { disabled: !_vm.selectedLocality },
            on: {
              click: function ($event) {
                return _vm.openSuburb()
              },
            },
          },
          [_vm._v(" Search ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }