<template>
  <div class="text-black mx-16 my-16 bg-white p-8">
    <DataTableReport
      :columns="columns"
      :rows="rows"
      :title="`Road Names Search`"
      :recordType="`road names`"
      :paginationProps="pagination"
      :isLoading="$wait.is('fetchingRecords')"
      v-on:updatePage="updatePage"
      v-on:sortDesc="sortDesc"
      v-on:sortAsc="sortAsc"
      :filterLists="filterLists"
      :filters="filters"
      :filterValues="qsParams"
      v-on:resetFilters="resetFilterSearchParams"
      v-on:filterChange="setSearchParamsFromFilters"
      :pageResultCountList="pageResultCountList"
      v-on:updatePageLimit="updatePageLimit"
      :showDates="false"
      :showKeywordSearch="true"
      v-on:setDateTo="setDateToParam"
      v-on:setDateFrom="setDateFromParam"
      v-on:searchViaKeyword="setRoadnameParam"
      v-on:download="downloadCsv"
      :showFilters="true"
    />
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
const FileSaver = require("file-saver");

import { fetchRoadNamesFromSdw } from "@/helpers/rnpCRUD.js";
import { getSuburbs } from "@/helpers/servicesq.js";
import DataTableReport from "./data-table/index.vue";

const defaultParams = {
  includeTotal: true,
  orderDirection: "asc",
  orderBy: "roadname"
};

import lgaListMixin from "@/mixins/lga-list-filter";

const SIX_MAPS_URI = "https://maps.six.nsw.gov.au/?search=";

export default {
  name: "roadNameSearchReport",
  components: { DataTableReport },
  mixins: [lgaListMixin],
  methods: {
    fetchRecords: waitFor("fetchingRecords", async function(isCsv) {
      try {
        const params = Object.assign({}, this.qsParams);
        if (!params.lga || !params.lga.length) {
          this.pagination = {
            record_count: 0,
            page: 1,
            limit: this.pagination.limit
          };

          this.rows = [];

          return;
        }

        params.lga = params.lga.join(",");
        const records = await fetchRoadNamesFromSdw(
          {
            ...params,
            submittedDateEnd: params.to,
            submittedDateStart: params.from,
            limit: this.pagination.limit,
            page: this.pagination.page
          },
          isCsv
        );

        if (!isCsv) {
          this.rows = records.roadNames.map(r => {
            return {
              ...r,
              url: `${SIX_MAPS_URI}${JSON.stringify(this.toSixMapQuery(r))}`
            };
          });
          this.pagination.record_count = records.meta.totalRoadNames;
        } else return records;
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error fetching records",
          text: error
        });
      }
    }),
    downloadCsv: waitFor("fetchingRecords", async function() {
      try {
        const data = await this.fetchRecords(true);

        const blob = new Blob([data.csv], {
          type: "text/csv;charset=utf-8"
        });
        FileSaver.saveAs(blob, data.filename);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error downloading records",
          text: error
        });
      }
    }),
    async setSearchParamsFromFilters(payload) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        [payload.name]: payload.filters[payload.name].value
      };
      if (this.qsParams[payload.name] === "Yes")
        this.qsParams[payload.name] = "yes";
      if (this.qsParams[payload.name] === "No")
        this.qsParams[payload.name] = "no";

      if (payload.name === "lga") {
        this.qsParams = { ...this.qsParams, suburb: "" };
        this.suburbList = [];

        const suburbs = await Promise.all(
          this.qsParams.lga.map(lga => getSuburbs(lga))
        );

        //remove duplicates
        const suburbHash = {};
        suburbs.flat().forEach(sub => {
          suburbHash[sub] = true;
        });

        this.suburbList = Object.keys(suburbHash).sort();
      }

      await this.fetchRecords();
    },
    async resetFilterSearchParams() {
      this.qsParams = {
        ...defaultParams,
        includeArchived: false
      };
      this.suburbList = [];
      this.rows = [];
      this.pagination = {
        record_count: 0,
        page: 0,
        limit: 10
      };
      await this.fetchRecords();
    },
    async updatePage(page) {
      this.pagination.page = page;
      await this.fetchRecords();
    },
    async sortDesc(field) {
      if (field === "roadnamestring") {
        this.qsParams.orderBy = "roadname";
      } else {
        this.qsParams.orderBy = field;
      }
      this.qsParams.orderDirection = "desc";
      await this.fetchRecords();
    },
    async sortAsc(field) {
      if (field === "roadnamestring") {
        this.qsParams.orderBy = "roadname";
      } else {
        this.qsParams.orderBy = field;
      }
      this.qsParams.orderDirection = "asc";
      await this.fetchRecords();
    },
    async updatePageLimit(limit) {
      this.pagination = {
        ...this.pagination,
        limit: limit,
        page: 1
      };

      await this.fetchRecords();
    },
    async setDateToParam(to) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        to,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    async setDateFromParam(from) {
      this.pagination.page = 1;
      this.qsParams = {
        ...this.qsParams,
        from,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    async setRoadnameParam(name) {
      this.pagination.page = 1;
      this.qsParams.includeArchived = true;
      this.qsParams.keyword = name;
      await this.fetchRecords();
    },
    toSixMapQuery(roadName) {
      return {
        name: "Address",
        fields: {
          roadName: roadName.roadnamebase,
          suburbname: roadName.suburb,
          roadType: roadName.roadnametype
        }
      };
    }
  },
  async created() {
    this.qsParams = { ...defaultParams };
    try {
      this.lgaList = this.lgas;
      await this.fetchRecords();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
  watch: {
    lgas(newVal, oldVal) {
      if (oldVal.length !== newVal.length) {
        this.lgaList = newVal;
      }
    },
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        await this.fetchRecords();
      }
    }
  },
  computed: {
    filterLists: {
      get() {
        return {
          suburbList: this.suburbList,
          lgaList: this.lgaList,
          statusList: this.statusList
        };
      }
    },
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    }
  },
  data() {
    return {
      pageResultCountList: [5, 10, 20, 50, 100],
      lgaList: [],
      suburbList: [],
      reducedRnaList: [],
      statusList: ["Gazetted", "Endorsed", "Name In Use"],
      pagination: {
        record_count: 0,
        page: 1,
        limit: 10
      },
      qsParams: {
        includeTotal: true,
        orderDirection: "asc",
        orderBy: "roadname"
      },
      filters: [
        {
          name: "lga",
          label: "LGA",
          type: "text",
          options: "lgaList",
          isAdminView: true,
          isAuthorityView: true,
          required: true,
          multiple: true
        },
        {
          name: "suburb",
          label: "Locality",
          type: "text",
          options: "suburbList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "status",
          label: "Status",
          type: "text",
          options: "statusList",
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      columns: [
        {
          label: "Road Name",
          field: "roadnamestring",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "LGA",
          field: "lga",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Locality",
          field: "suburb",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Status",
          field: "status",
          type: "status",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      rows: []
    };
  }
};
</script>
