var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-4 w-1/2 flex-grow filter" }, [
    _c("span", [_vm._v(_vm._s(_vm.label))]),
    _c(
      "div",
      { staticClass: "flex flex-row mr-4" },
      [
        _vm.type === "text"
          ? _c("v-select", {
              staticClass: "bg-grey-input flex-grow",
              attrs: {
                value: _vm.val,
                options: _vm.options,
                disabled: _vm.$attrs.disabled,
                multiple: _vm.multiple,
              },
              on: { input: (value) => _vm.$emit("input", value) },
            })
          : _vm._e(),
        _vm.type === "object"
          ? _c("v-select", {
              staticClass: "bg-grey-input flex-grow",
              attrs: {
                reduce: (opt) => opt.value,
                label: "label",
                value: _vm.val,
                options: _vm.options,
                disabled: _vm.$attrs.disabled,
              },
              on: { input: (value) => _vm.$emit("input", value) },
            })
          : _vm._e(),
        _vm.type === "date"
          ? _c(
              "v-datepicker",
              {
                staticClass: "bg-grey-input flex-grow date-filter",
                attrs: { mode: "single", value: _vm.dateVal, name: _vm.label },
                on: { input: _vm.dateSelected },
              },
              [
                _c("input", {
                  staticClass: "w-full mr-4 input-area date-filter",
                  attrs: {
                    type: "text",
                    placeholder: "Select date",
                    name: "finalisedDateEntry",
                  },
                  domProps: { value: _vm.dateVal },
                  on: { keydown: (e) => e.preventDefault() },
                }),
              ]
            )
          : _vm._e(),
        !!_vm.val
          ? _c(
              "button",
              {
                staticClass: "ml-2 px-4 button-date-picker",
                class: {
                  redButtonFaded: _vm.$attrs.disabled,
                  redButtonSolid: !_vm.$attrs.disabled,
                },
                attrs: { disabled: _vm.$attrs.disabled },
                on: {
                  click: function ($event) {
                    return _vm.$emit("clear")
                  },
                },
              },
              [_c("font-awesome-icon", { attrs: { icon: "times" } })],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }