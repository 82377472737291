<template>
  <div class="base-container">
    <section class="justify-center w-full block">
      <a
        class="flex justify-between"
        @click="
          $router.push({
            name: 'erratumGazettalNotice',
            params: { road: proposal }
          })
        "
      >
        <BackArrowWithTitle title="Preview Erratum Gazettal" />
      </a>

      <showLoading isLoading="fetching rna details">
        <template v-slot:component>
          <div
            class="w-full bg-white text-black rounded-lg pl-8 pr-8 pt-24 pb-24 mt-4 justify-center flex"
          >
            <div class="w-4/5 mx-auto font-times">
              <div
                class="border-t-2 border-b-2 border-solid border-black text-center"
              >
                <div class="w-full flex justify-center">
                  <div class="w-2/3">
                    <p class="pt-5 text-title">{{ rna.authority_name }}</p>
                    <p class="pt-3 text-normal-large">ROADS ACT 1993</p>
                    <p class="pt-3 text-normal">Naming of Roads</p>
                    <p class="pt-3 text-normal">ERRATUM</p>

                    <p class="pt-3 text-normal text-left justify-left">
                      In the notice published in NSW Government Gazette No.
                      {{ erratumGazette.gazetteNumber }} on
                      {{ formattedPublicationDate }}, page number
                      {{ erratumGazette.folioNumber }},
                      <span
                        v-if="
                          proposal.roadname != erratumGazette.roadName ||
                          proposal.road_type != erratumGazette.roadType
                        "
                      >
                        the words "{{ proposal.roadname }}
                        {{ proposal.road_type }}" are replaced with "{{
                          erratumGazette.roadName
                        }}
                        {{ erratumGazette.roadType }}"
                      </span>

                      <span v-if="proposal.extent != erratumGazette.extent">
                        the extent "{{ proposal.extent }}" is replaced with "{{
                          erratumGazette.extent
                        }}"
                      </span>
                      <span
                        v-if="
                          sortAndJoin(proposal.suburb_name) !==
                          sortAndJoin(erratumGazette.suburb_name)
                        "
                      >
                        the locality "{{ sortAndJoin(proposal.suburb_name) }}"
                        is replaced with "{{
                          sortAndJoin(erratumGazette.suburb_name)
                        }}"
                      </span>

                      <span
                        v-if="
                          proposal.roadname == erratumGazette.roadName &&
                          proposal.road_type == erratumGazette.roadType
                        "
                      >
                        for the road {{ proposal.roadname }}
                        {{ proposal.road_type }}.
                      </span>
                    </p>

                    <p class="pt-3 text-normal text-left justify-left">
                      This notice corrects that error.
                    </p>

                    <p class="pt-3 text-normal text-left justify-left mb-8">
                      The gazettal date remains {{ formattedGazettalDate }}.
                    </p>
                  </div>
                </div>

                <div
                  v-if="user.authority_name"
                  class="w-full flex justify-center mb-4"
                >
                  <p class="w-2/3 text-left">
                    {{ rna.manager_name }} <br />
                    {{ rna.manager_role }} <br />
                    {{ rna.authority_name }} <br />
                    {{ rna.address_line_1
                    }}<span v-if="rna.address_line_2"
                      ><br />

                      {{ rna.address_line_2 }}</span
                    >
                    <br />
                    {{ rna.rna_suburb }} {{ rna.rna_state }}
                    {{ rna.rna_postcode }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex pt-4 text-center pb-10">
            <router-link
              :to="{ name: 'erratumGazettalNotice', params: { road: road } }"
              class="button-blue-hollow flex-no-grow"
              >Return to draft</router-link
            >

            <div class="flex-grow"></div>

            <ButtonWithSpinner
              class="button-blue relative flex flex-no-grow justify-center whitespace-no-wrap font-semibold"
              data-cypress="submit-gazettal-notice-button"
              :disabled="$wait.is('saving gazette')"
              :isSpinning="$wait.is('saving gazette')"
              @click="submit"
              >Create Erratum Gazettal</ButtonWithSpinner
            >
          </div>
        </template></showLoading
      >
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { waitFor } from "vue-wait";
import { getRNA } from "@/helpers/rnaCRUD";
import { getRoadNameById } from "@/helpers/rnpCRUD.js";
import { createErratumGazettal } from "../../helpers/rnpCRUD";
import { createNamespacedHelpers, mapGetters, mapMutations } from "vuex";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "erratumGazettalPreview",

  props: {
    road: { type: Object },
    proposalId: { type: String }
  },

  data() {
    return {
      rna: {},
      proposal: null,
      errorFetching: false
    };
  },

  computed: {
    ...mapUserGetters(["user"]),
    ...mapGetters(["erratumGazette"]),

    formattedPublicationDate() {
      return moment(this.erratumGazette.publicationDate, "DD/MM/YYYY").format(
        "DD MMMM YYYY"
      );
    },
    formattedGazettalDate() {
      return moment(this.proposal.date_gazetted).format("DD MMMM YYYY");
    }
  },

  async created() {
    this.proposal = this.road;

    if (!this.proposal) {
      await this.fetchRoadInformation();
    }

    await this.fetchRNA();
  },

  methods: {
    ...mapMutations(["resetErratumGazette"]),

    fetchRoadInformation: waitFor("fetchingRoadInformation", async function () {
      try {
        this.proposal = await getRoadNameById(this.$route.params.roadNameId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve road name information",
          text: error.response.data.message
        });
      }
    }),

    fetchRNA: waitFor("fetching rna details", async function () {
      try {
        this.rna = await getRNA(this.proposal.road_naming_authority_id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.errorFetching = true;
      }
    }),

    submit: waitFor("saving gazette", async function () {
      try {
        await createErratumGazettal(this.road.identifier, this.erratumGazette);

        this.resetErratumGazette();

        this.$router.push({
          name: "gazettalSubmit",
          params: {
            road: this.road,
            proposalId: this.proposalId
          }
        });
      } catch (e) {
        const text =
          e.response.status === 409
            ? e.response.data.message
            : "There has been an error creating your gazettal notice. Please try again later";

        this.$notify({
          group: "toast",
          type: "error",
          title: "Error creating gazette notice",
          text
        });
      }
    }),
    sortAndJoin(suburbs) {
      const tempSuburbs = Array.from(suburbs);

      return tempSuburbs.sort().join(", ");
    }
  }
};
</script>

<style scoped>
.tableRow {
  border: black 1px solid;
}
</style>
