<template>
  <div class="base-container text-black">
    <section class="justify-center w-full block">
      <div class="flex w-full mb-3">
        <a
          @click="$router.go(-1)"
          class="cursor-pointer flex self-center"
          data-cypress="back-arrow"
        >
          <BackArrowWithTitle title="Audit Trail" />
        </a>
      </div>
    </section>

    <slot name="summary"></slot>
    <slot name="timeline"></slot>
  </div>
</template>

<script>
export default {
  name: "AuditHistoryComponent",

  /**
   * Instance props
   */
  props: {
    trailData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
