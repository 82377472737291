var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.confirmingLinkage
    ? _c(
        "div",
        [
          _vm._m(0),
          _vm._l(_vm.results, function (result) {
            return _c("result-card", {
              key: result.identifier,
              attrs: { result: result, source: _vm.geoName },
              on: { "link-geoname": _vm.linkGeoname },
            })
          }),
          !_vm.$wait.is(`fetchingGeonames`) && _vm.results && _vm.results.length
            ? _c(
                "section",
                {
                  staticClass:
                    "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mx-auto text-center self-center" },
                    [
                      _c("paginate", {
                        attrs: {
                          "page-count": Math.ceil(
                            _vm.resultCount / _vm.search.limit
                          ),
                          "page-range": 10,
                          "margin-pages": 1,
                          "click-handler": _vm.changePage,
                          "prev-text": "<",
                          "next-text": ">",
                          "container-class": "pagination",
                          "page-class": "page-item",
                        },
                        model: {
                          value: _vm.search.page,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "page", $$v)
                          },
                          expression: "search.page",
                        },
                      }),
                      _c("p", [
                        _vm._v(
                          " Showing " +
                            _vm._s(
                              (
                                (_vm.search.page - 1) * _vm.search.limit +
                                1
                              ).toLocaleString()
                            ) +
                            " to " +
                            _vm._s(
                              Math.min(
                                (_vm.search.page - 1) * _vm.search.limit +
                                  _vm.search.limit,
                                _vm.resultCount
                              ).toLocaleString()
                            ) +
                            " of " +
                            _vm._s(_vm.resultCount.toLocaleString()) +
                            " place names "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        [
          !_vm.linkConfirmed
            ? _c("link-confirmation-dialogue", {
                attrs: { source: _vm.geoName, target: _vm.linkingGeoname },
                on: {
                  "cancel-linkage": _vm.cancelLinkage,
                  "confirm-linkage": _vm.linkageConfirmed,
                },
              })
            : _c("process-confirmed-link", {
                attrs: { source: _vm.geoName, target: _vm.linkingGeoname },
              }),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex justify-between text-sm text-grey text-xs-bold" },
      [
        _c("div", { staticClass: "w-12 mr-4" }),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("Place Name")]),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("LGA")]),
        _c("div", { staticClass: "w-1/5" }, [_vm._v("Designation")]),
        _c("div", { staticClass: "w-1/5 text-center" }, [_vm._v("Status")]),
        _c("div", { staticClass: "w-1/6" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }