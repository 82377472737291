<template>
  <div class="flex flex-grow mt-4 px-6 pb-5">
    <div class="sm:-mx-6 lg:-mx-8">
      <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <h3 class="mb-4 text-base">{{ heading }}</h3>
          <table class="w-full table-fixed" v-if="proposals.length > 0">
            <thead>
              <tr>
                <th
                  class="w-3/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Proposal Name
                </th>
                <th
                  class="w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Local Gov. Area
                </th>
                <th
                  class="w-1/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  GNB File
                </th>
                <th
                  class="w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Publication Date
                </th>
                <th
                  class="w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  Closing Date
                </th>
                <th class="w-2/12 px-6 py-3 bg-grey-lighter"></th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="proposal in proposals" :key="proposal.identifier">
                <td class="px-6 py-4 break-words">
                  <div class="flex items-center">
                    <div class="text-sm leading-5 font-medium">
                      {{ proposal.geographical_name }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 break-words">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ proposal.lga ? proposal.lga[0] : "-" }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ proposal.gnb_file_reference || "None" }}
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                >
                  {{ formatDate(proposal.date_start) }}
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                >
                  {{ formatDate(proposal.date_end) }}
                </td>

                <td class="px-6 py-4 text-right text-sm leading-5 font-medium">
                  <component
                    :is="getActionComponent(proposal)"
                    :proposal="proposal"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="text-sm">
            There are currently no proposals to list.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ViewAction from "./actions/View";
import EditAction from "./actions/Edit";
import LockedAction from "./actions/Locked";

export default {
  name: "ProposalsList",

  /**
   * Instance properties
   */
  props: {
    /**
     * The title of the list to be displayed
     */
    heading: {
      type: String,
      default: "<list-heading>"
    },

    /**
     * The proposals to display in the list
     */
    proposals: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  /**
   * Instance computed properties
   */
  computed: {},

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {},

  /**
   * Instance methods
   */
  methods: {
    /**
     * What action should be shown alongside the record
     */
    getActionComponent(advert) {
      switch (advert.allow_action) {
        case "ALLOW_COMMENT":
          return EditAction;
          break;
        case "ALLOW_VIEW":
          return ViewAction;
          break;
        default:
        case "NO_ACTION":
          return LockedAction;
          break;
      }
    },

    /**
     * Format a date string into a given format
     */
    formatDate(date) {
      return moment(date).format("DD MMM, YYYY");
    }
  }
};
</script>
