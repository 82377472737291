import { getCredentials } from "../../helpers/s3";
import _find from "lodash.find";
import _cloneDeep from "lodash.clonedeep";
import moment from "moment";

import pnpCRUD from "../../helpers/pnpCRUD.js";
import {
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_PLACE
} from "../../components/proposals/place/pnp-constants.js";

import Vue from "vue";

const newPublicApplicantState = {
  organisation_represented: null,
  first_name: null,
  last_name: null,
  address_line_1: null,
  address_line_2: null,
  suburb: null,
  state: null,
  postcode: null,
  phone_no: null,
  email: null,
  contact_person: null
};

const newProposalState = {
  public_applicant: {
    ...newPublicApplicantState
  },
  proposal_exists: true,
  proposal_type: PROPOSAL_TYPE_PLACE,
  reference: null,
  organisation_identifier: null,
  lots: [],
  local_newspapers: [],
  display_status: null,
  response_to_objections: [],
  place_name: {
    jira_id: null,
    geographical_name: null,
    lga: [],
    lga_gazettal: [],
    lga_spatial: [],
    geoname_status: null,
    county: [],
    parish: [],
    locality: [],
    electorate: [],
    school_or_national_park: null,
    description: null,
    official_map_reference_document: [],
    advertisement_reference_document: [],
    gazette_notice_reference_document: [],
    minister_note_reference_document: [],
    marked_map_reference_document: [],
    marked_photo_reference_document: [],
    pronunciation: null,
    pronunciation_reference_document: [],
    reason_for_name_choice: null,
    origin: null,
    origin_reference_document: [],
    meaning: null,
    commemorated: null,
    commemorated_full_name: null,
    commemorated_birth_date: null,
    commemorated_death_date: null,
    commemorated_dates_unknown: null,
    commemorated_dates_unknown_description: null,
    commemorated_association_description: null,
    commemorated_source: null,
    source_reference_document: [],
    aboriginal_name: null,
    aboriginal_consulted: null,
    aboriginal_consulted_communities: null,
    aboriginal_reference_document: [],
    aboriginal_country: null,
    aboriginal_language: null,
    aboriginal_place_type: null,
    multicultural: null,
    multicultural_description: null,
    lot: [],
    additional_information: null,
    additional_information_reference_document: [],
    checklist: {
      supported_by_council: null,
      supported_by_public: null,
      public_support_reference_document: [],
      is_currently_named: null,
      current_name: null,
      complied_section_9_2: null,
      complied_section_9_8: null,
      aboriginal_name_considered: null,
      multicultural_name_considered: null
    },
    geoname_identifier: null
  },
  address_locality: {
    jira_id: null,
    geographical_name: null,
    lga: [],
    lga_gazettal: [],
    lga_spatial: [],
    geoname_status: null,
    affected_localities: [],
    reasoning_for_new_address_locality: null,
    reasoning_for_new_address_locality_other: null,
    additional_reasoning: null,
    official_map_reference_document: [],
    advertisement_reference_document: [],
    gazette_notice_reference_document: [],
    minister_note_reference_document: [],
    marked_map_reference_document: [],
    electorate: [],
    lot: [],
    reason_for_name_choice: null,
    commemorated: null,
    commemorated_full_name: null,
    commemorated_birth_date: null,
    commemorated_death_date: null,
    commemorated_dates_unknown: null,
    commemorated_dates_unknown_description: null,
    commemorated_association_description: null,
    commemorated_source: null,
    source_reference_document: [],
    supporting_reference_document: [],
    aboriginal_name: null,
    aboriginal_consulted: null,
    aboriginal_consulted_communities: null,
    aboriginal_reference_document: [],
    aboriginal_country: null,
    aboriginal_language: null,
    aboriginal_place_type: null,
    multicultural: null,
    multicultural_description: null,
    additional_information: null,
    additional_information_reference_document: [],
    checklist: {
      supported_by_council: null,
      council_resolution_reference_document: [],
      complied_chapter_6_8: null,
      proposed_name_background_information: null,
      background_info_reference_document: [],
      proposed_locality_name_unique: null,
      proposed_locality_name_linked_to_estate_or_subdivision: null,
      proposed_locality_name_uses_suffix_prefix_indicator: null,
      proposed_locality_name_improves_safety_service: null,
      proposed_locality_meets_size_limits: null,
      map_or_gis_file_provided: null,
      proposed_locality_multiple_lgas: null,
      lgas: [],
      public_consultation_by_council: null,
      public_consultation_reference_document: []
    },
    geoname_identifier: null
  },
  boundary_amendment: {
    jira_id: null,
    geographical_name: null,
    lga: [],
    lga_gazettal: [],
    lga_spatial: [],
    geoname_status: null,
    affected_localities: [],
    reasoning_for_new_address_locality: null,
    reasoning_for_new_address_locality_other: null,
    official_map_reference_document: [],
    advertisement_reference_document: [],
    gazette_notice_reference_document: [],
    minister_note_reference_document: [],
    marked_map_reference_document: [],
    supporting_reference_document: [],
    electorate: [],
    lot: [],
    additional_information: null,
    additional_information_reference_document: [],
    checklist: {
      proposed_locality_meets_size_limits: null,
      map_or_gis_file_provided: null,
      proposed_locality_multiple_lgas: null,
      lgas: [],
      public_consultation_by_council: null,
      public_consultation_reference_document: [],
      affected_lot_owners_occupiers: null,
      affected_lot_owners_occupiers_agree: null,
      proposed_locality_follows_clear_lines: null,
      proposed_locality_boundaries_bisect_properties: null
    },
    geoname_identifier: null
  },
  clarification: {
    request: null,
    response: null
  },
  clarifyAdvert: {
    request: null,
    response: null
  }
};

const state = {
  statuses: [],
  proposals: [],
  proposalCount: 0,
  proposal: _cloneDeep(newProposalState),
  organisations: [],
  evaluation: [],
  geoName: {
    search: {
      totalRecords: 0,
      params: {
        geographical_name: null,
        status: [],
        designation: [],
        lga: [],
        map_cma: null,
        parish: null
      },
      queryParams: {
        page: 1,
        limit: 20
      }
    },
    results: {
      totalGeonames: null,
      geonames: null
    },
    isDualNamed: false,
    linkedWith: null
  }
};

const actions = {
  /**
   * The user cancelled the action to link
   * one placename to another
   */
  linkActionCancelled: state => {
    state.commit("notDualNamed");
  },

  /**
   * The user completed linking of a placename
   * with another
   */
  completeLinkingActions: state => {
    state.commit("setDualNamed", true);
  },

  /**
   * The user is removing a linked placename
   */
  async unlinkPlacename(state, payload) {
    await pnpCRUD.unlinkPlacename(payload.source, payload.target);
    state.commit("notDualNamed");
  },

  /**
   * Finalise the linking of a geoname to another
   */
  async linkPlacename(state, payload) {
    const res = await pnpCRUD.linkPlacenames(payload.source, payload.target);

    state.commit("setLinkedPlacename", payload.target);
  },
  createNewProposal: state => {
    state.commit("createNewProposal", newProposalState);
  },
  searchGeonames: async (state, params) => {
    const geonames = await pnpCRUD.searchGeonames(params);
    state.commit("setGeonameSearchResults", geonames.geonames);
    state.commit("setGeonameSearchResultsTotal", geonames.totalGeonames);
  },
  searchGeonamesPublic: async (state, params) => {
    const geonames = await pnpCRUD.searchGeonamesPublic(params);
    state.commit("setGeonameSearchResults", geonames.geonames);
    state.commit("setGeonameSearchResultsTotal", geonames.totalGeonames);
  },
  clearGeonames: async state => {
    state.commit("setGeonameSearchResults", null);
    state.commit("setGeonameSearchResultsTotal", null);
  },
  fetchPlaceNameStatuses: async state => {
    const statuses = await pnpCRUD.getPlaceNameStatuses();
    statuses.push("All Statuses");
    state.commit("setPlaceNameStatuses", statuses);
  },
  evaluatePlaceName: async (state, params) => {
    if (!params.placeName || !params.placeName.length) {
      state.commit("setEvaluation", null);
      return;
    }
    state.commit("setEvaluation", await pnpCRUD.getPlaceNameEvaluation(params));
  },
  clearEvaluation: state => {
    state.commit("setEvaluation", []);
  },
  fetchProposalsList: async (state, params) => {
    state.commit(
      "setProposals",
      await pnpCRUD.getAllPlaceNameProposals(params)
    );
  },
  fetchOrganisations: async state => {
    state.commit("setOrganisations", await pnpCRUD.getAllOrganisations());
  },
  selectProposal: async function(pnpState, proposalId) {
    if (proposalId === "create") {
      return;
    }

    try {
      pnpState.commit("setProposalExists", true);
      const proposal = await pnpCRUD.getSinglePlaceNameProposal(proposalId);

      /*
      if (proposal.commemorated_birth_date) {
        proposal.commemorated_birth_date = moment(
          proposal.commemorated_birth_date
        ).format("D/M/YYYY");
      }

      if (proposal.commemorated_death_date) {
        proposal.commemorated_death_date = moment(
          proposal.commemorated_death_date
        ).format("D/M/YYYY");
      }
      */

      const type = proposal.type;

      let stateKey;

      if (type === PROPOSAL_TYPE_PLACE) stateKey = "place_name";
      else if (type === PROPOSAL_TYPE_LOCALITY) stateKey = "address_locality";
      else if (type === PROPOSAL_TYPE_BOUNDARY) stateKey = "boundary_amendment";
      const newProposalWithData = _cloneDeep(newProposalState);
      newProposalWithData[stateKey] = Object.assign(
        {},
        _cloneDeep(newProposalState[stateKey]),
        {
          ...proposal
        }
      );
      const newProposalForState = Object.assign({}, newProposalWithData, {
        organisation_identifier: proposal.organisation_identifier,
        proposal_type: proposal.type,
        lots: proposal.lot || [],
        local_newspapers: proposal.local_newspapers || [],
        reference: proposal.reference,
        proposal_exists: true,
        status: proposal.geoname_status,
        display_status: proposal.display_status,
        board_decisions:
          proposal.board_decisions &&
          proposal.board_decisions.sort(
            (a, b) => moment(b.date_created) - moment(a.date_created)
          )
      });
      await pnpState.commit("setProposal", newProposalForState);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("proposal not found: ", e);
      await pnpState.commit("setProposalExists", false);
    }
  },
  selectProposalPublic: async function(pnpState, params) {
    try {
      pnpState.commit("setProposalExists", true);
      const proposal = await pnpCRUD.getSinglePlaceNameProposalPublic(
        params.proposalId,
        params.unique,
        params.token
      );
      const type = proposal.type;
      let stateKey;

      if (type === PROPOSAL_TYPE_PLACE) stateKey = "place_name";
      else if (type === PROPOSAL_TYPE_LOCALITY) stateKey = "address_locality";
      else if (type === PROPOSAL_TYPE_BOUNDARY) stateKey = "boundary_amendment";
      const newProposalWithData = _cloneDeep(newProposalState);
      newProposalWithData[stateKey] = Object.assign(
        {},
        _cloneDeep(newProposalState[stateKey]),
        {
          ...proposal
        }
      );
      const newProposalForState = Object.assign({}, newProposalWithData, {
        organisation_identifier: proposal.organisation_identifier,
        proposal_type: proposal.type,
        lots: proposal.lot || [],
        local_newspapers: proposal.local_newspapers || [],
        reference: proposal.reference,
        proposal_exists: true,
        status: proposal.geoname_status,
        display_status: proposal.display_status,
        board_decisions:
          proposal.board_decisions &&
          proposal.board_decisions.sort(
            (a, b) => moment(b.date_created) - moment(a.date_created)
          )
      });
      await pnpState.commit("setProposal", newProposalForState);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("proposal not found: ", e);
      await pnpState.commit("setProposalExists", false);
    }
  },
  uploadFile: async (state, payload) => {
    let uploadCreds;
    let createResponseToObjectionsEntry;
    try {
      uploadCreds = await getCredentials(
        payload.fileData.s3Key,
        payload.fileData.file.name
      );
      
      // If the uploaded file is for 'RESPONSE TO OBJECTIONS'
      if(payload.prop === 'response_to_objections') {
        const data = {
          files: [{
            title: payload.fileData.file.name,
            s3_key: uploadCreds.fields.key
          }],
          geoname_identifier: payload.fileData.geoname_identifier,
          document_type: 'response_to_objections'
        };

        // Adds an entry of 'RESPONSE TO OBJECTIONS' to the DB
        createResponseToObjectionsEntry = await pnpCRUD.addResponseToObjectionEntryinReferenceDocument(data);
      }
    } catch (e) {
      Vue.notify({
        group: "toast",
        type: "notice",
        title: "Unsupported file type",
        text: "Please choose a different type of file"
      });
      return;
    }

    const formData = new FormData();
    formData.append("Content-Disposition", "attachment");
    formData.append("Content-Type", "application/octet-stream");
    formData.append("Cache-Control", "max-age=31536000");
    Object.entries(uploadCreds.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", payload.fileData.file); // must be the last one

    const xhr = new XMLHttpRequest();
    return new Promise(function(resolve, reject) {
      xhr.open("POST", uploadCreds.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        if (this.status === 204) {
          if (payload.isChecklistFile) {
            state.commit("addFileToChecklist", {
              key: payload.key,
              prop: payload.prop,
              title: payload.fileData.file.name,
              s3_key: uploadCreds.fields.key
            });
          } else if (payload.componentOnly) {
            if (payload.fileData.document_type) {
              resolve({
                title: payload.fileData.file.name,
                s3_key: uploadCreds.fields.key,
                document_type: payload.fileData.document_type
              });
            }
            resolve({
              title: payload.fileData.file.name,
              s3_key: uploadCreds.fields.key
            });
          } else {
            if(payload.prop === 'response_to_objections') {
              // Adds the file to S3
              state.commit("addFile", {
                prop: payload.prop,
                title: payload.fileData.file.name,
                s3_key: uploadCreds.fields.key
              });
              resolve({ success: createResponseToObjectionsEntry.success });
            } else {
              state.commit("addFile", {
                key: payload.key,
                prop: payload.prop,
                title: payload.fileData.file.name,
                s3_key: uploadCreds.fields.key
              });
            }
          }
          resolve();
        } else {
          Vue.notify({
            group: "toast",
            type: "error",
            title: this.status + " Error",
            text: "Please choose a different file"
          });
          reject(new Error("Error, status code = " + this.status));
        }
      };
    });
  },
  saveProposal: async ({ state }) => {
    let submission;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_PLACE)
      submission = state.proposal.place_name;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_LOCALITY)
      submission = state.proposal.address_locality;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_BOUNDARY)
      submission = state.proposal.boundary_amendment;
    if (submission) {
      const payload = Object.assign({}, submission, {
        lot: state.proposal.lots,
        type: state.proposal.proposal_type,
        local_newspapers: state.proposal.local_newspapers,
        reference: state.proposal.reference,
        organisation_identifier: state.proposal.organisation_identifier,
        display_status: state.proposal.display_status,
        geoname_status: "PROPOSED",
        ...state.proposal.public_applicant
      });
      return await pnpCRUD.savePlaceNameProposal(payload);
    }
    return;
  },
  savePublicProposal: async ({ state }) => {
    let submission;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_PLACE)
      submission = state.proposal.place_name;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_LOCALITY)
      submission = state.proposal.address_locality;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_BOUNDARY)
      submission = state.proposal.boundary_amendment;
    if (submission) {
      const payload = Object.assign({}, submission, {
        lot: state.proposal.lots,
        type: state.proposal.proposal_type,
        local_newspapers: state.proposal.local_newspapers,
        reference: state.proposal.reference,
        organisation_identifier: state.proposal.organisation_identifier,
        display_status: state.proposal.display_status,
        geoname_status: "PROPOSED",
        ...state.proposal.public_applicant
      });
      return await pnpCRUD.savePublicPlaceNameProposal(payload);
    }
    return;
  },
  saveDraftProposal: async ({ state }) => {
    let submission;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_PLACE)
      submission = state.proposal.place_name;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_LOCALITY)
      submission = state.proposal.address_locality;
    if (state.proposal.proposal_type === PROPOSAL_TYPE_BOUNDARY)
      submission = state.proposal.boundary_amendment;
    if (submission) {
      const payload = Object.assign({}, submission, {
        lot: state.proposal.lots,
        type: state.proposal.proposal_type,
        local_newspapers: state.proposal.local_newspapers,
        reference: state.proposal.reference,
        organisation_identifier: state.proposal.organisation_identifier,
        display_status: state.proposal.display_status,
        geoname_status: "DRAFTED",
        ...state.proposal.public_applicant
      });
      return await pnpCRUD.saveDraftPlaceNameProposal(payload);
    }
    return;
  },
  deleteProposal: async (state, proposalId) => {
    await pnpCRUD.deletePlaceNameProposal(proposalId);
    state.commit("createNewProposal", newProposalState);
  },
  getClarification: async (state, proposalId) => {
    state.commit(
      "setClarification",
      await pnpCRUD.getClarification(proposalId)
    );
  },
  getClarificationPublic: async (state, params) => {
    state.commit(
      "setClarification",
      await pnpCRUD.getClarificationPublic(
        params.proposalId,
        params.unique,
        params.token
      )
    );
  },
  getClarifyAdvert: async (state, proposalId) => {
    state.commit(
      "setClarifyAdvert",
      await pnpCRUD.getClarifyAdvert(proposalId)
    );
  },
  getClarifyAdvertPublic: async (state, params) => {
    state.commit(
      "setClarifyAdvert",
      await pnpCRUD.getClarifyAdvertPublic(
        params.proposalId,
        params.unique,
        params.token
      )
    );
  },
  withdrawProposalById: async (state, proposalId) => {
    await pnpCRUD.withdrawProposal(proposalId);
  }
};

const createGetters = function(obj, stateKey) {
  const getters = Object.keys(obj).map(key => {
    const getter = {};
    getter[`get_${stateKey}_${key}`] = state => state.proposal[stateKey][key];
    return getter;
  });
  return getters;
};

const createMutations = function(obj, stateKey) {
  const mutations = Object.keys(obj).map(key => {
    const mutation = {};
    mutation[`set_${stateKey}_${key}`] = (state, value) =>
      (state.proposal[stateKey][key] = value);
    return mutation;
  });
  return mutations;
};

const createChecklistGetters = function(obj, stateKey) {
  const getters = Object.keys(obj).map(key => {
    const getter = {};
    getter[`get_${stateKey}_${key}`] = state =>
      state.proposal[stateKey].checklist[key];
    return getter;
  });
  return getters;
};

const createChecklistMutations = function(obj, stateKey) {
  const mutations = Object.keys(obj).map(key => {
    const mutation = {};
    mutation[`set_${stateKey}_${key}`] = (state, value) =>
      (state.proposal[stateKey].checklist[key] = value);
    return mutation;
  });
  return mutations;
};

const validatePlaceState = function(PLACE_state) {
  return (
    !!PLACE_state.geographical_name &&
    !!PLACE_state.lga.length &&
    !!PLACE_state.locality.length &&
    !!PLACE_state.description &&
    !!PLACE_state.marked_map_reference_document.length &&
    !!PLACE_state.reason_for_name_choice &&
    !!PLACE_state.origin &&
    (PLACE_state.commemorated === true || PLACE_state.commemorated === false) &&
    (PLACE_state.commemorated
      ? !!PLACE_state.commemorated_full_name &&
        ((!!PLACE_state.commemorated_birth_date &&
          !!PLACE_state.commemorated_death_date) ||
          !!PLACE_state.commemorated_dates_unknown) &&
        (PLACE_state.commemorated_dates_unknown
          ? !!PLACE_state.commemorated_association_description
          : true)
      : true) &&
    (PLACE_state.aboriginal_name
      ? (PLACE_state.aboriginal_consulted
        ? !!PLACE_state.aboriginal_consulted_communities
        : true) &&
        !!PLACE_state.aboriginal_reference_document &&
        !!PLACE_state.aboriginal_language
      : true)
  );
};

const validateLocalityState = function(LOCALITY_state) {
  return (
    !!LOCALITY_state.geographical_name &&
    !!LOCALITY_state.affected_localities.length &&
    !!LOCALITY_state.reasoning_for_new_address_locality &&
    !!LOCALITY_state.reason_for_name_choice &&
    (LOCALITY_state.commemorated
      ? !!LOCALITY_state.commemorated_full_name &&
        ((!!LOCALITY_state.commemorated_birth_date &&
          !!LOCALITY_state.commemorated_death_date) ||
          !!LOCALITY_state.commemorated_dates_unknown) &&
        (LOCALITY_state.commemorated_dates_unknown
          ? !!LOCALITY_state.commemorated_association_description
          : true)
      : true) &&
    (LOCALITY_state.aboriginal_name
      ? (LOCALITY_state.aboriginal_consulted
        ? !!LOCALITY_state.aboriginal_consulted_communities
        : true) &&
        !!LOCALITY_state.aboriginal_reference_document &&
        !!LOCALITY_state.aboriginal_language
      : true)
  );
};

const validateBoundaryState = function(BOUNDARY_state) {
  return (
    !!BOUNDARY_state.geographical_name &&
    !!BOUNDARY_state.affected_localities.length &&
    (BOUNDARY_state.reasoning_for_new_address_locality === "Other"
      ? !!BOUNDARY_state.reasoning_for_new_address_locality_other
      : !!BOUNDARY_state.reasoning_for_new_address_locality)
  );
};

//getters
const getters = Object.assign(
  {},
  {
    getGeonameLinkedWith: state => {
      return state.geoName.linkedWith;
    },
    getGeonameDualNamingState: state => {
      return state.geoName.isDualNamed;
    },
    getDraftValidity: state => {
      if (state.proposal.proposal_type === PROPOSAL_TYPE_PLACE) {
        return (
          !!state.proposal.place_name.geographical_name &&
          !!state.proposal.reference
        );
      }
      if (state.proposal.proposal_type === PROPOSAL_TYPE_LOCALITY) {
        return (
          !!state.proposal.address_locality.geographical_name &&
          !!state.proposal.reference
        );
      }
      if (state.proposal.proposal_type === PROPOSAL_TYPE_BOUNDARY) {
        return (
          !!state.proposal.boundary_amendment.geographical_name &&
          !!state.proposal.reference
        );
      }
    },
    getPlaceNameFormValidity: state => {
      const PLACE_state = state.proposal.place_name;
      return validatePlaceState(PLACE_state);
    },
    getPlaceNameFormValidityAsAdmin: state => {
      const PLACE_state = state.proposal.place_name;
      return (
        validatePlaceState(PLACE_state) &&
        !!state.proposal.organisation_identifier
      );
    },
    getLocalityFormValidity: state => {
      const LOCALITY_state = state.proposal.address_locality;

      return validateLocalityState(LOCALITY_state);
    },
    getLocalityFormValidityAsAdmin: state => {
      const LOCALITY_state = state.proposal.address_locality;
      return (
        validateLocalityState(LOCALITY_state) &&
        state.proposal.organisation_identifier
      );
    },
    getBoundaryAmendmentFormValidity: state => {
      const BOUNDARY_state = state.proposal.boundary_amendment;
      return validateBoundaryState(BOUNDARY_state);
    },
    getBoundaryAmendmentFormValidityAsAdmin: state => {
      const BOUNDARY_state = state.proposal.boundary_amendment;
      return (
        validateBoundaryState(BOUNDARY_state) &&
        state.proposal.organisation_identifier
      );
    },
    getApplicantDetailsValidity: state => {
      const PUBLIC_APPLICANT_STATE = state.proposal.public_applicant;
      return (
        !!PUBLIC_APPLICANT_STATE.first_name &&
        !!PUBLIC_APPLICANT_STATE.last_name &&
        !!PUBLIC_APPLICANT_STATE.address_line_1 &&
        !!PUBLIC_APPLICANT_STATE.suburb &&
        !!PUBLIC_APPLICANT_STATE.postcode &&
        !!PUBLIC_APPLICANT_STATE.phone_no &&
        !!PUBLIC_APPLICANT_STATE.email
      );
    },
    getEvaluation: state => {
      if (state.evaluation && state.evaluation.issues)
        return state.evaluation.issues;
      else return null;
    },
    getDisplayStatus: state => state.proposal.display_status,
    getPlaceNameStatuses: state => state.statuses,
    proposalExists: state => state.proposal.proposal_exists,
    proposalStatus: state => state.proposal.display_status,
    getProposalsList: state => state.proposals,
    getProposalCount: state => state.proposalCount,
    getReferenceNumber: state => state.proposal.reference,
    getLocalNewspapers: state => state.proposal.local_newspapers,
    getProposalType: state => state.proposal.proposal_type,
    getLots: state => state.proposal.lots,
    getAgenda: state =>
      state.proposals.filter(proposal => proposal.selected_for_agenda === true),
    getOrganisations: state => state.organisations,
    getOrganisationIdentifier: state => state.proposal.organisation_identifier,
    get_clarification: state => state.proposal.clarification,
    get_clarifyAdvert: state => state.proposal.clarifyAdvert,
    getGeonameSearchParams: state => state.geoName.search,
    getGeonameSearchResults: state => state.geoName.results.geonames,
    getGeonameSearchTotal: state => state.geoName.results.totalGeonames
  },
  ...createGetters(state.proposal.place_name, "place_name"),
  ...createGetters(state.proposal.address_locality, "address_locality"),
  ...createGetters(state.proposal.boundary_amendment, "boundary_amendment"),
  ...createGetters(state.proposal.public_applicant, "public_applicant"),
  ...createChecklistGetters(state.proposal.place_name.checklist, "place_name"),
  ...createChecklistGetters(
    state.proposal.address_locality.checklist,
    "address_locality"
  ),
  ...createChecklistGetters(
    state.proposal.boundary_amendment.checklist,
    "boundary_amendment"
  )
);

const mutations = Object.assign(
  {},
  {
    setLinkedPlacename(state, payload) {
      state.geoName.linkedWith = payload;
    },
    setDualNamed(state, isDualNamed) {
      state.geoName.isDualNamed = isDualNamed;
    },
    notDualNamed(state) {
      state.geoName.isDualNamed = false;
    },
    setGeonameSearchResults: (state, geonames) =>
      (state.geoName.results.geonames = geonames),
    setGeonameSearchResultsTotal: (state, total) =>
      (state.geoName.results.totalGeonames = total),
    setPlaceNameStatuses: (state, statuses) => (state.statuses = statuses),
    setEvaluation: (state, evaluation) => (state.evaluation = evaluation),
    createNewProposal: (state, newState) =>
      (state.proposal = _cloneDeep(newState)),
    setProposal: (state, proposal) => (state.proposal = proposal),
    setProposalExists: (state, bool) => (state.proposal.proposal_exists = bool),
    setProposals: (state, proposalsData) => {
      state.proposals = proposalsData.proposals;
      state.proposalCount = parseInt(proposalsData.totalProposals);
    },
    setOrganisations: (state, organisations) =>
      (state.organisations = organisations),
    setOrganisationIdentifier: (state, organisation_identifier) =>
      (state.proposal.organisation_identifier = organisation_identifier),
    setReferenceNumber: (state, reference) =>
      (state.proposal.reference = reference),
    addParish: (state, payload) =>
      state.proposal[payload.key].parish.push(payload.parish),
    removeParish: (state, payload) =>
      state.proposal[payload.key].parish.splice(payload.index, 1),
    addCounty: (state, payload) =>
      state.proposal[payload.key].county.push(payload.county),
    removeCounty: (state, payload) =>
      state.proposal[payload.key].county.splice(payload.index, 1),
    addLocality: (state, payload) => {
      if (payload.key === "place_name")
        state.proposal.place_name.locality.push(payload.suburb);
      else state.proposal[payload.key].affected_localities.push(payload.suburb);
    },
    removeLocality: (state, payload) => {
      if (payload.key === "place_name")
        state.proposal.place_name.locality.splice(payload.index, 1);
      else
        state.proposal[payload.key].affected_localities.splice(
          payload.index,
          1
        );
    },
    addElectorate: (state, payload) =>
      state.proposal[payload.key].electorate.push(payload.electorate),
    removeElectorate: (state, payload) =>
      state.proposal[payload.key].electorate.splice(payload.index, 1),
    addLocalNewspapers: (state, payload) =>
      state.proposal.local_newspapers.push(payload.local_newspaper),
    removeLocalNewspapers: (state, payload) =>
      state.proposal.local_newspapers.splice(payload.index, 1),
    setProposalType: (state, type) => (state.proposal.proposal_type = type),
    addFile: (state, payload) => {
      if(payload.prop === 'response_to_objections') {
        state.proposal[payload.prop].push({
          title: payload.title,
          s3_key: payload.s3_key
        });
      } else {
        state.proposal[payload.key][payload.prop].push({
          title: payload.title,
          s3_key: payload.s3_key
        });
      }
    },
    deleteUploadedFile(state, payload) {
      state.proposal[payload.key][payload.prop].splice(payload.index, 1);
    },
    clearUploadedFile(state, payload) {
      state.proposal[payload.key][payload.prop].splice(payload.index, 1);
    },
    addFileToChecklist: (state, payload) => {
      state.proposal[payload.key].checklist[payload.prop].push({
        title: payload.title,
        s3_key: payload.s3_key
      });
    },
    deleteUploadedChecklistFile(state, payload) {
      state.proposal[payload.key].checklist[payload.prop].splice(
        payload.index,
        1
      );
    },
    clearUploadState(state, payload) {
      state.proposal[payload.key][payload.prop] = [];
    },
    addLot: (state, lot) => state.proposal.lots.push(lot),
    removeLot: (state, index) => state.proposal.lots.splice(index, 1),
    resetLots: state => (state.proposal.lots = []),
    addAffectedLga: (state, params) =>
      state.proposal[params.key].checklist.lgas.push(params.lga),
    removeAffectedLga: (state, params) =>
      state.proposal[params.key].checklist.lgas.splice(params.index, 1),
    addLgaName: (state, params) =>
      state.proposal[params.key].lga.push(params.lga_name),
    removeLgaName: (state, params) =>
      state.proposal[params.key].lga.splice(params.index, 1),
    selectProposalForAgenda: (state, payload) => {
      state.proposals = state.proposals.map(proposal => {
        if (proposal.identifier === payload.id) {
          proposal.selected_for_agenda = payload.value;
        }
        return proposal;
      });
    },
    clearAgenda: state => {
      state.proposals = state.proposals.map(proposal => {
        proposal.selected_for_agenda = false;
        return proposal;
      });
    },
    setClarification: (state, payload) => {
      state.proposal.clarification = payload;
    },
    setClarifyAdvert: (state, payload) => {
      state.proposal.clarifyAdvert = payload;
    }
  },
  ...createMutations(state.proposal.place_name, "place_name"),
  ...createMutations(state.proposal.address_locality, "address_locality"),
  ...createMutations(state.proposal.boundary_amendment, "boundary_amendment"),
  ...createMutations(state.proposal.public_applicant, "public_applicant"),
  ...createChecklistMutations(
    state.proposal.place_name.checklist,
    "place_name"
  ),
  ...createChecklistMutations(
    state.proposal.address_locality.checklist,
    "address_locality"
  ),
  ...createChecklistMutations(
    state.proposal.boundary_amendment.checklist,
    "boundary_amendment"
  )
);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
