<template>
  <div class="base-container">
    <ShowLoading isLoading="fetching clarification data">
      <template v-slot:component>
        <NetworkError v-if="errorLoading" />
        <section
          class="pt-1 justify-center w-full block"
          v-if="!errorLoading && !!road"
        >
          <a
            @click="$router.go(-1)"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
          >
            <BackArrowWithTitle title="Proposal Clarification" />
          </a>

          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center font-semibold"
          >
            <div class="w-9/10 flex mx-auto">
              <proposalIcon
                :label="road.roadname_classification"
                classNames="text-base mr-3 h-10 w-10"
              />
              <p class="self-center text-title mt-0">
                {{ road.roadname }} {{ road.road_type }}
              </p>
              <StatusButton
                :label="road.name_status"
                :status="road.name_status"
                class="ml-auto mr-6 font-semibold text-sm"
              />
            </div>
          </div>

          <div
            class="w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start"
          >
            <div class="w-9/10 mx-auto">
              <span class="self-center pt-10 w-full block">
                <h2 class="text-title text-black">Clarification Request:</h2>

                <p class="self-center text-normal text-grey-text">
                  Requested {{ clarificationRequestDate }}
                </p>

                <div class="flex justify-center">
                  <span class="dividing-line-h w-full mt-4"></span>
                </div>

                <p
                  class="self-center textRejectionReason text-normal text-black mt-5"
                >
                  {{ clarificationInfo.request }}
                </p>
              </span>
              <p class="text-normal-bold content-start mt-5">
                Clarification Documents
              </p>
              <div class="mb-10">
                <viewFile
                  v-if="clarificationInfo"
                  data-cypress="clarification-files"
                  :files="clarificationInfo.files"
                />
              </div>
            </div>
          </div>
          <div class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4">
            <div class="w-9/10 mx-auto">
              <p class="text-normal-bold content-start mb-2 mt-10">
                <span class="text-red-star">*</span>Your Response
              </p>

              <textarea
                class="text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input"
                v-model="appealText"
                placeholder="Please enter your response"
                :disabled="$wait.any"
              ></textarea>

              <uploadComponent
                label="Attach supporting documentation"
                class="mb-10"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                :disabled="uploading || $wait.any"
                :drafted="true"
                :deleteFile="deleteFunction"
              />
            </div>
          </div>
          <div class="flex pt-4 text-center pb-10 justify-end">
            <ButtonWithSpinner
              @click="submitClarification"
              class="p-3 ml-4 w-1/5 button-blue"
              :disabled="uploading || !appealText || $wait.any"
              :isSpinning="$wait.is('saving clarification response')"
            >
              Submit Clarification
            </ButtonWithSpinner>
          </div>
        </section>
      </template></ShowLoading
    >
  </div>
</template>

<script>
import proposalIcon from "../road/proposalIcon";
import moment from "moment-timezone";

import {
  submitClarificationResponseJira,
  getClarificationInfo
} from "../../../helpers/rnpCRUD";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { deleteSupportingDocument } from "../../../helpers/s3";
import { waitFor } from "vue-wait";

export default {
  components: {
    proposalIcon
  },
  props: {
    roadNameId: String
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters({ road: "roadNameProposal" }),

    uploadedFiles() {
      return this.$store.getters.uploadedFilesState;
    },
    clarificationRequestDate() {
      return moment(
        this.clarificationInfo.date_created,
        "YYYY-MM-DD kk:mm:ss.SSSSSS"
      ).format("DD MMM YYYY hh:mm:ss a z");
    }
  },
  data() {
    return {
      uploading: false,
      appealText: "",
      uploadedFile: [],
      uploadedValue: "",
      clarificationInfo: [],
      deleteFunction: deleteSupportingDocument,
      errorLoading: false
    };
  },

  methods: {
    ...mapActions(["getRoadNameProposalById"]),
    ...mapMutations(["clearUploadState"]),
    fetchData: waitFor("fetching clarification data", async function() {
      try {
        await this.clearUploadState();
        this.clarificationInfo = await getClarificationInfo(this.roadNameId);
        await this.getRoadNameProposalById(this.roadNameId);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.errorLoading = true;
      }
    }),
    submitClarification: waitFor(
      "saving clarification response",
      async function() {
        try {
          const payload = {
            road: this.road,
            clarification_response: this.appealText,
            files: this.uploadedFiles,
            requestId: this.clarificationInfo.identifier
          };
          await submitClarificationResponseJira(payload);
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Clarification Submitted"
          });
          await this.clearUploadState();
          this.$router.push({
            name: "clarifySubmit",
            params: { road: this.road }
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Sending Clarification",
            text: "Error"
          });
        }
      }
    )
  }
};
</script>

<style scoped>
.dividing-line-h {
  border-bottom-width: 1px;
}

textarea::placeholder {
  color: #646974;
  opacity: 1;
}
</style>
