<template>
  <div>
    <entry
      :cardTitle="entryData.action === 'update' ? 'Change in status' : '' + 'Status'"
      :eventDate="entryData.date"
    >
      <div>
        <p class="mb-2">The proposal status was {{ entryData.action === 'update' ? 'changed' : 'set' }} to:</p>
        <strong>{{ entryData.changes.status.to }}</strong>
      </div>
    </entry>
  </div>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "PlaceNameStatusChangeTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
