<template>
  <ShowLoading :isLoading="RNA_WAITER">
    <template v-slot:component>
      <div class="base-container text-black">
        <section class="pt-1 pb-5 justify-center w-full block">
          <div class="flex justify-between">
            <a
              @click="$router.go(-1)"
              class="cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center"
            >
              <BackArrowWithTitle title="Edit RNA" />
            </a>
          </div>
        </section>

        <div class="w-full bg-white rounded-lg" v-if="rna">
          <div class="w-9/10 flex flex-wrap content-center mx-auto pt-16 pb-24">
            <p class="text-title w-full">RNA Details</p>
            <div class="w-full dividing-line-h-1">&nbsp;</div>
            <p class="text-normal-bold w-full pt-4">
              <a class="text-red-star">*</a>Name of RNA
            </p>
            <input
              type="text"
              class="w-full mt-4 input-area"
              data-cypress="nameofrna-field"
              placeholder="Enter name of RNA here..."
              v-model="rna.authority_name"
              v-validate="'required'"
              name="Authority Name"
            />
            <FieldErrorMessage :message="errors.first('Authority Name')" />
            <p class="text-normal-bold w-full pt-4">
              Default Local Government Area
            </p>
            <norns-select
              class="w-full mr-4 norns-select"
              v-model="rna.default_lga"
              :multiple="false"
              :options="lgasClean"
              :searchable="true"
              :close-on-select="true"
              placeholder="Select LGA..."
              v-validate=""
              name="LGA"
              data-cypress="lga-select"
              data-vv-as="Local Government Authority"
              :select-on-tab="true"
            >
              <span slot="noOptions">Loading...</span>
            </norns-select>
            <FieldErrorMessage
              v-if="rna.default_lga && !lgasClean.includes(rna.default_lga)"
              message="LGA does not exist or name does not match available LGAs"
            />
            <p class="w-full text-title mt-16">
              Involved Party Gazettal Details
            </p>
            <div class="w-full dividing-line-h-1">&nbsp;</div>
            <p class="text-normal w-full mt-4">
              The details below will be inserted into all gazette notices
              relating to approved road names for this specific RNA.
              <br />If the details are changed here, those changes will be
              reflected in all gazette notices for road names generated by this
              system.
            </p>
            <div class="flex flex-row w-full">
              <div class="mt-6 w-1/2">
                <p class="text-normal-bold">
                  <a class="text-red-star">*</a>Name of Responsible Person
                </p>
                <input
                  type="text"
                  class="w-full mt-4 input-area"
                  data-cypress="nameofperson-field"
                  placeholder="Enter name of Responsible Person here..."
                  v-model="rna.manager_name"
                  v-validate="'required'"
                  name="Responsible Person Name"
                />
                <FieldErrorMessage
                  :message="errors.first('Responsible Person Name')"
                />
              </div>
              <div class="mt-6 w-1/2 ml-8">
                <p class="text-normal-bold">
                  <a class="text-red-star">*</a>Organisational Title of
                  Responsible Person
                </p>
                <input
                  type="text"
                  class="w-full mt-4 input-area"
                  data-cypress="nameoforg-field"
                  placeholder="Enter organisational title..."
                  v-model="rna.manager_role"
                  v-validate="'required'"
                  name="Responsible Person Title"
                />
                <FieldErrorMessage
                  :message="errors.first('Responsible Person Title')"
                />
              </div>
            </div>
            <div class="mt-6 w-full">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>RNA Address Line 1
              </p>
              <input
                type="text"
                class="w-full mt-4 input-area"
                data-cypress="address1-field"
                placeholder="Enter address here..."
                v-model="rna.address_line_1"
                v-validate="'required'"
                name="Authority Address"
              />
              <FieldErrorMessage :message="errors.first('Authority Address')" />
            </div>
            <div class="mt-6 w-full">
              <p class="text-normal-bold">RNA Address Line 2</p>
              <input
                type="text"
                class="w-full mt-4 input-area"
                data-cypress="address2-field"
                placeholder="Enter address here..."
                v-model="rna.address_line_2"
                name="Authority Address line 2"
                v-validate=""
              />
            </div>

            <div class="w-full flex flex-row">
              <div class="mt-6 w-1/2">
                <p class="text-normal-bold">
                  <a class="text-red-star">*</a>Suburb
                </p>
                <input
                  type="text"
                  class="w-full mt-4 input-area"
                  data-cypress="suburb-field"
                  placeholder="Enter suburb name here..."
                  v-model="rna.rna_suburb"
                  v-validate="'required'"
                  name="Suburb"
                />
                <FieldErrorMessage :message="errors.first('Suburb')" />
              </div>
              <div class="w-1/2 ml-8 flex flex-row">
                <div class="mt-6 w-1/2">
                  <p class="text-normal-bold">
                    <a class="text-red-star">*</a>State
                  </p>
                  <select
                    class="w-full mt-4 input-area"
                    v-model="rna.rna_state"
                    data-cypress="state-field"
                    v-validate="'required'"
                    name="State"
                  >
                    <option disabled hidden selected>{{
                      rna.rna_state
                    }}</option>
                    <option value="NSW" selected>NSW</option>
                    <option value="VIC">VIC</option>
                    <option value="SA">SA</option>
                    <option value="WA">VIC</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="ACT">ACT</option>
                  </select>
                  <FieldErrorMessage :message="errors.first('State')" />
                </div>
                <div class="mt-6 ml-8 w-1/2">
                  <p class="text-normal-bold">
                    <a class="text-red-star">*</a>Postcode
                  </p>
                  <input
                    type="text"
                    v-validate="'required|max:4|min:4'"
                    class="w-full mt-4 input-area"
                    data-cypress="postcode-field"
                    placeholder="Enter postcode here..."
                    v-model="rna.rna_postcode"
                    name="Postcode"
                  />
                  <FieldErrorMessage :message="errors.first('Postcode')" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex my-6">
          <button
            type="button"
            @click="$router.go(-1)"
            class="button-red-hollow"
            data-cypress="cancel-button"
          >
            Cancel
          </button>
          <ButtonWithSpinner
            type="button"
            @click="handleSubmitRna"
            class="button-blue ml-auto"
            data-cypress="save-button"
            :disabled="
              !isFormValid || !isFormDirty || $wait.is(SAVING_RNA_WAITER)
            "
            :isSpinning="$wait.is(SAVING_RNA_WAITER)"
          >
            Save &amp; Continue
          </ButtonWithSpinner>
        </div>
      </div>
    </template>
  </ShowLoading>
</template>

<script>
import validatorFieldBagMixin from "../../mixins/validator-field-bag-mixin";
import rnaMixin from "../../mixins/rna-mixin";
import lgaListMixin from "../../mixins/lga-list-filter";
import { mapMutations, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
export default {
  name: "EditRNA",
  mixins: [rnaMixin, validatorFieldBagMixin, lgaListMixin],
  props: ["id"],
  computed: {
    ...mapUserGetters(["user"])
  },
  methods: {
    ...mapMutations(["setDefaultRnaLga"]),
    async fetch() {
      try {
        this.rna = await this.fetchRNA(this.id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async handleSubmitRna() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        this.$notify({
          group: "toast",
          type: "notice",
          title: "Missing Information",
          text: "Please update all required fields"
        });
        return;
      }
      try {
        await this.saveRNA(this.rna);
        if (!this.user.isAdmin && this.user.isRNP) {
          this.setDefaultRnaLga(this.rna.default_lga);
        };
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "RNA Details have been saved"
        });
        this.$router.go(-1);
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Saving RNA",
          text: "Could not save the Authority"
        });
      }
    }
  },
  async created() {
    await this.fetch();
  },
  async updated() {
    await this.fetch();
  }
};
</script>

<style scoped></style>
