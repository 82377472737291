var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c("ShowLoading", {
        attrs: { isLoading: "loading rejection reason" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _vm.errorLoading ? _c("NetworkError") : _vm._e(),
                !_vm.errorLoading && _vm.roadNameProposal
                  ? _c(
                      "section",
                      { staticClass: "justify-center w-full block" },
                      [
                        _c("div", { staticClass: "flex justify-between" }, [
                          _c(
                            "a",
                            {
                              staticClass: "flex justify-between self-center",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.go(-1)
                                },
                              },
                            },
                            [
                              _c("BackArrowWithTitle", {
                                attrs: { title: "Appeal Road Name" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full flex bg-white text-black rounded-lg pl-8 pt-4 pb-4 mt-4 text-center",
                          },
                          [
                            _c("proposalIcon", {
                              attrs: {
                                label:
                                  _vm.roadNameProposal.roadname_classification,
                                classNames: "font-bold w-12 h-12",
                              },
                            }),
                            _c("h3", { staticClass: "self-center ml-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.roadNameProposal.roadname) +
                                  " " +
                                  _vm._s(_vm.roadNameProposal.road_type) +
                                  " "
                              ),
                            ]),
                            _c("StatusButton", {
                              staticClass: "ml-auto mr-8 font-bold",
                              attrs: {
                                label: _vm.roadNameProposal.name_status,
                                status: _vm.roadNameProposal.name_status,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full mx-auto flex flex-col bg-white text-black rounded-lg pb-8 mt-4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 mx-auto flex flex-col" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "self-center pt-5 w-full" },
                                  [
                                    _c("h2", { staticClass: "mt-6" }, [
                                      _vm._v("Reason for Rejection"),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "self-center text-normal mt-2",
                                      },
                                      [
                                        _vm._v(
                                          " Submitted " +
                                            _vm._s(_vm.dateSubmitted) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "dividing-line-h mt-4 w-full",
                                }),
                                _c(
                                  "span",
                                  { staticClass: "self-center block w-full" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "self-center text-normal mt-5 pb-10",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.rejectionReason) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-9/10 mx-auto flex flex-col" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "font-bold mb-2 mt-8 w-full" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-red-star" },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(" Reason for Appeal "),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.appealReason,
                                      expression: "appealReason",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass:
                                    "text-normal overflow-y-auto h-52 text-left items-start rounded p-5 text-grey-text bg-grey-input w-full mb-4",
                                  attrs: {
                                    name: "Appeal Reason",
                                    disabled:
                                      _vm.$wait.is("saving appeal") ||
                                      _vm.uploading,
                                  },
                                  domProps: { value: _vm.appealReason },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.appealReason = $event.target.value
                                    },
                                  },
                                }),
                                _c("uploadComponent", {
                                  staticClass: "pb-10",
                                  attrs: {
                                    label: "Attach supporting documentation",
                                    disabled:
                                      _vm.$wait.is("saving appeal") ||
                                      _vm.uploading,
                                    drafted: true,
                                    deleteFile: _vm.deleteFunction,
                                  },
                                  on: {
                                    uploadfinished: function ($event) {
                                      _vm.uploading = false
                                    },
                                    uploadstarted: function ($event) {
                                      _vm.uploading = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex pt-4 text-center pb-16 justify-end",
                          },
                          [
                            _c(
                              "ButtonWithSpinner",
                              {
                                staticClass:
                                  "button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold",
                                attrs: {
                                  "data-cypress": "submit-appeal-button",
                                  disabled:
                                    !_vm.appealReason ||
                                    _vm.$wait.is("saving appeal") ||
                                    _vm.roadNameProposal.roadname_classification.toLowerCase() ===
                                      "pre-approval",
                                  isSpinning: _vm.$wait.is("saving appeal"),
                                },
                                on: { click: _vm.submitAppeal },
                              },
                              [_vm._v("Submit Appeal")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }