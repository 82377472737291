var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-1" }, [
    _c("p", { staticClass: "text-label" }, [_vm._v("Account Status")]),
    _c(
      "span",
      {
        staticClass:
          "inline-block rounded-full w-2/3 px-2 py-2 text-sm font-bold mt-2 ml-auto mr-auto text-center",
        class: _vm.account_status,
      },
      [_vm._v(_vm._s(_vm.account_status))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }