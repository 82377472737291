<template>
  <div>
    <!--<span>{{ lgaName }}</span>-->
  </div>
</template>

<script>
import { loadModules } from "esri-loader";

export default {
  name: "web-map",
  mounted() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/widgets/Sketch",
        "esri/Map",
        "esri/views/MapView",
        "esri/geometry/Point",
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Polygon",
        "esri/geometry/Polyline"
      ],
      {
        css: true
      }
    ).then(
      ([
        Sketch,
        //ArcGISMap,
        Map,
        MapView,
        Point,
        Graphic,
        GraphicsLayer,
        Polygon,
        Polyline
      ]) => {
        const layer = new GraphicsLayer();

        this.layer = layer;

        /*
        const map = new ArcGISMap({
          basemap: "topo-vector"
        });
        */

        // center to NSW
        var pt = new Point({
          x: 147.4, //146.9211,
          y: -32.2, //-31.2532,
          //x: 31.2532,
          //y: 146.9211,
          spatialReference: 4326
        });
        const map = new Map({
          basemap: "topo-vector",
          layers: [layer]
        });
        this.view = new MapView({
          container: this.$el,
          map: map,
          center: pt, //[-3889895.81, 16716752.75], // NSW = 31.2532° S, 146.9211° E // -118, 34
          zoom: 6
        });

        if (!this.readOnly) {
          const sketch = new Sketch({
            layer: layer,
            view: this.view,
            // graphic will be selected as soon as it is created
            creationMode: "single", // "single"|"continuous"|"update"
            availableCreateTools: this.availableCreateTools || [
              "polyline",
              "polygon"
            ], //["point", "polyline", "polygon", "rectangle", "circle"]
            defaultCreateOptions: {
              mode: "hybrid" // "hybrid"|"freehand"|"click"
            }
          });
          sketch.visibleElements = {
            visibleElements: {
              createTools: {
                point: false,
                circle: false
              },
              selectionTools: {
                "lasso-selection": false
              }
            }
          };

          sketch.on("create", this.onCreate);

          this.view.ui.add(sketch, "top-right");

          sketch.on("delete", this.onDelete);
        }
        //this.lgaBoundary;

        this.setLgaBoundary = function(rings) {
          const tempJson = {
            rings: rings,
            spatialReference: 3857
          };
          this.lgaBoundary = new Polygon(tempJson);

          this.view.goTo(this.lgaBoundary);
        };

        this.gotoPolyline = function(paths) {
          const tempJson = {
            paths: paths,
            spatialReference: 3857
          };

          this.view.goTo(new Polyline(tempJson));
        };

        this.gotoPolygon = function(rings) {
          const tempJson = {
            rings: rings,
            spatialReference: 3857
          };

          //this.view.goTo(new Polygon(tempJson));
        };

        this.drawPolygon = function(rings) {
          const tempJson = {
            rings: rings,
            spatialReference: 3857
          };

          this.view.goTo(new Polygon(tempJson));

          this.suburbObjects = {};
        };

        this.setSuburbBoundary = function(
          suburbName,
          rings,
          goToSuburb = true
        ) {
          const polygon = {
            type: "polygon",
            rings: rings,
            spatialReference: 3857
          };

          console.log("polygon");
          console.log(polygon);

          var simpleFillSymbol = {
            type: "simple-fill",
            color: [100, 139, 200, 0.2], // orange, opacity 80%
            outline: {
              color: [255, 255, 255],
              width: 1
            }
          };

          var polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: simpleFillSymbol
          });

          //var graphicsLayer = new GraphicsLayer();
          //map.add(graphicsLayer);

          //layer.add(polygonGraphic);
          this.view.graphics.add(polygonGraphic);

          //this.suburbGraphics.push(polygonGraphic);
          this.suburbMap.set(suburbName, polygonGraphic);

          if (goToSuburb) {
            console.log("here!");
            console.log([...this.suburbMap.values()]);
            this.view.goTo([...this.suburbMap.values()]);
          }
        };

        this.showBuffer = function(buffer, distance) {
          this.removeBuffer();
          var polygon = {
            type: "polygon",
            rings: buffer.geometries[0].rings,
            spatialReference: 3857
          };

          var simpleFillSymbol = {
            type: "simple-fill",
            color: [227, 139, 79, 0.1], // orange, opacity 80%
            outline: {
              color: [0, 0, 0],
              width: 1
            }
          };

          var polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: simpleFillSymbol
          });

          //var graphicsLayer = new GraphicsLayer();
          //map.add(graphicsLayer);

          //layer.clear();

          //layer.add(polygonGraphic);
          this.view.graphics.add(polygonGraphic);

          this.bufferGraphic = polygonGraphic;

          //var zoom = 12 - distance / 10000;
          //console.log(zoom);

          //this.view.zoom = zoom;

          this.view.goTo(polygonGraphic);
          this.view.zoom = this.view.zoom - 2;
        };

        this.removeBuffer = function() {
          if (this.bufferGraphic) {
            this.view.graphics.remove(this.bufferGraphic);
          }
          this.removeRoadLine();
        };

        this.addRoadToMap = function(geometry, goToRoad = false) {
          console.log("addRoadToMap", geometry);
          //var roadName = roadObject.attributes.roadnamestring;
          var paths = geometry.paths;

          const polyline = {
            type: "polyline",
            paths: paths,
            spatialReference: 3857
          };

          console.log("polyline");
          console.log(polyline);

          var lineSymbol = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [255, 0, 0, 0.4], // RGB color values as an array
            width: 4
          };

          var lineAtt = {
            Name: "Road", //roadName, // The name of the pipeline
            Owner: "TransCanada", // The owner of the pipeline
            Length: "3,456 km" // The length of the pipeline
          };

          var polylineGraphic = new Graphic({
            geometry: polyline, // Add the geometry created in step 4
            symbol: lineSymbol, // Add the symbol created in step 5
            attributes: lineAtt // Add the attributes created in step 6
          });

          // Add the graphic to the view's default graphics view
          // If adding multiple graphics, use addMany and pass in the array of graphics.

          this.removeRoadLine();
          this.roadLine = polylineGraphic;
          this.view.graphics.add(this.roadLine);

          if (goToRoad) {
            this.view.goTo(polylineGraphic);
          }
        };

        this.removeRoadLine = function() {
          if (this.roadLine) {
            this.view.graphics.remove(this.roadLine);
          }
        };

        this.addUserSketchToMap = function(goToRoad = false) {
          //console.log("addRoadToMap", geometry);
          //var roadName = roadObject.attributes.roadnamestring;

          var geometry = {};

          if (this.userGeometryType == "esriGeometryPolyline") {
            var paths = this.userSketch.paths;

            geometry = {
              type: "polyline",
              paths: paths,
              spatialReference: 3857
            };
          }

          if (this.userGeometryType == "esriGeometryPolygon") {
            var rings = this.userSketch.rings;

            geometry = {
              type: "polygon",
              rings: rings,
              spatialReference: 3857
            };
          }

          var lineSymbol = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [100, 100, 100, 0.9], // RGB color values as an array
            width: 2
          };

          var lineAtt = {
            Name: "Road", //roadName, // The name of the pipeline
            Owner: "TransCanada", // The owner of the pipeline
            Length: "3,456 km" // The length of the pipeline
          };

          var polylineGraphic = new Graphic({
            geometry: geometry, // Add the geometry created in step 4
            symbol: lineSymbol, // Add the symbol created in step 5
            attributes: lineAtt // Add the attributes created in step 6
          });

          // Add the graphic to the view's default graphics view
          // If adding multiple graphics, use addMany and pass in the array of graphics.

          this.removeRoadLine();
          this.origUserSketch = polylineGraphic;
          this.view.graphics.add(this.origUserSketch);

          if (goToRoad) {
            this.view.goTo(polylineGraphic);
          }
        };

        this.removeOrigUserSketch = function() {
          if (this.origUserSketch) {
            this.view.graphics.remove(this.origUserSketch);
          }
        };

        if (this.lgaName) {
          this.zoomToLga(this.lgaName);
        }
      }
    );

    var removeUndoRedo = function() {
      const undoRedoDiv = document.querySelector(
        ".esri-sketch__section:nth-child(3)"
      );
      if (undoRedoDiv) {
        undoRedoDiv.remove();
      }
    };

    // get rid of undo redo icons
    window.setTimeout(removeUndoRedo, 123);
    window.setTimeout(removeUndoRedo, 523);
    window.setTimeout(removeUndoRedo, 1023);
    window.setTimeout(removeUndoRedo, 2023);
    window.setTimeout(removeUndoRedo, 3023);
    window.setTimeout(removeUndoRedo, 4023);
  },
  beforeDestroy() {
    if (this.view) {
      // destroy the map view
      this.view.destroy();
    }
  },
  methods: {
    zoomToLga: async function(lgaName) {
      const params = {
        where: `lganame='${lgaName}'`,
        f: "json"
      };

      //console.log(params);

      const boundaryUrl =
        "https://portal.spatial.nsw.gov.au/server/rest/services/NSW_Administrative_Boundaries_Theme/FeatureServer/8/query";

      async function fetchLGABoundary() {
        const response = await fetch(boundaryUrl, {
          method: "post",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: new URLSearchParams(params).toString()
        });
        return await response.json();
      }

      await fetchLGABoundary().then(json => {
        const geometry = json.features[0].geometry;
        this.setLgaBoundary(geometry.rings);
      });
    },
    onCreate(event) {
      // check if the create event's state has changed to complete indicating
      // the graphic create operation is completed.
      if (event.state === "complete") {
        // check if there is already a user drawing and clear it!
        this.clearUserSketch();
        this.removeBuffer();
        this.removeOrigUserSketch();

        this.graphic = event.graphic;

        var geometry = event.graphic.geometry;

        console.log(event);

        if (geometry.type == "polyline") {
          const tempJson = {
            paths: geometry.paths,
            spatialReference: 3857
          };

          this.userSketch = tempJson;
          this.userGeometryType = "esriGeometryPolyline";

          console.log(geometry.paths);

          console.log(JSON.stringify(geometry.paths));
        } else if (geometry.type == "polygon") {
          const tempJson = {
            rings: geometry.rings,
            spatialReference: 3857
          };

          this.gotoPolygon(geometry.rings);

          this.userSketch = tempJson;
          this.userGeometryType = "esriGeometryPolygon";
        }

        this.$emit("draw-complete", {
          geometryType: this.userGeometryType,
          geometries: [this.userSketch]
        });

        console.log("USER STUFF!!!", this.userSketch, this.userGeometryType);
      }
    },
    onDelete() {
      this.$emit("draw-delete");
    },
    doShowRadius: async function() {
      if (this.distance == 0) {
        return;
      }
      //const distanceInMeters = this.distance.substring(0, 2) + "000";

      //console.log(this.getUserSketch());
      //for (const suburb of suburbs) {

      //newValue.forEach(this.showSuburb);

      // query to get suburb coordinates
      var url =
        "https://portal.spatial.nsw.gov.au/server/rest/services/Utilities/Geometry/GeometryServer/buffer";

      const params = {
        geometries: JSON.stringify({
          geometryType: this.userGeometryType,
          geometries: [this.userSketch]
        }),
        inSR: 3857,
        outSR: 3857,
        bufferSR: 7844,
        distances: this.distance,
        unit: 9001,
        unionResults: true,
        geodesic: true,
        f: "json"
      };

      async function fetchBufferedBoundary() {
        const response = await fetch(url, {
          method: "post",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: new URLSearchParams(params).toString()
        });
        return await response.json();
      }

      var t = await fetchBufferedBoundary().then(json => {
        return json;
      });

      const geometry = t;

      this.showBuffer(geometry, this.distance);

      //this.showRadius = false;
    },
    async showSuburb(suburb, goToSuburb = false) {
      var urlSuburb =
        "https://portal.spatial.nsw.gov.au/server/rest/services/NSW_Administrative_Boundaries_Theme/FeatureServer/2/query";

      const params2 = {
        where: `suburbname='${suburb.trim().toUpperCase()}'`,
        geometry: JSON.stringify(this.lgaBoundary),
        geometryType: "esriGeometryPolygon",
        returnGeometry: true,
        returnExtentOnly: false,
        f: "json"
      };

      async function fetchSuburbBoundary() {
        const response = await fetch(urlSuburb, {
          method: "post",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          body: new URLSearchParams(params2).toString()
        });
        return await response.json();
      }

      await fetchSuburbBoundary().then(json => {
        const geometry = json.features[0].geometry;
        this.setSuburbBoundary(suburb, geometry.rings, goToSuburb);
      });
    },
    clearUserSketch() {
      if (this.userSketch && this.graphic && this.graphic.layer) {
        //this.view.graphics.clear();
        this.graphic.layer.remove(this.graphic);
      }
    },
    clearRadius() {
      this.removeBuffer();
    },
    doShowRoad(geometry) {
      //var roadName = roadObject.attributes.roadnamestring;
      console.log("WebMap ShowRoad", geometry);
      this.addRoadToMap(geometry, true);
    },
    doShowUserSketch(geometry) {
      this.userSketch = geometry.geometries[0];
      this.userGeometryType = geometry.geometryType;
      //var roadName = roadObject.attributes.roadnamestring;
      console.log("WebMap ShowRoad", geometry);
      this.addUserSketchToMap(true);

      // show suburbs
      this.suburbs.map(s => this.showSuburb(s, false));
    },
    doShowSuburbs() {
      this.suburbs.map(s => this.showSuburb(s, true));
      //this.view.goTo([...this.suburbMap.values()]);
    },
    doClearAll() {
      this.clearUserSketch();
      this.removeBuffer();
      this.removeOrigUserSketch();
      // remove all suburbs
      /*
      if (this.suburbGraphics && Array.isArray(this.suburbGraphics)) {
        this.suburbGraphics.forEach(suburb => this.layer.remove(suburb));
      }
      this.suburbGraphics = [];
      */

      this.suburbMap.forEach(suburb => this.view.graphics.remove(suburb));
      this.suburbMap.clear();
    }
  },
  props: {
    lgaName: String,
    suburbs: Array,
    distance: Number,
    readOnly: Boolean,
    availableCreateTools: Array
    //showRadius: Boolean
    //userSketch: Object
  },
  data: function() {
    return {
      userSketch: null,
      userGeometryType: null,
      view: null,
      lgaBoundary: null,
      graphic: null,
      bufferGraphic: null,
      roadLine: null,
      suburbMap: new Map()
    };
  },
  watch: {
    suburbs: async function(newValue, oldValue = []) {
      console.log("SUBURBS!!!!", newValue);

      if (!newValue.length) {
        this.suburbMap.forEach(suburb => this.view.graphics.remove(suburb));
        this.suburbMap.clear();
        return;
      }

      const tmpArray = newValue.filter(function(obj) {
        return oldValue.indexOf(obj) == -1;
      });

      // if a suburb was removed
      if (!tmpArray.length) {
        const tmpArray2 = oldValue.filter(function(obj) {
          return newValue.indexOf(obj) == -1;
        });

        const trimmedSuburb2 = tmpArray2[0].trim().toUpperCase();

        //console.log(trimmedSuburb2 + " was removed!");

        const indexOfSuburb = oldValue.indexOf(trimmedSuburb2);
        //console.log(indexOfSuburb + " index!");

        /*
        this.layer.remove(this.suburbGraphics[indexOfSuburb]);

        this.suburbGraphics.splice(indexOfSuburb, 1);
        this.view.goTo(this.suburbGraphics);
        */

        this.view.graphics.remove(this.suburbMap.get(trimmedSuburb2));

        this.suburbMap.delete(trimmedSuburb2);

        this.view.goTo([...this.suburbMap.values()]);

        return;
      }

      const trimmedSuburb = tmpArray[0].trim();

      console.log(trimmedSuburb + " was added!");

      this.showSuburb(trimmedSuburb, true);
    },
    lgaName: async function(newValue) {
      if (!newValue.length) {
        return;
      }

      await this.zoomToLga(newValue);
    }
  }
};
</script>

<style scoped>
div {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>

<style>
.esri-sketch__section:nth-child(3) {
  display: none;
}
</style>
