<template>
  <FormContainer>
    <div class="text-normal ">
      <h1 class="pt-6 mb-10 text-center mt-4 text-title">Recover Password</h1>
      <p class="pb-6">
        A confirmation code has been sent to your email address, please enter it
        here.
      </p>
      <!-- email address-->
      <div class="mb-4">
        <label class="block text-black text-normal-bold mb-2" for="email"
          >Email Address</label
        >
        <input
          v-validate="'required|email'"
          class="w-full input-area"
          id="email"
          name="email"
          type="text"
          v-model="user"
          placeholder
        />
        <span class="text-red text-xs italic" v-show="errors.has('email')">
          {{ errors.first("email") }}
        </span>
      </div>
      <!-- recovery code -->
      <div class="mb-4">
        <label class="block text-black text-normal-bold mb-2" for="code"
          >Recovery code</label
        >
        <input
          v-validate="'required'"
          class="w-full input-area"
          data-cypress="code"
          id="code"
          name="code"
          type="text"
          v-model="code"
          placeholder
          data-vv-as="Recovery code"
        />
        <span class="text-red text-xs italic" v-show="errors.has('code')">
          {{ errors.first("code") }}
        </span>
      </div>
      <!-- passwords -->
      <div class="flex">
        <div class="mb-6 w-1/2 ">
          <label
            class="block text-black text-normal-bold mb-2"
            for="passwordInitial"
            >New Password</label
          >
          <input
            v-validate="'required|passwordPolicy'"
            class="w-full mb-3 input-area"
            data-cypress="password1"
            id="passwordInitial"
            name="Password"
            type="password"
            ref="passwordInitial"
            v-model="passwordInitial"
            placeholder
          />
          <span
            class="text-red-button font-medium text-xs"
            v-show="errors.has('Password')"
            >{{ errors.first("Password") }}</span
          >
        </div>
        <div class="mb-6 w-1/2 ml-4">
          <label
            class="block text-black text-normal-bold mb-2"
            for="passwordconfirm"
            >Password confirm</label
          >
          <input
            v-validate="'required|confirmed:passwordInitial'"
            class="w-full mb-3 input-area"
            data-cypress="password2"
            id="passwordconfirm"
            name="passwordconfirm"
            type="password"
            v-model="passwordconfirm"
            placeholder
          />
          <span
            class="text-red-button font-medium text-xs"
            v-show="errors.has('passwordconfirm')"
            >Passwords do not match.</span
          >
        </div>
      </div>
      <!-- password complexity response -->

      <div class="flex flex-grow self-stretch"></div>
      <!-- reset password auth -->
      <ButtonWithSpinner
        class="w-full mt-4 button-blue rounded focus:outline-none focus:shadow-outline"
        data-cypress="submit"
        type="button"
        :disabled="$wait.any || !isFormDirty || !isFormValid"
        :isSpinning="$wait.any"
        @click="resetPassword"
      >
        Submit
      </ButtonWithSpinner>

      <!-- return to home -->
      <router-link
        tag="button"
        class="w-full bg-white text-normal-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 text-blue-button bg-white mb-10"
        type="button"
        :to="{ name: 'Authentication' }"
      >
        Return to login page
      </router-link>
    </div>
  </FormContainer>
</template>

<script>
import * as cognito from "../../helpers/cognito.js";
import FormContainer from "./form-container";
import validatorMixin from "../../mixins/validator-field-bag-mixin";

export default {
  name: "PasswordReset",
  components: { FormContainer },
  mixins: [validatorMixin],
  props: ["email", "recoveryCode"],
  data() {
    return {
      user: decodeURIComponent(this.email),
      code: this.recoveryCode,
      passwordInitial: null,
      passwordconfirm: null,
      isPasswordComlexityResponse: false
    };
  },
  methods: {
    addError(msg, field = "email", id = 3) {
      this.errors.add({
        field: field,
        id: id,
        msg: msg
      });
    },
    async resetPassword() {
      this.$wait.start("resetting password");
      try {
        const response = this.$validator.validateAll();

        if (response) {
          await cognito.forgotPasswordSubmitToCognito(
            this.user,
            this.code,
            this.passwordconfirm
          );
          this.$router.push("/");
        }
      } catch (err) {
        switch (err.code) {
          case "InvalidPasswordException":
          case "InvalidParameterException":
            this.addError("Password is invalid", "Password");
            break;
          case "UserNotFoundException":
            this.addError("Unknown user", "email");
            break;
          case "CodeMismatchException":
            this.addError("Recovery code is incorrect", "code");
            break;
          case "ExpiredCodeException":
            this.addError(
              "Recovery Code has expired. Please request a new one",
              "code"
            );
            break;
          default:
            this.addError(err.message);
        }

        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        this.$wait.end("resetting password");
      }
    }
  }
};
</script>
