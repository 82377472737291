var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex flex-row text-normal mb-4 mt-4" }, [
        _c(
          "div",
          { staticClass: "w-1/2 pr-4" },
          [
            _vm._m(0),
            _c("norns-select", {
              directives: [{ name: "validate", rawName: "v-validate" }],
              staticClass: "w-full mr-4 norns-select",
              attrs: {
                disabled: !_vm.lgasArray || _vm.readOnly,
                multiple: true,
                options: _vm.lgasClean,
                searchable: true,
                "close-on-select": false,
                "select-on-tab": true,
                "show-labels": true,
                "hide-selected": true,
                placeholder: "Select an LGA(s)",
                name: "lga_name",
                "data-cypress": "lga-field",
                "data-vv-as": "Local Government Area(s)",
              },
              model: {
                value: _vm.lga_name,
                callback: function ($$v) {
                  _vm.lga_name = $$v
                },
                expression: "lga_name",
              },
            }),
            _c("FieldErrorMessage", {
              attrs: { message: _vm.errors.first("lga_name") },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pr-4 w-1/2" },
          [
            _vm._m(1),
            _c("norns-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.continues",
                  modifiers: { continues: true },
                },
              ],
              staticClass: "w-full norns-select",
              attrs: {
                disabled:
                  (_vm.readOnly && !_vm.isEditing) || !_vm.lga_name.length,
                multiple: true,
                options: _vm.affectedAddressLocalities,
                searchable: true,
                "close-on-select": false,
                "select-on-tab": true,
                "show-labels": true,
                "hide-selected": true,
                placeholder:
                  "Start typing and press enter to add a Locality...",
                name: "suburb",
                "data-cypress": "address-input",
                "data-vv-as": "Address Localities",
              },
              model: {
                value: _vm.suburb,
                callback: function ($$v) {
                  _vm.suburb = $$v
                },
                expression: "suburb",
              },
            }),
            _c("FieldErrorMessage", {
              attrs: {
                message: _vm.errors.first("address_localities_taglist"),
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "label",
        [
          _vm._m(2),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.geographical_name,
                expression: "geographical_name",
              },
            ],
            staticClass: "w-full mr-4 input-area",
            attrs: {
              type: "text",
              "data-cypress": "place-name-field",
              placeholder: "Enter Name Here...",
              name: "geographical_name",
              id: "geographical_name",
              "data-vv-as": "Proposed Geographical Name",
              disabled:
                _vm.readOnly || !_vm.lga_name.length || !_vm.suburb.length,
            },
            domProps: { value: _vm.geographical_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.geographical_name = $event.target.value
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("geographical_name") },
          }),
          _c("showLoading", {
            attrs: { isLoading: "evaluatingPlaceName" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    !!_vm.geographical_name &&
                    !!_vm.geographical_name.length &&
                    _vm.evaluation &&
                    !!_vm.evaluation.length
                      ? _c(
                          "div",
                          _vm._l(_vm.evaluation, function (issue, key) {
                            return _c("MessagePanel", {
                              key: key,
                              attrs: {
                                warning: "",
                                msg: issue.shortDescription,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-row text-normal mb-4 mt-4" }, [
        _c(
          "label",
          { staticClass: "pr-4 w-1/2" },
          [
            _c("p", { staticClass: "text-normal-bold mb-2" }, [
              _vm._v(" Parish "),
            ]),
            _c("taggableInput", {
              staticClass: "w-full mr-4",
              attrs: {
                tagList: _vm.parish,
                placeholder: `Start typing and press enter to add a Parish...`,
                datacypress: "parish-input",
                disabled: _vm.readOnly,
              },
              on: {
                addTag: (parish) =>
                  _vm.addParish({ key: "place_name", parish: parish }),
                removeTag: (index) =>
                  _vm.removeParish({ key: "place_name", index: index }),
              },
            }),
          ],
          1
        ),
        _c(
          "label",
          { staticClass: "w-1/2" },
          [
            _c("p", { staticClass: "text-normal-bold mb-2" }, [
              _vm._v(" County "),
            ]),
            _c("taggableInput", {
              staticClass: "w-full mr-4",
              attrs: {
                tagList: _vm.county,
                placeholder: `Start typing and press enter to add a County...`,
                datacypress: "county-input",
                disabled: _vm.readOnly,
              },
              on: {
                addTag: (county) =>
                  _vm.addCounty({ key: "place_name", county: county }),
                removeTag: (index) =>
                  _vm.removeCounty({ key: "place_name", index: index }),
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex flex-row text-normal mt-6 mb-4" }, [
        _c(
          "label",
          { staticClass: "w-1/2 pr-4" },
          [
            _c("p", { staticClass: "text-normal-bold mb-2" }, [
              _vm._v(" State Electorate "),
            ]),
            _c("taggableInput", {
              staticClass: "w-full mr-4",
              attrs: {
                tagList: _vm.electorate,
                placeholder: `Start typing and press enter to add an Electorate...`,
                datacypress: "state-input",
                disabled: _vm.readOnly,
              },
              on: {
                addTag: (electorate) =>
                  _vm.addElectorate({
                    key: "place_name",
                    electorate: electorate,
                  }),
                removeTag: (index) =>
                  _vm.removeElectorate({ key: "place_name", index: index }),
              },
            }),
          ],
          1
        ),
      ]),
      _vm.user && !_vm.user.isPublic
        ? [
            _c("p", { staticClass: "text-normal-bold mt-8" }, [
              _vm._v(" Is the proposal for a School or a National Park? "),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row text-normal" },
              [
                _c(
                  "RadioButton",
                  {
                    staticClass: "flex-0 mr-6 mt-3",
                    attrs: {
                      "data-cypress": "school_or_national_park-no",
                      name: "school_or_national_park-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.school_or_national_park,
                      callback: function ($$v) {
                        _vm.school_or_national_park = $$v
                      },
                      expression: "school_or_national_park",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    staticClass: "flex-0 mr-6 mt-3",
                    attrs: {
                      "data-cypress": "school_or_national_park-yes",
                      name: "school_or_national_park-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.school_or_national_park,
                      callback: function ($$v) {
                        _vm.school_or_national_park = $$v
                      },
                      expression: "school_or_national_park",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("label", { staticClass: "w-2/3 mt-4" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "w-full mt-3 flex flex-col" },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full h-48 input-area",
              attrs: {
                "data-cypress": "feature-extent-location-description-field",
                name: "description",
                "data-vv-as": "Description",
                disabled: _vm.readOnly,
              },
              domProps: { value: _vm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
            _c("FieldErrorMessage", {
              attrs: { message: _vm.errors.first("description") },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("uploadScoped", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min_value:1",
                expression: "'min_value:1'",
              },
            ],
            staticClass: "mt-8",
            attrs: {
              "data-cypress": "upload-map",
              label: "Attach Marked Map",
              important: true,
              files: _vm.marked_map_reference_document,
              drafted: true,
              stateKey: "place_name",
              stateProp: "marked_map_reference_document",
              uploadFile: _vm.uploadFile,
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: "place_name",
                  prop: "marked_map_reference_document",
                  index: fileIndex,
                }),
            },
          }),
          _vm.errors.first("fileUploader")
            ? _c(
                "div",
                { staticClass: "flex items-center absolute" },
                [
                  _c("errorIcon"),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-red-error text-normal-bold" },
                    [
                      _vm._v(
                        " No Marked Map was uploaded for this place name. Please upload required files "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("uploadScoped", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min_value:1",
                expression: "'min_value:1'",
              },
            ],
            staticClass: "mt-8",
            attrs: {
              "data-cypress": "upload-photo",
              label: "Attach Marked Photo",
              important: false,
              files: _vm.marked_photo_reference_document,
              drafted: true,
              stateKey: "place_name",
              stateProp: "marked_photo_reference_document",
              uploadFile: _vm.uploadFile,
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: "place_name",
                  prop: "marked_photo_reference_document",
                  index: fileIndex,
                }),
            },
          }),
          _vm.errors.first("fileUploader")
            ? _c(
                "div",
                { staticClass: "flex items-center absolute" },
                [
                  _c("errorIcon"),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-red-error text-normal-bold" },
                    [_vm._v(" No Photos were uploaded for this place name. ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("p", { staticClass: "text-normal-bold mb-2" }, [
        _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
        _vm._v("Local Government Area(s) "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("p", { staticClass: "text-normal-bold mb-2" }, [
        _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
        _vm._v("Address Locality/Suburb "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 pt-4" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Proposed Geographical Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-6" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Describe the feature and its extent "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }