import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=ff531c10"
import script from "./layout.vue?vue&type=script&lang=js"
export * from "./layout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ff531c10')) {
      api.createRecord('ff531c10', component.options)
    } else {
      api.reload('ff531c10', component.options)
    }
    module.hot.accept("./layout.vue?vue&type=template&id=ff531c10", function () {
      api.rerender('ff531c10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-form/local-newspaper/layout.vue"
export default component.exports