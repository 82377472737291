var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.proposalId }, [
    _c(
      "div",
      {
        staticClass:
          "flex flex-wrap justify-center items-center text-normal gb-grey-input",
      },
      [
        _c("div", { staticClass: "backgroundDiv w-screen h-screen fixed" }),
        _c("img", {
          staticClass: "fixed h-12 logo-position",
          attrs: {
            src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "w-2/3 bg-white mx-auto mt-48 z-10 rounded flex flex-col shadow-lg mb-12",
          },
          [
            _c("div", { staticClass: "w-9/10 mx-auto mb-8 flex flex-col" }, [
              _c("img", {
                staticClass: "h-16 self-center mt-20",
                attrs: {
                  src: require("../../../../assets/Logos/GNB_Logo_short.png"),
                },
              }),
              _vm.exists || _vm.proposalId == "create"
                ? _c(
                    "div",
                    { staticClass: "text-black mb-8" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "base-container font-bold text-center w-full text-3xl",
                        },
                        [_vm._v(" Submit a Place Name Proposal ")]
                      ),
                      _c("showLoading", {
                        attrs: { isLoading: "loading Proposal" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "component",
                              fn: function () {
                                return [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        appear: "",
                                        name: "fade",
                                        mode: "out-in",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { key: _vm.proposalId },
                                        [
                                          _c("Type", {
                                            attrs: {
                                              readOnly: _vm.readOnly,
                                              isAdmin: false,
                                              organisations: _vm.organisations,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("GeographicalDetails", {
                                            attrs: {
                                              proposalType: _vm.type,
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("Lot", {
                                            staticClass: "mt-12",
                                            attrs: {
                                              proposalType: _vm.type,
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("NameDetailsAndOrigin", {
                                            staticClass: "mt-12",
                                            attrs: {
                                              proposalType: _vm.type,
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("Checklist", {
                                            staticClass: "mt-12",
                                            attrs: {
                                              proposalType: _vm.type,
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("AdditionalInfo", {
                                            staticClass: "mt-12",
                                            attrs: {
                                              proposalType: _vm.type,
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("LocalNewspaper", {
                                            staticClass: "mt-12",
                                            attrs: {
                                              readOnly: _vm.readOnly,
                                              isPublic: true,
                                            },
                                          }),
                                          _c("ApplicantDetails", {
                                            ref: "applicantDetailsForm",
                                            staticClass: "mt-12",
                                            attrs: {
                                              readOnly: true,
                                              isPublic: true,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex-grow",
                                              attrs: {
                                                name: "personalInformationNoticeCollection",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex flex-grow",
                                                },
                                                [
                                                  _c(
                                                    "Checkbox",
                                                    {
                                                      staticClass:
                                                        "w-full pl-0",
                                                      model: {
                                                        value:
                                                          _vm.personalInformationCollectionNotice,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.personalInformationCollectionNotice =
                                                            $$v
                                                        },
                                                        expression:
                                                          "personalInformationCollectionNotice",
                                                      },
                                                    },
                                                    [
                                                      [
                                                        _vm._v(
                                                          " Please tick to acknowledge the "
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf",
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " GNB Personal Information Collection Notice "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex base-container",
                                              attrs: { name: "3 buttons" },
                                            },
                                            [
                                              _c(
                                                "ButtonWithSpinner",
                                                {
                                                  staticClass:
                                                    "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                                                  attrs: {
                                                    "data-cypress":
                                                      "delete-button",
                                                    disabled: _vm.$wait.any,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$router.go(-1)
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel Proposal")]
                                              ),
                                              _vm.showDeleteButton
                                                ? _c(
                                                    "ButtonWithSpinner",
                                                    {
                                                      staticClass:
                                                        "button-red-hollow flex justify-center w-2/6 mt-4 mb-10",
                                                      attrs: {
                                                        "data-cypress":
                                                          "delete-button",
                                                        isSpinning:
                                                          _vm.$wait.is(
                                                            "deletingProposal"
                                                          ),
                                                        disabled: _vm.$wait.any,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.deleteProposalById,
                                                      },
                                                    },
                                                    [_vm._v("Delete Proposal")]
                                                  )
                                                : _vm._e(),
                                              _vm.showSubmitButton
                                                ? _c(
                                                    "ButtonWithSpinner",
                                                    {
                                                      staticClass:
                                                        "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                                                      attrs: {
                                                        "data-cypress":
                                                          "submit-button",
                                                        isSpinning:
                                                          _vm.$wait.is(
                                                            "savingProposal"
                                                          ),
                                                        disabled:
                                                          _vm.$wait.any ||
                                                          !_vm.isFormValid ||
                                                          !_vm.personalInformationCollectionNotice ||
                                                          _vm.errors.items
                                                            .length > 0,
                                                      },
                                                      on: { click: _vm.save },
                                                    },
                                                    [_vm._v("Submit Proposal")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3497028178
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.exists && _vm.proposalId !== "create"
                ? _c(
                    "div",
                    { staticClass: "text-black mb-16 text-center mt-16" },
                    [
                      _c("h3", [
                        _vm._v(" no proposal found for proposal ID: "),
                        _c("b", [_vm._v(_vm._s(_vm.proposalId))]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }