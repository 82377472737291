var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "my-20 text-center text-grey text-normal-bold" }, [
      _c("div", [_c("Loading", { staticClass: "flex-none" })], 1),
      _vm._v(" Searching "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }