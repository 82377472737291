<template>
  <div>
    <entry :eventDate="entryData.date" cardTitle="Gazette Date">
      <div v-if="gazetteDate" class="mt-2">
        <p class="mb-2">The Gazette Date was set to:</p>
        <strong>{{ gazetteDate }}</strong>
      </div>
    </entry>
  </div>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";
import moment from "moment";

export default {
  name: "GazetteDateTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Entry
  },
  computed: {
    gazetteDate() {
      if (this.entryData.changes) {
        const gazetteDateField = this.entryData.changes["gazette date"];
        return gazetteDateField
          ? moment(gazetteDateField.to).format("DD MMM YYYY")
          : null;
      }
      return null;
    }
  }
};
</script>
