var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black mb-12" },
    [
      _c(
        "span",
        { staticClass: "flex items-center text-black mt-3" },
        [
          _c(
            "h1",
            {
              staticClass: "text-title",
              attrs: { "data-cypress": "header-text" },
            },
            [_vm._v(" Manage Organisations ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "ml-auto button-blue-hollow",
              attrs: {
                to: { name: "organisationsAdd" },
                "data-cypress": "addneworganisation-button",
              },
            },
            [_vm._v("Add New Organisation")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "w-full mt-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.queryParams.search,
              expression: "queryParams.search",
            },
          ],
          staticClass: "input-area bg-white w-full",
          attrs: {
            type: "text",
            placeholder: "Search by organisation name...",
          },
          domProps: { value: _vm.queryParams.search },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.queryParams, "search", $event.target.value)
              },
              _vm.searchOrganisations,
            ],
          },
        }),
      ]),
      _c("Loading-spinner", {
        directives: [
          {
            name: "wait",
            rawName: "v-wait:visible",
            value: "loadingOrg",
            expression: "'loadingOrg'",
            arg: "visible",
          },
        ],
        staticClass: "flex-0",
      }),
      _c("OrganisationList", {
        directives: [
          {
            name: "wait",
            rawName: "v-wait:hidden",
            value: "loadingOrg",
            expression: "'loadingOrg'",
            arg: "hidden",
          },
        ],
        attrs: { organisations: _vm.organisations.orgs },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ organisation }) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-12 h-full flex-auto flex flex-col content-around items-center",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                        attrs: {
                          "data-cypress": "edit-organisation-link",
                          to: {
                            name: "organisationsEdit",
                            params: {
                              identifier: organisation.identifier,
                            },
                          },
                          title: `Edit ${organisation.organisation_name} details`,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "flex-auto",
                          attrs: { icon: "edit" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                        attrs: {
                          to: {
                            name: "administerUsers",
                            query: { organisation_id: organisation.id },
                          },
                          "data-cypress": "users-organisation-link",
                          title: `Manage ${organisation.organisation_name}'s users`,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "flex-auto",
                          attrs: { icon: "users" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button",
                        attrs: {
                          to: {
                            name: "placeNameProposals",
                            query: { organisation_id: organisation.id },
                          },
                          "data-cypress": "proposals-organisation-link",
                          title: `View ${organisation.organisation_name}'s proposals`,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "flex-auto",
                          attrs: { icon: "tree" },
                        }),
                      ],
                      1
                    ),
                    organisation.organisation_status == "approved"
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "flex-auto flex justify-center items-center text-red font-bold h-1/4 w-full action-button suspend-user",
                            attrs: {
                              title: `Suspend ${organisation.organisation_name}`,
                              "data-cypress": "suspend-button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmOrganisation(organisation)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "flex-auto",
                              attrs: { icon: "lock" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    organisation.organisation_status != "approved"
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "flex-auto flex justify-center items-center text-green font-bold h-1/4 w-full action-button activate-user",
                            attrs: {
                              title: `Approve ${organisation.organisation_name}`,
                              "data-cypress": "suspend-button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmOrganisation(organisation)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "flex-auto",
                              attrs: { icon: "check" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      !_vm.$wait.is(`loadingOrg`) &&
      _vm.organisations &&
      !_vm.organisations.count
        ? _c(
            "div",
            {
              staticClass:
                "self-center justify-center flex mt-32 text-black font-bold text-2xl w-full",
            },
            [_vm._v(" No Organisations Found ")]
          )
        : _vm._e(),
      !_vm.$wait.is(`loadingOrg`) &&
      _vm.organisations &&
      _vm.organisations.count
        ? _c(
            "div",
            { staticClass: "mx-auto text-center self-center" },
            [
              _c("paginate", {
                attrs: {
                  "page-count": Math.ceil(
                    _vm.organisations.count / _vm.queryParams.limit
                  ),
                  "page-range": 10,
                  "margin-pages": 1,
                  "click-handler": _vm.clickCallback,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class": "pagination",
                  "page-class": "page-item",
                },
                model: {
                  value: _vm.queryParams.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "page", $$v)
                  },
                  expression: "queryParams.page",
                },
              }),
              _c("p", [
                _vm._v(
                  " Showing " +
                    _vm._s(
                      (
                        (_vm.queryParams.page - 1) * _vm.queryParams.limit +
                        1
                      ).toLocaleString()
                    ) +
                    " to " +
                    _vm._s(
                      Math.min(
                        (_vm.queryParams.page - 1) * _vm.queryParams.limit +
                          _vm.queryParams.limit,
                        _vm.organisations.count
                      ).toLocaleString()
                    ) +
                    " of " +
                    _vm._s(_vm.organisations.count.toLocaleString()) +
                    " organisations "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }