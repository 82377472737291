"use strict";

export const VIEW_MODE_PNP = "pnp";
export const VIEW_MODE_RNP = "rnp";

const state = {
  updateReady: false,
  viewMode: VIEW_MODE_PNP,
  loginModal: false
};

const getters = {
  isUpdateReady: state => state.updateReady,
  viewMode: state => state.viewMode,
  isRNPView: state => state.viewMode === VIEW_MODE_RNP,
  isPNPView: state => state.viewMode === VIEW_MODE_PNP,
  showLoginModal: state => state.loginModal
};

const actions = {
  async NEW_APP_CONTENT({ commit }, payload) {
    commit("setRefresh", payload.updateReady);
    payload.cb();
  }
};

const mutations = {
  setRefresh(state, value) {
    state.updateReady = value;
  },
  viewRNP(state) {
    state.viewMode = VIEW_MODE_RNP;
  },
  viewPNP(state) {
    state.viewMode = VIEW_MODE_PNP;
  },
  updateLoginModalStatus(state, value) {
    state.loginModal = value === true;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
