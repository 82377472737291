<template>
  <div class="flex text-sm" :class="classNames">
    <label class="pt-3 pr-3 items-center content-center flex">
      <div class="cont w-6 h-6 mr-3 flex justify-center absolute" tabindex="0">
        <input
          type="radio"
          v-model="$attrs.value"
          :value="val"
          name="radios"
          @click="$emit('input', val)"
          class="hidden"
          :disabled="$attrs.disabled"
        />
        <div class="inner w-3 h-3 self-center"></div>
      </div>

      <div class="ml-8">{{ label }}</div>
    </label>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.val;
    }
  },
  props: {
    label: {
      type: String
    },
    val: {
      type: String
    },
    classNames: {
      type: String
    }
  }
};
</script>

<style scoped>
.cont {
  border: 2px solid #002664;
  border-radius: 99999px;
}
.inner {
  border-radius: 99999px;
}
.cont input:checked ~ .inner {
  background-color: #002664 !important;
}
.cont input:hover ~ .inner {
  background-color: #00266477;
}
.cont input:disabled {
  border: 2px solid #4c5461;
  border-radius: 99999px;
}
.cont input:disabled ~ .inner {
  background-color: #8b8b8b6c;
}
</style>
