<template>
  <router-link
    :to="{
      name: 'currentPlaceNameProposal',
      params: { advertisementId: proposal.advertisement_identifier }
    }"
  >
    View Proposal
  </router-link>
</template>

<script>
export default {
  name: "EditActionComponent",

  /**
   * Instance props
   */
  props: {
    proposal: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>
