import { render, staticRenderFns } from "./minister-decision.vue?vue&type=template&id=47fe8a54&"
import script from "./minister-decision.vue?vue&type=script&lang=js&"
export * from "./minister-decision.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47fe8a54')) {
      api.createRecord('47fe8a54', component.options)
    } else {
      api.reload('47fe8a54', component.options)
    }
    module.hot.accept("./minister-decision.vue?vue&type=template&id=47fe8a54&", function () {
      api.rerender('47fe8a54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/audit-trail/entries/minister-decision.vue"
export default component.exports