<template>
  <div class="w-full flex bg-white text-black rounded-lg pl-8 mt-4">
    <formalisedDateModal :road="road" :proposalId="proposalId" />
    <div class="self-center flex h-full -ml-3 w-18">
      <!-- TODO Apply styling from proposalIcon to these icons -->
      <proposalIcon
        :label="road.roadname_classification"
        classNames="w-12 h-12"
      />
    </div>
    <div class="self-center w-1/5 mr-8 break-words">
      <h3>{{ road.roadname }} {{ road.road_type }}</h3>
    </div>
    <div class="dividing-line-v pl-4 mr-4 w-1/2">
      <div class="flex">
        <div class="w-1/2">
          <p class="text-label pt-5 pb-3">Road Location</p>
          <div class="pb-3">
            <p
              class="pb-1"
              v-for="(sub, index) in road.suburb_name"
              v-bind:key="index"
            >
              {{ sub }}
            </p>
          </div>
        </div>
        <div class="w-1/2 flex flex-col justify-center">
          <span class="flex-0 text-label pt-5 self-center"
            >Proposal status</span
          >
          <StatusButton
            :label="road.name_status"
            :status="road.name_status"
            :extra="finalisedDate"
            classNames="font-bold mb-6"
          />
        </div>
      </div>
      <div class="flex">
        <div
          class="second-row w-1/2"
          :class="{ invisible: road.issue_count === null }"
        >
          <p class="text-label">Road Name Validity</p>
          <span class="text-black text-sub-normal-bold"
            >{{ road.issue_count }} Potential issues found</span
          >
        </div>
        <div
          class="second-row w-1/2 text-center"
          :class="{ invisible: !user.isAdmin }"
        >
          <p class="text-label">Naming System</p>
          <span class="text-black font-semibold">{{ road.naming_system }}</span>
        </div>
      </div>
    </div>

    <!-- actions -->

    <div class="dividing-line-v pl-5 w-3/10 justify-end">
      <p class="pt-5 pb-2 text-right pr-3 text-label">Actions</p>
      <div class="items-center text-right pr-3 justify-start">
        <div v-if="allowEdit">
          <router-link
            :to="{
              name: 'roadNameProposalEdit',
              params: { proposalId, roadNameId: road.identifier }
            }"
            class="text-normal font-bold text-blue-button"
          >
            Edit Road
          </router-link>
        </div>
        <div v-if="!allowEdit">
          <router-link
            :to="{
              name: 'roadNameProposalView',
              params: { proposalId, roadNameId: road.identifier }
            }"
            class="text-normal font-bold text-blue-button"
          >
            View
          </router-link>
        </div>
        <div v-if="isGazetted">
          <router-link
            :to="{
              name: 'erratumGazettalNotice',
              params: {
                proposalId,
                roadNameId: road.identifier
              }
            }"
            class="text-normal font-bold text-blue-button whitespace-no-wrap"
            @click.native="resetErratumGazette"
            >Create Erratum Gazette</router-link
          >
        </div>
        <template v-if="!this.user.isASP">
          <div v-if="isPublicApprovedProposal">
            <div
              v-if="road.is_gazette_allowed === false"
              class="text-blue-button"
            >
              Gazette available {{ dateGazetteAllowed }}
            </div>
            <div v-else>
              <router-link
                :to="{
                  name: 'gazettalNotice',
                  params: {
                    proposalId,
                    roadNameId: road.identifier
                  }
                }"
                class="text-normal font-bold text-blue-button whitespace-no-wrap"
                >Create Gazettal</router-link
              >
            </div>
          </div>
          <div v-if="showReGazetteLink">
            <router-link
              :to="{
                name: 'reGazette',
                params: {
                  proposalId,
                  roadNameId: road.identifier
                }
              }"
              class="text-normal font-bold text-blue-button whitespace-no-wrap"
              >Re-gazette</router-link
            >
          </div>
          <div v-if="showConvertToNonDedicatedLink">
            <button
              v-if="road.in_jira === false"
              @click="showConvertToNonDedicated(road)"
              class="text-normal font-bold text-blue-button whitespace-no-wrap"
            >
              Convert to Non-Dedicated
            </button>
          </div>
          <div v-if="showFormaliseDateEntry">
            <div
              v-if="road.is_formalisation_allowed === false"
              class="text-blue-button"
            >
              Formalisation available {{ dateFormalisationAllowed }}
            </div>
            <button
              v-else
              @click="showEdit(road.identifier)"
              class="text-normal font-bold text-blue-button"
            >
              {{
                road.date_endorsed
                  ? "Edit Formalise Date"
                  : "Add Formalise Date"
              }}
            </button>
          </div>
          <div
            v-else-if="
              isRejected &&
                road.roadname_classification.toLowerCase() !== 'pre-approval'
            "
          >
            <router-link
              :to="{
                name: 'appeal',
                params: {
                  proposalId: proposalId,
                  roadNameId: road.identifier
                }
              }"
              class="text-normal font-bold text-blue-button whitespace-no-wrap"
              >Option to Appeal</router-link
            >
          </div>
          <div v-else-if="canSeekClarification">
            <router-link
              :to="{
                name: 'roadSeekClarification',
                params: {
                  proposalId: proposalId,
                  roadNameId: road.identifier
                }
              }"
              class="text-normal font-bold text-blue-button whitespace-no-wrap"
              >Seek Clarification</router-link
            >
          </div>
          <div v-else-if="needsClarification">
            <router-link
              :to="{
                name: 'clarifyProposal',
                params: {
                  proposalId: proposalId,
                  roadNameId: road.identifier
                }
              }"
              class="text-normal font-bold text-blue-button whitespace-no-wrap"
              >Respond to Query</router-link
            >
          </div>
          <div v-if="isPreApprovedApprovedProposal">
            <router-link
              :to="{
                name: 'roadNameProposalEdit',
                params: { proposalId, roadNameId: road.identifier }
              }"
              class="text-normal font-bold text-blue-button"
              >Formalise/Gazette</router-link
            >
          </div>
          <div v-if="canWithdraw" class="ml-2">
            <a
              class="text-red-button text-normal font-bold cursor-pointer"
              @click="showWithdraw(road)"
              >Withdraw</a
            >
          </div>
        </template>
        <div>
          <router-link
            :to="{
              name: 'roadNameProposalAuditTrail',
              params: {
                proposalId,
                roadNameId: road.identifier
              }
            }"
            class="text-normal font-bold text-blue-button"
            >View Audit/Records</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import proposalIcon from "../proposalIcon";
import moment from "moment";

import formalisedDateModal from "./formalised-date-modal";
import { createNamespacedHelpers, mapMutations } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  props: ["road", "proposalId", "showWithdraw", "showConvertToNonDedicated"],
  data() {
    return {
      finalProposalStatus: [
        "rejected",
        "rejected on appeal",
        "drafted",
        "verified",
        "endorsed",
        "modification",
        "approved and archived",
        "discontinued",
        "gazetted",
        "concurred",
        "pending gazette",
        "pending endorsement",
        "withdrawn"
      ]
    };
  },
  components: {
    formalisedDateModal,
    proposalIcon
  },
  methods: {
    ...mapMutations(["resetErratumGazette"]),
    showEdit(id) {
      this.$modal.show(id);
    }
  },
  computed: {
    allowEdit() {
      return (
        (this.road.name_status === "Proposed" ||
          this.road.name_status === "Clarification") &&
        !this.user.isASP
      );
    },
    isGazetted() {
      return this.road.name_status === "Gazetted";
    },
    isPublicApprovedProposal() {
      const status = this.road.name_status.toLowerCase();
      const classification = this.road.roadname_classification.toLowerCase();
      return (
        (status === "approved" ||
          status === "approved on appeal" ||
          status === "concurred") &&
        classification === "public"
      );
    },
    isPreApprovedApprovedProposal() {
      const status = this.road.name_status.toLowerCase();
      // const classification = this.road.roadname_classification.toLowerCase();
      // return status === "pre-approved" && classification === "pre-approved";
      return status === "pre-approved";
    },
    showFormaliseDateEntry() {
      const status = this.road.name_status.toLowerCase();
      const classification = this.road.roadname_classification.toLowerCase();
      return (
        (status === "approved" ||
          status === "approved on appeal" ||
          status === "pending endorsement") &&
        (classification === "private" || classification === "non-dedicated")
      );
    },
    showConvertToNonDedicatedLink() {
      return (
        this.road.roadname_classification.toLowerCase() === "public" &&
        this.user.isAdmin &&
        this.road.name_status === "Approved"
      );
    },
    showReGazetteLink(){
      return (
        this.road.roadname_classification.toLowerCase() === "public" &&
        !this.user.isASP &&
        this.road.name_status === "Gazetted"
      );
    },
    finalisedDate() {
      if (this.road.date_gazetted) {
        return moment(this.road.date_gazetted).format("DD/MM/YYYY");
      }
      if (this.road.date_endorsed) {
        return moment(this.road.date_endorsed).format("DD/MM/YYYY");
      }

      return null;
    },
    dateGazetteAllowed() {
      if (this.road.date_gazette_allowed) {
        return moment(this.road.date_gazette_allowed).format("D MMM YYYY");
      }
      return null;
    },
    dateFormalisationAllowed() {
      if (this.road.date_formalisation_allowed) {
        return moment(this.road.date_formalisation_allowed).format(
          "D MMM YYYY"
        );
      }
      return null;
    },
    canWithdraw() {
      return (
        this.finalProposalStatus.indexOf(
          this.road.name_status.toLowerCase()
        ) === -1
      );
    },
    isRejected() {
      return this.road.name_status.toLowerCase() === "rejected";
    },
    canSeekClarification() {
      return (
        this.road.name_status.toLowerCase() === "under review" &&
        this.user.isAdmin
      );
    },
    needsClarification() {
      return this.road.name_status.toLowerCase() === "clarification";
    },
    ...mapUserGetters(["user"])
  }
};
</script>

<style scoped>
.second-row {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
</style>
