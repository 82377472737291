// types
export const PROPOSAL_TYPE_PLACE = "PLACENAME";
export const PROPOSAL_TYPE_BOUNDARY = "BOUNDARY";
export const PROPOSAL_TYPE_LOCALITY = "LOCALITY";

export const GEONAME_TYPE_LABELS = [
  {
    type: "ANY",
    label: "Any Proposal Type"
  },
  {
    type: "PLACENAME",
    label: "Place Name Proposal"
  },
  {
    type: "LOCALITY",
    label: "New/Amended Address Locality Name"
  },
  {
    type: "BOUNDARY",
    label: "Address Locality Boundary Amendment"
  }
];
