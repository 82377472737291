var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.user
    ? _c("div", { staticClass: "pl-4 flex flex-auto flex-row list-item" }, [
        _c(
          "div",
          {
            staticClass:
              "flex sm:flex-col sm:items-start flex-no-grow w-1/4 py-4",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex-auto flex justify-between content-between items-center w-full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "sm:hidden xl:flex w-12 h-12 rounded-full mr-4 bg-grey-lighter justify-center items-center flex-none",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-none" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-2xl",
                          attrs: { icon: "user" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow flex flex-col justify-start items-start",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "flex-auto pr-2 font-bold cursor-pointer",
                        attrs: {
                          tag: "p",
                          "data-cypress": "user-name-link",
                          to: {
                            name: "profile/view",
                            params: { id: _vm.user.email },
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.name))]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-base",
                        attrs: { "data-cypress": "user-authority-name" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.user.authority_name ||
                                _vm.user.organisation_name
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "dividing-line-v-2 mx-4" }),
        _c(
          "div",
          {
            staticClass:
              "py-4 flex-grow flex sm:flex-row flex-wrap items-stretch justify-around content-start",
          },
          [
            _c("div", { staticClass: "flex-1 flex flex-col justify-start" }, [
              _c("div", { staticClass: "flex-auto text-label" }, [
                _c("span", [_vm._v("Contact")]),
                _c(
                  "div",
                  { staticClass: "flex-no-grow flex flex-col break-all" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-black pb-2 pr-4 flex-1 flex align-center",
                        attrs: {
                          "data-cypress": "user-email-link",
                          title: _vm.user.email,
                          href: _vm.mailLink,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "pr-2 text-2xl flex-none",
                          attrs: { icon: "envelope" },
                        }),
                        _vm._v(" " + _vm._s(_vm.user.email) + " "),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "flex-1 flex align-center",
                        attrs: {
                          "data-cypress": "user-telephone-link",
                          href: _vm.telLink,
                          rel: "tel",
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "pr-2 text-2xl flex-none",
                          attrs: { icon: "phone" },
                        }),
                        _c(
                          "span",
                          { staticClass: "telephone-link flex-none" },
                          [_vm._v(_vm._s(_vm.user.phone_no))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "UserProperty",
              {
                staticClass:
                  "flex-none flex flex-col justify-around items-center",
                attrs: { name: "User Type" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex flex-col",
                    attrs: { "data-cypress": "user-type" },
                  },
                  _vm._l(_vm.accountType, function (type) {
                    return _c(
                      "div",
                      {
                        key: type,
                        staticClass:
                          "account-type flex-1 flex flex-row justify-between text-black",
                      },
                      [
                        type === "PNP"
                          ? _c("Tree", {
                              staticClass: "flex-none text-black w-6 h-6 pr-2",
                            })
                          : _vm._e(),
                        type === "RNP"
                          ? _c("Road", {
                              staticClass:
                                "flex-none menu-img text-black w-6 h-6 pr-2",
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "flex-auto" }, [
                          _vm._v(_vm._s(type)),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
            _c("UserProperty", {
              staticClass: "flex-1 flex flex-col justify-around items-center",
              attrs: {
                name: "Date Created",
                value: _vm.dateCreated,
                "data-cypress": "date-created",
              },
            }),
            _c("div", { staticClass: "flex-0 flex justify-end" }, [
              _c(
                "div",
                { staticClass: "flex-1 flex items-center justify-center" },
                [
                  _c("AccountStatus", {
                    attrs: {
                      account_status: _vm.user.user_account_status,
                      "data-cypress": "user-account",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "dividing-line-v-2 ml-4" }),
        _c(
          "div",
          {
            staticClass:
              "flex-no-grow flex flex-col sm:items-end sm:justify-center",
          },
          [_vm._t("default")],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }