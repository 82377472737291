var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white mb-5 p-5" },
    [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "w-2/5" }, [
          _c("div", { staticClass: "text-label" }, [_vm._v(" Road Name ")]),
          _c("div", { staticClass: "font-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.road.roadname) +
                " " +
                _vm._s(_vm.road.road_type) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "w-2/5" }, [
          _c("div", { staticClass: "text-label" }, [_vm._v(" Locality ")]),
          _c("div", [
            _vm._v(
              " " +
                _vm._s(_vm.road.suburbs && _vm.road.suburbs.join(", ")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "w-1/5 flex flex-row-reverse" }, [
          _c(
            "div",
            {
              ref: "collapse",
              staticClass:
                "rotate-transition text-blue-darker self-center pr-4",
              class: [_vm.collapsed ? "" : "rotate-chevron"],
              on: {
                click: function ($event) {
                  _vm.collapsed = !_vm.collapsed
                },
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "chevron-circle-down" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "transition",
        {
          attrs: { name: "collapse" },
          on: {
            enter: function ($event) {
              return _vm.beforeEnterCollapse()
            },
            leave: function ($event) {
              return _vm.beforeLeaveCollapse()
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.collapsed,
                  expression: "!collapsed",
                },
              ],
              ref: "collapse-body",
              staticClass: "mt-4",
            },
            [
              _c("div", { staticClass: "text-label" }, [_vm._v(" Extent ")]),
              _c("div", [_vm._v(" " + _vm._s(_vm.road.extent) + " ")]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }