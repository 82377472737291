<template>
  <div class="base-container text-black">
    <section class="justify-center block">
      <div class="flex mb-3">
        <a
          @click="
            $router.push({
              name: 'proposals'
            })
          "
          class="cursor-pointer flex self-center"
          data-cypress="back-arrow"
        >
          <BackArrowWithTitle title="Bulk Gazette" />
        </a>
        <div class="ml-auto self-end text-sm text-right">
          <div class="px-4" v-if="!this.$wait.is('fetching roads')">
            {{ selectedRoads.length }} proposals selected
          </div>
        </div>
      </div>
    </section>
    <section class="mb-8">
      <div>
        <div class="flex" v-for="road in roadNames" :key="road.identifier">
          <bulk-gazette-item :road="road" class="w-9/10" />
          <div class="pt-5">
            <Checkbox
              label="Gazette?"
              @input="e => toggleSelectRoadsForGazette({ value: e, road })"
              :value="road.selected"
            ></Checkbox>
          </div>
        </div>
        <networkError v-if="error" />
        <showLoading :isLoading="'fetching roads'">
          <template v-slot:component>
            <div class="text-center">
              <button class="button-blue" @click="fetchRoads" v-if="loadMore">
                View More Roads
              </button>
            </div>
          </template>
        </showLoading>
        <div v-if="!initialLoad">
          <uploadComponent
            label="Attach Relevant Image (Optional)"
            :drafted="true"
            :deleteFile="deleteFunction"
            :disabled="disabled"
          />
          <p class="text-normal-bold mb-2">
            <a class="text-red-star">*</a>Public Comment
          </p>
          <Checkbox
            class="w-full"
            label="These road name proposals have been advertised for public comment."
            v-model="advertisedComment"
            name="Public Comment"
          ></Checkbox>
          <div class="flex mt-16">
            <a
              @click="$router.push({ name: 'proposals' })"
              class="button-red-hollow flex-no-grow"
              >Cancel</a
            >
            <button
              :disabled="!canContinue"
              class="button-blue ml-auto"
              @click="gazetteMultipleProposals"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchRoadNamesForBulkGazette } from "@/helpers/rnpCRUD";
import { deleteSupportingDocument } from "@/helpers/s3";
import bulkGazetteItem from "./bulkGazetteItem";
import { mapMutations } from "vuex";

export default {
  components: {
    bulkGazetteItem
  },
  data() {
    return {
      staticParams: {
        limit: 100
      },
      page: 1,
      numSelected: 0,
      loadMore: false,
      advertisedComment: false,
      error: false,
      initialLoad: true
    };
  },
  computed: {
    selectedRoads() {
      return this.roadNames.filter(rn => rn.selected);
    },
    uploadedFiles() {
      return this.$store.getters.uploadedFilesState;
    },
    disabled() {
      return this.selectedRoads.length === 0 || this.$wait.any;
    },
    canContinue() {
      return !this.disabled && this.advertisedComment;
    },
    roadNames() {
      return this.$store.getters.getRoadsForGazette;
    }
  },
  methods: {
    fetchRoadNamesForBulkGazette,
    ...mapMutations([
      "clearUploadState",
      "setRoadsToBulkGazette",
      "toggleSelectRoadsForGazette"
    ]),
    async uploadFileForComponent(payload) {
      const s3FileData = await this.uploadFile(
        Object.assign({}, payload, {
          componentOnly: true
        })
      );
      if (s3FileData) {
        this.uploadedFiles.push(s3FileData);
      }
    },
    deleteFunction: deleteSupportingDocument,
    async gazetteMultipleProposals() {
      const uploads = this.uploadedFiles;
      await this.clearUploadState();
      this.$router.push({
        name: "gazettalPreview",
        params: {
          roads: this.selectedRoads,
          proposalId: "bulk",
          roadNameId: "bulk",
          uploads
        }
      });
    },
    async fetchRoads() {
      this.$wait.start("fetching roads");
      let roadnames;
      try {
        roadnames = await this.fetchRoadNamesForBulkGazette(
          { ...this.staticParams, page: this.page },
          false
        );
      } catch {
        this.error = true;
        return;
      }
      this.error = false;
      roadnames.proposals.forEach(rn => {
        rn.selected = false;
        rn.suburb_name = rn.suburbs;
      });
      if (this.roadNames.length < roadnames.meta.totalProposals) {
        this.setRoadsToBulkGazette(this.roadNames.concat(roadnames.proposals));
      }
      if (this.roadNames.length < roadnames.meta.totalProposals) {
        this.page = this.page + 1;
        this.loadMore = true;
      } else {
        this.loadMore = false;
      }
      this.$wait.end("fetching roads");
    }
  },
  async created() {},
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (from.name !== "gazettalPreview") {
        vm.setRoadsToBulkGazette([]);
        await vm.fetchRoads();
      } else {
        vm.page = Math.floor(vm.roadNames.length / vm.staticParams.limit) + 1;
        vm.loadMore = true;
      }
      vm.initialLoad = false;
    });
  }
};
</script>
