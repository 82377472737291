<template>
  <div>
    <loader v-if="$wait.is('processingLink')" text="Linking placename..." />
  </div>
</template>

<script>
import Loader from "./Loader";
import { mapActions, createNamespacedHelpers } from "vuex";

const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

export default {
  name: "ProcessConfirmedLinkage",
  /**
   * Instance properties
   */
  props: {
    /**
     * Which placename are we linking to
     */
    source: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * Which placename are we linking the source with
     */
    target: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    Loader
  },

  /**
   * The instance was created
   */
  async created() {
    this.$wait.start("processingLink");

    await this.linkPlacename({
      source: this.source,
      target: this.target
    });

    this.$wait.end("processingLink");
    this.$wait.start("completeProcess");
  },

  /**
   * Instance methods
   */
  methods: {
    ...mapPnpActions(["linkPlacename"])
  }
};
</script>
