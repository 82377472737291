<template>
  <div
    class="w-full h-screen overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <div class="bg-white my-3 rounded">
      <showLoading isLoading="fetchingSubmittedProposalWithComment">
        <template v-slot:component>
          <div
            v-if="proposal"
            class="w-full bg-white text-black text-center rounded-lg p-14"
          >
            <h3 class="pb-3 mb-4 w-full text-title">
              The comment for {{ proposal.geographical_name }} has been
              submitted
            </h3>
            <p>
              These comments will be considered in the development of the
              proposal. Once finalised, the outcome of the proposal may be
              viewed on the GNB website.
            </p>
            <div class="flex justify-center mt-8 mb-8">
              <Button
                class="button-blue-hollow  mr-3"
                :disabled="false"
                @click="returnToProposals()"
                >Return to Place Name Proposals
              </Button>
            </div>
          </div>
        </template>
      </showLoading>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";

import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "commentSubmittedInternal",
  data() {
    return {
      proposal: null
    };
  },
  async created() {
    await this.fetchProposal();
  },
  computed: {
    proposalId() {
      return this.$route.params.proposalId;
    }
  },
  methods: {
    returnToProposals() {
      this.$router.push({ name: "placeNameProposals" });
    },
    fetchProposal: waitFor(
      "fetchingSubmittedProposalWithComment",
      async function() {
        try {
          const proposal = await pnpCRUD.getAdvertisedProposal(this.proposalId);
          this.proposal = proposal;
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Unable to retrieve proposal",
            text: error
          });
        }
      }
    )
  }
};
</script>
