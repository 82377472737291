var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black text-normal" },
    [
      _c("section", { staticClass: "pb-5 justify-center w-full block" }, [
        _c(
          "div",
          { staticClass: "flex justify-between" },
          [
            _c(
              "router-link",
              {
                staticClass: "flex justify-between mr-4 self-center",
                attrs: {
                  to: { name: "organisationsManage" },
                  "data-cypress": "goback-arrow",
                },
              },
              [
                _c("BackArrowWithTitle", {
                  attrs: {
                    title:
                      "View Organisation - " +
                      _vm.organisation.organisation_name,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "bg-white w-full rounded-lg flex h-26 items-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "sm:hidden xl:flex w-12 h-12 rounded-full ml-6 bg-grey-lighter justify-center items-center flex-none",
            },
            [
              _c(
                "div",
                { staticClass: "flex-none" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-2xl",
                    attrs: { icon: "road" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-1/3 flex flex-wrap content-between ml-2 pl-4 py-8",
            },
            [
              _c("h3", { staticClass: "w-full" }, [
                _vm._v(_vm._s(_vm.organisation.organisation_name)),
              ]),
            ]
          ),
          _c("div", { staticClass: "h-full dividing-line-v" }),
          _c("div", { staticClass: "w-2/3 flex ml-6 h-full content-center" }, [
            _c("div", { staticClass: "w-1/2 flex flex-col justify-start" }, [
              _c(
                "a",
                {
                  staticClass:
                    "text-black pb-2 pr-4 py-4 flex-1 flex align-center cursor-default",
                  attrs: { "data-cypress": "organisation-email-link" },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "pr-2 text-2xl flex-none",
                    attrs: { icon: "envelope" },
                  }),
                  _vm._v(" Address "),
                ],
                1
              ),
              _c("span", { staticClass: "h-18" }, [
                _vm._v(
                  _vm._s(_vm.organisation.address_line_1) +
                    " " +
                    _vm._s(_vm.organisation.address_line_2)
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.organisation.organisation_suburb) +
                    " " +
                    _vm._s(_vm.organisation.organisation_state) +
                    " " +
                    _vm._s(_vm.organisation.organisation_postcode)
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "w-1/2 flex flex-wrap content-between py-8" },
              [
                _c("AccountStatus", {
                  attrs: {
                    account_status: _vm.organisation.organisation_status,
                    "data-cypress": "organisation-account",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "w-16 h-full flex-auto flex flex-col content-around items-center border-l-2",
            },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "flex-auto flex justify-center items-center font-bold actions w-full h-1/2 action-button rounded-tr-lg",
                  attrs: {
                    "data-cypress": "edit-organisation-link",
                    to: {
                      name: "organisationsEdit",
                      params: {
                        organisation: _vm.organisation,
                        id: _vm.organisation.identifier,
                      },
                    },
                    title: `Edit ${_vm.organisation.organisation_name} details`,
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "flex-auto",
                    attrs: { icon: "edit" },
                  }),
                ],
                1
              ),
              _vm.organisation.organisation_status == "approved"
                ? _c(
                    "button",
                    {
                      staticClass:
                        "flex-auto flex justify-center items-center text-red font-bold h-1/2 w-full action-button suspend-user rounded-br-lg",
                      attrs: {
                        title: `Suspend ${_vm.organisation.organisation_name}`,
                        "data-cypress": "suspend-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmOrganisation(_vm.organisation)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "flex-auto",
                        attrs: { icon: "lock" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.organisation.organisation_status != "approved"
                ? _c(
                    "button",
                    {
                      staticClass:
                        "flex-auto flex justify-center items-center text-green font-bold h-1/4 w-full action-button activate-user rounded-br-lg",
                      attrs: {
                        title: `Approve ${_vm.organisation.organisation_name}`,
                        "data-cypress": "suspend-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmOrganisation(_vm.organisation)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "flex-auto",
                        attrs: { icon: "check" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "flex-auto flex items-center mt-8" }, [
        _c("h2", [_vm._v(_vm._s(_vm.users.length) + " Users")]),
      ]),
      _c("UserList", {
        attrs: { users: _vm.users },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ user }) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-12 h-full flex-auto flex flex-col content-around items-center",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "flex-auto flex justify-center items-center font-bold actions w-full h-1/3 action-button",
                        attrs: {
                          "data-cypress": "view-user-link",
                          to: {
                            name: "profile/view",
                            params: { id: user.email },
                          },
                          title: `View ${user.first_name} ${user.last_name}'s profile`,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "flex-auto",
                          attrs: { icon: "eye" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "flex-auto flex justify-center items-center text-red font-bold h-1/3 w-full action-button suspend-user",
                        attrs: {
                          title: `Suspend ${user.first_name} ${user.last_name}`,
                          "data-cypress": "suspend-button",
                          disabled: "disabled",
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "flex-auto",
                          attrs: { icon: "lock" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", {
        staticClass: "h-24 flex-auto",
        attrs: { name: "purelyABuffer" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }