import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

const stringChecker = data => {
  return API.post(apiName, "/analyse-string-similarity", {
    body: data
  });
};

export default { stringChecker };
