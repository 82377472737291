<template>
  <div class="base-container">
    <ShowLoading isLoading="fetching clarification data">
      <template v-slot:component>
        <NetworkError v-if="errorLoading" />
        <section class="pt-1 justify-center w-full block" v-if="!errorLoading && correctStatus && user.isAdmin">
          <a
            @click="$router.push({ name: 'roadNameProposalBucket', params: {proposalId: $route.params.proposalId} })"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
          >
            <BackArrowWithTitle title="Proposal Seek Clarification" />
          </a>

          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center font-semibold"
          >
            <div class="w-9/10 flex mx-auto">
              <proposalIcon
                :label="road.roadname_classification"
                classNames="text-base mr-3 h-10 w-10"
              />
              <p class="self-center text-title mt-0">
                {{ road.roadname }} {{ road.road_type }}
              </p>
              <StatusButton
                :label="road.name_status"
                :status="road.name_status"
                class="ml-auto mr-6 font-semibold text-sm"
              />
            </div>
          </div>

          <div class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4">
            <div class="w-9/10 mx-auto">
              <p class="text-normal-bold content-start mb-2 mt-10">
                <span class="text-red-star">*</span>Clarification Request
              </p>

              <textarea
                class="text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input"
                v-model="clarificationText"
                placeholder="Please enter your clarification request"
                :disabled="$wait.any"
              ></textarea>

              <uploadComponent
                label="Attach supporting documentation"
                class="mb-10"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                :disabled="uploading || $wait.any"
                :drafted="true"
                :deleteFile="deleteFunction"
              />
            </div>
          </div>
          <div class="flex pt-4 text-center pb-10 justify-end">
            <ButtonWithSpinner
              @click="submitClarification"
              class="p-3 ml-4 w-1/5 button-blue"
              :disabled="uploading || !clarificationText || $wait.any"
              :isSpinning="$wait.is('saving clarification request')"
            >
              Submit Clarification Request
            </ButtonWithSpinner>
          </div>
        </section>
        <section class="pt-1 justify-center w-full block" v-if="!errorLoading && !correctStatus">
          <a
            @click="$router.push({ name: 'proposals' })"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
          >
            <BackArrowWithTitle title="Proposal Seek Clarification" />
          </a>
          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 text-center"
          >
            <div class="w-9/10 flex mx-auto" v-if="user.isAdmin">
              <font-awesome-icon icon="times" class="mr-2 text-red" />
              Proposal is not in the correct status to seek clarification
            </div>
            <div class="w-9/10 flex mx-auto" v-if="!user.isAdmin">
              <font-awesome-icon icon="times" class="mr-2 text-red" />
              You do not have permission
            </div>
          </div>
        </section>
      </template></ShowLoading
    >
  </div>
</template>

<script>
import proposalIcon from "@/components/proposals/road/proposalIcon";
import moment from "moment-timezone";

import {
  submitClarificationRequestJira,
} from "@/helpers/rnpCRUD";
import { mapActions, mapMutations, mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { deleteSupportingDocument } from "@/helpers/s3";
import { waitFor } from "vue-wait";

export default {
  name: "GNBSeekingClarification",
  components: {
    proposalIcon
  },
  props: {
    roadNameId: String
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters({ road: "roadNameProposal" }),
    ...mapUserGetters(["user"]),
    uploadedFiles() {
      return this.$store.getters.uploadedFilesState;
    },
    clarificationRequestDate() {
      return moment(
        this.request.date_modified,
        "YYYY-MM-DD kk:mm:ss.SSSSSS"
      ).format("DD MMM YYYY hh:mm:ss a z");
    },
    correctStatus() {
      return this.road.name_status === "Under Review";
    }
  },
  data() {
    return {
      uploading: false,
      clarificationText: "",
      uploadedValue: "",
      errorLoading: false
    };
  },

  methods: {
    ...mapActions(["getRoadNameProposalById"]),
    ...mapMutations(["clearUploadState"]),
    fetchData: waitFor("fetching clarification data", async function() {
      try {
        await this.clearUploadState();
        await this.getRoadNameProposalById(this.roadNameId);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.errorLoading = true;
      }
    }),
    submitClarification: waitFor(
      "saving clarification request",
      async function() {
        try {
          const payload = {
            roadnameIdentifier: this.road.identifier,
            clarification_request: this.clarificationText,
            files: this.uploadedFiles
          };
          await submitClarificationRequestJira(payload);
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Clarification Request Submitted"
          });
          await this.clearUploadState();
          this.$router.push({
            name: "clarifySeekSuccess",
            params: { road: this.road }
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Sending Clarification Request",
            text: "Error"
          });
        }
      }
    ),
    deleteFunction: deleteSupportingDocument
  }
};
</script>

<style scoped>
.dividing-line-h {
  border-bottom-width: 1px;
}

textarea::placeholder {
  color: #646974;
  opacity: 1;
}
</style>