var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [_c("authenticationContent")]
        },
        proxy: true,
      },
      {
        key: "form",
        fn: function () {
          return [
            _vm.$route.name === "reset"
              ? _c("passwordResetStart", { attrs: { email: _vm.email } })
              : _vm._e(),
            _vm.$route.name === "resetEnd"
              ? _c("passwordResetEnd", {
                  attrs: { email: _vm.email, recoveryCode: _vm.recoveryCode },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }