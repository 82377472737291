<template>
  <div
    class="new-content-notification flex flex-col items-center content-start"
  >
    <div class="title flex flex-row flex-grow justify-center items-center">
      <div class="flex-no-grow icon-container items-center content-center">
        <font-awesome-icon
          class=" flex-0 text-red text-3xl inline"
          icon="exclamation-circle"
        />
      </div>
      <h4 class="flex-grow text-white">
        NSW Place and Road Naming Proposals System has been updated!
      </h4>
    </div>
    <div class="content flex-grow text-white">
      <a @click="refreshBroswer" class="cursor-pointer"
        >Click here to load the new content</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NewContentNotification",
  methods: {
    refreshBroswer() {
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.new-content-notification {
  background-color: rgb(0, 38, 100);
  color: #fff;
  margin-top: 1.5em;
}

.title {
  padding: 1.1em 0;
}

.icon-container {
  padding-right: 1.5em;
  width: 50px;
}

.content {
  padding: 1em 0 1em 0;
  border-top: 2.8px solid #3c54c3;
}

a:hover {
  color: rgb(119, 150, 172);
}

h4 {
  height: auto;
}
</style>
