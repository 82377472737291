var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.request.to !== _vm.request.from
        ? _c("clarification-request", {
            attrs: { entryData: _vm.transformedRequestEntry(_vm.request) },
          })
        : _vm._e(),
      _vm.response.to !== _vm.response.from
        ? _c("clarification-response", {
            attrs: { entryData: _vm.transformedResponseEntry(_vm.response) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }