import { render, staticRenderFns } from "./place.vue?vue&type=template&id=6d6b7c80&"
import script from "./place.vue?vue&type=script&lang=js&"
export * from "./place.vue?vue&type=script&lang=js&"
import style0 from "./place.vue?vue&type=style&index=0&id=6d6b7c80&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d6b7c80')) {
      api.createRecord('6d6b7c80', component.options)
    } else {
      api.reload('6d6b7c80', component.options)
    }
    module.hot.accept("./place.vue?vue&type=template&id=6d6b7c80&", function () {
      api.rerender('6d6b7c80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/proposal-form/geographical-details/components/place.vue"
export default component.exports