var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c("showLoading", {
        attrs: { isLoading: "loading Proposal" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _c(
                  "section",
                  { staticClass: "pt-1 justify-center w-full block" },
                  [
                    !_vm.unique && !_vm.token
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "flex justify-between pt-4 pb-4 -mr-4 self-center",
                            on: {
                              click: function ($event) {
                                return _vm.$router.go(-1)
                              },
                            },
                          },
                          [
                            _c("BackArrowWithTitle", {
                              attrs: {
                                title: "Clarification Advert Information",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 font-semibold",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-9/10 flex mx-auto content-center pt-4",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("h2", [
                                  _vm._v(_vm._s(_vm.geographical_name)),
                                ]),
                                _c("h4", { staticClass: "text-blue" }, [
                                  _vm._v("Local Government Area(s)"),
                                ]),
                                _vm._l(_vm.lga_name, function (lga) {
                                  return _c("div", { key: lga.index }, [
                                    _c("span", { staticClass: "flex" }, [
                                      _vm._v(" " + _vm._s(lga) + " "),
                                    ]),
                                  ])
                                }),
                              ],
                              2
                            ),
                            _c("StatusButton", {
                              staticClass: "ml-auto mr-6 font-semibold text-sm",
                              attrs: {
                                label: _vm.display_status,
                                status: _vm.display_status,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start",
                      },
                      [
                        _c("div", { staticClass: "w-9/10 mx-auto" }, [
                          _c(
                            "span",
                            { staticClass: "self-center pt-10 w-full block" },
                            [
                              _c(
                                "h2",
                                { staticClass: "text-title text-black" },
                                [_vm._v("Clarification Request:")]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex justify-center" },
                                [
                                  _c("span", {
                                    staticClass: "dividing-line-h w-full mt-4",
                                  }),
                                ]
                              ),
                              _vm.clarification
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "self-center textRejectionReason text-normal text-black mt-5 mb-4 pb-10",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.clarification
                                              .display_advert_request
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4",
                      },
                      [
                        _c("div", { staticClass: "w-9/10 mx-auto" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-normal-bold content-start mb-2 mt-10",
                            },
                            [_vm._v(" Your Response ")]
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.clarificationResponse
                                    .clarification_response,
                                expression:
                                  "clarificationResponse.clarification_response",
                              },
                            ],
                            staticClass:
                              "text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input",
                            attrs: {
                              placeholder:
                                "Enter your clarification reponse here...",
                            },
                            domProps: {
                              value:
                                _vm.clarificationResponse
                                  .clarification_response,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.clarificationResponse,
                                  "clarification_response",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex pt-4 text-center pb-10 justify-end",
                      },
                      [
                        _c(
                          "ButtonWithSpinner",
                          {
                            staticClass:
                              "button-blue flex w-2/6 mt-4 mb-10 ml-4",
                            attrs: {
                              isSpinning: _vm.$wait.is(
                                "submitting clarification"
                              ),
                              disabled:
                                _vm.$wait.is("submitting clarification") ||
                                _vm.isDisabled,
                            },
                            on: { click: _vm.submitClarification },
                          },
                          [_vm._v("Submit Clarification")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }