var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "entry",
        { attrs: { eventDate: _vm.entryData.date, cardTitle: "Email" } },
        [
          _c("div", { staticClass: "mt-2" }, [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                " The below email was " +
                  _vm._s(
                    _vm.recipientEmail
                      ? "sent to " + _vm.recipientEmail
                      : "generated"
                  ) +
                  ": "
              ),
            ]),
            _vm.emailSubject
              ? _c("strong", [_vm._v(_vm._s(_vm.emailSubject))])
              : _vm.emailType
              ? _c("strong", [_vm._v(_vm._s(_vm.emailType))])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }