<template>
  <div>
    <div class="dividing-line-h"></div>
    <div class="my-20 text-center text-grey text-normal-bold">
      <div>
        <Loading class="flex-none"></Loading>
      </div>
      {{ text }}
    </div>
    <div class="dividing-line-h"></div>
  </div>
</template>

<script>
import Loading from 'vue-simple-spinner'

export default {
  name: "DualNamedGeoNameSearchFetchingResults",

  /**
   * Instance properties
   */
  props: {
    text: {
      type: String,
      default: "Searching..."
    }
  },

  /**
   * Instance child components
   */
  components: {
    Loading
  }
};
</script>

<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  height: 2em;
}
.rotate {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
