<template>
  <div class="base-container text-black">
    <section class="justify-center w-full block">
      <div class="flex w-full mb-3">
        <a
          @click="$router.go(-1)"
          class="cursor-pointer flex self-center"
          data-cypress="back-arrow"
        >
          <BackArrowWithTitle
            v-if="nameStatus !== null"
            :title="actionType + ' Road Name'"
          />
        </a>

        <div class="flex ml-auto">
          <a
            href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=96"
            target="_blank"
            class="button-blue-hollow ml-4 focus:outline-none focus:shadow-outline self-center"
            >View Road Naming Guidelines</a
          >
        </div>
      </div>
    </section>
    <NetworkError v-if="fetchError" />
    <ShowLoading
      v-if="!fetchError"
      isLoading="setting up road name proposal page"
    >
      <template slot="component">
        <div>
          <!-- Proposal Types -->
          <div class="w-full flex bg-white">
            <div class="w-5/6 mx-auto py-16">
              <div class="flex">
                <span
                  v-show="unapproved_gazette"
                  class="text-orange issue border-orange border-2 font-bold py-1 px-4 rounded-full ml-auto"
                >
                  Unapproved Gazette
                </span>
              </div>
              <p class="font-bold">
                <span class="text-red-star">*</span>Proposal Type
              </p>
              <div class="flex flex-row text-normal">
                <RadioButton
                  class="flex-0 mr-6 mt-3 mb-1"
                  name="Classification"
                  data-cypress="public-proposal"
                  v-model="proposalType"
                  checkedValue="Public"
                  :disabled="
                    isDisabled ||
                      nameStatus === 'Proposed' ||
                      nameStatus === 'Clarification'
                  "
                  v-validate="
                    'required|included:Public,Non-Dedicated,Pre-Approval'
                  "
                  data-vv-as="Road Name Classification"
                  >Public Road</RadioButton
                >
                <RadioButton
                  class="flex-0 mr-6 mt-3 mb-1"
                  data-cypress="nondedicated-proposal"
                  name="Classification"
                  v-model="proposalType"
                  checkedValue="Non-Dedicated"
                  :disabled="
                    isDisabled ||
                      nameStatus === 'Proposed' ||
                      nameStatus === 'Clarification'
                  "
                  v-validate="
                    'required|included:Public,Non-Dedicated,Pre-Approval'
                  "
                  data-vv-as="Road Name Classification"
                  >Non-Dedicated/Private Road</RadioButton
                >

                <RadioButton
                  v-if="nameStatus != 'Pre-Approved'"
                  class="flex-0 mr-6 mt-3 mb-1"
                  data-cypress="preapproved-proposal"
                  name="Classification"
                  v-model="proposalType"
                  checkedValue="Pre-Approval"
                  :disabled="
                    isDisabled ||
                      nameStatus === 'Proposed' ||
                      nameStatus === 'Clarification'
                  "
                  v-validate="
                    'required|included:Public,Non-Dedicated,Pre-Approval'
                  "
                  data-vv-as="Road Name Classification"
                  >Road Name Pre-Approval</RadioButton
                >
              </div>
              <FieldErrorMessage :message="errors.first('Classification')" />

              <!-- Form textbox / inputs section -->
              <div class="w-full flex content-center items-start mt-8">
                <div class="pr-4 w-1/2 items-start">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Local Government Area
                  </p>
                  <norns-select
                    :disabled="
                      nameStatus === 'Pre-Approved' ||
                        isDisabled ||
                        $wait.is('evaluatingName')
                    "
                    class="w-full mr-4 norns-select"
                    v-model="lgaName"
                    :multiple="false"
                    :options="lgasLimited"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="Select LGA..."
                    name="LGA"
                    data-cypress="lga-select"
                    v-validate="'required'"
                    data-vv-as="Local Government Authority"
                    :select-on-tab="true"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('LGA')" />
                </div>
                <div class="pl-4 w-1/2 flex flex-col">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Address Locality
                    (Suburb)
                  </p>
                  <norns-select
                    :disabled="
                      !lgaName ||
                        nameStatus === 'Pre-Approved' ||
                        isDisabled ||
                        $wait.is('evaluatingName')
                    "
                    class="w-full mr-4 norns-select"
                    v-model="suburbName"
                    :multiple="true"
                    :options="suburbOptions"
                    :searchable="true"
                    :close-on-select="false"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Pick a Suburb"
                    name="suburb"
                    data-cypress="suburb-field"
                    v-validate="'required'"
                    data-vv-as="Suburb"
                    :select-on-tab="true"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('suburb')" />
                </div>
              </div>
              <!-- proposed road name -->
              <div class="w-full flex items-start mt-8">
                <div class="w-1/3">
                  <p class="text-normal-bold mb-1">
                    <span class="text-red-star">*</span>Proposed Road Name
                  </p>
                  <input
                    type="text"
                    :disabled="
                      !lgaName ||
                        !suburbName.length ||
                        nameStatus === 'Pre-Approved' ||
                        isDisabled ||
                        $wait.is('evaluatingName')
                    "
                    class="w-full mr-4 input-area mb-1 mt-1"
                    data-cypress="roadname-field"
                    placeholder="Enter Name Here..."
                    name="Proposed Road Name"
                    id="roadName"
                    v-validate="'required'"
                    v-model.trim="roadName"
                  />
                </div>
                <div class="pl-4 w-1/3 -mb-3">
                  <p class="text-normal-bold mb-2">
                    <a
                      v-if="proposalType !== 'Pre-Approval'"
                      class="text-red-star"
                      >*</a
                    >Road Type
                  </p>
                  <norns-select
                    class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                    name="Road Type"
                    id="roadType"
                    placeholder="Select Road Type..."
                    data-cypress="select-roadtype-input"
                    v-model="roadType"
                    :multiple="false"
                    :disabled="
                      !lgaName ||
                        !suburbName.length ||
                        isDisabled ||
                        $wait.is('evaluatingName')
                    "
                    :options="roadTypesClean"
                    :searchable="true"
                    :close-on-select="true"
                    :select-on-tab="true"
                    v-validate="{
                      required: this.proposalType !== 'Pre-Approval'
                    }"
                  >
                    <span slot="noOptions">Loading...</span>
                  </norns-select>
                  <FieldErrorMessage :message="errors.first('Road Type')" />
                </div>
                <div class="pl-4 w-1/3 -mb-3">
                  <p class="text-normal-bold mb-2">
                    <a class="text-red-star">*</a>Radius
                  </p>
                  <p class="text-normal-bold mb-2">
                    <norns-select
                      :disabled="
                        isDisabled || !lgaName || $wait.is('evaluatingName')
                      "
                      class="w-full rounded-lg text-normal mb-1 mt-1 norns-select"
                      v-model="distance"
                      :multiple="false"
                      :options="distanceOptions"
                      :reduce="d => d.value"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="true"
                      :hide-selected="true"
                      placeholder="Pick a radius"
                      name="distance"
                      data-cypress="radius-field"
                      v-validate="'required'"
                      :select-on-tab="true"
                    >
                      <span slot="noOptions">Loading...</span>
                    </norns-select>
                    <FieldErrorMessage :message="errors.first('Radius')" />
                  </p>
                </div>
              </div>

              <div class="flex mb-5">
                <!-- Form textbox / inputs section -->
                <div class="w-full flex content-center items-start mt-8">
                  <div class="pr-4 items-start">
                    <p class="text-normal-bold mb-2">
                      Road Name Extent
                    </p>
                    Please draw the extent of the proposed road on the map.
                    <ul>
                      <li>
                        Select either the polyline
                        <img
                          src="/img/icons/polyline-110x80.png"
                          style="width:40px;"
                        />
                        or polygon
                        <img
                          src="/img/icons/polygon-92x92.png"
                          style="width:32px;"
                        />
                        tool from within the map
                      </li>
                      <li>Left click to mark the start of the road</li>
                      <li>Add additional points to mark out the road</li>
                      <li>Double click for the extent to be completed</li>
                      <li>
                        Click Evaluate to undertake a road name evaluation
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <WebMap
                  id="WebMap"
                  ref="WebMapRef"
                  style="height: 450px"
                  :lgaName="lgaName"
                  :suburbs="suburbName"
                  :distance="distance"
                  v-on:draw-complete="onDrawComplete"
                  v-on:draw-delete="onDrawDelete"
                  :readOnly="!canBeEdited"
                >
                </WebMap>
              </div>
              <div style="display: flex">
                <div style="margin-right: 1rem">
                  <ButtonWithSpinner
                    class="button-blue mb-1 mt-1"
                    @click="evaluateRoadName"
                    :isSpinning="$wait.is('evaluatingName')"
                    :disabled="
                        !this.canBeEdited ||
                        $wait.is('evaluatingName') ||
                        !(
                          roadName &&
                          distance &&
                          Object.keys(suburbName).length &&
                          Object.keys(geometry).length
                        )
                    "
                  >
                    Evaluate
                  </ButtonWithSpinner>
                </div>

                <!-- Adds a button that retrieves similar road names from the DB -->
                <div>
                  <ButtonWithSpinner
                    class="button-blue mb-1 mt-1"
                    @click="getSimilarRoadnamesFromDB"
                    :isSpinning="$wait.is('gettingSimilarRoads')"
                    :disabled="
                        (!this.canBeEdited && nameStatus !== 'Under Review') ||
                        $wait.is('gettingSimilarRoads') ||
                        !(
                          roadName &&
                          distance &&
                          Object.keys(suburbName).length &&
                          Object.keys(geometry).length
                        )
                    "
                    v-if="user.isAdmin"
                  >
                    Get Similar Roadnames in DB (For GNB)
                  </ButtonWithSpinner>
                </div>
              </div>
              <div class="flex flex-col mb-5">
                <p
                  class="flex-grow text-black text-sm font-bold text-center"
                  v-if="$wait.is('evaluatingName')"
                >
                  Checking Road name validity
                </p>
                <ShowLoading isLoading="evaluatingName">
                  <template v-slot:component>
                    <div class="flex flex-grow items-start">
                      <div
                        v-if="evaluated && evaluation && !!evaluation.breaches"
                        class="flex-grow"
                      >
                        <div class="text-orange-dark font-bold text-lg">
                          {{ evaluation.breaches.issueCount }} potential
                          issue(s) found
                        </div>
                        <div
                          v-for="failedGuideline in evaluation.breaches
                            .failures"
                          :key="failedGuideline.guideLineType"
                        >
                          <div class="text-orange font-bold pt-4">
                            {{ failedGuideline.title }}
                          </div>
                          <MessagePanel
                            class="flex-grow"
                            v-for="(message, index) in failedGuideline.issues"
                            :key="`${failedGuideline.guideLineType}-${index}`"
                            warning
                            :msg="message.shortDescription"
                            :mapURL="message.mapURL"
                            :geometry="message.geometry"
                            v-on:increment-btn="showLine"
                            :guideLineType="failedGuideline.guideLineType"
                          />
                          <MessagePanel
                            class="flex-grow"
                            v-if="
                              !failedGuideline.issues ||
                                failedGuideline.issues.length < 1
                            "
                            :key="failedGuideline.guideLineType"
                            warning
                            :msg="failedGuideline.shortDescription"
                            :mapURL="failedGuideline.mapURL"
                            :geometry="failedGuideline.geometry"
                            v-on:increment-btn="showLine"
                            :guideLineType="failedGuideline.guideLineType"
                          />
                        </div>
                      </div>
                      <div v-else-if="evaluated && evaluation.passes == true">
                        <MessagePanel
                          class="flex-grow"
                          :msg="'0 potential issues found'"
                          ok
                        />
                      </div>
                    </div>
                  </template>
                </ShowLoading>
              </div>

              <!-- Shows similar road names retrieved from the DB -->
              <div 
                class="flex flex-col mb-5"
                v-if="evaluated && similarRoadnames"
              >
                <p
                  class="flex-grow text-black text-sm font-bold text-center"
                  v-if="$wait.is('gettingSimilarRoads')"
                >
                  Checking Similar Road names in the Proposals System
                </p>
                <ShowLoading isLoading="gettingSimilarRoads" />
                <SimilarRoadnameLists 
                  v-if="!!this.similarRoadnames.breach && this.similarRoadnames.breach.issues.length >= 0 && !$wait.is('gettingSimilarRoads')"
                  :similarRoadnames="similarRoadnames.breach"       
                />
              </div>
              <!--
              <div class="flex flex-col mb-5">
                <ShowLoading isLoading="evaluatingName">
                  <template v-slot:component>
                    <div class="w-full pt-4">
                      <div
                        v-if="
                          evaluated &&
                            nameStatus !== 'Pre-Approved' &&
                            evaluation &&
                            !!evaluation.issues
                        "
                        class="flex-grow"
                      >
                        <div class="text-orange-dark font-bold text-lg">
                          {{ numIssues }} potential issue(s) found
                        </div>
                        <div
                          v-for="(issues, issueType) in evaluation.issues"
                          :key="issueType"
                        >
                          <div class="text-orange font-bold pt-4">
                            {{ issueType }}
                          </div>
                          <MessagePanel
                            class="flex-grow"
                            v-for="(message, index) in issues"
                            :key="index"
                            warning
                            :msg="message.shortDescription"
                            :mapURL="message.mapURL"
                          />
                        </div>
                      </div>
                      <div v-else-if="evaluated && !evaluation.issues">
                        <MessagePanel
                          class="flex-grow"
                          :msg="'0 potential issues found'"
                          ok
                        />
                      </div>
                    </div>
                  </template>
                </ShowLoading>
              </div>

              -->
              <div class="w-full flex mt-8">
                <label class="w-full">
                  <p class="text-normal-bold mb-2">
                    <span
                      class="text-red-star"
                      v-if="proposalType !== 'Pre-Approval'"
                      >*</span
                    >Extent
                  </p>
                  <textarea
                    class="w-full h-48 input-area"
                    data-cypress="extent-field"
                    placeholder="Please describe where the road starts and ends"
                    v-model="extent"
                    name="extent"
                    v-validate="{
                      required: this.proposalType !== 'Pre-Approval'
                    }"
                    :disabled="isDisabled"
                  ></textarea>
                </label>
              </div>
              <FieldErrorMessage :message="errors.first('extent')" />
              <div class="w-full flex mt-8">
                <label class="w-full">
                  <p class="text-normal-bold mb-2">
                    <span class="text-red-star">*</span>Origin
                  </p>
                  <textarea
                    :disabled="isDisabled"
                    class="w-full h-48 input-area"
                    data-cypress="origin-field"
                    placeholder="Please provide the meaning and history of the proposed name"
                    v-model="origin"
                    name="Origin"
                    v-validate="'required'"
                  ></textarea>
                </label>
              </div>

              <FieldErrorMessage :message="errors.first('Origin')" />

              <commemorate-person
                :roadIndex="index"
                :readOnly="
                  isDisabled ||
                    $wait.is('saving roadname') ||
                    $wait.is('setting up road name proposal page')
                "
                ref="personCommemoration"
              />

              <p class="text-normal-bold mt-8 mb-5">
                Does this road contain a multi-cultural dimension?
              </p>
              <div class="flex text-normal">
                <RadioButton
                  data-cypress="doesNotHaveMulticulturalDiemnsion"
                  class="mr-3"
                  v-model="multiculturalDimension"
                  :checkedValue="false"
                  :disabled="!!aboriginalDimension || isDisabled"
                  >No</RadioButton
                >
                <RadioButton
                  data-cypress="doesHaveMulticulturalDiemnsion"
                  v-model="multiculturalDimension"
                  :checkedValue="true"
                  :disabled="!!aboriginalDimension || isDisabled"
                >
                  Yes (Please specify in the 'Additional Information' field)
                </RadioButton>
              </div>

              <p class="text-normal-bold mt-8 mb-5">
                Does this road contain an Aboriginal dimension?
              </p>
              <div class="flex flex-row text-normal justify-start">
                <div class="flex text-normal">
                  <RadioButton
                    data-cypress="doesNotHaveAboriginalDiemnsion"
                    class="mr-3"
                    v-model="aboriginalDimension"
                    :checkedValue="false"
                    :disabled="!!multiculturalDimension || isDisabled"
                    >No</RadioButton
                  >
                  <RadioButton
                    data-cypress="doesHaveAboriginalDiemnsion"
                    v-model="aboriginalDimension"
                    :checkedValue="true"
                    :disabled="!!multiculturalDimension || isDisabled"
                  >
                    Yes (Please specify in the 'Additional Information' field)
                  </RadioButton>
                </div>
              </div>
              <p class="text-normal-bold mt-8">
                Additional information for this Road
              </p>
              <div class="w-full mt-3 flex">
                <textarea
                  class="w-full h-48 input-area"
                  data-cypress="information-field"
                  v-model="comment"
                  :disabled="isDisabled"
                  name="comment"
                ></textarea>
              </div>
              <uploadComponent
                class="mt-8"
                label="Please upload Map and Other Supporting Document"
                :important="proposalType !== 'Pre-Approval'"
                :uploads="documents"
                :drafted="!isDisabled"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                v-validate="
                  proposalType !== 'Pre-Approval' ? 'min_value:1' : ''
                "
                :disabled="isDisabled"
                :deleteFile="stageForDelete"
                name="documents"
              />
              <FieldErrorMessage
                data-cypress="nofile-error"
                :message="
                  errors.first('documents')
                    ? 'No files were uploaded for this Road Name. Please upload required documents.'
                    : ''
                "
              />
            </div>
          </div>

          <div class="flex justify-end">
            <ButtonWithSpinner
              class="button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold"
              data-cypress="cancel-button"
              @click="$router.go(-1)"
              :disabled="$wait.is('saving roadname')"
              >Cancel</ButtonWithSpinner
            >
            <ButtonWithSpinner
              class="button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold"
              data-cypress="addroad-button"
              @click="validateRoadNameProposal"
              :disabled="isSubmitDisabled"
              :isSpinning="$wait.is('saving roadname')"
              >Save</ButtonWithSpinner
            >
          </div>
        </div>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  createNamespacedHelpers
} from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import { evaluateRoadnameNew } from "../../../helpers/validations.js";
import { submitConvertRoadNameProposal, getSimilarRoadnamesFromDB } from "@/helpers/rnpCRUD";
import { getSuburbs } from "../../../helpers/servicesq.js";
import { deleteFile } from "../../../helpers/s3";
// custom elements

import roadTypeListMixin from "../../../mixins/road-type-list-filter";
import lgaListMixin from "../../../mixins/lga-list-filter";
import MessagePanel from "../place/messagePanel";
import CommemoratePerson from "../common/CommemoratePerson";
//import debounce from "lodash.debounce";

import WebMap from "./evaluation-public/WebMap.vue";
import SimilarRoadnameLists from "./similar-roads-in-db.vue";
import moment from "moment";

export default {
  name: "RoadNameProposal",
  mixins: [roadTypeListMixin, lgaListMixin],
  components: {
    MessagePanel,
    CommemoratePerson,
    WebMap,
    SimilarRoadnameLists
  },
  data() {
    return {
      similarRoadnames: {},
      evaluation: {},
      uploading: false,
      fetchError: false,
      suburbOptions: [],
      //distance: "",
      distanceOptions: [
        { label: "10km - Metropolitan", value: 10000 },
        { label: "20km - Regional Urban", value: 20000 },
        { label: "30km - Rural", value: 30000 }
      ],
      //geometry: {},
      deleteFunction: deleteFile,
      deletedFiles: [],
      evaluated: false
    };
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    roadNameId: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      uploadedFiles: "uploadedFilesState",
      roadNameProposal: "roadNameProposal"
    }),
    ...mapUserGetters(["user"]),
    isSubmitDisabled() {
      return (
        this.uploading ||
        this.isDisabled ||
        this.errors.any() ||
        !this.proposalType ||
        !this.lgaName ||
        !this.suburbName ||
        !this.roadName ||
        !this.origin ||
        !this.commemorationResponse ||
        this.commemorationValidationFails ||
        (this.proposalType !== "Pre-Approval" && !this.extent) ||
        (this.proposalType !== "Pre-Approval" && !this.roadType) ||
        !this.enoughFilesSubmitted ||
        !this.evaluated ||
        this.$wait.any
      );
    },
    /**
     * Has the user clicked one of the
     * commemoration radio buttons
     */
    commemorationResponse() {
      return this.$store.getters.roadNameProposal.commemorated !== null;
    },
    commemorationValidationFails() {
      if (this.$store.getters.roadNameProposal.commemorated == true) {
        // commemorated_association_description shoudl always be set
        if (
          !this.$store.getters.roadNameProposal.commemorated_full_name ||
          !this.$store.getters.roadNameProposal
            .commemorated_association_description
        ) {
          return true;
        }
        if (
          this.$store.getters.roadNameProposal.commemorated_birth_date &&
          this.$store.getters.roadNameProposal.commemorated_death_date
        ) {
          if (
            moment(
              this.$store.getters.roadNameProposal.commemorated_birth_date
            ) >
            moment(this.$store.getters.roadNameProposal.commemorated_death_date)
          ) {
            return true;
          }
        } else if (
          !this.$store.getters.roadNameProposal.commemorated_dates_unknown
        ) {
          return true;
        } else {
          // dob and/or dod are unknown
          if (
            !this.$store.getters.roadNameProposal
              .commemorated_dates_unknown_description
          ) {
            return true;
          }
        }
      }
      return false;
    },
    suburbName: {
      get() {
        return this.$store.getters.roadNameProposal.suburb_name;
      },
      set(value) {
        this.setRoadSuburbName(value);
      }
    },
    lgaName: {
      get() {
        return this.$store.getters.roadNameProposal.lga_name;
      },
      set(value) {
        this.setRoadLGAName(value);
      }
    },
    roadName: {
      get() {
        return this.$store.getters.roadNameProposal.roadname;
      },
      set(value) {
        this.setRoadName(value);
      }
    },
    roadType: {
      get() {
        return this.$store.getters.roadNameProposal.road_type;
      },
      set(value) {
        this.setRoadType(value);
      }
    },
    distance: {
      get() {
        return this.$store.getters.roadNameProposal.distance || 0;
      },
      set(value) {
        this.setRoadDistance(value);
      }
    },
    geometry: {
      get() {
        return this.$store.getters.roadNameProposal.geometry;
      },
      set(value) {
        this.setRoadGeometry(value);
      }
    },
    origin: {
      get() {
        return this.$store.getters.roadNameProposal.roadname_origin;
      },
      set(value) {
        this.setRoadOrigin(value);
      }
    },
    extent: {
      get() {
        return this.$store.getters.roadNameProposal.extent;
      },
      set(value) {
        this.setRoadExtent(value);
      }
    },
    multiculturalDimension: {
      get() {
        return this.$store.getters.roadNameProposal.has_multicultural_dimension;
      },
      set(value) {
        this.setRoadMulticultruralDimension(value);
      }
    },
    aboriginalDimension: {
      get() {
        return this.$store.getters.roadNameProposal.has_aboriginal_dimension;
      },
      set(value) {
        this.setRoadAboriginalDimension(value);
      }
    },
    comment: {
      get() {
        return this.$store.getters.roadNameProposal.comment;
      },
      set(value) {
        this.setRoadComment(value);
      }
    },
    nameStatus: {
      get() {
        return this.$store.getters.roadNameProposal.name_status;
      },
      set(value) {
        this.setRoadStatus(value);
      }
    },
    documents: {
      get() {
        return this.$store.getters.uploadedFilesState;
      }
    },
    proposalType: {
      get() {
        switch (
          this.$store.getters.roadNameProposal.roadname_classification.toLowerCase()
        ) {
          case "public":
            return "Public";
          case "pre-approval":
            return "Pre-Approval";
          case "non-dedicated":
            return "Non-Dedicated";
          default:
            return this.$store.getters.roadNameProposal.roadname_classification;
        }
      },
      set(value) {
        this.setProposalType(value);
      }
    },
    unapproved_gazette() {
      return this.$store.getters.roadNameProposal.unapproved_gazette;
    },
    isDisabled() {
      return (
        !this.canBeEdited ||
        this.uploading ||
        this.$wait.is("saving roadname") ||
        this.$wait.is("setting up road name proposal page")
      );
    },
    actionType() {
      if (this.$route.name === "proposalAdd") {
        return "Add";
      }
      return this.canBeEdited ? "Edit" : "View";
    },
    canBeEdited() {
      if (this.readOnly) {
        return false;
      }
      if (this.user.isASP) {
        return false;
      }
      if (
        ["", "Drafted", "Proposed", "Clarification", "Pre-Approved"].includes(
          this.nameStatus
        )
      ) {
        return true;
      }
      return false;
    },
    numIssues() {
      if (this.evaluation.issues) {
        const mappedIssueCounts = Object.keys(this.evaluation.issues).map(
          key => this.evaluation.issues[key].length
        );
        return mappedIssueCounts.reduce((a, b) => a + b);
      }
      return 0;
    },
    enoughFilesSubmitted() {
      if (this.nameStatus === "Pre-Approved") {
        return (
          this.documents.filter(
            f => !/roadname-evaluation.(pdf|csv)$/.test(f.title)
          ).length > 0
        );
      }
      if (this.proposalType === "Pre-Approval") {
        return true;
      }
      return this.documents.length > 0;
    }
  },
  watch: {
    lgaName: {
      async handler(newVal, oldVal) {
        await this.getSuburbsForLGA(newVal);
        if (newVal !== oldVal && oldVal !== "" && !this.$wait.is("initPage")) {
          this.suburbName = [];
          this.geometry = {};
          if (this.$refs.WebMapRef) {
            this.$refs.WebMapRef.doClearAll();
          }
          this.evaluated = false;
          this.evaluation = {};
          this.similarRoadnames = {};
        }
        if (
          !this.isDisabled &&
          newVal &&
          this.roadName &&
          this.suburbName.length > 0
        ) {
          window.setTimeout(() => {
            //this.evaluateRoadName &&
            //  this.evaluateRoadName(this.roadName, newVal, this.suburbName);
          }, 2000);
        }
      },
      immediate: false
    },
    // suburbName: debounce(function(newVal) {
    //   if (!this.isDisabled && newVal && this.roadName) {
    //     this.evaluateRoadName &&
    //       this.evaluateRoadName(this.roadName, newVal, this.suburbName);
    //   }
    // }, 2000),
    suburbName() {
      this.evaluated = false;
      this.evaluation = {};
      this.similarRoadnames = {};
    },
    roadName() {
      this.evaluated = false;
      this.evaluation = {};
      this.similarRoadnames = {};
    },
    distance() {
      this.evaluated = false;
      this.evaluation = {};
      this.similarRoadnames = {};
      //if (this.$refs.WebMapRef) {
      //this.$refs.WebMapRef.clearRadius();
      //}
    }
  },
  methods: {
    submitConvertRoadNameProposal,
    ...mapActions(["getRoadNameProposalById", "saveDraftRoadNameProposal"]),
    ...mapMutations([
      "roadsForProposalState",
      "addNewRoadName",
      "clearUploadState",
      "setRoadSuburbName",
      "setRoadLGAName",
      "setRoadName",
      "setRoadType",
      "setRoadDistance",
      "setRoadGeometry",
      "setRoadOrigin",
      "setRoadExtent",
      "setRoadMulticultruralDimension",
      "setRoadAboriginalDimension",
      "setRoadComment",
      "setRoadDocuments",
      "addRoadToProposal",
      "setProposalType",
      "setRoadStatus",
      "editRoadName",
      "setUploadedFiles"
    ]),
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      this.suburbOptions = suburbs.filter(sub => sub !== null && sub !== "");
    },
    /**
     * Run all known form validators present in the page
     */
    async runValidators() {
      const commRes = await this.$refs[
        "personCommemoration"
      ].$validator.validate();

      const propRes = await this.$validator.validate();

      return [commRes, propRes];
    },
    async validateRoadNameProposal() {
      try {
        this.roadName = this.roadName.trim().replace(/  +/g, " ");
        this.$wait.start("saving roadname");
        const results = await this.runValidators();
        if (!results.includes(false)) {
          this.setRoadDocuments(this.uploadedFiles);
          /*
          // don't delete from the server because with bulk upload, other road names might need the file
          for (let s3Key of this.deletedFiles) {
            await this.deleteFunction(s3Key).catch();
          }
          */
          console.log("commerated=" + this.$store.getters.commemorated);
          console.log("nameStatus=" + this.nameStatus);
          console.log("commemorated_full_name=" + this.$store.getters.roadNameProposal.commemorated_full_name);
          console.log("commemorated_dates_unknown=" + this.$store.getters.roadNameProposal.commemorated_dates_unknown);
          
          if (!["", "Drafted"].includes(this.nameStatus)) {
            if (this.nameStatus === "Pre-Approved") {
              console.log("submitConvertRoadNameProposal");
              await this.submitConvertRoadNameProposal(
                this.roadNameId,
                this.roadNameProposal
              );
            } else {
              console.log("saveDraftRoadNameProposal");
              if(!this.$store.getters.roadNameProposal.commemorated){
                this.$store.commit("setCommemoratedPerson",{name: null});
                this.$store.commit("setCommemoration", {isCommemorated: false} );
                this.$store.commit("setCommemoratedPersonDob", {dob: null});
                this.$store.commit("setCommemoratedPersonDod", {dod: null});
                this.$store.commit("setCommemorationDateUnknown", {isUnknown: false});
                this.$store.commit("setCommemorationSource", {source: null});
                this.$store.commit("setCommemorationAssociation", {association: null});                             
                this.$store.commit("setCommemorationDateUnknownReason", {reason: null}); 
              }
              await this.saveDraftRoadNameProposal(
                this.$store.getters.roadNameProposal
              );
            }
          }
          console.log("addRoadToProposal");
          this.addRoadToProposal({
            road: this.$store.getters.roadNameProposal,
            index: this.index
          });
          this.clearUploadState();
          // eslint-disable-next-line no-console
          console.log(`redirecting to ${this.$route.params.proposalId}`);

          this.$wait.end("saving roadname");
          this.$router.push({
            name: "roadNameProposalBucket",
            params: { proposalId: this.$route.params.proposalId }
          });
        } else {
          const el = document.querySelector(".field-error-message");

          el &&
            el.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          this.$wait.end("saving roadname");
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Save unsuccessful",
          text: "There was an error saving this roadname"
        });

        this.$wait.end("saving roadname");
      }
    },
    filterLGA(searchQuery) {
      this.lgaFilter = searchQuery;
    },
    stageForDelete(s3Key) {
      this.deletedFiles.push(s3Key);
    },
    async getSimilarRoadnamesFromDB() {
      this.evaluateRoadName();

      this.$wait.start("gettingSimilarRoads");

      try {
        this.similarRoadnames = await getSimilarRoadnamesFromDB(this.roadName);
      } catch (error) {
        this.$notify({
          message: error
        });
      }

      this.$wait.end("gettingSimilarRoads");
    },
    async evaluateRoadName() {
      this.evaluated = true;
      this.$wait.start("evaluatingName");

      if (this.$refs.WebMapRef) {
        this.$refs.WebMapRef.doShowRadius();
      }

      try {
        const evalResponse = await evaluateRoadnameNew(
          this.roadName,
          this.lgaName,
          this.suburbName,
          this.distance,
          this.geometry,
          this.$route.params.proposalId
        );

        this.evaluation = evalResponse;
        /*
        if (evalResponse.issues.length) {
          const issues = {};
          evalResponse.issues.forEach(issue => {
            const key = issue.title;
            if (issues[key]) {
              issues[key] = issues[key].concat(issue);
            } else {
              issues[key] = [issue];
            }
          });
          this.evaluation = { issues };
        } else {
          this.evaluation = {};
        }
      */
      } catch (e) {
        this.$notify({
          message: e
        });
      }
      this.$wait.end("evaluatingName");
    },
    onDrawComplete(geometry) {
      this.geometry = geometry;
      this.evaluated = false;
      this.evaluation = {};
      this.similarRoadnames = {};
    },
    onDrawDelete() {
      this.geometry = {};
      this.evaluated = false;
      this.evaluation = {};
      this.similarRoadnames = {};
      this.$refs.WebMapRef.clearUserSketch();
      this.$refs.WebMapRef.clearRadius();
    },
    showLine(geometry) {
      this.$refs.WebMapRef.doShowRoad(geometry);

      window.scrollTo({
        top: 650,
        behavior: "smooth"
      });
      //this.geometry = geometry.geometry;
    }
  },
  async created() {
    try {
      this.$wait.start("initPage");

      this.$wait.start("setting up road name proposal page");
      if (this.user.isASP && this.actionType !== "View") {
        this.$router.push({ name: "proposals" });
      }
      this.nameStatus = null;
      // Clear the uploaded files if the for was entered/exited improperly
      this.clearUploadState();

      if (this.roadNameId !== null) {
        // we're viewing a saved roadname
        this.$wait.start("setting up road name proposal page");
        await this.getRoadNameProposalById(this.roadNameId);
      } else {
        // we're viewing an unsaved roadname
        // get the right roadname proposal from state
        this.editRoadName(this.index);
      }

      //if the status == pre-approved then remove the current proposal type
      if (
        this.nameStatus === "Pre-Approved" &&
        this.proposalType === "Pre-Approval"
      ) {
        this.proposalType = "";
      }

      // if the proposal has uploaded files, add them to the upload state
      // TODO -  remove upload state and link it to the proposal
      if (this.$store.getters.roadNameProposal.uploadedFiles) {
        this.setUploadedFiles(
          this.$store.getters.roadNameProposal.uploadedFiles.filter(file => {
            if (["", "Drafted"].includes(this.nameStatus)) {
              return true;
            }
            //only show the proposal files (excludes clarification files for example)
            return (
              new RegExp(`^documents/(${file.roadname_id}|legacy)`).test(
                file.s3_key
              ) || new RegExp()
            );
          })
        );
      }
      if (this.lgasLimited.length === 1) {
        this.lgaName = this.lgasLimited[0];
      }

      this.$wait.end("setting up road name proposal page");
    } catch (e) {
      if (e.response.status === 500) {
        this.fetchError = true;
      }
      this.$wait.end("setting up road name proposal page");
    }
  },
  async mounted() {
    // console.log("this.lgaName", this.lgaName);
    // if (this.lgaName) {
    //   await this.getSuburbsForLGA(this.lgaName);
    // }

    if (this.lgaName) {
      await this.getSuburbsForLGA(this.lgaName);
    }

    window.setTimeout(
      () => {
        this.$wait.end("initPage");

        console.log("show user sketch", this.geometry);

        if (this.geometry && Object.keys(this.geometry).length > 0) {
          this.$refs.WebMapRef.doShowUserSketch(this.geometry);
          if (this.canBeEdited) {
            this.evaluateRoadName();
          }
        } else {
          this.$refs.WebMapRef.doShowSuburbs();
        }
      },

      3000
    );
  }
};
</script>
