<template>
  <div class="base-container">
    <ShowLoading isLoading="fetching redirect data">
      <NetworkError v-if="errorLoading" />
    </ShowLoading>
  </div>
</template>

<script>
import { getRoadNameByJiraId } from "@/helpers/rnpCRUD";
import { waitFor } from "vue-wait";

export default {
  name: "roadNameJiraClarificationRedirect",
  data() {
    return {
      errorLoading: false
    };
  },
  created: waitFor("fetching redirect data", async function(){
    try {
      const roadname = await getRoadNameByJiraId(this.$route.params.jiraId);
      this.$router.push({
        name: "roadSeekClarification",
        params: {
          proposalId: roadname.proposal_id,
          roadNameId: roadname.identifier
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      this.errorLoading = true;
    }
  })
};
</script>
