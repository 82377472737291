<template>
  <div>
    <!-- IS COMMEMORATED -->
    <div class="w-full hover:mb-4">
      <p class="text-normal-bold mt-8">
        <span class="text-red-star">*</span>Does this proposed name commemorate
        a person?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3"
          data-cypress="commemorated-no"
          name="commemorated checkbox"
          v-model="commemorated"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3"
          name="commemorated checkbox"
          data-cypress="commemorated-yes"
          v-model="commemorated"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>

    <section v-if="isCommemorated">
      <!-- FULLNAME -->
      <label class="w-2/3">
        <p class="text-normal-bold mb-2 mt-8">
          <span class="text-red-star">*</span>Full Name of Person
        </p>
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="commemoratedFullName-field"
          placeholder="Enter Name Here..."
          name="commemorated_full_name"
          id="commemoratedFullName"
          v-validate="'required'"
          v-model="name"
          data-vv-as="Full Name of Person"
          :disabled="readOnly"
        />
        <FieldErrorMessage :message="errors.first('commemorated_full_name')" />
      </label>

      <!-- DATES -->
      <div class=" text-normal mb-4 mt-4">
        <div class="mb-4">
          <!-- DOB -->
          <label class="text-normal-bold pb-2">
            <span class="text-red-star">*</span>Date of Birth
          </label>
          <div class="flex flex-row mt-2">
            <v-datepicker
              mode="single"
              v-model="dob"
              :max-date="dod || todaysDate"
            >
              <input
                :value="dob"
                slot-scope="{ inputProps, updateValue }"
                class="w-full mr-4 input-area"
                v-bind="inputProps"
                @input="e => updateDate(e, updateValue)"
                @change="setDobNullIfBlank"
                :disabled="readOnly"
                autocomplete="off"
                placeholder="Enter Date (DD/MM/YYYY)"
                data-cypress="commemorated_birth_date"
                v-validate="{
                  required: !commemoration_date_unknown,
                  date: !commemoration_date_unknown
                }"
                name="commemorated_birth_date"
                data-vv-as="Date of Birth"
              />
            </v-datepicker>

            <button
              class="ml-2 w-1/8"
              v-bind:class="{
                redButtonFaded: !dob,
                redButtonSolid: dob
              }"
              :disabled="!dob"
              @click="dob = null"
            >
              clear
            </button>
          </div>
          <FieldErrorMessage
            :message="errors.first('commemorated_birth_date')"
          />
        </div>

        <!-- DOD -->
        <div>
          <label class="text-normal-bold mb-2">
            <span class="text-red-star">*</span>Date of Death
          </label>
          <div class="flex flex-row mt-2">
            <v-datepicker
              mode="single"
              v-model="dod"
              :max-date="todaysDate"
              :min-date="dob"
            >
              <input
                :value="dod"
                slot-scope="{ inputProps, updateValue }"
                class="w-full mr-4 input-area"
                v-bind="inputProps"
                @input="e => updateDate(e, updateValue)"
                @change="setDodNullIfBlank"
                :disabled="readOnly"
                autocomplete="off"
                placeholder="Enter Date (DD/MM/YYYY)"
                data-cypress="commemorated_death_date"
                v-validate="{
                  required: !commemoration_date_unknown,
                  date: !commemoration_date_unknown
                }"
                name="commemorated_death_date"
                data-vv-as="Date of Death"
              />
            </v-datepicker>
            <button
              class="ml-2 w-1/8"
              v-bind:class="{
                redButtonFaded: !dod,
                redButtonSolid: dod
              }"
              @click="dod = null"
              :disabled="!dod"
            >
              clear
            </button>
          </div>
          <FieldErrorMessage
            :message="errors.first('commemorated_death_date')"
          />
        </div>

        <!-- UNKNOWN DATE -->
        <Checkbox
          class="pt-8 pl-0"
          data-cypress="commemorated_dates_unknown-field"
          label="The Date of Birth or Death is unknown"
          v-model="commemoration_date_unknown"
          :disabled="(dob && dod) || readOnly"
        ></Checkbox>
      </div>

      <!-- UNKNOWN REASON -->
      <label class="w-2/3 mb-4" v-if="commemoration_date_unknown">
        <p class="text-normal-bold mb-2">
          <span class="text-red-star">*</span>
          Please explain why the Date of Birth or Death is unknown
        </p>
        <div class="w-full mt-3 flex">
          <textarea
            class="w-full h-48 input-area"
            data-cypress="commemorated_dates_unknown_description-field"
            v-model="commemoration_date_unknown_reason"
            name="commemoration_date_unknown_description"
            :disabled="readOnly"
            data-vv-as="unknown commemoration date"
            v-validate="{ required: commemoration_date_unknown }"
          ></textarea>
        </div>
        <FieldErrorMessage
          :message="errors.first('commemoration_date_unknown_description')"
        />
      </label>

      <!-- ASSOCIATION -->
      <label class="w-2/3">
        <p class="text-normal-bold mb-2 mt-4">
          <span class="text-red-star">*</span>What is the person's association
          with this feature and their main contribution to the local community?
        </p>
        <div class="w-full mt-3 flex">
          <textarea
            class="w-full h-48 input-area"
            data-cypress="commemoration_association-field"
            v-model="commemoration_association"
            v-validate="'required'"
            name="commemorated_association_description"
            data-vv-as="Association"
            :disabled="readOnly"
          ></textarea>
        </div>
        <FieldErrorMessage
          :message="errors.first('commemorated_association_description')"
        />
      </label>

      <!-- SOURCE -->
      <label class="w-2/3">
        <p class="text-normal-bold mb-2 mt-8">
          What is the source for this information?
        </p>
        <div class="w-full mt-3 flex">
          <textarea
            class="w-full h-48 input-area"
            data-cypress="commemoration_source-field"
            v-model="commemoration_source"
            :disabled="readOnly"
          ></textarea>
        </div>
      </label>

      <!-- FILES -->
      <uploadScoped
        class="mt-8"
        label="Attach supporting documentation"
        :important="false"
        :files="commemorated_supporting"
        :drafted="true"
        stateKey="road_name"
        stateProp="commemorated_supporting"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'road_name',
              prop: 'commemorated_supporting',
              index: fileIndex
            })
        "
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No supported documentation was uploaded. Please upload required files
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";

export default {
  name: "CommemoratePersonComponent",

  inject: ["$validator"],

  /**
   * Local instance properties
   */
  props: {
    /**
     * Whether the fields are disabled
     * due to an external event (such as
     * a save in progress)
     */
    readOnly: {
      type: Boolean,
      default: false
    },

    /**
     * The array index of the road
     */
    roadIndex: {
      type: Number,
      default: 0
    }
  },

  /**
   * Local instance data
   **/
  data() {
    return {
      source_reference_document: null,
      dateFormat: "YYYY-MM-DD",
      readableDateFormat: "DD/MM/YYYY"
    };
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * The date today
     */
    todaysDate() {
      return moment().format("YYYY-MM-DD");
    },

    /**
     * The current road name proposal
     */
    roadname() {
      return this.$store.getters.roadNameProposal;
    },

    /**
     * Does this road name commemorate a person
     */
    commemorated: {
      get() {
        return this.roadname.commemorated;
      },
      set(isCommemorated) {
        this.$store.commit("setCommemoration", { isCommemorated });
      }
    },

    /**
     * The current commemoration state
     */
    isCommemorated() {
      return this.commemorated === true;
    },

    /**
     * The full name of the commemorated person
     */
    name: {
      get() {
        return this.roadname.commemorated_full_name;
      },
      set(name) {
        this.$store.commit("setCommemoratedPerson", { name });
      }
    },

    /**
     * The date of birth of the commemorated person
     */
    dob: {
      get() {
        return this.roadname.commemorated_birth_date
          ? moment(
              this.roadname.commemorated_birth_date,
              this.dateFormat
            ).format(this.readableDateFormat)
          : null;
      },
      set(dob) {
        this.$store.commit("setCommemoratedPersonDob", {
          dob: dob
            ? moment(dob, this.readableDateFormat).format(this.dateFormat)
            : null
        });
      }
    },

    /**
     * The date of death of the commemorated person (if known)
     */
    dod: {
      get() {
        return this.roadname.commemorated_death_date
          ? moment(
            this.roadname.commemorated_death_date,
            this.dateFormat
          ).format(this.readableDateFormat)
          : null;
      },
      set(dod) {
        this.$store.commit("setCommemoratedPersonDod", {
          dod: dod
            ? moment(dod, this.readableDateFormat).format(this.dateFormat)
            : null
        });
      }
    },

    /**
     * Whether the date of death is unknown
     */
    commemoration_date_unknown: {
      get() {
        return this.roadname.commemorated_dates_unknown;
      },
      set(isUnknown) {
        this.$store.commit("setCommemorationDateUnknown", {
          isUnknown
        });
      }
    },

    /**
     * Explanation as to why  the date of birth or death is unknown
     */
    commemoration_date_unknown_reason: {
      get() {
        return this.roadname.commemorated_dates_unknown_description;
      },
      set(reason) {
        this.$store.commit("setCommemorationDateUnknownReason", {
          reason
        });
      }
    },

    /**
     * The association of the commemorated person with the local community
     */
    commemoration_association: {
      get() {
        return this.roadname.commemorated_association_description;
      },
      set(association) {
        this.$store.commit("setCommemorationAssociation", {
          association
        });
      }
    },

    /**
     * The source of the commemoration information
     */
    commemoration_source: {
      get() {
        return this.roadname.commemorated_source;
      },
      set(source) {
        this.$store.commit("setCommemorationSource", {
          source
        });
      }
    },

    /**
     * Supporting documentation attached to the commemoration
     */
    commemorated_supporting() {
      return this.roadname.commemorated_supporting;
    },

    /**
     * Should we disable the unknown date checkbox
     */
    shouldDisableUnknown() {
      return !!(this.dob && this.dod);
    }
  },

  /**
   * Value watchers
   */
  watch: {
    /**
     * react to changes to the disable unknown flag
     */
    shouldDisableUnknown(shouldDisable) {
      if (shouldDisable) {
        this.commemoration_date_unknown = false;
      }
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapMutations({
      deleteUploadedFile: "removeUploadedCommemorationFile"
    }),
    ...mapActions({
      uploadFile: "uploadCommemorationFile"
    }),
    updateDate: debounce((e, updateFunc) => {
      if (
        /^[\d]{1,2}\/[\d]{1,2}\/[\d]{4}$/.test(e.target.value) &&
        moment(e.target.value, "D/M/YYYY").isValid()
      ) {
        //convert to YYYY-MM-DD so it interprets date correctly
        updateFunc(""); //  clear first so it detects it as an update. This is a but sloppy but seems to work.
        updateFunc(moment(e.target.value, "DD/MM/YYYY").format("YYYY-MM-DD"));
      }
    }, 1200),
    setDobNullIfBlank(e) {
      if (e.target.value == "") {
        this.dob = null;
      }
    },
    setDodNullIfBlank(e) {
      if (e.target.value == "") {
        this.dod = null;
      }
    }
  }
};
</script>
