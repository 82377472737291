<template>
  <div v-if="!confirmingLinkage">
    <div class="flex justify-between text-sm text-grey text-xs-bold">
      <div class="w-12 mr-4"></div>
      <div class="w-1/5">Place Name</div>
      <div class="w-1/5">LGA</div>
      <div class="w-1/5">Designation</div>
      <div class="w-1/5 text-center">Status</div>
      <div class="w-1/6"></div>
    </div>
    <result-card
      v-for="result in results"
      :result="result"
      :source="geoName"
      :key="result.identifier"
      @link-geoname="linkGeoname"
    />

    <section
      v-if="!$wait.is(`fetchingGeonames`) && results && results.length"
      class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6"
    >
      <div class="mx-auto text-center self-center">
        <paginate
          v-model="search.page"
          :page-count="Math.ceil(resultCount / search.limit)"
          :page-range="10"
          :margin-pages="1"
          :click-handler="changePage"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
        <p>
          Showing
          {{ ((search.page - 1) * search.limit + 1).toLocaleString() }}
          to
          {{
            Math.min(
              (search.page - 1) * search.limit + search.limit,
              resultCount
            ).toLocaleString()
          }}
          of
          {{ resultCount.toLocaleString() }}
          place names
        </p>
      </div>
    </section>
  </div>
  <div v-else>
    <link-confirmation-dialogue
      v-if="!linkConfirmed"
      :source="geoName"
      :target="linkingGeoname"
      @cancel-linkage="cancelLinkage"
      @confirm-linkage="linkageConfirmed"
    />
    <process-confirmed-link v-else :source="geoName" :target="linkingGeoname" />
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import ResultCard from "./ResultCard";
import LinkConfirmationDialogue from "./LinkConfirmationDialogue";
import ProcessConfirmedLink from "./ProcessConfirmedLink";

export default {
  name: "DualNamedSearchResultList",

  /**
   * Instance properties
   */
  props: {
    /**
     * the source geoName to be linked from
     */
    geoName: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * The results of the search
     */
    results: {
      type: Array,
      default: function() {
        return [];
      }
    },

    /**
     * The search criteria
     */
    search: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * The total count of results
     */
    resultCount: {
      type: String
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {
      confirmingLinkage: false,
      linkingGeoname: null,
      linkConfirmed: false
    };
  },

  /**
   * Instance child components
   */
  components: {
    Paginate,
    ResultCard,
    LinkConfirmationDialogue,
    ProcessConfirmedLink
  },

  /**
   * Instance methods
   */
  methods: {
    /**
     * Navigate the search results
     */
    changePage(page) {
      this.$emit("page-change", { page });
    },

    /**
     * The user chose a geoname to link to
     */
    linkGeoname(payload) {
      this.confirmingLinkage = true;
      this.linkingGeoname = payload.geoname;

      this.$emit("confirming-link");
    },

    /**
     * The user decided not to link the geonames
     */
    cancelLinkage() {
      this.confirmingLinkage = false;
      this.linkingGeoname = null;

      this.$emit("cancel-confirm");
    },

    /**
     * Linking with the target placename was confirmed
     */
    linkageConfirmed(payload) {
      this.linkConfirmed = true;
    }
  }
};
</script>
