var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "autocomplete" },
      [
        _c(
          "multiSelect",
          {
            staticClass: "w-full rounded-lg text-normal mb-1 mt-1",
            attrs: {
              multiple: false,
              disabled: _vm.disabled,
              options: _vm.roadTypes,
              searchable: true,
              "close-on-select": true,
              placeholder: "Select Road Type...",
              name: "roadTypeList",
              "data-cypress": "select-roadtype-input",
            },
            on: { "search-change": _vm.filterRoadTypeArray },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          [
            _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }