var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "bg-white shadow overflow-hidden sm:rounded-lg mb-6" },
      [
        _c(
          "div",
          { staticClass: "px-4 py-5 border-b border-gray-200 sm:px-6" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "text-3xl leading-6 font-medium text-gray-900 mb-6",
              },
              [_vm._v(" Current Place Name Proposals ")]
            ),
            _c("p", { staticClass: "mb-3 text-sm" }, [
              _vm._v(
                " The Geographical Names Board is seeking comment on the proposals below. "
              ),
            ]),
            _c("p", { staticClass: "mb-3 text-sm" }, [
              _vm._v(" By selecting the relevant proposal, you can: "),
            ]),
            _c("ul", { staticClass: "mb-3 text-sm" }, [
              _c("li", [_vm._v("view details of the proposal")]),
              _c("li", [_vm._v("view a map of the proposal")]),
              _c("li", [_vm._v("lodge your submission")]),
            ]),
            _c("p", { staticClass: "text-sm" }, [
              _vm._v(
                " All submissions will be considered prior to the proposal being officially assigned. "
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }