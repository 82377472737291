var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        clickToClose: false,
        name: "edit-geoname",
        width: "40%",
        height: "auto",
        classes: "rounded-lg bg-white py-6",
        scrollable: true,
      },
    },
    [
      _c(
        "div",
        { staticClass: "w-9/10 mx-auto flex flex-col justify-center" },
        [
          _c(
            "a",
            {
              staticClass: "cursor-pointer ml-auto",
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_c("errorIcon", { attrs: { classNames: "w-5" } })],
            1
          ),
          _c(
            "h2",
            {
              staticClass:
                "w-full flex justify-center font-bold text-black mt-12",
            },
            [_vm._v(" Confirm Changes ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full flex text-justify text-md text-black mt-10 px-16 py-2 justify-center",
            },
            [
              _vm._v(
                " Do the changes necessitate updates to the Spatial Ops team? "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full flex text-justify text-md text-black mt-10 mb-4 px-16 py-2 justify-center",
            },
            [
              _vm._v(
                " Please confirm that you have considered the implications of this change - in particular for Interested Parties. It is your responsibility to inform them of any material change you have made to this record. "
              ),
            ]
          ),
          _c("div", { staticClass: "flex justify-between pt-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex w-full flex-col justify-between content-between pt-4 pb-4 mx-4 mb-4 text-center",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "button-blue-hollow m-4",
                    on: {
                      click: function ($event) {
                        return _vm.saveChanges()
                      },
                    },
                  },
                  [_vm._v(" Save Changes ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button-blue m-4",
                    on: {
                      click: function ($event) {
                        return _vm.saveChangesAndNotify()
                      },
                    },
                  },
                  [_vm._v(" Save & Notify Spatial Ops ")]
                ),
                _c(
                  "button",
                  { staticClass: "button-red m-4", on: { click: _vm.hide } },
                  [_vm._v(" Cancel Changes ")]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }