<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="fixed h-12 logoPos"
    />

    <div class="w-2/3 mx-auto my-3 rounded">
      <intro-card />
      <pending-proposals
        :pendingPlaceNames="pendingPlaceNames"
        :pendingLocalities="pendingLocalities"
      />

      <current-proposals
        :currentPlaceNames="currentPlaceNames"
        :currentLocalities="currentLocalities"
      />

      <expired-proposals
        :expiredPlaceNames="expiredPlaceNames"
        :expiredLocalities="expiredLocalities"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { waitFor } from "vue-wait";
import IntroCard from "./IntroCard";
import pnpCRUD from "@/helpers/pnpCRUD";
import PendingProposals from "./PendingProposals";
import CurrentProposals from "./CurrentProposals";
import ExpiredProposals from "./ExpiredProposals";

export default {
  name: "afterSubmit",

  /**
   * Instance child components
   */
  components: {
    IntroCard,
    PendingProposals,
    CurrentProposals,
    ExpiredProposals
  },

  /**
   * Instance computed properties
   */
  computed: {
    pendingPlaceNames() {
      return this.proposals.naming.future;
    },
    pendingLocalities() {
      return this.proposals.locality.future;
    },
    currentPlaceNames() {
      return this.proposals.naming.current;
    },
    currentLocalities() {
      return this.proposals.locality.current;
    },
    expiredPlaceNames() {
      return this.proposals.naming.expired;
    },
    expiredLocalities() {
      return this.proposals.locality.expired;
    }
  },

  /**
   * Instance created lifecycle event
   */
  async created() {
    this.fetchProposals();
  },

  /**
   * Local instance data
   */
  data() {
    return {
      proposals: {
        naming: {
          future: [],
          expired: [],
          current: []
        },
        locality: {
          future: [],
          expired: [],
          current: []
        }
      }
    };
  },

  /**
   * Local instance methods
   */
  methods: {
    /**
     * Fetch the proposals from the server
     */
    fetchProposals: waitFor("fetching proposals", async function() {
      try {
        const proposals = await pnpCRUD.getAdvertisedProposals();
        this.proposals = proposals;
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve proposals",
          text: error
        });
      }
    })
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}
</style>
