<template>
  <ShowLoading isLoading="fetching user">
    <template slot="component">
      <div class="w-full">
        <div class="base-container">
          <section class="flex justify-start flex-1 py-6">
            <!--  <div class="flex-grow flex justify-between"> -->
            <a
              @click="$router.go(-1)"
              class="cursor-pointer flex-0"
              data-cypress="back-arrow"
            >
              <BackArrowWithTitle title="User Profile" />
            </a>
            <div class="flex-1 flex justify-end">
              <ChangePasswordModal
                class="flex-0 mx-2"
                :user="user"
                v-if="isCurrentUsersProfile"
              />
              <div class="flex-0 ml-2">
                <router-link
                  :to="{ name: 'profile/edit', params: { id: user.email } }"
                  v-if="isCurrentUsersProfile"
                  data-cypress="edit-account-link"
                  class="
                    block
                    text-center
                    button-blue-hollow
                    border-2
                    font-bold
                    py-4
                    px-8
                    rounded
                    focus:outline-none
                    focus:shadow-outline
                  "
                  >Edit Account</router-link
                >
              </div>
            </div>
            <!--  </div> -->
          </section>

          <NotAuthorisedMessage v-if="unauthorised" />

          <section
            class="pt-1 justify-center w-full flex flex-col self-center"
            v-if="!unauthorised"
          >
            <div
              class="flex justify-between bg-white text-black rounded-lg mb-4"
            >
              <div class="w-9/10 mx-auto flex">
                <div class="w-1/3 mx-auto flex items-center py-5">
                  <!-- icon -->
                  <div
                    class="
                      icon
                      relative
                      w-24
                      h-24
                      rounded-full
                      mr-5
                      bg-grey-lighter
                      text-center
                      pt-8
                    "
                  >
                    <font-awesome-icon icon="user" class="text-4xl" />
                  </div>
                  <!-- user details -->
                  <div class="flex-1 mr-4">
                    <h3 class="h-auto mb-2" data-cypress="user-full-name">
                      {{ name }}
                    </h3>
                    <p class="text-normal" data-cypress="user-authority-name">
                      {{ user.authority_name || user.organisation_name }}
                    </p>
                  </div>
                </div>
                <div class="dividing-line-v"></div>
                <div
                  class="
                    w-2/3
                    mx-auto
                    justify-between
                    items-center
                    flex
                    py-4
                    px-4
                  "
                >
                  <!-- user types -->
                  <UserProperty
                    name="User Type"
                    class="flex-0 flex flex-col justify-between items-start"
                  >
                    <div class="flex flex-col" data-cypress="user-type">
                      <div
                        class="
                          account-type
                          flex-1 flex flex-row
                          justify-between
                          text-black
                        "
                        v-for="type in accountType"
                        v-bind:key="type"
                      >
                        <Tree
                          v-if="type === 'PNP'"
                          class="flex-none text-black w-6 h-6 pr-2"
                        />
                        <Road
                          v-if="type === 'RNP'"
                          class="flex-none menu-img text-black w-6 h-6 pr-2"
                        />
                        <span class="flex-auto" data-cypress="user-type-item">{{
                          type
                        }}</span>
                      </div>
                    </div>
                  </UserProperty>
                  <!-- date created -->
                  <div
                    class="
                      flex-1 flex flex-row
                      justify-between
                      items-center
                      mx-4
                    "
                  >
                    <div class="mx-auto flex-0 flex flex-col">
                      <UserProperty
                        name="Date Created"
                        :value="dateCreated"
                        class="flex-1 flex flex-col justify-between items-start"
                        data-cypress="date-created"
                      />
                    </div>
                  </div>

                  <div>
                    <!-- account status -->
                    <div class="flex-0 flex justify-end">
                      <div class="flex-1 flex items-center justify-center">
                        <AccountStatus
                          :account_status="user.user_account_status"
                          data-cypress="user-account-status"
                        />
                      </div>
                    </div>
                    <!-- delete button -->
                    <!-- Appends a delete button to the user profile page. -->
                    <!-- Only admin users can delete users -->
                    <div
                      v-if="loggedUser.isAdmin"  
                      class="flex-0 flex justify-end"
                    >
                      <div class="flex-1 flex items-center justify-center">
                        <DeleteUserModal
                          :user="user"
                          :isFromUserProfile="true"
                          :loggedUser="loggedUser"
                          :passedRoadNameProposals="roadNameProposals"
                          :passedPlaceNameProposals="placeNameProposals"
                        />
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <div
              class="w-full flex bg-white text-black rounded-lg items-baseline"
            >
              <div class="w-9/10 mx-auto flex">
                <div class="w-1/6 flex items-center">
                  <!-- user details -->
                  <h3 class="h-auto">Contact Info</h3>
                </div>
                <div class="dividing-line-v"></div>
                <div class="w-5/6 flex justify-between p-8">
                  <!-- phone -->
                  <div class="flex-0 flex flex-col justify-between items-start">
                    <p class="text-label flex-0">Phone number</p>
                    <a
                      data-cypress="user-telephone-link"
                      :href="telLink"
                      rel="tel"
                      class="flex-1 flex align-center"
                    >
                      <font-awesome-icon
                        icon="phone"
                        class="pr-2 text-xl flex-none"
                      />
                      <span class="telephone-link text-black flex-none">{{
                        user.phone_no
                      }}</span>
                    </a>
                  </div>
                  <!-- email address-->
                  <div
                    class="
                      flex-1 flex flex-row
                      justify-between
                      items-center
                      mx-4
                    "
                  >
                    <div class="mx-auto flex-0 flex flex-col">
                      <p class="text-label flex-1">Email Address</p>
                      <a
                        data-cypress="user-email-link"
                        class="text-black pb-2 pr-4 flex-1 flex align-center"
                        :title="user.email"
                        :href="mailLink"
                        ><font-awesome-icon
                          icon="envelope"
                          class="pr-2 text-2xl flex-none"
                        />
                        <span class="text-black flex-none">{{
                          user.email
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <!-- position -->
                  <UserProperty
                    name="Position"
                    :value="user.position"
                    class="flex-0 flex flex-col justify-between items-start"
                    data-cypress="user-position"
                  />
                </div>
              </div>
            </div>
          </section>
          <section v-if="isRNPView && !unauthorised">
            <ShowLoading isLoading="fetching roadname proposals">
              <template v-slot:component>
                <div v-if="roadNameProposals.length">
                  <h2 class="text-black mt-16">Roadname Proposals</h2>
                  <div class="flex-0">
                    <div
                      v-if="loggedUser.isAdmin"
                      class="
                        px-3
                        py-3
                        md:mb-0
                        text-black
                        ml-auto
                        flex
                        items-center
                      "
                    >
                      <div class="mr-3 py-3 justify-center md:mb-0">
                        <label class="container items-baseline flex height-fix">
                          Check All Proposals
                          <input
                            class="checkbox-input"
                            type="checkbox"
                            :checked="checkAll"
                            @change="checkAllProposals"
                          />
                          <span class="border-2 checkmark"></span>
                        </label>
                      </div>
                      <BulkReassignmentsModal
                        :proposals="checkedProposals"
                        :fromUser="user"
                        :btnDisabled="
                          checkedProposals.identifiers.length === 0
                            ? true
                            : false
                        "
                        @passStatusOfReassignment="reRender($event)"
                      />
                    </div>
                  </div>
                  <section
                    class="
                      justify-center
                      w-full
                      block
                      self-center
                      bg-white
                      text-black
                      rounded-lg
                      pt-8
                      mt-3
                      mb-3
                    "
                    v-for="(proposal, index) in roadNameProposals"
                    v-bind:key="index"
                  >
                    <RoadNameProposalListItem
                      :proposal="proposal"
                      :reassign="true"
                      :index="index"
                      :checkAll="checkAll"
                      :untickProposal="untickProposal"
                      @passProposalIdentifier="
                        updateCheckedProposalList($event)
                      "
                    />
                  </section>
                </div>
                <div
                  class="
                    self-center
                    justify-center
                    flex
                    mt-32
                    text-black
                    font-bold
                    text-2xl
                    w-full
                  "
                  v-if="!roadNameProposals.length"
                >
                  {{
                    isCurrentUsersProfile
                      ? "You have"
                      : `${user.first_name} ${user.last_name} has`
                  }}
                  not made any proposals yet.
                </div>
              </template>
            </ShowLoading>
          </section>
          <section v-if="!isRNPView && !unauthorised">
            <ShowLoading isLoading="fetching placename proposals">
              <template v-slot:component>
                <div v-if="placeNameProposals.length">
                  <h2 class="text-black mt-16">Placename Proposals</h2>
                  <div class="flex-0">
                    <div
                      v-if="loggedUser.isAdmin"
                      class="
                        px-3
                        py-3
                        md:mb-0
                        text-black
                        ml-auto
                        flex
                        items-center
                      "
                    >
                      <div class="mr-3 py-3 justify-center md:mb-0">
                        <label class="container items-baseline flex height-fix">
                          Check All Proposals
                          <input
                            class="checkbox-input"
                            type="checkbox"
                            :checked="checkAll"
                            @change="checkAllProposals"
                          />
                          <span class="border-2 checkmark"></span>
                        </label>
                      </div>
                      <BulkReassignmentsModal
                        :proposals="checkedProposals"
                        :fromUser="user"
                        :btnDisabled="
                          checkedProposals.identifiers.length === 0
                            ? true
                            : false
                        "
                        @passStatusOfReassignment="reRender($event)"
                      />
                    </div>
                  </div>
                  <section
                    class="
                      justify-center
                      w-full
                      block
                      self-center
                      bg-white
                      text-black
                      rounded-lg
                      pt-8
                      mt-3
                      mb-3
                    "
                    v-for="(proposal, index) in placeNameProposals"
                    v-bind:key="index"
                  >
                    <PlaceNameProposalListItem
                      :proposal="proposal"
                      :index="index"
                      :checkAll="checkAll"
                      :untickProposal="untickProposal"
                      @passProposalIdentifier="
                        updateCheckedProposalList($event)
                      "
                    />
                  </section>
                </div>
                <div
                  class="
                    self-center
                    justify-center
                    flex
                    mt-32
                    text-black
                    font-bold
                    text-2xl
                    w-full
                  "
                  v-if="!placeNameProposals.length"
                >
                  {{
                    isCurrentUsersProfile
                      ? "You have"
                      : `${user.first_name} ${user.last_name} has`
                  }}
                  not made any proposals yet.
                </div>
              </template>
            </ShowLoading>
          </section>
          <section
            class="
              justify-center
              w-full
              block
              self-center
              text-center text-black
              rounded-lg
              pb-4
              mt-3
              mb-6
            "
            v-if="!unauthorised"
          >
            <div
              v-if="
                !$wait.is(`fetching roadname proposals`) &&
                !$wait.is(`fetching placename proposals`) &&
                (roadNameProposals.length || placeNameProposals.length)
              "
              class="mx-auto text-center self-center"
            >
              <paginate
                v-model="page"
                :page-count="Math.ceil(totalProposals / limit)"
                :page-range="10"
                :margin-pages="1"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :click-handler="scrollToTop"
              >
              </paginate>
              <p>
                Showing
                {{ ((page - 1) * limit + 1).toLocaleString() }}
                to
                {{
                  Math.min(
                    (page - 1) * limit + limit,
                    totalProposals
                  ).toLocaleString()
                }}
                of
                {{ totalProposals.toLocaleString() }}
                proposals
              </p>
            </div>
          </section>
        </div>
      </div>
    </template>
  </ShowLoading>
</template>

<script>
import Paginate from "vuejs-paginate";

import userDetailsMixin from "../../mixins/user-details";
import ChangePasswordModal from "./changePassword";

import Road from "../../assets/Images/road-dark-blue.svg";
import Tree from "../../assets/Images/tree-dark-blue.svg";
import UserProperty from "./userProperty";
import { getUser } from "../../helpers/userCRUD.js";
import { waitFor } from "vue-wait";
import pnpCrud from "../../helpers/pnpCRUD";
import RoadNameProposalListItem from "../proposals/road/proposal-list-item";
import PlaceNameProposalListItem from "../proposals/place/proposal-list-item";
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { getAllRoadnameProposals } from "../../helpers/proposalsCRUD";
import NotAuthorisedMessage from "../elements/unauthorisedMessage";
import BulkReassignmentsModal from "../proposals/common/bulkReassignmentsModal";
import DeleteUserModal from "../proposals/common/deleteUserModal";

export default {
  name: "userProfile",
  components: {
    ChangePasswordModal,
    UserProperty,
    Tree,
    Road,
    PlaceNameProposalListItem,
    RoadNameProposalListItem,
    Paginate,
    NotAuthorisedMessage,
    BulkReassignmentsModal,
    DeleteUserModal
  },
  mixins: [userDetailsMixin],
  async mounted() {
    await this.getUser(this.$route.params.id);
    await this.searchProposals();
  },
  computed: {
    ...mapUserGetters({ loggedUser: "user" }),
    ...mapGetters(["isRNPView", "showLoginModal"]),
    isCurrentUsersProfile() {
      return this.loggedUser.email === this.user.email;
    }
  },
  data() {
    return {
      unauthorised: false,
      user: {
        address_line_1: "",
        address_line_2: "",
        authority_name: "",
        date_created: "",
        date_modified: "",
        email: "",
        first_name: "",
        id: "",
        identifier: "",
        isPNP: "",
        isPublic: "",
        isRNP: "",
        isAdmin: "",
        last_name: "",
        organisation_id: "",
        organisation_name: "",
        phone_no: "",
        pnp_identifier: "",
        position: "",
        postcode: "",
        resource_id: "",
        rnp_identifier: "",
        road_naming_authority_id: "",
        state: "",
        suburb: "",
        user_account_status: "",
        user_id: ""
      },
      roadNameProposals: [],
      placeNameProposals: [],
      totalProposals: 0,
      page: 1,
      limit: 10,
      checkAll: false,
      untickProposal: false,
      checkedProposals: {
        identifiers: [],
        jiraIds: []
      }
    };
  },
  methods: {
    getUser: async function (id) {
      try {
        this.$wait.start("fetching user");
        this.user = await getUser(id);
      } catch (e) {
        if (e.response.data.statusCode === 400) this.unauthorised = true;
      } finally {
        this.$wait.end("fetching user");
      }
    },

    getRoadNameProposals: waitFor(
      "fetching roadname proposals",
      async function () {
        try {
          if (this.user.isRNP) {
            const result = await getAllRoadnameProposals({
              proposer_id: this.user.identifier,
              page: this.page,
              limit: this.limit,
              includeArchived: true
            });
            this.roadNameProposals = result.proposals;
            this.totalProposals = result.total;
            this.totalProposals = parseInt(result.total);
          }
        } catch (e) {
          this.roadNameProposals = [];
          this.totalProposals = 0;
          this.$notify({
            group: "toast",
            type: "error",
            title: "Roadname Proposals",
            text: e.response.data.message
          });
        }
      }
    ),
    getPlaceNameProposals: waitFor(
      "fetching placename proposals",
      async function () {
        try {
          if (this.user.isPNP || this.user.isPublic) {
            const result = await pnpCrud.searchPlacenameProposals({
              email: this.user.email,
              page: this.page,
              limit: this.limit,
              includeTotal: true,
              includeArchived: true
            });

            this.placeNameProposals = result.proposals;
            this.totalProposals = parseInt(result.totalProposals);
          }
        } catch (e) {
          this.placeNameProposals = [];
          this.totalProposals = 0;
          this.$notify({
            group: "toast",
            type: "error",
            title: "Placename Proposals",
            text: e.response.data.message
          });
        }
      }
    ),
    async searchProposals() {
      if (this.isRNPView) {
        await this.getRoadNameProposals();
      } else {
        await this.getPlaceNameProposals();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    // Checks the status of check box for reassignment in the child component
    updateCheckedProposalList(data) {
      if (data.isChecked) {
        // When the check box gets ticked, add the proposal info into the array
        this.checkedProposals.identifiers.push(data.identifier);
        this.checkedProposals.jiraIds.push(data.jiraId);
      } else {
        // When the check box gets untick, remove the proposal info in the array
        this.checkAll = false;
        this.untickProposal = true;
        let index = this.checkedProposals.identifiers.indexOf(data.identifier);
        this.checkedProposals.identifiers.splice(index, 1);
        index = this.checkedProposals.jiraIds.indexOf(data.jiraId);
        this.checkedProposals.jiraIds.splice(index, 1);
      }
    },
    // Ticks or untick all check boxes
    checkAllProposals() {
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.untickProposal = false;
        // Clears the proposal array
        this.checkedProposals.identifiers = [];
        this.checkedProposals.jiraIds = [];
        if (this.isRNPView) {
          this.roadNameProposals.forEach(proposal => {
            this.checkedProposals.identifiers.push(proposal.identifier);
            this.checkedProposals.jiraIds.push(proposal.jira_id);
          });
        } else {
          this.placeNameProposals.forEach(proposal => {
            this.checkedProposals.identifiers.push(proposal.identifier);
            this.checkedProposals.jiraIds.push(proposal.jira_id);
          });
        }
      } else {
        this.untickProposal = false;
        this.checkedProposals.identifiers = [];
        this.checkedProposals.jiraIds = [];
      }
    },
    // Reloads the proposals to rerender the screen
    async reRender() {
      this.checkedProposals.identifiers = [];
      this.checkedProposals.jiraIds = [];
      this.searchProposals();
    }
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchProposals();
      }
    },
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        await this.getUser(this.$route.params.id);
        await this.searchProposals();
      }
    }
  }
};
</script>

<style scoped>
.dividing-line-h {
  border-bottom-width: 1px;
}
.actions {
  color: #002664;
}
.arrow-icon {
  height: 20px;
  width: 20px;
  color: #d7153a;
}
.text-email {
  color: #002664;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08px;
  line-height: 17px;
}
#userVG {
  fill: grey;
}
.full-margin {
  width: 100%;
  position: relative;
  margin-right: -50vw;
  height: 100px;
  margin-top: 100px;
  /* left: 50%; */
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}
.saveButton {
  color: #ffffff;
  background-color: #002664;
  border-color: #002664;
}

.grey-bg {
  background-color: #ecf1f3;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

.yellowButton {
  color: #ffffff;
  border-color: #f9be00;
  background-color: #f9be00;
}
.greenButton {
  color: #00a908;
  border-color: #00a908;
}
.redButton {
  color: #c60c30;
  border-color: #c60c30;
}
.redStar {
  height: 27px;
  width: 21.75px;
  color: #d7153a;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.inputfield {
  background: #ecf1f3;
}
.odd-even:nth-child(even) {
  font-weight: 300;
  background: #f4f7f9;
}

.odd-even:nth-child(odd) {
  background: #ffffff;
  font-weight: 300;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #002664;
  background-color: #ffffff;
  transition-duration: 0.2s;
  border-radius: 2px;
}
input:disabled ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #5b5e63;
  background-color: rgb(194, 193, 193);
  transition-duration: 0.2s;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #84bddc;
  border-color: #002664;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #d8d8d8;
  border-color: #464a52;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #002664;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.height-fix {
  min-height: 24px;
}
</style>
