var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "base-container text-black h-auto flex flex-col pt-6 px-2 pb-14 mb-8",
      attrs: { "data-cypress": "delete-box" },
    },
    [
      _c(
        "a",
        {
          staticClass: "cursor-pointer ml-auto",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_c("errorIcon", { attrs: { classNames: "w-4" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex items-center content-center justify-center mt-8 px-10",
        },
        [
          _vm.organisation.organisation_status == "approved"
            ? _c("h2", [_vm._v(" Suspend Organisation ")])
            : _c("h2", [_vm._v(" Approve Organisation ")]),
        ]
      ),
      _c(
        "div",
        { staticClass: "w-full flex justify-center text-black mb-4 px-10" },
        [_vm._v(" " + _vm._s(_vm.organisation.organisation_name) + " ")]
      ),
      _vm.organisation.organisation_status == "approved"
        ? _c("div", { staticClass: "flex text-black mb-8 text-normal px-10" }, [
            _vm._v(
              " Are you sure you want to suspend the above organisation? "
            ),
          ])
        : _c("div", { staticClass: "flex text-black mb-8 text-normal px-10" }, [
            _vm._v(" Are you sure you want approve the above organisation? "),
          ]),
      _c("div", { staticClass: "flex justify-center w-full" }, [
        _c(
          "button",
          {
            staticClass:
              "button-blue-hollow mr-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none",
            attrs: { disabled: _vm.isWaiting, "data-cypress": "nokeep-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" Close Dialog ")]
        ),
        _vm.organisation.organisation_status == "approved"
          ? _c(
              "button",
              {
                staticClass:
                  "button-red ml-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none",
                attrs: {
                  disabled: _vm.isWaiting,
                  "data-cypress": "yesdelete-button",
                },
                on: {
                  click: function ($event) {
                    return _vm.confirm("suspended")
                  },
                },
              },
              [_vm._v(" Suspend organisation ")]
            )
          : _c(
              "button",
              {
                staticClass:
                  "button-green ml-4 w-1/2 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none",
                attrs: {
                  disabled: _vm.isWaiting,
                  "data-cypress": "yesdelete-button",
                },
                on: {
                  click: function ($event) {
                    return _vm.confirm("approved")
                  },
                },
              },
              [_vm._v(" Approve organisation ")]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }