var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "flex flex-col authenticationForm bg-white shadow-md rounded-lg pl-6 lg:w-11/12 md:mr-4 pl-12 pr-12 pt-10 pb-10 mb-4",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }