var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "update-lga",
            maxWidth: 600,
            height: "auto",
            classes: "rounded-lg bg-white",
            scrollable: false,
            clickToClose: false,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full mx-auto pt-6 pb-10 px-6 text-black flex flex-col text-normal",
            },
            [
              _c(
                "a",
                {
                  staticClass: "cursor-pointer ml-auto",
                  on: {
                    click: function ($event) {
                      return _vm.hide()
                    },
                  },
                },
                [_c("ErrorIcon", { attrs: { classNames: "w-4" } })],
                1
              ),
              _c("div", { staticClass: "px-10 pb-1 text-full" }, [
                _vm._v(" Are you sure you want to update all geoname LGAs? "),
              ]),
              _c(
                "div",
                { staticClass: "flex justify-center w-full mt-10 px-10" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button-red-hollow mr-4 w-2/5",
                      on: {
                        click: function ($event) {
                          return _vm.hide()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue ml-4 w-2/5",
                      attrs: {
                        type: "button",
                        disabled: _vm.$wait.is("updating lgas"),
                        isSpinning: _vm.$wait.is("updating lgas"),
                      },
                      on: { click: _vm.updateLGAs },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "base-container text-black flex flex-grow" }, [
        _c(
          "section",
          { staticClass: "justify-start w-full block flex flex-col" },
          [
            _c(
              "ShowLoading",
              { attrs: { isLoading: "fetching cron state" } },
              [
                _c(
                  "template",
                  { slot: "component" },
                  [
                    _vm.errorLoading ? _c("NetworkError") : _vm._e(),
                    !_vm.errorLoading
                      ? _c("div", [
                          _c(
                            "h1",
                            {
                              staticClass: "text-title",
                              attrs: { "data-cypress": "header" },
                            },
                            [_vm._v(" LGA Update Service ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "pb-3 mb-8 w-full text-title dividing-line-h",
                                },
                                [_vm._v(" Spatial Portal ")]
                              ),
                              _c("div", { staticClass: "flex mb-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "w-2/3 text-justify pr-2" },
                                  [
                                    _vm._v(
                                      ' When "Enabled" this service will query the Spatial Portal with the longitude and latitude of 1000 geonames every hour for the first week of each month. '
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "w-1/3 flex pr-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "ml-auto" },
                                    [
                                      _c("event-rule-toggle", {
                                        attrs: {
                                          disabled:
                                            _vm.$wait.is("toggling event"),
                                        },
                                        model: {
                                          value: _vm.enabled,
                                          callback: function ($$v) {
                                            _vm.enabled = $$v
                                          },
                                          expression: "enabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "w-2/3 text-justify pr-2" },
                                  [
                                    _vm._v(
                                      " Or you can query 1000 geonames right now "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "w-1/3 flex pr-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "ml-auto" },
                                    [
                                      _c(
                                        "ButtonWithSpinner",
                                        {
                                          staticClass: "button-blue",
                                          attrs: {
                                            disabled:
                                              _vm.$wait.is("querying Spatial"),
                                            isSpinning:
                                              _vm.$wait.is("querying Spatial"),
                                          },
                                          on: { click: _vm.queryNow },
                                        },
                                        [_vm._v("Query Now")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "pb-3 mb-8 w-full text-title dividing-line-h",
                                },
                                [_vm._v(" Update LGAs ")]
                              ),
                              _c("div", { staticClass: "flex mb-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "w-2/3 text-justify pr-2" },
                                  [
                                    _vm._v(
                                      ' Update the LGA of all geoname records with the "LGA Spatial" for those records '
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "w-1/3 flex pr-2" }, [
                                  _c("div", { staticClass: "ml-auto" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn button-blue",
                                        attrs: {
                                          disabled:
                                            _vm.$wait.is("querying Spatial"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$modal.show("update-lga")
                                          },
                                        },
                                      },
                                      [_vm._v("Update LGAs")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }