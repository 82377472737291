<template>
  <span class="block w-full text-center" style="width: 113px">-</span>
</template>

<script>
export default {
  name: "NoActionComponent",

  /***/
  data() {
    return {};
  },
  /***/
  components: {}
};
</script>
