var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-10 flex flex-grow items-center justify-start flex-row" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col flex-auto flex-grow items-center justify-center",
        },
        [
          _c("Loading", { staticClass: "flex-none" }),
          _c("span", { staticClass: "pt-2 text-black" }, [
            _vm._v("Loading..."),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }