<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <div class="w-5/6 mx-auto" v-bind:class="{ 'py-16': !isPublic }">
        <h3 class="pb-3 mb-4 w-full text-title dividing-line-h">
          Lot and Plan Information
        </h3>
        <p class="mb-8" v-if="!isTypePlaceNameProposal">
          Please provide lot and plan information or address details of affected
          properties
        </p>
        <p class="mb-8" v-if="isTypePlaceNameProposal">
          Please provide lot and plan information if available
        </p>

        <div class="w-full flex mb-2">
          <p class="w-3%"></p>
          <p class="text-normal-bold w-1/6 mr-4 mr-4">Lot</p>
          <p class="text-normal-bold w-1/6 mr-4">Section</p>
          <p class="text-normal-bold w-1/6 mr-4">Plan</p>
          <p class="text-normal-bold mr-4 flex-grow">Address</p>
          <p class=""></p>
        </div>

        <div
          class="w-full flex items-center mt-3"
          v-for="(lot, index) in lots"
          :key="index"
        >
          <p class="text-grey text-sub-title w-3%">
            {{ index + 1 }}
          </p>
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            v-bind:value="lot.lot"
            disabled
          />
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            v-bind:value="lot.section"
            disabled
          />
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            v-bind:value="lot.plan"
            disabled
          />
          <input
            type="text"
            class="rounded p-4 mr-4 text-normal text-grey-text bg-grey-input flex-grow self-center"
            v-bind:value="lot.address"
            disabled
          />

          <div
            class=" flex-grow justify-center p-4 items-center cursor-pointer"
            @click="remove(index)"
            v-bind:class="{
              redButtonFaded: readOnly,
              redButtonSolid: !readOnly
            }"
          >
            Remove
          </div>
        </div>

        <div class="w-full flex items-center mt-3">
          <p class="text-grey text-sub-title w-3%">
            {{ lots.length + 1 || "" }}
          </p>
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Enter lot..."
            data-cypress="lot-input"
            v-model="newLot.lot"
            :disabled="readOnly"
          />
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Enter Section..."
            data-cypress="section-input"
            v-model="newLot.section"
            :disabled="readOnly"
          />
          <input
            type="text"
            class="w-1/6 rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
            placeholder="Enter Plan..."
            data-cypress="plan-input"
            v-model="newLot.plan"
            :disabled="readOnly"
          />
          <input
            type="text"
            class="rounded p-4 mr-4 text-normal text-grey-text bg-grey-input flex-grow self-center"
            placeholder="Enter address..."
            data-cypress="lotaddress-input"
            v-model="newLot.address"
            :disabled="readOnly"
          />

          <div
            class=" flex-grow justify-center p-4 items-center cursor-pointer"
            data-cypress="add-lot-button"
            @click="addNewLot"
            :disabled="readOnly"
            v-bind:class="{
              greenButtonFaded: readOnly,
              greenButtonSolid: !readOnly
            }"
          >
            Add Lot
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_BOUNDARY
} from "@/components/proposals/place/pnp-constants.js";
const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations
} = createNamespacedHelpers("pnpState");

export default {
  data() {
    return {
      newLot: {
        lot: "",
        section: "",
        plan: "",
        address: ""
      }
    };
  },
  props: {
    proposalType: {
      type: String
    },
    readOnly: {
      type: Boolean
    },
    isPublic: {
      type: Boolean
    }
  },
  computed: {
    isTypePlaceNameProposal() {
      return this.proposalType === PROPOSAL_TYPE_PLACE;
    },
    isTypeNewAmendedLocality() {
      return this.proposalType === PROPOSAL_TYPE_LOCALITY;
    },
    isTypeLocalityBoundaryAmendment() {
      return this.proposalType === PROPOSAL_TYPE_BOUNDARY;
    },
    lots: {
      get() {
        return this.getLots();
      }
    }
  },
  watch: {
    proposalType: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal) {
          this.resetLots();
        }
      }
    }
  },
  methods: {
    addNewLot() {
      if (this.readOnly) return;
      this.addLot(this.newLot);
      this.newLot = {
        lot: "",
        section: "",
        plan: "",
        address: ""
      };
    },
    remove(index) {
      if (this.readOnly) return;
      this.removeLot(index);
    },
    ...mapPnpGetters(["getLots"]),
    ...mapPnpMutations(["addLot", "removeLot", "resetLots"])
  }
};
</script>

<style>
.greenButtonSolid {
  background-color: #00a908;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.greenButtonFaded {
  background-color: #00a90876;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: default;
}

.redButtonSolid {
  background-color: #c60c30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.redButtonFaded {
  background-color: #c60c3170;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: default;
}
</style>
