<template>
  <Layout>
    <template v-slot:content>
      <authenticationContent />
    </template>

    <template v-slot:form>
      <passwordResetStart v-if="$route.name === 'reset'" :email="email" />
      <passwordResetEnd
        v-if="$route.name === 'resetEnd'"
        :email="email"
        :recoveryCode="recoveryCode"
      />
    </template>
  </Layout>
</template>

<script>
import Layout from "../components/authentication/authentication-layout";
import passwordResetStart from "@/components/authentication/passwordResetStart.vue";
import passwordResetEnd from "@/components/authentication/passwordResetEnd.vue";
import authenticationContent from "@/components/authentication/authencticationContent.vue";

export default {
  name: "Reset",
  props: ["email", "recoveryCode"],
  components: {
    passwordResetStart,
    passwordResetEnd,
    authenticationContent,
    Layout
  }
};
</script>
