<template>
  <showLoading isLoading="loadingorganisationList">
    <template v-slot:component>
      <div class="flex flex-col flex-grow" data-cypress="user-list">
        <div
          v-for="(organisation, index) in organisations"
          v-bind:key="index"
          class="flex flex-row bg-white text-black rounded-lg mt-4 overflow-hidden"
        >
          <organisationListItem :organisation="organisation">
            <slot :organisation="organisation"></slot>
          </organisationListItem>
        </div>
      </div>
    </template>
  </showLoading>
</template>

<script>
import organisationListItem from "./organisationListItem";
import showLoading from "../elements/showLoading";

export default {
  name: "organisationList",
  components: {
    showLoading,
    organisationListItem
  },
  props: ["organisations"]
};
</script>

<style scoped>
.actions {
  color: #002664;
}
#userSVG {
  fill: grey;
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}

.dividing-line-v-2 {
  border-left-width: 1px;
}

.wait-container {
  padding-top: 0;
}
</style>
