<template>
  <div>
    <NetworkError v-if="fetchError" />
    <showLoading v-if="!fetchError" isLoading="loadingProposal">
      <template v-slot:component>
        <div>
          <createProposal
            v-if="isDraft"
            :proposalBucket="roadNameProposalBucket"
            :isDraft="isDraft"
          />
          <viewProposal
            v-if="!isDraft"
            :proposalBucket="roadNameProposalBucket"
            :reloadPage="reloadPage"
          />
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  createNamespacedHelpers
} from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { mapWaitingGetters } from "vue-wait";
import createProposal from "./proposal-create";
import viewProposal from "./proposal-view";

export default {
  name: "ProposalBucket",
  components: {
    createProposal,
    viewProposal
  },
  data: function() {
    return {
      fetchError: false
    };
  },
  computed: {
    ...mapGetters(["roadNameProposalBucket"]),
    ...mapUserGetters(["user"]),
    ...mapWaitingGetters(["loading proposal"]),
    isDraft() {
      return (
        this.$route.params.proposalId === "create" ||
        this.$store.getters.roadNameProposalBucket.status === "Drafted"
      );
    },
    proposalId() {
      return this.$route.params.proposalId;
    }
  },
  methods: {
    ...mapActions(["getRoadNameProposalBucketById"]),
    ...mapMutations(["clearState"]),
    async reloadPage(proposalId) {
      this.$wait.start("loadingProposal");
      await this.getRoadNameProposalBucketById(proposalId);
      this.$wait.end("loadingProposal");
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.$wait.start("loadingProposal");
      if (to.params.proposalId !== "create") {
        if (
          !this.isDraft ||
          this.proposalId !== this.roadNameProposalBucket.identifier
        ) {
          await this.getRoadNameProposalBucketById(to.params.proposalId);
        }
      } else if (from.params.proposalId !== "create") {
        this.clearState();
      }
      this.$wait.end("loadingProposal");
      next();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      this.$wait.end("loadingProposal");
      if (e.response.status === 500) {
        this.fetchError = true;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        vm.$wait.start("loadingProposal");
        if (to.params.proposalId === "create" && vm.user.isASP) {
          vm.$router.push({ name: "proposals" });
        }
        if (to.params.proposalId !== "create") {
          if (
            !vm.isDraft ||
            vm.proposalId !== vm.roadNameProposalBucket.identifier ||
            !new RegExp(`proposals/road-names/${vm.proposalId}`).test(from.path)
          ) {
            await vm.getRoadNameProposalBucketById(to.params.proposalId);
          }
        } else if (from.params.proposalId !== "create") {
          vm.clearState();
        }
        vm.$wait.end("loadingProposal");
      } catch (e) {
        if (e.response.status === 404) {
          return await next({ name: "proposals" });
        }
        if (e.response.status === 500) {
          vm.fetchError = true;
        }
        vm.$wait.end("loadingProposal");
      }
    });
  }
};
</script>
