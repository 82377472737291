var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "justify-center w-full block self-center bg-white text-black rounded-lg px-16 py-16",
      },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c(
            "button",
            {
              staticClass: "p-3 button-blue w-full mb-4",
              on: {
                click: function ($event) {
                  return _vm.goToRoute("geoNameSearchPublic")
                },
              },
            },
            [_vm._v(" Geoname Search ")]
          ),
          _c(
            "button",
            {
              staticClass: "p-3 button-blue w-full mb-4",
              on: {
                click: function ($event) {
                  return _vm.goToRoute("currentPlaceNameProposals")
                },
              },
            },
            [_vm._v(" Comment on Place Name Proposal ")]
          ),
          _c(
            "button",
            {
              staticClass: "p-3 button-blue w-full mb-4",
              on: {
                click: function ($event) {
                  return _vm.goToRoute("placeNameProposalPublic")
                },
              },
            },
            [_vm._v(" Create a Place Name Proposal ")]
          ),
          _c(
            "button",
            {
              staticClass: "p-3 button-blue w-full mb-4",
              on: {
                click: function ($event) {
                  return _vm.goToRoute("suburbSearch")
                },
              },
            },
            [_vm._v(" Suburb Search ")]
          ),
          _c(
            "button",
            {
              staticClass: "p-3 button-blue w-full mb-4",
              on: {
                click: function ($event) {
                  return _vm.goToRoute("roadNameEvaluationPublic")
                },
              },
            },
            [_vm._v(" Road Name Evaluation ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }