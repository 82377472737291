var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        tabs__light: _vm.mode === "light",
        tabs__dark: _vm.mode === "dark",
      },
    },
    [
      _c(
        "ul",
        { staticClass: "tabs__header" },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "li",
            {
              key: tab.title,
              class: { tab__selected: index == _vm.selectedIndex },
              on: {
                click: function ($event) {
                  return _vm.selectTab(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(tab.title) + " ")]
          )
        }),
        0
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }