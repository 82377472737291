<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-2xl leading-6 font-medium text-gray-900">
        Proposals Open for Public Comment
      </h3>
      <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        The following proposals are currently open for public comment.
      </p>
    </div>
    <div>
      <proposals-list
        heading="Place Name Proposals"
        :proposals="currentPlaceNames"
      />
      <proposals-list
        heading="Address Locality Proposals"
        :proposals="currentLocalities"
      />
    </div>
  </div>
</template>

<script>
import ProposalsList from "./ProposalsList";

export default {
  name: "CurrentProposalsCard",

  /**
   * Instance properties
   */
  props: {
    /**
     * The placename proposals to display in the list
     */
    currentPlaceNames: {
      type: Array,
      default: function() {
        return [];
      }
    },

    /**
     * The address locality proposals to display in the list
     */
    currentLocalities: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    ProposalsList
  }
};
</script>
