var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "absolute h-12 logoPos",
        attrs: {
          src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
        },
      }),
      _c(
        "div",
        { staticClass: "w-2/3 bg-white mx-auto my-3 z-10 rounded mt-32" },
        [
          _c(
            "div",
            { staticClass: "w-full bg-white text-black rounded-lg p-10 px-24" },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "text-red text-normal-bold cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.back()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "flex-auto",
                      attrs: {
                        icon: "arrow-left",
                        "data-cypress": "back-button",
                      },
                    }),
                    _vm._v(" Back "),
                  ],
                  1
                ),
              ]),
              _c(
                "h3",
                { staticClass: "pb-3 mb-8 w-full text-title dividing-line-h" },
                [_vm._v(" Comment on Place Name Proposal ")]
              ),
              _c("showLoading", {
                attrs: { isLoading: "fetchingProposalForComment" },
                scopedSlots: _vm._u([
                  {
                    key: "component",
                    fn: function () {
                      return [
                        _vm.proposal
                          ? _c(
                              "div",
                              [
                                _c("div", { staticClass: "text-black" }, [
                                  _c("div", { staticClass: "pb-8" }, [
                                    _c("p", [
                                      _vm._v(
                                        " The form below seeks your views on this specific proposal. All feedback is considered by the NSW Geographical Names Board. Please provide as much detail as you can to assist the Board to understand your views on the proposal. "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full border flex flex-row border-gray-200 rounded items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-black font-bold text-2xl mx-8",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.proposal.geographical_name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dividing-line-v text-black flex-grow flex flex-row",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4",
                                            },
                                            [
                                              _c("span", [_vm._v("LGA")]),
                                              _vm._l(
                                                _vm.proposal.lga,
                                                function (lga) {
                                                  return _c("p", { key: lga }, [
                                                    _vm._v(_vm._s(lga)),
                                                  ])
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-grow pb-4 pt-4 w-1/4 pr-4",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Designation"),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.proposal.designation
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-grow pb-4 pt-4 w-1/4",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Publication Date"),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      _vm.proposal.date_start
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-grow pb-4 pt-4 w-1/4",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Closing Date"),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      _vm.proposal.date_end
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "text-normal-bold mt-8" },
                                    [
                                      _c(
                                        "a",
                                        { staticClass: "text-red-star" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "Do you support the above proposal? "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-row text-normal",
                                    },
                                    [
                                      _c(
                                        "RadioButton",
                                        {
                                          staticClass: "flex-0 mr-6 mt-3 mb-1",
                                          attrs: {
                                            name: "supportive-yes",
                                            "data-cypress": "supportive-yes",
                                            checkedValue: true,
                                          },
                                          model: {
                                            value: _vm.is_supportive,
                                            callback: function ($$v) {
                                              _vm.is_supportive = $$v
                                            },
                                            expression: "is_supportive",
                                          },
                                        },
                                        [_vm._v("I support")]
                                      ),
                                      _c(
                                        "RadioButton",
                                        {
                                          staticClass: "flex-0 mr-6 mt-3 mb-1",
                                          attrs: {
                                            name: "supportive-no",
                                            "data-cypress": "supportive-no",
                                            checkedValue: false,
                                          },
                                          model: {
                                            value: _vm.is_supportive,
                                            callback: function ($$v) {
                                              _vm.is_supportive = $$v
                                            },
                                            expression: "is_supportive",
                                          },
                                        },
                                        [_vm._v("I object")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("label", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-normal-bold mb-2 mt-8",
                                    },
                                    [
                                      _c(
                                        "a",
                                        { staticClass: "text-red-star" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "What is your interest in this proposal? "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "w-full mt-3 flex flex-col",
                                    },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.interest,
                                            expression: "interest",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "w-full h-48 input-area",
                                        attrs: {
                                          "data-cypress": "interest",
                                          maxlength: _vm.interestMaxLength,
                                          name: "interest",
                                          "data-vv-as": "Interest in Proposal",
                                        },
                                        domProps: { value: _vm.interest },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.interest = $event.target.value
                                          },
                                        },
                                      }),
                                      _c("FieldErrorMessage", {
                                        attrs: {
                                          message: _vm.errors.first("interest"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("label", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-normal-bold mb-2 mt-8",
                                    },
                                    [
                                      _c(
                                        "a",
                                        { staticClass: "text-red-star" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "What is your reason for supporting/objecting this proposal? "
                                      ),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " If objecting, please outline where this proposal does not comply with the relevant policies in the "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=10",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("GNB Place Naming Policy")]
                                    ),
                                    _vm._v(" and/or "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0007/199411/NSW_Addressing_User_Manual.pdf#page=105",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("NSW Addressing User Manual")]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "w-full mt-3 flex" },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.comment,
                                            expression: "comment",
                                          },
                                        ],
                                        staticClass: "w-full h-48 input-area",
                                        attrs: { "data-cypress": "comment" },
                                        domProps: { value: _vm.comment },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.comment = $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _c("uploadScoped", {
                                  staticClass: "mt-8",
                                  attrs: {
                                    label: "Add Supporting Documentation",
                                    important: false,
                                    files:
                                      _vm.public_comment_reference_document,
                                    drafted: true,
                                    stateProp:
                                      "public_comment_reference_document",
                                    uploadFile: _vm.uploadFileForComponent,
                                    name: "public_comment_reference_document",
                                  },
                                  on: {
                                    deleteFile: (fileIndex) =>
                                      _vm.public_comment_reference_document.splice(
                                        fileIndex,
                                        1
                                      ),
                                  },
                                }),
                                _c("div", { staticClass: "mt-8" }, [
                                  _c("div", { staticClass: "mx-auto" }, [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "pb-3 mb-8 w-full text-title dividing-line-h",
                                      },
                                      [_vm._v(" Contact Details ")]
                                    ),
                                    _c("div", { staticClass: "w-full flex" }, [
                                      _c(
                                        "div",
                                        { staticClass: "w-1/2" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-normal-bold mt-4 mb-2",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("First Name "),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.first_name,
                                                expression: "first_name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass:
                                              "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Enter First Name Here...",
                                              "data-cypress": "firstname",
                                              name: "first_name",
                                              "data-vv-as": "First Name",
                                            },
                                            domProps: { value: _vm.first_name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.first_name =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c("FieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("first_name"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "w-1/2 ml-8" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-normal-bold mt-4 mb-2",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("Last Name "),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.last_name,
                                                expression: "last_name",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass:
                                              "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Enter Last Name Here...",
                                              "data-cypress": "lastname",
                                              name: "last_name",
                                              "data-vv-as": "Last Name",
                                            },
                                            domProps: { value: _vm.last_name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.last_name =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c("FieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("last_name"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "w-full" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mt-4 mb-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "text-red-star" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("Address Line 1 "),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.address_line_1,
                                              expression: "address_line_1",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass:
                                            "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Enter Address Here...",
                                            "data-cypress": "address1",
                                            name: "address_line_1",
                                            "data-vv-as": "Address",
                                          },
                                          domProps: {
                                            value: _vm.address_line_1,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.address_line_1 =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _c("FieldErrorMessage", {
                                          attrs: {
                                            message:
                                              _vm.errors.first(
                                                "address_line_1"
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "w-full" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-normal-bold mt-4 mb-2",
                                        },
                                        [_vm._v(" Address Line 2 ")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.address_line_2,
                                            expression: "address_line_2",
                                          },
                                        ],
                                        staticClass:
                                          "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "e.g. Apartment number, floor number, etc",
                                          "data-cypress": "address2",
                                          name: "address_line_2",
                                        },
                                        domProps: { value: _vm.address_line_2 },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.address_line_2 =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "w-full flex items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "w-1/2 mr-8" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-normal-bold mt-4 mb-2",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-red-star",
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v("Suburb/Town "),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.suburb,
                                                  expression: "suburb",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass:
                                                "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Enter Suburb Here...",
                                                "data-cypress": "suburb",
                                                name: "suburb",
                                                "data-vv-as": "Suburb",
                                              },
                                              domProps: { value: _vm.suburb },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.suburb =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c("FieldErrorMessage", {
                                              attrs: {
                                                message:
                                                  _vm.errors.first("suburb"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-1/2 flex items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/2 mr-8 mt-8 mb-2",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-normal-bold",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-red-star",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v("State "),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full mr-4 norns-select",
                                                  attrs: {
                                                    multiple: false,
                                                    options:
                                                      _vm.australianStates,
                                                    searchable: false,
                                                    "close-on-select": true,
                                                    "select-on-tab": true,
                                                    "show-labels": false,
                                                    "hide-selected": true,
                                                    placeholder:
                                                      "Select a State",
                                                    name: "state",
                                                    "data-cypress":
                                                      "state-field",
                                                    "data-vv-as": "State",
                                                  },
                                                  model: {
                                                    value: _vm.state,
                                                    callback: function ($$v) {
                                                      _vm.state = $$v
                                                    },
                                                    expression: "state",
                                                  },
                                                }),
                                                _c("FieldErrorMessage", {
                                                  attrs: {
                                                    message:
                                                      _vm.errors.first("state"),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "w-1/2" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-normal-bold mt-4 mb-2",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-red-star",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v("Postcode "),
                                                  ]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.postcode,
                                                      expression: "postcode",
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Postcode Here...",
                                                    "data-cypress": "postcode",
                                                    name: "postcode",
                                                    "data-vv-as": "Postcode",
                                                  },
                                                  domProps: {
                                                    value: _vm.postcode,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.postcode =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _c("FieldErrorMessage", {
                                                  attrs: {
                                                    message:
                                                      _vm.errors.first(
                                                        "postcode"
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "w-full flex items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "w-1/2 mr-8" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-normal-bold mt-4 mb-2",
                                              },
                                              [_vm._v(" Phone Number ")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.phone_no,
                                                  expression: "phone_no",
                                                },
                                              ],
                                              staticClass:
                                                "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Enter Phone Number Here...",
                                                "data-cypress": "phone",
                                                name: "phone_no",
                                              },
                                              domProps: { value: _vm.phone_no },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.phone_no =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "w-1/2" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-normal-bold mt-4 mb-2",
                                              },
                                              [
                                                _vm.notify_outcome === true
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-red-star",
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v("Email Address "),
                                              ]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.email,
                                                  expression: "email",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value:
                                                    _vm.notify_outcome === true
                                                      ? "required|email"
                                                      : "",
                                                  expression:
                                                    "\n                        notify_outcome === true ? 'required|email' : ''\n                      ",
                                                },
                                              ],
                                              staticClass:
                                                "w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center",
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Enter Email Address Here...",
                                                "data-cypress": "email",
                                                name: "email",
                                                "data-vv-as": "Email Address",
                                              },
                                              domProps: { value: _vm.email },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.email =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c("FieldErrorMessage", {
                                              attrs: {
                                                message:
                                                  _vm.errors.first("email"),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "w-full" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mt-4 mb-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "text-red-star" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("Country "),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.country,
                                              expression: "country",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass:
                                            "w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "Enter Country Here...",
                                            "data-cypress": "country",
                                            name: "Country",
                                            "data-vv-as": "Address",
                                          },
                                          domProps: { value: _vm.country },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.country = $event.target.value
                                            },
                                          },
                                        }),
                                        _c("FieldErrorMessage", {
                                          attrs: {
                                            message:
                                              _vm.errors.first("Country"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "text-normal-bold mt-12" },
                                      [
                                        _vm._v(
                                          " Do you want to receive an email outcome for this proposal? "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "mt-4" }, [
                                      _vm._v(
                                        " Please note that if you select 'Yes' you will be advised of any decisions by the Board regarding this proposal via email. "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-row text-normal",
                                      },
                                      [
                                        _c(
                                          "RadioButton",
                                          {
                                            staticClass:
                                              "flex-0 mr-6 mt-3 mb-1",
                                            attrs: {
                                              name: "supportive-no",
                                              "data-cypress": "email-yes",
                                              checkedValue: true,
                                            },
                                            model: {
                                              value: _vm.notify_outcome,
                                              callback: function ($$v) {
                                                _vm.notify_outcome = $$v
                                              },
                                              expression: "notify_outcome",
                                            },
                                          },
                                          [_vm._v("Yes")]
                                        ),
                                        _c(
                                          "RadioButton",
                                          {
                                            staticClass:
                                              "flex-0 mr-6 mt-3 mb-1",
                                            attrs: {
                                              name: "supportive-yes",
                                              "data-cypress": "email-no",
                                              checkedValue: false,
                                            },
                                            model: {
                                              value: _vm.notify_outcome,
                                              callback: function ($$v) {
                                                _vm.notify_outcome = $$v
                                              },
                                              expression: "notify_outcome",
                                            },
                                          },
                                          [_vm._v("No")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", {
                                    staticClass:
                                      "flex-grow pb-3 pt-3 ml-3 w-1/3 pr-3",
                                  }),
                                  _c("div", { staticClass: "flex-grow" }, [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-grow" },
                                      [
                                        _c(
                                          "Checkbox",
                                          {
                                            staticClass: "w-full pl-0",
                                            model: {
                                              value:
                                                _vm.personalInformationCollectionNotice,
                                              callback: function ($$v) {
                                                _vm.personalInformationCollectionNotice =
                                                  $$v
                                              },
                                              expression:
                                                "personalInformationCollectionNotice",
                                            },
                                          },
                                          [
                                            [
                                              _vm._v(
                                                " Please tick to acknowledge the "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf",
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " GNB Personal Information Collection Notice "
                                                  ),
                                                ]
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", {
                                    staticClass:
                                      "dividing-line-h w-full pt-4 pb-8 mb-8",
                                  }),
                                  _vm.duplicateComment
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex w-full text-red font-bold",
                                        },
                                        [
                                          _vm._v(
                                            " Thanks for your earlier comment on this proposal. If you wish to make adjustments to your earlier comments, please follow the link in the email confirmation we sent you at the time. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "flex w-full justify-end" },
                                    [
                                      _c(
                                        "Button",
                                        {
                                          staticClass:
                                            "button-red-hollow mt-4 mb-10",
                                          attrs: {
                                            "data-cypress": "cancel",
                                            disabled:
                                              _vm.$wait.is("submittingComment"),
                                          },
                                          on: { click: _vm.backToProposals },
                                        },
                                        [_vm._v("Cancel Comment")]
                                      ),
                                      _c(
                                        "ButtonWithSpinner",
                                        {
                                          staticClass:
                                            "button-blue mt-4 mb-10 ml-4",
                                          attrs: {
                                            "data-cypress": "submit",
                                            isSpinning:
                                              _vm.$wait.is("submittingComment"),
                                            disabled:
                                              _vm.$wait.is(
                                                "submittingComment"
                                              ) ||
                                              !_vm.isFormValid ||
                                              !!_vm.errors.items.length ||
                                              !_vm.personalInformationCollectionNotice,
                                          },
                                          on: { click: _vm.submitComment },
                                        },
                                        [_vm._v("Submit My Comment")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }