<template>
  <div class="main"></div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { waitFor } from "vue-wait";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY
} from "./pnp-constants.js";

export default {
  name: "placeNameProposal",

  async created() {
    await this.fetchProposal();
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        // access to component instance via `vm`
        await vm.selectProposal(vm.proposalId);

        if (!vm.geonameId) {
          throw Error("no geonameId");
        }

        vm.$router.replace({
          name: "geonameEditGNB",
          params: { geonameId: vm.geonameId }
        });
      } catch (e) {
        vm.$router.replace({
          name: "placeNameProposals"
        });
      }
    });
  },
  computed: {
    proposalId() {
      return this.$route.params.proposalId;
    },
    type: {
      get() {
        return this.getProposalType();
      }
    },
    ...mapUserGetters({ currentUser: "user" }),
    geonameId: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE)
          return this.get_place_name_geoname_identifier();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_geoname_identifier();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_geoname_identifier();
        else return null;
      }
    }
  },
  methods: {
    ...mapPnpGetters([
      "get_place_name_geoname_identifier",
      "get_address_locality_geoname_identifier",
      "get_boundary_amendment_geoname_identifier",
      "getProposalType"
    ]),
    ...mapPnpActions(["selectProposal"]),

    redirectToProposal() {
      this.$router.push({
        name: "placeNameProposal",
        params: { proposalId: this.proposalId }
      });
    },
    fetchProposal: waitFor("loading Proposal", async function() {
      try {
        await this.selectProposal(this.proposalId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Retrieval has Failed",
          text: `The retrieval of this Place Name Proposal has failed. ${error}`
        });
      }
    })
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/Images/BG_Places-white.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
