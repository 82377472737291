var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c(
        "ShowLoading",
        { attrs: { isLoading: "fetching redirect data" } },
        [_vm.errorLoading ? _c("NetworkError") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }