var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "backgroundDiv w-screen h-screen flex flex-wrap justify-center items-center",
      },
      [
        _c("img", {
          staticClass: "absolute h-12 logoPos",
          attrs: {
            src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
          },
        }),
        _c(
          "div",
          { staticClass: "w-2/3 bg-white mx-auto mt-36 z-10 rounded" },
          [
            _c(
              "div",
              {
                staticClass:
                  "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap",
              },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "w-full justify-center flex mt-20" },
                    [
                      _c("img", {
                        staticClass: "w-36 h-36",
                        attrs: {
                          src: require("../../../../assets/Images/Icon_Gazettal.png"),
                        },
                      }),
                    ]
                  ),
                  _c("h3", { staticClass: "text-title mb-20 font-bold" }, [
                    _vm._v(
                      " Your Place Name Proposal has been submitted for consideration. "
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }