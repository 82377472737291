var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "no-issue-container" }, [
      _vm.similarRoadnames.issueCount === 0
        ? _c(
            "div",
            { staticClass: "no-issue" },
            [
              _c("font-awesome-icon", {
                staticStyle: { "margin-right": "0.5rem" },
                attrs: { icon: "check" },
              }),
              _vm._v(" 0 potential issues found in the Proposals System "),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm.similarRoadnames.issueCount > 0
      ? _c(
          "div",
          { staticClass: "title-message", on: { click: _vm.toggleLists } },
          [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.similarRoadnames.issueCount} potential issue(s) found in the Proposals System`
                ) +
                " "
            ),
            _c(
              "span",
              [
                _c("font-awesome-icon", {
                  staticClass: "arrow",
                  class: _vm.showList ? "arrow-up" : "arrow-down",
                  attrs: { icon: "chevron-circle-down" },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _c("div", { class: _vm.isFirstOpen ? "display-none" : "" }, [
      _c(
        "div",
        { class: _vm.showList ? "show-lists" : "hide-lists" },
        [
          _vm._l(_vm.listOfIssuesForCurrentPage, function (issue, index) {
            return _c("div", { key: `${issue}-${index}` }, [
              _c(
                "div",
                { staticClass: "issue" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "exclamation" },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(`${issue.shortDescription} (${issue.status})`) +
                      " "
                  ),
                ],
                1
              ),
            ])
          }),
          _c(
            "div",
            { staticClass: "page-box" },
            [
              _c("paginate", {
                attrs: {
                  "page-count": _vm.calculateTotalPageNumber(),
                  "page-range": 10,
                  "margin-pages": 1,
                  "prev-text": "<",
                  "next-text": ">",
                  "container-class": "pagination",
                  "page-class": "page-item",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
              _c("p", [
                _vm._v(
                  " Showing " +
                    _vm._s(
                      (
                        (_vm.currentPage - 1) * _vm.issueLimit +
                        1
                      ).toLocaleString()
                    ) +
                    " to " +
                    _vm._s(
                      Math.min(
                        (_vm.currentPage - 1) * _vm.issueLimit + _vm.issueLimit,
                        _vm.similarRoadnames.issueCount
                      ).toLocaleString()
                    ) +
                    " of " +
                    _vm._s(_vm.similarRoadnames.issueCount.toLocaleString()) +
                    " proposals "
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }