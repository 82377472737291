<template>
  <div class="base-container mb-16">
    <div
      v-if="!results && !$wait.is(`fetchingGeonames`)"
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 px-20 my-3"
    >
      <section class="justify-center w-full block">
        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Place Name Search
        </h3>
        <p class="mb-4" v-if="isPublic">
          Search for a place name or geographic feature in the Geographical Name
          Register (GNR) of NSW to retrieve information about its name.
        </p>
        <ButtonWithSpinner
          v-if="isPublic"
          class="button-blue-hollow flex relative w-2/6 my-8  mr-auto"
          @click="downloadGNRRecords"
          :isSpinning="$wait.is('downloadingGeonames')"
          :disabled="$wait.is('downloadingGeonames')"
          >Download all GNR Records</ButtonWithSpinner
        >
        <div class="flex mb-5">
          <div class="w-1/2 bg-gray-500 mr-4">
            <label class="block text-normal-bold mb-2" for="geographical_name"
              >Place Name</label
            >
            <input
              id="geographical_name"
              type="text"
              v-model="search.geographical_name"
              class="w-full input-area"
              placeholder="Enter all or part of the place name..."
            />
          </div>
          <div class="w-1/2 bg-gray-500">
            <a
              class="block text-normal-bold mb-2"
              title="View Glossary of status values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf"
            >
              Status
            </a>

            <norns-select
              :disabled="!statusArray || readOnly"
              class="w-full norns-select"
              v-model="search.status"
              :multiple="true"
              :options="statusArray"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select a status"
              name="status"
              id="status"
              data-cypress="status-field"
            ></norns-select>
          </div>
        </div>
        <div class="flex mb-5">
          <div class="w-1/2 bg-gray-500 mr-4">
            <a
              class="block text-normal-bold mb-2"
              title="View Glossary of designation values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf"
            >
              Designation</a
            >
            <!-- TODO: populate this when api available -->
            <norns-select
              :disabled="!designations || readOnly"
              class="w-full norns-select"
              v-model="search.designation"
              :multiple="true"
              :options="designations"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select designation(s)"
              name="designation"
              id="designation"
              data-cypress="designation-field"
            ></norns-select>
          </div>
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="lga">LGA</label>
            <norns-select
              :disabled="!lgasArray || readOnly"
              class="w-full norns-select"
              v-model="search.lga"
              :multiple="true"
              :options="lgasArray"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select an LGA"
              name="lga"
              id="lga"
              data-cypress="lga-field"
            ></norns-select>
          </div>
        </div>
        <div class="flex mb-5">
          <div class="w-1/2 bg-gray-500 mr-4">
            <label class="block text-normal-bold mb-2" for="topographical_map"
              >1:25,000 Map Name</label
            >
            <input
              id="topographical_map"
              type="text"
              v-model="search.topographical_map"
              class="w-full input-area"
              placeholder="Enter all or part of the map name"
            />
          </div>
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="parish"
              >Parish</label
            >
            <input
              id="parish"
              type="text"
              v-model="search.parish"
              class="w-full input-area"
              placeholder="Enter all or part of the parish name"
            />
          </div>
        </div>
        <div class="flex mb-5">
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="lga"
              >Dual Named</label
            >
            <select
              class="w-full mt-4 input-area"
              id="dual_named"
              name="dual_named"
              v-model="search.dual_named"
            >
              <option value="" selected>Select Dual Named Status</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </section>
      <div class="flex" name="3 buttons">
        <ButtonWithSpinner
          class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
          data-cypress="delete-button"
          @click="$router.go(-1)"
          :disabled="$wait.any"
          >Cancel</ButtonWithSpinner
        >
        <ButtonWithSpinner
          class="button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto"
          @click="resetSearchForm"
          :isSpinning="false"
          :disabled="false"
          >Reset</ButtonWithSpinner
        >
        <ButtonWithSpinner
          class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
          @click="fetchSearchResults"
          :isSpinning="false"
          :disabled="false"
          >Search</ButtonWithSpinner
        >
      </div>
      <div class="dividing-line-h flex" v-if="isPublic" />
      <section class="justify-center w-full block mb-16" v-if="isPublic">
        <h3 class="pb-3 mb-8 w-full text-title">
          How do I search the GNR?
        </h3>
        <p class="mb-6">
          In the place name field, enter only the simple name component with no
          descriptive elements related to the placename. For example, to search
          for 'Mount Kosciuszko', just enter ‘Kosciuszko’.
        </p>
        <p class="mb-6">
          References to former LGA names, topographic maps and parishes occur in
          the database because the geographical features were originally
          gazetted against these entities.
        </p>
        <p class="mb-6">
          Large features such as rivers and national parks may span multiple
          LGAs, maps and parishes. The values of these attributes stored against
          a feature were considered significant to the feature at time of
          gazettal.
        </p>
        <p class="mb-6">
          If you are unsure which LGA, map or parish you are looking for, it may
          be useful to perform a place name search for a known feature within
          the area and then use the LGA, map or parish name shown on the
          resulting extract as the basis for your search.
        </p>
        <p class="mb-4">
          You can download your search results or alternatively, you can
          download every record in the GNR database.
        </p>
      </section>
    </div>
    <div
      v-if="results || $wait.is(`fetchingGeonames`)"
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3"
    >
      <div
        @click="back()"
        class="px-4 text-red text-normal-bold cursor-pointer"
      >
        <font-awesome-icon icon="arrow-left" class="flex-auto" /> Back
      </div>
      <div class="px-20">
        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Place Name Search Results
        </h3>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            Place Name
          </div>
          <div>{{ search.geographical_name || "None" }}</div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            <a
              title="View Glossary of status values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf"
            >
              Status
            </a>
          </div>
          <div v-if="!search.status.length">None</div>
          <div v-if="search.status.length">
            <p :key="index" v-for="(status, index) in search.status">
              {{ status }}
            </p>
          </div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            <a
              title="View Glossary of designation values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf"
            >
              Designation</a
            >
          </div>
          <div v-if="!search.designation.length">None</div>
          <div v-if="search.designation.length">
            <p :key="index" v-for="(designation, index) in search.designation">
              {{ designation }}
            </p>
          </div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            LGA
          </div>
          <div v-if="!search.lga.length">None</div>
          <div v-if="search.lga.length">
            <p :key="index" v-for="(lga, index) in search.lga">
              {{ lga }}
            </p>
          </div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            1:25,000 Map Name
          </div>
          <div>{{ search.topographical_map || "None" }}</div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            Parish
          </div>
          <div>{{ search.parish || "None" }}</div>
        </div>

        <div class="flex mb-2">
          <div class="text-normal-bold w-1/4">
            Dual Named
          </div>
          <div>{{ search.dual_named || "None" }}</div>
        </div>
        <showResults
          v-if="!$wait.is(`fetchingGeonames`) && results && results.length"
          :results="results"
          :page="queryParams.page"
          :limit="queryParams.limit"
          :resultsCount="resultsCount"
        />
        <fetchingResults v-if="$wait.is(`fetchingGeonames`)" />

        <noResults v-if="resultsCount === 0" />
        <section
          v-if="!$wait.is(`fetchingGeonames`) && results && results.length"
          class="justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6"
        >
          <div class="mx-auto text-center self-center">
            <paginate
              v-model="queryParams.page"
              :page-count="Math.ceil(resultsCount / queryParams.limit)"
              :page-range="10"
              :margin-pages="1"
              :click-handler="changePage"
              :prev-text="'<'"
              :next-text="'>'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
            <p>
              Showing
              {{
                (
                  (queryParams.page - 1) * queryParams.limit +
                  1
                ).toLocaleString()
              }}
              to
              {{
                Math.min(
                  (queryParams.page - 1) * queryParams.limit +
                    queryParams.limit,
                  resultsCount
                ).toLocaleString()
              }}
              of
              {{ resultsCount.toLocaleString() }}
              place names
            </p>
          </div>
        </section>

        <div class="flex justify-between mt-5 mb-10">
          <ButtonWithSpinner
            class="button-blue-hollow flex w-2/6 mt-4 mb-10 ml-4"
            @click="beginNewSearch"
            :isSpinning="false"
            :disabled="false"
            >Begin New Search</ButtonWithSpinner
          >
          <ButtonWithSpinner
            class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
            @click="downloadGNRRecords(search)"
            :isSpinning="$wait.is('downloadingGeonames')"
            :disabled="$wait.is('downloadingGeonames')"
            >Download Search Results</ButtonWithSpinner
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pnpCRUD from "../../../helpers/pnpCRUD";
import { createNamespacedHelpers } from "vuex";
import fetchingResults from "./components/fetchingResults";
import noResults from "./components/noResults";
import showResults from "./components/showResults";
import Paginate from "vuejs-paginate";
import { waitFor } from "vue-wait";
import FileSaver from "file-saver";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  data() {
    return {
      showResultsScreen: false,
      isFetchingResults: false,
      statuses: [],
      designations: [],
      readOnly: false,
      totalRecords: 0,
      lgasArray: [],
      search: {
        geographical_name: null,
        status: [],
        designation: [],
        lga: [],
        map_cma: null,
        parish: null,
        dual_named: ""
      },
      queryParams: {
        page: 1,
        limit: 20
      }
    };
  },
  components: {
    fetchingResults,
    noResults,
    showResults,
    Paginate
  },
  async created() {
    const [
      statuses,
      designations,
      lgasArray,
      gazettalLgasArray
    ] = await Promise.all([
      this.getStatuses(),
      pnpCRUD.getGeonameDesignations(),
      this.isPublic
        ? pnpCRUD.fetchPlaceNameLGAFeatureList()
        : pnpCRUD.fetchPlaceNameFeatureList("LGA"),
      this.isPublic
        ? pnpCRUD.fetchPlaceNameGazettalLGAFeatureList()
        : pnpCRUD.fetchPlaceNameFeatureList("LGA_GAZETTAL")
    ]);
    this.statuses = statuses;
    this.designations = designations;

    //combine lgasArray and gazettalLgasArray

    const combinedArray = lgasArray
      .concat(gazettalLgasArray)
      .reduce((lgaMap, lga) => {
        lgaMap[lga] = true;
        return lgaMap;
      }, {});

    this.lgasArray = Object.keys(combinedArray).sort();
  },
  computed: {
    isPublic() {
      return this.$route.meta && this.$route.meta.isPublic;
    },
    statusArray() {
      return this.statuses;
    },
    results() {
      return this.getGeonameSearchResults();
    },
    resultsCount() {
      return parseInt(this.getGeonameSearchTotal());
    },
    authUser() {
      return this.$store.state.user.user;
    },
    hasAuthUser() {
      return this.authUser !== null;
    },
    downloadUrl() {
      return this.isPublic
        ? pnpCRUD.DOWNLOAD_GEONAME_RECORDS_URL
        : pnpCRUD.AUTH_DOWNLOAD_GEONAME_RECORDS_URL;
    }
  },
  methods: {
    ...mapPnpActions([
      "searchGeonames",
      "searchGeonamesPublic",
      "clearGeonames"
    ]),
    async getStatuses() {
      if (this.isPublic) {
        return await pnpCRUD.getPlaceNameStatusesPublic();
      }

      return await pnpCRUD.getPlaceNameStatuses();
    },
    ...mapPnpGetters(["getGeonameSearchResults", "getGeonameSearchTotal"]),
    resetSearchForm() {
      this.clearGeonames();
      this.search = {
        geographical_name: null,
        status: [],
        designation: [],
        lga: [],
        map_cma: null,
        parish: null,
        dual_named: ""
      };
    },
    fetchSearchResults: waitFor("fetchingGeonames", async function() {
      if (this.isPublic) {
        await this.searchGeonamesPublic({
          ...this.queryParameters()
        });
      } else {
        await this.searchGeonames({
          ...this.queryParameters()
        });
      }
    }),
    /**
     * Handle the different ways we can get hold of
     * the downloaded file
     */
    async downloadResults(url) {
      if (this.isPublic) {
        return (window.location = url);
      }

      return await this.getSecureFileDownloadUrl(url);
    },
    /**
     * Retrieve the file path from the API
     */
    async getSecureFileDownloadUrl(url) {
      const response = await pnpCRUD.csvDownloadUrl(url);
      const s3Params = response.fileUrl.split("/");

      FileSaver.saveAs(response.fileUrl, s3Params[1]);
    },
    downloadGNRRecords: waitFor("downloadingGeonames", async function(
      searchParams
    ) {
      // ;

      if (!searchParams) {
        try {
          await this.downloadResults(this.downloadUrl);
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Cannot download records",
            text: "There was an issue downloading GNR records"
          });
        }
      } else {
        try {
          const url = pnpCRUD.DOWNLOAD_GEONAME_RECORDS_RESULTS_URL(
            this.downloadUrl,
            this.search
          );
          await this.downloadResults(encodeURI(url));
        } catch (error) {
          console.log(error);
          this.$notify({
            group: "toast",
            type: "error",
            title: "Cannot download records",
            text: "There was an issue downloading GNR records"
          });
        }
      }
    }),
    beginNewSearch() {
      this.queryParams.page = 1;
      this.resetSearchForm();
      this.scrollToTop();
    },
    back() {
      this.queryParams.page = 1;
      this.clearGeonames();
      this.scrollToTop();
    },
    queryParameters() {
      return {
        limit: this.queryParams.limit,
        page: this.queryParams.page,
        geographical_name: this.search.geographical_name,
        status: this.search.status,
        designation: this.search.designation,
        lga: this.search.lga,
        map_cma: this.search.topographical_map,
        parish: this.search.parish,
        dual_named: this.search.dual_named
      };
    },
    changePage: waitFor("loading Results", async function(pageNum) {
      this.queryParams.page = pageNum;
      this.scrollToTop();
      await this.fetchSearchResults();
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
