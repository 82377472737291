var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex input-area",
      class: { disabled: _vm.$attrs.disabled },
    },
    [
      _c("input", {
        staticClass: "w-full flex-grow rounded-lg input-autocomplete",
        attrs: {
          "data-cypress": "select-entity-input",
          list: "rnaList",
          name: "rnaList",
          type: "text",
          disabled: _vm.$attrs.disabled,
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.selectRna },
      }),
      _c(
        "datalist",
        { attrs: { id: "rnaList" } },
        [
          _c("option", { attrs: { value: "", default: "", hidden: "" } }, [
            _vm._v("Select An Authority"),
          ]),
          _vm._l(_vm.rnaList, function (rna) {
            return _c(
              "option",
              { key: rna.identifier, domProps: { value: rna.authority_name } },
              [_vm._v(_vm._s(rna.authority_name))]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }