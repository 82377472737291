var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.organisation
    ? _c("div", { staticClass: "pl-4 flex flex-auto flex-row list-item" }, [
        _c(
          "div",
          {
            staticClass:
              "flex sm:flex-col sm:items-start flex-no-grow w-1/4 py-4",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex-auto flex justify-between content-between items-center w-full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "sm:hidden xl:flex w-12 h-12 rounded-full mr-4 bg-grey-lighter justify-center items-center flex-none",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-none" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-2xl",
                          attrs: { icon: "tree" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow flex flex-col justify-start items-start",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "flex-auto pr-2 font-bold cursor-pointer",
                        attrs: {
                          tag: "p",
                          "data-cypress": "organisation-name-link",
                          to: {
                            name: "organisationsView",
                            params: { identifier: _vm.organisation.identifier },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.organisation.organisation_name))]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "dividing-line-v-2 mx-4" }),
        _c(
          "div",
          {
            staticClass:
              "py-4 flex-grow flex sm:flex-row flex-wrap items-stretch justify-around content-start",
          },
          [
            _c("div", { staticClass: "flex-1 flex flex-col justify-start" }, [
              _c("div", { staticClass: "flex-auto text-label" }, [
                _c(
                  "div",
                  { staticClass: "flex-no-grow flex flex-col break-all" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-black pb-2 pr-4 flex-1 flex align-center cursor-default",
                        attrs: { "data-cypress": "organisation-email-link" },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "pr-2 text-2xl flex-none",
                          attrs: { icon: "envelope" },
                        }),
                        _vm._v(" Address "),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "h-18" }, [
                      _vm._v(_vm._s(_vm.organisation.address_line_1)),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.organisation.address_line_2)),
                      _vm.organisation.address_line_2 ? _c("br") : _vm._e(),
                      _vm._v(
                        " " + _vm._s(_vm.organisation.organisation_suburb) + " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.organisation.organisation_state) +
                          " " +
                          _vm._s(_vm.organisation.organisation_postcode)
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("organisationProperty", {
              staticClass: "flex-0 flex flex-col justify-start items-center",
              attrs: {
                name: "Users",
                value: _vm.organisation.members,
                "data-cypress": "date-created",
              },
            }),
            _c("organisationProperty", {
              staticClass: "flex-1 flex flex-col justify-start items-center",
              attrs: {
                name: "Pending Proposals",
                value: _vm.organisation.proposals,
                "data-cypress": "date-created",
              },
            }),
            _c(
              "div",
              { staticClass: "flex-1 flex flex-col justify-center" },
              [
                _c("AccountStatus", {
                  attrs: {
                    account_status: _vm.organisation.organisation_status,
                    "data-cypress": "organisation-account",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "dividing-line-v-2 ml-4" }),
        _c(
          "div",
          {
            staticClass:
              "flex-no-grow flex flex-col sm:items-end sm:justify-center",
          },
          [_vm._t("default")],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }