<template>
  <div>
    <div
      v-if="!results && !$wait.is(`fetchingGeonames`)"
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3"
    >
      <section class="justify-center w-full block">
        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Place Name Search
        </h3>

        <!-- ROW -->
        <div class="flex mb-5">
          <!-- TEXT SEARCH -->
          <div class="w-1/2 bg-gray-500 mr-4">
            <label class="block text-normal-bold mb-2" for="geographical_name"
              >Place Name</label
            >
            <input
              id="geographical_name"
              type="text"
              v-model="search.geographical_name"
              class="w-full input-area"
              placeholder="Enter all or part of the place name..."
            />
          </div>

          <!-- STATUS SEARCH -->
          <div class="w-1/2 bg-gray-500">
            <a
              class="block text-normal-bold mb-2"
              title="View Glossary of status values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0003/59628/Glossary_of_Status_Values_GNB.pdf"
            >
              Status
            </a>

            <norns-select
              :disabled="!statuses || readOnly"
              class="w-full norns-select"
              v-model="search.status"
              :multiple="true"
              :options="statuses"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select a status"
              name="status"
              id="status"
              data-cypress="status-field"
            ></norns-select>
          </div>
        </div>

        <!-- ROW -->
        <div class="flex mb-5">
          <!-- DESIGNATION SEARCH -->
          <div class="w-1/2 bg-gray-500 mr-4">
            <a
              class="block text-normal-bold mb-2"
              title="View Glossary of designation values in the Geographical Names Register"
              target="_blank"
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0011/59627/Glossary_of_Designation_Values.pdf"
            >
              Designation</a
            >
            <norns-select
              :disabled="!designations || readOnly"
              class="w-full norns-select"
              v-model="search.designation"
              :multiple="true"
              :options="designations"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select designation(s)"
              name="designation"
              id="designation"
              data-cypress="designation-field"
            ></norns-select>
          </div>

          <!-- LGA SEARCH -->
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="lga">LGA</label>
            <norns-select
              :disabled="!lgas || readOnly"
              class="w-full norns-select"
              v-model="search.lga"
              :multiple="true"
              :options="lgas"
              :searchable="true"
              :close-on-select="false"
              :select-on-tab="true"
              :show-labels="true"
              :hide-selected="false"
              placeholder="Select an LGA"
              name="lga"
              id="lga"
              data-cypress="lga-field"
            ></norns-select>
          </div>
        </div>

        <!-- ROW -->
        <div class="flex mb-5">
          <!-- MAP SEARCH -->
          <div class="w-1/2 bg-gray-500 mr-4">
            <label class="block text-normal-bold mb-2" for="topographical_map"
              >1:25,000 Map Name</label
            >
            <input
              id="topographical_map"
              type="text"
              v-model="search.topographical_map"
              class="w-full input-area"
              placeholder="Enter all or part of the map name"
            />
          </div>

          <!-- PARISH SEARCH -->
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="parish"
              >Parish</label
            >
            <input
              id="parish"
              type="text"
              v-model="search.parish"
              class="w-full input-area"
              placeholder="Enter all or part of the parish name"
            />
          </div>
        </div>

        <!-- ROW -->
        <div class="flex mb-5">
          <!-- DUAL NAMED SEARCH -->
          <div class="w-1/2 bg-gray-500">
            <label class="block text-normal-bold mb-2" for="lga"
              >Dual Named</label
            >
            <select
              class="w-full mt-4 input-area"
              id="dual_named"
              name="dual_named"
              v-model="search.dual_named"
            >
              <option value="" selected>Select Dual Named Status</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </section>

      <!-- CONTROLS -->
      <div class="flex" name="3 buttons">
        <!-- CANCEL SEARCH -->
        <ButtonWithSpinner
          class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
          @click="$emit('cancel')"
          :disabled="$wait.any"
          >Cancel</ButtonWithSpinner
        >

        <!-- RESET SEARCH -->
        <ButtonWithSpinner
          class="button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-auto"
          @click="resetSearch"
          :isSpinning="false"
          :disabled="false"
          >Reset</ButtonWithSpinner
        >

        <!-- SEARCH SUBMIT -->
        <ButtonWithSpinner
          class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
          @click="searchRecords"
          :isSpinning="false"
          :disabled="false"
          >Search</ButtonWithSpinner
        >
      </div>
    </div>
    <search-results
      :search="query()"
      :geoName="geoName"
      @clear-search="resetSearch"
      @page-change="pageChange"
    />
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import pnpCRUD from "../../../helpers/pnpCRUD";
import SearchResults from "./SearchResultsComponent";
import { mapGetters, createNamespacedHelpers } from "vuex";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  name: "DualNamedSearchComponent",

  /**
   * Instance properties
   */
  props: {
    /**
     * the source geoName to be linked from
     */
    geoName: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * Is the form readOnly
     */
    readOnly: {
      type: Boolean,
      default: false
    },

    /**
     * Flag for closing the parent modal
     */
    isClosing: {
      type: Boolean,
      default: false
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {
      statuses: [],
      designations: [],
      search: {
        geographical_name: null,
        status: [],
        designation: [],
        lga: [],
        map_cma: null,
        parish: null,
        dual_named: ""
      },
      queryParams: {
        page: 1,
        limit: 20
      }
    };
  },

  /**
   * Instance child components
   */
  components: {
    SearchResults
  },

  /**
   * The instance was created
   */
  async created() {
    this.statuses = await pnpCRUD.getPlaceNameStatuses();
    this.designations = await pnpCRUD.getGeonameDesignations();
  },

  /**
   * Instance computed properties
   */
  computed: {
    ...mapGetters({
      lgas: "lgaState"
    }),

    /**
     * The results returned by the geoname search
     */
    results() {
      return this.getGeonameSearchResults();
    }
  },

  /**
   * Instance dta watchers
   */
  watch: {
    isClosing() {
      this.resetSearch();
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapPnpActions(["searchGeonames", "clearGeonames"]),
    ...mapPnpGetters(["getGeonameSearchResults", "getGeonameSearchTotal"]),

    /**
     * Carry out the search
     */
    async searchRecords() {
      this.$wait.start("fetchingGeonames");

      await this.searchGeonames({
        ...this.query()
      });

      setTimeout(() => {
        this.$wait.end("fetchingGeonames");
      }, 450);
    },

    /**
     * Reset the search back to its initial state
     */
    resetSearch() {
      this.clearGeonames();

      this.search = {
        geographical_name: null,
        status: [],
        designation: [],
        lga: [],
        map_cma: null,
        parish: null,
        dual_named: ""
      };
    },

    /**
     * Fetch the search params entered by the user
     */
    query() {
      return {
        limit: this.queryParams.limit,
        page: this.queryParams.page,
        geographical_name: this.search.geographical_name,
        status: this.search.status,
        designation: this.search.designation,
        lga: this.search.lga,
        map_cma: this.search.topographical_map,
        parish: this.search.parish,
        dual_named: this.search.dual_named
      };
    },

    /**
     * The user changed the page
     */
    async pageChange(payload) {
      this.queryParams.page = payload.page;

      await this.searchRecords();
    }
  }
};
</script>
