import { render, staticRenderFns } from "./lga-update.vue?vue&type=template&id=b7e2ffe2"
import script from "./lga-update.vue?vue&type=script&lang=js"
export * from "./lga-update.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b7e2ffe2')) {
      api.createRecord('b7e2ffe2', component.options)
    } else {
      api.reload('b7e2ffe2', component.options)
    }
    module.hot.accept("./lga-update.vue?vue&type=template&id=b7e2ffe2", function () {
      api.rerender('b7e2ffe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lga-update/lga-update.vue"
export default component.exports