<template>
  <div class="field-error-message" v-bind:class="{ hidden: !message }">
    <div class="flex justify-center items-start">
      <errorIcon class="flex-0 mt-1" />
      <div class="flex-1">
        <p
          class="pl-2 text-red-error text-normal-bold"
          data-cypress="error-text"
        >
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FieldErrorMessage",
  props: ["message"]
};
</script>
