var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fetchError ? _c("NetworkError") : _vm._e(),
      !_vm.fetchError
        ? _c("ShowLoading", {
            attrs: { isLoading: "fetching-audit-trail" },
            scopedSlots: _vm._u(
              [
                _vm.trailData
                  ? {
                      key: "component",
                      fn: function () {
                        return [
                          _c("audit-trail", {
                            attrs: { trailData: _vm.trailData },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "summary",
                                  fn: function () {
                                    return [
                                      _c("Summary", {
                                        attrs: {
                                          proposal: _vm.trailData.proposal,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "timeline",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.trailData.timeline,
                                      function (entry, index) {
                                        return _c(
                                          _vm.safeGetTrailEntryComponent(
                                            entry.eventType
                                          ),
                                          {
                                            key: index,
                                            tag: "component",
                                            attrs: { entryData: entry },
                                          }
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2803175756
                            ),
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }