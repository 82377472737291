"use strict";

import { mapWaitingActions } from "vue-wait";
import { mapGetters } from "vuex";

const WAITER = "loading rnas";
// Provides functions used for rnaSelect.vue
// for screens that need a list of RNAs
const rnaListMixin = {
  async created() {
    if (this.user && this.user.isAdmin) {
      if (!this.rnaList.length) {
        await this.getRnaList();
      }
    }
  },
  data() {
    return {
      rnaWaiter: WAITER,
      selectedRNA: null
    };
  },
  computed: {
    ...mapGetters(["rnaList"]),
    rnaName: {
      get() {
        return this.selectedRNA ? this.selectedRNA.authority_name : null;
      },
      set(value) {
        this.setSelectedRNA(value);
      }
    }
  },
  methods: {
    ...mapWaitingActions({
      getRnaList: WAITER
    }),
    setSelectedRNA(rnaId) {
      if (!rnaId) {
        this.selectedRNA = null;
        return;
      }
      const rna = this.rnaList.filter(e => e.identifier === rnaId);
      if (rna[0]) {
        this.selectedRNA = rna[0];
      }
    }
  }
};

export default rnaListMixin;
