var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full flex bg-white mb-6" }, [
    _c(
      "div",
      { staticClass: "w-5/6 mx-auto pt-8 pb-6" },
      [
        _c("div", { staticClass: "border-b-2 mb-6 self-center" }, [
          _c("h3", { staticClass: "w-3/4 inline-block font-bold text-2xl" }, [
            _vm._v(_vm._s(_vm.cardTitle)),
          ]),
          _c("h3", { staticClass: "w-1/4 inline-block text-right text-xs" }, [
            _vm._v(" " + _vm._s(_vm.formattedEventDate) + " "),
          ]),
        ]),
        _vm._t("default"),
        _c("div", {
          staticClass: "w-full flex content-center items-start mt-8",
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }