// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/Images/BG_Login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".fade-enter-active[data-v-112b9c1e],\n.fade-leave-active[data-v-112b9c1e] {\n  transition-duration: .6s;\n  transition-property: opacity;\n  transition-timing-function: ease;\n}\n.fade-enter[data-v-112b9c1e],\n.fade-leave-active[data-v-112b9c1e] {\n  opacity: 0;\n}\n.bg-cover-position[data-v-112b9c1e] {\n  top: 60vh;\n}\n.logo-position[data-v-112b9c1e] {\n  top: 30px;\n  left: 30px;\n}\n.cont[data-v-112b9c1e] {\n  border: 2px solid #002664;\n  border-radius: 99999px;\n}\n.inner[data-v-112b9c1e] {\n  border-radius: 99999px;\n}\n.cont input:checked ~ .inner[data-v-112b9c1e] {\n  background-color: #002664;\n}\n.backgroundDiv[data-v-112b9c1e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  top: 0px;\n}\n.redButtonSolid[data-v-112b9c1e] {\n  background-color: #c60c30;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  line-height: 17px;\n  text-align: center;\n  border-radius: 4px;\n}\n.greenButtonSolid[data-v-112b9c1e] {\n  background-color: #00a908;\n  color: white;\n  font-size: 14px;\n  font-weight: bold;\n  line-height: 17px;\n  text-align: center;\n  border-radius: 4px;\n}\n.blueButtonHollow[data-v-112b9c1e] {\n  border: 2px solid #002664;\n  color: #002664;\n  border-radius: 4px;\n}\n.blueButton[data-v-112b9c1e] {\n  border-radius: 4px;\n  border: 2px solid #002664;\n  background-color: #002664;\n  color: white;\n}\n.w-37[data-v-112b9c1e] {\n  width: 37%;\n}\n", ""]);
// Exports
module.exports = exports;
