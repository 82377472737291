<template>
  <tr class=" text-gray font-medium border border-gray-300 ">
    <td
      v-for="(column, index) in columns"
      class="pl-2 pr-4 py-4 "
      :key="`${index}-${columnState[index].isDesc}`"
    >
      <div class="flex flex-col items-start" v-if="isShowing(column.field)">
        <div class="flex flex-row" v-if="column.hideColumnLabel !== true">
          <div class="flex-grow pr-2">
            {{ column.label }}
          </div>
          <div
            v-if="column.isSortable && columnState[index]"
            class="cursor-pointer"
          >
            <font-awesome-icon
              :key="columnState[index].isAsc"
              :icon="
                column.type === 'date' ? `sort-numeric-up-alt` : `sort-alpha-up`
              "
              class="flex-auto mr-2"
              @click="sortAsc(index, column.field)"
              v-bind:class="{
                selectedFilter: columnState[index].isAsc,
                nonSelectedFilter: !columnState[index].isAsc
              }"
            />
          </div>
          <div
            v-if="column.isSortable && columnState[index]"
            class="cursor-pointer"
          >
            <font-awesome-icon
              :key="columnState[index].isDesc"
              :icon="
                column.type === 'date'
                  ? `sort-numeric-down`
                  : `sort-alpha-down-alt`
              "
              class="flex-auto"
              @click="sortDesc(index, column.field)"
              v-bind:class="{
                selectedFilter: columnState[index].isDesc,
                nonSelectedFilter: !columnState[index].isDesc
              }"
            />
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: "Header",
  props: {
    columnState: {
      type: Array
    },
    columns: {
      type: Array
    },
    filterLists: {
      type: Object
    },
    selectedColumns: {
      type: Array
    }
  },
  methods: {
    isShowing(field) {
      return !!this.selectedColumns.find(column => column === field);
    },
    sortDesc(index, field) {
      this.$emit("sortDesc", {
        index: index,
        field: field
      });
    },
    sortAsc(index, field) {
      this.$emit("sortAsc", {
        index: index,
        field: field
      });
    },
    getFilterOptions(key) {
      return this.filterLists[key];
    }
  }
};
</script>
<style scoped>
.selectedFilter {
  color: #2e2e2e !important;
}

.nonSelectedFilter {
  color: #c4c4c4 !important;
}
</style>
