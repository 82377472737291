<template>
  <entry cardTitle="Roadname Guideline Breaches" :eventDate="entryData.createdAt">
    <div class="w-full flex items-start mt-8">
      <div class="pr-4 items-start">
        <p class="text-normal-bold mb-2">Rejection Guidelines</p>
        <table class="rejection-table">
          <tbody>
            <tr v-for="(breach, index) in entryData.breaches" :key="index">
              <td class="px-3 py-2 align-top">{{ breach.guidelineNumber }}</td>
              <td class="px-2 py-2 align-top">
                {{ breach.guidelineDescription }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "RoadNameRejectionGuidelinesTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
