<template>
  <div
    class="w-full mx-auto pt-6 mb-2 px-6 text-black flex flex-col text-normal"
    data-cypress="add-box"
  >
    <a
      @click="hide"
      class="cursor-pointer ml-auto"
      v-if="!$wait.is('processingLink')"
    >
      <errorIcon classNames="w-4" />
    </a>
    <div
      class="flex items-center content-center justify-center mt-6 mb-5 px-10"
    >
      <h2>
        Link to an existing GNR Record
      </h2>
    </div>
    <div class="px-10">
      <search-component
        :isClosing="isClosing"
        :geoName="geoName"
        @cancel="cancelAction"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { mapWaitingActions, mapWaitingGetters } from "vue-wait";
const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import ErrorIcon from "./ErrorIcon";
import SearchComponent from "./SearchComponent";

export default {
  name: "DualNamedGeoNameSearchModal",

  /**
   * Instance properties
   */
  props: ["geoName"],

  /**
   * Instance child components
   */
  components: { ErrorIcon, SearchComponent },

  /**
   * Local instance data
   */
  data() {
    return {
      isClosing: false,
      isComplete: false
    };
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * The placename link process was completed
     */
    placeNameLinkComplete() {
      return this.$wait.is("completeProcess");
    }
  },

  /**
   * Instance value watchers
   */
  watch: {
    placeNameLinkComplete() {
      if (!this.isComplete) {
        this.isComplete = true;
        this.$wait.end("completeProcess");
        this.complete();
      }
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapPnpActions(["linkActionCancelled", "completeLinkingActions"]),

    /**
     * close the modal
     */
    async hide() {
      this.isClosing = true;
      this.linkActionCancelled();

      this.$nextTick(() => {
        this.$emit("close");
      });
    },

    /**
     * The linking process was completed
     */
    complete() {
      this.isClosing = true;
      this.completeLinkingActions();

      this.$nextTick(() => {
        this.$emit("close");
      });
    },

    /**
     * The user cancelled linking action
     */
    cancelAction() {
      this.linkActionCancelled();

      this.hide();
    }
  }
};
</script>
