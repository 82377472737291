var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black flex flex-grow" },
    [
      _c(
        "section",
        { staticClass: "justify-start w-full block flex flex-col" },
        [
          _c(
            "a",
            {
              staticClass: "flex justify-between",
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "roadNameProposalBucket",
                    proposalId: _vm.proposalId,
                  })
                },
              },
            },
            [
              _c("BackArrowWithTitle", {
                attrs: { title: "Create a Gazettal Notice" },
              }),
            ],
            1
          ),
          _c("showLoading", {
            attrs: { isLoading: "fetchingRoadInformation" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    !!_vm.road
                      ? _c("div", [
                          !_vm.isReadyForGazettal
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-1 items-start justify-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-row mt-8" },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass:
                                          "flex-0 text-red text-3xl inline mr-2",
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.road.roadname) +
                                            " " +
                                            _vm._s(_vm.road.road_type) +
                                            " already has a Gazettal notice "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isReadyForGazettal
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full flex bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4 text-center",
                                  },
                                  [
                                    _c("proposalIcon", {
                                      attrs: {
                                        label: _vm.road.roadname_classification,
                                        classNames: "w-10 h-10 text-normal",
                                      },
                                    }),
                                    _c(
                                      "h3",
                                      { staticClass: "self-center ml-4" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.road.roadname) +
                                            " " +
                                            _vm._s(_vm.road.road_type) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("StatusButton", {
                                      staticClass:
                                        "ml-auto mr-6 text-normal font-bold",
                                      attrs: {
                                        label: _vm.road.name_status,
                                        status: _vm.road.name_status,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 pb-16 pt-12",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-9/10 mx-auto flex flex-col",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex w-full" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "w-1/2 text-normal-bold",
                                                attrs: { for: "lgaText" },
                                              },
                                              [
                                                _vm._v(
                                                  " Local Government Area (LGA) "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.road.lga_name,
                                                      expression:
                                                        "road.lga_name",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "flex input-area w-full mt-2",
                                                  attrs: {
                                                    id: "lgaText",
                                                    name: "lgaText",
                                                    type: "text",
                                                    disabled: "",
                                                  },
                                                  domProps: {
                                                    value: _vm.road.lga_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.road,
                                                        "lga_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-1/2 ml-8 text-normal-bold",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "text-normal-bold w-1/2 ml-8 mt-4",
                                                    attrs: { for: "locality" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Address Locality (Suburb) "
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.road
                                                              .suburb_name,
                                                          expression:
                                                            "road.suburb_name",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "flex w-full input-area mt-2 mb-4",
                                                      attrs: {
                                                        id: "locality",
                                                        name: "locality",
                                                        type: "text",
                                                        disabled: "",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.road.suburb_name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.road,
                                                            "suburb_name",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex w-full" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "text-normal-bold w-1/2 mt-4",
                                                attrs: { for: "roadType" },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-red-star",
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v("Road Type "),
                                                _c(
                                                  "norns-select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                                    attrs: {
                                                      name: "Road Type",
                                                      id: "roadType",
                                                      placeholder:
                                                        "Select Road Type...",
                                                      "data-cypress":
                                                        "roadtype-select",
                                                      multiple: false,
                                                      options:
                                                        _vm.roadTypesClean,
                                                      searchable: true,
                                                      "close-on-select": true,
                                                      "select-on-tab": true,
                                                    },
                                                    on: {
                                                      input: _vm.setRoadType,
                                                    },
                                                    model: {
                                                      value: _vm.road.road_type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.road,
                                                          "road_type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "road.road_type",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "noOptions",
                                                        },
                                                        slot: "noOptions",
                                                      },
                                                      [_vm._v("Loading...")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("label", {
                                              staticClass:
                                                "text-normal-bold w-1/2 ml-8 mt-4",
                                            }),
                                          ]
                                        ),
                                        _c("FieldErrorMessage", {
                                          attrs: {
                                            message:
                                              _vm.errors.first("roadType"),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "w-full flex mt-8" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "w-full" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-normal-bold mb-2",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-red-star",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v("Extent "),
                                                  ]
                                                ),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.road.extent,
                                                      expression: "road.extent",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "w-full h-48 input-area",
                                                  attrs: {
                                                    id: "extent",
                                                    name: "extent",
                                                    "data-cypress":
                                                      "extent-field",
                                                    placeholder:
                                                      "Please describe where the road starts and ends",
                                                  },
                                                  domProps: {
                                                    value: _vm.road.extent,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.road,
                                                        "extent",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("FieldErrorMessage", {
                                          attrs: {
                                            message: _vm.errors.first("extent"),
                                          },
                                        }),
                                        _c("uploadComponent", {
                                          attrs: {
                                            label:
                                              "Attach Relevant Image (Optional)",
                                            drafted: true,
                                            deleteFile: _vm.deleteFunction,
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mb-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "text-red-star" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v("Public Comment "),
                                          ]
                                        ),
                                        _c("Checkbox", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required:true",
                                              expression: "'required:true'",
                                            },
                                          ],
                                          staticClass: "w-full",
                                          attrs: {
                                            label:
                                              "This road name proposal has been advertised for public comment.",
                                            error: _vm.errors.first(
                                              "Advertise for Public Comment"
                                            ),
                                            name: "Public Comment",
                                          },
                                          model: {
                                            value: _vm.advertisedComment,
                                            callback: function ($$v) {
                                              _vm.advertisedComment = $$v
                                            },
                                            expression: "advertisedComment",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex pt-4 text-center pb-10",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button-red-hollow flex-no-grow",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "roadNameProposalBucket",
                                              proposalId: _vm.proposalId,
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel Gazettal Notice")]
                                    ),
                                    _c("div", { staticClass: "flex-grow" }),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button-blue flex-no-grow",
                                        attrs: { disabled: _vm.isSaveDisabled },
                                        on: { click: _vm.submitNotice },
                                      },
                                      [_vm._v(" Continue ")]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }