var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "authentication flex flex-wrap justify-center items-start min-h-screen",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col md:flex-row flex-grow min-h-screen login-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "md:w-1/2 sm:mb-6 md:flex-grow pl-0 pr-12 pt-2 flex items-start",
            },
            [_vm._t("content")],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "md:w-1/2 sm:mb-6 md:flex-grow pl-2 pr-0 pt-2 mt-2 flex items-center",
            },
            [_vm._t("form")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }