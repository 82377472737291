var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      { staticClass: "button-blue-hollow", on: { click: _vm.send } },
      [_vm._v(" Copy " + _vm._s(_vm.count) + " contacts to clipboard ")]
    ),
    _c("a", { ref: "anchor", attrs: { href: _vm.mailToString } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }