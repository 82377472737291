<template>
  <div>
    <label class="w-2/3">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span>Origin of Proposed Name
      </p>

      <p>
        Please describe the origin of the feature's proposed name in the context
        of what the name signifies, what culture or background the name derives
        its meaning or to whom the name commemorates.
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="origin-field"
          v-model="origin"
          v-validate="'required'"
          name="origin_of_name"
          data-vv-as="Origin of Proposed Name"
          :disabled="readOnly"
        ></textarea>
        <FieldErrorMessage :message="errors.first('origin_of_name')" />
      </div>
    </label>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-origin"
        label="Attach supporting material for origin"
        :important="false"
        :files="origin_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="origin_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'origin_reference_document',
              index: fileIndex
            })
        "
        :disabled="readOnly"
      />
    </div>
    <label class="w-2/3">
      <p class="text-normal-bold mt-8 mb-2">
        <span class="text-red-star">*</span>Reason for the choice of the
        proposed name
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="reason_for_name_choice-field"
          v-model="reason_for_name_choice"
          v-validate="'required'"
          name="reason_for_name"
          data-vv-as="Reason for the choice of Proposed Name"
          :disabled="readOnly"
        ></textarea>
        <FieldErrorMessage :message="errors.first('reason_for_name')" />
      </div>
    </label>
    <label class="w-2/3">
      <p class="text-normal-bold mb-2 mt-8">
        Meaning of word used in proposed name
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="meaning-field"
        placeholder="Enter Meaning of Word Here..."
        name="meaning"
        id="meaning"
        v-model="meaning"
        :disabled="readOnly"
      />
    </label>
    <label class="w-2/3">
      <p class="text-normal-bold mb-2 mt-8">
        Provide the phonetic spelling of the proposed name
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="pronunciation-field"
        placeholder="Enter Phonetic Spelling Here..."
        name="Phonetic Spelling"
        id="phoneticSpelling"
        v-model="pronunciation"
        :disabled="readOnly"
      />
    </label>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-sound"
        label="Attach pronunciation audio/sound files"
        :important="false"
        :files="pronunciation_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="pronunciation_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'pronunciation_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
      />
    </div>
    <p class="text-normal-bold mt-8">
      <span class="text-red-star">*</span>Does this proposed name commemorate a
      person?
    </p>
    <div class="flex flex-row text-normal">
      <RadioButton
        class="flex-0 mr-6 mt-3"
        data-cypress="commemorated-no"
        name="commemorated-no"
        v-model="commemorated"
        :checkedValue="false"
        :disabled="readOnly"
        >No</RadioButton
      >
      <RadioButton
        class="flex-0 mr-6 mt-3"
        name="commemorated-yes"
        data-cypress="commemorated-yes"
        v-model="commemorated"
        :checkedValue="true"
        :disabled="readOnly"
        >Yes</RadioButton
      >
    </div>
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span>Full Name of Person
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="commemorated_full_name-field"
        placeholder="Enter Name Here..."
        name="commemorated_full_name"
        id="commemorated_full_name"
        v-validate="'required'"
        v-model="commemorated_full_name"
        data-vv-as="Full Name of Person"
        :disabled="readOnly"
      />
      <FieldErrorMessage :message="errors.first('commemorated_full_name')" />
    </label>
    <div class=" text-normal mb-4 mt-4" v-if="commemorated">
      <div class="mb-4">
        <label class="text-normal-bold pb-2">
          <span class="text-red-star">*</span>Date of Birth
        </label>
        <div class="flex flex-row mt-2">
          <v-datepicker
            mode="single"
            v-model="commemorated_birth_date"
            :max-date="commemorated_death_date || todaysDate"
            @dayclick="validateDate('commemorated_birth_date')"
          >
            <input
              slot-scope="{ inputProps, updateValue }"
              type="text"
              :value="commemorated_birth_date"
              placeholder="Enter Date (DD/MM/YYYY)"
              class="w-full mr-4 input-area"
              data-cypress="commemorated_birth_date"
              v-bind="inputProps"
              @input="e => updateDate(e, updateValue)"
              @change="setDobNullIfBlank"
              autocomplete="off"
              v-validate="'required|date'"
              :disabled="readOnly"
              name="commemorated_birth_date"
              data-vv-as="Date of Birth"
            />
          </v-datepicker>
          <button
            class="ml-2 w-1/8"
            v-bind:class="{
              redButtonFaded: !commemorated_birth_date,
              redButtonSolid: commemorated_birth_date
            }"
            :disabled="!commemorated_birth_date"
            @click="commemorated_birth_date = null"
          >
            clear
          </button>
        </div>
        <FieldErrorMessage :message="errors.first('commemorated_birth_date')" />
      </div>
      <div class="">
        <label class="text-normal-bold mb-2">
          <span class="text-red-star">*</span>Date of Death
        </label>
        <div class="flex flex-row mt-2">
          <v-datepicker
            mode="single"
            v-model="commemorated_death_date"
            :max-date="todaysDate"
            :min-date="commemorated_birth_date"
            @dayclick="validateDate('commemorated_death_date')"
          >
            <input
              slot-scope="{ inputProps, updateValue }"
              type="text"
              :value="commemorated_death_date"
              placeholder="Enter Date of Death..."
              class="w-full mr-4 input-area"
              data-cypress="commemorated_death_date"
              v-bind="inputProps"
              @input="e => updateDate(e, updateValue)"
              @change="setDodNullIfBlank"
              autocomplete="off"
              name="commemorated_death_date"
              data-vv-as="Date of Death"
              v-validate="'required|date'"
              :disabled="readOnly"
            />
          </v-datepicker>
          <button
            class="ml-2 w-1/8"
            v-bind:class="{
              redButtonFaded: !commemorated_death_date,
              redButtonSolid: commemorated_death_date
            }"
            @click="commemorated_death_date = null"
            :disabled="!commemorated_death_date"
          >
            clear
          </button>
        </div>
        <FieldErrorMessage :message="errors.first('commemorated_death_date')" />
      </div>
      <Checkbox
        class="pt-8 pl-0"
        data-cypress="commemorated_dates_unknown-field"
        label="The Date of Birth or Death is unknown"
        v-model="commemorated_dates_unknown"
        :disabled="
          readOnly || (commemorated_birth_date && commemorated_death_date)
        "
      ></Checkbox>
    </div>
    <label class="w-2/3 mb-4" v-if="commemorated_dates_unknown">
      <p class="text-normal-bold mb-2">
        Please explain why the Date of Birth or Death is unknown
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="commemorated_dates_unknown_description-field"
          v-model="commemorated_dates_unknown_description"
          :disabled="readOnly"
        ></textarea>
      </div>
    </label>
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-4">
        <span class="text-red-star">*</span>What is the person's association
        with this feature and their main contribution to the local community?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="ommemorated_association_description-field"
          v-model="commemorated_association_description"
          v-validate="'required'"
          name="commemorated_association_description"
          data-vv-as="Association"
          :disabled="readOnly"
        ></textarea>
        <FieldErrorMessage
          :message="errors.first('commemorated_association_description')"
        />
      </div>
    </label>
    <label class="w-2/3" v-if="commemorated">
      <p class="text-normal-bold mb-2 mt-8">
        What is the source for this information?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="commemorated_source-field"
          v-model="commemorated_source"
          :disabled="readOnly"
        ></textarea>
      </div>
    </label>
    <div v-if="commemorated">
      <uploadScoped
        class="mt-8"
        label="Attach supporting documentation"
        :important="false"
        :files="source_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="source_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'source_reference_document',
              index: fileIndex
            })
        "
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No supported documentation was uploaded. Please upload required files
        </div>
      </div>
    </div>
    <div>
      <p class="text-normal-bold mt-8">
        Is the proposed name an Aboriginal name?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          data-cypress="aboriginal_name-no"
          name="aboriginal_name-no"
          v-model="aboriginal_name"
          :checkedValue="false"
          :disabled="!!multicultural || readOnly"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          name="aboriginal_name-yes"
          data-cypress="aboriginal_name-yes"
          v-model="aboriginal_name"
          :checkedValue="true"
          :disabled="!!multicultural || readOnly"
          >Yes</RadioButton
        >
      </div>
    </div>

    <div v-if="aboriginal_name">
      <p class="text-normal-bold mt-8">
        Has the name been selected in consultation with the Local Aboriginal
        Land Council and the local Aboriginal community?
      </p>
      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          data-cypress="aboriginal_consulted-no"
          name="aboriginal_consulted-no"
          v-model="aboriginal_consulted"
          :checkedValue="false"
          :disabled="readOnly"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          name="aboriginal_consulted-yes"
          data-cypress="aboriginal_consulted-yes"
          v-model="aboriginal_consulted"
          :checkedValue="true"
          :disabled="readOnly"
          >Yes</RadioButton
        >
      </div>
      <MessagePanel
        warning
        msg="This response suggests that this proposal is likely to be deemed unacceptable"
        class="mt-4"
        v-if="aboriginal_consulted === false"
      />
    </div>
    <label class="w-2/3" v-if="aboriginal_consulted && aboriginal_name">
      <p class="text-normal-bold mb-2 mt-8">
        List the community groups consulted and provide evidence of support
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="aboriginal_consulted_communities-field"
          v-model="aboriginal_consulted_communities"
          :disabled="readOnly"
        ></textarea>
      </div>
    </label>
    <div v-if="aboriginal_consulted && aboriginal_name">
      <uploadScoped
        class="mt-8"
        label="Attach supporting documentation"
        :important="true"
        :files="aboriginal_reference_document"
        :drafted="true"
        stateKey="place_name"
        stateProp="aboriginal_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'place_name',
              prop: 'aboriginal_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
        data-vv-as="Supporting Documentation"
        name="aboriginal_reference_document"
      />
      <FieldErrorMessage
        :message="errors.first('aboriginal_reference_document')"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No supporting documentation was uploaded. Please upload required files
        </div>
      </div>
    </div>
    <div v-if="aboriginal_name" class="flex flex-row text-normal mb-4 mt-8">
      <label class="pr-4 w-2/3">
        <p class="text-normal-bold mb-2">
          Country/Aboriginal nation
        </p>
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="aboriginal_country-field"
          placeholder="Enter Country Here..."
          name="aboriginal_country"
          id="aboriginal_country"
          v-model="aboriginal_country"
          :disabled="readOnly"
        />
      </label>
      <label class="w-2/3">
        <p class="text-normal-bold mb-2">
          <span class="text-red-star">*</span>Language Group
        </p>
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="aboriginal_language-field"
          placeholder="Enter Language Group Here..."
          name="aboriginal_language"
          id="aboriginal_language"
          v-model="aboriginal_language"
          :disabled="readOnly"
        />
      </label>
    </div>
    <label class="w-2/3" v-if="aboriginal_name">
      <p class="text-normal-bold mb-2 mt-8">
        How would you describe the place type?
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="aboriginal_place_type-field"
          v-model="aboriginal_place_type"
          :disabled="readOnly"
        ></textarea>
      </div>
    </label>
    <p class="text-normal-bold mt-8">
      Does the proposed name have a multicultural dimension?
    </p>
    <div class="flex flex-row text-normal">
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        data-cypress="multicultural-no"
        name="multicultural-no"
        v-model="multicultural"
        :checkedValue="false"
        :disabled="!!aboriginal_name || readOnly"
        >No</RadioButton
      >
      <RadioButton
        class="flex-0 mr-6 mt-3 mb-1"
        name="multicultural-yes"
        data-cypress="multicultural-yes"
        v-model="multicultural"
        :checkedValue="true"
        :disabled="!!aboriginal_name || readOnly"
        >Yes</RadioButton
      >
    </div>
    <label class="w-2/3" v-if="multicultural">
      <p class="text-normal-bold mb-2 mt-8">
        <span class="text-red-star">*</span>Please describe the multicultural
        dimension
      </p>
      <div class="w-full mt-3 flex">
        <textarea
          class="w-full h-48 input-area"
          data-cypress="multicultural_description-field"
          v-model="multicultural_description"
          :disabled="readOnly"
        ></textarea>
      </div>
    </label>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import debounce from "lodash.debounce";

const { mapGetters: mapPnpGetters } = createNamespacedHelpers("pnpState");
const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");
const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import MessagePanel from "@/components/proposals/place/messagePanel";

export default {
  inject: ["$validator"],
  props: {
    readOnly: {
      type: Boolean
    }
  },
  components: {
    MessagePanel
  },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      readableDateFormat: "DD/MM/YYYY"
    };
  },
  computed: {
    todaysDate() {
      return moment().format("YYYY-MM-DD");
    },
    pronunciation_reference_document: {
      get() {
        return this.get_place_name_pronunciation_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_pronunciation_reference_document(value);
      }
    },
    pronunciation: {
      get() {
        return this.get_place_name_pronunciation();
      },
      set(value) {
        this.set_place_name_pronunciation(value);
      }
    },
    reason_for_name_choice: {
      get() {
        return this.get_place_name_reason_for_name_choice();
      },
      set(value) {
        this.set_place_name_reason_for_name_choice(value);
      }
    },
    origin: {
      get() {
        return this.get_place_name_origin();
      },
      set(value) {
        this.set_place_name_origin(value);
      }
    },
    origin_reference_document: {
      get() {
        return this.get_place_name_origin_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_origin_reference_document(value);
      }
    },
    meaning: {
      get() {
        return this.get_place_name_meaning();
      },
      set(value) {
        if (value || value === false) this.set_place_name_meaning(value);
      }
    },
    commemorated: {
      get() {
        return this.get_place_name_commemorated();
      },
      set(value) {
        if (value || value === false) this.set_place_name_commemorated(value);
      }
    },
    commemorated_full_name: {
      get() {
        return this.get_place_name_commemorated_full_name();
      },
      set(value) {
        this.set_place_name_commemorated_full_name(value);
      }
    },
    commemorated_birth_date: {
      get() {
        const date = this.get_place_name_commemorated_birth_date();
        if (!date) return null;
        return moment(date, this.dateFormat).format(this.readableDateFormat);
      },
      set(value) {
        this.set_place_name_commemorated_birth_date(
          value
            ? moment(value, this.readableDateFormat).format(this.dateFormat)
            : null
        );
      }
    },
    commemorated_death_date: {
      get() {
        const date = this.get_place_name_commemorated_death_date();
        if (!date) return null;
        return moment(date, this.dateFormat).format(this.readableDateFormat);
      },
      set(value) {
        this.set_place_name_commemorated_death_date(
          value
            ? moment(value, this.readableDateFormat).format(this.dateFormat)
            : null
        );
      }
    },
    commemorated_dates_unknown: {
      get() {
        return this.get_place_name_commemorated_dates_unknown();
      },
      set(value) {
        this.set_place_name_commemorated_dates_unknown(value);
      }
    },
    commemorated_dates_unknown_description: {
      get() {
        return this.get_place_name_commemorated_dates_unknown_description();
      },
      set(value) {
        this.set_place_name_commemorated_dates_unknown_description(value);
      }
    },
    commemorated_association_description: {
      get() {
        return this.get_place_name_commemorated_association_description();
      },
      set(value) {
        this.set_place_name_commemorated_association_description(value);
      }
    },
    commemorated_source: {
      get() {
        return this.get_place_name_commemorated_source();
      },
      set(value) {
        this.set_place_name_commemorated_source(value);
      }
    },
    source_reference_document: {
      get() {
        return this.get_place_name_source_reference_document();
      },
      set(value) {
        this.set_place_name_source_reference_document(value);
      }
    },
    aboriginal_name: {
      get() {
        return this.get_place_name_aboriginal_name();
      },
      set(value) {
        this.set_place_name_aboriginal_name(value);
      }
    },
    aboriginal_consulted: {
      get() {
        return this.get_place_name_aboriginal_consulted();
      },
      set(value) {
        this.set_place_name_aboriginal_consulted(value);
      }
    },
    aboriginal_consulted_communities: {
      get() {
        return this.get_place_name_aboriginal_consulted_communities();
      },
      set(value) {
        this.set_place_name_aboriginal_consulted_communities(value);
      }
    },
    aboriginal_reference_document: {
      get() {
        return this.get_place_name_aboriginal_reference_document();
      },
      set(value) {
        this.set_place_name_aboriginal_reference_document(value);
      }
    },
    aboriginal_country: {
      get() {
        return this.get_place_name_aboriginal_country();
      },
      set(value) {
        this.set_place_name_aboriginal_country(value);
      }
    },
    aboriginal_language: {
      get() {
        return this.get_place_name_aboriginal_language();
      },
      set(value) {
        this.set_place_name_aboriginal_language(value);
      }
    },
    aboriginal_place_type: {
      get() {
        return this.get_place_name_aboriginal_place_type();
      },
      set(value) {
        this.set_place_name_aboriginal_place_type(value);
      }
    },
    multicultural: {
      get() {
        return this.get_place_name_multicultural();
      },
      set(value) {
        this.set_place_name_multicultural(value);
      }
    },
    multicultural_description: {
      get() {
        return this.get_place_name_multicultural_description();
      },
      set(value) {
        this.set_place_name_multicultural_description(value);
      }
    },
    /**
     * Should we disable the unknown date checkbox
     */
    shouldDisableUnknown() {
      return !!(this.commemorated_birth_date && this.commemorated_death_date);
    }
  },
  /**
   * Value watchers
   */
  watch: {
    /**
     * react to changes to the disable unknown flag
     */
    shouldDisableUnknown(shouldDisable) {
      if (shouldDisable) {
        this.commemorated_dates_unknown = false;
        // this.$store.commit("setCommemorationDateUnknown", {
        //   isUnknown: false
        // });
      }
    }
  },

  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_place_name_pronunciation_reference_document",
      "get_place_name_pronunciation",
      "get_place_name_reason_for_name_choice",
      "get_place_name_origin",
      "get_place_name_origin_reference_document",
      "get_place_name_meaning",
      "get_place_name_commemorated",
      "get_place_name_commemorated_full_name",
      "get_place_name_commemorated_birth_date",
      "get_place_name_commemorated_death_date",
      "get_place_name_commemorated_dates_unknown",
      "get_place_name_commemorated_dates_unknown_description",
      "get_place_name_commemorated_association_description",
      "get_place_name_commemorated_source",
      "get_place_name_source_reference_document",
      "get_place_name_aboriginal_name",
      "get_place_name_aboriginal_consulted",
      "get_place_name_aboriginal_consulted_communities",
      "get_place_name_aboriginal_reference_document",
      "get_place_name_aboriginal_country",
      "get_place_name_aboriginal_language",
      "get_place_name_aboriginal_place_type",
      "get_place_name_multicultural",
      "get_place_name_multicultural_description"
    ]),
    ...mapPnpMutations([
      "deleteUploadedFile",
      "set_place_name_pronunciation_reference_document",
      "set_place_name_pronunciation",
      "set_place_name_reason_for_name_choice",
      "set_place_name_origin",
      "set_place_name_origin_reference_document",
      "set_place_name_meaning",
      "set_place_name_commemorated",
      "set_place_name_commemorated_full_name",
      "set_place_name_commemorated_birth_date",
      "set_place_name_commemorated_death_date",
      "set_place_name_commemorated_dates_unknown",
      "set_place_name_commemorated_dates_unknown_description",
      "set_place_name_commemorated_association_description",
      "set_place_name_commemorated_source",
      "set_place_name_source_reference_document",
      "set_place_name_aboriginal_name",
      "set_place_name_aboriginal_consulted",
      "set_place_name_aboriginal_consulted_communities",
      "set_place_name_aboriginal_reference_document",
      "set_place_name_aboriginal_country",
      "set_place_name_aboriginal_language",
      "set_place_name_aboriginal_place_type",
      "set_place_name_multicultural",
      "set_place_name_multicultural_description"
    ]),
    updateDate: debounce((e, updateFunc) => {
      if (
        /^[\d]{1,2}\/[\d]{1,2}\/[\d]{4}$/.test(e.target.value) &&
        moment(e.target.value, "D/M/YYYY").isValid()
      ) {
        //convert to YYYY-MM-DD so it interprets date correctly
        updateFunc(""); //  clear first so it detects it as an update. This is a but sloppy but seems to work.
        updateFunc(moment(e.target.value, "DD/MM/YYYY").format("YYYY-MM-DD"));
      }
    }, 1200),
    setDobNullIfBlank(e) {
      if (e.target.value == "") {
        this.commemorated_birth_date = null;
      }
    },
    setDodNullIfBlank(e) {
      if (e.target.value == "") {
        this.commemorated_death_date = null;
      }
    },
    async validateDate(element) {
      await this.$nextTick();
      this.$validator.validate(element);
    }
  }
};
</script>

<style scoped>
.redButtonSolid {
  background-color: #c60c30;
  color: white;
  font-size: 16px;
  width: 55px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.redButtonFaded {
  background-color: #c60c3170;
  color: white;
  font-size: 16px;
  width: 55px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: default;
}
</style>
