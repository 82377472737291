var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "fixed h-12 logoPos",
        attrs: {
          src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
        },
      }),
      _c(
        "div",
        { staticClass: "w-2/3 mx-auto my-3 rounded" },
        [
          _c("intro-card"),
          _c("pending-proposals", {
            attrs: {
              pendingPlaceNames: _vm.pendingPlaceNames,
              pendingLocalities: _vm.pendingLocalities,
            },
          }),
          _c("current-proposals", {
            attrs: {
              currentPlaceNames: _vm.currentPlaceNames,
              currentLocalities: _vm.currentLocalities,
            },
          }),
          _c("expired-proposals", {
            attrs: {
              expiredPlaceNames: _vm.expiredPlaceNames,
              expiredLocalities: _vm.expiredLocalities,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }