<template>
  <div id="uploadDiv" class="my-4 w-full" :class="classNames">
    <p class="pb-3 text-normal-bold">
      <a class="text-red-star" v-if="important">*</a> {{ label }}
    </p>
    <div class="flex w-full">
      <label class="flex w-full">
        <div
          class="w-7/8 flex p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded"
        >
          Click or drag files here to upload...
          <input
            type="file"
            class="hidden"
            @change="filesChange($event.target.files)"
          />
        </div>
        <div
          class="bg-blue-button rounded w-1/8 flex justify-center items-center cursor-pointer text-white text-normal-bold"
        >
          Add Files
        </div>
      </label>
    </div>
    <div class="mb-4" v-for="(file, index) in uploadedFile" v-bind:key="index">
      <div
        class="w-full mt-5 flex items-center rounded-lg border border-grey-border"
      >
        <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
        <p class="textNormal">{{ file.name }}</p>
        <a class="cursor-pointer ml-auto text-normal-bold text-blue-button"
          >View File</a
        >
        <a
          class="cursor-pointer ml-10 mr-2 text-normal-bold text-red-button"
          @click="removeFile(index)"
          >Remove</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    important: {
      type: Boolean
    },
    classNames: {
      type: String
    },
    uploads: {
      type: Array
    }
  },
  data() {
    return {
      uploadedFile: [],
      dragAndDropCapable: false
    };
  },
  methods: {
    filesChange(fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        this.uploadedFile.push(element);
      }
      this.$emit("input", this.uploadedFile);
      // this.uploads = this.uploadedFile;
    },
    removeFile(fileIndex) {
      this.uploadedFile.splice(fileIndex, 1);
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          /*
        For each event add an event listener that prevents the default action
        (opening the file in the browser) and stop the propagation of the event (so
        no other elements open the file in the browser)
      */
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
      Add an event listener for drop to the form
    */
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          /*
        Capture the files from the drop event and add handle them
      */
          this.filesChange(e.dataTransfer.files);
        }.bind(this)
      );
    }
  }
};
</script>
