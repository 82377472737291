var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c("span", { staticClass: "flex items-center" }, [
        _c(
          "a",
          {
            staticClass:
              "cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [
            _c("BackArrowWithTitle", {
              attrs: { title: "Organisation - Manage Members" },
            }),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "text-normal-bold mt-6" }, [
        _vm._v("Organisation members"),
      ]),
      _c("div", { staticClass: "w-full flex items-center mt-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newMemberId,
                expression: "newMemberId",
              },
            ],
            staticClass: "bg-white w-full input-area",
            attrs: { "data-cypress": "addmember-field" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.newMemberId = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c(
              "option",
              {
                attrs: {
                  value: "",
                  disabled: "",
                  hidden: "",
                  selected: "selected",
                },
              },
              [_vm._v("Select Authority User")]
            ),
            _vm._l(_vm.usersWithoutOrg, function (user, index) {
              return _c(
                "option",
                {
                  key: index,
                  staticClass: "bg-white w-full input-area",
                  domProps: { value: user.identifier },
                },
                [_vm._v(_vm._s(user.first_name) + " " + _vm._s(user.last_name))]
              )
            }),
          ],
          2
        ),
        _c(
          "button",
          {
            staticClass: "greenButton py-4 px-10 ml-4 whitespace-no-wrap",
            attrs: {
              disabled: !this.newMemberId,
              "data-cypress": "addmember-button",
            },
            on: { click: _vm.addOrganisationMember },
          },
          [_vm._v(" Add to Organisation ")]
        ),
      ]),
      _vm._l(_vm.organisation.members, function (user, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "w-full bg-white rounded-lg flex mt-5 items-center",
          },
          [
            _c("img", {
              staticClass: "w-12 h-12 m-4",
              attrs: { src: require("../../assets/Images/Icon_User.png") },
            }),
            _c("p", { staticClass: "text-title mr-4 w-1/6" }, [
              _vm._v(
                " " +
                  _vm._s(user.first_name) +
                  " " +
                  _vm._s(user.last_name) +
                  " "
              ),
            ]),
            _c("StatusButton", {
              staticClass: "ml-12 mr-6 py-1 px-10 text-normal font-bold",
              attrs: {
                label: user.user_account_status,
                status: user.user_account_status,
              },
            }),
            _c(
              "button",
              {
                staticClass: "removeText ml-auto mr-8",
                on: {
                  click: function ($event) {
                    return _vm.removeOrganisationMember(user.identifier)
                  },
                },
              },
              [_vm._v(" Remove ")]
            ),
          ],
          1
        )
      }),
      _c("div", { staticClass: "flex my-6" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }