<template>
  <nav class="flex w-full p-4 bg-blue-button">
    <div class="flex items-center text-white w-full md:max-w-xs pl-2">
      <img src="../../assets/Logos/GNB logo_White_RGB.png" class="h-14 " />
    </div>
    <div class="block sm:hidden">
      <button
        @click="toggle"
        class="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white"
      >
        <svg
          class="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
    </div>
    <div
      :class="open ? 'block' : 'hidden'"
      class="w-full flex-grow sm:flex sm:items-center sm:w-auto -mt-12"
    >
      <!-- class="svg-inline--fa fa-search fa-w-16"  -->
      <!-- search section -->
      <div class="base-container flex flex-row flex-no-wrap">
        <RoadNameSearch />
        <!-- icons section -->
        <div class="sm:flex flex w-1/6 ml-4 justify-end">
          <!-- profile -->

          <button class v-if="user">
            <router-link
              :to="{ name: 'profile/view', params: { id: user.email } }"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user"
                id="SVG"
                class="h-8 absolute -mt-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
              >
                <path
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                ></path>
              </svg>
            </router-link>
          </button>

          <!-- logout -->
          <router-link
            tag="button"
            id="logout-button"
            class="hover:text-grey-light text-white font-bold ml-16"
            data-cypress="logout"
            :to="{ name: 'logout' }"
          >
            LOG OUT
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from "vuex";
import RoadNameSearch from "../../components/proposals/road/searchBar";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "navigationMenu",
  components: {
    RoadNameSearch
  },
  data() {
    return {
      open: false
    };
  },

  methods: {
    ...mapMutations([
      "user/initUser",
      "initRnpState",
      "initRnaState",
      "initProposalsState",
      "initUploadState"
    ]),
    toggle() {
      this.open = !this.open;
    }
  },
  computed: {
    ...mapUserGetters(["user"])
  }
};
</script>

<style scoped>
.search {
  background-color: rgba(255, 255, 255, 0.2);
  height: 50px;
}
#SVG {
  fill: white;
  transition-duration: 0.2s;
}

#SVG:hover {
  fill: #84bddc;
}

.bg-teal-button {
  transition-duration: 0.2s;
}
.bg-teal-button:hover {
  background-color: white;
}

#logout-button {
  transition-duration: 0.2s;
}

#logout-button:hover {
  color: #84bddc;
}
</style>
