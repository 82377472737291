var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("p", { staticClass: "text-normal-bold" }, [
        _vm._v(" " + _vm._s(_vm.mapFieldToLabel("dual_named")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal" },
        [
          _c(
            "RadioButton",
            {
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                "data-cypress": "dual_named-no",
                name: "dual_named-no",
                checkedValue: false,
                disabled: _vm.dualNamingComplete,
              },
              on: { change: _vm.setDualNamedState },
              model: {
                value: _vm.geoName.dual_named,
                callback: function ($$v) {
                  _vm.$set(_vm.geoName, "dual_named", $$v)
                },
                expression: "geoName.dual_named",
              },
            },
            [_vm._v("No")]
          ),
          _c(
            "RadioButton",
            {
              staticClass: "flex-0 mr-6 mt-3 mb-1",
              attrs: {
                name: "dual_named-yes",
                "data-cypress": "dual_named-yes",
                checkedValue: true,
                disabled: _vm.dualNamingComplete,
              },
              on: { change: _vm.setDualNamedState },
              model: {
                value: _vm.geoName.dual_named,
                callback: function ($$v) {
                  _vm.$set(_vm.geoName, "dual_named", $$v)
                },
                expression: "geoName.dual_named",
              },
            },
            [_vm._v("Yes")]
          ),
        ],
        1
      ),
      _vm.dualNamingComplete
        ? _c("div", { staticClass: "mt-4" }, [
            _c("p", { staticClass: "text-normal-bold" }, [
              _vm._v(" " + _vm._s(_vm.mapFieldToLabel("dual_name")) + " "),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row mt-2 items-center" },
              [
                _c(
                  "a",
                  {
                    staticClass: "text-normal hover:underline",
                    attrs: { href: _vm.siblingUrl, target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.linkedWith))]
                ),
                !_vm.requestConfirmation
                  ? [
                      _c("span", { staticClass: "text-xs text-normal ml-4" }, [
                        _vm._v("["),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "text-xs text-normal",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.requestConfirmation = true
                            },
                          },
                        },
                        [_vm._v("remove")]
                      ),
                      _c("span", { staticClass: "text-xs text-normal" }, [
                        _vm._v("]"),
                      ]),
                    ]
                  : _c("p", { staticClass: "ml-4" }, [
                      _vm._v(" Are you sure? "),
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.requestConfirmation = false
                            },
                          },
                        },
                        [_vm._v("No, cancel")]
                      ),
                      _vm._v(" | "),
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeDualName.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Yes")]
                      ),
                    ]),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }