<template>
  <div :class="classNames" class="px-3 py-3 justify-center md:mb-0">
    <label class="container pt-1 pb-1">
      {{ label }}
      <input type="checkbox" />
      <span class="border-2 checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    checked: {
      type: String
    },
    classNames: {
      type: String,
      default: "md:w-1/2 "
    }
  }
};
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: rgba(100, 105, 116, 0.5);
  border-radius: 2px;
  background-color: #ecf1f3;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ecf1f3;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgba(100, 105, 116, 0.5);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
