"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

// get gazettal order number from db
export async function getGazettalOrderNumber() {
  return await API.get(apiName, "/gazettal");
}
export async function updateGazettalOrderNumber(orderNumber) {
  const payload = {
    body: {
      orderNumber
    }
  };
  return await API.post(apiName, "/gazettal/order", payload);
}

export async function scheduleFormalise(formalise) {
  const payload = {
    body: {
      formaliseDate: formalise.formalisedDate,
      jira_id: formalise.jira_id
    }
  };

  return await API.post(
    apiName,
    `/roadnames/${formalise.roadnameId}/formalise`,
    payload
  );
}

export async function updateFormalise(formalise) {
  const payload = {
    body: {
      formaliseDate: formalise.formalisedDate,
      jira_id: formalise.jira_id
    }
  };
  return await API.put(
    apiName,
    `/roadnames/${formalise.roadnameId}/formalise`,
    payload
  );
}