var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c(
      "div",
      { staticClass: "bg-white shadow overflow-hidden sm:rounded-lg" },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "px-4 py-5 border-b border-gray-200 sm:px-6" },
          [
            _c("dl", [
              _c("div", { staticClass: "col-span-6" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "block text-sm font-medium leading-5 text-gray-700",
                    attrs: { for: "street_address" },
                  },
                  [_vm._v("Comparison word")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sourceString,
                      expression: "sourceString",
                    },
                  ],
                  staticClass:
                    "border p-4 mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  attrs: {
                    id: "street_address",
                    placeholder: _vm.sourceString,
                  },
                  domProps: { value: _vm.sourceString },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.sourceString = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "mt-6" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "block text-sm leading-5 font-medium text-gray-700",
                    attrs: { for: "about" },
                  },
                  [_vm._v(" List of words to compare with ")]
                ),
                _c("div", { staticClass: "rounded-md shadow-sm" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wordList,
                        expression: "wordList",
                      },
                    ],
                    staticClass:
                      "border p-4 form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                    attrs: {
                      id: "about",
                      rows: "3",
                      placeholder: _vm.wordList,
                    },
                    domProps: { value: _vm.wordList },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.wordList = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm.results
                ? _c("div", { staticClass: "flex flex-col mt-6 mb-6" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "text-lg leading-6 font-medium text-gray-900 mb-4",
                      },
                      [_vm._v(" Comparison results ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg",
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "min-w-full divide-y divide-gray-200",
                                  },
                                  [
                                    _vm._m(1),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass:
                                          "bg-white divide-y divide-gray-200",
                                      },
                                      _vm._l(
                                        _vm.results,
                                        function (result, index) {
                                          return _c("tr", { key: index }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-6 py-4 whitespace-no-wrap",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-sm flex items-center",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          result.comparedWith
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-6 py-4 whitespace-no-wrap",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-sm leading-5 text-gray-900",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          result.sourceWord
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "px-6 py-4 whitespace-no-wrap text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          result.similarityScore.toFixed(
                                                            2
                                                          ) * 100
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "text-center px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(result.soundsLike) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-4 py-3 bg-grey-lighter text-left sm:px-6" },
                [
                  _c(
                    "span",
                    { staticClass: "inline-flex rounded-md shadow-sm" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-red-dark hover:bg-red focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mr-4",
                          on: { click: _vm.reset },
                        },
                        [_vm._v(" Reset ")]
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    { staticClass: "inline-flex rounded-md shadow-sm" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-teal-dark hover:bg-teal focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out",
                          attrs: {
                            type: "submit",
                            disabled: _vm.formIncomplete,
                          },
                          on: { click: _vm.checkString },
                        },
                        [_vm._v(" Check ")]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "px-4 py-5 border-b border-gray-200 sm:px-6" },
      [
        _c(
          "h3",
          { staticClass: "text-lg leading-6 font-medium text-gray-900" },
          [_vm._v(" Compare a string with some strings ")]
        ),
        _c(
          "p",
          { staticClass: "mt-1 max-w-2xl text-sm leading-5 text-gray-500" },
          [
            _vm._v(
              " Enter a target word followed by a list of words to compare it against "
            ),
            _c("br"),
            _vm._v(" for phonetic similarity and edit distance scoring. "),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "border-b" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Source ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Compared with ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Similarity score ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "text-center px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Sounds similar ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }