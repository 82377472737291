var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-full mx-auto pt-6 px-6 pb-14 mb-8 flex-col flex",
      attrs: { "data-cypress": "remove-box" },
    },
    [
      _c(
        "a",
        {
          staticClass: "cursor-pointer ml-auto",
          on: {
            click: function ($event) {
              return _vm.hide()
            },
          },
        },
        [_c("errorIcon", { attrs: { classNames: "w-4" } })],
        1
      ),
      _vm._m(0),
      _c(
        "h3",
        { staticClass: "w-full flex justify-center text-black mb-6 px-10" },
        [_vm._v(" " + _vm._s(_vm.type.label) + " ")]
      ),
      _c("div", { staticClass: "flex text-black mb-8 text-normal px-10" }, [
        _vm._v(
          " Are you sure you want to delete the road name type? You will not be able to reverse this operation once it is complete. "
        ),
      ]),
      _c("div", { staticClass: "flex justify-center w-full px-10" }, [
        _c(
          "div",
          {
            staticClass:
              "button-blue-hollow mr-4 w-2/5 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none",
            attrs: { "data-cypress": "no-keep" },
            on: {
              click: function ($event) {
                return _vm.hide()
              },
            },
          },
          [_vm._v(" No, keep the road type ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "button-red ml-4 w-2/5 cursor-pointer text-center whitespace-no-wrap justify-center flex leading-none",
            attrs: { "data-cypress": "yes-delete" },
            on: {
              click: function ($event) {
                return _vm.confirmDelete()
              },
            },
          },
          [_vm._v(" Yes, delete the road type ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "flex items-center content-center justify-center mt-8 mb-6 px-10",
      },
      [
        _c("h2", { staticClass: "text-black" }, [
          _vm._v("Delete Roadname Type"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }