<template>
  <div v-if="isAdmin" class="base-container text-black mb-32">
    <editGeoNameConfirmationModal
      v-on:saveGeoName="saveGeoName(false)"
      v-on:saveGeoNameWithUpdate="saveGeoName(true)"
    />
    <section class="justify-center w-full block">
      <div class="flex w-full mb-3">
        <!--a
          @click="$router.go(-1)"
          class="cursor-pointer flex self-center"
          data-cypress="back-arrow"
        >
          <BackArrowWithTitle title="Edit Place Name" />
        </a-->
        <h1>Edit Place Name</h1>
      </div>
    </section>
    <showLoading isLoading="fetchingGeoname">
      <template v-slot:component>
        <div v-if="geonameExists">
          <div class="w-full flex bg-white mb-8">
            <div class="w-5/6 mx-auto pt-8 pb-16">
              <div class="py-6 border-b-2 mb-6 self-center">
                <h3 class="font-bold">Place Name Status</h3>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="status_select"
                  >
                    <span class="text-red-star">*</span> Change Place Name
                    Status
                  </label>
                  <norns-select
                    :disabled="readOnly || !canChangeStatus"
                    class="w-full mr-4 norns-select"
                    v-model="geoName.status"
                    :multiple="false"
                    :options="statusList"
                    :close-on-select="true"
                    :select-on-tab="true"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Select a Status"
                    v-validate="'required'"
                    name="status"
                    data-cypress="status-field"
                    data-vv-as="Status"
                  ></norns-select>
                  <fieldErrorMessage :message="errors.first('status')" />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label class="block text-gray-700 font-bold mb-2">{{
                    mapFieldToLabel("status")
                  }}</label>
                  <StatusButton
                    :label="
                      geoName.dual_named
                        ? `Dual - ${geoName.status}`
                        : geoName.status
                    "
                    :status="geoName.status"
                    classNames="text-normal-bold"
                  />
                </div>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="date_created"
                  >
                    {{ mapFieldToLabel("date_created") }}
                  </label>
                  <input
                    :disabled="true"
                    v-model="date_created"
                    class="w-full mb-2 input-area"
                    id="date_created"
                    type="text"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="date_modified"
                    >{{ mapFieldToLabel("date_modified") }}</label
                  >
                  <input
                    :disabled="true"
                    v-model="date_modified"
                    class="w-full mb-2 input-area"
                    id="date_modified"
                    type="text"
                  />
                </div>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="previous_name"
                  >
                    {{ mapFieldToLabel("previous_name") }}
                  </label>
                  <taggableInput
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tagList="geoName.previous_name"
                    :placeholder="
                      `Start typing and press enter to add Previous Names...`
                    "
                    :datacypress="'address-input'"
                    v-on:addTag="
                      previous_name => geoName.previous_name.push(previous_name)
                    "
                    v-on:removeTag="
                      index => geoName.previous_name.splice(index, 1)
                    "
                    v-model="geoName.previous_name"
                    v-validate="'required'"
                    data-vv-as="Previous Names"
                    label="previous_name_taglist"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="gazettal_dates"
                    >{{ mapFieldToLabel("gazettal_dates") }}</label
                  >
                  <taggable-date-selector
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tags="gazettal_dates"
                    :datacypress="'date-input'"
                    v-on:addTag="addNewGazettalDate($event)"
                    v-on:removeTag="setRemovedGazettalDate($event)"
                    v-model="geoName.gazettal_dates"
                    v-validate="'required'"
                    data-vv-as="Gazettal Date"
                    label="gazettal_date_taglist"
                  />
                </div>
              </div>

              <dual-named :geoName="geoName" :readOnly="readOnly" />

              <div>
                <p class="text-normal-bold mt-3">
                  {{ mapFieldToLabel("is_suitable_for_public_display") }}
                </p>
                <div class="flex flex-row text-normal">
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    data-cypress="is_suitable_for_public_display-no"
                    name="is_suitable_for_public_display-no"
                    v-model="geoName.is_suitable_for_public_display"
                    :checkedValue="false"
                    :disabled="readOnly"
                    >No</RadioButton
                  >
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="is_suitable_for_public_display-yes"
                    data-cypress="is_suitable_for_public_display-yes"
                    v-model="geoName.is_suitable_for_public_display"
                    :checkedValue="true"
                    :disabled="readOnly"
                    >Yes</RadioButton
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex bg-white mb-8">
            <div class="w-5/6 mx-auto pt-8 pb-16">
              <div class="py-6 mb-6 border-b-2 self-center">
                <h3 class="font-bold">Geographical Details</h3>
              </div>
              <div class="w-full justify-center flex-col">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="geographical_name"
                  ><span class="text-red-star">*</span
                  >{{ mapFieldToLabel("geographical_name") }}</label
                >
                <input
                  v-model="geoName.geographical_name"
                  class="w-full mb-4 input-area"
                  id="geographical_name"
                  type="text"
                  v-validate="'required'"
                  name="geographical_name"
                  data-vv-as="Geographical Name"
                  :disabled="readOnly"
                />
                <fieldErrorMessage
                  :message="errors.first('geographical_name')"
                />
              </div>

              <div class="w-full justify-center flex-col">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="place_name"
                  >{{ mapFieldToLabel("place_name") }}</label
                >
                <input
                  v-model="geoName.place_name"
                  class="w-full mb-4 input-area"
                  id="place_name"
                  type="text"
                  name="place_name"
                  :disabled="readOnly"
                />
              </div>

              <div class="w-full justify-center flex-col mb-4">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="designation"
                  ><span class="text-red-star">*</span
                  >{{ mapFieldToLabel("designation") }}</label
                >
                <norns-select
                  :disabled="readOnly"
                  class="w-full mr-4 norns-select"
                  v-model="geoName.designation"
                  :multiple="false"
                  :options="designations"
                  :close-on-select="true"
                  :select-on-tab="true"
                  :show-labels="true"
                  :hide-selected="true"
                  placeholder="Select a Designation"
                  v-validate="'required'"
                  name="designation"
                  data-cypress="designation-field"
                  data-vv-as="Designation"
                ></norns-select>
                <fieldErrorMessage :message="errors.first('designation')" />
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="lga">
                    <span class="text-red-star">*</span
                    >{{ mapFieldToLabel("lga") }}
                  </label>
                  <norns-select
                    :disabled="readOnly"
                    class="w-full mr-4 norns-select"
                    v-model="geoName.lga"
                    :multiple="true"
                    :options="lgasArray"
                    :searchable="true"
                    :close-on-select="false"
                    :select-on-tab="true"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Select an LGA(s)"
                    v-validate="'required'"
                    name="lga"
                    data-cypress="lga-field"
                    data-vv-as="Local Government Area(s)"
                  ></norns-select>
                  <fieldErrorMessage :message="errors.first('lga')" />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="lga-gazettal">
                    {{ mapFieldToLabel("lga_gazettal") }}
                  </label>
                  <norns-select
                    :disabled="readOnly"
                    class="w-full mr-4 norns-select"
                    v-model="geoName.lga_gazettal"
                    :multiple="true"
                    :options="lgasArray"
                    :searchable="true"
                    :close-on-select="false"
                    :select-on-tab="true"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Select an LGA(s)"
                    name="lga-gazettal"
                    data-cypress="lga-gazettal-field"
                    data-vv-as="Local Government Area (LGA) at Gazettal"
                  ></norns-select>
                  <fieldErrorMessage :message="errors.first('lga-gazettal')" />
                </div>
              </div>
              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label class="block text-gray-700 font-bold mb-2" for="lga-spatial">
                    {{ mapFieldToLabel("lga_spatial") }}
                  </label>
                  <norns-select
                    :disabled="readOnly"
                    class="w-full mr-4 norns-select"
                    v-model="geoName.lga_spatial"
                    :multiple="true"
                    :options="lgasArray"
                    :searchable="true"
                    :close-on-select="false"
                    :select-on-tab="true"
                    :show-labels="true"
                    :hide-selected="true"
                    placeholder="Select an LGA(s)"
                    v-validate="'required'"
                    name="lga-spatial"
                    data-cypress="lga-spatial-field"
                    data-vv-as="Local Government Area(s)"
                  ></norns-select>
                  <fieldErrorMessage :message="errors.first('lga-spatial')" />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="suburb"
                  >
                    {{ mapFieldToLabel("locality") }}</label
                  >
                  <taggableInput
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tagList="geoName.locality"
                    :placeholder="
                      `Start typing and press enter to add a Locality...`
                    "
                    :datacypress="'address-input'"
                    v-on:addTag="locality => geoName.locality.push(locality)"
                    v-on:removeTag="index => geoName.locality.splice(index, 1)"
                    v-model="geoName.locality"
                    label="address_localities_taglist"
                  />
                </div>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="parish"
                  >
                    {{ mapFieldToLabel("parish") }}
                  </label>

                  <taggableInput
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tagList="geoName.parish"
                    :placeholder="
                      `Start typing and press enter to add a Parish...`
                    "
                    :datacypress="'address-input'"
                    v-on:addTag="parish => geoName.parish.push(parish)"
                    v-on:removeTag="index => geoName.parish.splice(index, 1)"
                    v-model="geoName.parish"
                    v-validate="'required'"
                    data-vv-as="Parishes"
                    label="address_localities_taglist"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="county"
                    >{{ mapFieldToLabel("county") }}</label
                  >

                  <taggableInput
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tagList="geoName.county"
                    :placeholder="
                      `Start typing and press enter to add a County...`
                    "
                    :datacypress="'address-input'"
                    v-on:addTag="county => geoName.county.push(county)"
                    v-on:removeTag="index => geoName.county.splice(index, 1)"
                    v-model="geoName.county"
                    v-validate="'required'"
                    data-vv-as="Counties"
                    label="address_localities_taglist"
                  />
                </div>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="state_electorate"
                  >
                    {{ mapFieldToLabel("electorate") }}
                  </label>
                  <taggableInput
                    :disabled="readOnly"
                    class="w-full mr-4"
                    :tagList="geoName.electorate"
                    :placeholder="
                      `Start typing and press enter to add an Electorate...`
                    "
                    :datacypress="'address-input'"
                    v-on:addTag="
                      electorate => geoName.electorate.push(electorate)
                    "
                    v-on:removeTag="
                      index => geoName.electorate.splice(index, 1)
                    "
                    v-model="geoName.electorate"
                    v-validate="'required'"
                    data-vv-as="Electorates"
                    label="electorate_taglist"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex ml-4 md:mb-4">
                  <div class="w-1/2 mr-3">
                    <label
                      class="block text-gray-700 font-bold mb-2"
                      for="gnb_file_reference"
                    >
                      {{ mapFieldToLabel("gnb_file_reference") }}
                    </label>
                    <input
                      :disabled="readOnly"
                      v-model="geoName.gnb_file_reference"
                      class="w-full mb-4 input-area"
                      id="gnb_file_reference"
                      type="text"
                    />
                  </div>
                  <div class="w-1/2">
                    <label
                      class="block text-gray-700 font-bold mb-2"
                      for="height"
                    >
                      {{ mapFieldToLabel("height") }}
                    </label>
                    <input
                      :disabled="readOnly"
                      v-model="geoName.height"
                      class="w-full mb-4 input-area"
                      id="height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="w-full flex">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="longitude"
                  >
                    {{ mapFieldToLabel("longitude") }}
                  </label>
                  <input
                    :disabled="readOnly"
                    v-model="geoName.longitude"
                    class="w-full mb-4 input-area"
                    id="longitude"
                    type="text"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="latitude"
                    >{{ mapFieldToLabel("latitude") }}</label
                  >
                  <input
                    :disabled="readOnly"
                    v-model="geoName.latitude"
                    class="w-full mb-4 input-area"
                    id="latitude"
                    type="text"
                  />
                </div>
              </div>

              <div class="w-full justify-center flex-col">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="description"
                  ><span class="text-red-star">*</span
                  >{{ mapFieldToLabel("description") }}</label
                >
                <div class="flex flex-col">
                  <textarea
                    :disabled="readOnly"
                    v-model="geoName.description"
                    class="w-full mb-4 input-area h-48 flex"
                    id="description"
                    name="description"
                    v-validate="'required'"
                  ></textarea>
                  <fieldErrorMessage :message="errors.first('description')" />
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex bg-white">
            <div class="w-5/6 mx-auto pb-16 pt-8">
              <div class="py-6 mb-6 border-b-2 self-center">
                <h3 class="font-bold">Name Details and Origin</h3>
              </div>
              <div class="w-full justify-center flex-col">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="origin"
                  >{{ mapFieldToLabel("origin") }}</label
                >
                <p>
                  Please describe the origin of the feature's proposed name in
                  the context of what the name signifies, what culture or
                  background the name derives its meaning or to whom the name
                  commemorates.
                </p>
                <textarea
                  :disabled="readOnly"
                  v-model="geoName.origin"
                  class="w-full input-area h-48"
                  id="origin"
                ></textarea>
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="mapFieldToLabel(`origin_reference_document`, true)"
                  :important="false"
                  :files="geoName.origin_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="origin"
                  stateProp="origin_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile('origin_reference_document', fileIndex);
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('origin')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`marked_map_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.marked_map_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="marked_map"
                  stateProp="marked_map_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.marked_map_reference_document.splice(fileIndex, 1)
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('marked_map')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`marked_photo_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.marked_photo_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="marked_photo"
                  stateProp="marked_photo_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.marked_photo_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('marked_photo')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="mapFieldToLabel(`source_reference_document`, true)"
                  :important="false"
                  :files="geoName.source_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="source"
                  stateProp="source_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.source_reference_document.splice(fileIndex, 1)
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('source')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(
                      `pending_endorsement_reference_document`,
                      true
                    )
                  "
                  :important="false"
                  :files="geoName.pending_endorsement_reference_document"
                  :drafted="true"
                  stateKey="pending_endorsement"
                  stateProp="pending_endorsement_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.pending_endorsement_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage
                  :message="errors.first('pending_endorsement')"
                />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`public_support_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.public_support_reference_document"
                  :drafted="true"
                  stateKey="public_support"
                  stateProp="public_support_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.public_support_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate=""
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('public_support')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(
                      `public_consultation_reference_document`,
                      true
                    )
                  "
                  :important="false"
                  :files="geoName.public_consultation_reference_document"
                  :drafted="true"
                  stateKey="public_consultation"
                  stateProp="public_consultation_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.public_consultation_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate=""
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('public_support')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`pronunciation_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.pronunciation_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="pronunciation"
                  stateProp="pronunciation_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.pronunciation_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('pronunciation')" />
              </div>
              <div class="w-full justify-center flex-col mb-2">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="spelling"
                  >{{ mapFieldToLabel("pronunciation", true) }}</label
                >
                <input
                  :disabled="readOnly"
                  v-model="geoName.pronunciation"
                  class="w-full input-area"
                  id="spelling"
                  type="text"
                />
              </div>
              <div class="w-full justify-center flex-col mb-2">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="meaning"
                  >{{ mapFieldToLabel("meaning", true) }}</label
                >
                <textarea
                  :disabled="readOnly"
                  v-model="geoName.meaning"
                  class="w-full input-area"
                  id="meaning"
                />
              </div>
              <div class="w-full justify-center flex-col mb-2">
                <label
                  class="block text-gray-700 font-bold mb-2"
                  for="history"
                  >{{ mapFieldToLabel("history") }}</label
                >
                <textarea
                  :disabled="readOnly"
                  v-model="geoName.history"
                  class="w-full input-area"
                  id="history"
                />
              </div>
              <div class="w-full justify-center flex-col mb-2">
                <uploadScopedWithTypes
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`historical_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.historical_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="historical"
                  stateProp="historical_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.historical_reference_document.splice(fileIndex, 1)
                  "
                  v-on:fileTypeChanged="
                    payload => updateHistoricalFileType(payload)
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('historical')" />
              </div>
              <div>
                <p class="text-normal-bold mt-8">
                  {{ mapFieldToLabel("aboriginal_name") }}
                </p>
                <div class="flex flex-row text-normal">
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    data-cypress="aboriginal_name-no"
                    name="aboriginal_name-no"
                    v-model="geoName.aboriginal_name"
                    :checkedValue="false"
                    :disabled="!!geoName.multicultural || readOnly"
                    >No</RadioButton
                  >
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="aboriginal_name-yes"
                    data-cypress="aboriginal_name-yes"
                    v-model="geoName.aboriginal_name"
                    :checkedValue="true"
                    :disabled="!!geoName.multicultural || readOnly"
                    >Yes</RadioButton
                  >
                </div>
              </div>
              <div class="w-full flex mt-4">
                <div class="md:w-1/2 justify-center flex-col md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="origin_county"
                  >
                    {{ mapFieldToLabel("aboriginal_country") }}
                  </label>
                  <input
                    :disabled="readOnly"
                    v-model="geoName.aboriginal_country"
                    class="w-full mb-4 input-area"
                    id="origin_county"
                    type="text"
                  />
                </div>
                <div class="md:w-1/2 justify-center flex-col ml-4 md:mb-4">
                  <label
                    class="block text-gray-700 font-bold mb-2"
                    for="language_group"
                    >{{ mapFieldToLabel("aboriginal_language") }}</label
                  >
                  <input
                    :disabled="readOnly"
                    v-model="geoName.aboriginal_language"
                    class="w-full mb-4 input-area"
                    id="language_group"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <p class="text-normal-bold">
                  {{ mapFieldToLabel("multicultural") }}
                </p>
                <div class="flex flex-row text-normal">
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-2"
                    data-cypress="multicultural-no"
                    name="multicultural-no"
                    v-model="geoName.multicultural"
                    :checkedValue="false"
                    :disabled="!!geoName.aboriginal_name || readOnly"
                    >No</RadioButton
                  >
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-2"
                    name="multicultural-yes"
                    data-cypress="multicultural-yes"
                    v-model="geoName.multicultural"
                    :checkedValue="true"
                    :disabled="!!geoName.aboriginal_name || readOnly"
                    >Yes</RadioButton
                  >
                </div>
              </div>
              <div class="w-full justify-center flex-col mt-4">
                <label
                  class="block text-gray-700 font-bold mb-3 mt-2"
                  for="geographical_name"
                >
                  {{ mapFieldToLabel("map_cma") }}</label
                >
                <input
                  v-model="geoName.map_cma"
                  class="w-full mb-4 input-area"
                  id="map_cma"
                  type="text"
                  name="map_cma"
                  :disabled="readOnly"
                />
              </div>

              <!-- 10000 MAP table --->
              <div class="mx-auto">
                <label
                  class="block text-gray-700 font-bold mb-4 mt-2"
                  for="legacy_map"
                >
                  {{ mapFieldToLabel("legacy_map_data") }}</label
                >
                <div class="w-full flex mb-2">
                  <p class="w-3%"></p>
                  <p class="text-normal-bold w-1/2">Map Name</p>

                  <p class="text-normal-bold w-1/2">Map Number</p>
                </div>

                <div
                  class="w-full flex items-center mt-3"
                  v-for="(map_data, index) in legacy_map_data"
                  :key="index"
                >
                  <p class="text-grey text-sub-title w-3%">
                    {{ index + 1 }}
                  </p>
                  <input
                    type="text"
                    class="rounded p-4 mr-4 text-normal bg-grey-input text-grey-text self-center w-1/2"
                    v-bind:value="map_data.legacy_map_name"
                    disabled
                  />
                  <input
                    type="text"
                    class="rounded p-4 mr-4 text-normal text-grey-text bg-grey-input self-center  w-1/4"
                    v-bind:value="map_data.legacy_map_reference"
                    disabled
                  />

                  <button
                    class="justify-center p-4 items-center cursor-pointer  w-1/4"
                    @click="removeLegacyMapData(index)"
                    :disabled="readOnly"
                    v-bind:class="{
                      redButtonFaded: readOnly,
                      redButtonSolid: !readOnly
                    }"
                  >
                    Remove
                  </button>
                </div>

                <div class="w-full flex items-center mt-3">
                  <p class="text-grey text-sub-title w-3%">
                    {{ legacy_map_data.length + 1 || "" }}
                  </p>

                  <input
                    type="text"
                    class="rounded p-4 mr-4 text-normal bg-grey-input  text-grey-text self-center w-1/2"
                    placeholder="Enter Name..."
                    data-cypress="map-name-input"
                    v-model="new_legacy_map_entry.legacy_map_name"
                    :disabled="readOnly"
                  />
                  <input
                    type="text"
                    class=" rounded p-4 mr-4 text-normal text-grey-text bg-grey-input self-center w-1/4"
                    placeholder="Enter Number..."
                    data-cypress="map-number-input"
                    v-model="new_legacy_map_entry.legacy_map_reference"
                    :disabled="readOnly"
                  />
                  <button
                    class="justify-center p-4 items-center cursor-pointer w-1/4"
                    data-cypress="add-map-button"
                    @click="addNewLegacyMapEntry"
                    :disabled="
                      readOnly ||
                        !new_legacy_map_entry.legacy_map_reference ||
                        !new_legacy_map_entry.legacy_map_name
                    "
                    v-bind:class="{
                      greenButtonFaded:
                        readOnly ||
                        !new_legacy_map_entry.legacy_map_reference ||
                        !new_legacy_map_entry.legacy_map_name,
                      greenButtonSolid:
                        !readOnly &&
                        !!new_legacy_map_entry.legacy_map_reference &&
                        !!new_legacy_map_entry.legacy_map_name
                    }"
                  >
                    Add map information
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="w-full flex bg-white">
            <div class="w-5/6 mx-auto pb-16 pt-8">
              <div class="py-6 mb-6 border-b-2 self-center">
                <h3 class="font-bold">GNB Attachments</h3>
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="mapFieldToLabel(`advert_reference_document`, true)"
                  :important="false"
                  :files="geoName.advert_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="advert"
                  stateProp="advert_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile('advert_reference_document', fileIndex);
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('advert')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="mapFieldToLabel(`gazette_reference_document`, true)"
                  :important="false"
                  :files="geoName.gazette_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="gazette"
                  stateProp="gazette_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile('gazette_reference_document', fileIndex);
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('gazette')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="mapFieldToLabel(`general_reference_document`, true)"
                  :important="false"
                  :files="geoName.general_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="general"
                  stateProp="general_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile('general_reference_document', fileIndex);
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('general')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(
                      `minister_briefing_note_reference_document`,
                      true
                    )
                  "
                  :important="false"
                  :files="geoName.minister_briefing_note_reference_document"
                  :drafted="true"
                  stateKey="minister_briefing_note"
                  stateProp="minister_briefing_note_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile(
                        'minister_briefing_note_reference_document',
                        fileIndex
                      );
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage
                  :message="errors.first('minister_briefing_note')"
                />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`media_release_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.media_release_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="media_release"
                  stateProp="media_release_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile('media_release_reference_document', fileIndex);
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('media_release')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(`official_map_reference_document`, true)
                  "
                  :important="false"
                  :files="geoName.official_map_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="official_map"
                  stateProp="official_map_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex =>
                      geoName.official_map_reference_document.splice(
                        fileIndex,
                        1
                      )
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage :message="errors.first('official_map')" />
              </div>
              <div class="w-full justify-center flex-col mb-4">
                <uploadScoped
                  class="mt-4"
                  data-cypress="upload-photo"
                  :label="
                    mapFieldToLabel(
                      `evaluation_report_reference_document`,
                      true
                    )
                  "
                  :important="false"
                  :files="geoName.evaluation_report_reference_document"
                  :showPublicDisplay="true"
                  :changeVisibility="changeFileVisibility"
                  :drafted="true"
                  stateKey="evaluation_report"
                  stateProp="evaluation_report_reference_document"
                  :uploadFile="uploadFileForComponent"
                  v-on:deleteFile="
                    fileIndex => {
                      deleteFile(
                        'evaluation_report_reference_document',
                        fileIndex
                      );
                    }
                  "
                  v-validate="'min_value:1'"
                  :disabled="readOnly"
                />
                <fieldErrorMessage
                  :message="errors.first('evaluation_report')"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-between w-full">
            <ButtonWithSpinner
              class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
              :disabled="$wait.is('savingGeoname') || readOnly"
              data-cypress="delete-button"
              @click="$router.go(-1)"
              >Cancel Changes</ButtonWithSpinner
            >

            <ButtonWithSpinner
              class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
              :disabled="!isFormValid || readOnly || $wait.is('savingGeoname')"
              @click="confirmChanges()"
              :isSpinning="$wait.is('savingGeoname')"
              >Confirm Changes</ButtonWithSpinner
            >
          </div>
          <LegacyAdverts :geonameId="geonameId" />
          <EditHistory
            :geonameId="geonameId"
            :fetchGeonameHistory="fetchGeonameHistory"
            :history="history"
          />
        </div>
      </template>
    </showLoading>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
import { waitFor } from "vue-wait";
import moment from "moment";

const {
  mapActions: mapPnpActions,
  mapGetters: mapPnpGetters
} = createNamespacedHelpers("pnpState");

import uploadScoped from "../elements/uploadScoped";
import taggableInput from "../proposals/place/taggableInput.vue";
import fieldErrorMessage from "../elements/fieldErrorMessage";
import editGeoNameConfirmationModal from "./editGeoNameConfirmationModal";
import pnpCRUD from "../../helpers/pnpCRUD";

import mapFieldToLabel from "./map-field-to-label.js";
import EditHistory from "./edit-history.vue";
import LegacyAdverts from "./legacy-adverts.vue";
import DualNamed from "./dual-named/DualNamedComponent";
import TaggableDateSelector from "../proposals/place/taggableDateSelector";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_BOUNDARY
} from "../proposals/place/pnp-constants.js";

export default {
  name: "geonameEdit",
  components: {
    uploadScoped,
    fieldErrorMessage,
    editGeoNameConfirmationModal,
    taggableInput,
    EditHistory,
    LegacyAdverts,
    TaggableDateSelector,
    DualNamed
  },
  data() {
    return {
      PROPOSAL_TYPE_PLACE,
      PROPOSAL_TYPE_LOCALITY,
      PROPOSAL_TYPE_BOUNDARY,
      history: [],
      lastOrder: "DESC",
      statuses: [],
      designations: [],
      geonameExists: false,
      new_legacy_map_entry: {},
      legacy_map_data: [],
      date_modified: null,
      date_created: null,
      geoName: {
        status: null,
        designation: null,
        geoname_type: null,
        place_name: null,
        geographical_name: null,
        gnb_file_reference: null,
        map_cma: null,
        longitude: null,
        latitude: null,
        height: null,
        description: null,
        meaning: null,
        origin: null,
        history: null,
        pronunciation: null,
        spatial_ref: null,
        gfid: null,
        gfid_type: null,
        aboriginal_name: null,
        aboriginal_country: null,
        aboriginal_language: null,
        multicultural: null,
        lga: null,
        locality: null,
        parish: null,
        county: null,
        electorate: null,
        previous_name: null,
        gazettal_dates: [],
        is_legacy: null,
        dual_named: false,
        dual_identifier: null,
        dual_geographical_name: null,
        is_suitable_for_public_display: false,
        national_feature_set: null,
        legacy_map_name: null,
        legacy_map_reference: null,
        official_map_reference_document: [],
        pronunciation_reference_document: [],
        origin_reference_document: [],
        historical_reference_document: [],
        marked_map_reference_document: [],
        marked_photo_reference_document: [],
        source_reference_document: [],
        pending_endorsement_reference_document: [],
        advert_reference_document: [],
        gazette_reference_document: [],
        general_reference_document: [],
        minister_briefing_note_reference_document: [],
        media_release_reference_document: [],
        evaluation_report_reference_document: [],
        public_support_reference_document: [],
        public_consultation_reference_document: []
      }
    };
  },
  computed: {
    ...mapGetters({
      lgasArray: "lgaState"
    }),
    ...mapPnpGetters({
      linkedGeoname: "getGeonameLinkedWith"
    }),
    endStateStatuses() {
      return [
        "Official Assigned",
        "Abandoned",
        "Rejected",
        "Discontinued",
        "Official Recorded",
        "Official Assigned NPWS",
        "Official Assigned EDU",
        "Withdrawn"
      ];
    },
    publicDocumentTypes() {
      return [
        "official_map_reference_document",
        "advert_reference_document",
        "gazette_reference_document",
        "media_release_reference_document"
      ];
    },
    readOnly() {
      // const currentStatus = this.geoName.status;
      // return (
      //   !this.geoName.is_legacy &&
      //   !this.endStateStatuses.some(status => currentStatus === status)
      // );
      return false;
    },
    statusList() {
      if (this.geoName.is_legacy) {
        return this.statuses;
      } else {
        return this.endStateStatuses;
      }
    },
    canChangeStatus() {
      if (this.geoName.is_legacy) {
        return true;
      } else {
        const currentStatus = this.geoName.status;
        return this.endStateStatuses.some(status => currentStatus === status);
      }
    },
    isFormValid() {
      return (
        this.geoName.geographical_name &&
        this.geoName.geographical_name.length > 0 &&
        this.geoName.lga &&
        this.geoName.lga.length > 0 &&
        this.geoName.description &&
        this.geoName.description.length > 0
      );
    },
    isAdmin() {
      return this.$store.state.user.user.isAdmin;
    },
    geonameId() {
      return this.$route.params.geonameId;
    },
    gazettal_dates() {
      return this.geoName.gazettal_dates ? this.geoName.gazettal_dates : [];
    }
  },
  async created() {
    this.statuses = await pnpCRUD.getPlaceNameStatuses();
    this.designations = await pnpCRUD.getGeonameDesignations();
    await this.fetchGeoname();
  },
  watch: {
    linkedGeoname(geoname) {
      if (geoname) {
        this.geoName.dual_named = true;
        this.geoName.dual_geographical_name = geoname.geographical_name;
        this.geoName.dual_identifier = geoname.identifier;
      }
    }
  },
  methods: {
    fetchGeonameHistory: waitFor("fetchingHistory", async function(order) {
      if (order) {
        this.lastOrder = order;
      }

      const sortOrder = order || this.lastOrder || "DESC";

      try {
        this.history = await pnpCRUD.getGeonameHistoryById(
          this.geonameId,
          sortOrder
        );
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to Historical Data",
          text: "There was an issue retrieving historical data"
        });
      }
    }),
    ...mapPnpActions(["uploadFile"]),
    mapFieldToLabel: (field, shortLabel) => mapFieldToLabel(field, shortLabel),
    addNewLegacyMapEntry() {
      this.legacy_map_data.push(this.new_legacy_map_entry);
      this.new_legacy_map_entry = {};
    },
    removeLegacyMapData(index) {
      this.legacy_map_data.splice(index, 1);
    },
    formatDate: date => {
      return moment(date).format("D MMM, YYYY");
    },
    updateHistoricalFileType(payload) {
      this.geoName.historical_reference_document[payload.index].document_type =
        payload.value;
    },
    async uploadFileForComponent(payload) {
      const s3FileData = await this.uploadFile(
        Object.assign({}, payload, {
          componentOnly: true
        })
      );
      if (s3FileData) {
        s3FileData.is_suitable_for_public_display = this.publicDocumentTypes.some(
          publicDocumentType => payload.prop === publicDocumentType
        );
        this.geoName[payload.prop].push(s3FileData);
      }
    },
    changeFileVisibility(prop, index, isPublic) {
      this.geoName[prop][index].is_suitable_for_public_display = isPublic;
    },
    deleteFile(prop, index) {
      this.geoName[prop].splice(index, 1);
    },
    async confirmChanges() {
      this.$modal.show("edit-geoname");
    },
    cancelChanges() {
      this.$router.push({ name: "placeNameProposals" });
    },
    saveGeoName: waitFor("savingGeoname", async function(
      requiresSpatialOpsUpdate
    ) {
      try {
        this.geoName.requiresSpatialOpsUpdate = requiresSpatialOpsUpdate;
        this.geoName.legacy_map_data = [...this.legacy_map_data];
        const geoname = Object.assign({}, this.geoName);
        await pnpCRUD.updateGeonameById({
          geoname,
          geonameId: this.geonameId
        });
        this.$notify({
          group: "toast",
          type: "success",
          title: "Record Updated",
          text: "The geographical name record was updated successfully."
        });
        this.fetchGeonameHistory();
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to Save Record",
          text: "There was an issue saving this geographical name record."
        });
      }
    }),
    fetchGeoname: waitFor("fetchingGeoname", async function() {
      try {
        const geoname = await pnpCRUD.getGeonameById(this.geonameId);
        this.geoName = Object.assign({}, geoname);
        if (!this.geoName.locality) this.geoName.locality = [];
        if (!this.geoName.lga) this.geoName.lga = [];
        if (!this.geoName.parish) this.geoName.parish = [];
        if (!this.geoName.county) this.geoName.county = [];
        if (!this.geoName.official_map_reference_document)
          this.geoName.official_map_reference_document = [];
        if (!this.geoName.pronunciation_reference_document)
          this.geoName.pronunciation_reference_document = [];
        if (!this.geoName.origin_reference_document)
          this.geoName.origin_reference_document = [];
        if (!this.geoName.marked_map_reference_document)
          this.geoName.marked_map_reference_document = [];
        if (!this.geoName.marked_photo_reference_document)
          this.geoName.marked_photo_reference_document = [];
        if (!this.geoName.source_reference_document)
          this.geoName.source_reference_document = [];
        if (!this.geoName.pending_endorsement_reference_document)
          this.geoName.pending_endorsement_reference_document = [];
        if (!this.geoName.advert_reference_document)
          this.geoName.advert_reference_document = [];
        if (!this.geoName.gazette_reference_document)
          this.geoName.gazette_reference_document = [];
        if (!this.geoName.general_reference_document)
          this.geoName.general_reference_document = [];
        if (!this.geoName.minister_briefing_note_reference_document)
          this.geoName.minister_briefing_note_reference_document = [];
        if (!this.geoName.media_release_reference_document)
          this.geoName.media_release_reference_document = [];
        if (!this.geoName.evaluation_report_reference_document)
          this.geoName.evaluation_report_reference_document = [];
        if (!this.geoName.historical_reference_document)
          this.geoName.historical_reference_document = [];
        if (!this.geoName.public_support_reference_document)
          this.geoName.public_support_reference_document = [];
        if (!this.geoName.public_consultation_reference_document)
          this.geoName.public_consultation_reference_document = [];
        if (!this.geoName.electorate) this.geoName.electorate = [];
        if (!this.geoName.gazettal_dates) this.geoName.gazettal_dates = [];
        if (!this.geoName.previous_name) this.geoName.previous_name = [];

        if (this.geoName.legacy_map_data) {
          this.legacy_map_data = [...this.geoName.legacy_map_data];
        } else this.legacy_map_data = [];

        this.geonameExists = !!this.geoName.geographical_name;

        this.date_modified = moment(geoname.date_modified).format(
          "DD/MM/YYYY h:mm:ss A"
        );
        this.date_created = moment(geoname.date_created).format(
          "DD/MM/YYYY h:mm:ss A"
        );
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to Retrieve Record",
          text: "There was an issue retrieving this geographical name"
        });
      }
    }),

    /**
     * Add a new gazettal date to the list
     */
    addNewGazettalDate(date) {
      this.geoName.gazettal_dates.push(date);
    },

    /**
     * Remove a gazettal date to the list
     */
    setRemovedGazettalDate(date) {
      if (Object.keys(date).includes("identifier")) {
        return this.removeExistingGazettal(date);
      }

      return this.removeLocalGazettal(date);
    },

    /**
     * Handle the removal of a new gazette date not yet
     * committed to the database
     */
    removeLocalGazettal(date) {
      const keepers = this.geoName.gazettal_dates.filter(
        storedDate => storedDate.date_gazetted !== date.date_gazetted
      );

      this.geoName.gazettal_dates = keepers;
    },

    /**
     * Remove an existing gazettal. Hides the date from the
     * list and is removed entirely on save
     */
    removeExistingGazettal(date) {
      const idx = this.geoName.gazettal_dates.findIndex(
        storedDate => storedDate.identifier === date.identifier
      );

      this.$set(this.geoName.gazettal_dates[idx], "isRemoving", true);
    }
  }
};
</script>

<style scoped>
.greenButtonSolid {
  background-color: #00a908;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}

.greenButtonFaded {
  background-color: #00a90876;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: default;
}
</style>
