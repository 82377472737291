var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "py-4 px-4 rounded",
      class: {
        "input-field-disabled": _vm.disabled,
        "input-field": !_vm.disabled,
        "py-6": _vm.displayableDates.length === 0 && _vm.disabled,
      },
    },
    [
      _vm._l(_vm.displayableDates, function (date, index) {
        return _c(
          "span",
          {
            key: index,
            staticClass: "tags-input-badge tags-input-badge-pill",
            class: { "tags-input-badge-disabled": !date.isEditable },
            attrs: {
              "data-toggle": !!date.isEditable ? "tooltip" : false,
              title:
                "This proposal is currently 'PENDING GAZETTAL'. Changes to attached gazettal dates are locked",
            },
          },
          [
            _c("span", { staticClass: "tag-text" }, [
              _vm._v(_vm._s(_vm.formatDate(date.date_gazetted))),
            ]),
            date.isEditable
              ? _c("i", {
                  staticClass: "multiselect__tag-icon",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeTag(date)
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      !_vm.disabled
        ? _c("datepicker", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, date_format: "yyyy-MM-dd" },
                expression: "{ required: true, date_format: 'yyyy-MM-dd' }",
              },
            ],
            staticClass: "w-full rounded-lg bg-grey-input",
            attrs: {
              name: "dateInput",
              placeholder: _vm.datePlaceholderText,
              format: _vm.datePlaceholder,
              "input-class": "w-full bg-grey-input text-grey-text",
            },
            on: {
              selected: function ($event) {
                return _vm.addTag($event)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }