import { render, staticRenderFns } from "./LinkConfirmationDialogue.vue?vue&type=template&id=34fc19cb"
import script from "./LinkConfirmationDialogue.vue?vue&type=script&lang=js"
export * from "./LinkConfirmationDialogue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34fc19cb')) {
      api.createRecord('34fc19cb', component.options)
    } else {
      api.reload('34fc19cb', component.options)
    }
    module.hot.accept("./LinkConfirmationDialogue.vue?vue&type=template&id=34fc19cb", function () {
      api.rerender('34fc19cb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/geo-names/dual-named/LinkConfirmationDialogue.vue"
export default component.exports