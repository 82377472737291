<template>
  <div>
    <MessagePanel
      warning
      msg="This response suggests that this proposal is likely to be deemed unacceptable"
      class="mb-4"
      v-if="complied_chapter_6_8 === false"
    />
    <div class="flex flex-row text-normal pb-6 dividing-line-h">
      <p class="w-3%">
        1.
      </p>
      <p class="text-normal w-5/6">
        Does this proposal comply with
        <a
          href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105"
          target="_blank"
          >Chapter 6.8 of the NSW Addressing User Manual?</a
        >
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="complied_chapter_6_8-no"
          name="complied_chapter_6_8-no"
          v-model="complied_chapter_6_8"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="complied_chapter_6_8-yes"
          data-cypress="complied_chapter_6_8-yes"
          v-model="complied_chapter_6_8"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>

    <div
      class=" text-normal  pt-6"
      v-bind:class="{
        'dividing-line-h pb-6': !proposed_name_background_information
      }"
    >
      <div class="flex-row flex">
        <p class="w-3%">
          2.
        </p>
        <p class="text-normal w-5/6">
          Is there background information on the proposed name (ie. Historical
          information, entomology, reason for selection)?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="proposed_name_background_information-no"
            name="proposed_name_background_information-no"
            v-model="proposed_name_background_information"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="proposed_name_background_information-yes"
            data-cypress="proposed_name_background_information-yes"
            v-model="proposed_name_background_information"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </div>
    </div>
    <div
      v-if="proposed_name_background_information"
      class=" text-normal pb-6 dividing-line-h"
    >
      <uploadScoped
        class="mt-4"
        label="Attach Supporting Documentation"
        :important="true"
        :files="background_info_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="background_info_reference_document"
        :uploadFile="
          params =>
            uploadFile(Object.assign({}, params, { isChecklistFile: true }))
        "
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedChecklistFile({
              key: 'address_locality',
              prop: 'background_info_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No Photos were uploaded for this place name.
        </div>
      </div>
      <div v-if="evaluation">
        <div
          v-for="(rules, index) in evaluation.issues"
          class="flex items-center relative"
          v-bind:key="index"
        >
          <errorIcon />
          <div class="text-red-error text-normal-bold ml-2">
            {{ rules.shortDescription }}
          </div>
        </div>
      </div>
    </div>
    <MessagePanel
      error
      msg="Council resolution support is needed before this proposal can be submitted"
      v-if="supported_by_council === false"
    />
    <div class="text-normal pb-6 pt-6 dividing-line-h">
      <div class="flex flex-row">
        <p class="w-3%">
          3.
        </p>
        <p class="text-normal w-5/6">
          Is the name supported by council resolution?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="name-no"
            name="name-no"
            v-model="supported_by_council"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="supported_by_council-yes"
            data-cypress="supported_by_council-yes"
            v-model="supported_by_council"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </div>
      <div v-if="supported_by_council">
        <uploadScoped
          class="mt-4"
          label="Attach supporting documentation"
          :important="true"
          :files="council_resolution_reference_document"
          :drafted="true"
          stateKey="address_locality"
          stateProp="council_resolution_reference_document"
          :uploadFile="
            params =>
              uploadFile(Object.assign({}, params, { isChecklistFile: true }))
          "
          v-on:deleteFile="
            fileIndex =>
              deleteUploadedChecklistFile({
                key: 'address_locality',
                prop: 'council_resolution_reference_document',
                index: fileIndex
              })
          "
          v-validate="'min_value:1'"
          :disabled="readOnly"
        />
        <div
          class="flex items-center absolute"
          v-if="errors.first('fileUploader')"
        >
          <errorIcon />
          <div class="ml-2 text-red-error text-normal-bold">
            No Photos were uploaded for this place name.
          </div>
        </div>
        <div v-if="evaluation">
          <div
            v-for="(rules, index) in evaluation.issues"
            class="flex items-center relative"
            v-bind:key="index"
          >
            <errorIcon />
            <div class="text-red-error text-normal-bold ml-2">
              {{ rules.shortDescription }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <MessagePanel
      warning
      msg="This response suggests that this proposal is likely to be deemed unacceptable"
      v-if="proposed_locality_name_unique === false"
    />
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        4.
      </p>
      <p class="text-normal w-5/6">
        To your knowledge, is the new address locality name unique in Australia?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_name_unique-no"
          name="proposed_locality_name_unique-no"
          v-model="proposed_locality_name_unique"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_name_unique-yes"
          data-cypress="proposed_locality_name_unique-yes"
          v-model="proposed_locality_name_unique"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>

    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        5.
      </p>
      <p class="text-normal w-5/6">
        Is the address locality named after an estate or linked to a subdivision
        name?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_name_linked_to_estate_or_subdivision-no"
          name="proposed_locality_name_linked_to_estate_or_subdivision-no"
          v-model="proposed_locality_name_linked_to_estate_or_subdivision"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_name_linked_to_estate_or_subdivision-yes"
          data-cypress="proposed_locality_name_linked_to_estate_or_subdivision-yes"
          v-model="proposed_locality_name_linked_to_estate_or_subdivision"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <MessagePanel
      warning
      msg="This response suggests that this proposal is likely to be deemed unacceptable"
      v-if="proposed_locality_name_uses_suffix_prefix_indicator === true"
    />
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        6.
      </p>
      <p class="text-normal w-5/6">
        Does the name use a qualifying terminology such as a prefix or suffix
        (e.g. north, south, inner, upper, old, new)?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_name_uses_suffix_prefix_indicator-no"
          name="proposed_locality_name_uses_suffix_prefix_indicator-no"
          v-model="proposed_locality_name_uses_suffix_prefix_indicator"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_name_uses_suffix_prefix_indicator-yes"
          data-cypress="proposed_locality_name_uses_suffix_prefix_indicator-yes"
          v-model="proposed_locality_name_uses_suffix_prefix_indicator"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        7.
      </p>
      <p class="text-normal w-5/6">
        Does the proposal improve public safety and service delivery?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_name_improves_safety_service-no"
          name="proposed_locality_name_improves_safety_service-no"
          v-model="proposed_locality_name_improves_safety_service"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_name_improves_safety_service-yes"
          data-cypress="proposed_locality_name_improves_safety_service-yes"
          v-model="proposed_locality_name_improves_safety_service"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        8.
      </p>
      <p class="text-normal w-5/6">
        For an urban area, does the new address locality meet the size limits as
        described in
        <a
          href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105"
          target="_blank"
          >Chapter 6.8 of the NSW Addressing User Manual</a
        >?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_meets_size_limits-no"
          name="proposed_locality_meets_size_limits-no"
          v-model="proposed_locality_meets_size_limits"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_meets_size_limits-yes"
          data-cypress="proposed_locality_meets_size_limits-yes"
          v-model="proposed_locality_meets_size_limits"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <MessagePanel
      error
      msg="A map or GIS file is needed before this proposal can be submitted"
      v-if="map_or_gis_file_provided === false"
    />
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        9.
      </p>
      <p class="text-normal w-5/6">
        Has a map or GIS file been provided (ESRI Shape file (.shp) preferred)?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="map_or_gis_file_provided-no"
          name="map_or_gis_file_provided-no"
          v-model="map_or_gis_file_provided"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="map_or_gis_file_provided-yes"
          data-cypress="map_or_gis_file_provided-yes"
          v-model="map_or_gis_file_provided"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>

    <div class="flex flex-row text-normal pb-6 pt-6">
      <p class="w-3%">
        10.
      </p>
      <p class="text-normal w-5/6">
        Does the address locality cover more than one LGA?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="proposed_locality_multiple_lgas-no"
          name="proposed_locality_multiple_lgas-no"
          v-model="proposed_locality_multiple_lgas"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="proposed_locality_multiple_lgas-yes"
          data-cypress="proposed_locality_multiple_lgas-yes"
          v-model="proposed_locality_multiple_lgas"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <div class="flex flex-row text-normal dividing-line-h">
      <div v-if="proposed_locality_multiple_lgas" class="flex flex-grow">
        <AffectedLgaTable
          :stateKey="`address_locality`"
          :readOnly="readOnly"
          :lgas="lgas"
        />
      </div>
    </div>
    <MessagePanel
      warning
      msg="This response suggests that this proposal is likely to be deemed unacceptable"
      v-if="public_consultation_by_council === false"
    />
    <div class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
      <p class="w-3%">
        11.
      </p>
      <p class="text-normal w-5/6">
        Has public consultation been carried out by the council?
      </p>
      <div class="float right flex-row flex">
        <RadioButton
          class="flex-0 mr-6"
          data-cypress="public_consultation_by_council-no"
          name="public_consultation_by_council-no"
          v-model="public_consultation_by_council"
          :checkedValue="false"
          :disabled="readOnly"
          v-validate="'required'"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6"
          name="public_consultation_by_council-yes"
          data-cypress="public_consultation_by_council-yes"
          v-model="public_consultation_by_council"
          :checkedValue="true"
          :disabled="readOnly"
          v-validate="'required'"
          >Yes</RadioButton
        >
      </div>
    </div>
    <div class="text-normal pb-6 dividing-line-h" v-if="public_consultation_by_council">
      <uploadScoped
        class="mt-4"
        data-cypress="upload-public_consultation"
        label="Attach supporting material for public consultation"
        :important="false"
        :files="public_consultation_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="public_consultation_reference_document"
        :uploadFile="
          params =>
            uploadFile(Object.assign({}, params, { isChecklistFile: true }))
        "
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedChecklistFile({
              key: 'address_locality',
              prop: 'public_consultation_reference_document',
              index: fileIndex
            })
        "
        :disabled="readOnly"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapPnpGetters } = createNamespacedHelpers("pnpState");
const { mapMutations: mapPnpMutations } = createNamespacedHelpers("pnpState");
const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import AffectedLgaTable from "./affectedLgaTable.vue";
import MessagePanel from "@/components/proposals/place/messagePanel";

export default {
  components: {
    AffectedLgaTable,
    MessagePanel
  },
  data() {
    return {
      evaluation: false
    };
  },
  props: {
    readOnly: {
      type: Boolean
    }
  },
  computed: {
    supported_by_council: {
      get() {
        return this.get_address_locality_supported_by_council();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_supported_by_council(value);
      }
    },
    council_resolution_reference_document: {
      get() {
        return this.get_address_locality_council_resolution_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_council_resolution_reference_document(
            value
          );
      }
    },
    complied_chapter_6_8: {
      get() {
        return this.get_address_locality_complied_chapter_6_8();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_complied_chapter_6_8(value);
      }
    },
    proposed_name_background_information: {
      get() {
        return this.get_address_locality_proposed_name_background_information();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_name_background_information(value);
      }
    },
    background_info_reference_document: {
      get() {
        return this.get_address_locality_background_info_reference_document();
      },
      set(value) {
        if (value || value === false) {
          this.set_address_locality_background_info_reference_document(value);
        }
      }
    },
    proposed_locality_name_unique: {
      get() {
        return this.get_address_locality_proposed_locality_name_unique();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_name_unique(value);
      }
    },
    proposed_locality_name_linked_to_estate_or_subdivision: {
      get() {
        return this.get_address_locality_proposed_locality_name_linked_to_estate_or_subdivision();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_name_linked_to_estate_or_subdivision(
            value
          );
      }
    },
    proposed_locality_name_uses_suffix_prefix_indicator: {
      get() {
        return this.get_address_locality_proposed_locality_name_uses_suffix_prefix_indicator();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_name_uses_suffix_prefix_indicator(
            value
          );
      }
    },
    proposed_locality_name_improves_safety_service: {
      get() {
        return this.get_address_locality_proposed_locality_name_improves_safety_service();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_name_improves_safety_service(
            value
          );
      }
    },
    proposed_locality_meets_size_limits: {
      get() {
        return this.get_address_locality_proposed_locality_meets_size_limits();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_meets_size_limits(value);
      }
    },
    map_or_gis_file_provided: {
      get() {
        return this.get_address_locality_map_or_gis_file_provided();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_map_or_gis_file_provided(value);
      }
    },
    proposed_locality_multiple_lgas: {
      get() {
        return this.get_address_locality_proposed_locality_multiple_lgas();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_proposed_locality_multiple_lgas(value);
      }
    },
    lgas: {
      get() {
        return this.get_address_locality_lgas();
      },
      set(value) {
        if (value || value === false) this.set_address_locality_lgas(value);
      }
    },
    public_consultation_by_council: {
      get() {
        return this.get_address_locality_public_consultation_by_council();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_public_consultation_by_council(value);
      }
    },
    public_consultation_reference_document: {
      get() {
        return this.get_address_locality_public_consultation_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_public_consultation_reference_document(value);
      }
    },
    local_newspapers: {
      get() {
        return this.get_address_locality_local_newspapers();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_local_newspapers(value);
      }
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_address_locality_supported_by_council",
      "get_address_locality_council_resolution_reference_document",
      "get_address_locality_complied_chapter_6_8",
      "get_address_locality_proposed_name_background_information",
      "get_address_locality_background_info_reference_document",
      "get_address_locality_proposed_locality_name_unique",
      "get_address_locality_proposed_locality_name_linked_to_estate_or_subdivision",
      "get_address_locality_proposed_locality_name_uses_suffix_prefix_indicator",
      "get_address_locality_proposed_locality_name_improves_safety_service",
      "get_address_locality_proposed_locality_meets_size_limits",
      "get_address_locality_map_or_gis_file_provided",
      "get_address_locality_proposed_locality_multiple_lgas",
      "get_address_locality_lgas",
      "get_address_locality_public_consultation_by_council",
      "get_address_locality_local_newspapers",
      "get_address_locality_public_consultation_reference_document"
    ]),
    ...mapPnpMutations([
      "deleteUploadedChecklistFile",
      "set_address_locality_supported_by_council",
      "set_address_locality_council_resolution_reference_document",
      "set_address_locality_complied_chapter_6_8",
      "set_address_locality_proposed_name_background_information",
      "set_address_locality_background_info_reference_document",
      "set_address_locality_proposed_locality_name_unique",
      "set_address_locality_proposed_locality_name_linked_to_estate_or_subdivision",
      "set_address_locality_proposed_locality_name_uses_suffix_prefix_indicator",
      "set_address_locality_proposed_locality_name_improves_safety_service",
      "set_address_locality_proposed_locality_meets_size_limits",
      "set_address_locality_map_or_gis_file_provided",
      "set_address_locality_proposed_locality_multiple_lgas",
      "set_address_locality_lgas",
      "set_address_locality_public_consultation_by_council",
      "set_address_locality_local_newspapers",
      "set_address_locality_public_consultation_reference_document"
    ])
  }
};
</script>

<style scoped>
a {
  color: #002664;
  text-decoration: none;
}
</style>
