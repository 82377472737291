import { render, staticRenderFns } from "./gazettal.vue?vue&type=template&id=575dedcb"
import script from "./gazettal.vue?vue&type=script&lang=js"
export * from "./gazettal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('575dedcb')) {
      api.createRecord('575dedcb', component.options)
    } else {
      api.reload('575dedcb', component.options)
    }
    module.hot.accept("./gazettal.vue?vue&type=template&id=575dedcb", function () {
      api.rerender('575dedcb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/road/audit-trail/entries/gazettal.vue"
export default component.exports