var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-grow mt-4 px-6 pb-5" }, [
    _c("div", { staticClass: "sm:-mx-6 lg:-mx-8" }, [
      _c(
        "div",
        { staticClass: "align-middle inline-block min-w-full sm:px-6 lg:px-8" },
        [
          _c("div", { staticClass: "overflow-hidden" }, [
            _c("h3", { staticClass: "mb-4 text-base" }, [
              _vm._v(_vm._s(_vm.heading)),
            ]),
            _vm.proposals.length > 0
              ? _c("table", { staticClass: "w-full table-fixed" }, [
                  _vm._m(0),
                  _c(
                    "tbody",
                    { staticClass: "bg-white divide-y divide-gray-200" },
                    _vm._l(_vm.proposals, function (proposal) {
                      return _c("tr", { key: proposal.identifier }, [
                        _c("td", { staticClass: "px-6 py-4 break-words" }, [
                          _c("div", { staticClass: "flex items-center" }, [
                            _c(
                              "div",
                              { staticClass: "text-sm leading-5 font-medium" },
                              [
                                _vm._v(
                                  " " + _vm._s(proposal.geographical_name) + " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "px-6 py-4 break-words" }, [
                          _c(
                            "div",
                            { staticClass: "text-sm leading-5 text-gray-900" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(proposal.lga ? proposal.lga[0] : "-") +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "px-6 py-4 whitespace-no-wrap" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-sm leading-5 text-gray-900",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      proposal.gnb_file_reference || "None"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(proposal.date_start)) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(proposal.date_end)) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-6 py-4 text-right text-sm leading-5 font-medium",
                          },
                          [
                            _c(_vm.getActionComponent(proposal), {
                              tag: "component",
                              attrs: { proposal: proposal },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _c("p", { staticClass: "text-sm" }, [
                  _vm._v(" There are currently no proposals to list. "),
                ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "w-3/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Proposal Name ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Local Gov. Area ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "w-1/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" GNB File ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Publication Date ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "w-2/12 px-6 py-3 bg-grey-lighter text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
          },
          [_vm._v(" Closing Date ")]
        ),
        _c("th", { staticClass: "w-2/12 px-6 py-3 bg-grey-lighter" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }