<template>
  <div
    class="backgroundDiv w-screen h-screen flex flex-wrap justify-center items-center"
  >
    <!-- <img class="logo h-12 absolute pin-t pin-l" src="../assets/Logos/GNB_Logo.png"> -->
    <img
      src="../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div
      class="w-2/3 bg-white mx-auto mt-16 z-10 rounded shadow-lg container"
      v-if="!loading && road.name_status == 'Sent For Appeal'"
    >
      <div class="w-9/10 mx-auto mb-8 dividing-line-h">
        <div class="mb-4 pt-16 text-black text-title">Uphold Decision</div>
      </div>

      <div
        class="w-9/10 mx-auto flex rounded-lg p-3 border border-solid border-grey-border"
      >
        <proposalIcon
          :label="road.roadname_classification"
          classNames="w-12 h-12 ml-2 mr-5 text-sm"
        />
        <!-- user details -->
        <div class="self-center w-3/8">
          <p class="font-semibold text-black text-sub-title">
            {{ road.roadname }} {{ road.road_type }}
          </p>
        </div>
        <!-- user types -->
        <div class="w-3/8 self-center pl-8">
          <p class="text-black text-grey-text text-normal">
            {{ road.suburb_name }} {{ road.lga_name }}
          </p>
        </div>
        <!-- date created -->
        <div class="ml-auto mr-2 self-center text-sm font-semibold">
          <StatusButton :label="road.name_status" :status="road.name_status" />
        </div>
      </div>
      <div class="w-9/10 mx-auto">
        <div class="pt-10 pb-2 w-full font-semibold text-normal">
          Please enter any comments or feedback to be shared with the Road
          Naming Authority here:
        </div>
        <div class="w-full">
          <textarea
            v-model="comments"
            placeholder="Write your comments or feedback here"
            class="w-full p-5 bg-grey-input h-64 rounded-lg text-normal"
          ></textarea>
        </div>
        <button
          @click="submitUphold"
          class="p-4 mt-4 flex justify-center ml-auto mb-16 button-blue w-1/5"
        >
          Submit Uphold
        </button>
      </div>
    </div>
    <div
      class="w-2/3 bg-white mx-auto mt-36 z-10 rounded"
      v-if="!loading && road.name_status != 'Sent For Appeal'"
    >
      <div
        class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 justify-center flex flex-wrap"
      >
        <div>
          <div class="w-full justify-center flex mt-20">
            <img
              src="../../assets/Images/Icon_Gazettal.png"
              class="w-36 h-36"
            />
          </div>
          <p class="text-title mb-20 mt-8">
            Your access to this page is denied.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import proposalIcon from "../../components/proposals/road/proposalIcon";

import { submitUpholdJira } from "../../helpers/rnpCRUD";
export default {
  name: "uphold",
  components: {
    proposalIcon
  },
  async created() {
    await this.$store.dispatch("getAppeledRoadInfo", this.$route.params.id);
    this.loading = false;
  },
  computed: {
    road() {
      return this.$store.getters.appealedRoadInfo;
    }
  },
  methods: {
    async submitUphold() {
      try {
        if (this.road.name_status != "Sent For Appeal") {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Information",
            text: "Cannot submit decision for this proposal status."
          });
        } else if (!this.comments) {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Information",
            text: "Cannot submit appeal without decision comment."
          });
        } else {
          let payload = {
            road: this.road,
            comment: this.comments
          };
          await submitUpholdJira(payload);
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Decision Submitted"
          });
          this.$router.push({
            name: "afterSubmitUphold",
            params: { road: this.road }
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Submit Error",
          text: "Error"
        });
        throw new Error(error);
      }
    }
  },
  data() {
    return {
      comments: "",
      loading: true
    };
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
}
button {
  font-weight: 600;
}

.container {
  max-width: 1200px;
}
</style>
