var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-sm" },
    [
      _c("div", { staticClass: "flex flex-row mb-4" }, [
        _c("h3", { staticClass: "flex-grow self-center" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "button-blue px-2 py-2 mb-4",
            on: {
              click: function ($event) {
                _vm.isShowingFilter = !_vm.isShowingFilter
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "flex-auto mr-2 filter-icon",
              attrs: { icon: "filter" },
            }),
            _vm._v(
              _vm._s(
                _vm.isShowingFilter
                  ? `Hide filter options`
                  : `Show filter options`
              ) + " "
            ),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "button-blue px-2 py-2 mb-4 float-right",
            attrs: { disabled: !_vm.rows.length },
            on: { click: _vm.download },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "flex-auto mr-2 filter-icon",
              attrs: { icon: "cloud-download-alt" },
            }),
            _vm._v(" Download Results "),
          ],
          1
        ),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isShowingFilter
          ? _c("div", { staticClass: "mb-4 pt-2" }, [
              _c(
                "div",
                { staticClass: "mb-4 flex-grow" },
                [
                  _c("span", [_vm._v("Selected Columns")]),
                  _c("v-select", {
                    class: `bg-grey-input mr-4`,
                    attrs: {
                      multiple: "",
                      value: _vm.selectedColumns,
                      label: "label",
                      reduce: (value) => value.field,
                      options: _vm.filteredColumns,
                    },
                    on: {
                      input: (columns) => _vm.updateColumnSelection(columns),
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "flex flex-row pb-4 mr-4" }, [
                _vm.showDates
                  ? _c("div", [
                      _c("span", [_vm._v(" Start Date ")]),
                      _c(
                        "div",
                        { staticClass: "flex flex-row" },
                        [
                          _c("v-datepicker", {
                            attrs: { mode: "single" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "w-full mr-4 input-area date-picker",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Select date",
                                        disabled: _vm.isLoading,
                                      },
                                      domProps: { value: _vm.fromDate },
                                      on: {
                                        keydown: (e) => e.preventDefault(),
                                      },
                                      nativeOn: {
                                        change: function ($event) {
                                          return props.updateValue(
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  },
                                },
                              ],
                              null,
                              false,
                              2888706304
                            ),
                            model: {
                              value: _vm.fromDate,
                              callback: function ($$v) {
                                _vm.fromDate = $$v
                              },
                              expression: "fromDate",
                            },
                          }),
                          !!_vm.fromDate
                            ? _c(
                                "button",
                                {
                                  staticClass: "ml-2 px-4 button-date-picker",
                                  class: {
                                    redButtonFaded: _vm.isLoading,
                                    redButtonSolid: !_vm.isLoading,
                                  },
                                  attrs: { disabled: !_vm.fromDate },
                                  on: {
                                    click: function ($event) {
                                      _vm.fromDate = null
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "times" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.showDates
                  ? _c("div", { staticClass: "ml-4" }, [
                      _c("span", [_vm._v(" End Date ")]),
                      _c(
                        "div",
                        { staticClass: "flex flex-row" },
                        [
                          _c("v-datepicker", {
                            attrs: { mode: "single" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass:
                                        "w-full input-area date-picker",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Select date",
                                        disabled: _vm.isLoading,
                                      },
                                      domProps: { value: _vm.toDate },
                                      on: {
                                        keydown: (e) => e.preventDefault(),
                                      },
                                      nativeOn: {
                                        change: function ($event) {
                                          return props.updateValue(
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  },
                                },
                              ],
                              null,
                              false,
                              3438033739
                            ),
                            model: {
                              value: _vm.toDate,
                              callback: function ($$v) {
                                _vm.toDate = $$v
                              },
                              expression: "toDate",
                            },
                          }),
                          !!_vm.toDate
                            ? _c(
                                "button",
                                {
                                  staticClass: "ml-2 px-4 button-date-picker",
                                  class: {
                                    redButtonFaded: _vm.isLoading,
                                    redButtonSolid: !_vm.isLoading,
                                  },
                                  attrs: { disabled: !_vm.toDate },
                                  on: {
                                    click: function ($event) {
                                      _vm.toDate = null
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "times" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mb-4", class: { "ml-4": _vm.showDates } },
                  [
                    _c("span", [_vm._v("Results per Page")]),
                    _c("v-select", {
                      staticClass: "bg-grey-input",
                      attrs: {
                        value: _vm.paginationProps.limit,
                        label: "label",
                        options: _vm.pageResultCountList,
                        disabled: _vm.isLoading,
                      },
                      on: { input: _vm.updatePageLimit },
                    }),
                  ],
                  1
                ),
                _vm.showKeywordSearch
                  ? _c(
                      "div",
                      { staticClass: "mb-4 ml-4 flex flex-grow flex-col" },
                      [
                        _c("span", [_vm._v("Keyword")]),
                        _c("div", { staticClass: "flex flex-row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.keyword,
                                expression: "keyword",
                              },
                            ],
                            staticClass: "bg-grey-input flex-grow keyword pl-4",
                            attrs: {
                              type: "text",
                              placeholder: "Filter...",
                              name: "filter",
                              id: "filter",
                            },
                            domProps: { value: _vm.keyword },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.searchViaKeyword.apply(
                                  null,
                                  arguments
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.keyword = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "ml-2 px-4 button-blue keyword",
                              on: { click: _vm.searchViaKeyword },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "flex-auto -mt-3",
                                attrs: { icon: "search" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("hr", { staticClass: "divider w-full mb-4 mt-2 mr-4" }),
              _c(
                "div",
                { staticClass: "filter__container" },
                _vm._l(_vm.filteredFilters, function (filter, index) {
                  return _c("DataTableFilter", {
                    key: index,
                    attrs: {
                      label: filter.label,
                      type: filter.type,
                      name: filter.name,
                      value: _vm.filterState[filter.name],
                      options: _vm.filterLists[filter.options],
                      multiple: filter.multiple || false,
                      disabled: _vm.isLoading,
                    },
                    on: {
                      input: (value) =>
                        _vm.updateFilter({ value: value, name: filter.name }),
                      clear: function ($event) {
                        return _vm.updateFilter({
                          value: null,
                          name: filter.name,
                        })
                      },
                    },
                  })
                }),
                1
              ),
              _c(
                "button",
                {
                  staticClass: "button-blue-hollow px-2 py-2 mr-4",
                  on: { click: _vm.resetFilters },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2 filter-icon",
                    attrs: { icon: "times" },
                  }),
                  _vm._v("Reset Filters "),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "table",
        { staticClass: "table-auto text-black w-full" },
        [
          _c("Header", {
            attrs: {
              columns: _vm.filteredColumns,
              columnState: _vm.columnState,
              filterLists: _vm.filterLists,
              selectedColumns: _vm.selectedColumns,
            },
            on: { sortDesc: _vm.sortDesc, sortAsc: _vm.sortAsc },
          }),
          _vm.isLoading
            ? _c("tr", [
                _c(
                  "td",
                  { attrs: { colspan: "80" } },
                  [_vm.isLoading ? _c("Fetching") : _vm._e()],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isLoading && !!_vm.missingRequiredFilters
            ? _c("tr", { staticClass: "border border-gray-300" }, [
                _c("td", { attrs: { colspan: "8" } }, [
                  _c("div", { staticClass: "py-16 text-center" }, [
                    _c("p", { staticClass: "text-black text-md" }, [
                      _vm._v(
                        " Please select " +
                          _vm._s(_vm.missingRequiredFilters.label) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          !_vm.isLoading &&
          (!_vm.rows || !_vm.rows.length) &&
          !_vm.missingRequiredFilters
            ? _c("tr", { staticClass: "border border-gray-300" }, [
                _c("td", { attrs: { colspan: "80" } }, [
                  _c("div", { staticClass: "py-16 text-center" }, [
                    _c("p", { staticClass: "text-black text-xl" }, [
                      _vm._v("No " + _vm._s(_vm.recordType) + " found"),
                    ]),
                    _c("p", { staticClass: "text-gray text-sm" }, [
                      _vm._v(
                        " Please adjust the filter options and try again "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          !_vm.isLoading && !_vm.missingRequiredFilters
            ? _vm._l(_vm.rows, function (row, row_index) {
                return _c(
                  "tr",
                  {
                    key: row_index,
                    staticClass: "datatable_row border border-gray-300",
                    attrs: { href: row.url, target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.openProposalInNewTab(row.url)
                      },
                    },
                  },
                  _vm._l(_vm.filteredColumns, function (column, index) {
                    return _c("td", { key: index, staticClass: "p-2" }, [
                      column.type === "boolean" && _vm.isShowing(column.field)
                        ? _c("span", [
                            row[column.field] === true
                              ? _c("span", [_vm._v(" Yes ")])
                              : row[column.field] === false
                              ? _c("span", [_vm._v(" No ")])
                              : _c("span", [_vm._v(" Unknown ")]),
                          ])
                        : _vm._e(),
                      column.type === "status" && _vm.isShowing(column.field)
                        ? _c("span", [
                            _c("b", [_vm._v(_vm._s(row[column.field]))]),
                          ])
                        : _vm._e(),
                      column.type === "type" && _vm.isShowing(column.field)
                        ? _c("span", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.displayType(row[column.field]))
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      column.field === "affected_localities" &&
                      row.type === "PLACENAME" &&
                      _vm.isShowing(column.field)
                        ? _c("span", [_c("b", [_vm._v("N/A")])])
                        : _vm._e(),
                      column.type === "text" &&
                      !Array.isArray(row[column.field]) &&
                      _vm.isShowing(column.field)
                        ? _c("span", [
                            !!row[column.field]
                              ? _c("span", [_vm._v(_vm._s(row[column.field]))])
                              : _vm._e(),
                            !row[column.field]
                              ? _c("span", [
                                  _c("b", [_vm._v(_vm._s(column.ifNull))]),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      column.type === "text" &&
                      Array.isArray(row[column.field]) &&
                      _vm.isShowing(column.field)
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.constructString(row[column.field])) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      column.type === "date" && _vm.isShowing(column.field)
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.parseDate(row[column.field])) + " "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "section",
        {
          staticClass:
            "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3",
        },
        [
          !_vm.isLoading && parseInt(_vm.paginationProps.record_count)
            ? _c(
                "div",
                { staticClass: "mx-auto text-center self-center" },
                [
                  _c("paginate", {
                    attrs: {
                      "page-count": Math.ceil(
                        parseInt(_vm.paginationProps.record_count) /
                          _vm.paginationProps.limit
                      ),
                      "page-range": 10,
                      "margin-pages": 1,
                      "click-handler": _vm.changePage,
                      "prev-text": "<",
                      "next-text": ">",
                      "container-class": "pagination",
                      "page-class": "page-item",
                    },
                    model: {
                      value: _vm.paginationProps.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.paginationProps, "page", $$v)
                      },
                      expression: "paginationProps.page",
                    },
                  }),
                  _c("p", [
                    _vm._v(
                      " Showing " +
                        _vm._s(
                          (
                            (_vm.paginationProps.page - 1) *
                              _vm.paginationProps.limit +
                            1
                          ).toLocaleString()
                        ) +
                        " to " +
                        _vm._s(
                          Math.min(
                            (_vm.paginationProps.page - 1) *
                              _vm.paginationProps.limit +
                              _vm.paginationProps.limit,
                            parseInt(_vm.paginationProps.record_count)
                          ).toLocaleString()
                        ) +
                        " of " +
                        _vm._s(
                          parseInt(
                            _vm.paginationProps.record_count
                          ).toLocaleString()
                        ) +
                        " " +
                        _vm._s(_vm.recordType) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }