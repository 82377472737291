<template>
  <entry cardTitle="Clarification Request" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">The following clarification request was sent:</p>
      <strong>{{ entryData.request }}</strong>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "ClarificationRequestTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  }
};
</script>
