var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$wait.is("fetchingGeonames")
        ? _c("loader", { attrs: { text: "Searching..." } })
        : _vm._e(),
      _vm.results && !_vm.$wait.is(`fetchingGeonames`)
        ? _c(
            "div",
            {
              staticClass:
                "justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3",
            },
            [
              _c(
                "div",
                [
                  !_vm.$wait.is(`fetchingGeonames`) &&
                  _vm.results &&
                  _vm.results.length
                    ? _c("results-list", {
                        attrs: {
                          geoName: _vm.geoName,
                          results: _vm.results,
                          search: _vm.search,
                          resultCount: _vm.resultCount,
                        },
                        on: {
                          "confirming-link": _vm.hideBackButton,
                          "cancel-confirm": _vm.showBackButton,
                          "confirm-linkage": _vm.linkageConfirmed,
                        },
                      })
                    : _vm._e(),
                  _vm.resultCount === "0"
                    ? _c("p", { staticClass: "text-center" }, [
                        _vm._v(" No results to display "),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowingBackButton,
                          expression: "isShowingBackButton",
                        },
                      ],
                      staticClass: "flex justify-between mt-5 mb-10",
                    },
                    [
                      _c(
                        "ButtonWithSpinner",
                        {
                          staticClass:
                            "button-blue-hollow flex w-2/6 m-auto mt-4",
                          attrs: { isSpinning: false, disabled: false },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v("Back to search")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }