<template>
  <div class="flex input-area" v-bind:class="{ disabled: $attrs.disabled }">
    <input
      class="w-full flex-grow rounded-lg input-autocomplete"
      data-cypress="select-entity-input"
      list="rnaList"
      name="rnaList"
      type="text"
      v-on:input="selectRna"
      :value="value"
      :disabled="$attrs.disabled"
      :placeholder="placeholder"
    />
    <datalist id="rnaList">
      <option value="" default hidden>Select An Authority</option>
      <option
        v-for="rna in rnaList"
        :value="rna.authority_name"
        :key="rna.identifier"
        >{{ rna.authority_name }}</option
      >
    </datalist>
  </div>
</template>

<script>
import find from "lodash.find";

export default {
  name: "RNASelect",
  props: {
    rnaList: {
      type: Array
    },
    value: String,
    placeholder: String
  },
  methods: {
    selectRna($event) {
      const rna = find(
        this.rnaList,
        rna => $event.target.value === rna.authority_name
      );
      if (rna) {
        this.$emit("input", rna.identifier);
      } else if (!$event.target.value) {
        this.$emit("input", null);
      }
    }
  }
};
</script>

<style scoped>
.autocomplete {
  background-color: #fff;
  border-radius: 4px;
}

.input-autocomplete:focus {
  outline: none;
}

.input-autocomplete {
  background-color: rgba(0, 0, 0, 0);
}

.input-area.disabled {
  background-color: #d4d8da;
}

datalist {
  display: none;
}
/* specifically hide the arrow on focus */
.input-autocomplete::-webkit-calendar-picker-indicator {
  display: none;
}

option {
  /* font-weight: 900 !important; */
  display: block;
  line-height: 1.5em;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
</style>
