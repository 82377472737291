var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormContainer", [
    _c("div", { staticClass: "flex flex-col" }, [
      _c("h1", { staticClass: "pt-6 mb-10 text-center mt-4 text-title" }, [
        _vm._v("Reset Password"),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col" },
        [
          _c("p", { staticClass: "flex-auto pb-6 w-full text-normal" }, [
            _vm._v(
              " Forgotten your password? Enter the email associated with your NSW Place and Road Naming Proposal System user account to send a recovery link. "
            ),
          ]),
          _c("div", { staticClass: "flex-auto mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block text-normal-bold mb-2",
                attrs: { for: "emailAddress" },
              },
              [_vm._v("Email")]
            ),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emailAddress,
                  expression: "emailAddress",
                },
              ],
              staticClass: "w-full input-area",
              attrs: {
                "data-cypress": "email",
                id: "emailAddress",
                name: "email",
                type: "text",
                placeholder: "",
              },
              domProps: { value: _vm.emailAddress },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.emailAddress = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')",
                  },
                ],
                staticClass: "text-error",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("email")) + " ")]
            ),
          ]),
          _c(
            "ButtonWithSpinner",
            {
              staticClass: "button-blue mt-4 w-full",
              attrs: {
                disabled: _vm.errors.items.length || _vm.$wait.any,
                isSpinning: _vm.$wait.any,
              },
              on: { click: _vm.forgotPasswordAuth },
            },
            [_vm._v("Send recovery link")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "button",
      {
        staticClass:
          "w-full bg-white text-normal-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 text-blue-button bg-white mb-10",
        attrs: { type: "button" },
        on: { click: _vm.handleReturnToLogin },
      },
      [_vm._v(" Return to login page ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }