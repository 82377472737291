var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.legacyAdvertisements.length,
          expression: "legacyAdvertisements.length",
        },
      ],
      staticClass: "w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 mb-12",
    },
    [
      _c("showLoading", {
        attrs: { isLoading: "fetchingLegacyAdverts" },
        scopedSlots: _vm._u([
          {
            key: "component",
            fn: function () {
              return [
                _c("div", { staticClass: "w-5/6 mx-auto" }, [
                  _c(
                    "div",
                    { staticClass: "py-6 mb-6 border-b-2 self-center" },
                    [
                      _c("h3", { staticClass: "font-bold" }, [
                        _vm._v("Legacy Advertisements"),
                      ]),
                    ]
                  ),
                ]),
                _vm._l(_vm.legacyAdvertisements, function (legacyAd, index) {
                  return _c(
                    "div",
                    {
                      key: legacyAd.identifier,
                      staticClass: "w-5/6 mx-auto mb-4",
                      class: [
                        index === _vm.legacyAdvertisements.length - 1
                          ? ""
                          : "dividing-line-h",
                      ],
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "text-black font-bold flex-grow" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  legacyAd.ad_title || legacyAd.identifier
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                legacyAd.collapsed = !legacyAd.collapsed
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "collapse",
                                refInFor: true,
                                staticClass:
                                  "rotate-transition text-blue-darker",
                                class: [
                                  legacyAd.collapsed ? "" : "rotate-chevron",
                                ],
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "chevron-circle-down" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "transition",
                        {
                          attrs: { name: "collapse" },
                          on: {
                            enter: function ($event) {
                              return _vm.beforeEnterCollapse(index)
                            },
                            leave: function ($event) {
                              return _vm.beforeLeaveCollapse(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !legacyAd.collapsed,
                                  expression: "!legacyAd.collapsed",
                                },
                              ],
                              ref: "collapse-body",
                              refInFor: true,
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex flex-row border-gray-200 rounded items-end",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-black flex-grow flex-row",
                                    },
                                    [
                                      _c("div", { staticClass: "flex" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-grow pb-4 pt-4 w-1/2",
                                          },
                                          [
                                            _c("b", [
                                              _vm._v("Publication Date"),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    legacyAd.date_publication
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-grow pb-4 pt-4 w-1/2",
                                          },
                                          [
                                            _c("b", [_vm._v("Closing Date")]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatDate(
                                                    legacyAd.date_closing
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "flex py-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "flex-grow w-1/2" },
                                          [
                                            _c("b", [
                                              _vm._v("Government Advert Id"),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(legacyAd.govt_ad_id)
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "flex-grow w-1/2" },
                                          [
                                            _c("b", [_vm._v("GNB Files")]),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(legacyAd.gnb_files)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1/3 ml-4 dividing-line-v stretch justify-end text-right",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-label font-bold pb-2 pt-4",
                                        },
                                        [_vm._v("Actions")]
                                      ),
                                      legacyAd.filename_gazette
                                        ? _c(
                                            "div",
                                            { staticClass: "text-blue-button" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    href: _vm.relativeFileUrl(
                                                      legacyAd.filename_gazette
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Download Gazette")]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "text-red font-bold",
                                            },
                                            [_vm._v(" No Gazette ")]
                                          ),
                                      legacyAd.filename_map
                                        ? _c(
                                            "div",
                                            { staticClass: "text-blue-button" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    href: _vm.relativeFileUrl(
                                                      legacyAd.filename_map
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Download Map")]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "text-red font-bold",
                                            },
                                            [_vm._v(" No Map ")]
                                          ),
                                      legacyAd.has_legacy_commentary
                                        ? _c(
                                            "div",
                                            {
                                              ref: "download-comment-button",
                                              refInFor: true,
                                              staticClass:
                                                "text-blue-button cursor-pointer",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  class: {
                                                    "cursor-wait":
                                                      legacyAd.loadingComments ===
                                                      true,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      legacyAd.loadingComments ===
                                                      true,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadComments(
                                                        legacyAd.govt_ad_id,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Download Comments")]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "text-red font-bold",
                                            },
                                            [_vm._v(" No Comments ")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }