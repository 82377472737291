<template>
  <div
    v-bind:class="{
      'input-field-disabled': disabled,
      'input-field': !disabled,
      'py-6': displayableDates.length === 0 && disabled
    }"
    class="py-4 px-4 rounded"
  >
    <span
      class="tags-input-badge tags-input-badge-pill"
      :class="{ 'tags-input-badge-disabled': !date.isEditable }"
      v-for="(date, index) in displayableDates"
      :key="index"
      :data-toggle="!!date.isEditable ? 'tooltip' : false"
      title="This proposal is currently 'PENDING GAZETTAL'. Changes to attached gazettal dates are locked"
    >
      <span class="tag-text">{{ formatDate(date.date_gazetted) }}</span>
      <i
        v-if="date.isEditable"
        class="multiselect__tag-icon"
        @click.prevent="removeTag(date)"
      ></i>
    </span>

    <datepicker
      v-if="!disabled"
      class="w-full rounded-lg bg-grey-input"
      @selected="addTag($event)"
      name="dateInput"
      :placeholder="datePlaceholderText"
      :format="datePlaceholder"
      v-validate="{ required: true, date_format: 'yyyy-MM-dd' }"
      input-class="w-full bg-grey-input text-grey-text"
    >
    </datepicker>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "TaggableDateSelector",
  /**
   *
   */
  $_veeValidate: {
    value() {
      return this.tagList;
    },
    name() {
      return this.label;
    }
  },

  /**
   * instance child components
   */
  components: { Datepicker },

  /**
   * instance props
   */
  props: {
    tags: {
      type: Array
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean
    }
  },

  /**
   * instance computed properties
   */
  computed: {
    /**
     * from the provided list of dates which ones should
     * be shown
     */
    displayableDates() {
      return this.tags
        .filter(tag => !Object.keys(tag).includes("isRemoving"))
        .sort((a, b) => new Date(a.date_gazetted) - new Date(b.date_gazetted));
    }
  },

  /**
   * local instance data
   */
  data() {
    return {
      datePlaceholderText: "Select date..."
    };
  },

  /**
   * instance methods
   */
  methods: {
    datePlaceholder() {
      return this.datePlaceholderText;
    },

    /**
     * format the date to a standard
     */
    formatDate: date => {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * add a new tag to the list
     */
    addTag(e) {
      const selectedDate = moment(e).format("YYYY-MM-DDTHH:mm:ss");

      const exists = this.tags.find(
        tag =>
          tag.date_gazetted.substring(0, 10) === selectedDate.substring(0, 10)
      );

      if (!exists) {
        this.$emit("addTag", {
          date_gazetted: selectedDate,
          isEditable: true
        });
      }
    },

    /**
     * remove a tag from the list
     */
    removeTag(tag) {
      this.$emit("removeTag", tag);
    }
  }
};
</script>
<style scoped>
.tags-input-badge-pill {
  position: relative !important;
  display: inline-block !important;
  padding: 8px 34px 8px !important;
  border-radius: 26px !important;
  margin-right: 10px !important;
  color: rgb(255, 255, 255) !important;
  line-height: 1 !important;
  background: #002664 !important;
  margin-bottom: 3px !important;
  margin-top: 3px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 100% !important;
  text-overflow: ellipsis !important;
}

.tags-input-badge-disabled {
  background: #c5c5c5 !important;
}

.tag-text {
  color: white;
}

.input-field {
  background-color: #ecf1f3;
  width: 100%;
  outline: none !important;

  -webkit-appearance: none;
}

.input-field-disabled {
  background-color: #d4d8da;
  width: 100%;
  outline: none !important;
  color: #35495e !important;
  -webkit-appearance: none;
}

input:focus {
  outline: none !important;

  -webkit-appearance: none;
}
</style>
