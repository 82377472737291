<template>
  <div>
    <label class="w-2/3">
      <p class="text-normal-bold mb-2">
        <span class="text-red-star">*</span>Proposed Address Locality Name
      </p>
      <input
        type="text"
        class="w-full mr-4 input-area"
        data-cypress="geographical_name-field"
        placeholder="Enter Name Here..."
        name="geographical_name"
        id="geographical_name"
        v-validate.continues="'required'"
        v-model="geographical_name"
        data-vv-as="Proposed Address Locality Name"
        :disabled="readOnly"
      />
      <FieldErrorMessage :message="errors.first('geographical_name')" />
      <showLoading isLoading="evaluatingPlaceName">
        <template v-slot:component>
          <div
            v-if="
              !!geographical_name &&
                !!geographical_name.length &&
                evaluation &&
                !!evaluation.length
            "
          >
            <MessagePanel
              v-for="(message, key) in evaluation"
              :key="key"
              warning
              :msg="message.shortDescription"
            />
          </div>
        </template>
      </showLoading>
    </label>
    <label class="w-2/3">
      <p class="text-normal-bold mt-4 mb-2">
        <span class="text-red-star">*</span>Local Government Area(s)
      </p>
    </label>
    <norns-select
      :disabled="!lgas || readOnly"
      class="w-full mr-4 norns-select"
      v-model="lga_name"
      :multiple="true"
      :options="lgasClean"
      :searchable="true"
      :close-on-select="false"
      :select-on-tab="true"
      :show-labels="true"
      :hide-selected="true"
      placeholder="Select an LGA(s)"
      name="lga"
      data-cypress="lga-field"
      v-validate.continues="'required'"
    ></norns-select>
    <FieldErrorMessage :message="errors.first('lga')" />
    <!-- affected localities -->
    <label class="w-2/3 mr-4">
      <p class="text-normal-bold mt-4 mb-2">
        <span class="text-red-star">*</span>Affected Address Localities/Suburbs
      </p>
    </label>
    <norns-select
      :disabled="readOnly && !isEditing  || !lga_name.length"
      class="w-full norns-select"
      v-model="affected_localities"
      :multiple="true"
      :options="affectedAddressLocalities"
      :searchable="true"
      :close-on-select="false"
      :select-on-tab="true"
      :show-labels="true"
      :hide-selected="true"
      placeholder="Enter an Affected Locality"
      name="affected_localities"
      data-cypress="affected-address-localities"
      v-validate.continues="'required'"
      data-vv-as="Affected Address Localities/Suburbs"
    ></norns-select>
    <FieldErrorMessage :message="errors.first('affected_localities')" />
    <!-- electorate -->
    <label class="w-2/3">
      <p class="text-normal-bold mb-2">
        State Electorate
      </p>
      <taggableInput
        class="w-full mr-4"
        :datacypress="`state-electorate`"
        :tagList="electorate"
        :placeholder="`Start typing and press enter to add an Electorate...`"
        :disabled="readOnly"
        v-on:addTag="
          electorate =>
            addElectorate({ key: 'address_locality', electorate: electorate })
        "
        v-on:removeTag="
          index => removeElectorate({ key: 'address_locality', index: index })
        "
      />
    </label>
    <div>
      <uploadScoped
        class="mt-8"
        data-cypress="upload-map"
        label="Attach Marked Map"
        :important="true"
        :files="marked_map_reference_document"
        :drafted="true"
        stateKey="address_locality"
        stateProp="marked_map_reference_document"
        :uploadFile="uploadFile"
        v-on:deleteFile="
          fileIndex =>
            deleteUploadedFile({
              key: 'address_locality',
              prop: 'marked_map_reference_document',
              index: fileIndex
            })
        "
        v-validate="'min_value:1'"
        :disabled="readOnly"
      />
      <div
        class="flex items-center absolute"
        v-if="errors.first('fileUploader')"
      >
        <errorIcon />
        <div class="ml-2 text-red-error text-normal-bold">
          No Marked Map was uploaded for this locality. Please upload required
          files
        </div>
      </div>
    </div>
    <!-- Reason for New/Amended Locality Address Name -->
    <div>
      <p class="text-normal-bold mb-2 mt-6">
        <span class="text-red-star">*</span>Reason for New/Amended Locality
        Address Name
      </p>
      <RadioButton
        v-for="reason in reasonForNewAmendedLocalityAddressList"
        :key="reason"
        class="flex-0 mt-6 mb-1"
        name="reasoning_for_new_address_locality"
        v-model="reasoning_for_new_address_locality"
        data-cypress="reasoning_for_new_address_locality-proposal"
        :checkedValue="reason"
        :disabled="readOnly"
        data-vv-as="Reason for New/Amended Locality Address Name"
        v-validate="'required:true'"
        >{{ reason }}</RadioButton
      >
      <FieldErrorMessage
        :message="errors.first('reasoning_for_new_address_locality')"
      />
      <label
        class="w-2/3 mt-6"
        v-if="reasoning_for_new_address_locality === `Other`"
      >
        <p class="text-normal-bold mb-2 mt-6 pl-8">
          <span class="text-red-star">*</span>Please provide details
        </p>
        <div class="w-full mt-3 pl-8 flex">
          <input
            class="w-full input-area"
            data-cypress="reasoning_for_new_address_locality-address-field"
            v-model="reasoning_for_new_address_locality_other"
            :disabled="readOnly"
          />
        </div>
      </label>
      <label class="w-2/3">
        <p class="text-normal-bold mb-2 mt-6">
          Any other relevant information regarding this reasoning?
        </p>
        <div class="w-full mt-3 flex">
          <textarea
            class="w-full h-48 input-area"
            data-cypress="additional_reasoning-field"
            v-model="additional_reasoning"
            :disabled="readOnly"
          ></textarea>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

import _debounce from "lodash.debounce";
import taggableInput from "@/components/proposals/place/taggableInput.vue";
import lgaListMixin from "@/mixins/lga-list-filter";
import MessagePanel from "@/components/proposals/place/messagePanel";

import { getSuburbs } from "@/helpers/servicesq.js";

export default {
  inject: ["$validator"],
  mixins: [lgaListMixin],
  components: {
    taggableInput,
    MessagePanel
  },
  props: {
    readOnly: {
      type: Boolean
    }
  },
  data() {
    return {
      model: {},
      reasonForNewAmendedLocalityAddressList: [
        "Represent new community",
        "Area of proposed urban growth",
        "Unique character compared to surrounding areas",
        "Change of land use",
        "Difficulties with the efficient delivery of services",
        "Problems assigning addresses",
        "Further land development and construction of roads",
        "Duplication of address locality name",
        "Other"
      ],
      affectedAddressLocalities: []
    };
  },
  created() {
    if (this.lgasClean.includes(this.defaultOrganisationLga)) {
      this.lga_name = [this.defaultOrganisationLga];
    }
  },
  computed: {
    isEditing() {
      return Boolean(this.$route.query.edit);
    },
    geographical_name: {
      get() {
        return this.get_address_locality_geographical_name();
      },
      set(value) {
        this.set_address_locality_geographical_name(value);
        if (value && value.length) {
          this.evaluate();
        }
      }
    },
    lga_name: {
      get() {
        return this.get_address_locality_lga();
      },
      set(value) {
        if (value || value === false) this.set_address_locality_lga(value);
        if (this.geographical_name && this.geographical_name.length) {
          this.evaluate();
        }
      }
    },
    affected_localities: {
      get() {
        return this.get_address_locality_affected_localities();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_affected_localities(value);
      }
    },
    reasoning_for_new_address_locality: {
      get() {
        return this.get_address_locality_reasoning_for_new_address_locality();
      },
      set(value) {
        this.set_address_locality_reasoning_for_new_address_locality(value);
      }
    },
    reasoning_for_new_address_locality_other: {
      get() {
        return this.get_address_locality_reasoning_for_new_address_locality_other();
      },
      set(value) {
        this.set_address_locality_reasoning_for_new_address_locality_other(
          value
        );
      }
    },
    additional_reasoning: {
      get() {
        return this.get_address_locality_additional_reasoning();
      },
      set(value) {
        this.set_address_locality_additional_reasoning(value);
      }
    },
    electorate: {
      get() {
        return this.get_address_locality_electorate();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_electorate(value);
      }
    },
    evaluation: {
      get() {
        return this.getEvaluation();
      }
    },
    marked_map_reference_document: {
      get() {
        return this.get_address_locality_marked_map_reference_document();
      },
      set(value) {
        if (value || value === false)
          this.set_address_locality_marked_map_reference_document(value);
      }
    },
    ...mapGetters(["defaultOrganisationLga"])
  },
  watch: {
    lga_name: {
      async handler(newVal) {
        if (newVal) {
          const newValsSorted = Object.assign([], newVal).sort();
          const newSubsReq = newValsSorted.map(sub =>
            this.getSuburbsForLGA(sub)
          );
          let newSubs2d = await Promise.all(newSubsReq);
          newSubs2d = newSubs2d.map((lgaSubs, index) =>
            lgaSubs.map(sub => `${sub} (${newValsSorted[index]})`)
          );
          this.affectedAddressLocalities = newSubs2d.flat();

          this.affected_localities = this.affected_localities.filter(al =>
            this.affectedAddressLocalities.includes(al)
          );
        } else {
          this.affectedAddressLocalities = [];
          this.affected_localities = [];
        }
      },
      immediate: false
    }
  },
  methods: {
    evaluate: _debounce(async function() {
      this.$wait.start("evaluatingPlaceName");
      try {
        await this.evaluatePlaceName({
          placeName: this.geographical_name,
          lgaNames: this.lga_name
        });
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Retrieving Evaluation",
          text: error
        });
      }
      this.$wait.end("evaluatingPlaceName");
    }, 500),
    async getSuburbsForLGA(lgaName) {
      const suburbs = await getSuburbs(lgaName);
      return suburbs.filter(sub => sub !== null && sub !== "");
    },
    ...mapPnpActions(["uploadFile", "evaluatePlaceName"]),
    ...mapPnpGetters([
      "get_address_locality_geographical_name",
      "get_address_locality_lga",
      "get_address_locality_affected_localities",
      "get_address_locality_reasoning_for_new_address_locality",
      "get_address_locality_reasoning_for_new_address_locality_other",
      "get_address_locality_additional_reasoning",
      "get_address_locality_electorate",
      "get_address_locality_marked_map_reference_document",
      "getEvaluation"
    ]),
    ...mapPnpMutations([
      "set_address_locality_affected_localities",
      "addElectorate",
      "removeElectorate",
      "deleteUploadedFile",
      "set_address_locality_geographical_name",
      "set_address_locality_lga",
      "set_address_locality_reasoning_for_new_address_locality",
      "set_address_locality_reasoning_for_new_address_locality_other",
      "set_address_locality_additional_reasoning",
      "set_address_locality_marked_map_reference_document",
      "set_address_locality_electorate"
    ])
  }
};
</script>
