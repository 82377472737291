<template>
  <select
    class="w-full rounded-lg p-4 text-normal mb-16"
    v-bind:value="rna"
    v-on:change="$emit('change', $event.target.value)"
  >
    <option v-if="!rnaList.length"></option>
    <option
      v-for="item in rnaList"
      :value="item.identifier"
      :key="item.identifier"
    >
      {{ item.authority_name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "rnaSelect",
  props: {
    rnaList: {
      type: Array
    },
    rna: {
      type: String
    }
  },
  model: {
    prop: "rna",
    event: "change"
  },
  event: "change"
};
</script>

<style></style>
