<template>
  <div class="base-container">
    <showLoading isLoading="loading Proposal">
      <template v-slot:component>
        <section class="pt-1 justify-center w-full block">
          <a
            @click="$router.go(-1)"
            class="flex justify-between pt-4 pb-4 -mr-4 self-center"
            v-if="!unique && !token"
          >
            <BackArrowWithTitle title="Proposal Clarification" />
          </a>

          <div
            class="w-full flex bg-white text-black rounded-lg pt-3 pb-3 mt-2 font-semibold"
          >
            <div class="w-9/10 flex mx-auto content-center pt-4">
              <div>
                <h2>{{ geographical_name }}</h2>
                <h4 class="text-blue">Local Government Area(s)</h4>
                <div v-for="lga in lga_name" v-bind:key="lga.index">
                  <span class="flex">
                    {{ lga }}
                  </span>
                </div>
              </div>
              <StatusButton
                :label="display_status"
                :status="display_status"
                class="ml-auto mr-6 font-semibold text-sm"
              />
            </div>
          </div>

          <div
            class="w-full block bg-white text-black rounded-lg mt-4 flex flex-col justify-start"
          >
            <div class="w-9/10 mx-auto">
              <span class="self-center pt-10 w-full block">
                <h2 class="text-title text-black">Clarification Request:</h2>

                <div class="flex justify-center">
                  <span class="dividing-line-h w-full mt-4"></span>
                </div>

                <p
                  class="self-center textRejectionReason text-normal text-black mt-5"
                  v-if="clarification"
                >
                  {{ clarification.request }}
                </p>
                <p class="text-normal-bold content-start mt-5">
                  Clarification Documents
                </p>
                <div class="mb-10">
                  <viewFile
                    v-if="clarification"
                    data-cypress="clarification-files"
                    :files="clarification.files"
                  />
                </div>
              </span>
            </div>
          </div>
          <div class="w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4">
            <div class="w-9/10 mx-auto">
              <p class="text-normal-bold content-start mb-2 mt-10">
                Your Response
              </p>

              <textarea
                class="text-normal text-black w-full overflow-y-auto h-64 mb-4 text-left items-start rounded p-5 text-black bg-grey-input"
                v-model="clarificationResponse.clarification_response"
                placeholder="Enter your clarification reponse here..."
              ></textarea>

              <uploadComponent
                label="Attach supporting documentation"
                class="mb-10"
                v-on:uploadfinished="uploading = false"
                v-on:uploadstarted="uploading = true"
                :disabled="uploading"
                :drafted="true"
                :deleteFile="deleteFunction"
              />
            </div>
          </div>
          <div class="flex pt-4 text-center pb-10 justify-end">
            <ButtonWithSpinner
              class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
              @click="submitClarification"
              :isSpinning="$wait.is('submitting clarification')"
              :disabled="$wait.is('submitting clarification') || isDisabled"
              >Submit Clarification</ButtonWithSpinner
            >
          </div>
        </section>
      </template>
    </showLoading>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import { waitFor } from "vue-wait";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

import {
  clarificationResponse,
  clarificationResponsePublic
} from "@/helpers/pnpCRUD";

import { deleteSupportingDocument } from "@/helpers/s3";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY
} from "@/components/proposals/place/pnp-constants.js";

export default {
  async created() {
    await this.fetchProposal();
  },
  data() {
    return {
      uploading: false,
      clarificationResponse: {
        clarification_response: ""
      },
      deleteFunction: deleteSupportingDocument
    };
  },
  computed: {
    ...mapGetters({
      uploadedFiles: "uploadedFilesState"
    }),
    proposalId() {
      return this.$route.params.proposalId;
    },
    type: {
      get() {
        return this.getProposalType();
      }
    },
    geographical_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE)
          return this.get_place_name_geographical_name();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_geographical_name();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_geographical_name();
        else return null;
      }
    },
    lga_name: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE) return this.get_place_name_lga();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_lga();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_lga();
        else return null;
      }
    },
    display_status: {
      get() {
        return this.getDisplayStatus();
      }
    },
    jira_id: {
      get() {
        if (this.type === PROPOSAL_TYPE_PLACE)
          return this.get_place_name_jira_id();
        else if (this.type === PROPOSAL_TYPE_LOCALITY)
          return this.get_address_locality_jira_id();
        else if (this.type === PROPOSAL_TYPE_BOUNDARY)
          return this.get_boundary_amendment_jira_id();
        else return null;
      }
    },
    clarification: {
      get() {
        return this.get_clarification();
      }
    },
    isDisabled() {
      return !this.clarificationResponse.clarification_response;
    },
    isPublic() {
      return this.$route.meta && this.$route.meta.isPublic;
    },
    token() {
      return this.$route.query ? this.$route.query.token : null;
    },
    unique() {
      return this.$route.query ? this.$route.query.unique : null;
    }
  },
  methods: {
    ...mapPnpGetters([
      "get_place_name_geographical_name",
      "get_place_name_lga",
      "get_place_name_jira_id",
      "get_address_locality_geographical_name",
      "get_address_locality_lga",
      "get_address_locality_jira_id",
      "get_boundary_amendment_geographical_name",
      "get_boundary_amendment_lga",
      "get_boundary_amendment_jira_id",
      "get_clarification",
      "getDisplayStatus",
      "getProposalType"
    ]),
    ...mapPnpActions([
      "selectProposal",
      "selectProposalPublic",
      "fetchProposalsList",
      "getClarification",
      "getClarificationPublic"
    ]),
    fetchProposal: waitFor("loading Proposal", async function() {
      try {
        if (!!this.token && !!this.unique) {
          await this.selectProposalPublic({
            proposalId: this.proposalId,
            unique: this.unique,
            token: this.token
          });
          await this.getClarificationPublic({
            proposalId: this.proposalId,
            unique: this.unique,
            token: this.token
          });

          if (
            !!this.display_status &&
            this.display_status !== "Clarification"
          ) {
            this.$router.push({
              name: "clarifyPnpSubmitPublic",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: true
              }
            });
          }
        } else {
          await this.selectProposal(this.proposalId);
          await this.getClarification(this.proposalId);

          if (
            !!this.display_status &&
            this.display_status !== "Clarification"
          ) {
            this.$router.push({
              name: "clarifyPnpSubmit",
              params: {
                proposalName: this.geographical_name,
                invalidStatus: true
              }
            });
          }
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Retrieval has Failed",
          text: `The retrieval of this Place Name Proposal has failed. ${error}`
        });
      }
    }),
    submitClarification: waitFor("submitting clarification", async function() {
      try {
        this.clarificationResponse.proposal_id = this.proposalId;
        this.clarificationResponse.files = this.uploadedFiles;
        this.clarificationResponse.id = this.clarification.id;

        if (!!this.token && !!this.unique) {
          await clarificationResponsePublic(
            this.clarificationResponse,
            this.unique,
            this.token
          );
          this.$router.push({
            name: "clarifyPnpSubmitPublic",
            params: { proposalName: this.geographical_name }
          });
        } else {
          await clarificationResponse(this.clarificationResponse);
          this.$router.push({
            name: "clarifyPnpSubmit",
            params: { proposalName: this.geographical_name }
          });
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Sending Clarification",
          text: "Error"
        });
      }
    })
  }
};
</script>

<style>
.dividing-line-h {
  border-bottom-width: 1px;
}

textarea::placeholder {
  color: #646974;
  opacity: 1;
}
</style>
