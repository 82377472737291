var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          attrs: { filter: _vm.filter },
          scopedSlots: _vm._u([
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _vm.$attrs.label
                    ? _c("span", [_vm._v(_vm._s(option[_vm.$attrs.label]))])
                    : _c("span", [_vm._v(_vm._s(option.label))]),
                ]
              },
            },
          ]),
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }