var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "w-full hover:mb-4" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-row text-normal" },
        [
          _c(
            "RadioButton",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-0 mr-6 mt-3",
              attrs: {
                "data-cypress": "commemorated-no",
                name: "commemorated checkbox",
                checkedValue: false,
                disabled: _vm.readOnly,
              },
              model: {
                value: _vm.commemorated,
                callback: function ($$v) {
                  _vm.commemorated = $$v
                },
                expression: "commemorated",
              },
            },
            [_vm._v("No")]
          ),
          _c(
            "RadioButton",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "flex-0 mr-6 mt-3",
              attrs: {
                name: "commemorated checkbox",
                "data-cypress": "commemorated-yes",
                checkedValue: true,
                disabled: _vm.readOnly,
              },
              model: {
                value: _vm.commemorated,
                callback: function ($$v) {
                  _vm.commemorated = $$v
                },
                expression: "commemorated",
              },
            },
            [_vm._v("Yes")]
          ),
        ],
        1
      ),
    ]),
    _vm.isCommemorated
      ? _c(
          "section",
          [
            _c(
              "label",
              { staticClass: "w-2/3" },
              [
                _vm._m(1),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  staticClass: "w-full mr-4 input-area",
                  attrs: {
                    type: "text",
                    "data-cypress": "commemoratedFullName-field",
                    placeholder: "Enter Name Here...",
                    name: "commemorated_full_name",
                    id: "commemoratedFullName",
                    "data-vv-as": "Full Name of Person",
                    disabled: _vm.readOnly,
                  },
                  domProps: { value: _vm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.name = $event.target.value
                    },
                  },
                }),
                _c("FieldErrorMessage", {
                  attrs: {
                    message: _vm.errors.first("commemorated_full_name"),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-normal mb-4 mt-4" },
              [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "flex flex-row mt-2" },
                      [
                        _c("v-datepicker", {
                          attrs: {
                            mode: "single",
                            "max-date": _vm.dod || _vm.todaysDate,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ inputProps, updateValue }) {
                                  return _c(
                                    "input",
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                !_vm.commemoration_date_unknown,
                                              date: !_vm.commemoration_date_unknown,
                                            },
                                            expression:
                                              "{\n                required: !commemoration_date_unknown,\n                date: !commemoration_date_unknown\n              }",
                                          },
                                        ],
                                        staticClass: "w-full mr-4 input-area",
                                        attrs: {
                                          disabled: _vm.readOnly,
                                          autocomplete: "off",
                                          placeholder:
                                            "Enter Date (DD/MM/YYYY)",
                                          "data-cypress":
                                            "commemorated_birth_date",
                                          name: "commemorated_birth_date",
                                          "data-vv-as": "Date of Birth",
                                        },
                                        domProps: { value: _vm.dob },
                                        on: {
                                          input: (e) =>
                                            _vm.updateDate(e, updateValue),
                                          change: _vm.setDobNullIfBlank,
                                        },
                                      },
                                      "input",
                                      inputProps,
                                      false
                                    )
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            771605913
                          ),
                          model: {
                            value: _vm.dob,
                            callback: function ($$v) {
                              _vm.dob = $$v
                            },
                            expression: "dob",
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "ml-2 w-1/8",
                            class: {
                              redButtonFaded: !_vm.dob,
                              redButtonSolid: _vm.dob,
                            },
                            attrs: { disabled: !_vm.dob },
                            on: {
                              click: function ($event) {
                                _vm.dob = null
                              },
                            },
                          },
                          [_vm._v(" clear ")]
                        ),
                      ],
                      1
                    ),
                    _c("FieldErrorMessage", {
                      attrs: {
                        message: _vm.errors.first("commemorated_birth_date"),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "flex flex-row mt-2" },
                      [
                        _c("v-datepicker", {
                          attrs: {
                            mode: "single",
                            "max-date": _vm.todaysDate,
                            "min-date": _vm.dob,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ inputProps, updateValue }) {
                                  return _c(
                                    "input",
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                !_vm.commemoration_date_unknown,
                                              date: !_vm.commemoration_date_unknown,
                                            },
                                            expression:
                                              "{\n                required: !commemoration_date_unknown,\n                date: !commemoration_date_unknown\n              }",
                                          },
                                        ],
                                        staticClass: "w-full mr-4 input-area",
                                        attrs: {
                                          disabled: _vm.readOnly,
                                          autocomplete: "off",
                                          placeholder:
                                            "Enter Date (DD/MM/YYYY)",
                                          "data-cypress":
                                            "commemorated_death_date",
                                          name: "commemorated_death_date",
                                          "data-vv-as": "Date of Death",
                                        },
                                        domProps: { value: _vm.dod },
                                        on: {
                                          input: (e) =>
                                            _vm.updateDate(e, updateValue),
                                          change: _vm.setDodNullIfBlank,
                                        },
                                      },
                                      "input",
                                      inputProps,
                                      false
                                    )
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2001876928
                          ),
                          model: {
                            value: _vm.dod,
                            callback: function ($$v) {
                              _vm.dod = $$v
                            },
                            expression: "dod",
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "ml-2 w-1/8",
                            class: {
                              redButtonFaded: !_vm.dod,
                              redButtonSolid: _vm.dod,
                            },
                            attrs: { disabled: !_vm.dod },
                            on: {
                              click: function ($event) {
                                _vm.dod = null
                              },
                            },
                          },
                          [_vm._v(" clear ")]
                        ),
                      ],
                      1
                    ),
                    _c("FieldErrorMessage", {
                      attrs: {
                        message: _vm.errors.first("commemorated_death_date"),
                      },
                    }),
                  ],
                  1
                ),
                _c("Checkbox", {
                  staticClass: "pt-8 pl-0",
                  attrs: {
                    "data-cypress": "commemorated_dates_unknown-field",
                    label: "The Date of Birth or Death is unknown",
                    disabled: (_vm.dob && _vm.dod) || _vm.readOnly,
                  },
                  model: {
                    value: _vm.commemoration_date_unknown,
                    callback: function ($$v) {
                      _vm.commemoration_date_unknown = $$v
                    },
                    expression: "commemoration_date_unknown",
                  },
                }),
              ],
              1
            ),
            _vm.commemoration_date_unknown
              ? _c(
                  "label",
                  { staticClass: "w-2/3 mb-4" },
                  [
                    _vm._m(4),
                    _c("div", { staticClass: "w-full mt-3 flex" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.commemoration_date_unknown_reason,
                            expression: "commemoration_date_unknown_reason",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: _vm.commemoration_date_unknown },
                            expression:
                              "{ required: commemoration_date_unknown }",
                          },
                        ],
                        staticClass: "w-full h-48 input-area",
                        attrs: {
                          "data-cypress":
                            "commemorated_dates_unknown_description-field",
                          name: "commemoration_date_unknown_description",
                          disabled: _vm.readOnly,
                          "data-vv-as": "unknown commemoration date",
                        },
                        domProps: {
                          value: _vm.commemoration_date_unknown_reason,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.commemoration_date_unknown_reason =
                              $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("FieldErrorMessage", {
                      attrs: {
                        message: _vm.errors.first(
                          "commemoration_date_unknown_description"
                        ),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "label",
              { staticClass: "w-2/3" },
              [
                _vm._m(5),
                _c("div", { staticClass: "w-full mt-3 flex" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.commemoration_association,
                        expression: "commemoration_association",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "w-full h-48 input-area",
                    attrs: {
                      "data-cypress": "commemoration_association-field",
                      name: "commemorated_association_description",
                      "data-vv-as": "Association",
                      disabled: _vm.readOnly,
                    },
                    domProps: { value: _vm.commemoration_association },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.commemoration_association = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("FieldErrorMessage", {
                  attrs: {
                    message: _vm.errors.first(
                      "commemorated_association_description"
                    ),
                  },
                }),
              ],
              1
            ),
            _c("label", { staticClass: "w-2/3" }, [
              _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
                _vm._v(" What is the source for this information? "),
              ]),
              _c("div", { staticClass: "w-full mt-3 flex" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.commemoration_source,
                      expression: "commemoration_source",
                    },
                  ],
                  staticClass: "w-full h-48 input-area",
                  attrs: {
                    "data-cypress": "commemoration_source-field",
                    disabled: _vm.readOnly,
                  },
                  domProps: { value: _vm.commemoration_source },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.commemoration_source = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("uploadScoped", {
              staticClass: "mt-8",
              attrs: {
                label: "Attach supporting documentation",
                important: false,
                files: _vm.commemorated_supporting,
                drafted: true,
                stateKey: "road_name",
                stateProp: "commemorated_supporting",
                uploadFile: _vm.uploadFile,
                disabled: _vm.readOnly,
              },
              on: {
                deleteFile: (fileIndex) =>
                  _vm.deleteUploadedFile({
                    key: "road_name",
                    prop: "commemorated_supporting",
                    index: fileIndex,
                  }),
              },
            }),
            _vm.errors.first("fileUploader")
              ? _c(
                  "div",
                  { staticClass: "flex items-center absolute" },
                  [
                    _c("errorIcon"),
                    _c(
                      "div",
                      { staticClass: "ml-2 text-red-error text-normal-bold" },
                      [
                        _vm._v(
                          " No supported documentation was uploaded. Please upload required files "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Does this proposed name commemorate a person? "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Full Name of Person "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold pb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Birth "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Date of Death "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(" Please explain why the Date of Birth or Death is unknown "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-4" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v(
        "What is the person's association with this feature and their main contribution to the local community? "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }