var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-white shadow overflow-hidden sm:rounded-lg mb-6" },
    [
      _vm._m(0),
      _c(
        "div",
        [
          _c("proposals-list", {
            attrs: {
              heading: "Place Name Proposals",
              proposals: _vm.currentPlaceNames,
            },
          }),
          _c("proposals-list", {
            attrs: {
              heading: "Address Locality Proposals",
              proposals: _vm.currentLocalities,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "px-4 py-5 border-b border-gray-200 sm:px-6" },
      [
        _c(
          "h3",
          { staticClass: "text-2xl leading-6 font-medium text-gray-900" },
          [_vm._v(" Proposals Open for Public Comment ")]
        ),
        _c(
          "p",
          { staticClass: "mt-1 max-w-2xl text-sm leading-5 text-gray-500" },
          [
            _vm._v(
              " The following proposals are currently open for public comment. "
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }