<template>
    <div>
        <div
            class="no-issue-container"
        >
            <div
                v-if="similarRoadnames.issueCount===0"
                class="no-issue"
            >
                <font-awesome-icon 
                    icon="check"
                    style="margin-right:0.5rem" 
                />  0 potential issues found in the Proposals System
            </div>
        </div>
        <div
            @click="toggleLists"
            class="title-message"
            v-if="similarRoadnames.issueCount>0"
        >
            {{ `${similarRoadnames.issueCount} potential issue(s) found in the Proposals System` }}
            <span>
                <font-awesome-icon 
                    icon="chevron-circle-down"
                    class="arrow"
                    :class="showList?'arrow-up':'arrow-down'" 
                />
            </span>
        </div>
        <div :class="isFirstOpen?'display-none':''">
            <div
                :class="showList?'show-lists':'hide-lists'"
            >
                <div 
                    v-for="(issue, index) in listOfIssuesForCurrentPage"
                    :key="`${issue}-${index}`"
                >
                    <div class="issue">
                        <font-awesome-icon icon="exclamation" class="mr-2" /> {{`${issue.shortDescription} (${issue.status})`}}
                    </div>
                </div>
                <div class="page-box">
                    <paginate
                        v-model="currentPage"
                        :page-count="calculateTotalPageNumber()"
                        :page-range="10"
                        :margin-pages="1"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                    ></paginate>
                    <p>
                        Showing
                        {{ ((currentPage - 1) * issueLimit + 1).toLocaleString() }}
                        to
                        {{
                        Math.min(
                            (currentPage - 1) * issueLimit + issueLimit,
                            similarRoadnames.issueCount
                        ).toLocaleString()
                        }}
                        of
                        {{ similarRoadnames.issueCount.toLocaleString() }}
                        proposals
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
    components: {
        Paginate
    },
    props: {
        similarRoadnames: {
            type: Object
        }
    },
    data() {
        return {
            showList: false,
            listOfIssuesForCurrentPage: [],
            currentPage: 1,
            // issueLimit - Specify the number of issues to display on one page
            issueLimit: 10,
            totalPages: 0,
            totalIssues: [],
            isFirstOpen: true
        }
    },
    watch: {
        similarRoadnames(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.refreshList(newVal.issues);
            }
        }, 
        currentPage(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.generateNewListForCurrentPage();
            }
        }
    },
    mounted() {
        this.refreshList(this.similarRoadnames.issues);
    },
    methods: {
        // Refreshes the current issue list and assigns new issues to the list
        refreshList(lists) {
            this.totalIssues = lists;

            this.listOfIssuesForCurrentPage = [];
            this.totalIssues.forEach((item, index) => {
                if(index < this.issueLimit) {
                    this.listOfIssuesForCurrentPage.push(item);
                }
            });

            this.totalPages = this.calculateTotalPageNumber();
            this.currentPage = 1;
        },
        // Shows or hides the list
        toggleLists() {
            this.showList = !this.showList;
            this.isFirstOpen = false;
        },
        // Calculates total page number
        calculateTotalPageNumber() {
            return Math.ceil(this.similarRoadnames.issueCount / this.issueLimit);
        },
        // Generate a list of issues for the current page
        generateNewListForCurrentPage() {
            this.listOfIssuesForCurrentPage = [];

            if(this.currentPage < this.totalPages){
                for(let i = (this.currentPage - 1) * this.issueLimit; i < this.currentPage * this.issueLimit; i++) {
                    this.listOfIssuesForCurrentPage.push(this.totalIssues[i]);
                }
            } else {
                for(let i = (this.currentPage - 1) * this.issueLimit; i < this.totalIssues.length; i++) {
                    this.listOfIssuesForCurrentPage.push(this.totalIssues[i]);
                }
            }
        }
    }
}
</script>

<style scoped>
    .title-message {
        cursor: pointer;
        font-size: 1.125rem;
        color: #de751f;
        font-weight: bold;
    }

    .issue {
        color: rgb(228, 120, 5);
        background-color: #fff1cf;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        font-weight: bold;
        border-radius: 0.25rem;
    }

    .page-box {
        text-align: center;
    }

    .display-none {
        display: none;
    }

    .show-lists {
        opacity: 1;
        animation: 0.5s linear;
        animation-name: open-lists;
    }

    .hide-lists {
        opacity: 0;
        height: 0;
        animation: 0.5s linear;
        animation-name: close-lists;
    }

    @keyframes open-lists{
        0%{
            opacity: 0;
            height:0;
        }

        100%{
            opacity: 1;
            height: 400px;
        }
    }

    @keyframes close-lists{
        0%{
            opacity: 1;
            height: 400px;
        }

        100%{
            opacity: 0;
            height:0;
        }
    }

    .arrow-up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    .arrow-down {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }

    .arrow {
        transition-duration: 0.8s;
        transition-property: transform;
    }

    .no-issue {
        padding: 0.5rem 1rem;
        color: rgb(43, 169, 2);
        background-color: #deffdb;
        font-weight: bold;
    }

    .no-issue-container {
        display: flex;
    }
</style>