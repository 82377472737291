var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "cancel-proposal",
        width: "40%",
        height: "auto",
        classes: "rounded-lg bg-white py-6 ",
      },
      on: { "before-open": _vm.getProposal },
    },
    [
      _c(
        "div",
        { staticClass: "w-9/10 mx-auto flex flex-col justify-center" },
        [
          _c(
            "a",
            {
              staticClass: "cursor-pointer ml-auto",
              on: {
                click: function ($event) {
                  return _vm.hide()
                },
              },
            },
            [_c("ErrorIcon", { attrs: { classNames: "w-5" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full flex justify-center text-black mt-10" },
            [_vm._v(" Withdraw Road Name Proposal? ")]
          ),
          _c(
            "h2",
            {
              staticClass:
                "w-full flex justify-center font-light text-black mt-8 text-center",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.road.roadname) +
                  " " +
                  _vm._s(_vm.road.road_type) +
                  " "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full inline font-light text-black mt-4 text-center mb-4 flex-auto",
            },
            [
              _vm._v(" Suburb:  "),
              _vm._l(_vm.road.suburb_name, function (sub, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "font-bold text-black inline" },
                  [
                    _vm._v(" " + _vm._s(sub)),
                    index !== _vm.road.suburb_name.length - 1
                      ? _c("span", [_vm._v(",  ")])
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
          _c("br"),
          _c(
            "h4",
            {
              staticClass:
                "w-full flex justify-center font-light text-black text-center",
            },
            [_vm._v(" in " + _vm._s(_vm.road.lga_name) + " ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-full flex text-justify text-sm font-light text-black mt-4 mb-4 py-2 justify-center",
            },
            [
              _vm._v(
                " Are you sure you want to withdraw the above road name proposal? "
              ),
            ]
          ),
          _c("div", { staticClass: "flex justify-between pt-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex w-full justify-between content-between pt-4 pb-4 mb-4 text-center",
              },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "wait",
                        rawName: "v-wait:disabled",
                        value: "withdrawing",
                        expression: "'withdrawing'",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "button-blue-hollow w-1/2 text-sm",
                    on: { click: _vm.hide },
                  },
                  [_vm._v(" No, keep this road name proposal active ")]
                ),
                _c(
                  "buttonWithSpinner",
                  {
                    staticClass: "button-red w-1/2 text-sm pt-4 ml-8",
                    attrs: {
                      isSpinning: _vm.$wait.is("withdrawing"),
                      disabled: false,
                    },
                    on: { click: _vm.withdraw },
                  },
                  [_vm._v(" Yes, withdraw this road name proposal ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }