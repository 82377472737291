var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "flex h-full flex-col" },
    [
      _c("navigationMenu", { staticClass: "flex-initial" }),
      _c(
        "div",
        { staticClass: "md:flex flex-auto justify-start" },
        [
          _c("sidebarMenu"),
          _c(
            "div",
            {
              staticClass:
                "flex-grow flex bg-grey-lighter shadow-lgInverseRight text-white",
            },
            [
              _c("ShowLoading", {
                attrs: { isLoading: "routeData" },
                scopedSlots: _vm._u([
                  {
                    key: "component",
                    fn: function () {
                      return [
                        _c(
                          "transition",
                          {
                            attrs: { appear: "", name: "fade", mode: "out-in" },
                          },
                          [_c("router-view")],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "authentication-modal",
            maxWidth: 540,
            scrollable: true,
            clickToClose: false,
            height: "auto",
          },
          on: { "before-close": _vm.beforeClose },
        },
        [_c("authenticationModal")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }