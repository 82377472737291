var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FormContainer", [
    _c(
      "div",
      { staticClass: "text-normal" },
      [
        _c("h1", { staticClass: "pt-6 mb-10 text-center mt-4 text-title" }, [
          _vm._v("Recover Password"),
        ]),
        _c("p", { staticClass: "pb-6" }, [
          _vm._v(
            " A confirmation code has been sent to your email address, please enter it here. "
          ),
        ]),
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            {
              staticClass: "block text-black text-normal-bold mb-2",
              attrs: { for: "email" },
            },
            [_vm._v("Email Address")]
          ),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email",
                expression: "'required|email'",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user,
                expression: "user",
              },
            ],
            staticClass: "w-full input-area",
            attrs: {
              id: "email",
              name: "email",
              type: "text",
              placeholder: "",
            },
            domProps: { value: _vm.user },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.user = $event.target.value
              },
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("email"),
                  expression: "errors.has('email')",
                },
              ],
              staticClass: "text-red text-xs italic",
            },
            [_vm._v(" " + _vm._s(_vm.errors.first("email")) + " ")]
          ),
        ]),
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "label",
            {
              staticClass: "block text-black text-normal-bold mb-2",
              attrs: { for: "code" },
            },
            [_vm._v("Recovery code")]
          ),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.code,
                expression: "code",
              },
            ],
            staticClass: "w-full input-area",
            attrs: {
              "data-cypress": "code",
              id: "code",
              name: "code",
              type: "text",
              placeholder: "",
              "data-vv-as": "Recovery code",
            },
            domProps: { value: _vm.code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.code = $event.target.value
              },
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("code"),
                  expression: "errors.has('code')",
                },
              ],
              staticClass: "text-red text-xs italic",
            },
            [_vm._v(" " + _vm._s(_vm.errors.first("code")) + " ")]
          ),
        ]),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "mb-6 w-1/2" }, [
            _c(
              "label",
              {
                staticClass: "block text-black text-normal-bold mb-2",
                attrs: { for: "passwordInitial" },
              },
              [_vm._v("New Password")]
            ),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|passwordPolicy",
                  expression: "'required|passwordPolicy'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordInitial,
                  expression: "passwordInitial",
                },
              ],
              ref: "passwordInitial",
              staticClass: "w-full mb-3 input-area",
              attrs: {
                "data-cypress": "password1",
                id: "passwordInitial",
                name: "Password",
                type: "password",
                placeholder: "",
              },
              domProps: { value: _vm.passwordInitial },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.passwordInitial = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("Password"),
                    expression: "errors.has('Password')",
                  },
                ],
                staticClass: "text-red-button font-medium text-xs",
              },
              [_vm._v(_vm._s(_vm.errors.first("Password")))]
            ),
          ]),
          _c("div", { staticClass: "mb-6 w-1/2 ml-4" }, [
            _c(
              "label",
              {
                staticClass: "block text-black text-normal-bold mb-2",
                attrs: { for: "passwordconfirm" },
              },
              [_vm._v("Password confirm")]
            ),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|confirmed:passwordInitial",
                  expression: "'required|confirmed:passwordInitial'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordconfirm,
                  expression: "passwordconfirm",
                },
              ],
              staticClass: "w-full mb-3 input-area",
              attrs: {
                "data-cypress": "password2",
                id: "passwordconfirm",
                name: "passwordconfirm",
                type: "password",
                placeholder: "",
              },
              domProps: { value: _vm.passwordconfirm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.passwordconfirm = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("passwordconfirm"),
                    expression: "errors.has('passwordconfirm')",
                  },
                ],
                staticClass: "text-red-button font-medium text-xs",
              },
              [_vm._v("Passwords do not match.")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "flex flex-grow self-stretch" }),
        _c(
          "ButtonWithSpinner",
          {
            staticClass:
              "w-full mt-4 button-blue rounded focus:outline-none focus:shadow-outline",
            attrs: {
              "data-cypress": "submit",
              type: "button",
              disabled: _vm.$wait.any || !_vm.isFormDirty || !_vm.isFormValid,
              isSpinning: _vm.$wait.any,
            },
            on: { click: _vm.resetPassword },
          },
          [_vm._v(" Submit ")]
        ),
        _c(
          "router-link",
          {
            staticClass:
              "w-full bg-white text-normal-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 text-blue-button bg-white mb-10",
            attrs: {
              tag: "button",
              type: "button",
              to: { name: "Authentication" },
            },
          },
          [_vm._v(" Return to login page ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }