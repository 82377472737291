<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <modal
        name="redirect-to-login"
        :width="'33%'"
        height="auto"
        :classes="'rounded-lg bg-white py-16 px-16 text-center'"
        @before-close="represents_entity = null"
      >
        <h3 class="mb-4">Login Required</h3>
        <p>
          As a representative of a Government Authority, you are required to log
          in to the system before submitting a Place Name Proposal.
        </p>
        <button @click="redirectToLogin" class="button-blue mt-4">
          Proceed to log in page
        </button>
      </modal>
      <div class="w-5/6 mx-auto ">
        <div class="w-full pb-8 pt-8" v-if="isAdmin">
          <p class="text-normal-bold mb-2 w-full">
            <span class="text-red-star">*</span>On Behalf of Organisation:
          </p>

          <select
            class="w-full rounded-md p-4 text-normal input-field"
            data-cypress="behalf-of-organisation-select"
            :disabled="readOnly"
            v-model="organisationIdentifier"
          >
            <option
              v-for="(pna, index) in organisations"
              v-bind:key="index"
              :value="pna.identifier"
              class=" my-4 font-light pl-4 h-8"
            >
              <div class="my-4">{{ pna.organisation_name }}</div>
            </option>
          </select>
        </div>
        <div class="w-full mx-auto" v-if="isPublic">
          <p class="text-normal-bold mt-4">
            <span class="text-red-star">*</span>Do you represent a Government
            Authority?
          </p>
          <div class="flex flex-row text-normal">
            <RadioButton
              class="flex-0 mr-6 mt-3 mb-1"
              name="represents_entity"
              v-model="represents_entity"
              data-cypress="represents_entity-no"
              :checkedValue="false"
              :disabled="readOnly"
              >No</RadioButton
            >
            <RadioButton
              class="flex-0 mr-6 mt-3 mb-1"
              name="represents_entity"
              v-model="represents_entity"
              data-cypress="represents_entity-true"
              :checkedValue="true"
              :disabled="readOnly"
              >Yes</RadioButton
            >
          </div>
        </div>
        <label class="w-2/3">
          <p class="text-normal-bold mb-2 w-full  mt-8">
            <span class="text-red-star">*</span>Your Reference
          </p>

          <input
            type="text"
            class="w-full mr-4 input-area"
            data-cypress="referenceNumber-field"
            placeholder="Enter your reference..."
            name="referenceNumber"
            id="referenceNumber"
            v-validate="'required'"
            v-model="referenceNumber"
            :disabled="readOnly"
          />
          <div v-if="evaluation">
            <div
              v-for="(rules, index) in evaluation.issues"
              class="flex items-center relative"
              v-bind:key="index"
            >
              <errorIcon />
              <div class="text-red-error text-normal-bold ml-2">
                {{ rules.shortDescription }}
              </div>
            </div>
          </div>
        </label>
        <div class="w-5/6 mx-auto pt-8 pb-16 flex-grow  w-full">
          <p class="text-normal-bold">
            <span class="text-red-star">*</span>Type of Proposal
          </p>
          <div class="flex flex-row text-normal">
            <RadioButton
              class="flex-0 mr-6 mt-3 mb-1"
              name="placeNameProposal"
              data-cypress="place-name-proposal"
              v-model="proposalType"
              :checkedValue="PROPOSAL_TYPE_PLACE"
              :disabled="readOnly"
              v-validate="'required'"
              >Place Name Proposal</RadioButton
            >

            <RadioButton
              class="flex-0 mr-6 mt-3 mb-1"
              data-cypress="new-amended-address-locality"
              name="newAmendedLocality"
              v-model="proposalType"
              :checkedValue="PROPOSAL_TYPE_LOCALITY"
              :disabled="readOnly"
              v-validate="'required'"
              v-if="!isPublic"
              >New/Amended Address Locality Name</RadioButton
            >

            <RadioButton
              class="flex-0 mr-6 mt-3 mb-1"
              data-cypress="address-locality-boundary-amendment"
              name="localityBoundaryAmendment"
              v-model="proposalType"
              :checkedValue="PROPOSAL_TYPE_BOUNDARY"
              :disabled="readOnly"
              v-validate="'required'"
              v-if="!isPublic"
              >Address Locality Boundary Amendment</RadioButton
            >
          </div>
          <div
            class="flex items-center absolute"
            v-if="errors.first('Road type check')"
          >
            <errorIcon />
            <div class="ml-2 text-red-error text-normal-bold">
              The type check field is required.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_BOUNDARY
} from "@/components/proposals/place/pnp-constants.js";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  props: {
    readOnly: {
      type: Boolean
    },
    isAdmin: {
      type: Boolean
    },
    organisations: {
      type: Array
    },
    isPublic: {
      type: Boolean
    }
  },
  data() {
    return {
      evaluation: false,
      isDisabled: false,
      represents_entity: null,
      PROPOSAL_TYPE_PLACE,
      PROPOSAL_TYPE_LOCALITY,
      PROPOSAL_TYPE_BOUNDARY
    };
  },
  computed: {
    proposalType: {
      get() {
        return this.getProposalType();
      },
      set(val) {
        if (val) {
          this.clearEvaluation();
          this.setProposalType(val);
        }
      }
    },
    referenceNumber: {
      get() {
        return this.getReferenceNumber();
      },
      set(value) {
        this.setReferenceNumber(value);
      }
    },
    organisationIdentifier: {
      get() {
        return this.getOrganisationIdentifier();
      },
      set(value) {
        this.setOrganisationIdentifier(value);
      }
    }
  },
  watch: {
    represents_entity(newVal) {
      if (newVal === true) {
        this.$modal.show("redirect-to-login");
      }
    }
  },
  methods: {
    redirectToLogin() {
      this.$router.push({
        name: "Authentication",
        params: { redirected: true }
      });
    },
    ...mapPnpActions(["clearEvaluation"]),
    ...mapPnpGetters([
      "getProposalType",
      "getReferenceNumber",
      "getOrganisationIdentifier"
    ]),
    ...mapPnpMutations([
      "setProposalType",
      "setReferenceNumber",
      "setOrganisationIdentifier"
    ])
  }
};
</script>
<style scoped>
.input-field {
  background-color: #ecf1f3;
}

.buttonBlue {
  border-radius: 4px;
  border: 2px solid #002664;
  background-color: #002664;
  color: white;
}
</style>
