<template>
  <showLoading :isLoading="isLoading">
    <template v-slot:component>
      <keep-alive>
        <router-view
          v-wait:hidden="isLoading"
          :organisation="organisation"
        ></router-view>
      </keep-alive>
    </template>
  </showLoading>
</template>

<script>
import showLoading from "../elements/showLoading";
import { waitFor } from "vue-wait";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    showLoading
  },
  props: ["identifier", "isLoading"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["organisation"])
  },
  methods: {
    ...mapActions(["getOrganisation"]),
    fetchOrganisation: waitFor("isLoading", async function() {
      await this.getOrganisation(this.identifier);
    })
  },
  async created() {
    this.fetchOrganisation();
  }
};
</script>

<style></style>
