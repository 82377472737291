<template>
  <div class="base-container">
    <section class="justify-center w-full block">
      <a
        v-if="proposalId !== 'bulk'"
        class="flex justify-between"
        @click="$router.push({ name: 'reGazette', params: { road } })"
      >
        <BackArrowWithTitle title="Preview Gazettal Notice" />
      </a>
      <showLoading isLoading="fetching rna details">
        <template v-slot:component>
          <div
            class="w-full bg-white text-black rounded-lg pl-8 pr-8 pt-24 pb-24 mt-4 justify-center flex"
          >
            <gazette-mock
              :roads="[road]"
              :rna="rna"
              :user="user"
            ></gazette-mock>
          </div>

          <div class="flex pt-4 text-center pb-10">
            <router-link
              :to="{ name: 'reGazette', params: { road } }"
              class="button-blue-hollow flex-no-grow"
              >Return to draft</router-link
            >
            <div class="flex-grow"></div>
            <ButtonWithSpinner
              class="button-blue relative flex flex-no-grow justify-center whitespace-no-wrap font-semibold"
              data-cypress="submit-gazettal-notice-button"
              :disabled="$wait.is('saving gazette') || multipleRNAError"
              :isSpinning="$wait.is('saving gazette')"
              @click="submit"
              >Create Gazettal Notice</ButtonWithSpinner
            >
          </div>
        </template></showLoading
      >
    </section>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { getRNA } from "../../helpers/rnaCRUD";
import { createReGazettalNotice } from "../../helpers/rnpCRUD";
import { waitFor } from "vue-wait";
import gazetteMock from "../gazettalPage/gazette-mock";

export default {
  name: "gazettalPreview",
  components: {
    gazetteMock
  },
  props: {
    road: { type: Object },
    uploads: { type: Array },
    proposalId: { type: String }
  },
  data() {
    return {
      rna: {},
      errorFetching: false,
      multipleRNAError: false
    };
  },
  computed: {
    ...mapUserGetters(["user"])
  },
  async created() {
    await this.fetchRNA();
  },
  methods: {
    fetchRNA: waitFor("fetching rna details", async function() {
      try {
        this.rna = await getRNA(this.road.road_naming_authority_id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.errorFetching = true;
      }
    }),
    submit: waitFor("saving gazette", async function() {
      try {
        await createReGazettalNotice(this.road, this.uploads);
        this.$router.push({
          name: "gazettalSubmit",
          params: {
            road: this.road,
            proposalId: this.proposalId
          }
        });
      } catch (e) {
        const text =
          e.response.status === 409
            ? e.response.data.message
            : "There has been an error creating your gazettal notice. Please try again later";
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error creating gazette notice",
          text
        });
      }
    })
  }
};
</script>
