var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "w-full rounded-lg p-4 text-normal mb-16",
      domProps: { value: _vm.rna },
      on: {
        change: function ($event) {
          return _vm.$emit("change", $event.target.value)
        },
      },
    },
    [
      !_vm.rnaList.length ? _c("option") : _vm._e(),
      _vm._l(_vm.rnaList, function (item) {
        return _c(
          "option",
          { key: item.identifier, domProps: { value: item.identifier } },
          [_vm._v(" " + _vm._s(item.authority_name) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }