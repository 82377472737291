<template>
  <entry
    cardTitle="Erratum Gazette Notice Created"
    :eventDate="entryData.createdAt"
  >
    <div>
      <p class="mb-6">An erratum gazette notice was created.</p>

      <table class="table-fixed w-full">
        <tbody>
          <tr>
            <td class="font-bold w-1/4">Road Name:</td>
            <td>{{ entryData.roadName.to }}</td>
          </tr>
          <tr>
            <td class="font-bold w-1/4">Road Type:</td>
            <td>{{ entryData.roadType.to }}</td>
          </tr>
          <tr>
            <td class="font-bold w-1/4">Extent:</td>
            <td>{{ entryData.extent.to }}</td>
          </tr>
          <tr v-if="entryData.locality && entryData.locality.to">
            <td class="font-bold w-1/4">Locality(s):</td>
            <td>{{ transformLocalities(entryData.locality.to) }}</td>
          </tr>
          <tr>
            <td class="font-bold w-1/4">Gazette Number:</td>
            <td>{{ entryData.gazetteNumber.to }}</td>
          </tr>
          <tr>
            <td class="font-bold w-1/4">Publication Date:</td>
            <td>{{ format(entryData.publishedDate.to) }}</td>
          </tr>
          <tr>
            <td class="font-bold w-1/4">Folio Number:</td>
            <td>{{ entryData.folioNumber.to }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";
import moment from "moment";

export default {
  name: "ErratumGazettalNoticeTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  },

  /**
   * Local instance methods
   */
  methods: {
    format(date) {
      return moment(date).format("DD MMM YYYY");
    },
    transformLocalities(localities) {
      const parsedLocalities = JSON.parse(localities);
      if (Array.isArray(parsedLocalities)) {
        return parsedLocalities.join(", ");
      }
      return parsedLocalities;
    }
  }
};
</script>
