import axios from "axios";
import Auth from "@aws-amplify/auth";
// import router from "@/router";
import store from "@/vuex/store";

export default class API {
  static async get(apiName, url, params) {
    const config = {
      method: "GET",
      url,
      params: params && params.queryStringParameters,
      headers: params && params.headers,
      responseType: (params && params.responseType) || "json"
    };

    return this.doRequest(config);
  }

  static async post(apiName, url, payload) {
    const config = {
      method: "POST",
      url,
      data: payload && payload.body,
      headers: payload && payload.headers,
      params: payload && payload.queryStringParameters
    };

    return this.doRequest(config);
  }

  static async put(apiName, url, payload) {
    const config = {
      method: "PUT",
      url,
      data: payload && payload.body,
      headers: payload && payload.headers,
      params: payload && payload.queryStringParameters
    };

    return this.doRequest(config);
  }

  static async del(apiName, url, payload) {
    const config = {
      method: "DELETE",
      url,
      data: payload && payload.body,
      headers: payload && payload.headers,
      params: payload && payload.queryStringParameters
    };

    return this.doRequest(config);
  }

  static async doRequest(reqConfig) {
    let jwt;
    let authHeader;
    try {
      jwt = (await Auth.currentSession()).idToken.jwtToken;
      authHeader = { Authorization: `Bearer ${jwt}` };
    } catch (e) {
      authHeader = { Authorization: "" };
    }

    const baseConfig = {
      baseURL: __AWS_API_URI__
    };

    const cfg = Object.assign({}, baseConfig, reqConfig);

    //merge headers
    cfg.headers = Object.assign({}, authHeader, cfg.headers);

    const resp = await axios.request(cfg).catch(async e => {
      if (e.response.status == 401) {
        store.commit("updateLoginModalStatus", true);
      }
      throw e;
    });

    return Promise.resolve(resp.data);
  }
}
