<template>
  <div>
    <div>
      <p class="text-normal-bold">
        {{ mapFieldToLabel("dual_named") }}
      </p>

      <div class="flex flex-row text-normal">
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          data-cypress="dual_named-no"
          name="dual_named-no"
          v-model="geoName.dual_named"
          :checkedValue="false"
          :disabled="dualNamingComplete"
          @change="setDualNamedState"
          >No</RadioButton
        >
        <RadioButton
          class="flex-0 mr-6 mt-3 mb-1"
          name="dual_named-yes"
          data-cypress="dual_named-yes"
          v-model="geoName.dual_named"
          :checkedValue="true"
          :disabled="dualNamingComplete"
          @change="setDualNamedState"
          >Yes</RadioButton
        >
      </div>

      <div v-if="dualNamingComplete" class="mt-4">
          <p class="text-normal-bold">
            {{ mapFieldToLabel("dual_name") }}
          </p>

          <div class="flex flex-row mt-2 items-center">
            <a :href="siblingUrl" target="_blank" class="text-normal hover:underline">{{ linkedWith }}</a>

            <template v-if="!requestConfirmation">
              <span class="text-xs text-normal ml-4">[</span>
              <a href="#" class="text-xs text-normal" @click.prevent="requestConfirmation = true">remove</a>
              <span class="text-xs text-normal">]</span>
            </template>

            <p class="ml-4" v-else>
              Are you sure?
              <a href="" @click.prevent="requestConfirmation = false">No, cancel</a>
              |
              <a href="" @click.prevent="removeDualName">Yes</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  createNamespacedHelpers
} from "vuex";
import SearchModal from "./DualNamedSearchModal";
import mapFieldToLabel from "../map-field-to-label.js";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  name: "GeonameDualNamed",

  /**
   * Instance properties
   */
  props: {
    /**
     * The current GeoName record
     */
    geoName: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * Is the form readOnly
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {
      requestConfirmation: false
    };
  },

  /**
   * Instance computed properties
   */
  computed: {
    ...mapPnpGetters(["getGeonameDualNamingState"]),

    showModal() {
      return true;
    },

    isDualNamed() {
      return this.getGeonameDualNamingState;
    },

    dualNamingComplete() {
      return this.geoName.dual_identifier !== null;
    },

    linkedWith() {
      return this.geoName.dual_geographical_name;
    },

    siblingUrl() {
      return `/Dashboard/geonames/${this.geoName.dual_identifier }/edit`;
    }
  },

  /**
   * Data watchers
   */
  watch: {
    isDualNamed(dualNamed) {
      this.geoName.dual_named = dualNamed;
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapPnpMutations(["setDualNamed"]),
    ...mapPnpActions(["unlinkPlacename"]),

    /**
     * Map the name of the field to a human label
     */
    mapFieldToLabel: (field, shortLabel) => mapFieldToLabel(field, shortLabel),

    /**
     * Set the current geoname dual named state
     */
    setDualNamedState(isDualNamed) {
      this.setDualNamed(isDualNamed);

      if (isDualNamed) {
        this.showSearchModal();
      }
    },

    /**
     * Show the search modal
     */
    showSearchModal() {
      this.$modal.show(
        SearchModal,
        {
          geoName: this.geoName
        },
        {
          height: "auto",
          minHeight: 400,
          width: "50%",
          shiftY: 5,
          scrollable: true,
          clickToClose: false
        },
        {
          "before-close": async event => {
            if (this.$wait.is("processingLink")) {
              event.stop();
            }
          }
        }
      );
    },

    /**
     * The user is removing the dual name associated
     * with the placename
     */
    async removeDualName() {
      await this.unlinkPlacename({
        source: this.geoName.identifier,
        target: this.geoName.dual_identifier
      });

      this.geoName.dual_identifier = null;
      this.geoName.dual_named = false;
      this.geoName.dual_geographical_name = null;
      this.requestConfirmation = false;

      this.$notify({
          group: "toast",
          type: "success",
          title: "Placename record updated",
          text: "The linked placename has been removed. There is no need to click the save button."
        });
    }
  }
};
</script>
