"use strict";

export default function (field, shortLabel) {
  let labels = {};

  switch (field.toLowerCase()) {
    case "status":
      labels.long = "Place Name Status";
      labels.short = "Place Name Status";
      break;
    case "designation":
      labels.long = "Designation";
      labels.short = "Designation";
      break;
    case "geoname_type":
      labels.long = "Type";
      labels.short = "Type";
      break;
    case "place_name":
      labels.long = "Place Name";
      labels.short = "Place Name";
      break;
    case "geographical_name":
      labels.long = "Geographical Name";
      labels.short = "Geographical Name";
      break;
    case "gnb_file_reference":
      labels.long = "GNB File Reference";
      labels.short = "GNB File Reference";
      break;
    case "map_cma":
      labels.long = "1:25,000 Map Name";
      labels.short = "1:25,000 Map Name";
      break;
    case "longitude":
      labels.long = "Longitude";
      labels.short = "Longitude";
      break;
    case "latitude":
      labels.long = "Latitude";
      labels.short = "Latitude";
      break;
    case "height":
      labels.long = "Height";
      labels.short = "Height";
      break;
    case "description":
      labels.long = "Describe the feature, extent and/or geographical location";
      labels.short = "Description";
      break;
    case "meaning":
      labels.long = "Meaning of word used in proposed name";
      labels.short = "Meaning of Word";
      break;
    case "origin":
      labels.long = "Origin of proposed name";
      labels.short = "Origin of proposed name";
      break;
    case "history":
      labels.long = "History";
      labels.short = "History";
      break;
    case "pronunciation":
      labels.long = "Provide the phonetic spelling of the proposed name";
      labels.short = "Phonetic Spelling";
      break;
    case "spatial_ref":
      labels.long = "Spatial reference";
      labels.short = "Spatial reference";
      break;
    case "gfid":
      labels.long = "GFID";
      labels.short = "GFID";
      break;
    case "gfid_type":
      labels.long = "GFID type";
      labels.short = "GFID type";
      break;
    case "aboriginal_name":
      labels.long = "Is the proposed name an Aboriginal name?";
      labels.short = "Is Aboriginal name";
      break;
    case "aboriginal_country":
      labels.long = "Country";
      labels.short = "Country";
      break;
    case "aboriginal_language":
      labels.long = "Language group";
      labels.short = "Language group";
      break;
    case "multicultural":
      labels.long = "Does the proposed name have a multicultural dimension?";
      labels.short = "Has multicultural dimension";
      break;
    case "lga":
      labels.long = "Local Government Area (LGA)";
      labels.short = "LGA";
      break;
    case "lga_gazettal":
      labels.long = "Local Government Area (LGA) at Gazettal";
      labels.short = "Gazettal LGA";
      break;
    case "lga_spatial":
      labels.long = "Spatial Local Government Area (LGA)";
      labels.short = "Spatial LGA";
      break;
    case "locality":
      labels.long = "Address Locality (Suburb)";
      labels.short = "Address Locality (Suburb)";
      break;
    case "parish":
      labels.long = "Parish";
      labels.short = "Parish";
      break;
    case "county":
      labels.long = "County";
      labels.short = "County";
      break;
    case "electorate":
      labels.long = "State Electorate(s)";
      labels.short = "State Electorate(s)";
      break;
    case "previous_name":
      labels.long = "Previous names";
      labels.short = "Previous names";
      break;
    case "gazettal_dates":
      labels.long = "Gazettal dates";
      labels.short = "Gazettal dates";
      break;
    case "is_legacy":
      labels.long = "Legacy";
      labels.short = "Legacy";
      break;
    case "dual_named":
      labels.long = "Dual proposal?";
      labels.short = "Dual proposal?";
      break;
    case "dual_name":
      labels.long = "Dual Name";
      labels.short = "Dual Name";
      break;
    case "is_suitable_for_public_display":
      labels.long = "Is GNR record suitable for public display?";
      labels.short = "Suitable for public display";
      break;
    case "national_feature_set":
      labels.long = "National feature set";
      labels.short = "National feature set";
      break;
    case "legacy_map_name":
      labels.long = "Map name";
      labels.short = "Map name";
      break;
    case "legacy_map_reference":
      labels.long = "Map number";
      labels.short = "Map number";
      break;
    case "legacy_map_data":
      labels.long = "1:100,000 map information";
      labels.short = "1:100,000 map information";
      break;
    case "official_map_reference_document":
      labels.long = "Attach official map";
      labels.short = "Official Map";
      break;
    case "pronunciation_reference_document":
      labels.long = "Attach pronunciation audio/sound files";
      labels.short = "Pronunciation";
      break;
    case "origin_reference_document":
      labels.long = "Attach supporting material for origin";
      labels.short = "Origin";
      break;

    case "source_reference_document":
      labels.long = "Source files";
      labels.short = "Commemoration Source";
      break;
    case "pending_endorsement_reference_document":
      labels.long = "Endorsement";
      labels.short = "Endorsement";
      break;
    case "aboriginal_reference_document":
      labels.long = "Source files";
      labels.short = "Source files";
      break;
    case "council_resolution_reference_document":
      labels.long = "Council Resolution files";
      labels.short = "Council Resolution files";
      break;
    case "background_info_reference_document":
      labels.long = "Background Info files";
      labels.short = "Background Info files";
      break;
    case "marked_map_reference_document":
      labels.long = "Marked Map";
      labels.short = "Marked Map";
      break;
    case "marked_photo_reference_document":
      labels.long = "Marked Photo";
      labels.short = "Marked Photo";
      break;
    case "public_comment_reference_document":
      labels.long = "Public Comment files";
      labels.short = "Public Comment files";
      break;
    case "advert_reference_document":
      labels.long = "Advert files";
      labels.short = "Advertisement";
      break;
    case "gazette_official_reference_document":
      labels.long = "Gazette Official";
      labels.short = "Gazette Official";
      break;
    case "gazette_reference_document":
      labels.long = "Gazette files";
      labels.short = "Gazette Notice";
      break;
    case "general_reference_document":
      labels.long = "General";
      labels.short = "General";
      break;
    case "minister_briefing_note_reference_document":
      labels.long = "Minister Briefing Note files";
      labels.short = "Minister Briefing Note";
      break;
    case "clarification_reference_document":
      labels.long = "Clarification files";
      labels.short = "Clarification files";
      break;
    case "supporting_reference_document":
      labels.long = "Supporting files";
      labels.short = "Supporting files";
      break;

    case "historical_reference_document":
      labels.long = "Attach supporting material for history";
      labels.short = "Supporting material for history";
      break;
    case "date_created":
      labels.long = "Date created";
      labels.short = "Date created";
      break;
    case "date_modified":
      labels.long = "Date last modified";
      labels.short = "Date last modified";
      break;

    case "evaluation_report_reference_document":
      labels.long = "Pre-Evaluation Report";
      labels.short = "Pre-Evaluation Report";
      break;

    case "media_release_reference_document":
      labels.long = "Media Release";
      labels.short = "Media Release";
      break;

    case "public_support_reference_document":
      labels.long = "Public Support";
      labels.short = "Public Support";
      break;
    case "public_consultation_reference_document":
      labels.long = "Public Consultation";
      labels.short = "Public Consultation";
      break;

    // HISTORY REFERENCE DOCUMENTS
    case "history_reference_document":
      labels.long = "Supporting material for history";
      labels.short = "Supporting material for history";
      break;
    case "history_verified_approved_reference_document":
      labels.long = "Supporting material for history - Verified Approved";
      labels.short = "Supporting material for history - Verified Approved";
      break;
    case "history_unverified_approved_reference_document":
      labels.long = "Supporting material for history - Unverified Approved";
      labels.short = "Supporting material for history - Unverified Approved";
      break;
    case "history_verified_rejected_reference_document":
      labels.long = "Supporting material for history - Verified Rejected";
      labels.short = "Supporting material for history - Verified Rejected";
      break;
    case "history_unverified_rejected_reference_document":
      labels.long = "Supporting material for history - Unverified Rejected";
      labels.short = "Supporting material for history - Unverified Rejected";
      break;
    default:
      labels.long = field;
      labels.short = field;
      break;
  }
  if (shortLabel) return labels.short;
  else return labels.long;
}
