"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

const savePlaceNameProposal = function(proposal) {
  if (proposal.identifier) {
    return API.put(apiName, "/placenames/proposals/" + proposal.identifier, {
      body: proposal
    });
  } else {
    return API.post(apiName, "/placenames/proposals", {
      body: proposal
    });
  }
};

const savePublicPlaceNameProposal = function(proposal) {
  return API.post(apiName, "/public/placenames/proposals", {
    body: proposal
  });
};

const saveDraftPlaceNameProposal = function(proposal) {
  if (proposal.identifier) {
    return API.put(apiName, "/placenames/proposals/" + proposal.identifier, {
      body: proposal
    });
  } else {
    return API.post(apiName, "/placenames/proposals", {
      body: proposal
    });
  }
};

const getAllPlaceNameProposals = function(params) {
  const data = {
    queryStringParameters: Object.assign({}, params, {
      includeTotal: true
    })
  };
  return API.get(apiName, "/placenames/proposals", data);
};

const getPlaceNameStatuses = function() {
  return API.get(apiName, "/placenames/statuses");
};

const getPlaceNameStatusesPublic = function() {
  return API.get(apiName, "/public/placenames/statuses");
};

const getPlaceNameEvaluation = function(params) {
  const data = {
    queryStringParameters: {
      placeName: params.placeName,
      lgaNames: JSON.stringify(params.lgaNames)
    }
  };
  return API.get(apiName, "/placenames/evaluation", data);
};

const getSinglePlaceNameProposal = function(id) {
  return API.get(apiName, `/placenames/proposals/${id}`);
};

const getSinglePlaceNameProposalByJiraId = function(id) {
  return API.get(
    apiName,
    `/placenames/proposals/${id}/get-proposal-by-jira-id`
  );
};

const getSinglePlaceNameProposalPublic = function(id, unique, token) {
  const data = {
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.get(apiName, `/public/placenames/proposals/${id}`, data);
};

const deletePlaceNameProposal = function(id) {
  return API.del(apiName, `/placenames/proposals/${id}`);
};

const getAllOrganisations = function() {
  return API.get(apiName, "/placenames/organisations");
};

const withdrawProposal = function(id) {
  return API.put(apiName, `/placenames/proposals/${id}/withdraw`);
};

const csvDownloadUrl = function(url) {
  return API.get(apiName, url);
};

export function getPnpGeonameStatusList() {
  return [
    "All Statuses",
    "PROPOSED",
    "CLARIFICATION",
    "BOARD CONSIDERATION",
    "TO BE ADVERTISED",
    "ADVERTISED",
    "ASSIGNED",
    "REVIEW OBJECTIONS",
    "OBJECTION CONSIDER",
    "ABANDONED",
    "DEFERRED",
    "MINISTER",
    "REJECTED",
    "CONCURRED",
    "ASSIGNED EDU",
    "ASSIGNED NPWS",
    "RECORDED",
    "WITHDRAWN",
    "DRAFTED"
  ];
}
const getAgendaDocument = async function(proposals, isSection10) {
  const data = {
    body: {
      proposals: proposals,
      isSection10: isSection10
    }
  };
  return await API.post(apiName, "/placenames/agenda", data);
};

const getSingleAgendaDocument = async function(identifier, isSection10, includingPI = false) {
  const data = {
    body: {
      isSection10: isSection10,
      includingPI: includingPI
    }
  };
  return await API.post(
    apiName,
    `/placenames/proposals/${identifier}/agenda`,
    data
  );
};

export function clarificationRequest(clarificationData) {
  const data = {
    body: {
      clarification_request: clarificationData.clarification_request,
      files: clarificationData.files
    }
  };
  return API.post(
    apiName,
    `/placenames/proposals/${clarificationData.proposalId}/clarification`,
    data
  );
}

const getClarification = function(proposal) {
  return API.get(apiName, `/placenames/proposals/${proposal}/clarification`);
};

export function clarificationResponse(clarificationResponse) {
  const data = {
    body: clarificationResponse
  };
  return API.put(
    apiName,
    `/placenames/proposals/${clarificationResponse.proposal_id}/clarification`,
    data
  );
}

const getClarificationPublic = function(proposal, unique, token) {
  const data = {
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.get(
    apiName,
    `/public/placenames/proposals/${proposal}/clarification`,
    data
  );
};
export function clarificationResponsePublic(
  clarificationResponse,
  unique,
  token
) {
  const data = {
    body: clarificationResponse,
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.put(
    apiName,
    `/public/placenames/proposals/${clarificationResponse.proposal_id}/clarification`,
    data
  );
}

//clarify Advert
const getClarifyAdvert = function(proposal) {
  return API.get(apiName, `/placenames/clarify-advert/${proposal}`);
};
export function clarifyAdvertResponse(clarificationResponse) {
  const data = {
    body: clarificationResponse
  };
  return API.post(apiName, "/placenames/clarify-advert", data);
}

const getClarifyAdvertPublic = function(proposal, unique, token) {
  const data = {
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.get(
    apiName,
    `/public/placenames/clarify-advert/${proposal}`,
    data
  );
};
export function clarifyAdvertResponsePublic(
  clarificationResponse,
  unique,
  token
) {
  const data = {
    body: clarificationResponse,
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.post(apiName, "/public/placenames/clarify-advert", data);
}

const searchPlacenameProposals = function(params) {
  const data = {
    queryStringParameters: params
  };
  return API.get(apiName, "/placenames/proposals", data);
};

const getAdvertisedProposals = function() {
  return API.get(apiName, "/public/placenames/advertised-proposals");
};

const getAdvertisedProposal = function(id) {
  return API.get(apiName, `/public/placenames/advertised-proposals/${id}`);
};

const getAdvertisedProposalComments = function(id) {
  return API.get(apiName, `/placenames/advertised-proposals/${id}/comments`);
};

const getAdvertisedProposalCommentsPublic = function(id, unique, token) {
  const data = {
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.get(
    apiName,
    `/public/placenames/advertised-proposals/${id}/comments`,
    data
  );
};

const submitAdvertisedProposalCommentResponsePublic = function(
  id,
  commentResponse,
  unique,
  token
) {
  const data = {
    body: {
      response: commentResponse
    },
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.put(apiName, `/public/placenames/comments/${id}/respond`, data);
};

const createAdvertisedProposalComment = function(payload) {
  const data = {
    body: payload
  };
  return API.post(
    apiName,
    "/public/placenames/advertised-proposals/comment",
    data
  );
};

const createAdvertisedProposalCommentForPublic = function(payload) {
  const data = {
    body: payload
  };
  return API.post(apiName, "/placenames/advertised-proposals/comment", data);
};

const updateAdvertisedProposalComment = function(payload) {
  const data = {
    body: payload,
    queryStringParameters: {
      unique: payload.unique,
      token: payload.token
    }
  };
  return API.put(
    apiName,
    `/public/placenames/comments/${payload.comment_identifier}`,
    data
  );
};

const submitAdvertisedProposalCommentResponse = function(payload) {
  const data = {
    body: {
      response: payload.commentResponse
    }
  };
  return API.put(
    apiName,
    `/placenames/comments/${payload.comment_identifier}`,
    data
  );
};

const getAdvertisedProposalForUpdate = function(payload) {
  const data = {
    body: payload,
    queryStringParameters: {
      unique: payload.unique,
      token: payload.token
    }
  };
  return API.get(
    apiName,
    `/public/placenames/comments/${payload.comment_identifier}`,
    data
  );
};

// ENDORSEMENT
const submitEndorsementInformation = function(payload) {
  const data = {
    body: payload
  };
  return API.post(apiName, "/placenames/endorsement", data);
};

const submitEndorsementInformationPublic = function(payload, unique, token) {
  const data = {
    body: payload,
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.post(apiName, "/public/placenames/endorsement", data);
};

// GEONAMES

const getGeonameById = function(id) {
  return API.get(apiName, `/placenames/geonames/${id}`);
};

const searchGeonames = function(params) {
  const data = {
    queryStringParameters: {
      limit: params.limit,
      page: params.page,
      includeTotal: true,
      geographical_name: params.geographical_name,
      status: JSON.stringify(params.status),
      designation: JSON.stringify(params.designation),
      lga: JSON.stringify(params.lga),
      map_cma: params.map_cma,
      parish: params.parish,
      dual_named:
        params.dual_named == "Yes"
          ? true
          : params.dual_named == "No"
          ? false
          : null
    }
  };
  return API.get(apiName, "/placenames/geonames", data);
};

const getGeonameByIdPublic = function(id) {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  
  if(pattern.test(id)) {
    return API.get(apiName, `/public/placenames/geonames/${id}`);
  } else {
    window.location.href = '/login';
  }
};

const searchGeonamesPublic = function(params) {
  const data = {
    queryStringParameters: {
      limit: params.limit,
      page: params.page,
      includeTotal: true,
      geographical_name: params.geographical_name,
      status: JSON.stringify(params.status),
      designation: JSON.stringify(params.designation),
      lga: JSON.stringify(params.lga),
      map_cma: params.map_cma,
      parish: params.parish,
      dual_named:
        params.dual_named == "Yes"
          ? true
          : params.dual_named == "No"
          ? false
          : null
    }
  };
  return API.get(apiName, "/public/placenames/geonames", data);
};

const updateGeonameById = function(params) {
  const data = {
    body: params.geoname
  };
  return API.put(apiName, `/placenames/geonames/${params.geonameId}`, data);
};

const getGeonameDesignations = function() {
  return API.get(apiName, "/public/placenames/designations");
};

const getLocalityList = function() {
  return API.get(apiName, "/public/placenames/suburbs");
};

const getLocalityListWithLgas = function() {
  return API.get(apiName, "/public/placenames/affectedlocalitieswithlga");
};

const transitionToReviewObjections = function(id) {
  return API.put(apiName, `/placenames/proposals/${id}/review-objections`);
};

const transitionToReviewObjectionsPublic = function(id, unique, token) {
  const data = {
    queryStringParameters: {
      unique: unique,
      token: token
    }
  };
  return API.put(
    apiName,
    `/public/placenames/proposals/${id}/review-objections`,
    data
  );
};

const createRedraftedProposal = function(id) {
  return API.put(apiName, `/placenames/proposals/${id}/clone`);
};

const fetchPlaceNameRecordsForReport = function(params) {
  const data = {
    queryStringParameters: params
  };
  return API.get(apiName, "/placenames/reports/proposals", data);
};

const downloadPlacenamesReportCsv = async function(params) {
  const data = {
    queryStringParameters: params
  };

  const res = await API.get(
    apiName,
    "/placenames/reports/proposals/download",
    data
  );

  return res;
};

const fetchPlaceNameFeatureList = function(featureType) {
  return API.get(apiName, `/placenames/reports/features/${featureType}`);
};

const fetchPlaceNameLGAFeatureList = function() {
  return API.get(apiName, "public/placenames/lgas");
};

const fetchPlaceNameGazettalLGAFeatureList = function() {
  return API.get(apiName, "public/placenames/lgas-gazettal");
};

const fetchOrganisationsForReport = function() {
  return API.get(apiName, "/placenames/reports/organisations");
};

const fetchUsersForReport = function() {
  return API.get(apiName, "/placenames/reports/users");
};

export async function submitOfficialMap(officialMap) {
  const data = {
    body: officialMap
  };
  return API.post(apiName, "/placenames/officialmap", data);
}

export async function getOfficialMaps(id) {
  const data = {
    queryStringParameters: {
      document_type: "official_map"
    }
  };
  return API.get(
    apiName,
    `/placenames/proposals/${id}/reference-documents`,
    data
  );
}
export async function getGazetteDocs(id) {
  const data = {
    queryStringParameters: {
      document_type: "gazette"
    }
  };
  return API.get(
    apiName,
    `/placenames/proposals/${id}/reference-documents`,
    data
  );
}
export async function getAdvertDocs(id) {
  const data = {
    queryStringParameters: {
      document_type: "advert"
    }
  };
  return API.get(
    apiName,
    `/placenames/proposals/${id}/reference-documents`,
    data
  );
}
export async function getMinisterNote(id) {
  const data = {
    queryStringParameters: {
      document_type: "minister_briefing_note"
    }
  };
  return API.get(
    apiName,
    `/placenames/proposals/${id}/reference-documents`,
    data
  );
}

export async function removeFileHandler(fileId, jira_id) {
  const data = {
    queryStringParameters: {
      jira_id: jira_id
    }
  };
  return API.del(apiName, `/placenames/reference-documents/${fileId}`, data);
}

const saveGeonameHistoricalData = function(params) {
  const data = {
    body: {
      ...params.payload
    }
  };
  return API.post(
    apiName,
    `/public/placenames/geonames/${params.id}/information`,
    data
  );
};

const downloadGeonameSearchResults = function(params) {
  const data = {
    queryStringParameters: {
      geographical_name: params.geographical_name,
      status: JSON.stringify(params.status),
      designation: JSON.stringify(params.designation),
      lga: JSON.stringify(params.lga),
      map_cma: params.map_cma,
      parish: params.parish,
      dual_named: params.dual_named
    }
  };
  return API.get(apiName, "/public/placenames/geonames/download", data);
};

const getGeonameHistoryById = function(id, order) {
  return API.get(
    apiName,
    `/placenames/geonames/${id}/history?orderDirection=${order}`
  );
};

const getProposalAuditTrailById = function(id) {
  return API.get(apiName, `/placenames/proposals/${id}/history`);
};

const getGeonameLegacyAdvertisementsById = function(id) {
  return API.get(apiName, `/placenames/geonames/${id}/legacy-advertisements`);
};

const getGeonameLegacyAdvertisementCommentsByGovtAdId = function(
  geonameIdentifier,
  govtAdId
) {
  return API.get(
    apiName,
    `/placenames/geonames/${geonameIdentifier}/legacy-advertisements/${govtAdId}/comments`,
    {
      headers: {
        accept: "application/pdf"
      },
      responseType: "arraybuffer"
    }
  );
};

const DOWNLOAD_GEONAME_RECORDS_URL = `${__AWS_API_URI__}/public/placenames/geonames/download`;
const AUTH_DOWNLOAD_GEONAME_RECORDS_URL = `${__AWS_API_URI__}/placenames/geonames-download/csv`;

const DOWNLOAD_GEONAME_RECORDS_RESULTS_URL = function(downloadUrl, params) {
  let url = `${downloadUrl}?`;

  let qs = [];
  if (params.geographical_name) {
    qs.push(`geographical_name=${params.geographical_name}`);
  }

  if (params.status && params.status.length) {
    qs.push(`status=${JSON.stringify(params.status)}`);
  }

  if (params.designation && params.designation.length) {
    qs.push(`designation=${JSON.stringify(params.designation)}`);
  }

  if (params.lga && params.lga.length) {
    qs.push(`lga=${JSON.stringify(params.lga)}`);
  }

  if (params.map_cma) {
    qs.push(`map_cma=${params.map_cma}`);
  }

  if (params.parish) {
    qs.push(`parish=${params.parish}`);
  }

  if (params.dual_named) {
    qs.push(
      `dual_named=${
        params.dual_named == "Yes"
          ? true
          : params.dual_named == "No"
          ? false
          : null
      }`
    );
  }

  return `${url}${qs.reduce((query, value, index) => {
    index === 0 ? (query += value) : (query += `&${value}`);
    return query;
  }, "")}`;
};

const linkPlacenames = function(source, target) {
  return API.put(apiName, "/placenames/geonames/link", {
    body: {
      source: source.identifier,
      target: target.identifier
    }
  });
};

const unlinkPlacename = function(source, target) {
  return API.put(apiName, "/placenames/geonames/unlink", {
    body: {
      source: source,
      target: target
    }
  });
};

const querySpatialPortalForLGA = function() {
  return API.post(apiName, "placenames/lgas/get-lga-from-spatial");
};

const getLGASpatialQueryCronStatus = function() {
  return API.get(apiName, "placenames/lgas/get-update-lambda-status");
};

const toggleLGASpatialQueryCron = function(status) {
  return API.put(apiName, `placenames/lgas/set-update-lambda-status/${status}`);
};

const updateLGAwithSpatialLGA = function() {
  return API.put(apiName, "placenames/lgas/update-lgas");
};

const reassignPnp = function(params) {
  const data = {
    body: {
      proposalIdentifier: params.proposalIdentifier,
      userFirstName: params.userFirstName,
      userLastName: params.userLastName,
      userEmail: params.userEmail,
      userIdentifier: params.userIdentifier,
      fromUserFirstName: params.fromUserFirstName,
      fromUserLastName: params.fromUserLastName
    }
  };

  return API.put(apiName, "placenames/proposal-reassignment", data);
}

const getBannerContent = async function() {
  return await API.get(apiName, "/public/public-banner/get-banner-content-from-s3");
}

const encryptPersonalInfo = async function(payload) {
  const data = {
    body: {
      proposalInfo: payload.proposalInfo
    }
  };

  return API.post(apiName, "placenames/encrypt-personal-info", data);
};

const decryptPersonalInfo = async function(payload) {
  const data = {
    body: {
      proposalInfo: payload.proposalInfo,
      encryptedPI: payload.encryptedPI
    }
  };

  return API.post(apiName, "placenames/decrypt-personal-info", data);
};

const addResponseToObjectionEntryinReferenceDocument = async function(payload) {
  const data = {
    body: {
      files: payload.files,
      geoname_identifier: payload.geoname_identifier,
      document_type: payload.document_type
    }
  };

  return API.post(apiName, "placenames/add-response-to-objection-entry-in-reference-document", data);
};

export async function deleteResponseToObjectionEntry(payload) {
  const data = {
    body: {
      file: payload.file,
      geoname_identifier: payload.geoname_identifier
    }
  };
  
  return API.del(apiName, "/placenames/delete-response-to-objection-entry-in-reference-document", data);
}

export default {
  linkPlacenames,
  unlinkPlacename,
  savePlaceNameProposal,
  saveDraftPlaceNameProposal,
  getAllPlaceNameProposals,
  getAllOrganisations,
  searchPlacenameProposals,
  getAgendaDocument,
  getSingleAgendaDocument,
  getSinglePlaceNameProposal,
  getSinglePlaceNameProposalByJiraId,
  getSinglePlaceNameProposalPublic,
  deletePlaceNameProposal,
  withdrawProposal,
  getPlaceNameEvaluation,
  getClarification,
  getClarificationPublic,
  getClarifyAdvert,
  getClarifyAdvertPublic,
  getPlaceNameStatuses,
  getAdvertisedProposals,
  getAdvertisedProposal,
  createAdvertisedProposalComment,
  updateAdvertisedProposalComment,
  getAdvertisedProposalForUpdate,
  createAdvertisedProposalCommentForPublic,
  getAdvertisedProposalComments,
  submitAdvertisedProposalCommentResponse,
  savePublicPlaceNameProposal,
  getGeonameById,
  searchGeonames,
  updateGeonameById,
  getGeonameDesignations,
  getLocalityList,
  getLocalityListWithLgas,
  getGeonameByIdPublic,
  searchGeonamesPublic,
  getPlaceNameStatusesPublic,
  transitionToReviewObjections,
  transitionToReviewObjectionsPublic,
  getAdvertisedProposalCommentsPublic,
  submitAdvertisedProposalCommentResponsePublic,
  downloadGeonameSearchResults,
  DOWNLOAD_GEONAME_RECORDS_URL,
  DOWNLOAD_GEONAME_RECORDS_RESULTS_URL,
  AUTH_DOWNLOAD_GEONAME_RECORDS_URL,
  createRedraftedProposal,
  saveGeonameHistoricalData,
  fetchPlaceNameRecordsForReport,
  fetchPlaceNameFeatureList,
  fetchPlaceNameLGAFeatureList,
  fetchPlaceNameGazettalLGAFeatureList,
  downloadPlacenamesReportCsv,
  fetchOrganisationsForReport,
  fetchUsersForReport,
  getGeonameHistoryById,
  getProposalAuditTrailById,
  getGeonameLegacyAdvertisementsById,
  getGeonameLegacyAdvertisementCommentsByGovtAdId,
  csvDownloadUrl,
  submitEndorsementInformation,
  submitEndorsementInformationPublic,
  querySpatialPortalForLGA,
  getLGASpatialQueryCronStatus,
  toggleLGASpatialQueryCron,
  updateLGAwithSpatialLGA,
  reassignPnp,
  getBannerContent,
  encryptPersonalInfo,
  decryptPersonalInfo,
  addResponseToObjectionEntryinReferenceDocument,
  deleteResponseToObjectionEntry
};
