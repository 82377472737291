<template>
  <showLoading isLoading="loadingRnaList">
    <template v-slot:component>
      <div class="flex flex-col flex-grow" data-cypress="user-list">
        <div
          v-for="(rna, index) in rnas"
          v-bind:key="index"
          class="flex flex-row bg-white text-black rounded-lg mt-4 overflow-hidden"
        >
          <RnaListItem :rna="rna">
            <slot :rna="rna"></slot>
          </RnaListItem>
        </div>
      </div>
    </template>
  </showLoading>
</template>

<script>
import RnaListItem from "./rnaListItem";
import showLoading from "../elements/showLoading";

export default {
  name: "RnaList",
  components: {
    showLoading,
    RnaListItem
  },
  props: ["rnas"]
};
</script>

<style scoped>
.actions {
  color: #002664;
}
#userSVG {
  fill: grey;
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}

.dividing-line-v-2 {
  border-left-width: 1px;
}

.wait-container {
  padding-top: 0;
}
</style>
