var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "min-h-screen flex flex-grow flex-col",
      attrs: { id: "app" },
    },
    [
      _c("router-view"),
      _c("notifications", {
        attrs: { group: "toast", position: "bottom right" },
      }),
      _vm.bannerData.displayBanner
        ? _c("MaintenanceBanner", {
            attrs: { slot: "body", bannerData: _vm.bannerData },
            slot: "body",
          })
        : _vm._e(),
      _c(
        "notifications",
        {
          attrs: {
            group: "newContentNotification",
            position: "bottom center",
            width: "100%",
          },
        },
        [
          _c("NewContentNotification", {
            attrs: { slot: "body" },
            slot: "body",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }