var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex text-sm", class: _vm.classNames }, [
    _c("label", { staticClass: "pt-3 pr-3 items-center content-center flex" }, [
      _c(
        "div",
        {
          staticClass: "cont w-6 h-6 mr-3 flex justify-center absolute",
          attrs: { tabindex: "0" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$attrs.value,
                expression: "$attrs.value",
              },
            ],
            staticClass: "hidden",
            attrs: {
              type: "radio",
              name: "radios",
              disabled: _vm.$attrs.disabled,
            },
            domProps: {
              value: _vm.val,
              checked: _vm._q(_vm.$attrs.value, _vm.val),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("input", _vm.val)
              },
              change: function ($event) {
                return _vm.$set(_vm.$attrs, "value", _vm.val)
              },
            },
          }),
          _c("div", { staticClass: "inner w-3 h-3 self-center" }),
        ]
      ),
      _c("div", { staticClass: "ml-8" }, [_vm._v(_vm._s(_vm.label))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }