var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Erratum Gazette Notice Created",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "mb-6" }, [
          _vm._v("An erratum gazette notice was created."),
        ]),
        _c("table", { staticClass: "table-fixed w-full" }, [
          _c("tbody", [
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [
                _vm._v("Road Name:"),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.entryData.roadName.to))]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [
                _vm._v("Road Type:"),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.entryData.roadType.to))]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [_vm._v("Extent:")]),
              _c("td", [_vm._v(_vm._s(_vm.entryData.extent.to))]),
            ]),
            _vm.entryData.locality && _vm.entryData.locality.to
              ? _c("tr", [
                  _c("td", { staticClass: "font-bold w-1/4" }, [
                    _vm._v("Locality(s):"),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.transformLocalities(_vm.entryData.locality.to))
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [
                _vm._v("Gazette Number:"),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.entryData.gazetteNumber.to))]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [
                _vm._v("Publication Date:"),
              ]),
              _c("td", [
                _vm._v(_vm._s(_vm.format(_vm.entryData.publishedDate.to))),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "font-bold w-1/4" }, [
                _vm._v("Folio Number:"),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.entryData.folioNumber.to))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }