var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center",
    },
    [
      _c("img", {
        staticClass: "absolute h-12 logoPos",
        attrs: {
          src: require("../../../../assets/Logos/GNB logo_White_RGB.png"),
        },
      }),
      _c(
        "div",
        { staticClass: "w-2/3 bg-white mx-auto my-3 z-10 rounded" },
        [
          _c("showLoading", {
            attrs: { isLoading: "fetchingSubmittedProposalWithComment" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    _vm.proposal
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "w-full bg-white text-black text-center rounded-lg p-14",
                          },
                          [
                            _c("img", {
                              staticClass: "gnb-logo mt-8",
                              attrs: {
                                src: require("../../../../assets/Logos/gnb.jpg"),
                              },
                            }),
                            _c(
                              "h3",
                              { staticClass: "pb-3 mb-4 w-full text-title" },
                              [
                                _vm._v(
                                  " Your comment on " +
                                    _vm._s(_vm.proposal.geographical_name) +
                                    " has been submitted "
                                ),
                              ]
                            ),
                            _c("p", [
                              _vm._v(
                                " Your comments will be considered in the development of the proposal. Once finalised, you can view the outcome of the proposal on the GNB website. "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex justify-center mt-8 mb-8" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "button-blue-hollow mr-3",
                                    attrs: {
                                      "data-cypress": "return",
                                      disabled: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.backToProposals()
                                      },
                                    },
                                  },
                                  [_vm._v("Return to Current Proposals Page ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }