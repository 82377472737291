var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Gazette Notice Rejection",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v(" Gazette notice rejected. "),
        ]),
        _c("p", { staticClass: "mb-2" }, [
          _c("strong", [_vm._v(_vm._s(_vm.entryData.comment.to))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }