var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ol",
      { staticClass: "checklist pl-0" },
      [
        _c("div", { staticClass: "pb-6 dividing-line-h" }, [
          _c("p", { staticClass: "text-normal-bold mb-2" }, [
            _vm._v(
              " How many lots or owners/occupiers does the proposed boundary amendment affect? "
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-row text-normal" },
            [
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6 mt-3 mb-1",
                  attrs: {
                    name: "affected_lot_owners_occupiers-<10",
                    "data-cypress": "affected_lot_owners_occupiers",
                    checkedValue: "<10",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.affected_lot_owners_occupiers,
                    callback: function ($$v) {
                      _vm.affected_lot_owners_occupiers = $$v
                    },
                    expression: "affected_lot_owners_occupiers",
                  },
                },
                [_vm._v("Less than 10")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6 mt-3 mb-1",
                  attrs: {
                    "data-cypress": "affected_lot_owners_occupiers",
                    name: "affected_lot_owners_occupiers-10-20",
                    checkedValue: "10-20",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.affected_lot_owners_occupiers,
                    callback: function ($$v) {
                      _vm.affected_lot_owners_occupiers = $$v
                    },
                    expression: "affected_lot_owners_occupiers",
                  },
                },
                [_vm._v("10 - 20")]
              ),
              _c(
                "RadioButton",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "flex-0 mr-6 mt-3 mb-1",
                  attrs: {
                    "data-cypress": "affected_lot_owners_occupiers",
                    name: "affected_lot_owners_occupiers->20",
                    checkedValue: ">20",
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.affected_lot_owners_occupiers,
                    callback: function ($$v) {
                      _vm.affected_lot_owners_occupiers = $$v
                    },
                    expression: "affected_lot_owners_occupiers",
                  },
                },
                [_vm._v("More than 20")]
              ),
            ],
            1
          ),
          _vm.errors.first("Road type check")
            ? _c(
                "div",
                { staticClass: "flex items-center absolute" },
                [
                  _c("errorIcon"),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-red-error text-normal-bold" },
                    [_vm._v(" The type check field is required. ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.affected_lot_owners_occupiers === `>20`
          ? _c(
              "li",
              {
                staticClass:
                  "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "float right flex-row flex" },
                  [
                    _c(
                      "RadioButton",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "flex-0 mr-6",
                        attrs: {
                          "data-cypress":
                            "proposed_locality_meets_size_limits-no",
                          name: "proposed_locality_meets_size_limits-no",
                          checkedValue: false,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.proposed_locality_meets_size_limits,
                          callback: function ($$v) {
                            _vm.proposed_locality_meets_size_limits = $$v
                          },
                          expression: "proposed_locality_meets_size_limits",
                        },
                      },
                      [_vm._v("No")]
                    ),
                    _c(
                      "RadioButton",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "flex-0 mr-6",
                        attrs: {
                          name: "proposed_locality_meets_size_limits-yes",
                          "data-cypress":
                            "proposed_locality_meets_size_limits-yes",
                          checkedValue: true,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.proposed_locality_meets_size_limits,
                          callback: function ($$v) {
                            _vm.proposed_locality_meets_size_limits = $$v
                          },
                          expression: "proposed_locality_meets_size_limits",
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.map_or_gis_file_provided === false
          ? _c("MessagePanel", {
              attrs: {
                error: "",
                msg: "A map or GIS file is needed before this proposal can be submitted",
              },
            })
          : _vm._e(),
        _vm.affected_lot_owners_occupiers === `>20`
          ? _c(
              "li",
              {
                staticClass:
                  "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
              },
              [
                _c("p", { staticClass: "text-normal w-5/6" }, [
                  _vm._v(
                    " Has a map or GIS file been provided (ESRI Shape file (.shp) preferred)? "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "float right flex-row flex" },
                  [
                    _c(
                      "RadioButton",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "flex-0 mr-6",
                        attrs: {
                          "data-cypress": "map_or_gis_file_provided-no",
                          name: "map_or_gis_file_provided-no",
                          checkedValue: false,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.map_or_gis_file_provided,
                          callback: function ($$v) {
                            _vm.map_or_gis_file_provided = $$v
                          },
                          expression: "map_or_gis_file_provided",
                        },
                      },
                      [_vm._v("No")]
                    ),
                    _c(
                      "RadioButton",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "flex-0 mr-6",
                        attrs: {
                          name: "map_or_gis_file_provided-yes",
                          "data-cypress": "map_or_gis_file_provided-yes",
                          checkedValue: true,
                          disabled: _vm.readOnly,
                        },
                        model: {
                          value: _vm.map_or_gis_file_provided,
                          callback: function ($$v) {
                            _vm.map_or_gis_file_provided = $$v
                          },
                          expression: "map_or_gis_file_provided",
                        },
                      },
                      [_vm._v("Yes")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.proposed_locality_multiple_lgas === true
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "This response suggests that this proposal is likely to be deemed unacceptable",
              },
            })
          : _vm._e(),
        _vm.affected_lot_owners_occupiers === `>20`
          ? _c("li", { staticClass: "flex flex-row text-normal pb-6 pt-6" }, [
              _c("p", { staticClass: "text-normal w-5/6" }, [
                _vm._v(
                  " Does the address locality cover more than one LGA? If so, please indicate whether each affected Local Government Authority has been consulted? "
                ),
              ]),
              _c(
                "div",
                { staticClass: "float right flex-row flex" },
                [
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6",
                      attrs: {
                        "data-cypress": "proposed_locality_multiple_lgas-no",
                        name: "proposed_locality_multiple_lgas-no",
                        checkedValue: false,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.proposed_locality_multiple_lgas,
                        callback: function ($$v) {
                          _vm.proposed_locality_multiple_lgas = $$v
                        },
                        expression: "proposed_locality_multiple_lgas",
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "RadioButton",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      staticClass: "flex-0 mr-6",
                      attrs: {
                        name: "proposed_locality_multiple_lgas-yes",
                        "data-cypress": "proposed_locality_multiple_lgas-yes",
                        checkedValue: true,
                        disabled: _vm.readOnly,
                      },
                      model: {
                        value: _vm.proposed_locality_multiple_lgas,
                        callback: function ($$v) {
                          _vm.proposed_locality_multiple_lgas = $$v
                        },
                        expression: "proposed_locality_multiple_lgas",
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.affected_lot_owners_occupiers === `>20`
          ? _c(
              "div",
              { staticClass: "flex flex-row text-normal dividing-line-h" },
              [
                _vm.proposed_locality_multiple_lgas
                  ? _c(
                      "div",
                      { staticClass: "flex flex-grow" },
                      [
                        _c("AffectedLgaTable", {
                          attrs: {
                            stateKey: `boundary_amendment`,
                            readOnly: _vm.readOnly,
                            lgas: _vm.lgas,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.public_consultation_by_council === false &&
        _vm.affected_lot_owners_occupiers === `>20`
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "This response suggests that this proposal is likely to be deemed unacceptable",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(
                " Has a public consultation been carried out by the Council? "
              ),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "public_consultation_by_council-no",
                      name: "public_consultation_by_council-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.public_consultation_by_council,
                      callback: function ($$v) {
                        _vm.public_consultation_by_council = $$v
                      },
                      expression: "public_consultation_by_council",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "public_consultation_by_council-yes",
                      "data-cypress": "public_consultation_by_council-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.public_consultation_by_council,
                      callback: function ($$v) {
                        _vm.public_consultation_by_council = $$v
                      },
                      expression: "public_consultation_by_council",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.public_consultation_by_council
          ? _c(
              "div",
              { staticClass: "text-normal pb-6 dividing-line-h" },
              [
                _c("uploadScoped", {
                  staticClass: "mt-4",
                  attrs: {
                    "data-cypress": "upload-public_consultation",
                    label: "Attach supporting material for public consultation",
                    important: false,
                    files: _vm.public_consultation_reference_document,
                    drafted: true,
                    stateKey: "boundary_amendment",
                    stateProp: "public_consultation_reference_document",
                    uploadFile: (params) =>
                      _vm.uploadFile(
                        Object.assign({}, params, { isChecklistFile: true })
                      ),
                    disabled: _vm.readOnly,
                  },
                  on: {
                    deleteFile: (fileIndex) =>
                      _vm.deleteUploadedChecklistFile({
                        key: "boundary_amendment",
                        prop: "public_consultation_reference_document",
                        index: fileIndex,
                      }),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(
                " Do all affected owners/occupiers agree with the proposed amendment? "
              ),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress": "affected_lot_owners_occupiers_agree-no",
                      name: "affected_lot_owners_occupiers_agree-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.affected_lot_owners_occupiers_agree,
                      callback: function ($$v) {
                        _vm.affected_lot_owners_occupiers_agree = $$v
                      },
                      expression: "affected_lot_owners_occupiers_agree",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "affected_lot_owners_occupiers_agree-yes",
                      "data-cypress": "affected_lot_owners_occupiers_agree-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.affected_lot_owners_occupiers_agree,
                      callback: function ($$v) {
                        _vm.affected_lot_owners_occupiers_agree = $$v
                      },
                      expression: "affected_lot_owners_occupiers_agree",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(
                " Do the address locality boundaries follow clear and distinguishable lines such as rivers, railways, major road centerlines? "
              ),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress":
                        "proposed_locality_follows_clear_lines-no",
                      name: "proposed_locality_follows_clear_lines-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_locality_follows_clear_lines,
                      callback: function ($$v) {
                        _vm.proposed_locality_follows_clear_lines = $$v
                      },
                      expression: "proposed_locality_follows_clear_lines",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "proposed_locality_follows_clear_lines-yes",
                      "data-cypress":
                        "proposed_locality_follows_clear_lines-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_locality_follows_clear_lines,
                      callback: function ($$v) {
                        _vm.proposed_locality_follows_clear_lines = $$v
                      },
                      expression: "proposed_locality_follows_clear_lines",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.proposed_locality_boundaries_bisect_properties === true
          ? _c("MessagePanel", {
              attrs: {
                warning: "",
                msg: "This response suggests that this proposal is likely to be deemed unacceptable",
              },
            })
          : _vm._e(),
        _c(
          "li",
          {
            staticClass: "flex flex-row text-normal pb-6 pt-6 dividing-line-h",
          },
          [
            _c("p", { staticClass: "text-normal w-5/6" }, [
              _vm._v(" Do the address locality boundaries bisect properties? "),
            ]),
            _c(
              "div",
              { staticClass: "float right flex-row flex" },
              [
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      "data-cypress":
                        "proposed_locality_boundaries_bisect_properties-no",
                      name: "proposed_locality_boundaries_bisect_properties-no",
                      checkedValue: false,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_locality_boundaries_bisect_properties,
                      callback: function ($$v) {
                        _vm.proposed_locality_boundaries_bisect_properties = $$v
                      },
                      expression:
                        "proposed_locality_boundaries_bisect_properties",
                    },
                  },
                  [_vm._v("No")]
                ),
                _c(
                  "RadioButton",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "flex-0 mr-6",
                    attrs: {
                      name: "proposed_locality_boundaries_bisect_properties-yes",
                      "data-cypress":
                        "proposed_locality_boundaries_bisect_properties-yes",
                      checkedValue: true,
                      disabled: _vm.readOnly,
                    },
                    model: {
                      value: _vm.proposed_locality_boundaries_bisect_properties,
                      callback: function ($$v) {
                        _vm.proposed_locality_boundaries_bisect_properties = $$v
                      },
                      expression:
                        "proposed_locality_boundaries_bisect_properties",
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-5/6" }, [
      _vm._v(
        " For an urban area, does the new address locality meet the size limits as described in "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0020/223346/NSW_Address_Policy_and_User_Manual.pdf#page=105",
            target: "_blank",
          },
        },
        [_vm._v("Chapter 6.8 of the NSW Addressing User Manual")]
      ),
      _vm._v("? "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }