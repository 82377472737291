"use strict";

import API from "@/helpers/api";
const API_NAME = "dfsiApplicationAPI";
const BASE_PATH = "/organisations";

// push new organisation to database
export function saveOrganisationToDb(organisationData) {
  const payload = {
    body: organisationData
  };

  return API.post(API_NAME, BASE_PATH, payload);
}

export function updateOrganisation(organisationData) {
  const payload = {
    body: organisationData
  };

  return API.put(
    API_NAME,
    `${BASE_PATH}/${organisationData.identifier}`,
    payload
  );
}

export function getOrganisationList() {
  return API.get(API_NAME, BASE_PATH);
}
export function getOrganisations(queryStringParameters) {
  return API.get(API_NAME, BASE_PATH, {
    queryStringParameters
  });
}

export function getEntityList() {
  return API.get(API_NAME, "/entities");
}

export function deleteOrganisation(id) {
  return API.del(API_NAME, `${BASE_PATH}/${id}`);
}

export function getOrganisation(id) {
  return API.get(API_NAME, `${BASE_PATH}/${id}`);
}
export function updateUserOrganisation(details) {
  const payload = {
    body: details
  };
  return API.put(API_NAME, `${BASE_PATH}/user/${details.user_id}`, payload);
}
export function getUsersWithoutOrganisation() {
  return API.get(API_NAME, "/users?isPublic=true");
}

export function getDefaultOrganisationLga(orgIdentifier) {
  return API.get(API_NAME, `${BASE_PATH}/${orgIdentifier}/default-lga`);
}
