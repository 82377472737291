var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container" }, [
    _c("section", { staticClass: "pt-1 justify-center w-full block" }, [
      _c("div", { staticClass: "flex justify-between" }),
      _c("h2", { staticClass: "text-black ml-4 self-center text-title" }, [
        _vm._v(" Submit Proposal Clarification Request "),
      ]),
      _c(
        "div",
        {
          staticClass:
            "max-w-6xl bg-white text-black rounded-lg pl-8 pt-3 pb-3 mt-4 justify-center flex flex-wrap",
        },
        [
          _vm._m(0),
          _vm.road
            ? _c("p", { staticClass: "text-title" }, [
                _vm._v(
                  " Your proposal clarification for " +
                    _vm._s(_vm.road.roadname) +
                    " " +
                    _vm._s(_vm.road.road_type) +
                    " has been submitted. "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex pt-4 text-center pb-10" },
        [
          _vm.road
            ? _c(
                "router-link",
                {
                  staticClass: "w-1/3 button-blue-hollow",
                  attrs: { to: { name: "proposals" } },
                },
                [_vm._v("Go to Proposals Dashboard")]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "ml-auto w-1/3 button-blue",
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "roadNameProposalBucket",
                    params: { proposalId: _vm.road.proposal_id },
                  })
                },
              },
            },
            [_vm._v(" Return to Road Name Proposal ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full justify-center flex" }, [
      _c("img", {
        staticClass: "h-52 my-4",
        attrs: { src: require("@/assets/Images/Icon_Gazettal.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }