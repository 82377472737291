<template>
  <div class="base-container text-black">
    <h1 class=" text-title mt-6 pb-2 self-center">
      Road Name Similarity Threshold
    </h1>
    <div class="w-full mx-auto bg-white rounded-lg text-normal">
      <div class="w-9/10 mx-auto">
        <p class="w-full mt-3 pt-12 mb-8 text-normal  font-semibold">
          Current Threshold
        </p>
        <p class="mb-2"></p>
        <input
          type="text"
          name="orderNumberTxt"
          data-cypress="ordernumber"
          placeholder="0123456789"
          v-model="orderNumber"
          v-validate="'digits'"
          id
          class="w-full h-12 mb-20 pl-6 bg-grey-input"
        />
      </div>
    </div>
    <div
      class="button-blue w-1/4 mt-6 ml-auto justify-center items-center flex cursor-pointer whitespace-no-wrap"
      data-cypress="save"
      @click="updateOrderNumber"
    >
      Save Changes
    </div>
  </div>
</template>

<script>
import {
  getGazettalOrderNumber,
  updateGazettalOrderNumber
} from "../../helpers/gazettalCRUD";
export default {
  data() {
    return {
      orderNumber: ""
    };
  },
  async mounted() {
    this.orderNumber = await getGazettalOrderNumber();
    this.orderNumber = this.orderNumber.purchase_order_no;
  },
  methods: {
    async updateOrderNumber() {
      try {
        const res = await updateGazettalOrderNumber(this.orderNumber);
        if (res) {
          this.$notify({
            group: "toast",
            type: "Success",
            title: "Similarity Threshold Updated",
            text: `The Road Name Similarity Threshold has been set to ${this.orderNumber}`
          });
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "A database error has occured.",
          text: `An error has occured during the purchase order number update. ${error}`
        });
      }
    }
  }
};
</script>
