var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "base-container" }, [
      _c(
        "section",
        { staticClass: "pt-1 justify-center w-full block" },
        [
          _c(
            "div",
            { staticClass: "flex text-normal self-center justify-center mb-4" },
            [_c("Tree", { staticClass: "tree-img" })],
            1
          ),
          _c(
            "span",
            {
              staticClass:
                "flex font-bold text-3xl text-black self-center justify-center mb-4",
            },
            [_vm._v(" Manage Place Name Proposals ")]
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "withdraw-proposal",
                width: "33%",
                height: "auto",
                classes: "rounded-lg bg-white py-16 px-16 text-center",
              },
            },
            [
              _c("h3", { staticClass: "mb-4" }, [_vm._v("Confirm Withdrawal")]),
              _c("p", { staticClass: "text-black mb-4" }, [
                _vm._v(" Are you sure you wish to withdraw this proposal? "),
              ]),
              _c(
                "ButtonWithSpinner",
                {
                  staticClass: "button-red w-2/6 mt-4 ml-auto",
                  attrs: {
                    isSpinning: _vm.$wait.is("withdrawing proposal"),
                    disabled: _vm.$wait.is("withdrawing proposal"),
                  },
                  on: { click: _vm.withdrawConfirm },
                },
                [_vm._v("Withdraw")]
              ),
              _c(
                "ButtonWithSpinner",
                {
                  staticClass: "button-blue-hollow w-2/6 mt-4 ml-4",
                  attrs: { disabled: _vm.$wait.is("withdrawing proposal") },
                  on: { click: _vm.withdrawCancel },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "redraft-proposal",
                width: "50%",
                height: "auto",
                classes: "rounded-lg bg-white py-16 px-16 text-center",
              },
            },
            [
              _c("h3", { staticClass: "mb-4" }, [_vm._v("Re-draft Proposal")]),
              _c("p", { staticClass: "text-black" }, [
                _vm._v(
                  " Are you sure that you want to Re-draft this proposal? Doing so will retire the current record and it’s status and create a new duplicate record in a draft state. You will be able to modify the proposal before submission. "
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-row justify-center w-full" },
                [
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-red mt-4 mx-4",
                      attrs: { disabled: _vm.$wait.is("loading Proposals") },
                      on: { click: _vm.redraftCancel },
                    },
                    [_vm._v(" Cancel Re-draft ")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue mt-4 mx-4",
                      attrs: {
                        isSpinning: _vm.$wait.is("loading Proposals"),
                        disabled: _vm.$wait.is("loading Proposals"),
                      },
                      on: { click: _vm.redraftConfirm },
                    },
                    [_vm._v(" Re-draft Proposal ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "modal",
            {
              attrs: {
                name: "delete-proposal",
                width: "50%",
                height: "auto",
                classes: "rounded-lg bg-white py-16 px-16 text-center",
              },
            },
            [
              _c("h3", { staticClass: "mb-4" }, [_vm._v("Delete Proposal")]),
              _c("p", { staticClass: "text-black" }, [
                _vm._v(" Are you sure that you want to delete this proposal? "),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-row justify-center w-full" },
                [
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-red mt-4 mx-4",
                      attrs: { disabled: _vm.$wait.is("deleting proposal") },
                      on: { click: _vm.deleteCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "ButtonWithSpinner",
                    {
                      staticClass: "button-blue mt-4 mx-4",
                      attrs: {
                        isSpinning: _vm.$wait.is("deleting proposal"),
                        disabled: _vm.$wait.is("deleting proposal"),
                      },
                      on: { click: _vm.deleteConfirm },
                    },
                    [_vm._v(" Delete Proposal ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.isAdmin && !_vm.isPreparingAgenda()
            ? _c("div", { staticClass: "w-full flex" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "button-blue mt-4 mb-6 mx-4 jest-view-section-8 flex-1",
                    on: {
                      click: function ($event) {
                        return _vm.getSection8()
                      },
                    },
                  },
                  [_vm._v(" View Proposals for Board Consideration ")]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "button-blue mt-4 mb-6 mx-4 jest-view-section-10 flex-1",
                    on: {
                      click: function ($event) {
                        return _vm.getSection10()
                      },
                    },
                  },
                  [_vm._v(" View Proposals for GNB Reconsideration ")]
                ),
              ])
            : _vm._e(),
          _c("showLoading", {
            attrs: { isLoading: "loading Proposals" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    !_vm.isPreparingAgenda()
                      ? _c("div", { staticClass: "flex items-center mt-4" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.pnpTypeFilter,
                                  expression: "pnpTypeFilter",
                                },
                              ],
                              staticClass:
                                "w-1/2 input-area-appear bg-white mr-4",
                              attrs: { name: "pnp_type", id: "pnp_type" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.pnpTypeFilter = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.filterTypes,
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    hidden: "",
                                    selected: "selected",
                                  },
                                },
                                [_vm._v("Any Proposal Type")]
                              ),
                              _vm._l(
                                _vm.GEONAME_TYPE_LABELS,
                                function (item, key) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: { value: item.type },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.pnpGeonameStatusFilter,
                                  expression: "pnpGeonameStatusFilter",
                                },
                              ],
                              staticClass:
                                "w-1/2 input-area-appear bg-white mr-0",
                              attrs: {
                                name: "display_status",
                                id: "display_status",
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.pnpGeonameStatusFilter = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.filterGeonameStatus,
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    hidden: "",
                                    selected: "selected",
                                  },
                                },
                                [_vm._v("All Statuses")]
                              ),
                              _vm._l(_vm.statusList, function (item, key) {
                                return _c(
                                  "option",
                                  { key: key, domProps: { value: item } },
                                  [_vm._v(_vm._s(item))]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm.isPreparingAgenda()
                      ? _c(
                          "div",
                          { staticClass: "w-full" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "button-back",
                                on: {
                                  click: function ($event) {
                                    return _vm.closeAgendaView()
                                  },
                                },
                              },
                              [
                                _c("BackArrowWithTitle", {
                                  staticClass: "float-left",
                                  attrs: { title: "Manage Proposals" },
                                }),
                              ],
                              1
                            ),
                            _vm.isViewingSection8
                              ? _c(
                                  "ButtonWithSpinner",
                                  {
                                    staticClass:
                                      "button-blue float-right w-40 mt-4 mb-6 mx-4",
                                    attrs: {
                                      isSpinning:
                                        _vm.$wait.is("downloadingAgenda"),
                                      disabled:
                                        _vm.$wait.is("downloadingAgenda") ||
                                        _vm.agenda.length < 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadAgenda()
                                      },
                                    },
                                  },
                                  [_vm._v("Download Proposals")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex-0" },
                      [
                        !_vm.isPreparingAgenda()
                          ? _c("Checkbox", {
                              staticClass: "text-black ml-auto flex-no-shrink",
                              attrs: {
                                "data-cypress": "checkboxArchived",
                                disabled: false,
                                name: "isArchived",
                                label: "Show Archived Proposals?",
                                isDisabled: true,
                              },
                              model: {
                                value: _vm.includeArchived,
                                callback: function ($$v) {
                                  _vm.includeArchived = $$v
                                },
                                expression: "includeArchived",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.proposals.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "self-center justify-center flex mt-32 text-black font-bold text-2xl w-full",
                          },
                          [_vm._v(" No Proposals Found ")]
                        )
                      : _vm._e(),
                    _vm._l(_vm.proposals, function (proposal, index) {
                      return _c(
                        "section",
                        {
                          key: index,
                          staticClass:
                            "justify-center w-full block self-center bg-white text-black rounded-lg pt-4 mt-3 mb-3",
                          class: {
                            "border-blue": proposal.selected_for_agenda,
                            "modified-background":
                              proposal.display_status === "Modified",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "w-full pl-14 pr-2 flex-col mx-auto",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-1 self-center flex justify-between pb-6",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "self-center flex-1" },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "self-center pt-2 h-auto mb-4 pr-10",
                                          attrs: {
                                            "data-cypress":
                                              "proposal-reference",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                proposal.geographical_name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      proposal.reference_no
                                        ? _c("p", { staticClass: "mb-2" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex-1 text-blue text-normal-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " Reference Number: " +
                                                    _vm._s(
                                                      proposal.reference_no
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        { staticClass: "pb-2 text-normal" },
                                        [
                                          _vm._v(
                                            " Submitted " +
                                              _vm._s(
                                                _vm.formattedTime(
                                                  proposal.date_created
                                                )
                                              ) +
                                              " by "
                                          ),
                                          proposal.organisation_name
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "flex-1 text-blue-button text-normal-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        proposal.organisation_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !proposal.organisation_name
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "flex-1 text-blue-button text-normal-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        proposal.first_name
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        proposal.last_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "flex-wrap flex-start" },
                                        [
                                          _c("proposalButton", {
                                            attrs: {
                                              label:
                                                _vm.placeClassificationHelper(
                                                  proposal.type
                                                ),
                                              classNames: `text-normal-bold cursor-default ${proposal.type}`,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  !_vm.isPreparingAgenda()
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pr-14 pt-2 flex-column text-right",
                                        },
                                        [
                                          proposal.display_status !== "Draft"
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "placeNameProposal",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("View Proposal")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status !== "Draft"
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "PNPAuditTrail",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "View Audit/Records"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isAdmin &&
                                          proposal.display_status !== "Draft" &&
                                          (proposal.type ===
                                            _vm.PROPOSAL_TYPE_BOUNDARY ||
                                            proposal.type ===
                                              _vm.PROPOSAL_TYPE_LOCALITY)
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "placeNameProposal",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                          query: { edit: true },
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Edit Proposal")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isProposalPendingEndorsement(
                                            proposal
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "endorsePlaceNameProposal",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Add Endorsement Info"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status === "Draft"
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "placeNameProposal",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Resume Draft")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.checkAddPublicCommentAvailability(
                                            proposal
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "placeNameProposalAddPublicComment",
                                                          params: {
                                                            proposalId:
                                                              proposal.advertisement_identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Add Public Comment"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status ===
                                            "Under Review" && _vm.user.isAdmin
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "pnpSeekClarification",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Seek Clarification"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status ===
                                            "Clarification" ||
                                          proposal.display_status ===
                                            "Clarification - Dual"
                                            ? _c(
                                                "div",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "clarifyPnp",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Respond to Query")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status ===
                                          "Clarification Advert"
                                            ? _c(
                                                "div",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold",
                                                      attrs: {
                                                        to: {
                                                          name: "clarifyAdvertPnp",
                                                          params: {
                                                            proposalId:
                                                              proposal.identifier,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Add Display Information"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.display_status ===
                                            "Applicant To Review Comments" ||
                                          proposal.display_status ===
                                            "Applicant To Review Comments - Dual"
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.compareDate(
                                                    proposal.advertisement_date_end
                                                  ) &&
                                                  !_vm.user.isAdmin &&
                                                  _vm.user.organisation_name
                                                    ? _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "text-blue-button font-bold",
                                                          attrs: {
                                                            to: {
                                                              name: "pnpProposalComments",
                                                              params: {
                                                                proposal:
                                                                  proposal,
                                                                advertisementId:
                                                                  proposal.advertisement_identifier,
                                                                fromRespondToComments: true,
                                                                isCouncilUploadAvailable:
                                                                  proposal.display_status ===
                                                                  "Applicant To Review Comments",
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Respond to Comments"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.compareDate(
                                                    proposal.advertisement_date_end
                                                  ) && !_vm.user.isAdmin
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Response to Comments available "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "after " +
                                                            _vm._s(
                                                              _vm.formattedTime(
                                                                proposal.advertisement_date_end
                                                              )
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.checkPublicCommentAvailability(
                                            proposal
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  proposal.advertisement_identifier
                                                    ? _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "text-blue-button font-bold",
                                                          attrs: {
                                                            to: {
                                                              name: "pnpProposalComments",
                                                              params: {
                                                                advertisementId:
                                                                  proposal.advertisement_identifier,
                                                                fromRespondToComments: false,
                                                                proposal:
                                                                  proposal,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " View Comments/Responses "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          proposal.withdrawalAvailable
                                            ? _c("div", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-red-button font-bold",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.withdrawProposal(
                                                          proposal.identifier
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Withdraw")]
                                                ),
                                              ])
                                            : _vm._e(),
                                          proposal.modifiedAvailable &&
                                          !_vm.isAdmin
                                            ? _c("div", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-red-button font-bold",
                                                    on: {
                                                      click: function ($event) {
                                                        proposal.display_status ===
                                                        "Modified"
                                                          ? _vm.redraftConfirm(
                                                              proposal.identifier
                                                            )
                                                          : _vm.redraftProposal(
                                                              proposal.identifier
                                                            )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Re-draft")]
                                                ),
                                              ])
                                            : _vm._e(),
                                          proposal.display_status === "Draft"
                                            ? _c("div", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-red-button font-bold",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteProposal(
                                                          proposal.identifier
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Delete Draft")]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.isPreparingAgenda()
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "self-start flex flex-row",
                                        },
                                        [
                                          _vm.isViewingSection8
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "pt-3" },
                                                  [_vm._v("Select Proposal?")]
                                                ),
                                                _c("Checkbox", {
                                                  staticClass:
                                                    "inline font-bold flex text-blue-button",
                                                  on: {
                                                    input: (val) =>
                                                      _vm.selectProposalForAgenda(
                                                        {
                                                          id: proposal.identifier,
                                                          value: val,
                                                        }
                                                      ),
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.isViewingSection10
                                            ? _c(
                                                "div",
                                                { staticClass: "pr-14 pt-2" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-blue-button font-bold cursor-pointer",
                                                      class: [
                                                        proposal.loadingAgenda
                                                          ? "cursor-wait"
                                                          : "cursor-pointer",
                                                      ],
                                                      attrs: {
                                                        disabled:
                                                          proposal.loadingAgenda,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadSingleAgenda(
                                                            proposal.identifier,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Download Agenda Item"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("hr", { staticClass: "pnp-divider w-full" }),
                          _c("div", { staticClass: "w-9/10 mx-auto" }, [
                            _c(
                              "div",
                              { staticClass: "flex flex-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-3/6 pb-4 pt-2 mr-4" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-1 text-blue-button text-normal-bold",
                                      },
                                      [_vm._v(" Local Government Area(s) ")]
                                    ),
                                    _vm._l(proposal.lga, function (lga, index) {
                                      return _c(
                                        "span",
                                        { key: index, staticClass: "block" },
                                        [_vm._v(_vm._s(lga))]
                                      )
                                    }),
                                    !proposal.lga.length
                                      ? _c(
                                          "span",
                                          { staticClass: "font-bold block" },
                                          [_vm._v(" None ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-grow ml-10 pt-2 pb-4" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-1 text-blue-button text-normal-bold",
                                      },
                                      [_vm._v(" Localities ")]
                                    ),
                                    proposal.type === _vm.PROPOSAL_TYPE_PLACE
                                      ? _c(
                                          "div",
                                          [
                                            _vm._l(
                                              proposal.locality,
                                              function (locality, index) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: index,
                                                    staticClass: "block",
                                                  },
                                                  [_vm._v(_vm._s(locality))]
                                                )
                                              }
                                            ),
                                            !proposal.locality.length
                                              ? _c(
                                                  "span",
                                                  { staticClass: "font-bold" },
                                                  [_vm._v(" None ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    proposal.type ===
                                      _vm.PROPOSAL_TYPE_BOUNDARY ||
                                    proposal.type === _vm.PROPOSAL_TYPE_LOCALITY
                                      ? _c(
                                          "div",
                                          [
                                            _vm._l(
                                              proposal.affected_localities,
                                              function (locality, index) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: index,
                                                    staticClass: "block",
                                                  },
                                                  [_vm._v(_vm._s(locality))]
                                                )
                                              }
                                            ),
                                            !proposal.affected_localities.length
                                              ? _c(
                                                  "span",
                                                  { staticClass: "font-bold" },
                                                  [_vm._v(" None ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c("StatusButton", {
                                  attrs: {
                                    label: proposal.display_status,
                                    status: proposal.display_status,
                                    classNames:
                                      "text-normal-bold cursor-default",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    _c(
                      "section",
                      {
                        staticClass:
                          "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
                      },
                      [
                        !_vm.$wait.is("loading Proposals") &&
                        _vm.proposals.length
                          ? _c(
                              "div",
                              {
                                staticClass: "mx-auto text-center self-center",
                              },
                              [
                                _c("paginate", {
                                  attrs: {
                                    "page-count": Math.ceil(
                                      _vm.proposalCount / _vm.limit
                                    ),
                                    "page-range": 10,
                                    "margin-pages": 1,
                                    "click-handler": _vm.changePage,
                                    "prev-text": "<",
                                    "next-text": ">",
                                    "container-class": "pagination",
                                    "page-class": "page-item",
                                  },
                                  model: {
                                    value: _vm.page,
                                    callback: function ($$v) {
                                      _vm.page = $$v
                                    },
                                    expression: "page",
                                  },
                                }),
                                _c("p", [
                                  _vm._v(
                                    " Showing " +
                                      _vm._s(
                                        (
                                          (_vm.page - 1) * _vm.limit +
                                          1
                                        ).toLocaleString()
                                      ) +
                                      " to " +
                                      _vm._s(
                                        Math.min(
                                          (_vm.page - 1) * _vm.limit +
                                            _vm.limit,
                                          _vm.proposalCount
                                        ).toLocaleString()
                                      ) +
                                      " of " +
                                      _vm._s(
                                        _vm.proposalCount.toLocaleString()
                                      ) +
                                      " proposals "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }