<template>
  <div class="base-container text-black">
    <section class="pr-20 justify-center w-full block">
      <div class="flex justify-between">
        <router-link
          :to="{ name: 'rnasManage' }"
          class="flex justify-between mb-3 -mr-4 self-center"
          data-cypress="goback"
        >
          <BackArrowWithTitle title="Add New RNA" />
        </router-link>
      </div>
    </section>

    <div class="w-full bg-white rounded-lg text-normal pb-10">
      <div class="w-9/10 flex flex-wrap content-center mx-auto pt-12 pb-12">
        <h2 class="w-full">RNA Details</h2>
        <div class="dividing-line-h-1 h-2">&nbsp;</div>

        <p class="font-bold w-full pt-8 mb-2">
          <a class="text-red-star">*</a>Name of RNA
        </p>
        <input
          type="text"
          class="w-full input-area"
          data-cypress="nameofrna-field"
          placeholder="Enter name of RNA here..."
          v-model="authority_name"
          v-validate="'required'"
          name="Authority Name"
          :disabled="$wait.is('saving rna')"
        />

        <FieldErrorMessage :message="errors.first('Authority Name')" />
        <h2 class="w-full mt-16">Involved Party Gazettal Details</h2>
        <div class="dividing-line-h-1 h-2">&nbsp;</div>
        <p class="text-normal w-full pt-8 mb-4">
          The details below will be inserted into all gazette notices relating
          to approved road names for this specific RNA.
          <br />If the details are changed here, those changes will be reflected
          in all gazette notices for road names generated by this system.
        </p>

        <div class="mt-6 pr-10 w-1/2">
          <p class="font-bold mb-2">
            <a class="text-red-star">*</a>Name of Responsible Person
          </p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="nameofperson-field"
            placeholder="Enter name of Responsible Person here..."
            v-model="manager_name"
            v-validate="'required'"
            name="Responsible Person Name"
            :disabled="$wait.is('saving rna')"
          />
          <FieldErrorMessage
            :message="errors.first('Responsible Person Name')"
          />
        </div>

        <div class="mt-6 w-1/2">
          <p class="font-bold mb-2">
            <a class="text-red-star">*</a>Organisational Title of Responsible
            Person
          </p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="nameoforg-field"
            placeholder="Enter organisational title..."
            v-model="manager_role"
            v-validate="'required'"
            name="Responsible Person Title"
            :disabled="$wait.is('saving rna')"
          />
          <FieldErrorMessage
            :message="errors.first('Responsible Person Title')"
          />
        </div>

        <div class="mt-6 w-full">
          <p class="font-bold mb-2">
            <a class="text-red-star">*</a>RNA Address Line 1
          </p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="address1-field"
            placeholder="Enter address here..."
            v-model="address_line_1"
            v-validate="'required'"
            name="Authority Address"
            :disabled="$wait.is('saving rna')"
          />
          <FieldErrorMessage :message="errors.first('Authority Address')" />
        </div>

        <div class="mt-6 w-full">
          <p class="font-bold mb-2">Address Line 2</p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="address2-field"
            placeholder="Leave blank if none..."
            v-model="address_line_2"
            :disabled="$wait.is('saving rna')"
          />
        </div>

        <div class="mt-6 pr-10 w-1/2">
          <p class="font-bold mb-2"><a class="text-red-star">*</a>Suburb</p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="suburb-field"
            placeholder="Enter suburb name here..."
            v-model="rna_suburb"
            v-validate="'required'"
            name="Suburb"
            :disabled="$wait.is('saving rna')"
          />
          <FieldErrorMessage :message="errors.first('Suburb')" />
        </div>

        <div class="mt-6 pr-10 w-1/4">
          <p class="font-bold mb-2"><a class="text-red-star">*</a>State</p>
          <select
            class="w-full input-area"
            v-model="rna_state"
            data-cypress="state-field"
            v-validate="'required'"
            name="State"
            :disabled="$wait.is('saving rna')"
          >
            <option value="NSW" selected>NSW</option>
            <option value="VIC">VIC</option>
            <option value="SA">SA</option>
            <option value="WA">VIC</option>
            <option value="NT">NT</option>
            <option value="QLD">QLD</option>
            <option value="ACT">ACT</option>
          </select>
          <FieldErrorMessage :message="errors.first('State')" />
        </div>

        <div class="mt-6 w-1/4">
          <p class="font-bold mb-2"><a class="text-red-star">*</a>Postcode</p>
          <input
            type="text"
            class="w-full input-area"
            data-cypress="postcode-field"
            placeholder="Enter postcode here..."
            v-model="rna_postcode"
            v-validate="'required'"
            name="Postcode"
            :disabled="$wait.is('saving rna')"
          />
          <FieldErrorMessage :message="errors.first('Postcode')" />
        </div>
      </div>
    </div>

    <div class="flex my-6">
      <router-link
        :to="{ name: 'rnasManage' }"
        class="button-red-hollow"
        data-cypress="cancel-button"
        :disabled="$wait.is('saving rna')"
        >Cancel</router-link
      >
      <ButtonWithSpinner
        @click="handleSumbitRna"
        class="button-blue ml-auto"
        data-cypress="save-button"
        :disabled="errors.items.length > 0 || $wait.is('saving rna')"
        :isSpinning="$wait.is('saving rna')"
      >
        Save & Continue
      </ButtonWithSpinner>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateRNA",
  data() {
    return {
      authority_name: "",
      manager_name: "",
      manager_role: "",
      address_line_1: "",
      address_line_2: "",
      rna_suburb: "",
      rna_state: "",
      rna_postcode: ""
    };
  },
  methods: {
    async handleSumbitRna() {
      try {
        this.$wait.start("saving rna");
        const isValid = await this.$validator.validate();

        if (isValid) {
          await this.$store.dispatch("updateNewRnaDataState", {
            authority_name: this.authority_name,
            manager_name: this.manager_name,
            manager_role: this.manager_role,
            address_line_1: this.address_line_1,
            address_line_2: this.address_line_2,
            rna_suburb: this.rna_suburb,
            rna_state: this.rna_state,
            rna_postcode: this.rna_postcode
          });
          await this.$store.dispatch("pushNewRNAToDatabase");
          await this.$store.dispatch("getRnaList");
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Road Naming Authority Saved"
          });
          this.$router.push({ name: "rnasManage" });
        } else {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Missing Information",
            text: "Please update all required fields"
          });
        }
        this.$wait.end("saving rna");
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Saving RNA",
          text: "Could not save the Authority"
        });
        this.$wait.end("saving rna");
      }
    }
  }
};
</script>

<style scoped></style>
