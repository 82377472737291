<template>
  <div>
    <clarification-request
      :entryData="transformedRequestEntry(request)"
      v-if="request.to !== request.from"
    ></clarification-request>
    <clarification-response
      :entryData="transformedResponseEntry(response)"
      v-if="response.to !== response.from"
    ></clarification-response>
  </div>
</template>

<script>
import clarificationRequest from "@/components/proposals/common/audit-trail/entries/clarification-request";
import clarificationResponse from "@/components/proposals/common/audit-trail/entries/clarification-response";

export default {
  name: "ClarificationTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    clarificationRequest,
    clarificationResponse
  },
  methods: {
    transformedRequestEntry(change) {
      return {
        createdAt: this.entryData.date,
        request: change.to
      };
    },
    transformedResponseEntry(change) {
      return {
        createdAt: this.entryData.date,
        response: change.to
      };
    }
  },
  computed: {
    request() {
      return this.entryData.changes["clarification request"];
    },
    response() {
      return this.entryData.changes["clarification response"];
    }
  }
};
</script>
