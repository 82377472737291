<template>
  <div>
    <loader v-if="$wait.is('fetchingGeonames')"  text="Searching..." />
    <div
      v-if="results && !$wait.is(`fetchingGeonames`)"
      class="justify-center w-full block self-center bg-white text-black rounded-lg py-4 my-3"
    >
      <div>
        <results-list
          v-if="!$wait.is(`fetchingGeonames`) && results && results.length"
          :geoName="geoName"
          :results="results"
          :search="search"
          :resultCount="resultCount"
          @confirming-link="hideBackButton"
          @cancel-confirm="showBackButton"
          @confirm-linkage="linkageConfirmed"
        />

        <p v-if="resultCount === '0'" class="text-center">
          No results to display
        </p>

        <div
          class="flex justify-between mt-5 mb-10"
          v-show="isShowingBackButton"
        >
          <ButtonWithSpinner
            class="button-blue-hollow flex w-2/6 m-auto mt-4"
            :isSpinning="false"
            :disabled="false"
            @click="goBack"
            >Back to search</ButtonWithSpinner
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
import Loader from "./Loader";
import ResultsList from "./ResultsListing";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

export default {
  name: "DualNamedSearchResultsComponent",

  /**
   * Instance properties
   */
  props: {
    /**
     * the source geoName to be linked from
     */
    geoName: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * The search criteria
     */
    search: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {
      isShowingBackButton: true
    };
  },

  /**
   * Instance child components
   */
  components: {
    Loader,
    ResultsList
  },

  /**
   * Instance computed properties
   */
  computed: {
    /**
     * The results returned by the geoname search
     */
    results() {
      return this.getGeonameSearchResults();
    },

    /**
     * The total number of results returned by the search
     */
    resultCount() {
      return this.getGeonameSearchTotal();
    }
  },

  /**
   * Local instance methods
   */
  methods: {
    ...mapPnpGetters(["getGeonameSearchResults", "getGeonameSearchTotal"]),

    /**
     * Return to the search fields
     */
    goBack() {
      this.$emit("clear-search");
    },

    /**
     * Navigate the search results
     */
    changePage(page) {
      this.$emit("page-change", { page });
    },

    /**
     * Hide the back button
     */
    hideBackButton() {
      this.isShowingBackButton = false;
    },

    /**
     * Show the back button
     */
    showBackButton() {
      this.isShowingBackButton = true;
    },

    /**
     * Linking with the target placename was confirmed
     */
    linkageConfirmed(payload) {}
  }
};
</script>
