"use strict";

import API from "@/helpers/api";
import memoize from "lodash.memoize";

const apiName = "dfsiApplicationAPI";

export const getAllLgasFromServiceSq = memoize(async function() {
  return API.get(apiName, "/lga");
});

export const getSuburbs = memoize(async function(lganame) {
  if (!lganame) return [];
  return API.get(apiName, "/public/lga/suburbsinlga", {
    queryStringParameters: {
      lgaName: lganame
    }
  });
});
