<template>
  <div class="base-container text-black">
    <section class="pt-1 pb-5 justify-center w-full block">
      <div class="flex justify-between">
        <a
          @click="$router.go(-1)"
          class="cursor-pointer flex justify-between pt-4 pb-4 -mr-4 self-center"
        >
          <BackArrowWithTitle title="Edit Organisation" />
        </a>
      </div>
    </section>

    <div class="w-full bg-white rounded-lg">
      <div class="w-9/10 flex flex-wrap content-center mx-auto pt-16 pb-24">
        <p class="text-title w-full">Organisation Details</p>
        <div class="w-full dividing-line-h-1">&nbsp;</div>
        <p class="text-normal-bold w-full pt-4">
          <a class="text-red-star">*</a>Name of Organisation
        </p>
        <input
          type="text"
          class="w-full mt-4 input-area"
          data-cypress="nameoforganisation-field"
          placeholder="Enter name of organisation here..."
          v-model="internalOrganisation.organisation_name"
          v-validate="'required'"
          name="Organisation Name"
        />
        <FieldErrorMessage :message="errors.first('Organisation Name')" />
        <p class="text-normal-bold w-full pt-4">
          Default Local Government Area
        </p>
        <norns-select
          class="w-full mr-4 norns-select"
          v-model="internalOrganisation.default_lga"
          :multiple="false"
          :options="lgasClean"
          :searchable="true"
          :close-on-select="true"
          placeholder="Select LGA..."
          name="LGA"
          data-cypress="lga-select"
          data-vv-as="Local Government Authority"
          :select-on-tab="true"
        >
          <span slot="noOptions">Loading...</span>
        </norns-select>
        <FieldErrorMessage
          v-if="
            internalOrganisation.default_lga &&
              !lgasClean.includes(internalOrganisation.default_lga)
          "
          message="LGA does not exist or name does not match available LGAs"
        />
        <p class="text-normal-bold w-full pt-8">
          <a class="text-red-star">*</a>Organisation Status
        </p>
        <select
          v-model="internalOrganisation.organisation_status"
          class="w-full mt-4 input-area"
          data-cypress="organisation-status-field"
          v-validate="'required'"
          name="Organisation Status"
        >
          <option value disabled hidden selected="selected"
            >Select Status</option
          >
          <option class="bg-white w-full input-area" value="approved"
            >Approved</option
          >
          <option class="bg-white w-full input-area" value="suspended"
            >Suspended</option
          >
          <option class="bg-white w-full input-area" value="pending"
            >Pending</option
          >
        </select>
        <FieldErrorMessage :message="errors.first('Organisation Status')" />

        <div class="w-full dividing-line-h-1">&nbsp;</div>
        <div class="mt-6 w-full">
          <p class="text-normal-bold">
            <a class="text-red-star">*</a>Organisation Address Line 1
          </p>
          <input
            type="text"
            class="w-full mt-4 input-area"
            data-cypress="address1-field"
            placeholder="Enter address here..."
            v-model="internalOrganisation.address_line_1"
            v-validate="'required'"
            name="Organisation Address"
          />
          <FieldErrorMessage :message="errors.first('Organisation Address')" />
        </div>
        <div class="mt-6 w-full">
          <p class="text-normal-bold">Organisation Address Line 2</p>
          <input
            type="text"
            class="w-full mt-4 input-area"
            data-cypress="address2-field"
            placeholder="Enter address here..."
            v-model="internalOrganisation.address_line_2"
            name="address2"
          />
        </div>
        <div class="w-full flex flex-row">
          <div class="mt-6 w-1/2">
            <p class="text-normal-bold"><a class="text-red-star">*</a>Suburb</p>
            <input
              type="text"
              class="w-full mt-4 input-area"
              data-cypress="suburb-field"
              placeholder="Enter suburb name here..."
              v-model="internalOrganisation.organisation_suburb"
              v-validate="'required'"
              name="Organisation Suburb"
            />
            <FieldErrorMessage :message="errors.first('Organisation Suburb')" />
          </div>
          <div class="w-1/2 ml-8 flex flex-row">
            <div class="mt-6 w-1/2">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>State
              </p>
              <select
                class="w-full mt-4 input-area"
                v-model="internalOrganisation.organisation_state"
                data-cypress="state-field"
                v-validate="'required'"
                name="state"
              >
                <option disabled hidden selected>{{
                  internalOrganisation.organisation_state
                }}</option>
                <option value="NSW" selected>NSW</option>
                <option value="VIC">VIC</option>
                <option value="SA">SA</option>
                <option value="WA">VIC</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="ACT">ACT</option>
              </select>
            </div>
            <div class="mt-6 ml-8 w-1/2">
              <p class="text-normal-bold">
                <a class="text-red-star">*</a>Postcode
              </p>
              <input
                type="text"
                v-validate="'required|max:4|min:4'"
                class="w-full mt-4 input-area"
                data-cypress="postcode-field"
                placeholder="Enter postcode here..."
                v-model="internalOrganisation.organisation_postcode"
                name="Organisation Postcode"
              />
              <FieldErrorMessage
                :message="errors.first('Organisation Postcode')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex my-6">
      <button
        @click="$router.go(-1)"
        class="button-red-hollow"
        data-cypress="cancel-button"
      >
        Cancel
      </button>
      <button
        @click="handleSumbitOrganisation"
        class="button-blue ml-auto"
        data-cypress="save-button"
        :disabled="errors.any()"
      >
        Save & Continue
      </button>
    </div>
  </div>
</template>

<script>
import { updateOrganisation } from "../../helpers/organisationCRUD";
import lgaListMixin from "../../mixins/lga-list-filter";
import { mapActions, mapMutations } from "vuex";

export default {
  props: ["organisation"],
  data() {
    return {
      internalOrganisation: this.organisation
    };
  },
  mixins: [lgaListMixin],
  methods: {
    ...mapActions(["getOrganisation"]),
    ...mapMutations(["setDefaultOrganisationLga"]),
    async handleSumbitOrganisation() {
      try {
        const isValid = await this.$validator.validate();

        if (isValid) {
          await updateOrganisation(this.internalOrganisation);
          await this.getOrganisation(this.organisation.identifier);
          if (!this.user.isAdmin && this.user.isPNP) {
            this.setDefaultOrganisationLga(this.organisation.default_lga);
          }
          this.$notify({
            group: "toast",
            type: "success",
            title: "Success",
            text: "Information updated"
          });
          this.$router.go(-1);
        } else {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Missing Information",
            text: "Please update all required fields"
          });
        }
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Saving organisation",
          text: "Could not save the Organisation"
        });
      }
    }
  }
};
</script>

<style scoped></style>
