var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.files, function (file, index) {
      return _c("div", { key: index, staticClass: "mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full mt-5 flex items-center rounded-lg border border-grey-border",
          },
          [
            _c("img", {
              staticClass: "mx-3 m-1",
              attrs: { src: require("../../assets/Images/Icon-xls.png") },
            }),
            _c(
              "p",
              {
                staticClass: "textNormal break-all pr-4",
                attrs: { "data-cypress": "file-title" },
              },
              [_vm._v(_vm._s(file.title))]
            ),
            _c(
              "a",
              {
                staticClass:
                  "cursor-pointer ml-auto mr-8 text-normal-bold text-blue-button flex-no-shrink",
                attrs: { href: _vm.relativeFileUrl(file.s3_key) },
              },
              [_vm._v("View File")]
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }