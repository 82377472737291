<template>
  <div class="flex justify-between my-5 text-xs">
    <div class="w-12 mr-4"></div>
    <div class="w-1/5 mr-4 flex items-center">
      <span class="font-bold">{{ result.geographical_name }}</span>
    </div>
    <div class="w-1/5 mr-4 align-middle flex items-center">{{ lga }}</div>
    <div class="w-1/5 mr-4 align-middle  flex items-center">
      {{ designation }}
    </div>
    <div class="w-1/5 text-center">
      <status-button :label="status" :status="status"></status-button>
    </div>
    <div class="w-1/6 text-center flex items-center justify-center">
      <a v-if="!resultIsSource" href="#" @click.prevent="linkMe">Link</a>
    </div>
  </div>
</template>

<script>
import StatusButton from "../../elements/statusButton";

export default {
  name: "DualNamedSearchResultCard",

  /**
   * Instance properties
   */
  props: {
    /**
     * Which placename are we linking to
     */
    source: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * The results of the search
     */
    result: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    StatusButton
  },

  /**
   * Instance computed properties
   */
  computed: {
    lga() {
      return this.result.lga ? this.result.lga[0] : "-";
    },
    status() {
      return this.result.status;
    },
    designation() {
      return this.result.designation;
    },
    resultIsSource() {
      return this.result.identifier === this.source.identifier;
    }
  },

  /**
   * Instance methods
   */
  methods: {
    /**
     * The user chose a geoname to link
     */
    linkMe() {
      this.$emit("link-geoname", { geoname: this.result });
    }
  }
};
</script>
