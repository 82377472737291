"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

export async function getSurveyorGeneralInfo() {
  return await API.get(apiName, "/surveyor-general");
}

export function getActingSurveyorGeneral() {
  return API.get(apiName, "/surveyor-general/acting");
}

export function deleteActingSurveyorGeneral() {
  return API.del(apiName, "/surveyor-general/acting");
}

export function saveActingSurveyorGeneral(actingSG) {
  return API.post(apiName, "/surveyor-general/acting", {
    body: actingSG
  });
}

export async function saveSurveyorGeneralInfo(surveyorGeneralInfo) {
  const payload = {
    body: surveyorGeneralInfo
  };
  return await API.put(apiName, "/surveyor-general", payload);
}
