var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-4xl pt-12 mx-auto base-container" },
    [
      _c("section", { staticClass: "pt-1 px-0 justify-center w-full block" }, [
        _c(
          "div",
          { staticClass: "flex justify-between mt-4" },
          [
            _c(
              "router-link",
              {
                staticClass: "flex justify-between pb-2 self-center",
                attrs: { to: { name: "administerUsers" } },
              },
              [
                _c("BackArrowWithTitle", {
                  attrs: { title: "Edit Profile - Surveyor General" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "ShowLoading",
        { attrs: { isLoading: "Fetching surveyor general data" } },
        [
          _c(
            "template",
            { slot: "component" },
            [
              _vm.fetchError ? _c("NetworkError") : _vm._e(),
              !_vm.fetchError
                ? _c(
                    "section",
                    {
                      staticClass: "pt-0 pb-4 px-0 justify-center w-full block",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "max-w-6xl flex-column bg-white text-black rounded-lg pl-12 pr-12 pt-12 pb-12 mt-4 mb-8",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-6 pb-0 border-b-2 self-center" },
                            [_c("h2", [_vm._v("Surveyor-General Details")])]
                          ),
                          _c("form", { staticClass: "w-full justify-center" }, [
                            _c(
                              "div",
                              { staticClass: "px-3 py-3 flex flex-wrap mt-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md:w-1/2 px-3 justify-center md:mb-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "block font-bold mb-2",
                                        attrs: { for: "grid-first-name" },
                                      },
                                      [
                                        _c("span", { staticClass: "redStar" }, [
                                          _vm._v("*"),
                                        ]),
                                        _vm._v(" First Name "),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.surveyorGeneral.firstName,
                                          expression:
                                            "surveyorGeneral.firstName",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                        },
                                        {
                                          name: "wait",
                                          rawName: "v-wait:disabled",
                                          value: "saving surveyor general data",
                                          expression:
                                            "'saving surveyor general data'",
                                          arg: "disabled",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                                      attrs: {
                                        id: "grid-first-name",
                                        type: "text",
                                        placeholder: "First Name",
                                        name: "First Name",
                                        "data-vv-scope": "surveyorGeneral",
                                      },
                                      domProps: {
                                        value: _vm.surveyorGeneral.firstName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.surveyorGeneral,
                                            "firstName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md:w-1/2 px-3 justify-center md:mb-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "block font-bold mb-2",
                                        attrs: { for: "grid-last-name" },
                                      },
                                      [
                                        _c("span", { staticClass: "redStar" }, [
                                          _vm._v("*"),
                                        ]),
                                        _vm._v(" Last Name "),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.surveyorGeneral.lastName,
                                          expression:
                                            "surveyorGeneral.lastName",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                        },
                                        {
                                          name: "wait",
                                          rawName: "v-wait:disabled",
                                          value: "saving surveyor general data",
                                          expression:
                                            "'saving surveyor general data'",
                                          arg: "disabled",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                                      attrs: {
                                        id: "grid-last-name",
                                        type: "text",
                                        placeholder: "Last Name",
                                        name: "Last Name",
                                        "data-vv-scope": "surveyorGeneral",
                                      },
                                      domProps: {
                                        value: _vm.surveyorGeneral.lastName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.surveyorGeneral,
                                            "lastName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "px-3 py-6 flex flex-wrap" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md:w-full px-3 justify-center md:mb-0",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "block font-bold mb-2",
                                        attrs: { for: "grid-password" },
                                      },
                                      [
                                        _c("span", { staticClass: "redStar" }, [
                                          _vm._v("*"),
                                        ]),
                                        _vm._v(
                                          " Surveyor-General Contact Email Address "
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.surveyorGeneral.email,
                                          expression: "surveyorGeneral.email",
                                        },
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "email|required",
                                          expression: "'email|required'",
                                        },
                                        {
                                          name: "wait",
                                          rawName: "v-wait:disabled",
                                          value: "saving surveyor general data",
                                          expression:
                                            "'saving surveyor general data'",
                                          arg: "disabled",
                                        },
                                      ],
                                      staticClass:
                                        "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                                      attrs: {
                                        id: "grid-password",
                                        type: "text",
                                        placeholder: "Email",
                                        "data-vv-scope": "surveyorGeneral",
                                        name: "Email",
                                      },
                                      domProps: {
                                        value: _vm.surveyorGeneral.email,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.surveyorGeneral,
                                            "email",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("FieldErrorMessage", {
                                      attrs: {
                                        message: _vm.errors.first(
                                          "Email",
                                          "surveyorGeneral"
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.errors.any("surveyorGeneral")
                              ? _c(
                                  "div",
                                  { staticClass: "flex items-center absolute" },
                                  [
                                    _c("errorIcon"),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-red-error text-normal-bold ml-2",
                                      },
                                      [
                                        _vm._v(
                                          " All surveyor general info fields are required "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex justify-between pt-4 pb-4 mb-20" },
                        [
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "wait",
                                  rawName: "v-wait:disabled",
                                  value: "saving surveyor general data",
                                  expression: "'saving surveyor general data'",
                                  arg: "disabled",
                                },
                              ],
                              staticClass:
                                "cancelButton self-center button-red-hollow",
                              attrs: {
                                to: { name: "administerUsers" },
                                "data-cypress": "cancel",
                              },
                            },
                            [_vm._v("Cancel Changes")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row flex-grow justify-end",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "self-center button-blue-hollow flex-no-grow mr-4",
                                  on: { click: _vm.showActingModal },
                                },
                                [_vm._v(" Set Acting Surveyor General ")]
                              ),
                              _c(
                                "ButtonWithSpinner",
                                {
                                  staticClass:
                                    "saveButton self-center button-blue",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.isSaveDisabled,
                                    isSpinning: _vm.$wait.is(
                                      "saving surveyor general data"
                                    ),
                                  },
                                  on: { click: _vm.save },
                                },
                                [_vm._v("Save Changes ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }