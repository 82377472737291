<template>
  <div
    @click="handleClick"
    class="flex flex-row items-center"
    v-bind:class="{ disabled: disabled }"
  >
    <div class="feux-radio-outer flex justify-center items-center">
      <div class="feux-radio-inner" v-bind:class="{ selected: checked }" />
    </div>
    <span class="flex-1 text-black">
      <slot>{{ value }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  props: ["checkedValue", "value", "disabled", "name"],
  model: {
    event: "change"
  },
  computed: {
    checked() {
      return this.checkedValue === this.value;
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit("change", !this.checked ? this.checkedValue : null);
    }
  },
  $_veeValidate: {
    value() {
      return this.value;
    },
    name() {
      return this.name;
    }
  }
};
</script>

<style>
.feux-radio-outer {
  border: 2px solid #002664;
  border-radius: 99999px;
  margin-right: 0.6em;
  @apply w-6;
  @apply h-6;
}

.feux-radio-inner {
  background-color: #002664;
  border-radius: 99999px;
  width: 60%;
  height: 60%;
  flex: 0 0 none;
  display: none;
}

.feux-radio-inner.selected {
  display: block;
}

.disabled .feux-radio-outer {
  border: 2px solid #8192b1;
  opacity: 0.5;
}
.disabled .feux-radio-inner {
  opacity: 0.5;
}
</style>
