"use strict";

import API from "@/helpers/api";
const API_NAME = "dfsiApplicationAPI";
const BASE_PATH = "/rna";

// push new rna to database
export function saveRnaToDb(RNAData) {
  const payload = {
    body: RNAData
  };

  return API.post(API_NAME, BASE_PATH, payload);
}

export function updateRna(RNAData) {
  const payload = {
    body: RNAData
  };

  return API.put(API_NAME, `${BASE_PATH}/${RNAData.identifier}`, payload);
}

export function getRnaList() {
  return API.get(API_NAME, BASE_PATH);
}

export function getEntityList() {
  return API.get(API_NAME, "/entities");
}

export function deleteRNA(id) {
  return API.del(API_NAME, `${BASE_PATH}/${id}`);
}

export async function getRNA(id) {
  return API.get(API_NAME, `${BASE_PATH}/${id}`);
}
export function updateUserRna(details) {
  const payload = {
    body: details
  };
  return API.put(API_NAME, `/rna/user/${details.user_id}`, payload);
}

export function getDefaultRnaLga(rnaIdentifier) {
  return API.get(API_NAME, `/rna/${rnaIdentifier}/default-lga`);
}
