<template>
  <div id="uploadDiv" data-cypress="upload-box" class="my-4 w-full" :class="classNames">
    <p class="pb-3 text-normal-bold">
      <a class="text-red-star" v-if="important">*</a>
      <span v-if="labelColor" :style="{color: labelColor}">{{ label }}</span>
      <span v-if="!labelColor">{{ label }}</span>
    </p>
    <div class="flex w-full">
      <label class="flex w-full">
        <div
          class="w-7/8 flex p-4 pl-5 cursor-pointer text-normal text-grey-text bg-grey-input rounded"
          v-bind:class="{ 'disabled-file-picker': disabled }"
          ref="fileform"
        >
          Click or drag file here to upload...
          <input
            type="file"
            class="hidden"
            ref="fileInput"
            data-cypress="file-upload-input"
            @change="filesChange($event.target.files)"
            :disabled="disabled"
            :accept="acceptedFiles"
          />
        </div>

        <ButtonWithSpinner
          class="bg-blue-button rounded rounded-l-none relative w-1/8 flex justify-center items-center cursor-pointer text-white text-normal font-semibold"
          :disabled="isWaiting || disabled"
          :isSpinning="isWaiting"
          data-cypress="addFilesButton"
          @click="$refs.fileInput.click()"
        >Add Files</ButtonWithSpinner>
      </label>
    </div>
    <div class="mb-4" v-for="(file, index) in files" v-bind:key="index">
      <div class="w-full mt-5 flex items-center rounded-lg border border-grey-border">
        <img src="../../assets/Images/Icon-xls.png" class="mx-3 m-1" />
        <p class="textNormal textOverFlow" data-cypress="file-title" v-if="!labelColor">{{ file.title }}</p>
        <p class="textNormal textOverFlow" data-cypress="file-title" v-if="labelColor" :style="{color: labelColor}">{{ file.title }}</p>
        <div
          class="cursor-pointer ml-auto text-normal-bold text-blue-button flex-no-shrink"
        >
          <label :hidden="!showPublicDisplay">
            <input
              type="radio"
              :name="stateProp + '_' + index"
              class="m-3"
              :checked="file.is_suitable_for_public_display !== true"
              @change="changeToPublic(stateProp, index, false)"
              value="false"
            />Private
          </label>
          <label :hidden="!showPublicDisplay">
            <input
              type="radio"
              :name="stateProp + '_' + index"
              class="m-3"
              :checked="file.is_suitable_for_public_display === true"
              @change="changeToPublic(stateProp, index, true)"
              value="true"
            />Public
          </label>
        </div>
        <a
          class="cursor-pointer ml-16 text-normal-bold text-blue-button flex-no-shrink"
          :href="relativeFileUrl(file.s3_key)"
          v-on="isLocalHost ? { click: showDownloadError } : {}"
        >View File</a>
        <button
          class="cursor-pointer ml-10 mr-8 text-normal-bold"
          v-bind:class="{
            'text-grey-light': !drafted || disabled,
            'text-red-button': drafted && !disabled
          }"
          data-cypress="removefiles"
          @click="removeFile(index)"
          :disabled="disabled"
        >Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { deleteFile } from "../../helpers/s3";
import ButtonWithSpinner from "./buttonWithSpinner";
import {
  allFilesList,
  documentFilesList,
  gisFilesList,
  imageFilesList
} from "../../helpers/fileWhitelists";
import pnpCRUD from "../../helpers/pnpCRUD.js";
export default {
  components: {
    ButtonWithSpinner
  },
  props: {
    label: {
      type: String
    },
    labelColor: {
      type: String,
      default: null
    },
    geonameIdentifier: {
      type: String,
      default: null
    },
    proposalName: {
      type: String
    },
    councilName: {
      type: String
    },
    files: {
      type: Array
    },
    important: {
      type: Boolean
    },
    classNames: {
      type: String
    },
    drafted: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    stateKey: {
      type: String
    },
    stateProp: {
      type: String
    },
    uploadFile: {
      type: Function
    },
    accept: {
      type: String,
      default: ""
    },
    showPublicDisplay: {
      type: Boolean,
      default: false
    },
    changeVisibility: {
      type: Function
    }
  },
  $_veeValidate: {
    name() {
      return "fileUploader";
    },
    value() {
      return this.files.length;
    }
  },
  data() {
    return {
      s3Key: Date.now(),
      isWaiting: false,
      uploadNum: 0,
      dragAndDropCapable: false,
      isLocalHost: __FILES_URL__.replace(/^https?:\/\//, '').split(':').includes('localhost')
    };
  },
  computed: {
    acceptedFiles() {
      if (this.accept === "") return allFilesList;
      if (this.accept === "documents") return documentFilesList;
      if (this.accept === "images") return imageFilesList;
      if (this.accept === "gisFiles") return gisFilesList;
      return this.accept;
    }
  },
  methods: {
    ...mapMutations(["deleteUploadedFile"]),
    relativeFileUrl(key) {
      if (this.isLocalHost) {
        return undefined;
      } else {
        return `${__FILES_URL__}/${key}`;
      }
    },
    showDownloadError() {
      this.$notify({
        group: "toast",
        type: "error",
        title: "Local Stack Download Error",
        text: "Local development stacks cannot download any attachments."
      });
    },
    async filesChange(fileList) {
      try {
        this.isWaiting = true;
        this.uploadNum = fileList.length;
        for (let file of fileList) {
          if (file) {
            // If the uploaded file is for 'RESPONSE TO OBJECTIONS'
            if(this.stateProp === 'response_to_objections') {
              let newFileName;

              // Updates the file name in accordance with the file name protocol
              // name of council_name of proposal_response_number
              if(!this.files.length) {
                // If there is no any files previously uploaded
                newFileName = `${this.councilName}_Response_${this.proposalName}_1.${file.name.split('.')[file.name.split('.').length - 1]}`;
              } else {
                // If there are files already uploaded
                const responseNumbers = this.files.map(file => Number((file.title.split('_')[file.title.split('_').length - 1]).split('.')[0]));
                newFileName = `${this.councilName}_Response_${this.proposalName}_${Math.max(...responseNumbers) + 1}.${file.name.split('.')[file.name.split('.').length - 1]}`;
              }

              newFileName = newFileName.split(' ').join('_');

              //Updates the file name in the blob
              file = new File([file], newFileName, { type: file.type });

              // Executes the uploadFile 
              const result = await this.uploadFile({
                key: this.stateKey,
                prop: this.stateProp,
                fileData: { file, s3Key: this.s3Key, geoname_identifier: this.geonameIdentifier }
              });

              // If file upload is successful, it re-renders the uploadScope component
              if(result.success) {
                this.$emit("rerender", true);
              }
            } else {
              await this.uploadFile({
                key: this.stateKey,
                prop: this.stateProp,
                fileData: { file, s3Key: this.s3Key }
              });
            }
          } else {
            this.$notify({
              group: "toast",
              type: "notice",
              title: "File type not supported",
              text: "This file type is not supported"
            });
          }
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "notice",
          title: "File type not supported",
          text: "This file type is not supported"
        });
      }

      this.isWaiting = false;
      this.$emit("uploadfinished");
    },
    async removeFile(fileIndex) {
      if (this.drafted) {
        // If the delete file is for 'RESPONSE TO OBJECTIONS'
        if(this.stateProp === 'response_to_objections') {
          // Deletes the file from S3
          deleteFile(this.files[fileIndex].s3_key);

          const payload = {
            file: this.files[fileIndex],
            geoname_identifier: this.geonameIdentifier
          }

          // Deletes the file entry from the DB
          const result = await pnpCRUD.deleteResponseToObjectionEntry(payload);

          // If file delete is successful, it re-renders the uploadScope component
          if(result.success) {
            this.$emit("rerender", true);
          }
        } else {
          deleteFile(this.files[fileIndex].s3_key);
          this.$emit("deleteFile", fileIndex);
        }
      } else {
        this.$notify({
          group: "toast",
          type: "notice",
          title: "Cannot remove",
          text: "Files can only be removed from drafted proposals"
        });
      }
    },
    changeToPublic(prop, index, isPublic) {
      this.changeVisibility(prop, index, isPublic);
    },
    /*
       Determines if the drag and drop functionality is in the
       window
    */
    determineDragAndDropCapable() {
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement("div");

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.
      */
      return "draggable" in div || ("ondragstart" in div && "ondrop" in div);
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    this.drafted === !!this.drafted;

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          /*
        For each event add an event listener that prevents the default action
        (opening the file in the browser) and stop the propagation of the event (so
        no other elements open the file in the browser)
      */
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
      Add an event listener for drop to the form
    */
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          /*
        Capture the files from the drop event and handle them
      */
          this.filesChange(e.dataTransfer.files);
        }.bind(this)
      );
    }
  }
};
</script>

<style>
.disabled-file-picker {
  background-color: #d4d8da !important;
}

.textOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
