<template>
  <div
    v-bind:class="{ isError: error, isWarning: warning, isOk: ok }"
    class="main p-2 mt-4 px-4 font-bold rounded"
  >
    <font-awesome-icon icon="exclamation" class="mr-2" v-if="!!warning" />
    <font-awesome-icon icon="times" class="mr-2" v-if="!!error" />
    <font-awesome-icon icon="check" class="mr-2" v-if="!!ok" />
    {{ msg
    }}<span v-if="mapURL && !geometry">
      -
      <a :href="mapURL" target="_blank" class="text-blue-button font-bold"
        >View Map</a
      ></span
    >
    <span v-if="geometry">
      -
      <a
        @click="$emit('increment-btn', geometry)"
        class="text-blue-button font-bold cursor-pointer"
        >View on map</a
      ></span
    >
  </div>
</template>

<script>
export default {
  props: {
    guideLineType: {
      type: String
    },
    msg: {
      type: String
    },
    mapURL: {
      type: String
    },
    geometry: {
      type: Object
    },
    error: {
      type: Boolean
    },
    warning: {
      type: Boolean
    },
    ok: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
.isError {
  color: #ad0000;
  background-color: #ffd1cf;
}

.isWarning {
  color: rgb(228, 120, 5);
  background-color: #fff1cf;
}

.isOk {
  color: rgb(43, 169, 2);
  background-color: #deffdb;
}

/* .main {
  padding: 6px;
} */
</style>
