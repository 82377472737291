<template>
  <span
    :class="account_status"
    class="inline-block rounded-full  px-4 py-2 text-sm font-bold ml-auto mr-auto text-center capitalize"
    >{{ account_status }}</span
  >
</template>

<script>
export default {
  props: {
    account_status: {
      type: String
    }
  }
};
</script>

<style scoped>
.active {
  background-color: #d4f8b7;
  color: #007906;
}
.approved {
  background-color: #d4f8b7;
  color: #007906;
}
.pending {
  background-color: #fdf5bf;
  color: #dc5800;
}
.suspended {
  background-color: #ffccd5;
  color: #c60c30;
}
.needs {
  background-color: #c9ecff;
  color: #0a7cb9;
}
</style>
