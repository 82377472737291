"use strict";

import API from "@/helpers/api";
const apiName = "dfsiApplicationAPI";

//Fix road classification
function fixClassification(payload) {
  if (payload == "Private") {
    return "Non-Dedicated";
  } else if (payload == "UNKNOWN") {
    return "Unapproved Gazette";
  } else {
    return payload;
  }
}

function createLists(proposal) {
  const statusList = proposal.roads.reduce(
    (map => (r, a) => (
      !map.has(a.name_status) &&
        map.set(
          a.name_status,
          r[
            r.push({
              status: a.name_status,
              amount: 0
            }) - 1
          ]
        ),
      map.get(a.name_status).amount++,
      r
    ))(new Map()),
    []
  );
  statusList.push({
    status: "All",
    amount: proposal.roads.length
  });

  const classificationList = proposal.roads.reduce(
    (map => (r, a) => (
      !map.has(a.roadname_classification) &&
        map.set(
          a.roadname_classification,
          r[
            r.push({
              roadname_classification: a.roadname_classification,
              name: fixClassification(a.roadname_classification),
              amount: 0
            }) - 1
          ]
        ),
      map.get(a.roadname_classification).amount++,
      r
    ))(new Map()),
    []
  );

  classificationList.push({
    roadname_classification: "All",
    name: "All",
    amount: proposal.roads.length
  });

  return {
    classificationList,
    statusList
  };
}

// getAllProposals
export async function getAllRoadnameProposals(queryStringParameters) {
  const result = await API.get(apiName, "/proposals", {
    queryStringParameters
  });

  result.proposals = result.proposals.map(proposal =>
    Object.assign(proposal, createLists(proposal))
  );

  return result;
}

export function withdrawProposal(id) {
  return API.put(apiName, `/roadnames/${id}/withdraw`);
}
export async function getProposalById(id) {
  let proposal = await API.get(apiName, `/proposals/${id}`);

  proposal = Object.assign({}, proposal, createLists(proposal));
  for (let s of proposal.statusList.values()) {
    if (s.status === "Drafted") proposal.status = "Drafted";
  }

  return proposal;
}
export function deleteDraftedProposal(id) {
  return API.del(apiName, `/proposals/${id}`);
}

export function getBulkUploadTemplate(lga) {
  return API.get(apiName, `/proposals/templates/${lga}`);
}
