<template>
  <div class="w-full text-center">
    <h3 class="text-xl mb-4">Are you sure you want to link</h3>
    <span class="block w-full text-2xl font-bold mb-4 text-blue-darker">{{
      source.geographical_name
    }}</span>
    <span class="block w-full  mb-4">with</span>
    <span class="block w-full text-2xl font-bold mb-4 text-blue-darker">{{
      target.geographical_name
    }}</span>

    <div class="mt-12 mb-8">
      <button @click.prevent="cancel" class="button-red-hollow py-4 px-4 mr-6">
        Cancel
      </button>
      <button
        @click.prevent="confirm"
        class="btn focus:outline-none focus:shadow-outline relative px-4 button-blue"
      >
        Yes, go ahead
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DualNameLinkConfirmationDialogue",

  /**
   * Instance properties
   */
  props: {
    /**
     * Which placename are we linking to
     */
    source: {
      type: Object,
      default: function() {
        return {};
      }
    },

    /**
     * Which placename are we linking the source with
     */
    target: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  /**
   * Instance methods
   */
  methods: {
    /**
     * The user clicked the cancel button
     */
    cancel() {
      this.$emit("cancel-linkage");
    },

    /**
     * The user clicked the confirm button
     */
    confirm() {
      this.$emit("confirm-linkage", { placename: this.target });
    }
  }
};
</script>
