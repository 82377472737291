<template>
  <div class="base-container">
    <div class="flex justify-between items-center mt-2">
      <h1 class="text-title self-center" data-cypress="header">
        <Tree class="header-img" v-if="$route.meta.isPlaceNameRoute" />
        <Road class="header-img" v-if="$route.meta.isRoadNameRoute" />{{ partyType }} Parties
      </h1>
      <button
        @click="showEdit({ name: '', email: '' }, -1)"
        class="button-blue-hollow"
        data-cypress="addnew-button"
      >
        Add New {{ partyType }} Party
      </button>
    </div>
    <NetworkError v-if="fetchError" />
    <Loading-spinner v-wait:visible="'loading parties'" class="flex-0" />
    <div
      class="flex flex-grow flex-col"
      v-if="!fetchError"
      v-wait:hidden="'loading parties'"
    >
      <div
        class="flex flex-grow bg-white rounded-lg mt-4 items-center content-center"
        v-for="(party, index) in parties"
        v-bind:key="index"
      >
        <h3 class="flex items-center text-black w-1/3 h-18 pl-8">
          {{ party.name }}
        </h3>
        <p class="font-bold text-blue-button pl-3">
          {{ party.email }}
        </p>
        <p
          class="font-bold text-blue-button ml-auto cursor-pointer"
          data-cypress="edit"
          @click="showEdit(party, index)"
        >
          Edit
        </p>

        <p
          class="font-bold text-red-button ml-12 mr-12 cursor-pointer"
          data-cypress="remove"
          @click="showRemove(party, index)"
        >
          Remove
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as partyCruds from "../../helpers/partyCRUD";
import partiesModal from "./partiesModal.vue";
import confirmDeleteModal from "./confirmDeleteModal.vue";
import Road from "../../assets/Images/road-black.svg";
import Tree from "../../assets/Images/tree-black.svg";
import { waitFor } from "vue-wait";
export default {
  name: "parties",
  components: { Tree, Road },
  async created() {
    this.type = this.$route.meta.isPlaceNameRoute ? "pnr" : "rnr";

    await this.fetchPartyList();
  },
  data() {
    return {
      type: String,
      fetchError: false,
      parties: []
    };
  },
  methods: {
    showEdit(party) {
      const refreshList = this.fetchPartyList;
      this.$modal.show(
        partiesModal,
        {
          party,
          type: this.type
        },
        {
          height: "auto",
          width: "50%"
        },
        {
          "before-close": async () => {
            await refreshList();
          }
        }
      );
    },
    showRemove(party) {
      const refreshList = this.fetchPartyList;
      this.$modal.show(
        confirmDeleteModal,
        {
          party,
          type: this.type
        },
        {
          height: "auto",
          width: "50%"
        },
        {
          "before-close": async () => {
            await refreshList();
          }
        }
      );
    },
    hideRemove() {
      this.$modal.hide("cancel-party");
    },
    fetchPartyList: waitFor("fetching parties list", async function() {
      try {
        this.fetchError = false;
        this.parties = await partyCruds.getPartyList(this.type);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (e.response.status === 500) {
          this.fetchError = true;
        }
      }
    })
  },
  computed: {
    showLoginModal() {
      return this.$store.getters.showLoginModal;
    },
    partyType() {
      return this.type === "pnr" ? "Interested" : "Relevant";
    }
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchPartyList();
      }
    }
  }
};
</script>

<style scoped>
.header-img {
  height: 30px;
  margin-right: 10px;
}
</style>
