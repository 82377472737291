var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column bg-white text-black rounded-lg p-5 mt-4 mb-2" },
    [
      _vm._m(0),
      _c(
        "ShowLoading",
        { attrs: { isLoading: "fetching acting surveyor" } },
        [
          _c(
            "template",
            { slot: "component" },
            [
              _vm.fetchError ? _c("NetworkError") : _vm._e(),
              _c(
                "form",
                {
                  staticClass: "w-full justify-center flex flex-col",
                  attrs: { novalidate: "" },
                },
                [
                  _c("div", { staticClass: "py-3 flex flex-wrap mt-4" }, [
                    _c("div", { staticClass: "flex flex-grow flex-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "md:w-1/2 pr-3 justify-center md:mb-0" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "block font-bold mb-2",
                              attrs: { for: "grid-password" },
                            },
                            [
                              _c("span", { staticClass: "redStar" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" Start Date "),
                            ]
                          ),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  date_format: "yyyy-MM-dd",
                                  required: true,
                                },
                                expression:
                                  "{\n                  date_format: 'yyyy-MM-dd',\n                  required: true\n                }",
                              },
                              {
                                name: "wait",
                                rawName: "v-wait:disabled",
                                value: "saving acting surveyor",
                                expression: "'saving acting surveyor'",
                                arg: "disabled",
                              },
                            ],
                            staticClass:
                              "appearance-none block w-full grey-bg rounded mb-3 leading-tight text-normal text-grey-text input-form rounded-lg mb-4",
                            attrs: {
                              name: "actingStartDate",
                              placeholder: "Start Date",
                              format: _vm.customFormatter,
                              "data-vv-as":
                                "Acting Surveyor General start date",
                              "input-class": "w-full input-area",
                              "calendar-class": "mb-4",
                            },
                            model: {
                              value: _vm.actingDateStart,
                              callback: function ($$v) {
                                _vm.actingDateStart = $$v
                              },
                              expression: "actingDateStart",
                            },
                          }),
                          _c("FieldErrorMessage", {
                            attrs: {
                              message: _vm.errors.first("actingDateStart"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md:w-1/2 justify-center md:mb-0" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "block font-bold mb-2",
                              attrs: { for: "grid-password" },
                            },
                            [
                              _c("span", { staticClass: "redStar" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" End Date "),
                            ]
                          ),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  date_format: "yyyy-MM-dd",
                                  required: true,
                                },
                                expression:
                                  "{\n                  date_format: 'yyyy-MM-dd',\n                  required: true\n                }",
                              },
                              {
                                name: "wait",
                                rawName: "v-wait:disabled",
                                value: "saving acting surveyor",
                                expression: "'saving acting surveyor'",
                                arg: "disabled",
                              },
                            ],
                            staticClass:
                              "appearance-none block w-full grey-bg rounded mb-3 leading-tight text-normal text-grey-text input-form rounded-lg mb-4",
                            attrs: {
                              name: "actingDateEnd",
                              placeholder: "End Date",
                              format: _vm.customFormatter,
                              "data-vv-as": "Acting Surveyor General end date",
                              "input-class": "w-full input-area",
                              "calendar-class": "mb-4",
                              "open-date": _vm.actingSurveyorGeneral.dateStart
                                ? _vm.actingSurveyorGeneral.dateStart.toDate()
                                : new Date(),
                            },
                            model: {
                              value: _vm.actingDateEnd,
                              callback: function ($$v) {
                                _vm.actingDateEnd = $$v
                              },
                              expression: "actingDateEnd",
                            },
                          }),
                          _c("FieldErrorMessage", {
                            attrs: {
                              message: _vm.errors.first("actingDateEnd"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "md:w-1/2 pr-3 justify-center md:mb-0" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "block font-bold mb-2",
                            attrs: { for: "grid-acting-first-name" },
                          },
                          [
                            _c("span", { staticClass: "redStar" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" First Name "),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.actingSurveyorGeneral.firstName,
                              expression: "actingSurveyorGeneral.firstName",
                            },
                            {
                              name: "wait",
                              rawName: "v-wait:disabled",
                              value: "saving acting surveyor",
                              expression: "'saving acting surveyor'",
                              arg: "disabled",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass:
                            "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                          attrs: {
                            id: "grid-acting-first-name",
                            type: "text",
                            placeholder: "First Name",
                            name: "ASG First Name",
                          },
                          domProps: {
                            value: _vm.actingSurveyorGeneral.firstName,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.actingSurveyorGeneral,
                                "firstName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "md:w-1/2 m justify-center md:mb-0" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "block font-bold mb-2",
                            attrs: { for: "grid-acting-last-name" },
                          },
                          [
                            _c("span", { staticClass: "redStar" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" Last Name "),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.actingSurveyorGeneral.lastName,
                              expression: "actingSurveyorGeneral.lastName",
                            },
                            {
                              name: "wait",
                              rawName: "v-wait:disabled",
                              value: "saving acting surveyor",
                              expression: "'saving acting surveyor'",
                              arg: "disabled",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass:
                            "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                          attrs: {
                            id: "grid-acting-last-name",
                            type: "text",
                            placeholder: "Last Name",
                            name: "ASG Last Name",
                          },
                          domProps: {
                            value: _vm.actingSurveyorGeneral.lastName,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.actingSurveyorGeneral,
                                "lastName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "block font-bold mb-2",
                          attrs: { for: "grid-acting-password" },
                        },
                        [
                          _c("span", { staticClass: "redStar" }, [_vm._v("*")]),
                          _vm._v(" Email Address "),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.actingSurveyorGeneral.email,
                            expression: "actingSurveyorGeneral.email",
                          },
                          {
                            name: "wait",
                            rawName: "v-wait:disabled",
                            value: "saving acting surveyor",
                            expression: "'saving acting surveyor'",
                            arg: "disabled",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "email|required",
                            expression: "'email|required'",
                          },
                        ],
                        staticClass:
                          "appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area",
                        attrs: {
                          id: "grid-acting-password",
                          type: "text",
                          placeholder: "Email",
                          name: "Email",
                        },
                        domProps: { value: _vm.actingSurveyorGeneral.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.actingSurveyorGeneral,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("FieldErrorMessage", {
                        attrs: {
                          message: _vm.errors.first(
                            "Email",
                            "actingSurveyorGeneral"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.errors.any("actingSurveyorGeneral")
                    ? _c(
                        "div",
                        { staticClass: "flex items-center absolute" },
                        [
                          _c("errorIcon"),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-red-error text-normal-bold ml-2",
                            },
                            [
                              _vm._v(
                                " Acting surveyor general info cannot be left partial "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex flex-row justify-between" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "self-center button-red-hollow flex-no-grow",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "ButtonWithSpinner",
                        {
                          directives: [
                            {
                              name: "wait",
                              rawName: "v-wait:disabled",
                              value: "deleteing acting surveyor",
                              expression: "'deleteing acting surveyor'",
                              arg: "disabled",
                            },
                          ],
                          staticClass:
                            "self-center button-red-hollow flex-no-grow",
                          attrs: {
                            isSpinning: _vm.$wait.is(
                              "deleteing acting surveyor"
                            ),
                          },
                          on: { click: _vm.removeActingSurveyorGeneral },
                        },
                        [_vm._v(" Remove ")]
                      ),
                      _c(
                        "ButtonWithSpinner",
                        {
                          staticClass: "saveButton self-center button-blue",
                          attrs: {
                            type: "button",
                            disabled: _vm.isSaveDisabled,
                            isSpinning: _vm.$wait.is("saving acting surveyor"),
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Save Changes ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex justify-between pb-0 border-b-2" }, [
      _c("h2", [_vm._v("Acting Surveyor General Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }