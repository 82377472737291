var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "block w-full text-center",
      staticStyle: { width: "113px" },
    },
    [_vm._v("-")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }