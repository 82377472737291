var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fork min-h-screen" }, [
    _c("img", {
      staticClass: "gnbLogoTop",
      attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
    }),
    _c(
      "button",
      { staticClass: "logout float-right", on: { click: _vm.userLogout } },
      [_vm._v(" Log Out ")]
    ),
    _vm._m(0),
    _c("h3", { staticClass: "subHeading sm:text-center" }, [
      _vm._v(
        " Hello " +
          _vm._s(_vm.displayName) +
          ", welcome to the NSW Place and Road Naming Proposal System. "
      ),
    ]),
    _c("div", { staticClass: "flex flex-wrap justify-center items-start" }, [
      _c(
        "div",
        { staticClass: "shadow-md bg-white rounded-lg panel" },
        [
          _c("Road", { staticClass: "icon" }),
          _c("h3", { staticClass: "sm:text-center blue" }, [
            _vm._v(" Road Name Proposals "),
          ]),
          _c(
            "button",
            {
              staticClass:
                "w-full mt-4 font-bold button-blue focus:outline-none focus:shadow-outline mb-10 btn-blue jest-go-to-RNP",
              attrs: {
                type: "button",
                "data-cypress": "fork-manage-road-names",
              },
              on: { click: _vm.goToRNPDashboard },
            },
            [_vm._v(" Manage Road Name Proposals ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "shadow-md bg-white rounded-lg panel" },
        [
          _c("Tree", { staticClass: "icon" }),
          _c("h3", { staticClass: "sm:text-center green" }, [
            _vm._v(" Place Name Proposals "),
          ]),
          _c(
            "button",
            {
              staticClass:
                "w-full mt-4 font-bold button-green focus:outline-none focus:shadow-outline mb-10 btn-green jest-go-to-PNP",
              attrs: {
                type: "button",
                "data-cypress": "fork-manage-place-names",
              },
              on: { click: _vm.goToPNPDashboard },
            },
            [_vm._v(" Manage Place Name Proposals ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sm:text-center" }, [
      _c("img", {
        staticClass: "gnbLogo",
        attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }