var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black flex flex-grow" },
    [
      _c(
        "section",
        { staticClass: "justify-start w-full block flex flex-col" },
        [
          _c(
            "a",
            {
              staticClass: "flex justify-between",
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "roadNameProposalBucket",
                    params: { proposalId: _vm.proposalId },
                  })
                },
              },
            },
            [
              _c("BackArrowWithTitle", {
                attrs: { title: "Re-gazette A Road" },
              }),
            ],
            1
          ),
          _c(
            "ShowLoading",
            { attrs: { isLoading: "fetchingRoadInformation" } },
            [
              _c("template", { slot: "component" }, [
                !!_vm.roadName
                  ? _c("div", [
                      !_vm.isReadyForReGazettal
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-1 items-start justify-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex flex-row mt-8" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass:
                                      "flex-0 text-red text-3xl inline mr-2",
                                    attrs: { icon: "exclamation-circle" },
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.roadName.roadname) +
                                        " " +
                                        _vm._s(_vm.roadName.road_type) +
                                        " is not currently Gazetted "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isReadyForReGazettal
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full flex bg-white text-black rounded-lg pl-4 pt-3 pb-3 mt-4 text-center",
                              },
                              [
                                _c("proposalIcon", {
                                  attrs: {
                                    label: _vm.roadName.roadname_classification,
                                    classNames: "w-10 h-10 text-normal",
                                  },
                                }),
                                _c("h3", { staticClass: "self-center ml-4" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.roadName.roadname) +
                                      " " +
                                      _vm._s(_vm.roadName.road_type) +
                                      " "
                                  ),
                                ]),
                                _c("StatusButton", {
                                  staticClass:
                                    "ml-auto mr-6 text-normal font-bold",
                                  attrs: {
                                    label: _vm.roadName.name_status,
                                    status: _vm.roadName.name_status,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full bg-white text-black rounded-lg pt-3 pb-3 mt-4 pb-16 pt-12",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-9/10 mx-auto flex flex-col",
                                  },
                                  [
                                    _c("div", { staticClass: "flex w-full" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "w-1/2 text-normal-bold",
                                          attrs: { for: "lgaText" },
                                        },
                                        [
                                          _vm._v(
                                            " Local Government Area (LGA) "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.roadName.lga_name,
                                                expression: "roadName.lga_name",
                                              },
                                            ],
                                            staticClass:
                                              "flex input-area w-full mt-2",
                                            attrs: {
                                              id: "lgaText",
                                              name: "lgaText",
                                              type: "text",
                                              disabled: "",
                                            },
                                            domProps: {
                                              value: _vm.roadName.lga_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.roadName,
                                                  "lga_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-1/2 ml-8 text-normal-bold",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-normal-bold mb-2",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                "Address Locality (Suburb) "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "norns-select",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass:
                                                "w-full mr-4 norns-select",
                                              attrs: {
                                                disabled:
                                                  !_vm.roadName.lga_name,
                                                multiple: true,
                                                options: _vm.suburbOptions,
                                                searchable: true,
                                                "close-on-select": false,
                                                "show-labels": true,
                                                "hide-selected": true,
                                                placeholder: "Pick a Suburb",
                                                name: "suburb",
                                                "data-cypress": "suburb-field",
                                                "data-vv-as": "Suburb",
                                                "select-on-tab": true,
                                              },
                                              model: {
                                                value: _vm.roadName.suburb_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roadName,
                                                    "suburb_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roadName.suburb_name",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "noOptions" },
                                                  slot: "noOptions",
                                                },
                                                [_vm._v("Loading...")]
                                              ),
                                            ]
                                          ),
                                          _c("FieldErrorMessage", {
                                            attrs: {
                                              message:
                                                _vm.errors.first("suburb"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "flex w-full" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-normal-bold w-1/2 mt-4",
                                          attrs: { for: "lgaRoadType" },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-normal-bold mb-2",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("Road Type "),
                                            ]
                                          ),
                                          _c(
                                            "norns-select",
                                            {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass:
                                                "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                              attrs: {
                                                name: "Road Type",
                                                id: "roadType",
                                                placeholder:
                                                  "Select Road Type...",
                                                "data-cypress":
                                                  "roadtype-select",
                                                multiple: false,
                                                options: _vm.roadTypesClean,
                                                searchable: true,
                                                "close-on-select": true,
                                                "select-on-tab": true,
                                              },
                                              on: { input: _vm.setRoadType },
                                              model: {
                                                value: _vm.roadName.road_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.roadName,
                                                    "road_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "roadName.road_type",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "noOptions" },
                                                  slot: "noOptions",
                                                },
                                                [_vm._v("Loading...")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "w-1/2 ml-8 text-normal-bold",
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "w-full flex mt-8" },
                                      [
                                        _c("label", { staticClass: "w-full" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-normal-bold mb-2",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-red-star",
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("Extent "),
                                            ]
                                          ),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.roadName.extent,
                                                expression: "roadName.extent",
                                              },
                                            ],
                                            staticClass:
                                              "w-full h-48 input-area",
                                            attrs: {
                                              id: "extent",
                                              name: "extent",
                                              "data-cypress": "extent-field",
                                              placeholder:
                                                "Please describe where the road starts and ends",
                                            },
                                            domProps: {
                                              value: _vm.roadName.extent,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.roadName,
                                                  "extent",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _c("FieldErrorMessage", {
                                      attrs: {
                                        message: _vm.errors.first("extent"),
                                      },
                                    }),
                                    _c("uploadComponent", {
                                      attrs: {
                                        label:
                                          "Please upload Map and Other Supporting Document",
                                        drafted: true,
                                        deleteFile: _vm.deleteFunction,
                                        disabled:
                                          _vm.$wait.is("saving re-gazette"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-end mt-6" },
                                      [
                                        _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-red-hollow relative flex justify-center w-1/6 mr-auto mt-4 mb-10 whitespace-no-wrap font-semibold",
                                            attrs: {
                                              "data-cypress": "cancel-button",
                                              disabled:
                                                _vm.$wait.is(
                                                  "saving re-gazette"
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "roadNameProposalBucket",
                                                  params: {
                                                    proposalId: _vm.proposalId,
                                                  },
                                                })
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _c(
                                          "ButtonWithSpinner",
                                          {
                                            staticClass:
                                              "button-blue relative flex justify-center w-1/6 mt-4 mb-10 whitespace-no-wrap font-semibold",
                                            attrs: {
                                              "data-cypress": "addroad-button",
                                              disabled:
                                                _vm.$wait.any || !_vm.canSubmit,
                                              isSpinning:
                                                _vm.$wait.is(
                                                  "saving re-gazette"
                                                ),
                                            },
                                            on: { click: _vm.save },
                                          },
                                          [_vm._v("Continue")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }