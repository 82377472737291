var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "py-4 px-4 rounded",
      class: {
        "input-field-disabled": _vm.disabled,
        "input-field": !_vm.disabled,
        "py-6": _vm.tagList.length === 0 && _vm.disabled,
      },
    },
    [
      _vm._l(_vm.tagList, function (tag, index) {
        return _c(
          "span",
          {
            key: index,
            staticClass:
              "tags-input-badge tags-input-badge-pill tags-input-badge-selected-default",
          },
          [
            !_vm.isDate
              ? _c("span", {
                  staticClass: "tag-text",
                  domProps: { innerHTML: _vm._s(tag) },
                })
              : _vm._e(),
            _vm.isDate
              ? _c("span", {
                  staticClass: "tag-text",
                  domProps: { innerHTML: _vm._s(_vm.formatDate(tag)) },
                })
              : _vm._e(),
            !_vm.disabled
              ? _c("i", {
                  staticClass: "multiselect__tag-icon",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeTag(index)
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      !_vm.disabled && !_vm.isDate
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input",
              },
            ],
            ref: "taginput",
            class: _vm.disabled ? "input-field-disabled" : "input-field",
            attrs: {
              type: "text",
              placeholder: _vm.placeholder,
              "data-cypress": _vm.datacypress,
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.input },
            on: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.addTag(false)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  )
                    return null
                  return _vm.addTag.apply(null, arguments)
                },
              ],
              blur: function ($event) {
                _vm.input = ``
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.input = $event.target.value
              },
            },
          })
        : _vm._e(),
      !_vm.disabled && _vm.isDate
        ? _c("datepicker", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, date_format: "yyyy-MM-dd" },
                expression: "{ required: true, date_format: 'yyyy-MM-dd' }",
              },
            ],
            staticClass: "w-full rounded-lg bg-grey-input",
            attrs: {
              name: "dateInput",
              placeholder: "Select date...",
              format: _vm.datePlaceholder,
              "input-class": "w-full bg-grey-input text-grey-text",
            },
            on: {
              selected: function ($event) {
                return _vm.addDateTag($event)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }