var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("clarification-request", {
    attrs: { entryData: _vm.transformedEntryData },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }