var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-container" }, [
      _c("span", [
        _c("img", {
          staticClass: "h-16",
          attrs: { src: require("../../assets/Logos/GNB logo_White_RGB.png") },
        }),
      ]),
      _c("div", { staticClass: "mx-auto w-full items-center flex" }, [
        _c("div", [
          _c("div", [
            _c("h2", { staticClass: "text-white text-left mt-6 mb-2" }, [
              _vm._v(" NSW Place and Road Naming Proposal System "),
            ]),
          ]),
          _c("div", { staticClass: "heading mt-1 mb-4" }),
          _c("p", { staticClass: "text-white mt-5" }, [
            _vm._v(
              " The system is a mechanism for proposing place and road names in NSW. It supports the application of place and road naming processes in a collaborative environment accessed by naming authorities, local government and the Geographical Names Board (GNB). "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }