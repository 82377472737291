var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "justify-center w-full block self-center bg-white text-black rounded-lg pt-8 mt-3 mb-3",
      class: { isProcessing: !!_vm.numProcessing },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "proposal-list-item flex flex-col flex flex-grow justify-center self-center",
        },
        [
          _c("div", { staticClass: "w-9/10 mx-auto" }, [
            _c(
              "div",
              { staticClass: "flex-1 self-center flex justify-between pb-6" },
              [
                _c("div", { staticClass: "self-center flex-1" }, [
                  _c("div", { staticClass: "flex items-center" }, [
                    _vm.reassign && _vm.user.isAdmin
                      ? _c("div", { staticClass: "py-3 justify-center mb-1" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "container items-baseline flex height-fix",
                            },
                            [
                              _c("input", {
                                staticClass: "checkbox-input",
                                attrs: { type: "checkbox" },
                                domProps: { checked: _vm.isChecked },
                                on: { change: _vm.passProposalIdentifier },
                              }),
                              _c("span", { staticClass: "border-2 checkmark" }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "h2",
                      {
                        staticClass: "self-center pt-2 h-auto mb-4 pr-10",
                        attrs: { "data-cypress": "proposal-reference" },
                      },
                      [
                        _vm._v(
                          " Proposal " + _vm._s(_vm.proposal.reference_no) + " "
                        ),
                      ]
                    ),
                  ]),
                  _c("p", { staticClass: "pb-2 text-normal" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.submittedOrDrafted) +
                        " " +
                        _vm._s(_vm.dateSubmitted) +
                        " by "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "flex-1 text-blue-button text-normal-bold",
                      },
                      [_vm._v(" " + _vm._s(_vm.proposal.authority_name) + " ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-wrap flex-start" },
                    _vm._l(
                      _vm.proposal.classificationList,
                      function (item, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c("proposalButton", {
                              attrs: {
                                label: `${item.amount} ${item.name} Roads`,
                                status: item.roadname_classification,
                                classNames: "text-normal-bold cursor-default",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "self-start" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "text-blue-button font-bold flex-1 actions",
                        attrs: {
                          to: {
                            name: "roadNameProposalBucket",
                            params: { proposalId: _vm.proposal.identifier },
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.viewText))]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "dividing-line-h-1 w-full" }),
          _c("div", { staticClass: "w-9/10 flex-col mx-auto" }, [
            _c("div", { staticClass: "self-center justify pt-4 flex" }, [
              _c(
                "div",
                { staticClass: "flex flex-col w-1/6 flex-no-grow mt-4" },
                [
                  _c("h3", {}, [_vm._v(_vm._s(_vm.numRoads) + " Roads")]),
                  !!_vm.numProcessing
                    ? _c("p", [
                        _vm._v(_vm._s(_vm.numProcessing) + " Roads processing"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex-wrap flex-1 mb-4" },
                _vm._l(_vm.proposal.statusList, function (item, index) {
                  return _c(
                    "span",
                    { key: index },
                    [
                      item.amount === 0
                        ? _c("StatusButton", {
                            attrs: {
                              label: item.status,
                              status: item.status,
                              classNames: "text-normal-bold",
                            },
                          })
                        : _vm._e(),
                      item.amount !== 0
                        ? _c("StatusButton", {
                            attrs: {
                              label: item.amount + ` ` + item.status,
                              status: item.status,
                              classNames: "text-normal-bold cursor-default",
                              "data-cypress": `proposal-classification-${item.roadname_classification}`,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "ml-auto flex",
                  on: {
                    click: function ($event) {
                      _vm.collapsed = !_vm.collapsed
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "collapse",
                      staticClass:
                        "rotate-transition text-blue-darker self-center",
                      class: [_vm.collapsed ? "" : "rotate-chevron"],
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "chevron-circle-down" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            "transition",
            {
              attrs: { name: "collapse" },
              on: {
                enter: _vm.beforeEnterCollapse,
                leave: _vm.beforeLeaveCollapse,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed",
                    },
                  ],
                  ref: "collapse-body",
                },
                _vm._l(_vm.proposal.roads, function (road) {
                  return _c("div", { key: road.identifier }, [
                    _c("div", { staticClass: "flex pl-8" }, [
                      _c(
                        "div",
                        { staticClass: "w-1/8 self-center" },
                        [
                          _c("proposalIcon", {
                            attrs: {
                              label: road.roadname_classification,
                              classNames: "w-12 h-12",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "w-1/4 mr-8 break-words" }, [
                        _c("p", { staticClass: "text-label pt-5 pb-2" }, [
                          _vm._v("Road Name"),
                        ]),
                        _c("h3", [
                          _vm._v(
                            _vm._s(road.roadname) + " " + _vm._s(road.road_type)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "w-3/8" }, [
                        _c("p", { staticClass: "text-label pt-5 pb-3" }, [
                          _vm._v("Road Location"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pb-3" },
                          _vm._l(road.suburb_name, function (sub, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "pb-1" },
                              [_vm._v(" " + _vm._s(sub) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-1/4 flex flex-col justify-center" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "flex-0 text-label pt-5 self-center",
                            },
                            [_vm._v("Proposal status")]
                          ),
                          _c("StatusButton", {
                            attrs: {
                              label: road.name_status,
                              status: road.name_status,
                              extra: _vm.finalisedDate(road),
                              classNames: "font-bold mb-6",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }