var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ForkLayout", {
    attrs: {
      goToPNPDashboard: _vm.goToPNPDashboard,
      goToRNPDashboard: _vm.goToRNPDashboard,
      displayName: _vm.userGivenName,
      userLogout: _vm.userLogout,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }