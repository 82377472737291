<template>
  <div>
    <button class="button-blue-hollow" @click="send">
      Copy {{ count }} contacts to clipboard
    </button>
    <a :href="mailToString" ref="anchor"></a>
  </div>
</template>

<script>
import { exportUsers } from "../../helpers/userCRUD.js";
import { mapWaitingGetters } from "vue-wait";

import Vue from "vue";
import Clipboard from "v-clipboard";

Vue.use(Clipboard);

export default {
  name: "UserExportComponent",

  /**
   * Local instance data
   */
  data() {
    return {
      mailTo: "",
      bccList: null,
      count: 0
    };
  },

  /**
   * Instance computed properties
   */
  computed: {
    ...mapWaitingGetters({
      filteringUsers: "fetching users"
    }),

    /**
     * The mail to string in the href
     */
    mailToString() {
      return `mailto:${this.mailTo}`;
    },

    /**
     * The query string parameters to send with the export request
     */
    params() {
      if (this.$route.query.isRNP) {
        return { isRNP: true };
      }

      if (this.$route.query.isPNP) {
        return { isPNP: true };
      }

      if (this.$route.query.isPublic) {
        return { isPublic: true };
      }

      if (this.$route.query.isAdmin) {
        return { isAdmin: true };
      }
    }
  },

  /**
   * Instance data watchers
   */
  watch: {
    async filteringUsers() {
      const response = await exportUsers(this.params);

      this.bccList = response.users.map(u => u.email).join(";");
      this.count = response.users.length;
    }
  },

  /**
   * Instance methods
   */
  methods: {
    /**
     * Send to the current list
     */
    async send() {
      try {
        this.$clipboard(this.bccList);

        this.$notify({
          group: "toast",
          type: "success",
          title: "Copy error",
          text: "Contacts copied to clipboard"
        });

        setTimeout(() => {
          this.$refs.anchor.click();
        }, 500);
      } catch (e) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Copy Error",
          text: "Unable to copy contacts to clipboard"
        });
      }
    }
  }
};
</script>

<style scoped>
a {
  visibility: hidden;
}
</style>
