<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-2xl leading-6 font-medium text-gray-900">
        Proposals To Be Advertised
      </h3>
      <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
        The following proposals will become available for public comment soon.
      </p>
    </div>
    <div>
      <proposals-list
        heading="Place Name Proposals"
        :proposals="pendingPlaceNames"
      />
      <proposals-list
        heading="Address Locality Proposals"
        :proposals="pendingLocalities"
      />
    </div>
  </div>
</template>

<script>
import ProposalsList from "./ProposalsList";

export default {
  name: "PendingProposalsCard",

  /**
   * Instance properties
   */
  props: {
    /**
     * The placename proposals to display in the list
     */
    pendingPlaceNames: {
      type: Array,
      default: function() {
        return [];
      }
    },

    /**
     * The address locality proposals to display in the list
     */
    pendingLocalities: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  /**
   * Local instance data
   */
  data() {
    return {};
  },

  /**
   * Instance child components
   */
  components: {
    ProposalsList
  }
};
</script>
