<template>
  <div
    class="backgroundDiv w-full h-screen fixed overflow-y-auto flex flex-wrap justify-center items-center"
  >
    <img
      src="../../../../assets/Logos/GNB logo_White_RGB.png"
      class="absolute h-12 logoPos"
    />
    <div class="w-2/3 bg-white mx-auto my-3 z-10 rounded mt-32 ">
      <div class="w-full bg-white text-black rounded-lg p-10 px-24 ">
        <div>
          <span
            @click="back()"
            class="text-red text-normal-bold cursor-pointer"
          >
            <font-awesome-icon
              icon="arrow-left"
              class="flex-auto"
              data-cypress="back-button"
            />
            Back
          </span>
        </div>

        <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
          Comment on Place Name Proposal
        </h3>
        <showLoading isLoading="fetchingProposalForComment">
          <template v-slot:component>
            <div v-if="proposal">
              <div class="text-black">
                <div class="pb-8">
                  <p>
                    The form below seeks your views on this specific proposal.
                    All feedback is considered by the NSW Geographical Names
                    Board. Please provide as much detail as you can to assist
                    the Board to understand your views on the proposal.
                  </p>
                </div>
                <div
                  class="w-full border flex flex-row border-gray-200 rounded items-center "
                >
                  <span class="text-black font-bold text-2xl mx-8">
                    {{ proposal.geographical_name }}
                  </span>
                  <div
                    class="dividing-line-v text-black flex-grow flex flex-row "
                  >
                    <div class="flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4">
                      <span>LGA</span>
                      <p v-for="lga in proposal.lga" :key="lga">{{ lga }}</p>
                    </div>
                    <div class="flex-grow pb-4 pt-4 w-1/4 pr-4">
                      <span>Designation</span>
                      <p>{{ proposal.designation }}</p>
                    </div>
                    <div class="flex-grow pb-4 pt-4 w-1/4">
                      <span>Publication Date</span>
                      <p>{{ formatDate(proposal.date_start) }}</p>
                    </div>
                    <div class="flex-grow pb-4 pt-4 w-1/4">
                      <span>Closing Date</span>
                      <p>{{ formatDate(proposal.date_end) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p class="text-normal-bold mt-8">
                  <a class="text-red-star">*</a>Do you support the above
                  proposal?
                </p>
                <div class="flex flex-row text-normal">
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="supportive-yes"
                    data-cypress="supportive-yes"
                    v-model="is_supportive"
                    :checkedValue="true"
                    >I support</RadioButton
                  >
                  <RadioButton
                    class="flex-0 mr-6 mt-3 mb-1"
                    name="supportive-no"
                    data-cypress="supportive-no"
                    v-model="is_supportive"
                    :checkedValue="false"
                    >I object</RadioButton
                  >
                </div>
              </div>
              <label>
                <p class="text-normal-bold mb-2 mt-8">
                  <a class="text-red-star">*</a>What is your interest in this
                  proposal?
                </p>
                <div class="w-full mt-3 flex flex-col">
                  <textarea
                    class="w-full h-48 input-area"
                    data-cypress="interest"
                    v-model="interest"
                    :maxlength="interestMaxLength"
                    name="interest"
                    v-validate="'required'"
                    data-vv-as="Interest in Proposal"
                  ></textarea>
                  <!-- <span class="text-blue block"
                    >{{ interestCharactersRemaining }} characters
                    remaining</span
                  > -->
                  <FieldErrorMessage :message="errors.first('interest')" />
                </div>
              </label>
              <label>
                <p class="text-normal-bold mb-2 mt-8">
                  <a class="text-red-star">*</a>What is your reason for
                  supporting/objecting this proposal?
                </p>
                <p>
                  If objecting, please outline where this proposal does not
                  comply with the relevant policies in the
                  <a
                    href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=10"
                    target="_blank"
                    >GNB Place Naming Policy</a
                  >
                  and/or
                  <a
                    href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0007/199411/NSW_Addressing_User_Manual.pdf#page=105"
                    target="_blank"
                    >NSW Addressing User Manual</a
                  >
                </p>
                <div class="w-full mt-3 flex">
                  <textarea
                    class="w-full h-48 input-area"
                    data-cypress="comment"
                    v-model="comment"
                  ></textarea>
                </div>
              </label>
              <uploadScoped
                class="mt-8"
                label="Add Supporting Documentation"
                :important="false"
                :files="public_comment_reference_document"
                :drafted="true"
                stateProp="public_comment_reference_document"
                :uploadFile="uploadFileForComponent"
                v-on:deleteFile="
                  fileIndex =>
                    public_comment_reference_document.splice(fileIndex, 1)
                "
                name="public_comment_reference_document"
              />
              <div class="mt-8">
                <!-- Contact Details -->
                <div class=" mx-auto">
                  <h3 class="pb-3 mb-8 w-full text-title dividing-line-h">
                    Contact Details
                  </h3>

                  <div class="w-full flex">
                    <div class="w-1/2">
                      <p class="text-normal-bold mt-4 mb-2">
                        <a class="text-red-star">*</a>First Name
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter First Name Here..."
                        v-model="first_name"
                        data-cypress="firstname"
                        name="first_name"
                        v-validate="'required'"
                        data-vv-as="First Name"
                      />
                      <FieldErrorMessage
                        :message="errors.first('first_name')"
                      />
                    </div>
                    <div class="w-1/2 ml-8">
                      <p class="text-normal-bold  mt-4 mb-2">
                        <a class="text-red-star">*</a>Last Name
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter Last Name Here..."
                        v-model="last_name"
                        data-cypress="lastname"
                        name="last_name"
                        v-validate="'required'"
                        data-vv-as="Last Name"
                      />
                      <FieldErrorMessage :message="errors.first('last_name')" />
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-normal-bold  mt-4 mb-2">
                      <a class="text-red-star">*</a>Address Line 1
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter Address Here..."
                      v-model="address_line_1"
                      data-cypress="address1"
                      name="address_line_1"
                      v-validate="'required'"
                      data-vv-as="Address"
                    />
                    <FieldErrorMessage
                      :message="errors.first('address_line_1')"
                    />
                  </div>
                  <div class="w-full">
                    <p class="text-normal-bold mt-4 mb-2">
                      Address Line 2
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="e.g. Apartment number, floor number, etc"
                      data-cypress="address2"
                      v-model="address_line_2"
                      name="address_line_2"
                    />
                  </div>

                  <div class="w-full flex items-center">
                    <div class="w-1/2 mr-8">
                      <p class="text-normal-bold mt-4 mb-2">
                        <a class="text-red-star">*</a>Suburb/Town
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter Suburb Here..."
                        v-model="suburb"
                        data-cypress="suburb"
                        name="suburb"
                        v-validate="'required'"
                        data-vv-as="Suburb"
                      />
                      <FieldErrorMessage :message="errors.first('suburb')" />
                    </div>
                    <div class="w-1/2 flex items-center">
                      <div class="w-1/2 mr-8 mt-8 mb-2">
                        <p class="text-normal-bold">
                          <a class="text-red-star">*</a>State
                        </p>
                        <v-select
                          class="w-full mr-4 norns-select"
                          v-model="state"
                          :multiple="false"
                          :options="australianStates"
                          :searchable="false"
                          :close-on-select="true"
                          :select-on-tab="true"
                          :show-labels="false"
                          :hide-selected="true"
                          placeholder="Select a State"
                          v-validate="'required'"
                          name="state"
                          data-cypress="state-field"
                          data-vv-as="State"
                        ></v-select>
                        <FieldErrorMessage :message="errors.first('state')" />
                      </div>
                      <div class="w-1/2">
                        <p class="text-normal-bold  mt-4 mb-2">
                          <a class="text-red-star">*</a>Postcode
                        </p>
                        <input
                          type="text"
                          class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                          placeholder="Enter Postcode Here..."
                          v-model="postcode"
                          data-cypress="postcode"
                          name="postcode"
                          v-validate="'required'"
                          data-vv-as="Postcode"
                        />
                        <FieldErrorMessage
                          :message="errors.first('postcode')"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="w-full flex items-center">
                    <div class="w-1/2 mr-8">
                      <p class="text-normal-bold mt-4 mb-2">
                        Phone Number
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter Phone Number Here..."
                        v-model="phone_no"
                        data-cypress="phone"
                        name="phone_no"
                      />
                    </div>
                    <div class="w-1/2">
                      <p class="text-normal-bold mt-4 mb-2">
                        <a class="text-red-star" v-if="notify_outcome === true"
                          >*</a
                        >Email Address
                      </p>
                      <input
                        type="text"
                        class="w-full rounded-lg p-4 text-normal bg-grey-input text-grey-text self-center"
                        placeholder="Enter Email Address Here..."
                        v-model="email"
                        data-cypress="email"
                        name="email"
                        v-validate="
                          notify_outcome === true ? 'required|email' : ''
                        "
                        data-vv-as="Email Address"
                      />
                      <FieldErrorMessage :message="errors.first('email')" />
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-normal-bold  mt-4 mb-2">
                      <a class="text-red-star">*</a>Country
                    </p>
                    <input
                      type="text"
                      class="w-full rounded-lg p-4 mr-4 text-normal bg-grey-input text-grey-text self-center"
                      placeholder="Enter Country Here..."
                      v-model="country"
                      data-cypress="country"
                      name="Country"
                      v-validate="'required'"
                      data-vv-as="Address"
                    />
                    <FieldErrorMessage :message="errors.first('Country')" />
                  </div>
                </div>
                <div>
                  <p class="text-normal-bold mt-12">
                    Do you want to receive an email outcome for this proposal?
                  </p>
                  <p class="mt-4">
                    Please note that if you select 'Yes' you will be advised of
                    any decisions by the Board regarding this proposal via
                    email.
                  </p>
                  <div class="flex flex-row text-normal">
                    <RadioButton
                      class="flex-0 mr-6 mt-3 mb-1"
                      name="supportive-no"
                      data-cypress="email-yes"
                      v-model="notify_outcome"
                      :checkedValue="true"
                      >Yes</RadioButton
                    >
                    <RadioButton
                      class="flex-0 mr-6 mt-3 mb-1"
                      name="supportive-yes"
                      data-cypress="email-no"
                      v-model="notify_outcome"
                      :checkedValue="false"
                      >No</RadioButton
                    >
                  </div>
                </div>

                <div class="flex-grow pb-3 pt-3 ml-3 w-1/3 pr-3"></div>

                <div class="flex-grow">                  
                  <div class="flex flex-grow">
                   <Checkbox
                    class="w-full pl-0"            
                    v-model="personalInformationCollectionNotice">
                    <template slot:label>
                    Please tick to acknowledge the <a href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf" target="_blank" > GNB Personal Information Collection Notice </a>
                   </template>
                  </Checkbox>      
                  </div>                   
                </div>

                <div class="dividing-line-h w-full pt-4 pb-8 mb-8" />
                <div
                  class="flex w-full text-red font-bold"
                  v-if="duplicateComment"
                >
                  Thanks for your earlier comment on this proposal. If you wish
                  to make adjustments to your earlier comments, please follow
                  the link in the email confirmation we sent you at the time.
                </div>
                <div class="flex w-full justify-end">
                  <Button
                    class="button-red-hollow mt-4 mb-10"
                    data-cypress="cancel"
                    :disabled="$wait.is('submittingComment')"
                    @click="backToProposals"
                    >Cancel Comment</Button
                  >

                  <ButtonWithSpinner
                    class="button-blue mt-4 mb-10 ml-4"
                    data-cypress="submit"
                    :isSpinning="$wait.is('submittingComment')"
                    :disabled="
                      $wait.is('submittingComment') ||
                        !isFormValid ||
                        !!errors.items.length || !personalInformationCollectionNotice
                    "
                    @click="submitComment"
                    >Submit My Comment</ButtonWithSpinner
                  >
                </div>
              </div>
            </div>
          </template>
        </showLoading>
      </div>
    </div>
  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapPnpActions } = createNamespacedHelpers("pnpState");

import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "commentForm",
  data() {
    return {
      proposal: null,
      interestMaxLength: 1023,
      duplicateComment: false,
      australianStates: ["NSW", "QLD", "VIC", "SA", "WA", "ACT", "NT", "TAS"],
      is_supportive: null,
      interest: null,
      comment: null,
      notify_outcome: null,
      hidden_contact: null,
      public_comment_reference_document: [],
      first_name: null,
      last_name: null,
      address_line_1: null,
      address_line_2: null,
      suburb: null,
      state: null,
      postcode: null,
      phone_no: null,
      country: "Australia",
      email: null,
      personalInformationCollectionNotice: false
    };
  },
  async created() {
    await this.fetchProposal();
    this.duplicateComment = false;
  },
  watch: {
    email: {
      handler(oldVal, newVal) {
        if (oldVal !== newVal && this.duplicateComment === true) {
          this.duplicateComment = false;
        }
      }
    }
  },
  computed: {
    advertisementId() {
      return this.$route.params.advertisementId;
    },
    commentId() {
      return this.$route.params.commentId;
    },
    interestCharactersRemaining() {
      if (this.interest) {
        return this.interestMaxLength - this.interest.length;
      } else return this.interestMaxLength;
    },
    isFormValid() {
      return (
        (this.is_supportive === true || this.is_supportive === false) &&
        !!this.interest &&
        !!this.interest.length &&
        !!this.comment &&
        !!this.comment.length &&
        !!this.first_name &&
        !!this.first_name.length &&
        !!this.last_name &&
        !!this.last_name.length &&
        !!this.suburb &&
        !!this.suburb.length &&
        !!this.state &&
        !!this.postcode &&
        !!this.postcode.length &&
        !!this.country &&
        !!this.country.length &&
        (this.notify_outcome ? !!this.email && !!this.email.length : true)
      );
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    back() {
      this.$router.push({ name: "currentPlaceNameProposal" });
    },
    backToProposals() {
      this.$router.push({ name: "currentPlaceNameProposals" });
    },
    fetchProposal: waitFor("fetchingProposalForComment", async function() {
      try {
        if (this.commentId !== "create") {
          const token = this.$route.query ? this.$route.query.token : null;
          const unique = this.$route.query ? this.$route.query.unique : null;
          const proposal = await pnpCRUD.getAdvertisedProposal(
            this.advertisementId
          );
          this.proposal = proposal;
          const preFill = await pnpCRUD.getAdvertisedProposalForUpdate({
            token,
            unique,
            comment_identifier: this.commentId
          });

          this.is_supportive = preFill.is_supportive;
          this.interest = preFill.interest;
          this.comment = preFill.comment;
          this.notify_outcome = preFill.notify_outcome;
          this.hidden_contact = preFill.hidden_contact;
          this.public_comment_reference_document =
            preFill.public_comment_reference_document || [];
          this.first_name = preFill.first_name;
          this.last_name = preFill.last_name;
          this.address_line_1 = preFill.address_line_1;
          this.address_line_2 = preFill.address_line_2;
          this.suburb = preFill.suburb;
          this.state = preFill.state;
          this.postcode = preFill.postcode;
          this.phone_no = preFill.phone_no;
          this.country = preFill.country;
          this.email = preFill.email;
        } else {
          const proposal = await pnpCRUD.getAdvertisedProposal(
            this.advertisementId
          );
          this.proposal = proposal;
        }
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to retrieve proposal",
          text: error
        });
      }
    }),
    async uploadFileForComponent(payload) {
      const s3FileData = await this.uploadFile(
        Object.assign({}, payload, {
          componentOnly: true
        })
      );
      if (s3FileData) {
        this.public_comment_reference_document.push(s3FileData);
      }
    },
    submitComment: waitFor("submittingComment", async function() {
      try {
        const payload = Object.assign(
          {},
          {
            is_supportive: this.is_supportive,
            interest: this.interest,
            comment: this.comment,
            notify_outcome: this.notify_outcome,
            hidden_contact: this.hidden_contact,
            public_comment_reference_document: this
              .public_comment_reference_document,
            first_name: this.first_name,
            last_name: this.last_name,
            address_line_1: this.address_line_1,
            address_line_2: this.address_line_2,
            suburb: this.suburb,
            state: this.state,
            postcode: this.postcode,
            phone_no: this.phone_no,
            country: this.country,
            email: this.email,
            advertisement_identifier: this.proposal.advertisement_identifier
          }
        );
        if (this.commentId === "create") {
          await pnpCRUD.createAdvertisedProposalComment(payload);
        } else {
          const token = this.$route.query ? this.$route.query.token : null;
          const unique = this.$route.query ? this.$route.query.unique : null;
          await pnpCRUD.updateAdvertisedProposalComment(
            Object.assign({}, payload, {
              token,
              unique,
              comment_identifier: this.commentId
            })
          );
        }

        this.$router.push({ name: "placeNameProposalPublicCommentSubmitted" });
      } catch (error) {
        if (error.message && error.message.includes("409")) {
          this.duplicateComment = true;
        } else {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Unable to save comment",
            text: error
          });
        }
      }
    }),
    formatDate: date => {
      const newDate = moment(date).format("DD MMM, YYYY");
      return newDate;
    }
  }
};
</script>

<style scoped>
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: cover;
}
.logoPos {
  top: 30px;
  left: 30px;
  position: fixed;
}

.multiselect__single {
  background: none !important;
  background-color: none !important;
  color: black;
}
</style>
