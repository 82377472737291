<template>
  <div class="base-container text-black">
    <section class="mb-8">
      <h2 class="text-black text-title mb-8 self-center">
        Submit Gazettal Notices
      </h2>
      <div
        class="w-full bg-white text-black rounded-lg pt-16 pb-16 mt-4 justify-center flex flex-wrap"
      >
        <div class="w-full justify-center flex mb-8 pt-5">
          <img
            src="../../assets/Images/press-release.png"
            class
            alt="Press release image"
          />
        </div>
        <div class="justify-center text-center">
          <showLoading :isLoading="'gazetting roads'" />
          <div class="flex pb-2" v-for="(road, index) of gazettedRoads" :key="index">
            <span
              :class="[road.error ? 'invalid' : 'valid']"
              class="font-bold text-center text-sm text-white flex pr-8"
            >
              <font-awesome-icon
                v-if="road.error"
                icon="times"
                class="mx-auto self-center"
              />
              <font-awesome-icon
                v-else
                icon="check"
                class="mx-auto self-center"
              />
            </span>
            <span
              >Your Gazettal Notice for <b>{{ road.name }}</b> has
              {{ road.error ? "failed to submit" : "been submitted" }}.</span
            >
          </div>
        </div>
      </div>
      <div class="flex pt-4 text-center pb-10">
        <router-link :to="{ name: 'proposals' }" class="w-1/3 ml-auto">
          <button class="button-blue" :disabled="$wait.any">
            Go to My Proposals Dashboard
          </button>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { createGazettalNotice } from "../../helpers/rnpCRUD";

export default {
  props: ["roads", "uploads"],
  data() {
    return {
      gazettedRoads: [],
      gazetted: false
    };
  },
  created() {
    this.gazetteRoads();
  },
  methods: {
    async gazetteRoads() {
      this.$wait.start("gazetting roads");
      const companionRoads = this.roads.map(road => road.identifier);
      for (const road of this.roads) {
        let error = false;
        try {
          await createGazettalNotice({ ...road, companionRoads }, this.uploads);
        } catch {
          error = true;
        }
        this.gazettedRoads.push({ name: `${road.roadname} ${road.road_type}`, error });
        this.gazetted = true;
      }
      this.$wait.end("gazetting roads");
    }
  }
};
</script>

<style scoped>

.valid {
  color: rgb(43, 169, 2);
  width: 25px;
}

.invalid {
  color: #ad0000;
  width: 25px;
}

</style>