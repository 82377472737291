<template>
  <div class="text-black mx-16 my-16 bg-white p-8">
    <DataTableReport
      :columns="columns"
      :rows="rows"
      :title="`Place Names Proposal Summary`"
      :recordType="`proposals`"
      :paginationProps="pagination"
      :isLoading="$wait.is('fetchingRecords')"
      v-on:updatePage="updatePage"
      v-on:sortDesc="sortDesc"
      v-on:sortAsc="sortAsc"
      :filterLists="{
        localityList,
        affectedLocalitiesList,
        lgaList,
        parishList,
        countyList,
        electorateList,
        typeList,
        aboriginalList,
        multiculturalList,
        organisationList,
        statusList,
        usersList
      }"
      :filters="filters"
      :filterValues="qsParams"
      v-on:resetFilters="resetFilterSearchParams"
      v-on:filterChange="setSearchParamsFromFilters"
      :pageResultCountList="pageResultCountList"
      :showDates="true"
      :showKeywordSearch="true"
      v-on:updatePageLimit="updatePageLimit"
      v-on:setDateTo="setDateToParam"
      v-on:setDateFrom="setDateFromParam"
      v-on:searchViaKeyword="setGeographicalNameParam"
      v-on:download="downloadCsv"
      :isAdmin="isAdmin"
    />
  </div>
</template>

<script>
import DataTableReport from "./data-table/index.vue";
import pnpCRUD from "../../helpers/pnpCRUD.js";
import { mapGetters, createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import { waitFor } from "vue-wait";

import moment from "moment";

const FileSaver = require("file-saver");

const defaultParams = {
  includeTotal: true,
  orderDirection: "ASC",
  orderBy: "geographical_name"
};

export default {
  name: "placeNameReport",
  components: { DataTableReport },
  methods: {
    async setSearchParamsFromFilters(payload) {
      this.pagination.page = 1;
      this.qsParams.includeArchived = true;
      this.qsParams = {
        ...this.qsParams,
        [payload.name]: payload.filters[payload.name].value
      };
      await this.fetchRecords();
    },
    async resetFilterSearchParams() {
      this.pagination.page = 1;
      this.qsParams = { ...defaultParams, includeArchived: false };
      await this.fetchRecords();
    },
    async updatePage(page) {
      this.pagination.page = page;
      await this.fetchRecords();
    },
    async sortDesc(field) {
      this.qsParams.orderDirection = "DESC";
      this.qsParams.orderBy = field;
      //this.qsParams.includeArchived = true;
      await this.fetchRecords();
    },
    async sortAsc(field) {
      this.qsParams.orderDirection = "ASC";
      this.qsParams.orderBy = field;
      //this.qsParams.includeArchived = true;
      await this.fetchRecords();
    },
    async updatePageLimit(limit) {
      this.pagination = Object.assign(
        {},
        { ...this.pagination },
        { limit: limit, page: 1 }
      );
      await this.fetchRecords();
    },
    async setDateToParam(to) {
      this.pagination.page = 1;
      // this.qsParams.toDate = to;
      // this.qsParams.includeArchived = true;
      this.qsParams = {
        ...this.qsParams,
        to,
        includeArchived: true
      };
      await this.fetchRecords();
    },
    async setDateFromParam(from) {
      this.pagination.page = 1;
      // this.qsParams.fromDate = from;
      // this.qsParams.includeArchived = true;
      this.qsParams = {
        ...this.qsParams,
        from,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    async setGeographicalNameParam(name) {
      this.pagination.page = 1;
      // this.qsParams.includeArchived = true;
      // this.qsParams.geographical_name = name;
      this.qsParams = {
        ...this.qsParams,
        geographical_name: name,
        includeArchived: true
      };

      await this.fetchRecords();
    },
    downloadCsv: waitFor("fetchingRecords", async function() {
      try {
        const data = await pnpCRUD.downloadPlacenamesReportCsv({
          ...this.qsParams,
          to: this.qsParams.to
            ? moment(this.qsParams.to, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
          from: this.qsParams.from
            ? moment(this.qsParams.from, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
          type: this.qsParams.type
            ? Object.keys(this.typeObj).find(
                k => this.typeObj[k] === this.qsParams.type
              )
            : ""
        });

        const blob = new Blob([data.csv], {
          type: "text/csv;charset=utf-8"
        });
        FileSaver.saveAs(blob, data.filename);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error downloading records",
          text: error
        });
      }
    }),
    fetchRecords: waitFor("fetchingRecords", async function() {
      try {
        const records = await pnpCRUD.fetchPlaceNameRecordsForReport({
          ...this.qsParams,
          to: this.qsParams.to
            ? moment(this.qsParams.to, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
          from: this.qsParams.from
            ? moment(this.qsParams.from, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
          type: this.qsParams.type
            ? Object.keys(this.typeObj).find(
                k => this.typeObj[k] === this.qsParams.type
              )
            : "",
          limit: this.pagination.limit,
          page: this.pagination.page
        });
        this.rows = records.proposals;
        this.pagination.record_count = records.totalProposals;
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error fetching records",
          text: error
        });
      }
    }),
    fetchFeatureLists: waitFor("fetchingRecords", async function() {
      const [
        localityList,
        affectedLocalitiesList,
        lgaList,
        parishList,
        countyList,
        electorateList,
        organisationList,
        statusList,
        usersList
      ] = await Promise.all([
        pnpCRUD.fetchPlaceNameFeatureList("LOCALITY"),
        pnpCRUD.getLocalityListWithLgas(),
        pnpCRUD.fetchPlaceNameFeatureList("LGA"),
        pnpCRUD.fetchPlaceNameFeatureList("PARISH"),
        pnpCRUD.fetchPlaceNameFeatureList("COUNTY"),
        pnpCRUD.fetchPlaceNameFeatureList("ELECTORATE"),
        pnpCRUD.fetchOrganisationsForReport(),
        pnpCRUD.getPlaceNameStatuses(),
        pnpCRUD.fetchUsersForReport()
      ]);

      this.localityList = localityList;
      this.affectedLocalitiesList = affectedLocalitiesList;
      this.lgaList = lgaList;
      this.parishList = parishList;
      this.countyList = countyList;
      this.electorateList = electorateList;
      this.statusList = statusList;
      this.usersList = usersList;

      // transform from array of strings to key/value pairs
      // allows us to add the 'Member of Public' option below
      this.organisationList = organisationList.map(organisation => {
        return {
          label: organisation,
          value: organisation
        };
      });
      this.organisationList.unshift({
        label: "Member of Public",
        value: "PUBLIC"
      });

      // initiate static lists here
      this.typeList = ["Place Name", "Address Locality", "Boundary Amendment"];
      //this.typeList = ["PLACENAME", "LOCALITY", "BOUNDARY"];
      this.typeObj = {
        PLACENAME: "Place Name",
        LOCALITY: "Address Locality",
        BOUNDARY: "Boundary Amendment"
      };
      this.aboriginalList = ["Yes", "No", "Unknown"];
      this.multiculturalList = ["Yes", "No", "Unknown"];
      this.pageResultCountList = [5, 10, 20, 50, 100];
    })
  },
  computed: {
    ...mapUserGetters(["user"]),
    ...mapGetters(["defaultOrganisationLga", "showLoginModal"]),
    isAdmin() {
      return !!this.user.isAdmin;
    }
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetchRecords();
      }
    }
  },
  async created() {
    this.qsParams = { ...defaultParams };
    await this.fetchFeatureLists();
    if (this.lgaList.includes(this.defaultOrganisationLga)) {
      this.qsParams.lga = this.defaultOrganisationLga;
    }
    await this.fetchRecords();
  },
  data() {
    return {
      localityList: [],
      affectedLocalitiesList: [],
      lgaList: [],
      parishList: [],
      countyList: [],
      electorateList: [],
      typeList: [],
      organisationList: [],
      aboriginalList: [],
      multiculturalList: [],
      pageResultCountList: [],
      statusList: [],
      usersList: [],
      pagination: {
        record_count: 0,
        page: 1,
        limit: 10
      },
      qsParams: {
        includeTotal: true,
        orderDirection: "ASC",
        orderBy: "geographical_name"
      },
      filters: [
        {
          name: "type",
          label: "Proposal Type",
          field: "type",
          type: "text",
          options: "typeList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "lga",
          label: "LGA",
          type: "text",
          options: "lgaList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "locality",
          label: "Locality",
          type: "text",
          options: "localityList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "affected_localities",
          label: "Affected Locality",
          type: "text",
          options: "affectedLocalitiesList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "electorate",
          label: "State Electorate",
          type: "text",
          options: "electorateList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "organisation_name",
          label: "Naming Proponent",
          type: "object",
          options: "organisationList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "submitted_by",
          label: "Submitted By",
          type: "text",
          options: "usersList",
          isAdminView: true,
          isAuthorityView: true
        },
        {
          name: "aboriginal_name",
          label: "Aboriginal Name",
          type: "text",
          options: "aboriginalList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "multicultural",
          label: "Multicultural Name",
          type: "text",
          options: "multiculturalList",
          isAdminView: true,
          isAuthorityView: false
        },
        {
          name: "display_status",
          label: "Status",
          type: "text",
          options: "statusList",
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      columns: [
        {
          label: "Place Name Proposal",
          field: "geographical_name",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Proposal Type",
          field: "type",
          type: "type",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "LGA",
          field: "lga",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Locality",
          field: "locality",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Affected Locality",
          field: "affected_localities",
          type: "text",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "State Electorate",
          field: "electorate",
          type: "text",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Naming Proponent",
          field: "organisation_name",
          type: "text",
          ifNull: "Member of Public",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Submitted By",
          field: "submitted_by",
          type: "text",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Gazettal Date",
          field: "proposal_gazettal_date",
          type: "date",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Submitted Date",
          field: "submitted_date",
          type: "date",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        },
        {
          label: "Aboriginal Name",
          field: "aboriginal_name",
          type: "text",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Multicultural Name",
          field: "multicultural",
          type: "text",
          isSortable: true,
          default: false,
          isAdminView: true,
          isAuthorityView: false
        },
        {
          label: "Status",
          field: "display_status",
          type: "status",
          isSortable: true,
          default: true,
          isAdminView: true,
          isAuthorityView: true
        }
      ],
      rows: []
    };
  }
};
</script>
