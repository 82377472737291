<template>
  <div class="base-container text-black mb-12">
    <span class="flex items-center text-black mt-3">
      <h1 class="text-title" data-cypress="header-text">Manage RNAs</h1>
      <router-link
        :to="{ name: 'rnasAdd' }"
        class="ml-auto button-blue-hollow"
        data-cypress="addnewrna-button"
        >Add New RNA</router-link
      >
    </span>
    <div class="w-full mt-4">
      <input
        type="text"
        class="input-area bg-white w-full"
        placeholder="Search by RNA name..."
        @input="searchRna"
      />
    </div>
    <NetworkError v-if="fetchError" />
    <Loading-spinner
      v-if="!fetchError"
      v-wait:visible="'loadingRnaList'"
      class="flex-0"
    />
    <rnaList v-if="!fetchError" :rnas="rnas" v-wait:hidden="'loadingRnaList'">
      <template slot-scope="{ rna }">
        <div
          class="w-12 h-full flex-auto flex flex-col content-around items-center "
        >
          <router-link
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            data-cypress="edit-rna-link"
            :to="{
              name: 'rnasEdit',
              params: { id: rna.identifier }
            }"
            :title="`Edit ${rna.authority_name} details`"
            ><font-awesome-icon icon="edit" class="flex-auto"
          /></router-link>
          <router-link
            :to="{
              name: 'administerUsers',
              query: { road_naming_authority_id: rna.identifier }
            }"
            data-cypress="users-rna-link"
            :title="`Manage ${rna.authority_name}'s users`"
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            ><font-awesome-icon icon="users" class="flex-auto"
          /></router-link>
          <router-link
            :to="{
              name: 'proposals',
              query: { road_naming_authority_id: rna.identifier }
            }"
            data-cypress="proposals-rna-link"
            :title="`View ${rna.authority_name}'s proposals`"
            class="flex-auto flex justify-center items-center font-bold actions w-full h-1/4 action-button"
            ><font-awesome-icon icon="road" class="flex-auto"
          /></router-link>
          <!-- <button
            :title="`Suspend ${rna.authority_name}`"
            class=" flex-auto flex justify-center items-center text-red font-bold h-1/4 w-full action-button suspend-user "
            data-cypress="suspend-button"
            disabled="disabled"
          >
            <font-awesome-icon icon="lock" class="flex-auto" />
          </button> -->
        </div>
      </template>
    </rnaList>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RnaList from "./rnaList";
export default {
  components: {
    RnaList
  },
  async mounted() {
    await this.fetch();
  },

  methods: {
    searchRna(event) {
      this.filter = event.target.value.toLowerCase();
    },
    async fetch() {
      try {
        this.$wait.start("loadingRnaList");
        await this.getRnaList();
        this.$wait.end("loadingRnaList");
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (e.response.status === 500) {
          this.fetchError = true;
        }
        this.$wait.end("loadingRnaList");
      }
    },
    ...mapActions(["getRnaList"])
  },
  computed: {
    ...mapGetters(["rnaList", "showLoginModal"]),
    rnas: {
      get() {
        return this.rnaList.filter(rna =>
          (rna.authority_name || "").toLowerCase().includes(this.filter)
        );
      }
    }
  },
  data: function() {
    return {
      filter: "",
      fetchError: false
    };
  },
  watch: {
    async showLoginModal() {
      if (!this.showLoginModal) {
        // a login modal has been closed, so we'd better refresh
        await this.$nextTick();
        this.fetch();
      }
    }
  }
};
</script>
<style>
.actions {
  color: #002664;
}
.action-button:hover {
  background: #002664;
  color: white;
}

.action-button.suspend-user:hover {
  background: #e3342f;
  color: #fff;
}
</style>
