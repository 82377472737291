<template>
  <div class="text-black">
    <div class="w-full flex bg-white">
      <div class="w-5/6 mx-auto" v-bind:class="{ 'py-16': !isPublic }">
        <h3 class="pb-3 mb-4 w-full text-title dividing-line-h">
          Additional Information
        </h3>
        <label class="pr-4 w-2/3">
          <p class="text-normal-bold mb-2 mt-6">
            Any other relevant information regarding this proposal?
          </p>
          <textarea
            class="w-full h-48 input-area"
            data-cypress="additional_information-field"
            v-model="additional_information"
            :disabled="readOnly"
          ></textarea>
        </label>
        <uploadScoped
          class="mt-4"
          data-cypress="upload-additional-information"
          label="Attach supporting material for the additional information"
          :important="false"
          :files="additional_information_reference_document"
          :drafted="true"
          :stateKey="uploadKey"
          stateProp="additional_information_reference_document"
          :uploadFile="params => uploadFile(Object.assign({}, params, {}))"
          v-on:deleteFile="
            fileIndex =>
              deleteUploadedFile({
                key: uploadKey,
                prop: 'additional_information_reference_document',
                index: fileIndex
              })
          "
          :disabled="readOnly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY,
  PROPOSAL_TYPE_BOUNDARY
} from "@/components/proposals/place/pnp-constants.js";

export default {
  data() {
    return {
      evaluation: false
    };
  },
  props: {
    readOnly: {
      type: Boolean
    },
    proposalType: {
      type: String
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    additional_information: {
      get() {
        switch (this.proposalType) {
          case PROPOSAL_TYPE_PLACE: {
            return this.get_place_name_additional_information();
          }
          case PROPOSAL_TYPE_LOCALITY: {
            return this.get_address_locality_additional_information();
          }
          case PROPOSAL_TYPE_BOUNDARY: {
            return this.get_boundary_amendment_additional_information();
          }
          default: {
            return null;
          }
        }
      },
      set(value) {
        switch (this.proposalType) {
          case PROPOSAL_TYPE_PLACE: {
            return this.set_place_name_additional_information(value);
          }
          case PROPOSAL_TYPE_LOCALITY: {
            return this.set_address_locality_additional_information(value);
          }
          case PROPOSAL_TYPE_BOUNDARY: {
            return this.set_boundary_amendment_additional_information(value);
          }
          default: {
            return null;
          }
        }
      }
    },
    additional_information_reference_document: {
      get() {
        switch (this.proposalType) {
          case PROPOSAL_TYPE_PLACE: {
            return this.get_place_name_additional_information_reference_document();
          }
          case PROPOSAL_TYPE_LOCALITY: {
            return this.get_address_locality_additional_information_reference_document();
          }
          case PROPOSAL_TYPE_BOUNDARY: {
            return this.get_boundary_amendment_additional_information_reference_document();
          }
          default: {
            return null;
          }
        }
      },
      set(value) {
        switch (this.proposalType) {
          case PROPOSAL_TYPE_PLACE: {
            return this.set_place_name_additional_information_reference_document(value);
          }
          case PROPOSAL_TYPE_LOCALITY: {
            return this.set_address_locality_additional_information_reference_document(
              value
            );
          }
          case PROPOSAL_TYPE_BOUNDARY: {
            return this.set_boundary_amendment_additional_information_reference_document(
              value
            );
          }
          default: {
            return null;
          }
        }
      }
    },
    uploadKey() {
      switch (this.proposalType) {
        case PROPOSAL_TYPE_PLACE: {
          return "place_name";
        }
        case PROPOSAL_TYPE_LOCALITY: {
          return "address_locality";
        }
        case PROPOSAL_TYPE_BOUNDARY: {
          return "boundary_amendment";
        }
        default: {
          return null;
        }
      }
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_place_name_additional_information",
      "get_address_locality_additional_information",
      "get_boundary_amendment_additional_information",
      "get_place_name_additional_information_reference_document",
      "get_address_locality_additional_information_reference_document",
      "get_boundary_amendment_additional_information_reference_document"
    ]),
    ...mapPnpMutations([
      "deleteUploadedFile",
      "set_place_name_additional_information",
      "set_address_locality_additional_information",
      "set_boundary_amendment_additional_information",
      "set_place_name_additional_information_reference_document",
      "set_address_locality_additional_information_reference_document",
      "set_boundary_amendment_additional_information_reference_document"
    ])
  }
};
</script>
