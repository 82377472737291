<template>
  <div class="base-container text-black">
    <modal
      name="roadname-bulk-upload-lga"
      :width="'40%'"
      height="auto"
      :classes="'rounded-lg bg-white py-6'"
    >
      <div class="w-9/10 mx-auto flex flex-col justify-center">
        <a @click="hideModal()" class="cursor-pointer ml-auto">
          <ErrorIcon classNames="w-5" />
        </a>
        <div class="w-full mb-10">
          <p class="text-normal">
            Please select a Local Government Area. Note that all roads in a bulk
            upload submission must have the same Local Government Area.
          </p>
          <p class="text-normal-bold mb-2">
            <span class="text-red-star">*</span>Local Government Area
          </p>
          <norns-select
            class="w-full mr-4 norns-select"
            v-model="lgaName"
            :multiple="false"
            :options="lgasLimited"
            :searchable="true"
            :close-on-select="true"
            placeholder="Select LGA..."
            name="LGA"
            data-cypress="lga-select"
            v-validate="'required'"
            data-vv-as="Local Government Authority"
            :select-on-tab="true"
          >
            <span slot="noOptions">Loading...</span>
          </norns-select>
          <button
            @click="setLga()"
            :disabled="!lgaName"
            class="button-blue w-1/2 text-sm pt-4 mx-auto mt-20 block"
          >
            Download
          </button>
        </div>
      </div>
    </modal>
    <div class="mt-6 justify-center w-full block">
      <a
        @click="() => $router.push({ name: 'proposals' })"
        class="cursor-pointer flex self-center"
        data-cypress="back-arrow"
      >
        <BackArrowWithTitle title="Road Name Proposal" />
      </a>
      <!--h1 class="text-title" data-cypress="header-text">Road Name Proposal</h1-->
      <p class="mb-10">
        Welcome. Here you can create your Road Name Proposal. A Road Name
        Proposal can consist of a single road or multiple roads. You can add the
        details for each road using ‘Add New Road’ and provide the information
        sought. Once a single road name proposal is completed you can repeat
        that process to add more roads to the proposal, or you can use the tick
        boxes to confirm that your proposal complies with NSW Road Naming rules
        and then submit the proposal.
      </p>

      <!-- acting behalf of rna -->
      <div v-if="this.user.isAdmin">
        <h2>On Behalf of RNA</h2>
        <rnaSelect
          class="input-area input-area-alt"
          :rnaList="rnaList"
          v-model="rna"
          data-cypress="authority"
          name="rnaEntity"
          id="rnaEntity"
          :disabled="$wait.any"
        />
      </div>

      <h2>Reference Name</h2>
      <p class="mb-4">
        You can give this proposal a reference name to make future
        identification easier.
      </p>

      <input
        type="text"
        v-model="referenceName"
        name="referenceName"
        class="w-full input-area-alt mb-16"
        data-cypress="reference-field"
        placeholder="Enter reference name here"
        :disabled="$wait.any"
      />
    </div>
    <div class="justify-center w-full block">
      <div class="flex justify-between">
        <button
          type="button"
          @click="addNewRoad"
          class="button-blue-hollow"
          data-cypress="addroad-button"
          :disabled="$wait.any"
        >
          Add New Road
        </button>
        <ButtonWithSpinner
          type="button"
          class="button-blue-hollow"
          data-cypress="get-template-button"
          :disabled="$wait.any"
          @click="getUploadTemplate"
          :isSpinning="$wait.is('downloading template')"
          >Get Bulk Upload Template</ButtonWithSpinner
        >
        <input
          type="file"
          class="hidden"
          ref="fileInput"
          data-cypress="file-upload-input"
          @change="filesChange($event.target.files)"
          :disabled="$wait.any"
          :accept="['.xlsx', '.xls']"
        />
        <button
          type="button"
          class="button-blue-hollow"
          data-cypress="bulkupload-button"
          :disabled="$wait.any"
          @click="$refs.fileInput.click()"
        >
          Bulk Upload
        </button>
      </div>
      <h2 class="pt-3">
        New Road Names - {{ this.roadsForProposal.length }} Roads
      </h2>
      <div class="flex items-center my-4 justify-between">
        <input
          type="text"
          class="w-2/3 mr-4 input-area input-area-alt self-center"
          data-cypress="search-field"
          placeholder="Narrow search by road name or ID..."
          v-model="roadSearch"
          :disabled="$wait.any"
        />

        <div
          v-if="totalEvaluationIssues !== null"
          class="py-4 px-6 rounded-full font-bold text-white whitespace-no-wrap"
          :class="[totalEvaluationIssues > 0 ? 'Issue' : 'Valid']"
        >
          <!--<Loading v-if="$wait.is('evaluating')" class="flex - 0" />-->
          {{
            totalEvaluationIssues === null
              ? ""
              : totalEvaluationIssues + " Potential Issues"
          }}
        </div>
      </div>

      <div v-if="roadsForProposal.length === 0">
        <div class="w-full bg-white rounded-lg">
          <div class="w-full flex justify-center items-center">
            <img
              src="../../../assets/Images/Icon_Gazettal.png"
              class="w-32 mx-16 mt-16 mb-4"
            />
          </div>
          <p
            class="pb-16 font-bold text-grey-text text-center"
            data-cypress="noroads-text"
          >
            No roads have been added to this proposal
          </p>
        </div>
      </div>
      <div v-else>
        <roadInProposal
          v-for="(road, index) in filteredRoads"
          v-bind:key="index"
          :road="road"
          :removeRoad="removeRoad"
          :index="index"
          v-on:evaluation-results="handleEvaluationCreation"
          :evaluation="getIssueCount(index)"
        />
      </div>

      <!-- Road Name Agreements -->
      <h2 class="mt-16">Road Name Agreements</h2>
      <div class="w-full bg-white rounded-lg text-normal">
        <div class="mx-auto w-9/10 py-8">
          <Checkbox
            data-cypress="livingConfirmationCheckbox"
            v-model="checkBoxChecked[0]"
            v-validate="'required:true'"
            :error="errors.first('Living')"
            name="Living"
            class="w-full"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that no roads in this
            proposal are named after any currently living person.
          </Checkbox>

          <Checkbox
            v-model="checkBoxChecked[1]"
            v-validate="'required:true'"
            :error="errors.first('Spelling')"
            name="Spelling"
            class="w-full"
            data-cypress="spellingConfirmationCheckbox"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that all roads have the
            same spelling as the name from which they are derived from.
          </Checkbox>
          <Checkbox
            v-model="checkBoxChecked[2]"
            v-validate="'required:true'"
            :error="errors.first('Commercial')"
            name="Commercial"
            class="w-full"
            data-cypress="commercialNameConfirmationCheck"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that I have not used a
            commercial or business name that is currently active for any road
            names.
          </Checkbox>
          <Checkbox
            v-model="checkBoxChecked[3]"
            v-validate="'required:true'"
            :error="errors.first('Given Name')"
            name="Given Name"
            class="w-full"
            data-cypress="givenNameConfirmationCheckbox"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that I have not used a
            given or first name in conjunction with a surname for any road
            names.
          </Checkbox>
          <Checkbox
            v-model="checkBoxChecked[4]"
            v-validate="'required:true'"
            data-cypress="abbreviationsConfirmationCheckbox"
            :error="errors.first('Abbreviations')"
            name="Abbreviations"
            class="w-full"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that I have not used
            abbreviations or acronyms for or in any road names. (Except St can
            be used for Saint)
          </Checkbox>
          <Checkbox
            v-model="checkBoxChecked[5]"
            v-validate="'required:true'"
            :error="errors.first('Authorization')"
            name="Authorization"
            class="w-full"
            data-cypress="authorisationConfirmationCheckbox"
            :disabled="$wait.any"
          >
            <span class="text-red">*</span>I confirm that I am authorised by
            this Road Naming Authority (as defined by the Roads Act 1993,
            section 162) to submit this road name proposal.
          </Checkbox>
        </div>
      </div>
      <div class="flex" name="3 buttons">
        <ButtonWithSpinner
          class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
          data-cypress="delete-button"
          @click="handleDeleteProposal"
          :disabled="$wait.any"
          >Delete Proposal</ButtonWithSpinner
        >
        <ButtonWithSpinner
          class="button-red-hollow flex relative w-2/6 mt-4 mb-10 ml-auto"
          data-cypress="cancel-button"
          @click="handleCancelChanges"
          :disabled="$wait.any"
        >
          Cancel
          {{ $route.params.proposalId !== "create" ? "Changes" : "" }}
        </ButtonWithSpinner>
        <ButtonWithSpinner
          class="button-blue-hollow flex relative w-2/6 mt-4 mb-10 ml-4"
          data-cypress="savedraft-button"
          @click="handleSubmitDraft"
          :isSpinning="$wait.is('saving draft')"
          :disabled="$wait.any || !roadsForProposal.length"
          >Save as Draft</ButtonWithSpinner
        >
        <ButtonWithSpinner
          class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
          data-cypress="submit-button"
          @click="handleSubmitProposal"
          :isSpinning="$wait.is('saving proposal')"
          :disabled="
            $wait.any || !roadsForProposal.length || !allCheckBoxes || hasErrors
          "
          >Submit Proposal</ButtonWithSpinner
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, createNamespacedHelpers, mapGetters } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
import { deleteDraftedProposal } from "../../../helpers/proposalsCRUD";
import XLSX from "xlsx";
import lgaListMixin from "../../../mixins/lga-list-filter";
import { getBulkUploadTemplate } from "@/helpers/proposalsCRUD";
import FileSaver from "file-saver";

import roadInProposal from "./proposal-edit/road-in-proposal";
import { evaluateRoadnameNewBulk } from "../../../helpers/validations";

export default {
  components: {
    roadInProposal
  },
  mixins: [lgaListMixin],
  props: ["create", "proposalBucket", "isDraft"],
  computed: {
    ...mapUserGetters(["user"]),
    ...mapGetters(["rnaList", "roadsForProposal"]),
    allCheckBoxes() {
      let allTrue = true;
      for (const b of this.checkBoxChecked) {
        if (!b) {
          allTrue = false;
          break;
        }
      }
      return allTrue;
    },
    referenceName: {
      get: function() {
        return this.proposalBucket.reference_no;
      },
      set: function(value) {
        this.setReferenceName(value);
      }
    },
    filteredRoads() {
      const filtered = this.$store.getters.roadsForProposal.filter(road => {
        return road.roadname
          .toLowerCase()
          .includes((this.roadSearch || "").toLowerCase());
      });

      let validityFilter = [];
      switch (this.validityFilter) {
        case "All Roads":
          break;
        case "Potential issue":
          validityFilter = Object.keys(this.evaluationReports).reduce(
            (memo, roadName) => {
              if (this.evaluationReports[roadName] > 0) {
                memo.push(roadName);
              }

              return memo;
            },
            validityFilter
          );
          break;
        case "Valid":
          validityFilter = Object.keys(this.evaluationReports).reduce(
            (memo, roadName) => {
              if (!this.evaluationReports[roadName] == 0) {
                memo.push(roadName);
              }

              return memo;
            },
            validityFilter
          );
          break;
        default:
          break;
      }

      if (!validityFilter.length) return filtered;

      return filtered.filter(roadNameProposal =>
        validityFilter.some(name => roadNameProposal.roadname === name)
      );
    },
    rna: {
      get() {
        return this.proposalBucket.road_naming_authority_id;
      },
      set(value) {
        this.setBucketRNA(value);
      }
    },
    totalEvaluationIssues() {
      if (!this.evaluationResults) {
        return null;
      }

      let totalIssues = 0;
      this.evaluationResults.forEach(
        data =>
          (totalIssues =
            totalIssues +
            (!data.passes && data.breaches.issueCount
              ? data.breaches.issueCount
              : 0))
      );
      return totalIssues;
    },
    hasErrors() {
      return (
        this.$store.getters.roadsForProposal.filter(road => {
          return !road.distance || Object.keys(road.geometry).length < 1;
        }).length > 0
      );
    }
  },
  data() {
    return {
      roadNameSearchQuery: "",
      roadTypes: ["All Road Types", "Public", "Pre-Approved", "Non-Dedicated"],
      validityFilter: "All Roads",
      roadSearch: "",
      checkBoxChecked: [false, false, false, false, false, false],
      evaluationReports: {},
      validityTexts: [
        "Valid Road Name",
        "Potential Issue Found",
        "Invalid Road Name"
      ],
      validityReference: ["Valid", "Potential issue", "All Roads"],
      lgaName: "",
      evaluationResults: null
    };
  },
  async mounted() {
    if (this.user.isAdmin) {
      this.$wait.start("loading");
      if (this.user.isAdmin && !this.$store.getters.rnaList.length) {
        await this.$store.dispatch("getRnaList");
      }

      this.$wait.end("loading");
    }
    !this.proposalBucket.road_naming_authority_id &&
      this.setBucketRNA(this.user.road_naming_authority_id);

    //console.log(this.roadsForProposal);
    // validate all roads here!

    if (this.roadsForProposal.length) {
      //this.$wait.start("evaluating");
      const allResults = await evaluateRoadnameNewBulk(
        this.roadsForProposal,
        this.$route.params.proposalId
      );
      this.evaluationResults = allResults.results;
      //this.$wait.end("evaluating");
    }
  },
  methods: {
    ...mapMutations([
      "proposalstatus",
      "proposalUser",
      "clearState",
      "roadsForProposalStateRemove",
      "setReferenceName",
      "editRoadName",
      "setBucketRNA"
    ]),
    getBulkUploadTemplate,
    addNewRoad() {
      this.editRoadName();
      this.$router.push({
        name: "proposalAdd",
        params: { index: this.roadsForProposal.length }
      });
    },

    async handleSubmitProposal() {
      try {
        this.$wait.start("saving proposal");
        await this.$validator.validate();
        if (this.errors.items && this.errors.items.length > 0)
          return this.$wait.end("saving proposal");

        await this.$store.dispatch(
          "pushRoadnameProposalToDatabase",
          "Proposed"
        );
        this.clearState();
        this.$wait.end("saving proposal");
        this.$router.push({ name: "proposalSubmitted" });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.$wait.end("saving proposal");
        this.$notify({
          group: "toast",
          type: "error",
          title: "Submission Failed",
          text: `The Roadname Proposal submission has failed. ${error}`
        });
      }
    },
    async handleSubmitDraft() {
      try {
        this.$wait.start("saving draft");
        this.proposalstatus("Drafted");
        await this.$store.dispatch("pushRoadnameProposalToDatabase", "Drafted");
        this.$wait.end("saving draft");
        this.$router.push({
          name: "proposals"
        });
      } catch (error) {
        this.$wait.end("saving draft");
        this.$notify({
          group: "toast",
          type: "error",
          title: "Draft issue",
          text: `The Roadname Proposal Draft has been not saved, an issue has occured please contact the administrator.${error}`
        });
      }
    },
    handleCancelChanges() {
      this.$router.push({ name: "proposals" });
    },
    removeRoad(index) {
      // this.$delete(
      //   this.evaluationReports,
      //   this.roadsForProposal[index].roadname
      // );
      this.$delete(this.evaluationResults, index);
      this.roadsForProposalStateRemove(index);
    },
    async handleDeleteProposal() {
      if (this.$route.params.proposalId !== "create") {
        try {
          this.$wait.start("deleting draft proposal");
          await deleteDraftedProposal(this.$route.params.proposalId);
          this.$wait.end("deleting draft proposal");
          this.clearState();
          this.$notify({
            group: "toast",
            type: "success",
            title: "Draft proposal deleted",
            text: "The draft proposal has been deleted"
          });
          return this.$router.push({ name: "proposals" });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          this.$wait.end("deleting draft proposal");
          if (e.response.status === 404) {
            // proposal wasnt found
            return this.$router.push({ name: "proposals" });
          }
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error",
            text: "You are not allowed to delete this proposal"
          });
        }

        return;
      }
      this.clearState();
      this.$notify({
        group: "toast",
        type: "success",
        title: "Proposal cleared",
        text: "The draft proposal has been cleared."
      });
    },
    handleEvaluationCreation(roadName, issueCount) {
      this.$set(this.evaluationReports, roadName, issueCount);
    },
    filesChange(files) {
      let reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const output = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { raw: false }
        );
        const transformedRoadNames = output
          .slice(0, 50)
          .map(row => {
            Object.keys(row).forEach(attr => {
              if (row[attr] === "") {
                delete row[attr];
              }
            });
            return row;
          })
          .filter(row => {
            return !(
              (Object.keys(row).length === 2 && row.Number && row.LGA) ||
              !row.LGA
            );
          })
          .map(rn => {
            return {
              roadname_classification: { value: rn["Proposal Type"] },
              lga_name: { value: rn["LGA"] },
              suburb_name: { value: rn["Suburb"] },
              roadname_origin: { value: rn["Origin"] },
              roadname: { value: rn["Road Name"] },
              extent: { value: rn["Extent"] },
              road_type: { value: rn["Road Type"] },
              has_multicultural_dimension: {
                value: rn["Does this road contain a multi-cultural dimension?"]
              },
              has_aboriginal_dimension: {
                value: rn["Does this road contain an Aboriginal dimension?"]
              },
              comment: { value: rn["Additional information for this Road"] },
              commemorated: {
                value:
                  rn["Does this proposed name commemorate a person?"] || "No"
              },
              commemorated_full_name: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration person full name"]
              },
              commemorated_birth_date: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration person date of birth (DD/MM/YYYY)"]
              },
              commemorated_death_date: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration person date of death (DD/MM/YYYY)"]
              },
              commemorated_dates_unknown: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration person date of birth/death unknown"]
              },
              commemorated_dates_unknown_description: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Please explain why the Date of Birth or Death is unknown"]
              },
              commemorated_association_description: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration person association"]
              },
              commemorated_source: {
                value:
                  rn["Does this proposed name commemorate a person?"] &&
                  rn["Commemoration information source"]
              }
            };
          });
        this.$router.push({
          name: "proposalBulkAdd",
          params: {
            roadNames: transformedRoadNames
          }
        });
      };
      reader.readAsArrayBuffer(files[0]);
    },
    hideModal() {
      this.lgaName = null;
      this.$modal.hide("roadname-bulk-upload-lga");
    },
    async getUploadTemplate() {
      //if not default lga or selected lga
      if (
        (this.defaultRnaLga && this.lgaState.includes(this.defaultRnaLga)) ||
        this.lgaName
      ) {
        // get template
        try {
          this.$wait.start("downloading template");
          const lga =
            this.defaultRnaLga && this.lgaState.includes(this.defaultRnaLga)
              ? this.defaultRnaLga
              : this.lgaName;
          const s3location = await this.getBulkUploadTemplate(lga);
          FileSaver.saveAs(
            s3location.uploadUrl,
            lga.replace(/\s+/g, "_") + ".xlsx"
          );
        } catch (e) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Unable to retrieve template",
            text: "There was an issue retrieving upload tempalte"
          });
        } finally {
          this.$wait.end("downloading template");
        }
        this.hideModal();
      } else {
        this.$modal.show("roadname-bulk-upload-lga");
      }
    },
    setLga() {
      this.getUploadTemplate();
      this.$modal.hide("roadname-bulk-upload-lga");
    },
    getIssueCount(index) {
      if (!this.evaluationResults) {
        return null;
      }

      const roadEvaluation = this.evaluationResults[index];
      if (!roadEvaluation) {
        return null;
      }
      return !roadEvaluation.passes && roadEvaluation.breaches.issueCount
        ? roadEvaluation.breaches.issueCount
        : 0;
    }
  }
};
</script>

<style scoped>
.Valid {
  border-radius: 27px;
  background-color: #00a908;
}
.Invalid {
  border-radius: 27px;
  background-color: #d7153a;
}
.Issue {
  border-radius: 27px;
  background-color: #ff7f2f;
}

input::placeholder {
  color: #646974;
  opacity: 1;
}

/deep/ .vs__dropdown-menu {
  max-height: 180px !important;
}
</style>
