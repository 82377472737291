"use strict";

const validatorFieldBagMixin = {
  computed: {
    isFormDirty: {
      get() {
        return (
          this.fields &&
          Object.keys(this.fields).some(key => this.fields[key].dirty)
        );
      }
    },
    isFormValid: {
      get() {
        return this.errors && !this.errors.items.length;
      }
    }
  },
  methods: {
    isFormScopeDirty(...scopes) {
      return this.isValidatorScopeDirty(...scopes);
    },
    isValidatorScopeDirty(...scopes) {
      if (!scopes || !scopes.length) {
        return this.isFormDirty;
      }

      return scopes.some(scopeName => {
        const scope = this.fields[`$${scopeName}`];
        if (!scope) return false;
        return Object.keys(scope).some(key => scope[key].dirty);
      });
    },
    isValidatorScopeValid(...scopes) {
      if (!scopes || !scopes.length) {
        return this.isFormValid;
      }

      return scopes.some(scopeName => {
        const scope = this.fields[`$${scopeName}`];
        if (!scope) return false;
        return Object.keys(scope).every(key => scope[key].valid);
      });
    }
  }
};

export default validatorFieldBagMixin;
