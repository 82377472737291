<template>
  <div class="w-4xl pt-12 mx-auto base-container">
    <section class="pt-1 px-0 justify-center w-full block">
      <div class="flex justify-between mt-4">
        <router-link
          :to="{ name: 'administerUsers' }"
          class="flex justify-between pb-2 self-center"
        >
          <BackArrowWithTitle title="Edit Profile - Surveyor General" />
        </router-link>
      </div>
    </section>
    <ShowLoading isLoading="Fetching surveyor general data">
      <template slot="component">
        <NetworkError v-if="fetchError" />
        <section
          v-if="!fetchError"
          class="pt-0 pb-4 px-0 justify-center w-full block"
        >
          <div
            class="max-w-6xl flex-column bg-white text-black rounded-lg pl-12 pr-12 pt-12 pb-12 mt-4 mb-8"
          >
            <div class="mx-6 pb-0 border-b-2 self-center">
              <h2>Surveyor-General Details</h2>
            </div>

            <form class="w-full justify-center">
              <div class="px-3 py-3 flex flex-wrap mt-4">
                <div class="md:w-1/2 px-3 justify-center md:mb-0">
                  <label class="block font-bold mb-2" for="grid-first-name">
                    <span class="redStar">*</span> First Name
                  </label>
                  <input
                    class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
                    id="grid-first-name"
                    type="text"
                    placeholder="First Name"
                    v-model="surveyorGeneral.firstName"
                    v-validate="{ required: true }"
                    name="First Name"
                    data-vv-scope="surveyorGeneral"
                    v-wait:disabled="'saving surveyor general data'"
                  />
                </div>
                <div class="md:w-1/2 px-3 justify-center md:mb-0">
                  <label class="block font-bold mb-2" for="grid-last-name">
                    <span class="redStar">*</span> Last Name
                  </label>
                  <input
                    class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
                    id="grid-last-name"
                    type="text"
                    placeholder="Last Name"
                    v-model="surveyorGeneral.lastName"
                    v-validate="{ required: true }"
                    v-wait:disabled="'saving surveyor general data'"
                    name="Last Name"
                    data-vv-scope="surveyorGeneral"
                  />
                </div>
              </div>
              <div class="px-3 py-6 flex flex-wrap">
                <div class="md:w-full px-3 justify-center md:mb-0">
                  <label class="block font-bold mb-2" for="grid-password">
                    <span class="redStar">*</span> Surveyor-General Contact
                    Email Address
                  </label>
                  <input
                    class="appearance-none block w-full rounded py-4 px-4 mb-3 input-form input-area"
                    id="grid-password"
                    type="text"
                    placeholder="Email"
                    v-model="surveyorGeneral.email"
                    v-validate="'email|required'"
                    v-wait:disabled="'saving surveyor general data'"
                    data-vv-scope="surveyorGeneral"
                    name="Email"
                  />
                  <FieldErrorMessage
                    :message="errors.first('Email', 'surveyorGeneral')"
                  />
                </div>
              </div>
              <div
                class="flex items-center absolute"
                v-if="errors.any('surveyorGeneral')"
              >
                <errorIcon />
                <div class="text-red-error text-normal-bold ml-2">
                  All surveyor general info fields are required
                </div>
              </div>
            </form>
          </div>

          <div class="flex justify-between pt-4 pb-4 mb-20">
            <router-link
              :to="{ name: 'administerUsers' }"
              class="cancelButton self-center button-red-hollow"
              data-cypress="cancel"
              v-wait:disabled="'saving surveyor general data'"
              >Cancel Changes</router-link
            >
            <div class="flex flex-row flex-grow justify-end">
              <button
                @click="showActingModal"
                class="self-center button-blue-hollow flex-no-grow mr-4"
              >
                Set Acting Surveyor General
              </button>

              <ButtonWithSpinner
                class="saveButton self-center button-blue"
                @click="save"
                type="button"
                :disabled="isSaveDisabled"
                :isSpinning="$wait.is('saving surveyor general data')"
                >Save Changes
              </ButtonWithSpinner>
            </div>
          </div>
        </section>
      </template>
    </ShowLoading>
  </div>
</template>

<script>
import * as sgCruds from "../../helpers/surveyorGeneralCruds";
import moment from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import { waitFor } from "vue-wait";
import validatorFieldBagMixin from "@/mixins/validator-field-bag-mixin";
import actingModal from "./acting-surveyor-general-modal";

export default {
  name: "EditSurveyorGeneralInfo",
  mixins: [validatorFieldBagMixin],
  components: {
    Datepicker
  },
  data() {
    return {
      surveyorGeneral: {
        firstName: "",
        lastName: "",
        email: ""
      },
      fetchError: false
    };
  },
  computed: {
    isSaveDisabled() {
      return (
        !this.isFormValid ||
        !this.isFormScopeDirty("surveyorGeneral") ||
        this.$wait.is("saving surveyor general data")
      );
    }
  },
  methods: {
    fetch: waitFor("Fetching surveyor general data", async function() {
      try {
        this.fetchError = false;
        const { surveyorGeneral } = await sgCruds.getSurveyorGeneralInfo();

        if (surveyorGeneral) {
          this.surveyorGeneral = surveyorGeneral;
        }
      } catch (e) {
        if (e.response.status === 500) {
          // this.fetchError = true;
          return;
        }

        this.$notify({
          group: "toast",
          type: "info",
          title: "Unknown Error",
          text:
            "We were unable to get the Surveyor General information at this time."
        });
      }
    }),
    save: waitFor("saving surveyor general data", async function() {
      if (await this.$validator.validate()) {
        try {
          await sgCruds.saveSurveyorGeneralInfo(this.surveyorGeneral);

          this.$notify({
            group: "toast",
            type: "success",
            title: "Update Succesful",
            text: "The surveyor general info has been updated"
          });

          this.$router.push({ name: "administerUsers" });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          this.$notify({
            group: "toast",
            type: "error",
            title: "Update Error",
            text: e.response.data.message
          });
        }
      }
    }),
    showActingModal() {
      this.$modal.show(
        actingModal,
        {},
        {
          height: "auto"
        }
      );
    },
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },
  async mounted() {
    await this.fetch();
  }
};
</script>

<style scoped>
.actions {
  color: #002664;
}
#userSVG {
  fill: grey;
}
.editSurveyorButton {
  color: #002664;
  border-color: #002664;
}

.grey-bg {
  background-color: #ecf1f3;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

.redStar {
  height: 27px;
  width: 21.75px;
  color: #d7153a;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.inputfield {
  background: #ecf1f3;
}
.arrow-icon {
  height: 20px;
  width: 20px;
  color: #d7153a;
}

.border-b-2 {
  border-bottom-width: 1px;
}
.input-form:focus {
  @apply outline-none shadow-outline;
}
</style>
