import { render, staticRenderFns } from "./commentForm.vue?vue&type=template&id=6158e856&scoped=true"
import script from "./commentForm.vue?vue&type=script&lang=js"
export * from "./commentForm.vue?vue&type=script&lang=js"
import style0 from "./commentForm.vue?vue&type=style&index=0&id=6158e856&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6158e856",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3904792131/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6158e856')) {
      api.createRecord('6158e856', component.options)
    } else {
      api.reload('6158e856', component.options)
    }
    module.hot.accept("./commentForm.vue?vue&type=template&id=6158e856&scoped=true", function () {
      api.rerender('6158e856', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/proposals/place/public-comments/commentForm.vue"
export default component.exports