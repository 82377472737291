<template>
  <label class="switch m-auto justify-center">
    <input
      type="checkbox"
      id="togBtn"
      :checked="value"
      @change="e => $emit('input', e.target.checked)"
      :disabled="disabled"
    />
    <div class="slider round p-auto text-sm justify-center"></div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
.switch {
  position: relative;
  justify-content: center;
  display: inline-block;
  width: 140px;
  height: 40px;
}

.switch input {
  display: none;
}

.slider {
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(202, 34, 34, 0.2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
}

.slider:before {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  content: "Disabled";
  height: 40px;
  width: 110px;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

input:checked + .slider {
  background-color: rgba(105, 179, 45, 0.2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  content: "Enabled";
  background-color: #00a908;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  background-color: #00a908;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
}

/*--------- END --------*/
</style>
