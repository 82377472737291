var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c("section", { staticClass: "justify-center block" }, [
      _c("div", { staticClass: "flex mb-3" }, [
        _c(
          "a",
          {
            staticClass: "cursor-pointer flex self-center",
            attrs: { "data-cypress": "back-arrow" },
            on: {
              click: function ($event) {
                return _vm.$router.push({
                  name: "proposals",
                })
              },
            },
          },
          [_c("BackArrowWithTitle", { attrs: { title: "Bulk Gazette" } })],
          1
        ),
        _c("div", { staticClass: "ml-auto self-end text-sm text-right" }, [
          !this.$wait.is("fetching roads")
            ? _c("div", { staticClass: "px-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.selectedRoads.length) +
                    " proposals selected "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "mb-8" }, [
      _c(
        "div",
        [
          _vm._l(_vm.roadNames, function (road) {
            return _c(
              "div",
              { key: road.identifier, staticClass: "flex" },
              [
                _c("bulk-gazette-item", {
                  staticClass: "w-9/10",
                  attrs: { road: road },
                }),
                _c(
                  "div",
                  { staticClass: "pt-5" },
                  [
                    _c("Checkbox", {
                      attrs: { label: "Gazette?", value: road.selected },
                      on: {
                        input: (e) =>
                          _vm.toggleSelectRoadsForGazette({ value: e, road }),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.error ? _c("networkError") : _vm._e(),
          _c("showLoading", {
            attrs: { isLoading: "fetching roads" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _vm.loadMore
                        ? _c(
                            "button",
                            {
                              staticClass: "button-blue",
                              on: { click: _vm.fetchRoads },
                            },
                            [_vm._v(" View More Roads ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          !_vm.initialLoad
            ? _c(
                "div",
                [
                  _c("uploadComponent", {
                    attrs: {
                      label: "Attach Relevant Image (Optional)",
                      drafted: true,
                      deleteFile: _vm.deleteFunction,
                      disabled: _vm.disabled,
                    },
                  }),
                  _vm._m(0),
                  _c("Checkbox", {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        "These road name proposals have been advertised for public comment.",
                      name: "Public Comment",
                    },
                    model: {
                      value: _vm.advertisedComment,
                      callback: function ($$v) {
                        _vm.advertisedComment = $$v
                      },
                      expression: "advertisedComment",
                    },
                  }),
                  _c("div", { staticClass: "flex mt-16" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button-red-hollow flex-no-grow",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({ name: "proposals" })
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button-blue ml-auto",
                        attrs: { disabled: !_vm.canContinue },
                        on: { click: _vm.gazetteMultipleProposals },
                      },
                      [_vm._v(" Continue ")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Public Comment "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }