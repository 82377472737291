var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base-container text-black" }, [
    _c("section", { staticClass: "pr-20 justify-center w-full block" }, [
      _c(
        "div",
        { staticClass: "flex justify-between" },
        [
          _c(
            "router-link",
            {
              staticClass: "flex justify-between mb-3 -mr-4 self-center",
              attrs: { to: { name: "rnasManage" }, "data-cypress": "goback" },
            },
            [_c("BackArrowWithTitle", { attrs: { title: "Add New RNA" } })],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "w-full bg-white rounded-lg text-normal pb-10" }, [
      _c(
        "div",
        {
          staticClass:
            "w-9/10 flex flex-wrap content-center mx-auto pt-12 pb-12",
        },
        [
          _c("h2", { staticClass: "w-full" }, [_vm._v("RNA Details")]),
          _c("div", { staticClass: "dividing-line-h-1 h-2" }, [_vm._v(" ")]),
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.authority_name,
                expression: "authority_name",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full input-area",
            attrs: {
              type: "text",
              "data-cypress": "nameofrna-field",
              placeholder: "Enter name of RNA here...",
              name: "Authority Name",
              disabled: _vm.$wait.is("saving rna"),
            },
            domProps: { value: _vm.authority_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.authority_name = $event.target.value
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("Authority Name") },
          }),
          _c("h2", { staticClass: "w-full mt-16" }, [
            _vm._v("Involved Party Gazettal Details"),
          ]),
          _c("div", { staticClass: "dividing-line-h-1 h-2" }, [_vm._v(" ")]),
          _vm._m(1),
          _c(
            "div",
            { staticClass: "mt-6 pr-10 w-1/2" },
            [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.manager_name,
                    expression: "manager_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "nameofperson-field",
                  placeholder: "Enter name of Responsible Person here...",
                  name: "Responsible Person Name",
                  disabled: _vm.$wait.is("saving rna"),
                },
                domProps: { value: _vm.manager_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.manager_name = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("Responsible Person Name") },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-6 w-1/2" },
            [
              _vm._m(3),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.manager_role,
                    expression: "manager_role",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "nameoforg-field",
                  placeholder: "Enter organisational title...",
                  name: "Responsible Person Title",
                  disabled: _vm.$wait.is("saving rna"),
                },
                domProps: { value: _vm.manager_role },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.manager_role = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: {
                  message: _vm.errors.first("Responsible Person Title"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-6 w-full" },
            [
              _vm._m(4),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address_line_1,
                    expression: "address_line_1",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "address1-field",
                  placeholder: "Enter address here...",
                  name: "Authority Address",
                  disabled: _vm.$wait.is("saving rna"),
                },
                domProps: { value: _vm.address_line_1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.address_line_1 = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("Authority Address") },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mt-6 w-full" }, [
            _c("p", { staticClass: "font-bold mb-2" }, [
              _vm._v("Address Line 2"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address_line_2,
                  expression: "address_line_2",
                },
              ],
              staticClass: "w-full input-area",
              attrs: {
                type: "text",
                "data-cypress": "address2-field",
                placeholder: "Leave blank if none...",
                disabled: _vm.$wait.is("saving rna"),
              },
              domProps: { value: _vm.address_line_2 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.address_line_2 = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "mt-6 pr-10 w-1/2" },
            [
              _vm._m(5),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rna_suburb,
                    expression: "rna_suburb",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "suburb-field",
                  placeholder: "Enter suburb name here...",
                  name: "Suburb",
                  disabled: _vm.$wait.is("saving rna"),
                },
                domProps: { value: _vm.rna_suburb },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.rna_suburb = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("Suburb") },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-6 pr-10 w-1/4" },
            [
              _vm._m(6),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rna_state,
                      expression: "rna_state",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full input-area",
                  attrs: {
                    "data-cypress": "state-field",
                    name: "State",
                    disabled: _vm.$wait.is("saving rna"),
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.rna_state = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "NSW", selected: "" } }, [
                    _vm._v("NSW"),
                  ]),
                  _c("option", { attrs: { value: "VIC" } }, [_vm._v("VIC")]),
                  _c("option", { attrs: { value: "SA" } }, [_vm._v("SA")]),
                  _c("option", { attrs: { value: "WA" } }, [_vm._v("VIC")]),
                  _c("option", { attrs: { value: "NT" } }, [_vm._v("NT")]),
                  _c("option", { attrs: { value: "QLD" } }, [_vm._v("QLD")]),
                  _c("option", { attrs: { value: "ACT" } }, [_vm._v("ACT")]),
                ]
              ),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("State") },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-6 w-1/4" },
            [
              _vm._m(7),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rna_postcode,
                    expression: "rna_postcode",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full input-area",
                attrs: {
                  type: "text",
                  "data-cypress": "postcode-field",
                  placeholder: "Enter postcode here...",
                  name: "Postcode",
                  disabled: _vm.$wait.is("saving rna"),
                },
                domProps: { value: _vm.rna_postcode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.rna_postcode = $event.target.value
                  },
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("Postcode") },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex my-6" },
      [
        _c(
          "router-link",
          {
            staticClass: "button-red-hollow",
            attrs: {
              to: { name: "rnasManage" },
              "data-cypress": "cancel-button",
              disabled: _vm.$wait.is("saving rna"),
            },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "ButtonWithSpinner",
          {
            staticClass: "button-blue ml-auto",
            attrs: {
              "data-cypress": "save-button",
              disabled:
                _vm.errors.items.length > 0 || _vm.$wait.is("saving rna"),
              isSpinning: _vm.$wait.is("saving rna"),
            },
            on: { click: _vm.handleSumbitRna },
          },
          [_vm._v(" Save & Continue ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold w-full pt-8 mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Name of RNA "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal w-full pt-8 mb-4" }, [
      _vm._v(
        " The details below will be inserted into all gazette notices relating to approved road names for this specific RNA. "
      ),
      _c("br"),
      _vm._v(
        "If the details are changed here, those changes will be reflected in all gazette notices for road names generated by this system. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Name of Responsible Person "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Organisational Title of Responsible Person "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("RNA Address Line 1 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Suburb"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("State"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-bold mb-2" }, [
      _c("a", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Postcode"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }