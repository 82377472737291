<template>
  <div :key="proposalId">
    <div
      class="flex flex-wrap justify-center items-center text-normal gb-grey-input"
    >
      <div class="backgroundDiv w-screen h-screen fixed"></div>
      <img
        src="../../../../assets/Logos/GNB logo_White_RGB.png"
        class="fixed h-12 logo-position"
      />
      <div
        class="w-2/3 bg-white mx-auto mt-48 z-10 rounded flex flex-col shadow-lg mb-12"
      >
        <div class="w-9/10 mx-auto mb-8 flex flex-col">
          <img
            src="../../../../assets/Logos/GNB_Logo_short.png"
            class="h-16 self-center mt-20"
          />

          <div class="text-black mb-8 " v-if="exists || proposalId == 'create'">
            <div class="base-container font-bold text-center w-full text-3xl">
              Submit a Place Name Proposal
            </div>

            <showLoading isLoading="loading Proposal">
              <template v-slot:component>
                <transition appear name="fade" mode="out-in">
                  <div :key="proposalId">
                    <Type
                      :readOnly="readOnly"
                      :isAdmin="false"
                      :organisations="organisations"
                      :isPublic="true"
                    />
                    <GeographicalDetails
                      :proposalType="type"
                      :readOnly="readOnly"
                      :isPublic="true"
                    />
                    <Lot
                      class="mt-12"
                      :proposalType="type"
                      :readOnly="readOnly"
                      :isPublic="true"
                    />
                    <NameDetailsAndOrigin
                      class="mt-12"
                      :proposalType="type"
                      :readOnly="readOnly"
                      :isPublic="true"
                    />
                    <Checklist
                      class="mt-12"
                      :proposalType="type"
                      :readOnly="readOnly"
                      :isPublic="true"
                    />
                    <AdditionalInfo
                      class="mt-12"
                      :proposalType="type"
                      :readOnly="readOnly"
                      :isPublic="true"
                    />
                    <LocalNewspaper
                      :readOnly="readOnly"
                      :isPublic="true"
                      class="mt-12"
                    />
                    <ApplicantDetails
                      :readOnly="true"
                      :isPublic="true"
                      class="mt-12"
                      ref="applicantDetailsForm"
                    />

                    <div class="flex-grow" name="personalInformationNoticeCollection">                  
                      <div class="flex flex-grow">
                       <Checkbox
                        class="w-full pl-0"            
                        v-model="personalInformationCollectionNotice">
                        <template slot:label>
                        Please tick to acknowledge the <a href="https://www.gnb.nsw.gov.au/__data/assets/pdf_file/0009/229356/Personal_Information_Collection_Notice.pdf" target="_blank" > GNB Personal Information Collection Notice </a>
                       </template>
                      </Checkbox>      
                      </div>                   
                    </div> 

                    <div class="flex base-container" name="3 buttons">
                      <ButtonWithSpinner
                        class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
                        data-cypress="delete-button"
                        @click="$router.go(-1)"
                        :disabled="$wait.any"
                        >Cancel Proposal</ButtonWithSpinner
                      >
                      <ButtonWithSpinner
                        v-if="showDeleteButton"
                        class="button-red-hollow flex justify-center w-2/6 mt-4 mb-10"
                        @click="deleteProposalById"
                        data-cypress="delete-button"
                        :isSpinning="$wait.is('deletingProposal')"
                        :disabled="$wait.any"
                        >Delete Proposal</ButtonWithSpinner
                      >
                      <ButtonWithSpinner
                        v-if="showSubmitButton"
                        class="button-blue flex w-2/6 mt-4 mb-10 ml-4"
                        data-cypress="submit-button"
                        @click="save"
                        :isSpinning="$wait.is('savingProposal')"
                        :disabled="
                          $wait.any || !isFormValid
                          || !personalInformationCollectionNotice
                          || errors.items.length > 0
                        "
                        >Submit Proposal</ButtonWithSpinner
                      >
                    </div>
                  </div>
                </transition>
              </template>
            </showLoading>
          </div>
          <div
            v-if="!exists && proposalId !== 'create'"
            class="text-black mb-16 text-center mt-16"
          >
            <h3>
              no proposal found for proposal ID: <b>{{ proposalId }}</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { waitFor } from "vue-wait";

const {
  mapGetters: mapPnpGetters,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

import Type from "./proposal-type/proposalType.vue";
import GeographicalDetails from "./geographical-details/layout.vue";
import Lot from "./lot-and-dp-information/layout.vue";
import NameDetailsAndOrigin from "./name-details-and-origin/layout.vue";
import AdditionalInfo from "./additional-info/layout.vue";
import Checklist from "./checklists/layout.vue";
import LocalNewspaper from "./local-newspaper/layout.vue";
import ApplicantDetails from "./applicant-details/layout.vue";

import pnpCRUD from "@/helpers/pnpCRUD.js";

import {
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_LOCALITY
} from "../pnp-constants.js";

export default {
  provide() {
    return {
      $validator: this.$validator
    };
  },
  name: "placeNameProposal",
  data() {
    return {
      isSaving: false,
      personalInformationCollectionNotice: false
    };
  },
  components: {
    Type,
    GeographicalDetails,
    NameDetailsAndOrigin,
    Lot,
    AdditionalInfo,
    Checklist,
    LocalNewspaper,
    ApplicantDetails
  },
  computed: {
    type: {
      get() {
        return this.getProposalType();
      }
    },
    referenceNumber: {
      get() {
        return this.getReferenceNumber();
      }
    },
    isApplicantDetailsValid() {
      return this.getApplicantDetailsValidity();
    },
    isBoundaryAmendmentFormValid() {
      return this.getBoundaryAmendmentFormValidity();
    },
    isLocalityFormValid() {
      return this.getLocalityFormValidity();
    },
    isPlaceNameFormValid() {
      return this.getPlaceNameFormValidity();
    },
    localityHardStops() {
      return (
        this.$store.state.pnpState.proposal.address_locality.checklist
          .supported_by_council === false ||
        this.$store.state.pnpState.proposal.address_locality.checklist
          .map_or_gis_file_provided === false
      );
    },
    boundaryAmendmentHardStops() {
      return (
        this.$store.state.pnpState.proposal.boundary_amendment.checklist
          .map_or_gis_file_provided === false
      );
    },
    isFormValid() {
      if (this.type === PROPOSAL_TYPE_BOUNDARY) {
        return (
          !!this.referenceNumber &&
          this.isBoundaryAmendmentFormValid &&
          this.isApplicantDetailsValid &&
          !this.boundaryAmendmentHardStops
        );
      } else if (this.type === PROPOSAL_TYPE_PLACE) {
        return (
          !!this.referenceNumber &&
          this.isPlaceNameFormValid &&
          this.isApplicantDetailsValid
        );
      } else if (this.type === PROPOSAL_TYPE_LOCALITY) {
        return (
          !!this.referenceNumber &&
          this.isLocalityFormValid &&
          this.isApplicantDetailsValid &&
          !this.localityHardStops
        );
      } else return false;
    },
    exists: {
      get() {
        return this.proposalExists();
      }
    },
    readOnly: {
      get() {
        return this.proposalId !== "create" && this.status !== "DRAFTED";
      }
    },
    proposalId: {
      get() {
        return this.$route.params.proposalId;
      }
    },
    status: {
      get() {
        return this.proposalStatus();
      }
    },
    showDraftButton: {
      get() {
        return this.proposalId === "create" || this.status === "DRAFTED";
      }
    },
    showDeleteButton: {
      get() {
        return this.status === "DRAFTED";
      }
    },
    showSubmitButton: {
      get() {
        return this.proposalId === "create" || this.status === "DRAFTED";
      }
    },
    organisations: {
      get() {
        return this.getOrganisations();
      }
    }
  },
  async created() {
    this.fetchOrganisations();
    const id = this.proposalId;

    if (id === "create") {
      this.createNewProposal();
    } else {
      this.selectProposal(this.proposalId);
    }
  },
  watch: {
    proposalId: function(newId, oldId) {
      if (newId === "create" && newId !== oldId) {
        this.createNewProposal();
      }
    }
  },
  methods: {
    fetchProposal: waitFor("loading Proposal", async function() {
      try {
        await this.selectProposal(this.proposalId);
      } catch (error) {
        this.$notify({
          group: "toast",
          type: "error",
          title: "Proposal Retrieval has Failed",
          text: `The retrieval of this Place Name Proposal has failed. ${error}`
        });
      }
    }),
    async save() {
      if (!(await this.$validator.validateAll())) {
        return;
      }
      this.$wait.start("savingProposal");
      try {
        await this.savePublicProposal();
        this.$wait.end("savingProposal");
        this.$router.push({ name: "proposalPublicSubmitted" });
      } catch (error) {
        this.$wait.end("savingProposal");
        if (error.message == "Request failed with status code 403") {
          this.$notify({
            group: "toast",
            type: "notice",
            title: "Please login to submit a proposal",
            text:
              "You are already a registered user of the system and you cannot submit a public proposal."
          });
        } else {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Submission has Failed",
            text: `The Place Name Proposal submission has failed. ${error}`
          });
        }
      }
    },
    async saveAsDraft() {
      this.$wait.start("savingProposalAsDraft");
      try {
        await this.saveDraftProposal();
        this.$wait.end("savingProposalAsDraft");
        this.$router.push({ name: "placeNameProposals" });
      } catch (error) {
        this.$wait.end("savingProposalAsDraft");
        this.$notify({
          group: "toast",
          type: "error",
          title: "Draft Submission has Failed",
          text: `The Place Name Proposal draft submission has failed. ${error}`
        });
      }
    },
    async deleteProposalById() {
      this.$wait.start("deletingProposal");
      try {
        await this.deleteProposal(this.proposalId);
        this.$wait.end("deletingProposal");
        this.$router.go(-1);
      } catch (error) {
        this.$wait.end("deletingProposal");
        this.$notify({
          group: "toast",
          type: "error",
          title: "Submission Deletion has Failed",
          text: `The deletion of this Place Name Proposal has failed. ${error}`
        });
      }
    },
    ...mapPnpGetters([
      "getReferenceNumber",
      "getProposalType",
      "proposalExists",
      "proposalStatus",
      "getOrganisations",
      "getPlaceNameFormValidity",
      "getLocalityFormValidity",
      "getBoundaryAmendmentFormValidity",
      "getApplicantDetailsValidity"
    ]),
    ...mapPnpActions([
      "savePublicProposal",
      "saveDraftProposal",
      "selectProposal",
      "fetchProposalsList",
      "createNewProposal",
      "fetchOrganisations",
      "deleteProposal"
    ]),
    ...mapUserGetters(["userGroups"])
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.6s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.bg-cover-position {
  top: 60vh;
}
.logo-position {
  top: 30px;
  left: 30px;
}
.cont {
  border: 2px solid #002664;
  border-radius: 99999px;
}
.inner {
  border-radius: 99999px;
}
.cont input:checked ~ .inner {
  background-color: #002664;
}
.backgroundDiv {
  background-image: url("../../../../assets/Images/BG_Login.jpg");
  background-size: 100% 100%;
  top: 0px;
}
.redButtonSolid {
  background-color: #c60c30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}
.greenButtonSolid {
  background-color: #00a908;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 4px;
}
.blueButtonHollow {
  border: 2px solid #002664;
  color: #002664;
  border-radius: 4px;
}
.blueButton {
  border-radius: 4px;
  border: 2px solid #002664;
  background-color: #002664;
  color: white;
}
.w-37 {
  width: 37%;
}
</style>
