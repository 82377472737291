import StatusChange from "@/components/proposals/common/audit-trail/entries/status-change";
import ClarificationRequest from "@/components/proposals/common/audit-trail/entries/clarification-request";
import ClarificationResponse from "@/components/proposals/common/audit-trail/entries/clarification-response";
import ClarificationDocument from "@/components/proposals/common/audit-trail/documents/clarification-document";

export default {
  data() {
    return {
      componentMap: {
        STATUS_CHANGE: StatusChange,
        CLARIFICATION_REQUEST: ClarificationRequest,
        CLARIFICATION_RESPONSE: ClarificationResponse,
        CLARIFICATION_DOCUMENT: ClarificationDocument
      }
    };
  },

  methods: {
    /**
     * Retrieve the component needed for the provided
     * trail entry
     */
    getTrailEntryComponent(eventType) {
      if (!this.componentMap[eventType]) {
        // throw new Error("Unknown trail entry type");
        return null;
      }

      return this.componentMap[eventType];
    }
  }
};
