<template>
  <div class="px-3 py-3 justify-center md:mb-0">
    <label class="container items-baseline flex height-fix">
      <slot>{{ label }}</slot>
      <input
        class="checkbox-input"
        type="checkbox"
        v-bind:checked="value"
        v-on:change="$emit('input', $event.target.checked)"
        :disabled="$attrs.disabled"
        :name="$attrs.name"
      />
      <span class="border-2 checkmark"></span>
    </label>
    <FieldErrorMessage :message="error" />
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  $_veeValidate: {
    value() {
      return this.value;
    },
    name() {
      return this.$attrs.name;
    }
  },
  props: {
    value: {},
    label: String,
    error: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #002664;
  background-color: #ffffff;
  transition-duration: 0.2s;
  border-radius: 2px;
}
input:disabled ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #5b5e63;
  background-color: rgb(194, 193, 193);
  transition-duration: 0.2s;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #84bddc;
  border-color: #002664;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #d8d8d8;
  border-color: #464a52;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #002664;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.height-fix {
  min-height: 24px;
}
</style>
