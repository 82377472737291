<template>
  <div class="flex input-area" v-bind:class="{ disabled: $attrs.disabled }">
    <input
      class="w-full flex-grow rounded-lg input-autocomplete"
      data-cypress="select-entity-input"
      list="entityList"
      name="entityList"
      type="text"
      v-on:input="selectEntity"
      :value="value"
      :disabled="$attrs.disabled"
      :placeholder="placeholder"
    />
    <datalist id="entityList">
      <option v-for="item in entityListFiltered" :key="item.index">{{
        item.name
      }}</option>
    </datalist>
  </div>
</template>

<script>
import find from "lodash.find";

export default {
  name: "entitySelect",
  props: {
    entityList: {
      type: Array
    },
    value: String,
    showAll: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: String
  },
  computed: {
    entityListFiltered() {
      return this
        .entityList; /* .filter(e =>
        new RegExp((this.value || "").trim(), "gi").test((e.name || "").trim())
      ); */
    }
  },
  methods: {
    selectEntity($event) {
      const entity = find(this.entityList, e => $event.target.value === e.name);
      if (entity || !$event.target.value) {
        this.$emit("input", entity);
      }
    }
  }
};
</script>

<style scoped>
.autocomplete {
  background-color: #fff;
  border-radius: 4px;
}

.input-autocomplete:focus {
  outline: none;
}

.input-autocomplete {
  background-color: rgba(0, 0, 0, 0);
}

.input-area.disabled {
  background-color: #d4d8da;
}

datalist {
  display: none;
}
/* specifically hide the arrow on focus */
.input-autocomplete::-webkit-calendar-picker-indicator {
  display: none;
}

option {
  /* font-weight: 900 !important; */
  display: block;
  line-height: 1.5em;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
</style>
