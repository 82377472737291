var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-container text-black" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "roadname-bulk-upload-delete-confirm",
            width: "40%",
            height: "auto",
            classes: "rounded-lg bg-white py-6 ",
          },
        },
        [
          _c(
            "div",
            { staticClass: "w-9/10 mx-auto flex flex-col justify-center" },
            [
              _c(
                "a",
                {
                  staticClass: "cursor-pointer ml-auto",
                  on: {
                    click: function ($event) {
                      return _vm.hideModal()
                    },
                  },
                },
                [_c("ErrorIcon", { attrs: { classNames: "w-5" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "w-full flex justify-center text-black mt-10" },
                [_vm._v(" Remove Road Name From Upload? ")]
              ),
              _c(
                "h2",
                {
                  staticClass:
                    "w-full flex justify-center font-light text-black mt-8 text-center",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.modalRoad.roadname && _vm.modalRoad.roadname.value
                      ) +
                      " " +
                      _vm._s(
                        _vm.modalRoad.road_type && _vm.modalRoad.road_type.value
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-full inline font-light text-black mt-4 text-center mb-4 flex-auto",
                },
                [
                  _vm._v(" Suburb:  "),
                  _c("span", { staticClass: "font-bold text-black inline" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.modalRoad.suburb_name &&
                            _vm.modalRoad.suburb_name.value
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c("br"),
              _c(
                "h4",
                {
                  staticClass:
                    "w-full flex justify-center font-light text-black text-center",
                },
                [
                  _vm._v(
                    " in " +
                      _vm._s(
                        _vm.modalRoad.lga_name && _vm.modalRoad.lga_name.value
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-full flex text-justify text-sm font-light text-black mt-4 mb-4 py-2 justify-center",
                },
                [
                  _vm._v(
                    " Are you sure you want to remove the above road name from the upload? "
                  ),
                ]
              ),
              _c("div", { staticClass: "flex justify-between pt-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full justify-between content-between pt-4 pb-4 mb-4 text-center",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button-blue-hollow w-1/2 text-sm",
                        on: { click: _vm.hideModal },
                      },
                      [_vm._v(" No, don't remove yet ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button-red w-1/2 text-sm pt-4 ml-8",
                        attrs: { disabled: false },
                        on: {
                          click: function ($event) {
                            return _vm.removeFromUpload(_vm.modalRoad.index)
                          },
                        },
                      },
                      [_vm._v(" Yes, remove this road name ")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("section", { staticClass: "justify-center block" }, [
        _c("div", { staticClass: "flex mb-3" }, [
          _c(
            "a",
            {
              staticClass: "cursor-pointer flex self-center",
              attrs: { "data-cypress": "back-arrow" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({
                    name: "roadNameProposalBucket",
                    params: { proposalId: _vm.$route.params.proposalId },
                  })
                },
              },
            },
            [_c("BackArrowWithTitle", { attrs: { title: "Add Road Names" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-auto self-end mr-8 text-sm text-right" },
            [
              _c("div", { staticClass: "px-4" }, [
                _vm._v(
                  " Adding " +
                    _vm._s(_vm.roadNamesInternal.length) +
                    " road names "
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.errorCount > 0 && !_vm.$wait.is("evaluating roads"),
                      expression:
                        "errorCount > 0 && !$wait.is('evaluating roads')",
                    },
                  ],
                  staticClass:
                    "text-white issue font-bold py-2 px-4 rounded-full",
                },
                [_vm._v(" " + _vm._s(_vm.errorCount) + " invalid road names ")]
              ),
            ]
          ),
        ]),
      ]),
      !_vm.bulkError
        ? _c(
            "section",
            [
              _c("showLoading", {
                attrs: { isLoading: "evaluating roads" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "component",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "w-full bg-white" }, [
                            _c("div", { staticClass: "w-5/6 mx-auto py-16" }, [
                              _c(
                                "div",
                                _vm._l(
                                  _vm.roadNamesInternal,
                                  function (roadName, key) {
                                    return _c(
                                      "div",
                                      {
                                        key: key,
                                        staticClass: "w-full flex mb-5 mr-4",
                                        class: [
                                          _vm.rowIsValid(roadName)
                                            ? "bg-green-lightest"
                                            : "bg-red-lightest",
                                        ],
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "p-5 flex-grow" },
                                          [
                                            _c("div", { staticClass: "flex" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1/4 text-label",
                                                },
                                                [_vm._v(" Proposal Type ")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1/4 text-label",
                                                },
                                                [_vm._v(" Road Name ")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1/4 text-label",
                                                },
                                                [_vm._v(" Road Type ")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-1/4 text-label",
                                                },
                                                [_vm._v("   ")]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "flex font-500" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "w-1/4" },
                                                  [
                                                    _c("valid", {
                                                      attrs: {
                                                        field:
                                                          roadName.roadname_classification,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "w-1/4" },
                                                  [
                                                    _c("valid", {
                                                      attrs: {
                                                        field:
                                                          roadName.roadname,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "w-1/4" },
                                                  [
                                                    _c("valid", {
                                                      attrs: {
                                                        field:
                                                          roadName.road_type,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "w-1/4 flex flex-row-reverse",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        ref: "collapse",
                                                        refInFor: true,
                                                        staticClass:
                                                          "rotate-transition text-blue-darker",
                                                        class: [
                                                          roadName.collapsed
                                                            ? ""
                                                            : "rotate-chevron",
                                                        ],
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            roadName.collapsed =
                                                              !roadName.collapsed
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            attrs: {
                                                              icon: "chevron-circle-down",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "py-1 pl-3 pr-8",
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            staticClass:
                                                              "align-baseline",
                                                            attrs: {
                                                              role: "button",
                                                              icon: "trash",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showModal(
                                                                  key
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "transition",
                                              {
                                                attrs: { name: "collapse" },
                                                on: {
                                                  enter: function ($event) {
                                                    return _vm.beforeEnterCollapse(
                                                      key
                                                    )
                                                  },
                                                  leave: function ($event) {
                                                    return _vm.beforeLeaveCollapse(
                                                      key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !roadName.collapsed,
                                                        expression:
                                                          "!roadName.collapsed",
                                                      },
                                                    ],
                                                    ref: "collapse-body",
                                                    refInFor: true,
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "w-1/2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-label",
                                                              },
                                                              [_vm._v(" LGA ")]
                                                            ),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("valid", {
                                                                  attrs: {
                                                                    field:
                                                                      roadName.lga_name,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "w-1/2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Suburb "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("valid", {
                                                                  attrs: {
                                                                    field:
                                                                      roadName.suburb_name,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-2" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-label",
                                                          },
                                                          [_vm._v(" Origin ")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("valid", {
                                                              attrs: {
                                                                field:
                                                                  roadName.roadname_origin,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-2" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-label",
                                                          },
                                                          [_vm._v(" Extent ")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c("valid", {
                                                              attrs: {
                                                                field:
                                                                  roadName.extent,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    roadName.commemorated &&
                                                    !!roadName.commemorated
                                                      .value
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex mt-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "w-full text-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Commemoration Info "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2 pt-1 w-1/4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Name "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_full_name,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pt-1 w-1/4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Date of Birth "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_birth_date,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pt-1 w-1/4",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Date of Death "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_death_date,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pl-2 pt-1",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-label",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Unknown Date of Birth/Death? "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c("valid", {
                                                                    attrs: {
                                                                      field:
                                                                        roadName.commemorated_dates_unknown,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          roadName.commemorated_dates_unknown &&
                                                          roadName
                                                            .commemorated_dates_unknown
                                                            .value === true
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2 pt-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Reason why Date of Birth/Death is Unknown "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_dates_unknown_description,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2 pt-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Person's association with the road name "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_association_description,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          roadName.commemorated_source &&
                                                          roadName
                                                            .commemorated_source
                                                            .value
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2 pt-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-label",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Source of commemoration information "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "valid",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              field:
                                                                                roadName.commemorated_source,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        roadName.has_multicultural_dimension &&
                                                        roadName
                                                          .has_multicultural_dimension
                                                          .value
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pt-1 w-1/2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Has multicultural dimension? "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "valid",
                                                                      {
                                                                        attrs: {
                                                                          field:
                                                                            roadName.has_multicultural_dimension,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        roadName.has_aboriginal_dimension &&
                                                        roadName
                                                          .has_aboriginal_dimension
                                                          .value
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pt-1 w-1/2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-label",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Has aboriginal dimension? "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "valid",
                                                                      {
                                                                        attrs: {
                                                                          field:
                                                                            roadName.has_aboriginal_dimension,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    roadName.comment &&
                                                    roadName.comment.value
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "pt-1",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Additional Information "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("valid", {
                                                                  attrs: {
                                                                    field:
                                                                      roadName.comment,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-bold text-center text-sm text-white flex",
                                            class: [
                                              _vm.rowIsValid(roadName)
                                                ? "valid-border"
                                                : "invalid-border",
                                            ],
                                          },
                                          [
                                            _vm.roadErrorCount(roadName) > 0
                                              ? _c("font-awesome-icon", {
                                                  staticClass:
                                                    "mx-auto self-center",
                                                  attrs: { icon: "times" },
                                                })
                                              : _c("font-awesome-icon", {
                                                  staticClass:
                                                    "mx-auto self-center",
                                                  attrs: { icon: "check" },
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c("div", { staticClass: "w-1/3 -mb-3" }, [
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [
                                    _c("a", { staticClass: "text-red-star" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v("Radius "),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "text-normal-bold mb-2" },
                                  [
                                    _c(
                                      "norns-select",
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass:
                                          "w-full rounded-lg text-normal mb-1 mt-1 norns-select",
                                        attrs: {
                                          multiple: false,
                                          options: _vm.distanceOptions,
                                          reduce: (d) => d.value,
                                          searchable: false,
                                          "close-on-select": true,
                                          "show-labels": true,
                                          "hide-selected": true,
                                          placeholder: "Pick a radius",
                                          name: "distance",
                                          "data-cypress": "radius-field",
                                          "select-on-tab": true,
                                        },
                                        model: {
                                          value: _vm.distance,
                                          callback: function ($$v) {
                                            _vm.distance = $$v
                                          },
                                          expression: "distance",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "noOptions" },
                                            slot: "noOptions",
                                          },
                                          [_vm._v("Loading...")]
                                        ),
                                      ]
                                    ),
                                    _c("FieldErrorMessage", {
                                      attrs: {
                                        message: _vm.errors.first("Radius"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "flex mb-5" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full flex content-center items-start mt-8",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pr-4 items-start" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-normal-bold mb-2",
                                          },
                                          [_vm._v(" Road Name Extent ")]
                                        ),
                                        _vm._v(
                                          " Please draw the extent of the proposed roads on the map. "
                                        ),
                                        _c("ul", [
                                          _c("li", [
                                            _vm._v(" Select the polygon "),
                                            _c("img", {
                                              staticStyle: { width: "32px" },
                                              attrs: {
                                                src: "/img/icons/polygon-92x92.png",
                                              },
                                            }),
                                            _vm._v(
                                              " tool from within the map "
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Left click to mark the start of the road"
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Add additional points to mark out the road"
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Double click for the extent to be completed"
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("WebMap", {
                                    ref: "WebMapRef",
                                    staticStyle: { height: "450px" },
                                    attrs: {
                                      id: "WebMap",
                                      lgaName: _vm.lgaName,
                                      distance: 0,
                                      readOnly: false,
                                      availableCreateTools:
                                        _vm.availableCreateTools,
                                    },
                                    on: {
                                      "draw-complete": _vm.onDrawComplete,
                                      "draw-delete": function ($event) {
                                        _vm.geometry = {}
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mx-auto py-8" },
                                [
                                  _c(
                                    "Checkbox",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required:true",
                                          expression: "'required:true'",
                                        },
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        name: "noDraw",
                                        disabled:
                                          _vm.$wait.any || _vm.geometryIsSet,
                                      },
                                      model: {
                                        value: _vm.noDraw,
                                        callback: function ($$v) {
                                          _vm.noDraw = $$v
                                        },
                                        expression: "noDraw",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " I have decided to not draw the extent of the roads and understand that I will need to manually draw the extent on each of the roads in the proposal before I can submit the proposal. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.roadNamesInternal.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("uploadComponent", {
                                        staticClass: "mt-8 pr-8",
                                        attrs: {
                                          label:
                                            "Please upload Map and Other Supporting Documents.  Any uploaded files will be added to ALL roads above.",
                                          uploads: _vm.uploadedFilesState,
                                          drafted: true,
                                          disabled: !_vm.canUpload,
                                          deleteFile: _vm.deleteFile,
                                          name: "documents",
                                          important: _vm.needsFile,
                                        },
                                        on: {
                                          uploadfinished: function ($event) {
                                            _vm.uploading = false
                                          },
                                          uploadstarted: function ($event) {
                                            _vm.uploading = true
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "mr-8" }, [
                                _vm.roadNamesInternal.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-grow flex-col justify-center items-center py-4",
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "flex-0 text-red text-3xl inline",
                                          attrs: { icon: "exclamation-circle" },
                                        }),
                                        _c(
                                          "p",
                                          { staticClass: "text-black flex-0" },
                                          [
                                            _vm._v(
                                              " No road name records found "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm.errorCount > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-red-dark text-sm font-bold",
                                      },
                                      [
                                        _vm._v(
                                          " There are invalid road name records in this submission. "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " Please remove the invalid road names from this submission or make corrections to your excel file and upload again. "
                                        ),
                                      ]
                                    )
                                  : _vm.errorCount === 0 && _vm.needsFile
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-orange-dark text-sm font-bold",
                                      },
                                      [
                                        _vm._v(
                                          " Please upload a map and/or supporting documents before submitting. "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-row-reverse w-full pr-8 mt-10 mb-20",
                                },
                                [
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass: "button-blue-hollow",
                                      attrs: {
                                        type: "button",
                                        "data-cypress": "bulk-addroad-button",
                                        disabled: !_vm.canSubmit,
                                      },
                                      on: { click: _vm.addRoadsToProposal },
                                    },
                                    [_vm._v("Add Roads To Proposal")]
                                  ),
                                  _c(
                                    "ButtonWithSpinner",
                                    {
                                      staticClass: "button-red-hollow mr-auto",
                                      attrs: {
                                        "data-cypress": "cancel-button",
                                        disabled: _vm.uploading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "roadNameProposalBucket",
                                            params: {
                                              proposalId:
                                                _vm.$route.params.proposalId,
                                            },
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3416421092
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.bulkError
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-grow flex-col justify-center items-center py-4",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "flex-0 text-red text-3xl inline",
                attrs: { icon: "exclamation-circle" },
              }),
              _c("p", { staticClass: "text-black flex-0" }, [
                _vm._v(" Cannot upload roads for multiple LGAs. "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }