<template>
  <entry cardTitle="Gazette Notice Created" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">The gazette notice was created.</p>
    </div>

    <table class="table-fixed w-full">
      <tbody>
        <tr>
          <td class="font-bold w-1/4">Road Type:</td>
          <td>{{ entryData.roadType.to }}</td>
        </tr>
        <tr>
          <td class="font-bold w-1/4">Extent:</td>
          <td>{{ entryData.extent.to }}</td>
        </tr>
        <tr v-if="entryData.locality && entryData.locality.to">
          <td class="font-bold w-1/4">Locality(s):</td>
          <td>{{ transformLocalities(entryData.locality.to) }}</td>
        </tr>
      </tbody>
    </table>
  </entry>
</template>

<script>
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "GazettalNoticeTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  },
  methods: {
    transformLocalities(localities){
      const parsedLocalities = JSON.parse(localities);
      if (Array.isArray(parsedLocalities)) {
        return parsedLocalities.join(", ");
      }
      return parsedLocalities;
    }
  }
};
</script>
