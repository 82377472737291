<template>
  <div>
    <ol class="checklist pl-0">
      <template v-if="!school_or_national_park">
        <MessagePanel
          warning
          msg="Potential issue identified. Refer 9.5 of the Place Naming Policy."
          class="mb-4"
          v-if="supported_by_council === false"
        />
        <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
          <p class="text-normal w-5/6">
            Is the name supported by council resolution?
          </p>
          <div class="float right flex-row flex">
            <RadioButton
              class="flex-0 mr-6"
              data-cypress="supported_by_council-no"
              name="supported_by_council-no"
              v-model="supported_by_council"
              :checkedValue="false"
              :disabled="readOnly"
              v-validate="'required'"
              >No</RadioButton
            >
            <RadioButton
              class="flex-0 mr-6"
              name="supported_by_council-yes"
              data-cypress="supported_by_council-yes"
              v-model="supported_by_council"
              :checkedValue="true"
              :disabled="readOnly"
              v-validate="'required'"
              >Yes</RadioButton
            >
          </div>
        </li>
      </template>
      <MessagePanel
        warning
        msg="Potential issue identified. Refer 9.5 of the Place Naming Policy."
        v-if="supported_by_public === false"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">Does the name have public support?</p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="supported_by_public-no"
            name="supported_by_public-no"
            v-model="supported_by_public"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="supported_by_public-yes"
            data-cypress="supported_by_public-yes"
            v-model="supported_by_public"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <div class="text-normal pb-6 dividing-line-h" v-if="supported_by_public">
        <uploadScoped
          class="mt-4"
          data-cypress="upload-public-support"
          label="Attach supporting material for public support"
          :important="false"
          :files="public_support_reference_document"
          :drafted="true"
          stateKey="place_name"
          stateProp="public_support_reference_document"
          :uploadFile="
            params =>
              uploadFile(Object.assign({}, params, { isChecklistFile: true }))
          "
          v-on:deleteFile="
            fileIndex =>
              deleteUploadedChecklistFile({
                key: 'place_name',
                prop: 'public_support_reference_document',
                index: fileIndex
              })
          "
          :disabled="readOnly"
        />
      </div>
      <MessagePanel
        warning
        msg="Potential issue identified. Refer 9.2 of the Place Naming Policy."
        v-if="is_currently_named === true"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">Is the feature currently named?</p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="is_currently_named-no"
            name="is_currently_named-no"
            v-model="is_currently_named"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="is_currently_named-yes"
            data-cypress="is_currently_named-yes"
            v-model="is_currently_named"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <div class="text-normal pb-6 dividing-line-h" v-if="is_currently_named">
        <input
          type="text"
          class="w-full mr-4 input-area"
          data-cypress="currently_named-field"
          placeholder="Enter Current Name Here..."
          name="currrent_name"
          id="current_name"
          v-model="current_name"
          :disabled="readOnly"
        />
      </div>
      <MessagePanel
        warning
        msg="Potential issue identified. Refer 9.2 of the Place Naming Policy."
        v-if="complied_section_9_2 === false"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Does this name comply with
          <a
            href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=10"
            target="_blank"
            >Section 9.2</a
          >
          in the GNB Place Naming Policy?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="complied_section_9_2-no"
            name="complied_section_9_2-no"
            v-model="complied_section_9_2"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="complied_section_9_2-yes"
            data-cypress="complied_section_9_2-yes"
            v-model="complied_section_9_2"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <template v-if="!school_or_national_park">
        <MessagePanel
          warning
          msg="Potential issue identified. Refer 9.8 of the Place Naming Policy."
          v-if="complied_section_9_8 === false"
        />
        <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
          <p class="text-normal w-5/6">
            If naming a reserve, does this name comply with
            <a
              href="http://www.gnb.nsw.gov.au/__data/assets/pdf_file/0017/220148/GNB_Place_Naming_Policy.pdf#page=11"
              target="_blank"
              >Section 9.8 Generic Reserve Names</a
            >
            in the GNB Place Naming Policy?
          </p>
          <div class="float right flex-row flex">
            <RadioButton
              class="flex-0 mr-6"
              data-cypress="complied_section_9_8-no"
              name="complied_section_9_8-no"
              v-model="complied_section_9_8"
              :checkedValue="false"
              :disabled="readOnly"
              v-validate="'required'"
              >No</RadioButton
            >
            <RadioButton
              class="flex-0 mr-6"
              name="complied_section_9_8-yes"
              data-cypress="complied_section_9_8-yes"
              v-model="complied_section_9_8"
              :checkedValue="true"
              :disabled="readOnly"
              v-validate="'required'"
              >Yes</RadioButton
            >
          </div>
        </li>
      </template>
      <MessagePanel
        warning
        msg="Potential issue identified. Refer 9.2 of the Place Naming Policy."
        v-if="aboriginal_name_considered === false"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">Has an Aboriginal name been considered?</p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="aboriginal_name_considered-no"
            name="aboriginal_name_considered-no"
            v-model="aboriginal_name_considered"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="aboriginal_name_considered-yes"
            data-cypress="aboriginal_name_considered-yes"
            v-model="aboriginal_name_considered"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
      <MessagePanel
        warning
        msg="Potential issue identified. Refer 9.2 of the Place Naming Policy."
        v-if="multicultural_name_considered === false"
      />
      <li class="flex flex-row text-normal pb-6 pt-6 dividing-line-h">
        <p class="text-normal w-5/6">
          Has a multi-cultural name been considered?
        </p>
        <div class="float right flex-row flex">
          <RadioButton
            class="flex-0 mr-6"
            data-cypress="multicultural_name_considered-no"
            name="multicultural_name_considered-no"
            v-model="multicultural_name_considered"
            :checkedValue="false"
            :disabled="readOnly"
            v-validate="'required'"
            >No</RadioButton
          >
          <RadioButton
            class="flex-0 mr-6"
            name="multicultural_name_considered-yes"
            data-cypress="multicultural_name_considered-yes"
            v-model="multicultural_name_considered"
            :checkedValue="true"
            :disabled="readOnly"
            v-validate="'required'"
            >Yes</RadioButton
          >
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapPnpGetters,
  mapMutations: mapPnpMutations,
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");
import MessagePanel from "@/components/proposals/place/messagePanel";

export default {
  props: {
    readOnly: {
      type: Boolean
    }
  },
  components: {
    MessagePanel
  },
  computed: {
    supported_by_council: {
      get() {
        return this.get_place_name_supported_by_council();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_supported_by_council(value);
      }
    },
    supported_by_public: {
      get() {
        return this.get_place_name_supported_by_public();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_supported_by_public(value);
      }
    },
    is_currently_named: {
      get() {
        return this.get_place_name_is_currently_named();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_is_currently_named(value);
      }
    },
    complied_section_9_2: {
      get() {
        return this.get_place_name_complied_section_9_2();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_complied_section_9_2(value);
      }
    },
    complied_section_9_8: {
      get() {
        return this.get_place_name_complied_section_9_8();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_complied_section_9_8(value);
      }
    },
    aboriginal_name_considered: {
      get() {
        return this.get_place_name_aboriginal_name_considered();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_aboriginal_name_considered(value);
      }
    },
    multicultural_name_considered: {
      get() {
        return this.get_place_name_multicultural_name_considered();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_multicultural_name_considered(value);
      }
    },
    local_newspapers: {
      get() {
        return this.get_place_name_local_newspapers();
      },
      set(value) {
        if (value || value === false)
          this.set_place_name_local_newspapers(value);
      }
    },
    public_support_reference_document: {
      get() {
        return this.get_place_name_public_support_reference_document();
      },
      set(value) {
        if (value || value === false) {
          this.set_place_name_public_support_reference_document(value);
        }
      }
    },
    current_name: {
      get() {
        return this.get_place_name_current_name();
      },
      set: debounce(function(value) {
        this.set_place_name_current_name(value);
      }, 100)
    },
    school_or_national_park() {
      return this.get_place_name_school_or_national_park();
    }
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    ...mapPnpGetters([
      "get_place_name_supported_by_council",
      "get_place_name_supported_by_public",
      "get_place_name_is_currently_named",
      "get_place_name_complied_section_9_2",
      "get_place_name_complied_section_9_8",
      "get_place_name_aboriginal_name_considered",
      "get_place_name_multicultural_name_considered",
      "get_place_name_local_newspapers",
      "get_place_name_school_or_national_park",
      "get_place_name_public_support_reference_document",
      "get_place_name_current_name"
    ]),
    ...mapPnpMutations([
      "deleteUploadedChecklistFile",
      "set_place_name_supported_by_council",
      "set_place_name_supported_by_public",
      "set_place_name_is_currently_named",
      "set_place_name_complied_section_9_2",
      "set_place_name_complied_section_9_8",
      "set_place_name_aboriginal_name_considered",
      "set_place_name_multicultural_name_considered",
      "set_place_name_local_newspapers",
      "set_place_name_public_support_reference_document",
      "set_place_name_current_name"
    ])
  }
};
</script>

<style scoped>
a {
  color: #002664;
  text-decoration: none;
}

.checklist {
  list-style: none;
  counter-reset: li;
  /* list-style-position: inside; */
}

.checklist li::before {
  content: "." counter(li);
  width: 1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.checklist li {
  counter-increment: li;
}
</style>
