<template>
  <FormContainer>
    <div class="flex flex-col">
      <h1 class="pt-6 mb-10 text-center mt-4 text-title">Reset Password</h1>
      <div class="flex flex-col">
        <p class="flex-auto pb-6 w-full text-normal">
          Forgotten your password? Enter the email associated with your NSW
          Place and Road Naming Proposal System user account to send a recovery
          link.
        </p>
        <!-- email address -->
        <div class="flex-auto mb-4 ">
          <label class="block text-normal-bold mb-2" for="emailAddress"
            >Email</label
          >
          <input
            v-validate="'required|email'"
            class="w-full input-area"
            data-cypress="email"
            id="emailAddress"
            name="email"
            type="text"
            v-model="emailAddress"
            placeholder
          />
          <span class="text-error" v-show="errors.has('email')">
            {{ errors.first("email") }}
          </span>
        </div>
        <!-- reset password auth -->
        <ButtonWithSpinner
          class="button-blue mt-4 w-full"
          :disabled="errors.items.length || $wait.any"
          v-on:click="forgotPasswordAuth"
          :isSpinning="$wait.any"
          >Send recovery link</ButtonWithSpinner
        >
      </div>
    </div>

    <!-- return to home -->
    <button
      class="w-full bg-white text-normal-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline pt-4 text-blue-button bg-white mb-10"
      type="button"
      @click="handleReturnToLogin"
    >
      Return to login page
    </button>
  </FormContainer>
</template>

<script>
import * as cognito from "../../helpers/cognito.js";
import FormContainer from "./form-container";
import ButtonWithSpinner from "../elements/buttonWithSpinner";

export default {
  components: { FormContainer, ButtonWithSpinner },
  name: "passwordReset",
  props: ["email"],
  data() {
    return {
      emailAddress: this.email,
      errorMessage: ""
    };
  },
  methods: {
    async forgotPasswordAuth() {
      this.$wait.start("resetting password");
      try {
        const response = await this.$validator.validateAll();
        if (response) {
          await cognito.forgotPasswordAuthCognito(this.emailAddress);
          this.$wait.end("resetting password");
          this.$router.push({ name: "resetEnd" });
        } else {
          this.$wait.end("resetting password");
          return;
        }
      } catch (err) {
        this.$wait.end("resetting password");
        let errorMessage = "";

        if (!err) {
          errorMessage = "An authentication error has occured";
          return;
        }
        switch (err.code) {
          case "UserNotFoundException": {
            errorMessage = "Email Address not found";
            break;
          }
          case "InvalidParameterException":
            errorMessage = "You have not yet been verified by a staff member";
            break;
          default:
            errorMessage = err.message;
        }

        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Resetting account password",
          text: errorMessage
        });
      }
    },

    handleReturnToLogin() {
      this.$router.push("/");
    }
  }
};
</script>
