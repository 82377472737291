<template>
  <Button
    class=" relative px-8"
    v-on:click="$emit('click')"
    disabled="$attrs.disabled"
  >
    <svg
      class="absolute spinner rotate mr-2 svg-inline--fa fa-spinner fa-w-16"
      v-if="isSpinning"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="spinner"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
      ></path>
    </svg>
    <div class="flex-grow font-semibold pl-2">
      <slot></slot>
    </div>
  </Button>
</template>

<script>
export default {
  name: "ButtonWithSpinner",
  props: ["isSpinning"]
};
// comment
</script>
<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  height: 1em;
  left: 1em;
}
.rotate {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
