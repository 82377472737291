var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-black" }, [
    _c("div", { staticClass: "w-full flex bg-white" }, [
      _c(
        "div",
        { staticClass: "w-5/6 mx-auto", class: { "py-16": !_vm.isPublic } },
        [
          _c(
            "h3",
            { staticClass: "pb-3 mb-4 w-full text-title dividing-line-h" },
            [_vm._v(" Local Newspaper(s) ")]
          ),
          _c("p", { staticClass: "mb-8" }, [
            _vm._v(
              " If approved by the GNB, this proposal will be advertised in a local newspaper. Please provide the name of a local newspaper. "
            ),
          ]),
          _c(
            "label",
            { staticClass: "pr-4 w-2/3" },
            [
              _c("taggableInput", {
                directives: [{ name: "validate", rawName: "v-validate" }],
                staticClass: "w-full mr-4",
                attrs: {
                  tagList: _vm.localNewspapers,
                  placeholder: `Start typing and press enter to add a Newspaper name...`,
                  datacypress: "newspaper-input",
                  "data-vv-as": "Local Newspaper(s)",
                  label: "local_newspaper_taglist",
                  disabled: _vm.readOnly,
                },
                on: {
                  addTag: (newspaper) =>
                    _vm.addLocalNewspapers({ local_newspaper: newspaper }),
                  removeTag: (index) =>
                    _vm.removeLocalNewspapers({ index: index }),
                },
                model: {
                  value: _vm.localNewspapers,
                  callback: function ($$v) {
                    _vm.localNewspapers = $$v
                  },
                  expression: "localNewspapers",
                },
              }),
              _c("FieldErrorMessage", {
                attrs: { message: _vm.errors.first("local_newspaper_taglist") },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }