var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isFromUserProfile && _vm.showDelete
        ? _c(
            "button",
            {
              staticClass:
                "mx-2 mt-2 border-1 pt-2 pb-2 pl-6 pr-6 rounded-full focus:outline-none text-error cursor-pointer button-red-hollow",
              on: {
                click: function ($event) {
                  return _vm.openDeleteUserModal(_vm.modalName)
                },
              },
            },
            [_vm._v(" Delete ")]
          )
        : _vm._e(),
      !_vm.isFromUserProfile
        ? _c(
            "button",
            {
              staticClass: "actions action-button w-full h-full",
              attrs: {
                title: `Delete ${_vm.user.first_name} ${_vm.user.last_name}`,
                "data-cypress": "delete-button",
              },
              on: {
                click: function ($event) {
                  return _vm.determineShowDeleteInAdminUsers()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "flex-auto",
                attrs: { icon: "trash" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("modal", { attrs: { name: _vm.modalName, height: "auto" } }, [
        _c("div", { staticClass: "flex flex-col justify-between" }, [
          _c("h2", { staticClass: "text-title flex-0 px-4" }, [
            _vm._v("Delete User"),
          ]),
          _c("div", { staticClass: "dividing-line-h" }),
          _c("div", { staticClass: "text-normal text-center mt-4" }, [
            _c("h3", [_vm._v(_vm._s(_vm.confirmMessage))]),
          ]),
          _c("div", { staticClass: "my-6 flex justify-around" }, [
            _c(
              "button",
              {
                staticClass: "button-red",
                attrs: { type: "button", "data-cypress": "delete-button" },
                on: {
                  click: function ($event) {
                    return _vm.callDeleteUser()
                  },
                },
              },
              [_vm._v(" Delete ")]
            ),
            _c(
              "button",
              {
                staticClass: "button-blue",
                attrs: { type: "button", "data-cypress": "cancel-button" },
                on: {
                  click: function ($event) {
                    return _vm.closeDeleteUserModal(_vm.modalName)
                  },
                },
              },
              [_vm._v(" Cancel ")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }