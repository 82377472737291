var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "entry",
    {
      attrs: {
        cardTitle: "Roadname Rejection Appeal Decision",
        eventDate: _vm.entryData.createdAt,
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "mb-2" }, [
          _vm._v("Decision reached on the roadname rejection appeal."),
        ]),
        _c("strong", [_vm._v(_vm._s(_vm.entryData.appealDecision.to))]),
        _c("p", { staticClass: "mt-2 mb-2" }, [_vm._v("SG decision comment:")]),
        _c("p", { staticClass: "mt-2" }, [
          _c("strong", [_vm._v(_vm._s(_vm.entryData.decisionComment.to))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }