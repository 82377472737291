var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "label",
        { staticClass: "w-2/3" },
        [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate.continues",
                value: "required",
                expression: "'required'",
                modifiers: { continues: true },
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.geographical_name,
                expression: "geographical_name",
              },
            ],
            staticClass: "w-full mr-4 input-area",
            attrs: {
              type: "text",
              "data-cypress": "geographical_name-field",
              placeholder: "Enter Name Here...",
              name: "geographical_name",
              id: "geographical_name",
              "data-vv-as": "Proposed Address Locality Name",
              disabled: _vm.readOnly,
            },
            domProps: { value: _vm.geographical_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.geographical_name = $event.target.value
              },
            },
          }),
          _c("FieldErrorMessage", {
            attrs: { message: _vm.errors.first("geographical_name") },
          }),
          _c("showLoading", {
            attrs: { isLoading: "evaluatingPlaceName" },
            scopedSlots: _vm._u([
              {
                key: "component",
                fn: function () {
                  return [
                    !!_vm.geographical_name &&
                    !!_vm.geographical_name.length &&
                    _vm.evaluation &&
                    !!_vm.evaluation.length
                      ? _c(
                          "div",
                          _vm._l(_vm.evaluation, function (message, key) {
                            return _c("MessagePanel", {
                              key: key,
                              attrs: {
                                warning: "",
                                msg: message.shortDescription,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("norns-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.continues",
            value: "required",
            expression: "'required'",
            modifiers: { continues: true },
          },
        ],
        staticClass: "w-full mr-4 norns-select",
        attrs: {
          disabled: !_vm.lgas || _vm.readOnly,
          multiple: true,
          options: _vm.lgasClean,
          searchable: true,
          "close-on-select": false,
          "select-on-tab": true,
          "show-labels": true,
          "hide-selected": true,
          placeholder: "Select an LGA(s)",
          name: "lga",
          "data-cypress": "lga-field",
          "data-vv-as": "Local Government Area(s)",
        },
        model: {
          value: _vm.lga_name,
          callback: function ($$v) {
            _vm.lga_name = $$v
          },
          expression: "lga_name",
        },
      }),
      _c("FieldErrorMessage", { attrs: { message: _vm.errors.first("lga") } }),
      _vm._m(2),
      _c("norns-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.continues",
            value: "required",
            expression: "'required'",
            modifiers: { continues: true },
          },
        ],
        staticClass: "w-full norns-select",
        attrs: {
          disabled: (_vm.readOnly && !_vm.isEditing) || !_vm.lga_name.length,
          multiple: true,
          options: _vm.affectedAddressLocalities,
          searchable: true,
          "close-on-select": false,
          "select-on-tab": true,
          "show-labels": true,
          "hide-selected": true,
          placeholder: "Enter an Affected Locality",
          name: "affected_localities",
          "data-cypress": "affected-address-localities",
          "data-vv-as": "Affected Address Localities/Suburbs",
        },
        model: {
          value: _vm.affected_localities,
          callback: function ($$v) {
            _vm.affected_localities = $$v
          },
          expression: "affected_localities",
        },
      }),
      _c("FieldErrorMessage", {
        attrs: { message: _vm.errors.first("affected_localities") },
      }),
      _c(
        "label",
        { staticClass: "w-2/3" },
        [
          _c("p", { staticClass: "text-normal-bold mb-2" }, [
            _vm._v(" State Electorate "),
          ]),
          _c("taggableInput", {
            staticClass: "w-full mr-4",
            attrs: {
              datacypress: `state-electorate`,
              tagList: _vm.electorate,
              placeholder: `Start typing and press enter to add a Locality...`,
              disabled: _vm.readOnly,
            },
            on: {
              addTag: (electorate) =>
                _vm.addElectorate({
                  key: "boundary_amendment",
                  electorate: electorate,
                }),
              removeTag: (index) =>
                _vm.removeElectorate({
                  key: "boundary_amendment",
                  index: index,
                }),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("uploadScoped", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min_value:1",
                expression: "'min_value:1'",
              },
            ],
            staticClass: "mt-8",
            attrs: {
              "data-cypress": "upload-map",
              label: "Attach Marked Map",
              important: true,
              files: _vm.marked_map_reference_document,
              drafted: true,
              stateKey: "boundary_amendment",
              stateProp: "marked_map_reference_document",
              uploadFile: _vm.uploadFile,
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: "boundary_amendment",
                  prop: "marked_map_reference_document",
                  index: fileIndex,
                }),
            },
          }),
          _vm.errors.first("fileUploader")
            ? _c(
                "div",
                { staticClass: "flex items-center absolute" },
                [
                  _c("errorIcon"),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-red-error text-normal-bold" },
                    [
                      _vm._v(
                        " No Marked Map was uploaded for this locality. Please upload required files "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._m(3),
          _vm._l(_vm.reasonForNewAmendedLocalityAddressList, function (reason) {
            return _c(
              "RadioButton",
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required:true",
                    expression: "'required:true'",
                  },
                ],
                key: reason,
                staticClass: "flex-0 mt-6 mb-1",
                attrs: {
                  name: "reasoning_for_new_address_locality",
                  "data-cypress": "reasoning_for_new_address_locality",
                  checkedValue: reason,
                  "data-vv-as": "Reason for New/Amended Locality Address Name",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.reasoning_for_new_address_locality,
                  callback: function ($$v) {
                    _vm.reasoning_for_new_address_locality = $$v
                  },
                  expression: "reasoning_for_new_address_locality",
                },
              },
              [_vm._v(_vm._s(reason))]
            )
          }),
          _c("FieldErrorMessage", {
            attrs: {
              message: _vm.errors.first("reasoning_for_new_address_locality"),
            },
          }),
          _vm.reasoning_for_new_address_locality === `Other`
            ? _c("label", { staticClass: "w-2/3" }, [
                _vm._m(4),
                _c("div", { staticClass: "w-full mt-3 pl-8 flex" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reasoning_for_new_address_locality_other,
                        expression: "reasoning_for_new_address_locality_other",
                      },
                    ],
                    staticClass: "w-full input-area",
                    attrs: {
                      "data-cypress":
                        "reasoning_for_new_address_locality-field",
                      disabled: _vm.readOnly,
                    },
                    domProps: {
                      value: _vm.reasoning_for_new_address_locality_other,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.reasoning_for_new_address_locality_other =
                          $event.target.value
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        [
          _c("uploadScoped", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "min_value:1",
                expression: "'min_value:1'",
              },
            ],
            staticClass: "mt-8",
            attrs: {
              "data-cypress": "upload-map",
              label: "Attach supporting documentation",
              important: false,
              files: _vm.supporting_reference_document,
              drafted: true,
              stateKey: "boundary_amendment",
              stateProp: "supporting_reference_document",
              uploadFile: _vm.uploadFile,
              disabled: _vm.readOnly,
            },
            on: {
              deleteFile: (fileIndex) =>
                _vm.deleteUploadedFile({
                  key: "boundary_amendment",
                  prop: "supporting_reference_document",
                  index: fileIndex,
                }),
            },
          }),
          _vm.errors.first("fileUploader")
            ? _c(
                "div",
                { staticClass: "flex items-center absolute" },
                [
                  _c("errorIcon"),
                  _c(
                    "div",
                    { staticClass: "ml-2 text-red-error text-normal-bold" },
                    [
                      _vm._v(
                        " No Marked Map was uploaded for this locality. Please upload required files "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Proposed Address Locality Boundary Amendment "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "w-2/3" }, [
      _c("p", { staticClass: "text-normal-bold mt-4 mb-2" }, [
        _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
        _vm._v("Local Government Area(s) "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "w-2/3 mr-4" }, [
      _c("p", { staticClass: "text-normal-bold mb-2 mt-4" }, [
        _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
        _vm._v("Affected Address Localities/Suburbs "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-6" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Reason for Address Locality Boundary Amendment "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-normal-bold mb-2 mt-6 pl-8" }, [
      _c("span", { staticClass: "text-red-star" }, [_vm._v("*")]),
      _vm._v("Please provide details "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }