import { render, staticRenderFns } from "./authentication-layout.vue?vue&type=template&id=502d0bd8&scoped=true&"
var script = {}
import style0 from "./authentication-layout.vue?vue&type=style&index=0&id=502d0bd8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502d0bd8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3860973392/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('502d0bd8')) {
      api.createRecord('502d0bd8', component.options)
    } else {
      api.reload('502d0bd8', component.options)
    }
    module.hot.accept("./authentication-layout.vue?vue&type=template&id=502d0bd8&scoped=true&", function () {
      api.rerender('502d0bd8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/authentication/authentication-layout.vue"
export default component.exports