<template>
  <div class="base-container text-normal">
    <a
      @click="returnToManageProposalsList"
      class="pt-4 pb-4"
      v-if="!unique && !token"
    >
      <BackArrowWithTitle
        title="Manage Proposals"
        size="h4"
        class="pl-16 pt-4"
      />
    </a>
    <div class="w-9/10 mx-auto">
      <showLoading isLoading="fetchingProposalWithComments">
        <template v-slot:component>
          <div
            v-if="advertisement"
            class="w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 px-8"
          >
            <div
              class="w-full border flex flex-row border-gray-200 rounded items-center"
            >
              <span class="text-black font-bold text-2xl mx-8">
                {{ advertisement.geographical_name }}
              </span>
              <div class="dividing-line-v text-black flex-grow flex flex-row">
                <div class="flex-grow pb-4 pt-4 ml-4 w-1/4 pr-4">
                  <span>LGA</span>
                  <p v-for="lga in advertisement.lga" :key="lga">{{ lga }}</p>
                </div>
                <div class="flex-grow pb-4 pt-4 w-1/4 pr-4">
                  <span>Designation</span>
                  <p>{{ advertisement.designation }}</p>
                </div>
                <div class="flex-grow pb-4 pt-4 w-1/4">
                  <span>Publication Date</span>
                  <p>{{ formatDate(advertisement.date_start) }}</p>
                </div>
                <div class="flex-grow pb-4 pt-4 w-1/4">
                  <span>Closing Date</span>
                  <p>{{ formatDate(advertisement.date_end) }}</p>
                </div>
              </div>
            </div>
          </div>
          <section
            v-if="!!comments"
            class="pt-10 mb-20 justify-center w-full block self-center"
          >
            <!-- Only admin users are able to encrypt or decrypt the personal info -->
            <div
              v-if="user.isAdmin"
              class="flex justify-end mb-4"
            >
              <!-- If personal information is not yet encrypted, this feature will be disabled. -->
              <div class="flex" v-if="comments.length && isAlreadyEncrypted">
                <button
                  class="button-blue mx-4 jest-view-section-10 flex-1"
                  @click="openModal(modalName[1])"
                >
                  Decrypt PI
                </button>
              </div>
              <!-- Encrypted personal information cannot be re-encrypted. -->
              <div class="flex" v-if="comments.length && !isAlreadyEncrypted">
                <button
                  class="button-blue mx-4 jest-view-section-10 flex-1"
                  @click="openModal(modalName[0])"
                >
                  Encrypt PI
                </button>
              </div>
              <div class="flex">
                <button
                  class="button-blue mx-4 jest-view-section-10 flex-1"
                  @click="downloadSingleAgenda(false)"
                >
                  Download Public Comments Without PI
                </button>
              </div>
              <div class="flex" v-if="!isAlreadyEncrypted">
                <button
                  class="button-blue mx-4 jest-view-section-10 flex-1"
                  @click="downloadSingleAgenda(true)"
                >
                  Download Public Comments With PI
                </button>
              </div>
            </div>
            <div
              v-if="user.isAdmin && proposal.response_to_objections.length"
              style="color:black; text-align: right; margin: 1rem 0; font-size: 1.2rem;"
            >
              Council has uploaded {{ proposal.response_to_objections.length }} document{{ proposal.response_to_objections.length === 1 ? "." : "s." }}
            </div>
            <h2
              class="font-bold"
              v-bind:class="isPublic ? 'text-white' : 'text-black'"
            >
              Comments For This Proposal
            </h2>
            <div v-if="isUploadAvailable">
              <uploadScoped
                class="mt-8"
                data-cypress="upload-map"
                label="Response to Objections Upload"
                :important="false"
                :files="responseToObjectionFiles"
                :drafted="true"
                stateKey="proposal"
                stateProp="response_to_objections"
                :uploadFile="uploadFile"
                v-on:deleteFile="
                  fileIndex =>
                    responseToObjectionFiles.splice(fileIndex, 1)
                "
                v-validate="'min_value:1'"
                :disabled="false"
                :labelColor="'black'"
                :geonameIdentifier="proposal.geoname_identifier"
                :proposalName="proposal.geographical_name"
                :councilName="proposal.organisation_name"
                v-on:rerender="
                  payload => rerenderUploadScope(payload)
                "
              />
            </div>
            <div
              class="w-full flex rounded-lg pt-6 pb-6 mt-6 mb-6 pr-8 pl-8 orange-box border-2 text-center self-center justify-center"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="exclamation-circle"
                class="svg-inline--fa fa-exclamation-circle fa-w-32 w-8 h-8 mr-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                />
              </svg>

              <h4 class="text-center self-center orange-text">
                The comments provided here are private/confidential and should
                not be made public.
              </h4>
            </div>
            <div
              v-for="(comment, index) in comments"
              v-bind:key="index"
              class="w-full bg-white text-black rounded-lg pt-8 pb-8 mt-6 mb-6"
            >
              <div class="w-9/10 mx-auto">
                <div class="flex justify-between">
                  <div
                    class="flex-collumn justify-between pt-4 pb-4 -mr-4 self-center"
                  >
                    <p class="pb-4 text-base font-thin text-black">
                      Posted {{ formattedTime(comment.date_created) }}
                    </p>

                    <h3 v-if="user.isAdmin" 
                        class="font-bold self-center pb-4">
                        {{ comment.first_name }} {{ comment.last_name }}
                    </h3>

                    <p v-if="user.isAdmin"
                       class="pb-8 text-base font-thin text-black">
                        {{ comment.address_line_1 }} {{ comment.address_line_2
                        }}<br />
                        {{ comment.suburb }} {{ comment.state }}
                        {{ comment.postcode }}<br />
                        {{ comment.email }}<br />
                        {{ comment.phone_no }}
                    </p>
                    <h3
                      v-if="(isPublic || !user.isAdmin)"
                      class="font-bold self-center pb-4"
                    >
                      <p
                        class="pb-8 text-base font-thin text-black">
                          {{ comment.suburb }} {{ comment.state }}
                          {{ comment.postcode }}<br />
                      </p>
                    </h3>        
                  </div>

                  <div class="flex-start justify-between pt-4 pb-4 -mr-4">
                    <StatusButton
                      :label="`Public Comment ${getCommentType(
                        comment.is_supportive
                      )}`"
                      :status="getCommentType(comment.is_supportive)"
                    />
                  </div>
                </div>
                <div class="self-center">
                  <b>Interest In Proposal:</b>
                  <p class="pb-8 text-base text-black">
                    {{ comment.interest }}
                  </p>
                </div>
                <div class="self-center">
                  <b>Comment:</b>
                  <p class="pb-8 text-base text-black break-words">
                    {{ comment.comment }}
                  </p>
                </div>
                <div
                  class="self-center pb-8"
                  v-if="comment.public_comment_reference_document && !isAlreadyEncrypted"
                >
                  <b>Supporting Documentation:</b>
                  <div
                    :key="index"
                    v-for="(
                      document, index
                    ) in comment.public_comment_reference_document"
                  >
                    <a
                      :href="relativeFileUrl(document.s3_key)"
                      target="_blank"
                      >{{ document.title }}</a
                    >
                  </div>
                </div>
                <div
                  class="self-center pb-8"
                  v-if="comment.public_comment_reference_document && isAlreadyEncrypted"
                >
                  <b>Supporting Documentation:</b>
                  <div>
                    {{ `${comment.public_comment_reference_document.length} file(s) encrypted` }}
                  </div>
                </div>
                <div
                  v-if="comment.is_supportive === false && !comment.response"
                >
                  <form class="w-full">
                    <div class="py-3 mb-2 flex content-start flex-wrap">
                      <h3 class="font-bold self-center pb-4">Your Response</h3>
                      <div class="flex md:w-full md:mb-0">
                        <textarea
                          class="w-full mb-3 input-area"
                          id="comment-response"
                          type="text"
                          rows="7"
                          placeholder="Comment Response"
                          v-model="comment.draftResponse"
                          :disabled="!isRespondToComments || (!isPublic && !!user.isAdmin)"
                        ></textarea>
                      </div>
                    </div>
                  </form>
                  <div class="flex justify-between">
                    <ButtonWithSpinner
                      class="button-blue-hollow mt-4 mb-4"
                      :isSpinning="$wait.is('submittingComment')"
                      @click="
                        submitComment(comment.draftResponse, comment.identifier)
                      "
                      :disabled="
                        $wait.is('submittingComment') ||
                        !isRespondToComments || 
                        (!isPublic && !!user.isAdmin)
                      "
                    >
                      Send Response
                    </ButtonWithSpinner>
                  </div>
                </div>
                <div v-if="comment.response" class="pb-4 w-full">
                  <hr class="pnp-divider w-full mb-8" />
                  <h3 class="mb-2">Your Response</h3>
                  <p>
                    <b>{{ comment.response }}</b>
                  </p>
                  <div class="flex justify-end w-full">
                    <Button
                      class="button-blue-hollow mt-4 mb-4"
                      @click="
                        updateComment(comment.draftResponse, comment.identifier)
                      "
                      :disabled="!isRespondToComments || (!isPublic && !!user.isAdmin)"
                    >
                      Update Response
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <buttonWithSpinner
              :disabled="
                $wait.is('finalisingComments') || !isRespondToComments || (!isPublic && !!user.isAdmin)
              "
              class="button-blue float-right"
              @click="finaliseComments()"
              :isSpinning="$wait.is('finalisingComments')"
            >
              Finalise Comment Responses
            </buttonWithSpinner>
          </section>
        </template>
      </showLoading>
    </div>

    <!-- Personal Info encryption modal -->
    <modal :name="modalName[0]" :height="'auto'" @before-close="changeUploadTrimConfirmationToFalse">
      <div class="flex flex-col justify-between">
        <h2 class="text-title flex-0 px-4 mb-4">Encrypt Personal Info</h2>
        <div class="dividing-line-h"></div>
        <div class="p-4">
          <div>
            <div class="mr-3 py-3 justify-center md:mb-0">
              <label class="container flex height-fix text-black ml-8">
                Confirm that comments have been uploaded to TRIM successfully
                <input
                  class="checkbox-input"
                  type="checkbox"
                  v-model="isUploadTrimConfirmed"
                />
                <span class="border-2 checkmark"></span>
              </label>
            </div>
          </div>
          <div class="self-end my-6 flex justify-between">
            <ButtonWithSpinner
              class="button-blue-hollow mt-4 mb-4"
              :isSpinning="$wait.is('encryptingPersonalInfo')"
              @click="
                encryptPersonalInfo()
              "
              :disabled="$wait.is('encryptingPersonalInfo') || !isUploadTrimConfirmed"
            >
              Encrypt
            </ButtonWithSpinner>
            <button 
              @click="closeModal(modalName[0])"
              class="button-red-hollow mt-4 mb-4"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </modal>

    <!-- Personal Info decryption modal -->
    <modal :name="modalName[1]" :height="'auto'" @before-close="deletePastedPersonalInfo">
      <div class="flex flex-col justify-between">
        <h2 class="text-title flex-0 px-4 mb-4">Decrypt Personal Info</h2>
        <div class="dividing-line-h"></div>
        <div class="p-4">
          <div class="text-black mb-4">
            <span>Please copy the encrypted personal information attached in the Jira issue and paste it below:</span>
          </div>
          <div>
            <textarea 
              v-model="encryptedPI" 
              class="w-full input-area text-area-min-h"
            />
          </div>
          <div class="text-black">
            <span>If there is no encrypted personal information in the Jira issue, decryption is not possible.</span>
          </div>
          <div class="self-end mb-6 flex justify-between">
            <ButtonWithSpinner
              class="button-blue-hollow mt-4 mb-4"
              :isSpinning="$wait.is('decryptingPersonalInfo')"
              @click="
                decryptPersonalInfo(encryptedPI)
              "
              :disabled="$wait.is('decryptingPersonalInfo') || !encryptedPI"
            >
              Decrypt
            </ButtonWithSpinner>
            <button 
              @click="closeModal(modalName[1])"
              class="button-red-hollow mt-4 mb-4"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import { waitFor } from "vue-wait";
import moment from "moment";

import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");
const {
  mapActions: mapPnpActions
} = createNamespacedHelpers("pnpState");

import FileSaver from "file-saver";

import {
  PROPOSAL_TYPE_PLACE,
  PROPOSAL_TYPE_BOUNDARY,
  PROPOSAL_TYPE_LOCALITY,
  GEONAME_TYPE_LABELS
} from "../../pnp-constants.js";
import pnpCRUD from "@/helpers/pnpCRUD";

export default {
  name: "proposalComments",
  data() {
    return {
      PROPOSAL_TYPE_PLACE,
      PROPOSAL_TYPE_BOUNDARY,
      PROPOSAL_TYPE_LOCALITY,
      GEONAME_TYPE_LABELS,
      advertisement: null,
      comments: null,
      modalName: ["encryptPI", "decryptPI"],
      isUploadTrimConfirmed: false,
      isAlreadyEncrypted: false,
      encryptedPI: null,
      responseToObjectionFiles: null
    };
  },
  created() {
    this.fetchProposalWithComments();
  },
  methods: {
    ...mapPnpActions(["uploadFile"]),
    async rerenderUploadScope(data) {
      const updatedProposalInfo = await pnpCRUD.getSinglePlaceNameProposal(this.proposal.identifier);
      this.responseToObjectionFiles = updatedProposalInfo.response_to_objections;
    },
    // Encrypts personal info
    async encryptPersonalInfo() {
      this.$wait.start("encryptingPersonalInfo");
      try {
        const payload = {
          proposalInfo: {
            advertisement_identifier: this.advertisement_identifier,
            identifier: this.proposal.identifier,
            jira_id: this.proposal.jira_id
          }
        };

        const response = await pnpCRUD.encryptPersonalInfo(payload);

        this.$notify({
          group: "toast",
          type: response.type,
          title: response.title,
          text: response.message
        });

      } catch(e) {
        console.error(e);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "An internal error has occurred. Please try again later."
        });
      } finally {
        this.$wait.end("encryptingPersonalInfo");
        this.closeModal(this.modalName[0]);
        await this.fetchProposalWithComments();
      }
    },
    openModal(modalName) {
      this.$modal.show(modalName, {
        height: "auto"
      });
    },
    closeModal(modalName) {
      this.$modal.hide(modalName);
    },
    changeUploadTrimConfirmationToFalse() {
      this.isUploadTrimConfirmed = false;
    },
    deletePastedPersonalInfo() {
      this.encryptedPI = null;
    },
    // Decrypts personal info
    async decryptPersonalInfo(encryptedPI) {
      this.$wait.start("decryptingPersonalInfo");
      try {
        const payload = {
          proposalInfo: {
            advertisement_identifier: this.advertisement_identifier,
            identifier: this.proposal.identifier,
            jira_id: this.proposal.jira_id
          },
          encryptedPI: encryptedPI
        };

        const response = await pnpCRUD.decryptPersonalInfo(payload);
        
        this.$notify({
          group: "toast",
          type: response.type,
          title: response.title,
          text: response.message
        });
      } catch(e) {
        console.error(e);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error",
          text: "An internal error has occurred. Please try again later."
        });
      } finally {
        this.$wait.end("decryptingPersonalInfo");
        this.closeModal(this.modalName[1]);
        await this.fetchProposalWithComments();
      }
    },
    relativeFileUrl(key) {
      return `${__FILES_URL__}/${key}`;
    },
    formatDate: date => {
      return moment(date).format("DD MMM, YYYY");
    },
    getCommentType(isSupportive) {
      if (isSupportive === true) {
        return "Supporting";
      } else return "Objecting";
    },
    returnToManageProposalsList() {
      this.$router.push({ name: "placeNameProposals" });
    },
    formattedTime(time) {
      var tm = moment(time, "YYYY-MM-DD kk:mm:ss.SSSSSS");
      return tm.format("DD MMM YYYY hh:mm:ss a z ");
    },
    fetchProposalWithComments: waitFor(
      "fetchingProposalWithComments",
      async function () {
        try {
          const advertisement = await pnpCRUD.getAdvertisedProposal(
            this.advertisement_identifier
          );
          this.advertisement = advertisement;
          if (!!this.token && !!this.unique) {
            const comments = await pnpCRUD.getAdvertisedProposalCommentsPublic(
              this.advertisement_identifier,
              this.unique,
              this.token
            );
            this.comments = comments;
          } else {
            const comments = await pnpCRUD.getAdvertisedProposalComments(
              this.advertisement_identifier
            );
            this.comments = comments;
            
            // Checks if the personal info is already encrypted
            if(comments.length) {
              this.isAlreadyEncrypted = comments[0].first_name.includes("Name encrypted") ? true : false;
            }
          }
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Retrieving Advertisement & Comments",
            text: error
          });
        }
      }
    ),
    submitComment: waitFor(
      "submittingComment",
      async function (commentResponse, comment_identifier) {
        try {
          if (!!this.token && !!this.unique) {
            await pnpCRUD.submitAdvertisedProposalCommentResponsePublic(
              comment_identifier,
              commentResponse,
              this.unique,
              this.token
            );
          } else {
            await pnpCRUD.submitAdvertisedProposalCommentResponse({
              commentResponse,
              comment_identifier
            });
          }

          const newComments = this.comments.map(comment => {
            if (comment.identifier === comment_identifier) {
              comment.response = commentResponse;
            }
            return comment;
          });
          this.comment = newComments;
        } catch (error) {
          this.$notify({
            group: "toast",
            type: "error",
            title: "Error Submitting Response",
            text: error
          });
        }
      }
    ),
    finaliseComments: waitFor("finalisingComments", async function () {
      try {
        if (!!this.token && !!this.unique) {
          await pnpCRUD.transitionToReviewObjectionsPublic(
            this.advertisement.proposal_identifier,
            this.unique,
            this.token
          );
        } else {
          await pnpCRUD.transitionToReviewObjections(
            this.advertisement.proposal_identifier
          );
        }
        this.$router.push({ name: "placeNameProposals" });
        this.$notify({
          group: "toast",
          type: "success",
          title: "Comments Finalised",
          text: "Your Comments Have Been Finalised"
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("error: ", error);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Error Finalising Comments",
          text: "An error has occurred while finalising your comments"
        });
      }
    }),
    updateComment(commentResponse, comment_identifier) {
      this.comments.forEach(comment => {
        if (comment.identifier === comment_identifier) {
          comment.draftResponse = comment.response;
          comment.response = null;
        }
        return comment;
      });
    },
    async downloadSingleAgenda(includingPI) {
      this.$wait.start("downloadingAgenda");
      try {
        const docoLink = await pnpCRUD.getSingleAgendaDocument(
          this.advertisement.proposal_identifier,
          true,
          includingPI
        );
        const s3Params = docoLink.documentLocation.split("/");
        FileSaver.saveAs(`${docoLink.documentUrl}`, s3Params[1]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("There was an Error while downloading the Agenda ", e);
      } finally {
        this.$wait.end("downloadingAgenda");
      }
    }
  },
  computed: {
    ...mapUserGetters(["user"]),
    proposal: {
      get() {
        this.responseToObjectionFiles = this.$route.params.proposal.response_to_objections;
        return this.$route.params.proposal;
      }
    },
    advertisement_identifier: {
      get() {
        return this.$route.params.advertisementId;
      }
    },
    isPublic() {
      return this.$route.meta && this.$route.meta.isPublic;
    },
    token() {
      return this.$route.query ? this.$route.query.token : null;
    },
    unique() {
      return this.$route.query ? this.$route.query.unique : null;
    },
    isRespondToComments() {
      return this.$route.params.fromRespondToComments;
    },
    isUploadAvailable() {
      return this.$route.params.isCouncilUploadAvailable;
    }
  }
};
</script>

<style scoped>
.actions {
  color: #002664;
}
.arrow-icon {
  height: 20px;
  width: 20px;
  color: #d7153a;
}
.text-email {
  color: #002664;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.08px;
  line-height: 17px;
}
#userSVG {
  fill: grey;
}
.orange-box {
  background-color: #fff2d7;
  border-color: #dc5800;
  color: #dc5800;
}
.orange-text {
  color: #dc5800;
}
.full-margin {
  width: 100%;
  position: relative;
  margin-right: -50vw;
  height: 100px;
  margin-top: 100px;
  /* left: 50%; */
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}
.saveButton {
  color: #ffffff;
  background-color: #002664;
  border-color: #002664;
}

.grey-bg {
  background-color: #ecf1f3;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

.yellowButton {
  color: #ffffff;
  border-color: #f9be00;
  background-color: #f9be00;
}
.greenButton {
  color: #00a908;
  border-color: #00a908;
}
.redButton {
  color: #c60c30;
  border-color: #c60c30;
}
.redStar {
  height: 27px;
  width: 21.75px;
  color: #d7153a;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.inputfield {
  background: #ecf1f3;
}
.odd-even:nth-child(even) {
  font-weight: 300;
  background: #f4f7f9;
}

.odd-even:nth-child(odd) {
  background: #ffffff;
  font-weight: 300;
}

.pnp-divider {
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 1rem;
  height: 24px;
  width: 24px;
  border-color: #002664;
  background-color: #ffffff;
  transition-duration: 0.2s;
  border-radius: 2px;
}
input:disabled ~ .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-color: #5b5e63;
  background-color: rgb(194, 193, 193);
  transition-duration: 0.2s;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #84bddc;
  border-color: #002664;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #d8d8d8;
  border-color: #464a52;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #002664;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.height-fix {
  min-height: 24px;
}

.text-area-min-h {
  min-height: 8rem;
}
</style>
