var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ShowLoading",
    { attrs: { isLoading: "fetching user" } },
    [
      _c("template", { slot: "component" }, [
        _c("div", { staticClass: "w-full" }, [
          _c(
            "div",
            { staticClass: "base-container" },
            [
              _c("section", { staticClass: "flex justify-start flex-1 py-6" }, [
                _c(
                  "a",
                  {
                    staticClass: "cursor-pointer flex-0",
                    attrs: { "data-cypress": "back-arrow" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _c("BackArrowWithTitle", {
                      attrs: { title: "User Profile" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex-1 flex justify-end" },
                  [
                    _vm.isCurrentUsersProfile
                      ? _c("ChangePasswordModal", {
                          staticClass: "flex-0 mx-2",
                          attrs: { user: _vm.user },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex-0 ml-2" },
                      [
                        _vm.isCurrentUsersProfile
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "block text-center button-blue-hollow border-2 font-bold py-4 px-8 rounded focus:outline-none focus:shadow-outline",
                                attrs: {
                                  to: {
                                    name: "profile/edit",
                                    params: { id: _vm.user.email },
                                  },
                                  "data-cypress": "edit-account-link",
                                },
                              },
                              [_vm._v("Edit Account")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.unauthorised ? _c("NotAuthorisedMessage") : _vm._e(),
              !_vm.unauthorised
                ? _c(
                    "section",
                    {
                      staticClass:
                        "pt-1 justify-center w-full flex flex-col self-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-between bg-white text-black rounded-lg mb-4",
                        },
                        [
                          _c("div", { staticClass: "w-9/10 mx-auto flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-1/3 mx-auto flex items-center py-5",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "icon relative w-24 h-24 rounded-full mr-5 bg-grey-lighter text-center pt-8",
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "text-4xl",
                                      attrs: { icon: "user" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "flex-1 mr-4" }, [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "h-auto mb-2",
                                      attrs: {
                                        "data-cypress": "user-full-name",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.name) + " ")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "text-normal",
                                      attrs: {
                                        "data-cypress": "user-authority-name",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.authority_name ||
                                              _vm.user.organisation_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "dividing-line-v" }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-2/3 mx-auto justify-between items-center flex py-4 px-4",
                              },
                              [
                                _c(
                                  "UserProperty",
                                  {
                                    staticClass:
                                      "flex-0 flex flex-col justify-between items-start",
                                    attrs: { name: "User Type" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex flex-col",
                                        attrs: { "data-cypress": "user-type" },
                                      },
                                      _vm._l(_vm.accountType, function (type) {
                                        return _c(
                                          "div",
                                          {
                                            key: type,
                                            staticClass:
                                              "account-type flex-1 flex flex-row justify-between text-black",
                                          },
                                          [
                                            type === "PNP"
                                              ? _c("Tree", {
                                                  staticClass:
                                                    "flex-none text-black w-6 h-6 pr-2",
                                                })
                                              : _vm._e(),
                                            type === "RNP"
                                              ? _c("Road", {
                                                  staticClass:
                                                    "flex-none menu-img text-black w-6 h-6 pr-2",
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flex-auto",
                                                attrs: {
                                                  "data-cypress":
                                                    "user-type-item",
                                                },
                                              },
                                              [_vm._v(_vm._s(type))]
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-1 flex flex-row justify-between items-center mx-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mx-auto flex-0 flex flex-col",
                                      },
                                      [
                                        _c("UserProperty", {
                                          staticClass:
                                            "flex-1 flex flex-col justify-between items-start",
                                          attrs: {
                                            name: "Date Created",
                                            value: _vm.dateCreated,
                                            "data-cypress": "date-created",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "flex-0 flex justify-end" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-1 flex items-center justify-center",
                                        },
                                        [
                                          _c("AccountStatus", {
                                            attrs: {
                                              account_status:
                                                _vm.user.user_account_status,
                                              "data-cypress":
                                                "user-account-status",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.loggedUser.isAdmin
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-0 flex justify-end",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-1 flex items-center justify-center",
                                            },
                                            [
                                              _c("DeleteUserModal", {
                                                attrs: {
                                                  user: _vm.user,
                                                  isFromUserProfile: true,
                                                  loggedUser: _vm.loggedUser,
                                                  passedRoadNameProposals:
                                                    _vm.roadNameProposals,
                                                  passedPlaceNameProposals:
                                                    _vm.placeNameProposals,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full flex bg-white text-black rounded-lg items-baseline",
                        },
                        [
                          _c("div", { staticClass: "w-9/10 mx-auto flex" }, [
                            _c(
                              "div",
                              { staticClass: "w-1/6 flex items-center" },
                              [
                                _c("h3", { staticClass: "h-auto" }, [
                                  _vm._v("Contact Info"),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "dividing-line-v" }),
                            _c(
                              "div",
                              { staticClass: "w-5/6 flex justify-between p-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-0 flex flex-col justify-between items-start",
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-label flex-0" },
                                      [_vm._v("Phone number")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "flex-1 flex align-center",
                                        attrs: {
                                          "data-cypress": "user-telephone-link",
                                          href: _vm.telLink,
                                          rel: "tel",
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "pr-2 text-xl flex-none",
                                          attrs: { icon: "phone" },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "telephone-link text-black flex-none",
                                          },
                                          [_vm._v(_vm._s(_vm.user.phone_no))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-1 flex flex-row justify-between items-center mx-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mx-auto flex-0 flex flex-col",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-label flex-1" },
                                          [_vm._v("Email Address")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-black pb-2 pr-4 flex-1 flex align-center",
                                            attrs: {
                                              "data-cypress": "user-email-link",
                                              title: _vm.user.email,
                                              href: _vm.mailLink,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass:
                                                "pr-2 text-2xl flex-none",
                                              attrs: { icon: "envelope" },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-black flex-none",
                                              },
                                              [_vm._v(_vm._s(_vm.user.email))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("UserProperty", {
                                  staticClass:
                                    "flex-0 flex flex-col justify-between items-start",
                                  attrs: {
                                    name: "Position",
                                    value: _vm.user.position,
                                    "data-cypress": "user-position",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isRNPView && !_vm.unauthorised
                ? _c(
                    "section",
                    [
                      _c("ShowLoading", {
                        attrs: { isLoading: "fetching roadname proposals" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "component",
                              fn: function () {
                                return [
                                  _vm.roadNameProposals.length
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "text-black mt-16" },
                                            [_vm._v("Roadname Proposals")]
                                          ),
                                          _c("div", { staticClass: "flex-0" }, [
                                            _vm.loggedUser.isAdmin
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "px-3 py-3 md:mb-0 text-black ml-auto flex items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mr-3 py-3 justify-center md:mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "container items-baseline flex height-fix",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Check All Proposals "
                                                            ),
                                                            _c("input", {
                                                              staticClass:
                                                                "checkbox-input",
                                                              attrs: {
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  _vm.checkAll,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.checkAllProposals,
                                                              },
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "border-2 checkmark",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "BulkReassignmentsModal",
                                                      {
                                                        attrs: {
                                                          proposals:
                                                            _vm.checkedProposals,
                                                          fromUser: _vm.user,
                                                          btnDisabled:
                                                            _vm.checkedProposals
                                                              .identifiers
                                                              .length === 0
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          passStatusOfReassignment:
                                                            function ($event) {
                                                              return _vm.reRender(
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._l(
                                            _vm.roadNameProposals,
                                            function (proposal, index) {
                                              return _c(
                                                "section",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "justify-center w-full block self-center bg-white text-black rounded-lg pt-8 mt-3 mb-3",
                                                },
                                                [
                                                  _c(
                                                    "RoadNameProposalListItem",
                                                    {
                                                      attrs: {
                                                        proposal: proposal,
                                                        reassign: true,
                                                        index: index,
                                                        checkAll: _vm.checkAll,
                                                        untickProposal:
                                                          _vm.untickProposal,
                                                      },
                                                      on: {
                                                        passProposalIdentifier:
                                                          function ($event) {
                                                            return _vm.updateCheckedProposalList(
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  !_vm.roadNameProposals.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "self-center justify-center flex mt-32 text-black font-bold text-2xl w-full",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isCurrentUsersProfile
                                                  ? "You have"
                                                  : `${_vm.user.first_name} ${_vm.user.last_name} has`
                                              ) +
                                              " not made any proposals yet. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3598931616
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isRNPView && !_vm.unauthorised
                ? _c(
                    "section",
                    [
                      _c("ShowLoading", {
                        attrs: { isLoading: "fetching placename proposals" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "component",
                              fn: function () {
                                return [
                                  _vm.placeNameProposals.length
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "text-black mt-16" },
                                            [_vm._v("Placename Proposals")]
                                          ),
                                          _c("div", { staticClass: "flex-0" }, [
                                            _vm.loggedUser.isAdmin
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "px-3 py-3 md:mb-0 text-black ml-auto flex items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mr-3 py-3 justify-center md:mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "container items-baseline flex height-fix",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Check All Proposals "
                                                            ),
                                                            _c("input", {
                                                              staticClass:
                                                                "checkbox-input",
                                                              attrs: {
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  _vm.checkAll,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.checkAllProposals,
                                                              },
                                                            }),
                                                            _c("span", {
                                                              staticClass:
                                                                "border-2 checkmark",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "BulkReassignmentsModal",
                                                      {
                                                        attrs: {
                                                          proposals:
                                                            _vm.checkedProposals,
                                                          fromUser: _vm.user,
                                                          btnDisabled:
                                                            _vm.checkedProposals
                                                              .identifiers
                                                              .length === 0
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          passStatusOfReassignment:
                                                            function ($event) {
                                                              return _vm.reRender(
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          _vm._l(
                                            _vm.placeNameProposals,
                                            function (proposal, index) {
                                              return _c(
                                                "section",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "justify-center w-full block self-center bg-white text-black rounded-lg pt-8 mt-3 mb-3",
                                                },
                                                [
                                                  _c(
                                                    "PlaceNameProposalListItem",
                                                    {
                                                      attrs: {
                                                        proposal: proposal,
                                                        index: index,
                                                        checkAll: _vm.checkAll,
                                                        untickProposal:
                                                          _vm.untickProposal,
                                                      },
                                                      on: {
                                                        passProposalIdentifier:
                                                          function ($event) {
                                                            return _vm.updateCheckedProposalList(
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  !_vm.placeNameProposals.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "self-center justify-center flex mt-32 text-black font-bold text-2xl w-full",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isCurrentUsersProfile
                                                  ? "You have"
                                                  : `${_vm.user.first_name} ${_vm.user.last_name} has`
                                              ) +
                                              " not made any proposals yet. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2648149429
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.unauthorised
                ? _c(
                    "section",
                    {
                      staticClass:
                        "justify-center w-full block self-center text-center text-black rounded-lg pb-4 mt-3 mb-6",
                    },
                    [
                      !_vm.$wait.is(`fetching roadname proposals`) &&
                      !_vm.$wait.is(`fetching placename proposals`) &&
                      (_vm.roadNameProposals.length ||
                        _vm.placeNameProposals.length)
                        ? _c(
                            "div",
                            { staticClass: "mx-auto text-center self-center" },
                            [
                              _c("paginate", {
                                attrs: {
                                  "page-count": Math.ceil(
                                    _vm.totalProposals / _vm.limit
                                  ),
                                  "page-range": 10,
                                  "margin-pages": 1,
                                  "prev-text": "<",
                                  "next-text": ">",
                                  "container-class": "pagination",
                                  "page-class": "page-item",
                                  "click-handler": _vm.scrollToTop,
                                },
                                model: {
                                  value: _vm.page,
                                  callback: function ($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page",
                                },
                              }),
                              _c("p", [
                                _vm._v(
                                  " Showing " +
                                    _vm._s(
                                      (
                                        (_vm.page - 1) * _vm.limit +
                                        1
                                      ).toLocaleString()
                                    ) +
                                    " to " +
                                    _vm._s(
                                      Math.min(
                                        (_vm.page - 1) * _vm.limit + _vm.limit,
                                        _vm.totalProposals
                                      ).toLocaleString()
                                    ) +
                                    " of " +
                                    _vm._s(
                                      _vm.totalProposals.toLocaleString()
                                    ) +
                                    " proposals "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }