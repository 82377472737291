<template>
  <div>
    <div class="autocomplete">
      <multiSelect
        class="w-full rounded-lg text-normal mb-1 mt-1"
        v-model="value"
        :multiple="false"
        :disabled="disabled"
        :options="roadTypes"
        :searchable="true"
        :close-on-select="true"
        placeholder="Select Road Type..."
        name="roadTypeList"
        data-cypress="select-roadtype-input"
        @search-change="filterRoadTypeArray"
      >
        <span slot="noOptions">Loading...</span>
      </multiSelect>
    </div>
  </div>
</template>

<script>
import multiSelect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "roadTypeSelect",
  components: {
    multiSelect
  },
  props: {
    roadType: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  async mounted() {
    if (!this.roadTypeList.length) {
      await this.fetchRoadTypeList();
    }
  },
  data() {
    return {
      roadTypeFilter: ""
    };
  },
  computed: {
    ...mapGetters(["roadTypeList"]),
    value: {
      get() {
        return this.roadType;
      },
      set(value) {
        this.$emit("change", value[0]);
      }
    },
    roadTypes() {
      const filter = this.roadTypeFilter;
      return this.roadTypes.filter(function(item) {
        return new RegExp(`^${filter}`, "i").test(item);
      });
    }
  },
  methods: {
    ...mapActions(["fetchRoadTypeList"]),
    filterRoadTypeArray(searchQuery) {
      this.roadTypeFilter = searchQuery;
    }
  }
};
</script>
