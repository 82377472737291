// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/Images/BG_Login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".fork[data-v-39084fd7] {\n  background-size: cover;\n  background-attachment: fixed;\n  background-repeat: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.icon[data-v-39084fd7] {\n  max-height: 50px;\n}\n.btn-green[data-v-39084fd7] {\n  background-color: #69b32d;\n  border-color: #69b32d;\n  margin-bottom: 0;\n}\n.btn-green[data-v-39084fd7]:hover {\n  background-color: white;\n  color: #69b32d;\n}\n.btn-blue[data-v-39084fd7] {\n  background-color: #00abe6;\n  border-color: #00abe6;\n  margin-bottom: 0;\n}\n.btn-blue[data-v-39084fd7]:hover {\n  background-color: white;\n  color: #00abe6;\n}\n.subHeading[data-v-39084fd7] {\n  color: white;\n  size: 38px;\n  font-weight: 600;\n  margin: 16px;\n}\n.gnbLogo[data-v-39084fd7] {\n  margin-top: 64px;\n  width: 400px;\n}\n.gnbLogoTop[data-v-39084fd7] {\n  width: 150px;\n  margin: 16px;\n}\n.panel[data-v-39084fd7] {\n  padding: 56px;\n  margin: 16px;\n  font-weight: 600;\n}\n.logout[data-v-39084fd7] {\n  padding: 16px;\n  font-weight: 500;\n  font-size: 14px;\n  color: white;\n}\n", ""]);
// Exports
module.exports = exports;
