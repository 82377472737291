<template>
  <entry cardTitle="Gazettal" :eventDate="entryData.createdAt">
    <div>
      <p class="mb-2">
        The proposal gazette date was set to.
      </p>
      <p>
        <b>{{ format(entryData.dateGazetted.to) }}</b>
      </p>
    </div>
  </entry>
</template>

<script>
import moment from "moment";
import Entry from "@/components/proposals/common/audit-trail/entries/entry";

export default {
  name: "RoadNameGazettalTrailEntry",

  /**
   * Instance properties
   */
  props: {
    entryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  /**
   * Instance child components
   */
  components: {
    Entry
  },

  /**
   * Local instance methods
   */
  methods: {
    format(date) {
      return moment(date).format("DD MMM YYYY");
    }
  }
};
</script>
