"use strict";

export default {
  // eslint-disable-next-line no-undef
  identityPoolId: __AWS_COGNITO_IDENITY_POOL_ID__,
  // eslint-disable-next-line no-undef
  userPoolId: __AWS_COGNITO_USER_POOL_ID__,
  // eslint-disable-next-line no-undef
  userPoolWebClientId: __AWS_COGNITO_CLIENT_ID__,
  // eslint-disable-next-line no-undef
  region: __AWS_REGION__
};
