<template>
  <ForkLayout
    :goToPNPDashboard="goToPNPDashboard"
    :goToRNPDashboard="goToRNPDashboard"
    :displayName="userGivenName"
    :userLogout="userLogout"
  />
</template>

<script>
import ForkLayout from "../components/fork/Layout";
import { createNamespacedHelpers, mapMutations } from "vuex";

const { mapGetters: mapUserGetters } = createNamespacedHelpers("user");

export default {
  name: "Fork",
  components: { ForkLayout },
  created() {
    if (this.user.isAdmin) {
      this.goToRNPDashboard();
    }
    if (
      !(
        this.userGroups.includes("PnaUsers") &&
        this.userGroups.includes("RnaUsers")
      )
    ) {
      if (
        this.userGroups.includes("PnaUsers") ||
        this.userGroups.includes("GeneralPublicUsers")
      ) {
        this.goToPNPDashboard();
        return;
      }
      if (this.userGroups.includes("RnaUsers")) {
        this.goToRNPDashboard();
      } else {
        this.goToRNPDashboard();
      }
    }
  },
  computed: {
    ...mapUserGetters(["userGivenName", "userGroups", "user"])
  },
  methods: {
    goToPNPDashboard() {
      this.viewPNP();
      this.$router.push({ name: "placeNameProposals" });
    },
    goToRNPDashboard() {
      this.viewRNP();
      this.$router.push({ name: "proposals" });
    },
    userLogout() {
      this.$router.push({ name: "logout" });
    },
    ...mapMutations(["viewRNP", "viewPNP"])
  }
};
</script>
