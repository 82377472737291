<template>
  <div class="flex flex-1 flex-col">
    <Loading-spinner v-wait:visible="'fetching user details'" class="flex-0" />
    <div v-wait:hidden="'fetching user details'" class="flex-1 flex">
      <UnauthorisedMessage v-if="unuathorised" class="flex flex-1" />
      <div class="base-container text-normal text-black" v-if="!unuathorised">
        <section class="pt-1 justify-center w-full block">
          <div class="flex justify-between">
            <a @click="$router.go(-1)" class="flex justify-between pb-4 self-center">
              <BackArrowWithTitle title="Edit Profile" />
            </a>
            <div class="flex justify-between items-center py-4">
              <router-link
                :to="{ name: 'profile/view' }"
                class="button-blue-hollow flex-0 items-center"
              >View User Profile</router-link>
            </div>
          </div>
        </section>
        <form novalidate>
          <section class="pt-1 bg-white w-full block rounded-lg pt-8 pb-8 mb-6">
            <div class="w-9/10 mx-auto flex flex-col text-black">
              <div class="pt-6 pb-0">
                <h2 class="text-title">Personal Details</h2>
              </div>
              <div class="dividing-line-h"></div>
              <div class="pt-6 pb-6 flex">
                <div class="w-1/5 flex-col text-center lg:flex sm:hidden">
                  <div
                    class="icon relative w-24 h-24 rounded-full flex justify-center items-center mr-5 bg-grey-lighter text-center"
                  >
                    <font-awesome-icon icon="user" class="text-4xl flex-0" />
                  </div>
                </div>
                <div class="sm:w-full lg:w-4/5 justify-center">
                  <div class="py-3 w-full flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                    <div class="sm:w-full lg:w-1/2 justify-center md:mb-0">
                      <label
                        class="block text-gray-700 text-sm font-semibold mb-1"
                        for="first_name"
                      >
                        <span class="redStar">*</span> First Name
                      </label>
                      <input
                        class="w-full input-area"
                        id="first_name"
                        data-cypress="first_name"
                        name="first_name"
                        type="text"
                        v-model="user.first_name"
                        v-validate="'required'"
                        data-vv-as="First name"
                      />
                      <FieldErrorMessage
                        class="mt-2 flex-row"
                        :message="errors.first('first_name')"
                      />
                    </div>
                    <div class="sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0">
                      <label class="block text-gray-700 text-sm font-semibold mb-1" for="last_name">
                        <span class="redStar">*</span> Last Name
                      </label>
                      <input
                        class="w-full input-area"
                        id="last_name"
                        data-cypress="last_name"
                        name="last_name"
                        type="text"
                        v-model="user.last_name"
                        v-validate="'required'"
                        data-vv-as="Last name"
                      />
                      <FieldErrorMessage
                        class="mt-2 flex-row"
                        :message="errors.first('last_name')"
                      />
                    </div>
                  </div>

                  <div class="py-3 flex flex-row mb-2">
                    <div class="sm:w-full lg:w-1/2 pr-4 justify-center md:mb-0">
                      <label
                        class="block text-gray-700 text-sm font-semibold mb-1"
                        for="user_account_status"
                      >Account Status</label>
                      <select
                        v-model="user.user_account_status"
                        class="w-full input-area"
                        data-cypress="account_status"
                        name="user_account_status"
                        id="user_account_status"
                        :disabled="!currentUser.isAdmin"
                        v-validate
                      >
                        <option
                          v-for="value in accountStatuses"
                          :key="value"
                          :value="value"
                        >{{ toProperCase(value) }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="w-full py-3 flex flex-col mb-2">
                    <label
                      class="md:w-full block text-gray-700 text-sm font-semibold mb-1"
                      for="grid-entity"
                    >
                      <span class="redStar">*</span> User Types
                    </label>
                    <div class="flex-1 flex flex-wrap">
                      <Checkbox
                        class="w-1/2 flex-no-shrink"
                        v-model="isRNP"
                        :disabled="isRNPDisabled"
                        label="Road Name Authority Representative"
                        data-cypress="RNA_Representative"
                        name="isRNP"
                        v-validate
                        :isDisabled="true"
                      />
                      <Checkbox
                        class="w-1/2 flex-no-shrink"
                        v-if="currentUser.isAdmin && user.isRNP && entity === 'Administrative Spatial Program'"
                        v-model="isASP"
                        :disabled="!currentUser.isAdmin"
                        label="Administrative Spatial Program User"
                        data-cypress="ASP_User"
                        name="isASP"
                        v-validate
                        :isDisabled="true"
                      />
                      <Checkbox
                        class="w-1/2 flex-no-shrink"
                        :disabled="isPublic || user.isRNP || user.isPNP"
                        v-model="isPublic"
                        label="Public Applicant"
                        data-cypress="Public_applicant"
                        name="isPublic"
                        v-validate
                        :isDisabled="true"
                      />
                      <Checkbox
                        class="w-1/2 flex-no-shrink"
                        v-model="isPNP"
                        :disabled="isPNPDisabled"
                        label="Local/Other Govt Entity Representative"
                        data-cypress="Other_Representative"
                        name="isPNP"
                        v-validate
                        :isDisabled="true"
                      />
                      <Checkbox
                        class="w-1/2 flex-no-shrink"
                        v-model="user.isAdmin"
                        v-if="currentUser.isAdmin"
                        label="GNB Admin"
                        data-cypress="Admin"
                        name="isAdmin"
                        :disabled="isAdminDisabled"
                        v-validate
                        :isDisabled="true"
                      />
                    </div>
                  </div>

                  <div
                    class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2"
                    v-if="!user.isPublic"
                  >
                    <div class="sm:w-full lg:w-1/2 justify-center md:mb-0">
                      <label class="block text-gray-700 text-sm font-semibold mb-1" for="entity">
                        <span class="redStar">*</span> Entity User Represents
                      </label>
                      <EntitySelect
                        v-model="entity"
                        name="entity"
                        class="flex-1 input-area"
                        data-cypress="entity_represents"
                        v-validate="isRNP || isPNP ? 'required' : ''"
                        :entityList="entityList"
                        :disabled="
                          !currentUser.isAdmin ||
                            (!originalUser.isRNP && !originalUser.isPNP)
                        "
                      />
                    </div>
                    <div class="sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0">
                      <label class="block text-gray-700 text-sm font-semibold mb-1" for="position">
                        <span class="redStar">*</span> Position/Title
                      </label>
                      <input
                        class="w-full input-area"
                        id="position"
                        name="position"
                        data-cypress="titel"
                        type="text"
                        v-validate="'required'"
                        v-model="user.position"
                        :disabled="user.isPublic"
                        data-vv-as="Position"
                      />
                      <FieldErrorMessage class="mt-2 flex-row" :message="errors.first('position')" />
                    </div>
                  </div>
                  <div class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                    <div class="sm:w-full lg:w-1/2 justify-center md:mb-0">
                      <label class="block text-gray-700 text-sm font-semibold mb-1" for="phone_no">
                        <span class="redStar">*</span> Phone Number
                      </label>
                      <input
                        class="w-full input-area"
                        id="phone_no"
                        name="phone_no"
                        data-cypress="phoneNumber"
                        type="text"
                        v-model="user.phone_no"
                        v-validate="'required|phoneNumber'"
                        data-vv-as="Phone number"
                      />
                      <FieldErrorMessage class="mt-2 flex-row" :message="errors.first('phone_no')" />
                    </div>
                    <div class="sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0">
                      <label class="block text-gray-700 text-sm font-semibold mb-1" for="last_name">
                        <span class="redStar">*</span> Email Address
                      </label>
                      <input
                        class="w-full input-area"
                        id="email"
                        name="email"
                        data-cypress="email"
                        type="email"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                  </div>

                  <div v-if="user.isPublic">
                    <div class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                      <div class="w-full justify-center md:mb-0">
                        <label
                          class="block text-gray-700 text-sm font-semibold mb-1"
                          for="address_line_1"
                        >Address Line 1</label>
                        <input
                          class="w-full input-area"
                          id="address_line_1"
                          name="address_line_1"
                          type="text"
                          v-model="user.address_line_1"
                          data-vv-as="Address line 1"
                          v-validate
                        />
                      </div>
                    </div>
                    <div class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                      <div class="w-full justify-center md:mb-0">
                        <label
                          class="block text-gray-700 text-sm font-semibold mb-1"
                          for="address_line_2"
                        >Address Line 2</label>
                        <input
                          class="w-full input-area"
                          id="address_line_2"
                          name="address_line_2"
                          type="text"
                          v-model="user.address_line_2"
                          data-vv-as="Address line 2"
                          v-validate
                        />
                      </div>
                    </div>
                    <div class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                      <div class="sm:w-full lg:w-1/2 justify-center md:mb-0">
                        <label
                          class="block text-gray-700 text-sm font-semibold mb-1"
                          for="suburb"
                        >Suburb</label>
                        <input
                          class="w-full input-area"
                          id="suburb"
                          name="suburb"
                          type="text"
                          v-model="user.suburb"
                          data-vv-as="Suburb"
                          v-validate
                        />
                      </div>
                      <div
                        class="sm:w-full lg:w-1/2 sm:pt-6 lg:pt-0 lg:ml-8 justify-center md:mb-0"
                      >
                        <label
                          class="block text-gray-700 text-sm font-semibold mb-1"
                          for="address_line_2"
                        >State</label>
                        <select
                          v-model="user.state"
                          class="w-full input-area"
                          name="state"
                          id="state"
                          data-vv-as="State"
                          v-validate
                        >
                          <option v-for="value in states" :key="value" :value="value">{{ value }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="py-3 flex flex-row sm:flex-wrap lg:flex-no-wrap mb-2">
                      <div class="sm:w-full lg:w-1/2 lg:pr-4 justify-center md:mb-0">
                        <label
                          class="block text-gray-700 text-sm font-semibold mb-1"
                          for="postcode"
                        >Postcode</label>
                        <input
                          class="w-full input-area"
                          id="postcode"
                          name="postcode"
                          type="text"
                          v-model="user.postcode"
                          data-vv-as="Post code"
                          v-validate
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="pt-1 w-full block">
            <div class="flex justify-end mb-24">
              <ButtonWithSpinner
                class="button-blue"
                data-cypress="save-button"
                :isSpinning="$wait.is('saving user')"
                @click="save"
                :disabled="!isDirty || !!errors.items.length || $wait.any"
              >Save Changes</ButtonWithSpinner>
            </div>
          </section>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser, updateUser } from "../../helpers/userCRUD.js";
import userDetailsMixin from "../../mixins/user-details";
import entityListMixin from "../../mixins/entity-list";
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapUserGetters,
  mapMutations: mapUserMutations
} = createNamespacedHelpers("user");
import EntitySelect from "./entitySelect";

export default {
  name: "editUserProfile",
  mixins: [userDetailsMixin, entityListMixin],
  components: {
    EntitySelect
  },

  async mounted() {
    await this.getUser();
  },

  data() {
    return {
      unuathorised: false,
      user: {
        address_line_1: "",
        address_line_2: "",
        authority_name: "",
        date_created: "",
        date_modified: "",
        email: "",
        first_name: "",
        id: "",
        identifier: "",
        isPNP: "",
        isPublic: "",
        isRNP: "",
        isASP: "",
        isAdmin: "",
        last_name: "",
        organisation_id: "",
        organisation_name: "",
        phone_no: "",
        pnp_identifier: "",
        position: "",
        postcode: "",
        resource_id: "",
        rnp_identifier: "",
        road_naming_authority_id: "",
        state: "",
        suburb: "",
        user_account_status: "",
        user_id: ""
      },
      originalUser: {
        address_line_1: "",
        address_line_2: "",
        authority_name: "",
        date_created: "",
        date_modified: "",
        email: "",
        first_name: "",
        id: "",
        identifier: "",
        isPNP: "",
        isPublic: "",
        isRNP: "",
        isASP: "",
        isAdmin: "",
        last_name: "",
        organisation_id: "",
        organisation_name: "",
        phone_no: "",
        pnp_identifier: "",
        position: "",
        postcode: "",
        resource_id: "",
        rnp_identifier: "",
        road_naming_authority_id: "",
        state: "",
        suburb: "",
        user_account_status: "",
        user_id: ""
      },
      accountStatuses: ["approved", "suspended", "pending"],
      states: ["NSW", "ACT", "VIC", "TAS", "SA", "WA", "NT", "QLD"]
    };
  },
  computed: {
    ...mapUserGetters({ currentUser: "user" }),
    isPublic: {
      get() {
        return this.user.isPublic;
      }
    },
    isRNP: {
      get() {
        return this.user.isRNP;
      },
      set(value) {
        this.user.isRNP = value;
        this.user.road_naming_authority_id = this.user.isRNP
          ? this.userEntity.rnid
          : null;
      }
    },
    isPNP: {
      get() {
        return this.user.isPNP;
      },
      set(value) {
        this.user.isPNP = value;
        this.user.organisation_id = this.user.isPNP
          ? this.userEntity.pnid
          : null;
      }
    },
    isASP: {
      get() {
        return this.user.isASP;
      },
      set(value) {
        this.user.isASP = value;
      }
    },
    entity: {
      get() {
        return this.user.authority_name || this.user.organisation_name;
      },
      set(value) {
        if (!value) value = { name: "", rnid: "", pnid: null };

        this.user.road_naming_authority_id = value.rnid;
        this.user.authority_name = value.rnid ? value.name : null;

        this.user.organisation_id = value.pnid;
        this.user.organisation_name = value.pnid ? value.name : null;

        this.user.isRNP = !!value.rnid;
        this.user.isPNP = !!value.pnid;
      }
    },
    userEntity: {
      get() {
        return (
          this.entityList.filter(
            e =>
              e.name === this.user.authority_name ||
              e.name === this.user.organisation_name
          )[0] || {
            name: "",
            rnid: "",
            pnid: null
          }
        );
      }
    },
    isRNPDisabled: {
      get() {
        if (this.originalUser.isRNP) {
          return true;
        }
        if (!this.currentUser.isAdmin) {
          return true;
        }

        return !this.userEntity.rnid;
      }
    },
    isPNPDisabled: {
      get() {
        if (this.originalUser.isPNP) {
          return true;
        }
        if (!this.currentUser.isAdmin) {
          return true;
        }

        return !this.userEntity.pnid;
      }
    },
    isAdminDisabled: {
      get() {
        return (
          this.originalUser.isPublic ||
          (!this.userEntity.pnid && this.userEntity.rnid)
        );
      }
    },
    isDirty: {
      get() {
        return Object.keys(this.fields).some(f => this.fields[f].dirty);
      }
    }
  },

  methods: {
    ...mapUserMutations(["setUser"]),
    async save() {
      try {
        this.$wait.start("saving user");
        this.user = await updateUser(this.user);
        this.originalUser = Object.assign({}, this.user);
        if (this.user.email === this.currentUser.email) {
          this.setUser(Object.assign({}, this.user));
        }

        this.$router.go(-1);

        this.$notify({
          group: "toast",
          type: "success",
          title: "User has been updated",
          text: `${this.user.first_name} ${this.user.last_name} has been updated`
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$notify({
          group: "toast",
          type: "error",
          title: "Unable to save updates",
          text: `Something went wrong updating this profile: ${e.message}`
        });
      } finally {
        this.$wait.end("saving user");
      }
    },
    async getUser() {
      this.$wait.start("fetching user details");
      try {
        this.user = await getUser(this.$route.params.id);
        this.originalUser = Object.assign({}, this.user);
      } catch (e) {
        this.unuathorised = true;
      }
      this.$wait.end("fetching user details");
    },
    toProperCase(str) {
      return `${str[0].toUpperCase()}${str.slice(1)}`;
    }
  }
};
</script>

<style scoped>
.actions {
  color: #002664;
}
.arrow-icon {
  height: 20px;
  width: 20px;
  color: #d7153a;
}
.text-email {
  color: #002664;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.08px;
  line-height: 17px;
}
#userSVG {
  fill: grey;
}
.full-margin {
  width: 100%;
  position: relative;
  margin-right: -50vw;
  height: 100px;
  margin-top: 100px;
  /* left: 50%; */
}
.darkblueButton {
  color: #002664;
  border-color: #002664;
}
.saveButton {
  color: #ffffff;
  background-color: #002664;
  border-color: #002664;
}

.grey-bg {
  background-color: #ecf1f3;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}
.lightBlueButton {
  color: #0a7cb9;
  border-color: #0a7cb9;
}
.yellowButton {
  color: #ffffff;
  border-color: #f9be00;
  background-color: #f9be00;
}
.greenButton {
  color: #00a908;
  border-color: #00a908;
}
.redButton {
  color: #c60c30;
  border-color: #c60c30;
}
.redStar {
  height: 27px;
  width: 21.75px;
  color: #d7153a;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.inputfield {
  background: #ecf1f3;
}
.odd-even:nth-child(even) {
  font-weight: 300;
  background: #f4f7f9;
}

.odd-even:nth-child(odd) {
  background: #ffffff;
  font-weight: 300;
}

.dividing-line-h {
  border-bottom-width: 1px;
}
</style>
